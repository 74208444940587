import { Injectable } from '@angular/core';
import { RCVarsService } from './rcvars-service.service';
import { AStarFinder } from 'astar-typescript';
import { PGBar } from './pgbar';
import { plog } from './plog.service';

@Injectable({
  providedIn: 'root',
})
export class DramaService {
  plog;
  wh;
  cam
  width;
  height;
  roundwait;
  roundwait_default = 1000;
  isdramaplaying = false;
  cam_lastfollow;
  thetw; // the tween;
  torestoreaniPlay = [];
  dramacode = "";
  snapshotflag = "";
  dramainfo = [];
  spriteloadedhere = [];
  sounds = [];
  npcpositions = [];
  fromwhere = "";
  pgbar;
  skipbtn;
  fillbg;
  filltxt;
  stttxt;
  credit;
  makevisible = [];
  npcloaded = [];
  cratedimg = [];
  creatednpc = [];
  clickpreventer;
  setting_autoturn = "yes";

  constructor(private rcvarsservice: RCVarsService) {
    this.plog = new plog();
    //this.plog.setLevel(4);
  }

  async play(wh, dramacode, fromwhere = "global", snapshotflag = "") {
    if (this.isdramaplaying == true) {
      this.plog.log("drama preventing playing another drama,");
      return;
    }
    this.isdramaplaying = true;
    if (fromwhere == "") fromwhere = "global";
    this.wh = wh;
    try {
      await wh.registry.list.gameitf.closegui(wh, wh.inventorycontainer_stackitem);
    } catch (e) {
      console.log("ERROR", e);
    }

    this.wh.walkpathcmd = [];
    try {
      this.wh.time.removeEvent(this.wh.npcfacespriteevent);
    } catch (e) {
      this.plog.log(e);
    }
    this.dramacode = dramacode;
    this.fromwhere = fromwhere;
    //this.snapshotflag = snapshotflag;
    this.wh.registry.list.timectl.pausetime = true;

    this.width = this.wh.sys.game.canvas.width;
    this.height = this.wh.sys.game.canvas.height;

    if (this.fromwhere == "npcconver") {
      if (this.wh.npccontainer != undefined) {
        try {
          this.wh.npccontainer.visible = false;
        } catch (e) {
          this.plog.log("ERROR", e);
        }
      }
    }
    this.wh.registry.list.rcloading.loading2(wh);


    this.fillbg = wh.add.rectangle(0, 0, this.width, this.height, 0x000000);
    this.fillbg.setPosition(0, 0);
    this.fillbg.setOrigin(0, 0);
    this.fillbg.visible = false;
    this.fillbg.setDepth(900000);

    this.filltxt = wh.add.text(0, 0, '', wh.fs["dramafilltxt"]);
    this.filltxt.setWordWrapWidth(this.width * 0.8);
    this.filltxt.setDepth(900090);

    this.stttxt = wh.add.text(0, 0, '', wh.fs["dramastttxt"]);
    this.stttxt.setWordWrapWidth(this.width * 0.8);
    this.stttxt.setDepth(900090);
    this.stttxt.visible = false;


    this.credit = wh.add.text(0, 0, '', wh.fs["dramacredittxt"]);
    this.credit.setWordWrapWidth(this.width * 0.4);
    this.credit.setDepth(900090);

    this.dramainfo = await this.wh.registry.list.rchttp.getcommon2("drama_get_data", { dramaid: this.dramacode });
    if (this.dramainfo.length == 0) {
      this.gif_nodrama();

      this.isdramaplaying = false;
    }
    this.plog.log('DramaService play', dramacode);
    this.npcpositions = [];
    this.npcloaded = [];
    this.torestoreaniPlay = [];
    this.creatednpc = [];
    this.cratedimg = [];
    this.sounds = [];
    for (const npclistk in this.wh.npclist) {
      var npclistv = this.wh.npclist[npclistk];
      var npcnamea = (npclistk + "").split("-");
      //sthis.plog.log("npcpositions s", npcnamea);
      this.npcpositions.push({ name: npcnamea[1], currentx: npclistv.currentx, currenty: npclistv.currenty });
    }
    for (const npclistk in this.wh.npclist) {
      //all npc face char at start
      var npclistv = this.wh.npclist[npclistk];
      var npcnamea = (npclistk + "").split("-");
      var npcinfo = this.rcvarsservice.gameobj.registry.list.rcvarpass.npc[npcnamea[1]];

      //this.plog.log("loop npc for facing", npclistk, npclistv, npcinfo);
      //sthis.plog.log("npcpositions s", npcnamea);

      //this.plog.log('npcclick npcinfo', npcinfo, wh);
      var diffx = npclistv.currentx - this.wh.char_main.currentx;
      var diffy = npclistv.currenty - this.wh.char_main.currenty;
      //this.plog.log('npcclick npcinfo diff', diffx, diffy);

      //turning face 
      var npcfacesprite;
      var npcfacespriteinfo = npcinfo["mapsprite"].split("_");
      if (Math.abs(diffx) > Math.abs(diffy)) {
        if (diffx < 0) {
          npcfacesprite = npcfacespriteinfo[0] + "-idle-r";
        }
        if (diffx > 0) {
          npcfacesprite = npcfacespriteinfo[0] + "-idle-l";
        }
      } else {
        if (diffy < 0) {
          npcfacesprite = npcfacespriteinfo[0] + "-idle-d";
        }
        if (diffy > 0) {
          npcfacesprite = npcfacespriteinfo[0] + "-idle-u";
        }
      }
      if (npcfacesprite != undefined) {

        if (this.setting_autoturn == "yes") {
          //this.plog.log('npcclick npcinfo npcfacesprite', npcfacesprite, this.rcvarsservice.gameobj.scene.scenes[0].anims.anims.entries);
          if (this.rcvarsservice.gameobj.scene.scenes[0].anims.anims.entries[npcfacesprite] != undefined &&
            this.rcvarsservice.gameobj.scene.scenes[0].anims.anims.entries[npcfacesprite].frames != undefined &&
            this.rcvarsservice.gameobj.scene.scenes[0].anims.anims.entries[npcfacesprite].frames.length > 0) {

            //this.plog.log('npcclick npcinfo facing to', npcfacesprite, this);
            if (npclistv != undefined && npclistv.anims != undefined && npclistv.anims.currentFrame != undefined) {
              //this.plog.log("drama auto face npc", npcfacesprite, npclistv);

              var currentkey = npclistv.anims.currentFrame.textureKey + "";
              npclistv.play({ key: npcfacesprite, repeatDelay: 2000 });
            }

          }
        }
      }
    }
    this.clickpreventer = this.wh.add.sprite(0, 0, "thecolor", "black");
    this.clickpreventer.alpha = 0;
    this.clickpreventer.removeAllListeners();
    this.clickpreventer.setInteractive();
    this.clickpreventer.setOrigin(0, 0);
    this.clickpreventer.displayHeight = this.height;
    this.clickpreventer.displayWidth = this.width;
    this.clickpreventer.setDepth(100000000000); // above all

    this.wh.registry.list.gameitf.guiwindowhideall(this.wh);

    this.skipbtn = this.wh.add.sprite(0, 0, "thesb", "skip");
    this.skipbtn.displayHeight = 20;
    this.skipbtn.scaleY = this.skipbtn.scaleX;
    this.skipbtn.setPosition(this.width / 2, this.height - 40);
    this.skipbtn.setDepth(1000000);
    this.skipbtn.removeAllListeners();
    this.skipbtn.setInteractive();
    this.skipbtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        this.isdramaplaying = false;
        this.skipbtn.visible = false;
      },
      this
    );

    let loader = new Phaser.Loader.LoaderPlugin(this.wh);


    var svversionurlstring = this.rcvarsservice.svversionstring;
    if (svversionurlstring != '') {
      svversionurlstring = '?' + svversionurlstring;
    }
    for (var k in this.dramainfo) {
      var v = this.dramainfo[k];
      if (v['action'] == "playsprite") {
        this.spriteloadedhere.push(v["params"]["p2"]);
        var spritea = v["params"]["p2"].split("_");

        loader.atlas(
          spritea[0] + '',
          this.wh.registry.list.rcvarpass.rc_baseurl +
          'sprite/' + spritea[0] + '.png' + svversionurlstring,
          this.wh.registry.list.rcvarpass.rc_baseurl +
          'sprite/' + spritea[0] + '.json' + svversionurlstring
        ); // + Math.random());
      }
      if (v['params']['audio'] != undefined && v['params']['audio'] != "") {
        //this.plog.log("dramapreloadingaudio",v['params']);
        if (this.wh.isofftmute != true) {
          loader.audio(
            v['params']['audio'] + '',
            this.rcvarsservice.rc_baseurl +
            v['params']['audio'] +
            svversionurlstring
          );
        }
      }
      if (v['action'] == "sound-start") {
        if (this.wh.isofftmute != true) {
          loader.audio(
            v['params']['p1'] + '',
            this.rcvarsservice.rc_baseurl +
            v['params']['p1'] +
            svversionurlstring
          );
        }
      }
      if (v['action'] == "spawnnpc") {
        this.npcloaded.push(v["params"]["p1"]);
        var spritea = v["params"]["p1"].split("_");

        loader.atlas(
          spritea[0] + '',
          this.wh.registry.list.rcvarpass.rc_baseurl +
          'sprite/' + spritea[0] + '.png' + svversionurlstring,
          this.wh.registry.list.rcvarpass.rc_baseurl +
          'sprite/' + spritea[0] + '.json' + svversionurlstring
        ); // + Math.random());
      }

      if (v['action'] == "imgbg" || v['action'] == "imgfg") {

        loader.image(
          v["params"]["p1"] + '',
          this.wh.registry.list.rcvarpass.rc_baseurl + v["params"]["p1"] + svversionurlstring
        ); // + Math.random());
      }

    }
    loader.on(
      'progress',
      function (value) {
        //this.plog.log("loaderevent", value, loader.totalComplete, loader.totalToLoad);
        this.wh.registry.list.rcloading.loading2text('Loading ' + loader.totalComplete + '/' + loader.totalToLoad);
        this.wh.registry.list.rcloading.loading2percent(value);
      }, this
    );


    loader.once(Phaser.Loader.Events.COMPLETE, async () => {
      //this.plog.log('Phaser.Loader.Events.COMPLETE', this);
      for (const k in this.spriteloadedhere) {
        this.wh.registry.list.gameitf.genloadedanims(this.wh, this.spriteloadedhere[k]);
      }

      var spriteset = [
        { code: 'idle', framename: 'walk-d', fr: 1, end: 1 },
        { code: 'walk-d', framename: 'walk-d', fr: 16, end: 9 },
        { code: 'walk-u', framename: 'walk-u', fr: 16, end: 9 },
        { code: 'walk-l', framename: 'walk-l', fr: 16, end: 9 },
        { code: 'walk-r', framename: 'walk-r', fr: 16, end: 9 },
        { code: 'thrust-d', framename: 'thrust-d', fr: 8, end: 8 },
        { code: 'thrust-u', framename: 'thrust-u', fr: 8, end: 8 },
        { code: 'thrust-l', framename: 'thrust-l', fr: 8, end: 8 },
        { code: 'thrust-r', framename: 'thrust-r', fr: 8, end: 8 },
        { code: 'spellcast-d', framename: 'spellcast-d', fr: 7, end: 7 },
        { code: 'spellcast-u', framename: 'spellcast-u', fr: 7, end: 7 },
        { code: 'spellcast-l', framename: 'spellcast-l', fr: 7, end: 7 },
        { code: 'spellcast-r', framename: 'spellcast-r', fr: 7, end: 7 },
        { code: 'slash-d', framename: 'slash-d', fr: 6, end: 6 },
        { code: 'slash-u', framename: 'slash-u', fr: 6, end: 6 },
        { code: 'slash-l', framename: 'slash-l', fr: 6, end: 6 },
        { code: 'slash-r', framename: 'slash-r', fr: 6, end: 6 },
        { code: 'shoot-d', framename: 'shoot-d', fr: 13, end: 13 },
        { code: 'shoot-u', framename: 'shoot-u', fr: 13, end: 13 },
        { code: 'shoot-l', framename: 'shoot-l', fr: 13, end: 13 },
        { code: 'shoot-r', framename: 'shoot-r', fr: 13, end: 13 },
        { code: 'atk-d', framename: 'atk-d', fr: 6, end: 6 },
        { code: 'atk-u', framename: 'atk-u', fr: 6, end: 6 },
        { code: 'atk-l', framename: 'atk-l', fr: 6, end: 6 },
        { code: 'atk-r', framename: 'atk-r', fr: 6, end: 6 },
        { code: 'walk-d-idle', framename: 'idle-d', fr: 3, end: 3 },
        { code: 'walk-u-idle', framename: 'idle-u', fr: 3, end: 3 },
        { code: 'walk-l-idle', framename: 'idle-l', fr: 3, end: 3 },
        { code: 'walk-r-idle', framename: 'idle-r', fr: 3, end: 3 },
        { code: 'idle-d', framename: 'idle-d', fr: 3, end: 3 },
        { code: 'idle-u', framename: 'idle-u', fr: 3, end: 3 },
        { code: 'idle-l', framename: 'idle-l', fr: 3, end: 3 },
        { code: 'idle-r', framename: 'idle-r', fr: 3, end: 3 },
        { code: 'dead', framename: 'dead', fr: 6, end: 6 },
        { code: 'climb', framename: 'climb', fr: 6, end: 6 },
        { code: 'sit-u', framename: 'sit-u', fr: 1, end: 1 },
        { code: 'sit-d', framename: 'sit-d', fr: 1, end: 1 },
        { code: 'sit-r', framename: 'sit-r', fr: 1, end: 1 },
        { code: 'sit-l', framename: 'sit-l', fr: 1, end: 1 },
      ];
      for (const k in this.npcloaded) {
        var v = this.npcloaded[k];

        this.plog.log("this.rcvarsservice.gameobj", this.rcvarsservice.gameobj);
        for (var idx in spriteset) {
          var valspriteset = spriteset[idx];
          this.plog.log("loadcharsprite", idx, v);
          /*this.rcvarsservice.gameobj.anims.remove(v + '-' + valspriteset['code']);
          //this.plog.log('zaninpc xx', this.rcvarsservice.gameobj, thisanims, thissprite);
          if (this.rcvarsservice.gameobj.textures.list[v].frames[valspriteset['code'] + '-1'] == undefined) {
            //this.plog.warn("zaninpc xx skip, textures not found", thisanimsa[1] + '-1', this.rcvarsservice.gameobj.textures.game.textures.list[thisanimsa[0]]);
            continue;
          }*/
          this.rcvarsservice.gameobj.anims.create({
            key: v + '-' + valspriteset['code'],
            frames: this.rcvarsservice.gameobj.anims.generateFrameNames(v, {
              prefix: valspriteset['framename'] + '-',
              end: valspriteset['end'],
              zeroPad: 0,
              start: 1,
            }),
            repeat: -1,
            frameRate: valspriteset['fr'],
          });




        }

      }

      await this.play_loaded();
    });

    await this.gif_fordrama(wh);
    await loader.start();
  }
  async play_loaded() {

    //var x = this.char_main.currentx;
    //var y = this.char_main.currenty;\]]\
    this.wh.kbfocus = "drama";

    var pgbaroption = {
      x: 20,
      y: this.height - (20 + 10),
      w: this.width - 40,
      h: 10,
      max: this.dramainfo.length,
      me: 0,
      txt: '',
      barcol1: 0xffc70f,
      barcol2: 0xffc70f,
      bgcol: 0xffffff,
      imgcol: 'green'
    }; //0x6b4602
    this.pgbar = new PGBar(this.wh, pgbaroption, 'img');

    this.cam = this.wh.cameras.cameras[0];
    this.plog.log(this.cam)
    this.plog.log(this.cam._follow)
    this.cam_lastfollow = this.wh.cameras.cameras[0]._follow;
    this.cam_refollow(this.wh);
    //console.log(this.cam)
    this.wh.registry.list.rcloading.close();


    /*for (var i = 0; i <= 70; i++) {
      dramainfo = dramainfo.replace("\r\n", "\n");
    }
    var dramainfoa = dramainfo.split("\n");
    */
    this.wh.registry.list.gameitf.mapfadeout(this.wh, 1000);
    await this.wh.registry.list.rctoast.sleep(800);

    //reset settings
    this.setting_autoturn = "yes";
    this.wh.camman(this.wh);

    var scenei = 0;
    for (var k in this.dramainfo) {
      if (this.isdramaplaying == false) {
        this.gif_nodrama();
        return;
      }
      //await this.gif_fordrama();
      scenei++;
      var v = this.dramainfo[k];
      this.roundwait = this.roundwait_default;
      this.plog.log("dramaloop ", k, v);
      this.pgbar.set1(scenei);
      if (v['params'] == null || v['params'] == "" || v['params'] == undefined) continue;
      switch (v['action']) {
        case "setting":
          //this.action_say(v["params"]);
          if (v['params']['p1'] == "autoturn") {
            this.setting_autoturn = v['params']['p2'];
            //console.log("setting autoturn", v['params']);
            continue;
          }
          break;
        case "say":
          this.action_say(v["params"]);
          break;
        case "pan":
          this.action_pan(v["params"]);
          break;
        case "zoom":
          this.action_zoom(v["params"]);
          break;
        case "face":
          this.action_face(v["params"]);
          break;
        case "cam-reset":
          this.cam_refollow(this.wh);
          break;
        case "cam-follow":
          this.action_camFollow(v["params"]);
          break;
        case "wait":
          await this.wh.registry.list.rctoast.sleep(v["params"]["p1"]);
          break;
        case "walkto":
          await this.action_walkTo(v["params"]);
          break;
        case "walktopos":
          await this.action_walkToPos(v["params"]);
          break;
        case "playsprite":
          await this.action_playsprite(v["params"]);
          break;

        case "ani-play":
          this.action_aniPlay(v["params"]);
          break;
        case "overlayeffect-play":
          this.action_overlayeffectPlay(v["params"]);
          break;
        case "overlayeffect-stop":
          this.action_overlayeffectStop(v["params"]);
          break;
        case "settime":
          this.action_settime(v["params"]);
          break;
        case "sound-start":
          this.action_soundStart(v["params"]);
          break;
        case "sound-stop":
          this.action_soundStop(v["params"]);
          break;
        case "anieffect-play":
          this.action_anieffectPlay(v["params"]);
          break;
        case "filltext":
          this.action_filltext(v["params"]);
          break
        case "fade-toblack":
          await this.action_fadeToBlack(v["params"]);
          break
        case "fade-tolight":
          await this.action_fadeToLight(v["params"]);
          break
        case "showthis":
          await this.action_showthis(v["params"]);
          break
        case "hidethis":
          await this.action_hidethis(v["params"]);
          break
        case "spawnnpc":
          await this.action_spawnnpc(v["params"]);
          break
        case "imgbg":
          await this.action_imgbg(v["params"]);
          break
        case "imgfg":
          await this.action_imgfg(v["params"]);
          break
        case "imghide":
          await this.action_imghide(v["params"]);
          break
        case "credit":
          await this.action_credit(v["params"]);
          break
        case "setpos":
          await this.action_setpos(v["params"]);
          break
        case "effects":
          await this.action_effects(v["params"]);
          break



        default:
          this.plog.error("drama action unknown", v['action'], v['params']);
          break;
      }

      //snapshot s
      if (this.snapshotflag == "yes") {
        this.wh.registry.list.gameitf.savesnapshotsys(this.wh, "drama", v['id']);
        //await this.wh.registry.list.rctoast.sleep(950);

      }
      //snapshot e

      if (this.roundwait == 0) this.roundwait = this.roundwait_default;
      for (var sleepi = 1; sleepi <= Math.ceil(this.roundwait / 100); sleepi++) {
        //console.log("sleeploop",sleepi,this.isdramaplaying as boolean);
        if (this.isdramaplaying as boolean === true) {
        } else {
          break;
        }
        await this.wh.registry.list.rctoast.sleep(100);
      }
      //await this.wh.registry.list.rctoast.sleep(this.roundwait);
      this.stttxt.setText("");
    }
    this.gif_nodrama();
    this.isdramaplaying = false;
    return true;
  }
  async gif_fordrama(wh) {
    this.plog.log("gif_fordrama ", this.isdramaplaying);
    //this.wh.gifscontainer.visible = false;
    //this.wh.gifscontainer.setVisible(false);
    await wh.registry.list.gameitf.hidegui(wh);
  }

  async gif_nodrama() {
    this.plog.log("gif_NOdrama ", this.isdramaplaying);
    //return;
    this.wh.registry.list.gameitf.mapfadeout(this.wh, 1000);
    await this.wh.registry.list.rctoast.sleep(800);
    this.wh.kbfocus = "";
    this.cam.setZoom(1, 1);
    this.cam_refollow(this.wh);
    this.wh.registry.list.timectl.pausetime = false;

    this.wh.registry.list.weather.hslStop();
    this.wh.registry.list.weather.toonifyStop();
    this.wh.registry.list.weather.pixelateStop();
    this.stttxt.setText("");
    this.stttxt.visible = false;
    try {
      this.stttxt.destroy();
    } catch (e) {
      this.plog.log("ERROR", e);
    }
    try {
      this.filltxt.destroy();
    } catch (e) {
      this.plog.log("ERROR", e);
    }
    //stop sounds
    //console.log("stopping sound,list",this.sounds);
    for (const k in this.sounds) {
      //console.log("stopping sound",k);
      try {
        this.wh.sound.stopByKey(this.sounds[k]);
      } catch (e) {
        this.plog.log("ERROR", e);
      }

    }

    //stop cratedimg
    for (const k in this.cratedimg) {
      try {
        this.cratedimg[k].destroy();
      } catch (e) {
        this.plog.log("ERROR", e);
      }

    }

    //stop creatednpc
    for (const k in this.creatednpc) {
      try {
        this.creatednpc[k].destroy();
      } catch (e) {
        this.plog.log("ERROR", e);
      }

    }

    try {
      this.clickpreventer.destroy();
    } catch (e) {
      this.plog.log("ERROR", e);
    }

    try {
      this.credit.destroy();
    } catch (e) {
      this.plog.log("ERROR", e);
    }

    for (const npcpositionsk in this.npcpositions) {
      var npcpositionsv = this.npcpositions[npcpositionsk];
      //this.plog.log("npcpositions", npcpositionsk, npcpositionsv);
      var npcsprite = this.wh.registry.list.phm.findnpccode(this.wh, npcpositionsv["name"]);
      //this.plog.log("npcpositions", npcsprite);
      if (npcsprite != undefined) {
        if (npcsprite.name.indexOf("bushreward") != -1) continue;
        if (npcsprite.name.indexOf("pnnpc") != -1) continue;
        if (npcsprite.name.indexOf("farmplot") != -1) continue;
        npcsprite.currentx = npcpositionsv.currentx;
        npcsprite.currenty = npcpositionsv.currenty;
        npcsprite.setPosition((npcsprite.currentx - 1) * 32, (npcsprite.currenty - 1) * 32);
      }
    }
    //restore ani
    for (const k in this.torestoreaniPlay) {
      var v = this.torestoreaniPlay[k];
      var tgrestore = this.wh.char_main;
      if (k == "char_main") { //focus on char_main
      } else { //focus on an npc
        //this.plog.log("npclist", this.npclist);
        var npcsprite = this.wh.registry.list.phm.findnpccode(this.wh, k);
        if (npcsprite != undefined) {
          tgrestore = npcsprite;
        } else {
          this.plog.log("drama. torestoreaniPlay cant find target sprite");
          continue;;
        }
      }
      try {
        this.plog.log("drama restoreAniPlay", k, v);
        tgrestore.play(v);
      } catch (e) {
        console.log("drama restoreAniPlay error", e);
      }
    }
    //restore ani
    for (const k in this.makevisible) {
      var v = this.makevisible[k];
      var tgrestore = this.wh.char_main;
      if (v == "char_main") { //focus on char_main
      } else { //focus on an npc
        //this.plog.log("npclist", this.npclist);
        var npcsprite = this.wh.registry.list.phm.findnpccode(this.wh, k);
        if (npcsprite != undefined) {
          tgrestore = npcsprite;
        } else {
          this.plog.log("drama. makevisible cant find target sprite");
          continue;;
        }
      }
      tgrestore.visible = true;
      this.plog.log("drama makevisible", k, v, tgrestore);

    }


    this.isdramaplaying = false;

    //this.wh.gifscontainer.visible = true;
    if (this.fromwhere != "npcconver") {
      //npc will restore the gui itself
      this.wh.registry.list.gameitf.showgui(this.wh);
    }
    if (this.fromwhere == "npcconver") {
      if (this.wh.npccontainer != undefined) {
        try {
          this.wh.npccontainer.visible = true;
          this.wh.kbfocus = "npc";
        } catch (e) {
          this.plog.log("ERROR", e);
        }
      }
    }
    try {
      this.pgbar.destroy();
    } catch (e) {
      this.plog.log("ERROR", e);
    }

    try {
      this.skipbtn.destroy();
    } catch (e) {
      this.plog.log("ERROR", e);
    }
  }
  //////////////////////////////////

  cam_nofollow(wh) {
    wh.cameras.main.stopFollow();;
  }
  cam_refollow(wh) {
    this.plog.log("cam_refollow", wh);
    //wh.cameras.main.stopFollow();;
    wh.cameras.main.startFollow(wh.char_main, true, 0.05, 0.05);
  }
  cam_follow(wh, obj) {
    //wh.cameras.main.stopFollow();;
    wh.cameras.main.startFollow(obj, true, 0.05, 0.05);
  }
  //////////////////////////////////
  action_setpos(p) {
    this.plog.log("action_setpos", p);

    this.roundwait = 1;

    var tmppos = p["p2"].split("-");

    if (p["p1"] == "char_main") { //focus on char_main
      //this.camman_refollow(this.wh);

      this.wh.char_main.currentx = Number(tmppos[0]);
      this.wh.char_main.currenty = Number(tmppos[1]);
      this.wh.char_main.setPosition((this.wh.char_main.currentx - 1) * 32, (this.wh.char_main.currenty - 1) * 32);

    } else { //focus on an npc
      //this.plog.log("npclist", this.npclist);
      var npcsprite = this.wh.registry.list.phm.findnpccode(this.wh, p["p1"]);
      if (npcsprite != undefined) {
        //this.camman_follow(his.wh, npcsprite);

        npcsprite.currentx = Number(tmppos[0]);
        npcsprite.currenty = Number(tmppos[1]);
        npcsprite.setPosition((npcsprite.currentx - 1) * 32, (npcsprite.currenty - 1) * 32);
      } else {
        this.plog.log("drama.play action_setpos cant find target sprite");
        return;
      }
    }
  }
  action_say(p) {
    this.plog.log("action_say()", p);
    this.roundwait = Math.floor(Number(p["p3"]));
    this.wh.registry.list.phm.hidemapsayitems();

    if (this.wh.isofftmute != true) {
      if (p['audio'] != undefined && p['audio'] != '') {
        this.plog.log("drama.play trying to play audio ", p['audio'], this.wh.sound);
        var duration = 0;
        try {
          this.sounds.push('' + p['audio']);
          let sfx = this.wh.sound.add('' + p['audio']);
          sfx.play({ volume: 1.0 });
          this.plog.log("drama.play trying to play audio DUR", sfx);
          duration = Math.floor(sfx.totalDuration * 1000);
        } catch (e) {
          this.plog.log("ERROR", e);
        }
        //this.roundwait = duration + Math.floor(Number(p["p3"]));
        /* if (this.roundwait < duration) {
           this.roundwait = duration + 50;
           this.plog.log("drama.play increase waittime to", this.roundwait, " for say audio");
         }*/
        this.roundwait = Math.floor(Number(p["p3"])) + duration;
        this.plog.log("drama.play trying to play audio AFTER", p['audio'], this.wh.sound);
      } else {
        //add wait 2 seconds if no audio
        this.roundwait = Math.floor(this.roundwait) + 2000;
      }
    } else {
      //add wait 2 seconds if sound muted
      this.roundwait = Math.floor(this.roundwait) + 2000;
    }
    var usep2 = this.wh.trs("t:dramaeditor_sub:id:xparams:" + p["did"] + "===" + p["p2"]);

    if (p["p1"] == "char_main") { //focus on char_main
      this.wh.registry.list.phm.charsaybubble(this.wh.char_main, usep2, p["p4"], this.roundwait);
      //this.camman_refollow(this.wh);
    } else if (p["p1"] == "narrator") { //focus on char_main
      //this.wh.registry.list.phm.charsaybubble(this.wh.char_main, p["p2"], p["p4"]);
      //this.camman_refollow(this.wh);
      if (usep2.toString().length > 100) {
        this.stttxt.setStyle(this.wh.fs["dramastttxt_sm"]);
      } else {
        this.stttxt.setStyle(this.wh.fs["dramastttxt"]);
      }
      this.stttxt.setText("" + usep2.toString().trim());
      this.stttxt.setOrigin(0.5, 0.5);
      this.stttxt.setPosition(this.width / 2, this.height - this.stttxt.displayHeight - 50);
      this.stttxt.visible = true;
    } else { //focus on an npc
      //this.plog.log("npclist", this.npclist);
      var npcsprite = this.wh.registry.list.phm.findnpccode(this.wh, p["p1"]);

      if (npcsprite != undefined) {
        //this.camman_follow(his.wh, npcsprite);
        this.wh.registry.list.phm.charsaybubble(npcsprite, usep2, p["p4"], this.roundwait);
        this.rcvarsservice.gameobj.scene.scenes[0].registry.list.phm.fow_clear(npcsprite.currentx, npcsprite.currenty, 'revealbrush');


        //char auto face talking npc

        var charfacesprite;
        var npcinfo = this.rcvarsservice.gameobj.registry.list.rcvarpass.npc[p["p1"]];

        //this.plog.log('npcclick npcinfo', npcinfo, wh);
        var diffx = npcsprite.currentx - this.wh.char_main.currentx;
        var diffy = npcsprite.currenty - this.wh.char_main.currenty;
        if (Math.abs(diffx) > Math.abs(diffy)) {
          if (diffx < 0) {
            charfacesprite = "-idle-l";
          }
          if (diffx > 0) {
            charfacesprite = "-idle-r";
          }
        } else {
          if (diffy < 0) {
            charfacesprite = "-idle-u";
          }
          if (diffy > 0) {
            charfacesprite = "-idle-d";
          }
        }
        if (charfacesprite != undefined) {
          if (this.setting_autoturn == "yes") {
            var currentkey = this.wh.char_main.anims.currentFrame.textureKey + "";
            //this.plog.log("facing char to :", currentkey + "" + charfacesprite);
            this.wh.char_main.play({ key: currentkey + "" + charfacesprite, repeatDelay: 2000 });
          }
        }
      } else {
        this.plog.log("drama.play action_say cant find target sprite");
        var tmppos = p["p1"].split("-");
        if (tmppos.length == 2) {
          var tg = {
            x: Number(tmppos[0]) * 32, y: Number(tmppos[1]) * 32,
            currentx: tmppos[0], currenty: tmppos[1],
            scene: this.wh, displayHeight: 32, displayWidth: 32, _displayOriginY: 32,
            parentContainer: this.wh.mapcontainer
          }
          this.wh.registry.list.phm.charsaybubble(tg, usep2, p["p4"]);

        } else {
          this.plog.log("drama.play action_say cant find target sprite");
          return;
        }
      }
    }
  }
  action_pan(p) {
    this.plog.log("action_pan()", p, this.wh);
    this.plog.log("action_pan()", p, this.cam);
    this.cam_nofollow(this.wh);
    this.roundwait = Math.floor(Number(p["p3"])) + 50;
    var tg = this.wh.char_main;
    if (p["p1"] != "char_main") {
      var npcsprite = this.wh.registry.list.phm.findnpccode(this.wh, p["p1"]);
      if (npcsprite != undefined) {
        //this.camman_follow(his.wh, npcsprite);
        tg = npcsprite;
      } else {
        this.plog.log("drama.play action_pan cant find target sprite");
        var tmppos = p["p1"].split("-");
        if (tmppos.length == 2) {
          tg = { x: Number(tmppos[0]) * 32, y: Number(tmppos[1]) * 32, currentx: tmppos[0], currenty: tmppos[1] }
        } else {
          this.plog.log("drama.play action_pan cant find target sprite");
          return;
        }
      }
    }


    var twstartposx = tg.x - (this.width / 2);
    var twstartposy = tg.y - (this.height / 2);
    var twendposx = tg.x - (this.width / 2);
    var twendposy = tg.y - (this.height / 2);
    this.plog.log("drama pan tween tg", tg); //return;

    if (p["p2"] == "l") {
      twstartposx = twstartposx + (this.width * 0.5)
    }
    if (p["p2"] == "r") {
      twstartposx = twstartposx - (this.width * 0.5)
    }
    if (p["p2"] == "u") {
      twstartposy = twstartposy - (this.width * 0.5)
    }
    if (p["p2"] == "d") {
      twstartposy = twstartposy + (this.width * 0.5)
    }

    this.plog.log("drama pan tween data", twstartposx, twstartposy, twendposx, twendposy);

    this.thetw = this.wh.tweens.add({
      targets: this.cam,
      //angle: -20, // '+=100'
      scrollX: { from: twstartposx, to: twendposx },
      scrollY: { from: twstartposy, to: twendposy },
      ease: "Linear", // 'Cubic', 'Elastic', 'Bounce', 'Back'
      duration: Math.floor(Number(p["p3"])),
      repeat: 0,
      yoyo: false,
      onUpdate: function () {
        this.wh.updatenightlightposition();

      }.bind(this)
    });
    //this.cam.pan(twendposx, twendposy, 2000);

  }
  action_anieffectPlay(p) {

    this.plog.log("action_anieffectPlay()", p, this.wh);
    //this.cam_nofollow(this.wh);
    this.roundwait = Math.floor(Number(p["p3"])) + 50;
    var tg = this.wh.char_main;
    if (p["p1"] != "char_main") {
      var npcsprite = this.wh.registry.list.phm.findnpccode(this.wh, p["p1"]);
      if (npcsprite != undefined) {
        //this.camman_follow(his.wh, npcsprite);
        tg = npcsprite;
      } else {
        this.plog.log("drama.play action_anieffectPlay cant find target sprite");
        return;
      }
    }
    if (p["p2"] == "wiggle") {
      this.wh.registry.list.gameitf.wiggle(this.wh, tg, 2);
    }
    if (p["p2"] == "doidoi") {
      this.wh.registry.list.gameitf.doidoi(this.wh, tg);
    }
  }
  action_zoom(p) {
    this.plog.log("action_zoom()", p, this.cam);
    //this.cam_nofollow(this.wh);

    this.roundwait = Math.floor(Number(p["p3"])) + 50;
    var tg = this.wh.char_main;
    if (p["p1"] != "char_main") {
      var npcsprite = this.wh.registry.list.phm.findnpccode(this.wh, p["p1"]);
      if (npcsprite != undefined) {
        //this.camman_follow(his.wh, npcsprite);
        tg = npcsprite;
        //if (this.cam._follow != tg)
        //this.cam_follow(this.wh, tg);
      } else {

        var tmppos = p["p1"].split("-");
        if (tmppos.length == 2) {
          //this.cam_nofollow(this.wh);
          tg = { x: Number(tmppos[0]) * 32, y: Number(tmppos[1]) * 32, currentx: tmppos[0], currenty: tmppos[1] }
        } else {
          this.plog.log("drama.play action_pan cant find target sprite");
          return;
        }
      }
    } else {
      //if (this.cam._follow != this.wh.char_mai)
      //this.cam_follow(this.wh, this.wh.char_main);
      //return;
    }

    //
    var twstartposx = tg.x - (this.width * 0.5);// - (this.width / 2);
    var twstartposy = tg.y - (this.height * 0.5);/// - (this.height / 2);
    this.plog.log("drama zoom tween tg", tg); //return;

    this.plog.log("drama zoom tween data", twstartposx, twstartposy);
    //this.cam.scrollX = twstartposx;
    //this.cam.scrollY = twstartposy;
    this.cam.zoomTo(Number(p["p2"]), Number(p["p3"]), 'Linear', true);


    //this.cam.pan(twendposx, twendposy, 2000);

  }
  action_face(p) {
    this.plog.log("action_face()", p);
    this.roundwait = Math.floor(Number(p["p3"]));
    var tg = this.wh.char_main;
    if (p["p1"] == "char_main") { //focus on char_main
    } else { //focus on an npc
      //this.plog.log("npclist", this.npclist);
      var npcsprite = this.wh.registry.list.phm.findnpccode(this.wh, p["p1"]);
      if (npcsprite != undefined) {
        tg = npcsprite;
      } else {
        this.plog.log("drama.play action_say cant find target sprite");
        return;
      }
    }
    this.plog.log("action face tg", tg);
    if (tg.anims.currentFrame == undefined) {
      this.plog.log("action face  no currentFrame", tg);

    } else {
      var currentkey = tg.anims.currentFrame.textureKey + "";
      this.plog.log("action_face currentkey", currentkey + "-idle-" + p["p2"], tg);

      tg.play({ key: currentkey + "-idle-" + p["p2"], repeatDelay: 2000 });
    }
  }
  action_aniPlay(p) {
    this.plog.log("action_aniPlay()", p);
    this.roundwait = Math.floor(Number(p["p4"]));
    var tg = this.wh.char_main;
    if (p["p1"] == "char_main") { //focus on char_main
    } else { //focus on an npc
      //this.plog.log("npclist", this.npclist);
      var npcsprite = this.wh.registry.list.phm.findnpccode(this.wh, p["p1"]);
      if (npcsprite != undefined) {
        tg = npcsprite;
      } else {
        this.plog.error("drama.play action_aniPlay cant find target sprite");
        return;
      }
    }
    //this.plog.error("action action_aniPlay tg", tg);
    var currentkey = tg.anims.currentFrame.textureKey + "";
    var currnetframe = tg.anims.currentFrame.textureFrame + "";
    var currnetframea = currnetframe.split("-");
    //this.plog.error("currentframea", currnetframea);
    //this.plog.error("currentkey", currentkey);
    if (this.torestoreaniPlay[p["p1"]] == undefined)
      this.torestoreaniPlay[p["p1"]] = tg.anims.currentAnim.key + "";
    //var aniuse = currentkey + "-" + p["p2"] + "-" + currnetframea[1];
    var aniuse = currentkey + "-" + p["p2"];
    //if (p["p2"] == "dead") aniuse = currentkey + "-" + p["p2"];
    this.plog.log("action_aniPlay aniuse", aniuse);
    try {
      tg.play({ key: aniuse, repeatDelay: 100, repeat: p["p3"] });
    } catch (e) {
      console.log(e);
    }
  }
  action_camFollow(p) {
    this.plog.log("action_camFollow()", p);
    //this.roundwait = Math.floor(Number(p["p3"]));
    var tg = this.wh.char_main;
    // this.roundwait = 1;
    this.roundwait = Math.floor(Number(p["p2"])) + 50;
    if (p["p1"] == "char_main") { //focus on char_main

      //this.cam_follow(this.wh, tg);
    } else { //focus on an npc
      //this.plog.log("npclist", this.npclist);
      var npcsprite = this.wh.registry.list.phm.findnpccode(this.wh, p["p1"]);
      if (npcsprite != undefined) {
        tg = npcsprite;
      } else {
        this.plog.log("drama.play action_say cant find target sprite");
        return;
      }
    }

    var twstartposx = tg.x - (this.width * 0.5);// - (this.width / 2);
    var twstartposy = tg.y - (this.height * 0.5);/// - (this.height / 2);
    this.plog.log("drama zoom tween tg", tg); //return;
    this.cam_nofollow(this.wh);
    this.plog.log("drama zoom tween data", twstartposx, twstartposy);
    //this.cam.scrollX = twstartposx;
    //this.cam.scrollY = twstartposy;

    this.wh.tweens.add({
      targets: this.cam,
      scrollX: twstartposx,
      scrollY: twstartposy,
      ease: 'Linear',//Power1
      duration: Math.floor(Number(p["p2"])),
      repeat: 0,
      onComplete: function () {
        this.cam_follow(this.wh, tg);
        //console.log("complete")
      }.bind(this)
    });
    this.plog.log("action action_camFollow tg", tg);
    //this.cam_follow(this.wh, tg);
  }


  async action_walkTo(p) {
    this.plog.log("action_walkTo()", p);
    this.roundwait = 1;

    //this.roundwait = Math.floor(Number(p["p3"]));
    var tg = this.wh.char_main;
    if (p["p1"] == "char_main") { //focus on char_main
    } else { //focus on an npc
      //this.plog.log("npclist", this.npclist);
      var npcsprite = this.wh.registry.list.phm.findnpccode(this.wh, p["p1"]);
      if (npcsprite != undefined) {
        tg = npcsprite;
      } else {
        this.plog.log("drama.play action_walkTo cant find target sprite");
        return;
      }
    }
    var walktg = this.wh.char_main;
    var includeEndNode = false;
    if (p["p2"] == "char_main") { //focus on char_main
    } else { //focus on an npc
      //this.plog.log("npclist", this.npclist);
      var npcsprite = this.wh.registry.list.phm.findnpccode(this.wh, p["p2"]);
      if (npcsprite != undefined) {
        walktg = npcsprite;
      } else {
        var tmppos = p["p2"].split("-");
        if (tmppos.length == 2) {
          includeEndNode = true;
          walktg = {
            x: Number(tmppos[0]) * 32, y: Number(tmppos[1]) * 32,
            currentx: tmppos[0], currenty: tmppos[1],
            scene: this.wh, displayHeight: 32, displayWidth: 32, _displayOriginY: 32,
            parentContainer: this.wh.mapcontainer
          }

        } else {
          this.plog.log("drama.play action_walkTo cant find target sprite");
          return;
        }
      }
    }
    this.plog.log("action walkto tg", tg, walktg);
    const mobmetrix = JSON.parse(
      JSON.stringify(this.rcvarsservice.activemapmetrix)
    );
    if (mobmetrix[walktg.currenty] == undefined) mobmetrix[walktg.currenty] = [];
    mobmetrix[walktg.currenty][walktg.currentx] = 0;
    if (mobmetrix[tg.currenty] == undefined) mobmetrix[tg.currenty] = [];
    mobmetrix[tg.currenty][tg.currentx] = 0;
    try {
      var tmpfloorastar = new AStarFinder({
        grid: {
          matrix: mobmetrix,
        },
        includeStartNode: false,
        includeEndNode: includeEndNode,
        diagonalAllowed: true,
      });
    } catch (e) {
      this.plog.log("ERROR: Astar", e);
    }
    //this.plog.log('walkto tmpfloorastar', tmpfloorastar);

    let startPos = {
      x: tg.currentx,
      y: tg.currenty,
    };

    let goalPos = { x: Number(walktg.currentx), y: Number(walktg.currenty) };
    try {
      var myPathwayx = tmpfloorastar.findPath(startPos, goalPos);
    } catch (e) {
      this.plog.warn("ERROR!", e);
      return;
    }
    this.plog.log('walkto myPathwayx', myPathwayx);
    for (var i = 1; i < p["p3"]; i++) {
      try {
        myPathwayx.pop();
      } catch (e) {
        this.plog.log(e);
      }
    }
    if (myPathwayx.length > 0) {
      while (myPathwayx.length > 0) {
        var nextstop = myPathwayx.shift();;
        //this.plog.log("nextstop", nextstop);

        //await this.wh.registry.list.rctoast.sleep(100);
        this.rcvarsservice.gameobj.scene.scenes[0].registry.list.phm.fow_clear(tg.currentx, tg.currenty, 'revealbrush');
        var dir;
        if (nextstop[0] > tg.currentx && nextstop[1] > tg.currenty) {
          dir = "down right";
        }
        if (nextstop[0] > tg.currentx && nextstop[1] < tg.currenty) {
          dir = "up right";
        }
        if (nextstop[0] < tg.currentx && nextstop[1] < tg.currenty) {
          dir = "up left";
        }
        if (nextstop[0] < tg.currentx && nextstop[1] > tg.currenty) {
          dir = "down left";
        }
        if (nextstop[0] == tg.currentx && nextstop[1] > tg.currenty) {
          dir = "down";
        }
        if (nextstop[0] == tg.currentx && nextstop[1] < tg.currenty) {
          dir = "up";
        }
        if (nextstop[0] > tg.currentx && nextstop[1] == tg.currenty) {
          dir = "right";
        }
        if (nextstop[0] < tg.currentx && nextstop[1] == tg.currenty) {
          dir = "left";
        }
        await this.spritewalk(tg, dir, this.wh);
      }

      var currentkey = tg.anims.currentFrame.textureKey + "";
      var currnetframe = tg.anims.currentFrame.textureFrame + "";
      var currnetframea = currnetframe.split("-");

      tg.play({ key: currentkey + "-idle-" + currnetframea[1], repeatDelay: 2000 });
    }
  }

  async action_walkToPos(p) {
    this.plog.log("action_walkToPos()", p);
    //this.roundwait = Math.floor(Number(p["p3"]));
    this.roundwait = 1;

    var tg = this.wh.char_main;
    if (p["p1"] == "char_main") { //focus on char_main
    } else { //focus on an npc
      //this.plog.log("npclist", this.npclist);
      var npcsprite = this.wh.registry.list.phm.findnpccode(this.wh, p["p1"]);
      if (npcsprite != undefined) {
        tg = npcsprite;
      } else {
        this.plog.log("drama.play action_say cant find target sprite");
        return;
      }
    }

    var tgx = Number(p["p2"]);
    var tgy = Number(p["p3"]);
    const mobmetrix = JSON.parse(
      JSON.stringify(this.rcvarsservice.activemapmetrix)
    );
    if (mobmetrix[tgy] == undefined) mobmetrix[tgy] = [];
    mobmetrix[tgy][tgx] = 0;
    if (mobmetrix[tg.currenty] == undefined) mobmetrix[tg.currenty] = [];
    mobmetrix[tg.currenty][tg.currentx] = 0;
    try {
      var tmpfloorastar = new AStarFinder({
        grid: {
          matrix: mobmetrix,
        },
        includeStartNode: false,
        includeEndNode: false,
        diagonalAllowed: true,
      });
    } catch (e) {
      this.plog.log("ERROR: Astar", e);
    }
    //this.plog.log('walkto tmpfloorastar', tmpfloorastar);

    let startPos = {
      x: tg.currentx,
      y: tg.currenty,
    };

    let goalPos = { x: Number(tgx), y: Number(tgy) };
    try {
      var myPathwayx = tmpfloorastar.findPath(startPos, goalPos);
    } catch (e) {
      this.plog.warn("ERROR!", e);
      return;
    }
    this.plog.log('walktopos myPathwayx', myPathwayx);
    if (myPathwayx.length > 0) {
      while (myPathwayx.length > 0) {
        var nextstop = myPathwayx.shift();;
        this.plog.log("nextstop", nextstop);

        //await this.wh.registry.list.rctoast.sleep(100);

        var dir;
        if (nextstop[0] > tg.currentx && nextstop[1] > tg.currenty) {
          dir = "down right";
        }
        if (nextstop[0] > tg.currentx && nextstop[1] < tg.currenty) {
          dir = "up right";
        }
        if (nextstop[0] < tg.currentx && nextstop[1] < tg.currenty) {
          dir = "up left";
        }
        if (nextstop[0] < tg.currentx && nextstop[1] > tg.currenty) {
          dir = "down left";
        }
        if (nextstop[0] == tg.currentx && nextstop[1] > tg.currenty) {
          dir = "down";
        }
        if (nextstop[0] == tg.currentx && nextstop[1] < tg.currenty) {
          dir = "up";
        }
        if (nextstop[0] > tg.currentx && nextstop[1] == tg.currenty) {
          dir = "right";
        }
        if (nextstop[0] < tg.currentx && nextstop[1] == tg.currenty) {
          dir = "left";
        }
        await this.spritewalk(tg, dir, this.wh);
      }

      var currentkey = tg.anims.currentFrame.textureKey + "";
      var currnetframe = tg.anims.currentFrame.textureFrame + "";
      var currnetframea = currnetframe.split("-");

      tg.play({ key: currentkey + "-idle-" + currnetframea[1], repeatDelay: 2000 });
    }
  }

  async action_playsprite(p) {
    this.plog.log("action_playsprite()", p);
    this.roundwait = Math.floor(Number(p["p3"]));
    var tg = this.wh.char_main;
    if (p["p1"] == "char_main") { //focus on char_main
    } else { //focus on an npc
      //this.plog.log("npclist", this.npclist);
      var npcsprite = this.wh.registry.list.phm.findnpccode(this.wh, p["p1"]);
      if (npcsprite != undefined) {
        tg = npcsprite;
      } else {

        var tmppos = p["p1"].split("-");
        if (tmppos.length == 2) {
          tg = {
            x: Number(tmppos[0]) * 32, y: Number(tmppos[1]) * 32,
            currentx: tmppos[0], currenty: tmppos[1],
            scene: this.wh, displayHeight: 32, displayWidth: 32, _displayOriginY: 32,
          }
        } else {
          this.plog.log("drama.play action_playsprite cant find target sprite");
          return;
        }
      }
    }
    //this.wh.registry.list.showreward.effwithtxt(tg, p["p2"], "");
    this.wh.registry.list.phm.mapitemeffect(tg, p["p2"], 1)//(wh, efftectid, repeat = 0)

    //await this.wh.registry.list.rctoast.sleep(800);


  }
  action_soundStart(p) {
    this.plog.log("drama soundstart", p);
    this.roundwait = 1;
    if (this.wh.isofftmute == true) {
      this.plog.log("drama soundstart skip, muted", p);
      return;
    }
    try {
      var sfx = this.wh.sound.add('' + p['p1']);
      sfx.play({ volume: Number(p['p2']) });
      this.sounds.push(p["p1"] + "");
      this.plog.log("drama.action_soundStart trying to play audio DUR", sfx);
    } catch (e) {
      this.plog.log("ERROR", e);
    }
  }

  action_soundStop(p) {
    this.plog.log("drama action_soundStop", p);
    this.wh.sound.stopByKey(p["p1"]);
    this.roundwait = 1;


  }
  async action_settime(p) {
    this.roundwait = 1;

    this.wh.registry.list.timectl.timefunc(this.wh, "" + p["p1"]);
    this.wh.registry.list.timectl.timechange("" + p["p1"]);
    this.wh.registry.list.timectl.timename = "" + p["p1"];
  }
  async action_overlayeffectPlay(p) {
    this.roundwait = 1;

    if (p["p1"] == "fog") {
      this.wh.registry.list.weather.fogStart(this.wh);
    }
    if (p["p1"] == "rain") {
      this.wh.registry.list.weather.rainStart(this.wh);
    }
    if (p["p1"] == "heavyrain") {
      this.wh.registry.list.weather.heavyRainStart(this.wh);
    }
    if (p["p1"] == "ray1") {
      this.wh.registry.list.weather.startRay1(this.wh);
    }
    if (p["p1"] == "snow") {
      this.wh.registry.list.weather.snowStart(this.wh);
    }
  }

  async action_overlayeffectStop(p) {
    this.roundwait = 1;

    this.wh.registry.list.weather.reset(this.wh);
  }

  async action_filltext(p) {
    this.plog.log("drama play action_filltext", p);
    this.roundwait = 1;
    this.filltxt.setText("" + p["p1"]);
    this.filltxt.setOrigin(0.5, 0.5);
    this.filltxt.setPosition(this.width / 2, this.height / 2);
    this.filltxt.visible = true;
    this.fillbg.visible = true;
    this.wh.registry.list.weather.reset(this.wh);
    await this.wh.registry.list.rctoast.sleep(p["p2"]);
    this.filltxt.visible = false;
    this.fillbg.visible = false;
  }
  async action_credit(p) {
    this.plog.log("drama play action_credit", p);
    this.roundwait = 1;
    this.credit.setText("" + p["p1"]);
    this.credit.setOrigin(0, 0.5);
    this.credit.setPosition((this.width / 2) + (this.skipbtn.displayWidth / 2) + 10, this.height - 40);
    this.credit.visible = true;
  }
  async action_fadeToBlack(p) {
    this.plog.log("drama play action_fadeToBlack", p);
    this.fillbg.setAlpha(0);
    this.fillbg.visible = true;
    this.filltxt.visible = false;
    this.wh.tweens.add({
      targets: this.fillbg,
      alpha: 1,
      ease: 'Linear',//Power1
      duration: Number(p["p1"]),
      repeat: 0,
    });
    this.roundwait = 1;
    await this.wh.registry.list.rctoast.sleep(Number(p["p1"]) + 100);

  }

  async action_fadeToLight(p) {
    this.plog.log("drama play action_fadeToLight", p);
    this.roundwait = 1;
    this.fillbg.setAlpha(1);
    this.filltxt.setAlpha(0);
    this.wh.tweens.add({
      targets: this.fillbg,
      alpha: 0,
      ease: 'Linear',//Power1
      duration: Number(p["p1"]),
      repeat: 0,
    });
    await this.wh.registry.list.rctoast.sleep(Number(p["p1"]) + 100);
    this.fillbg.visible = false;
    this.filltxt.visible = false;

  }

  async action_showthis(p) {
    this.plog.log("action_showthis", p);
    this.roundwait = 1;
    var tg = this.wh.char_main;
    if (p["p1"] == "char_main") { //focus on char_main
    } else { //focus on an npc
      //this.plog.log("npclist", this.npclist);
      var npcsprite = this.wh.registry.list.phm.findnpccode(this.wh, p["p1"]);
      if (npcsprite != undefined) {
        tg = npcsprite;
      } else {
        this.plog.log("drama.play action_showthis cant find target sprite");
        return;
      }

    }
    tg.visible = true;
  }
  async action_hidethis(p) {
    this.plog.log("action_hidethis", p);
    this.roundwait = 1;
    var tg = this.wh.char_main;
    if (p["p1"] == "char_main") { //focus on char_main
    } else { //focus on an npc
      //this.plog.log("npclist", this.npclist);
      var npcsprite = this.wh.registry.list.phm.findnpccode(this.wh, p["p1"]);
      if (npcsprite != undefined) {
        tg = npcsprite;
      } else {
        this.plog.log("drama.play action_hidethis cant find target sprite");
        return;
      }
    }
    this.makevisible.push(p["p1"]);
    tg.visible = false;
    this.plog.log("action_hidethis", p, tg, tg.visible);

  }

  async action_imgbg(p) {
    this.plog.log("action_imgbg", p);
    this.roundwait = 1;
    var tmppos = (p["p2"] + "").split("-");
    var tmp = this.wh.add.image(0, 0, p["p1"]);

    tmp.zsortfloorlevel = true;
    //this.wh.extradestroylist.push(tmp);
    this.wh.mapcontainer.add(tmp);
    tmp.setOrigin(0.5, 0.5);
    tmp.setPosition((Number(tmppos[0]) - 1) * 32, (Number(tmppos[1]) - 1) * 32);
    this.rcvarsservice.gameobj.scene.scenes[0].zsortlist.push(tmp);

    if (p["p3"] != "") {
      try {
        tmp.setScale(p["p3"]);
      } catch (e) {
        this.plog.log(e);
      }
    }
    this.cratedimg.push(tmp);


  }
  async action_imgfg(p) {
    this.plog.log("action_imgfg", p);
    this.roundwait = 1;
    var tmppos = (p["p2"] + "").split("-");
    var tmp = this.wh.add.image(0, 0, p["p1"]);
    tmp.setOrigin(0, 0);
    tmp.setPosition((Number(tmppos[0]) - 0) * 32, (Number(tmppos[1]) - 0) * 32);
    this.rcvarsservice.gameobj.scene.scenes[0].zsortlist.push(tmp);
    if (p["p3"] != "") {
      try {
        tmp.setScale(p["p3"]);
      } catch (e) {
        this.plog.log(e);
      }
    }
    this.cratedimg.push(tmp);

    tmp.zsorttoplevel = true;
    //this.wh.extradestroylist.push(tmp);
    this.wh.mapcontainer.add(tmp);
  }


  async action_imghide(p) {
    this.plog.log("action_imghide");
    this.roundwait = 1;

    for (const k in this.cratedimg) {
      if (this.cratedimg[k].texture.key == p["p1"]) {
        this.plog.log("action_imghide", this.cratedimg[k]);
        try {
          this.cratedimg[k].visible = false;
        } catch (e) {
          this.plog.log("ERROR", e);
        }
      }
    }

  }
  async action_spawnnpc(p) {
    this.plog.log("action_spawnnpc", p);
    this.roundwait = 1;
    var npcposa = (p["p2"] + "").split('-');
    let tmp = this.rcvarsservice.gameobj.scene.scenes[0].add
      .sprite(Number(npcposa[0]), Number(npcposa[0]), p["p1"])
      .play({ key: p["p1"] + "-walk-d", frameRate: 1, repeatDelay: 0 })
      .play({ key: p["p1"] + "-idle", frameRate: 16, repeatDelay: 0 }); ///cellvalue
    //npc mapsprite size
    tmp.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);
    tmp.displayWidth = 2 * 32;
    tmp.scaleY = tmp.scaleX;
    tmp.name = 'npc-' + p['p1'] + '-' + npcposa[0] + '-' + npcposa[1] + '-' + this.rcvarsservice.activemap['map']['code'];
    this.plog.log("spawnnpc name", tmp.name);
    tmp.currentx = Number(npcposa[0]); tmp.currenty = Number(npcposa[1]);
    tmp.setPosition((tmp.currentx - 1) * 32, (tmp.currenty - 1) * 32);
    this.rcvarsservice.gameobj.scene.scenes[0].zsortlist.push(tmp);
    this.rcvarsservice.gameobj.scene.scenes[0].npclist[tmp.name] = tmp;
    this.rcvarsservice.gameobj.scene.scenes[0].extradestroylist.push(tmp);
    this.wh.mapcontainer.add(tmp);
    this.wh.mapcontainer.bringToTop(tmp);
    tmp.visible = true;
    this.wh.registry.list.phm.initmap_preloaddone_sortlayer_mapdata();
    this.wh.registry.list.phm.initmap_preloaddone_sortlayer();//.bind(this);
    // tmp.zsortfloorlevel = true;
    this.creatednpc.push(tmp);
  }
  async action_effects(p) {

    this.plog.log("action_effects", p);
    this.roundwait = 1;
    switch (p["p1"]) {
      case "pixelateStart":
        this.wh.registry.list.weather.pixelateStart(this.wh, p["p2"]);
        break;
      case "toonifyStart":
        this.wh.registry.list.weather.toonifyStart(this.wh, p["p2"]);
        break;
      case "hslStart":
        this.wh.registry.list.weather.hslStart(this.wh, p["p2"], p["p3"], p["p4"]);
        break;

      default:
        break;
    }
  }
  ///////////////////////////////////////////////////////////
  async spritewalk(tg, dir, wh) {
    //this.plog.log("maincharwalk", dir, wh);
    //this.plog.log("maincharwalk this", this);

    var width = this.width;
    var height = this.height;

    //this.plog.log("mapbound start", wh.mapbgimg, width, height);
    var newcx = 0;
    var newcy = 0;
    var dirx = 0;
    var diry = 0;

    //wh.char_main.x = 100;        wh.char_main.y = 100;
    newcx = tg.currentx;
    newcy = tg.currenty;
    var walkspeednormal = 140;
    var walkspeedslow = 170;

    walkspeednormal = 130;
    walkspeedslow = 150;

    var walkspeed = walkspeednormal;
    var tmpposchk;
    var tmpposchka1;
    var tmpposchka2;
    var spritename = tg.anims.currentFrame.textureKey + "";//this.registry.list.rcvarpass.activechar["sprite"];
    if (dir == "up") {
      newcy -= 1;
      diry = 32;
      tg.play({ key: spritename + "-walk-u", repeat: -1 }, true);
    }
    if (dir == "up right") {
      newcy -= 1;
      newcx += 1;
      diry = 32;
      dirx = -32;
      tg.play({ key: spritename + "-walk-r", repeat: -1 }, true);
      walkspeed = walkspeedslow;
    }
    if (dir == "right") {
      newcx += 1;
      dirx = -32;
      tg.play({ key: spritename + "-walk-r", repeat: -1 }, true);
    }
    if (dir == "down right") {
      newcy += 1;
      newcx += 1;
      diry = -32;
      dirx = -32;
      tg.play({ key: spritename + "-walk-r", repeat: -1 }, true);
      walkspeed = walkspeedslow;
    }
    if (dir == "down") {
      newcy += 1;
      diry = -32;
      tg.play({ key: spritename + "-walk-d", repeat: -1 }, true);
    }
    if (dir == "down left") {
      newcy += 1;
      newcx -= 1;
      diry = -32;
      dirx = 32;
      tg.play({ key: spritename + "-walk-l", repeat: -1 }, true);
      walkspeed = walkspeedslow;
    }
    if (dir == "left") {
      newcx -= 1;
      dirx = 32;
      tg.play({ key: spritename + "-walk-l", repeat: -1 }, true);
    }
    if (dir == "up left") {
      newcy -= 1;
      newcx -= 1;
      diry = 32;
      dirx = 32;
      tg.play({ key: spritename + "-walk-l", repeat: -1 }, true);
      walkspeed = walkspeedslow;
    }
    //this.plog.log("walkspeed",walkspeed);

    tg.currentx = newcx;
    tg.currenty = newcy;




    //wh.mapbgimg.setPosition(wh.defaultmaplayerx+32, wh.defaultmaplayery+32);
    ///wh.mapcontainer.setPosition(0, 0);
    //peacepos
    // this.plog.log("wh.mapbgimg", wh.mapbgimg, newcx, newcy);
    var tweenchar = wh.tweens.add({
      targets: tg,
      x: wh.mapbgimg.x + (newcx * 32) + wh.defaultmaplayerx + 0,
      y: wh.mapbgimg.y + (newcy * 32) + wh.defaultmaplayery + 0,
      ease: 'Linear',//Power1
      duration: walkspeed,
      repeat: 0,
      onComplete: function (wh) {

      }
    });

    await this.wh.registry.list.rctoast.sleep(walkspeed);

  }
}