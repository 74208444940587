import InputText from './InputText.js';
const GetAdvancedValue = Phaser.Utils.Objects.GetAdvancedValue;
const BuildGameObject = Phaser.GameObjects.BuildGameObject;
export default function (config, addToScene) {
  if (config === undefined) {
    config = {};
  }
  if (addToScene !== undefined) {
    config.add = addToScene;
  }
  var width = GetAdvancedValue(config, 'width', undefined);
  var height = GetAdvancedValue(config, 'height', undefined);
  var gameObject = new InputText(this.scene, 0, 0, width, height, config);
  BuildGameObject(this.scene, gameObject, config);
  return gameObject;
}
;