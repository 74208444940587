export default {
  popUp: function (gameObject, duration) {
    gameObject.popUp(duration);
  },
  scaleDown: function (gameObject, duration) {
    gameObject.scaleDown(duration);
  },
  fadeIn: function (gameObject, duration) {
    gameObject.fadeIn(duration);
  },
  fadeOut: function (gameObject, duration) {
    gameObject.fadeOut(duration);
  }
};