import { Injectable } from '@angular/core';
import { PGBar } from './pgbar';
import { plog } from './plog.service';

@Injectable({
  providedIn: 'root',
})
export class FishingService {
  closing = false;
  floatertimerfunctime = 2000;
  fishingrules = [];
  bg;
  wh;
  closebtn;
  startimer;
  circ1;
  circ2;
  powereffect;
  fishbar;
  rodpower = 1;
  hookpower = 1;
  floater;
  floatertimer;
  astring;
  astringstep = 0;
  waittime = 10;
  powerpgbar;
  powertimer;
  currentpower = 0;
  fishingrule = '';
  currentstar = 0;
  fishingtoken;
  npcid = "";
  plog;

  constructor() { 
    this.plog=new plog();
  }

  async show(wh, id) {
    this.plog.log('FishingService start', wh, id);

    this.wh = wh;
    if (this.wh.registry.list.gameitf.clicktoosoon()) return; this.wh.registry.list.gameitf.markclick();

    this.currentpower = 0;
    this.currentstar = 0;
    this.closing = false;
    this.npcid = id;
    if (wh.fishingcontainer != undefined) {
      await this.close();
    }
    wh.fishingcontainer = wh.add.container(0, 0);
    wh.fishingcontainer.setDepth(1000000);

    let loader = new Phaser.Loader.LoaderPlugin(wh);

    var svversionurlstring = this.wh.registry.list.rcvarpass.svversionstring;
    if (svversionurlstring != '') {
      svversionurlstring = '?' + svversionurlstring;
    }

    var loadlist = ['gui/circlehallowflare', 'gui/fishingbg'];
    for (var k in loadlist) {
      var key = loadlist[k];
      loader.image(
        key + '',
        this.wh.registry.list.rcvarpass.rc_baseurl +
        '' +
        key +
        '.png' +
        svversionurlstring
      );
    }
    var loadlist = ['waterripple', 'fishbar', 'ixbeff66'];
    for (var k in loadlist) {
      var key = loadlist[k];
      loader.atlas(
        key + '',
        this.wh.registry.list.rcvarpass.rc_baseurl +
        'sprite/' +
        key +
        '.png' +
        svversionurlstring,
        this.wh.registry.list.rcvarpass.rc_baseurl +
        'sprite/' +
        key +
        '.json' +
        svversionurlstring
      );
    }
    this.wh.registry.list.rcloading.loading2(this.wh);
    loader.on(
      'progress',
      function (value) {
        //this.plog.log("loaderevent", value, loader.totalComplete, loader.totalToLoad);
        this.wh.registry.list.rcloading.loading2text(
          'Loading ' + loader.totalComplete + '/' + loader.totalToLoad
        );
        this.wh.registry.list.rcloading.loading2percent(value);
      },
      this
    );
    loader.once(
      Phaser.Loader.Events.COMPLETE,
      () => {
        this.plog.log('Phaser.Loader.Events.COMPLETE');
        this.plog.log(this);
        this.show_loaded(wh, id);
      },
      this
    );
    loader.start();
  }
  floatertimerfunc() {
    this.plog.log('floatertimerfunc', this);

    var width = this.wh.game.canvas.width;
    var height = this.wh.game.canvas.height;
    var l = width * 0.2;
    var r = width * 0.8;
    var t = height * 0.2;
    var b = height * 0.8;
    //    return Math.random() * (max - min) + min;
    var usew = Math.random() * (r - l) + l;
    var useh = Math.random() * (t - b) + b;
    //this.floater.setPosition(usew,useh);
    var tw = this.wh.tweens.add({
      targets: this.floater,
      x: usew,
      y: useh,
      ease: 'Linear',
      duration: this.floatertimerfunctime, // duration of animation; higher=slower
      loop: 0,
      onUpdate: function (a1, a2, a3, a4, a5) {
        //this.plog.log("onupdate",this);
        if (
          this.targets[0] != undefined &&
          this.targets[0].scene != undefined
        ) {
          this.targets[0].scene.registry.list.fishing.astringstep++;
          if (this.targets[0].scene.registry.list.fishing.astringstep == 10) {
            this.targets[0].scene.registry.list.fishing.astringstep = 0;
            var tmp = this.targets[0].scene.add
              .sprite(0, 0, 'waterripple')
              .play('waterripple_b_once');
            tmp.setPosition(
              this.targets[0].x,
              this.targets[0].y - this.targets[0].displayHeight / 2
            );
            tmp.setOrigin(0.5, 0.5);
            tmp.on('animationcomplete', function () {
              //this.plog.log("animationcomplete",this);
              this.destroy();
            });
          }
          this.targets[0].scene.registry.list.fishing.astring.clear();
          this.targets[0].scene.registry.list.fishing.astring.lineBetween(
            this.targets[0].scene.game.canvas.width + 100,
            -200,
            this.targets[0].x,
            this.targets[0].y - this.targets[0].displayHeight / 2
          );
        }
      },
    });
    //this.astring.clear();
    //this.astring.lineBetween(100, 100, usew, useh);
  }
  async show_loaded(wh, id) {
    var userod = wh.registry.list.rcvarpass.inputitem[id + '---0'];
    var usehook = wh.registry.list.rcvarpass.inputitem[id + '---1'];
    var usebait = wh.registry.list.rcvarpass.inputitem[id + '---2'];
    wh.fishingcontainer.setDepth(1000000);

    this.fishingrules = await this.wh.registry.list.rchttp.getcommon2(
      'fishingrule',
      { mode: 'get', item: usebait['item'], npcid: this.npcid }
    );
    this.plog.log('this.fishingrules', this.fishingrules);
    var keys = Object.keys(this.fishingrules);
    this.fishingrule =
      this.fishingrules[keys[(keys.length * Math.random()) << 0]];
    //this.fishingrule = this.fishingrules[Math.floor(Math.random()*this.fishingrules.length)];
    this.fishingtoken = this.fishingrule["fishingtoken"];
    this.floatertimerfunctime =
      this.floatertimerfunctime / this.fishingrule['fishpower'];
    this.plog.log(
      'fishpower',
      this.fishingrule['fishpower'],
      this.floatertimerfunctime
    );

    this.wh.registry.list.rcloading.close();

    var width = wh.game.canvas.width;
    var height = wh.game.canvas.height;
    this.powerpgbar = new PGBar(this.wh, {
      x: 0,
      y: 0,
      w: width - 40,
      h: 20,
      max: 100,
      me: 0,
      barcol1: 0x6ec2ff,
      barcol2: 0x0394fc,
      txt: '',
    });
    this.powerpgbar.draw();
    this.wh.registry.list.gameitf.genloadedanims(this.wh, 'ixbeff66_b');
    this.wh.registry.list.gameitf.genloadedanims(this.wh, 'waterripple_b');
    this.wh.registry.list.gameitf.genloadedanims(this.wh, 'fishbar_a');
    this.plog.log('FishingService show_loaded', wh, id);

    this.bg = wh.add.image(0, 0, 'gui/fishingbg');
    wh.fishingcontainer.add(this.bg);
    wh.fishingcontainer.bringToTop(this.bg);
    this.bg.setPosition(width / 2, height / 2);
    this.bg.displayWidth = width;
    this.bg.displayHeight = height;
    this.bg.setInteractive();
    this.bg.removeAllListeners();
    this.bg.on(
      'pointerup',
      function () {
        this.tapped(wh);
      },
      this
    );
    this.circ1 = wh.add.image(0, 0, 'gui/circlehallowflare');
    wh.fishingcontainer.add(this.circ1);
    wh.fishingcontainer.bringToTop(this.circ1);
    this.circ1.setPosition(width / 2, height / 2);
    this.circ1.displayWidth = width * 0.5;
    this.circ1.displayHeight = this.circ1.displayWidth * 0.7;
    this.circ1.visible = false;
    this.circ2 = wh.add.image(0, 0, 'gui/circlehallowflare');
    wh.fishingcontainer.add(this.circ2);
    wh.fishingcontainer.bringToTop(this.circ2);
    this.circ2.setPosition(width / 2, height / 2);
    this.circ2.displayWidth = width * 0.9;
    this.circ2.displayHeight = this.circ2.displayWidth * 0.7;
    this.circ2.visible = false;
    //use inputitem data from prefetched from whatever caller module
    this.plog.log(
      'FishingService show_loaded inputitem',
      wh.registry.list.rcvarpass.inputitem
    );
    this.plog.log('FishingService show_loaded items', userod, usehook, usebait);
    this.circ2.alpha = 0.8;

    if (userod == undefined || usehook == undefined || usebait == undefined) {
      this.wh.registry.list.phm.charsaybubble(
        this.wh.char_main,
        'I need to put in all items..'
      );
      return;
    }
    if (userod['item'] == 'fishing_rod1') this.rodpower = 1;
    if (userod['item'] == 'fishing_rod2') this.rodpower = 2;
    if (userod['item'] == 'fishing_rod3') this.rodpower = 3;
    if (userod['item'] == 'fishing_rod4') this.rodpower = 4;
    if (userod['item'] == 'fishing_rod1') this.waittime = 3;
    if (userod['item'] == 'fishing_rod2') this.waittime = 2.3;
    if (userod['item'] == 'fishing_rod3') this.waittime = 1.8;
    if (userod['item'] == 'fishing_rod4') this.waittime = 1;
    if (usehook['item'] == 'fishing_hook1') this.hookpower = 1;
    if (usehook['item'] == 'fishing_hook2') this.hookpower = 2;
    if (usehook['item'] == 'fishing_hook3') this.hookpower = 3;
    if (usehook['item'] == 'fishing_hook4') this.hookpower = 4;

    this.wh.fishingcontainer.add(this.powerpgbar.bar);
    this.wh.fishingcontainer.bringToTop(this.powerpgbar.bar);
    this.powerpgbar.bar.setPosition(20, height - 40);

    this.floater = wh.add.sprite(0, 0, 'waterripple').play('waterripple_b');
    this.floater.setPosition(width / 2, height / 2);
    this.floater.setOrigin(0.5, 0.5);
    wh.fishingcontainer.add(this.floater);
    wh.fishingcontainer.bringToTop(this.floater);

    this.powereffect = wh.add.sprite(0, 0, 'ixbeff66_b').play('ixbeff66_b');
    this.powereffect.setPosition(width / 2, height - 50);
    this.powereffect.setOrigin(0.5, 0.5);
    this.powereffect.displayWidth = width;
    this.powereffect.displayHeight = 100;
    this.powereffect.visible = false;
    wh.fishingcontainer.add(this.powereffect);
    wh.fishingcontainer.bringToTop(this.powereffect);

    this.astring = this.wh.add.graphics();

    wh.fishingcontainer.add(this.astring);
    wh.fishingcontainer.bringToTop(this.astring);
    this.astring.lineStyle(3, 0x00ff00, 1);
    this.astring.clear();
    this.astring.lineBetween(
      width + 100,
      -200,
      this.floater.x,
      this.floater.y - this.floater.displayHeight / 2
    );
    this.fishbar = this.wh.add.sprite(0, 0, 'fishbar', 'a-1'); //    .play('fishbar_i0');
    var fishbarh = Math.min(width, height);
    fishbarh = Math.floor(fishbarh * 0.85);
    this.fishbar.displayHeight = fishbarh;
    this.fishbar.scaleX = this.fishbar.scaleY;
    this.fishbar.setOrigin(0.5, 0.5);

    this.fishbar.setPosition(width - this.fishbar.displayWidth, height / 2);

    wh.fishingcontainer.add(this.fishbar);
    wh.fishingcontainer.bringToTop(this.fishbar);
    //wh.fishingcontainer.add(this.fishbar.bar);
    //wh.fishingcontainer.bringToTop(this.fishbar.bar);
    //wh.fishingcontainer.add(this.fishbar.barfg);
    //wh.fishingcontainer.bringToTop(this.fishbar.barfg);

    this.closebtn = wh.add.sprite(0, 0, "thesq", 'back');
    wh.fishingcontainer.add(this.closebtn);
    wh.fishingcontainer.bringToTop(this.closebtn);

    this.closebtn.displayWidth = this.closebtn.displayHeight = 70;
    this.closebtn.setPosition(40, height - 75);
    this.closebtn.setInteractive();
    this.closebtn.removeAllListeners();
    this.closebtn.on(
      'pointerup',
      function () {
        this.close();
      },
      this
    );

    this.powertimer = wh.time.addEvent({
      delay: 100, // ms
      callback: this.powertimerfunc,
      //args: [],
      callbackScope: this,
      loop: true,
    });

    var waitt = Math.random() * (4000 - 2500) + 2500;
    this.plog.log('fishing random wait', waitt);

    this.wh.kbfocuslv1 = "fishing";

    /*
    var griddat = [];
    var griddatf = [];

    griddat.push(this.closebtn);
    griddatf.push(this.closebtn);
    this.wh.registry.list.gp.guigrid(griddat, griddatf);
    guigrid_noclosebtn*/
    await wh.registry.list.rctoast.sleep(waitt);
    this.startfishing(wh);
  }
  startfishing(wh) {
    this.plog.log('fishing startfishing');
    if (this.closing == true) {
      this.plog.log('fishing startfishing this.closing');
      return;
    }
    this.floatertimer = wh.time.addEvent({
      delay: this.floatertimerfunctime, // ms
      callback: this.floatertimerfunc,
      //args: [],
      callbackScope: this,
      loop: true,
    });

    this.startimer = wh.time.addEvent({
      delay: 2000, // ms
      callback: this.startimerfunc,
      //args: [],
      callbackScope: this,
      loop: true,
    });

    this.circ1.visible = true;
    this.circ2.visible = true;

    var tw1 = this.wh.tweens.add({
      targets: this.circ1,
      alpha: 0.7,
      ease: 'Power3',
      duration: 495, // duration of animation; higher=slower
      loop: -1,
      yoyo: true,
    });
    var tw2 = this.wh.tweens.add({
      targets: this.circ2,
      alpha: 0.4,
      ease: 'Power3',
      duration: 752, // duration of animation; higher=slower
      loop: -1,
      yoyo: true,
    });
  }
  powertimerfunc() {
    var powereachtick = 100 / this.waittime;
    powereachtick = powereachtick / 10;
    this.currentpower += powereachtick;
    this.powerpgbar.set1(this.currentpower);
    //this.plog.log('powertimerfunc', this.currentpower);
    if (this.currentpower >= 100) {
      this.powereffect.visible = true;
    } else {
      this.powereffect.visible = false;
    }
    if (this.fishbar != undefined) {
      if (this.currentstar == 0) this.fishbar.setTexture('fishbar', 'a-1');
      if (this.currentstar == 1) this.fishbar.setTexture('fishbar', 'b-1');
      if (this.currentstar == 2) this.fishbar.setTexture('fishbar', 'c-1');
      if (this.currentstar == 3) this.fishbar.setTexture('fishbar', 'd-1');
      if (this.currentstar == 4) this.fishbar.setTexture('fishbar', 'e-1');
      if (this.currentstar == 5) this.fishbar.setTexture('fishbar', 'f-1');
      if (this.currentstar == 6) this.fishbar.setTexture('fishbar', 'g-1');
      if (this.currentstar == 7) this.fishbar.setTexture('fishbar', 'h-1');
      if (this.currentstar == 8) this.fishbar.setTexture('fishbar', 'i-1');
      if (this.currentstar == 9) this.fishbar.setTexture('fishbar', 'j-1');
      if (this.currentstar == 10) this.fishbar.setTexture('fishbar', 'k-1');
    }
  }
  tapped(wh) {
    this.plog.log('fishing tapped', wh);
    if (this.currentpower < 100) {
      var tw2 = this.wh.tweens.add({
        targets: this.powerpgbar,
        y: this.powerpgbar.y - 20,
        ease: 'Power1',
        duration: 100, // duration of animation; higher=slower
        loop: 0,
        yoyo: true,
      });
      return;
    }

    var width = this.wh.game.canvas.width;
    var height = this.wh.game.canvas.height;
    this.currentpower = 0;
    var x1 = this.floater.x;
    var y1 = this.floater.y;
    var x2 = width / 2;
    var y2 = height / 2;
    var a = x1 - x2;
    var b = y1 - y2;

    var c = Math.sqrt(a * a + b * b);
    var c1step = Math.min(width, height) / 2 / 10;
    var cuse = Math.floor(c / c1step);
    this.plog.log('fishing dist', c, c1step, cuse);
    var starcollected = 0;
    if (cuse == 0) starcollected = 5;
    if (cuse == 1) starcollected = 4;
    if (cuse == 2) starcollected = 3;
    if (cuse == 3) starcollected = 3;
    if (cuse == 4) starcollected = 2;
    if (cuse == 5) starcollected = 2;
    if (cuse == 6) starcollected = 1;
    if (cuse == 7) starcollected = 0;
    if (cuse == 8) starcollected = 0;
    if (cuse == 9) starcollected = 0;
    if (cuse == 10) starcollected = 0;
    this.currentstar += starcollected;
    if (starcollected > 0) this.currentstar += this.rodpower;
    if (this.currentstar > 10) this.currentstar = 10;
    if (starcollected != 0) {
      var tmp = this.wh.add.sprite(0, 0, 'star');
      tmp.displayWidth = tmp.displayHeight = 32;
      tmp.setPosition(
        this.floater.x,
        this.floater.y - this.floater.displayHeight / 2
      );
      var tw2 = this.wh.tweens.add({
        targets: tmp,
        x: width - 40,
        y: height - 40,
        ease: 'Power1',
        duration: 300, // duration of animation; higher=slower
        loop: 0,
        yoyo: false,
        onComplete: function () {
          //this.plog.log('startweencomplete', this);
          this.targets[0].destroy();
        },
      });
    }
    if (this.currentstar >= 10) {
      this.win();
    }
  }
  startimerfunc() {
    this.plog.log("startimerfunc");
    if (this.currentstar > 1) this.currentstar--;
    if (this.currentstar > 10) this.currentstar = 10;
  }
  async win() {
    var winres = await this.wh.registry.list.rchttp.getcommon2(
      'fishingrule',
      { mode: 'win', token: this.fishingtoken }
    );
    this.plog.log("fishing winres", winres);
    //show(wh, reward, config = {})
    await this.close();
    this.wh.registry.list.showreward.show(this.wh, ["stackitem:" + winres["item"] + ":1"], { text: "You Caught: " });
    await this.wh.registry.list.rchttp.updatecharinfo();
    this.wh.registry.list.rcvarpass.inputitem =
      await this.wh.registry.list.rchttp.getcommon2('inputitem', {
        mode: 'get',
      });
    this.wh.registry.list.gameitf.inputitem_updatedisplay(this.wh);
  }
  async close() {
    this.closing = true;
    if (this.powertimer != undefined) this.powertimer.remove();
    this.plog.log('this.floatertimer', this.floatertimer);
    this.plog.log('this.close', this.wh);
    //if (this.floatertimer != undefined) this.floatertimer.remove();
    //if (this.startimer != undefined) this.startimer.remove();


    for (const k in this.wh.fishingcontainer.list) {
      try {
        await this.wh.fishingcontainer.remove(this.wh.fishingcontainer.list[k], true);
        //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list[k].destroy();
      } catch (e) {
        this.plog.log("ERROR! fishingcontainer.destroy() ", e);
      }
    }

    for (const k in this.wh.fishingcontainer.list) {
      try {
        await this.wh.fishingcontainer.remove(this.wh.fishingcontainer.list[k], true);
        //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list[k].destroy();
      } catch (e) {
        this.plog.log("ERROR! fishingcontainer.destroy() ", e);
      }
    }
    try {
      this.wh.fishingcontainer.destroy();//true
    } catch (e) {
      this.plog.log("ERROR! fishingcontainer.destroy() ", e);
    }
    this.wh.fishingcontainer = undefined;
    this.wh.registry.list.gp.guigrid_focushide();
    this.wh.kbfocus = "";
    this.wh.kbfocuslv1 = "";
    //this=undefined;
  }
}
