import { Injectable } from '@angular/core';
/*
@Injectable({
  providedIn: 'root',
});*/
enum DebugLevel {
  OFF = 0,
  ERROR = 1,
  WARN = 2,
  INFO = 3,
  DEBUG = 4
};
export class plog {
  currentLevel;

  constructor() {
    this.currentLevel = 1;
  }
  public setLevel(level): void {
    this.currentLevel = level;
  }

  public l(...args: any[]): void {
    if (this.currentLevel == 0) return;
    if (this.currentLevel <= 4) { //same as debug
      console.log("PLOG:", arguments[0], arguments[1], arguments[2], arguments[3], arguments[4], arguments[5], this.getline());
    }
  }
  public log(...args: any[]): void {
    //console.log(this.currentLevel,arguments);
    if (this.currentLevel == 0) return;
    if (this.currentLevel >= 4) { //same as debug
      console.group("PLOG:");
      console.log( arguments[0], arguments[1], arguments[2], arguments[3], arguments[4], arguments[5]);
      //console.log("tt");
      console.groupCollapsed("Trace");
        console.trace();
        //var tmpline=this.getline();
        //console.log(tmpline);
        console.groupEnd();
      console.groupEnd();
    }
  }

  public error(...args: any[]): void {
    if (this.currentLevel >= 1) {
      console.group("PLOG:");
      console.error( arguments[0], arguments[1], arguments[2], arguments[3], arguments[4], arguments[5]);
      //console.log("tt");
      console.groupCollapsed("Trace");
        console.trace();
        //var tmpline=this.getline();
        //console.log(tmpline);
        console.groupEnd();
      console.groupEnd();
    }
  }

  public warn(...args: any[]): void {
    if (this.currentLevel >= 3) {
      console.group("PLOG:");
      console.warn( arguments[0], arguments[1], arguments[2], arguments[3], arguments[4], arguments[5]);
      //console.log("tt");
      console.groupCollapsed("Trace");
        console.trace();
        //var tmpline=this.getline();
        //console.log(tmpline);
        console.groupEnd();
      console.groupEnd();
    }
  }

  public info(...args: any[]): void {
    if (this.currentLevel >= 4) {
      console.group("PLOG:");
      console.info( arguments[0], arguments[1], arguments[2], arguments[3], arguments[4], arguments[5]);
      //console.log("tt");
      console.groupCollapsed("Trace");
        console.trace();
        //var tmpline=this.getline();
        //console.log(tmpline);
        console.groupEnd();
      console.groupEnd();
    }
  }

  public debug(...args: any[]): void {
    if (4 <= this.currentLevel) {
      console.group("PLOG:");
      console.log( arguments[0], arguments[1], arguments[2], arguments[3], arguments[4], arguments[5]);
      //console.log("tt");
      console.groupCollapsed("Trace");
        console.trace();
        //var tmpline=this.getline();
        //console.log(tmpline);
        console.groupEnd();
      console.groupEnd();
    }
  }
  public getline() {
    const e = new Error();
    const regex = /\((.*):(\d+):(\d+)\)$/
    const match = regex.exec(e.stack.split("\n")[2]);
    return e.stack;
    return {
      filepath: match[1],
      line: match[2],
      column: match[3],
      stack: e.stack
    };
  }
}
