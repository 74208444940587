import { Injectable } from '@angular/core';
import { RCVarsService } from './rcvars-service.service';

@Injectable({
  providedIn: 'root'
})
export class OverlayDSPService {
  o;
  oc;
  odiv;

  constructor(
    public rcvarsservice: RCVarsService
  ) {
  }
  startval() {
    this.odiv = document.getElementById("odspdiv");
    this.odiv.style.visibility="visible";
    this.o = document.getElementById("odspdivct");
    this.oc = document.getElementById("odspdivclose");
    
    this.o.innerHTML = "";
    this.rcvarsservice.rcgame.overlay_hide();
    this.oc.style.visibility="visible";
  }
  setMaxWidth(width) {
    this.o.style.maxWidth=width;
  }
  
  async menuevent(wh) {
    var data=await wh.registry.list.rchttp.getcommon2("menuevent",{});
    console.log("menuevent", wh, data);
    this.startval();
    console.log("menuevent oc",  this.oc);

    this.setMaxWidth('600px');
    this.o.innerHTML = "menuevent";

    for (const k in data) {
      var v = data[k];
      console.log("menuevent",k, v);
      this.o.innerHTML = this.o.innerHTML + "<a target=_blank href='#' onclick=\"window.open('"+wh.registry.list.rcvarpass.rc_website+"?page=viewevent&id="+k+"', '_system', ''); return false;\"><img src='"+v["img"]["url"]+"' style='width: 100%;'></a>";
      this.o.innerHTML = this.o.innerHTML + "";
    }
    //this.rcvarsservice.rcgame.rc_selectcharacter(false);

  }
}
