import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { plog } from './plog.service';

@Injectable({
  providedIn: 'root'
})
export class RCLoadingService {
  loading2textmask;


  isLoading;
  removebgtween;
  createbgtween;
  loading2bg;
  loading2txt;
  loading2locker = false;
  timectltimer;
  fxPixelated;
  loadingtip;
  pixelatelv = 0;
  pixelatestart = 10;
  loadingdescr;
  loading2txtb;
  loading2dsptext = "Loading";
  loading2box;
  loading2tween;
  loading2imgbg;
  loadingcat;
  loadingcattween;
  loading2currentpc = 0;
  wh;
  loading2txtoffsetheight = 50;
  loadingbox2dist = 100;
  loadingtips = [];
  loadingtipsindex = 0;
  loading2descrtext;

  plog;
  constructor(
    private loadingController: LoadingController
  ) {
    this.loadingtips.push("Loading the map for the first time might take longer than subsequent loads");
    this.loadingtips.push("You can find free HP potions in bushes");
    this.loadingtips.push("There are free daily chests available in the Shop menu");
    this.loadingtips.push("Get a blessing before starting your adventure");
    this.loadingtips.push("Don't forget to collect the free items from daily spinners");
    this.loadingtips.push("Items on the floor will eventually disappear");
    this.loadingtips.push("If the loading progress bar becomes unresponsive, please restart the game");
    this.shuffle(this.loadingtips);
    //this.plog.log("this.loadingtips", this.loadingtips);
    this.plog = new plog();
  }
  shuffle(array) {
    let currentIndex = array.length, randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex != 0) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }

    return array;
  }
  setText(text: string) {
    const elem = document.querySelector(
      "div.loading-wrapper div.loading-content");
    if (elem) elem.innerHTML = text;
  }
  rcltimefunccount = 0;
  rcltimefunc() {
    this.rcltimefunccount++;
    //this.plog.log("rcltimefunc", this.timectltimer);
    if (this.loadingtip == undefined) {
      return;
    }
    var width = this.wh.game.canvas.width;
    var height = this.wh.game.canvas.height;
    var localwidth = Math.min(width, height);
    if (localwidth > 550) localwidth = 550;
    var str = this.loadingtips[this.loadingtipsindex];
    this.loadingtip.setText(str);
    this.loadingtip.setWordWrapWidth(localwidth - 40);
    this.loadingtip.setPosition(width / 2 - (this.loadingtip.displayWidth / 2), (height / 2) + this.loading2txtoffsetheight + 50);

    this.loadingtip.setDepth(15000005);
    this.loadingdescr.setWordWrapWidth(localwidth - 40);
    this.loadingdescr.setPosition(width / 2 - (this.loadingdescr.displayWidth / 2), (height) - 20);

    this.loadingdescr.setDepth(15000005);
    this.loadingtipsindex++;
    if (this.loadingtipsindex >= this.loadingtips.length) this.loadingtipsindex = 0;

    if (this.rcltimefunccount > 2) {
      this.loading2box.visible = true;
      this.loading2txt.visible = true;
      this.loading2txtb.visible = true;
      this.loading2bg.visible = true;
      this.loadingtip.visible = true;
      this.loadingdescr.visible = true;
    }
  }
  loading2text(txt) {
    if (this.loading2txt != undefined) {
      var width = this.wh.game.canvas.width;
      var height = this.wh.game.canvas.height;
    }
    this.loading2dsptext = txt;
    this.loading2updatedsp();
  }
  loading2setdescr(txt) {
    //console.log("loading2setdescr",txt);
    if (this.loadingdescr != undefined) {
      //console.log("loading2setdescr RUN",txt);
      this.loading2descrtext = txt;
      this.loading2updatedsp();
    }
  }
  loading2lock(toggle) {
    this.loading2locker = toggle;
  }
  async loading2(wh, delaydsp = false, showbg = true) {
    this.plog.warn("loading2(),", delaydsp);
    //if (delaydsp==true) console.error("trace");
    this.wh = wh;
    this.loading2currentpc = 0;
    this.isLoading = true;
    this.rcltimefunccount = 0;
    try {
      //this.loadingController.dismiss().then(() => this.plog.log('loading2 dismiss loadingController'));
    } catch (error) {
      this.plog.log("error loading2 dismiss loadingController", error);
    }

    var width = wh.game.canvas.width;
    var height = wh.game.canvas.height;
    if (this.loading2bg != undefined) {
      this.loading2bg.destroy();
      this.loading2bg = undefined;
    }
    if (this.loading2txt != undefined) {
      this.loading2txt.destroy();
      this.loading2txt = undefined;
    }
    if (this.loading2txtb != undefined) {
      this.loading2txtb.destroy();
      this.loading2txtb = undefined;
    }
    if (this.loadingtip != undefined) {
      this.loadingtip.destroy();
      this.loadingtip = undefined;
    }
    if (this.loadingdescr != undefined) {
      this.loadingdescr.destroy();
      this.loadingdescr = undefined;
    }
    /*if (this.loading2imgbg != undefined) {
      this.loading2imgbg.destroy();
      this.loading2imgbg = undefined;
    }*/

    if (this.createbgtween != undefined) {
      try {
        this.createbgtween.stop();
        this.createbgtween.remove();
      } catch (e) {
        console.log(e);
      }
      this.createbgtween = undefined;
    }

    if (this.loading2imgbg == undefined && showbg == true) {
      this.pixelatelv = this.pixelatestart + 0;
      var randimguse = Math.floor(Math.random() * 12) + 1;
      this.loading2imgbg = wh.add.image(0, 0, "loadingbg" + randimguse);
      this.loading2imgbg.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);
      //this.fxPixelated = this.loading2imgbg.preFX.addPixelate(this.pixelatelv);
      //this.fxPixelated = this.loading2imgbg.preFX.addBlur();
      //console.log("loading 2 run", randimguse);
      this.loading2imgbg.setOrigin(0.5, 0.5);
      //this.loading2imgbg.setAlpha(0.75);
      if (width > height) {
        this.loading2imgbg.displayWidth = width + 5;
        this.loading2imgbg.scaleY = this.loading2imgbg.scaleX;
      } else {
        this.loading2imgbg.displayHeight = height + 5;
        this.loading2imgbg.scaleX = this.loading2imgbg.scaleY;
      }
      this.loading2imgbg.setPosition(width / 2, height / 2);
      this.loading2imgbg.setDepth(15000001);
      this.loading2imgbg.setAlpha(0);
      this.createbgtween = this.wh.tweens.add({
        targets: [
          this.loading2imgbg
        ],
        alpha: { from: 0, to: 1 },
        ease: 'Power1',
        duration: 300,
        onComplete: async function () {
        }.bind(this)
      });
    }
    if (showbg == false) {
      if (this.loading2imgbg != undefined) {
        this.loading2imgbg.destroy();
        this.loading2imgbg = undefined;
      }
    }
    this.loading2bg = wh.add.image(0, 0, "itf-black");
    this.loading2bg.setPosition(0, 0);
    this.loading2bg.setOrigin(0, 0);
    this.loading2bg.setAlpha(0.75);
    this.loading2bg.visible = true;
    this.loading2bg.setDepth(15000000);
    this.loading2bg.setInteractive();
    this.loading2bg.displayWidth = width + 5;
    this.loading2bg.displayHeight = height + 5;



    var localwidth = Math.min(width, height);
    if (localwidth > 550) localwidth = 550;

    this.loadingtip = wh.add.text(0, 0, "Loading", { fontFamily: 'ffff,uuuu', fontSize: '12px', stroke: '#333', strokeThickness: 4, color: '#fff', fontStyle: 'normal', align: 'center', resolution: 12 });
    this.loading2txt = wh.add.text(0, 0, "Loading", { fontFamily: 'ffff,uuuu', fontSize: '18px', stroke: '#000', strokeThickness: 0, color: '#fff', fontStyle: 'normal', resolution: 12 });
    this.loading2txtb = wh.add.text(0, 0, "Loading", { fontFamily: 'ffff,uuuu', fontSize: '18px', stroke: '#000', strokeThickness: 0, color: '#000', fontStyle: 'normal', resolution: 12 });
    this.loadingdescr = wh.add.text(0, 0, " ", { fontFamily: 'ffff,uuuu', fontSize: '8px', stroke: '#000', strokeThickness: 0, color: '#fff', fontStyle: 'normal', align: 'center' });
    this.loadingtip.postFX.addGlow(0x02A8DF, 4, 0, false, 0.2, 10);


    //this.loading2txt.setStroke(0x000000, 1);
    //this.loading2txt.setShadow(0,0, '#000000', 3, true, true);

    this.loading2txt.setWordWrapWidth(localwidth - 40);
    this.loading2txt.setPosition(width / 2 - (this.loading2txt.displayWidth / 2), (height / 2) + this.loading2txtoffsetheight - (this.loading2txt.displayHeight / 2));
    this.loading2txt.setDepth(15000003);
    this.loading2txtb.setWordWrapWidth(localwidth - 40);
    this.loading2txtb.setPosition(width / 2 - (this.loading2txtb.displayWidth / 2), (height / 2) + this.loading2txtoffsetheight - (this.loading2txt.displayHeight / 2));

    if (this.loadingcat != undefined) {
      //this.plog.log("loading2box exists", this.loading2box);
      this.loadingcat.destroy();
      this.loadingcat = undefined;
    } else {
      //this.plog.log("rcloadingwh", wh);

      if (wh.anims.anims.entries["loadingcat_b"] == undefined) {
        var tmpframe = wh.game.anims.generateFrameNames(
          'loadingcat',
          {
            prefix: 'b-',
            start: 1,
            end: 6,
            zeroPad: 0,
          }
        );
        //this.plog.log('load2e frames for ' + key, tmpframe, val);
        var tmpf = wh.game.anims.create({
          key: 'loadingcat_b',
          frames: tmpframe,
          frameRate: 20,
          repeat: -1,
        });
      }
    }
    this.loadingcat = wh.add.sprite(-100, -100, "loadingcat");
    this.loadingcat.play("loadingcat_b");
    this.loadingcat.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);

    this.loadingcat.setOrigin(1, 1);
    this.loadingcat.setDepth(15000006);


    this.loading2txtb.setDepth(15000005);

    this.loading2text("Loading..");

    if (this.loading2box != undefined) {
      //this.plog.log("loading2box exists", this.loading2box);
      this.loading2box.destroy();
      this.loading2box = undefined;
    }

    this.loading2box = wh.add.image(0, 0, "itf-white");
    this.loading2box.setOrigin(0, 0.5);
    //this.loading2box.postFX.addGlow(0xffffff, 3, 0, false, 0.2, 10);

    //this.loading2box.preFX.addBloom(0xffffff, 0.3, 0.3, 1.5, 2);
    //addBloom([color], [offsetX], [offsetY], [blurStrength], [strength], [steps])

    var boxsize = 50;

    //this.loading2box.fillRect((width / 2) - (boxsize / 2), (height / 2) - (boxsize / 2), boxsize, boxsize);
    //this.loading2box.fillRect(0, (height / 2) - (boxsize / 2), 1, boxsize);
    this.loading2box.setDepth(15000002);

    if (delaydsp == true) {
      this.loading2box.visible = false;
      this.loading2txt.visible = false;
      this.loading2txtb.visible = false;
      this.loading2bg.visible = false;
      this.loadingtip.visible = false;
      this.loadingcat.visible = false;
      this.loadingdescr.visible = false;
    } else {
      this.loading2box.visible = true;
      this.loading2txt.visible = true;
      this.loading2txtb.visible = true;
      this.loading2bg.visible = true;
      this.loadingtip.visible = true;
      this.loadingcat.visible = true;
      this.loadingdescr.visible = true;
    }
    if (wh.cameras.main != undefined) {
      //this.plog.log("camman wh",wh);
      if (typeof wh.camman === "function") {
        // ref by Preloader scene
        wh.camman(wh);
      }

    }
    /*
        this.loading2tween = this.wh.tweens.add({
          targets: this.loading2box,
          x: {from: this.loading2box.x-this.loadingbox2dist, to: this.loading2box.x+this.loadingbox2dist},
          ease: 'Power1',
          duration: 1000,
          yoyo: true,
          repeat: -1,
          onStart: function () {
            //this.plog.log('onStart'); this.plog.log(arguments); 
          },
          onComplete: function () {
            //this.plog.log('onComplete'); this.plog.log(arguments);
          },
          onYoyo: function () {
            //this.plog.log('onYoyo'); this.plog.log(arguments); 
          },
          onRepeat: function () {
            //this.plog.log('onRepeat'); this.plog.log(arguments); 
          },
        });
        */
    //this.plog.log("loading2 this", this);
    this.rcltimefunc();
    if (this.timectltimer == undefined) {
      this.timectltimer = this.wh.time.addEvent({
        delay: 3200,                // ms
        callback: this.rcltimefunc,
        //args: [],
        callbackScope: this,
        loop: true
      });
    }
    this.timectltimer.pause = false;
    this.loading2percent(0);
  }
  async loading2updatedsp() {

    //this.plog.log("loading2updatedsp", this);
    //this.loading2text(this.loading2dsptext+" " + Math.floor(this.loading2currentpc * 100) + "%");
    if (this.loading2box == undefined || this.loading2txt == undefined) return;
    var width = this.wh.game.canvas.width;
    var height = this.wh.game.canvas.height;
    //this.loading2box.clear();
    //this.loading2box.fillStyle(0xFFFFFF, 10.0);

    var boxsize = 50;
    //this.loading2box.fillRect((width / 2) - (boxsize / 2), (height / 2) - (boxsize / 2), boxsize, boxsize);
    if (this.loading2box != undefined) {
      //this.loading2box.fillRect(0, (height / 2) - (boxsize / 2), width * this.loading2currentpc, boxsize);
      this.loading2box.setPosition(0, height / 2);
      this.loading2box.displayHeight = boxsize;
      this.loading2box.displayWidth = width * this.loading2currentpc;
    }
    if (this.loading2txt != undefined)
      this.loading2txt.setText(this.loading2dsptext + " " + Math.floor(this.loading2currentpc * 100) + "%");
    if (this.loading2txt != undefined)
      this.loading2txt.setPosition((width / 2) - (this.loading2txt.displayWidth / 2), (height / 2) - (this.loading2txt.displayHeight / 2));
    if (this.loading2txtb != undefined)
      this.loading2txtb.setText(this.loading2dsptext + " " + Math.floor(this.loading2currentpc * 100) + "%");
    if (this.loading2txtb != undefined)
      this.loading2txtb.setPosition((width / 2) - (this.loading2txtb.displayWidth / 2), (height / 2) - (this.loading2txtb.displayHeight / 2));
    if (this.loadingdescr != undefined) {
      this.loadingdescr.setText(this.loading2descrtext);
      this.loadingdescr.setPosition((width / 2) - (this.loadingdescr.displayWidth / 2), (height - 20));

    }

    if (this.loadingcat != undefined) {
      this.loadingcat.displayHeight = 72;
      this.loadingcat.scaleX = this.loadingcat.scaleY;
      //this.loadingcat.setPosition((width * this.loading2currentpc)-16, (height / 2) - (boxsize / 2)+20);
      if (this.loadingcattween) {

      }
      this.loadingcat.y = (height / 2) - (boxsize / 2) + 20;
      this.loadingcattween = this.wh.tweens.add({
        targets: this.loadingcat,
        x: (width * this.loading2currentpc) - 16,
        ease: 'Linear',
        duration: 150, // duration of animation; higher=slower
        delay: 50,
      });
    }
    if (this.loading2imgbg != undefined) {

      let result = (1 - this.loading2currentpc) * this.pixelatestart;
      this.pixelatelv = result;
      if (this.pixelatelv < 2) {
        //this.pixelatelv = 2;
      }
      if (this.pixelatelv < 0) {
        this.pixelatelv = 0;
      }
      // console.log(this.loading2currentpc, this.pixelatelv);
      //this.loading2imgbg.preFX.addPixelate(this.pixelatelv);
      // this.fxPixelated.amount = Math.floor(this.pixelatelv); //pixelate
      // disable blur update
      ///this.fxPixelated.strength = Math.floor(this.pixelatelv / 10); //blur
      //this.loading2imgbg.setOrigin(0, 0);
      this.loading2imgbg.setPosition(width / 2, height / 2);
      //this.loading2imgbg.setAlpha(0.75);
      if (width > height) {
        this.loading2imgbg.displayWidth = width + 5;
        this.loading2imgbg.scaleY = this.loading2imgbg.scaleX;
      } else {
        this.loading2imgbg.displayHeight = height + 5;
        this.loading2imgbg.scaleX = this.loading2imgbg.scaleY;
      }
    }
    if (this.loading2textmask == undefined)
      this.loading2textmask = await this.loading2box.createBitmapMask();//createGeometryMask
    try {
      this.loading2textmask.bitmapMask.displayWidth = this.loading2box.displayWidth
    } catch (e) {
      console.log(e);
    }
    //console.log(this.loading2textmask);
    if (this.loading2txtb != undefined)
      this.loading2txtb.setMask(this.loading2textmask);

  }
  loading2percent(pc) {
    this.loading2currentpc = pc;
    if (this.loading2box == undefined) {
      //this.plog.log("loading2percent", pc, "this.loading2box not created");
      return;
    }
    this.loading2updatedsp();

  }
  async loading() {
    this.isLoading = true;
    return await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Please wait...',
      backdropDismiss: false,
      showBackdrop: true,
      spinner: "circles"
    }).then(a => {
      a.present().then(() => {
        this.plog.log('presented');
        if (!this.isLoading) {
          a.dismiss().then(() => this.plog.log('abort presenting'));
        }
      });
    });
    const loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Please wait...',
      backdropDismiss: false,
      showBackdrop: true,
      spinner: "crescent"
    });
    await loading.present();
    //const { role, data } = await loading.onDidDismiss();
    //this.plog.log('Loading dismissed!');
  }
  isloading() {
    if (this.isLoading == true) return true;
    return false;
  }
  async close() {
    if (this.loading2locker == true) {
      this.plog.log("loading2locker active, ignore");
      return;
    }
    if (this.timectltimer != undefined) {
      this.timectltimer.pause = true;
      this.timectltimer.remove(false);
      this.timectltimer = undefined;
    }


    if (this.removebgtween != undefined) {
      try {
        this.removebgtween.stop();
        this.removebgtween.remove();
      } catch (e) {
        console.log(e);
      }
      this.removebgtween = undefined;
    }
    if (this.createbgtween != undefined) {
      try {
        this.createbgtween.stop();
        this.createbgtween.remove();
      } catch (e) {
        console.log(e);
      }
      this.createbgtween = undefined;
    }

    //this.plog.log("rcloading.close(); b", this, this.loading2box);
    this.isLoading = false;
    if (this.loading2bg != undefined) {
      this.loading2bg.destroy();//true
      this.loading2bg = undefined;
    }
    if (this.loadingcat != undefined) {
      this.loadingcat.destroy();//true
      this.loadingcat = undefined;
    }
    if (this.loading2txt != undefined) {
      this.loading2txt.destroy();//true
      this.loading2txt = undefined;
    }
    if (this.loadingtip != undefined) {
      this.loadingtip.destroy();//true
      this.loadingtip = undefined;
    }
    if (this.loadingdescr != undefined) {
      this.loadingdescr.destroy();//true
      this.loadingdescr = undefined;
    }
    if (this.loading2txtb != undefined) {
      this.loading2txtb.destroy();//true
      this.loading2txtb = undefined;
    }

    if (this.loading2imgbg != undefined) {
      this.removebgtween = this.wh.tweens.add({
        targets: [
          this.loading2imgbg
        ],
        alpha: { from: 1, to: 0 },
        ease: 'Power1',
        duration: 500,
        onComplete: async function () {
          //console.log("tweentree2 complete", this);
          try {
            this.loading2imgbg.destroy();//true
            this.loading2imgbg = undefined;
          } catch (e) {
            console.log(e);
          }

        }.bind(this)
      });
      //this.loading2imgbg.destroy();//true
      //this.loading2imgbg = undefined;
    }

    if (this.loading2box != undefined) {
      //this.loading2box.clear();
      this.loading2box.destroy();//true
      this.loading2box = undefined;
      //this.plog.log("rcloading.close(); a", this.loading2box);
    }
    try {
      await this.loadingController.dismiss().then(() => this.plog.log('dismissed'));
    } catch (error) {
      this.plog.log("error", error);
    }
    /*
        this.loadingController.dismiss().then((response) => {
          this.plog.log('Loader closed!', response);
        }).catch((err) => {
          this.plog.log('Loader Error occured : ', err);
        });*/
  }
  async presentLoadingWithOptions() {
    const loading = await this.loadingController.create({
      spinner: null,
      duration: 5000,
      message: 'Click the backdrop to dismiss early...',
      translucent: true,
      cssClass: 'custom-class custom-loading',
      backdropDismiss: true
    });
    await loading.present();

    const { role, data } = await loading.onDidDismiss();
    this.plog.log('Loading dismissed with role:', role);
  }
}
