import { Injectable } from '@angular/core';
import { RCVarsService } from './rcvars-service.service';
import { RCHTTP } from './rchttp.service';
import { plog } from './plog.service';

@Injectable({
  providedIn: 'root',
})
export class RCHomeInvenStackItemsService {
  shopdragbtn;
  empty;
  loading;
  selecteditemno = '';
  currentresppage = 1;
  respperpage = 25;
  resptotalpage;
  selectcircle;
  itemdescr;
  shopitemlist = [];
  shopitemlistp = [];
  shopfwdbtn;
  shopbackbtn;
  invendropbtn;
  invenmode = '';
  shopclosebtn;
  no5;
  no10;
  shopguibg;
  plog;

  constructor(public rcvarsservice: RCVarsService, public rchttp: RCHTTP) { 
    this.plog=new plog();
  }

  loadingshow() {
    this.wh.homeinventorycontainer_stackitem.bringToTop(this.loading);
    this.loading.visible = true;
  }
  loadinghide() {
    this.loading.visible = false;
  }
  wh;
  firsttimerun = true;
  firsttimerunfunc() {
    if (this.firsttimerun == true) {
      this.wh.registry.list.gameitf.guiwindowclick(
        this.wh.homeinventorycontainer_stackitem
      );
    }
    this.firsttimerun = false;
  }
  async showinventory(wh, shopdata, invenmode, forcefocus = false) {
    if (forcefocus == true) this.firsttimerun = true;

    this.wh = wh;

    if (this.wh.homeinventorycontainer_stackitem != undefined) {
      await this.showinventory_close(this.wh);
    }
    await this.wh.registry.list.rchttp.updatecharinfo();
    this.invenmode = invenmode;/*
    for (var i = 0; i < 100; i++) {
      if (this.shopitemlist[i] != undefined) this.shopitemlist[i].destroy(); // = undefined;
      if (this.shopitemlistp[i] != undefined) this.shopitemlistp[i].destroy(); // = undefined;
    }
    if (wh.homeinventorycontainer_stackitem != undefined) {
      wh.homeinventorycontainer_stackitem.visible = true;
      this.showinventory_loaded(wh, shopdata);
      return;
    }*/
    var width = wh.game.canvas.width;
    var height = wh.game.canvas.height;
    this.plog.log('showinventory', wh, shopdata);

    const selectcircle = wh.add.sprite(0, 0, 'selectcircle').play('selected_b');
    selectcircle.setOrigin(0.5, 0.5);
    selectcircle.visible = false;
    selectcircle.setAlpha(0.7);
    const shopguibg = wh.add.image(0, 0, 'homeinvensguibg');
    shopguibg.setOrigin(0.5, 0.5);
    shopguibg.setInteractive({ pixelPerfect: true });
    shopguibg.removeAllListeners();
    this.shopguibg = shopguibg;
    const shopbackbtn = wh.add.sprite(0, 0, "thesb", "backsign");//wh.add.image(0, 0, 'listbackbtn');
    shopbackbtn.setOrigin(0.5, 0.5);
    shopbackbtn.setInteractive();
    shopbackbtn.removeAllListeners();
    const shopfwdbtn = wh.add.sprite(0, 0, "thesb", "forwardsign");//wh.add.image(0, 0, 'listfwdbtn');
    shopfwdbtn.setOrigin(0.5, 0.5);
    shopfwdbtn.setInteractive();
    shopfwdbtn.removeAllListeners();
    const invendropbtn = wh.add.sprite(0, 0, "thesb", "retrieve");//wh.add.image(0, 0, 'homeinvenret');
    invendropbtn.setOrigin(0.5, 0.5);
    invendropbtn.setInteractive();
    invendropbtn.removeAllListeners();
    //this.plog.log('showinventory var', this.rcvarsservice);
    this.shopfwdbtn = shopfwdbtn;
    this.shopbackbtn = shopbackbtn;
    this.selectcircle = selectcircle;
    this.invendropbtn = invendropbtn;

    this.shopclosebtn = wh.add.sprite(300, 300, "thesq", 'close');
    this.shopclosebtn.name='skipcntnrsizecal';
    this.shopclosebtn.setOrigin(0.5, 0.5);
    this.shopclosebtn.setPosition(155, -200);
    this.shopclosebtn.displayWidth = 40;
    this.shopclosebtn.displayHeight = 40;
    this.shopclosebtn.setInteractive();
    this.shopclosebtn.removeAllListeners();
    this.shopclosebtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        //this.scene.registry.list["thethis"].phm.floorclick(this);
        this.plog.log(
          'gc.npcbackbtn pointerup',
          pointer,
          localX,
          localY,
          event,
          this
        );
        this.showinventory_close(wh);
      },
      this
    );

    shopbackbtn.displayWidth = 100;
    shopfwdbtn.displayWidth = 100;
    invendropbtn.displayWidth = 100;
    shopbackbtn.displayHeight = 40;
    shopfwdbtn.displayHeight = 40;
    invendropbtn.displayHeight = 40;
    shopbackbtn.setPosition(-110, 220);
    shopfwdbtn.setPosition(110, 220);
    invendropbtn.setPosition(-10, 220);
    wh.homeinventorycontainer_stackitem = wh.add.container(300, 300);
    wh.homeinventorycontainer_stackitem._refsrv = this;
    wh.homeinventorycontainer_stackitem.name = "homeinventorycontainer_stackitem";
    wh.homeinventorycontainer_stackitem.add(this.shopclosebtn);
    wh.homeinventorycontainer_stackitem.add(selectcircle);
    wh.homeinventorycontainer_stackitem.add(shopguibg);
    wh.homeinventorycontainer_stackitem.add(shopbackbtn);
    wh.homeinventorycontainer_stackitem.add(shopfwdbtn);
    wh.homeinventorycontainer_stackitem.add(invendropbtn);
    wh.homeinventorycontainer_stackitem.bringToTop(this.shopclosebtn);
    var itemdescr = wh.add.text(0, 0,this.wh.trs("f:homeinventorycontainer:Click Item to View Description===Click Item to View Description"), wh.fs["winguiitemdescr"]);
    wh.homeinventorycontainer_stackitem.add(itemdescr);
    itemdescr.setOrigin(0, 0);
    itemdescr.setPosition(-140, 179);
    this.itemdescr = itemdescr;

    var no5 = wh.add.sprite(0, 0, "thesq", 'numb');
    this.no5 = no5;
    no5.displayWidth = 18;
    no5.displayHeight = 18;
    no5.setPosition(52, 210);
    var no10 = wh.add.sprite(0, 0, "thesq", 'n10b');
    this.no10 = no10;
    no10.displayWidth = 18;
    no10.displayHeight = 18;
    no10.setPosition(52, 230);
    no5.setInteractive();
    no10.setInteractive();
    no5.removeAllListeners();
    no10.removeAllListeners();
    no5.on('pointerover', function () {
      wh.registry.list.gameitf.gamemenutooltip(
        wh,
        this,
        this.wh.trs("f:homeinventorycontainer:Retrieve Items===Retrieve Items"),
        'right'
      );
    });
    no10.on('pointerover', function () {
      wh.registry.list.gameitf.gamemenutooltip(
        wh,
        this,
        this.wh.trs("f:homeinventorycontainer:Retrieve 10 of selected item===Retrieve 10 of selected item"),
        'right'
      );
    });
    wh.homeinventorycontainer_stackitem.add(no5);
    wh.homeinventorycontainer_stackitem.add(no10);

    this.empty = wh.add.image(300, 300, 'empty');
    this.empty.setOrigin(0.5, 0.5);
    this.empty.setPosition(0, 0);
    this.empty.displayWidth = this.empty.displayHeight = 300;
    wh.homeinventorycontainer_stackitem.add(this.empty);
    wh.homeinventorycontainer_stackitem.bringToTop(this.empty);
    this.empty.visible = false;


    this.loading = wh.add.sprite(300, 300, 'pixelloading').play('pixelloading_b');
    this.loading.setOrigin(0.5, 0.5);
    this.loading.setPosition(0, 0);
    this.loading.displayWidth = this.loading.displayHeight = 60;

    wh.homeinventorycontainer_stackitem.add(this.loading);
    wh.homeinventorycontainer_stackitem.bringToTop(this.loading);
    //drag

    this.shopdragbtn = wh.add.image(300, 300, 'spacer');
     this.shopdragbtn.setOrigin(0.5, 0.5);
    this.shopdragbtn.setPosition(0, -200);
    this.shopdragbtn.displayWidth = 270;
    this.shopdragbtn.displayHeight = 60;
    this.shopdragbtn.setInteractive();
    this.shopdragbtn.removeAllListeners();
    this.shopdragbtn.on(
      'pointerout',
      function (pointer, localX, localY, event) {
        this.rcvarsservice.gameobj.scene.scenes[0].homeinventorycontainer_stackitem.dragactive =
          false;
      },
      this
    );
    this.shopdragbtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        this.rcvarsservice.gameobj.scene.scenes[0].homeinventorycontainer_stackitem.dragactive =
          false;
      },
      this
    );
    this.shopdragbtn.on(
      'pointerdown',
      function (pointer, localX, localY, event) {
        this.rcvarsservice.gameobj.registry.list.gameitf.guiwindowclick(
          this.rcvarsservice.gameobj.scene.scenes[0]
            .homeinventorycontainer_stackitem
        );

        this.rcvarsservice.gameobj.scene.scenes[0].homeinventorycontainer_stackitem.dragactive =
          true;
        this.rcvarsservice.gameobj.scene.scenes[0].homeinventorycontainer_stackitem.dragactivex =
          this.rcvarsservice.gameobj.scene.scenes[0].homeinventorycontainer_stackitem.x;
        this.rcvarsservice.gameobj.scene.scenes[0].homeinventorycontainer_stackitem.dragactivey =
          this.rcvarsservice.gameobj.scene.scenes[0].homeinventorycontainer_stackitem.y;
        this.rcvarsservice.gameobj.scene.scenes[0].homeinventorycontainer_stackitem.dragactivepx =
          pointer.x;
        this.rcvarsservice.gameobj.scene.scenes[0].homeinventorycontainer_stackitem.dragactivepy =
          pointer.y;
      },
      this
    );
    this.rcvarsservice.gameobj.scene.scenes[0].homeinventorycontainer_stackitem.dragactive =
      false;
    this.rcvarsservice.gameobj.scene.scenes[0].homeinventorycontainer_stackitem.dragactivex = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].homeinventorycontainer_stackitem.dragactivey = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].homeinventorycontainer_stackitem.dragactivepx = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].homeinventorycontainer_stackitem.dragactivepy = 0;
    this.shopdragbtn.on(
      'pointermove',
      function (pointer, localX, localY, event) {
        //this.scene.registry.list["thethis"].phm.floorclick(this);
        //this.plog.log('shop pointermove', pointer, localX, localY, event, this);
        //this.showinventory_close(wh);
        if (
          this.rcvarsservice.gameobj.scene.scenes[0]
            .homeinventorycontainer_stackitem.dragactive == true
        ) {
          var tmpx =
            this.rcvarsservice.gameobj.scene.scenes[0]
              .homeinventorycontainer_stackitem.dragactivex;
          var tmpy =
            this.rcvarsservice.gameobj.scene.scenes[0]
              .homeinventorycontainer_stackitem.dragactivey;
          var tmppx =
            this.rcvarsservice.gameobj.scene.scenes[0]
              .homeinventorycontainer_stackitem.dragactivepx;
          var tmppy =
            this.rcvarsservice.gameobj.scene.scenes[0]
              .homeinventorycontainer_stackitem.dragactivepy;
          var movedx = tmppx - pointer.x;
          var movedy = tmppy - pointer.y;
          //this.plog.log('drag', tmppx, pointer.x, movedx);

          this.rcvarsservice.gameobj.scene.scenes[0].homeinventorycontainer_stackitem.x =
            tmpx - movedx;
          this.rcvarsservice.gameobj.scene.scenes[0].homeinventorycontainer_stackitem.y =
            tmpy - movedy;
          //this.rcvarsservice.gameobj.scene.scenes[0].homeinventorycontainer_stackitem.y = pointer.y;
        }
      },
      this
    );
    wh.homeinventorycontainer_stackitem.add(this.shopdragbtn);
    wh.homeinventorycontainer_stackitem.bringToTop(this.shopdragbtn);

    /*
    this.plog.log("dragsize",shopguibg.width, shopguibg.height);
    wh.homeinventorycontainer_stackitem.setSize(shopguibg.width, shopguibg.height);
    wh.homeinventorycontainer_stackitem.setInteractive();
    wh.input.on('mousemove', function(pointer) {
      //check here is a scene has a selected gameObject
      //if(this.selected) { //to be able to detect if user really select something.
          wh.setPosition(pointer.x, pointer.y);
      //}
    }, this);*/

    this.plog.log(
      'homeinventorycontainer_stackitem',
      wh.homeinventorycontainer_stackitem,
      width,
      height
    );
    wh.homeinventorycontainer_stackitem.setPosition(width / 2, height / 2);
    //move
    wh.homeinventorycontainer_stackitem.x =
      width - this.shopguibg.width / 2 + 20;

    let loader = new Phaser.Loader.LoaderPlugin(wh);
    /*
    move to spritestack
    for (var vv in shopdata["stackitem_home"]) {
      var vdat = shopdata["stackitem_home"][vv];
      var itemdat = this.rcvarsservice.allstackitem[vdat.item];
      //this.plog.log("xxxx",itemdat,vdat.item,this.rcvarsservice);
      if (itemdat == undefined) continue;
      loader.image(vdat["item"], this.rcvarsservice.rc_baseurl + "stackitem/" + itemdat["sprite"] + ".png");

      this.plog.log("load shop ", vv, vdat, itemdat);
    }
    */
    loader.once(
      Phaser.Loader.Events.COMPLETE,
      () => {
        this.plog.log('Phaser.Loader.Events.COMPLETE');
        this.plog.log(this);
        this.showinventory_loaded(wh, shopdata);
      },
      this
    );
    loader.start();
  }
  showinventory_loaded(wh, shopdata) {
    this.loadingshow();
    this.firsttimerunfunc();
    this.invendropbtn.visible = true;
    this.no5.visible = true;
    this.no10.visible = true;
    this.plog.log('showinventory_loaded', wh, shopdata);
    //this.rcvarsservice.gameobj.registry.list.gameitf.guiwindowclick(this.rcvarsservice.gameobj.scene.scenes[0].homeinventorycontainer_stackitem);

    var margintop = -90;
    var marginleft = -117;
    var cxpos = 0;
    var crow = 0;
    var i = 0;
    for (var i = 0; i < 300; i++) {
      /*if (this.shopitemlist[i] != undefined) {
        this.shopitemlist[i].visible = false;
        this.shopitemlistp[i].setText("");
        this.shopitemlistp[i].visible = false;
      }*/
    }
    for (var i = 0; i < 300; i++) {
      if (this.shopitemlist[i] != undefined) this.shopitemlist[i].destroy(); // = undefined;
      if (this.shopitemlistp[i] != undefined) this.shopitemlistp[i].destroy(); // = undefined;
      if (this.shopitemlist[i] != undefined) this.shopitemlist[i] = undefined;
      if (this.shopitemlistp[i] != undefined) this.shopitemlistp[i] = undefined;
    }
    this.currentresppage = 1;
    var i = 0;
    for (var vv in shopdata['stackitem_home']) {
      var vdat = shopdata['stackitem_home'][vv];
      var itemdat = this.rcvarsservice.allstackitem[vdat.item];
      this.plog.log('loaded shop ', vv, vdat, itemdat);
      if (itemdat == undefined) continue;
      this.shopitemlist[i] = wh.add.sprite(
        32,
        32,
        'stackitem_tp',
        itemdat['code']
      );
      //var tmp = wh.add.image(32, 32, itemdat["code"]);
      this.shopitemlist[i].displayWidth = 48;
      this.shopitemlist[i].displayHeight = 48;
      wh.homeinventorycontainer_stackitem.add(this.shopitemlist[i]);
      this.shopitemlist[i].setPosition(
        marginleft + cxpos * 59,
        margintop + crow * 59
      );
      this.shopitemlistp[i] = wh.add.text(0, 0, '' + vdat['amnt'], wh.fs["wingui_plus"]);
      wh.homeinventorycontainer_stackitem.add(this.shopitemlistp[i]);
      this.shopitemlistp[i].setPosition(
        this.shopitemlist[i].x - 27,
        this.shopitemlist[i].y + 11
      );
      this.shopitemlist[i].visible = true;
      this.shopitemlistp[i].visible = true;
      this.shopitemlist[i].setInteractive();
      this.shopitemlist[i].removeAllListeners();
      this.shopitemlist[i].name = '' + vdat['item'] + '-' + i;
      this.shopitemlist[i].on(
        'pointerup',
        function (pointer, localX, localY, event) {
          this.scene.plog.log('shop itemclick this', this);
          this.scene.plog.log('shop itemclick', pointer, localX, localY, event);
          this.scene.registry.list.homeinvens.showinventory_itemclick(
            this.scene,
            this
          );
        }
      );
      cxpos++;
      if (cxpos > 4) {
        cxpos = 0;
        crow++;
      }
      i++;
      if (i % 25 == 0) {
        cxpos = 0;
        crow = 0;
      }
    }

    if (i == 0) {
      this.empty.visible = true;
    } else {
      this.empty.visible = false;
    }

    this.plog.log('shop i count', i);
    if (i > 25) {
      //pages

      this.respperpage = 25;
      this.resptotalpage = Math.ceil(i / 25);
    }
    this.shopbackbtn.removeAllListeners();
    this.shopfwdbtn.removeAllListeners();
    this.shopbackbtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        this.plog.log('shop shopbackbtn', this);
        this.showinventory_showpage(wh, 'back');
      },
      this
    );

    this.shopfwdbtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        this.plog.log('shop shopfwdbtn', this);
        this.showinventory_showpage(wh, 'next');
      },
      this
    );

    this.showinventory_showpage(wh, 'back');
    this.loadinghide();
    this.invendropbtn.removeAllListeners();
    this.invendropbtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        this.plog.log('shop invendropbtn', this);
        this.showinventory_ret(this, 1);
      },
      this
    );
    this.no5.removeAllListeners();
    this.no10.removeAllListeners();
    this.no5.setInteractive();
    this.no10.setInteractive();

    this.no5.on(
      'pointerup',
      async function () {
        //this.plog.log(this);
        if (this.selecteditemno == '' || this.selecteditemno == undefined)
          return;
        var tmpid =
          this.rcvarsservice.activechar['stackitem_home'][this.selecteditemno][
          'id'
          ];
        var tmpamnt =
          this.rcvarsservice.activechar['stackitem_home'][this.selecteditemno][
          'amnt'
          ];
        var tmpitem =
          this.rcvarsservice.activechar['stackitem_home'][this.selecteditemno][
          'item'
          ];
        var tmpsellprice =
          this.rcvarsservice.activechar['stackitem_home'][this.selecteditemno][
          'sellprice'
          ];
        var iteminfo2 = this.rcvarsservice.allstackitem[tmpitem];

        var amnt =
          await this.rcvarsservice.gameobj.registry.list.rctoast.rcenternum(
            this.rcvarsservice.gameobj.registry.list.phaserGame.scene.scenes[0],
            this.wh.trs("f:homeinventorycontainer:Confirm Retrieve===Confirm Retrieve"),
            'Retrieving ' + iteminfo2['name'] + '\n'+this.wh.trs("f:homeinventorycontainer:How many to retrieve?===How many to retrieve?")
          );
        amnt = Number(amnt);
        if (amnt < 1) return;
        this.showinventory_ret(this, amnt);
        // var confirm = await this.rcvarsservice.gameobj.registry.list.rctoast.rcconfirm(wh.rcvarsservice.gameobj.registry.list.phaserGame.scene.scenes[0],"Confirm Sell", "Sell " + amnt + " of:\n" + iteminfo2["name"] + " for " + (tmpsellprice * amnt) + " gold");
      },
      this
    );
    this.no10.on(
      'pointerup',
      function () {
        this.showinventory_ret(this, 10);
      },
      this
    );

    /*this.no5.setInteractive();
    this.no10.setInteractive();
    this.no5.removeAllListeners();
    this.no10.removeAllListeners();*/
    this.no5.on('pointerover', function () {
      wh.registry.list.gameitf.gamemenutooltip(
        wh,
        this,
        wh.trs("f:homeinventorycontainer:Retrieve selected item===Retrieve selected item"),
        'right'
      );
    });
    this.no10.on('pointerover', function () {
      wh.registry.list.gameitf.gamemenutooltip(
        wh,
        this,
        wh.trs("f:homeinventorycontainer:Retrieve selected item===Retrieve selected item"),
        'right'
      );
    });
  }

  async showinventory_ret(wh, amnt) {
    this.plog.log('showinventory_ret', wh, amnt);
    if (this.selecteditemno == '') return;
    if (
      wh.rcvarsservice.activechar['stackitem_home'][this.selecteditemno] ==
      undefined
    ) {
      this.plog.log(
        'showinventory_sell skip dropped',
        this.selecteditemno,
        wh.rcvarsservice.activechar['stackitem_home'][this.selecteditemno]
      );
      return; //dropped
    }
    var tmpid =
      wh.rcvarsservice.activechar['stackitem_home'][this.selecteditemno]['id'];
    var tmpamnt =
      wh.rcvarsservice.activechar['stackitem_home'][this.selecteditemno][
      'amnt'
      ];
    var tmpitem =
      wh.rcvarsservice.activechar['stackitem_home'][this.selecteditemno][
      'item'
      ];
    var tmpsellprice =
      wh.rcvarsservice.activechar['stackitem_home'][this.selecteditemno][
      'sellprice'
      ];
    var iteminfo2 = wh.rcvarsservice.allstackitem[tmpitem];
    this.loadingshow();
    if (amnt != 1) {
      var confirm =
        await this.rcvarsservice.gameobj.registry.list.rctoast.rcconfirm(
          wh.rcvarsservice.gameobj.registry.list.phaserGame.scene.scenes[0],
          this.wh.trs("f:homeinventorycontainer:Confirm Retrieve===Confirm Retrieve"),
          'Retriving ' + amnt + ' ' + iteminfo2['name'] + ' ?'
        );

      this.plog.log('rcconfirmresult', confirm);
      //var confirm = await this.rcvarsservice.gameobj.registry.list.rctoast.confirm("Confirm Sell", "Sell " + amnt + " of:\n" + iteminfo2["name"] + " for " + (tmpsellprice * amnt) + " gold");
      if (confirm != 'ok') {
        this.loadinghide();
        return;
      }
    }
    this.plog.log('showinventory_ret', wh.rcvarsservice.activechar);
    var tmpid =
      wh.rcvarsservice.activechar['stackitem_home'][this.selecteditemno]['id'];
    var tmpamnt =
      wh.rcvarsservice.activechar['stackitem_home'][this.selecteditemno][
      'amnt'
      ];
    var res = await this.rchttp.retrievestackitem(tmpid, amnt);
    await this.rcvarsservice.setactivechar(
      res,
      wh.rcvarsservice.activechar['id']
    );
    this.plog.log('showinventory_ret res', res, wh.rcvarsservice);
    await this.rcvarsservice.gameobj.registry.list.gameitf.charinfoupdated(
      this.rcvarsservice.gameobj.scene.scenes[0]
    );

    if (
      wh.rcvarsservice.activechar['stackitem_home'][this.selecteditemno] !=
      undefined
    ) {
      this.plog.log('select same item since it still exists after drop');
      this.rcvarsservice.gameobj.registry.list.stackinven.selecteditemno =
        this.selecteditemno;
      this.selectcircle.visible = true;
    } else {
      this.rcvarsservice.gameobj.registry.list.homeinvens.selecteditemno = '';
      this.selectcircle.visible = false;
    }
    this.loadinghide();
    this.showinventory_showpage(wh, 'retainpage');
    /*if (tmpamnt <= 1) {
      this.selecteditemno = "";
    }*/
  }
  showinventory_itemclick(wh, item) {
    this.selectcircle.visible = true;
    var namea = item.name.split('-');
    wh.registry.list.homeinvens.selecteditemno = namea[1];
    this.plog.log('showinventory_itemclick', wh, item);
    this.plog.log(
      'showinventory_itemclick shopitemlist',
      namea,
      this.shopitemlist
    );
    this.selectcircle.displayWidth = 52;
    this.selectcircle.displayHeight = 52;
    this.selectcircle.setPosition(item.x, item.y);
    //wh.homeinventorycontainer_stackitem.sendToBack(wh.shopguibg);
    wh.homeinventorycontainer_stackitem.bringToTop(this.selectcircle);
    wh.homeinventorycontainer_stackitem.bringToTop(this.shopitemlist[namea[1]]);
    wh.homeinventorycontainer_stackitem.bringToTop(
      this.shopitemlistp[namea[1]]
    );
    this.plog.log(
      'showinventory_itemclick selectcircle',
      namea,
      item,
      wh.registry.list.rcvarpass.activechar
    );
    var iteminfo =
      wh.registry.list.rcvarpass.activechar['stackitem_home'][namea[1]];
    var iteminfo2 = wh.registry.list.rcvarpass.allstackitem[namea[0]];

    //this.selectcircle.width=
    if (this.invenmode == 'sell') {
      this.itemdescr.setText(
        iteminfo2['name'] +
        ' x' +
        iteminfo['amnt'] +
        ' sell 1 for ' +
        iteminfo['sellprice'] +
        ' gold'
      );
    } else {
      this.itemdescr.setText(
        iteminfo2['name'] + ' x' + iteminfo['amnt'] + ' click Drop to DROP ALL'
      );
    }
  }
  async showinventory_close(wh) {
    wh.homeinventorycontainer_stackitem.visible = false;
    await wh.registry.list.gameitf.closegui(wh, wh.homeinventorycontainer_stackitem);

    for (const k in this.wh.homeinventorycontainer_stackitem.list) {
      this.plog.log(k,this.wh.homeinventorycontainer_stackitem.list[k])
      try {
        this.wh.homeinventorycontainer_stackitem.list[k].visible=false;
        await this.wh.homeinventorycontainer_stackitem.remove(this.wh.homeinventorycontainer_stackitem.list[k], true);
        //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list[k].destroy();
      } catch (e) {
        this.plog.log("ERROR! homeinventorycontainer_stackitem.destroy() ", e);
      }
    }
    for (const k in this.wh.homeinventorycontainer_stackitem.list) {
      try {
        await this.wh.homeinventorycontainer_stackitem.remove(this.wh.homeinventorycontainer_stackitem.list[k]);
      } catch (e) {
        this.plog.log("ERROR! homeinventorycontainer_stackitem.destroy() ", e);
      }
    }
    try {
      await this.wh.homeinventorycontainer_stackitem.destroy(true);
    } catch (e) {
      this.plog.log("ERROR! homeinventorycontainer_stackitem.destroy() ", e);
    }
    wh.homeinventorycontainer_stackitem = undefined;
  }
  showinventory_showpage(gc, viewpage) {
    this.selectcircle.visible = false;
    if (viewpage == 'back') {
      if (this.currentresppage > 1) this.currentresppage--;
    }
    if (viewpage == 'next') {
      if (this.currentresppage < this.resptotalpage) this.currentresppage++;
    }
    for (var i = 0; i <= 100; i++) {
      if (this.shopitemlist[i] != undefined) {
        this.shopitemlist[i].visible = false;
      }
      if (this.shopitemlistp[i] != undefined) {
        this.shopitemlistp[i].visible = false;
      }
    }
    var numfrom = this.respperpage * (this.currentresppage - 1);
    var numto = this.respperpage * this.currentresppage;
    this.plog.log(
      'showinventory_showpage',
      viewpage,
      this.currentresppage,
      'fromto',
      numfrom,
      numto,
      'perpage',
      this.respperpage
    );

    var griddat = [];
    var griddatf = [];

    griddat.push(this.shopclosebtn);
    griddat.push(this.invendropbtn);

    griddat.push(this.shopfwdbtn);
    griddat.push(this.shopbackbtn);
    for (var i = numfrom; i < numto; i++) {
      if (this.shopitemlist[i] != undefined) {
        this.shopitemlist[i].visible = true;
        griddat.push(this.shopitemlist[i]);
        griddatf.push(this.shopitemlist[i]);
      }
      if (this.shopitemlistp[i] != undefined) {
        this.shopitemlistp[i].visible = true;
      }
    }
    this.wh.registry.list.gp.guigrid(griddat, griddatf);
  }

  rebuildguigrid() {
    this.plog.log("rebuildguigrid();b", this.wh.kbfocus);
    this.showinventory_showpage(null, "");
    this.plog.log("rebuildguigrid();a", this.wh.kbfocus);
  }
}
