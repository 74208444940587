import { Injectable } from '@angular/core';
declare var gtag;

@Injectable({
  providedIn: 'root',
})
export class GAService {
  constructor() {}
  se(eventname) {
    //ga('send', 'se_'+eventname);
    try {
      gtag('event', 'overlayview', {'page': eventname});
    } catch (error) {
      console.log("GA ERROR",error);
    }
  }
  se2(catename,eventname) {
    //ga('send', 'se_'+eventname);
    try {
      gtag('event', catename, {'page': eventname});
    } catch (error) {
      console.log("GA ERROR",error);
    }
  }
}
