import { Injectable } from '@angular/core';
import { RCSVVARSService } from './rcsvvars.service';
import { RCHTTP } from './rchttp.service';
import { RCVarsService } from './rcvars-service.service';
@Injectable({
  providedIn: 'root',
})
export class RCUpdateITFService {
  constructor(
    private rcsvvar: RCSVVARSService,
    private rchttp: RCHTTP,
    private rcvarsservice: RCVarsService
  ) {}

  hide_ifbtns(wh) {
    wh.itf_selectchar = false;
    var blocks;
    blocks = document.getElementsByClassName('selectcharacter-box');

    for (var i = 0; i <= 10; i++) {
      if (blocks[i] != undefined) blocks[i].style.visibility = 'hidden';
    }
  }
  show_ifbtns(wh) {
    wh.itf_selectchar = true;
  }
  async recreate_selectcharacter(mainpage) {
    var charinfo;
    charinfo = this.rcvarsservice.allchars; //await this.rchttp.getcharacters();
    console.log('recreate_selectcharacter', this.rcvarsservice);
    //console.log("recreate_selectcharacter this",this.rcvarsservice.gameobj.registry.list.thethis);
    //this.rcvarsservice.gameobj.registry.list.thethis.selectcharimg0
    console.log('allchars', this.rcvarsservice.allchars);
    this.rcvarsservice.charinfo = charinfo['data'];
    //console.log("chardb1", charinfo["data"]);
    //console.log("chardb", this.rcvarsservice.charinfo);
    //for (var i=0;i<=10;i++)this.rcvarsservice.rcgame.selectcharimg[i]="xxxx"+i;
    var charinforaw = [];
    var count = 0;
    for (var vv in charinfo['data']) {
      var vdat = charinfo['data'][vv];
      var sprite = charinfo['data'][vv]['sprite'];
      var genderu = 'male';
      if (vdat['gender'] == 'F') genderu = 'female';
      vdat['spriteuse'] =
        this.rcvarsservice.rc_baseurl +
        'raceicon/' +
        vdat['race'] +
        '-' +
        genderu +
        '-none-walk-d.gif';
      //console.log("recreate_selectcharacter", vv, sprite);
      if (sprite == '1-male-') vdat['sprite'] = sprite + 'none';
      if (sprite == '1-female-') vdat['sprite'] = sprite + 'none';
      if (sprite == '2-male-') vdat['sprite'] = sprite + 'none';
      if (sprite == '2-female-') vdat['sprite'] = sprite + 'none';
      if (sprite == '3-male-') vdat['sprite'] = sprite + 'none';
      if (sprite == '3-female-') vdat['sprite'] = sprite + 'none';
      if (sprite == '4-male-') vdat['sprite'] = sprite + 'none';
      if (sprite == '4-female-') vdat['sprite'] = sprite + 'none';
      if (sprite == '5-male-') vdat['sprite'] = sprite + 'none';
      if (sprite == '5-female-') vdat['sprite'] = sprite + 'none';
      //console.log("recreate_selectcharacter vdat", vdat);
      //console.log("this.rcvarsservice",this.rcvarsservice);
      this.rcvarsservice.rcgame.selectcharimg[count] = vdat['spriteuse'];
      charinforaw.push(vdat);
      count++;
    }
    //console.log("charinforaw", charinforaw);
    var blocks;
    blocks = document.getElementsByClassName('selectcharacter-box');

    var selectchar_topinfo = document.getElementById('selectchar_topinfo');
    selectchar_topinfo.innerHTML =
      'Character Slot: ' +
      charinforaw.length +
      '/' +
      this.rcvarsservice.gamer['charslot'];
    //console.log("blocks", blocks);
    var shownblank = false;
    for (var i = 0; i <= 10; i++) {
      //if (i>this.rcvarsservice.gamer["charslot"]) continue;
      /*if (charinforaw[i] == undefined) {
        var exsdiv = document.getElementById("selectcharacter-action-exists" + i);
        if (exsdiv != undefined) exsdiv.style.visibility = 'hidden';
        var nexsdiv = document.getElementById("selectcharacter-action-blank" + i);
        if (nexsdiv != undefined) nexsdiv.style.visibility = 'hidden';
        var statdiv = document.getElementById("selectcharacter-status" + i);
        if (statdiv != undefined)  statdiv.innerHTML = ' ';

        //if (blocks[i] != undefined) blocks[i].style.visibility = 'hidden';
        //continue;
      }*/
      //console.log("ixxx" + i, blocks[i], charinforaw[i]);
      //console.log("c" + i, this.rcvarsservice.charinfo[i]);
      if (charinforaw[i] == undefined) {
        //console.log("undefined shownblank " + i, charinforaw[i]);
        //console.log('sssssssssssssss', i, this.rcvarsservice.gamer['charslot']);
        if (shownblank == false) {
          shownblank = true;
          var exsdiv = document.getElementById(
            'selectcharacter-action-exists' + i
          );
          if (exsdiv != undefined) {
            exsdiv.style.visibility = 'hidden';
          } else {
            console.warn('ssssssssssssssserror', i, "exsdiv not exists");
          }
          var nexsdiv = document.getElementById(
            'selectcharacter-action-blank' + i
          );
          if (nexsdiv != undefined) {
            nexsdiv.style.visibility = 'visible';
          } else {
            console.warn('ssssssssssssssserror', i, "nexsdiv not exists");
          }
          var statdiv = document.getElementById('selectcharacter-status' + i);
          if (statdiv != undefined) {
            statdiv.innerHTML = ' ';
          } else {
            console.warn('ssssssssssssssserror', i, "statdiv not exists");
          }
        } else {
          if (blocks[i] != undefined) {
            blocks[i].style.visibility = 'hidden';
          } else {
            console.warn('ssssssssssssssserror', i, "blocks[i] not exists");
          }
          var exsdiv = document.getElementById(
            'selectcharacter-action-exists' + i
          );
          if (exsdiv != undefined) {
            exsdiv.style.visibility = 'hidden';
          } else {
            console.warn('ssssssssssssssserror', i, "exsdiv not exists");
          }
          var nexsdiv = document.getElementById(
            'selectcharacter-action-blank' + i
          );
          if (nexsdiv != undefined) {
            nexsdiv.style.visibility = 'hidden';
          } else {
            console.warn('ssssssssssssssserror', i, "nexsdiv not exists");
          }
        }
        if (i >= this.rcvarsservice.gamer['charslot']) {
          shownblank = true;
          var exsdiv = document.getElementById(
            'selectcharacter-action-exists' + i
          );
          if (exsdiv != undefined) exsdiv.style.visibility = 'hidden';
          var nexsdiv = document.getElementById(
            'selectcharacter-action-blank' + i
          );
          if (nexsdiv != undefined) nexsdiv.style.visibility = 'hidden';
          var statdiv = document.getElementById('selectcharacter-status' + i);
          if (statdiv != undefined) statdiv.innerHTML = ' ';
          //var imgi = document.getElementById('selectcharacter-img' + i);
          //if (imgi != undefined) imgi.style.visibility = 'hidden';
          blocks[i].style.visibility='hidden';
          
        }
        //blocks[i].style.visibility='hidden';
      } else {
        ///console.log("ssssssssssxxxfillinginfo",charinforaw[i]);
        var exsdiv = document.getElementById(
          'selectcharacter-action-exists' + i
        );
        exsdiv.style.visibility = 'visible';
        if (charinforaw[i]['canplay'] != 'yes') {
          exsdiv.style.visibility = 'hidden';
          exsdiv.style.height = '0px';
          //exsdiv.visible = false;
        }
        var nexsdiv = document.getElementById(
          'selectcharacter-action-blank' + i
        );
        nexsdiv.style.visibility = 'hidden';
        var statdiv = document.getElementById('selectcharacter-status' + i);
        statdiv.innerHTML = ' <b>' + charinforaw[i].name + '</b>';
        statdiv.innerHTML +=
          ' <BR>' +
          this.rcsvvar.getrace(charinforaw[i].race) +
          '<BR>lv.' +
          charinforaw[i].level;
        if (blocks[i] != undefined) blocks[i].style.visibility = 'visible';
        var imgobj = document.getElementById('selectcharacter-img' + i);
        //console.log("imgobj",imgobj);
        //imgobj.src=this.rcvarsservice.
      }
    }

    var linked_sslink = document.getElementById('linked_sslink');
    var linked_sslinkstr = '';
    for (const k in this.rcvarsservice.gamer['sslink']) {
      var v = this.rcvarsservice.gamer['sslink'][k];
      console.log('linked_sslinkstr', v);
      linked_sslinkstr = linked_sslinkstr + ' ';

      if (v['provider'] == 'google.com') {
        linked_sslinkstr =
          linked_sslinkstr +
          '   <ion-icon name="logo-google"></ion-icon> ';
      }
      if (v['provider'] == 'facebook.com') {
        linked_sslinkstr =
          linked_sslinkstr +
          '   <ion-icon name="logo-facebook"></ion-icon>          ';
      }
      if (v['provider'] == 'twitter.com') {
        linked_sslinkstr =
          linked_sslinkstr +
          '   <ion-icon name="logo-twitter"></ion-icon>          ';
      }
      linked_sslinkstr =
        linked_sslinkstr +
        ' ' +
        v['data']['displayName'] +
        ' (' +
        v['data']['email'] +
        ')     <ion-button size="small" color="warning" id="unlinkaccountbtn'+v["id"]+'">  <ion-icon name="close-circle" size="small"></ion-icon>        </ion-button><BR>';
    }
    if (linked_sslinkstr != '') {
      linked_sslinkstr = '          <div class="itfbar">'+
      '<img src="../assets/interface/itfbar-linkedaccounts.png">'+
    '</div>' + linked_sslinkstr;
    }
    linked_sslink.innerHTML = linked_sslinkstr;
    console.log('linked_sslink', linked_sslink, linked_sslinkstr);
    
    for (const k in this.rcvarsservice.gamer['sslink']) {
      var v = this.rcvarsservice.gamer['sslink'][k];
      var o=document.getElementById('unlinkaccountbtn'+v["id"]);
      o.addEventListener('click',($event)=>{
        console.log("xxxxxxxx",this,$event);
        this.rcvarsservice.gameobj.registry.list.thethis.unlinksocialaccount($event.target);
        // run your angular code here with goto
      });
    }
  }
}
