import { Injectable } from '@angular/core';
import { RCVarsService } from './rcvars-service.service';
import { GameInterfaceService } from './game-interface.service';
import { RCHTTP } from './rchttp.service';
import { plog } from './plog.service';

@Injectable({
  providedIn: 'root'
})
export class PickRewardService {
  selecteditemno = "";
  selecteditemcode = "";
  currentresppage = 1;
  respperpage = 15;
  resptotalpage;
  selectcircle;
  itemdescr;
  shopitemlist = [];
  shopitemlistp = [];
  shopfwdbtn;
  shopbackbtn;
  selectbtn;
  rewardlist = [];
  shopclosebtn;
  bgcover;
  shopguibg;
  wh;
  cbfuncparam;
  chestid="";
  plog;

  constructor(
    public rcvarsservice: RCVarsService,
    public rchttp: RCHTTP,
    public gameitf: GameInterfaceService
  ) { 
    this.plog=new plog();
  }

  async show(wh, cbfunc, chestid, rewardlist, itemdescrtxt = "Select Item") {
    this.rewardlist = rewardlist;
    this.chestid = chestid;
    this.wh = wh;
    this.cbfuncparam = cbfunc;
    itemdescrtxt=this.wh.gl(itemdescrtxt);
    await this.wh.registry.list.rchttp.updatecharinfo();

    for (var i = 0; i < 100; i++) {
      if (this.shopitemlist[i] != undefined)
        this.shopitemlist[i].destroy();// = undefined;
      if (this.shopitemlistp[i] != undefined)
        this.shopitemlistp[i].destroy();// = undefined;
    }
    if (wh.pickrewardcontainer != undefined) {
      this.plog.log("pickrewardcontainer", wh.pickrewardcontainer);
      wh.pickrewardcontainer.destroy();//true
      //return;
    }
    var width = wh.game.canvas.width;
    var height = wh.game.canvas.height;
    this.plog.log("show", wh, this.rewardlist);

    const selectcircle = wh.add.sprite(0, 0, "selectcircle").play("selected_b");
    //const selectcircle = wh.add.image(0, 0, "selectcircle");
    selectcircle.setOrigin(0.5, 0.5);
    selectcircle.visible = false;
    selectcircle.setAlpha(0.7);
    const shopguibg = wh.add.image(0, 0, "pickitemguibg");
    shopguibg.setOrigin(0.5, 0.5);
    shopguibg.setInteractive({ pixelPerfect: true });
    shopguibg.removeAllListeners();
    this.shopguibg = shopguibg;
    const shopbackbtn = wh.add.sprite(0, 0, "thesb", "backsign");//wh.add.image(0, 0, "listbackbtn");
    shopbackbtn.setOrigin(0.5, 0.5);
    shopbackbtn.setInteractive();
    shopbackbtn.removeAllListeners();
    const shopfwdbtn = wh.add.sprite(0, 0, "thesb", "forwardsign");//wh.add.image(0, 0, "listfwdbtn");
    shopfwdbtn.setOrigin(0.5, 0.5);
    shopfwdbtn.setInteractive();
    shopfwdbtn.removeAllListeners();
    const selectbtn = wh.add.sprite(0, 0, "thesb", "select");//wh.add.image(0, 0, "selectbtn");
    selectbtn.setOrigin(0.5, 0.5);
    selectbtn.setInteractive();
    selectbtn.removeAllListeners();
    this.plog.log("show shopdata", rewardlist);

    //this.plog.log("show var", this.rcvarsservice);
    this.shopfwdbtn = shopfwdbtn;
    this.shopbackbtn = shopbackbtn;
    this.selectcircle = selectcircle;
    this.selectbtn = selectbtn;

    var shopclosebtn = wh.add.sprite(300, 300, "thesq",'close');
    this.shopclosebtn = shopclosebtn;
    this.shopclosebtn.setOrigin(0.5, 0.5);
    this.shopclosebtn.setPosition(165, -145);
    shopclosebtn.displayWidth = 40;
    shopclosebtn.displayHeight = 40;
    this.shopclosebtn.setInteractive();
    this.shopclosebtn.removeAllListeners();
    this.shopclosebtn.on('pointerup', function (pointer, localX, localY, event) {
      //this.scene.registry.list["thethis"].phm.floorclick(this);
      this.plog.log("gc.npcbackbtn pointerup", pointer, localX, localY, event, this);
      this.show_close(wh);
    }, this);

    wh.pickrewardcontainer = wh.add.container(300, 300);

    wh.pickrewardcontainer.add(this.shopclosebtn);
    wh.pickrewardcontainer.add(selectcircle);
    wh.pickrewardcontainer.add(shopguibg);
    wh.pickrewardcontainer.add(shopbackbtn);
    wh.pickrewardcontainer.add(shopfwdbtn);
    wh.pickrewardcontainer.add(selectbtn);


    this.bgcover = wh.add.rectangle(0, 0, width, height, 0xffffff);
    wh.pickrewardcontainer.add(this.bgcover);
    wh.pickrewardcontainer.sendToBack(this.bgcover);
    this.bgcover.setAlpha(0.7);
    this.bgcover.setOrigin(0.5, 0.5);
    this.bgcover.setPosition(0, 0);
    this.bgcover.setAlpha(0.5);
    this.bgcover.visible = true;
    this.bgcover.setInteractive();
    this.bgcover.removeAllListeners();


    shopbackbtn.displayWidth = 100;
    shopfwdbtn.displayWidth = 100;
    selectbtn.displayWidth = 100;
    shopbackbtn.displayHeight = 40;
    shopfwdbtn.displayHeight = 40;
    selectbtn.displayHeight = 40;
    shopbackbtn.setPosition(-110, 150);
    shopfwdbtn.setPosition(110, 150);
    selectbtn.setPosition(0, 150);

    wh.pickrewardcontainer.bringToTop(this.shopclosebtn);
    var itemdescr = wh.add.text(0, 0, "" + itemdescrtxt, wh.fs["winguibardescr"]);
    wh.pickrewardcontainer.add(itemdescr);
    itemdescr.setOrigin(0, 0);
    itemdescr.setPosition(-140, -115);
    this.itemdescr = itemdescr;

    //this.plog.log("pickrewardcontainer", wh.pickrewardcontainer, width, height);
    wh.pickrewardcontainer.setPosition((width / 2), (height / 2));
    //move
    //wh.pickrewardcontainer.x = width - (this.shopguibg.width / 2);

    /////////////////////////////////////////////////////////////////////////
    
    this.show_loaded();
  }
  show_loaded() {
    var wh = this.wh;
    var shopdata = this.rewardlist;
    this.selectbtn.visible = true;

    this.plog.log("show_loaded", wh, shopdata);
    this.rcvarsservice.gameobj.registry.list.gameitf.guiwindowclick(this.rcvarsservice.gameobj.scene.scenes[0].pickrewardcontainer);

    var margintop = -32;
    var marginleft = -117;
    var cxpos = 0;
    var crow = 0;
    var i = 0;
    for (var i = 0; i < 100; i++) {
      if (this.shopitemlist[i] != undefined) {
        this.plog.log("shopitemlist", this.shopitemlist[i]);
        this.shopitemlist[i].visible = false;
        //this.shopitemlistp[i].setText("");
        this.shopitemlistp[i].visible = false;
      }
    }

    for (var i = 0; i < 100; i++) {
      if (this.shopitemlist[i] != undefined)
        this.shopitemlist[i].destroy();// = undefined;
      if (this.shopitemlistp[i] != undefined)
        this.shopitemlistp[i].destroy();// = undefined;
    }

    this.currentresppage = 1;

    var i = 0;
    for (var vv in shopdata) {
      var vdat = shopdata[vv];
      var itemdat = this.rcvarsservice.allstackitem[vdat.item];
      this.plog.log("loaded rewardlist ", vv, vdat, itemdat);
      var tmp;
      var tmpp ;
      var vdata=vdat.split(":");
      if (vdata[0] == "item") {
        tmp = wh.add.sprite(32, 32, "item_tp", vdata[1]);
        tmpp = wh.add.text(0, 0, "+" + vdata[2], wh.fs["wingui_amnt"]);
      } else {
        
        tmp = wh.add.sprite(32, 32, "stackitem_tp", vdata[1]);
        tmpp = wh.add.text(0, 0, "" + vdata[2], wh.fs["wingui_amnt"]);
      }
      //var tmp = wh.add.image(32, 32, itemdat["code"]);
      tmp.displayWidth = 48;
      tmp.displayHeight = 48;
      wh.pickrewardcontainer.add(tmp);
      tmp.setPosition(marginleft + (cxpos * 59), margintop + (crow * 59));
      wh.pickrewardcontainer.add(tmpp);
      tmpp.setPosition(tmp.x - 27, tmp.y + 11);
      this.shopitemlist[i] = tmp;
      this.shopitemlistp[i] = tmpp;
      this.shopitemlist[i].visible = true;
      this.shopitemlistp[i].visible = true;
      this.shopitemlist[i].setInteractive();
      this.shopitemlist[i].removeAllListeners();
      this.shopitemlist[i].name = "" + vdata[0] + "-" + vdat+"-"+this.chestid;//i;
      this.shopitemlist[i].on('pointerup', function (pointer, localX, localY, event) {
        this.scene.plog.log("shop itemclick this", this);
        this.scene.plog.log("shop itemclick", pointer, localX, localY, event);
        this.scene.registry.list.pickreward.show_itemclick(this.scene, this);
      });
      cxpos++;
      if (cxpos > 4) {
        cxpos = 0;
        crow++;
      }
      i++;
      if (i % 15 == 0) {
        cxpos = 0;
        crow = 0;
      }
    }
    this.plog.log("shop i count", i);
    if (i > 15) { //pages

      this.respperpage = 15;
      this.resptotalpage = Math.ceil(i / 15);
    }
    this.shopbackbtn.removeAllListeners();
    this.shopfwdbtn.removeAllListeners();
    this.shopbackbtn.on('pointerup', function (pointer, localX, localY, event) {
      this.plog.log("shop shopbackbtn", this);
      this.show_showpage(wh, "back");
    }, this);

    this.shopfwdbtn.on('pointerup', function (pointer, localX, localY, event) {
      this.plog.log("shop shopfwdbtn", this);
      this.show_showpage(wh, "next");
    }, this);

    this.show_showpage(wh, "current");

    this.selectbtn.removeAllListeners();
    this.selectbtn.on('pointerup', function (pointer, localX, localY, event) {
      this.plog.log("shop selectbtn", this);
      this.show_drop(this.wh);
    }, this);

  }


  async show_drop(wh) {
    this.plog.log("show_drop", wh, this.selecteditemno,this.selecteditemcode);
    if (this.selecteditemcode == "") return;

    this.cbfuncparam(this.selecteditemno,this.chestid);
    this.show_close(wh);
  }


  show_itemclick(wh, item) {
    this.plog.log("show_itemclick", this,wh, item);
    this.selectcircle.visible = true;
    var namea = item.name.split("-");
    var namea2 = namea[1].split(":");

    this.plog.log("show_itemclick shopitemlist", namea, this.shopitemlist);
    wh.registry.list.pickreward.selecteditemno = namea[1];
    wh.registry.list.pickreward.selecteditemcode = namea[0];
    
    this.selectcircle.displayWidth = 52;
    this.selectcircle.displayHeight = 52;
    this.selectcircle.setPosition(item.x, item.y)
    //wh.pickrewardcontainer.sendToBack(wh.shopguibg);
    wh.pickrewardcontainer.bringToTop(this.selectcircle);
    wh.pickrewardcontainer.bringToTop(this.shopitemlist[namea[1]]);
    wh.pickrewardcontainer.bringToTop(this.shopitemlistp[namea[1]]);
    this.plog.log("show_itemclick selectcircle", namea, item, wh.registry.list.rcvarpass.activechar);
    if (namea[0]=="item") {
      var iteminfo2 = wh.registry.list.rcvarpass.allitem[namea2[1]];
    this.plog.log("show_itemclick selectcircle", namea, iteminfo2);
    this.wh.registry.list.rcosd.show(wh, "itemnoeq", iteminfo2,item);
    } else {
      var iteminfo2 = wh.registry.list.rcvarpass.allstackitem[namea2[1]];
      this.plog.log("show_itemclick selectcircle", namea, iteminfo2);
      this.wh.registry.list.rcosd.show(wh, "itemnoeq", iteminfo2, item);

    }

    //this.selectcircle.width=
    //this.plog.log("xxxx", iteminfo2);



  }
  show_close(wh) {
    //wh.pickrewardcontainer.visible = false;
    //wh.registry.list.gameitf.closegui(wh,wh.pickrewardcontainer);
    //wh.pickrewardcontainer.destroy();//true

    var tw1 = this.wh.tweens.add({
      targets: this.wh.pickrewardcontainer,
      alpha: 0.0,
      ease: 'Power3',
      duration: 495, // duration of animation; higher=slower
      loop: 0,
      yoyo: false,
      onComplete: function () {
        wh.pickrewardcontainer.destroy();
      }.bind(this)
    });
  }
  show_showpage(gc, viewpage) {
    this.selectcircle.visible = false;
    if (viewpage == "back") {
      if (this.currentresppage > 1) this.currentresppage--;
    }
    if (viewpage == "next") {
      if (this.currentresppage < this.resptotalpage) this.currentresppage++;
    }
    for (var i = 0; i <= 100; i++) {
      //this.plog.log(this.shopitemlist[i]);
      if (this.shopitemlist[i] != undefined) {
        this.shopitemlist[i].visible = false;
      }
      if (this.shopitemlistp[i] != undefined) {
        this.shopitemlistp[i].visible = false;
      }
    }
    var numfrom = this.respperpage * (this.currentresppage - 1);
    var numto = this.respperpage * (this.currentresppage);
    this.plog.log("show_showpage", viewpage, this.currentresppage, "fromto", numfrom, numto, "perpage", this.respperpage);

    for (var i = numfrom; i < numto; i++) {
      if (this.shopitemlist[i] != undefined) {
        this.shopitemlist[i].visible = true;
        this.shopitemlistp[i].visible = true;
        var namea = this.shopitemlist[i].name.split("-");
        var isnewdata = this.wh.registry.list.rcvarpass.activechar["stackitem"][namea[1]];
        //this.plog.log("xxx1", isnewdata);
      }
    }

  }

}
