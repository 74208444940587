import { Injectable } from '@angular/core';
import { plog } from './plog.service';

@Injectable({
  providedIn: 'root',
})
export class RCCharInfoService {
  archdb1 = [];
  archdb2 = [];
  archdb3 = [];
  archdb4 = [];
  archdb5 = [];

  isbadint = "no";
  svtimeinfo;
  logtextobj = [];
  showcombatlogtext_pagei = 0;
  showcombatlogtext_pageitotalpage = 0;
  showcombatlogtext_pageiperpage = 1;
  currentresppage = 1;
  respperpage = 25;
  resptotalpage = 1;
  resptotalrecords = 0;

  viewingbattlelogid;
  viewingbattlelogtoken;
  loaded = false;
  wh;
  bg;
  backbtn;
  backbtn2; //upperright
  combatdb;
  combatlogobj = [];
  bgmain;
  bgmain2;
  empty;
  submn;
  subfilteritem = 'sword';
  subfilterstackitem = 'questitems';
  backbtns = [];
  fwdbtns = [];
  plog;

  constructor() {
    this.plog = new plog();
  }

  async show(wh) {
    this.wh = wh;
    this.wh.registry.list.rcloading.loading2(this.wh);

    if (this.wh.charinfocontainer == undefined) {
      this.wh.charinfocontainer = this.wh.add.container(0, 0);
      this.wh.charinfocontainer.setDepth(1500000);
    }
    if (this.wh.charinfocontainer != undefined) {
      this.wh.charinfocontainer.visible = true;
    }
    this.isbadint = await this.wh.registry.list.rcstorage.get("setting_badint", true);

    if (this.loaded == true) {
      this.show_loaded();
      return;
    }
    var width = wh.game.canvas.width;
    var height = wh.game.canvas.height;
    this.plog.log('charinfo show()', this);

    this.bgmain = this.wh.add.rectangle(0, height / 2, 70, height, 0xe6ab07);
    this.bgmain.setOrigin(0, 0.5);
    this.wh.charinfocontainer.add(this.bgmain);
    this.wh.charinfocontainer.sendToBack(this.bgmain);
    this.bgmain2 = this.wh.add.rectangle(0, height / 2, 140, height, 0xffd970);
    this.bgmain2.setOrigin(0, 0.5);
    this.bgmain2.visible = false;
    this.wh.charinfocontainer.add(this.bgmain2);
    this.wh.charinfocontainer.sendToBack(this.bgmain2);

    this.bg = this.wh.add.rectangle(
      width / 2,
      height / 2,
      width,
      height,
      0xfff5d9
    );
    this.wh.charinfocontainer.add(this.bg);
    this.wh.charinfocontainer.sendToBack(this.bg);
    this.bg.setInteractive();
    this.bg.removeAllListeners();
    this.backbtn = this.wh.add.sprite(0, 0, "thesq", 'back');
    this.wh.charinfocontainer.add(this.backbtn);
    this.wh.charinfocontainer.bringToTop(this.backbtn);
    this.backbtn.setOrigin(0.5, 0.5);
    this.backbtn.setInteractive();
    this.backbtn.removeAllListeners();
    this.backbtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        this.show_close();
      },
      this
    );
    this.backbtn.visible = true;
    var backbtnwh = 60;
    this.backbtn.displayWidth = this.backbtn.displayHeight = backbtnwh;
    this.backbtn.setPosition(backbtnwh / 2, height - backbtnwh / 2);
    var gamemenu = this.wh.registry.list.rcvarpass.gamemenu;
    this.backbtn2 = this.wh.add.sprite(0, 0, "thesq", 'back');
    this.wh.charinfocontainer.add(this.backbtn2);
    this.wh.charinfocontainer.bringToTop(this.backbtn2);
    this.backbtn2.setOrigin(0.5, 0.5);
    this.backbtn2.setInteractive();
    this.backbtn2.removeAllListeners();
    this.backbtn2.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        this.show_close();
      },
      this
    );
    this.backbtn2.visible = true;
    var backbtn2wh = 15;
    this.backbtn2.displayWidth = this.backbtn2.displayHeight = backbtn2wh;
    this.backbtn2.setPosition(width - backbtn2wh / 2, backbtn2wh / 2);

    this.plog.log('gamemenu', gamemenu);
    var mni = 0;
    var mnwh = 60;
    var mnmargintop = 35;
    var mnmarginleft = 5;
    for (let key of Object.keys(gamemenu)) {
      let val = gamemenu[key];
      if (val['pos'] != 3) continue;
      this.plog.log('gamemenu i3', key, val);
      //var tmpicon = wh.add.image(wh.cameras.main.width / 2, wh.cameras.main.height / 2, 'icon_' + val.code);
      var tmpicon = wh.add.sprite(
        wh.cameras.main.width / 2,
        wh.cameras.main.height / 2,
        'gamemenu',
        val.code
      );
      this.wh.charinfocontainer.add(tmpicon);
      this.wh.charinfocontainer.bringToTop(tmpicon);
      tmpicon.setPosition(mnmarginleft + mnwh / 2, mnmargintop + mni * mnwh);
      tmpicon.displayWidth = tmpicon.displayHeight = mnwh;
      tmpicon.name = val.code + '-' + val.pos;
      tmpicon.setInteractive();
      tmpicon.removeAllListeners();

      tmpicon.on('pointerover', function () {
        //this.plog.log("mainmenu over", arguments, this);
        //this.gamemenutooltip();
        var tmpvalnamea = this.name.split('-');
        var tooltiptext =
          this.scene.registry.list.rcvarpass.gamemenu[tmpvalnamea[0]].name;
        //this.plog.log("mainmenu over", tooltiptext);
        //this.plog.log("xxxx",tmpvalnamea);

        this.scene.registry.list.gameitf.gamemenutooltip(
          this.scene,
          this,
          tooltiptext,
          'right'
        );
      });
      tmpicon.on('pointerup', function () {
        this.scene.plog.log('mainmenu pointerup', arguments, this);
        var tmpvalnamea = this.name.split('-');
        this.scene.registry.list.charinfo.showpage(tmpvalnamea[0]);
        this.scene.registry.list.snd.snd("btn2");

        //var tmpvalnamea = this.name.split("-");
        //this.scene.registry.list.thethis.execmod.exec("gamemenu:" + tmpvalnamea[0], this.scene);
      });
      mni++;
    }

    //archiquest
    this.backbtns['archiquest'] = wh.add.sprite(0, 0, "thesb", "backsign");//this.wh.add.image(300, 300, 'listbackbtn');
    this.backbtns['archiquest'].setOrigin(0.5, 0.5);
    this.backbtns['archiquest'].setPosition(130, height - 25);
    this.backbtns['archiquest'].displayWidth = 100;
    this.backbtns['archiquest'].displayHeight = 50;
    this.backbtns['archiquest'].setInteractive();
    this.backbtns['archiquest'].removeAllListeners();
    this.backbtns['archiquest'].on(
      'pointerup',
      function () {
        this.showpage_page('archiquest', 'back');
        this.wh.registry.list.snd.snd("btn2");
      },
      this
    );

    this.fwdbtns['archiquest'] = wh.add.sprite(0, 0, "thesb", "forwardsign");//this.wh.add.image(300, 300, 'listfwdbtn');
    this.fwdbtns['archiquest'].setOrigin(0.5, 0.5);
    this.fwdbtns['archiquest'].setPosition(250, height - 25);
    this.fwdbtns['archiquest'].displayWidth = 100;
    this.fwdbtns['archiquest'].displayHeight = 50;
    this.fwdbtns['archiquest'].setInteractive();
    this.fwdbtns['archiquest'].removeAllListeners();
    this.fwdbtns['archiquest'].on(
      'pointerup',
      function () {
        this.showpage_page('archiquest', 'fwd');
        this.wh.registry.list.snd.snd("btn2");

      },
      this
    );
    //archimap
    this.backbtns['archimap'] = wh.add.sprite(0, 0, "thesb", "backsign");//this.wh.add.image(300, 300, 'listbackbtn');
    this.backbtns['archimap'].setOrigin(0.5, 0.5);
    this.backbtns['archimap'].setPosition(130, height - 25);
    this.backbtns['archimap'].displayWidth = 100;
    this.backbtns['archimap'].displayHeight = 50;
    this.backbtns['archimap'].setInteractive();
    this.backbtns['archimap'].removeAllListeners();
    this.backbtns['archimap'].on(
      'pointerup',
      function () {
        this.showpage_page('archimap', 'back');
        this.wh.registry.list.snd.snd("btn2");

      },
      this
    );

    this.fwdbtns['archimap'] = wh.add.sprite(0, 0, "thesb", "forwardsign");//this.wh.add.image(300, 300, 'listfwdbtn');
    this.fwdbtns['archimap'].setOrigin(0.5, 0.5);
    this.fwdbtns['archimap'].setPosition(250, height - 25);
    this.fwdbtns['archimap'].displayWidth = 100;
    this.fwdbtns['archimap'].displayHeight = 50;
    this.fwdbtns['archimap'].setInteractive();
    this.fwdbtns['archimap'].removeAllListeners();
    this.fwdbtns['archimap'].on(
      'pointerup',
      function () {
        this.showpage_page('archimap', 'fwd');
        this.wh.registry.list.snd.snd("btn2");

      },
      this
    );

    //archistack
    this.backbtns['archistack'] = wh.add.sprite(0, 0, "thesb", "backsign");//this.wh.add.image(300, 300, 'listbackbtn');
    this.backbtns['archistack'].setOrigin(0.5, 0.5);
    this.backbtns['archistack'].setPosition(130, height - 25);
    this.backbtns['archistack'].displayWidth = 100;
    this.backbtns['archistack'].displayHeight = 50;
    this.backbtns['archistack'].setInteractive();
    this.backbtns['archistack'].removeAllListeners();
    this.backbtns['archistack'].on(
      'pointerup',
      function () {
        this.showpage_page('archistack', 'back');
        this.wh.registry.list.snd.snd("btn2");

      },
      this
    );

    this.fwdbtns['archistack'] = wh.add.sprite(0, 0, "thesb", "forwardsign");//this.wh.add.image(300, 300, 'listfwdbtn');
    this.fwdbtns['archistack'].setOrigin(0.5, 0.5);
    this.fwdbtns['archistack'].setPosition(250, height - 25);
    this.fwdbtns['archistack'].displayWidth = 100;
    this.fwdbtns['archistack'].displayHeight = 50;
    this.fwdbtns['archistack'].setInteractive();
    this.fwdbtns['archistack'].removeAllListeners();
    this.fwdbtns['archistack'].on(
      'pointerup',
      function () {
        this.showpage_page('archistack', 'fwd');
        this.wh.registry.list.snd.snd("btn2");

      },
      this
    );

    //archiitem
    this.backbtns['archiitem'] = wh.add.sprite(0, 0, "thesb", "backsign");//this.wh.add.image(300, 300, 'listbackbtn');
    this.backbtns['archiitem'].setOrigin(0.5, 0.5);
    this.backbtns['archiitem'].setPosition(130, height - 25);
    this.backbtns['archiitem'].displayWidth = 100;
    this.backbtns['archiitem'].displayHeight = 50;
    this.backbtns['archiitem'].setInteractive();
    this.backbtns['archiitem'].removeAllListeners();
    this.backbtns['archiitem'].on(
      'pointerup',
      function () {
        this.showpage_page('archiitem', 'back');
        this.wh.registry.list.snd.snd("btn2");

      },
      this
    );

    this.fwdbtns['archiitem'] = wh.add.sprite(0, 0, "thesb", "forwardsign");//this.wh.add.image(300, 300, 'listfwdbtn');
    this.fwdbtns['archiitem'].setOrigin(0.5, 0.5);
    this.fwdbtns['archiitem'].setPosition(250, height - 25);
    this.fwdbtns['archiitem'].displayWidth = 100;
    this.fwdbtns['archiitem'].displayHeight = 50;
    this.fwdbtns['archiitem'].setInteractive();
    this.fwdbtns['archiitem'].removeAllListeners();
    this.fwdbtns['archiitem'].on(
      'pointerup',
      function () {
        this.showpage_page('archiitem', 'fwd');
        this.wh.registry.list.snd.snd("btn2");

      },
      this
    );

    //archimob
    this.backbtns['archimob'] = wh.add.sprite(0, 0, "thesb", "backsign");//this.wh.add.image(300, 300, 'listbackbtn');
    this.backbtns['archimob'].setOrigin(0.5, 0.5);
    this.backbtns['archimob'].setPosition(130, height - 25);
    this.backbtns['archimob'].displayWidth = 100;
    this.backbtns['archimob'].displayHeight = 50;
    this.backbtns['archimob'].setInteractive();
    this.backbtns['archimob'].removeAllListeners();
    this.backbtns['archimob'].on(
      'pointerup',
      function () {
        this.showpage_page('archimob', 'back');
        this.wh.registry.list.snd.snd("btn2");

      },
      this
    );

    this.fwdbtns['archimob'] = wh.add.sprite(0, 0, "thesb", "forwardsign");//this.wh.add.image(300, 300, 'listfwdbtn');
    this.fwdbtns['archimob'].setOrigin(0.5, 0.5);
    this.fwdbtns['archimob'].setPosition(250, height - 25);
    this.fwdbtns['archimob'].displayWidth = 100;
    this.fwdbtns['archimob'].displayHeight = 50;
    this.fwdbtns['archimob'].setInteractive();
    this.fwdbtns['archimob'].removeAllListeners();
    this.fwdbtns['archimob'].on(
      'pointerup',
      function () {
        this.showpage_page('archimob', 'fwd');
        this.wh.registry.list.snd.snd("btn2");

      },
      this
    );

    //combatlog
    this.backbtns['combatlog'] = wh.add.sprite(0, 0, "thesb", "backsign");//this.wh.add.image(300, 300, 'listbackbtn');
    this.backbtns['combatlog'].setOrigin(0.5, 0.5);
    this.backbtns['combatlog'].setPosition(130, height - 25);
    this.backbtns['combatlog'].displayWidth = 100;
    this.backbtns['combatlog'].displayHeight = 50;
    this.backbtns['combatlog'].setInteractive();
    this.backbtns['combatlog'].removeAllListeners();
    this.backbtns['combatlog'].on(
      'pointerup',
      function () {
        this.showpage_page('combatlog', 'back');
        this.wh.registry.list.snd.snd("btn2");

      },
      this
    );

    this.fwdbtns['combatlog'] = wh.add.sprite(0, 0, "thesb", "forwardsign");//this.wh.add.image(300, 300, 'listfwdbtn');
    this.fwdbtns['combatlog'].setOrigin(0.5, 0.5);
    this.fwdbtns['combatlog'].setPosition(250, height - 25);
    this.fwdbtns['combatlog'].displayWidth = 100;
    this.fwdbtns['combatlog'].displayHeight = 50;
    this.fwdbtns['combatlog'].setInteractive();
    this.fwdbtns['combatlog'].removeAllListeners();
    this.fwdbtns['combatlog'].on(
      'pointerup',
      function () {
        this.showpage_page('combatlog', 'fwd');
        this.wh.registry.list.snd.snd("btn2");

      },
      this
    );

    //medal 
    this.backbtns['medal'] = wh.add.sprite(0, 0, "thesb", "backsign");//this.wh.add.image(300, 300, 'listbackbtn');
    this.backbtns['medal'].setOrigin(0.5, 0.5);
    this.backbtns['medal'].setPosition(130, height - 25);
    this.backbtns['medal'].displayWidth = 100;
    this.backbtns['medal'].displayHeight = 50;
    this.backbtns['medal'].setInteractive();
    this.backbtns['medal'].removeAllListeners();
    this.backbtns['medal'].on(
      'pointerup',
      function () {
        this.showpage_page('medal', 'back');
        this.wh.registry.list.snd.snd("btn2");

      },
      this
    );

    this.fwdbtns['medal'] = wh.add.sprite(0, 0, "thesb", "forwardsign");//this.wh.add.image(300, 300, 'listfwdbtn');
    this.fwdbtns['medal'].setOrigin(0.5, 0.5);
    this.fwdbtns['medal'].setPosition(250, height - 25);
    this.fwdbtns['medal'].displayWidth = 100;
    this.fwdbtns['medal'].displayHeight = 50;
    this.fwdbtns['medal'].setInteractive();
    this.fwdbtns['medal'].removeAllListeners();
    this.fwdbtns['medal'].on(
      'pointerup',
      function () {
        this.showpage_page('medal', 'fwd');
        this.wh.registry.list.snd.snd("btn2");

      },
      this
    );


    this.wh.charinfocontainer.add(this.fwdbtns['archiquest']);
    this.wh.charinfocontainer.bringToTop(this.fwdbtns['archiquest']);
    this.wh.charinfocontainer.add(this.backbtns['archiquest']);
    this.wh.charinfocontainer.bringToTop(this.backbtns['archiquest']);

    this.wh.charinfocontainer.add(this.fwdbtns['archimap']);
    this.wh.charinfocontainer.bringToTop(this.fwdbtns['archimap']);
    this.wh.charinfocontainer.add(this.backbtns['archimap']);
    this.wh.charinfocontainer.bringToTop(this.backbtns['archimap']);

    this.wh.charinfocontainer.add(this.fwdbtns['archistack']);
    this.wh.charinfocontainer.bringToTop(this.fwdbtns['archistack']);
    this.wh.charinfocontainer.add(this.backbtns['archistack']);
    this.wh.charinfocontainer.bringToTop(this.backbtns['archistack']);

    this.wh.charinfocontainer.add(this.fwdbtns['archiitem']);
    this.wh.charinfocontainer.bringToTop(this.fwdbtns['archiitem']);
    this.wh.charinfocontainer.add(this.backbtns['archiitem']);
    this.wh.charinfocontainer.bringToTop(this.backbtns['archiitem']);

    this.wh.charinfocontainer.add(this.fwdbtns['archimob']);
    this.wh.charinfocontainer.bringToTop(this.fwdbtns['archimob']);
    this.wh.charinfocontainer.add(this.backbtns['archimob']);
    this.wh.charinfocontainer.bringToTop(this.backbtns['archimob']);

    this.wh.charinfocontainer.add(this.fwdbtns['combatlog']);
    this.wh.charinfocontainer.bringToTop(this.fwdbtns['combatlog']);
    this.wh.charinfocontainer.add(this.backbtns['combatlog']);
    this.wh.charinfocontainer.bringToTop(this.backbtns['combatlog']);

    this.wh.charinfocontainer.add(this.fwdbtns['medal']);
    this.wh.charinfocontainer.bringToTop(this.fwdbtns['medal']);
    this.wh.charinfocontainer.add(this.backbtns['medal']);
    this.wh.charinfocontainer.bringToTop(this.backbtns['medal']);

    var svversionurlstring = this.wh.registry.list.rcvarpass.svversionstring;
    if (svversionurlstring != '') {
      svversionurlstring = '?' + svversionurlstring;
    }
    let loader = new Phaser.Loader.LoaderPlugin(this.wh);
    var loadlist = [
      'archi_archi_tp',
      'archi_archi_tpg',
      'item_tpg',
      'stackitem_tpg',
      'mob',
      'mobg',
      'charstatus_tp',
      'itemtypeico',
      'stackitemtypeico',
    ];
    for (var k in loadlist) {
      var key = loadlist[k];
      //console.log(this.wh);
      if (this.wh.game.textures.list[key] != undefined) continue;
      if (this.isbadint == "yes" &&
        key != "itemtypeico" &&
        key != "stackitemtypeico"
      ) {
        loader.atlas(
          key + '',
          this.wh.registry.list.rcvarpass.rc_baseurl +
          '_badint/sprite/' +
          key +
          '.png' +
          svversionurlstring,
          this.wh.registry.list.rcvarpass.rc_baseurl +
          'sprite/' +
          key +
          '.json' +
          svversionurlstring
        );
      } else {
        loader.atlas(
          key + '',
          this.wh.registry.list.rcvarpass.rc_baseurl +
          'sprite/' +
          key +
          '.png' +
          svversionurlstring,
          this.wh.registry.list.rcvarpass.rc_baseurl +
          'sprite/' +
          key +
          '.json' +
          svversionurlstring
        );
      }
    }
    loader.once(Phaser.Loader.Events.COMPLETE, () => {
      this.plog.log('Phaser.Loader.Events.COMPLETE');
      this.plog.log(this);
      this.show_loaded();
    });
    this.wh.registry.list.rcloading.loading2setdescr("loading assets");

    loader.start();
  }
  sortdesc(myArguments) {
    return myArguments.sort(function (a, b) {
      return b - a;
    });
  }
  show_loaded() {
    this.loaded = true;

    this.plog.log('charinfo show_loaded()');
    this.showpage('charstat');
  }
  async showpage(pageid) {
    this.loaded = true;
    this.wh.registry.list.rcloading.loading2setdescr("preparing gui");
    this.wh.registry.list.gameitf.hidechatinput(this.wh);
    this.wh.registry.list.rcloading.loading2(this.wh, false, false);
    this.wh.registry.list.snd.snd("btn");
    var width = this.wh.game.canvas.width;
    var height = this.wh.game.canvas.height;
    this.bgmain.setPosition(0, height / 2);
    this.bgmain.displayWidth = 70;
    this.bgmain.displayHeight = height;
    this.bgmain2.setPosition(0, height / 2);
    this.bgmain2.displayWidth = 140;
    this.bgmain2.displayHeight = height;
    this.bg.setPosition(width / 2, height / 2, width, height);
    this.bg.displayWidth = width;
    this.bg.displayHeight = height;
    this.backbtn.setPosition(60 / 2, height - 60 / 2);
    this.backbtn2.setPosition(width - 15 / 2, 15 / 2);

    for (const k in this.submn) {
      if (this.submn[k] != undefined) this.submn[k].destroy();
    }
    for (const k in this.fwdbtns) {
      if (this.fwdbtns[k] != undefined) this.fwdbtns[k].visible = false;
    }
    for (const k in this.backbtns) {
      if (this.backbtns[k] != undefined) this.backbtns[k].visible = false;
    }

    this.submn = [];
    this.plog.log('showpage', pageid, this.bg);
    if (this.wh.charinfocontainersub != undefined) {
      this.wh.charinfocontainersub.destroy();//true
      this.wh.charinfocontainersub = undefined;
    }
    if (this.empty == undefined) {
      this.empty = this.wh.add.image(128, 128, 'loreprogressmain');
      this.empty.setOrigin(0.5, 0.5);
      this.empty.setPosition(0, 0);
      this.empty.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);;

      this.empty.displayWidth = this.empty.displayHeight = 128;
      this.empty.visible = false;
      this.wh.charinfocontainer.add(this.empty);
      this.wh.charinfocontainer.bringToTop(this.empty);
    }
    this.empty.visible = false;

    this.wh.charinfocontainersub = this.wh.add.container(0, 0);
    this.wh.charinfocontainersub.setDepth(1600000);
    this.wh.charinfocontainersub.x = this.wh.charinfocontainersub.y = 0;
    this.plog.log('charinfo showpage ', pageid);//, this.empty);
    //this.wh.charinfocontainersub.add(this.empty);
    this.empty.displayWidth = this.empty.displayHeight = 128;//      Math.min(width, height) - 140;
    if (this.empty.displayWidth > 450) {
      this.empty.displayWidth = this.empty.displayHeight = 350;
    }
    this.empty.x = width / 2 + 32;
    this.empty.y = height / 2;
    this.empty.displayWidth = this.empty.displayHeight = 128;
    this.empty.setInteractive();
    this.empty.removeAllListeners();
    this.empty.on('pointerup', async function () {
      //this.plog.log("stari this",this);
      await this.scene.registry.list.execmod.exec("talktonpc:loreprogress", this.scene);
      this.scene.registry.list.charinfo.show_close();
    });
    if (this.svtimeinfo == undefined) {
      this.svtimeinfo = this.wh.add.text(0, 0, "", this.wh.fs["wingui_text_s"]);
      this.wh.charinfocontainer.add(this.svtimeinfo);
      this.wh.charinfocontainer.bringToTop(this.svtimeinfo);
      this.svtimeinfo.visible = false;
      this.svtimeinfo.setPosition(80, height - 125);
    }
    ////////////////
    var i = 0;
    var iconwh = 64;
    var rowh = 74;
    var paddingl = 120;
    var paddingt = 45;
    var starwh = 20;
    var fs = {
      pass: this.wh.fs["list_title"],
      did: this.wh.fs["list_descr"],
    };
    // fs['pass']['fontSize']='2px';
    // fs['did']['fontSize']='2px';
    fs['pass']['color'] = '#000000';
    this.plog.log("xxxxxxx", fs);
    this.bgmain2.visible = false;
    this.svtimeinfo.visible = false;
    if (pageid == 'archiquest') {
      this.backbtns['archiquest'].visible = true;
      this.fwdbtns['archiquest'].visible = true;
      this.backbtns['archiquest'].setPosition(130, height - 25);
      this.fwdbtns['archiquest'].setPosition(250, height - 25);

      //
      var paddingl = 60;

      var iteminfo = await this.wh.registry.list.rchttp.getcommon2(pageid);
      this.plog.log('iteminfo', iteminfo);
      this.resptotalrecords = 0;
      for (var k in iteminfo) this.resptotalrecords++;
      this.respperpage = Math.floor(height / rowh) - 1;
      this.resptotalpage = Math.ceil(this.resptotalrecords / this.respperpage);
      this.plog.log('archidat resptotalrecords', this.resptotalrecords);
      this.plog.log('archidat respperpage', this.respperpage);
      this.plog.log('archidat resptotalpage', this.resptotalpage);

      var i = 0;
      var ii = 0;
      var starconfiglocal = iteminfo['STARCONFIG'];
      for (var k in iteminfo) {
        var v = iteminfo[k];

        if (k == 'STARCONFIG') continue;

        if (v['cc'] == undefined || v['cc'] == 0) continue;
        this.plog.log('loop i', k, v);
        /* var tmpi = this.wh.add.sprite(0, 0, "stackitem_tp", k);
         this.wh.charinfocontainersub.add(tmpi);
         this.wh.charinfocontainersub.bringToTop(tmpi);
         tmpi.displayWidth = tmpi.displayHeight = iconwh;
         tmpi.setPosition(paddingl, (rowh * i) + 2 + paddingt);*/
        this.archdb1[i] = this.wh.add.text(0, 0, v['name'], fs['pass']);
        this.archdb1[i].setShadow(0, 0, '#000000', 0, true, true);
        //this.archdb1[i].setStyle(fs['pass']);
        this.wh.charinfocontainersub.add(this.archdb1[i]);
        this.wh.charinfocontainersub.bringToTop(this.archdb1[i]);
        this.archdb1[i].setPosition(
          paddingl + iconwh / 2 + 5,
          rowh * ii + 2 + paddingt - iconwh / 2
        );

        var stari = 0;
        this.archdb2[i] = [];
        for (var sk in starconfiglocal) {
          var sv = starconfiglocal[sk];
          if (v['cc'] >= sv['num']) {
            this.archdb2[i][stari] = this.wh.add.sprite(0, 0, "thesq", 'star');
          } else {
            this.archdb2[i][stari] = this.wh.add.sprite(0, 0, "thesq", 'stardis');
          }
          this.wh.charinfocontainersub.add(this.archdb2[i][stari]);
          this.wh.charinfocontainersub.bringToTop(this.archdb2[i][stari]);
          this.archdb2[i][stari].displayWidth = this.archdb2[i][stari].displayHeight = starwh;
          this.archdb2[i][stari].name = sv['num'];
          this.archdb2[i][stari].setPosition(
            paddingl + iconwh + 5 + (starwh + 10) * stari,
            rowh * ii + 2 + paddingt + 5
          );

          this.archdb2[i][stari].setInteractive();
          this.archdb2[i][stari].removeAllListeners();
          this.archdb2[i][stari].on('pointerover', function () {
            this.scene.registry.list.gameitf.gamemenutooltip(this.scene, this, this.name + "", "btm");
          });
          //this.plog.log("archiquest",stari,v);
          if (v['cc'] >= sv['num']) {
            if (v["claimed" + (stari + 1)] + "" == "0") {
              this.archdb2[i][stari].setTexture("stackitem_tpt", "diamond");
              this.archdb2[i][stari].displayWidth = this.archdb2[i][stari].displayHeight =
                starwh + 8;
              this.archdb2[i][stari].level = stari;
              this.archdb2[i][stari].questid = k;
              this.archdb2[i][stari].on('pointerup', async function () {
                //this.plog.log("stari this",this);
                var rw = await this.scene.registry.list.execmod.exec("collectarchiquest:" + this.questid + ":" + this.level, this.scene);
                //this.scene.plog.error("collectarchiquest rw",rw);
                this.setTexture("thesq", 'star');
                this.displayWidth = this.displayHeight = 20;
                //this.scene.registry.list.gameitf.gamemenutooltip(this.scene, this, this.name, "btm");
                this.scene.registry.list.showreward.show(this.scene, rw, {
                  bg: 'combatwin',
                  text: 'Reward',
                });
                //this.scene.registry.list.gameitf.gamemenutooltip(this.scene, this, this.name, "btm");
              });
            }
          }
          stari++;
        }

        this.archdb3[i] = this.wh.add.text(
          0,
          0,
          '(' + v['cc'] + ')',
          //i + ' ' + ii + '(' + v['cc'] + ')',
          fs['did']
        );
        this.archdb3[i].setShadow(0, 0, '#000000', 0, true, true);
        //this.archdb3[i].setStyle(fs['did']);
        this.wh.charinfocontainersub.add(this.archdb3[i]);
        this.wh.charinfocontainersub.bringToTop(this.archdb3[i]);
        this.archdb3[i].setPosition(
          paddingl + iconwh + 0 + (starwh + 10) + 120,
          rowh * ii + 2 + paddingt
        );

        i++;
        ii++;
        if (ii == this.respperpage) ii = 0;
      }
      this.showpage_page('archiquest', '1st');
    }

    if (pageid == 'charstat') {
      //
      var iteminfo = await this.wh.registry.list.rchttp.getcommon2('charstat');
      var svtimeinfo = await this.wh.registry.list.rchttp.getcommon2('svtimeinfo');

      this.svtimeinfo.visible = true;
      var currentsv = await this.wh.registry.list.rcstorage.get('rc_usesv', true);
      var advmntinfo = "";
      if (svtimeinfo["rwquota_advancement"] != undefined) {
        advmntinfo += "\n" + svtimeinfo["rwquota_advancement"];
      }
      if (svtimeinfo["rwquota_loginreward"] != undefined) {
        advmntinfo += "\n" + svtimeinfo["rwquota_loginreward"];
      }
      var versioninfo = "";
      versioninfo = this.wh.registry.list.phaserGame.scene.scenes[1].platform
        + " " + this.wh.registry.list.phaserGame.scene.scenes[1].cliversionstring;
      if (this.wh.registry.list.phaserGame.scene.scenes[1].platform == "android") {
        var androidversion = "";
        try {
          androidversion = await this.wh.registry.list.thethis.getCurrentAppVersion();
          versioninfo = versioninfo + " " + this.wh.registry.list.phaserGame.scene.scenes[1].platform
            + " " + androidversion;
        } catch (e) {
          console.log(e);
        }

      }
      this.svtimeinfo.setText("PlayerID: " + this.wh.registry.list.rchttp.rcvars.gamer.id + ": " + this.wh.registry.list.rchttp.rcvars.gamer.email +
        "\nServer Reset Time (for event, bushes, etc)\nin " +
        svtimeinfo["str"] +
        "\nCurrent Server: " + this.wh.registry.list.rcvarpass.rc_sv[currentsv]["name"] + " (" + currentsv + ") :: " + versioninfo
        + "\nCharXID: " + this.wh.registry.list.rcvarpass.activechar["id"]
        + " Inventory lv: " + this.wh.registry.list.rcvarpass.activechar["invenlv"]
        + " StackInventory lv: " + this.wh.registry.list.rcvarpass.activechar["stackinvenlv"] + advmntinfo);
      this.svtimeinfo.setPosition(80, height - (10 + this.svtimeinfo.displayHeight));


      //iteminfo = this.sortdesc(iteminfo);
      this.plog.log('charstat', iteminfo);
      this.empty.visible = false;
      if (iteminfo.length == 0) {
        this.empty.visible = true;
        this.wh.charinfocontainersub.bringToTop(this.empty);
        this.plog.log('charstat empty', this.empty);
      } else {
        this.empty.visible = true;
        this.empty.y = height - 200;
        this.wh.charinfocontainersub.bringToTop(this.empty);
        this.plog.log('charstat NOT empty', this.empty);
      }

      this.combatdb = iteminfo;
      for (var k in iteminfo) {
        //for (const k = iteminfo.length - 1; k >= 0; k--) {

        var v = iteminfo[k];
        this.plog.log('loop i', k, v);
        //var tmpi = this.wh.add.sprite(0, 0, "stackitem_tp", k);
        var tmpi;

        var tmpi = this.wh.add.sprite(0, 0, 'charstatus_tp', v['status']);
        this.wh.charinfocontainersub.add(tmpi);
        this.wh.charinfocontainersub.bringToTop(tmpi);
        tmpi.setInteractive();
        tmpi.removeAllListeners();
        tmpi.name = k;
        tmpi.on('pointerup', function () {
          //this.plog.log(tmpi);
          //this.scene.registry.list.charinfo.showbattlelog(this.name);
        });
        tmpi.displayWidth = tmpi.displayHeight = iconwh;
        tmpi.setPosition(paddingl, rowh * i + 2 + paddingt);
        var statinfo =
          this.wh.registry.list.rcvarpass.charstatus_tp[v['status']];
        var tmp = this.wh.add.text(0, 0, statinfo['name'], fs['pass']);
        tmp.setShadow(0, 0, '#000000', 0, true, true);
        //tmp.setStyle(fs['pass']);
        this.wh.charinfocontainersub.add(tmp);
        this.wh.charinfocontainersub.bringToTop(tmp);
        tmp.setPosition(
          paddingl + iconwh / 2 + 5,
          rowh * i + 10 + paddingt - iconwh / 2
        );

        var tmpdid = this.wh.add.text(
          0,
          0,
          this.wh.gl(statinfo['descr']) + this.wh.gl('\nends at ') + v['dtestr'],
          fs['did']
        );
        tmpdid.setShadow(0, 0, '#000000', 0, true, true);
        //tmpdid.setStyle(fs['did']);
        this.wh.charinfocontainersub.add(tmpdid);
        this.wh.charinfocontainersub.bringToTop(tmpdid);
        tmpdid.setPosition(paddingl + iconwh / 2 + 5, rowh * i + 2 + paddingt);

        i++;
      }
    }

    if (pageid == 'combatlog') {

      this.backbtns['combatlog'].visible = true;
      this.fwdbtns['combatlog'].visible = true;
      this.backbtns['combatlog'].setPosition(130, height - 25);
      this.fwdbtns['combatlog'].setPosition(250, height - 25);

      //
      var iteminfo = await this.wh.registry.list.rchttp.getcommon2(pageid);
      var mapinfo = await this.wh.registry.list.rchttp.getcommon2('mapinfo');


      this.resptotalrecords = 0;
      for (var k in iteminfo) this.resptotalrecords++;
      this.respperpage = Math.floor(height / rowh) - 1;
      this.resptotalpage = Math.ceil(this.resptotalrecords / this.respperpage);
      this.plog.log('archidat resptotalrecords', this.resptotalrecords);
      this.plog.log('archidat respperpage', this.respperpage);
      this.plog.log('archidat resptotalpage', this.resptotalpage);


      //iteminfo = this.sortdesc(iteminfo);
      this.plog.log('iteminfo', iteminfo);
      this.combatdb = iteminfo;
      var i = 0;
      var ii = 0;
      for (var k in iteminfo) {
        //for (const k = iteminfo.length - 1; k >= 0; k--) {

        var v = iteminfo[k];
        this.plog.log('loop i', k, v);
        //var tmpi = this.wh.add.sprite(0, 0, "stackitem_tp", k);
        if (v['result'] == 'win') {
          this.archdb1[i] = this.wh.add.sprite(0, 0, "thesq", 'combatwin');
        } else {
          this.archdb1[i] = this.wh.add.sprite(0, 0, "thesq", 'combatlose');
        }
        this.wh.charinfocontainersub.add(this.archdb1[i]);
        this.wh.charinfocontainersub.bringToTop(this.archdb1[i]);
        this.archdb1[i].setInteractive();
        this.archdb1[i].removeAllListeners();
        this.archdb1[i].name = k;
        this.archdb1[i].on('pointerup', function () {
          //this.plog.log(this.archdb1[i]);
          this.scene.registry.list.charinfo.showbattlelog(this.name);
        });
        this.archdb1[i].displayWidth = this.archdb1[i].displayHeight = iconwh;
        this.archdb1[i].setPosition(paddingl, rowh * ii + 2 + paddingt);
        this.archdb2[i] = this.wh.add.text(0, 0, v['dt'], fs['pass']);
        this.archdb2[i].setShadow(0, 0, '#000000', 0, true, true);
        //this.archdb2[i].setStyle(fs['pass']);
        this.wh.charinfocontainersub.add(this.archdb2[i]);
        this.wh.charinfocontainersub.bringToTop(this.archdb2[i]);
        this.archdb2[i].setPosition(
          paddingl + iconwh / 2 + 5,
          rowh * ii + 15 + paddingt - iconwh / 2
        );

        this.archdb3[i] = this.wh.add.text(
          0,
          0,
          this.wh.gl('in : ') + mapinfo[v['map']]['name'],
          fs['did']
        );
        this.archdb3[i].setShadow(0, 0, '#000000', 0, true, true);
        //this.archdb3[i].setStyle(fs['did']);
        this.wh.charinfocontainersub.add(this.archdb3[i]);
        this.wh.charinfocontainersub.bringToTop(this.archdb3[i]);
        this.archdb3[i].setPosition(paddingl + iconwh - 10, rowh * ii + 7 + paddingt);

        i++;
        ii++;
        if (ii == this.respperpage) ii = 0;
      }
      this.showpage_page('combatlog', '1st');
    }
    if (pageid == 'archimap') {
      this.backbtns['archimap'].visible = true;
      this.fwdbtns['archimap'].visible = true;
      this.backbtns['archimap'].setPosition(130, height - 25);
      this.fwdbtns['archimap'].setPosition(250, height - 25);

      //
      var iteminfo = await this.wh.registry.list.rchttp.getcommon2(pageid);
      this.plog.log('iteminfo', iteminfo);

      this.resptotalrecords = 0;
      for (var k in iteminfo) this.resptotalrecords++;
      this.respperpage = Math.floor(height / rowh) - 1;
      this.resptotalpage = Math.ceil(this.resptotalrecords / this.respperpage);
      this.plog.log('archidat resptotalrecords', this.resptotalrecords);
      this.plog.log('archidat respperpage', this.respperpage);
      this.plog.log('archidat resptotalpage', this.resptotalpage);

      var i = 0;
      var ii = 0;
      var starconfiglocal = iteminfo['STARCONFIG'];
      for (var k in iteminfo) {
        var v = iteminfo[k];
        if (k == 'STARCONFIG') continue;
        if (v['cc'] == undefined || v['cc'] == 0) continue;
        this.archdb1[i] = this.wh.add.sprite(0, 0, 'stackitem_tp', 'mapscrollmap1');//k
        this.wh.charinfocontainersub.add(this.archdb1[i]);
        this.wh.charinfocontainersub.bringToTop(this.archdb1[i]);
        this.archdb1[i].displayWidth = this.archdb1[i].displayHeight = iconwh;
        this.archdb1[i].setPosition(paddingl, rowh * ii + 2 + paddingt);
        this.archdb2[i] = this.wh.add.text(0, 0, v['name'], fs['pass']);
        this.archdb2[i].setShadow(0, 0, '#000000', 0, true, true);
        //this.archdb2[i].setStyle(fs['pass']);
        this.wh.charinfocontainersub.add(this.archdb2[i]);
        this.wh.charinfocontainersub.bringToTop(this.archdb2[i]);
        this.archdb2[i].setPosition(
          paddingl + iconwh / 2 + 5,
          rowh * ii + 2 + paddingt - iconwh / 2
        );

        var stari = 0;
        this.archdb3[i] = [];
        for (var sk in starconfiglocal) {
          var sv = starconfiglocal[sk];
          //this.plog.log("archimap", i, sk, stari);
          if (v['cc'] >= sv['num']) {
            this.archdb3[i][stari] = this.wh.add.sprite(0, 0, "thesq", 'star');
          } else {
            this.archdb3[i][stari] = this.wh.add.sprite(0, 0, "thesq", 'stardis');
          }
          this.wh.charinfocontainersub.add(this.archdb3[i][stari]);
          this.wh.charinfocontainersub.bringToTop(this.archdb3[i][stari]);
          this.archdb3[i][stari].displayWidth = this.archdb3[i][stari].displayHeight =
            starwh;
          this.archdb3[i][stari].name = sv['num'];
          this.archdb3[i][stari].setPosition(
            paddingl + iconwh + 5 + (starwh + 10) * stari,
            rowh * ii + 2 + paddingt + 5
          );

          this.archdb3[i][stari].setInteractive();
          this.archdb3[i][stari].removeAllListeners();
          this.archdb3[i][stari].on('pointerover', function () {
            this.scene.registry.list.gameitf.gamemenutooltip(this.scene, this, this.name + "", "btm");
          });
          stari++;
        }

        this.archdb4[i] = this.wh.add.text(0, 0, '(' + v['cc'] + ')', fs['did']);
        this.archdb4[i].setShadow(0, 0, '#000000', 0, true, true);
        //this.archdb4[i].setStyle(fs['did']);
        this.wh.charinfocontainersub.add(this.archdb4[i]);
        this.wh.charinfocontainersub.bringToTop(this.archdb4[i]);
        this.archdb4[i].setPosition(
          paddingl + iconwh + 25 + (starwh + 10) * 4,
          rowh * ii + 2 + paddingt
        );

        i++;
        ii++;
        if (ii == this.respperpage) ii = 0;
      }
      this.showpage_page('archimap', '1st');
    }
    if (pageid == 'archistack') {
      this.backbtns['archistack'].visible = true;
      this.fwdbtns['archistack'].visible = true;
      this.backbtns['archistack'].setPosition(130, height - 25);
      this.fwdbtns['archistack'].setPosition(250, height - 25);
      //
      this.bgmain2.visible = true;
      var iteminfo = await this.wh.registry.list.rchttp.getcommon2(pageid);
      this.plog.log('iteminfo', iteminfo);
      this.bgmain2.visible = true;

      this.resptotalrecords = 0;
      for (var k in iteminfo) this.resptotalrecords++;
      this.respperpage = Math.floor(height / rowh) - 1;
      this.resptotalpage = Math.ceil(this.resptotalrecords / this.respperpage);

      paddingl = 175;
      var paddingls = 105;
      var stackarr = [];
      stackarr['currency'] = ['currency'];
      stackarr['chest'] = ['chest'];
      //stackarr['egg'] = ['egg'];
      stackarr['util'] = ['util'];
      stackarr['questitems'] = ['questitems'];

      var paddingts = paddingt + 0;
      var iconwhsub = 30;


      var i = 0;
      for (var k in stackarr) {
        var v = stackarr[k];
        //this.plog.log('xxxxx', k, v, i);

        this.submn[k] = this.wh.add.sprite(
          0,
          0,
          'stackitemtypeico',
          k,
          iconwhsub * i + 2 + paddingts
        );
        this.wh.charinfocontainer.add(this.submn[k]);
        this.wh.charinfocontainer.bringToTop(this.submn[k]);
        this.submn[k].displayWidth = this.submn[k].displayHeight = iconwhsub;
        this.submn[k].setPosition(paddingls, (iconwhsub + 3) * i + 30);
        paddingts = paddingts + rowh;
        this.submn[k].setInteractive();
        this.submn[k].removeAllListeners();
        this.submn[k].name = k;
        this.submn[k].on('pointerup', function () {
          this.scene.registry.list.charinfo.subfilterstackitem = this.name;
          //this.plog.log('xxxxx', this);
          this.scene.registry.list.charinfo.showpage('archistack');
        });
        i++;
      }

      var tmpcounti = 0;
      var tmparraytocheck = stackarr[this.subfilterstackitem];
      for (var k in iteminfo) {
        var v = iteminfo[k];
        if (v['cc'] == undefined || v['cc'] == 0) continue;
        if (tmparraytocheck.includes(v['pcode'])) {
          tmpcounti++;
        }
      }
      this.resptotalpage = Math.ceil(tmpcounti / this.respperpage);

      var i = 0;
      var ii = 0;
      var starconfiglocal = iteminfo['STARCONFIG'];
      for (var k in iteminfo) {
        if (k == 'STARCONFIG') continue;
        var v = iteminfo[k];
        this.plog.log('loop i', k, v);
        var tmparraytocheck = stackarr[this.subfilterstackitem];
        /*this.plog.log(
          'xxxx',
          v,
          v['pcode'],
          'in',
          this.subfilterstackitem,
          tmparraytocheck,
          tmparraytocheck.includes(v['pcode'])
        );*/
        if (tmparraytocheck.includes(v['pcode'])) {
        } else {
          continue;
        }
        if (v['cc'] == undefined || v['cc'] == 0) continue;

        this.archdb1[i] = this.wh.add.sprite(0, 0, 'stackitem_tp', k);
        this.wh.charinfocontainersub.add(this.archdb1[i]);
        this.wh.charinfocontainersub.bringToTop(this.archdb1[i]);
        this.archdb1[i].displayWidth = this.archdb1[i].displayHeight = iconwh;
        this.archdb1[i].setPosition(paddingl, rowh * ii + 2 + paddingt);
        this.archdb2[i] = this.wh.add.text(0, 0, v['name'], fs['pass']);
        this.archdb2[i].setShadow(0, 0, '#000000', 0, true, true);
        //tmp.setStyle(fs['pass']);
        this.wh.charinfocontainersub.add(this.archdb2[i]);
        this.wh.charinfocontainersub.bringToTop(this.archdb2[i]);
        this.archdb2[i].setPosition(
          paddingl + iconwh / 2 + 5,
          rowh * ii + 2 + paddingt - iconwh / 2
        );

        var stari = 0;
        this.archdb3[i] = [];
        for (var sk in starconfiglocal) {
          var sv = starconfiglocal[sk];
          //var star;
          if (v['cc'] >= sv['num']) {
            this.archdb3[i][stari] = this.wh.add.sprite(0, 0, "thesq", 'star');
          } else {
            this.archdb3[i][stari] = this.wh.add.sprite(0, 0, "thesq", 'stardis');
          }
          this.wh.charinfocontainersub.add(this.archdb3[i][stari]);
          this.wh.charinfocontainersub.bringToTop(this.archdb3[i][stari]);
          this.archdb3[i][stari].displayWidth = this.archdb3[i][stari].displayHeight = starwh;
          this.archdb3[i][stari].name = sv['num'];
          this.archdb3[i][stari].setPosition(
            paddingl + iconwh + 5 + (starwh + 5) * stari,
            rowh * ii + 2 + paddingt + 5
          );

          this.archdb3[i][stari].setInteractive();
          this.archdb3[i][stari].removeAllListeners();
          this.archdb3[i][stari].on('pointerover', function () {
            this.scene.registry.list.gameitf.gamemenutooltip(this.scene, this, this.name + "", "btm");
          });
          stari++;
        }

        this.archdb4[i] = this.wh.add.text(0, 0, '(' + v['cc'] + ')', fs['did']);
        this.archdb4[i].setShadow(0, 0, '#000000', 0, true, true);
        //tmpdid.setStyle(fs['did']);
        this.wh.charinfocontainersub.add(this.archdb4[i]);
        this.wh.charinfocontainersub.bringToTop(this.archdb4[i]);
        this.archdb4[i].setPosition(
          paddingl + iconwh + 25 + (starwh + 10) * 4,
          rowh * ii + 2 + paddingt
        );

        i++;
        ii++;
        if (ii == this.respperpage) ii = 0;
      }
      this.showpage_page('archistack', '1st');
    }
    if (pageid == 'archiitem') {
      this.backbtns['archiitem'].visible = true;
      this.fwdbtns['archiitem'].visible = true;
      this.backbtns['archiitem'].setPosition(130, height - 25);
      this.fwdbtns['archiitem'].setPosition(250, height - 25);
      //
      var iteminfo = await this.wh.registry.list.rchttp.getcommon2(pageid);
      this.plog.log('iteminfo', iteminfo);
      this.bgmain2.visible = true;

      paddingl = 175;
      var paddingls = 105;
      var stackarr = [];
      stackarr['sword'] = ['sword'];
      stackarr['longsword'] = ['longsword'];
      stackarr['axe'] = ['axe'];
      stackarr['longaxe'] = ['longaxe'];
      stackarr['wand'] = ['wand'];
      stackarr['bow'] = ['bow'];
      stackarr['dagger'] = ['dagger'];
      stackarr['shield'] = ['shield'];
      stackarr['accessories'] = ['ring', 'earring', 'necklace', 'bracelet'];
      stackarr['boots'] = ['strboots', 'chaboots', 'dexboots', 'conboots'];
      stackarr['gloves'] = ['strgloves', 'dexgloves', 'chagloves', 'dexarmor'];
      stackarr['armor'] = ['strarmor', 'conarmor', 'chaarmor', 'conboots'];
      stackarr['pant'] = ['strpants', 'conpants', 'chapants', 'dexpants'];
      stackarr['helmet'] = ['strhelmet', 'conhelmet', 'chahelmet', 'dexhelmet'];
      var paddingts = paddingt + 0;


      this.resptotalrecords = 0;
      for (var k in iteminfo) this.resptotalrecords++;
      this.respperpage = Math.floor(height / rowh) - 1;
      this.resptotalpage = Math.ceil(this.resptotalrecords / this.respperpage);


      var iconwhsub = 30;
      var i = 0;
      for (var k in stackarr) {
        var v = stackarr[k];
        //this.plog.log('xxxxx', k, v, i);

        this.submn[k] = this.wh.add.sprite(
          0,
          0,
          'itemtypeico',
          k,
          iconwhsub * i + 2 + paddingts
        );
        this.wh.charinfocontainersub.add(this.submn[k]);
        this.wh.charinfocontainersub.bringToTop(this.submn[k]);
        this.submn[k].displayWidth = this.submn[k].displayHeight = iconwhsub;
        this.submn[k].setPosition(paddingls, (iconwhsub + 3) * i + 30);
        paddingts = paddingts + rowh;
        this.submn[k].setInteractive();
        this.submn[k].removeAllListeners();
        this.submn[k].name = k;
        this.submn[k].on('pointerup', function () {
          this.scene.registry.list.charinfo.subfilteritem = this.name;
          //this.plog.log('xxxxx', this);
          this.scene.registry.list.charinfo.showpage('archiitem');
        });
        i++;
      }

      var tmpcounti = 0;
      var tmparraytocheck = stackarr[this.subfilteritem];
      for (var k in iteminfo) {
        var v = iteminfo[k];
        if (v['cc'] == undefined || v['cc'] == 0) continue;
        if (tmparraytocheck.includes(v['pcode'])) {
          tmpcounti++;
        }
      }
      this.resptotalpage = Math.ceil(tmpcounti / this.respperpage);


      var i = 0;
      var ii = 0;
      var starconfiglocal = iteminfo['STARCONFIG'];

      for (var k in iteminfo) {
        //continue;;

        if (k == 'STARCONFIG') continue;
        var v = iteminfo[k];
        this.plog.log('loop i', k, v);
        var tmparraytocheck = stackarr[this.subfilteritem];
        //this.plog.log("xxxx",v,v["pcode"],"in",this.subfilteritem,tmparraytocheck,tmparraytocheck.includes(v["pcode"]));
        if (tmparraytocheck.includes(v['pcode'])) {
        } else {
          continue;
        }
        if (v['cc'] == undefined || v['cc'] == 0) continue;
        //continue;
        this.archdb1[i] = this.wh.add.sprite(0, 0, 'item_tp', k);
        this.wh.charinfocontainersub.add(this.archdb1[i]);
        this.wh.charinfocontainersub.bringToTop(this.archdb1[i]);
        this.archdb1[i].displayWidth = this.archdb1[i].displayHeight = iconwh;
        this.archdb1[i].setPosition(paddingl, rowh * ii + 2 + paddingt);
        this.archdb2[i] = this.wh.add.text(0, 0, v['name'], fs['pass']);
        this.archdb2[i].setShadow(0, 0, '#000000', 0, true, true);
        //tmp.setStyle(fs['pass']);
        this.wh.charinfocontainersub.add(this.archdb2[i]);
        this.wh.charinfocontainersub.bringToTop(this.archdb2[i]);
        this.archdb2[i].setPosition(
          paddingl + iconwh / 2 + 5,
          rowh * ii + 2 + paddingt - iconwh / 2
        );

        var stari = 0;
        this.archdb3[i] = [];
        for (var sk in starconfiglocal) {
          var sv = starconfiglocal[sk];
          var star;
          if (v['cc'] >= sv['num']) {
            this.archdb3[i][stari] = this.wh.add.sprite(0, 0, "thesq", 'star');
          } else {
            this.archdb3[i][stari] = this.wh.add.sprite(0, 0, "thesq", 'stardis');
          }
          this.wh.charinfocontainersub.add(this.archdb3[i][stari]);
          this.wh.charinfocontainersub.bringToTop(this.archdb3[i][stari]);
          this.archdb3[i][stari].displayWidth = this.archdb3[i][stari].displayHeight = starwh;
          this.archdb3[i][stari].name = sv['num'];
          this.archdb3[i][stari].setPosition(
            paddingl + iconwh + 5 + (starwh + 5) * stari,
            rowh * ii + 2 + paddingt + 5
          );

          this.archdb3[i][stari].setInteractive();
          this.archdb3[i][stari].removeAllListeners();
          this.archdb3[i][stari].on('pointerover', function () {
            //console.log(this);
            this.scene.registry.list.gameitf.gamemenutooltip(this.scene, this, this.name + "", "btm");
          });
          stari++;
        }

        this.archdb4[i] = this.wh.add.text(0, 0, '(' + v['cc'] + ')', fs['did']);
        this.archdb4[i].setShadow(0, 0, '#000000', 0, true, true);
        //tmpdid.setStyle(fs['did']);
        this.wh.charinfocontainersub.add(this.archdb4[i]);
        this.wh.charinfocontainersub.bringToTop(this.archdb4[i]);
        this.archdb4[i].setPosition(
          paddingl + iconwh + 25 + (starwh + 10) * 4,
          rowh * ii + 2 + paddingt
        );

        i++;
        ii++;
        if (ii == this.respperpage) ii = 0;
      }
    }

    if (pageid == 'archimob') {
      this.backbtns['archimap'].visible = true;
      this.fwdbtns['archimap'].visible = true;
      this.backbtns['archimap'].setPosition(130, height - 25);
      this.fwdbtns['archimap'].setPosition(250, height - 25);
      //archi_mob

      var iteminfo = await this.wh.registry.list.rchttp.getcommon2(pageid);
      this.plog.log('iteminfo', iteminfo);

      this.resptotalrecords = 0;
      for (var k in iteminfo) {
        var v = iteminfo[k];
        if (v['cc'] == undefined || v['cc'] == 0) continue;
        this.resptotalrecords++;
      }
      this.respperpage = Math.floor(height / rowh) - 1;
      this.resptotalpage = Math.ceil(this.resptotalrecords / this.respperpage);
      this.plog.log('archidat resptotalrecords', this.resptotalrecords);
      this.plog.log('archidat respperpage', this.respperpage);
      this.plog.log('archidat resptotalpage', this.resptotalpage);

      var i = 0;
      var ii = 0;
      var starconfiglocal = iteminfo['STARCONFIG'];
      for (var k in iteminfo) {
        if (k == 'STARCONFIG') continue;

        var v = iteminfo[k];
        if (v['cc'] == undefined || v['cc'] == 0) continue;
        this.plog.log('loop i', k, v);
        this.archdb1[i] = this.wh.add.sprite(0, 0, 'mob', k);
        this.wh.charinfocontainersub.add(this.archdb1[i]);
        this.wh.charinfocontainersub.bringToTop(this.archdb1[i]);
        this.archdb1[i].displayWidth = iconwh;
        this.archdb1[i].scaleY = this.archdb1[i].scaleX;
        this.archdb1[i].setPosition(paddingl, rowh * ii + 2 + paddingt);
        this.archdb2[i] = this.wh.add.text(0, 0, v['name'], fs['pass']);
        this.archdb2[i].setShadow(0, 0, '#000000', 0, true, true);
        //this.archdb2[i].setStyle(fs['pass']);
        //this.plog.log("archimobfs",fs['pass'])
        this.wh.charinfocontainersub.add(this.archdb2[i]);
        this.wh.charinfocontainersub.bringToTop(this.archdb2[i]);
        this.archdb2[i].setPosition(
          paddingl + iconwh / 2 + 5,
          rowh * ii + 2 + paddingt - iconwh / 2
        );

        var stari = 0;
        this.archdb3[i] = [];


        for (var sk in starconfiglocal) {
          var sv = starconfiglocal[sk];
          if (v['cc'] >= sv['num']) {
            this.archdb3[i][stari] = this.wh.add.sprite(0, 0, "thesq", 'star');
          } else {
            this.archdb3[i][stari] = this.wh.add.sprite(0, 0, "thesq", 'stardis');
          }
          this.wh.charinfocontainersub.add(this.archdb3[i][stari]);
          this.wh.charinfocontainersub.bringToTop(this.archdb3[i][stari]);
          this.archdb3[i][stari].displayWidth = this.archdb3[i][stari].displayHeight = starwh;
          this.archdb3[i][stari].name = sv['num'];
          this.archdb3[i][stari].setPosition(
            paddingl + iconwh + 5 + (starwh + 10) * stari,
            rowh * ii + 2 + paddingt + 5
          );

          this.archdb3[i][stari].setInteractive();
          this.archdb3[i][stari].removeAllListeners();
          this.archdb3[i][stari].on('pointerover', function () {
            this.scene.registry.list.gameitf.gamemenutooltip(this.scene, this, this.name + "", "btm");
          });
          stari++;
        }

        this.archdb4[i] = this.wh.add.text(0, 0, '(' + v['cc'] + ')', fs['did']);
        this.archdb4[i].setShadow(0, 0, '#000000', 0, true, true);
        // this.archdb4[i].setStyle(fs['did']);
        this.wh.charinfocontainersub.add(this.archdb4[i]);
        this.wh.charinfocontainersub.bringToTop(this.archdb4[i]);
        this.archdb4[i].setPosition(
          paddingl + iconwh + 25 + (starwh + 10) * 4,
          rowh * ii + 2 + paddingt
        );

        i++;
        ii++;
        if (ii == this.respperpage) ii = 0;
      }
      this.showpage_page('archimap', '1st');
    }
    if (pageid == 'medal') {
      this.backbtns['archimap'].visible = true;
      this.fwdbtns['archimap'].visible = true;
      this.backbtns['archimap'].setPosition(130, height - 25);
      this.fwdbtns['archimap'].setPosition(250, height - 25);


      //archi_archi
      var iteminfo = await this.wh.registry.list.rchttp.getcommon2(
        'archi' + pageid
      );
      this.plog.log('iteminfo', iteminfo);

      this.resptotalrecords = 0;
      for (var k in iteminfo) this.resptotalrecords++;
      this.respperpage = Math.floor(height / rowh) - 1;
      this.resptotalpage = Math.ceil(this.resptotalrecords / this.respperpage);
      this.plog.log('archidat resptotalrecords', this.resptotalrecords);
      this.plog.log('archidat respperpage', this.respperpage);
      this.plog.log('archidat resptotalpage', this.resptotalpage);

      var i = 0;
      var ii = 0;
      var starconfiglocal = iteminfo['STARCONFIG'];
      for (var k in iteminfo) {
        if (k == "STARCONFIG") continue;
        var v = iteminfo[k];
        this.plog.log('loop i', k, v);
        this.archdb1[i] = this.wh.add.sprite(0, 0, 'archi_archi_tp', k);
        this.wh.charinfocontainersub.add(this.archdb1[i]);
        this.wh.charinfocontainersub.bringToTop(this.archdb1[i]);
        this.archdb1[i].displayWidth = this.archdb1[i].displayHeight = iconwh;
        this.archdb1[i].setPosition(paddingl, rowh * ii + 2 + paddingt);
        this.archdb2[i] = this.wh.add.text(0, 0, v['name'], fs['pass']);
        this.archdb2[i].setShadow(0, 0, '#000000', 0, true, true);
        //this.archdb2[i].setStyle(fs['pass']);
        this.wh.charinfocontainersub.add(this.archdb2[i]);
        this.wh.charinfocontainersub.bringToTop(this.archdb2[i]);
        this.archdb2[i].setPosition(
          paddingl + iconwh / 2 + 5,
          rowh * ii + 2 + paddingt - iconwh / 2
        );

        var stari = 0;
        this.archdb3[i] = [];
        for (var sk in starconfiglocal) {
          var sv = starconfiglocal[sk];
          if (v['cc'] >= sv['num']) {
            this.archdb3[i][stari] = this.wh.add.sprite(0, 0, "thesq", 'star');
          } else {
            this.archdb3[i][stari] = this.wh.add.sprite(0, 0, "thesq", 'stardis');
          }
          this.wh.charinfocontainersub.add(this.archdb3[i][stari]);
          this.wh.charinfocontainersub.bringToTop(this.archdb3[i][stari]);
          this.archdb3[i][stari].displayWidth = this.archdb3[i][stari].displayHeight = starwh;
          this.archdb3[i][stari].name = sv['num'];
          this.archdb3[i][stari].setPosition(
            paddingl + iconwh + 5 + (starwh + 10) * stari,
            rowh * ii + 2 + paddingt + 5
          );

          this.archdb3[i][stari].setInteractive();
          this.archdb3[i][stari].removeAllListeners();

          this.archdb3[i][stari].on('pointerover', function () {
            this.scene.registry.list.gameitf.gamemenutooltip(this.scene, this, this.name + "", "btm");
          });
          //this.plog.log("archiquest",stari,v);
          if (v['cc'] >= sv['num']) {
            if (v["claimed" + (stari + 1)] + "" == "0") {
              this.archdb3[i][stari].setTexture("stackitem_tpt", "diamond");
              this.archdb3[i][stari].displayWidth = this.archdb3[i][stari].displayHeight =
                starwh + 8;
              this.archdb3[i][stari].level = stari;
              this.archdb3[i][stari].questid = k;
              this.archdb3[i][stari].on('pointerup', async function () {
                //this.plog.log("stari this",this);
                var rw = await this.scene.registry.list.execmod.exec("collectarchimedal:" + this.questid + ":" + this.level, this.scene);
                //console.error("error(rw);",rw);
                //this.scene.plog.warn(rw);
                this.setTexture("thesq", 'star');
                this.displayWidth = this.displayHeight = 20;
                //this.scene.registry.list.gameitf.gamemenutooltip(this.scene, this, this.name, "btm");
                this.scene.registry.list.showreward.show(this.scene, rw, {
                  bg: 'combatwin',
                  text: 'Reward',
                });
              });
            }
          }
          stari++;
        }

        this.archdb4[i] = this.wh.add.text(0, 0, '(' + v['cc'] + ')', fs['did']);
        this.archdb4[i].setShadow(0, 0, '#000000', 0, true, true);
        // this.archdb4[i].setStyle(fs['did']);
        this.wh.charinfocontainersub.add(this.archdb4[i]);
        this.wh.charinfocontainersub.bringToTop(this.archdb4[i]);
        this.archdb4[i].setPosition(
          paddingl + iconwh + 25 + (starwh + 10) * 4,
          rowh * ii + 2 + paddingt
        );

        i++;
        ii++;
        if (ii == this.respperpage) ii = 0;
      }
      this.showpage_page('medal', '1st');
    }

    this.bg.removeAllListeners();
    /*
        this.bg.on('pointermove', function (pointer) {
          //this.plog.log(this);
          //var pos = this.parentContainer.y + this.y;
          //this.plog.log("pos", pos);
          //if (pos > -150) {
          if (pointer.isDown) {
            var height = this.scene.registry.parent.config.height;
            var tmpsize = this.scene.registry.list.gameitf.getContainerSize(
              this.scene.registry.parent,
              this.scene.charinfocontainersub
            );
            this.plog.log(
              'bgpointermove',
              tmpsize,
              this.scene.registry.parent,
              this.scene.charinfocontainersub
            );
            //this.plog.log(this, 0 - (tmpsize.h - height), this.scene.charinfocontainersub);
            this.scene.charinfocontainersub.y += pointer.velocity.y / 3;
            this.scene.charinfocontainersub.y = Phaser.Math.Clamp(
              this.scene.charinfocontainersub.y,
              0 - (tmpsize.h - height + 30),
              1
            );
            this.plog.log(
              'bgpointermove Clamp',
              this.scene.charinfocontainersub.y,
              0 - (tmpsize.h - height + 30)
            );
    
            //this.plog.log("xxxskill in container", 0-(this.countraceskill*50),this.skillctnr_char.y);
          }
          //}
        });*/
    this.wh.registry.list.rcloading.close();
    this.bgmain.x = -70;
    var tw = this.wh.tweens.add({
      targets: this.bgmain,
      x: 0,
      ease: 'Power3',
      duration: 300,
      delay: 0,
    });
    this.bgmain2.x = -70;
    var tw = this.wh.tweens.add({
      targets: this.bgmain2,
      x: 0,
      ease: 'Power3',
      duration: 300,
      delay: 200,
    });
  }
  show_close() {
    this.wh.registry.list.snd.snd("btn3");

    if (this.wh.charinfocontainer != undefined)
      this.wh.charinfocontainer.visible = false;
    if (this.wh.charinfocontainersub != undefined)
      this.wh.charinfocontainersub.visible = false;
    this.plog.log('charinfo show_loaded()');
  }
  showbattlelog(id) {
    this.viewingbattlelogid = id;
    let loader = new Phaser.Loader.LoaderPlugin(this.wh);
    var loadlist = [];
    var dat = this.combatdb[id];
    this.viewingbattlelogtoken = dat['token'];
    for (const k in dat['log']) {
      var vc = dat['log'][k];
      if (vc['type'] == 'char') {
        loadlist[vc['sprite']] = vc['sprite'];
      }
    }
    var svversionurlstring = this.wh.registry.list.rcvarpass.svversionstring;
    if (svversionurlstring != '') {
      svversionurlstring = '?' + svversionurlstring;
    }
    for (var k in loadlist) {
      var key = loadlist[k];
      loader.atlas(
        key + '',
        this.wh.registry.list.rcvarpass.rc_baseurl +
        'sprite/' +
        key +
        '.png' +
        svversionurlstring,
        this.wh.registry.list.rcvarpass.rc_baseurl +
        'sprite/' +
        key +
        '.json' +
        svversionurlstring
      );
    }
    loader.once(Phaser.Loader.Events.COMPLETE, () => {
      this.plog.log('Phaser.Loader.Events.COMPLETE');
      this.plog.log(this);
      this.showbattlelog_loaded();
    });
    loader.start();
  }
  showcombatlogtext_page(viewpage) {
    this.plog.log("showcombatlogtext_page", viewpage);

    if (viewpage == '1st') {
      this.showcombatlogtext_pagei = 1;
    }
    if (viewpage == 'back') {
      if (this.showcombatlogtext_pagei > 1) this.showcombatlogtext_pagei--;
    }
    if (viewpage == 'fwd') {
      if (this.showcombatlogtext_pagei < this.showcombatlogtext_pageitotalpage) this.showcombatlogtext_pagei++;
    }
    for (const k in this.logtextobj) {
      if (this.logtextobj[k] != undefined) {
        this.logtextobj[k].visible = false;
      }
    }

    var numfrom = this.showcombatlogtext_pageiperpage * (this.showcombatlogtext_pagei - 1);
    var numto = this.showcombatlogtext_pageiperpage * this.showcombatlogtext_pagei;
    this.plog.log(
      'showcombatlogtext_page',
      viewpage,
      this.showcombatlogtext_pagei,
      'fromto',
      numfrom,
      numto,
      'perpage',
      this.showcombatlogtext_pageiperpage
    );

    for (var i = numfrom; i < numto; i++) {
      if (this.logtextobj[i] != undefined) {
        this.logtextobj[i].visible = true;
      }
    }
  }
  showcombatlogtext() {
    this.wh.registry.list.rcloading.loading2(this.wh, false, false);
    var width = this.wh.game.canvas.width;
    var height = this.wh.game.canvas.height;
    var dat = this.combatdb[this.viewingbattlelogid];

    this.plog.log("showcombatlogtext", dat);

    this.combatlogobj['logtextbg'] = this.wh.add.rectangle(
      width / 2,
      height / 2,
      width,
      height,
      0xffe59c
    );

    this.combatlogobj['logtextback'] = this.wh.add.sprite(width / 2 - 100,
      height - 50, "thesb", "backsign"
    );
    this.combatlogobj['logtextfwd'] = this.wh.add.sprite(width / 2 + 100,
      height - 50, "thesb", "forwardsign"
    );
    this.wh.charinfocontainersub.add(this.combatlogobj['logtextbg']);
    this.wh.charinfocontainersub.bringToTop(this.combatlogobj['logtextbg']);

    this.wh.charinfocontainersub.add(this.combatlogobj['logtextback']);
    this.wh.charinfocontainersub.bringToTop(this.combatlogobj['logtextback']);
    this.wh.charinfocontainersub.add(this.combatlogobj['logtextfwd']);
    this.wh.charinfocontainersub.bringToTop(this.combatlogobj['logtextfwd']);

    this.combatlogobj['logtextback'].setInteractive();
    this.combatlogobj['logtextback'].removeAllListeners();
    this.combatlogobj['logtextback'].on(
      'pointerup',
      function () {
        this.plog.log('logtextback');
        this.showcombatlogtext_page("back");
      },
      this
    );
    this.combatlogobj['logtextfwd'].setInteractive();
    this.combatlogobj['logtextfwd'].removeAllListeners();
    this.combatlogobj['logtextfwd'].on(
      'pointerup',
      function () {
        this.plog.log('logtextfwd');
        this.showcombatlogtext_page("fwd");
      },
      this
    );

    this.combatlogobj['logtextbg'].setInteractive();
    this.combatlogobj['logtextbg'].removeAllListeners();
    this.combatlogobj['logtextbg'].on(
      'pointerup',
      function () {
        this.plog.log('logtextbg');
        this.closecombatdetail();
      },
      this
    );
    if (dat.logtext == undefined) {
      this.wh.registry.list.rcloading.close();
      //return;
    }
    //dat.logtext=dat.log;

    var lineh = 35;
    this.showcombatlogtext_pageiperpage = Math.floor((height - 100) / (lineh));
    this.showcombatlogtext_pageitotalpage = Math.ceil(dat.logtext.length / this.showcombatlogtext_pageiperpage);
    var i = 0;
    var ii = 0;
    for (const k in dat.logtext) {
      var txt = dat.logtext[k] + "";
      if (txt == undefined) return;
      var txta = txt.split(":::");
      this.logtextobj[i] = this.wh.add.text(20, (lineh * ii), i + " " + txta[1], this.wh.fs["logtext_" + txta[0]]);
      this.logtextobj[i].setStyle({ fixedWidth: width - 40, backgroundColor: '#686868' });
      this.logtextobj[i].setWordWrapWidth(width - 50);;

      this.wh.charinfocontainersub.add(this.logtextobj[i]);
      this.wh.charinfocontainersub.bringToTop(this.logtextobj[i]);
      //this.logtextobj[i].setPosition(20,)
      i++;
      ii++;
      if (ii >= this.showcombatlogtext_pageiperpage) ii = 0;
    }
    this.showcombatlogtext_page("1st");
    this.wh.registry.list.rcloading.close();
  }
  showbattlelog_loaded() {
    var width = this.wh.game.canvas.width;
    var height = this.wh.game.canvas.height;
    this.wh.charinfocontainersub.y = 0;
    this.plog.log('showbattlelog', this.viewingbattlelogid);
    this.combatlogobj['bg'] = this.wh.add.rectangle(
      width / 2,
      height / 2,
      width,
      height,
      0xffe59c
    );
    this.wh.charinfocontainersub.add(this.combatlogobj['bg']);
    this.wh.charinfocontainersub.bringToTop(this.combatlogobj['bg']);
    this.combatlogobj['bg'].setInteractive();
    this.combatlogobj['bg'].removeAllListeners();
    this.combatlogobj['bg'].on(
      'pointerup',
      function () {
        this.plog.log('bgclicked');
        this.closecombatdetail();
      },
      this
    );
    /*
    this.combatlogobj['detailbtn'] = this.wh.add.sprite(0, 0, "thesb", "detail");
    this.wh.charinfocontainersub.add(this.combatlogobj['detailbtn']);
    this.wh.charinfocontainersub.bringToTop(this.combatlogobj['detailbtn']);
    this.combatlogobj['detailbtn'].setPosition(width / 2, height - this.combatlogobj['detailbtn'].displayHeight);
    this.combatlogobj['detailbtn'].setInteractive();
    this.combatlogobj['detailbtn'].removeAllListeners();
    this.combatlogobj['detailbtn'].on(
      'pointerup',
      function () {
        this.plog.log('detailbtn', this.viewingbattlelogid);
        //this.closecombatdetail();
        this.showcombatlogtext();
      },
      this
    );
*/

    this.combatlogobj['detailbtn'] = this.wh.add.sprite(0, 0, "thesq", "newwin");
    this.wh.charinfocontainersub.add(this.combatlogobj['detailbtn']);
    this.wh.charinfocontainersub.bringToTop(this.combatlogobj['detailbtn']);
    this.combatlogobj['detailbtn'].displayWidth = this.combatlogobj['detailbtn'].displayHeight = 72;

    this.combatlogobj['detailbtn'].setPosition((width / 2) + (this.combatlogobj['detailbtn'].displayWidth / 2 + 5), height - this.combatlogobj['detailbtn'].displayHeight);
    this.combatlogobj['detailbtn'].setInteractive();
    this.combatlogobj['detailbtn'].removeAllListeners();
    this.combatlogobj['detailbtn'].on(
      'pointerup',
      function () {
        this.plog.log('detailbtn', this.viewingbattlelogtoken, this);
        //this.closecombatdetail();
        //this.showcombatlogtext();
        window.open(this.wh.registry.list.rcvarpass.rcserver_url + "_openbattlelog.php?iden=" + this.viewingbattlelogtoken);
      },
      this
    );
    this.combatlogobj['sharebtn'] = this.wh.add.sprite(0, 0, "thesq", "share");
    this.wh.charinfocontainersub.add(this.combatlogobj['sharebtn']);
    this.wh.charinfocontainersub.bringToTop(this.combatlogobj['sharebtn']);
    this.combatlogobj['sharebtn'].displayWidth = this.combatlogobj['sharebtn'].displayHeight = 72;
    this.combatlogobj['sharebtn'].setPosition((width / 2) - (this.combatlogobj['sharebtn'].displayWidth / 2 + 5), height - this.combatlogobj['sharebtn'].displayHeight);
    this.combatlogobj['sharebtn'].setInteractive();
    this.combatlogobj['sharebtn'].removeAllListeners();
    this.combatlogobj['sharebtn'].on(
      'pointerup',
      function () {
        this.plog.log('sharebtn', this.viewingbattlelogid);
        //this.closecombatdetail();
        //this.showcombatlogtext();
        this.wh.registry.list.anyjs.sharer_combatlog(this.viewingbattlelogtoken);
      },
      this
    );
    if (this.viewingbattlelogtoken == undefined || this.viewingbattlelogtoken == "") {
      this.combatlogobj['sharebtn'].visible = false;
      this.combatlogobj['detailbtn'].visible = false;
    }
    if (this.viewingbattlelogtoken == "sharereward" || this.viewingbattlelogtoken == "redocombat") {
      this.combatlogobj['sharebtn'].visible = false;
      this.combatlogobj['detailbtn'].visible = false;
    }

    var dat = this.combatdb[this.viewingbattlelogid];
    if (dat['result'] == 'win') {
      this.combatlogobj['vltxt'] = this.wh.add.text(0, 0, 'Victory', this.wh.fs["showreward_victory"]);
      this.combatlogobj['vltxt'].setStyle(this.wh.fs["showreward_victory"]);
    } else {
      this.combatlogobj['vltxt'] = this.wh.add.text(0, 0, 'Defeated', this.wh.fs["showreward_victory"]);
      this.combatlogobj['vltxt'].setStyle(this.wh.fs["showreward_victory"]);
    }
    /*this.combatlogobj['vltxt'].setStroke('#2d2d2d', 8);
    this.combatlogobj['vltxt'].setShadow(4, 4, '#000000', 2, false, true);

    this.combatlogobj['vltxt'].setShadow(0, 0, '#000000', 0, true, true);*/
    this.wh.charinfocontainersub.add(this.combatlogobj['vltxt']);
    this.wh.charinfocontainersub.bringToTop(this.combatlogobj['vltxt']);
    this.combatlogobj['vltxt'].setPosition(
      width / 2 - this.combatlogobj['vltxt'].displayWidth / 2,
      20
    );

    var cbtc = 0;
    this.combatlogobj['cbt'] = [];
    //team 1
    var cbti = 0;
    var cbtiwh = 60;
    var cbtpaddingl = 50;
    var cbtpaddingtt = 150;
    for (const k in dat['log']) {
      var vc = dat['log'][k];
      //var vcmobdata = this.wh.registry.list.rcvarpass.allmob[vc["mobcode"]];
      if (vc['team'] == 1) {
        this.plog.log('team 1', vc);
        if (vc['type'] == 'char') {
          this.combatlogobj['cbt'][cbtc] = this.wh.add.sprite(
            0,
            0,
            vc['sprite'],
            'walk-d-1'
          );
        } else {
          if (vc['hp_remain'] < 1) {
            this.combatlogobj['cbt'][cbtc] = this.wh.add.sprite(
              0,
              0,
              'mobg',
              vc['mobcode']
            );
          } else {
            this.combatlogobj['cbt'][cbtc] = this.wh.add.sprite(
              0,
              0,
              'mob',
              vc['mobcode']
            );
          }
        }
        this.combatlogobj['cbt'][cbtc].texture.setFilter(Phaser.Textures.FilterMode.NEAREST);

        this.wh.charinfocontainersub.add(this.combatlogobj['cbt'][cbtc]);
        this.wh.charinfocontainersub.bringToTop(this.combatlogobj['cbt'][cbtc]);
        this.combatlogobj['cbt'][cbtc].setPosition(
          cbtpaddingl + cbti * (cbtiwh + 10),
          cbtpaddingtt
        );
        this.combatlogobj['cbt'][cbtc].displayWidth = cbtiwh;
        this.combatlogobj['cbt'][cbtc].scaleY = this.combatlogobj['cbt'][cbtc].scaleX;
        this.combatlogobj['cbt'][cbtc + 'gen_atkcount'] = this.wh.add.text(0, 0,
          'Atk Times: ' + vc['gen_atkcount'],
          {
            fontFamily: 'ffff',
            fontSize: '8px',
            color: '#9B0000',
          }
        );
        this.combatlogobj['cbt'][cbtc + 'gen_atkcount'].setPosition(
          cbtpaddingl +
          cbti * (cbtiwh + 10) -
          this.combatlogobj['cbt'][cbtc + 'gen_atkcount'].displayWidth / 2,
          cbtpaddingtt + 40
        );
        this.wh.charinfocontainersub.add(
          this.combatlogobj['cbt'][cbtc + 'gen_atkcount']
        );
        this.wh.charinfocontainersub.bringToTop(
          this.combatlogobj['cbt'][cbtc + 'gen_atkcount']
        );

        this.combatlogobj['cbt'][cbtc + 'gen_atk'] = this.wh.add.text(
          0,
          0,
          'ATK: ' + this.wh.registry.list.gameitf.humannumber(vc['gen_atk'], 0),
          {
            fontFamily: 'ffff',
            fontSize: '8px',
            color: '#9B0000',
          }
        );
        this.combatlogobj['cbt'][cbtc + 'gen_atk'].setPosition(
          cbtpaddingl +
          cbti * (cbtiwh + 10) -
          this.combatlogobj['cbt'][cbtc + 'gen_atk'].displayWidth / 2,
          cbtpaddingtt + 60
        );
        this.wh.charinfocontainersub.add(
          this.combatlogobj['cbt'][cbtc + 'gen_atk']
        );
        this.wh.charinfocontainersub.bringToTop(
          this.combatlogobj['cbt'][cbtc + 'gen_atk']
        );

        this.combatlogobj['cbt'][cbtc + 'gen_skilluse'] = this.wh.add.text(
          0,
          0,
          'Skill Use: ' + vc['gen_skilluse'],
          {
            fontFamily: 'ffff',
            fontSize: '8px',
            color: '#000',
          }
        );

        this.combatlogobj['cbt'][cbtc + 'gen_skilluse'].setPosition(
          cbtpaddingl +
          cbti * (cbtiwh + 10) -
          this.combatlogobj['cbt'][cbtc + 'gen_skilluse'].displayWidth / 2,
          cbtpaddingtt + 80
        );
        this.wh.charinfocontainersub.add(
          this.combatlogobj['cbt'][cbtc + 'gen_skilluse']
        );
        this.wh.charinfocontainersub.bringToTop(
          this.combatlogobj['cbt'][cbtc + 'gen_skilluse']
        );

        this.combatlogobj['cbt'][cbtc + 'hp_remain'] = this.wh.add.text(
          0,
          0,
          'Health: ' + this.wh.registry.list.gameitf.humannumber(vc['hp_remain'], 0),
          {
            fontFamily: 'ffff',
            fontSize: '8px',
            color: '#060',
          }
        );
        this.combatlogobj['cbt'][cbtc + 'hp_remain'].setPosition(
          cbtpaddingl +
          cbti * (cbtiwh + 10) -
          this.combatlogobj['cbt'][cbtc + 'hp_remain'].displayWidth / 2,
          cbtpaddingtt + 100
        );
        this.wh.charinfocontainersub.add(
          this.combatlogobj['cbt'][cbtc + 'hp_remain']
        );
        this.wh.charinfocontainersub.bringToTop(
          this.combatlogobj['cbt'][cbtc + 'hp_remain']
        );
        //this.combatlogobj["cbt"][cbtc]=
        cbti++;
        cbtc++;
      }
    }
    cbti = 0;
    var cbtpaddingt = 300;
    for (const k in dat['log']) {
      var vc = dat['log'][k];
      if (vc['team'] == 2) {
        this.plog.log('team 2', vc);
        if (vc['type'] == 'char') {
          this.combatlogobj['cbt'][cbtc] = this.wh.add.sprite(
            0,
            0,
            vc['mobcode'],
            'walk-d-1'
          );
        } else {
          if (vc['hp_remain'] < 1) {
            this.combatlogobj['cbt'][cbtc] = this.wh.add.sprite(
              0,
              0,
              'mobg',
              vc['mobcode']
            );
          } else {
            this.combatlogobj['cbt'][cbtc] = this.wh.add.sprite(
              0,
              0,
              'mob',
              vc['mobcode']
            );
          }
        }
        this.combatlogobj['cbt'][cbtc].texture.setFilter(Phaser.Textures.FilterMode.NEAREST);

        this.wh.charinfocontainersub.add(this.combatlogobj['cbt'][cbtc]);
        this.wh.charinfocontainersub.bringToTop(this.combatlogobj['cbt'][cbtc]);
        this.combatlogobj['cbt'][cbtc].setPosition(
          cbtpaddingl + cbti * (cbtiwh + 10),
          cbtpaddingt
        );
        this.combatlogobj['cbt'][cbtc].displayWidth = cbtiwh;
        this.combatlogobj['cbt'][cbtc].scaleY = this.combatlogobj['cbt'][cbtc].scaleX;

        this.combatlogobj['cbt'][cbtc + 'gen_atkcount'] = this.wh.add.text(
          0,
          0,
          'Atk Times: ' + vc['gen_atkcount'],
          {
            fontFamily: 'ffff',
            fontSize: '8px',
            color: '#900',
          }
        );
        this.combatlogobj['cbt'][cbtc + 'gen_atkcount'].setPosition(
          cbtpaddingl +
          cbti * (cbtiwh + 10) -
          this.combatlogobj['cbt'][cbtc + 'gen_atkcount'].displayWidth / 2,
          cbtpaddingt + 40
        );
        this.wh.charinfocontainersub.add(
          this.combatlogobj['cbt'][cbtc + 'gen_atkcount']
        );
        this.wh.charinfocontainersub.bringToTop(
          this.combatlogobj['cbt'][cbtc + 'gen_atkcount']
        );

        this.combatlogobj['cbt'][cbtc + 'gen_atk'] = this.wh.add.text(
          0,
          0,
          'ATK: ' + this.wh.registry.list.gameitf.humannumber(vc['gen_atk'], 0),
          {
            fontFamily: 'ffff',
            fontSize: '8px',
            color: '#600',
          }
        );
        this.combatlogobj['cbt'][cbtc + 'gen_atk'].setPosition(
          cbtpaddingl +
          cbti * (cbtiwh + 10) -
          this.combatlogobj['cbt'][cbtc + 'gen_atk'].displayWidth / 2,
          cbtpaddingt + 60
        );
        this.wh.charinfocontainersub.add(
          this.combatlogobj['cbt'][cbtc + 'gen_atk']
        );
        this.wh.charinfocontainersub.bringToTop(
          this.combatlogobj['cbt'][cbtc + 'gen_atk']
        );

        this.combatlogobj['cbt'][cbtc + 'gen_skilluse'] = this.wh.add.text(
          0,
          0,
          'Skill Use: ' + vc['gen_skilluse'],
          {
            fontFamily: 'ffff',
            fontSize: '8px',
            color: '#000',
          }
        );
        this.combatlogobj['cbt'][cbtc + 'gen_skilluse'].setPosition(
          cbtpaddingl +
          cbti * (cbtiwh + 10) -
          this.combatlogobj['cbt'][cbtc + 'gen_skilluse'].displayWidth / 2,
          cbtpaddingt + 80
        );
        this.wh.charinfocontainersub.add(
          this.combatlogobj['cbt'][cbtc + 'gen_skilluse']
        );
        this.wh.charinfocontainersub.bringToTop(
          this.combatlogobj['cbt'][cbtc + 'gen_skilluse']
        );
        //this.combatlogobj["cbt"][cbtc]=

        this.combatlogobj['cbt'][cbtc + 'hp_remain'] = this.wh.add.text(
          0,
          0,
          'Health: ' + this.wh.registry.list.gameitf.humannumber(vc['hp_remain'], 0),
          {
            fontFamily: 'ffff',
            fontSize: '8px',
            color: '#060',
          }
        );
        this.combatlogobj['cbt'][cbtc + 'hp_remain'].setPosition(
          cbtpaddingl +
          cbti * (cbtiwh + 10) -
          this.combatlogobj['cbt'][cbtc + 'hp_remain'].displayWidth / 2,
          cbtpaddingt + 100
        );
        this.wh.charinfocontainersub.add(
          this.combatlogobj['cbt'][cbtc + 'hp_remain']
        );
        this.wh.charinfocontainersub.bringToTop(
          this.combatlogobj['cbt'][cbtc + 'hp_remain']
        );
        //this.combatlogobj["cbt"][cbtc]=
        cbti++;
        cbtc++;
      }
    }
    this.plog.log('combat dat', dat);


  }
  closecombatdetail() {
    this.plog.log('closecombatdetail', this.combatlogobj);
    if (this.combatlogobj['logtextbg'] != undefined)
      this.combatlogobj['logtextbg'].visible = false;
    if (this.combatlogobj['logtextbg'] != undefined)
      this.combatlogobj['logtextbg'].visible = false;
    this.combatlogobj['bg'].visible = false;
    this.combatlogobj['vltxt'].visible = false;
    if (this.combatlogobj['detailbtn'] != undefined)
      this.combatlogobj['detailbtn'].visible = false;
    if (this.combatlogobj['sharebtn'] != undefined)
      this.combatlogobj['sharebtn'].visible = false;
    if (this.combatlogobj['logtextback'] != undefined)
      this.combatlogobj['logtextback'].visible = false;
    if (this.combatlogobj['logtextfwd'] != undefined)
      this.combatlogobj['logtextfwd'].visible = false;
    for (const k in this.combatlogobj['cbt']) {
      this.combatlogobj['cbt'][k].visible = false;
    }

    for (const k in this.logtextobj) {
      if (this.logtextobj[k] != undefined) {
        this.logtextobj[k].visible = false;
      }
    }
    return;
    this.combatlogobj['bg'].destroy();
    this.combatlogobj['bg'] = undefined;
    this.combatlogobj['vltxt'].destroy();
    this.combatlogobj['vltxt'] = undefined;
    for (const k in this.combatlogobj['cbt']) {
      this.combatlogobj['cbt'][k].destroy();
      this.combatlogobj['cbt'][k] = undefined;
    }
  }
  showpage_page(gc, viewpage) {
    var cc = 0;
    //if (viewpage == 'archiquest')
    if (viewpage == '1st') {
      this.currentresppage = 1;
    }
    if (viewpage == 'back') {
      if (this.currentresppage > 1) this.currentresppage--;
    }
    if (viewpage == 'fwd') {
      if (this.currentresppage < this.resptotalpage) this.currentresppage++;
    }
    for (var i = 0; i <= 300; i++) {
      if (this.archdb1[i] != undefined) {
        this.archdb1[i].visible = false;
      }
      if (this.archdb2[i] != undefined) {
        this.archdb2[i].visible = false;
        if (Array.isArray(this.archdb2[i]))
          for (var k in this.archdb2[i]) {
            if (this.archdb2[i][k].type == 'Image')
              this.archdb2[i][k].visible = false;
            if (this.archdb2[i][k].type == 'Sprite')
              this.archdb2[i][k].visible = false;
          }
        //for (var k in this.archdb2[i]) this.archdb2[i][k].visible = false;
      }
      if (this.archdb3[i] != undefined) {
        this.archdb3[i].visible = false;
        if (Array.isArray(this.archdb3[i]))
          for (var k in this.archdb3[i]) {
            //this.plog.log("hidestar", k, this.archdb3[i][k]);
            if (this.archdb3[i][k].type == 'Image')
              this.archdb3[i][k].visible = false;
            if (this.archdb3[i][k].type == 'Sprite')
              this.archdb3[i][k].visible = false;
          }
      }
      if (this.archdb4[i] != undefined) {
        this.archdb4[i].visible = false;
      }
      if (this.archdb5[i] != undefined) {
        this.archdb5[i].visible = false;
      }
    }
    var numfrom = this.respperpage * (this.currentresppage - 1);
    var numto = this.respperpage * this.currentresppage;
    this.plog.log(
      'showinventory_showpage',
      viewpage,
      this.currentresppage,
      'fromto',
      numfrom,
      numto,
      'perpage',
      this.respperpage
    );

    for (var i = numfrom; i < numto; i++) {
      if (this.archdb1[i] != undefined) {
        this.archdb1[i].visible = true;
      }
      if (this.archdb2[i] != undefined) {
        this.archdb2[i].visible = true;
        if (Array.isArray(this.archdb2[i]))
          for (var k in this.archdb2[i]) {
            if (this.archdb2[i][k].type == 'Image')
              this.archdb2[i][k].visible = true;
            if (this.archdb2[i][k].type == 'Sprite')
              this.archdb2[i][k].visible = true;
          }
      }
      if (this.archdb3[i] != undefined) {
        this.archdb3[i].visible = true;
        if (Array.isArray(this.archdb3[i]))
          for (var k in this.archdb3[i]) {
            if (this.archdb3[i][k].type == 'Image')
              this.archdb3[i][k].visible = true;
            if (this.archdb3[i][k].type == 'Sprite')
              this.archdb3[i][k].visible = true;
          }
        //this.archdb3[i][k].visible = true;
      }
      if (this.archdb4[i] != undefined) {
        this.archdb4[i].visible = true;
      }
      if (this.archdb5[i] != undefined) {
        this.archdb5[i].visible = true;
      }
    }
  }
}
