import { AStarFinder } from 'astar-typescript';
import { plog } from './plog.service';

export class MapCritter {
  o;
  wh;
  currentx;
  currenty;
  sprite;
  doing = '';
  behavetimer;
  doinginprocess = false;
  astar;
  walkarray = [];
  lastspriteface = 'd';
  idlecountdown = 0;
  actionspeed;
  plog;

  constructor(wh, o) {
    this.wh = wh;
    this.o = o;
    this.plog = new plog();
    this.plog.log('MapCritter constructor', wh, o);
    if (this.o['sprite'] == undefined || this.o['subcode'] == '') return;
    var mobchkmax = 10;
    var mobchkmin = 5;
    var monnum = Math.floor(
      Math.random() * (mobchkmax - mobchkmin) + mobchkmin
    );
    this.actionspeed = Math.floor(Math.random() * (1500 - 600) + 600);
    this.idlecountdown = monnum;
    if (this.o.nature == 'rapid') {
      this.actionspeed = this.actionspeed / 4;
    }
    if (this.o.nature == 'teleport') {
      this.actionspeed = 100;
    }
    this.astar = new AStarFinder({
      grid: {
        matrix: this.wh.registry.list.rcvarpass.activemapmetrix,
      },
      includeStartNode: false,
      includeEndNode: true,
    });
    //this.plog.log("critter astar", this.astar);
    this.behavetimer = this.wh.time.addEvent({
      delay: this.actionspeed,
      callback: this.behave,
      callbackScope: this,
      loop: true,
    });
    this.wh.mobs.push(this);
  }
  getobj() {
    return this.o;
  }
  talk() {
    this.plog.log('critter talk', this.o.talk);
    if (this.o.talk == undefined || this.o.talk == '') return;
    var items = this.o.talk.split('|');
    var item = items[Math.floor(Math.random() * items.length)];
    this.wh.registry.list.phm.charsaybubble(this.sprite, item);
  }
  spawn(optionalpos?) {
    if (this.o['sprite'] == undefined || this.o['subcode'] == '') return;

    const tmp = this.wh.add.sprite(64, 64, this.o.sprite);
    //var actarr = ["u", "d", "l", "r"];

    tmp.play(
      //'critter_' + this.o['sprite'] + '_' + this.o['subcode'] + '_d'
      //'critter_' + this.o['sprite'] + '_' + this.o['subcode'] + '_d_idle'
      'critter_' + this.o['sprite'] + '_' + this.o['subcode'] + '_' + this.lastspriteface + '_idle'

    ); //.setDisplayOrigin(0.5, 0.8);
    this.wh.zsortlist.push(tmp);
    tmp.mapmonobj = this;
    //tmp.setScale(this.o["mapsize"]);
    this.sprite = tmp;
    this.sprite.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);

    this.sprite.setInteractive();
    this.sprite.on(
      'pointerdown',
      function () {
        this.talk();
      },
      this
    );
    this.plog.log('crit', this.wh);
    //var tmp = this.rcvarsservice.gameobj.scene.scenes[0].add.image((spawnposa[1] * 32), (spawnposa[0] * 32), this.rcvarsservice.activechar["sprite"], "walk-d-1").setDepth(999);
    this.wh.mapcontainer.add(tmp);
    this.wh.mapcontainer.bringToTop(tmp);

    this.movetorandomarea(optionalpos);

    var moveto = this.getrandwalkpos() + '';
    var movetoa = moveto.split('-');
    var startPos = { x: this.currentx, y: this.currenty };
    var goalPos = { x: movetoa[0], y: movetoa[1] };
    //this.plog.log("I'mma walk to ", movetoa);
    var myPathway = this.astar.findPath(startPos, goalPos);
    //this.plog.log(myPathway);
    this.walkarray = myPathway;

    //this.plog.log("charic",charic);
    //this.plog.log("moveTo", this.charzindex, this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer);
    //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.moveTo(tmp, this.charzindex);
    //this.rcvarsservice.gameobj.scene.scenes[0].char_main = tmp;

    this.sprite.displayHeight = 64; // * this.o["mapsize"];
    this.sprite.displayWidth = 64; // * this.o["mapsize"];
    this.behave();
  }
  movetorandomarea(setpos?) {
    if (setpos == '' || setpos == undefined) {
      var moveto = this.getrandwalkpos() + '';
      var movetoa = moveto.split('-');
    } else {
      movetoa = setpos;
    }
    //this.plog.log('crit moveto', movetoa);
    this.currentx = Number(movetoa[0]);
    this.currenty = Number(movetoa[1]);
    this.sprite.x = (this.currentx - 1) * 32; //+width/2;
    this.sprite.y = (this.currenty - 1) * 32; //+width/2;
    this.sprite.displayHeight = 64; // * this.o["mapsize"];
    this.sprite.displayWidth = 64; // * this.o["mapsize"];
    this.sprite.setOrigin(0.5, 0.8);
    //this.plog.log(random, months[random]);
  }
  getrandwalkpos() {
    var mobwalkarr = this.wh.registry.list.rcvarpass.activemap['walkable'];
    if (mobwalkarr == undefined) {
      this.plog.log('critter movetorandomarea: walk not found');
    }
    var usablemobwalk = [];
    for (var key in mobwalkarr) {
      var val = mobwalkarr[key];
      if (val == 'yn_y') {
        usablemobwalk.push(key);
      }
    }
    var randompos = Math.floor(Math.random() * usablemobwalk.length);
    var moveto = usablemobwalk[randompos];
    //this.plog.log('mapmon movetorandomarea: usablemobwalk', usablemobwalk);
    //this.plog.log('mapmon movetorandomarea: randompos', moveto);
    return moveto;
  }
  findnextclosestwalkable() {
    var mobwalkarr = this.wh.registry.list.rcvarpass.activemap['walkable'];
    var closestdist = 1000;
    var bestpos = '';
    for (var key in mobwalkarr) {
      var val = mobwalkarr[key];
      if (val == 'yn_y') {
        var keya = key.split('-');
        //this.plog.log("findnextclosestwalkable keya",keya);
        var dist = Math.sqrt(
          Math.pow(this.currentx - Number(keya[0]), 2) +
          Math.pow(this.currenty - Number(keya[1]), 2)
        );
        //this.plog.log("findnextclosestwalkable dist",dist);
        if (dist < closestdist) {
          closestdist = dist;
          bestpos = key;
        }
      }
    }
    return bestpos;
  }
  behave() {
    if (this.astar == undefined) {
    }
    if (
      this.wh.combatcontainer != undefined &&
      this.wh.combatcontainer.list.length > 0
    ) {
      return;
    }
    //this.plog.log("behaving", this);
    if (this.sprite != undefined) {
      this.sprite.scale = 1;
    }
    var action = ['walk', 'idle']; //, "sleep"
    if (this.o.behave == "walkonly") {
      action = ['walk']; //, "sleep"
    }
    //var action = ["walk"];

    if (this.doing == '') {
      var randomaction = Math.floor(Math.random() * action.length);
      var randomactionv = action[randomaction] + '';
      this.doing = randomactionv;
    }
    if (this.o.nature == 'still') {
      this.doing = 'idle';
    }
    if (this.o.nature == 'warp') {
      var action = ['warp', 'idle'];
      var randomaction = Math.floor(Math.random() * action.length);
      var randomactionv = action[randomaction] + '';
      this.doing = randomactionv;
    }
    if (this.doinginprocess == true) return;

    if (this.doing == 'warp') {
      var moveto = this.getrandwalkpos() + '';
      var movetoa = moveto.split('-');
      this.currentx = Number(movetoa[0]);
      this.currenty = Number(movetoa[1]);
      this.sprite.x = (this.currentx - 1) * 32; //+width/2;
      this.sprite.y = (this.currenty - 1) * 32; //+width/2;
      this.wh.registry.list.phm.mapitemeffect(this.sprite, 'effect95_b');
    }
    if (this.doing == 'walk') {
      //this.sprite.play("mob_" + this.o.mobcode + "_idle");
      this.sprite.play(
        'critter_' + this.o['sprite'] + '_' + this.o['subcode'] + '_' + this.lastspriteface + '_idle'
      );
      //this.plog.log("mapcritter walk sprite",'critter_' + this.o['sprite'] + '_' + this.o['subcode'] + '_' + this.lastspriteface + '_idle')

      //this.doinginprocess=true;
      if (this.walkarray.length == 0) {
        var moveto = this.getrandwalkpos() + '';
        var movetoa = moveto.split('-');
        var startPos = { x: this.currentx, y: this.currenty };
        var goalPos = { x: movetoa[0], y: movetoa[1] };
        //this.plog.log("I'mma walk to ", movetoa);
        try {
          var myPathway = this.astar.findPath(startPos, goalPos);
        } catch (e) {
          this.plog.log("mapcritter astar findPath error", e);
          return;
        }
        //this.plog.log('critter mypathway', myPathway);
        this.walkarray = myPathway;
        this.doing = '';
        if (myPathway.length == 0) {
          //this.plog.log('critter mypathway problem,', myPathway);
          var tmpnextpos = this.findnextclosestwalkable();
          //this.plog.log('critter mypathway tmpnextpos,', tmpnextpos);
          if (tmpnextpos != '') {
            var tmpnextposa = tmpnextpos.split('-');
            var jumpup = 64;
            var timeline = this.wh.add.timeline([
              {
                at: 0,
                tween: {
                  targets: this.sprite,
                  onComplete: function () {
                    // this.plog.log("redirjump timelinecomplete ", wh, zwnameclickeda);
                    //this.chkwarppos_warp(wh, zwnameclickeda[1]);
                    this.currentx = tmpnextposa[0];
                    this.currenty = tmpnextposa[1];
                  }.bind(this),
                  x: { from: this.sprite.x, to: (Number(tmpnextposa[0]) - 1) * 32 },
                  ease: 'Sine.easeInOut',
                  duration: 400,
                  yoyo: false,
                }
              },
              {
                at: 0,
                tween: {
                  targets: this.sprite,
                  y: {
                    from: this.sprite.y,
                    to:
                      Math.min((Number(tmpnextposa[1]) - 1) * 32, this.sprite.y) -
                      jumpup,
                  },
                  ease: 'Sine.easeOut',
                  duration: 200,
                  yoyo: false,
                }
              },
              {
                at: 200,
                tween: {
                  targets: this.sprite,
                  y: {
                    from:
                      Math.min((Number(tmpnextposa[1]) - 1) * 32, this.sprite.y) -
                      jumpup,
                    to: (Number(tmpnextposa[1]) - 1) * 32,
                  },
                  ease: 'Sine.easeIn',
                  duration: 200,
                  yoyo: false,
                }
              }
            ]);
            timeline.play();
            //this.plog.log(timeline);
          }
        }
        return;
      }
      if (this.walkarray.length != 0) {
        var nextstop = this.walkarray.shift();
        var dir;
        if (nextstop[0] > this.currentx && nextstop[1] > this.currenty) {
          dir = 'down right';
          this.lastspriteface = 'r';
          dir = 'right';
        }
        if (nextstop[0] > this.currentx && nextstop[1] < this.currenty) {
          dir = 'up right';
          this.lastspriteface = 'r';
          dir = 'right';
        }
        if (nextstop[0] < this.currentx && nextstop[1] < this.currenty) {
          dir = 'up left';
          this.lastspriteface = 'l';
          dir = 'left';
        }
        if (nextstop[0] < this.currentx && nextstop[1] > this.currenty) {
          dir = 'down left';
          this.lastspriteface = 'l';
          dir = 'left';
        }
        if (nextstop[0] == this.currentx && nextstop[1] > this.currenty) {
          dir = 'down';
          this.lastspriteface = 'd';
          dir = 'down';
        }
        if (nextstop[0] == this.currentx && nextstop[1] < this.currenty) {
          dir = 'up';
          this.lastspriteface = 'u';
          dir = 'up';
        }
        if (nextstop[0] > this.currentx && nextstop[1] == this.currenty) {
          dir = 'right';
          this.lastspriteface = 'r';
        }
        if (nextstop[0] < this.currentx && nextstop[1] == this.currenty) {
          dir = 'left';
          this.lastspriteface = 'l';
        }
        var newcx = (Number(nextstop[0]) - 1) * 32;
        var newcy = (Number(nextstop[1]) - 1) * 32;
        this.currentx = nextstop[0];
        this.currenty = nextstop[1];
        this.showspritewalk(dir);
        //this.plog.log("walk", dir, nextstop);
        var walkspeednormal = this.actionspeed - 5;
        var tweenchar = this.wh.tweens.add({
          targets: this.sprite,
          x: newcx,
          y: newcy,
          ease: 'Linear',
          duration: walkspeednormal,
          repeat: 0,
          onUpdate: function () {
            //this.plog.log('onupdate critter tween',arguments,this);
            //this.plog.log(arguments);
          },
          onStart: function () {
            //this.plog.log('onStart'); this.plog.log(arguments);
          },
          onComplete: function (wh) {
            //this.plog.log('onComplete', this);
            this.doing = '';
            if (
              this.targets[0].mapmonobj.o.nature == 'teleport' &&
              this.targets[0].mapmonobj.walkarray.length == 0
            ) {
              this.targets[0].scene.phasermapservice.mapitemeffect(
                this.targets[0],
                'effect95_b'
              );
            }
          },
          onYoyo: function () {
            //this.plog.log('onYoyo'); this.plog.log(arguments);
          },
          onRepeat: function () {
            //this.plog.log('onRepeat'); this.plog.log(arguments);
          },
        });
      }
    }
    if (this.doing == 'idle') {
      this.sprite.play(
        'critter_' +
        this.o['sprite'] +
        '_' +
        this.o['subcode'] +
        '_' +
        this.lastspriteface +
        '_idle'
      );

      if (this.idlecountdown < 0) {
        var mobchkmax = 10;
        var mobchkmin = 5;
        var monnum = Math.floor(
          Math.random() * (mobchkmax - mobchkmin) + mobchkmin
        );

        this.idlecountdown = monnum;
        this.doing = '';
      }
      this.idlecountdown--;
      //this.plog.log("Idle it is", this.idlecountdown);
    }
    if (this.doing == 'sleep') {
      this.sprite.play('mob_' + this.o.mobcode + '_sleep');
      if (this.idlecountdown < 0) {
        var mobchkmax = 10;
        var mobchkmin = 5;
        var monnum = Math.floor(
          Math.random() * (mobchkmax - mobchkmin) + mobchkmin
        );

        this.idlecountdown = monnum;
        this.doing = '';
      }
      this.idlecountdown--;
      //this.plog.log("SLEEP it is", this.idlecountdown);
    }
  }
  showspritewalk(dir) {
    //this.sprite.alpha = 0.5;
    //this.sprite.setAlpha(0.5);
    if (dir == 'left') {
      this.sprite.play(
        'critter_' + this.o['sprite'] + '_' + this.o['subcode'] + '_l'
      );
    }
    if (dir == 'right') {
      this.sprite.play(
        'critter_' + this.o['sprite'] + '_' + this.o['subcode'] + '_r'
      );
    }
    if (dir == 'up') {
      this.sprite.play(
        'critter_' + this.o['sprite'] + '_' + this.o['subcode'] + '_u'
      );
    }
    if (dir == 'down') {
      this.sprite.play(
        'critter_' + this.o['sprite'] + '_' + this.o['subcode'] + '_d'
      );
    }
  }
}
