import { Injectable } from '@angular/core';
//import { AngularFireAuth } from '@angular/fire/compat/auth';
//import { AngularFireAuth } from "@angular/fire/compat/auth";
//import { AngularFireAuth } from "firebase/compat/auth";
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { RCVarsService } from './rcvars-service.service';
//import firebase from 'firebase/compat';
//import { Capacitor } from "@capacitor/core";

//import { AngularFireAuth } from "@angular/fire/auth";

//import { auth } from '@angular/fire/compat/auth';
//import { AngularFireAuthModule } from "@angular/fire/compat/auth";
//import { auth } from 'firebase';
//import auth from 'firebase/firebase-auth';
//import auth from '@angular/fire/compat/auth';
//import auth from '@angular/fire/compat/angular-fire-compat';
//import auth from '@angular/fire/compat/angular-fire-compat';
//import { auth } from '@angular/fire/compat/auth';
//import * as firebase from 'firebase/app';
//import
//import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  wh; // thethis
  userData;
  constructor(
    public angularFireAuth: AngularFireAuth,
    public rcvarsservice: RCVarsService
  ) {
    this.angularFireAuth.authState.subscribe(async (user) => {
      if (this.rcvarsservice == undefined) return;
      if (this.rcvarsservice.gameobj == undefined) return;
      if (this.rcvarsservice.gameobj.registry == undefined) return;
      //console.log('loginwithsocialfirebase1', user, this.rcvarsservice.gameobj.registry.list.thethis);
      //console.log('loginwithsocialfirebase2', user, this.rcvarsservice.gameobj.registry.list.thethis);
      if (user) {
        this.userData = user;
        //localStorage.setItem('firebaseuser', JSON.stringify(user));
        //JSON.parse(localStorage.getItem('user'));
        var chklogin_loggedin =
          await this.rcvarsservice.gameobj.registry.list.thethis.rcstorage.get(
            'loggedin'
          );
        if (chklogin_loggedin != 'yes') {
          //login if not logged in
          this.rcvarsservice.gameobj.registry.list.thethis.task_checkfirebaseauth =
            true;
          /*var connected =
            await this.rcvarsservice.gameobj.registry.list.thethis.rchttp.getcommon2(
              'loginwithsocialfirebase',
              { user: user }
            );
          console.log('loginwithsocialfirebase not loggedin', user, connected);*/
          //this.signoutUser();
        } else {
          //connect
          await this.rcvarsservice.gameobj.registry.list.thethis.rchttp.getcommon2(
            'connectfirebase',
            { user: user }
          );
          await this.signoutUser();
        }
      } else {
        //localStorage.setItem('firebaseuser', null);
        //JSON.parse(localStorage.getItem('user'));
        this.userData = null;
      }
    });
  }
  create() {
    console.log("firebaseauth create()");
    firebase.initializeApp(this);

  }
  createUser(value) {
    return new Promise<any>((resolve, reject) => {
      this.angularFireAuth
        .createUserWithEmailAndPassword(value.email, value.password)
        .then(
          (res) => resolve(res),
          (err) => reject(err)
        );
    });
  }

  signinUser(value) {
    return new Promise<any>((resolve, reject) => {
      this.angularFireAuth
        .signInWithEmailAndPassword(value.email, value.password)
        .then(
          (res) => resolve(res),
          (err) => reject(err)
        );
    });
  }

  signoutUser() {
    return new Promise<void>((resolve, reject) => {
      if (this.angularFireAuth.currentUser) {
        this.angularFireAuth
          .signOut()
          .then(() => {
            console.log('Firebase Sign out');
            resolve();
          })
          .catch(() => {
            reject();
          });
      }
    });
  }

  userDetails() {
    return this.angularFireAuth.user;
  }

  FacebookAuth() {
    //return this.AuthLogin(new firebase.auth.FacebookAuthProvider());
    console.log("firebase FacebookAuth()");
    this.angularFireAuth.signInWithRedirect(
      new firebase.auth.FacebookAuthProvider()
    );
  }

  async GoogleAuth() {
    console.log("firebase GoogleAuth()");
    /*
    let params;
    if (Capacitor.getPlatform()=="android") {
      params = {
        'webClientId': '884581827531-qpe9juhqamoclj359npqgle2frc8t5i5.apps.googleusercontent.com',
        'offline': true
      }
    }
    else {
      params = {}
    }
    this.google.login(params)
      .then((response) => {
        const { idToken, accessToken } = response
        this.onLoginSuccess(idToken, accessToken);
      }).catch((error) => {
        console.log(error)
        alert('error:' + JSON.stringify(error))
      });*/

    this.angularFireAuth.signInWithRedirect(
      new firebase.auth.GoogleAuthProvider()
    );

    /*
        this.angularFireAuth.signInWithCredential(
          new firebase.auth.AuthCredential()
        );
    */
    //this.angularFireAuth.signInWithPopup(new firebase.auth.GoogleAuthProvider());
  }
  TwitterAuth() {
    console.log("firebase TwitterAuth()");

    try {
      this.angularFireAuth.signInWithRedirect(
        new firebase.auth.TwitterAuthProvider()
      );
    } catch (err) {
      console.log('firebase err twitter', err);
    }
    //this.angularFireAuth.signInWithPopup(new firebase.auth.TwitterAuthProvider());
  }
  AppleAuth() {
    // this.angularFireAuth.signInWithPopup(new firebase.auth.);
  }
  // Auth logic to run auth providers
  AuthLogin(provider) {
    return this.angularFireAuth
      .signInWithPopup(provider)
      .then((result) => {
        console.log('You have been successfully logged in!');
      })
      .catch((error) => {
        console.log(error);
      });
  }





}
