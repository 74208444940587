import Copy from '../../../utils/array/Copy.js';
export default {
  savePathData() {
    if (this.pathDataSaved) {
      return this;
    }
    this.pathDataSave = [...this.pathData];
    this.pathData.length = 0;
    this.pathDataSaved = true;
    return this;
  },
  restorePathData() {
    if (!this.pathDataSaved) {
      return this;
    }
    Copy(this.pathData, this.pathDataSave);
    this.pathDataSave = undefined;
    this.pathDataSaved = false;
    return this;
  }
};