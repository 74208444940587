import { Injectable } from '@angular/core';
import { RCVarsService } from './rcvars-service.service';
import { RCOSDService } from './rcosd.service';
import { RCHTTP } from './rchttp.service';
import { plog } from './plog.service';

@Injectable({
  providedIn: 'root',
})
export class RCEquipmentService {
  shopdragbtn;
  title;
  lastclickpos = "";
  selectcircle;
  unequipbtn;
  shopclosebtn;
  itemdescr;
  allplusdescr;
  eqitems = [];
  eqitemsr = [];
  eqitemsp = [];
  itemwh = 54;
  shopguibg;
  charicon;
  wh;
  loading;
  firsttimerun = true;
  plog;

  constructor(
    public rcvarsservice: RCVarsService,
    public rcosd: RCOSDService,
    public rchttp: RCHTTP
  ) {
    this.plog = new plog();
  }

  loadingshow() {
    this.wh.equipmentcontainer.bringToTop(this.loading);
    this.loading.visible = true;
  }
  loadinghide() {
    this.loading.visible = false;
  }
  firsttimerunfunc() {
    if (this.firsttimerun == true) {
      try {
        ///this.wh.registry.list.gameitf.guiwindowclick(this.wh.equipmentcontainer);
      } catch (e) {
        this.plog.log("ERROR!", e);
      }
    }
    this.firsttimerun = false;
  }
  async show(wh, forcefocus = false) {
    if (forcefocus == true) this.firsttimerun = true;

    this.wh = wh;
    /*if (wh.equipmentcontainer != undefined) {
      wh.equipmentcontainer.visible = true;
      this.show_loaded(wh);
      return;
    }*/
    if (this.wh.equipmentcontainer != undefined) {
      await this.show_close(this.wh);
    }
    var width = wh.game.canvas.width;
    var height = wh.game.canvas.height;
    this.plog.log('equipment show', wh, this);

    const selectcircle = wh.add.image(0, 0, 'selectcircle');
    selectcircle.setOrigin(0.5, 0.5);
    selectcircle.visible = false;
    selectcircle.setAlpha(0.7);
    const shopguibg = wh.add.image(0, 0, 'equipguibg');
    shopguibg.setOrigin(0.5, 0.5);
    shopguibg.setInteractive({ pixelPerfect: true });
    shopguibg.removeAllListeners();
    this.shopguibg = shopguibg;
    //this.plog.log('showinventory var', this.rcvarsservice);
    this.selectcircle = selectcircle;

    this.shopclosebtn = wh.add.sprite(300, 300, "thesq", 'close');
    this.shopclosebtn.name = 'skipcntnrsizecal';
    this.shopclosebtn.setOrigin(0.5, 0.5);
    this.shopclosebtn.setPosition(155, -200);
    this.shopclosebtn.displayWidth = 40;
    this.shopclosebtn.displayHeight = 40;
    this.shopclosebtn.setInteractive();
    this.shopclosebtn.removeAllListeners();
    this.shopclosebtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        //this.scene.registry.list["thethis"].phm.floorclick(this);
        this.plog.log(
          'gc.npcbackbtn pointerup',
          pointer,
          localX,
          localY,
          event,
          this
        );
        this.show_close(wh);
      },
      this
    );

    var tmpframe = this.rcvarsservice.gameobj.anims.generateFrameNames(
      this.rcvarsservice.activechar['spriteorig'] + '',
      {
        prefix: 'walk-d-',
        start: 1,
        end: 2,
        zeroPad: 0,
      }
    );
    this.rcvarsservice.gameobj.anims.remove('mainchar-idle');
    var tmpx = this.rcvarsservice.gameobj.anims.create({
      key: 'mainchar-idle',
      frames: tmpframe,
      frameRate: 1,
      repeat: -1,
    });
    const charicon = this.rcvarsservice.gameobj.scene.scenes[0].add
      .sprite(
        100,
        80,
        this.rcvarsservice.activechar['spriteorig'],
        tmpframe[0]['frame']
      )
      .setPosition(0, 50)
      .setScale(3)
      .play('mainchar-idle'); //.setDisplayOrigin(0.5, 0.8);
    //this.plog.log('Phaser.Textures.FilterMode', Phaser.Textures.FilterMode);
    charicon.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);
    //Phaser.Textures.FilterMode.LINEAR or Phaser.Textures.FilterMode.NEAREST

    wh.equipmentcontainer = wh.add.container(300, 300);
    wh.equipmentcontainer._refsrv = this;
    wh.equipmentcontainer.visible = false;
    wh.equipmentcontainer.name = "equipmentcontainer";
    wh.equipmentcontainer.add(this.shopclosebtn);
    wh.equipmentcontainer.add(selectcircle);
    wh.equipmentcontainer.add(shopguibg);
    wh.equipmentcontainer.add(charicon);
    this.charicon = charicon;
    wh.equipmentcontainer.bringToTop(this.shopclosebtn);
    this.charicon.setPosition(-20, 50);
    var itemdescr = wh.add.text(0, 0, wh.trs("f:equipmentcontainer:Click Item to View Description===Click Item to View Description"), wh.fs["winguiitemdescr"]);
    wh.equipmentcontainer.add(itemdescr);
    itemdescr.setOrigin(0, 0);
    itemdescr.setPosition(-140, 179);
    this.itemdescr = itemdescr;

    this.allplusdescr = wh.add.text(0, 0, wh.trs("f:equipmentcontainer:Item Plus Bonus===Item Plus Bonus") + ': ', wh.fs["winguibardescr"]);
    wh.equipmentcontainer.add(this.allplusdescr);
    this.allplusdescr.setPosition(-100, -173);


    this.loading = wh.add.sprite(300, 300, 'pixelloading').play('pixelloading_b');
    this.loading.setOrigin(0.5, 0.5);
    this.loading.setPosition(0, 0);
    this.loading.displayWidth = this.loading.displayHeight = 60;
    wh.equipmentcontainer.add(this.loading);
    wh.equipmentcontainer.bringToTop(this.loading);

    //drag

    this.shopdragbtn = wh.add.image(300, 300, 'spacer');
    this.shopdragbtn.setOrigin(0.5, 0.5);
    this.shopdragbtn.setPosition(0, -200);
    this.shopdragbtn.displayWidth = 270;
    this.shopdragbtn.displayHeight = 60;
    this.shopdragbtn.setInteractive();
    this.shopdragbtn.removeAllListeners();
    this.shopdragbtn.on(
      'pointerout',
      function (pointer, localX, localY, event) {
        this.rcvarsservice.gameobj.scene.scenes[0].equipmentcontainer.dragactive =
          false;
      },
      this
    );
    this.shopdragbtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        this.rcvarsservice.gameobj.scene.scenes[0].equipmentcontainer.dragactive =
          false;
      },
      this
    );
    this.shopdragbtn.on(
      'pointerdown',
      function (pointer, localX, localY, event) {
        this.rcvarsservice.gameobj.registry.list.gameitf.guiwindowclick(
          this.rcvarsservice.gameobj.scene.scenes[0].equipmentcontainer
        );

        this.rcvarsservice.gameobj.scene.scenes[0].equipmentcontainer.dragactive =
          true;
        this.rcvarsservice.gameobj.scene.scenes[0].equipmentcontainer.dragactivex =
          this.rcvarsservice.gameobj.scene.scenes[0].equipmentcontainer.x;
        this.rcvarsservice.gameobj.scene.scenes[0].equipmentcontainer.dragactivey =
          this.rcvarsservice.gameobj.scene.scenes[0].equipmentcontainer.y;
        this.rcvarsservice.gameobj.scene.scenes[0].equipmentcontainer.dragactivepx =
          pointer.x;
        this.rcvarsservice.gameobj.scene.scenes[0].equipmentcontainer.dragactivepy =
          pointer.y;
      },
      this
    );
    this.rcvarsservice.gameobj.scene.scenes[0].equipmentcontainer.dragactive =
      false;
    this.rcvarsservice.gameobj.scene.scenes[0].equipmentcontainer.dragactivex = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].equipmentcontainer.dragactivey = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].equipmentcontainer.dragactivepx = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].equipmentcontainer.dragactivepy = 0;
    this.shopdragbtn.on(
      'pointermove',
      function (pointer, localX, localY, event) {
        //this.scene.registry.list["thethis"].phm.floorclick(this);
        //this.plog.log('shop pointermove', pointer, localX, localY, event, this);
        //this.showinventory_close(wh);
        if (
          this.rcvarsservice.gameobj.scene.scenes[0].equipmentcontainer
            .dragactive == true
        ) {
          var tmpx =
            this.rcvarsservice.gameobj.scene.scenes[0].equipmentcontainer
              .dragactivex;
          var tmpy =
            this.rcvarsservice.gameobj.scene.scenes[0].equipmentcontainer
              .dragactivey;
          var tmppx =
            this.rcvarsservice.gameobj.scene.scenes[0].equipmentcontainer
              .dragactivepx;
          var tmppy =
            this.rcvarsservice.gameobj.scene.scenes[0].equipmentcontainer
              .dragactivepy;
          var movedx = tmppx - pointer.x;
          var movedy = tmppy - pointer.y;
          //this.plog.log('drag', tmppx, pointer.x, movedx);

          this.rcvarsservice.gameobj.scene.scenes[0].equipmentcontainer.x =
            tmpx - movedx;
          this.rcvarsservice.gameobj.scene.scenes[0].equipmentcontainer.y =
            tmpy - movedy;
          //this.rcvarsservice.gameobj.scene.scenes[0].equipmentcontainer.y = pointer.y;
        }
      },
      this
    );
    wh.equipmentcontainer.add(this.shopdragbtn);
    wh.equipmentcontainer.bringToTop(this.shopdragbtn);

    /*
    this.plog.log("dragsize",shopguibg.width, shopguibg.height);
    wh.equipmentcontainer.setSize(shopguibg.width, shopguibg.height);
    wh.equipmentcontainer.setInteractive();
    wh.input.on('mousemove', function(pointer) {
      //check here is a scene has a selected gameObject
      //if(this.selected) { //to be able to detect if user really select something.
          wh.setPosition(pointer.x, pointer.y);
      //}
    }, this);*/
    let loader = new Phaser.Loader.LoaderPlugin(wh);

    //move
    wh.equipmentcontainer.setPosition((width / 2), (height / 2));

    wh.equipmentcontainer.x = width - this.shopguibg.width / 2 + 20;


    this.title = wh.add.text(0, 0, 'EQUIPMENT', wh.fs["winguititle42"]);
    //this.plog.log("winguititle fs", wh.fs["winguititle"])
    wh.equipmentcontainer.add(this.title);
    wh.equipmentcontainer.bringToTop(this.title);
    this.title.setPosition(0 - this.title.displayWidth / 2, -226);


    this.wh.registry.list.gameitf.guiwindowclick(this.wh.equipmentcontainer);

    /*
    //move to spritepack
        for (var vv in equipment) {
          var vdat = equipment[vv];
          var itemdat = this.rcvarsservice.allitem[vdat.item];
          loader.image(vdat["item"], this.rcvarsservice.rc_baseurl + "item/" + itemdat["pcode"] + "/" + itemdat["sprite"] + ".png");
    
          this.plog.log("load shop ", vv, vdat, itemdat);
        }*/
    loader.once(
      Phaser.Loader.Events.COMPLETE,
      () => {
        this.plog.log('Phaser.Loader.Events.COMPLETE');
        //this.plog.log(this);
        this.show_loaded(wh);
      },
      this
    );
    loader.start();
  }
  updateslotsize() {
    for (var vv in this.eqitems) {
      var vdat = this.eqitems[vv];
      this.plog.log('updateslotsize', vv, vdat);
      this.eqitems[vv].displayWidth = this.itemwh;
      this.eqitems[vv].displayHeight = this.itemwh;
      this.wh.equipmentcontainer.bringToTop(this.eqitems[vv]);
      //this.eqitems[vv].setInteractive(false);
      //this.eqitems[vv].setInteractive();
    }
  }
  async show_loaded(wh) {
    this.firsttimerunfunc();
    this.plog.log('show_loaded', wh, this);
    //chk sprite loaded 
    if (wh.textures.list[this.rcvarsservice.activechar['spriteorig']] == undefined) {
      await wh.registry.list.phm.loadcharsprite(this.rcvarsservice.activechar['spriteorig']);
    }
    this.loadinghide();

    //this.wh.registry.list.gameitf.guiwindowclick(this.wh.equipmentcontainer);

    var griddat = [];
    var griddatf = [];

    var width = wh.game.canvas.width;
    var height = wh.game.canvas.height;

    var equipment = this.rcvarsservice.activechar['equip'];
    this.plog.log('equipment eq', equipment);
    this.plog.log('equipmentcontainer', wh.equipmentcontainer, width, height);
    //wh.equipmentcontainer.setPosition((width / 2), (height / 2));
    var rarstr = "";
    if (wh.registry.list.rcvarpass.activechar['cbt']['raritycount'] != undefined &&
      wh.registry.list.rcvarpass.activechar['cbt']['raritycount'] > 0) {
      rarstr = " Rarity: " + wh.registry.list.rcvarpass.activechar['cbt']['raritycount']
    }
    this.allplusdescr.setText(
      'Item Plus Bonus: ' +
      wh.registry.list.rcvarpass.activechar['allplus'] +
      ' (+' +
      wh.registry.list.rcvarpass.activechar['cbt']['allpluspercent'] +
      '%)'+rarstr
    );
    var equiplist = [
      'weapon1',
      'weapon2',
      'head',
      'armor',
      'pant',
      'boot',
      'hand',
      'earring',
      'ring',
      'necklace',
      'bracelet',
    ];
    for (let i = 0; i < equiplist.length; i++) {
      var vname = equiplist[i];
      if (this.eqitemsp[vname] != undefined) this.eqitemsp[vname].destroy();
      if (this.eqitems[vname] != undefined) this.eqitems[vname].destroy();
      if (this.eqitemsr[vname] != undefined) this.eqitemsr[vname].destroy()

      if (this.eqitemsp[vname] != undefined) this.eqitemsp[vname] = undefined;
      if (this.eqitems[vname] != undefined) this.eqitems[vname] = undefined;
      if (this.eqitemsr[vname] != undefined) this.eqitemsr[vname] = undefined;
    }
    for (let i = 0; i < equiplist.length; i++) {
      var vname = equiplist[i];
      this.eqitemsr[vname] = wh.add.sprite(16, 16, "spacer");


      this.eqitemsp[vname] = wh.add.text(0, 0, ' ', wh.fs["wingui_plus"]);

      //this.plog.log('eqitems i', i, vname);
      this.eqitems[vname] = wh.add.image(0, 0, 'selectcircle');
      this.eqitems[vname].displayWidth = this.itemwh;
      this.eqitems[vname].name = vname;
      this.eqitems[vname].displayHeight = this.itemwh;
      //this.eqitems[vname].setInteractive();
      if (vname == 'weapon1') {
        this.eqitems[vname].setPosition(-118, -33);
      }
      if (vname == 'weapon2') {
        this.eqitems[vname].setPosition(-118, 27);
      }
      if (vname == 'head') {
        this.eqitems[vname].setPosition(118, -93);
      }
      if (vname == 'armor') {
        this.eqitems[vname].setPosition(118, -33);
      }
      if (vname == 'hand') {
        this.eqitems[vname].setPosition(60, -33);
      }
      if (vname == 'pant') {
        this.eqitems[vname].setPosition(118, 27);
      }
      if (vname == 'boot') {
        this.eqitems[vname].setPosition(118, 87);
      }
      if (vname == 'ring') {
        this.eqitems[vname].setPosition(-118, 144);
      }
      if (vname == 'necklace') {
        this.eqitems[vname].setPosition(-58, 144);
      }
      if (vname == 'earring') {
        this.eqitems[vname].setPosition(0, 144);
      }
      if (vname == 'bracelet') {
        this.eqitems[vname].setPosition(60, 144);
      }
      this.eqitemsp[vname].x = this.eqitems[vname].x - 27;
      this.eqitemsp[vname].y = this.eqitems[vname].y + 12;

      /*this.eqitems[vname].on("pointerover", function () {
        this.plog.log("equip i over", arguments, this);
      });*/

      wh.equipmentcontainer.add(this.eqitems[vname]);
      wh.equipmentcontainer.add(this.eqitemsr[vname]);
      wh.equipmentcontainer.add(this.eqitemsp[vname]);
      wh.equipmentcontainer.bringToTop(this.eqitemsr[vname]);
      wh.equipmentcontainer.bringToTop(this.eqitems[vname]);
      wh.equipmentcontainer.bringToTop(this.eqitemsp[vname]);
    }


    const unequipbtn = wh.add.sprite(0, 0, "thesb", "unequip");//wh.add.image(0, 0, "unequipbtn");
    unequipbtn.setOrigin(0.5, 0.5);
    unequipbtn.setPosition(-120, -155);
    unequipbtn.setAlpha(1);
    unequipbtn.displayHeight = 40;
    unequipbtn.displayWidth = 90;
    wh.equipmentcontainer.add(unequipbtn);
    wh.equipmentcontainer.bringToTop(this.unequipbtn);

    unequipbtn.setInteractive();
    unequipbtn.removeAllListeners();
    unequipbtn.on("pointerup", function () {
      this.plog.log("UNequipbtn down", wh, arguments);
      var selecteditem = this.finditembyequippos(
        wh.registry.list.rcvarpass.activechar['equip'],
        this.lastclickpos
      );
      this.unequip(selecteditem);
      //if (wh.registry.list.rcosd.unequipbtn != undefined) {
      this.unequipbtn.visible = false;
      //}
    }.bind(this));
    this.unequipbtn = unequipbtn;
    //this.plog.log('eqitems', this.eqitems);

    //this.rcvarsservice.gameobj.registry.list.gameitf.guiwindowclick(this.rcvarsservice.gameobj.scene.scenes[0].equipmentcontainer);
    this.charicon.play(this.rcvarsservice.activechar['spriteorig'] + '-idle');
    this.charicon.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);

    var equipment = this.rcvarsservice.activechar['equip'];
    //this.plog.log('equipment list', equipment);

    for (var vv in this.eqitems) {
      var vdat = this.eqitems[vv];
      this.eqitemsp[vv].setText('');
      this.eqitems[vv].setTexture('spacer');
      var isequipped = this.finditembyequippos(
        wh.registry.list.rcvarpass.activechar['equip'],
        vv
      );
      if (isequipped == false) {
        this.plog.log('set event for unequipped slot ', vv);
        this.eqitems[vv].removeAllListeners();
        //this.eqitems[vv].setInteractive(new Phaser.Geom.Rectangle(-25, -25, 54, 54), Phaser.Geom.Rectangle.Contains);
        this.eqitems[vv].setInteractive();
        //this.eqitems[vv].input.hitArea.setTo(-25, -25, 25, 25);
        // this.wh.input.enableDebug(this.eqitems[vv], 0xff00ff);

        this.eqitems[vv].on('pointerover', function () {
          var ttstr = wh.registry.list.rcvarpass.equippos[this.name];
          var tmpitem = this.scene.registry.list.equipment.eqitems[this.name];
          //this.scene.plog.error(ttstr);
          wh.registry.list.gameitf.gamemenutooltip(
            wh,
            tmpitem,
            wh.trs("t:equippos:code:name:" + ttstr['code'] + "===" + ttstr['name']),
            'top'
          );
          //this.scene.registry.list.equipment.updateslotsize();;
        });
      } else {
        this.plog.log('skip equipped ', vv);
      }
      this.eqitems[vv].displayWidth = this.itemwh;
      this.eqitems[vv].displayHeight = this.itemwh;
      //this.eqitems[vv].setInteractive();
    }

    for (var vv in equipment) {
      var vdat = equipment[vv];
      var itemdat = this.rcvarsservice.allitem[vdat.item];
      if (
        Number(vdat['itemplus']) != 0 &&
        Number(vdat['itemplus']) + '' != 'NaN'
      ) {
        this.eqitemsp[vdat['equippos']].setText('+' + Number(vdat['itemplus']));
      }
      if (vdat["rarity"] == "rar") {
        this.eqitemsr[vdat['equippos']].setTexture("effrar").play("effrar_b");
      }
      if (vdat["rarity"] == "leg") {
        this.eqitemsr[vdat['equippos']].setTexture("effleg").play("effleg_b");
      }
      if (vdat["rarity"] == "ult") {
        this.eqitemsr[vdat['equippos']].setTexture("effleg").play("effleg_b");
        this.eqitemsr[vdat['equippos']].setTint(0xff0000);
      }
      if (vdat["rarity"] == "rel") {
        this.eqitemsr[vdat['equippos']].setTexture("effleg").play("effleg_b");
        this.eqitemsr[vdat['equippos']].setTint(0x0000ff);
      }
      this.eqitemsr[vdat['equippos']].displayHeight = this.eqitemsr[vdat['equippos']].displayWidth = 128;
      this.eqitemsr[vdat['equippos']].setPosition(this.eqitems[vdat['equippos']].x, this.eqitems[vdat['equippos']].y);

      //this.plog.log('equipped i', vdat['equippos'], vdat, itemdat);
      this.eqitems[vdat['equippos']].removeAllListeners();
      //this.eqitems[vdat["equippos"]].setTexture("shopguibg");
      this.eqitems[vdat['equippos']].setTexture('item_tp', vdat['item']);
      //this.eqitems[vdat["equippos"]].setTexture(vdat["item"]);
      //this.eqitems[vdat["equippos"]].visible = false;
      //this.eqitems[vdat["equippos"]].visible = true;
      this.eqitems[vdat['equippos']].displayWidth = this.itemwh;
      this.eqitems[vdat['equippos']].displayHeight = this.itemwh;
      this.eqitems[vdat['equippos']].setInteractive();
      //this.eqitems[vdat["equippos"]].setInteractive(new Phaser.Geom.Rectangle(-25, -25, 54, 54), Phaser.Geom.Rectangle.Contains);
      /*
      //this.eqitems[vdat["equippos"]].setInteractive();*/
      griddat.push(this.eqitems[vdat['equippos']]);
      griddatf.push(this.eqitems[vdat['equippos']]);

      this.eqitems[vdat['equippos']].on('pointerup', function () {
        this.scene.plog.log('pointerup equipment i', this.name, this);
        this.scene.registry.list.equipment.show_itemclick(this.scene, this);
      });
      this.eqitems[vdat['equippos']].on('pointerover', function () {
        var ttstr = wh.registry.list.rcvarpass.equippos[this.name];
        var tmpitem = this.scene.registry.list.equipment.eqitems[this.name];
        //this.plog.log(tmpitem);
        wh.registry.list.gameitf.gamemenutooltip(
          wh,
          tmpitem,
          wh.trs("t:equippos:code:name:" + ttstr['code'] + "===" + ttstr['name']),
          'top'
        );
      });
    }

    this.wh.equipmentcontainer.visible = true;
    this.wh.registry.list.resizeman.RCMapResizeManager_work(this.wh);
    griddat.push(this.shopclosebtn);
    griddat.push(this.unequipbtn);
    this.wh.registry.list.gp.guigrid(griddat, griddatf);
  }

  show_itemclick(wh, item) {
    this.selectcircle.visible = true;
    //var namea = item.name.split("-");
    this.plog.log('show_itemclick', wh, item);
    this.lastclickpos = item.name;
    this.selectcircle.displayWidth = this.itemwh;
    this.selectcircle.displayHeight = this.itemwh;
    item.displayWidth = this.itemwh;
    item.displayHeight = this.itemwh;
    this.selectcircle.setPosition(item.x, item.y);
    //wh.equipmentcontainer.sendToBack(wh.shopguibg);
    wh.equipmentcontainer.bringToTop(this.selectcircle);
    wh.equipmentcontainer.bringToTop(item);
    this.plog.log(
      'show_itemclick selectcircle',
      item.name,
      item,
      wh.registry.list.rcvarpass.activechar
    );
    var iteminfo = this.finditembyequippos(
      wh.registry.list.rcvarpass.activechar['equip'],
      item.name
    );
    //wh.registry.list.rcvarpass.activechar["equip"][name];
    var iteminfo2 = wh.registry.list.rcvarpass.allitem[iteminfo['item']];

    //this.selectcircle.width=
    //this.itemdescr.setText(iteminfo2["name"] + " x" + iteminfo["amnt"]);
    this.itemdescr.setText(iteminfo2['name']);
    this.rcosd.show(wh, 'equipment', iteminfo2, iteminfo);
  }
  async show_close(wh) {
    wh.equipmentcontainer.visible = false;
    await wh.registry.list.gameitf.closegui(wh, wh.equipmentcontainer);

    for (const k in this.wh.equipmentcontainer.list) {
      try {
        await this.wh.equipmentcontainer.remove(this.wh.equipmentcontainer.list[k], true);
        //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list[k].destroy();
      } catch (e) {
        this.plog.log("ERROR! equipmentcontainer.destroy() ", e);
      }
    }
    try {
      await this.wh.equipmentcontainer.destroy(true);
    } catch (e) {
      this.plog.log("ERROR! equipmentcontainer.destroy() ", e);
    }
    wh.equipmentcontainer = undefined;
  }
  finditembyequippos(arr, pos) {
    //this.plog.log('finditembyequippos', arr, pos);
    for (var vv in arr) {
      var vdat = arr[vv];
      if (vdat['equippos'] == pos) {
        //this.plog.log('finditembyequippos found', vdat);
        return vdat;
      }
    }
    //this.plog.log('finditembyequippos NOT FOUND');
    return false;
  }
  async unequip(wh) {
    this.plog.log('rcinventory equip', wh, this);
    this.loadingshow();
    var res = await this.rchttp.unequip(wh['id']);
    await this.rcvarsservice.setactivechar(
      res,
      this.rcvarsservice.activechar['id']
    );
    //this.plog.log("rcinventory equip", this);
    await this.rcvarsservice.gameobj.registry.list.gameitf.charinfoupdated(
      this.rcvarsservice.gameobj.scene.scenes[0]
    );
    this.charicon.play(this.rcvarsservice.activechar['spriteorig'] + '-idle');
    this.charicon.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);
    this.loadinghide();
  }

  rebuildguigrid() {
    this.plog.log("rebuildguigrid();b", this.wh.kbfocus);
    this.show_loaded(this.wh);
    this.plog.log("rebuildguigrid();a", this.wh.kbfocus);
  }
}
