import { Injectable } from '@angular/core';
import { RCVarsService } from './rcvars-service.service';
import { RCOSDService } from './rcosd.service';
import { RCHTTP } from './rchttp.service';
import { RCRequirementService } from './rcrequirement.service';
import { plog } from './plog.service';

@Injectable({
  providedIn: 'root'
})
export class PartyMapService {
  shopdragbtn;
  shopbackbtn;
  shopfwdbtn;
  plog;
  myparty;
  loading;
  resptotalpage;
  respperpage = 5;
  joinbtn = [];
  title;
  inputtext;
  currentresppage = 0;
  countparty = 0;
  questcompletebtn;
  sendbtn;
  msglist = [];
  qname;
  itemdescr;
  roomid = "";
  wh;
  shopguibg;
  shopclosebtn;
  mapid;
  createbtn;
  aq;//active quest
  partyname = [];
  partydescr = [];

  constructor(
    public rcvarsservice: RCVarsService,
    public rcosd: RCOSDService,
    public rchttp: RCHTTP,
    public req: RCRequirementService
  ) {
    this.plog = new plog();
    this.plog.setLevel(1);                                 
  }

  firsttimerun = true;
  firsttimerunfunc() {
    if (this.firsttimerun == true) {
      this.wh.registry.list.gameitf.guiwindowclick(this.wh.partymapcontainer);
    }
    this.firsttimerun = false;
  }

  loadingshow() {
    this.wh.partymapcontainer.bringToTop(this.loading);
    this.loading.visible = true;
  }
  loadinghide() {
    this.loading.visible = false;
  }
  async show(wh, forcefocus = false) {
    this.plog.log("partymap show", wh, mapid, this);
    if (forcefocus == true) this.firsttimerun = true;
    this.wh = wh;
    var mapid = this.wh.registry.list.rcvarpass.activechar["map"];
    this.mapid = mapid;
    //await this.rchttp.updatecharinfo();
    this.aq = await this.wh.registry.list.rchttp.getcommon2("partymap", { mapid: this.mapid, mode: "get" });
    //this.show_close();
    if (wh.partymapcontainer != undefined) {
      await this.show_close(this.wh);
    }
    var width = wh.game.canvas.width;
    var height = wh.game.canvas.height;
    //this.plog.log("partymap show", wh, this);


    const shopguibg = wh.add.image(0, 0, "blankbarguibg");//questguibg
    shopguibg.setOrigin(0.5, 0.5);
    shopguibg.setInteractive({ pixelPerfect: true });
    shopguibg.removeAllListeners();
    this.shopguibg = shopguibg;
    this.plog.log("questguibg var", this.rcvarsservice);

    this.shopclosebtn = wh.add.sprite(300, 300, "thesq", 'close');
    this.shopclosebtn.name = 'skipcntnrsizecal';
    this.shopclosebtn.setOrigin(0.5, 0.5);
    this.shopclosebtn.setPosition(155, -200);
    this.shopclosebtn.displayWidth = 40;
    this.shopclosebtn.displayHeight = 40;
    this.shopclosebtn.setInteractive();
    this.shopclosebtn.removeAllListeners();
    this.shopclosebtn.on('pointerup', function (pointer, localX, localY, event) {
      //this.scene.registry.list["thethis"].phm.floorclick(this);
      this.plog.log("gc.npcbackbtn pointerup", pointer, localX, localY, event, this);
      this.show_close(wh);
    }, this);



    wh.partymapcontainer = wh.add.container(300, 300);
    wh.partymapcontainer._refsrv = this;
    wh.partymapcontainer.name = "partymapcontainer";
    wh.partymapcontainer.add(this.shopclosebtn);
    wh.partymapcontainer.add(shopguibg);
    wh.partymapcontainer.bringToTop(this.shopclosebtn);
    //Click Item to View Description
    this.itemdescr = wh.add.text(0, 0, " ... ", wh.fs["winguiitemdescr"]);
    wh.partymapcontainer.add(this.itemdescr);
    this.itemdescr.setOrigin(0, 0);
    this.itemdescr.setPosition(0-140, 150);
    this.itemdescr.setWordWrapWidth(300,true);


    this.shopbackbtn = wh.add.sprite(0, 0, "thesb", "backsign");//wh.add.image(0, 0, 'listbackbtn');
    this.shopbackbtn.setOrigin(0.5, 0.5);
    this.shopbackbtn.setInteractive();
    this.shopbackbtn.removeAllListeners();
    this.shopfwdbtn = wh.add.sprite(0, 0, "thesb", "forwardsign");//wh.add.image(0, 0, 'listfwdbtn');
    this.shopfwdbtn.setOrigin(0.5, 0.5);
    this.shopfwdbtn.setInteractive();
    this.shopfwdbtn.removeAllListeners();

    this.shopbackbtn.displayWidth = 100;
    this.shopfwdbtn.displayWidth = 100;
    this.shopbackbtn.displayHeight = 40;
    this.shopfwdbtn.displayHeight = 40;
    this.shopbackbtn.setPosition(-110, 220);
    this.shopfwdbtn.setPosition(110, 220);
    wh.partymapcontainer.add(this.shopfwdbtn);
    wh.partymapcontainer.add(this.shopbackbtn);
    wh.partymapcontainer.bringToTop(this.shopfwdbtn);
    wh.partymapcontainer.bringToTop(this.shopbackbtn);

    this.shopbackbtn.removeAllListeners();
    this.shopfwdbtn.removeAllListeners();
    this.shopbackbtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        this.plog.log('shop shopbackbtn', this);
        this.showpage('back');
      },
      this
    );

    this.shopfwdbtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        this.plog.log('shop shopfwdbtn', this);
        this.showpage('next');
      },
      this
    );


    this.loading = wh.add.sprite(300, 300, 'pixelloading').play('pixelloading_b');
    this.loading.setOrigin(0.5, 0.5);
    this.loading.setPosition(0, 0);
    this.loading.displayWidth = this.loading.displayHeight = 60;
    wh.partymapcontainer.add(this.loading);
    wh.partymapcontainer.bringToTop(this.loading);

    //drag


    this.shopdragbtn = wh.add.image(300, 300, 'spacer');
    this.shopdragbtn.setOrigin(0.5, 0.5);
    this.shopdragbtn.setPosition(0, -200);
    this.shopdragbtn.displayWidth = 270;
    this.shopdragbtn.displayHeight = 60;
    this.shopdragbtn.setInteractive();
    this.shopdragbtn.removeAllListeners();
    this.shopdragbtn.on('pointerout', function (pointer, localX, localY, event) {
      this.rcvarsservice.gameobj.scene.scenes[0].partymapcontainer.dragactive = false;
    }, this);
    this.shopdragbtn.on('pointerup', function (pointer, localX, localY, event) {
      this.rcvarsservice.gameobj.scene.scenes[0].partymapcontainer.dragactive = false;
    }, this);
    this.shopdragbtn.on('pointerdown', function (pointer, localX, localY, event) {
      this.rcvarsservice.gameobj.registry.list.gameitf.guiwindowclick(this.rcvarsservice.gameobj.scene.scenes[0].partymapcontainer);

      this.rcvarsservice.gameobj.scene.scenes[0].partymapcontainer.dragactive = true;
      this.rcvarsservice.gameobj.scene.scenes[0].partymapcontainer.dragactivex = this.rcvarsservice.gameobj.scene.scenes[0].partymapcontainer.x;
      this.rcvarsservice.gameobj.scene.scenes[0].partymapcontainer.dragactivey = this.rcvarsservice.gameobj.scene.scenes[0].partymapcontainer.y;
      this.rcvarsservice.gameobj.scene.scenes[0].partymapcontainer.dragactivepx = pointer.x;
      this.rcvarsservice.gameobj.scene.scenes[0].partymapcontainer.dragactivepy = pointer.y;
    }, this);
    this.rcvarsservice.gameobj.scene.scenes[0].partymapcontainer.dragactive = false;
    this.rcvarsservice.gameobj.scene.scenes[0].partymapcontainer.dragactivex = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].partymapcontainer.dragactivey = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].partymapcontainer.dragactivepx = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].partymapcontainer.dragactivepy = 0;
    this.shopdragbtn.on('pointermove', function (pointer, localX, localY, event) {
      //this.scene.registry.list["thethis"].phm.floorclick(this);
      //this.plog.log("shop pointermove", pointer, localX, localY, event, this);
      //this.showinventory_close(wh);
      if (this.rcvarsservice.gameobj.scene.scenes[0].partymapcontainer.dragactive == true) {
        var tmpx = this.rcvarsservice.gameobj.scene.scenes[0].partymapcontainer.dragactivex;
        var tmpy = this.rcvarsservice.gameobj.scene.scenes[0].partymapcontainer.dragactivey;
        var tmppx = this.rcvarsservice.gameobj.scene.scenes[0].partymapcontainer.dragactivepx;
        var tmppy = this.rcvarsservice.gameobj.scene.scenes[0].partymapcontainer.dragactivepy;
        var movedx = tmppx - pointer.x;
        var movedy = tmppy - pointer.y;
        //this.plog.log("drag", tmppx, pointer.x, movedx);

        this.rcvarsservice.gameobj.scene.scenes[0].partymapcontainer.x = tmpx - movedx;
        this.rcvarsservice.gameobj.scene.scenes[0].partymapcontainer.y = tmpy - movedy;
        //this.maskg.setPosition(this.rcvarsservice.gameobj.scene.scenes[0].partymapcontainer.x,this.rcvarsservice.gameobj.scene.scenes[0].partymapcontainer.y)
        //this.rcvarsservice.gameobj.scene.scenes[0].partymapcontainer.y = pointer.y;
      }
    }, this);
    wh.partymapcontainer.add(this.shopdragbtn);
    wh.partymapcontainer.bringToTop(this.shopdragbtn);


    this.title = wh.add.text(0, 0, 'PARTY', wh.fs["winguititle"]);
    //this.plog.log("winguititle fs", wh.fs["winguititle"])
    wh.partymapcontainer.add(this.title);
    wh.partymapcontainer.bringToTop(this.title);
    this.title.setPosition(0 - this.title.displayWidth / 2, -223);
    //var equipment = this.rcvarsservice.activechar["equip"];
    //this.plog.log("equipment eq", equipment);
    //this.plog.log("partymapcontainer", wh.partymapcontainer, width, height);
    wh.partymapcontainer.setPosition((width / 2), (height / 2));
    let loader = new Phaser.Loader.LoaderPlugin(wh);

    loader.once(Phaser.Loader.Events.COMPLETE, () => {
      this.plog.log("Phaser.Loader.Events.COMPLETE");
      this.plog.log(this);
      this.show_loaded();
    }, this);
    loader.start();
  }
  async show_loaded(pagemode = 'normal') {
    if (this.wh.partymapcontainer == undefined) {
      this.plog.warn("partymap show_loaded() container undefined");
      return;
    }
    //this.rcvarsservice.gameobj.registry.list.gameitf.guiwindowclick(this.rcvarsservice.gameobj.scene.scenes[0].partymapcontainer);
    this.plog.log("partymap show_loaded", this.aq);
    this.firsttimerunfunc();

    this.aq = await this.wh.registry.list.rchttp.getcommon2("partymap", { mapid: this.mapid, mode: "get" });

    if (this.qname != undefined) {
      this.qname.destroy();
      this.qname = undefined;
    }
    if (this.sendbtn != undefined) {
      this.sendbtn.destroy();
      this.sendbtn = undefined;
    }
    if (this.myparty != undefined) {
      this.myparty.destroy();
      this.myparty = undefined;
    }

    for (var i = 0; i < 100; i++) {
      if (this.partyname[i] != undefined) {
        this.partyname[i].destroy();
        this.partyname[i] = undefined;
      }
      if (this.partydescr[i] != undefined) {
        this.partydescr[i].destroy();
        this.partydescr[i] = undefined;
      }
      if (this.joinbtn[i] != undefined) {
        this.joinbtn[i].destroy();
        this.joinbtn[i] = undefined;
      }
    }
    if (this.createbtn != undefined) {
      this.createbtn.destroy();
      this.createbtn = undefined;
    }
    if (pagemode == '1st') {
      this.currentresppage = 1;
    }
    if (pagemode == 'normal') {
      this.currentresppage = 1;
    }
    if (pagemode == 'retainpage') {
      if (this.currentresppage == 0 || this.currentresppage == undefined)
        this.currentresppage = 1;
    }
    if (this.aq == undefined) {
      this.show_close(this.wh);
      return;
    }
    if (this.aq["party"] == undefined) {
      this.show_close(this.wh);
      return;
    }

    this.countparty = 0;
    for (const k in this.aq["party"]) {
      //var v = this.aq["party"][k];
      this.countparty++;
    }
    if (this.countparty > 5) {
      //pages
      this.respperpage = 5;
      this.resptotalpage = Math.ceil(this.countparty / this.respperpage);
    }
    var mapname = this.wh.registry.list.rcvarpass.allmap[this.mapid];
    this.qname = this.wh.add.text(0, 0, "" + mapname["name"], this.wh.fs["questinfo_title"]);
    this.qname.setWordWrapWidth(this.shopguibg.displayWidth * 0.8);
    this.itemdescr.setText(this.wh.trs("f:partymapcontainer:Active party in:===Active party in:")+" " + mapname["name"] + "\n"+this.wh.trs("f:partymapcontainer:Max level difference is 20, or you won't get XP,KI===Max level difference is 20, or you won't get XP,KI"));
    this.plog.log("XXXX",this.wh.partymapcontainer,this.qname);
    this.wh.partymapcontainer.add(this.qname);
    this.wh.partymapcontainer.bringToTop(this.qname);
    this.qname.setPosition(0 - (this.qname.displayWidth / 2), -170);
    this.createbtn = this.wh.add.sprite(0, 0, "thesb", "create");
    this.wh.partymapcontainer.add(this.createbtn);
    this.wh.partymapcontainer.bringToTop(this.createbtn);
    this.createbtn.setPosition(-105, -120);
    this.createbtn.removeAllListeners();
    this.createbtn.setInteractive();
    this.createbtn.on("pointerup", async function () {
      var newname;
      newname = await this.wh.registry.list.rctoast.rcentertext(
        this.wh,
        this.wh.trs("f:partymapcontainer:Enter Party Name===Enter Party Name"),
        this.wh.trs("f:partymapcontainer:If you have another party in any map===If you have another party in any map,they will be disbanded"),
        "Party: " + this.wh.registry.list.rcvarpass.activechar["name"]
      );
      if (newname != "cancel") {
        var res = await this.wh.registry.list.rchttp.getcommon2("partymap", { mode: "create", mapid: this.mapid, newname: newname });
        //this.show_loaded("1st");
        this.wh.registry.list.partymy.show(this.wh);
        this.show_close(this.wh);
      }

    }, this);

    this.myparty = this.wh.add.text(0, 0, this.aq["myparty"], this.wh.fs["winguilist_title"]);
    if (this.aq["myparty"] == "") {
      this.myparty.setText(this.wh.trs("f:partymapcontainer:No current party===No current party"));
    } else {
      this.myparty.setText("Current: " + this.aq["myparty"]);
    }
    this.myparty.setWordWrapWidth(190);
    this.wh.partymapcontainer.add(this.myparty);
    this.wh.partymapcontainer.bringToTop(this.myparty);
    this.myparty.setPosition(-40, -140);

    var rowh = 50;
    var iconwh = 40;
    var i = 0;
    var rowi = 0;
    for (var vv in this.aq["party"]) {
      var vdat = this.aq["party"][vv];

      this.partyname[i] = this.wh.add.text(0, 0, vdat["name"], this.wh.fs["winguilist_descr_black"]);
      this.partyname[i].name = "" + vdat["id"];
      this.wh.partymapcontainer.add(this.partyname[i]);
      this.wh.partymapcontainer.bringToTop(this.partyname[i]);
      this.partyname[i].setPosition(-140, (-100) + (rowh * rowi));
      this.partydescr[i] = this.wh.add.text(0, 0, vdat["descr"], this.wh.fs["winguilist_descr"]);
      this.partydescr[i].name = "" + vdat["id"];
      this.wh.partymapcontainer.add(this.partydescr[i]);
      this.wh.partymapcontainer.bringToTop(this.partydescr[i]);
      this.partydescr[i].setWordWrapWidth(270);

      this.partydescr[i].setPosition(-140 + 10, (-100) + (rowh * rowi) + 19);
      this.joinbtn[i] = this.wh.add.sprite(0, 0, "thesb", "join");
      this.joinbtn[i].displayHeight = 30;
      this.joinbtn[i].scaleX = this.joinbtn[i].scaleY;
      this.joinbtn[i].name = vdat["id"];
      this.wh.partymapcontainer.add(this.joinbtn[i]);
      this.wh.partymapcontainer.bringToTop(this.joinbtn[i]);
      this.joinbtn[i].setPosition(100 + 10, (-100) + (rowh * rowi) + 25);

      this.joinbtn[i].removeAllListeners();
      this.joinbtn[i].setInteractive();
      this.joinbtn[i].on("pointerup", async function () {
        this.scene.registry.list.partymap.join(this.name);

      });

      i++;
      rowi++;
      if (rowi % 5 == 0) rowi = 0;
    }

    this.loadinghide();
    this.showpage('1st');

  }
  async show_close(wh) {
    if (wh.partymapcontainer!=undefined)
    wh.partymapcontainer.visible = false;
    await wh.registry.list.gameitf.closegui(wh, wh.partymapcontainer);
    for (let desi = 0; desi < 15; desi++) {


      for (const k in this.wh.partymapcontainer.list) {
        try {
          await this.wh.partymapcontainer.remove(this.wh.partymapcontainer.list[k], true);
          //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list[k].destroy();
        } catch (e) {
          this.plog.log("ERROR! partymapcontainer.destroy() ", e);
        }
      }

      for (const k in this.wh.partymapcontainer.list) {
        try {
          await this.wh.partymapcontainer.remove(this.wh.partymapcontainer.list[k], true);
          //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list[k].destroy();
        } catch (e) {
          this.plog.log("ERROR! partymapcontainer.destroy() ", e);
        }
      }
    }
      wh.partymapcontainer = undefined;

    }
    showpage(viewpage) {
      this.loadinghide();
      if (viewpage == 'back') {
        if (this.currentresppage > 1) this.currentresppage--;
      }
      if (viewpage == '1st') {
        this.currentresppage = 1;
      }
      if (viewpage == 'next') {
        if (this.currentresppage < this.resptotalpage) this.currentresppage++;
      }
      for (var i = 0; i <= 100; i++) {
        //this.plog.log(this.shopitemlist[i]);
        if (this.partyname[i] != undefined) {
          this.partyname[i].visible = false;
        }
        if (this.partydescr[i] != undefined) {
          this.partydescr[i].visible = false;
        }
        if (this.joinbtn[i] != undefined) {
          this.joinbtn[i].visible = false;
        }
      }
      var numfrom = this.respperpage * (this.currentresppage - 1);
      var numto = this.respperpage * this.currentresppage;
      this.plog.log('showinventory_showpage', viewpage, this.currentresppage, 'fromto', numfrom, numto, 'perpage', this.respperpage);

      var griddat = [];
      var griddatf = [];

      griddat.push(this.shopclosebtn);

      griddat.push(this.shopfwdbtn);
      griddat.push(this.shopbackbtn);
      griddat.push(this.createbtn);
      griddatf.push(this.createbtn);

      for (var i = numfrom; i < numto; i++) {
        if (this.partyname[i] != undefined) {
          this.partyname[i].visible = true;
          this.partydescr[i].visible = true;
          this.joinbtn[i].visible = false;
          griddat.push(this.joinbtn[i]);
          griddatf.push(this.joinbtn[i]);
          if (this.joinbtn[i] != undefined && this.joinbtn[i].name != undefined) {
            //this.plog.log("decisbv",i,namea,this.lastdat);
            if (this.aq["party"][this.joinbtn[i].name] != undefined) {
              if (this.aq["party"][this.joinbtn[i].name]["joined"] == 'YES' || this.aq["party"][this.joinbtn[i].name]["canjoin"] == 'NO') {
                this.joinbtn[i].visible = false;
              } else {
                this.joinbtn[i].visible = true;
              }
            }
          }
        }
      }
      this.wh.registry.list.gp.guigrid(griddat, griddatf);

    }
  async join(partyid) {
      this.plog.log("join", partyid);
      var cfm
      if (this.aq["myparty"] != "") {
        cfm = await this.wh.registry.list.rctoast.rcconfirm(this.wh,
          this.wh.trs("f:partymapcontainer:Join Party===Join Party"), 
          this.wh.trs("f:partymapcontainer:Joining a party will===Joining a party will remove you from any other party"));
      } else {
        cfm = "ok";
      }
      if (cfm == "ok") {
        var res = await this.wh.registry.list.rchttp.getcommon2("partymap", { mode: "join", partyid: partyid });
        this.show_loaded("1st");
        this.wh.registry.list.partymy.show(this.wh);
      }
    }

    rebuildguigrid() {
      this.plog.log("rebuildguigrid();b", this.wh.kbfocus);
      this.showpage("");
      this.plog.log("rebuildguigrid();a", this.wh.kbfocus);
    }
  }
