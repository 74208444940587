const ALIGN = Phaser.Display.Align;
export default {
  center: ALIGN.CENTER,
  left: ALIGN.LEFT_CENTER,
  right: ALIGN.RIGHT_CENTER,
  top: ALIGN.TOP_CENTER,
  bottom: ALIGN.BOTTOM_CENTER,
  'left-top': ALIGN.TOP_LEFT,
  'top-left': ALIGN.TOP_LEFT,
  'left-center': ALIGN.LEFT_CENTER,
  'center-left': ALIGN.LEFT_CENTER,
  'left-bottom': ALIGN.BOTTOM_LEFT,
  'bottom-left': ALIGN.BOTTOM_LEFT,
  'center-top': ALIGN.TOP_CENTER,
  'top-center': ALIGN.TOP_CENTER,
  'center-center': ALIGN.CENTER,
  'center-bottom': ALIGN.BOTTOM_CENTER,
  'bottom-center': ALIGN.BOTTOM_CENTER,
  'right-top': ALIGN.TOP_RIGHT,
  'top-right': ALIGN.TOP_RIGHT,
  'right-center': ALIGN.RIGHT_CENTER,
  'center-right': ALIGN.RIGHT_CENTER,
  'right-bottom': ALIGN.BOTTOM_RIGHT,
  'bottom-right': ALIGN.BOTTOM_RIGHT
};