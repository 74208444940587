import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class MISC {

    constructor() {

        return this;
    }
    numberformat(nnn) {
        return (Math.round(nnn * 100) / 100).toFixed(2);
;
        nnn = nnn + "";
        var separador = ".";
        var decimais = 2;
        let a: any = nnn.split('');
        let ns = '';
        a.forEach((c: any) => { if (!isNaN(c)) { ns = ns + c; } });
        ns = parseInt(ns).toString();
        if (ns.length < (decimais + 1)) {
            ns = ('0'.repeat(decimais + 1) + ns);
            ns = ns.slice((decimais + 1) * -1);
        }
        let ans = ns.split('');
        let r = '';
        for (let i = 0; i < ans.length; i++) {
            if (i === ans.length - decimais) {
                r = r + separador + ans[i];
            } else {
                r = r + ans[i];
            }
        }
        return r;
    }
    percent(totalValue, partialValue) {
        var tmp=(100 * partialValue) / totalValue;
        //console.log("percent",totalValue,partialValue,"=",tmp);
        return tmp;
    }
}