import { Injectable } from '@angular/core';
import { RCVarsService } from './rcvars-service.service';
import { RCHTTP } from './rchttp.service';
import { RCOSDService } from './rcosd.service';
import { plog } from './plog.service';

@Injectable({
  providedIn: 'root',
})
export class RCInventoryService {
  plog;
  shopdragbtn;
  title;
  empty;
  winguiltab;
  weakitemlist = [];
  weakitemlistall = [];
  filtertype = '';
  stackarr = [];
  wh;
  invensize;
  sellallbtn;
  donateallbtn;
  sellanywhere: any;
  shine1;
  equipbutton;
  submn = [];
  selecteditemno = '';
  currentresppage = 1;
  respperpage = 25;
  resptotalpage;
  selectcircle;
  itemdescr;
  shopitemlist = [];
  shopitemlistq = []; //quality icon
  shopitemlistp = [];
  shopitemlistr = []; //rarity
  sellalllowgrade;
  sellallalllowgrade;
  depositbtn;
  shopfwdbtn;
  shopbackbtn;
  invendropbtn;
  invenmode = '';
  sellbtn;
  donatebtn;
  shopclosebtn;
  shopguibg;
  shopdata;
  loading;
  firsttimerun = true;
  firsttimerunfunc() {
    if (this.firsttimerun == true) {
      this.wh.registry.list.gameitf.guiwindowclick(
        this.wh.inventorycontainer_item
      );
    }
    this.firsttimerun = false;
  }
  loadingshow() {
    try {
      this.wh.inventorycontainer_item.bringToTop(this.loading);
    } catch (e) {
      this.plog.log(e);
    }
    this.loading.visible = true;
  }
  loadinghide() {
    this.loading.visible = false;
  }
  constructor(
    public rcvarsservice: RCVarsService,
    public rchttp: RCHTTP,
    public rcosd: RCOSDService
  ) {
    this.plog = new plog();
    this.plog.setLevel(3);
  }

  async showinventory(wh, shopdata, invenmode = '', forcefocus = false) {
    if (forcefocus == true) this.firsttimerun = true;

    this.wh = wh;
    if (this.wh.inventorycontainer_item != undefined) {
      await this.showinventory_close(this.wh);
    }
    this.weakitemlist = [];
    this.weakitemlistall = [];

    this.shopdata = shopdata;
    this.invenmode = invenmode;
    /*if (wh.inventorycontainer_item != undefined) {
      wh.inventorycontainer_item.visible = true;
      this.showinventory_loaded(wh, shopdata);
      return;
    }*/
    var width = wh.game.canvas.width;
    var height = wh.game.canvas.height;
    this.plog.log('showinventory', wh, shopdata);
    /*
        if (wh.inventorycontainer_item != undefined) {
          this.plog.log(
            'inventorycontainer_item',
            wh.inventorycontainer_item
          );
          wh.inventorycontainer_item.visible = true;
          this.showinventory_loaded(wh, shopdata);
          return;
        }
    */
    this.winguiltab = wh.add.image(0, 0, 'winguiltab');
    this.winguiltab.setOrigin(0.5, 0.5);
    this.winguiltab.displayWidth = this.winguiltab.displayHeight = 50;
    this.winguiltab.visible = false;
    const selectcircle = wh.add.image(0, 0, 'selectcircle');// wh.add.sprite(0, 0, "ixbeff168_b").play({key:"ixbeff168_b",frameRate:60});//
    selectcircle.setOrigin(0.5, 0.5);
    selectcircle.visible = false;
    selectcircle.setAlpha(0.7);
    const shopguibg = wh.add.image(0, 0, 'blankbarboxguibg');//itemguibg
    shopguibg.setOrigin(0.5, 0.5);
    shopguibg.setInteractive({ pixelPerfect: true });
    shopguibg.removeAllListeners();
    this.shopguibg = shopguibg;

    this.sellallbtn = wh.add.sprite(0, 0, "thesb", "sellall");//wh.add.image(0, 0, 'incubate');
    this.sellallbtn.setOrigin(0.5, 0.5);
    this.sellallbtn.setInteractive();
    this.sellallbtn.removeAllListeners();
    this.donateallbtn = wh.add.sprite(0, 0, "thesb", "donateall");//wh.add.image(0, 0, 'incubate');
    this.donateallbtn.setOrigin(0.5, 0.5);
    this.donateallbtn.setInteractive();
    this.donateallbtn.removeAllListeners();


    const shopbackbtn = wh.add.sprite(0, 0, "thesb", "backsign");//wh.add.image(0, 0, 'listbackbtn');
    shopbackbtn.setOrigin(0.5, 0.5);
    shopbackbtn.setInteractive();
    shopbackbtn.removeAllListeners();
    const shopfwdbtn = wh.add.sprite(0, 0, "thesb", "forwardsign");//wh.add.image(0, 0, 'listfwdbtn');
    shopfwdbtn.setOrigin(0.5, 0.5);
    shopfwdbtn.setInteractive();
    shopfwdbtn.removeAllListeners();
    const invendropbtn = wh.add.sprite(0, 0, "thesb", "drop");//wh.add.image(0, 0, 'dropbtn');
    invendropbtn.setOrigin(0.5, 0.5);
    invendropbtn.setInteractive();
    invendropbtn.removeAllListeners();
    this.equipbutton = wh.add.sprite(0, 0, "thesb", "equip");//wh.add.image(0, 0, 'equipbtn');
    this.equipbutton.setOrigin(0.5, 0.5);
    this.equipbutton.setInteractive();
    this.equipbutton.removeAllListeners();
    //this.plog.log('showinventory var', this.rcvarsservice);
    const donatebtn = wh.add.sprite(0, 0, "thesb", "donate");//wh.add.image(0, 0, 'sellbtn');
    donatebtn.setOrigin(0.5, 0.5);
    donatebtn.setInteractive();
    donatebtn.removeAllListeners();
    const sellbtn = wh.add.sprite(0, 0, "thesb", "sell");//wh.add.image(0, 0, 'sellbtn');
    sellbtn.setOrigin(0.5, 0.5);
    sellbtn.setInteractive();
    sellbtn.removeAllListeners();

    this.sellallalllowgrade = wh.add.sprite(0, 0, 'updownarrow', 'dy');
    this.sellallalllowgrade.setOrigin(0.5, 0.5);
    this.sellallalllowgrade.setInteractive();
    this.sellallalllowgrade.removeAllListeners();
    this.sellalllowgrade = wh.add.sprite(0, 0, 'updownarrow', 'dr');
    this.sellalllowgrade.setOrigin(0.5, 0.5);
    this.sellalllowgrade.setInteractive();
    this.sellalllowgrade.removeAllListeners();
    const depositbtn = wh.add.sprite(0, 0, "thesb", "deposit");//wh.add.image(0, 0, 'homeinvendep');
    depositbtn.setOrigin(0.5, 0.5);
    depositbtn.setInteractive();
    depositbtn.removeAllListeners();
    this.shopfwdbtn = shopfwdbtn;
    this.shopbackbtn = shopbackbtn;
    this.selectcircle = selectcircle;
    this.invendropbtn = invendropbtn;
    this.depositbtn = depositbtn;
    this.sellbtn = sellbtn;
    this.donatebtn = donatebtn;

    const sellanywhere = wh.add.sprite(0, 0, "stackitem_tp", "sellitem");//wh.add.image(0, 0, 'sellbtn');
    sellanywhere.setOrigin(0.5, 0.5);
    sellanywhere.setInteractive();
    sellanywhere.removeAllListeners();
    sellanywhere.on(
      'pointerup',
      async function () {
        var res = await this.rchttp.getcommon2("consumestackitem", { usecode: 'sellitem' })
        if (res == 'ok') {
          this.wh.registry.list.execmod.exec(
            'npc_sellitem::',
            this.wh
          );
        }

        await this.wh.registry.list.rchttp.updatecharinfo();

        this.wh.registry.list.gameitf.guiwindowclick(
          this.wh.inventorycontainer_item
        );
      }, this);
    this.sellanywhere = sellanywhere;
    var shine1 = wh.add.sprite(100, 100, "shinebox", "shinebox_b");
    shine1.play("shinebox_b");
    shine1.setOrigin(0.5, 0.5);
    this.shine1 = shine1;
    this.sellanywhere.name = 'skipcntnrsizecal';
    this.shine1.name = 'skipcntnrsizecal';

    this.sellallbtn.setPosition(0, -155);
    this.sellallbtn.displayWidth = 100;
    this.sellallbtn.displayHeight = 35;
    this.sellallbtn.visible = false;

    this.donateallbtn.setPosition(0, -155);
    this.donateallbtn.displayWidth = 100;
    this.donateallbtn.displayHeight = 35;
    this.donateallbtn.visible = false;

    this.shopclosebtn = wh.add.sprite(300, 300, "thesq", 'close');
    this.shopclosebtn.name = 'skipcntnrsizecal';
    this.shopclosebtn.setOrigin(0.5, 0.5);
    this.shopclosebtn.setPosition(155, -200);
    this.shopclosebtn.displayWidth = 40;
    this.shopclosebtn.displayHeight = 40;
    this.shopclosebtn.setInteractive();
    this.shopclosebtn.removeAllListeners();
    this.shopclosebtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        //this.scene.registry.list["thethis"].phm.floorclick(this);
        this.plog.log(
          'gc.npcbackbtn pointerup',
          pointer,
          localX,
          localY,
          event,
          this
        );
        this.showinventory_close(wh);
      },
      this
    );

    wh.inventorycontainer_item = wh.add.container(300, 300);
    wh.inventorycontainer_item.visible = false;
    wh.inventorycontainer_item.name = "inventorycontainer_item";
    wh.inventorycontainer_item.add(this.shopclosebtn);
    wh.inventorycontainer_item.add(selectcircle);
    wh.inventorycontainer_item.add(shopguibg);
    wh.inventorycontainer_item.add(shopbackbtn);
    wh.inventorycontainer_item.add(shopfwdbtn);
    wh.inventorycontainer_item.add(invendropbtn);
    wh.inventorycontainer_item.add(sellbtn);
    wh.inventorycontainer_item.add(donatebtn);
    wh.inventorycontainer_item.add(this.sellalllowgrade);
    wh.inventorycontainer_item.add(this.sellallalllowgrade);
    wh.inventorycontainer_item.add(this.sellallbtn);

    wh.inventorycontainer_item.add(this.donateallbtn);
    wh.inventorycontainer_item.add(this.depositbtn);
    wh.inventorycontainer_item.add(this.winguiltab);

    wh.inventorycontainer_item.add(this.sellanywhere);
    wh.inventorycontainer_item.add(this.shine1);
    wh.inventorycontainer_item.bringToTop(this.donateallbtn);
    wh.inventorycontainer_item.bringToTop(this.shine1);
    wh.inventorycontainer_item.bringToTop(this.sellallbtn);

    shopbackbtn.displayWidth = 100;
    shopfwdbtn.displayWidth = 100;
    invendropbtn.displayWidth = 100;
    sellbtn.displayWidth = 100;
    donatebtn.displayWidth = 100;
    depositbtn.displayWidth = 100;
    shopbackbtn.displayHeight = 40;
    shopfwdbtn.displayHeight = 40;
    invendropbtn.displayHeight = 40;
    sellbtn.displayHeight = 40;
    donatebtn.displayHeight = 40;
    depositbtn.displayHeight = 40;
    this.equipbutton.displayHeight = 35;
    this.equipbutton.displayWidth = 80;
    this.sellalllowgrade.displayWidth = this.sellalllowgrade.displayHeight = 18;
    this.sellalllowgrade.setPosition(60, 225);
    this.sellallalllowgrade.displayWidth = this.sellallalllowgrade.displayHeight = 18;
    this.sellallalllowgrade.setPosition(60, 205);
    shopbackbtn.setPosition(-100, 220);

    sellanywhere.displayWidth = sellanywhere.displayHeight = 64;
    shine1.displayWidth = shine1.displayHeight = 64;

    this.equipbutton.setPosition(-110, -155)
    shopfwdbtn.setPosition(100, 220);
    invendropbtn.setPosition(0, 220);
    sellbtn.setPosition(0, 220);
    donatebtn.setPosition(0, 220);
    depositbtn.setPosition(0, 220);

    this.sellanywhere.setPosition(-150, -200);
    this.sellanywhere.visible = false;
    this.shine1.setPosition(-150, -200);
    this.shine1.visible = false;

    this.donateallbtn.on(
      'pointerup',
      function () {
        this.showinventory_donateall(wh);
        // this.plog.log('incubatebtndis', this);
      },
      this
    );
    this.donateallbtn.on(
      'pointerover',
      function () {
        wh.registry.list.gameitf.gamemenutooltip(
          wh,
          this.donateallbtn,
          'Donate all Items in this category',
          'top'
        );
        // this.plog.log('incubatebtndis', this);
      },
      this
    );
    this.sellallbtn.on(
      'pointerup',
      function () {
        this.showinventory_sellall();
        // this.plog.log('incubatebtndis', this);
      },
      this
    );
    this.sellallbtn.on(
      'pointerover',
      function () {
        wh.registry.list.gameitf.gamemenutooltip(
          wh,
          this.sellallbtn,
          'Sell all Items in this category',
          'top'
        );
        // this.plog.log('incubatebtndis', this);
      },
      this
    );
    wh.inventorycontainer_item.add(this.equipbutton);
    wh.inventorycontainer_item.bringToTop(this.equipbutton);
    this.equipbutton.on("pointerup", function () {
      this.plog.log("equipbtn down", this);
      this.equip();
      //wh.registry.list.inven.equip(selecteditem);
    }, this);

    this.sellalllowgrade.on('pointerover', function () {
      this.scene.registry.list.gameitf.gamemenutooltip(
        this.scene,
        this,
        wh.trs("f:inventorycontainer:Sell all items weaker than what you are wearing===Sell all items weaker than what you are wearing"),
        'btm'
      );
    });
    this.sellallalllowgrade.on('pointerover', function () {
      this.scene.registry.list.gameitf.gamemenutooltip(
        this.scene,
        this,
        this.scene.trs("f:inventorycontainer:Sell all items weaker than what you are wearing as in stat or item===Sell all items weaker than what you are wearing as in stat or item plus?"),
        'btm'
      );
    });
    this.sellalllowgrade.on('pointerup', async function () {
      //this.plog.log(        'sellalllow',        this.scene.registry.list.inven.weakitemlist,        this      );
      var weaklen = this.scene.registry.list.inven.weakitemlist.length;
      if (weaklen == 0) {
        this.scene.registry.list.rctoast.rcconfirm(
          this.scene,
          this.scene.trs("f:inventorycontainer:No item to sell===No item to sell"),
          this.scene.trs("f:inventorycontainer:You don't have weak item to sell===You don't have weak item to sell\n(indicated by red down arrow)")
        );
        return;
      }
      var cfm = await this.scene.registry.list.rctoast.rcconfirm(
        this.scene,
        this.scene.trs("f:inventorycontainer:Confirm selling multiple items===Confirm selling multiple items"),
        this.scene.trs("f:inventorycontainer:Are you sure to sell===Are you sure to sell") + ' ' +
        weaklen +
        ' items from your inventory?\n(indicated by red down arrow) ' +
        weaklen +
        ' '
      );
      //this.plog.log('sellalllowgrade  cfm', cfm);
      if (cfm == 'ok') {
        await this.scene.registry.list.inven.showinventory_sellalllow();
      }
    });
    this.sellallalllowgrade.on('pointerup', async function () {
      //this.plog.log(        'sellallalllow',        this.scene.registry.list.inven.weakitemlistall,        this      );
      const arrayUnique = (value, index, self) => {
        return self.indexOf(value) === index;
      }
      this.scene.registry.list.inven.weakitemlistall = this.scene.registry.list.inven.weakitemlistall.filter(arrayUnique);

      var weaklen = this.scene.registry.list.inven.weakitemlistall.length;
      if (weaklen == 0) {
        this.scene.registry.list.rctoast.rcconfirm(
          this.scene,
          this.scene.trs("f:inventorycontainer:No item to sell===No item to sell"),
          this.scene.trs("f:inventorycontainer:You don't have weak item to sellyellow===You don't have weak item to selln(indicated by red down arrow or yellow down arrow)")
        );
        return;
      }
      var cfm = await this.scene.registry.list.rctoast.rcconfirm(
        this.scene,
        this.scene.trs("f:inventorycontainer:Confirm selling multiple items===Confirm selling multiple items"),
        this.scene.trs("f:inventorycontainer:Are you sure to sell===Are you sure to sell") + ' ' +
        weaklen +
        ' items from your inventory?\n(indicated by red down arrow AND yellow down arrow) ' +
        weaklen +
        ' '
      );
      //this.plog.log('sellallalllowgrade  cfm', cfm);
      if (cfm == 'ok') {
        await this.scene.registry.list.inven.showinventory_sellallalllow();
      }
    });

    wh.inventorycontainer_item.bringToTop(this.sellalllowgrade);
    wh.inventorycontainer_item.bringToTop(this.shopclosebtn);
    var itemdescr = wh.add.text(0, 0, wh.trs("f:inventorycontainer:Click Item to View Description===Click Item to View Description"), wh.fs["winguiitemdescr"]);
    wh.inventorycontainer_item.add(itemdescr);
    itemdescr.setOrigin(0, 0);
    itemdescr.setPosition(-140, 179);
    this.itemdescr = itemdescr;
    this.invensize = wh.add.text(0, 0, '0/0', wh.fs["winguibardescr"]);
    //this.invensize.setStyle(wh.fs["winguibardescr"]);
    wh.inventorycontainer_item.add(this.invensize);
    this.invensize.setOrigin(1, 1);
    this.invensize.setPosition(150, -138);




    this.empty = wh.add.image(300, 300, 'empty');
    this.empty.setOrigin(0.5, 0.5);
    this.empty.setPosition(0, 0);
    this.empty.displayWidth = this.empty.displayHeight = 300;
    wh.inventorycontainer_item.add(this.empty);
    wh.inventorycontainer_item.bringToTop(this.empty);
    this.empty.visible = false;

    //drag

    this.shopdragbtn = wh.add.image(300, 300, 'spacer');
    this.shopdragbtn.setOrigin(0.5, 0.5);
    this.shopdragbtn.setPosition(0, -200);
    this.shopdragbtn.displayWidth = 270;
    this.shopdragbtn.displayHeight = 60;
    this.shopdragbtn.setInteractive();
    this.shopdragbtn.removeAllListeners();
    this.shopdragbtn.on(
      'pointerout',
      function (pointer, localX, localY, event) {
        this.rcvarsservice.gameobj.scene.scenes[0].inventorycontainer_item.dragactive =
          false;
      },
      this
    );
    this.shopdragbtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        this.rcvarsservice.gameobj.scene.scenes[0].inventorycontainer_item.dragactive =
          false;
      },
      this
    );
    this.shopdragbtn.on(
      'pointerdown',
      function (pointer, localX, localY, event) {
        this.rcvarsservice.gameobj.registry.list.gameitf.guiwindowclick(
          this.rcvarsservice.gameobj.scene.scenes[0].inventorycontainer_item
        );

        this.rcvarsservice.gameobj.scene.scenes[0].inventorycontainer_item.dragactive =
          true;
        this.rcvarsservice.gameobj.scene.scenes[0].inventorycontainer_item.dragactivex =
          this.rcvarsservice.gameobj.scene.scenes[0].inventorycontainer_item.x;
        this.rcvarsservice.gameobj.scene.scenes[0].inventorycontainer_item.dragactivey =
          this.rcvarsservice.gameobj.scene.scenes[0].inventorycontainer_item.y;
        this.rcvarsservice.gameobj.scene.scenes[0].inventorycontainer_item.dragactivepx =
          pointer.x;
        this.rcvarsservice.gameobj.scene.scenes[0].inventorycontainer_item.dragactivepy =
          pointer.y;
      },
      this
    );
    this.rcvarsservice.gameobj.scene.scenes[0].inventorycontainer_item.dragactive =
      false;
    this.rcvarsservice.gameobj.scene.scenes[0].inventorycontainer_item.dragactivex = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].inventorycontainer_item.dragactivey = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].inventorycontainer_item.dragactivepx = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].inventorycontainer_item.dragactivepy = 0;
    this.shopdragbtn.on(
      'pointermove',
      function (pointer, localX, localY, event) {
        //this.scene.registry.list["thethis"].phm.floorclick(this);
        //this.plog.log('shop pointermove', pointer, localX, localY, event, this);
        //this.showinventory_close(wh);
        if (
          this.rcvarsservice.gameobj.scene.scenes[0].inventorycontainer_item
            .dragactive == true
        ) {
          var tmpx =
            this.rcvarsservice.gameobj.scene.scenes[0].inventorycontainer_item
              .dragactivex;
          var tmpy =
            this.rcvarsservice.gameobj.scene.scenes[0].inventorycontainer_item
              .dragactivey;
          var tmppx =
            this.rcvarsservice.gameobj.scene.scenes[0].inventorycontainer_item
              .dragactivepx;
          var tmppy =
            this.rcvarsservice.gameobj.scene.scenes[0].inventorycontainer_item
              .dragactivepy;
          var movedx = tmppx - pointer.x;
          var movedy = tmppy - pointer.y;
          //this.plog.log('drag', tmppx, pointer.x, movedx);

          this.rcvarsservice.gameobj.scene.scenes[0].inventorycontainer_item.x =
            tmpx - movedx;
          this.rcvarsservice.gameobj.scene.scenes[0].inventorycontainer_item.y =
            tmpy - movedy;
          //this.rcvarsservice.gameobj.scene.scenes[0].inventorycontainer_item.y = pointer.y;
        }
      },
      this
    );
    wh.inventorycontainer_item.add(this.shopdragbtn);
    wh.inventorycontainer_item.bringToTop(this.shopdragbtn);

    /*
    this.plog.log("dragsize",shopguibg.width, shopguibg.height);
    wh.inventorycontainer_item.setSize(shopguibg.width, shopguibg.height);
    wh.inventorycontainer_item.setInteractive();
    wh.input.on('mousemove', function(pointer) {
      //check here is a scene has a selected gameObject
      //if(this.selected) { //to be able to detect if user really select something.
          wh.setPosition(pointer.x, pointer.y);
      //}
    }, this);*/

    this.plog.log(
      'inventorycontainer_item x',
      wh.inventorycontainer_item,
      width,
      height
    );
    wh.inventorycontainer_item.setPosition(width / 2, height / 2);
    //move
    wh.inventorycontainer_item.x = width - this.shopguibg.width / 2 + 30;

    this.loading = wh.add.sprite(300, 300, 'pixelloading').play('pixelloading_b');
    this.loading.setOrigin(0.5, 0.5);
    this.loading.setPosition(0, 0);
    this.loading.displayWidth = this.loading.displayHeight = 60;
    wh.inventorycontainer_item.add(this.loading);
    wh.inventorycontainer_item.bringToTop(this.loading);


    this.title = wh.add.text(0, 0, 'INVENTORY', wh.fs["winguititle"]);
    this.plog.log("winguititle fs", wh.fs["winguititle"])
    wh.inventorycontainer_item.add(this.title);
    wh.inventorycontainer_item.bringToTop(this.title);
    this.title.setPosition(0 - this.title.displayWidth / 2, -225);

    let loader = new Phaser.Loader.LoaderPlugin(wh);
    /*
    //move to spritepack
    for (var vv in shopdata["item"]) {
      var vdat = shopdata["item"][vv];
      var itemdat = this.rcvarsservice.allitem[vdat.item];
      loader.image(vdat["item"], this.rcvarsservice.rc_baseurl + "item/" + itemdat["pcode"] + "/" + itemdat["sprite"] + ".png");

      this.plog.log("load shop ", vv, vdat, itemdat);
    }*/

    var svversionurlstring = this.wh.registry.list.rcvarpass.svversionstring;
    if (svversionurlstring != '') {
      svversionurlstring = '?' + svversionurlstring;
    }
    var loadlist = ['itemtypeico'];
    for (var k in loadlist) {
      var key = loadlist[k];
      loader.atlas(
        key + '',
        this.wh.registry.list.rcvarpass.rc_baseurl +
        'sprite/' +
        key +
        '.png' +
        svversionurlstring,
        this.wh.registry.list.rcvarpass.rc_baseurl +
        'sprite/' +
        key +
        '.json' +
        svversionurlstring
      );
    }

    loader.once(
      Phaser.Loader.Events.COMPLETE,
      () => {
        this.plog.log('Phaser.Loader.Events.COMPLETE');
        this.plog.log(this);
        this.showinventory_loaded(wh, shopdata);
      },
      this
    );
    this.loadingshow();
    loader.start();
  }
  showinventory_loaded(wh, shopdata, pagemode = 'normal') {
    this.firsttimerunfunc();

    this.rcvarsservice.gameobj.registry.list.gameitf.guiwindowclick(
      this.rcvarsservice.gameobj.scene.scenes[0].inventorycontainer_item
    );
    this.weakitemlist = [];
    if (this.sellallbtn != undefined)
      this.sellallbtn.visible = false;

    if (this.donateallbtn != undefined)
      this.donateallbtn.visible = false;

    if (wh.registry.list.rcvarpass.activechar['map'] == 'home' || wh.registry.list.rcvarpass.activechar['map'] == 'ship1') {
      this.sellbtn.visible = false;
      this.donatebtn.visible = false;
      this.invendropbtn.visible = false;
      this.depositbtn.visible = true;
      this.sellalllowgrade.visible = false;
      this.sellallalllowgrade.visible = false;
    } else {
      if (this.invenmode == 'sell') {
        this.title.setText("Sell Items");
        this.sellbtn.visible = true;
        this.donatebtn.visible = false;
        this.invendropbtn.visible = false;
        this.depositbtn.visible = false;
        this.shopfwdbtn.setPosition(120, 220);
        this.sellalllowgrade.visible = true;
        this.sellallalllowgrade.visible = true;
      } else if (this.invenmode == 'donate') {
        this.title.setText("Donate Items");
        this.sellalllowgrade.visible = false;
        this.sellallalllowgrade.visible = false;
        this.sellbtn.visible = false;
        this.donatebtn.visible = true;
        this.depositbtn.visible = false;
        this.invendropbtn.visible = false;
        this.shopfwdbtn.setPosition(100, 220);
      } else {
        this.sellalllowgrade.visible = false;
        this.sellallalllowgrade.visible = false;
        this.sellbtn.visible = false;
        this.donatebtn.visible = false;
        this.depositbtn.visible = false;
        this.invendropbtn.visible = true;
        this.shopfwdbtn.setPosition(100, 220);
      }
    }
    this.loadinghide();
    var paddingl = -175;
    var paddingt = -140;
    //var stackarr = [];

    this.sellanywhere.visible = false;
    this.shine1.visible = false;
    if (wh.registry.list.rcreq.chk({ stackitem1: "sellitem", stackitem1_min: 1 })) {
      this.sellanywhere.visible = true;
      this.shine1.visible = true;
    }
    if (this.invenmode == 'sell') {
      this.sellanywhere.visible = false;
      this.shine1.visible = false;
    }
    this.stackarr['sword'] = [
      'sword',
      'longsword',
      'axe',
      'longaxe',
      'wand',
      'bow',
      'dagger',
    ];
    /*this.stackarr['longsword'] = ['longsword'];
    this.stackarr['axe'] = ['axe'];
    this.stackarr['longaxe'] = ['longaxe'];
    this.stackarr['wand'] = ['wand'];
    this.stackarr['bow'] = ['bow'];
    this.stackarr['dagger'] = ['dagger'];*/
    this.stackarr['shield'] = ['shield'];
    this.stackarr['accessories'] = ['ring', 'earring', 'necklace', 'bracelet'];
    this.stackarr['boots'] = ['strboots', 'chaboots', 'dexboots', 'conboots'];
    this.stackarr['gloves'] = [
      'strgloves',
      'dexgloves',
      'chagloves',
      'dexarmor',
    ];
    this.stackarr['armor'] = ['strarmor', 'conarmor', 'chaarmor', 'conboots'];
    this.stackarr['pant'] = ['strpants', 'conpants', 'chapants', 'dexpants'];
    this.stackarr['helmet'] = [
      'strhelmet',
      'conhelmet',
      'chahelmet',
      'dexhelmet',
    ];

    var paddingts = paddingt + 0;
    var iconwhsub = 30;
    var rowh = 40;
    var i = 0;
    for (var k in this.stackarr) {
      var v = this.stackarr[k];
      //this.plog.log('xxxxx', this.wh, k, v, i);

      this.submn[k] = this.wh.add.sprite(
        0,
        0,
        'itemtypeico',
        k,
        iconwhsub * i + 2 + paddingts
      );
      this.wh.inventorycontainer_item.add(this.submn[k]);
      this.wh.inventorycontainer_item.bringToTop(this.submn[k]);
      this.submn[k].displayWidth = this.submn[k].displayHeight = iconwhsub;
      this.submn[k].setPosition(
        paddingl,
        paddingt + ((iconwhsub + 3) * i + 65)
      );
      paddingts = paddingts + rowh;
      this.submn[k].setInteractive();
      this.submn[k].removeAllListeners();
      this.submn[k].name = k;
      this.submn[k].on('pointerup', function () {
        this.scene.plog.log('xxxxxpointerup', this, this.scene.registry.list.inven.winguiltab);
        this.scene.registry.list.inven.winguiltab.setPosition(this.x - 7, this.y);
        if (this.scene.registry.list.inven.filtertype == this.name) {
          this.scene.registry.list.inven.filtertype = '';
          this.scene.registry.list.inven.winguiltab.visible = false;
        } else {
          this.scene.registry.list.inven.filtertype = this.name;
          this.scene.registry.list.inven.winguiltab.visible = true;
          this.scene.inventorycontainer_item.moveBelow(this.scene.registry.list.inven.winguiltab, this);

        }
        this.scene.registry.list.inven.showinventory_loaded(
          this.scene.registry.list.inven.wh,
          this.scene.registry.list.inven.shopdata
        );

      });
      this.submn[k].on('pointerover', function () {
        var str = this.name;
        if (str == 'sword') str = 'weapons';
        str = this.scene.trs("f:inventorycontainer:itemtype-" + str + "===" + str);
        this.scene.registry.list.gameitf.gamemenutooltip(
          this.scene,
          this,
          str,
          'left'
        );
      });
      i++;
      if (this.filtertype != "" && this.filtertype == k) {
        this.wh.registry.list.inven.winguiltab.setPosition(this.submn[k].x - 7, this.submn[k].y);
        this.wh.registry.list.inven.winguiltab.visible = true;
        this.wh.inventorycontainer_item.moveBelow(this.wh.registry.list.inven.winguiltab, this.submn[k]);
      }
    }

    this.plog.log('showinventory_loaded', wh, shopdata);
    //this.rcvarsservice.gameobj.registry.list.gameitf.guiwindowclick(
    //  this.rcvarsservice.gameobj.scene.scenes[0].inventorycontainer_item
    //);

    var margintop = -90;
    var marginleft = -117;
    var cxpos = 0;
    var crow = 0;
    var i = 0;
    for (var i = 0; i < 100; i++) {
      if (this.shopitemlist[i] != undefined) {
        this.shopitemlist[i].visible = false;
        // this.shopitemlistp[i].setText("");
        this.shopitemlistp[i].visible = false;
        this.shopitemlistq[i].visible = false;
        this.shopitemlistr[i].visible = false;
      }
    }
    for (var i = 0; i < 100; i++) {
      if (this.shopitemlist[i] != undefined) {
        //this.shopitemlist[i].destroy(); // = undefined;
        this.shopitemlist[i] = undefined;
      }
      if (this.shopitemlistp[i] != undefined) {
        //this.shopitemlistp[i].destroy(); // = undefined;
        this.shopitemlistp[i] = undefined;
      }
      if (this.shopitemlistq[i] != undefined) {
        //this.shopitemlistq[i].destroy(); // = undefined;
        this.shopitemlistq[i] = undefined;
      }
      if (this.shopitemlistr[i] != undefined) {
        //this.shopitemlistq[i].destroy(); // = undefined;
        this.shopitemlistr[i] = undefined;
      }
    }
    this.plog.log('pagemode', pagemode, this.currentresppage);
    if (pagemode == 'normal') {
      this.currentresppage = 1;
    }
    if (pagemode == 'retainpage') {
      if (this.currentresppage == 0 || this.currentresppage == undefined)
        this.currentresppage = 1;
    }
    var countslot = 0;
    for (const countslotk in shopdata['item']) {
      countslot++;
    }
    this.plog.log("invensize oversize", shopdata['item'].length, this.rcvarsservice.activechar['invensize']);//,this.invensize
    if (countslot >= this.rcvarsservice.activechar['invensize']) {
      this.invensize.setStyle(wh.fs["winguibardescrred"]);
    } else {
      this.invensize.setStyle(wh.fs["winguibardescr"]);
    }
    this.invensize.setText(
      'Slot ' + countslot + '/' + this.rcvarsservice.activechar['invensize']
    );
    var i = 0;
    this.plog.log('loaded itemdatall ', shopdata);

    if (this.filtertype != undefined && this.filtertype != "" && countslot != 0) {
      if (this.invenmode == 'sell') {
        this.sellallbtn.visible = true;
      }
      if (this.invenmode == "donate") {
        this.donateallbtn.visible = true;
      }
    }
    for (var vv in shopdata['item']) {
      var vdat = shopdata['item'][vv];
      var itemdat = this.rcvarsservice.allitem[vdat.item];
      this.plog.log('loaded itemdat ', vv, vdat, itemdat);
      //var tmp = wh.add.image(32, 32, itemdat["code"]);
      if (itemdat == undefined) continue;
      if (this.filtertype != '' && this.filtertype != undefined) {
        if (!this.stackarr[this.filtertype].includes(itemdat['pcode']))
          continue;
      }
      this.shopitemlist[i] = wh.add.sprite(32, 32, 'item_tp', itemdat['code']);
      this.shopitemlistr[i] = wh.add.sprite(16, 16, "spacer");
      if (vdat["rarity"] == "rar") {
        this.shopitemlistr[i].setTexture("effrar").play("effrar_b");
        //this.plog.warn("playing rarity");
      }
      if (vdat["rarity"] == "leg") {
        this.shopitemlistr[i].setTexture("effleg").play("effleg_b");
        //this.plog.warn("playing rarity");
      }
      if (vdat["rarity"] == "ult") {
        this.shopitemlistr[i].setTexture("effleg").play("effleg_b");
        this.shopitemlistr[i].setTint(0xff0000);
      }
      if (vdat["rarity"] == "rel") {
        this.shopitemlistr[i].setTexture("effleg").play("effleg_b");
        this.shopitemlistr[i].setTint(0x0000ff);
      }
      this.shopitemlistr[i].displayHeight = this.shopitemlistr[i].displayWidth = 32;
      this.shopitemlist[i].displayWidth = 48;
      this.shopitemlist[i].displayHeight = 48;
      wh.inventorycontainer_item.add(this.shopitemlist[i]);
      wh.inventorycontainer_item.add(this.shopitemlistr[i]);
      this.shopitemlist[i].setPosition(marginleft + cxpos * 59, margintop + crow * 59);
      this.shopitemlistr[i].setPosition(this.shopitemlist[i].x - 16, this.shopitemlist[i].y - 16);
      //wh.inventorycontainer_item.moveBelow(this.shopitemlistr[i], this.shopitemlist[i]);

      this.shopitemlistq[i] = wh.add.sprite(32, 32, 'updownarrow', 'u');
      this.shopitemlistq[i].displayWidth = this.shopitemlistq[i].displayHeight = 16;
      wh.inventorycontainer_item.add(this.shopitemlistq[i]);
      this.shopitemlistq[i].setPosition(
        marginleft + cxpos * 59 + 20,
        margintop + crow * 59 - 20
      );
      //this.plog.log("xxx1",tmp);
      //item quality arrow s
      var thisitemqinfo = wh.registry.list.rcvarpass.allitem[itemdat['code']];
      var countatt = this.countatt(thisitemqinfo);
      var currentatt = this.getitemdat(wh, thisitemqinfo['equip_at']);
      //this.plog.log('thisitemqinfo', thisitemqinfo, countatt, currentatt);
      if (countatt == currentatt) {
        this.shopitemlistq[i].setTexture('spacer');
        if (
          vdat['itemplus'] < this.getitemdatplus(wh, thisitemqinfo['equip_at'])
        ) {
          this.shopitemlistq[i].setTexture('updownarrow', 'dr');
          if (vdat["rarity"] == undefined || vdat["rarity"] == "") {
            this.weakitemlist.push(vdat['id']);
            this.weakitemlistall.push(vdat['id']);
          }

        } else if (
          vdat['itemplus'] == this.getitemdatplus(wh, thisitemqinfo['equip_at'])
        ) {
          this.shopitemlistq[i].setTexture('spacer');
          //this.weakitemlist.push(vdat['id']);
        } else {
          if (
            wh.registry.list.rcreq.chk(thisitemqinfo['requirements']) == true
          ) {
            this.shopitemlistq[i].setTexture('updownarrow', 'u');
          } else {
            this.shopitemlistq[i].setTexture('updownarrow', 'ur');
          }
        }
      }
      if (countatt > currentatt) {
        this.plog.log(
          'thisitemqinfo chk',
          wh.registry.list.rcreq.chk(thisitemqinfo['requirements']),
          thisitemqinfo['requirements']
        );
        if (wh.registry.list.rcreq.chk(thisitemqinfo['requirements']) == true) {
          this.shopitemlistq[i].setTexture('updownarrow', 'u');
        } else {
          this.shopitemlistq[i].setTexture('updownarrow', 'ur');
        }
      }
      if (countatt < currentatt) {
        if (
          vdat['itemplus'] <= this.getitemdatplus(wh, thisitemqinfo['equip_at'])
        ) {
          this.shopitemlistq[i].setTexture('updownarrow', 'dr');
          if (vdat["rarity"] == undefined || vdat["rarity"] == "") {
            this.weakitemlist.push(vdat['id']);
            this.weakitemlistall.push(vdat['id']);
          }

        } else {
          this.shopitemlistq[i].setTexture('updownarrow', 'dy');
          if (vdat["rarity"] == undefined || vdat["rarity"] == "") {
            this.weakitemlistall.push(vdat['id']);
          }

        }
      }
      //item quality arrow e
      if (Number(vdat['itemplus']) != 0) {
        this.shopitemlistp[i] = wh.add.text(0, 0, '+' + vdat['itemplus'], wh.fs["wingui_plus"]);
      } else {
        this.shopitemlistp[i] = wh.add.text(0, 0, '', wh.fs["wingui_plus"]);
      }
      wh.inventorycontainer_item.add(this.shopitemlistp[i]);
      this.shopitemlistp[i].setPosition(this.shopitemlist[i].x - 27, this.shopitemlist[i].y + 11);
      this.shopitemlist[i].visible = true;
      this.shopitemlistp[i].visible = true;
      this.shopitemlistq[i].visible = true;
      this.shopitemlistr[i].visible = true;
      this.shopitemlist[i].setInteractive();
      this.shopitemlist[i].removeAllListeners();
      this.shopitemlist[i].name =
        '' + vdat['item'] + '-' + vdat['id'] + '-' + i;
      this.shopitemlist[i].on(
        'pointerup',
        function (pointer, localX, localY, event) {
          this.scene.plog.log('shop itemclick this', this);
          this.scene.plog.log('shop itemclick', pointer, localX, localY, event);
          this.scene.registry.list.inven.showinventory_itemclick(
            this.scene,
            this
          );
        }
      );

      //wh.inventorycontainer_item.bringToTop(this.shopitemlist[i]);

      cxpos++;
      if (cxpos > 4) {
        cxpos = 0;
        crow++;
      }
      i++;
      if (i % 25 == 0) {
        cxpos = 0;
        crow = 0;
      }
    }
    this.plog.log('shop i count', i);
    if (i > 25) {
      //pages
      this.respperpage = 25;
      this.resptotalpage = Math.ceil(i / 25);
    }


    if (i == 0) {
      this.empty.visible = true;
    } else {
      this.empty.visible = false;
    }

    this.shopbackbtn.removeAllListeners();
    this.shopfwdbtn.removeAllListeners();
    this.shopbackbtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        this.plog.log('shop shopbackbtn', this);
        this.showinventory_showpage(wh, 'back');
      },
      this
    );
    this.shopfwdbtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        this.plog.log('shop shopfwdbtn', this);
        this.showinventory_showpage(wh, 'next');
      },
      this
    );
    this.showinventory_showpage(wh, 'current');
    this.invendropbtn.removeAllListeners();
    this.invendropbtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        this.plog.log('invendropbtn', this);
        this.showinventory_drop(this);
      },
      this
    );
    this.sellbtn.removeAllListeners();
    this.sellbtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        this.plog.log('shop sellbtn', this);
        this.showinventory_sell(this);
      },
      this
    );

    this.donatebtn.removeAllListeners();
    this.donatebtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        this.plog.log('shop donatebtn', this);
        this.showinventory_donate(this);
      },
      this
    );

    this.depositbtn.removeAllListeners();
    this.depositbtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        this.plog.log('shop sellbtn', this);
        this.showinventory_deposit(this);
      },
      this
    );
    this.wh.inventorycontainer_item.visible = true;
    this.wh.registry.list.resizeman.RCMapResizeManager_work(this.wh);
    if (this.sellanywhere.visible==true) {
      this.wh.registry.list.tut.show(this.wh, "inven_sellanywhere");
    }
  }
  async showinventory_sellalllow() {
    this.plog.log('showinventory_sellalllow', this, this.weakitemlist);
    this.loadingshow();
    var itemlist = this.weakitemlist.join(',');
    var res = await this.rchttp.getcommon2('sellalllowgradeitem', {
      itemlist: itemlist,
    });
    this.plog.log('showinventory_sellalllow res', res, itemlist);
    await this.wh.registry.list.rchttp.updatecharinfo();

    this.loadinghide();
    this.selecteditemno = '';
    this.weakitemlist = [];
  }

  async showinventory_sellallalllow() {
    this.plog.log('showinventory_sellallalllow', this, this.weakitemlistall);
    this.loadingshow();
    var itemlist = this.weakitemlistall.join(',');
    var res = await this.rchttp.getcommon2('sellallalllowgradeitem', {
      itemlist: itemlist,
    });
    this.plog.log('showinventory_sellallalllow res', res, itemlist);
    await this.wh.registry.list.rchttp.updatecharinfo();

    this.loadinghide();
    this.selecteditemno = '';
    this.weakitemlistall = [];
  }
  async showinventory_sellall() {
    this.plog.log('showinventory_sellall');
    if (this.filtertype == undefined || this.filtertype == "") return;
    var cateinfo = this.wh.trs("f:inventorycontainer:itemtype-" + this.filtertype + "===" + this.filtertype);

    //console.log(cateinfo);
    var confirm =
      await this.rcvarsservice.gameobj.registry.list.rctoast.rcconfirm(
        this.rcvarsservice.gameobj.scene.scenes[0],
        this.rcvarsservice.gameobj.scene.scenes[0].trs("f:attricontainer:Confirm Selling===Confirm Selling"),
        "Sell all items in category: " + cateinfo + "?\n\nTo sell only low quality items, use arrow button at the bottom of this screen"
      );
    //this.plog.log('rcconfirmresult', confirm);
    //var confirm = await this.rcvarsservice.gameobj.registry.list.rctoast.confirm("Confirm Sell", "Sell " + amnt + " of:\n" + iteminfo2["name"] + " for " + (tmpsellprice * amnt) + " gold");
    //console.log(confirm);
    if (confirm != 'ok') {
      this.loadinghide();
      return;
    }

    this.rcvarsservice.gameobj.registry.list.snd.snd("coins");

    var res = await this.rchttp.sellitemallcate(this.filtertype);
    await this.wh.registry.list.rchttp.updatecharinfo();
  }
  async showinventory_donate(wh) {
    this.plog.log('showinventory_donate', wh, Number(this.selecteditemno));
    if (this.selecteditemno == '') return;
    if (wh.rcvarsservice.activechar['item'][this.selecteditemno] == undefined) {
      this.plog.log(
        'showinventory_donate skip dropped',
        this.selecteditemno,
        wh.rcvarsservice.activechar['item'][this.selecteditemno]
      );
      return; //dropped
    }
    var tmpid = wh.rcvarsservice.activechar['item'][this.selecteditemno]['id'];
    var tmpitem =
      wh.rcvarsservice.activechar['item'][this.selecteditemno]['item'];
    var tmpsellprice =
      wh.rcvarsservice.activechar['item'][this.selecteditemno]['price'];
    var iteminfo2 = wh.rcvarsservice.allitem[tmpitem];

    //var confirm = await this.rcvarsservice.gameobj.registry.list.rctoast.confirm("Confirm Sell", "<p>Sell this items?</p><p>" + iteminfo2["name"] + " lv." + iteminfo2["level"] + "</p><p>For " + tmpsellprice + " gold");
    var confirm =
      await this.rcvarsservice.gameobj.registry.list.rctoast.rcconfirm(
        wh.rcvarsservice.gameobj.registry.list.phaserGame.scene.scenes[0],
        wh.rcvarsservice.gameobj.registry.list.phaserGame.scene.scenes[0].trs("f:inventorycontainer:Confirm Donate===Confirm Donate"),
        wh.rcvarsservice.gameobj.registry.list.phaserGame.scene.scenes[0].trs("f:inventorycontainer:Donate this item?===Donate this item?") + ' \n' +
        iteminfo2['name'] +
        ' lv.' +
        iteminfo2['level'] +
        '\nFor ' +
        tmpsellprice +
        ' credits'
      );

    if (confirm != 'ok') return;
    /*this.rcvarsservice.gameobj.registry.list.gameitf.osdflash(
      this.rcvarsservice.gameobj.scene.scenes[0],
      [{ txt: 'Gold +' + tmpsellprice, col: 'green' }]
    );*/
    this.rcvarsservice.gameobj.registry.list.snd.snd("coins");

    this.plog.log('showinventory_donate', wh.rcvarsservice.activechar);
    this.loadingshow();
    var tmpid = wh.rcvarsservice.activechar['item'][this.selecteditemno]['id'];
    var tmpamnt =
      wh.rcvarsservice.activechar['item'][this.selecteditemno]['amnt'];
    var res = await this.rchttp.donateitem(tmpid);
    await this.rcvarsservice.setactivechar(
      res,
      wh.rcvarsservice.activechar['id']
    );
    this.plog.log('showinventory_donate res', res, wh.rcvarsservice);
    this.rcvarsservice.gameobj.registry.list.gameitf.charinfoupdated(
      this.rcvarsservice.gameobj.scene.scenes[0]
    );
    this.rcvarsservice.gameobj.registry.list.wsrc.sendeffect("donate", "ixbeff137_b");
    this.loadinghide();
    //if (tmpamnt<=1) {
    this.selecteditemno = '';
    //}
  }

  async showinventory_donateall(wh) {
   // console.log('showinventory_donateall', wh, Number(this.selecteditemno));
    if (this.filtertype == undefined || this.filtertype == "") return;
    var cateinfo = this.wh.trs("f:inventorycontainer:itemtype-" + this.filtertype + "===" + this.filtertype);


    //var confirm = await this.rcvarsservice.gameobj.registry.list.rctoast.confirm("Confirm Sell", "<p>Sell this items?</p><p>" + iteminfo2["name"] + " lv." + iteminfo2["level"] + "</p><p>For " + tmpsellprice + " gold");
    var confirm =
      await this.rcvarsservice.gameobj.registry.list.rctoast.rcconfirm(
        wh.registry.list.phaserGame.scene.scenes[0],
        wh.registry.list.phaserGame.scene.scenes[0].trs("f:inventorycontainer:Confirm Donate===Confirm Donate"),
          'Donate ALL items in category? \n' +
          cateinfo
      );

    if (confirm != 'ok') return;
    /*this.rcvarsservice.gameobj.registry.list.gameitf.osdflash(
      this.rcvarsservice.gameobj.scene.scenes[0],
      [{ txt: 'Gold +' + tmpsellprice, col: 'green' }]
    );*/
    wh.registry.list.snd.snd("coins");

    this.plog.log('showinventory_donateall', wh.registry.list.rcvarpass.activechar);
    this.loadingshow(); 
    var res = await this.rchttp.donateitemallcate(this.filtertype);
    await this.rcvarsservice.setactivechar(
      res,
      wh.registry.list.rcvarpass.activechar['id']
    );
    this.plog.log('showinventory_donateall res', res, wh);
    wh.registry.list.gameitf.charinfoupdated(
      wh
    );
    wh.registry.list.wsrc.sendeffect("donate", "ixbeff137_b");

    this.loadinghide();
    //if (tmpamnt<=1) {
    this.selecteditemno = '';
    //}
  }
  async showinventory_sell(wh) {
    this.plog.log('showinventory_sell', wh, Number(this.selecteditemno));
    if (this.selecteditemno == '') return;
    if (wh.rcvarsservice.activechar['item'][this.selecteditemno] == undefined) {
      this.plog.log(
        'showinventory_sell skip dropped',
        this.selecteditemno,
        wh.rcvarsservice.activechar['item'][this.selecteditemno]
      );
      return; //dropped
    }
    var tmpid = wh.rcvarsservice.activechar['item'][this.selecteditemno]['id'];
    var tmpitem =
      wh.rcvarsservice.activechar['item'][this.selecteditemno]['item'];
    var tmpsellprice =
      wh.rcvarsservice.activechar['item'][this.selecteditemno]['sellprice'];
    var iteminfo2 = wh.rcvarsservice.allitem[tmpitem];

    //var confirm = await this.rcvarsservice.gameobj.registry.list.rctoast.confirm("Confirm Sell", "<p>Sell this items?</p><p>" + iteminfo2["name"] + " lv." + iteminfo2["level"] + "</p><p>For " + tmpsellprice + " gold");
    var confirm =
      await this.rcvarsservice.gameobj.registry.list.rctoast.rcconfirm(
        wh.rcvarsservice.gameobj.registry.list.phaserGame.scene.scenes[0],
        wh.rcvarsservice.gameobj.registry.list.phaserGame.scene.scenes[0].trs("f:inventorycontainer:Confirm Sell===Confirm Sell"),
        wh.rcvarsservice.gameobj.registry.list.phaserGame.scene.scenes[0].trs("f:inventorycontainer:Sell this items?===Sell this items?") + ' \n' +
        iteminfo2['name'] +
        ' lv.' +
        iteminfo2['level'] +
        '\nFor ' +
        tmpsellprice +
        ' gold'
      );

    if (confirm != 'ok') return;
    this.rcvarsservice.gameobj.registry.list.gameitf.osdflash(
      this.rcvarsservice.gameobj.scene.scenes[0],
      [{ txt: 'Gold +' + tmpsellprice, col: 'green' }]
    );
    this.rcvarsservice.gameobj.registry.list.snd.snd("coins");

    this.plog.log('showinventory_sell', wh.rcvarsservice.activechar);
    this.loadingshow();
    var tmpid = wh.rcvarsservice.activechar['item'][this.selecteditemno]['id'];
    var tmpamnt =
      wh.rcvarsservice.activechar['item'][this.selecteditemno]['amnt'];
    var res = await this.rchttp.sellitem(tmpid);
    await this.rcvarsservice.setactivechar(
      res,
      wh.rcvarsservice.activechar['id']
    );
    this.plog.log('showinventory_sell res', res, wh.rcvarsservice);
    this.rcvarsservice.gameobj.registry.list.gameitf.charinfoupdated(
      this.rcvarsservice.gameobj.scene.scenes[0]
    );
    this.loadinghide();
    //if (tmpamnt<=1) {
    this.selecteditemno = '';
    //}
  }
  async showinventory_drop(wh) {
    this.plog.log('showinventory_drop', wh, Number(this.selecteditemno));
    if (this.selecteditemno == '') return;
    if (wh.rcvarsservice.activechar['item'][this.selecteditemno] == undefined) {
      this.plog.log(
        'showinventory_drop skip dropped',
        this.selecteditemno,
        wh.rcvarsservice.activechar['item'][this.selecteditemno]
      );
      return; //dropped
    }
    var tmpid = wh.rcvarsservice.activechar['item'][this.selecteditemno]['id'];
    var tmpitem =
      wh.rcvarsservice.activechar['item'][this.selecteditemno]['item'];
    var iteminfo2 = wh.rcvarsservice.allitem[tmpitem];

    //var confirm = await this.rcvarsservice.gameobj.registry.list.rctoast.confirm("Confirm Drop", "<p>Remove this items?</p><p>" + iteminfo2["name"] + " lv." + iteminfo2["level"] + "</p>");
    var confirm =
      await this.rcvarsservice.gameobj.registry.list.rctoast.rcconfirm(
        wh.rcvarsservice.gameobj.registry.list.phaserGame.scene.scenes[0],
        wh.rcvarsservice.gameobj.registry.list.phaserGame.scene.scenes[0].trs("f:inventorycontainer:Confirm Drop===Confirm Drop"),
        wh.rcvarsservice.gameobj.registry.list.phaserGame.scene.scenes[0].trs("f:inventorycontainer:Remove this items?===Remove this items?") + ' \n' +
        iteminfo2['name'] +
        ' lv.' +
        iteminfo2['level']
      );
    this.plog.log('rcconfirmresult', confirm);
    if (confirm != 'ok') return;
    this.plog.log('showinventory_drop', wh.rcvarsservice.activechar);
    this.loadingshow();
    var res = await this.rchttp.dropitem(tmpid);
    await this.rcvarsservice.setactivechar(
      res,
      wh.rcvarsservice.activechar['id']
    );
    this.plog.log('showinventory_drop res', res, wh.rcvarsservice);
    this.rcvarsservice.gameobj.registry.list.gameitf.charinfoupdated(
      this.rcvarsservice.gameobj.scene.scenes[0]
    );
    wh.rcvarsservice.gameobj.registry.list.snd.snd("drop");
    this.loadinghide();
    this.selecteditemno = '';
  }

  async showinventory_deposit(wh) {
    this.plog.log('showinventory_deposit', wh, Number(this.selecteditemno));
    if (this.selecteditemno == '') return;
    if (wh.rcvarsservice.activechar['item'][this.selecteditemno] == undefined) {
      this.plog.log(
        'showinventory_deposit skip dropped',
        this.selecteditemno,
        wh.rcvarsservice.activechar['item'][this.selecteditemno]
      );
      return; //dropped
    }
    var tmpid = wh.rcvarsservice.activechar['item'][this.selecteditemno]['id'];
    this.plog.log('showinventory_deposit', wh.rcvarsservice.activechar);
    this.loadingshow();
    await this.rchttp.deposititem(tmpid);
    this.plog.log('afterdeposit');
    await this.wh.registry.list.rchttp.updatecharinfo();

    //this.plog.log('showinventory_deposit res', res, wh.rcvarsservice);
    this.rcvarsservice.gameobj.registry.list.gameitf.charinfoupdated(
      this.rcvarsservice.gameobj.scene.scenes[0]
    );
    this.loadinghide();

    this.rcvarsservice.gameobj.registry.list.gameitf.guiwindowclick(
      this.rcvarsservice.gameobj.scene.scenes[0].inventorycontainer_item
    );

    this.selecteditemno = '';
  }

  showinventory_itemclick(wh, item) {
    this.selectcircle.visible = true;
    var namea = item.name.split('-');
    wh.registry.list.inven.selecteditemno = namea[1];
    this.plog.log('showinventory_itemclick', wh, item);
    this.plog.log(
      'showinventory_itemclick shopitemlist',
      namea,
      this.shopitemlist
    );
    this.selectcircle.displayWidth = 48;
    this.selectcircle.displayHeight = 48;
    this.selectcircle.setPosition(item.x, item.y);
    //wh.inventorycontainer_item.sendToBack(wh.shopguibg);
    wh.inventorycontainer_item.bringToTop(this.selectcircle);
    wh.inventorycontainer_item.bringToTop(this.shopitemlist[namea[2]]);
    wh.inventorycontainer_item.bringToTop(this.shopitemlistp[namea[2]]);
    this.plog.log(
      'showinventory_itemclick selectcircle',
      namea,
      item,
      wh.registry.list.rcvarpass.activechar
    );
    var iteminfo = wh.registry.list.rcvarpass.activechar['item'][namea[1]];
    var iteminfo2 = wh.registry.list.rcvarpass.allitem[namea[0]];

    if (iteminfo == undefined) {
      this.plog.log("ERROR: showinventory_itemclick, iteminfo undefined");
      return;
    }
    if (iteminfo2 == undefined) {
      this.plog.log("ERROR: showinventory_itemclick, iteminfo2 undefined");
      return;
    }

    var ispassreq = wh.registry.list.rcreq.chk(iteminfo2["requirements"]);
    this.plog.log(
      'showinventory_itemclick ispassreq',
      ispassreq,
      iteminfo
    );
    if (this.invenmode == "donate") {
      this.equipbutton.visible = false;
      this.donatebtn.visible = true;
    } else {
      if (ispassreq == true) {
        this.equipbutton.visible = true;
      } else {
        this.equipbutton.visible = false;
      }
    }
    //this.selectcircle.width=
    //this.itemdescr.setText(iteminfo2["name"] + " x" + iteminfo["amnt"]);
    //this.plog.log("xxxx",this);
    if (wh.registry.list.rcvarpass.activechar['map'] == 'home' || wh.registry.list.rcvarpass.activechar['map'] == 'ship1') {
      this.itemdescr.setText(iteminfo2['name'] + wh.trs("f:inventorycontainer:deposit to home inventory===deposit to home inventory"));
    } else {
      if (this.invenmode == 'sell') {
        this.itemdescr.setText(
          iteminfo2['name'] + wh.gl(' sell for  ') + iteminfo['sellprice'] + ' gold'
        );
      } else {
        this.itemdescr.setText(iteminfo2['name']);
      }
    }
    wh.registry.list.rchttp.getcommon2('removeisnew_item', {
      itemid: iteminfo['id'],
    });
    wh.registry.list.rcvarpass.activechar['item'][namea[1]]['isnew'] = 0;
    this.rcosd.show(wh, 'item', iteminfo2, iteminfo);
  }
  async showinventory_close(wh) {
    wh.inventorycontainer_item.visible = false;
    await wh.registry.list.gameitf.closegui(wh, wh.inventorycontainer_item);

    for (const k in this.wh.inventorycontainer_item.list) {
      try {
        await this.wh.inventorycontainer_item.remove(this.wh.inventorycontainer_item.list[k], true);
        //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list[k].destroy();
      } catch (e) {
        this.plog.log("ERROR! inventorycontainer_item.destroy() ", e);
      }
    }
    try {
      await this.wh.inventorycontainer_item.destroy(true);
    } catch (e) {
      this.plog.log("ERROR! inventorycontainer_item.destroy() ", e);
    }
    wh.inventorycontainer_item = undefined;
  }
  showinventory_showpage(gc, viewpage) {
    this.selectcircle.visible = false;
    this.equipbutton.visible = false;
    if (viewpage == 'back') {
      if (this.currentresppage > 1) this.currentresppage--;
    }
    if (viewpage == 'next') {
      if (this.currentresppage < this.resptotalpage) this.currentresppage++;
    }
    for (var i = 0; i <= 100; i++) {
      if (this.shopitemlist[i] != undefined) {
        this.shopitemlist[i].visible = false;
      }
      if (this.shopitemlistp[i] != undefined) {
        this.shopitemlistp[i].visible = false;
      }
      if (this.shopitemlistq[i] != undefined) {
        this.shopitemlistq[i].visible = false;
      }
      if (this.shopitemlistr[i] != undefined) {
        this.shopitemlistr[i].visible = false;
      }
    }
    var numfrom = this.respperpage * (this.currentresppage - 1);
    var numto = this.respperpage * this.currentresppage;
    this.plog.log(
      'showinventory_showpage',
      viewpage,
      this.currentresppage,
      'fromto',
      numfrom,
      numto,
      'perpage',
      this.respperpage
    );

    var griddat = [];
    var griddatf = [];

    for (var i = numfrom; i < numto; i++) {
      if (this.shopitemlist[i] != undefined) {
        this.shopitemlist[i].visible = true;
        this.shopitemlistp[i].visible = true;
        this.shopitemlistq[i].visible = true;
        this.shopitemlistr[i].visible = true;

        griddat.push(this.shopitemlist[i]);
        griddatf.push(this.shopitemlist[i]);
        //this.plog.log("xxx1", this.shopitemlist[i]);
        var namea = this.shopitemlist[i].name.split('-');
        var isnewdata =
          this.wh.registry.list.rcvarpass.activechar['item'][namea[1]];
        //this.plog.log("xxx1", isnewdata);
        if (isnewdata != undefined && isnewdata['isnew'] == 1) {
          this.wh.registry.list.notif.show(this.wh, this.shopitemlist[i]);
        }
      }
    }

    for (var k in this.stackarr) {
      var v = this.stackarr[k];
      griddat.push(this.submn[k]);
    }
    griddat.push(this.sellanywhere);
    griddat.push(this.equipbutton);
    //griddat.push(this.useallbtn);
    //griddat.push(this.incubatebtn);

    griddat.push(this.shopclosebtn);
    griddat.push(this.invendropbtn);
    griddat.push(this.sellbtn);
    griddat.push(this.donatebtn);
    griddat.push(this.depositbtn);

    griddat.push(this.shopfwdbtn);
    griddat.push(this.shopbackbtn);
    this.wh.registry.list.gp.guigrid(griddat, griddatf);
    //this.plog.log("xxx1", this.shopitemlist);
  }

  async equip() {
    this.plog.log('rcinventory equip', this.selecteditemno,);
    var res = await this.rchttp.equip(this.selecteditemno);
    await this.wh.registry.list.rchttp.updatecharinfo();

  }
  countatt(data) {
    var cc = 0;
    cc = cc + Number(data.requirements['att_cha']);
    cc = cc + Number(data.requirements['att_con']);
    cc = cc + Number(data.requirements['att_dex']);
    cc = cc + Number(data.requirements['att_int']);
    cc = cc + Number(data.requirements['att_str']);
    cc = cc + Number(data.requirements['att_wis']);
    return cc;
  }
  getitemdat(wh, pos) {
    var cc = 0;
    for (const k in wh.registry.list.rcvarpass.activechar['equip']) {
      var v = wh.registry.list.rcvarpass.activechar['equip'][k];
      //this.plog.log('getitemdat', v);
      if (v['equippos'] == pos) {
        return this.countatt(wh.registry.list.rcvarpass.allitem[v['item']]);
      }
    }
    return 0;
  }
  getitemdatplus(wh, pos) {
    for (const k in wh.registry.list.rcvarpass.activechar['equip']) {
      var v = wh.registry.list.rcvarpass.activechar['equip'][k];
      //this.plog.log('getitemdat', v);
      if (v['equippos'] == pos) {
        return Number(v['itemplus']);
      }
    }
    return 0;
  }

  rebuildguigrid() {
    this.plog.log("rebuildguigrid();b", this.wh.kbfocus);
    this.showinventory_showpage(null, "");
    this.plog.log("rebuildguigrid();a", this.wh.kbfocus);
  }
}
