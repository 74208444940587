import { AStarFinder } from "astar-typescript";
import { plog } from "./plog.service";

export class MapWalker {
    o;
    wh;
    currentx;
    currenty;
    sprite;
    doing = "";
    behavetimer;
    doinginprocess = false;
    astar;
    walkarray = [];
    lastspriteface;
    idlecountdown = 0;
    actionspeed;
    hidecountdown = 0;
    npcdata;
    walktarget = "";
    npcname;
    fixeddoing = "";
    pathi = 0;
    path;
    plog;

    constructor(wh, o) {
        this.wh = wh;
        this.plog=new plog();
        this.o = o;
        //this.plog.log("MapWalker constructor", wh, o); 
        if (this.o["npccode"] == undefined || this.o["npccode"] == "") return;

        //this.plog.log("MapWalker constructorthis.wh.registry.list.rcvarpass.activemapmetrix", this.wh.registry.list.rcvarpass.activemapmetrix);
        //this.npcname = this.o["npcname"];
        this.path = this.o["path"];
        this.actionspeed = Math.floor(Math.random() * (1500 - 600) + 600);
        this.idlecountdown = 0;
        this.npcdata = this.wh.registry.list.rcvarpass.npc[this.o.npccode];
        //this.plog.log("mapwalker npcdata", this.o.npccode);
        this.npcdata['sleeptime'] = 'night';
        if (this.wh.registry.list.rcvarpass.activemapmetrix == undefined) {
            this.plog.error("this.wh.registry.list.rcvarpass.activemapmetrix not defind");
            return;
        }
        this.o.sprite = this.o["npccode"];
        this.npcname = this.npcdata['name'];
        this.o["mapsize"] = 1;
        this.astar = new AStarFinder({
            grid: {
                matrix: this.wh.registry.list.rcvarpass.activemapmetrix
            },
            includeStartNode: false,
            includeEndNode: true
        });
        //this.plog.log("mob astar", this.astar, this);

        //this.wh.mobs.push(this);
        //this.wh.mobs[this.o.petid] = this;

    }
    getobj() {
        return this.o;
    }
    async spawn() {
        if (this.o["npccode"] == undefined || this.o["npccode"] == "") return;
        if (this.sprite != undefined && this.o["npccode"] != "") return;
        if (this.wh.registry.list.rcvarpass.activemapmetrix.length == 0) return;
        //this.plog.log("mapwalker spawn", this, this.wh);
        if (this.wh.textures.list[this.o.sprite] == undefined) {
            this.plog.error("mapwalker.spawn, can't find sprite", this.o);
            return;
        }
        this.sprite = this.wh.add.sprite(100, 100, this.o.sprite);
        this.sprite.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);

        try {
            this.sprite.play("" + this.o["sprite"] + "-idle-d");//.setDisplayOrigin(0.5, 0.8);
        } catch (err) {
            this.plog.log("mapwalker.spawn() error while trying to play idle ", err);
            return;
        }
        this.sprite.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);

        this.wh.zsortlist.push(this.sprite);
        this.wh.extradestroylist.push(this.sprite);
        this.sprite.mapmonobj = this;
        //tmp.sprite.setScale(this.o["mapsize"]);
        this.sprite.displayWidth = this.o["mapsize"] * 32;
        this.sprite.scaleY = this.sprite.scaleX;
        //this.plog.log("mapmop spawn sprite", this.sprite);
        //tmp.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);
        /*
        this.sprite.setInteractive();
        this.sprite.on("pointerdown", function () {
            //this.plog.log("mobclick", this);
            if (this.wh.registry.list.rcvarpass.activemap["map"]["code"] != "backyard") {
                if (this.wh.registry.list.showreward.isclosed()) {
                    this.wh.registry.list.combat.start(this.wh);
                    this.hide(4);
                } else {
                    this.hide(1);
                }
            } else {
                //click mob on backyard
                this.plog.log("mobnpcname", this);
                if (this.npcname != undefined && this.npcname != "") {
                    //this.wh.registry.list.phm.charsaybubble(this.sprite, this.npcname);
                    this.wh.registry.list.phm.charsaybubble(this.wh.char_main, "That's " + this.npcname);
                    this.cheers();
                }
            }
        }, this);
        */
        //var tmp = this.rcvarsservice.gameobj.scene.scenes[0].add.image((spawnposa[1] * 32), (spawnposa[0] * 32), this.rcvarsservice.activechar["sprite"], "walk-d-1").setDepth(999);
        this.wh.mapcontainer.add(this.sprite);
        //this.movetofirstpos();

        /*var moveto = this.getrandwalkpos() + "";
        var movetoa = moveto.split("-");
        var startPos = { x: this.currentx, y: this.currenty }
        var goalPos = { x: movetoa[0], y: movetoa[1] }
        //this.plog.log("I'mma walk to ", movetoa);
        var myPathway = this.astar.findPath(startPos, goalPos);
        //this.plog.log(myPathway);
        this.walkarray = myPathway;
        */

        //this.plog.log("charic",charic);
        //this.plog.log("moveTo", this.charzindex, this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer);
        //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.moveTo(tmp, this.charzindex);
        //this.rcvarsservice.gameobj.scene.scenes[0].char_main = tmp;
        await this.movetofirstpos();
        this.behavetimer = this.wh.time.addEvent({ delay: this.actionspeed, callback: this.behave, callbackScope: this, loop: true });
        //this.behave();
    }
    async movetofirstpos() {
        //this.pathi = 0;
        var movetoo = this.getrandwalkpos();
        //this.plog.log("mapwalker movetofirstpos moveto=", this.pathi, movetoo);
        var movetoa = movetoo['pos'].split("-");
        this.currentx = Number(movetoa[0]);
        this.currenty = Number(movetoa[1]);
        this.sprite.x = ((this.currentx - 1) * 32);//+width/2;
        this.sprite.y = ((this.currenty - 1) * 32);//+width/2;
        this.sprite.displayHeight = 64 * this.o["mapsize"];
        this.sprite.scaleX = this.sprite.scaleY;//displayWidth = 64 * this.o["mapsize"];
        this.sprite.setOrigin(0.5, 0.8);
        //this.plog.log(random, months[random]);
    }
    getrandwalkpos() {
        var moveto = this.path[this.pathi];
        if (moveto == undefined) {
            this.pathi = 0;
            moveto = this.path[this.pathi];
        }
        return moveto;
    }
    hide(countdown) {
        this.sprite.visible = false;
        this.hidecountdown = (countdown * 1000) / this.actionspeed;
    }
    async behave() {
        //this.plog.log("mapwalker.behave",this.wh.combatcontainer);
        //this.plog.log("mapwalker.behave", this.sprite);
        if (this.wh.pauseload == true) return;
        if (this.sprite == undefined) {
            this.plog.log("error, mapwalker.behave(), this.sprite=undefined");
            return;
        }
        if (this.sprite != undefined && this.sprite.scene == undefined) {
            this.plog.log("error, mapwalker.behave(), this.sprite.scene=undefined");
            this.mydestroy();
            return;
        }
        if (this.wh.combatcontainer != undefined && this.wh.combatcontainer.list.length > 0) {
            return;
        }
        /*
        if (this.sprite.visible == false) {
            this.wh.registry.list.phm.mapitemeffect(this.sprite, "warpgate_b");
            this.sprite.visible = true;
        }*/

        //this.plog.log("behaving", this);
        //var action = ["walk", "idle", "sleep"];
        //var action = ["walk"];

        if (this.doinginprocess == true) return;


        this.pathi++;
        if (this.pathi >= this.path.length) this.pathi = 0;
        var curobj = this.path[this.pathi];
        //this.plog.error("mapwalker behav", this.npcdata,this.pathi, curobj)

        //don't re-appear at night
        if (this.sprite.visible == false && this.wh.registry.list.timectl.timename == 'night') {
            this.doinginprocess = true;
            await this.wh.registry.list.rctoast.sleep(2000);
            if (curobj['action'] == 'idle') this.pathi++; //skip idle if after night
            this.doinginprocess = false;
            return;
        }

        if (curobj['action'] == "idle") {
            this.doinginprocess = true;
            if (this.sprite == undefined) return;
            var currentkey = this.sprite.anims.currentFrame.textureKey + "";

            var currnetframe = this.sprite.anims.currentFrame.textureFrame + "";
            var currnetframea = currnetframe.split("-");
            try {
                this.sprite.play({ key: currentkey + "-idle-" + currnetframea[1], repeatDelay: 2000 });
            } catch (e) {
                this.plog.log(e);
            }
            //this.plog.error("mapwalker pausing for", curobj['pausetime']);
            await this.wh.registry.list.rctoast.sleep(curobj['pausetime']);
            this.doinginprocess = false;
            //this.plog.log("Idle it is", this.idlecountdown);
            return;
        }
        if (curobj['action'] != "hide") {
            if (this.sprite.visible == false) {
                this.wh.registry.list.phm.mapitemeffect(this.sprite, "warpgate_b");
                this.sprite.visible = true;
            }
        }
        if (curobj['action'] == "walk") {
            if (this.sprite == undefined) return;
            var currentkey = this.sprite.anims.currentFrame.textureKey + "";
            var currnetframe = this.sprite.anims.currentFrame.textureFrame + "";
            var currnetframea = currnetframe.split("-");

            try {
                // this.sprite.play("" + this.o.sprite + "-idle-d");
                this.sprite.play({ key: currentkey + "-idle-" + currnetframea[1], repeatDelay: 2000 });

            } catch (e) {
                this.plog.log(e);
            }

            //this.doinginprocess=true;
            if (this.walkarray.length == 0) {
                //find the path
                //await this.wh.registry.list.rctoast.sleep(950);

                var moveto = curobj['pos'] + "";
                var movetoa = moveto.split("-");
                var startPos = { x: this.currentx, y: this.currenty }
                var goalPos = { x: movetoa[0], y: movetoa[1] }
                //this.plog.log("I'mma walk to ", movetoa);
                //this.plog.log("mobastar ", this.astar);
                if (startPos == undefined || goalPos == undefined) return;
                try {
                    var myPathway = this.astar.findPath(startPos, goalPos);
                } catch (e) {
                    this.plog.log("mapwalker astar findPath error", e);
                    return;
                }
                //this.plog.log(myPathway);
                this.walkarray = myPathway;
                //this.walktarget = "";
                //this.doing = "";
                //this.plog.error("mapwalker walk path", this.currentx, this.currenty, startPos, goalPos, myPathway);

                //return;
                if (this.walkarray.length == 0) {
                    this.plog.error("mapwalker cant astar path, force move to next path");
                    this.movetofirstpos();
                    //this.behave();
                    return;
                }
            }

            if (this.walkarray.length > 0) {
                this.doinginprocess = true;
                while (this.walkarray.length > 0) {
                    var nextstop = this.walkarray.shift();;
                    //this.plog.log("nextstop", nextstop);

                    // await this.wh.registry.list.rctoast.sleep(100);

                    var dir;
                    if (nextstop[0] > this.currentx && nextstop[1] > this.currenty) {
                        dir = "down right";
                    }
                    if (nextstop[0] > this.currentx && nextstop[1] < this.currenty) {
                        dir = "up right";
                    }
                    if (nextstop[0] < this.currentx && nextstop[1] < this.currenty) {
                        dir = "up left";
                    }
                    if (nextstop[0] < this.currentx && nextstop[1] > this.currenty) {
                        dir = "down left";
                    }
                    if (nextstop[0] == this.currentx && nextstop[1] > this.currenty) {
                        dir = "down";
                    }
                    if (nextstop[0] == this.currentx && nextstop[1] < this.currenty) {
                        dir = "up";
                    }
                    if (nextstop[0] > this.currentx && nextstop[1] == this.currenty) {
                        dir = "right";
                    }
                    if (nextstop[0] < this.currentx && nextstop[1] == this.currenty) {
                        dir = "left";
                    }
                    await this.spritewalk(dir, this.wh);
                }
                try {
                    var currentkey = this.sprite.anims.currentFrame.textureKey + "";
                    var currnetframe = this.sprite.anims.currentFrame.textureFrame + "";
                } catch (e) {
                    this.plog.log("ERROR", e);
                    return;
                }
                var currnetframea = currnetframe.split("-");

                this.sprite.play({ key: currentkey + "-walk-" + currnetframea[1], repeatDelay: 2000 });

                await this.wh.registry.list.rctoast.sleep(curobj['pausetime']);
                this.doinginprocess = false;
                return;
            }
        }
        if (curobj['action'] == "say") {
            this.doinginprocess = true;
            if (this.sprite == undefined) return;
            this.wh.registry.list.phm.charsaybubble(this.sprite, curobj["param1"]);

            var currentkey = this.sprite.anims.currentFrame.textureKey + "";

            var currnetframe = this.sprite.anims.currentFrame.textureFrame + "";
            var currnetframea = currnetframe.split("-");
            try {
                this.sprite.play({ key: currentkey + "-idle-" + currnetframea[1], repeatDelay: 2000 });
            } catch (e) {
                this.plog.log(e);
            }
            // this.plog.error("mapwalker pausing for", curobj['pausetime']);
            await this.wh.registry.list.rctoast.sleep(curobj['pausetime']);
            this.doinginprocess = false;
            return;
            //this.plog.log("Idle it is", this.idlecountdown);
        }

        if (curobj['action'] == "hide") {
            this.doinginprocess = true;
            if (this.sprite == undefined) return;
            this.sprite.visible = false;
            //this.plog.error("mapwalker pausing for", curobj['pausetime']);
            this.wh.registry.list.phm.mapitemeffect(this.sprite, "warpgate_b");
            await this.wh.registry.list.rctoast.sleep(curobj['pausetime']);
            this.doinginprocess = false;
            return;
            //this.plog.log("Idle it is", this.idlecountdown);
        }
    }

    async spritewalk(dir, wh) {
        //this.plog.log("maincharwalk", dir, wh);
        //this.plog.log("maincharwalk this", this);

        //var width = this.width;
        //var height = this.height;
        var tg = this.sprite;
        //this.plog.log("mapbound start", wh.mapbgimg, width, height);
        var newcx = 0;
        var newcy = 0;
        var dirx = 0;
        var diry = 0;

        //wh.char_main.x = 100;        wh.char_main.y = 100;
        newcx = this.currentx;
        newcy = this.currenty;
        var walkspeednormal = 140;
        var walkspeedslow = 170;
        walkspeednormal = 300;
        walkspeedslow = 350;

        var walkspeed = walkspeednormal;
        var tmpposchk;
        var tmpposchka1;
        var tmpposchka2;
        try {
            var spritename = this.sprite.anims.currentFrame.textureKey + "";//this.registry.list.rcvarpass.activechar["sprite"];
        } catch (e) {
            this.plog.log("ERROR", e);
            return;
        }
        if (dir == "up") {
            newcy -= 1;
            diry = 32;
            tg.play({ key: spritename + "-walk-u", repeat: 100 }, true);
        }
        if (dir == "up right") {
            newcy -= 1;
            newcx += 1;
            diry = 32;
            dirx = -32;
            tg.play({ key: spritename + "-walk-r", repeat: 100 }, true);
            walkspeed = walkspeedslow;
        }
        if (dir == "right") {
            newcx += 1;
            dirx = -32;
            tg.play({ key: spritename + "-walk-r", repeat: 100 }, true);
        }
        if (dir == "down right") {
            newcy += 1;
            newcx += 1;
            diry = -32;
            dirx = -32;
            tg.play({ key: spritename + "-walk-r", repeat: 100 }, true);
            walkspeed = walkspeedslow;
        }
        if (dir == "down") {
            newcy += 1;
            diry = -32;
            tg.play({ key: spritename + "-walk-d", repeat: 100 }, true);
        }
        if (dir == "down left") {
            newcy += 1;
            newcx -= 1;
            diry = -32;
            dirx = 32;
            tg.play({ key: spritename + "-walk-l", repeat: 100 }, true);
            walkspeed = walkspeedslow;
        }
        if (dir == "left") {
            newcx -= 1;
            dirx = 32;
            tg.play({ key: spritename + "-walk-l", repeat: 100 }, true);
        }
        if (dir == "up left") {
            newcy -= 1;
            newcx -= 1;
            diry = 32;
            dirx = 32;
            tg.play({ key: spritename + "-walk-l", repeat: 100 }, true);
            walkspeed = walkspeedslow;
        }
        //this.plog.log("walkspeed",walkspeed);

        this.currentx = newcx;
        this.currenty = newcy;

        var tweenchar = wh.tweens.add({
            targets: tg,
            x: wh.mapbgimg.x + (newcx * 32) + wh.defaultmaplayerx + 0,
            y: wh.mapbgimg.y + (newcy * 32) + wh.defaultmaplayery + 0,
            ease: 'Linear',//Power1
            duration: walkspeed,
            repeat: 0,
            onComplete: function (wh) {
                //this.plog.log('onComplete', this);

            }
        });
        await this.wh.registry.list.rctoast.sleep(walkspeed);

    }
    showspritewalk(dir) {
        //this.sprite.alpha = 0.5;
        //this.sprite.setAlpha(0.5);
        if (dir == "left") {
            this.sprite.flipX = false;
        }
        if (dir == "right") {
            this.sprite.flipX = true;
        }
    }
    cheers() {
        this.plog.log("cheers()", this);
        this.walkarray = [];
        var mobchkmax = 2;
        var mobchkmin = 1;
        var monnum = Math.floor(Math.random() * (mobchkmax - mobchkmin) + mobchkmin);

        this.idlecountdown = 2;
        this.doing = "cheers";
        return;
        try {
            this.sprite.play({ key: "mob_" + this.o["sprite"] + "_bored", repeat: 5 });//.setDisplayOrigin(0.5, 0.8);
        } catch (err) {
            this.plog.log("mapwalker.cheers() error while trying to play victory (cheers) ", err);
            return;
        }
    }

    async mydestroy() {
        //return;
        //this.plog.log("MapWalker mydestroy();", this.behavetimer);

        if (this.behavetimer != undefined) {
            try {
                this.behavetimer.paused = true;

                await this.behavetimer.remove(false);
                //this.behavetimer = undefined;
            } catch (e) {
                this.plog.log("mappet mydestroy error1", e)
            }
        }

        if (this.sprite != undefined) {
            this.sprite.destroy();
            this.sprite = undefined;
        }
    }
}