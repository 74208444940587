import { Injectable } from '@angular/core';
import { RCVarsService } from './rcvars-service.service';
declare var stripepaymentRequest;
declare var onesignal_inter;
declare var sharer_global;
declare var sharer_combatlog;
declare var sharer_sharecombatforreward;
declare var amazonbuypackage;

@Injectable({
  providedIn: 'root',
})
export class AnyJs {
  constructor(private rcvarsservice: RCVarsService) { }

  public methodCall() {
    console.log('done');
    return true;
  }
  public stripepaymentRequest(shopcode) {
    console.log('AnyJs stripepaymentRequest', shopcode);
    stripepaymentRequest(shopcode);
    return true;
  }

  public amazonbuypackage(shopcode) {
    console.log('AnyJs amazonbuypackage', shopcode);
    amazonbuypackage(shopcode);
    return true;
  }
  public onesignal_init(chklogin_l) {
    //console.log('AnyJs onesignal_inter', chklogin_l);
    try {
      onesignal_inter(chklogin_l);
    } catch (e) {
      console.warn("anyjs.onesignal_inter()", chklogin_l, e);
    }
    return true;
  }
  public sharer_global() {
    console.log('sharer_global');
    try {
      sharer_global();
    } catch (e) {
      console.warn("anyjs.sharer_global()", e);
    }
  }
  public sharer_combatlog(iden) {
    console.log('sharer_combatlog', iden, this);
    try {
      sharer_combatlog(this.rcvarsservice.rcserver_url + "_openbattlelog.php?iden=" + iden);
    } catch (e) {
      console.warn("anyjs.sharer_combatlog()", e);
    }
  }
  public sharer_sharecombatforreward(iden) {
    console.log('sharer_sharecombatforreward', iden, this);
    try {
      sharer_sharecombatforreward(this.rcvarsservice.rcserver_url + "_openbattlelog.php?iden=" + iden);
    } catch (e) {
      console.warn("anyjs.sharer_sharecombatforreward()", e);
    }
  }
}