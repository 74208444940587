import { Injectable } from '@angular/core';
import { RCVarsService } from './rcvars-service.service';
import { RCOSDService } from './rcosd.service';
import { RCHTTP } from './rchttp.service';
import { plog } from './plog.service';

@Injectable({
  providedIn: 'root',
})
export class SparBoardService {
  skicon = [];
  skob = [];
  sktt = [];
  skbg = [];
  skdescr = [];
  skdescr2 = [];
  fulldat;
  resptotalpage;
  respperpage = 6;
  currentresppage = 1;

  shopbackbtn;
  shopfwdbtn;

  dwidescr_weekly;
  wwdata;
  dwichest_weekly;
  wwtitle = [];
  wwdescr = [];
  wwicon = [];
  wwbtn = [];

  lastdat;
  ddtitle = [];
  dddescr = [];
  ddicon = [];
  ddbtn = [];
  dwidescr_daily;
  dwichest_daily;
  countpets;
  sparbcontainer_char;
  //maskg;
  //maskm;
  selectcircle;
  scrollareabox;
  shopclosebtn;
  helpicon;
  itemdescr;
  eqitems = [];
  eqitemsp = [];
  itemwh = 54;
  shopguibg;
  wh;
  menubaritem = [];
  btnstory;
  btndaily;
  btnweekly;
  countpet;
  mode = '';
  plog;

  constructor(
    public rcvarsservice: RCVarsService,
    public rcosd: RCOSDService,
    public rchttp: RCHTTP
  ) {
    this.plog = new plog();
  }

  firsttimerun = true;
  firsttimerunfunc() {
    if (this.firsttimerun == true) {
      this.wh.registry.list.gameitf.guiwindowclick(this.wh.sparbcontainer);
    }
    this.firsttimerun = false;
  }

  async show(wh, mode, forcefocus = false) {
    this.mode = mode;
    if (forcefocus == true) this.firsttimerun = true;
    this.wh = wh;
    if (wh.sparbcontainer != undefined) {
      await this.show_close(this.wh);
    }
    var width = wh.game.canvas.width;
    var height = wh.game.canvas.height;
    this.plog.log('sparboard show', wh, this);

    this.shopbackbtn = wh.add.sprite(0, 0, "thesb", "backsign");
    this.shopbackbtn.setOrigin(0.5, 0.5);
    this.shopbackbtn.setInteractive();
    this.shopbackbtn.removeAllListeners();
    this.shopfwdbtn = wh.add.sprite(0, 0, "thesb", "forwardsign");
    this.shopfwdbtn.setOrigin(0.5, 0.5);
    this.shopfwdbtn.setInteractive();
    this.shopfwdbtn.removeAllListeners();

    const shopguibg = wh.add.image(0, 0, 'toprankguibg');
    shopguibg.setOrigin(0.5, 0.5);
    shopguibg.setInteractive({ pixelPerfect: true });
    shopguibg.removeAllListeners();
    this.shopguibg = shopguibg;
    //this.plog.log("questgui var", this.rcvarsservice);

    var shopclosebtn = wh.add.sprite(300, 300, "thesq", 'close');
    this.shopclosebtn = shopclosebtn;
    this.shopclosebtn.setOrigin(0.5, 0.5);
    this.shopclosebtn.setPosition(155, -200);
    shopclosebtn.displayWidth = 40;
    shopclosebtn.displayHeight = 40;
    this.shopclosebtn.setInteractive();
    this.shopclosebtn.removeAllListeners();
    this.shopclosebtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        //this.scene.registry.list["thethis"].phm.floorclick(this);
        this.plog.log(
          'gc.npcbackbtn pointerup',
          pointer,
          localX,
          localY,
          event,
          this
        );
        this.show_close(wh);
      },
      this
    );


    this.helpicon = wh.add.sprite(300, 300, "thesq", 'help');
    this.helpicon.setOrigin(0.5, 0.5);
    this.helpicon.setPosition(165, -160);
    this.helpicon.displayWidth = 40;
    this.helpicon.displayHeight = 40;
    this.helpicon.setInteractive();
    this.helpicon.removeAllListeners();
    this.helpicon.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        //this.scene.registry.list["thethis"].phm.floorclick(this);
        this.plog.log(
          'gc.npcbackbtn pointerup',
          pointer,
          localX,
          localY,
          event,
          this
        );
        this.wh.registry.list.rctoast.rclongalert(this.wh,
          this.wh.trs("f:sparbcontainer:Season Reward===Season Reward"),
          this.wh.gl(this.fulldat["spar"]["descr"]));
        //this.show_close(wh);
      },
      this
    );

    wh.sparbcontainer = wh.add.container(300, 300);
    wh.sparbcontainer._refsrv = this;
    wh.sparbcontainer.name = "sparbcontainer";
    wh.sparbcontainer.add(this.shopclosebtn);
    wh.sparbcontainer.add(this.helpicon);
    wh.sparbcontainer.add(shopguibg);
    wh.sparbcontainer.bringToTop(this.shopclosebtn);
    wh.sparbcontainer.bringToTop(this.helpicon);
    var itemdescr = wh.add.text(0, 0, '', wh.fs["list_descr"]);
    wh.sparbcontainer.add(itemdescr);
    itemdescr.setOrigin(0, 0);
    itemdescr.setPosition(-100, -160);
    this.itemdescr = itemdescr;

    this.shopbackbtn.displayWidth = 100;
    this.shopfwdbtn.displayWidth = 100;
    this.shopbackbtn.displayHeight = 40;
    this.shopfwdbtn.displayHeight = 40;
    this.shopbackbtn.setPosition(-110, 220);
    this.shopfwdbtn.setPosition(110, 220);
    wh.sparbcontainer.add(this.shopfwdbtn);
    wh.sparbcontainer.add(this.shopbackbtn);
    wh.sparbcontainer.bringToTop(this.shopfwdbtn);
    wh.sparbcontainer.bringToTop(this.shopbackbtn);

    this.shopbackbtn.removeAllListeners();
    this.shopfwdbtn.removeAllListeners();
    this.shopbackbtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        this.plog.log('shop shopbackbtn', this);
        this.showinventory_showpage(wh, 'back');
      },
      this
    );

    this.shopfwdbtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        this.plog.log('shop shopfwdbtn', this);
        this.showinventory_showpage(wh, 'next');
      },
      this
    );

    //drag
    var shopdragbtn = wh.add.image(300, 300, 'spacer');
    wh.shopdragbtn = shopdragbtn;
    wh.shopdragbtn.setOrigin(0.5, 0.5);
    wh.shopdragbtn.setPosition(0, -200);
    shopdragbtn.displayWidth = 270;
    shopdragbtn.displayHeight = 60;
    wh.shopdragbtn.setInteractive();
    wh.shopdragbtn.removeAllListeners();
    wh.shopdragbtn.on(
      'pointerout',
      function (pointer, localX, localY, event) {
        this.rcvarsservice.gameobj.scene.scenes[0].sparbcontainer.dragactive =
          false;
      },
      this
    );
    wh.shopdragbtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        this.rcvarsservice.gameobj.scene.scenes[0].sparbcontainer.dragactive =
          false;
      },
      this
    );
    wh.shopdragbtn.on(
      'pointerdown',
      function (pointer, localX, localY, event) {
        this.rcvarsservice.gameobj.registry.list.gameitf.guiwindowclick(
          this.rcvarsservice.gameobj.scene.scenes[0].sparbcontainer
        );

        this.rcvarsservice.gameobj.scene.scenes[0].sparbcontainer.dragactive =
          true;
        this.rcvarsservice.gameobj.scene.scenes[0].sparbcontainer.dragactivex =
          this.rcvarsservice.gameobj.scene.scenes[0].sparbcontainer.x;
        this.rcvarsservice.gameobj.scene.scenes[0].sparbcontainer.dragactivey =
          this.rcvarsservice.gameobj.scene.scenes[0].sparbcontainer.y;
        this.rcvarsservice.gameobj.scene.scenes[0].sparbcontainer.dragactivepx =
          pointer.x;
        this.rcvarsservice.gameobj.scene.scenes[0].sparbcontainer.dragactivepy =
          pointer.y;
      },
      this
    );
    this.rcvarsservice.gameobj.scene.scenes[0].sparbcontainer.dragactive =
      false;
    this.rcvarsservice.gameobj.scene.scenes[0].sparbcontainer.dragactivex = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].sparbcontainer.dragactivey = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].sparbcontainer.dragactivepx = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].sparbcontainer.dragactivepy = 0;
    wh.shopdragbtn.on(
      'pointermove',
      function (pointer, localX, localY, event) {
        //this.scene.registry.list["thethis"].phm.floorclick(this);
        //this.plog.log('shop pointermove', pointer, localX, localY, event, this);
        //this.showinventory_close(wh);
        if (
          this.rcvarsservice.gameobj.scene.scenes[0].sparbcontainer
            .dragactive == true
        ) {
          var tmpx =
            this.rcvarsservice.gameobj.scene.scenes[0].sparbcontainer
              .dragactivex;
          var tmpy =
            this.rcvarsservice.gameobj.scene.scenes[0].sparbcontainer
              .dragactivey;
          var tmppx =
            this.rcvarsservice.gameobj.scene.scenes[0].sparbcontainer
              .dragactivepx;
          var tmppy =
            this.rcvarsservice.gameobj.scene.scenes[0].sparbcontainer
              .dragactivepy;
          var movedx = tmppx - pointer.x;
          var movedy = tmppy - pointer.y;
          //this.plog.log('drag', tmppx, pointer.x, movedx);

          this.rcvarsservice.gameobj.scene.scenes[0].sparbcontainer.x =
            tmpx - movedx;
          this.rcvarsservice.gameobj.scene.scenes[0].sparbcontainer.y =
            tmpy - movedy;
          //this.maskg.setPosition(this.rcvarsservice.gameobj.scene.scenes[0].sparbcontainer.x,this.rcvarsservice.gameobj.scene.scenes[0].sparbcontainer.y)
          //this.rcvarsservice.gameobj.scene.scenes[0].sparbcontainer.y = pointer.y;
          //this.setmaskposition();
        }
      },
      this
    );
    wh.sparbcontainer.add(wh.shopdragbtn);
    wh.sparbcontainer.bringToTop(wh.shopdragbtn);
    this.menubaritem.push(wh.shopdragbtn);
    //skilltype bar
    //var equipment = this.rcvarsservice.activechar["equip"];
    //this.plog.log("equipment eq", equipment);
    //this.plog.log("sparbcontainer", wh.sparbcontainer, width, height);
    var svversionurlstring = this.wh.registry.list.rcvarpass.svversionstring;
    if (svversionurlstring != '') {
      svversionurlstring = '?' + svversionurlstring;
    }
    wh.sparbcontainer.setPosition(width / 2, height / 2);
    let loader = new Phaser.Loader.LoaderPlugin(wh);
    var loadlist = ['mob'];
    for (var k in loadlist) {
      var key = loadlist[k];
      loader.atlas(
        key + '',
        this.wh.registry.list.rcvarpass.rc_baseurl +
        'sprite/' +
        key +
        '.png' +
        svversionurlstring,
        this.wh.registry.list.rcvarpass.rc_baseurl +
        'sprite/' +
        key +
        '.json' +
        svversionurlstring
      );
    }
    //move
    wh.sparbcontainer.x = width - this.shopguibg.width / 2;
    /*
    var graphics = wh.make.graphics();
    graphics.fillStyle(0xff0000);
    graphics.fillRect(-200, 0, 300, 300);
    //wh.sparbcontainer.add(graphics);
    //graphics.setOrigin(0,0);
    graphics.setPosition(0, 0);
    graphics.setDepth(10000000);

    //graphics.displayHeight = graphics.displayWidth = 400;
    //this.maskg = graphics;
    this.plog.log("xxxgraphics", graphics);

    //var mask = new Phaser.Display.Masks.GeometryMask(wh.sparbcontainer_char, graphics);
    const mask = graphics.createGeometryMask();
    this.maskm = mask;
*/

    //wh.sparbcontainer_char.on('pointermove', function (pointer) {
    //this.plog.log("xxxcontainer", this);
    //});
    //this.setmaskposition();
    loader.once(
      Phaser.Loader.Events.COMPLETE,
      () => {
        //this.plog.log('Phaser.Loader.Events.COMPLETE');
        //this.plog.log(this);
        //this.show_loaded();
        //this.setmaskposition();

        this.buildpetctnr();
        this.rcvarsservice.gameobj.registry.list.gameitf.guiwindowclick(
          this.rcvarsservice.gameobj.scene.scenes[0].sparbcontainer
        );
      },
      this
    );
    loader.start();
  }
  /*
  setmaskposition() {
    this.maskm.geometryMask.setPosition(
      this.rcvarsservice.gameobj.scene.scenes[0].sparbcontainer.x + 50,
      this.rcvarsservice.gameobj.scene.scenes[0].sparbcontainer.y - 130
    );
    //this.maskm.geometryMask.displayWidth=500;
  }*/
  async buildpetctnr() {
    this.plog.log('buildpetctnr');
    this.firsttimerunfunc();
    var wh = this.wh;
    if (wh.sparbcontainer_char != undefined) {
      wh.sparbcontainer_char.destroy();
    }

    var width = wh.game.canvas.width;
    var height = wh.game.canvas.height;

    wh.sparbcontainer_char = wh.add.container(0, 0);
    wh.sparbcontainer.add(wh.sparbcontainer_char);
    var dat;
    this.fulldat = await this.rchttp.getcommon2('spardata', {
      sparid: this.mode,
    });
    dat = this.fulldat['board'];
    this.itemdescr.setText(this.fulldat["spar"]["name"]);
    this.lastdat = dat;
    this.countpet = 0;
    for (const k in dat) {
      var v = dat[k];
      this.countpet++;
    }
    if (this.countpet > 6) {
      //pages

      this.respperpage = 6;
      this.resptotalpage = Math.ceil(this.countpet / this.respperpage);
    }
    //this.plog.log('xxxcountpet', this.countpet);
    this.sparbcontainer_char = wh.sparbcontainer_char;
    wh.sparbcontainer_char.setPosition(-120, -320);
    var i = -5;
    var rowh = 50;
    var iconwh = 40;
    var rowi = 0;
    var ii = 0;
    var rowhadjust = -260;
    for (const k in dat) {
      var v = dat[k];
      //this.plog.log('xxxskdat', ii, rowi, v);
      this.skicon[ii] = wh.add.sprite(0, 0, 'charava', v["char"]['sprite']);

      wh.sparbcontainer_char.add(this.skicon[ii]);
      this.skicon[ii].displayHeight = this.skicon[ii].displayWidth = iconwh;
      this.skicon[ii].setPosition(
        iconwh / 2 - 20,
        rowi * rowh - 5 + rowhadjust
      );
      this.skbg[ii] = wh.add.image(0, 0, 'rowbg');
      wh.sparbcontainer_char.add(this.skbg[ii]);
      this.skbg[ii].setOrigin(0.5, 0.5);
      this.skbg[ii].setInteractive();
      this.skbg[ii].removeAllListeners();
      this.skbg[ii].name = v['char']["id"];
      this.skbg[ii].on("pointerup", function () {
        this.scene.plog.log('sparboard i click', this.name, this);

        this.scene.registry.list.zchari.show(
          this.scene,
          this.name
        );
      });
      /*this.skbg[ii].on('pointermove', function (pointer) {
        //this.plog.log(this);
        //var pos = this.parentContainer.y + this.y;
        //this.plog.log("pos", pos);
        //if (pos > -150) {
        if (pointer.isDown) {
          this.scene.registry.list.petgui.sparbcontainer_char.y += (pointer.velocity.y / 3);
          this.scene.registry.list.petgui.sparbcontainer_char.y = Phaser.Math.Clamp(this.scene.registry.list.petgui.sparbcontainer_char.y, 430 - (this.scene.registry.list.petgui.countpet * 50), 150);
          //this.scene.registry.list.petgui.movemenubartotop();
          //this.plog.log("xxxskill in container", 0-(this.countpet*50),this.sparbcontainer_char.y);
        }
        //}
      });

      this.skbg[ii].on('pointerup', async function (pointer) {
        this.plog.log("xxx,skill,", this);
        //var tmpnamea = this.name.split("---");
        var data = await this.scene.registry.list.rchttp.getcommon2("allpets");

        this.scene.registry.list.rcosd.show(wh, "pet", data[this.name]);
      });*/

      this.skbg[ii].displayHeight = iconwh + 10;
      this.skbg[ii].displayWidth = 300;
      this.skbg[ii].setPosition(-20 + (this.skbg[ii].displayWidth / 2), rowi * rowh - rowh / 2 - 5 + rowhadjust + (this.skbg[ii].displayHeight / 2));
      wh.sparbcontainer_char.sendToBack(this.skbg[ii]);

      var namedsp = v["char"]['name'] + '';
      if (namedsp == '') namedsp = '??';
      //namedsp = namedsp + ' lv.' + v["char"]['level'];
      this.sktt[ii] = wh.add.text(0, 0, namedsp, wh.fs["list_title"]);
      wh.sparbcontainer_char.add(this.sktt[ii]);
      wh.sparbcontainer_char.bringToTop(this.sktt[ii]);
      this.sktt[ii].setPosition(30, rowi * rowh - 25 + rowhadjust);
      //tmpskname.setResolution(0.25);
      //this.plog.log('zzzzzz', this.sktt[ii]);
      this.skdescr[ii] = wh.add.text(0, 0, wh.registry.list.rcvarpass.allrace[v["char"]["race"]]["name"] + ' lv.' + v["char"]['level'], wh.fs["list_descr"]);
      this.skdescr2[ii] = wh.add.text(0, 0, 'dmg ' + v["combat"]['ddd'], wh.fs["list_dmg"]);
      wh.sparbcontainer_char.add(this.skdescr[ii]);
      wh.sparbcontainer_char.bringToTop(this.skdescr[ii]);
      this.skdescr[ii].setPosition(30, rowi * rowh - 2 + rowhadjust);
      wh.sparbcontainer_char.add(this.skdescr2[ii]);
      wh.sparbcontainer_char.bringToTop(this.skdescr2[ii]);
      this.skdescr2[ii].setPosition(280 - this.skdescr2[ii].displayWidth, rowi * rowh - 20 + rowhadjust);



      i++;
      ii++;
      rowi++;
      if (rowi == 6) rowi = 0;
    }
    this.sparbcontainer_char.y = 150;
    //wh.sparbcontainer_char.setMask(this.maskm);
    this.showinventory_showpage(wh, 'back');

    //this.plog.log("xxxmask", this.maskm);
    this.wh.registry.list.rcloading.close();
  }

  showinventory_showpage(gc, viewpage) {
    if (viewpage == 'back') {
      if (this.currentresppage > 1) this.currentresppage--;
    }
    if (viewpage == 'next') {
      if (this.currentresppage < this.resptotalpage) this.currentresppage++;
    }
    for (var i = 0; i <= 100; i++) {
      //this.plog.log(this.shopitemlist[i]);
      if (this.skicon[i] != undefined) {
        this.skicon[i].visible = false;
      }
      if (this.sktt[i] != undefined) {
        this.sktt[i].visible = false;
      }
      if (this.skob[i] != undefined) {
        this.skob[i].visible = false;
      }
      if (this.skbg[i] != undefined) {
        this.skbg[i].visible = false;
      }
      if (this.skdescr[i] != undefined) {
        this.skdescr[i].visible = false;
      }
      if (this.skdescr2[i] != undefined) {
        this.skdescr2[i].visible = false;
      }
    }
    var numfrom = this.respperpage * (this.currentresppage - 1);
    var numto = this.respperpage * this.currentresppage;
    this.plog.log(
      'showinventory_showpage',
      viewpage,
      this.currentresppage,
      'fromto',
      numfrom,
      numto,
      'perpage',
      this.respperpage
    );

    var griddat = [];
    var griddatf = [];

    griddat.push(this.shopclosebtn);
    griddat.push(this.helpicon);

    griddat.push(this.shopfwdbtn);
    griddat.push(this.shopbackbtn);


    for (var i = numfrom; i < numto; i++) {
      if (this.skicon[i] != undefined) {
        this.skicon[i].visible = true;
        this.skbg[i].visible = true;
        if (this.skob[i] != undefined) this.skob[i].visible = true;
        this.sktt[i].visible = true;
        this.skdescr[i].visible = true;
        this.skdescr2[i].visible = true;
        griddat.push(this.skbg[i]);
        griddatf.push(this.skbg[i]);

      }
    }
    this.wh.registry.list.gp.guigrid(griddat, griddatf);

  }

  async show_close(wh) {
    wh.sparbcontainer.visible = false;
    this.wh.registry.list.gameitf.closegui(wh, wh.sparbcontainer);

    for (const k in this.wh.sparbcontainer.list) {
      try {
        await this.wh.sparbcontainer.remove(this.wh.sparbcontainer.list[k], true);
        //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list[k].destroy();
      } catch (e) {
        this.plog.log("ERROR! sparbcontainer.destroy() ", e);
      }
    }

    for (const k in this.wh.sparbcontainer.list) {
      try {
        await this.wh.sparbcontainer.remove(this.wh.sparbcontainer.list[k], true);
        //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list[k].destroy();
      } catch (e) {
        this.plog.log("ERROR! sparbcontainer.destroy() ", e);
      }
    }
    wh.sparbcontainer = undefined;
  }

  rebuildguigrid() {
    this.plog.log("rebuildguigrid();b", this.wh.kbfocus);
    this.showinventory_showpage(null, "");
    this.plog.log("rebuildguigrid();a", this.wh.kbfocus);
  }
}
