import { AStarFinder } from "astar-typescript";
import { plog } from "./plog.service";
import { AppRoutingModule } from "./app-routing.module";

export class COMBATANT {
    iconlist = [];
    timeline;
    atkarrow = [];
    atkarrowi = 0;
    name = "";
    aaorigid;
    spriteorig = "";
    issummonexplode;
    pgbar_health;
    attacktimeorig = 800;
    attacktime = 800;
    attackwaituntil = 0;
    combating = false;
    time_goodbuff = 1000;
    time_badbuff = 1000;
    cbt;
    wh;
    issummoned = false;
    summonedby;
    summonedwith;
    sprite;
    posinfo;
    spawnpos;
    objid;
    tmpe;
    dbid;
    targetid = undefined;
    combatobj;
    currentposx = 0;
    currentposy = 0;
    iswalking = false;
    levelrunnerid = 0;
    statuses = [];//buff debuff
    dmgadjust = 0.1;
    maxenergyrec = 0.35;
    friendlyname = "";
    plog;

    calcspd(parameter) {
        // Check if the parameter is within the valid range
        if (parameter < 1) {
            parameter = 1;
        }
        if (parameter > 20000) {
            parameter = 20000
        }

        // Calculate the number within the desired range
        return Math.floor((parameter - 1) / 20000 * 650) + 1;
    }
    constructor(combatobj, wh, cbt, posinfo, spawnpos, aaorigid) {
        //this.plog.log("COMBATANT constructor", wh, cbt, posinfo, spawnpos);
        this.plog = new plog();
        this.cbt = Object.assign({}, cbt),// cbt;
            this.wh = wh;
        this.aaorigid = aaorigid;
        this.spawnpos = spawnpos;
        this.posinfo = posinfo;
        this.combatobj = combatobj;
        //this.cbt["hp"] = this.cbt["maxhp"];
        //this.plog.log("COMBATANT constructor config", this.combatobj.config,this.cbt);
        this.cbt["hp"] = Number(this.cbt["hp"]);
        this.attacktimeorig = Math.floor(1200 - this.calcspd(this.cbt["spd"]));
        this.time_goodbuff = Math.floor(1000 * this.combatobj.timespeed);
        this.time_badbuff = Math.floor(1000 * this.combatobj.timespeed);
        //this.plog.log("combatant attacktime=", this.attacktimeorig);
        if (this.combatobj.config.combatmode == "boss" || this.combatobj.config.combatmode == "svboss") {
            if (Number(this.cbt["team"]) == 2) {
                this.cbt["hp"] = this.cbt["maxhp"] - Number(this.combatobj.config["data"]["bossdmg"]);
                this.plog.log("COMBATANT constructor team2bossmode", this.combatobj.config, this.cbt);
            }
        }
        if (Number(this.cbt["team"]) == 2) {
            this.friendlyname = "team2 " + this.cbt['name'] + " lv." + this.cbt['level'];
            this.dmgadjust = 0.105;
        } else {
            this.friendlyname = "team1 " + this.cbt['name'] + " lv." + this.cbt['level'];
        }
        if (this.cbt["userunnerlevel"] != undefined) {
            this.levelrunnerid = this.cbt["userunnerlevel"];
            //this.plog.error("set runneridt0",this.levelrunnerid);
        }
        this.cbt["energy"] = 1;//energy multiplier, for skill
        //if (this.cbt["hp"]==NaN) this.cbt["hp"]=this.cbt["maxhp"];
        //this.plog.log("combatant spawnpos mobteam2!",spawnpos);
        var wha = spawnpos.split("-");
        this.currentposx = wha[0];
        this.currentposy = wha[1];
        // this.plog.log("xxx", this.cbt);

        for (const key in this.cbt["skilluse"]) {
            if (Object.prototype.hasOwnProperty.call(this.cbt["skilluse"], key)) {
                this.cbt["skilluse"][key]["energy"] = 0;
            }
        }
        for (const key in this.cbt["skillweaponuse"]) {
            if (Object.prototype.hasOwnProperty.call(this.cbt["skillweaponuse"], key)) {
                this.cbt["skillweaponuse"][key]["energy"] = 0;
            }
        }
        this.name = this.cbt.name + " (lv." + this.cbt.level + ")";
        //this.plog.log("COMBATANT constructor e", this);
        if (this.cbt['hp'] == undefined || Number.isNaN(this.cbt['hp']) || isNaN(this.cbt["hp"])) {
            //this.plog.log("combatant construct hpchk", this.cbt)
            this.cbt['hp'] = Number(this.cbt['maxhp']);
        }
        this.spriteorig = this.cbt["spriteorig"] = this.cbt["sprite"];
        if (this.combatobj.mincombat == "yes") {
            if (this.cbt["type"] == "char") {
                if (this.cbt["team"] == 1) {
                    this.cbt["sprite"] = "mincombatcharb";
                } else {
                    this.cbt["sprite"] = "mincombatcharr";
                }
            } else {
                if (this.cbt["team"] == 1) {
                    this.cbt["sprite"] = "mincombatmobb";
                } else {
                    this.cbt["sprite"] = "mincombatmobr";
                }
            }
        }
    }
    logtext(col, txt) {
        /**/
        var color = "#000000";
        var fontw = "normal";

        if (col.indexOf("B") != -1) {
            col = col.replace("B", "");
            fontw = "bold";
        }
        if (txt.indexOf("!!defeated") != -1) {
            txt = txt.replace("!!defeated", "defeated");
            fontw = "bold";
        }
        if (col == "a") {//auto
            if (this.cbt.team == 1) col = "g";
            if (this.cbt.team == 2) col = "r";
        }

        if (col == "r") color = "#ff0000";
        if (col == "g") color = "#006600";
        if (col == "b") color = "#0000ff";
        if (col == "0") color = "#000000";

        this.combatobj.logtext.push(col + fontw + ":::" + txt);
        //this.plog.log('%c logtext ' + txt + ' ', 'color: ' + color + '; font-weight: ' + fontw + ";");
        //♣♣♣ 
        ///this.combatobj.updatelogtext();
    }
    logtextgetname(id?) {
        if (id == undefined)
            return this.name + ' : ';
        if (this.combatobj.cbto[id] != undefined)
            return this.combatobj.cbto[id].name;
        return "-";
    }
    setcurrentpos(wh) {
        //this.plog.log("setcurrentpos", wh, this);
        var wha = wh.toString().split("-");
        this.currentposx = wha[0];
        this.currentposy = wha[1];
    }
    moveto(x, y) {
        this.setcurrentpos(x + "-" + y);
        //this.plog.log("setcurrentpos", wh, this);

        this.sprite.x = this.posinfo.left + (x * this.posinfo.grid);
        this.sprite.y = this.posinfo.top + (y * this.posinfo.gridy);
    }
    movetobackline() {
        if (this.cbt["team"] == 1) {
            var xplace = Math.random() * (this.combatobj.gridh - 1) + 1;
            xplace = Math.floor(xplace);
            this.moveto(1, xplace);
        } else {
            var xplace = Math.random() * (this.combatobj.gridh - 3) + 1;
            xplace = Math.floor(xplace);
            this.moveto(this.combatobj.gridw, xplace);
        }
    }
    setspawnpos(wh) {
        //this.plog.log("setspawnpos", wh, this);
        this.spawnpos = wh;
        this.wh.registry.list.rchttp.savespawnpos(this.cbt["type"], this.dbid, wh);
        var wha = wh.toString().split("-");
        this.currentposx = wha[0];
        this.currentposy = wha[1];
    }
    createSprite() {
        var tmp = this.wh.registry.list.rcvarpass.gameobj.scene.scenes[0].add.sprite(100, 100, this.cbt["sprite"]);;;
        //this.plog.log("createSprite", this.posinfo.charsize, this.wh, this.cbt);
        tmp.refobj = this;
        this.sprite = tmp;
        this.sprite.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);

        this.wh.combatcontainer.add(this.sprite);


        if (this.cbt["type"] == "mob" || this.cbt["type"] == "pet") {
            //this.plog.error("createsprite,idle",this.cbt["type"],"mob_" + this.cbt["sprite"] + '_idle',this.combatobj.cbtfr);
            this.sprite.play({ key: "mob_" + this.cbt["sprite"] + '_idle', frameRate: this.combatobj.cbtfr });
            var mobinfo = this.wh.registry.list.rcvarpass.allmob[this.cbt["mobcode"]];
            this.sprite.displayHeight = this.posinfo.charsize * mobinfo["combatsize"];
            this.sprite.scaleX = this.sprite.scaleY;//displayWidth = this.posinfo.charsize * mobinfo["combatsize"];
            // this.plog.log("createSprite mob", mobinfo["combatsize"]);
            //this.plog.log("createSprite mob", this.posinfo.charsize, mobinfo["combatsize"], this.sprite.displayHeight);

        }
        if (this.cbt["type"] == "char") {
            this.sprite.play({ key: this.cbt["sprite"] + '-walk-l-idle', frameRate: this.combatobj.cbtfr, repeatDelay: 3000 });

            this.sprite.displayHeight = this.posinfo.charsize * 1;
            this.sprite.displayWidth = this.posinfo.charsize * 1;
        }
        if (this.cbt["team"] == 1) {
            this.faceright();
        }
        if (this.cbt != undefined && this.cbt["raritycount"] != undefined && this.cbt["raritycount"] != "" && this.cbt["raritycount"] + "" != "0") {
            //aura
            if (this.combatobj.mincombat != "yes") {
                if (this.wh.registry.list.thethis != undefined && this.wh.registry.list.thethis.chkconfiglighteffects != undefined && this.wh.registry.list.thethis.chkconfiglighteffects == "yes") {
                    this.tmpe = this.wh.registry.list.gameitf.genchareff(Math.floor(Number(this.cbt["raritycount"])), this.wh);

                    this.wh.combatcontainer.add(this.tmpe);
                    this.wh.combatcontainer.bringToTop(this.tmpe);
                    this.wh.combatcontainer.bringToTop(this.sprite);
                    this.combatobj.followset.push({ leader: this.sprite, follower: this.tmpe });
                    this.tmpe.scaleX = this.tmpe.scaleY = this.sprite.scaleX;
                }
            }
            this.wh.combatcontainer.bringToTop(this.sprite);
        }
        this.wh.combatcontainer.bringToTop(this.sprite);
        //this.plog.log(tmp);
        this.wh.registry.list.rcvarpass.gameobj.scene.scenes[0].zsortlistcombat.push(this.sprite);
        //setpos
        var posa = this.spawnpos.split("-");
        this.sprite.x = this.posinfo.left + (posa[0] * this.posinfo.grid);
        this.sprite.y = this.posinfo.top + (posa[1] * this.posinfo.gridy);
        this.logtext("b", this.logtextgetname() + "Spawn Position: " + posa + ", team " + this.cbt.team);
        this.sprite.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);

    }
    faceright() {
        //this.plog.error("faceright",this.cbt["type"],this);
        if (this.cbt["type"] == "mob" || this.cbt["type"] == "pet") {
            this.sprite.play({ key: "mob_" + this.cbt["sprite"] + '_idle', frameRate: this.combatobj.cbtfr, repeat: 100 });
            this.sprite.flipX = true;
        }
        if (this.cbt["type"] == "char") {
            this.sprite.play({ key: this.cbt["sprite"] + '-walk-r-idle', frameRate: this.combatobj.cbtfr, repeatDelay: 3000 });
        }
    }
    faceleft() {
        //this.plog.error("faceleft",this.cbt["type"],this);
        //this.sprite.flipX = false;
        if (this.cbt["type"] == "mob" || this.cbt["type"] == "pet") {
            //this.sprite.play({ key: "mob_" + this.cbt["sprite"] + '_idle', frameRate: this.combatobj.cbtfr});
            this.sprite.flipX = false;
        }
        if (this.cbt["type"] == "char") {
            this.sprite.play({ key: this.cbt["sprite"] + '-walk-l-idle', frameRate: this.combatobj.cbtfr });
        }
    }
    findknockbackpos(x, y, dir, amnt) {
        if (dir == "u") {
            y = y - amnt; if (y < 0) y = 0;
        }
        if (dir == "d") {
            y = y + amnt; if (y > this.combatobj.gridh) y = this.combatobj.gridh;
        }
        if (dir == "l") {
            x = x - amnt; if (x < 0) x = 0;
        }
        if (dir == "r") {
            x = x + amnt; if (x > this.combatobj.gridw) x = this.combatobj.gridw;
        }
        return { x: x, y: y };
    }
    findthistargetdirection(x, y) {

        var diffx = x - this.sprite.x;
        var diffy = y - this.sprite.y;
        //this.plog.log("findtargetdirection", diffx, diffy);
        var resdir = "d"; //default dir
        if (diffx > 0) {
            resdir = "l";
        } else {
            resdir = "r";
        }
        if (Math.abs(diffy) > Math.abs(diffx)) { //more on another axis
            if (diffy > 0) {
                resdir = "d";
            } else {
                resdir = "u";
            }
        }
        //this.plog.log("findthistargetdirection",x,y,resdir);
        return resdir;
    }
    findtargetdirection() {
        if (this.targetid == undefined) return "d";
        //this.plog.log("findtargetdirection",this.targetid,this.combatobj.cbto[this.targetid]);
        var diffx = this.combatobj.cbto[this.targetid].sprite.x - this.sprite.x;
        var diffy = this.combatobj.cbto[this.targetid].sprite.y - this.sprite.y;
        //this.plog.log("findtargetdirection", diffx, diffy);
        var resdir = "d"; //default dir
        if (diffx > 0) {
            resdir = "r";
        } else {
            resdir = "l";
        }
        if (Math.abs(diffy) > Math.abs(diffx)) { //more on another axis
            if (diffy > 0) {
                resdir = "d";
            } else {
                resdir = "u";
            }
        }
        return resdir;
    }
    battletick() {
        //if (this.cbt["hp"] <= 0) this.dead();
        //this.plog.log("battletick");
        //if (this.cbt["team"] == 2) this.plog.log("battletick team 2");
        this.dostatusesffects();
        var usecbt = this.getcbtwithstat();
        if (usecbt["stun"] == 1) {
            //this.plog.log("skip, stun", this.objid);
            if (this.cbt["type"] == "char") {
                var dir = this.findtargetdirection();
                this.sprite.play({ key: this.cbt["sprite"] + "-idle-" + dir, frameRate: this.combatobj.cbtfr }, true);//.chain({ key: this.cbt["sprite"] + "-idle-" + dir, frameRate: this.combatobj.cbtfr });
            } else {
                this.sprite.play({ key: "mob_" + this.cbt["sprite"] + "_dizzy", frameRate: this.combatobj.cbtfr * 2 }, true);//.chain({ key: "mob_" + this.cbt["sprite"] + "_dizzy", frameRate: this.combatobj.cbtfr*10 });
            }
            this.updatepgbar();
            this.sprite.setBlendMode(Phaser.BlendModes.MULTIPLY);

            return;
        } else {
            this.sprite.setBlendMode(Phaser.BlendModes.NORMAL);

        }
        if (this.iswalking == true) {
            //this.plog.log("skip, walking");
            return;
        }
        if (this.targetid == undefined) {
            //if (this.cbt["team"] == 2) this.plog.log("battletick team 2 lookfortarget");
            this.targetid = this.lookfortarget();
        }
        //if (this.objid == 0)
        //this.plog.log("xxx char target", this.targetid, this.combatobj.cbto[this.targetid]);
        if (this.targetid == undefined) {
            //this.plog.log("battletick No target found for", this.objid, " type=", this.cbt["type"]);
            return;
        } else {
            //this.plog.log("my id",this.objid,"got targetid=", this.targetid);
        }
        if (this.combatobj.cbto[this.targetid] == undefined) {
            //if (this.cbt["team"] == 2) this.plog.log("battletick team 2  targetid undefined"+this.targetid);

            this.targetid = undefined;
            return;
        }
        if (this.cbt["team"] == 1) {
            //this.plog.log("battletick chr target=", this.targetid, this.chkattacktime());
        }
        //this.plog.log("walkablegrid from cbt ", this.combatobj.walkablegrid);
        var gridclone = this.combatobj.walkablegrid;
        //this.plog.log("battletick mob gridclone=",gridclone);
        if (this.cbt["team"] == 2) {
        }
        gridclone[this.combatobj.cbto[this.targetid].currentposy][this.combatobj.cbto[this.targetid].currentposx] = 0;
        var tmpastar = new AStarFinder({
            grid: {
                matrix: gridclone
            },
            includeStartNode: false,
            includeEndNode: false
        });
        if (this.combatobj.cbto[this.targetid] == undefined) {
            this.targetid = undefined;
            return;
        }
        var startPos = { x: this.currentposx, y: this.currentposy };
        var goalPos = { x: this.combatobj.cbto[this.targetid].currentposx, y: this.combatobj.cbto[this.targetid].currentposy };
        //this.plog.log("battletick startPos", startPos);
        //this.plog.log("battletick goalPos", goalPos);
        if (startPos.x == goalPos.x && startPos.y == goalPos.y) {
            //already standing on it
            //if (this.cbt["team"] == 2) this.plog.log("battletick team 2 doattacktarget");
            this.doattacktarget();
            return;
        }
        try {
            var myPathway = tmpastar.findPath(startPos, goalPos);
            //this.plog.log("findPath ", startPos, goalPos, "grid", gridclone);
        } catch (error) {
            //this.plog.log("ERROR target ",this.targetid,this.combatobj.cbto[this.targetid].cbt["type"]);
            //possibly block path to target
            this.targetid = undefined;
            this.plog.log("ERROR ", this.cbt["type"], error);
            //this.plog.log("ERROR ", startPos, goalPos);
            return;
        }
        if (myPathway.length <= this.cbt["combatrange"]) {
            //this.plog.log("in range, do attack", this.targetid, this.combatobj.cbto[this.targetid], this.cbt);
            //if (this.cbt["team"] == 2) this.plog.log("battletick mob doattacktarget=", this.targetid, myPathway);
            this.doattacktarget();
        } else {
            //if (this.cbt["team"] == 2) this.plog.log("battletick mob dowalktotarget=", this.targetid);

            //this.plog.log("NOT in range, gonna walk", this.cbt["combatrange"]);
            if (myPathway == undefined || myPathway[0] == undefined || myPathway[0][0] == undefined) {
                //this.targetid = this.lookfortarget();
                //return;
            } else {
                this.dowalktotarget(myPathway[0]);
                //this.plog.log(myPathway);
                this.setcurrentpos(myPathway[0][0] + "-" + myPathway[0][1]);
            }
        }
        //this.plog.log("myPathway", myPathway);
        //if (this.cbt["team"] == 2) this.plog.log("battletick team 2 reach final");

        this.updatepgbar();
    }
    updatepgbar() {
        //this.plog.log("updatepgbar")
        //this.plog.log("updatepgbar iconlist",this.iconlist.length)
        for (const k in this.iconlist) {
            var v = this.iconlist[k];
            if (v == undefined) continue;
            try {
                v.destroy();
            } catch (e) {
                this.plog.log("ERROR", e);
            }
        }
        //statusicon
        if (this.pgbar_health != undefined) {// && this.combatobj.mincombat != "yes"
            if (this.cbt["team"] == 1) {
                var iconstartx = this.pgbar_health.bar.x + 10;
                var iconstarty = this.pgbar_health.bar.y - 10;
            } else {
                var iconstartx = this.pgbar_health.bar.x + 15;
                var iconstarty = this.pgbar_health.bar.y - 10;
                // this.plog.log("updatepgbar status1", this.pgbar_health);
            }
            //this.plog.log("updatepgbar statuses", this.statuses);
            var iconsize = 15;
            var iconmargin = 2;
            var count = 0;
            for (const k in this.statuses) {
                var v = this.statuses[k];
                if (v == undefined) continue;
                if (v["time"] < this.combatobj.gett()) {
                    this.statuses[k] = undefined;
                    continue;
                }
                if (count >= 4) break; // not enough space
                //this.plog.log("skill_tp statuschk", v);
                var tmp;
                if (this.wh.registry.list.rcvarpass.skilltp[v["icon"]] != undefined) {
                    if (v["icon"] != undefined) {
                        var iconuse = v["icon"] + "";
                        iconuse = iconuse.replace("-", "");
                        tmp = this.wh.registry.list.rcvarpass.gameobj.scene.scenes[0].add.sprite(100, 100, "skill_tp", iconuse);
                    }
                } else {
                    if (v["icon"] != undefined) {
                        var iconuse = v["icon"] + "";
                        iconuse = iconuse.replace("-", "");
                        tmp = this.wh.registry.list.rcvarpass.gameobj.scene.scenes[0].add.sprite(100, 100, "skill_weapon_tp", iconuse);
                    }
                }
                if (tmp != undefined) {
                    this.iconlist.push(tmp);
                    this.wh.registry.list.rcvarpass.gameobj.scene.scenes[0].combatcontainer.add(tmp);
                    this.wh.registry.list.rcvarpass.gameobj.scene.scenes[0].combatcontainer.bringToTop(tmp);
                    tmp.y = iconstarty;
                    tmp.x = iconstartx + (iconsize * count) + (iconmargin * count);
                    tmp.displayHeight = tmp.displayWidth = iconsize;
                    //this.plog.log("updatepgbar status", v,tmp);
                    //this.plog.log("updatepgbar time", v,v["time"]-this.combatobj.gett());
                    var tw = this.wh.tweens.add(
                        {
                            targets: tmp,
                            alpha: 0,
                            ease: 'Power1',
                            duration: 40, // duration of animation; higher=slower
                            delay: v["time"] - this.combatobj.gett(),
                            onComplete: function () {
                                //this.plog.log("oncomplete atkarrow", this, arguments);
                                if (this.targets[0] != undefined)
                                    this.targets[0].destroy();
                                //this.targets[0].refobj.combating = false;
                            }  // set context? how?
                        }, this
                    );
                    count++;
                }
            }
        }



        //this.plog.log(" dead chk hp", this.cbt["hp"], this.objid);

        if (this.cbt["hp"] <= 0) {
            //this.plog.log("found dead", this.objid);
            //this.dead();
        }
        //return;

        if (this.pgbar_health != undefined) {
            //this.pgbar_health.set1(this.cbt["hp"]);
            //this.plog.log("combatant-updatepgbar",this.pgbar_health);
            this.pgbar_health.set(this.cbt["hp"], this.cbt["maxhp"]);
            //this.plog.log("combatant updatepgbar"+this.friendlyname,this.pgbar_health,this.cbt["hp"], this.cbt["maxhp"]);
        } else {
            // this.plog.log("combatant updatepgbar"+this.friendlyname+"  pgbar_health undefined!",this.pgbar_health,this.cbt["hp"], this.cbt["maxhp"]);
        }
        for (const key in this.cbt["skilluse"]) {
            if (Object.prototype.hasOwnProperty.call(this.cbt["skilluse"], key)) {
                //this.plog.log(this.cbt["skilluse"][key].bar);
                if (this.cbt["skilluse"][key].bar != undefined) { //team1
                    this.cbt["skilluse"][key].bar.set1(this.cbt["skilluse"][key]["energy"]);
                }
                //this.plog.log("iconbar iconbar=", this.cbt["skilluse"][key].iconbar);
                if (this.cbt["skilluse"][key].iconbar != undefined) { //team2

                    var percent = ((100 * this.cbt["skilluse"][key]["energy"]) / this.cbt['skilluse'][key]['skilleffects']['a.energy']);
                    if (percent > 100) percent = 100;
                    if (percent < 0) percent = 0;
                    //this.plog.log("iconbar percent=", percent, this.cbt['skilluse'][key]['skilleffects']['a.energy']);
                    var usew = (percent / 100) * this.cbt["skilluse"][key].icon.displayWidth;
                    this.cbt["skilluse"][key].iconbar.displayWidth = usew;
                    //this.plog.log("iconbar usew=", usew, this.cbt["skilluse"][key].iconbar );
                }

            }
        }

        for (const key in this.cbt["skillweaponuse"]) {
            if (Object.prototype.hasOwnProperty.call(this.cbt["skillweaponuse"], key)) {
                //this.plog.log(this.cbt["skilluse"][key].bar);
                if (this.cbt["skillweaponuse"][key].bar != undefined) { //team1
                    this.cbt["skillweaponuse"][key].bar.set1(this.cbt["skillweaponuse"][key]["energy"]);
                }
                //this.plog.log("iconbar iconbar=", this.cbt["skilluse"][key].iconbar);
                if (this.cbt["skillweaponuse"][key].iconbar != undefined) { //team2

                    var percent = ((100 * this.cbt["skillweaponuse"][key]["energy"]) / this.cbt['skillweaponuse'][key]['skilleffects']['a.energy']);
                    if (percent > 100) percent = 100;
                    if (percent < 0) percent = 0;
                    //this.plog.log("iconbar percent=", percent, this.cbt['skilluse'][key]['skilleffects']['a.energy']);
                    var usew = (percent / 100) * this.cbt["skillweaponuse"][key].icon.displayWidth;
                    this.cbt["skillweaponuse"][key].iconbar.displayWidth = usew;
                    //this.plog.log("iconbar usew=", usew, this.cbt["skilluse"][key].iconbar );
                }

            }
        }
        /*
        for (const key in this.cbt["skillweaponuse"]) {
            if (Object.prototype.hasOwnProperty.call(this.cbt["skillweaponuse"], key)) {
                if (this.cbt["skillweaponuse"][key].bar != undefined) {
                    this.cbt["skillweaponuse"][key].bar.set1(this.cbt["skillweaponuse"][key]["energy"]);
                }
            }
        }*/
    }
    addenergy(num, fromclick = false) {
        //num = 5;
        //this.plog.log("addenergy()", num);
        //check skill first
        //this.plog.log("addenergy", this.cbt["skilluse"],num);
        /*if (this.cbt["team"] == 2) {
            this.plog.error("addenergy team2", fromclick,num);
        }*/
        var numuse;
        for (const key in this.cbt["skilluse"]) {
            if (Object.prototype.hasOwnProperty.call(this.cbt["skilluse"], key)) {
                if (this.cbt["skilluse"][key]["skill_type"] == "active") {
                    /* 
                 if (this.cbt["team"] == 2) {
                     this.plog.error("addenergy team2 skilluse", fromclick,num,this.cbt["skilluse"][key]);
                 }*/
                    //this.plog.log("addenergy", this.cbt["skilluse"][key],num);
                    //this.plog.log("addenergy", this.cbt["skilluse"][key]["code"], this.cbt["skilluse"][key]["energy"], "/", this.cbt["skilluse"][key]["skilleffects"]["a.energy"]);
                    if (fromclick == true) {
                        if (this.combatobj.battlebegin == "no") {
                            if (this.cbt["skilluse"][key]["energy"] <= this.cbt["skilluse"][key]["skilleffects"]["a.energy"] * 0.4) {
                                this.cbt["skilluse"][key]["energy"] += num;
                            }
                        } else {
                            this.cbt["skilluse"][key]["energy"] += num / 10;
                        }
                    } else {
                        numuse = num;
                        if (numuse > Math.floor(this.cbt["skilluse"][key]["skilleffects"]["a.energy"] * this.maxenergyrec)) {
                            numuse = Math.floor(this.cbt["skilluse"][key]["skilleffects"]["a.energy"] * this.maxenergyrec);
                        }
                        this.cbt["skilluse"][key]["energy"] += numuse;
                    }
                }
            }
            if (this.combatobj.battlebegin == "no")
                if (fromclick == true) {
                    if (this.cbt["skilluse"][key]["energy"] > this.cbt["skilluse"][key]["skilleffects"]["a.energy"] * 0.4) {
                        this.cbt["skilluse"][key]["energy"] = this.cbt["skilluse"][key]["skilleffects"]["a.energy"] * 0.4;
                    }
                }
        }
        for (const key in this.cbt["skillweaponuse"]) {
            if (Object.prototype.hasOwnProperty.call(this.cbt["skillweaponuse"], key)) {


                if (this.cbt["skillweaponuse"][key]["skill_type"] == "active") {
                    if (fromclick == true) {
                        if (this.combatobj.battlebegin == "no") {
                            if (this.cbt["skillweaponuse"][key]["energy"] <= this.cbt["skillweaponuse"][key]["skilleffects"]["a.energy"] * 0.4) {
                                this.cbt["skillweaponuse"][key]["energy"] += num;
                            }
                        } else {
                            this.cbt["skillweaponuse"][key]["energy"] += num / 10;
                        }
                    } else {
                        numuse = num;
                        if (numuse > Math.floor(this.cbt["skillweaponuse"][key]["skilleffects"]["a.energy"] * this.maxenergyrec)) {
                            numuse = Math.floor(this.cbt["skillweaponuse"][key]["skilleffects"]["a.energy"] * this.maxenergyrec);
                        }
                        this.cbt["skillweaponuse"][key]["energy"] += numuse;
                        /*if (this.cbt["team"] == 2) {
                            this.plog.error("addenergy team2 skillweaponuse", fromclick,num,this.cbt["skillweaponuse"][key]);
                        }*/
                    }
                }
            }
            if (fromclick == true)
                if (this.combatobj.battlebegin == "no")
                    if (this.cbt["skillweaponuse"][key]["energy"] > this.cbt["skillweaponuse"][key]["skilleffects"]["a.energy"] * 0.4) {
                        this.cbt["skillweaponuse"][key]["energy"] = this.cbt["skillweaponuse"][key]["skilleffects"]["a.energy"] * 0.4;
                    }
        }
    }
    doattacktarget() {
        if (this.combating == true) return;
        if (!this.chkattacktime()) {
            //this.plog.log("doattacktarget skip attackwaituntil", this.chkattacktime(), this.combatobj.gett(), this.attackwaituntil);
            return;
        }
        //if (this.objid == 0)
        //    this.plog.log("doattacktarget() from", this.objid, "to", this.targetid);
        var countremaining = 0;
        for (const key in this.combatobj.cbto) {
            if (this.combatobj.cbto[key] != undefined) countremaining++;
        }
        //this.plog.log("doattacktarget() remaining cbt", countremaining);
        //check skill first
        for (const key in this.cbt["skilluse"]) {
            if (Object.prototype.hasOwnProperty.call(this.cbt["skilluse"], key)) {
                if (this.cbt["skilluse"][key] == undefined) {
                    this.plog.error("COMBAT skilluse Can't find skill info ", key);
                }
                if (this.cbt["skilluse"][key]["skill_type"] == "active") {
                    //this.plog.log("xxx", this.cbt["skilluse"][key]);
                    if (this.cbt["skilluse"][key]["energy"] >= this.cbt["skilluse"][key]["skilleffects"]["a.energy"]) {
                        //this.plog.log("doskill skill", this.cbt["skilluse"][key]);
                        this.markattacktime();
                        this.cbt["skilluse"][key]["energy"] = 0;
                        this.doskill(this.cbt["skilluse"][key]);
                        if (this.cbt["skilluse"][key]["sfx"] == "") {
                            this.wh.registry.list.snd.snd("atkhit");
                        } else {
                            this.wh.registry.list.snd.snd(this.cbt["skilluse"][key]["sfx"]);
                        }
                        return;
                    }
                }
            }
        }
        for (const key in this.cbt["skillweaponuse"]) {
            if (Object.prototype.hasOwnProperty.call(this.cbt["skillweaponuse"], key)) {
                if (this.cbt["skillweaponuse"][key] == undefined) {
                    this.plog.log("COMBAT skillweaponuse Can't find skill info ", key);
                }
                if (this.cbt["skillweaponuse"][key]["skill_type"] == "active") {
                    if (this.cbt["skillweaponuse"][key]["energy"] >= this.cbt["skillweaponuse"][key]["skilleffects"]["a.energy"]) {
                        //this.plog.log("doskillweapon", this.cbt["skillweaponuse"][key]);
                        this.cbt["skillweaponuse"][key]["energy"] = 0;
                        this.markattacktime();
                        this.doskill(this.cbt["skillweaponuse"][key]);
                        if (this.cbt["skillweaponuse"][key]["sfx"] == "") {
                            this.wh.registry.list.snd.snd("atkhit");
                        } else {
                            this.wh.registry.list.snd.snd(this.cbt["skillweaponuse"][key]["sfx"]);
                        }
                        return;
                    }
                }
            }
        }

        //physical attack
        //this.plog.log("xxxphysical attack", this.cbt["sprite"] + "-" + this.cbt["attackani"] + "-r", this.cbt);
        this.combating = true;
        var dir = this.findtargetdirection();
        //if (this.objid == 0)
        //    this.plog.log("xxxattackani", this.objid, dir, this.cbt["attackani"]);
        if (this.cbt["type"] == "char") {
            try {
                this.sprite.play({ key: this.cbt["sprite"] + "-" + this.cbt["attackani"] + "-" + dir, frameRate: this.combatobj.cbtfr * 2 }, true).chain({ key: this.cbt["sprite"] + "-walk-" + dir, frameRate: this.combatobj.cbtfr });
            } catch (e) {
                this.plog.log(e);
            }
            //this.plog.log("xxxxatksprite", this.cbt, this.cbt["sprite"] + "-" + this.cbt["attackani"] + "-" + dir, this.wh);
        } else {
            try {
                this.sprite.play({ key: "mob_" + this.cbt["sprite"] + "_attack", frameRate: this.combatobj.cbtfr }, false).chain({ key: "mob_" + this.cbt["sprite"] + "_idle", frameRate: this.combatobj.cbtfr });
            } catch (e) {
                this.plog.log(e);
            }
        }
        if (this.cbt["attackani"] == "shoot") {
            //bow attack, display arrow
            this.atkarrowi++;
            this.atkarrow[this.atkarrowi] = this.wh.add.image(0, 0, 'atkarrow');
            //atkarrow
            //this.plog.log("atkarrowxx", this.atkarrowi, this.atkarrow[this.atkarrowi]);
            //tmparrow.lifespan = 1000;
            this.wh.combatcontainer.add(this.atkarrow[this.atkarrowi]);
            this.wh.combatcontainer.bringToTop(this.atkarrow[this.atkarrowi]);
            var tmpangle = this.getAngle(this.sprite.x, this.sprite.y, this.combatobj.cbto[this.targetid].sprite.x, this.combatobj.cbto[this.targetid].sprite.y - (this.combatobj.cbto[this.targetid].sprite.displayHeight / 2));
            //tmparrow.refobj = this;
            this.atkarrow[this.atkarrowi].angle = tmpangle;
            this.atkarrow[this.atkarrowi].setPosition(this.sprite.x, this.sprite.y - (this.sprite.displayHeight / 3));
            //this.plog.log("tmparrowxxx",tmparrow);
            //this.plog.log("tmpangle",tmpangle);

            var tw = this.wh.tweens.add(
                {
                    targets: this.atkarrow[this.atkarrowi],
                    x: this.combatobj.cbto[this.targetid].sprite.x,
                    y: this.combatobj.cbto[this.targetid].sprite.y,
                    ease: 'Linear',
                    duration: 300 * this.combatobj.timespeed, // duration of animation; higher=slower
                    delay: 0,
                    onComplete: function () {
                        //this.plog.log("oncomplete atkarrow", this, arguments);
                        this.targets[0].destroy();
                        //this.targets[0].refobj.combating = false;
                    }  // set context? how?
                }, this
            );
            this.wh.registry.list.snd.snd("atkarrow");
        } else if (this.cbt["attackani"] == "spellcast") {
            //bow attack, display arrow
            this.atkarrowi++;
            this.atkarrow[this.atkarrowi] = this.wh.add.image(0, 0, 'spellcastatk');
            this.atkarrow[this.atkarrowi].displayWidth = this.atkarrow[this.atkarrowi].displayHeight = 24;
            //atkarrow
            //this.plog.log("atkarrowxx", this.atkarrowi, this.atkarrow[this.atkarrowi]);
            //tmparrow.lifespan = 1000;
            this.wh.combatcontainer.add(this.atkarrow[this.atkarrowi]);
            this.wh.combatcontainer.bringToTop(this.atkarrow[this.atkarrowi]);
            var tmpangle = this.getAngle(this.sprite.x, this.sprite.y, this.combatobj.cbto[this.targetid].sprite.x, this.combatobj.cbto[this.targetid].sprite.y - (this.combatobj.cbto[this.targetid].sprite.displayHeight / 2));
            //tmparrow.refobj = this;
            this.atkarrow[this.atkarrowi].angle = tmpangle;
            this.atkarrow[this.atkarrowi].setPosition(this.sprite.x, this.sprite.y - (this.sprite.displayHeight / 3));
            //this.plog.log("tmparrowxxx",tmparrow);
            //this.plog.log("tmpangle",tmpangle);

            var tw = this.wh.tweens.add(
                {
                    targets: this.atkarrow[this.atkarrowi],
                    x: this.combatobj.cbto[this.targetid].sprite.x,
                    y: this.combatobj.cbto[this.targetid].sprite.y,
                    ease: 'Linear',
                    duration: 300 * this.combatobj.timespeed, // duration of animation; higher=slower
                    delay: 0,
                    onComplete: function () {
                        //this.plog.log("oncomplete atkarrow", this, arguments);
                        this.targets[0].destroy();
                        //this.targets[0].refobj.combating = false;
                    }  // set context? how?
                }, this
            );
            this.wh.registry.list.snd.snd("atkarrow");
        } else {
            this.wh.registry.list.snd.snd("atkhit");
        }


        this.markattacktime();
        this.dealdamagetotarget();
    }
    doskill(skillcbt) {
        //this.plog.log("doskill", skillcbt);
        if (this.combatobj.battlelog[this.objid] != undefined)
            this.combatobj.battlelog[this.objid].gen_skilluse += 1;

        if (this.targetid == undefined) {
            this.plog.log("doskill targetid", this.targetid);
        }
        if (this.cbt["team"] == 2) {
            //this.plog.log("team 2 doskill", skillcbt)
        }
        skillcbt["energy"] = 0;
        //sprite action
        var dir = this.findtargetdirection();

        if (this.cbt["type"] == "char") {
            this.sprite.play({ key: this.cbt["sprite"] + "-" + this.cbt["attackani"] + "-" + dir, frameRate: this.combatobj.cbtfr }, true).chain({ key: this.cbt["sprite"] + "-walk-" + dir, frameRate: this.combatobj.cbtfr }, true);
            //this.sprite.play(this.cbt["sprite"] + "-" + this.cbt["attackani"] + "-" + dir, true).chain(this.cbt["sprite"] + "-walk-" + dir);
            //this.plog.log("xxxxatksprite", this.cbt["sprite"] + "-" + this.cbt["attackani"] + "-" + dir, this.wh);
            //this.plog.log("charskill",this)
            var skillid1 = skillcbt["code"].slice(-1);
            var skillid2 = skillcbt["code"].slice(-2);
            //if (this.wh.cameras.cameras[1].shake.isRunning == false) {
            if (skillid1 == "7" || skillid2 == "15") {
                this.wh.cameras.cameras[1].shake(250 * this.combatobj.timespeed, 0.0025);
            }
            //}
            this.plog.log("charskill shake", skillid1, skillid2, 250 * this.combatobj.timespeed)
        } else {
            //this.sprite.play("mob_" + this.cbt["sprite"] + "_attack", true);

            this.sprite.play({ key: "mob_" + this.cbt["sprite"] + "_attack", frameRate: this.combatobj.cbtfr }, true).chain("mob_" + this.cbt["sprite"] + "_idle");
        }
        //this.sprite.postFX.addBloom(0xff0000, 1, 1, 0, 2);
        /*if (Math.random() < 0.5) {
            this.sprite.preFX.addGlow(0xff0000, 1, 0, 0, 2,0);
        } else {
            this.sprite.preFX.addGlow(0x0000ff, 1, 0, 0, 2,0);
        }*/
        //[color], [outerStrength], [innerStrength], [knockout], [quality], [distance]
        //sprite first
        if (skillcbt["skilleffects"]["sprite.user"] != "" && skillcbt["skilleffects"]["sprite.user"] != undefined) {
            //this.plog.log("doskill sprite.user", skillcbt["sprite.user"]);
            const tmp = this.doskill_createeff(skillcbt["skilleffects"]["sprite.user"], skillcbt["skilleffects"]["sprite.user"]);
            this.wh.combatcontainer.add(tmp);
            this.wh.combatcontainer.bringToTop(tmp);
            tmp.setPosition(this.sprite.x, this.sprite.y);
            this.doskill_removeeff(tmp);
        }
        if (skillcbt["skilleffects"]["sprite.aoe"] != "" && skillcbt["skilleffects"]["sprite.aoe"] != undefined) {
            const tmp = this.doskill_createeff(skillcbt["skilleffects"]["sprite.aoe"], skillcbt["skilleffects"]["sprite.aoe"]);
            this.wh.combatcontainer.add(tmp);
            this.wh.combatcontainer.bringToTop(tmp);
            this.doskill_removeeff(tmp);

            var tmpaoe;
            if (skillcbt["skilleffects"]["aoe.area"] == "foe") {
                tmp.setPosition(this.combatobj.cbto[this.targetid].sprite.x, this.combatobj.cbto[this.targetid].sprite.y);
                tmpaoe = this.getdistancearea(this.combatobj.cbto[this.targetid].currentposx, this.combatobj.cbto[this.targetid].currentposy, skillcbt["skilleffects"]["aoe.size"]);
            }
            if (skillcbt["skilleffects"]["aoe.area"] == "me") {
                tmp.setPosition(this.sprite.x, this.sprite.y);
                tmpaoe = this.getdistancearea(this.sprite.x, this.sprite.y, Number(skillcbt["skilleffects"]["aoe.size"]));
            }
            //this.plog.log("doskill sprite.aoe", skillcbt["sprite.aoe"]);
            //this.plog.log("xxx", tmpaoe);
            var aoetime = Number(skillcbt["skilleffects"]["aoe.time"]) * 1000; // in seconds
            if (aoetime == 0) aoetime = 1000;

            for (const aoek in tmpaoe) {
                const tmpi = tmpaoe[aoek];
                //this.plog.log("doskill tmpaoe tmpi", tmpi, skillcbt["skilleffects"]);
                if (this.wh.anims.anims.entries[skillcbt["skilleffects"]["sprite.aoefloori"]] == undefined) {
                    this.plog.error("doskill tmpaoe EFF NOT FOUND", tmpi, skillcbt);
                    continue;
                }
                var effmapi = this.doskill_createeff(skillcbt["skilleffects"]["sprite.aoefloori"], skillcbt["skilleffects"]["sprite.aoefloori"]);

                this.wh.combatcontainer.add(effmapi);
                //this.wh.combatcontainer.bringToTop(effmapi);
                this.wh.combatcontainer.moveBelow(effmapi, this.combatobj.bg);
                this.wh.combatcontainer.moveAbove(effmapi, this.combatobj.bg);

                effmapi.setPosition(this.posinfo.left + (tmpi[0] * this.posinfo.grid), this.posinfo.top + (tmpi[1] * this.posinfo.gridy));
                this.doskill_removeeff(effmapi, aoetime);
            }
        }

        //this part likely to be unused since no this.targetid var
        if (skillcbt["skilleffects"]["sprite.target"] != "" && skillcbt["skilleffects"]["sprite.target"] != undefined) {
            //this.plog.log("sprite.target", skillcbt["sprite.target"]);
            const tmp = this.doskill_createeff(skillcbt["skilleffects"]["sprite.target"], skillcbt["skilleffects"]["sprite.target"]);

            this.wh.combatcontainer.add(tmp);
            this.wh.combatcontainer.bringToTop(tmp);
            tmp.setPosition(this.combatobj.cbto[this.targetid].sprite.x, this.combatobj.cbto[this.targetid].sprite.y);
            this.doskill_removeeff(tmp);
            //this.plog.error("sprite.target", skillcbt, skillcbt["skilleffects"]["sprite.target"], tmp);
        }
        this.doskill_skilleffect(skillcbt);
    }
    doskill_skilleffect(skillcbt) {
        this.sprite.preFX.clear();
        //this.plog.log("doskill_skilleffect", skillcbt);
        var skef = skillcbt["skilleffects"];
        ////////////target
        var target = [];
        if (skef["a.target"] == "me") {
            target.push(this);
        }
        if (skef["a.target"] == "foe") {
            var tmpchk = this.combatobj.cbto[this.targetid];
            //this.plog.log("doskill_skilleffect target=foe:", this.targetid, tmpchk,);
            if (tmpchk == undefined) {
                this.targetid = undefined
            } else {
                target.push(this.combatobj.cbto[this.targetid]);
            }
        }
        if (skef["a.target"] == "allfoe") {
            for (const k in this.combatobj.cbto) {
                if (this.combatobj == undefined) continue;
                //if (this.combatobj.cbt == undefined) continue;
                //if (this.cbt == undefined) continue;
                var v = this.combatobj.cbto[k];
                //this.plog.log("finding allfoe", this, v)
                if (v != undefined) {
                    if (v.cbt["team"] != this.cbt["team"]) {
                        target.push(v);
                    }
                }
            }
        }
        if (skef["a.target"] == "allally") {
            for (const k in this.combatobj.cbto) {
                var v = this.combatobj.cbto[k];
                if (v == undefined) continue;
                if (v.cbt["team"] == this.cbt["team"]) {
                    target.push(v);
                }
            }
        }
        if (skef["a.target"] == "nearbyfoe") {
            for (const k in this.combatobj.cbto) {
                var v = this.combatobj.cbto[k];
                if (v == undefined) continue;
                var chk = Phaser.Math.Distance.Between(this.currentposx, this.currentposy, v.currentposx, v.currentposy);
                if (chk <= 2) {
                    if (v.cbt["team"] != this.cbt["team"]) {
                        //this.plog.log("nearbyfoe", v);
                        target.push(v);
                    }
                }
            }
        }
        if (target.length == 0) {
            this.plog.log("doskill_skilleffect got 0 targets", skef["a.target"]);
        }
        //this.plog.log("doskill_skilleffect target", skef["a.target"], target.length, target, skef);
        //////////// follow effect settings
        var tmpatked = 0;
        if (skef["dmg.type"] != undefined && skef["dmg.type"] != "") {
            for (var i = 1; i <= skef["dmg.times"]; i++) {
                for (const k in target) {
                    var v = target[k];
                    if (v != undefined) {
                        if (skef["dmg.type"] == "p") {
                            if (v != undefined) {
                                tmpatked = v.attacked((Number(this.cbt["patk"]) * Number(this.dmgadjust) * skef["dmg.amnt"]), "", this.objid);
                                this.logtext("a", this.logtextgetname() + "use skill " + skillcbt["name"] + " deal physical damage to " + v["name"] + " dmg" + tmpatked);
                            }
                            //this.plog.log("doskill_skilleffect ", skef); //this.wh.as();
                        }
                        if (skef["dmg.type"] == "crit") {
                            if (v != undefined) {
                                tmpatked = v.attacked((Number(this.cbt["crit"]) * Number(this.dmgadjust)) * Number(skef["dmg.amnt"]), "", this.objid);
                                //this.plog.log("doskill_skilleffect ", skef); //this.wh.as();
                                this.logtext("a", this.logtextgetname() + "use skill " + skillcbt["name"] + " deal critical damage to " + v["name"] + " dmg" + tmpatked);
                            }
                        }
                        if (skef["dmg.type"] == "m") {
                            if (v != undefined) {
                                tmpatked = v.attacked((Number(this.cbt["matk"]) * Number(this.dmgadjust)) * Number(skef["dmg.amnt"]), "", this.objid);
                                this.logtext("a", this.logtextgetname() + "use skill " + skillcbt["name"] + " deal magic damage to " + v["name"] + " dmg" + tmpatked);

                            }
                            //this.plog.log("doskill_skilleffect ", skef); //this.wh.as();
                        }
                    }
                }
            }
        }
        if (skef["status.code"] != undefined && skef["status.code"] != "") {
            //var effectcol=0xffffff;
            for (const k in target) {
                var v = target[k];
                if (v == undefined) continue;
                if (skef["status.time"] == 0) skef["status.time"] = 5;
                //this.plog.log("skef adding statuses", v, skillcbt);
                if (skef["status.code"] == "inc.def") {
                    v.statuses.push({
                        def: Number(v.cbt["def"]) + (Number(v.cbt["def"]) * Number(skef["status.amnt"])),
                        time: this.combatobj.gett() + (this.time_goodbuff * skef["status.time"]),
                        icon: skillcbt["code"]
                    });
                    this.logtext("a", this.logtextgetname() + "bless " + v["name"] + " with skill " + skillcbt["name"] + " increase defense ");
                    //effectcol=0x9999ff;
                }
                if (skef["status.code"] == "inc.patk") {
                    v.statuses.push({
                        patk: Number(v.cbt["patk"]) + (Number(v.cbt["patk"]) * Number(skef["status.amnt"])),
                        time: this.combatobj.gett() + (this.time_goodbuff * skef["status.time"]),
                        icon: skillcbt["code"]
                    });
                    this.plog.log("skef add patk", v.statuses);
                    this.logtext("a", this.logtextgetname() + "bless " + v["name"] + " with skill " + skillcbt["name"] + " increase physical attack ");
                    //effectcol=0xff9999;
                }
                if (skef["status.code"] == "inc.spd") {
                    v.statuses.push({
                        spd: Number(v.cbt["spd"]) + (Number(v.cbt["spd"]) * Number(skef["status.amnt"])),
                        time: this.combatobj.gett() + (this.time_goodbuff * skef["status.time"]),
                        icon: skillcbt["code"]
                    });
                    this.logtext("a", this.logtextgetname() + "bless " + v["name"] + " with skill " + skillcbt["name"] + " increase speed ");
                    //effectcol=0x0000ff;
                }
                if (skef["status.code"] == "inc.crit") {
                    v.statuses.push({
                        crit: Number(v.cbt["crit"]) + (Number(v.cbt["crit"]) * Number(skef["status.amnt"])),
                        time: this.combatobj.gett() + (this.time_goodbuff * skef["status.time"]),
                        icon: skillcbt["code"]
                    });
                    this.logtext("a", this.logtextgetname() + "bless " + v["name"] + " with skill " + skillcbt["name"] + " increase crit chance ");
                    //effectcol=0xff2222;
                }
                if (skef["status.code"] == "inc.patkdef") {
                    v.statuses.push({
                        ///patk: v.cbt["patk"] + (v.cbt["patk"] * skef["status.amnt"]),
                        def: Number(v.cbt["def"]) + (Number(v.cbt["def"]) * Number(skef["status.amnt"])),
                        time: this.combatobj.gett() + (this.time_goodbuff * skef["status.time"]),
                        icon: skillcbt["code"]
                    });
                    this.logtext("a", this.logtextgetname() + "bless " + v["name"] + " with skill " + skillcbt["name"] + " increase defense and physical attack");
                    //effectcol=0x0000ff;
                }
                if (skef["status.code"] == "burn") {
                    v.statuses.push({
                        burn: 1,
                        time: this.combatobj.gett() + (this.time_goodbuff * skef["status.time"]),
                        burnamnt: (Number(this.cbt["crit"]) * Number(skef["status.amnt"]) * 0.01),
                        icon: skillcbt["code"]
                    });
                    this.logtext("a", this.logtextgetname() + "curse " + v["name"] + " with skill " + skillcbt["name"] + " now burning ");
                    //effectcol=0xff0000;
                }
                if (skef["status.code"] == "inc.dodge") {
                    v.statuses.push({
                        dodge50: 1,
                        time: this.combatobj.gett() + (this.time_goodbuff * skef["status.time"]),
                        icon: skillcbt["code"]
                    });
                    this.logtext("a", this.logtextgetname() + "bless " + v["name"] + " with skill " + skillcbt["name"] + " increase dodge chance ");
                    //effectcol=0x00ff00;
                }
                if (skef["status.code"] == "bleed") {
                    v.statuses.push({
                        bleed: 1,
                        time: this.combatobj.gett() + (this.time_goodbuff * skef["status.time"]),
                        bleedamnt: (this.cbt["crit"] * skef["status.amnt"] * 0.01),
                        icon: skillcbt["code"]
                    });
                    this.logtext("a", this.logtextgetname() + "curse " + v["name"] + " with skill " + skillcbt["name"] + " now bleeding ");
                    //effectcol=0xaa0000;
                }
                if (skef["status.code"] == "stun") {
                    v.statuses.push({
                        stun: 1,
                        time: this.combatobj.gett() + (this.time_goodbuff * skef["status.time"] * 0.8),
                        icon: skillcbt["code"]
                    });
                    this.plog.log("adding stun");
                    this.logtext("a", this.logtextgetname() + "stun " + v["name"] + " with skill " + skillcbt["name"] + " ");
                    //effectcol=0x000000;
                }
                if (skef["status.code"] == "slow") {
                    v.statuses.push({
                        slow: 1,
                        time: this.combatobj.gett() + (this.time_goodbuff * skef["status.time"]),
                        icon: skillcbt["code"]
                    });
                    this.logtext("a", this.logtextgetname() + "curse " + v["name"] + " with skill " + skillcbt["name"] + " decrease speed ");
                    //effectcol=0x888888;
                }
                if (skef["status.code"] == "inc.energy") {
                    v.statuses.push({
                        energy: 1,
                        time: this.combatobj.gett() + (this.time_goodbuff * skef["status.time"]),
                        icon: skillcbt["code"]
                    });
                    this.logtext("a", this.logtextgetname() + "bless " + v["name"] + " with skill " + skillcbt["name"] + " increase energy generation ");
                    //effectcol=0x666699;
                }
                if (skef["status.code"] == "heal") {
                    v.statuses.push({
                        heal: 1,
                        healamnt: Number(skef["status.amnt"]),
                        time: this.combatobj.gett() + (this.time_goodbuff * skef["status.time"] * this.combatobj.timespeed),
                        icon: skillcbt["code"]
                    });
                    this.logtext("a", this.logtextgetname() + "heal " + v["name"] + " with skill " + skillcbt["name"] + " increase health generation ");
                    //effectcol=0x00ff00;
                }
                //play effect sprite (for status target sprite)
                if (skef["sprite.target"] != "" && skef["sprite.target"] != undefined) {
                    //this.plog.log("sprite.target", skillcbt["sprite.target"]);
                    //this.plog.error("sprite.target in doskill_skilleffect", skef,skef["sprite.target"],v,skef);

                    const tmp = this.doskill_createeff(skef["sprite.target"], skef["sprite.target"]);
                    this.wh.combatcontainer.add(tmp);
                    this.wh.combatcontainer.bringToTop(tmp);
                    tmp.setPosition(v.sprite.x, v.sprite.y);
                    this.doskill_removeeff(tmp);//,(this.time_goodbuff * skef["status.time"]));
                }
                //v.sprite.preFX.addGlow(effectcol,20, 0, false, 0.1, 2);
                //1, 0, false, 0.1,20);
                //addGlow([color], [outerStrength], [innerStrength], [knockout], [quality], [distance])

            } //end loop target
        } // end if status.code
        if (skef["target.knockback"] != undefined) {
            //if (skef["target.knockback"] != undefined && skef["status.code"] != "" && skef["status.code"] != 0) {
            //this.plog.log("knockback targets", target);

            for (const k in target) {
                var v = target[k];

                var dir = this.findthistargetdirection(v.currentposx, v.currentposy);
                //this.plog.log("knockback,", v.name, dir, skef, k, v);
                var kbpos = v.findknockbackpos(v.currentposx, v.currentposy, dir, skef["target.knockback"]);
                //this.plog.log("knockback dest", v.currentposx, v.currentposy,"TO",kbpos.x, kbpos.y);

                var tgx = this.posinfo.left + (kbpos.x * this.posinfo.grid);
                var tgy = this.posinfo.top + (kbpos.y * this.posinfo.gridy);
                v.setcurrentpos(kbpos.x + "-" + kbpos.y);

                this.timeline =
                    this.wh.add.timeline(
                        [
                            {
                                at: 0,
                                tween: {
                                    targets: v.sprite,
                                    x: { from: v.sprite.x, to: tgx },
                                    ease: 'Power3',
                                    duration: 200 * this.combatobj.timespeed,
                                }
                            }, {
                                at: 0,
                                tween: {
                                    targets: v.sprite,
                                    y: { from: v.sprite.y, to: tgy - this.posinfo.grid },
                                    ease: 'Power3',
                                    duration: 100 * this.combatobj.timespeed,
                                    offset: 0,
                                    //repeat: -1,
                                    yoyo: false,
                                }
                            }, {
                                at: 100 * this.combatobj.timespeed,
                                tween: {
                                    targets: v.sprite,
                                    y: { from: tgy - this.posinfo.grid, to: tgy },
                                    ease: 'Bounce',
                                    duration: 100 * this.combatobj.timespeed,
                                }
                            }

                        ]
                    );
                this.timeline.play();
                this.logtext("a", this.logtextgetname() + "knockback " + v["name"] + " with skill " + skillcbt["name"] + " ");
            }
        }
        //this.plog.log("spawn.code chk", this.issummoned, skef);
        if (this.issummoned != true) {
            skef["spawn.num"] = Number(skef["spawn.num"]);
            if (skef["spawn.code"] != undefined && skef["spawn.code"] != "" && skef["spawn.num"] != 0) {
                var countsummoned = 0;
                for (const smmdk in this.combatobj.cbto) {
                    if (this.combatobj.cbto[smmdk] != undefined) {
                        if (this.combatobj.cbto[smmdk].summonedby == this.objid && this.combatobj.cbto[smmdk].summonedwith == skillcbt["code"]) {
                            //summoned with this skill still exists, will skip
                            countsummoned = countsummoned + 1;
                        }
                    }
                }
                if (countsummoned != 0) {
                    this.plog.log("spawn.code, skip spawning, summoned still alive");
                } else {
                    //this.plog.log("spawn.code chk num from 1 to ", skef["spawn.num"]);
                    for (var i = 1; i <= skef["spawn.num"]; i++) {
                        //this.plog.log("spawn.code chk I num from 1 to ", skef["spawn.num"]);
                        ///for (const k in target) {
                        ///var v = target[k];
                        ///if (v == undefined) continue;
                        var tmpkey = this.combatobj.cbto.length;
                        if (tmpkey > 25) continue;
                        var mobdatuse = Object.assign({}, this.wh.registry.list.rcvarpass.allmob[skef["spawn.code"]]);
                        var tmpme;
                        this.plog.log("skill spawn.code", skef["spawn.code"], skef);
                        tmpme = mobdatuse["cbt"];
                        tmpme["name"] = mobdatuse["name"] + "(summoned)";
                        tmpme["sprite"] = mobdatuse["sprite"];
                        tmpme["mobcode"] = mobdatuse["code"];
                        tmpme["team"] = this.cbt["team"];
                        //tmpme["randid"]="Mine"+Math.random();
                        tmpme["type"] = "mob";
                        var spawnpos = '1-1';
                        if (skef["spawn.place"] == "backline") {
                            if (this.cbt["team"] == 1) {
                                var xplace = Math.random() * (this.combatobj.gridh - 1) + 1;
                                xplace = Math.floor(xplace);
                                spawnpos = '1-' + xplace;
                            } else {
                                var xplace = Math.random() * (this.combatobj.gridh - 1) + 1;
                                xplace = Math.floor(xplace);
                                spawnpos = this.combatobj.gridw + '-' + xplace;
                            }
                        }
                        if (skef["spawn.place"] == "enemyline") {
                            if (this.cbt["team"] == 1) {
                                var xplace = Math.random() * (this.combatobj.gridh - 1) + 1;
                                xplace = Math.floor(xplace);
                                spawnpos = this.combatobj.gridw + '-' + xplace;
                            } else {
                                var xplace = Math.random() * (this.combatobj.gridh - 1) + 1;
                                xplace = Math.floor(xplace);
                                spawnpos = '1-' + xplace;
                            }
                        }
                        if (skef["spawn.place"] == "enemymidline") {
                            this.plog.log("spawn.code", skef);
                            if (this.cbt["team"] == 1) {
                                var xplace = Math.random() * (this.combatobj.gridh - 1) + 1;
                                xplace = Math.floor(xplace);
                                spawnpos = Math.floor(this.combatobj.gridw * 0.7) + '-' + xplace;
                            } else {
                                var xplace = Math.random() * (this.combatobj.gridh - 1) + 1;
                                xplace = Math.floor(xplace);
                                spawnpos = Math.floor(this.combatobj.gridw * 0.3) + '-' + xplace;
                            }
                        }
                        if (skef["spawn.place"] == "topline") {
                            var xplace = Math.random() * (this.combatobj.gridw - 1) + 1;
                            xplace = Math.floor(xplace);
                            spawnpos = '' + xplace + '-1';
                        }
                        if (skef["spawn.place"] == "btmline") {
                            var xplace = Math.random() * (this.combatobj.gridh - 1) + 1;
                            xplace = Math.floor(xplace);
                            spawnpos = '' + xplace + '-' + this.combatobj.gridh;
                        }
                        //this.plog.log("spawn.code skillcbt+spawnpos", skillcbt, spawnpos);
                        var tmprandom = Math.random();
                        this.combatobj.cbto[tmpkey] = new COMBATANT(
                            this.combatobj,
                            this.combatobj.wh,
                            tmpme,
                            this.combatobj.posinfo,
                            spawnpos,
                            tmprandom);
                        this.combatobj.cbto[tmpkey].objid = tmpkey; //key;

                        this.combatobj.cbto[tmpkey].createSprite();
                        this.combatobj.cbto[tmpkey].issummoned = true;//summoned cbt can't use more summon
                        if (this.cbt["type"] == "char" && this.cbt["level"] > 150 && this.cbt["race"] == 5) {
                            //this.plog.log("racebuff2 5 nekro ",this);
                            this.combatobj.cbto[tmpkey].issummonexplode = true;//summoned cbt 

                        }

                        this.combatobj.cbto[tmpkey].summonedby = this.objid;
                        this.combatobj.cbto[tmpkey].summonedwith = skillcbt["code"];
                        var spawnposa = spawnpos.split("-");
                        const tmpteleporteff = this.doskill_createeff("pipomapeffect013a_b", "pipomapeffect013a_b");
                        this.wh.combatcontainer.add(tmpteleporteff);
                        this.wh.combatcontainer.bringToTop(tmpteleporteff);
                        tmpteleporteff.displayWidth = this.posinfo.grid * 2.5;
                        tmpteleporteff.scaleY = tmpteleporteff.scaleX;
                        this.plog.log("spawn", spawnposa, tmpteleporteff);
                        tmpteleporteff.setPosition(this.posinfo.left + (Number(spawnposa[0]) * this.posinfo.grid), this.posinfo.top + (Number(spawnposa[1]) * this.posinfo.gridy));
                        this.doskill_removeeff(tmpteleporteff, 600);
                        this.logtext("a", this.logtextgetname() + "summoned " + this.combatobj.cbto[tmpkey]["name"] + " with skill " + skillcbt["name"] + " lv." + this.combatobj.cbto[tmpkey]["cbt"]["level"]);
                        //this.plog.log("spawn.code spawned", tmpkey, this.combatobj.cbto[tmpkey]);
                        ///}
                    }
                }
            }
        } else {
            this.plog.log("skip spawning, spawned cbt cant spawn more cbt");
        }
        if (skef["aoe.area"] != undefined && skef["aoe.area"] != "") {
            //this.plog.log("xxxxxaoe.target", skef);

            var tmpaoe;
            var tmpaoex;
            var tmpaoey;
            if (skillcbt["skilleffects"]["aoe.area"] == "foe") {
                tmpaoex = this.combatobj.cbto[this.targetid].currentposx;
                tmpaoey = this.combatobj.cbto[this.targetid].currentposy;
            }
            if (skillcbt["skilleffects"]["aoe.area"] == "me") {
                tmpaoex = this.sprite.currentposx;
                tmpaoey = this.sprite.currentposy;
            }
            tmpaoe = this.getdistancearea(tmpaoex, tmpaoey, skef["aoe.size"]);

            //this.plog.log("xxxxxdoskill_skilleffect aoe", tmpaoex, tmpaoey,skef["aoe.size"],tmpaoe);
            var aoetime = Number(skillcbt["skilleffects"]["aoe.time"]) * 1000; // in seconds
            if (aoetime == 0) aoetime = 1000;
            //this.plog.log("xxxxxtmpaoe",tmpaoe);

            for (const aoek in tmpaoe) {
                const tmpi = tmpaoe[aoek];
                for (const cbtk in this.combatobj.cbto) {
                    var cbtv = this.combatobj.cbto[cbtk];
                    if (cbtv != undefined) {
                        if (cbtv.cbt["team"] != this.cbt["team"] && cbtv.currentposx == tmpi[0] && cbtv.currentposy == tmpi[1]) {
                            //this.plog.log("xxxxxapplyingeffect", skef, "to", cbtv);
                            if (skef["aoe.effect"] == "burn") {
                                cbtv.statuses.push({
                                    burn: 1,
                                    time: this.combatobj.gett() + (this.time_goodbuff * skef["aoe.time"]),
                                    burnamnt: (this.cbt["crit"] * skef["aoe.amnt"]),
                                    icon: skillcbt["code"]
                                });
                                this.logtext("a", this.logtextgetname() + "AOE burn " + cbtv["name"] + " with skill " + skillcbt["name"] + " ");
                            }
                            if (skef["aoe.effect"] == "bleed") {
                                cbtv.statuses.push({
                                    bleed: 1,
                                    time: this.combatobj.gett() + (this.time_goodbuff * skef["aoe.time"]),
                                    bleedamnt: (this.cbt["crit"] * skef["aoe.amnt"]),
                                    icon: skillcbt["code"]
                                });
                                this.logtext("a", this.logtextgetname() + "AOE bleed " + cbtv["name"] + " with skill " + skillcbt["name"] + " ");

                            }
                            if (skef["aoe.effect"] == "stun") {
                                cbtv.statuses.push({
                                    stun: 1,
                                    time: this.combatobj.gett() + (this.time_goodbuff * skef["aoe.time"]),
                                    icon: skillcbt["code"]
                                });
                                this.logtext("a", this.logtextgetname() + "AOE stun " + cbtv["name"] + " with skill " + skillcbt["name"] + " ");

                                //this.plog.log("adding stun");
                            }
                            if (skef["aoe.effect"] == "slow") {
                                cbtv.statuses.push({
                                    slow: 1,
                                    time: this.combatobj.gett() + (this.time_goodbuff * skef["aoe.time"]),
                                    icon: skillcbt["code"]
                                });
                                this.logtext("a", this.logtextgetname() + "AOE slow " + cbtv["name"] + " with skill " + skillcbt["name"] + " ");
                            }
                        }
                    }
                }
            }
        }
        if (skef["teleport.type"] != undefined && skef["teleport.type"] != "") {
            var spawnpos = "1-1";
            //this.plog.log("xxx teleport",skef);
            if (skef["teleport.type"] == "backline") {
                if (this.cbt["team"] == 1) {
                    var xplace = Math.random() * (this.combatobj.gridh - 1) + 1;
                    xplace = Math.floor(xplace);
                    spawnpos = '1-' + xplace;
                } else {
                    var xplace = Math.random() * (this.combatobj.gridh - 1) + 1;
                    xplace = Math.floor(xplace);
                    spawnpos = this.combatobj.gridw + '-' + xplace;
                }
                this.logtext("a", this.logtextgetname() + "teleported to backline");

            }
            if (skef["teleport.type"] == "enemyline") {
                if (this.cbt["team"] == 1) {
                    var xplace = Math.random() * (this.combatobj.gridh - 1) + 1;
                    xplace = Math.floor(xplace);
                    spawnpos = this.combatobj.gridw + '-' + xplace;
                } else {
                    var xplace = Math.random() * (this.combatobj.gridh - 1) + 1;
                    xplace = Math.floor(xplace);
                    spawnpos = '1-' + xplace;
                }
                this.logtext("a", this.logtextgetname() + "teleported to enemyline ");

            }
            if (skef["teleport.type"] == "enemymidline") {
                if (this.cbt["team"] == 1) {
                    var xplace = Math.random() * (this.combatobj.gridh - 1) + 1;
                    xplace = Math.floor(xplace);
                    spawnpos = Math.floor(this.combatobj.gridw * 0.75) + '-' + xplace;
                } else {
                    var xplace = Math.random() * (this.combatobj.gridh - 1) + 1;
                    xplace = Math.floor(xplace);
                    spawnpos = Math.floor(this.combatobj.gridh * 0.25) + '-' + xplace;
                }
                this.logtext("a", this.logtextgetname() + "teleported to enemy mid line ");

            }

            var spawnposa = spawnpos.split("-");
            const tmpteleporteff = this.doskill_createeff("portalpurple1_b", "portalpurple1_b");
            this.wh.combatcontainer.add(tmpteleporteff);
            this.wh.combatcontainer.bringToTop(tmpteleporteff);
            tmpteleporteff.displayWidth = this.posinfo.grid * 2.5;
            tmpteleporteff.displayHeight = this.posinfo.grid * 1.3;
            //this.plog.log("spawn",spawnposa,tmpteleporteff);
            tmpteleporteff.setPosition(this.posinfo.left + (Number(spawnposa[0]) * this.posinfo.grid), this.posinfo.top + (Number(spawnposa[1]) * this.posinfo.gridy));
            this.doskill_removeeff(tmpteleporteff, 600);
            this.moveto(spawnposa[0], spawnposa[1]);
        }

    }
    doskill_removeeff(wh, dur: number = 400) {
        var tw = this.wh.tweens.add(
            {
                targets: wh,
                alpha: 0,
                ease: 'Power3',
                duration: 50 * this.combatobj.timespeed, // duration of animation; higher=slower
                delay: dur * this.combatobj.timespeed,
                onComplete: function () {
                    //this.plog.log("oncomplete combatant walk", this, arguments);
                    try {
                        arguments[1][0].scene.combateffcount--;
                    } catch (e) {
                        console.warn("ERROR intween", e);
                    }
                    try {
                        arguments[1][0].destroy();
                    } catch (e) {
                        console.warn("ERROR intween", e);
                    }
                    try {
                        this.targets[0].destroy();
                    } catch (e) {
                        console.warn("ERROR intween", e);
                    }

                    //this.plog.log("oncomplete combatant walk", this, arguments);

                }  // set context? how?
            }, this
        );
    }

    doskill_createeff(a, b) {
        if (this.combatobj.mincombat == "yes") {
            return this.wh.registry.list.rcvarpass.gameobj.scene.scenes[0].add.sprite(100, 100, "spacera").play("spacera_b");
        }

        if (this.wh.combateffcount < 0) this.wh.combateffcount = 0;
        if (this.wh.combateffcount > 24) {
            //return;
            return this.wh.registry.list.rcvarpass.gameobj.scene.scenes[0].add.sprite(100, 100, "spacera").play("spacera_b");

        }
        this.wh.combateffcount++;
        //this.plog.log("doskill_createeff", this.wh.combateffcount);
        var tmp = this.wh.registry.list.rcvarpass.gameobj.scene.scenes[0].add.sprite(100, 100, "" + a).play("" + b);
        tmp.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);;
        return tmp

    }
    dealdamagetotarget() {
        //this.plog.log("dealdamagetotarget", this.targetid,this);
        if (this.targetid == undefined) return;
        if (this.combatobj.cbto[this.targetid] == undefined) return;
        //this.plog.log("dealdamagetotarget from", this.objid, "to", this.targetid);
        //if (this.objid == 0)
        //    this.plog.log("dealdamagetotarget", this.objid, "to", this.targetid);
        var usecbt = this.getcbtwithstat();
        var targetcbt = this.combatobj.cbto[this.targetid].getcbtwithstat();

        //this.plog.log("cbtchkrace racebuff", usecbt, targetcbt);
        //this.plog.log("cbtchkrace racebuff", targetcbt["race"],targetcbt);
        //adjust atk
        //this.plog.log('dealdamagetotarget()', usecbt, this.dmgadjust);
        usecbt["patk"] = usecbt["patk"] * this.dmgadjust;
        usecbt["crit"] = usecbt["crit"] * this.dmgadjust;
        //atkpow
        var atkpow = usecbt["patk"];
        var atkpowcrit = usecbt["crit"];
        var maxdmg = 99999999;
        var teamdamage = 0;
        var reflectdmg = 0;

        /*if (atkpow.toString()=="NaN") {
            this.plog.error("nancrit",atkpow,atkpowcrit,usecbt);
        }*/
        //racebuff s introduced in v.11
        //this.plog.error("racebuff", usecbt);
        if (usecbt['type'] == 'char') {
            if (targetcbt["race"] == 4) {
                //this.plog.log("racebuff 4 tanker (maxdmg,def)", maxdmg, targetcbt["def"]);
                maxdmg = Math.floor(targetcbt["maxhp"] * 0.07);
                targetcbt["def"] = (targetcbt["def"] * 2) + targetcbt["level"];
                //this.plog.log("racebuff 4 tanker (maxdmg,def)", maxdmg, targetcbt["def"]);
            }
            if (usecbt["race"] == 2) {
                //this.plog.log("racebuff 2 magic (teamdamage)", teamdamage);
                teamdamage = 0.08;
                //this.plog.log("racebuff 2 magic (teamdamage)", teamdamage);
            }
            if (usecbt["race"] == 1) {
                //this.plog.log("racebuff 1 swordman A before (patk,crit)", usecbt["patk"], usecbt["crit"]);
                usecbt["patk"] = (usecbt["patk"] * 2) + usecbt["level"];
                usecbt["crit"] = (usecbt["crit"] * 2) + usecbt["level"];
                //this.plog.log("racebuff 1 swordman A after (patk,crit)", usecbt["patk"], usecbt["crit"]);
            }
            if (targetcbt["race"] == 1) {
                //this.plog.log("racebuff 1 swordman T before(def)", targetcbt["def"]);
                targetcbt["def"] = (targetcbt["def"] * 2) + targetcbt["level"];
                //this.plog.log("racebuff 1 swordman T after (def)", targetcbt["def"]);
            }
            if (usecbt["race"] == 3) {
                //this.plog.log("racebuff 3 ranger before (acc,crit)", usecbt["acc"], usecbt["crit"]);
                usecbt["acc"] = (usecbt["acc"] * 3) + usecbt["level"];
                usecbt["crit"] = (usecbt["crit"] * 3) + usecbt["level"];
                //this.plog.log("racebuff 3 ranger after (acc,crit)", usecbt["acc"], usecbt["crit"]);
            }
            if (targetcbt["race"] == 5) {
                //this.plog.log("racebuff 5 nekro before (reflectdmg)", reflectdmg);
                reflectdmg = 0.2;
                if (this.wh.registry.list.timectl.timename == "night") {
                    usecbt["patk"] = (usecbt["patk"] * 2.5) + usecbt["level"];
                    usecbt["crit"] = (usecbt["crit"] * 2.5) + usecbt["level"];
                }
                //this.plog.log("racebuff 5 nekro after (reflectdmg)", reflectdmg);
            }
            if (usecbt["race"] == 1 && usecbt["level"] > 150) {
                this.plog.log("racebuff2 1 swordman", usecbt["acc"], usecbt["crit"]);
                this.combatobj.cbto[this.targetid].statuses.push({
                    burn: 1,
                    time: this.combatobj.gett() + (this.time_goodbuff * 1),
                    burnamnt: (Number(usecbt["crit"]) * 0.05),
                    icon: "swordman1"
                });
            }
            if (usecbt["race"] == 2 && usecbt["level"] > 150) {
                //this.plog.log("racebuff2 2 magic (teamdamage)", teamdamage);
                teamdamage = 0.18;
                //this.plog.log("racebuff2 2 magic (teamdamage)", teamdamage);
            }
            if (usecbt["race"] == 4 && usecbt["level"] > 150) {
                //this.plog.log("racebuff2 4 tanker ()", this.combatobj.cbto[this.targetid]);

                var dir = this.findthistargetdirection(this.combatobj.cbto[this.targetid].currentposx, this.combatobj.cbto[this.targetid].currentposy);
                //this.plog.log("racebuff2 knockback,", this.combatobj.cbto[this.targetid].name, dir);
                var kbpos = this.combatobj.cbto[this.targetid].findknockbackpos(this.combatobj.cbto[this.targetid].currentposx, this.combatobj.cbto[this.targetid].currentposy, dir, 1);
                //this.plog.log("racebuff2 knockback dest", this.combatobj.cbto[this.targetid].currentposx, this.combatobj.cbto[this.targetid].currentposy, "TO", kbpos.x, kbpos.y);

                var tgx = this.posinfo.left + (kbpos.x * this.posinfo.grid);
                var tgy = this.posinfo.top + (kbpos.y * this.posinfo.gridy);
                this.combatobj.cbto[this.targetid].setcurrentpos(kbpos.x + "-" + kbpos.y);

                this.timeline =

                    this.wh.add.timeline(
                        [
                            {
                                at: 0,
                                tween: {
                                    targets: this.combatobj.cbto[this.targetid].sprite,
                                    x: { from: this.combatobj.cbto[this.targetid].sprite.x, to: tgx },
                                    ease: 'Power3',
                                    duration: 200 * this.combatobj.timespeed,
                                }
                            }, {
                                at: 0,
                                tween: {
                                    targets: this.combatobj.cbto[this.targetid].sprite,
                                    y: { from: this.combatobj.cbto[this.targetid].sprite.y, to: tgy - this.posinfo.grid },
                                    ease: 'Power3',
                                    duration: 100 * this.combatobj.timespeed,
                                    offset: 0,
                                    //repeat: -1,
                                    yoyo: false,
                                }
                            }, {
                                at: 100 * this.combatobj.timespeed,
                                tween: {
                                    targets: this.combatobj.cbto[this.targetid].sprite,
                                    y: { from: tgy - this.posinfo.grid, to: tgy },
                                    ease: 'Bounce',
                                    duration: 100 * this.combatobj.timespeed,
                                }
                            }

                        ])
                this.timeline.play();
                this.targetid = undefined;// find new tg
                //this.plog.log("racebuff2 4 tanker ()", teamdamage);
            }
        }

        //raceboff e
        //calculate element
        var elementlogstr = "";
        //this.plog.log("dealdamagetotarget elementb", usecbt, targetcbt, atkpow);

        if (usecbt["element"] != undefined) {
            if (usecbt["element"] == "fire" && (targetcbt["element"] == "earth" || targetcbt["element"] == "")) {
                atkpow = atkpow * 1.15;
                atkpowcrit = atkpowcrit * 1.15;
                elementlogstr = " (fire+15%)";
            }
            if (usecbt["element"] == "earth" && (targetcbt["element"] == "water" || targetcbt["element"] == "")) {
                atkpow = atkpow * 1.15;
                atkpowcrit = atkpowcrit * 1.15;
                elementlogstr = " (earth+15%)";
            }
            if (usecbt["element"] == "water" && (targetcbt["element"] == "wind" || targetcbt["element"] == "")) {
                atkpow = atkpow * 1.15;
                atkpowcrit = atkpowcrit * 1.15;
                elementlogstr = " (water+15%)";
            }
            if (usecbt["element"] == "wind" && (targetcbt["element"] == "fire" || targetcbt["element"] == "")) {
                atkpow = atkpow * 1.15;
                atkpowcrit = atkpowcrit * 1.15;
                elementlogstr = " (wind+15%)";
            }
            if (usecbt["element"] == "electric" && (targetcbt["element"] != "electric" || targetcbt["element"] == "")) {
                elementlogstr = " (electric+15%)";
                atkpow = atkpow * 1.2;
                atkpowcrit = atkpowcrit * 1.2;
            }
            if (usecbt["element"] == "soul" && targetcbt["element"] != "soul") {
                elementlogstr = " (soul+1000%)";
                atkpow = atkpow * 1000;
                atkpowcrit = atkpowcrit * 1000;
            }
            //this.plog.log("dealdamagetotarget element", elementlogstr, usecbt["element"], targetcbt["element"], atkpow);
        }
        //this.combatobj.jgf();
        //calculate critical chance
        var dodgechance = targetcbt["dodge"];

        if (targetcbt["dodge50"] == 1) {
            dodgechance = dodgechance * 2;
            //this.plog.log("yyyyyy inc dodge");
        }
        var critchance = this.combatobj.randp([
            { k: "crit", n: usecbt["acc"] },
            { k: "normal", n: dodgechance }
        ]);

        if (this.cbt["type"] == "char" && this.cbt["level"] > 150 && this.cbt["race"] == 5) {
            //this.plog.log("racebuff2 3 ranger ", usecbt["acc"], usecbt["crit"]);
            critchance = "crit";
            this.combatobj.cbto[this.targetid].attacked(atkpow, critchance, this.objid);
        }

        if (critchance == "crit") {
            atkpowcrit = atkpowcrit - targetcbt["def"];

            if (atkpowcrit < 0) {
                atkpowcrit = Math.floor(Math.random() * (3 - 1)) + 1;;//min1-3
            }
            atkpowcrit = atkpowcrit + Math.floor(Math.random() * (usecbt['level'] - 1)) + 1;;//crit add (1 - level)
            //this.plog.log("yyyyy CRITICAL");
            //this.plog.log("yyyyy CRITICAL", this.objid, targetcbt["def"], atkpowcrit, atkpow);
        } else {
            //this.plog.log("yyyyy ", this.objid, targetcbt["def"], usecbt["patk"], atkpow);

        }
        atkpow = atkpow - targetcbt["def"];

        if (atkpow < 0) {
            atkpow = Math.floor(Math.random() * (3 - 1)) + 1;;//min1-3
        }
        if (atkpow > maxdmg) {
            if (atkpow > targetcbt["maxhp"]) {
                maxdmg = maxdmg * 2;
            }
            if (usecbt["level"] > (targetcbt["level"] + 7)) {
                maxdmg = maxdmg * 2;
            }
            if (atkpow > maxdmg) {
                atkpow = maxdmg;
            }
        }


        if (teamdamage != 0) {
            for (const teamdamagek in this.combatobj.cbto) {
                if (this.combatobj == undefined) continue;
                var teamdamagev = this.combatobj.cbto[teamdamagek];
                //this.plog.log("finding allfoe", this, v)
                if (teamdamagev != undefined) {
                    if (teamdamagev.cbt["team"] == targetcbt["team"]) {
                        if (this.combatobj.cbto[teamdamagev.objid] != undefined) {
                            this.combatobj.cbto[teamdamagev.objid].attacked(atkpow * teamdamage, critchance, this.objid);
                            //this.plog.log("racebuff deal teamdamage to ", teamdamagev.objid, atkpow * teamdamage);
                        }

                    }
                }
            }
        }

        if (reflectdmg != 0) {
            var tmpatkedref = this.attacked(atkpow * reflectdmg, critchance, this.objid);
            this.logtext("a", this.logtextgetname() + "reflected dmg " + tmpatkedref + " dmg");
            //this.plog.log("racebuff reflectdmg to", this.objid, reflectdmg, atkpow, atkpow * reflectdmg);
        }

        if (this.combatobj.battlelog[this.objid] != undefined) {
            if (this.combatobj.battlelog[this.objid].gen_atk != undefined)
                this.combatobj.battlelog[this.objid].gen_atk += atkpow;
            if (this.combatobj.battlelog[this.objid].gen_atkcount != undefined)
                this.combatobj.battlelog[this.objid].gen_atkcount += 1;
        }
        if (this.combatobj.battlelog[this.targetid] != undefined) {
            if (this.combatobj.battlelog[this.targetid].gen_damage != undefined)
                this.combatobj.battlelog[this.targetid].gen_damage += atkpow;
        }
        //this.plog.log("xxxxxxx critchance",critchance);
        //this.plog.log("xxxxxxx",usecbt,targetcbt);
        var targetname = this.logtextgetname(this.targetid);//incase target dead
        if (this.combatobj.cbto[this.targetid] != undefined) {
            var tmpatked = this.combatobj.cbto[this.targetid].attacked(atkpow, critchance, this.objid);
        }
        this.logtext("a", this.logtextgetname() + "attack " + targetname + " dmg" + (tmpatked) + elementlogstr);
        //this.combatobj.cbto[this.targetid].cbt["hp"]=0;//-=this.cbt["patk"];
        this.combating = false;
        this.addenergy(usecbt["patk"] / 10); //add to attacker
        /*if (this.combatobj.cbto[this.targetid] != undefined && this.combatobj.cbto[this.targetid].cbt["hp"] <= 0) { 
            this.targetid = undefined;
        }*/
        //this.wh.asdf();
    }
    dostatusesffects() {
        //this.plog.log("dostatusesffects-s", cbtusedse);
        //this.plog.log("dostatusesffects-s", this.statuses);
        //this.plog.log("dostatusesffects-s", this.statuses.length);
        for (var k in this.statuses) {
            var v = this.statuses[k];
            if (v != undefined && v["time"] != undefined && v["time"] > 0 && v["time"] < this.combatobj.gett()) {
                //expired
                //this.plog.log("dostatusesffects-sexpired", this.statuses[k]);
                this.statuses[k] = undefined;
                continue;
            }
        }
        var cbtusedse = this.getcbtwithstat();
        for (var k in cbtusedse) {
            var v = cbtusedse[k];
            if (v == undefined) continue;
            /*if (v["time"] != undefined && v["time"] > 0 && v["time"] < this.combatobj.gett()) {
                //expired
                this.plog.log("dostatusesffectsexpired",this.statuses[k]);
                this.statuses[k] = undefined;
                continue;
            }*/
            //this.plog.log("dostatusesffects x ", k, v);
            if (k != undefined && k == "bleed" && v == 1) {
                //this.plog.log("dostatusesffects bleed", v);
                var localtmpbleed = cbtusedse["bleedamnt"] * this.dmgadjust
                localtmpbleed = localtmpbleed / 1000;
                var tmpatked = this.attacked(localtmpbleed, "");
                this.logtext("a", this.logtextgetname() + "bleeding " + " dmg" + (tmpatked));
            }
            if (k != undefined && k == "heal") {//&& v == 1
                //this.plog.log("dostatusesffects heal", v,cbtusedse);
                var healamntlocal = ((10 + Number(cbtusedse["healamnt"])) / 5000) * cbtusedse['maxhp']; //2.5%
                this.heal(healamntlocal);// * this.dmgadjust
                this.logtext("a", this.logtextgetname() + "healing " + " pts" + (healamntlocal));
            }
            if (k != undefined && k == "burn" && v == 1) {
                var localtmpburn = cbtusedse["burnamnt"] * this.dmgadjust;
                localtmpburn = localtmpburn / 1000;
                var tmpatked = this.attacked(localtmpburn, "");
                this.logtext("a", this.logtextgetname() + "burning " + " dmg" + (tmpatked));

                //this.plog.log("dostatusesffects burn", v);
            }
        }
    }
    getcbtwithstat() {
        var cbtusegcws = Object.assign({}, this.cbt);
        //this.plog.log("getcbtwithstat s", this.cbt, this.statuses);
        /*if (atkpow.toString()=="NaN") {
            this.plog.error("nancrit",atkpow,atkpowcrit,usecbt);
        }*/
        for (var k in this.statuses) {
            var v = this.statuses[k];
            if (v == undefined) continue;
            if (v["time"] < this.combatobj.gett()) {
                //expired
                this.statuses[k] = undefined;
                continue;
            }
            if (v["def"] != undefined) {
                cbtusegcws["def"] += Number(v["def"]);
            }
            if (v["patk"] != undefined) {
                cbtusegcws["patk"] += Number(v["patk"]);

                /*if (v['patk'].toString() == "NaN") {
                    this.plog.error("nancrit", v, cbtusegcws);
                }*/
            }
            if (v["acc"] != undefined) {
                cbtusegcws["acc"] += Number(v["acc"]);
            }
            if (v["crit"] != undefined) {
                cbtusegcws["crit"] += Number(v["crit"]);
            }
            if (v["hp"] != undefined) {
                cbtusegcws["hp"] += Number(v["hp"]);
            }
            if (v["matk"] != undefined) {
                cbtusegcws["matk"] += Number(v["matk"]);
            }
            if (v["maxhp"] != undefined) {
                cbtusegcws["maxhp"] += Number(v["maxhp"]);
            }
            if (v["maxmp"] != undefined) {
                cbtusegcws["maxmp"] += Number(v["maxmp"]);
            }
            if (v["spd"] != undefined) {
                cbtusegcws["spd"] += Number(v["spd"]);
            }
            if (v["energy"] != undefined) {
                cbtusegcws["energy"] += Number(v["spd"]);
            }
            if (v["stun"] != undefined) {
                cbtusegcws["stun"] = 1;
            }
            if (v["bleed"] != undefined) {
                cbtusegcws["bleed"] = 1;
                if (cbtusegcws["bleedamnt"] == undefined) cbtusegcws["bleedamnt"] = 0;
                cbtusegcws["bleedamnt"] = Number(cbtusegcws["bleedamnt"]) + v["bleedamnt"];
            }
            if (v["burn"] != undefined) {
                //this.plog.log("getcbtwithstat burn", v);
                cbtusegcws["burn"] = 1;
                if (cbtusegcws["burnamnt"] == undefined) cbtusegcws["burnamnt"] = 0;
                cbtusegcws["burnamnt"] = Number(cbtusegcws["burnamnt"]) + v["burnamnt"];
            }
            if (v["heal"] != undefined) {
                //this.plog.log("getcbtwithstat burn", v);
                cbtusegcws["heal"] = 1;
                if (cbtusegcws["healamnt"] == undefined) cbtusegcws["healamnt"] = 0;
                cbtusegcws["healamnt"] = Number(cbtusegcws["healamnt"]) + Number(v["healamnt"]);
            }

            /*if (cbtusegcws['patk'].indexOf("NaN") != -1) {
                this.plog.error("nancrit", cbtusegcws,v);
            }*/
        }
        //this.plog.log("getcbtwithstat e", cbtusegcws);
        return cbtusegcws;
    }
    heal(healpts) {
        //this.plog.log("heal()",healpts)
        var usecbt = this.getcbtwithstat();

        if (healpts < 0) {
            //this.plog.log("attackedheal",atk0,atkmode);
            healpts = 0;
        }

        this.cbt["hp"] = usecbt["hp"] + healpts;
        var atkosd = "+" + healpts;
        //this.osdflash(atkosd + " by " + atkby, "red");
        this.osdflash(atkosd, "green");
        if (this.cbt["hp"] > this.cbt["maxhp"]) this.cbt["hp"] = this.cbt["maxhp"];
    }
    attacked(atk0, atkmode = "", atkby = "") {
        var usecbt = this.getcbtwithstat();
        //console.log("attacked",atk0, atkmode , atkby)
        ///atk = 1;
        var atk = atk0 + 0;
        if (atk < 0) {
            this.plog.log("attackedheal", atk0, atkmode);
        }
        if (Number(this.cbt["team"]) == 1) {
            //this.plog.log("combatant.attacked team1", atk0);            
        }
        if (atk0.toString() == "NaN") {
            this.plog.error("nancrit", atk, atk0);
        }
        /*if (atk.toString()=="NaN") {
            this.plog.error("nancrit",atk,atk0);
        }*/
        //this.sprite.setBlendMode(Phaser.BlendModes.ADD);
        //this.sprite.setTint(0x000000);
        //const color2 = new Phaser.Display.Color(255, 255, 255);
        this.sprite.setTintFill(0xffffff);

        this.sprite.scene.tweens.addCounter({
            from: 1,
            to: 0,
            duration: 100 * this.combatobj.timespeed,
            onComplete: function () {
                //console.log("tintcomplete",arguments);
                try {
                    arguments[0].clearTint();
                } catch (e) {
                    console.log(e);
                }
                //const value = Math.floor(tween.getValue());
                //image.setTint(Phaser.Display.Color.GetColor(value, value, value));

            }.bind(this, this.sprite)
        });
        //color2.brighten(10);
        //this.sprite.setFillStyle(color2.color);
        ///this.addenergy((atk / 10) * usecbt["energy"]);
        //adjust
        // this.plog.log("attackedB", this.objid, atk, this.cbt["hp"]);
        //atk=atk*0.5;
        //atk = atk - usecbt["def"]; //already calculated from dealdamagetotarget()
        if (atk < 0) atk = 0;
        //this.plog.log("attackedA", this.objid, atk, this.cbt["hp"]);

        //max dmg 30% maxhealth
        var maxatk = Math.ceil(this.cbt["maxhp"] * 0.3);
        if (maxatk == 0) maxatk = Math.ceil(Math.random() * (3 - 1)) + 1;
        if (atk > maxatk) {
            atk = maxatk;
        }
        var randtip = Math.ceil(Math.random() * (3 - 1)) + 1;
        atk = Math.ceil(atk + randtip);
        //this.plog.log("attackedF", this.objid, atk, this.cbt["hp"], maxatk);

        //this.plog.log("attacked status", usecbt,"maxatk",maxatk,"thiscbt",this.cbt);
        //racebuff s introduced in v.11
        if (this.cbt["type"] == "char") {
            if (usecbt["race"] == 4) {
                if (atk > Math.ceil(usecbt["maxhp"] * 0.07)) {
                    atk = Math.ceil(usecbt["maxhp"] * 0.07);
                }
                //this.plog.log("racebuff 4 tanker maxdmg", maxdmg, targetcbt);
            }
            if (usecbt["race"] == 2) {
                //this.plog.log("racebuff 2 magic ", maxdmg, targetcbt);
            }
            if (usecbt["race"] == 1) {
                //this.plog.log("racebuff 1 swordman A");
            }

            if (usecbt["race"] == 3) {
                //this.plog.log("racebuff 3 ranger ", maxdmg, targetcbt);
            }
            if (usecbt["race"] == 5) {
                //this.plog.log("racebuff 5 nekro ", maxdmg, targetcbt);
            }

            ////////////

            if (this.cbt["level"] > 150 && this.cbt["race"] == 4) {
                //this.plog.log("racebuff2 4 tanker maxdmg ", usecbt["acc"], usecbt["crit"]);
                if (atk > Math.ceil(usecbt["maxhp"] * 0.04)) {
                    atk = Math.ceil(usecbt["maxhp"] * 0.04);
                }
            }
            if (this.cbt["level"] > 150 && this.cbt["race"] == 1) {
                //this.plog.log("racebuff2 1 swordman maxdmg ", usecbt["acc"], usecbt["crit"]);
                if (atk > Math.ceil(usecbt["maxhp"] * 0.20)) {
                    atk = Math.ceil(usecbt["maxhp"] * 0.20);
                }
            }
        }
        //raceboff e

        if (this.combatobj.config.combatmode == "boss" || this.combatobj.config.combatmode == "svboss") {
            if (Number(this.cbt["team"]) == 2) {
                var maxatk = Math.floor(this.cbt["maxhp"] * 0.03);
                if (maxatk == 0) maxatk = Math.floor(Math.random() * (3 - 1)) + 1;
                //console.log("bossmode atk",atk,maxatk);
                if (atk > maxatk) {
                    atk = maxatk; //max 3% of hp each attack in boss mode
                }
            }
        }
        //this.plog.log("attacked status", usecbt["name"],usecbt, "maxatk", maxatk, "atk", atk);

        this.cbt["hp"] = usecbt["hp"] - atk;
        var atkosd = "-" + atk;

        if (atkmode == "crit") {
            atkosd = atkosd + " crit";
            this.osdflash(atkosd, "bigred");
        } else {
            this.osdflash(atkosd, "red");
        }
        //this.osdflash(atkosd + " by " + atkby, "red");

        if (this.cbt["hp"] < 0) this.cbt["hp"] = 0;
        var isded = false;

        if (this.combatobj.battlelog[this.objid] != undefined) {
            this.combatobj.battlelog[this.objid].hp_remain = this.cbt["hp"];
            this.combatobj.battlelog[this.objid].dealt_dmg += atk;
        }
        if (this.cbt["hp"] <= 0) {
            this.plog.log("found dead (attacked())", this.objid);
            //this.logtext("Ba", this.logtextgetname(this.objid) + " defeated by this following attack");
            isded = true
            this.dead();
        }
        //    this.plog.log("attacked", this.cbt["type"], this.cbt["team"],"atk",atk);
        //
        //this.plog.log("atkby", atkby, )

        if (this.cbt != undefined && this.cbt["team"] == 2) {
            //&& Number(this.combatobj.cbto[atkby]["cbt"]["team"]) == 1
            //this.plog.log("atkby", atkby, this.combatobj.cbto[atkby]["cbt"]["team"], this.combatobj.cbto[atkby])
            //if (atkby != "" && Number(this.cbt["team"]) == 2) {
            this.combatobj.totaldmg = this.combatobj.totaldmg + atk;
            this.combatobj.totaldmgtxt.setText("Damage: " + this.combatobj.totaldmg);//, atk

            var tw = this.wh.tweens.add(
                {
                    targets: this.combatobj.totaldmgtxt,
                    scale: 1.1,
                    ease: 'Power1',
                    duration: 20,
                    yoyo: true,
                    oncomplete: function () {
                        this.combatobj.totaldmgtxt.setScale(1);
                        //this.plog.log("tttttttttttt");
                    }.bind(this)
                }, this
            );
        }
        if (isded == false) {
            return atk + "(HP remaining" + this.cbt["hp"] + ")";
        } else {
            return atk + " !!defeated";
        }
        //this.updatepgbar();
        //if (this.cbt["hp"] <= 0) this.dead();
    }
    dead() {
        //return
        // this.plog.log("dead()", this.objid, this.cbt);
        this.plog.log("dead()", this.cbt['hp'], this.cbt['maxhp'], this.pgbar_health);
        //if (this.cbt["team"] == 1) return;//undead

        if (this.issummonexplode) {
            //this.plog.error("racebuff2 5 summoned by nekro, explode when dead");
            for (const key in this.combatobj.cbto) {
                var v = this.combatobj.cbto[key];
                if (v == undefined) continue;
                var chk = Phaser.Math.Distance.Between(this.currentposx, this.currentposy, v.currentposx, v.currentposy);
                if (chk <= 5) {
                    if (v.cbt["team"] != this.cbt["team"]) {
                        //this.plog.log("nearbyfoe", v);
                        const tmpdeadexplodei = this.doskill_createeff("effenergycharge6681_b", "effenergycharge6681_b");
                        this.wh.combatcontainer.add(tmpdeadexplodei);
                        this.wh.combatcontainer.bringToTop(tmpdeadexplodei);
                        tmpdeadexplodei.displayWidth = this.posinfo.grid * 3;
                        tmpdeadexplodei.scaleY = tmpdeadexplodei.scaleX;
                        tmpdeadexplodei.setPosition(this.combatobj.cbto[key].sprite.x, this.combatobj.cbto[key].sprite.y);
                        this.doskill_removeeff(tmpdeadexplodei, 600);
                        this.combatobj.cbto[key].attacked(this.combatobj.cbto[key].cbt["maxhp"] * 0.2);
                        //this.plog.error("racebuff2 5 summoned by nekro, explode when dead deal to", key, this.combatobj.cbto[key]);
                    }
                }
            }

            const tmpdeadexplode = this.doskill_createeff("effenergycharge6681_b", "effenergycharge6681_b");
            this.wh.combatcontainer.add(tmpdeadexplode);
            this.wh.combatcontainer.bringToTop(tmpdeadexplode);
            tmpdeadexplode.displayWidth = this.posinfo.grid * 5;
            tmpdeadexplode.scaleY = tmpdeadexplode.scaleX;
            tmpdeadexplode.setPosition(this.sprite.x, this.sprite.y);
            this.doskill_removeeff(tmpdeadexplode, 600);

        }
        if (this.pgbar_health != undefined) {
            this.pgbar_health.setcol({
                barcol1: 0xc4c4c4,
                barcol2: 0x737373,
                bgcol: 0x333333
            });
            this.pgbar_health.setimgcol('grey');
        }

        for (const key in this.cbt["skilluse"]) {
            if (Object.prototype.hasOwnProperty.call(this.cbt["skilluse"], key)) {
                if (this.cbt["skilluse"][key].bar != undefined) { //team1
                    this.cbt["skilluse"][key].bar.setcol({
                        barcol1: 0xc4c4c4,
                        barcol2: 0x737373,
                        bgcol: 0x333333
                    });
                    this.cbt["skilluse"][key].bar.setimgcol('grey');
                }
            }
        }
        for (const key in this.cbt["skillweaponuse"]) {
            if (Object.prototype.hasOwnProperty.call(this.cbt["skillweaponuse"], key)) {
                if (this.cbt["skillweaponuse"][key].bar != undefined) { //team1
                    this.cbt["skillweaponuse"][key].bar.setcol({
                        barcol1: 0xc4c4c4,
                        barcol2: 0x737373,
                        bgcol: 0x333333
                    });
                    this.cbt["skillweaponuse"][key].bar.setimgcol('grey');
                }
            }
        }
        this.updatepgbar();
        //this.logtext("BaL", this.logtextgetname(this.objid) + " defeated");
        //for challenge mode, team 2 get revived with higher level
        if (this.combatobj.config.combatmode == "challenge" || this.combatobj.config.combatmode == "svboss") {
            if (this.cbt["team"] == 2 && this.cbt["race"] != "") {
                this.movetobackline();
                //this.plog.log("team 2 dead in challenge mode", this.levelrunnerid, this.cbt);
                const tmplevelupeff = this.doskill_createeff("pipomapeffect013a_b", "pipomapeffect013a_b");
                this.wh.combatcontainer.add(tmplevelupeff);
                this.wh.combatcontainer.bringToTop(tmplevelupeff);
                tmplevelupeff.displayWidth = this.posinfo.grid * 3;
                tmplevelupeff.scaleY = tmplevelupeff.scaleX;
                //this.plog.log("respawn with higher level ", tmplevelupeff, tmplevelupeff);
                tmplevelupeff.setPosition(this.sprite.x, this.sprite.y);
                this.doskill_removeeff(tmplevelupeff, 600);
                //console.log(this.cbt,this.cbt["race"]);
                if (this.levelrunnerid < this.wh.registry.list.rcvarpass.stdstat[this.cbt["race"]].length)
                    this.levelrunnerid++;
                this.logtext("b", this.logtextgetname() + "Now Level: " + this.levelrunnerid);

                //this.plog.log("team 2 dead in challenge mode", this.cbt["race"], this.levelrunnerid,this.wh.registry.list.rcvarpass.stdstat);
                var tmpcbt = this.wh.registry.list.rcvarpass.stdstat[this.cbt["race"]][this.levelrunnerid]["cbt"];
                for (const k in tmpcbt) { //copy only existing value
                    //var v = tmpcbt[k];
                    this.cbt[k] = tmpcbt[k];
                    //this.plog.log("challenge mode copying", k, tmpcbt[k]);
                }
                this.cbt["hp"] = this.cbt["maxhp"];

                //this.cbt["team"]=2;
                //this.plog.log("team 2 dead in challenge mode after", this.cbt);
                this.osdflash("Level " + this.cbt["level"], "black", "left");
                this.iswalking = false;
                this.targetid = undefined;
                if (this.pgbar_health != undefined) {
                    this.pgbar_health.setcol({
                        barcol1: 0x24ff2b,
                        barcol2: 0x00d407,
                        bgcol: 0x333333
                    });
                    this.pgbar_health.setimgcol('green');
                    this.updatepgbar();
                }
                //this.battletick();
                return;
            }
        }
        this.updatepgbar();
        if (this.cbt["type"] == "char") {
            this.sprite.play({ key: this.cbt["sprite"] + "-dead", frameRate: this.combatobj.cbtfr, repeatDelay: 30000 });
        } else {
            this.sprite.play({ key: "mob_" + this.cbt["sprite"] + "_dead", frameRate: this.combatobj.cbtfr });
        }

        var tw = this.wh.tweens.add(
            {
                targets: this.sprite,
                alpha: 0,
                ease: 'Power1',
                duration: 2000, // duration of animation; higher=slower
                delay: 200,
                onComplete: function () {
                    //this.targets[0].destroy();
                }
            }, this
        );
        tw.play();
        //this.wh.asdf();
        //remove this cbt from stage and from everyone's target
        for (const key in this.combatobj.cbto) {
            var item = this.combatobj.cbto[key];
            if (item != undefined) {
                //this.plog.log("xxx", this.objid, key, item);
                if (item.targetid == this.objid) {
                    //this.plog.log("dead found cbt targeting this,", key, this.combatobj.cbto[key]);
                    this.combatobj.cbto[key].targetid = undefined;
                    //this.wh.asdf();
                }
            }
        }
        this.combatobj.cbto[this.objid] = undefined;
        this.updatepgbar();
        this.plog.log("dead() e " + this.friendlyname, this.cbt["hp"])
        //this.plog.log(this.combatobj.cbto);
        //this.wh.pause();
        /*for (const key in this.combatobj.cbto) {
            var item = this.combatobj.cbto[key];
            if (item != undefined) {
                //this.plog.log("xxx",key,item);
                if (key + "" == this.objid + "") {
                    //this.combatobj.cbto.splice(key, 1);
                    this.combatobj.cbto[key] = undefined;
                };
            }
        }*/
        //dropeffects
        //console.log(this.combatobj.config);
        if (this.combatobj.config.combatmode == "") {
            var max = 4;
            var min = 1;
            var dropi = Math.floor(Math.random() * (max - min + 1)) + min;
            const colors = ["lightblue", "orange", "yellow"];
            for (var i = 0; i <= dropi; i++) {

                const randomIndex = Math.floor(Math.random() * colors.length);
                var thiscol = colors[randomIndex];
                var thiso = this.wh.add.sprite(100, 100, "thecolor", thiscol);
                thiso.displayWidth = thiso.displayHeight = 5;
                this.wh.combatcontainer.add(thiso);
                this.wh.combatcontainer.bringToTop(thiso);
                thiso.x = this.sprite.x;
                thiso.y = this.sprite.y;

                var max = 64;
                var min = -64;
                var distx = Math.floor(Math.random() * (max - min + 1)) + min;
                var newx = Math.floor(thiso.x + distx);
                //console.log(newx);
                var maxy = 70;
                var miny = 50;
                var disty = Math.floor(Math.random() * (maxy - miny + 1)) + miny;

                var max = 28;
                var min = -28;
                var randdy = Math.floor(Math.random() * (max - min + 1)) + min;
                //disty=disty+randdy;
                var durationuse = 500 + randdy * 2;
                var twdrop = this.wh.add.timeline([
                    {
                        at: 0,
                        tween: {
                            targets: thiso,
                            y: { from: thiso.y - disty, to: thiso.y + randdy },
                            ease: 'Bounce',
                            duration: durationuse,
                            delay: 50
                        }
                    }, {
                        at: 0,
                        tween: {
                            targets: thiso,
                            x: { from: thiso.x, to: newx },
                            ease: 'Power3',
                            duration: durationuse,
                            delay: 50
                        }
                    }, {
                        at: 3000,
                        tween: {
                            targets: thiso,
                            alpha: 0,
                            ease: 'Bounce',
                            duration: durationuse,

                            onComplete: function () {
                                //this.targets[0].destroy();
                                //console.log(arguments);
                                try {
                                    arguments[1][0].destroy();
                                } catch (e) {
                                    console.log(e);
                                }
                            }
                        }
                    },]
                );
                twdrop.play();
            }
        }
    }
    getAngle(x1, y1, x2, y2) {
        // angle in radians
        var angleRadians = Math.atan2(y2 - y1, x2 - x1);
        // angle in degrees
        var angleDeg = (Math.atan2(y2 - y1, x2 - x1) * 180 / Math.PI);
        return angleDeg;
    }
    dowalktotarget(pos) {
        this.iswalking = true;
        //this.plog.log("dowalktotarget()");
        //if (pos==undefined) return;
        //if (pos[0]==undefined||pos[1]==undefined) return;
        var targetpos = this.combatobj.getxy(pos[0], pos[1]);
        //this.plog.log("dowalktotarget() targetpos", targetpos);
        var dir = this.findtargetdirection();
        if (this.cbt["team"] == 1) {
            //this.plog.log("dowalktotarget() ", this.cbt["team"], this.objid);
        }
        if (this.cbt.type == "char") {
            this.sprite.play({ key: this.cbt["sprite"] + "-walk-" + dir, frameRate: this.combatobj.cbtfr }, true);
        } else {
            this.sprite.play({ key: "mob_" + this.cbt["sprite"] + "_idle", frameRate: this.combatobj.cbtfr }, true);
            if (dir == "r") {
                this.faceright();
            }
            if (dir == "l") {
                this.faceleft();
            }
        }

        var walkspeeduse = Math.floor(1000 * this.combatobj.timespeed);//* this.combatobj.timespeed;
        walkspeeduse = walkspeeduse - 2;
        //this.plog.error("battleticktime walkspeeduse", (walkspeeduse), "this.combatobj.timerpersec", this.combatobj.timerpersec, "this.combatobj.timespeed", this.combatobj.timespeed);

        var tw = this.wh.tweens.add(
            {
                targets: this.sprite,
                x: targetpos.x,
                y: targetpos.y,
                ease: 'Linear',
                duration: walkspeeduse, // duration of animation; higher=slower
                //duration: this.walkspeed * this.combatobj.timespeed, // duration of animation; higher=slower
                delay: 0,
                onComplete: function () {
                    //this.plog.log("oncomplete combatant walk", this, arguments);
                    this.targets[0].refobj.iswalking = false;
                    this.targets[0].refobj.targetid = undefined;
                    //this.plog.log("oncomplete combatant walk", this, arguments);
                }  // set context? how?
            }, this
        );
    }
    lookfortarget(): number {
        //this.plog.log("lookfortarget by a ", this.cbt["type"], "team", this.cbt["team"]);
        var countteam1 = 0;
        var countteam2 = 0;
        for (const key in this.combatobj.cbto) {
            if (Object.prototype.hasOwnProperty.call(this.combatobj.cbto, key) && this.combatobj.cbto[key] != undefined) {
                if (this.combatobj.cbto[key].cbt["team"] == 1) countteam1++;
                if (this.combatobj.cbto[key].cbt["team"] == 2) countteam2++;
            }
        }
        if (countteam1 == 0) {
            this.plog.log("team 2 wins");
            this.wh.registry.list.rcvarpass.gameobj.scene.scenes[0].time.removeEvent(this.combatobj.timer);
            this.combatobj.team2wins();
            return 0;
        }
        if (countteam2 == 0) {
            this.plog.log("team 1 wins");
            this.wh.registry.list.rcvarpass.gameobj.scene.scenes[0].time.removeEvent(this.combatobj.timer);
            this.combatobj.team1wins();
            return 0;
        }
        //this.plog.log("lookfortarget", countteam1, countteam2);
        var tmpa = [];
        for (const key in this.combatobj.cbto) {
            if (Object.prototype.hasOwnProperty.call(this.combatobj.cbto, key) && this.combatobj.cbto[key] != undefined) {
                const mob = this.combatobj.cbto[key];
                if (mob.cbt["team"] + "" != this.cbt["team"] + "") {
                    //this.plog.log("lookatmob", this.objid, "team", this.cbt["team"], "looking at", key, "team", mob.cbt["team"]);
                    //tmp["spawnpos"] = team2locations[imob];
                    tmpa.push([key, Phaser.Math.Distance.BetweenPoints(this.sprite, mob.sprite)]);
                }
            }
        }
        tmpa.sort(function (a, b) {
            return a[1] - b[1];
        });
        if (this.cbt["type"] == "mob") {
            //this.plog.log("lookfortarget tmpa", tmpa, this.combatobj.cbto);
        }
        var target = tmpa[0][0];
        return Number(target);
    }

    osdflash(txt, col, extrapos = "") {
        //return;
        //this.plog.log("combatosdflashcount",this.wh.combatosdflashcount);
        if (this.combatobj.mincombat == "yes") return;
        if (this.wh.combatosdflashcount > 30) return;
        if (this.wh.combatosdflashcount < 0) this.wh.combatosdflashcount = 0;
        var dsparr = [];
        var val = txt;
        var dsparrl = dsparr.length;
        var flashtime = 2000;
        var addx = 0;
        if (extrapos == "left") addx = -this.sprite.displayWidth * 2;
        if (col == "red") {
            dsparr[dsparrl] = this.wh.add.text(0, 0, txt, { fontFamily: 'ffff', fontSize: '16px', stroke: '#fff', strokeThickness: 3, color: '#f00', fontStyle: 'bold' });
            dsparr[dsparrl].setPosition(this.sprite.x + addx, this.sprite.y);
            dsparr[dsparrl].setDepth(150000);
        }
        if (col == "bigred") {
            dsparr[dsparrl] = this.wh.add.text(0, 0, txt, { fontFamily: 'ffff', fontSize: '20px', stroke: '#fff', strokeThickness: 3, color: '#f00', fontStyle: 'bold' });
            dsparr[dsparrl].setPosition(this.sprite.x + addx, this.sprite.y);
            dsparr[dsparrl].setDepth(150000);
        }
        if (col == "black") {
            dsparr[dsparrl] = this.wh.add.text(0, 0, txt, { fontFamily: 'ffff', fontSize: '18px', stroke: '#fff', strokeThickness: 3, color: '#000', fontStyle: 'bold' });
            dsparr[dsparrl].setPosition(this.sprite.x + addx, this.sprite.y);
            dsparr[dsparrl].setDepth(150000);
            flashtime = 5000;
        }
        if (col == "green") {
            dsparr[dsparrl] = this.wh.add.text(0, 0, val["txt"], { fontFamily: 'ffff', fontSize: '16px', stroke: '#fff', strokeThickness: 3, color: '#0f0', fontStyle: 'bold' });
            dsparr[dsparrl].setPosition(this.sprite.x + addx, this.sprite.y);
            dsparr[dsparrl].setDepth(150000);
        }
        this.wh.combatosdflashcount++;
        var tw = this.wh.tweens.add(
            {
                targets: dsparr[dsparrl],
                alpha: 0,
                y: dsparr[dsparrl].y - 100,
                ease: 'Power3',
                duration: 2000, // duration of animation; higher=slower
                delay: 0,      // wait 500 ms before starting
                onComplete: function () {
                    //this.plog.log("osdflash tweencomplete", this);
                    this.targets[0].scene.combatosdflashcount--;
                    if (this.target != undefined && this.target[0] != undefined) {
                        this.target[0].destroy();
                    }
                    try {
                        arguments[1][0].destroy();
                    } catch (e) {
                        this.targets[0].scene.plog.log("ERROR", e)
                    }
                }  // set context? how?
            }
        );

    }
    dopassiveskill() {
        for (const key in this.cbt["skilluse"]) {
            if (Object.prototype.hasOwnProperty.call(this.cbt["skilluse"], key)) {
                if (this.cbt["skilluse"][key] == undefined) this.plog.warn("combat dopassiveskill cant find skill", key);
                if (this.cbt["skilluse"][key]["skill_type"] == "passive") {
                    //this.plog.log("dopassiveskill", key, this.cbt["skilluse"][key]);
                    this.doskill(this.cbt["skilluse"][key]);
                }
            }
        }
        for (const key in this.cbt["skillweaponuse"]) {
            if (Object.prototype.hasOwnProperty.call(this.cbt["skillweaponuse"], key)) {
                if (this.cbt["skillweaponuse"][key] == undefined) this.plog.warn("combat dopassiveskill skillweaponuse cant find skill", key, this.cbt);
                if (this.cbt["skillweaponuse"][key]["skill_type"] == "passive") {
                    //this.plog.log("dopassiveskill", key, this.cbt["skillweaponuse"][key]);
                    this.doskill(this.cbt["skillweaponuse"][key]);
                }
            }
        }
    }
    markattacktime() {
        this.attackwaituntil = this.combatobj.gett() + this.attacktime;
        //if (this.objid == 0) this.plog.log("markattacktime", this.attackwaituntil);
    }
    chkattacktime() {
        //var diff = this.attackwaituntil - this.combatobj.gett();
        //if (this.objid == 0)
        //this.plog.log("chkattacktime ", diff, this.attackwaituntil, this.combatobj.gett());
        if (this.attackwaituntil < this.combatobj.gett()) {
            //if (this.objid == 0) this.plog.log("chkattacktime ok", diff, this.attackwaituntil, this.combatobj.gett());
            return true;;
        }
        //if (this.objid == 0) this.plog.log("chkattacktime FALSE", diff, this.attackwaituntil, this.combatobj.gett());
        return false;;

    }
    getdistancearea(x, y, d) {
        var tmpgrid = [];
        var resarr = [];
        for (let yy = 0; yy <= this.combatobj.gridh; yy++) {
            if (tmpgrid[yy] == undefined) tmpgrid[yy] = [];
            for (let xx = 0; xx <= this.combatobj.gridw; xx++) {
                tmpgrid[yy][xx] = 0;
                var chk = Phaser.Math.Distance.Between(x, y, xx, yy);
                if (chk <= d) {
                    resarr.push([xx, yy]);
                }
                //this.plog.log("getdistancearea",x,y,xx,yy,chk);
            }
        }
        return resarr;
    }
}