/**
 * @license
 * SPDX-License-Identifier: Apache-2.0
 */
/** Class for holding element and attribute policies used for sanitization. */
export class SanitizerTable {
  constructor(allowedElements, elementPolicies, allowedGlobalAttributes, globalAttributePolicies, globallyAllowedAttributePrefixes) {
    this.allowedElements = allowedElements;
    this.elementPolicies = elementPolicies;
    this.allowedGlobalAttributes = allowedGlobalAttributes;
    this.globalAttributePolicies = globalAttributePolicies;
    this.globallyAllowedAttributePrefixes = globallyAllowedAttributePrefixes;
  }
  isAllowedElement(elementName) {
    // Note: `HTMLFormElement`s are always dropped, supporting them is very
    // costly because of the DOM clobberring they can cause. The additional code
    // size required to properly work around DOM clobberring issues is large and
    // shouldn't be put on every user of the sanitizer. Thoroughly review
    // b/210975025 and the CLs linked there before you start allowing form
    // elements.
    return elementName !== 'FORM' && (this.allowedElements.has(elementName) || this.elementPolicies.has(elementName));
  }
  getAttributePolicy(attributeName, elementName) {
    const elementPolicy = this.elementPolicies.get(elementName);
    if (elementPolicy === null || elementPolicy === void 0 ? void 0 : elementPolicy.has(attributeName)) {
      return elementPolicy.get(attributeName);
    }
    if (this.allowedGlobalAttributes.has(attributeName)) {
      return {
        policyAction: AttributePolicyAction.KEEP
      };
    }
    const globalPolicy = this.globalAttributePolicies.get(attributeName);
    if (globalPolicy) {
      return globalPolicy;
    }
    if (this.globallyAllowedAttributePrefixes && [...this.globallyAllowedAttributePrefixes].some(prefix => attributeName.indexOf(prefix) === 0)) {
      return {
        policyAction: AttributePolicyAction.KEEP
      };
    }
    return {
      policyAction: AttributePolicyAction.DROP
    };
  }
}
/**
 * Values derived from
 * https://godoc.corp.google.com/pkg/google3/third_party/safehtml/sanitizer/policy#AttributePolicy
 */
export var AttributePolicyAction;
(function (AttributePolicyAction) {
  AttributePolicyAction[AttributePolicyAction["DROP"] = 0] = "DROP";
  AttributePolicyAction[AttributePolicyAction["KEEP"] = 1] = "KEEP";
  AttributePolicyAction[AttributePolicyAction["KEEP_AND_SANITIZE_URL"] = 2] = "KEEP_AND_SANITIZE_URL";
  AttributePolicyAction[AttributePolicyAction["KEEP_AND_NORMALIZE"] = 3] = "KEEP_AND_NORMALIZE";
  // This action is currently equivalent to KEEP as there is no style sanitizer.
  AttributePolicyAction[AttributePolicyAction["KEEP_AND_SANITIZE_STYLE"] = 4] = "KEEP_AND_SANITIZE_STYLE";
  AttributePolicyAction[AttributePolicyAction["KEEP_AND_USE_RESOURCE_URL_POLICY"] = 5] = "KEEP_AND_USE_RESOURCE_URL_POLICY";
  AttributePolicyAction[AttributePolicyAction["KEEP_AND_USE_RESOURCE_URL_POLICY_FOR_SRCSET"] = 6] = "KEEP_AND_USE_RESOURCE_URL_POLICY_FOR_SRCSET";
})(AttributePolicyAction || (AttributePolicyAction = {}));
// From the spec:
// https://html.spec.whatwg.org/multipage/custom-elements.html#prod-potentialcustomelementname:~:text=name%20must%20not%20be%20any%20of%20the%20following%3A
const FORBIDDEN_CUSTOM_ELEMENT_NAMES = new Set(['ANNOTATION-XML', 'COLOR-PROFILE', 'FONT-FACE', 'FONT-FACE-SRC', 'FONT-FACE-URI', 'FONT-FACE-FORMAT', 'FONT-FACE-NAME', 'MISSING-GLYPH']);
/**
 * Helper for checking if an element tag is a custom element.
 */
export function isCustomElement(tag) {
  return !FORBIDDEN_CUSTOM_ELEMENT_NAMES.has(tag.toUpperCase()) && /^[a-z][-_.a-z0-9]*-[-_.a-z0-9]*$/i.test(tag);
}