import { Injectable } from '@angular/core';
//import { pipe } from 'rxjs';
import { plog } from './plog.service';

@Injectable({
  providedIn: 'root'
})
export class WeatherCtlService {
  wh;
  plog;
  thundertime;

  constructor() {
    this.plog = new plog();
  }

  reset(wh) {
    this.wh = wh;

    //this.plog.log("WeatherCtl reset", wh, this.wh.mapeffects);
    //console.log(this.wh.mapeffects);
    //var items = ["rain", "raindrops", "heavyrain", "ray1", "ray1tween", "fog", "fogalpha"];
    for (const v in this.wh.mapeffects) {
      //var v = this.wh.mapeffects[k];
      if (this.wh.mapeffects[v] != undefined) {
        //this.plog.error("WeatherCtl reset", k, v, typeof this.wh.mapeffects[v], this.wh.mapeffects[v]);
        //this.plog.error("WeatherCtl reset", this.wh.mapeffects[v]["type"],this.wh.mapeffects[v]);
        if (this.wh.mapeffects[v]["type"] == "Image") {
          this.wh.mapeffects[v].destroy();//true
          this.wh.mapeffects[v] = undefined;
        } else if (this.wh.mapeffects[v]["type"] == "ParticleEmitter") {
          this.wh.mapeffects[v].destroy();

        } else if (this.wh.mapeffects[v]["totalDuration"] != undefined) {
          //tween
          this.wh.mapeffects[v].destroy();
        } else {
          this.wh.mapeffects[v].destroy();
        }
      }
    }
    this.wh.registry.list.snd.stop("heavy-rain");
    this.wh.registry.list.snd.stop("light-rain");
    clearTimeout(this.thundertime);
  }

  subcreate(effid, particle, conf) {
    this.plog.log("WeatherCtl subcreate", effid, particle, conf);
    if (this.wh.mapeffects[effid] == undefined) {
      this.wh.mapcontainer.add(particle);
      particle.ParticleEmitter(conf);
    }
  }
  async fogStart(wh) {
    this.wh = wh;
    var chkconfigweathereffects = await this.wh.registry.list.rcstorage.get('setting_weathereffects');
    if (chkconfigweathereffects == "no") {
      this.plog.log("weathereffects disabled by settings");
      return;
    }
    var cwidth = this.wh.maplayers["bg"].displayWidth;
    var cheight = this.wh.maplayers["bg"].displayHeight;
    if (this.wh.mapeffects["fogalpha"] != undefined) {
      this.wh.mapeffects["fogalpha"].destroy();//true
      this.wh.mapeffects["fogalpha"] = undefined;
    }

    var width = this.wh.game.canvas.width;
    var height = this.wh.game.canvas.height;
    /*
        this.wh.mapeffects["fogalpha"] = wh.add.image(0, 0, "alpha10");
        this.wh.mapeffects["fogalpha"].setOrigin(0, 0);
        this.wh.mapeffects["fogalpha"].setPosition(0, 0);
        this.wh.mapeffects["fogalpha"].displayWidth = width;
        this.wh.mapeffects["fogalpha"].displayHeight = height;
        //tmp.setBlendMode(Phaser.BlendModes.ADD);
        //this.wh.mapeffects["fogalpha"].setBlendMode(Phaser.BlendModes.LIGHTEN);
        //tmp.setBlendMode(Phaser.BlendModes.HARD_LIGHT);
        this.wh.mapeffects["fogalpha"].setBlendMode(Phaser.BlendModes.LUMINOSITY);//1
        //tmp.setBlendMode(Phaser.BlendModes.MULTIPLY);
        //tmp.setBlendMode(Phaser.BlendModes.EXCLUSION);
        //this.wh.mapeffects["fogalpha"].setAlpha(0.6);
    */

    this.wh.mapeffects["fogalpha"] = this.wh.add.particles(400, 200, 'fog', {
      frame: ["b-1", "b-2", "b-3", "b-4", "b-5", "b-6", "b-7", "b-8"],
      frequency: 3000,
      yoyo: true,
      x: { min: 0, max: 0 },
      y: { min: 0, max: cheight },
      lifespan: { min: 30000, max: 40000 },
      speedX: { min: 50, max: 100 },
      scaleY: { min: 3, max: 4 },
      alpha: { min: 0.1, max: 0.4 },
      scaleX: { min: 4, max: 5 },
      quantity: { min: 1, max: 2 },
    });
    return;
    var rainParticle = this.wh.add.particles("fog");
    this.subcreate("fog", rainParticle, {
      frame: ["b-1", "b-2", "b-3", "b-4", "b-5", "b-6", "b-7", "b-8"],
      frequency: 3000,
      yoyo: true,
      x: { min: 0, max: 0 },
      y: { min: 0, max: cheight },
      lifespan: { min: 30000, max: 40000 },
      speedX: { min: 50, max: 100 },
      scaleY: { min: 3, max: 4 },
      alpha: { min: 0.1, max: 0.4 },
      scaleX: { min: 4, max: 5 },
      quantity: { min: 1, max: 2 },
      // blendMode: 'LIGHTEN',
    });
    this.plog.log("fogStart", this.wh);
    this.wh.camman(this.wh);
  }
  async rainStart(wh) {
    var cwidth = this.wh.maplayers["bg"].displayWidth;
    var cheight = this.wh.maplayers["bg"].displayHeight;
    this.wh = wh;
    this.plog.log("wh", this.wh);
    var chkconfigweathereffects = await this.wh.registry.list.rcstorage.get('setting_weathereffects');
    if (chkconfigweathereffects == "no") {
      this.plog.log("weathereffects disabled by settings");
      return;
    }
    this.wh.registry.list.snd.snd("light-rain", true);

    this.wh.mapeffects["rain1"] = this.wh.add.particles(400, 200, 'chatbubblearrow', {
      x: { min: 0, max: cwidth },
      y: 0,
      lifespan: { min: 500, max: 1000 },
      speedY: 1000,
      scaleY: { min: 1, max: 4 },
      scaleX: .02,
      quantity: { min: 5, max: 15 },
    });

    this.wh.mapeffects["rain1drop"] = this.wh.add.particles(400, 200, 'raindrops', {
      frame: "b-1",
      x: { min: -50, max: cwidth + 50 },
      y: { min: -50, max: cheight + 50 },
      lifespan: 70,
      scale: { start: 0.5, end: 0.8 },
      quantity: { min: 5, max: 15 },
    });
    return;
    var rainParticle = this.wh.add.particles("chatbubblearrow");
    this.subcreate("rain", rainParticle, {
      x: { min: 0, max: cwidth },
      y: 0,
      lifespan: { min: 500, max: 1000 },
      speedY: 1500,
      scaleY: { min: 1, max: 4 },
      scaleX: .02,
      quantity: { min: 5, max: 15 },
      // blendMode: 'LIGHTEN',
    });

    var rainParticle = this.wh.add.particles("raindrops");
    this.subcreate("raindrops", rainParticle, {
      frame: "b-1",
      x: { min: -50, max: cwidth + 50 },
      y: { min: -50, max: cheight + 50 },
      lifespan: 70,
      scale: { start: 0.5, end: 0.8 },
      quantity: { min: 5, max: 15 },
      //blendMode: 'ADD',

    });
  }
  getRandomInterval(minSeconds: number, maxSeconds: number): number {
    const minMilliseconds = minSeconds * 1000;
    const maxMilliseconds = maxSeconds * 1000;
    return Math.floor(Math.random() * (maxMilliseconds - minMilliseconds + 1)) + minMilliseconds;
  }

  async heavyRain_thunder() {
    //console.log("Task executed at", new Date());
    this.wh.cameras.cameras[0].flash();
    await this.wh.registry.list.rctoast.sleep(300);
    this.wh.cameras.cameras[0].flash();

    this.wh.registry.list.snd.snd("thunder");
    this.scheduleNextThunder();
  }

  scheduleNextThunder() {
    const interval = this.getRandomInterval(20, 40);
    this.thundertime = setTimeout(() => {
      this.heavyRain_thunder();
    }, interval);
  }


  async heavyRainStart(wh) {
    var cwidth = this.wh.maplayers["bg"].displayWidth;
    var cheight = this.wh.maplayers["bg"].displayHeight;

    var width = wh.game.canvas.width;
    var height = wh.game.canvas.height;
    this.wh = wh;
    var chkconfigweathereffects = await this.wh.registry.list.rcstorage.get('setting_weathereffects');
    if (chkconfigweathereffects == "no") {
      this.plog.log("weathereffects disabled by settings");
      return;
    }
    this.wh.registry.list.snd.snd("heavy-rain", true);
    this.thundertime = this.scheduleNextThunder();


    this.wh.mapeffects["rain2"] = this.wh.add.particles(0, 0, 'chatbubblearrow', {

      x: { min: 0, max: width},
      y: 0,
      lifespan: { min: 500, max: 1000 },
      speedY: 1000,
      scaleY: { min: 1, max: 4 },
      scaleX: .02,
      quantity: { min: 15, max: 25 },
    });
    this.wh.mapeffects["rain2drop"] = this.wh.add.particles(0, 0, 'raindrops', {
      frame: "b-1",
      x: { min: 0, max: width },
      y: { min: 0, max: height },
      lifespan: 70,
      scale: { start: 0.5, end: 0.8 },
      quantity: { min: 15, max: 20 },
    });
    return;

    var rainParticle = this.wh.add.particles("chatbubblearrow");
    this.subcreate("heavyrain", rainParticle, {
      x: { min: 0, max: cwidth },
      y: 0,
      lifespan: { min: 500, max: 1000 },
      speedY: 1500,
      scaleY: { min: 1, max: 4 },
      scaleX: .02,
      quantity: { min: 15, max: 25 },
      // blendMode: 'LIGHTEN',
    });
    var rainParticle = this.wh.add.particles("raindrops");
    this.subcreate("heavyraindrops", rainParticle, {
      frame: "b-1",
      x: { min: -50, max: cwidth + 50 },
      y: { min: -50, max: cheight + 50 },
      lifespan: 70,
      scale: { start: 0.5, end: 0.8 },
      quantity: { min: 15, max: 20 },
      //blendMode: 'ADD',

    });
  }

  async snowStart(wh) {
    var cwidth = this.wh.maplayers["bg"].displayWidth;
    var cheight = this.wh.maplayers["bg"].displayHeight;

    var width = wh.game.canvas.width;
    var height = wh.game.canvas.height;

    this.wh = wh;

    var chkconfigweathereffects = await this.wh.registry.list.rcstorage.get('setting_weathereffects');
    if (chkconfigweathereffects == "no") {
      this.plog.log("weathereffects disabled by settings");
      return;
    }
    this.wh.mapeffects["snow"] = this.wh.add.particles(0, 0, 'snowflakes', {
      frame: ["b-1", "b-2", "b-3", "b-4", "b-5", "b-6"],
      x: { min: 0, max: width },
      y: { min: 0, max: height },
      lifespan: { min: 15000, max: 30000 },
      speedY: { min: 20, max: 100 },
      speedX: { min: 0, max: 40 },
      scale: { min: 0.8, max: 1.2 },
      quantity: { min: 3, max: 5 },
      frequency: 1000,
      rotation: { min: 0, max: 40 },
    });
    return;
    var rainParticle = this.wh.add.particles("snowflakes");
    this.subcreate("snow1", rainParticle, {
      frame: ["b-1", "b-2", "b-3", "b-4", "b-5", "b-6"],
      //frame: ["b-1"],
      x: { min: 0 - (cwidth / 3), max: cwidth },
      y: { min: 0, max: cheight / 2 },
      lifespan: { min: 15000, max: 30000 },
      speedY: { min: 20, max: 100 },
      speedX: { min: 0, max: 40 },
      scale: { min: 0.8, max: 1.2 },
      quantity: { min: 3, max: 5 },
      frequency: 1000,
      rotation: { min: 0, max: 40 },
      // blendMode: 'LIGHTEN',
    });

    var rainParticle = this.wh.add.particles("snowflakeslarge");
    this.subcreate("snow2", rainParticle, {
      frame: ["b-1", "b-2", "b-3", "b-4", "b-5", "b-6"],
      //frame: ["b-1"],
      x: { min: 0 - (cwidth / 3), max: cwidth },
      y: 0,
      lifespan: { min: 15000, max: 30000 },
      speedY: { min: 100, max: 150 },
      speedX: { min: 100, max: 100 },
      scale: { min: 0.3, max: 0.5 },
      quantity: { min: 1, max: 3 },
      frequency: 1000,
      rotation: { min: 0, max: 40 },
      // blendMode: 'LIGHTEN',
    });


  }
  async startRay1(wh) {

    this.wh = wh;
    var chkconfigweathereffects = await this.wh.registry.list.rcstorage.get('setting_weathereffects');
    if (chkconfigweathereffects == "no") {
      this.plog.log("weathereffects disabled by settings");
      return;
    }
    var cwidth = this.wh.maplayers["bg"].displayWidth;
    var cheight = this.wh.maplayers["bg"].displayHeight;
    if (this.wh.mapeffects["ray1"] != undefined) {
      this.wh.mapeffects["ray1"].destroy();//true
      this.wh.mapeffects["ray1"] = undefined;
    }

    var width = this.wh.game.canvas.width;
    var height = this.wh.game.canvas.height;
    this.wh.mapeffects["ray1"] = wh.add.image(0, 0, "testlightts");
    this.wh.mapeffects["ray1"].setOrigin(0, 0);
    this.wh.mapeffects["ray1"].setPosition(0, 0);
    this.wh.mapeffects["ray1"].displayWidth = width;
    this.wh.mapeffects["ray1"].displayHeight = height;
    //tmp.setBlendMode(Phaser.BlendModes.ADD);
    this.wh.mapeffects["ray1"].setBlendMode(Phaser.BlendModes.LIGHTEN);
    //tmp.setBlendMode(Phaser.BlendModes.HARD_LIGHT);
    //tmp.setBlendMode(Phaser.BlendModes.LUMINOSITY);//1
    //tmp.setBlendMode(Phaser.BlendModes.MULTIPLY);
    //tmp.setBlendMode(Phaser.BlendModes.EXCLUSION);
    this.wh.mapeffects["ray1"].setAlpha(0.6);
    this.wh.mapeffects["ray1tween"] = wh.tweens.add(
      {
        targets: this.wh.mapeffects["ray1"],
        alpha: 0.4,
        ease: 'Power3',
        duration: 1000, // duration of animation; higher=slower
        delay: 500,      // wait 500 ms before starting
        loop: -1,
        yoyo: true
      }
    );
    this.wh.camman(this.wh);
  }
  async pixelateStart(wh, pixel = 5) {
    var config = {
      pixelWidth: pixel,
      pixelHeight: pixel,
      // name: 'rexPixelationPostFx'
    }
    this.plog.log("pixelateStart", this.wh);
    if (wh != undefined) this.wh = wh;
    var tmp;
    var pipelineInstance1 = await this.wh.plugins.get('PixelationPipelinePlugin')
    this.plog.log("pixelateStart pipelineInstance1", pipelineInstance1);

    var pipelineInstance2 = await pipelineInstance1.add(this.wh.cameras.cameras[0], config);
    this.plog.log("pixelateStart pipelineInstance2", pipelineInstance2);
  }
  async pixelateStop() {
    this.plog.log("pixelateStop", this.wh);
    var tmp;
    var pipelineInstance1 = await this.wh.plugins.get('PixelationPipelinePlugin')
    this.plog.log("pixelateStop pipelineInstance1", pipelineInstance1);
    var pipelineInstance2 = await pipelineInstance1.remove(this.wh.cameras.cameras[0]);
  }


  async toonifyStart(wh, edge = 0.5) {
    var config = {
      edgeThreshold: edge,
      // hueLevels: 0,
      // sLevels: 0,
      // vLevels: 0,
      // edgeColor: 0,
      sLevels: 4,
      vLevels: 4,
    }
    this.plog.log("toonifyStart", this.wh);
    if (wh != undefined) this.wh = wh;
    var tmp;
    var pipelineInstance1 = await this.wh.plugins.get('ToonifyPipelinePlugin')
    this.plog.log("toonifyStart pipelineInstance1", pipelineInstance1);

    var pipelineInstance2 = await pipelineInstance1.add(this.wh.cameras.cameras[0], config);
    this.plog.log("toonifyStart pipelineInstance2", pipelineInstance2);
  }
  async toonifyStop() {
    this.plog.log("toonifyStop", this.wh);
    var tmp;
    var pipelineInstance1 = await this.wh.plugins.get('ToonifyPipelinePlugin')
    this.plog.log("toonifyStop pipelineInstance1", pipelineInstance1);
    var pipelineInstance2 = await pipelineInstance1.remove(this.wh.cameras.cameras[0]);
  }
  /*wont start if enable

  async grayscaleStart(wh, intense = 0.5) {
    var config = {
      intensity: intense,
    }
    this.plog.log("toonifyStart", this.wh);
    if (wh != undefined) this.wh = wh;
    var tmp;
    var pipelineInstance1 = await this.wh.plugins.get('rexGrayScalePipeline')
    this.plog.log("toonifyStart pipelineInstance1", pipelineInstance1);

    var pipelineInstance2 = await pipelineInstance1.add(this.wh.cameras.cameras[0], config);
    this.plog.log("toonifyStart pipelineInstance2", pipelineInstance2);
  }
  async grayscaleStop() {
    this.plog.log("grayscaleStop", this.wh);
    var tmp;
    var pipelineInstance1 = await this.wh.plugins.get('rexGrayScalePipeline')
    this.plog.log("toonifyStop pipelineInstance1", pipelineInstance1);
    var pipelineInstance2 = await pipelineInstance1.remove(this.wh.cameras.cameras[0]);
  }
  */

  async hslStart(wh, hueRotate = 0, satAdjust = 1, lumAdjust = 0.5) {

    var config = {
      hueRotate: hueRotate,
      satAdjust: satAdjust,
      lumAdjust: lumAdjust,
    }
    this.plog.log("hslStart", this.wh);
    this.hslStop();
    if (wh != undefined) this.wh = wh;
    var tmp;
    var pipelineInstance1 = await this.wh.plugins.get('HSLAdjustPipelinePlugin')
    this.plog.log("hslStart pipelineInstance1", pipelineInstance1);

    var pipelineInstance2 = await pipelineInstance1.add(this.wh.cameras.cameras[0], config);
    this.plog.log("hslStart pipelineInstance2", pipelineInstance2);
  }

  async hslStop() {
    this.plog.log("hslStop", this.wh);
    var tmp;
    var pipelineInstance1 = await this.wh.plugins.get('HSLAdjustPipelinePlugin')
    this.plog.log("hslStop pipelineInstance1", pipelineInstance1);
    var pipelineInstance2 = await pipelineInstance1.remove(this.wh.cameras.cameras[0]);
  }
}
