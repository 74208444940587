import { Injectable } from '@angular/core';
import { RCVarsService } from './rcvars-service.service';
import { RCHTTP } from './rchttp.service';
import { RCOSDService } from './rcosd.service';
import { plog } from './plog.service';

@Injectable({
  providedIn: 'root',
})
export class RCHomeInvenItemsService {
  shopdragbtn;
  empty;
  loading;
  selecteditemno = '';
  currentresppage = 1;
  respperpage = 25;
  resptotalpage;
  selectcircle;
  itemdescr;
  shopitemlist = [];
  shopitemlistr = [];
  shopitemlistp = [];
  shopfwdbtn;
  shopbackbtn;
  invendropbtn;
  invenmode = '';
  shopclosebtn;
  shopguibg;
  plog;

  constructor(
    public rcvarsservice: RCVarsService,
    public rchttp: RCHTTP,
    public rcosd: RCOSDService
  ) { 
    this.plog=new plog();
  }

  loadingshow() {
    this.wh.homeinventorycontainer_item.bringToTop(this.loading);
    this.loading.visible = true;
  }
  loadinghide() {
    this.loading.visible = false;
  }
  wh;
  firsttimerun = true;
  firsttimerunfunc() {
    if (this.firsttimerun == true) {
      this.wh.registry.list.gameitf.guiwindowclick(
        this.wh.homeinventorycontainer_item
      );
    }
    this.firsttimerun = false;
  }
  async showinventory(wh, shopdata, invenmode, forcefocus = false) {
    if (forcefocus == true) this.firsttimerun = true;

    this.wh = wh;

    if (this.wh.homeinventorycontainer_item != undefined) {
      await this.showinventory_close(this.wh);
    }

    await this.wh.registry.list.rchttp.updatecharinfo();
    this.plog.log('showinventory home', wh, shopdata);
    this.invenmode = invenmode;
    /*for (var i = 0; i < 100; i++) {
      if (this.shopitemlist[i] != undefined) this.shopitemlist[i].destroy();
      if (this.shopitemlistp[i] != undefined) this.shopitemlistp[i].destroy();
      if (this.shopitemlistr[i] != undefined) this.shopitemlistr[i].destroy();
      this.shopitemlist[i] = undefined;
      this.shopitemlistp[i] = undefined;
      this.shopitemlistr[i] = undefined;
    }
    if (wh.homeinventorycontainer_item != undefined) {
      wh.homeinventorycontainer_item.visible = true;
      this.showinventory_loaded(wh, shopdata);
      return;
    }*/
    var width = wh.game.canvas.width;
    var height = wh.game.canvas.height;
    this.plog.log('showinventory', wh, shopdata);

    const selectcircle = wh.add.image(0, 0, 'selectcircle');
    selectcircle.setOrigin(0.5, 0.5);
    selectcircle.visible = false;
    selectcircle.setAlpha(0.7);
    const shopguibg = wh.add.image(0, 0, 'homeinveniguibg');
    shopguibg.setOrigin(0.5, 0.5);
    shopguibg.setInteractive({ pixelPerfect: true });
    shopguibg.removeAllListeners();
    this.shopguibg = shopguibg;
    const shopbackbtn = wh.add.sprite(0, 0, "thesb", "backsign");//wh.add.image(0, 0, 'listbackbtn');
    shopbackbtn.setOrigin(0.5, 0.5);
    shopbackbtn.setInteractive();
    shopbackbtn.removeAllListeners();
    const shopfwdbtn = wh.add.sprite(0, 0, "thesb", "forwardsign");//wh.add.image(0, 0, 'listfwdbtn');
    shopfwdbtn.setOrigin(0.5, 0.5);
    shopfwdbtn.setInteractive();
    shopfwdbtn.removeAllListeners();
    const invendropbtn = wh.add.sprite(0, 0, "thesb", "retrieve");//wh.add.image(0, 0, 'homeinvenret');
    invendropbtn.setOrigin(0.5, 0.5);
    invendropbtn.setInteractive();
    invendropbtn.removeAllListeners();
    //this.plog.log('showinventory var', this.rcvarsservice);
    this.shopfwdbtn = shopfwdbtn;
    this.shopbackbtn = shopbackbtn;
    this.selectcircle = selectcircle;
    this.invendropbtn = invendropbtn;

    this.shopclosebtn = wh.add.sprite(300, 300, "thesq", 'close');
    this.shopclosebtn.name='skipcntnrsizecal';
    this.shopclosebtn.setOrigin(0.5, 0.5);
    this.shopclosebtn.setPosition(155, -200);
    this.shopclosebtn.displayWidth = 40;
    this.shopclosebtn.displayHeight = 40;
    this.shopclosebtn.setInteractive();
    this.shopclosebtn.removeAllListeners();
    this.shopclosebtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        //this.scene.registry.list["thethis"].phm.floorclick(this);
        this.plog.log(
          'gc.npcbackbtn pointerup',
          pointer,
          localX,
          localY,
          event,
          this
        );
        this.showinventory_close(wh);
      },
      this
    );

    shopbackbtn.displayWidth = 100;
    shopfwdbtn.displayWidth = 100;
    invendropbtn.displayWidth = 100;
    shopbackbtn.displayHeight = 40;
    shopfwdbtn.displayHeight = 40;
    invendropbtn.displayHeight = 40;
    shopbackbtn.setPosition(-100, 220);
    shopfwdbtn.setPosition(100, 220);
    invendropbtn.setPosition(0, 220);
    wh.homeinventorycontainer_item = wh.add.container(300, 300);
    wh.homeinventorycontainer_item._refsrv = this;
    wh.homeinventorycontainer_item.name = "homeinventorycontainer_item";
    wh.homeinventorycontainer_item.add(this.shopclosebtn);
    wh.homeinventorycontainer_item.add(selectcircle);
    wh.homeinventorycontainer_item.add(shopguibg);
    wh.homeinventorycontainer_item.add(shopbackbtn);
    wh.homeinventorycontainer_item.add(shopfwdbtn);
    wh.homeinventorycontainer_item.add(invendropbtn);
    wh.homeinventorycontainer_item.bringToTop(this.shopclosebtn);
    var itemdescr = wh.add.text(0, 0, wh.gl(this.wh.trs("f:homeinventorycontainer:Click Item to View Description===Click Item to View Description")), wh.fs["winguiitemdescr"]);
    wh.homeinventorycontainer_item.add(itemdescr);
    itemdescr.setOrigin(0, 0);
    itemdescr.setPosition(-140, 179);
    this.itemdescr = itemdescr;




    this.empty = wh.add.image(300, 300, 'empty');
    this.empty.setOrigin(0.5, 0.5);
    this.empty.setPosition(0, 0);
    this.empty.displayWidth = this.empty.displayHeight = 300;
    wh.homeinventorycontainer_item.add(this.empty);
    wh.homeinventorycontainer_item.bringToTop(this.empty);
    this.empty.visible = false;


    this.loading = wh.add.sprite(300, 300, 'pixelloading').play('pixelloading_b');
    this.loading.setOrigin(0.5, 0.5);
    this.loading.setPosition(0, 0);
    this.loading.displayWidth = this.loading.displayHeight = 60;
    wh.homeinventorycontainer_item.add(this.loading);
    wh.homeinventorycontainer_item.bringToTop(this.loading);

    //drag

    this.shopdragbtn = wh.add.image(300, 300, 'spacer');
     this.shopdragbtn.setOrigin(0.5, 0.5);
    this.shopdragbtn.setPosition(0, -200);
    this.shopdragbtn.displayWidth = 270;
    this.shopdragbtn.displayHeight = 60;
    this.shopdragbtn.setInteractive();
    this.shopdragbtn.removeAllListeners();
    this.shopdragbtn.on(
      'pointerout',
      function (pointer, localX, localY, event) {
        this.rcvarsservice.gameobj.scene.scenes[0].homeinventorycontainer_item.dragactive =
          false;
      },
      this
    );
    this.shopdragbtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        this.rcvarsservice.gameobj.scene.scenes[0].homeinventorycontainer_item.dragactive =
          false;
      },
      this
    );
    this.shopdragbtn.on(
      'pointerdown',
      function (pointer, localX, localY, event) {
        this.rcvarsservice.gameobj.registry.list.gameitf.guiwindowclick(
          this.rcvarsservice.gameobj.scene.scenes[0].homeinventorycontainer_item
        );

        this.rcvarsservice.gameobj.scene.scenes[0].homeinventorycontainer_item.dragactive =
          true;
        this.rcvarsservice.gameobj.scene.scenes[0].homeinventorycontainer_item.dragactivex =
          this.rcvarsservice.gameobj.scene.scenes[0].homeinventorycontainer_item.x;
        this.rcvarsservice.gameobj.scene.scenes[0].homeinventorycontainer_item.dragactivey =
          this.rcvarsservice.gameobj.scene.scenes[0].homeinventorycontainer_item.y;
        this.rcvarsservice.gameobj.scene.scenes[0].homeinventorycontainer_item.dragactivepx =
          pointer.x;
        this.rcvarsservice.gameobj.scene.scenes[0].homeinventorycontainer_item.dragactivepy =
          pointer.y;
      },
      this
    );
    this.rcvarsservice.gameobj.scene.scenes[0].homeinventorycontainer_item.dragactive =
      false;
    this.rcvarsservice.gameobj.scene.scenes[0].homeinventorycontainer_item.dragactivex = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].homeinventorycontainer_item.dragactivey = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].homeinventorycontainer_item.dragactivepx = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].homeinventorycontainer_item.dragactivepy = 0;
    this.shopdragbtn.on(
      'pointermove',
      function (pointer, localX, localY, event) {
        //this.scene.registry.list["thethis"].phm.floorclick(this);
        //this.plog.log('shop pointermove', pointer, localX, localY, event, this);
        //this.showinventory_close(wh);
        if (
          this.rcvarsservice.gameobj.scene.scenes[0].homeinventorycontainer_item
            .dragactive == true
        ) {
          var tmpx =
            this.rcvarsservice.gameobj.scene.scenes[0]
              .homeinventorycontainer_item.dragactivex;
          var tmpy =
            this.rcvarsservice.gameobj.scene.scenes[0]
              .homeinventorycontainer_item.dragactivey;
          var tmppx =
            this.rcvarsservice.gameobj.scene.scenes[0]
              .homeinventorycontainer_item.dragactivepx;
          var tmppy =
            this.rcvarsservice.gameobj.scene.scenes[0]
              .homeinventorycontainer_item.dragactivepy;
          var movedx = tmppx - pointer.x;
          var movedy = tmppy - pointer.y;
          //this.plog.log('drag', tmppx, pointer.x, movedx);

          this.rcvarsservice.gameobj.scene.scenes[0].homeinventorycontainer_item.x =
            tmpx - movedx;
          this.rcvarsservice.gameobj.scene.scenes[0].homeinventorycontainer_item.y =
            tmpy - movedy;
          //this.rcvarsservice.gameobj.scene.scenes[0].homeinventorycontainer_item.y = pointer.y;
        }
      },
      this
    );
    wh.homeinventorycontainer_item.add(this.shopdragbtn);
    wh.homeinventorycontainer_item.bringToTop(this.shopdragbtn);

    /*
    this.plog.log("dragsize",shopguibg.width, shopguibg.height);
    wh.homeinventorycontainer_item.setSize(shopguibg.width, shopguibg.height);
    wh.homeinventorycontainer_item.setInteractive();
    wh.input.on('mousemove', function(pointer) {
      //check here is a scene has a selected gameObject
      //if(this.selected) { //to be able to detect if user really select something.
          wh.setPosition(pointer.x, pointer.y);
      //}
    }, this);*/

    this.plog.log(
      'homeinventorycontainer_item x',
      wh.homeinventorycontainer_item,
      width,
      height
    );
    wh.homeinventorycontainer_item.setPosition(width / 2, height / 2);
    //move
    wh.homeinventorycontainer_item.x = width - this.shopguibg.width / 2 + 20;

    let loader = new Phaser.Loader.LoaderPlugin(wh);
    /*
    //move to spritepack
    for (var vv in shopdata["item_home"]) {
      var vdat = shopdata["item_home"][vv];
      var itemdat = this.rcvarsservice.allitem[vdat.item];
      loader.image(vdat["item"], this.rcvarsservice.rc_baseurl + "item/" + itemdat["pcode"] + "/" + itemdat["sprite"] + ".png");

      this.plog.log("load shop ", vv, vdat, itemdat);
    }*/
    loader.once(
      Phaser.Loader.Events.COMPLETE,
      () => {
        this.plog.log('Phaser.Loader.Events.COMPLETE');
        this.plog.log(this);
        this.showinventory_loaded(wh, shopdata);
      },
      this
    );
    loader.start();
  }
  showinventory_loaded(wh, shopdata) {
    this.loadingshow();
    this.firsttimerunfunc();
    this.invendropbtn.visible = true;

    this.plog.log('showinventory_loaded', wh, shopdata);
    //this.rcvarsservice.gameobj.registry.list.gameitf.guiwindowclick(this.rcvarsservice.gameobj.scene.scenes[0].homeinventorycontainer_item);

    var margintop = -90;
    var marginleft = -117;
    var cxpos = 0;
    var crow = 0;
    var i = 0;
    for (var i = 0; i < 100; i++) {
      if (this.shopitemlist[i] != undefined) {
        this.shopitemlist[i].visible = false;
      }
      if (this.shopitemlistp[i] != undefined) {
        this.shopitemlistp[i].visible = false;
      }
      if (this.shopitemlistr[i] != undefined) {
        this.shopitemlistr[i].visible = false;
      }
    }
    for (var i = 0; i < 100; i++) {
      if (this.shopitemlist[i] != undefined) this.shopitemlist[i].destroy(); // = undefined;
      if (this.shopitemlistp[i] != undefined) this.shopitemlistp[i].destroy(); // = undefined;
      if (this.shopitemlistr[i] != undefined) this.shopitemlistr[i].destroy(); // = undefined;
    }
    for (var i = 0; i < 100; i++) {
      if (this.shopitemlist[i] != undefined) this.shopitemlist[i] = undefined;
      if (this.shopitemlistr[i] != undefined) this.shopitemlistr[i] = undefined;
      if (this.shopitemlistp[i] != undefined) this.shopitemlistp[i] = undefined;
    }
    this.currentresppage = 1;
    var i = 0;
    this.plog.log('loaded itemdatall ', shopdata);

    for (var vv in shopdata['item_home']) {
      var vdat = shopdata['item_home'][vv];
      var itemdat = this.rcvarsservice.allitem[vdat.item];
      //this.plog.log('loaded itemdat ', vv, vdat, itemdat);
      //var tmp = wh.add.image(32, 32, itemdat["code"]);
      this.shopitemlist[i] = wh.add.sprite(32, 32, 'item_tp', itemdat['code']);
      this.shopitemlist[i].displayWidth = 48;
      this.shopitemlist[i].displayHeight = 48;
      wh.homeinventorycontainer_item.add(this.shopitemlist[i]);
      this.shopitemlist[i].setPosition(marginleft + cxpos * 59, margintop + crow * 59);
      this.shopitemlistr[i] = wh.add.sprite(16, 16, "spacer");
      if (vdat["rarity"] == "rar") {
        this.shopitemlistr[i].setTexture("effrar").play("effrar_b");
      }
      if (vdat["rarity"] == "leg") {
        this.shopitemlistr[i].setTexture("effleg").play("effleg_b");
      }
      if (vdat["rarity"] == "ult") {
        this.shopitemlistr[i].setTexture("effleg").play("effleg_b");
        this.shopitemlistr[i].setTint(0xff0000);
      }
      if (vdat["rarity"] == "rel") {
        this.shopitemlistr[i].setTexture("effleg").play("effleg_b");
        this.shopitemlistr[i].setTint(0xff0000);
      }
      this.shopitemlistr[i].displayHeight = this.shopitemlistr[i].displayWidth = 32;
      //this.plog.log("xxx1",tmp);
      if (Number(vdat['itemplus']) != 0) {
        this.shopitemlistp[i] = wh.add.text(0, 0, '+' + vdat['itemplus'], wh.fs["wingui_plus"]);
      } else {
        this.shopitemlistp[i] = wh.add.text(0, 0, '', wh.fs["wingui_plus"]);
      }
      wh.homeinventorycontainer_item.add(this.shopitemlistp[i]);
      wh.homeinventorycontainer_item.add(this.shopitemlistr[i]);
      this.shopitemlistr[i].setPosition(this.shopitemlist[i].x - 16, this.shopitemlist[i].y + 0);

      this.shopitemlistp[i].setPosition(this.shopitemlist[i].x - 27, this.shopitemlist[i].y + 11);
      this.shopitemlist[i].visible = true;
      this.shopitemlistr[i].visible = true;
      this.shopitemlistp[i].visible = true;
      this.shopitemlist[i].setInteractive();
      this.shopitemlist[i].removeAllListeners();
      this.shopitemlist[i].name = '' + vdat['item'] + '-' + i;
      this.shopitemlist[i].on(
        'pointerup',
        function (pointer, localX, localY, event) {
          this.scene.plog.log('shop itemclick this', this);
          this.scene.plog.log('shop itemclick', pointer, localX, localY, event);
          this.scene.registry.list.homeinveni.showinventory_itemclick(
            this.scene,
            this
          );
        }
      );
      cxpos++;
      if (cxpos > 4) {
        cxpos = 0;
        crow++;
      }
      i++;
      if (i % 25 == 0) {
        cxpos = 0;
        crow = 0;
      }
    }


    if (i == 0) {
      this.empty.visible = true;
    } else {
      this.empty.visible = false;
    }


    this.plog.log('shop i count', i);
    if (i > 25) {
      //pages
      this.respperpage = 25;
      this.resptotalpage = Math.ceil(i / 25);
    }
    this.shopbackbtn.removeAllListeners();
    this.shopfwdbtn.removeAllListeners();
    this.shopbackbtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        this.plog.log('shop shopbackbtn', this);
        this.showinventory_showpage(wh, 'back');
      },
      this
    );
    this.shopfwdbtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        this.plog.log('shop shopfwdbtn', this);
        this.showinventory_showpage(wh, 'next');
      },
      this
    );
    this.showinventory_showpage(wh, 'back');
    this.loadinghide();
    this.invendropbtn.removeAllListeners();
    this.invendropbtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        this.plog.log('invendropbtn', this);
        this.showinventory_ret(this);
      },
      this
    );
  }

  async showinventory_ret(wh) {
    this.plog.log('showinventory_ret', wh, Number(this.selecteditemno));
    if (this.selecteditemno == '') return;
    if (
      wh.rcvarsservice.activechar['item_home'][this.selecteditemno] == undefined
    ) {
      this.plog.log(
        'showinventory_ret skip dropped',
        this.selecteditemno,
        wh.rcvarsservice.activechar['item_home'][this.selecteditemno]
      );
      return; //dropped
    }
    var tmpid =
      wh.rcvarsservice.activechar['item_home'][this.selecteditemno]['id'];
    this.loadingshow();
    var res = await this.rchttp.retrieveitem(tmpid);
    await this.rcvarsservice.setactivechar(
      res,
      wh.rcvarsservice.activechar['id']
    );
    this.plog.log('showinventory_ret res', res, wh.rcvarsservice);
    this.rcvarsservice.gameobj.registry.list.gameitf.charinfoupdated(
      this.rcvarsservice.gameobj.scene.scenes[0]
    );
    this.selecteditemno = '';
    this.showinventory_showpage(wh, 'retainpage');
    this.loadinghide();
  }
  showinventory_itemclick(wh, item) {
    this.selectcircle.visible = true;
    var namea = item.name.split('-');
    wh.registry.list.homeinveni.selecteditemno = namea[1];
    this.plog.log('showinventory_itemclick', wh, item);
    this.plog.log(
      'showinventory_itemclick shopitemlist',
      namea,
      this.shopitemlist
    );
    this.selectcircle.displayWidth = 48;
    this.selectcircle.displayHeight = 48;
    this.selectcircle.setPosition(item.x, item.y);
    //wh.homeinventorycontainer_item.sendToBack(wh.shopguibg);
    wh.homeinventorycontainer_item.bringToTop(this.selectcircle);
    wh.homeinventorycontainer_item.bringToTop(this.shopitemlist[namea[1]]);
    wh.homeinventorycontainer_item.bringToTop(this.shopitemlistr[namea[1]]);
    wh.homeinventorycontainer_item.bringToTop(this.shopitemlistp[namea[1]]);
    this.plog.log(
      'showinventory_itemclick selectcircle',
      namea,
      item,
      wh.registry.list.rcvarpass.activechar
    );
    var iteminfo = wh.registry.list.rcvarpass.activechar['item_home'][namea[1]];
    var iteminfo2 = wh.registry.list.rcvarpass.allitem[namea[0]];

    //this.selectcircle.width=
    //this.itemdescr.setText(iteminfo2["name"] + " x" + iteminfo["amnt"]);
    this.itemdescr.setText(iteminfo2['name'] + ' in Home inventory');
    this.rcosd.show(wh, 'itemnoeq', iteminfo2, iteminfo);
  }
  async showinventory_close(wh) {
    wh.homeinventorycontainer_item.visible = false;
    await wh.registry.list.gameitf.closegui(wh, wh.homeinventorycontainer_item);

    for (const k in this.wh.homeinventorycontainer_item.list) {
      try {
        this.wh.homeinventorycontainer_item.list[k].visible=false;
        await this.wh.homeinventorycontainer_item.remove(this.wh.homeinventorycontainer_item.list[k], true);
        //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list[k].destroy();
      } catch (e) {
        this.plog.log("ERROR! homeinventorycontainer_item.destroy() ", e);
      }
    }
    try {
      await this.wh.homeinventorycontainer_item.destroy(true);
    } catch (e) {
      this.plog.log("ERROR! homeinventorycontainer_item.destroy() ", e);
    }
    wh.homeinventorycontainer_item = undefined;
  }
  showinventory_showpage(gc, viewpage) {
    this.selectcircle.visible = false;
    if (viewpage == 'back') {
      if (this.currentresppage > 1) this.currentresppage--;
    }
    if (viewpage == 'next') {
      if (this.currentresppage < this.resptotalpage) this.currentresppage++;
    }
    for (var i = 0; i <= 100; i++) {
      if (this.shopitemlist[i] != undefined) {
        this.shopitemlist[i].visible = false;
      }
      if (this.shopitemlistp[i] != undefined) {
        this.shopitemlistp[i].visible = false;
      }
      if (this.shopitemlistr[i] != undefined) {
        this.shopitemlistr[i].visible = false;
      }
    }
    var numfrom = this.respperpage * (this.currentresppage - 1);
    var numto = this.respperpage * this.currentresppage;
    this.plog.log(
      'showinventory_showpage',
      viewpage,
      this.currentresppage,
      'fromto',
      numfrom,
      numto,
      'perpage',
      this.respperpage
    );

    var griddat = [];
    var griddatf = [];

    griddat.push(this.shopclosebtn);
    griddat.push(this.invendropbtn);

    griddat.push(this.shopfwdbtn);
    griddat.push(this.shopbackbtn);

    for (var i = numfrom; i < numto; i++) {
      if (this.shopitemlist[i] != undefined) {
        this.shopitemlist[i].visible = true;
        this.shopitemlistp[i].visible = true;
        this.shopitemlistr[i].visible = true;

        griddat.push(this.shopitemlist[i]);
        griddatf.push(this.shopitemlist[i]);
        this.wh.registry.list.gp.guigrid(griddat, griddatf);

        //this.plog.log('xxx1', this.shopitemlist[i]);
      }
    }
    this.wh.registry.list.gp.guigrid(griddat, griddatf);

  }

  async equip(wh) {
    this.plog.log('rcinventory equip', wh, this);
    var res = await this.rchttp.equip(wh['id']);
    await this.rcvarsservice.setactivechar(
      res,
      this.rcvarsservice.activechar['id']
    );
    //this.plog.log("rcinventory equip", this);
    await this.rcvarsservice.gameobj.registry.list.gameitf.charinfoupdated(
      this.rcvarsservice.gameobj.scene.scenes[0]
    );
  }

  rebuildguigrid() {
    this.plog.log("rebuildguigrid();b", this.wh.kbfocus);
    this.showinventory_showpage(null, "");
    this.plog.log("rebuildguigrid();a", this.wh.kbfocus);
  }
}
