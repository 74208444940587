import { Injectable } from '@angular/core';
import { plog } from './plog.service';

@Injectable({
  providedIn: 'root',
})
export class ShowRewardService {
  wh;
  rewardtext;
  tmpbg;
  tmpbg2;
  clicktoclose;
  showrewardcontainer;
  showrewardcontainer_sub;

  effwithtxt_sprite;
  effwithtxt_spriteo;
  effwithtxt_txt;

  config;
  usesingletextshow = false;
  rwicon = [];
  rwtext = [];
  plog;
  containerlist = [];

  constructor() {
    this.plog = new plog();
  }
  boxani(box, cc) {
    //this.plog.error(box,cc);
    var origsize = box.displayWidth;
    box.displayWidth = box.displayWidth * 0.5;
    box.displayHeight = box.displayHeight * 0.5;
    var tw = this.wh.tweens.add({
      targets: box,
      displayWidth: origsize,
      displayHeight: origsize,
      ease: 'Bounce',
      duration: 500, // duration of animation; higher=slower
      delay: cc * 60,
    });
    tw.play();
  }
  async show(wh, reward, config = {}) {
    this.wh = wh;
    this.wh.kbfocuslv1 = "showreward";
    this.usesingletextshow = false;
    this.wh.plog.warn("show-reward show(),", this.wh.kbfocus, this.wh);

    if (reward == undefined) {
      this.plog.error("show-reward show(), reward undefined");
      return;
    }

    this.config = config;
    if (this.config['windowmode'] != undefined && (this.config['windowmode'] == 4 || this.config['windowmode'] == 9 || this.config['windowmode'] == 16)) {
      wh.showrewardcontainer = wh.add.container(0, 0);
      wh.showrewardcontainer_sub = wh.add.container(0, 0);
    } else {
      if (wh.showrewardcontainer == undefined) {
        wh.showrewardcontainer = wh.add.container(0, 0);
      }
      if (wh.showrewardcontainer_sub == undefined) {
        wh.showrewardcontainer_sub = wh.add.container(0, 0);
      }
    }
    this.showrewardcontainer = wh.showrewardcontainer;
    this.showrewardcontainer_sub = wh.showrewardcontainer_sub;
    this.containerlist.push(wh.showrewardcontainer);
    this.containerlist.push(wh.showrewardcontainer_sub);

    wh.showrewardcontainer.setAlpha(1);
    wh.showrewardcontainer_sub.setAlpha(1);

    var bg = this.config['bg'];
    if (bg == undefined) bg = 'rewardbg';
    if (this.config['text'] == undefined) this.config['text'] = 'Reward';
    this.config["text"] = this.wh.gl(this.config["text"]);
    //this.plog.log('ShowRewardService show', wh, reward, bg);
    if (bg == "rewardbg" || bg == "combatwin") {
      this.wh.registry.list.snd.snd("combatwin");
    } else {
      this.wh.registry.list.snd.snd("combatlose");
    }
    //resultscreen
    var width = this.wh.game.canvas.width;
    var height = this.wh.game.canvas.height;
    this.tmpbg = this.wh.add.image(0, 0, bg);
    this.tmpbg.setOrigin(0.5, 0.5);
    this.tmpbg.setInteractive();
    this.tmpbg.setDepth(5000000);
    this.tmpbg.setPosition(width / 2, height / 2);

    if (width > height) {
      this.tmpbg.displayWidth = width + 5;
      this.tmpbg.scaleY = this.tmpbg.scaleX;
    } else {
      this.tmpbg.displayHeight = height + 5;
      this.tmpbg.scaleX = this.tmpbg.scaleY;
    }
    this.tmpbg.displayWidth = width + 5;
    this.tmpbg.displayHeight = height + 5;


    this.tmpbg.on(
      'pointerup',
      function () {
        this.show_close();
      },
      this
    );
    this.tmpbg.setAlpha(0);
    var tw = this.wh.tweens.add({
      targets: this.tmpbg,
      alpha: 1,
      ease: 'Power3',
      duration: 500, // duration of animation; higher=slower
      delay: 0, // wait 500 ms before starting
      onComplete: function () {
        //this.visible=false;
      }, // set context? how?
    });

    this.tmpbg2 = this.wh.add.image(300, 300, 'flare0');
    this.tmpbg2.setOrigin(0.5, 0.5);
    this.tmpbg2.setDepth(5000000);
    this.tmpbg2.setPosition(width / 2, height / 2);
    if (width > height) {
      this.tmpbg2.displayWidth = width + 5;
      this.tmpbg2.scaleY = this.tmpbg2.scaleX;
    } else {
      this.tmpbg2.displayHeight = height + 5;
      this.tmpbg2.scaleX = this.tmpbg2.scaleY;
    }
    if (this.config.light == false) this.tmpbg2.visible = false;
    //this.tmpbg2.setBlendMode(Phaser.BlendModes.COLOR_BURN);
    var tw2 = this.wh.tweens.add({
      targets: this.tmpbg2,
      angle: 359,
      ease: 'Linear',
      duration: 5000, // duration of animation; higher=slower
      delay: 0,
      repeat: -1,
    }); //this.plog.log("xxxx",this);

    this.rewardtext = this.wh.add.text(0, 0, this.config['text'], this.wh.fs["showreward_victory"]);
    this.rewardtext.setPosition(
      width / 2 - this.rewardtext.displayWidth / 2,
      3
    );
    this.rewardtext.setDepth(5000001);

    this.clicktoclose = this.wh.add.text(0, 0, this.wh.trs("f:showrewardcontainer:Click anywhere to close===Click anywhere to close"), this.wh.fs["showreward_clicktoclose"]);

    this.clicktoclose.setWordWrapWidth(width - 150)
    this.clicktoclose.setPosition((width / 2) - (this.clicktoclose.displayWidth / 2), height - this.clicktoclose.displayHeight);

    this.clicktoclose.setDepth(5000002);
    this.clicktoclose.visible = true;

    if (this.config["windowmode"] != undefined) {
      this.clicktoclose.visible = false;
    }
    this.showrewardcontainer.add(this.clicktoclose);
    this.showrewardcontainer.sendToBack(this.clicktoclose);
    this.showrewardcontainer.add(this.tmpbg2);
    this.showrewardcontainer.sendToBack(this.tmpbg2);
    this.showrewardcontainer.add(this.tmpbg);
    this.showrewardcontainer.sendToBack(this.tmpbg);
    this.showrewardcontainer.add(this.rewardtext);
    this.showrewardcontainer.bringToTop(this.rewardtext);

    var toppos = 0; //120;//(height / 2) - 35;
    var leftpos = 0; //width * 0.1;
    var ic = 0;
    var iconwh = 64;
    var iconmargin = 5;
    var showsharereward = false;

    if (reward.length <= 6) {
      var iconwh = 128;
      var iconmargin = 10;
    }
    if (reward.length <= 12) {
      var iconwh = 96;
      var iconmargin = 10;
    }
    var curx = 0;
    var cury = 0;
    var maxperrow = (width * 0.8) / (iconwh + iconmargin);
    var fonticonstyle = {
      fontSize: '12px',
      color: '#fff',
      stroke: '#000',
      strokeThickness: 2,
      wordWrap: true,
      wordWrapWidth: 60,
    };
    var fonticonstylered = {
      fontSize: '12px',
      color: '#f00',
      stroke: '#000',
      strokeThickness: 2,
      wordWrap: true,
      wordWrapWidth: 60,
    };
    var fontypos = iconwh / 2;
    var fontxpos = 0 - iconwh / 2 + 4;
    for (const k in reward) {
      //this.plog.log('showreward', ic, va, 'maxperrow', maxperrow);
      //this.plog.log('showreward row', curx, cury);

      var v = reward[k];
      var va = v.split(':');
      if (va[0] == 'sharerewarddsp' && this.config["windowmode"] == undefined) {

        showsharereward = true;
        var tmp = this.wh.add.sprite(0, 0, "thesq", 'share');
        tmp.displayWidth = tmp.displayHeight = iconwh / 2;
        this.showrewardcontainer.add(tmp);
        this.showrewardcontainer.bringToTop(tmp);
        tmp.setOrigin(0.5, 0.5);
        tmp.setPosition(width / 2, height - (iconwh + 20));
        tmp.setInteractive();
        tmp.on('pointerup', function () {
          this.plog.log('showreward this', this);
          this.plog.log('showreward arguments', arguments);
          //this.scene.registry.list.rctoast.rctoast(this.name);
          this.show_close();
          this.wh.registry.list.execmod.exec("sharecombatforreward", this.wh);
        }, this);

        var shine1 = this.wh.add.sprite(100, 100, "shinebox", "shinebox_b");
        shine1.play("shinebox_b");
        shine1.displayWidth = shine1.displayHeight = iconwh / 2;
        this.showrewardcontainer.add(shine1);
        this.showrewardcontainer.bringToTop(shine1);
        shine1.setOrigin(0.5, 0.5);
        shine1.setPosition(tmp.x, tmp.y);

        var adddetail = this.wh.trs("f:showrewardcontainer:Share to get winners reward again===Share to get winners reward again") + "";
        var tmpt = this.wh.add.text(0, 0, '' + adddetail + '' + va[1], this.wh.fs["showreward_amnt"]);
        this.showrewardcontainer.add(tmpt);
        this.showrewardcontainer.bringToTop(tmpt);
        tmpt.setOrigin(0.5, 0);
        tmpt.setPosition(width / 2, (tmp.y + (tmp.displayHeight / 2)) - 5);

        continue;
      }
      if (va[0] == 'showcombatredo' && this.config["windowmode"] == undefined) {
        this.plog.log("showreward found showcombatredo");
        //const fx =tmp.postFX.addShine(1, .2, 5);

        var tmp = this.wh.add.sprite(0, 0, "stackitem_tp", 'combatredo');
        tmp.displayWidth = tmp.displayHeight = iconwh;

        this.showrewardcontainer.add(tmp);
        this.showrewardcontainer.bringToTop(tmp);
        tmp.name = '' + adddetail + " with StackItem Combat Renumdo";
        tmp.setOrigin(1, 0);
        tmp.setPosition(width - 10, 10);
        tmp.setInteractive();
        tmp.on('pointerup', function () {
          this.plog.log('showreward this', this);
          this.plog.log('showreward arguments', arguments);
          //this.scene.registry.list.rctoast.rctoast(this.name);
          this.show_close();
          this.wh.registry.list.execmod.exec("redocombat", this.wh);
        }, this);

        var shine1 = this.wh.add.sprite(100, 100, "shinebox", "shinebox_b");
        shine1.play("shinebox_b");
        shine1.displayWidth = shine1.displayHeight = iconwh;
        this.showrewardcontainer.add(shine1);
        this.showrewardcontainer.bringToTop(shine1);
        shine1.setOrigin(0.5, 0.5);
        shine1.setPosition(tmp.x - (iconwh / 2), tmp.y + (iconwh / 2));

        adddetail = "REDO COMBAT";
        var tmpt = this.wh.add.text(0, 0, '' + adddetail + '', this.wh.fs["showreward_amnt"]);
        this.showrewardcontainer.add(tmpt);
        this.showrewardcontainer.bringToTop(tmpt);
        tmpt.setOrigin(1, 0);
        tmpt.setPosition(width - 10, iconwh + 0);
        //console.log(this.config);
        if (this.config['combatredocount'] > 4) {
          var tmpt4 = this.wh.add.text(0, 0, 'REDO x4', this.wh.fs["showreward_amnt"]);
          this.showrewardcontainer.add(tmpt4);
          this.showrewardcontainer.bringToTop(tmpt4);
          tmpt4.setOrigin(1, 0);
          tmpt4.setPosition(width - 10, iconwh + 20);

          tmpt4.setInteractive();
          tmpt4.on('pointerup', function () {
            this.plog.log('showreward tmpt4 this', this);
            this.plog.log('showreward tmpt4 arguments', arguments);
            //this.scene.registry.list.rctoast.rctoast(this.name);
            this.show_close();
            this.wh.registry.list.execmod.exec("redocombatx4", this.wh);
          }, this);
        }
        if (this.config['combatredocount'] > 9) {
          var tmpt9 = this.wh.add.text(0, 0, 'REDO x9', this.wh.fs["showreward_amnt"]);
          this.showrewardcontainer.add(tmpt9);
          this.showrewardcontainer.bringToTop(tmpt9);
          tmpt9.setOrigin(1, 0);
          tmpt9.setPosition(width - 10, iconwh + 40);

          tmpt9.setFontSize("14px");
          tmpt9.setInteractive();
          tmpt9.on('pointerup', function () {
            this.plog.log('showreward tmpt9 this', this);
            this.plog.log('showreward tmpt9 arguments', arguments);
            //this.scene.registry.list.rctoast.rctoast(this.name);
            this.show_close();
            this.wh.registry.list.execmod.exec("redocombatx9", this.wh);
          }, this);
        }
        if (this.config['combatredocount'] > 16) {
          var tmpt16 = this.wh.add.text(0, 0, 'REDO x16', this.wh.fs["showreward_amnt"]);
          this.showrewardcontainer.add(tmpt16);
          this.showrewardcontainer.bringToTop(tmpt16);
          tmpt16.setOrigin(1, 0);
          tmpt16.setPosition(width - 10, iconwh + 62);
          tmpt16.setFontSize("16px");
          tmpt16.setInteractive();
          tmpt16.on('pointerup', function () {
            this.plog.log('showreward tmpt16 this', this);
            this.plog.log('showreward tmpt16 arguments', arguments);
            //this.scene.registry.list.rctoast.rctoast(this.name);
            this.show_close();
            this.wh.registry.list.execmod.exec("redocombatx16", this.wh);
          }, this);
        }
        continue;
      }

      ic++;
      var usetoppos = toppos + (iconwh + iconmargin) * cury;
      var useleftpos = leftpos + (iconwh + iconmargin) * curx;

      if (va[0] == 'text') {
        this.usesingletextshow = true;
        var tmp = this.wh.add.sprite(0, 0, "thesq", '' + va[1]);
        tmp.displayWidth = tmp.displayHeight = iconwh;
        this.boxani(tmp, ic);
        this.showrewardcontainer_sub.add(tmp);
        this.showrewardcontainer_sub.bringToTop(tmp);
        adddetail = "";

        adddetail = " " + va[2];
        var tmpt = this.wh.add.text(0, 0, '' + adddetail + '', this.wh.fs["showreward_bigtext"]);
        tmp.name = ' ' + adddetail;

        this.showrewardcontainer_sub.add(tmpt);
        this.showrewardcontainer_sub.bringToTop(tmpt);
        tmp.setOrigin(0.5, 0.5);
        tmpt.setOrigin(0.5, 0.5);
        tmp.setPosition(width / 2, height / 2);
        var bigtextw = 500;
        if (bigtextw > width * 0.9) bigtextw = width * 0.9;
        tmpt.setWordWrapWidth(bigtextw);
        tmpt.setPosition(width / 2, height / 2);
        this.showrewardcontainer_sub.setPosition(0, 0);
        tmpt.setWordWrapWidth(bigtextw);
        this.plog.log("text", tmp, tmpt);

      }
      if (va[0] == 'xp') {
        var tmp = this.wh.add.sprite(0, 0, "thesq", 'xp');
        tmp.displayWidth = tmp.displayHeight = iconwh;
        this.boxani(tmp, ic);
        this.showrewardcontainer_sub.add(tmp);
        this.showrewardcontainer_sub.bringToTop(tmp);
        var adddetail = "";
        if (va[2] != undefined) {
          adddetail = " " + va[2];
          var tmpt = this.wh.add.text(0, 0, '' + this.wh.registry.list.gameitf.humannumber(va[1], 0) + '', this.wh.fs["showreward_amnt"]);
          tmp.name = 'XP: ' + this.wh.registry.list.gameitf.humannumber(va[1], 0) + adddetail;

        } else {
          if ((va[1] + "").length > 12) {
            var tmpt = this.wh.add.text(0, 0, '' + va[1] + '', this.wh.fs["showreward_amnt_s"]);
          } else {
            var tmpt = this.wh.add.text(0, 0, '' + va[1] + '', this.wh.fs["showreward_amnt"]);
          }
          tmp.name = 'XP: ' + va[1] + adddetail;

        }
        this.showrewardcontainer_sub.add(tmpt);
        this.showrewardcontainer_sub.bringToTop(tmpt);
        tmp.setPosition(useleftpos, usetoppos);
        tmpt.setWordWrapWidth(iconwh);
        tmpt.setOrigin(0, 1);
        tmpt.setPosition(useleftpos + fontxpos, usetoppos + fontypos);
        tmpt.setWordWrapWidth(iconwh);
        //this.plog.log("xp", tmp, tmpt);
        tmp.setInteractive();
        tmp.on('pointerdown', function () {
          this.scene.plog.log('showreward this', this);
          this.scene.plog.log('showreward arguments', arguments);
          this.scene.registry.list.rctoast.rctoast(this.scene, this.name);
        });
      }
      if (va[0] == '-xp') {
        var tmp = this.wh.add.sprite(0, 0, "thesq", 'xp');
        tmp.displayWidth = tmp.displayHeight = iconwh;
        this.boxani(tmp, ic);
        this.showrewardcontainer_sub.add(tmp);
        this.showrewardcontainer_sub.bringToTop(tmp);
        var tmpt = this.wh.add.text(0, 0, '-' + this.wh.registry.list.gameitf.humannumber(va[1], 0) + '', this.wh.fs["showreward_itemtextred"]);
        this.showrewardcontainer_sub.add(tmpt);
        this.showrewardcontainer_sub.bringToTop(tmpt);
        tmp.setPosition(useleftpos, usetoppos);
        tmpt.setWordWrapWidth(iconwh);
        tmpt.setOrigin(0, 1);
        tmpt.setPosition(useleftpos + fontxpos, usetoppos + fontypos);
        tmpt.setWordWrapWidth(iconwh);
        //this.plog.log("xp", tmp, tmpt);
        tmp.setInteractive();
        tmp.name = 'Lose XP: ' + this.wh.registry.list.gameitf.humannumber(va[1], 0);
        tmp.on('pointerdown', function () {
          this.scene.plog.log('showreward this', this);
          this.scene.plog.log('showreward arguments', arguments);
          this.scene.registry.list.rctoast.rctoast(this.scene, this.name);
        });
      }
      if (va[0] == 'ki') {
        var tmp = this.wh.add.sprite(0, 0, "thesq", 'ki');
        tmp.displayWidth = tmp.displayHeight = iconwh;
        this.boxani(tmp, ic);
        this.showrewardcontainer_sub.add(tmp);
        this.showrewardcontainer_sub.bringToTop(tmp);
        var tmpt = this.wh.add.text(0, 0, '' + this.wh.registry.list.gameitf.humannumber(va[1], 0) + '', this.wh.fs["showreward_amnt"]);
        this.showrewardcontainer_sub.add(tmpt);
        this.showrewardcontainer_sub.bringToTop(tmpt);
        tmp.setPosition(useleftpos, usetoppos);
        tmpt.setWordWrapWidth(iconwh);
        tmpt.setOrigin(0, 1);
        tmpt.setPosition(useleftpos + fontxpos, usetoppos + fontypos);
        tmpt.setWordWrapWidth(iconwh);

        var adddetail = "";
        if (va[2] != undefined) adddetail = " " + va[2];
        tmp.name = 'KI: ' + this.wh.registry.list.gameitf.humannumber(va[1], 0) + adddetail;
        //this.plog.log("ki", tmp, tmpt);
        tmp.setInteractive();
        tmp.on('pointerdown', function () {
          this.scene.plog.log('showreward this', this);
          this.scene.plog.log('showreward arguments', arguments);
          this.scene.registry.list.rctoast.rctoast(this.scene, this.name);
        });
      }
      if (va[0] == 'stackitem') {
        var tmp = this.wh.add.sprite(0, 0, 'stackitem_tp', va[1]);
        tmp.displayWidth = tmp.displayHeight = iconwh;
        this.boxani(tmp, ic);
        this.showrewardcontainer_sub.add(tmp);
        this.showrewardcontainer_sub.bringToTop(tmp);
        var tmpt = this.wh.add.text(0, 0, '' + va[2] + '', this.wh.fs["showreward_amnt"]);
        this.showrewardcontainer_sub.add(tmpt);
        this.showrewardcontainer_sub.bringToTop(tmpt);
        tmp.setPosition(useleftpos, usetoppos);
        tmpt.setWordWrapWidth(iconwh);
        tmpt.setOrigin(0, 1);
        tmpt.setPosition(useleftpos + fontxpos, usetoppos + fontypos);
        //this.plog.log("xp", tmp, tmpt);
        var iteminfo = this.wh.registry.list.rcvarpass.allstackitem[va[1]];
        //this.plog.log("showreward stackitem iteminfo", iteminfo);
        tmp.setInteractive();
        if (iteminfo == undefined) {
          tmp.name = '??';
        } else {
          tmp.name = iteminfo['name'];
        }
        tmp.on('pointerdown', function () {
          //this.plog.log('showreward this', this);
          //this.plog.log('showreward arguments', arguments);
          this.scene.registry.list.rctoast.rctoast(this.scene, this.name);
        });
      }
      if (va[0] == '-stackitem') {
        var tmp = this.wh.add.sprite(0, 0, 'stackitem_tp', va[1]);
        tmp.displayWidth = tmp.displayHeight = iconwh;
        this.boxani(tmp, ic);
        this.showrewardcontainer_sub.add(tmp);
        this.showrewardcontainer_sub.bringToTop(tmp);
        var tmpt = this.wh.add.text(0, 0, '-' + va[2] + '', this.wh.fs["showreward_itemtextred"]);
        this.showrewardcontainer_sub.add(tmpt);
        this.showrewardcontainer_sub.bringToTop(tmpt);
        tmp.setPosition(useleftpos, usetoppos);
        tmpt.setWordWrapWidth(iconwh);
        tmpt.setOrigin(0, 1);
        tmpt.setPosition(useleftpos + fontxpos, usetoppos + fontypos);
        //this.plog.log("xp", tmp, tmpt);
        var iteminfo = this.wh.registry.list.rcvarpass.allstackitem[va[1]];
        tmp.setInteractive();
        tmp.name = 'Lose (dropped): ' + iteminfo['name'] + ' x' + va[2];
        tmp.on('pointerdown', function () {
          //this.plog.log('showreward this', this);
          //this.plog.log('showreward arguments', arguments);
          this.scene.registry.list.rctoast.rctoast(this.scene, this.name);
        });
      }
      if (va[0] == 'item') {
        var tmp = this.wh.add.sprite(0, 0, 'item_tp', va[1]);
        tmp.displayWidth = tmp.displayHeight = iconwh;
        this.boxani(tmp, ic);
        this.showrewardcontainer_sub.add(tmp);
        this.showrewardcontainer_sub.bringToTop(tmp);
        var iteminfo = this.wh.registry.list.rcvarpass.allitem[va[1]];
        var tmpt = this.wh.add.text(
          0,
          0,
          '' + iteminfo['name'] + '',
          this.wh.fs["showreward_itemtext"]
        );
        this.showrewardcontainer_sub.add(tmpt);
        this.showrewardcontainer_sub.bringToTop(tmpt);
        tmp.setPosition(useleftpos, usetoppos);
        tmpt.setWordWrapWidth(iconwh);
        tmpt.setOrigin(0, 1);
        tmpt.setPosition(useleftpos + fontxpos, usetoppos + fontypos);
        //this.plog.log("xp", tmp, tmpt);
        tmp.setInteractive();
        tmp.name = iteminfo['name'];
        tmp.on('pointerdown', function () {
          this.scene.plog.log('showreward this', this);
          this.scene.plog.log('showreward arguments', arguments);
          this.scene.registry.list.rctoast.rctoast(this.scene, this.name);
        });
      }
      if (va[0] == '-item') {
        var tmp = this.wh.add.sprite(0, 0, 'item_tp', va[1]);
        tmp.displayWidth = tmp.displayHeight = iconwh;
        this.boxani(tmp, ic);
        this.showrewardcontainer_sub.add(tmp);
        this.showrewardcontainer_sub.bringToTop(tmp);
        var iteminfo = this.wh.registry.list.rcvarpass.allitem[va[1]];
        var tmpt = this.wh.add.text(
          0,
          0,
          '' + iteminfo['name'] + '',
          this.wh.fs["showreward_itemtextred"]
        );
        this.showrewardcontainer_sub.add(tmpt);
        this.showrewardcontainer_sub.bringToTop(tmpt);
        tmp.setPosition(useleftpos, usetoppos);
        tmpt.setWordWrapWidth(iconwh);
        tmpt.setOrigin(0, 1);
        tmpt.setPosition(useleftpos + fontxpos, usetoppos + fontypos);
        //this.plog.log("xp", tmp, tmpt);
        tmp.setInteractive();
        tmp.name = 'Lose (dropped): ' + iteminfo['name'];
        tmp.on('pointerdown', function () {
          this.scene.plog.log('showreward this', this);
          this.scene.plog.log('showreward arguments', arguments);
          this.scene.registry.list.rctoast.rctoast(this.scene, this.name);
        });
      }
      if (va[0] == 'mob') {
        var tmp = this.wh.add.sprite(0, 0, 'mob', va[1]);
        tmp.displayWidth = iconwh;
        this.boxani(tmp, ic);
        tmp.scaleY = tmp.scaleX;
        this.showrewardcontainer_sub.add(tmp);
        this.showrewardcontainer_sub.bringToTop(tmp);
        var iteminfo = this.wh.registry.list.rcvarpass.allmob[va[1]];
        var tmpt = this.wh.add.text(
          0,
          0,
          '' + iteminfo['name'] + '',
          this.wh.fs["showreward_itemtext"]
        );
        this.showrewardcontainer_sub.add(tmpt);
        this.showrewardcontainer_sub.bringToTop(tmpt);
        tmp.setPosition(useleftpos, usetoppos);
        tmpt.setWordWrapWidth(iconwh);
        tmpt.setOrigin(0, 1);
        tmpt.setPosition(useleftpos + fontxpos, usetoppos + fontypos);
        //this.plog.log("xp", tmp, tmpt);
        tmp.setInteractive();
        tmp.name = iteminfo['name'];
        tmp.on('pointerdown', function () {
          this.scene.plog.log('showreward this', this);
          this.scene.plog.log('showreward arguments', arguments);
          this.scene.registry.list.rctoast.rctoast(this.scene, this.name);
        });
      }
      if (va[0] == 'skill') {
        console.log(this.wh);
        var tmp = this.wh.add.sprite(0, 0, 'skill_tp', va[1]);
        tmp.displayWidth = iconwh;
        this.boxani(tmp, ic);
        tmp.scaleY = tmp.scaleX;
        this.showrewardcontainer_sub.add(tmp);
        this.showrewardcontainer_sub.bringToTop(tmp);
        var iteminfo = this.wh.registry.list.rcvarpass.skilltp[va[1]];
        var tmpt = this.wh.add.text(
          0,
          0,
          '' + iteminfo['name'] + '',
          this.wh.fs["showreward_itemtext"]
        );
        this.showrewardcontainer_sub.add(tmpt);
        this.showrewardcontainer_sub.bringToTop(tmpt);
        tmp.setPosition(useleftpos, usetoppos);
        tmpt.setWordWrapWidth(iconwh);
        tmpt.setOrigin(0, 1);
        tmpt.setPosition(useleftpos + fontxpos, usetoppos + fontypos);
        //this.plog.log("xp", tmp, tmpt);
        tmp.setInteractive();
        tmp.name = iteminfo['name'];
        tmp.on('pointerdown', function () {
          this.scene.plog.log('showreward this', this);
          this.scene.plog.log('showreward arguments', arguments);
          this.scene.registry.list.rctoast.rctoast(this.scene, this.name);
        });
      }
      if (va[0] == 'msgred') {
        var tmp = this.wh.add.sprite(0, 0, "thesq", 'msgred');
        var useicon = false;
        if (va[1] == 'Inventory Full') {
          tmp.setTexture("thesq", 'inventoryfull');
          useicon = true;
        }
        if (va[1] == 'Stack Inventory Full') {
          tmp.setTexture("thesq", 'stackinventoryfull');
          useicon = true;
        }
        if (va[1] == 'StackInventory Full') {
          tmp.setTexture("thesq", 'stackinventoryfull');
          useicon = true;
        }
        tmp.displayWidth = tmp.displayHeight = iconwh;
        this.boxani(tmp, ic);
        this.showrewardcontainer_sub.add(tmp);
        this.showrewardcontainer_sub.bringToTop(tmp);
        tmp.setPosition(useleftpos, usetoppos);
        //this.plog.log("msgred", tmp, tmpt);

        if (!useicon) {

          if ((va[1] + "").length > 12) {
            var tmpt = this.wh.add.text(0, 0, '' + va[1] + '', this.wh.fs["showreward_itemtext_s"]);
          } else {
            var tmpt = this.wh.add.text(0, 0, '' + va[1] + '', this.wh.fs["showreward_itemtext"]);
          }

          this.showrewardcontainer_sub.add(tmpt);
          this.showrewardcontainer_sub.bringToTop(tmpt);
          tmpt.setWordWrapWidth(iconwh);
          tmpt.setOrigin(0, 1);
          tmpt.setPosition(useleftpos + fontxpos, usetoppos + fontypos);
          //this.plog.log("showreward msgred txt", tmpt);
        }
        tmp.name = va[1];
        tmp.setInteractive();
        tmp.on('pointerdown', function () {
          this.scene.plog.log('showreward this', this);
          this.scene.plog.log('showreward arguments', arguments);
          this.scene.registry.list.rctoast.rctoast(this.scene, this.name);
        });
      }

      if (va[0] == 'msgorange') {
        var tmp = this.wh.add.sprite(0, 0, "thesq", 'msgorange');
        var useicon = false;
        if (va[1] == 'Inventory Full') {
          tmp.setTexture("thesq", 'invenfullicon');
          useicon = true;
        }
        if (va[1] == 'Stack Inventory Full') {
          tmp.setTexture("thesq", 'stackinvenfullicon');
          useicon = true;
        }
        if (va[1] == 'StackInventory Full') {
          tmp.setTexture("thesq", 'stackinvenfullicon');
          useicon = true;
        }

        tmp.displayWidth = tmp.displayHeight = iconwh;
        this.boxani(tmp, ic);
        this.showrewardcontainer_sub.add(tmp);
        this.showrewardcontainer_sub.bringToTop(tmp);
        tmp.setPosition(useleftpos, usetoppos);
        //this.plog.log("msgred", tmp, tmpt);
        if (!useicon) {

          if ((va[1] + "").length > 12) {
            var tmpt = this.wh.add.text(0, 0, '' + va[1] + '', this.wh.fs["showreward_itemtext_s"]);
          } else {
            var tmpt = this.wh.add.text(0, 0, '' + va[1] + '', this.wh.fs["showreward_itemtext"]);
          }
          this.showrewardcontainer_sub.add(tmpt);
          this.showrewardcontainer_sub.bringToTop(tmpt);
          tmpt.setWordWrapWidth(iconwh);
          tmpt.setOrigin(0, 1);
          tmpt.setPosition(useleftpos + fontxpos, usetoppos + fontypos);
          //this.plog.log("showreward msgorange txt", tmpt);
        }
        tmp.name = va[1];
        tmp.setInteractive();
        tmp.on('pointerdown', function () {
          this.scene.plog.log('showreward this', this);
          this.scene.plog.log('showreward arguments', arguments);
          this.scene.registry.list.rctoast.rctoast(this.scene, this.name);
        });
      }

      if (va[0] == 'msgblack') {
        var tmp = this.wh.add.sprite(0, 0, "thesq", 'msgblack');
        var useicon = false;
        if (va[1] == 'Inventory Full') {
          tmp.setTexture("thesq", 'invenfullicon');
          useicon = true;
        }
        if (va[1] == 'Stack Inventory Full') {
          tmp.setTexture("thesq", 'stackinvenfullicon');
          useicon = true;
        }
        if (va[1] == 'StackInventory Full') {
          tmp.setTexture("thesq", 'stackinvenfullicon');
          useicon = true;
        }

        tmp.displayWidth = tmp.displayHeight = iconwh;
        this.boxani(tmp, ic);
        this.showrewardcontainer_sub.add(tmp);
        this.showrewardcontainer_sub.bringToTop(tmp);
        tmp.setPosition(useleftpos, usetoppos);
        //this.plog.log("msgred", tmp, tmpt);
        if (!useicon) {
          if ((va[1] + "").length > 12) {
            var tmpt = this.wh.add.text(0, 0, '' + va[1] + '', this.wh.fs["showreward_itemtext_s"]);
          } else {
            var tmpt = this.wh.add.text(0, 0, '' + va[1] + '', this.wh.fs["showreward_itemtext"]);
          }
          this.showrewardcontainer_sub.add(tmpt);
          this.showrewardcontainer_sub.bringToTop(tmpt);
          tmpt.setWordWrapWidth(iconwh);
          tmpt.setOrigin(0, 1);
          tmpt.setPosition(useleftpos + fontxpos, usetoppos + fontypos);
          //this.plog.log("showreward msgorange txt", tmpt);
        }
        tmp.name = va[1];
        tmp.setInteractive();
        tmp.on('pointerdown', function () {
          this.scene.plog.log('showreward this', this);
          this.scene.plog.log('showreward arguments', arguments);
          this.scene.registry.list.rctoast.rctoast(this.scene, this.name);
        });
      }

      curx = curx + 1;
      if (curx > maxperrow) {
        curx = 0;
        cury = cury + 1;
      }
      /*
      if (va[0] == "mob") {
        this.plog.log("reward mob", va);
        var iteminfo = this.wh.registry.list.rcvarpass.allmob[va[1]];
        this.rwtext[ic] = this.wh.add.text(0, 0, "Pet: " + iteminfo["name"] + " ", { fontFamily: 'ffff', fontSize: '20px', color: '#fff' });
        this.rwtext[ic].setStroke('#2d2d2d', 6);
        this.rwtext[ic].setShadow(4, 4, "#000000", 2, false, true);
        this.rwtext[ic].setPosition((width / 2) - (this.rwtext[ic].displayWidth / 2), usetoppos);
        this.rwtext[ic].setDepth(200100);
        //icon
        ic = ic + 3;
        this.rwicon[ic] = this.wh.registry.list.rcvarpass.gameobj.scene.scenes[0].add.sprite(100, 100, "mob", va[1]);
        this.rwicon[ic].setOrigin(0.5, 0.5);
        this.rwicon[ic].setPosition((width / 2), usetoppos + rowheight + rowheight);
        this.rwicon[ic].displayWidth = this.rwicon[ic].displayHeight = ((rowheight * 3) + 4);
        this.rwicon[ic].setDepth(200101);
      }
      if (va[0] == "item") {
        var iteminfo = this.wh.registry.list.rcvarpass.allitem[va[1]];
        this.rwtext[ic] = this.wh.add.text(0, 0, "Item: " + iteminfo["name"] + " ", { fontFamily: 'ffff', fontSize: '20px', color: '#fff' });
        this.rwtext[ic].setStroke('#2d2d2d', 6);
        this.rwtext[ic].setShadow(4, 4, "#000000", 2, false, true);
        this.rwtext[ic].setPosition((width / 2) - (this.rwtext[ic].displayWidth / 2), usetoppos);
        this.rwtext[ic].setDepth(200100);
        //icon
        this.rwicon[ic] = this.wh.registry.list.rcvarpass.gameobj.scene.scenes[0].add.sprite(100, 100, "item_tp", va[1]);
        this.rwicon[ic].setPosition((width / 2) - ((this.rwtext[ic].displayWidth / 2) + rowheight), usetoppos);
        this.rwicon[ic].displayWidth = this.rwicon[ic].displayHeight = (rowheight + 4);
        this.rwicon[ic].setOrigin(0, 0);
        this.rwicon[ic].setDepth(200100);
      }

      if (va[0] == "stackitem") {
        var iteminfo = this.wh.registry.list.rcvarpass.allstackitem[va[1]];
        this.rwtext[ic] = this.wh.add.text(0, 0, "StackItem: " + iteminfo["name"] + " x" + va[2], { fontFamily: 'ffff', fontSize: '20px', color: '#fff' });
        this.rwtext[ic].setStroke('#2d2d2d', 6);
        this.rwtext[ic].setShadow(4, 4, "#000000", 2, false, true);
        this.rwtext[ic].setPosition((width / 2) - (this.rwtext[ic].displayWidth / 2), usetoppos);
        this.rwtext[ic].setDepth(200100);
        this.rwicon[ic] = this.wh.registry.list.rcvarpass.gameobj.scene.scenes[0].add.sprite(100, 100, "stackitem_tp", va[1]);
        this.rwicon[ic].setPosition((width / 2) - ((this.rwtext[ic].displayWidth / 2) + rowheight), usetoppos);
        this.rwicon[ic].displayWidth = this.rwicon[ic].displayHeight = (rowheight + 4);
        this.rwicon[ic].setOrigin(0, 0);
        this.rwicon[ic].setDepth(200100);
      }

      if (va[0] == "xp") {
        this.rwtext[ic] = this.wh.add.text(0, 0, "XP+" + va[1], { fontFamily: 'ffff', fontSize: '20px', color: '#fff' });
        this.rwtext[ic].setStroke('#2d2d2d', 6);
        this.rwtext[ic].setShadow(4, 4, "#000000", 2, false, true);
        this.rwtext[ic].setPosition((width / 2) - (this.rwtext[ic].displayWidth / 2), usetoppos);
        this.rwtext[ic].setDepth(200100);
      }
      if (va[0] == "ki") {
        this.rwtext[ic] = this.wh.add.text(0, 0, "KI+" + va[1], { fontFamily: 'ffff', fontSize: '20px', color: '#fff' });
        this.rwtext[ic].setStroke('#2d2d2d', 6);
        this.rwtext[ic].setShadow(4, 4, "#000000", 2, false, true);
        this.rwtext[ic].setPosition((width / 2) - (this.rwtext[ic].displayWidth / 2), usetoppos);
        this.rwtext[ic].setDepth(200100);
      }
      if (va[0] == "text") {
        this.rwtext[ic] = this.wh.add.text(0, 0, "" + va[1], { fontFamily: 'ffff', fontSize: '20px', color: '#fff' });
        this.rwtext[ic].setStroke('#2d2d2d', 6);
        this.rwtext[ic].setShadow(4, 4, "#000000", 2, false, true);
        this.rwtext[ic].setPosition((width / 2) - (this.rwtext[ic].displayWidth / 2), usetoppos);
        this.rwtext[ic].setDepth(200100);
      }
      */
    }
    this.showrewardcontainer.setDepth(5000002);
    this.showrewardcontainer_sub.setDepth(5000004);
    this.showrewardcontainer.visible = true;
    this.showrewardcontainer_sub.visible = true;

    var subpos = this.wh.registry.list.gameitf.getContainerSize(
      this.showrewardcontainer_sub,
      this.showrewardcontainer_sub
    );
    //this.plog.log('showreward subpos', subpos);
    if (this.usesingletextshow == false) {
      this.showrewardcontainer_sub.setPosition(
        width / 2 - (subpos.w / 2 - iconwh / 2),
        height / 2 - (subpos.h / 2 - iconwh / 2)
      );
    }

    /*var tmpmask = wh.add.image(0, 0, "thecolor");
    tmpmask.visible=false;
    this.showrewardcontainer.add(tmpmask);
    var tmpmaskm = await wh.shapemnm.createBitmapMask();
    tmpmaskm.setOrigin(0, 0);
    this.showrewardcontainer.setMask(tmpmaskm);


    
    //wh.gifscontainer.add(wh.maskmnm);

    tmpmask.visible = false;
    tmpmaskm.displayWidth=width+5;
    tmpmaskm.displayHeight=height+5;
    tmpmaskm.setPosition(0,0);*/
    //this.plog.log(      'showreward subpos',      this.showrewardcontainer,      this.showrewardcontainer_sub    );
    //this.plog.log(      'showreward subposchk',      this.showrewardcontainer.alpha,      this.showrewardcontainer.visible    ); 
    if (this.config['windowmode'] != undefined && this.config['windowmode'] == 4) {
      var poslist = [];
      var px = 0; var py = 0;
      for (var pi = 1; pi <= 4; pi++) {
        px++;
        if (px > 2) { px = 1; py++; }
        poslist[pi] = [width * 0.5 * (px - 1), height * 0.5 * py];
      }
      //console.log(poslist);
      this.showrewardcontainer.setScale(0.5);
      this.showrewardcontainer.setPosition(
        poslist[this.config['windowpos']][0],
        poslist[this.config['windowpos']][1]
      );
      //tmpmaskm.setScale(0.5);
      //tmpmaskm.setPosition(0,0);
      this.showrewardcontainer_sub.setScale(0.5);
      this.showrewardcontainer_sub.x = poslist[this.config['windowpos']][0] + this.showrewardcontainer_sub.x * 0.5;
      this.showrewardcontainer_sub.y = poslist[this.config['windowpos']][1] + this.showrewardcontainer_sub.y * 0.5;

    }
    if (this.config['windowmode'] != undefined && this.config['windowmode'] == 9) {
      var poslist = [];
      var px = 0; var py = 0;
      for (var pi = 1; pi <= 9; pi++) {
        px++;
        if (px > 3) { px = 1; py++; }
        poslist[pi] = [width * 0.333 * (px - 1), height * 0.333 * py];
      }
      //console.log(poslist);
      this.showrewardcontainer.setScale(0.333);
      this.showrewardcontainer.setPosition(
        poslist[this.config['windowpos']][0],
        poslist[this.config['windowpos']][1]
      );
      //tmpmaskm.setScale(0.5);
      //tmpmaskm.setPosition(0,0);
      this.showrewardcontainer_sub.setScale(0.333);
      this.showrewardcontainer_sub.x = poslist[this.config['windowpos']][0] + this.showrewardcontainer_sub.x * 0.333;
      this.showrewardcontainer_sub.y = poslist[this.config['windowpos']][1] + this.showrewardcontainer_sub.y * 0.333;

    }
    if (this.config['windowmode'] != undefined && this.config['windowmode'] == 16) {
      var poslist = [];
      var px = 0; var py = 0;
      for (var pi = 1; pi <= 16; pi++) {
        px++;
        if (px > 4) { px = 1; py++; }
        poslist[pi] = [width * 0.25 * (px - 1), height * 0.25 * py];
      }
      //console.log(poslist);
      this.showrewardcontainer.setScale(0.25);
      this.showrewardcontainer.setPosition(
        poslist[this.config['windowpos']][0],
        poslist[this.config['windowpos']][1]
      );
      //tmpmaskm.setScale(0.5);
      //tmpmaskm.setPosition(0,0);
      this.showrewardcontainer_sub.setScale(0.25);
      this.showrewardcontainer_sub.x = poslist[this.config['windowpos']][0] + this.showrewardcontainer_sub.x * 0.25;
      this.showrewardcontainer_sub.y = poslist[this.config['windowpos']][1] + this.showrewardcontainer_sub.y * 0.25;

    }
    //update charinfo automatically when show reward
    await this.wh.registry.list.rchttp.updatecharinfo();
  }

  showlose(wh, reward) {
    this.wh = wh;
    for (const k in reward) {
      var v = reward[k];
    }

    if (wh.showrewardcontainer == undefined) {
      wh.showrewardcontainer = wh.add.container(0, 0);
    }
    if (wh.showrewardcontainer_sub == undefined) {
      wh.showrewardcontainer_sub = wh.add.container(0, 0);
    }
    this.showrewardcontainer = wh.showrewardcontainer;
    this.showrewardcontainer_sub = wh.showrewardcontainer_sub;

    //this.plog.log('ShowRewardService showlose', wh, reward);
    //resultscreen

    var width = this.wh.game.canvas.width;
    var height = this.wh.game.canvas.height;
    this.tmpbg = this.wh.add.image(300, 300, 'combatlose');
    this.tmpbg.setOrigin(0.5, 0.5);
    this.tmpbg.setInteractive();
    this.tmpbg.setDepth(5000000);
    this.tmpbg.setPosition(width / 2, height / 2);
    if (width > height) {
      this.tmpbg.displayWidth = width + 5;
      this.tmpbg.scaleY = this.tmpbg.scaleX;
    } else {
      this.tmpbg.displayHeight = height + 5;
      this.tmpbg.scaleX = this.tmpbg.scaleY;
    }
    this.tmpbg.on(
      'pointerdown',
      function () {
        this.show_close();
      },
      this
    );
    this.tmpbg.setAlpha(0);
    var tw = this.wh.tweens.add({
      targets: this.tmpbg,
      alpha: 1,
      ease: 'Power3',
      duration: 500, // duration of animation; higher=slower
      delay: 0, // wait 500 ms before starting
      onComplete: function () {
        //this.visible=false;
      }, // set context? how?
    });

    this.tmpbg2 = this.wh.add.image(300, 300, 'flare0');
    this.tmpbg2.setOrigin(0.5, 0.5);
    this.tmpbg2.setDepth(5000000);
    this.tmpbg2.setPosition(width / 2, height / 2);
    if (width > height) {
      this.tmpbg2.displayWidth = width + 5;
      this.tmpbg2.scaleY = this.tmpbg2.scaleX;
    } else {
      this.tmpbg2.displayHeight = height + 5;
      this.tmpbg2.scaleX = this.tmpbg2.scaleY;
    }
    //this.tmpbg2.setBlendMode(Phaser.BlendModes.COLOR_BURN);
    var tw2 = this.wh.tweens.add({
      targets: this.tmpbg2,
      angle: 359,
      alpha: 0,
      ease: 'Linear',
      duration: 5000, // duration of animation; higher=slower
      delay: 0,
      repeat: 0,
    }); //this.plog.log("xxxx",this);

    this.rewardtext = this.wh.add.text(0, 0, 'Not success', {
      fontFamily: 'ffff',
      fontSize: '48px',
      color: '#ed9600',
    });
    this.rewardtext.setStroke('#2d2d2d', 16);
    this.rewardtext.setShadow(4, 4, '#000000', 2, false, true);
    this.rewardtext.setPosition(
      width / 2 - this.rewardtext.displayWidth / 2,
      height / 3
    );
    this.rewardtext.setDepth(5000001);

    this.clicktoclose = this.wh.add.text(0, 0, 'Click anywhere to close', {
      fontFamily: 'ffff',
      fontSize: '36px',
      color: '#fff',
    });
    this.clicktoclose.setStroke('#2d2d2d', 16);
    this.clicktoclose.setShadow(4, 4, '#000000', 2, false, true);
    this.clicktoclose.setPosition(
      width / 2 - this.clicktoclose.displayWidth / 2,
      20
    );
    this.clicktoclose.setDepth(5000002);
    if (this.config["windowmode"] != undefined) {
      this.clicktoclose.visible = false;
    }

    var toppos = height / 2 - 35;
    var ic = 0;
    var rowheight = 30;
    for (const k in reward) {
      ic++;
      var v = reward[k];
      var va = v.split(':');
      var usetoppos = toppos + rowheight * ic;
      if (va[0] == 'mob') {
        this.plog.log('reward mob', va);
        var iteminfo = this.wh.registry.list.rcvarpass.allmob[va[1]];
        this.rwtext[ic] = this.wh.add.text(
          0,
          0,
          'Pet: ' + iteminfo['name'] + ' ',
          { fontFamily: 'ffff', fontSize: '20px', color: '#fff' }
        );

        this.rwtext[ic].setStroke('#2d2d2d', 6);
        this.rwtext[ic].setShadow(4, 4, '#000000', 2, false, true);
        this.rwtext[ic].setPosition(
          width / 2 - this.rwtext[ic].displayWidth / 2,
          usetoppos
        );
        this.rwtext[ic].setDepth(5000100);
        //icon
        ic = ic + 3;
        this.rwicon[ic] =
          this.wh.registry.list.rcvarpass.gameobj.scene.scenes[0].add.sprite(
            100,
            100,
            'mob',
            va[1]
          );
        this.rwicon[ic].setOrigin(0.5, 0.5);
        this.rwicon[ic].setPosition(
          width / 2,
          usetoppos + rowheight + rowheight
        );
        this.rwicon[ic].displayWidth = this.rwicon[ic].displayHeight =
          rowheight * 3 + 4;
        this.rwicon[ic].setDepth(5000101);

        this.showrewardcontainer.add(this.rwtext[ic]);
        this.showrewardcontainer.bringToTop(this.rwtext[ic]);
        this.showrewardcontainer.add(this.rwicon[ic]);
        this.showrewardcontainer.bringToTop(this.rwicon[ic]);
      }
      if (va[0] == 'item') {
        var iteminfo = this.wh.registry.list.rcvarpass.allitem[va[1]];
        this.rwtext[ic] = this.wh.add.text(
          0,
          0,
          'Item: ' + iteminfo['name'] + ' ',
          { fontFamily: 'ffff', fontSize: '20px', color: '#fff' }
        );
        this.rwtext[ic].setStroke('#2d2d2d', 6);
        this.rwtext[ic].setShadow(4, 4, '#000000', 2, false, true);
        this.rwtext[ic].setPosition(
          width / 2 - this.rwtext[ic].displayWidth / 2,
          usetoppos
        );
        this.rwtext[ic].setDepth(5000100);
        //icon
        this.rwicon[ic] =
          this.wh.registry.list.rcvarpass.gameobj.scene.scenes[0].add.sprite(
            100,
            100,
            'item_tp',
            va[1]
          );
        this.rwicon[ic].setPosition(
          width / 2 - (this.rwtext[ic].displayWidth / 2 + rowheight),
          usetoppos
        );
        this.rwicon[ic].displayWidth = this.rwicon[ic].displayHeight =
          rowheight + 4;
        this.rwicon[ic].setOrigin(0, 0);
        this.rwicon[ic].setDepth(5000100);
        this.showrewardcontainer.add(this.rwtext[ic]);
        this.showrewardcontainer.bringToTop(this.rwtext[ic]);
        this.showrewardcontainer.add(this.rwicon[ic]);
        this.showrewardcontainer.bringToTop(this.rwicon[ic]);
      }

      if (va[0] == 'stackitem') {
        var iteminfo = this.wh.registry.list.rcvarpass.allstackitem[va[1]];
        this.rwtext[ic] = this.wh.add.text(
          0,
          0,
          'StackItem: ' + iteminfo['name'] + ' x' + va[2],
          { fontFamily: 'ffff', fontSize: '20px', color: '#fff' }
        );
        this.rwtext[ic].setStroke('#2d2d2d', 6);
        this.rwtext[ic].setShadow(4, 4, '#000000', 2, false, true);
        this.rwtext[ic].setPosition(
          width / 2 - this.rwtext[ic].displayWidth / 2,
          usetoppos
        );
        this.rwtext[ic].setDepth(5000100);
        this.rwicon[ic] =
          this.wh.registry.list.rcvarpass.gameobj.scene.scenes[0].add.sprite(
            100,
            100,
            'stackitem_tp',
            va[1]
          );
        this.rwicon[ic].setPosition(
          width / 2 - (this.rwtext[ic].displayWidth / 2 + rowheight),
          usetoppos
        );
        this.rwicon[ic].displayWidth = this.rwicon[ic].displayHeight =
          rowheight + 4;
        this.rwicon[ic].setOrigin(0, 0);
        this.rwicon[ic].setDepth(5000100);
        this.showrewardcontainer.add(this.rwtext[ic]);
        this.showrewardcontainer.bringToTop(this.rwtext[ic]);
        this.showrewardcontainer.add(this.rwicon[ic]);
        this.showrewardcontainer.bringToTop(this.rwicon[ic]);
      }

      if (va[0] == 'xp') {
        this.rwtext[ic] = this.wh.add.text(0, 0, 'XP+' + va[1], {
          fontFamily: 'ffff',
          fontSize: '20px',
          color: '#fff',
        });
        this.rwtext[ic].setStroke('#2d2d2d', 6);
        this.rwtext[ic].setShadow(4, 4, '#000000', 2, false, true);
        this.rwtext[ic].setPosition(
          width / 2 - this.rwtext[ic].displayWidth / 2,
          usetoppos
        );
        this.rwtext[ic].setDepth(5000100);
        this.showrewardcontainer.add(this.rwtext[ic]);
        this.showrewardcontainer.bringToTop(this.rwtext[ic]);
      }
      if (va[0] == 'ki') {
        this.rwtext[ic] = this.wh.add.text(0, 0, 'KI+' + va[1], {
          fontFamily: 'ffff',
          fontSize: '20px',
          color: '#fff',
        });
        this.rwtext[ic].setStroke('#2d2d2d', 6);
        this.rwtext[ic].setShadow(4, 4, '#000000', 2, false, true);
        this.rwtext[ic].setPosition(
          width / 2 - this.rwtext[ic].displayWidth / 2,
          usetoppos
        );
        this.rwtext[ic].setDepth(5000100);
        this.showrewardcontainer.add(this.rwtext[ic]);
        this.showrewardcontainer.bringToTop(this.rwtext[ic]);
      }
      if (va[0] == 'text') {
        this.rwtext[ic] = this.wh.add.text(0, 0, '' + va[1], {
          fontFamily: 'ffff',
          fontSize: '20px',
          color: '#fff',
        });
        this.rwtext[ic].setStroke('#2d2d2d', 6);
        this.rwtext[ic].setShadow(4, 4, '#000000', 2, false, true);
        this.rwtext[ic].setPosition(
          width / 2 - this.rwtext[ic].displayWidth / 2,
          usetoppos
        );
        this.rwtext[ic].setDepth(5000100);
        this.showrewardcontainer.add(this.rwtext[ic]);
        this.showrewardcontainer.bringToTop(this.rwtext[ic]);
      }
    }

    this.showrewardcontainer.setDepth(5000002);
    this.showrewardcontainer_sub.setDepth(5000004);
    this.showrewardcontainer.add(this.tmpbg2);
    this.showrewardcontainer.sendToBack(this.tmpbg2);
    this.showrewardcontainer.add(this.tmpbg);
    this.showrewardcontainer.sendToBack(this.tmpbg);
    this.showrewardcontainer.add(this.rewardtext);
    this.showrewardcontainer.bringToTop(this.rewardtext);
  }
  isclosed() {
    //this.plog.log("showreward isclosed();", this.showrewardcontainer, this);
    if (this.showrewardcontainer != undefined) return false;
    return true;
  }
  async show_close() {
    //this.plog.log('showreward show_close');
    //console.log(this.containerlist);
    /*
    for (const k in this.rwicon) {
      if (this.rwicon[k] != undefined) {
        this.rwicon[k].destroy();
        this.rwicon[k] = undefined;
      }
    }
    for (const k in this.rwtext) {
      if (this.rwtext[k] != undefined) {
        this.rwtext[k].destroy();
        this.rwtext[k] = undefined;
      }
    }
    this.rewardtext.destroy();
    this.rewardtext = undefined;
    this.clicktoclose.destroy();
    this.clicktoclose = undefined;
    this.tmpbg.destroy();
    this.tmpbg = undefined;
    this.tmpbg2.destroy();
    this.tmpbg2 = undefined;*/
    this.wh.kbfocuslv1 = "";

    this.wh.tweens.add({
      targets: [
        this.wh.showrewardcontainer,
        this.wh.showrewardcontainer_sub
      ],
      alpha: 0,
      ease: 'Power1',
      duration: 300,
      delay: 100,
      yoyo: false,
      repeat: 0,
      onComplete: function () {
        this.parent.scene.plog.log('showreward close tweencomplete', this);
        if (arguments[1][0] != undefined)
          arguments[1][0].destroy();
        if (arguments[1][1] != undefined)
          arguments[1][1].destroy();
        if (this.parent.scene.registry.list.phaserGame.scene.scenes[0].showrewardcontainer != undefined)
          this.parent.scene.registry.list.phaserGame.scene.scenes[0].showrewardcontainer.destroy();
        this.parent.scene.registry.list.phaserGame.scene.scenes[0].showrewardcontainer = undefined;
        if (this.parent.scene.registry.list.phaserGame.scene.scenes[0].showrewardcontainer_sub != undefined)
          this.parent.scene.registry.list.phaserGame.scene.scenes[0].showrewardcontainer_sub.destroy();
        this.parent.scene.registry.list.phaserGame.scene.scenes[0].showrewardcontainer_sub = undefined;
        this.parent.scene.registry.list.showreward.showrewardcontainer = undefined;
        this.parent.scene.registry.list.showreward.showrewardcontainer_sub = undefined;

        this.parent.scene.showrewardcontainer = undefined;
        this.parent.scene.showrewardcontainer_sub = undefined;
      },
    });


    for (var destroyi = 0; destroyi <= 10; destroyi++) {
      try {
        for (const k in this.containerlist) {
          for (const kk in this.containerlist[k].list) {
            await this.containerlist[k].remove(this.containerlist[k].list[kk], true);
            //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list[k].destroy();
          }
        }
      } catch (e) {
        this.plog.log("ERROR! mapcontainer.destroy() ", e);
      }
    }

    for (var destroyi = 0; destroyi <= 10; destroyi++) {
      try {
        for (const k in this.containerlist) {
          await this.containerlist[k].destroy();
          //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list[k].destroy();
        }
      } catch (e) {
        this.plog.log("ERROR! mapcontainer.destroy() ", e);
      }
    }
    for (var destroyi = 0; destroyi <= 10; destroyi++) {
      try {
        for (const k in this.containerlist) {
          delete this.containerlist[k];
        }
      } catch (e) {
        this.plog.log("ERROR! mapcontainer.destroy() ", e);
      }

    }
    this.wh.registry.list.tut.show(this.wh, "dissmissfirstreward");


    return;

    if (this.wh.showrewardcontainer != undefined)
      this.wh.showrewardcontainer.destroy();//true
    if (this.wh.showrewardcontainer_sub != undefined)
      this.wh.showrewardcontainer_sub.destroy();//true
    this.wh.showrewardcontainer = undefined;
    this.wh.showrewardcontainer_sub = undefined;
  }
  getstr(wh, reward) {
    this.wh = wh;
    var resstr = '';
    for (const k in reward) {
      var v = reward[k];
      var va = v.split(':');
      if (va[0] == 'xp') {
        resstr += '\nXP ' + va[1];
      }
      if (va[0] == 'ki') {
        resstr += '\nKI ' + va[1];
      }
      if (va[0] == 'stackitem') {
        var iteminfo = this.wh.registry.list.rcvarpass.allstackitem[va[1]];
        resstr += '\n' + va[2] + ' ' + iteminfo['name'];
      }
      if (va[0] == 'item') {
        var iteminfo = this.wh.registry.list.rcvarpass.allitem[va[1]];
        resstr += '\nITEM ' + iteminfo['name'];
      }
      if (va[0] == 'mob') {
      }
    }
    return resstr;
  }
  effwithtxt(wh, sprite, txt) {
    this.plog.log("effwithtxt", wh, sprite, txt);
    this.effwithtxt_sprite = sprite;
    this.effwithtxt_txt = txt;
    this.wh = wh;
    let loader = new Phaser.Loader.LoaderPlugin(this.wh);

    var spritea = sprite.split("_");
    var svversionurlstring = this.wh.registry.list.rcvarpass.svversionstring;
    if (svversionurlstring != '') {
      svversionurlstring = '?' + svversionurlstring;
    }
    loader.atlas(
      spritea[0] + '',
      this.wh.registry.list.rcvarpass.rc_baseurl +
      'sprite/' +
      spritea[0] +
      '.png' +
      svversionurlstring,
      this.wh.registry.list.rcvarpass.rc_baseurl +
      'sprite/' +
      spritea[0] +
      '.json' +
      svversionurlstring
    );

    //this.wh.registry.list.rcloading.loading2(this.wh);
    loader.on(
      'progress',
      function (value) {
        //this.plog.log("loaderevent", value, loader.totalComplete, loader.totalToLoad);
        this.wh.registry.list.rcloading.loading2text(
          'Loading ' + loader.totalComplete + '/' + loader.totalToLoad
        );
        this.wh.registry.list.rcloading.loading2percent(value);
      },
      this
    );
    loader.once(
      Phaser.Loader.Events.COMPLETE,
      () => {
        //this.plog.log('Phaser.Loader.Events.COMPLETE');
        //this.plog.log(this);
        this.effwithtxt_loaded();
      },
      this
    );
    loader.start();
  }
  effwithtxt_loaded() {
    this.wh.registry.list.gameitf.genloadedanims(this.wh, this.effwithtxt_sprite);
    if (this.effwithtxt_spriteo != undefined) {
      this.effwithtxt_spriteo.destroy();
      this.effwithtxt_spriteo = undefined;
    }
    this.wh.registry.list.phm.mapitemeffect(this.wh.char_main, this.effwithtxt_sprite);

    //this.effwithtxt_spriteo = this.wh.add.sprite(0, 0, this.effwithtxt_sprite).play({ key: this.effwithtxt_sprite, frameRate: 64 });
    this.wh.registry.list.rcloading.close();
    var txt = [];
    txt["col"] = "blue";
    txt["txt"] = this.effwithtxt_txt;
    this.wh.registry.list.gameitf.osdflash(this.wh, [txt]);
    //var width = this.wh.game.canvas.width;
    //var height = this.wh.game.canvas.height;
    //this.effwithtxt_spriteo.setOrigin(0.5, 0.5);
    //this.effwithtxt_spriteo.setPosition(width / 2, height / 2);


  }
}
