import { Injectable } from '@angular/core';
import { RCVarsService } from './rcvars-service.service';
import { plog } from './plog.service';

@Injectable({
  providedIn: 'root',
})
export class intl {
  plog;

  constructor(
    public rcvarsservice: RCVarsService,
  ) {
    this.plog = new plog();
    this.plog.setLevel(4);
  }
  public ff(str) {
    //this.plog.log(str, this)
    //this.plog.log(str, this, this.rcvarsservice.lng)
    //console.log("intl.ff",str);
    if (str.indexOf("===") == -1) {
      return str;
    }
    var stra = str.split("===");
    var kcode = (stra[0] + "").trim();
    if (kcode == "") return stra[1];
    if (this.rcvarsservice.lng == undefined) return stra[1];
    if (this.rcvarsservice.lng[kcode] == undefined) return stra[1];
    this.rcvarsservice.lng[kcode] = this.rcvarsservice.lng[kcode].replace('\\n', "\n");
    return this.rcvarsservice.lng[kcode];
  }
}
