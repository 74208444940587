import { COMBATANT } from './combatant';
import { PGBar } from './pgbar';
import { plog } from './plog.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class COMBAT {
  width = 0;
  height = 0;
  partymember;
  pausecombat = 0;
  followset = [];
  nostatuses = [];
  logtextdsp = [];
  tapsprite = [];
  superenergy = [];
  tapspritei = 0;
  logtextdspcount = 6; ///6
  battlebegin = "no";
  logtext = [];
  combatresultsent = false;
  combattoken = '';
  timer;
  totaldmgtxt;
  totaldmg = 0;
  shine1;
  mobsniffer;
  mobsnifferactive = false;
  mincombat = "no";
  catteam1 = { "01": [], "02": [], "03": [], "04": [], "05": [] };
  catteam2 = { "01": [], "02": [], "03": [], "04": [], "05": [] };

  gridw = 14;
  gridh = 15;

  timespeed = 0.3;//0.5;
  stdtime = 100;
  cbtfr = 3;
  timelimit = 60;
  timercount = 0;
  timerpersec = 10;
  timerperseccount = 0;
  timerclock;
  wh;
  o;
  bg;
  combatants = [];
  battlegrid = [];
  battlegridbtn = [];
  animsloaded = [];
  public cbto = [];
  charsize = 0;
  posinfo;
  mobloaded = [];
  charloaded = [];
  team1list = [];
  team2list = [];
  cbstage = 'prepare';
  public selectedchar_id;
  selectedchar_eff;
  closebtn;
  beginbattlebtn;
  battlelog = {};
  combatspeed = 1;
  spdbtn1;
  spdbtn2;
  spdbtn4;
  spdbtn8;
  combatinprogress = "no";
  timedelayuse: number;
  plog;
  isbadint = "no";

  constructor() {
    //this.plog.log("COMBAT constructor");
    this.battlelog = {};
    this.combatresultsent = false;
    this.battlebegin = "no";
    this.plog = new plog();
  }
  walkablegrid = [];
  config;
  optionparam = '';
  getobj() {
    return this.o;
  }
  setcombatspeed(spd) {
    this.spdbtn1.setAlpha(0.7);
    this.spdbtn2.setAlpha(0.7);
    this.spdbtn4.setAlpha(0.7);
    this.spdbtn8.setAlpha(0.7);
    this.plog.log('setcombatspeed', spd);
    this.combatspeed = spd;
    if (spd == 1) this.spdbtn1.setAlpha(1);
    if (spd == 2) this.spdbtn2.setAlpha(1);
    if (spd == 4) this.spdbtn4.setAlpha(1);
    if (spd == 8) this.spdbtn8.setAlpha(1);
    this.wh.registry.list.rchttp.getcommon2('savecombatspeed', {
      combatspeed: spd,
    });
    if (spd == 1) {
      this.timespeed = 1;
      this.stdtime = 1000;
      this.cbtfr = 3;
    }
    if (spd == 2) {
      this.timespeed = 0.5;
      this.stdtime = 1000;
      this.cbtfr = 6;
    }
    if (spd == 4) {
      this.timespeed = 0.25;
      this.stdtime = 1000;
      this.cbtfr = 9;
    }
    if (spd == 8) {
      this.timespeed = 0.125;
      this.stdtime = 1000;
      this.cbtfr = 12;
    }
    if (this.mincombat == "yes") {
      //this.cbtfr = 0.1;
    }
    //this.settimerwithspeed();
    this.plog.log('setcombatspeed', 'timespeed', this.timespeed, 'stdtime', this.stdtime, 'cbtfr', this.cbtfr);
  }
  settimerwithspeed() {
    if (this.timer != undefined) {
      this.timer.remove(false);
      this.timer = undefined;
    }
    if (this.battlebegin == "no") return;
    //this.plog.error("battleticktime",(1000 / this.timerpersec) * this.timespeed);
    this.timedelayuse = Math.floor(1000 * this.timespeed);
    this.timedelayuse = Math.floor(this.timedelayuse / this.timerpersec);
    //this.timerpersec// * this.timespeed,
    this.timer = this.wh.time.addEvent(
      {
        delay: this.timedelayuse,// * this.timespeed,
        callback: this.battletick,
        args: [],
        callbackScope: this,
        loop: true,
        repeat: 0,
        startAt: 0,
        timeScale: 1,
        paused: false,
      },
      this
    );
    this.plog.log("settimerwithspeed() s delay=", this.timedelayuse, "timespeed=", this.timespeed, "timerpersec=", this.timerpersec);
  }
  async start(wh, config = {}, mobstart = '') {
    this.plog.log('COMBAT start', wh, config, mobstart);
    ///wh.registry.list.snd.bgm("combat");
    if (this.combatinprogress == "yes") return;
    this.mobsnifferactive = false;
    try {
      await wh.registry.list.gameitf.closegui(wh, wh.friendlistcontainer);
    } catch (e) {
      this.plog.log("ERROR", e);
    }
    //this.plog.error(config);
    wh.mapcontainer_hide();
    this.logtext = [];
    this.battlelog = [];
    this.totaldmg = 0;
    this.optionparam = mobstart;
    this.config = config;
    this.battlebegin = "no";
    this.combatinprogress = "yes";
    if (this.config == undefined) this.config = {};
    if (this.config['data'] == undefined) this.config['data'] = [];
    if (this.config['data']['code'] == undefined)
      this.config['data']['code'] = '';
    this.wh = wh;
    this.wh.kbfocus = "combat";
    this.mincombat = await this.wh.registry.list.rcstorage.get('setting_mincombat', true);

    this.topclockdone = [];
    this.catteam1 = { "01": [], "02": [], "03": [], "04": [], "05": [] };
    this.catteam2 = { "01": [], "02": [], "03": [], "04": [], "05": [] };

    this.isbadint = await this.wh.registry.list.rcstorage.get("setting_badint", true);
    //console.error("isbadint",this.isbadint);
    wh.registry.list.rcloading.loading2(wh);
    if (this.config['combatmode'] == undefined) this.config['combatmode'] = "";
    var combattoken = await wh.registry.list.rchttp.getcommon2('combattoken', {
      combatmode: this.config['combatmode']
    });
    this.combattoken = combattoken["token"];
    this.partymember = combattoken["party"];
    //this.plog.log('combattoken', combattoken);
    this.closebtn = wh.add.sprite(300, 300, "thesq", 'close');
    this.closebtn.visible = false;
    this.closebtn.setOrigin(0.5, 0.5);
    this.closebtn.setInteractive();
    this.closebtn.removeAllListeners();
    this.shine1 = wh.add.sprite(100, 100, "shinebox", "shinebox_b");
    this.shine1.setOrigin(0, 0);
    this.shine1.play("shinebox_b");

    this.mobsniffer = wh.add.sprite(0, 0, "stackitem_tp", "mobdetect");//wh.add.image(0, 0, 'sellbtn');
    this.mobsniffer.setOrigin(0.5, 0.5);
    this.mobsniffer.setInteractive();
    this.mobsniffer.removeAllListeners();
    this.mobsniffer.on(
      'pointerdown',
      async function () {
        var res = await this.wh.registry.list.rchttp.getcommon2("consumestackitem", { usecode: 'mobdetect' })
        if (res == 'ok') {
          this.mobsniffer.visible = false;
          //this.shine1.visible = false;
          //this.shine1.play('ixbeff107_b');
          this.shine1.play({ key: "ixbeff107_b", repeat: 0 });
          this.shine1.displayWidth = this.shine1.displayHeight = 128;
          this.wh.tweens.add(
            {
              targets: this.shine1,
              alpha: 0,
              ease: 'Linear',
              duration: 300, // duration of animation; higher=slower
              delay: 0,
              onComplete: function () {
                this.targets[0].destroy();
              }
            }, this);
          this.mobsnifferactive = true;
          this.updateracetagline();
          ///
        }
        await this.wh.registry.list.rchttp.updatecharinfo();
      }, this);

    this.totaldmgtxt = wh.add.text(0, 0, 'Damage: 0', {
      fontFamily: 'ffff',
      fontSize: '24px',
      stroke: '#f00',
      strokeThickness: 6,
      color: '#fff',
    });
    this.timerclock = wh.add.text(0, 0, '00:00', {
      fontFamily: 'ffff',
      fontSize: '20px',
      stroke: '#000',
      strokeThickness: 6,
      color: '#fff',
    });
    this.closebtn.on(
      'pointerdown',
      function (pointer, localX, localY, event) {
        //this.scene.registry.list["thethis"].phm.floorclick(this);
        //this.plog.log(          'this.closebtn pointerdown',          pointer,          localX,          localY,          event,          this        );
        this.exitbattle(true);
      },
      this
    );
    this.wh.walkpathcmd = [];

    this.spdbtn1 = wh.add.sprite(300, 300, "thesq", 'n1x');
    this.spdbtn1.setOrigin(0.5, 0.5);
    this.spdbtn1.setInteractive();
    this.spdbtn1.removeAllListeners();
    this.spdbtn1.on(
      'pointerdown',
      function (pointer, localX, localY, event) {
        this.setcombatspeed(1);
        this.settimerwithspeed();
      },
      this
    );

    this.spdbtn2 = wh.add.sprite(300, 300, "thesq", 'n2x');
    this.spdbtn2.setOrigin(0.5, 0.5);
    this.spdbtn2.setInteractive();
    this.spdbtn2.removeAllListeners();
    this.spdbtn2.on(
      'pointerdown',
      function (pointer, localX, localY, event) {
        this.setcombatspeed(2);
        this.settimerwithspeed();
      },
      this
    );

    this.spdbtn4 = wh.add.sprite(300, 300, "thesq", 'n4x');
    this.spdbtn4.setOrigin(0.5, 0.5);
    this.spdbtn4.setInteractive();
    this.spdbtn4.removeAllListeners();
    this.spdbtn4.on(
      'pointerdown',
      function (pointer, localX, localY, event) {
        this.setcombatspeed(4);
        this.settimerwithspeed();
      },
      this
    );

    this.spdbtn8 = wh.add.sprite(300, 300, "thesq", 'n8x');
    this.spdbtn8.setOrigin(0.5, 0.5);
    this.spdbtn8.setInteractive();
    this.spdbtn8.removeAllListeners();
    this.spdbtn8.on(
      'pointerdown',
      function (pointer, localX, localY, event) {
        this.setcombatspeed(8);
        this.settimerwithspeed();
      },
      this
    );
    this.spdbtn1.visible = false;
    this.spdbtn2.visible = false;
    this.spdbtn4.visible = false;
    this.spdbtn8.visible = false;

    if (
      this.wh.registry.list.rcvarpass.activechar['combatspeed'] == undefined ||

      Number.isNaN(this.wh.registry.list.rcvarpass.activechar['combatspeed'])
    ) {
      this.wh.registry.list.rcvarpass.activechar['combatspeed'] = 1;
    }
    this.setcombatspeed(
      this.wh.registry.list.rcvarpass.activechar['combatspeed']
    );

    if (this.wh.combatcontainer == undefined) {
      this.wh.combatcontainer = this.wh.add.container(width, height);
    }
    if (this.wh.combatcontainer_reward == undefined) {
      this.wh.combatcontainer_reward = this.wh.add.container(width, height);
    }
    wh.combatcontainer.name = "combatcontainer";
    wh.combatcontainer_reward.name = "combatcontainer_reward";
    wh.combatcontainer.add(this.totaldmgtxt);
    wh.combatcontainer.add(this.timerclock);

    if (this.config['combatmode'] != 'challenge' && this.config['combatmode'] != 'svboss') {
      this.plog.log('combatmode!=challenge, hiding totaldmgtxt');
      this.totaldmgtxt.visible = false;
    }
    this.wh.pausemapmob();
    //if (this.config['combatmode'] != '' || Number(this.wh.registry.list.rcvarpass.activemap['map']['forcemoblevel']) != 0) {
    //preload level increase for modes
    if (
      this.wh.registry.list.rcvarpass.stdstat == undefined ||
      this.wh.registry.list.rcvarpass.stdstat.length < 3
    ) {
      await this.wh.registry.list.rcsvvars.preloadstdstat();
      //this.plog.log('stdstat', this.wh.registry.list.rcvarpass.stdstat);
    }
    //}
    wh.combatcontainer.add(this.closebtn);
    wh.combatcontainer.add(this.spdbtn1);
    wh.combatcontainer.add(this.spdbtn2);
    wh.combatcontainer.add(this.spdbtn4);
    wh.combatcontainer.add(this.spdbtn8);

    wh.combatcontainer.add(this.shine1);
    wh.combatcontainer.add(this.mobsniffer);
    this.beginbattlebtn = wh.add.image(300, 300, 'beginbattlebtn');
    wh.combatcontainer.add(this.beginbattlebtn);
    this.beginbattlebtn.setInteractive();
    this.beginbattlebtn.removeAllListeners();
    this.beginbattlebtn.on(
      'pointerdown',
      function (pointer, localX, localY, event) {
        //this.scene.registry.list["thethis"].phm.floorclick(this);
        //this.plog.log(          'this.beginbattlebtn pointerdown',          pointer,          localX,          localY,          event,          this        );
        this.beginbattle();
      },
      this
    );
    this.closebtn.visible = false;
    this.beginbattlebtn.visible = false;

    this.selectedchar_eff =
      this.wh.registry.list.rcvarpass.gameobj.scene.scenes[0].add.sprite(
        100,
        100,
        'portalpurple1_b'
      );
    this.selectedchar_eff.play('portalpurple1_b');
    wh.combatcontainer.add(this.selectedchar_eff);
    this.selectedchar_eff.visible = false;
    //save game
    await wh.registry.list.rchttp.savegame(
      wh.registry.list.rcvarpass.activechar
    );

    var width = this.wh.game.canvas.width;
    var height = this.wh.game.canvas.height;
    this.width = width;
    this.height = height;
    //setup grid 30x15
    var battlegroundXmargin = width * 0.1;
    if (height > width) {
      battlegroundXmargin = width * 0.05;
    }
    var battlegroundYmargin = height * 0.1 + height * 0.2; //add bg margin and panel top
    var avaiw = width - battlegroundXmargin * 2;
    var avaih = height - battlegroundYmargin * 2;
    var battlegridsizex = avaiw / this.gridw;
    var battlegridsizey = avaih / this.gridh;
    //battlegridsizey=battlegridsizey/2;
    if (battlegridsizey > battlegridsizex) battlegridsizey = battlegridsizex;
    this.charsize = ((battlegridsizex + battlegridsizey) / 2) * 3;
    var toppos = (height - battlegridsizey * this.gridh) * 0.8;
    if (height > width) {
      //portrait
      var toppos = (height - battlegridsizey * this.gridh) * 0.6;
    }
    var leftpos = battlegroundXmargin;
    this.posinfo = {
      left: leftpos,
      top: toppos,
      grid: battlegridsizex,
      gridy: battlegridsizey,
      charsize: this.charsize,
    };
    for (let xx = 0; xx <= Math.floor(this.gridw); xx++) {
      if (this.battlegrid[xx] == undefined) this.battlegrid[xx] = [];
      for (let yy = 0; yy <= this.gridh; yy++) {
        this.battlegrid[xx][yy] = 1;
        var tmpg = wh.add.sprite(0, 0, "thesq", 'targetgreen');
        tmpg.name = 'battlefloorgrid-' + xx + '-' + yy;
        this.battlegridbtn[xx + '-' + yy] = tmpg;
        tmpg.setPosition(
          leftpos + battlegridsizex * xx,
          toppos + battlegridsizey * yy
        );
        tmpg.displayHeight = 4;
        tmpg.displayWidth = 4;
        wh.combatcontainer.add(tmpg);
        wh.combatcontainer.bringToTop(tmpg);
        tmpg.visible = false;
      }
    }


    wh.combatcontainer.bringToTop(this.mobsniffer);
    wh.combatcontainer.bringToTop(this.shine1);

    this.mobsniffer.visible = false;
    this.shine1.visible = false;

    this.shine1.displayWidth = this.shine1.displayHeight = 48;
    this.mobsniffer.displayWidth = this.mobsniffer.displayHeight = 48;
    this.shine1.setPosition(width - 52, height - 52);
    this.shine1.setOrigin(0, 0);
    this.mobsniffer.setPosition(width - 52, height - 52);

    this.plog.log('COMBAT start', wh);
    //this.plog.log('COMBAT battlegrid', this.battlegrid);
    //var tmpme = wh.registry.list.rcvarpass.activechar['cbt'];
    //this.plog.log("combat.partymember", this.partymember);
    if (this.partymember != undefined &&
      this.partymember.member != undefined) {
      this.plog.log("combat.partymember pass", this.partymember);
      for (const partyk in this.partymember.member) {
        if (this.partymember.member[partyk] != undefined) {
          this.partymember.member[partyk]["spawnpos"] = "";
          this.partymember.member[partyk]["cbt"]["spawnpos"] = "";
          this.partymember.member[partyk]["combatpos"] = "";
          this.partymember.member[partyk]["cbt"]["combatpos"] = "";
          //this.plog.log("loop partymember", partyk, this.partymember.member[partyk]);
          this.addplayertoteam(1, this.partymember.member[partyk]);
        }
      }
    }
    this.addplayertoteam(1, wh.registry.list.rcvarpass.activechar);
    var mobteamuse = wh.registry.list.rcvarpass.activemap['map']['mobteam'];
    if (
      this.config != undefined &&
      this.config['combatmode'] != undefined &&
      this.config['combatmode'] != ''
    ) {
      mobteamuse = this.config['data']['mobteam'];
    }

    if (this.config["combatmode"] == "pvp") {
      this.addplayertoteam(2, this.config["data"]);
    } else {
      this.addmapmobtoteam2(mobteamuse);
    }


    //this.plog.log('combatants', this.combatants);
    let loader = new Phaser.Loader.LoaderPlugin(this.wh);
    //this.plog.log("combatloader", this.wh, loader);
    if (this.wh.scene.scene.textures.list["battleground_default"] == undefined) {
      var tmpurl;
      if (this.isbadint == "yes") {
        //console.error("ISBADINT");
        tmpurl = this.wh.registry.list.rcvarpass.getlocal(
          '_badint/battleground/default.png'
        );
        loader.image(
          'battleground_default',
          tmpurl
        );
      } else {
        tmpurl = this.wh.registry.list.rcvarpass.getlocal(
          'battleground/default.png'
        );
        loader.image(
          'battleground_default',
          tmpurl
        );
      }
    }

    var tmpurl;
    if (this.isbadint == "yes") {
      tmpurl = this.wh.registry.list.rcvarpass.getlocal(
        '_badint/battleground/' +
        this.wh.registry.list.rcvarpass.activemap['map']['code'] +
        '.png'
      );
      loader.image(
        'battleground_' +
        this.wh.registry.list.rcvarpass.activemap['map']['code'],
        tmpurl
      );
    } else {
      tmpurl = this.wh.registry.list.rcvarpass.getlocal(
        'battleground/' +
        this.wh.registry.list.rcvarpass.activemap['map']['code'] +
        '.png'
      );
      loader.image(
        'battleground_' +
        this.wh.registry.list.rcvarpass.activemap['map']['code'],
        tmpurl
      );
    }

    //scan skill sprites
    var atlastoload = [];
    var mobtoload = [];
    var chartoload = [];
    atlastoload.push("racetag_b");

    atlastoload.push("effenergycharge6681_b"); // summoned explode by nekro 

    for (const key in this.combatants) {
      if (Object.prototype.hasOwnProperty.call(this.combatants, key)) {
        //check hp maxhp
        if (this.combatants[key]['hp'] == undefined) {
          this.combatants[key]['hp'] = this.combatants[key]['maxhp'];
        }
        //this.plog.error("zzz---",this.combatants[key]);
        //const cbti = this.combatants[key];
        this.combatants[key]['skilluse'] = [];
        if (
          this.combatants[key]['type'] == 'mob' ||
          this.combatants[key]['type'] == 'pet'
        ) {
          //this.plog.log(            'add sprite while scan skill mobtoload',            this.combatants[key],            this.combatants[key]['sprite']          );
          //this.plog.error("add mobtoload", key, this.combatants[key], this.combatants[key]['sprite'], this.combatants[key]['spriteorig']);
          mobtoload.push(this.combatants[key]['sprite']);
          atlastoload.push(this.combatants[key]['sprite']); // summoned explode by nekro 

          //mobtoload.push(this.combatants[key]['spriteorig']);

        }

        if (this.combatants[key]['type'] == 'char') {
          //this.plog.log(            'add sprite while scan skill mobtoload (char)',            this.combatants[key]['sprite']          );
          chartoload.push(this.combatants[key]['sprite']);
        }
        for (const skillk in this.combatants[key]['skill']) {
          var skilltmp = this.combatants[key]['skill'][skillk];
          //clone
          // const skilltmp = Object.assign({},this.combatants[key]['skill'][skillk]);
          //this.plog.log('loop skill for ', key, ' skill is ', skilltmp);
          //this.plog.log("loop skill ", this.wh.registry.list.rcvarpass);
          var tmpskillval = this.wh.registry.list.rcvarpass.skilltp[skilltmp];
          //this.plog.log('loop skill ', tmpskillval);
          if (tmpskillval == undefined) {
            this.plog.log("skillusexx-tmpskillval undefined", skilltmp);
          }
          if (tmpskillval == undefined) continue;
          this.combatants[key]['skilluse'].push(Object.assign({}, tmpskillval));//tmpskillval);
          if (tmpskillval['skilleffects']['sprite.aoe'] != undefined)
            atlastoload.push(tmpskillval['skilleffects']['sprite.aoe']);
          if (tmpskillval['skilleffects']['sprite.project'] != undefined)
            atlastoload.push(tmpskillval['skilleffects']['sprite.project']);
          if (tmpskillval['skilleffects']['sprite.target'] != undefined)
            atlastoload.push(tmpskillval['skilleffects']['sprite.target']);
          if (tmpskillval['skilleffects']['sprite.user'] != undefined)
            atlastoload.push(tmpskillval['skilleffects']['sprite.user']);
          if (tmpskillval['skilleffects']['sprite.weather'] != undefined)
            atlastoload.push(tmpskillval['skilleffects']['sprite.weather']);
          if (tmpskillval['skilleffects']['sprite.aoefloori'] != undefined)
            atlastoload.push(tmpskillval['skilleffects']['sprite.aoefloori']);
          if (
            tmpskillval['skilleffects']['spawn.code'] != undefined &&
            tmpskillval['skilleffects']['spawn.code'] != ''
          ) {
            var mobtospawn = tmpskillval['skilleffects']['spawn.code'];
            var mobtospawndata =
              this.wh.registry.list.rcvarpass.allmob[mobtospawn];
            //this.plog.log('ssssssss', mobtospawn, mobtospawndata);
            if (mobtospawndata != undefined)
              mobtoload.push(mobtospawndata['sprite']);
          }
        }
        this.combatants[key]['skillweaponuse'] = [];
        for (const skillk in this.combatants[key]['skillweapon']) {
          var skilltmp = this.combatants[key]['skillweapon'][skillk];
          //this.plog.log('loop skilllweapon for ', key, ' skill is ', skilltmp);
          //this.plog.log("loop skilllweapon ", this.wh.registry.list.rcvarpass);
          var tmpskillval =
            this.wh.registry.list.rcvarpass.skillweapontp[skilltmp];
          if (tmpskillval == undefined) continue;
          //this.plog.log('loop skilllweapon  ', tmpskillval);
          this.combatants[key]['skillweaponuse'].push(Object.assign({}, tmpskillval));
          if (tmpskillval['skilleffects']['sprite.aoe'] != undefined)
            atlastoload.push(tmpskillval['skilleffects']['sprite.aoe']);
          if (tmpskillval['skilleffects']['sprite.project'] != undefined)
            atlastoload.push(tmpskillval['skilleffects']['sprite.project']);
          if (tmpskillval['skilleffects']['sprite.target'] != undefined)
            atlastoload.push(tmpskillval['skilleffects']['sprite.target']);
          if (tmpskillval['skilleffects']['sprite.user'] != undefined)
            atlastoload.push(tmpskillval['skilleffects']['sprite.user']);
          if (tmpskillval['skilleffects']['sprite.weather'] != undefined)
            atlastoload.push(tmpskillval['skilleffects']['sprite.weather']);
          if (tmpskillval['skilleffects']['sprite.aoefloori'] != undefined)
            atlastoload.push(tmpskillval['skilleffects']['sprite.aoefloori']);
          if (tmpskillval['skilleffects']['spawn.code'] != undefined)
            mobtoload.push(tmpskillval['skilleffects']['spawn.code']);
        }
      }
    }
    this.plog.log('combatants', this.combatants);
    //this.plog.error('chkcatatlas', this.catteam1, this.catteam2);


    //this.plog.log("combatbeforeload",this.catteam1);

    var cct1 = 0; for (const ktmp in this.catteam1["01"]) cct1++;
    var cct2 = 0; for (const ktmp in this.catteam2["01"]) cct2++;
    if (cct1 > 0 || cct2 > 0) {
      atlastoload.push("ixbeff245_b"); //Bizkit tanker
      await this.wh.registry.list.phm.loadcatsprite("catset01"); //thecat
    }
    var cct1 = 0; for (const ktmp in this.catteam1["02"]) cct1++;
    var cct2 = 0; for (const ktmp in this.catteam2["02"]) cct2++;
    if (cct1 > 0 || cct2 > 0) {
      atlastoload.push("ixbeff29_b"); //misty ranger
      await this.wh.registry.list.phm.loadcatsprite("catset02"); //thecat
    }

    var cct1 = 0; for (const ktmp in this.catteam1["03"]) cct1++;
    var cct2 = 0; for (const ktmp in this.catteam2["03"]) cct2++;
    if (cct1 > 0 || cct2 > 0) {
      await this.wh.registry.list.phm.loadcatsprite("catset03"); //thecat
    }
    var cct1 = 0; for (const ktmp in this.catteam1["04"]) cct1++;
    var cct2 = 0; for (const ktmp in this.catteam2["04"]) cct2++;
    if (cct1 > 0 || cct2 > 0) {
      atlastoload.push("ixbeff256_b"); //luna nekro	
      await this.wh.registry.list.phm.loadcatsprite("catset04"); //thecat
    }

    var cct1 = 0; for (const ktmp in this.catteam1["05"]) cct1++;
    var cct2 = 0; for (const ktmp in this.catteam2["05"]) cct2++;
    if (cct1 > 0 || cct2 > 0) {
      atlastoload.push("ixbeff216_b"); //shadow magician
      await this.wh.registry.list.phm.loadcatsprite("catset05"); //thecat
    }

    atlastoload.push("ixbeff106_b");
    //this.plog.error('atlastoload', atlastoload);

    for (var key in atlastoload) {
      var atlasval = atlastoload[key];
      //this.plog.log('atlastoload ', key, atlasval);
      if (atlasval != undefined) {
        var animsdata = this.wh.registry.list.rcvarpass.allanims[atlasval];
        if (animsdata == undefined) {
          //this.plog.error('ERROR animsdata ', atlasval, ' failed to get data', this.wh.registry.list.rcvarpass.allanims);
        } else {
          this.animsloaded.push(atlasval);
          //this.plog.error('tmpatlastoload', atlasval, this.wh.registry.list.rcvarpass, animsdata);
          var tmpatlastoload = animsdata['sprite'];
          //this.plog.log('load atlas', tmpatlastoload, this.wh.registry.list.rcvarpass.rc_baseurl + 'sprite/' + tmpatlastoload + '.png', this.wh.registry.list.rcvarpass.rc_baseurl + 'sprite/' + tmpatlastoload + '.json'); // + Math.random());
          // + Math.random()
          if (this.wh.textures.list[tmpatlastoload] == undefined) {
            //this.plog.error('tmpatlastoload', atlasval, this.wh.registry.list.rcvarpass, animsdata);
            loader.atlas(
              tmpatlastoload + '',
              this.wh.registry.list.rcvarpass.rc_baseurl +
              'sprite/' +
              tmpatlastoload +
              '.png',
              this.wh.registry.list.rcvarpass.rc_baseurl +
              'sprite/' +
              tmpatlastoload +
              '.json'
            ); // + Math.random());
          } else {
            //this.plog.error('tmpatlastoloadSKIPEXISTS', tmpatlastoload, this.wh.textures.list[tmpatlastoload]);
          }
        }
      }
    }
    if (this.wh.textures.list['skill_tp'] == undefined) {
      loader.atlas('skill_tp', this.wh.registry.list.rcvarpass.rc_baseurl + 'sprite/skill_tp.png', this.wh.registry.list.rcvarpass.rc_baseurl + 'sprite/skill_tp.json');
    }
    if (this.wh.textures.list['skill_weapon_tp'] == undefined) {
      loader.atlas('skill_weapon_tp', this.wh.registry.list.rcvarpass.rc_baseurl + 'sprite/skill_weapon_tp.png', this.wh.registry.list.rcvarpass.rc_baseurl + 'sprite/skill_weapon_tp.json');
    }

    //this.plog.log("chartoload", chartoload);
    for (var key in chartoload) {
      var charval = chartoload[key];
      if (charval != undefined) {
        if (this.wh.textures.list[charval] == undefined) {
          loader.atlas(
            charval + '',
            this.wh.registry.list.rcvarpass.rc_baseurl +
            'sprite/' +
            charval +
            '.png?xxxx' +
            Math.random(),
            this.wh.registry.list.rcvarpass.rc_baseurl +
            'sprite/' +
            charval +
            '.json?xxxx' +
            Math.random()
          );
        }
        this.charloaded.push(charval);
      }
    }
    //this.plog.log('mobtoload', mobtoload);
    for (var key in mobtoload) {
      var mobval = mobtoload[key];
      if (mobval == "") continue;
      //this.plog.log('mobtoload i loop', key, mobval);
      if (mobval != undefined) {
        var mobdata = this.wh.registry.list.rcvarpass.allmob[mobval];
        //this.plog.log('mobtoload i loop', key, mobval);
        //if (mobdata == undefined) continue;//remove since sprite can not be match mobcode
        /*if (mobdata["sprite"] == "me") {
                    //continue;
                } else {
                }*/
        if (mobval == 'me') {
          continue;
        }
        //this.plog.log('mobtoload atlas', mobval, mobdata);
        //loader.atlas(mobdata["sprite"] + "", this.wh.registry.list.rcvarpass.rc_baseurl + "sprite/" + mobdata["sprite"] + ".png", this.wh.registry.list.rcvarpass.rc_baseurl + "sprite/" + mobdata["sprite"] + ".json");// + Math.random());
        //loader.atlas(mobdata["sprite"] + "", this.wh.registry.list.rcvarpass.rc_baseurl + "sprite/" + mobdata["sprite"] + ".png?" + Math.random(), this.wh.registry.list.rcvarpass.rc_baseurl + "sprite/" + mobdata["sprite"] + ".json?" + Math.random());
        //this.mobloaded.push(mobdata["sprite"]);

        if (this.wh.textures.list[mobval] == undefined) {
          loader.atlas(
            mobval + '',
            this.wh.registry.list.rcvarpass.rc_baseurl +
            'sprite/' +
            mobval +
            '.png?' +
            Math.random(),
            this.wh.registry.list.rcvarpass.rc_baseurl +
            'sprite/' +
            mobval +
            '.json?' +
            Math.random()
          );
        }
        this.mobloaded.push(mobval);
      }
    }

    loader.on(
      'progress',
      function (value) {
        //this.plog.log("loaderevent", value, loader.totalComplete, loader.totalToLoad);
        this.wh.registry.list.rcloading.loading2text(
          'Loading ' + loader.totalComplete + '/' + loader.totalToLoad
        );
        this.wh.registry.list.rcloading.loading2percent(
          value
        );
      },
      this
    );

    loader.once(Phaser.Loader.Events.COMPLETE, () => {
      this.plog.log('Phaser.Loader.Events.COMPLETE', this);
      //this.plog.log(this);
      this.start_loaded();
    });

    loader.start();
  }

  //Here's a JavaScript implementation of the Durstenfeld shuffle, an optimized version of Fisher-Yates:
  /* Randomize array in-place using Durstenfeld shuffle algorithm */
  shuffleArray(array) {
    for (var i = array.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1));
      var temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  }
  combattap() {
    //this.plog.log("combattap()", this,arguments);
    //eff16burstwithrandom4dkblue
    this.tapspritei++;
    this.tapsprite[this.tapspritei] = this.wh.add.sprite(arguments[0][0].x, arguments[0][0].y, "ixbeff106_b");
    this.tapsprite[this.tapspritei].play("ixbeff106_b");
    this.wh.combatcontainer.add(this.tapsprite[this.tapspritei]);
    this.wh.combatcontainer.bringToTop(this.tapsprite[this.tapspritei]);
    this.tapsprite[this.tapspritei].setPosition(arguments[0][0].x, arguments[0][0].y);
    var randomscale = (Math.random() * (3 - 1 + 1)) + 1;
    this.tapsprite[this.tapspritei].setScale(randomscale / 3);
    var tw = this.wh.tweens.add({
      targets: this.tapsprite[this.tapspritei],
      alpha: 0,
      ease: 'Power3',
      duration: 500, // duration of animation; higher=slower
      delay: 0, // wait 500 ms before starting
      onComplete: function () {
        //this.plog.log("combattap tweencomplete",this);
        this.targets[0].destroy();
      }, // set context? how?
    });
    //this.plog.log("combattap()", this.cbto);
    for (const key in this.cbto) {
      if (this.cbto[key] != undefined) {
        if (this.cbto[key]["cbt"]["team"] == 1) {
          //true || 
          this.cbto[key].addenergy(5, true);
          this.cbto[key].updatepgbar();
        }
      }
    }
    this.updateracetagline();
  }
  start_loaded() {
    this.plog.log('COMBAT start_loaded');
    this.plog.log("combatfoundcat start_loaded", this.catteam1, this.catteam2);

    this.closebtn.visible = true;
    this.beginbattlebtn.visible = true;

    var griddat = [];
    var griddatf = [];

    this.spdbtn1.visible = true;
    this.spdbtn2.visible = true;
    if (this.wh.registry.list.rcvarpass.activechar["level"] > 10)
      this.spdbtn4.visible = true;
    if (this.wh.registry.list.rcvarpass.activechar["level"] > 20)
      this.spdbtn8.visible = true;

    var width = this.wh.game.canvas.width;
    var height = this.wh.game.canvas.height;
    //this.plog.log('COMBAT start_loaded wh', this);
    //this.plog.log('COMBAT start_loaded w h', width, height);
    if (this.wh.registry.list.rcreq.chk({ stackitem1: "mobdetect", stackitem1_min: 1 })) {
      this.mobsniffer.visible = true;
      this.shine1.visible = true;
    }
    this.wh.combatcontainer.setDepth(120000);
    this.wh.combatcontainer.depth = 120000;
    this.wh.combatcontainer_reward.setDepth(150000);
    this.wh.combatcontainer_reward.depth = 150000;
    //this.plog.log("this.bg",this.bg);
    if (this.bg == undefined) {
      if (this.wh.scene.scene.textures.list["battleground_" +
        this.wh.registry.list.rcvarpass.activemap['map']['code']] == undefined) {
        this.bg = this.wh.add.image(
          0,
          0,
          'battleground_default'
        );
      } else {
        this.bg = this.wh.add.image(
          0,
          0,
          'battleground_' +
          this.wh.registry.list.rcvarpass.activemap['map']['code']
        );
      }
      this.bg.setInteractive();
      this.bg.removeAllListeners();
      this.bg.on("pointerdown", function () {
        this.combattap(arguments);

      }, this);
    }
    this.wh.combatcontainer.setPosition(0, 0);
    /*if (this.wh.registry.list.timectl.timename == "night") {
            this.bg.resetPipeline(true, true);
            this.bg.setPipeline("Light2D");
        } else {
            this.bg.resetPipeline(true, true);
        }*/
    var coloruse = 0xfffffff;
    if (this.wh.registry.list.timectl.timename == 'day') {
      coloruse = 0xffffff;
    }
    if (this.wh.registry.list.timectl.timename == 'morning') {
      coloruse = 0x6666ff;
    }
    if (this.wh.registry.list.timectl.timename == 'evening') {
      coloruse = 0xffff99;
    }
    if (this.wh.registry.list.timectl.timename == 'dusk') {
      coloruse = 0xbbbb00;
    }
    if (this.wh.registry.list.timectl.timename == 'night') {
      coloruse = 0x6666ff;
    }
    this.bg.setTint(coloruse);
    //this.timerclock.setPosition((width / 2) - (this.timerclock.displayWidth / 2), (this.timerclock.displayHeight / 2) + 5);
    this.timerclock.setPosition(3, -5);
    //this.totaldmgtxt.setPosition((width / 2) - (this.totaldmgtxt.displayWidth / 2), (this.totaldmgtxt.displayHeight / 2) + 30);
    this.totaldmgtxt.setPosition(width * 0.3, 1);
    //wh.combatcontainer.add(this.totaldmgtxt);

    this.bg.setOrigin(0.5, 0.5);
    this.wh.combatcontainer.add(this.bg);
    this.wh.combatcontainer.sendToBack(this.bg);
    /*if (width > height) {
      this.bg.displayHeight = height;
      this.bg.scaleX = this.bg.scaleY;
      if (this.bg.displayWidth < width) this.bg.displayWidth = width;
    } else {
      this.bg.displayWidth = width;
      this.bg.scaleY = this.bg.scaleX;
      if (this.bg.displayHeight < height) this.bg.displayHeight = height;
    }*/

    this.bg.setPosition(width / 2, height / 2);

    if (width > height) {
      this.bg.displayWidth = width + 5;
      this.bg.scaleY = this.bg.scaleX;
    } else {
      this.bg.displayHeight = height + 5;
      this.bg.scaleX = this.bg.scaleY;
    }
    //this.plog.log("COMBAT start_loaded bg", this.bg);

    //generate anims from loaded char atlas
    for (var key in this.charloaded) {
      var val = this.charloaded[key];

      var actarrc = [
        ['spellcast-u', 7],
        ['spellcast-d', 7],
        ['spellcast-l', 7],
        ['spellcast-r', 7],
        ['thrust-u', 8],
        ['thrust-d', 8],
        ['thrust-l', 8],
        ['thrust-r', 8],
        ['walk-u', 9],
        ['walk-d', 9],
        ['walk-l', 9],
        ['walk-r', 9],
        ['slash-u', 6],
        ['slash-d', 6],
        ['slash-l', 6],
        ['slash-r', 6],
        ['shoot-u', 13],
        ['shoot-d', 13],
        ['shoot-l', 13],
        ['shoot-r', 13],
        ['dead', 6] /*,
                ["atk-u", 6], ["atk-d", 6], ["atk-l", 6], ["atk-r", 6]*/,
      ];


      var spriteset = [
        { code: 'idle', framename: 'walk-d', fr: 1, end: 1 },
        { code: 'walk-d', framename: 'walk-d', fr: 16, end: 9 },
        { code: 'walk-u', framename: 'walk-u', fr: 16, end: 9 },
        { code: 'walk-l', framename: 'walk-l', fr: 16, end: 9 },
        { code: 'walk-r', framename: 'walk-r', fr: 16, end: 9 },
        { code: 'thrust-d', framename: 'thrust-d', fr: 8, end: 8 },
        { code: 'thrust-u', framename: 'thrust-u', fr: 8, end: 8 },
        { code: 'thrust-l', framename: 'thrust-l', fr: 8, end: 8 },
        { code: 'thrust-r', framename: 'thrust-r', fr: 8, end: 8 },
        { code: 'spellcast-d', framename: 'spellcast-d', fr: 7, end: 7 },
        { code: 'spellcast-u', framename: 'spellcast-u', fr: 7, end: 7 },
        { code: 'spellcast-l', framename: 'spellcast-l', fr: 7, end: 7 },
        { code: 'spellcast-r', framename: 'spellcast-r', fr: 7, end: 7 },
        { code: 'atk-d', framename: 'atk-d', fr: 6, end: 6 },
        { code: 'atk-u', framename: 'atk-u', fr: 6, end: 6 },
        { code: 'atk-l', framename: 'atk-l', fr: 6, end: 6 },
        { code: 'atk-r', framename: 'atk-r', fr: 6, end: 6 },
        { code: 'walk-d-idle', framename: 'idle-d', fr: 3, end: 3 },
        { code: 'walk-u-idle', framename: 'idle-u', fr: 3, end: 3 },
        { code: 'walk-l-idle', framename: 'idle-l', fr: 3, end: 3 },
        { code: 'walk-r-idle', framename: 'idle-r', fr: 3, end: 3 },
        { code: 'idle-d', framename: 'idle-d', fr: 3, end: 3 },
        { code: 'idle-u', framename: 'idle-u', fr: 3, end: 3 },
        { code: 'idle-l', framename: 'idle-l', fr: 3, end: 3 },
        { code: 'idle-r', framename: 'idle-r', fr: 3, end: 3 },
      ];

      for (var idx in spriteset) {
        var valspriteset = spriteset[idx];
        valspriteset['fr'] = Number(valspriteset['fr']);
        if (valspriteset['fr'] < 48) valspriteset['fr'] = 48; //test fix spd
        //this.plog.log("loadcharsprite", idx, val);
        if (this.wh.registry.list.rcvarpass.gameobj.textures.game.textures.list[val].frames[valspriteset['framename'] + '-1'] == undefined) {
          //this.plog.log("loadcharsprite_loaded xx skip, textures not found", val['framename'] + '-1');
          continue;
        }
        this.wh.registry.list.rcvarpass.gameobj.anims.remove(val + '-' + valspriteset['code']);
        this.wh.registry.list.rcvarpass.gameobj.anims.create({
          key: val + '-' + valspriteset['code'],
          frames: this.wh.registry.list.rcvarpass.gameobj.anims.generateFrameNames(val, {
            prefix: valspriteset['framename'] + '-',
            end: valspriteset['end'],
            zeroPad: 0,
            start: 1,
          }),
          repeat: -1,
          frameRate: valspriteset['fr'],
        });
      }
    }
    //this.plog.log("generate done xxxx", this.wh.registry.list.rcvarpass.gameobj.anims);
    //generate anims from loaded atlas
    //this.plog.log('generate from mobloaded', this.mobloaded);
    for (var key in this.mobloaded) {
      var val = this.mobloaded[key];

      var actarr = [
        'idle',
        'attack',
        'evade',
        'active',
        'spella',
        'bored',
        'chant',
        'spellb',
        'sleep',
        'guard',
        'seta',
        'dizzy',
        'damage',
        'chantb',
        'injure',
        'xxx',
        'chantc',
        'dead',
      ];

      for (var akey in actarr) {
        var action = actarr[akey];

        var tmpframe =
          this.wh.registry.list.rcvarpass.gameobj.anims.generateFrameNames(
            val,
            {
              prefix: action + '-',
              start: 1,
              end: 3,
              zeroPad: 0,
            }
          );
        //this.plog.log("mobchk loaded frames for " + action, mobchk, tmpframe);
        if (this.wh.anims.anims.entries['mob_' + val + '_' + action] == undefined) {
          var tmpf = this.wh.registry.list.rcvarpass.gameobj.anims.create({
            key: 'mob_' + val + '_' + action,
            frames: tmpframe,
            frameRate: 8,
            yoyo: true,
            repeat: 0,
          });
        }
      }
    }
    for (var key in this.animsloaded) {
      var val = this.animsloaded[key];
      var animsdata = this.wh.registry.list.rcvarpass.allanims[val];

      //this.plog.log("load2e anims loaded", key, val);
      var codea = animsdata['code'] + '';
      var codea2 = codea.split('_');
      //this.plog.log("load2 anims", codea2, key, val);
      var tmpframe =
        this.wh.registry.list.rcvarpass.gameobj.anims.generateFrameNames(
          animsdata['sprite'] + '',
          {
            prefix: codea2[1] + '-',
            start: 1,
            end: animsdata['num'],
            zeroPad: 0,
          }
        );
      //this.plog.error("load2e frames for " + key,val,animsdata['sprite'], tmpframe);
      if (this.wh.anims.anims.entries[val] == undefined) {
        var tmpf = this.wh.registry.list.rcvarpass.gameobj.anims.create({
          key: val + '',
          frames: tmpframe,
          frameRate: 16,
          repeat: -1,
        });
        //this.plog.error("load2e frames CREATING " + key,val,tmpf);

      }
      //this.plog.log("load2e res", tmpframe, tmpf);
      //this.plog.log("load2e anims", this.wh.registry.list.rcvarpass.gameobj.anims);
    }

    var team1count = 0;
    var team2count = 0;
    /*
        for (const key in this.combatants) {
            if (Object.prototype.hasOwnProperty.call(this.combatants, key)) {
                if (this.combatants[key]["team"] == 1) team1count++;
                if (this.combatants[key]["team"] == 2) team2count++;
            }
        }
        */

    //spawn pos team1
    var tmpteam1grid = [];
    for (let xx = 3; xx <= Math.floor(this.gridw / 2) - 2; xx++) {
      //if (tmpteam2grid[xx] == undefined) tmpteam2grid[xx] = [];
      for (let yy = 3; yy <= this.gridw - 3; yy++) {
        tmpteam1grid.push(xx + '-' + yy);
      }
    }
    var tmpteam2grid = [];
    for (let xx = Math.floor(this.gridw / 2) + 2; xx <= this.gridw - 2; xx++) {
      //if (tmpteam2grid[xx] == undefined) tmpteam2grid[xx] = [];
      for (let yy = 3; yy <= this.gridw - 3; yy++) {
        tmpteam2grid.push(xx + '-' + yy);
      }
    }
    // Shuffle array
    var tmpteam1grids = this.shuffleArray(tmpteam1grid);
    var tmpteam2grids = this.shuffleArray(tmpteam2grid);
    // Get sub-array of first n elements after shuffled
    //let team1locations = tmpteam1grids.slice(0, team1count);
    //let team2locations = tmpteam2grids.slice(0, team2count);
    //this.plog.log("team2locations", team2count, team2locations, tmpteam2grid,tmpteam2grids)
    var imob = 0;
    this.team1list = [];
    this.team2list = [];

    //validate s
    var cbteffka = ["acc", "combatrange", "crit", "def", "dodge", "hp", "level", "matk", "maxhp", "maxmp", "patk", "spd"];
    var skilleffka = ["status.amnt", "status.time", "a.energy", "dmg.amnt", "dmg.time", "aoe.amnt", "aoe.size", "aoe.time", "dmg.amnt", "dmg.times", "spawn.num", "target.knockback", "dmg.times"];

    for (const key in this.combatants) {
      if (Object.prototype.hasOwnProperty.call(this.combatants, key)) {

        //validate cbt items
        for (const keysk in cbteffka) {
          if (Object.prototype.hasOwnProperty.call(cbteffka, keysk)) {
            var keysv = cbteffka[keysk];
            if (this.combatants[key][keysv] != undefined) {
              this.combatants[key][keysv] = Number(this.combatants[key][keysv]);
              //this.plog.error("validatefix", key, keysk, this.combatants[key][keysv]);
            } else {
              //this.plog.error("validatefix NOTFOUND", key, keysv);
            }
          }
        }

        //validate skilleffects set Number()
        for (const SKkey in this.combatants[key]["skilluse"]) {
          if (Object.prototype.hasOwnProperty.call(this.combatants[key]["skilluse"], SKkey)) {
            //this.combatants[key]["skilluse"][SKkey]['level'] = Number(this.combatants[key]["skilluse"][SKkey]['level']);
            if (this.combatants[key]["skilluse"][SKkey]['skilleffects'] != undefined) {
              for (const keysk in skilleffka) {
                if (Object.prototype.hasOwnProperty.call(this.combatants[key]["skilluse"], SKkey)) {
                  if (this.combatants[key]["skilluse"][SKkey]['skilleffects'][skilleffka[keysk]] != undefined) {
                    this.combatants[key]["skilluse"][SKkey]['skilleffects'][skilleffka[keysk]] = Number(this.combatants[key]["skilluse"][SKkey]['skilleffects'][skilleffka[keysk]]);
                  }
                }
              }
            }
            //this.plog.log("validate skilluse", this.cbt["skilluse"][key]);

          }
        }
        for (const SKkey in this.combatants[key]["skillweaponuse"]) {
          if (Object.prototype.hasOwnProperty.call(this.combatants[key]["skillweaponuse"], SKkey)) {
            //this.combatants[key]["skillweaponuse"][key]['level'] = Number(this.combatants[key]["skillweaponuse"][SKkey]['level']);
            if (this.combatants[key]["skillweaponuse"][SKkey]['skilleffects'] != undefined) {
              for (const keysk in skilleffka) {
                if (Object.prototype.hasOwnProperty.call(this.combatants[key]["skillweaponuse"], keysk)) {
                  if (this.combatants[key]["skillweaponuse"][SKkey]['skilleffects'][skilleffka[keysk]] != undefined) {
                    this.combatants[key]["skillweaponuse"][SKkey]['skilleffects'][skilleffka[keysk]] = Number(this.combatants[key]["skillweaponuse"][SKkey]['skilleffects'][skilleffka[keysk]]);
                  }
                }
              }
            }
            //this.plog.log("validate skillweaponuse", this.cbt["skillweaponuse"][key]);
          }
        }
        //this.plog.error("VALIDATE", this.combatants[key]);

      }
    }
    //validate e

    //this.plog.log("mobteam2 tmpteam2grids", tmpteam2grids);
    //this.plog.log("mobteam2 tmpteam1grids", tmpteam1grids);
    var tmpspawnposloop;
    for (const key in this.combatants) {
      if (Object.prototype.hasOwnProperty.call(this.combatants, key)) {
        //const mob = this.combatants[key];
        //clone
        const mob = Object.assign({}, this.combatants[key]);

        //this.plog.log("mobteam2 loopstart chk", key, mob['spawnpos'], mob);
        tmpspawnposloop = "";
        // this.plog.log('creatingCombatant ', mob);
        //if (mob["team"] == 1) {
        //this.plog.log('mob', imob, mob);
        if (mob['team'] == 1) {
          tmpspawnposloop = "";
          tmpspawnposloop = tmpteam1grids[team1count] + "";
          if (mob['spawnpos'] != undefined && mob['spawnpos'] != '') {
            tmpspawnposloop = mob['spawnpos'] + "";
          }
          //this.plog.log("mobteam2!! 1!  setpos chk", tmpspawnposloop);
          team1count++;
        }
        if (mob['team'] == 2) {
          tmpspawnposloop = "";
          // this.plog.log('mobteam2', imob, mob);
          if (this.config['combatmode'] == "") {
            if (mob['spawnpos'] != undefined && mob['spawnpos'] != "undefined" && mob['spawnpos'] != "undefined-" && mob['spawnpos'] != '') {
              this.plog.log("mobteam2  combatmode", this.config['combatmode']);

              //if (this.config['combatmode'] == "") {
              //fight mapmob
              const tmpstr = mob['spawnpos'].toString() + "";
              //this.plog.log("mobteam2  setpos b", tmpstr);
              var tmpspawnposloopa;
              tmpspawnposloopa = "";
              tmpspawnposloopa = tmpstr.split("-");
              tmpspawnposloopa[0] = Number(tmpspawnposloopa[0]) + 0;
              tmpspawnposloopa[1] = Number(tmpspawnposloopa[1]) + 0;
              //this.plog.log("mobteam2  tmpspawnposloopa", tmpspawnposloopa);
              tmpspawnposloop = (tmpspawnposloopa[0]) + "-" + (tmpspawnposloopa[1]);
              //this.plog.log("mobteam2  setpos a", tmpspawnposloop);
              /*} else {
                //pvp
                tmpspawnpos = this.reversespawnpos(mob['spawnpos']);
                this.plog.log("mobteam2  setpos combatmode " + this.config['combatmode'], tmpspawnpos);
              }*/
              //tmpspawnpos = this.reversespawnpos(mob['spawnpos']);
            } else {
              tmpspawnposloop = tmpteam2grids[team2count] + ""
            }
          } else {
            //tmpspawnposloop = tmpteam2grids[team2count] + "";
            //this.plog.log("mobteam2  use default spawnpos", tmpspawnposloop);
            if (mob['spawnpos'] != undefined && mob['spawnpos'] != "") {
              tmpspawnposloop = this.reversespawnpos(mob['spawnpos'] + "");
            } else {
              tmpspawnposloop = tmpteam2grids[team2count] + ""
            }
          }
          team2count++;
        }

        //this.plog.log("mobteam2  tmpspawnpos prefinal", tmpspawnposloop);
        if (Array.isArray(tmpspawnposloop)) {
          this.plog.log("mobteam2-  convert array to strin", tmpspawnposloop);
          tmpspawnposloop = tmpspawnposloop[0] + "-" + tmpspawnposloop[1];
        }
        //this.plog.log("mobteam2  tmpspawnpos final", mob['team'], tmpspawnposloop);
        var tmprandom = Math.random();
        var tmp = new COMBATANT(
          this,
          this.wh,
          Object.assign({}, mob),// mob,
          this.posinfo,
          tmpspawnposloop + "",
          tmprandom
        );

        tmp.objid = this.cbto.length; //key;
        tmp.dbid = mob.dbid; //key;
        this.cbto[tmp.objid] = tmp;
        if (mob['team'] == 1) {
          this.team1list.push(tmp);
        }
        if (mob['team'] == 2) {
          this.team2list.push(tmp);
        }
        imob++;
        //}
      }
    } /*
        var imob = 0;
        for (const key in this.combatants) {
            if (Object.prototype.hasOwnProperty.call(this.combatants, key)) {
                const mob = this.combatants[key];
                if (mob["team"] == 2) {
                    //this.plog.log("mob",imob,mob);
                    //tmp["spawnpos"] = team2locations[imob];
                    var tmprandom=Math.random();
                    var tmp = new COMBATANT(this, this.wh, mob, this.posinfo, team2locations[imob],tmprandom);
                    tmp.objid = this.cbto.length+1;//key;
                    //tmp.aaorigid=tmp.objid;
                    this.cbto[tmp.objid] = tmp;
                    imob++;
                }
            }
        }*/
    //this.plog.log('cbto', this.cbto);
    //place combatants
    for (const key in this.cbto) {
      if (Object.prototype.hasOwnProperty.call(this.cbto, key)) {
        const cbti = this.cbto[key];
        //this.plog.log('place cbt', cbti, key);
        if (cbti == undefined) continue;
        cbti.createSprite();
      }
    }
    //return;
    this.wh.combatcontainer.bringToTop(this.selectedchar_eff);
    this.setzindex();
    //add event for prepare stage
    for (const key in this.cbto) {
      if (Object.prototype.hasOwnProperty.call(this.cbto, key)) {
        if (this.cbto[key]['cbt']['team'] == 1) {
          //this.plog.log('foundteam1', this.cbto[key]['cbt']);
          this.cbto[key].sprite.setInteractive(this.wh.input.makePixelPerfect(150));
          this.cbto[key].sprite.removeAllListeners();
          this.cbto[key].sprite.on(
            'pointerdown',
            function () {
              this.pointerdownteam1(key);
            },
            this
          );
        }
      }
    }
    //create combatants
    /*
        this.combatants[this.combatants.length] = this.createcombatants("gamechar", this.wh.registry.list.rcvarpass.activechar["id"]);
        var imob=0;
        for (var key in this.wh.registry.list.rcvarpass.activechar["pets"]) {
            var petdata = this.wh.registry.list.rcvarpass.activechar["pets"][key];
            //var tmp=this.createcombatants("pet", petdata["id"]);
            tmp["spawnpos"]=team2locations[imob];
            this.cbto[this.combatants.length] = tmp;
            imob++;
        }*/
    this.wh.combatcontainer.bringToTop(this.closebtn);
    this.wh.combatcontainer.bringToTop(this.spdbtn1);
    this.wh.combatcontainer.bringToTop(this.spdbtn2);
    this.wh.combatcontainer.bringToTop(this.spdbtn4);
    this.wh.combatcontainer.bringToTop(this.spdbtn8);
    this.closebtn.setPosition(45, height - 60);
    this.spdbtn1.setPosition(45, height - 150 + 10);
    this.spdbtn2.setPosition(45, height - 200 + 10);
    this.spdbtn4.setPosition(45, height - 250 + 10);
    this.spdbtn8.setPosition(45, height - 300 + 10);
    this.closebtn.displayHeight = this.closebtn.displayWidth = 80;
    this.spdbtn1.displayHeight = this.spdbtn1.displayWidth = 50;
    this.spdbtn2.displayHeight = this.spdbtn2.displayWidth = 50;
    this.spdbtn4.displayHeight = this.spdbtn4.displayWidth = 50;
    this.spdbtn8.displayHeight = this.spdbtn8.displayWidth = 50;

    this.wh.combatcontainer.bringToTop(this.beginbattlebtn);
    this.beginbattlebtn.setPosition(width / 2, height - 55);
    this.beginbattlebtn.displayWidth = 200;
    this.beginbattlebtn.scaleY = this.beginbattlebtn.scaleX;

    this.wh.combatcontainer.bringToTop(this.timerclock);

    this.createlogtext();
    this.createpanel();
    this.createpanelteam2();
    this.createcatpanel();
    this.battletick_followset();

    for (const key in this.cbto) {
      if (Object.prototype.hasOwnProperty.call(this.cbto, key)) {
        if (this.cbto[key]['cbt']['team'] == 1) {
          this.cbto[key].updatepgbar();
        }
      }
    }
    this.wh.registry.list.rcloading.close();
    if (this.optionparam == 'mobinitcombat') {
      //this.plog.log("combat mobinitcombat");
      this.closebtn.visible = false;
      this.beginbattle();
    }
    this.wh.combatcontainer.bringToTop(this.beginbattlebtn);
    this.updateracetagline();

    //test
    this.wh.registry.list.gameitf.savesnapshot(this.wh, "precombat", this.combattoken);

    griddat.push(this.closebtn);
    griddat.push(this.spdbtn1);
    griddat.push(this.spdbtn2);
    griddat.push(this.spdbtn4);
    griddat.push(this.spdbtn8);
    griddat.push(this.beginbattlebtn);
    griddat.push(this.mobsniffer);
    for (const key in this.cbto) {
      if (Object.prototype.hasOwnProperty.call(this.cbto, key)) {
        if (this.cbto[key]['cbt']['team'] == 1) {
          griddat.push(this.cbto[key].sprite);
        }
      }
    }

    griddatf.push(this.beginbattlebtn);
    this.wh.registry.list.gp.guigrid(griddat, griddatf);
    this.wh.registry.list.tut.show(this.wh, "firstcombat");

  }
  createlogtext() {
    return;/////xxxx
    var logwidth = 300;
    var lineheight = 35;
    var width = this.wh.game.canvas.width;
    if (width > 700) {
      logwidth = (width - 100);
    }
    var height = this.wh.game.canvas.height;
    var xpos = width - (logwidth);
    var ypos = height - (lineheight * this.logtextdspcount);
    //height = height - (lineheight * this.logtextdspcount);
    if (width < 400) xpos = width - logwidth;
    for (var i = 0; i <= this.logtextdspcount; i++) {
      if (this.logtextdsp[i] != undefined) {
        this.logtextdsp[i].destroy();
        this.logtextdsp[i] = undefined;
      }
      this.logtextdsp[i] = this.wh.add.text(0, 0, " ", this.wh.fs["logtext_0"]);
      if (width > 700) {
        this.logtextdsp[i].setStyle({ fixedWidth: logwidth });
        this.logtextdsp[i].setWordWrapWidth(logwidth);;
      }

      this.wh.combatcontainer.add(this.logtextdsp[i]);
      this.wh.combatcontainer.bringToTop(this.logtextdsp[i]);
      this.logtextdsp[i].setPosition(xpos, ypos + (lineheight * i));
    }
  }
  updatelogtext() {

    return;/////xxxx
    var width = this.wh.game.canvas.width;
    var logwidth = 300;
    if (width > 700) {
      logwidth = (width - 100);
    }
    //this.plog.log("updatelogtext", this.logtext, this.logtextdsp);
    for (var i = 0; i <= this.logtextdspcount; i++) {
      var txt = this.logtext[(this.logtext.length - (i + 1))];
      //this.plog.log("updatelogtext", i, txt, this.logtextdsp[i]);
      if (this.logtextdsp[i] == undefined) continue;
      if (this.logtextdsp[i] == null) continue;
      if (txt == undefined) continue;
      var txta = txt.split(":::");
      this.logtextdsp[i].setText(txta[1]);
      this.logtextdsp[i].setStyle(this.wh.fs["logtext_" + txta[0]]);
      if (width > 700) {
        this.logtextdsp[i].setStyle({ fixedWidth: logwidth });
        this.logtextdsp[i].setWordWrapWidth(logwidth);;
      }
    }
  }
  createpanel() {
    var space = 8;
    var width = this.wh.game.canvas.width;
    var height = this.wh.game.canvas.height;
    //var boxw = 50;
    var boxw = Math.floor((width - 100) / (this.team1list.length + 1));
    if (this.team2list.length * 72 < height * 0.6) {
      //use full w if team 2 takes up leass than 50% scr height
      var boxw = Math.floor((width - 10) / (this.team1list.length + 1));
    }
    //this.plog.error("boxw", boxw);
    //if (width > 500) boxw = 60;
    if (boxw > 100) boxw = 100;
    var toppos = 20;
    var leftpos = space * 2;
    var i = 0;
    this.plog.log('createpanel this.team1list', this.team1list);
    for (const key in this.team1list) {
      if (Object.prototype.hasOwnProperty.call(this.team1list, key)) {
        //var item = this.team1list[key];
        //clone
        const item = Object.assign({}, this.team1list[key]);
        var useanimkey = "";
        //[0].sprite.texture.key
        //this.plog.log("mincombatchk", item.sprite.texture.key, item.spriteorig, item.sprite);
        //this.plog.error("mincombatchk", item.sprite.texture.key, item.spriteorig, item.sprite);
        if (item.sprite.anims.currentAnim == undefined || item.sprite.anims.currentAnim == null) {
          if (item.cbt["type"] == "char") {
            useanimkey = item.spriteorig + "-walk-l-idle";
          } else {
            useanimkey = "mob_" + item.spriteorig + "_idle";
          }
        } else {
          useanimkey = item.spriteorig;//item.sprite.anims.currentAnim.key;
          //useanimkey = item.spriteorig.anims.currentAnim.key;
          //this.plog.log("mincombatchk HERE",useanimkey);
        }

        var icon =
          this.wh.registry.list.rcvarpass.gameobj.scene.scenes[0].add.sprite(
            100,
            100,
            useanimkey + ''
          );
        //this.plog.error("useanimkey", useanimkey,icon);

        var iconbg = this.wh.registry.list.rcvarpass.gameobj.scene.scenes[0].add.sprite(
          100,
          100,
          'justcircle'
        );
        iconbg.setAlpha(0.7);

        var iconbg2 =
          this.wh.registry.list.rcvarpass.gameobj.scene.scenes[0].add.sprite(
            100,
            100,
            'thecolor', 'white'
          );//item.sprite.anims.currentAnim.key
        iconbg2.setAlpha(0.3);
        iconbg2.setOrigin(0, 0);
        //this.plog.log('createpanel icon', icon);
        if (item.cbt['type'] == "char") {
          icon.play({ key: item.spriteorig + "-walk-r-idle", repeat: 1 });
          //this.plog.error("chariconmincombat",icon,item.spriteorig);
        } else {
          icon.play({ key: "mob_" + useanimkey + "_idle", repeat: 1 });
        }
        if (icon.anims.currentAnim != undefined) {
          //this.plog.log('createpanel iconpause', icon, icon.anims.currentAnim);
        } else {
          //this.plog.log('createpanel iconpause no currentAnim', icon, item);
        }
        icon.setOrigin(0.5, 0.5);
        icon.flipX = true;
        icon.displayWidth = 64;
        icon.scaleY = icon.scaleX;
        icon.x = leftpos + boxw * i + boxw / 2 + space * i;
        icon.y = toppos + 64;
        iconbg2.x = (leftpos + boxw * i + space * i) - 2;
        iconbg2.y = icon.y + 16;
        iconbg2.displayWidth = boxw + 4;

        iconbg.x = icon.x; iconbg.y = icon.y;
        iconbg.displayWidth = iconbg.displayHeight = 54;
        //icon.anims.pause(icon.anims.currentAnim.frames[0]);
        //this.plog.error("combat.ts useanimkey", icon, useanimkey);

        //icon.anims.pause(icon.anims.animationManager.anims.entries[useanimkey].frames[0]);
        // icon.anims.pause(icon.anims.animationManager.anims.entries[useanimkey].frames[0]);
        if (item['cbt']['type'] != 'char') icon.y = icon.y + 16;
        //this.plog.log("xxxx", item.cbt);
        this.wh.combatcontainer.add(iconbg2);
        this.wh.combatcontainer.bringToTop(iconbg2);
        this.wh.combatcontainer.add(iconbg);
        this.wh.combatcontainer.bringToTop(iconbg);
        this.wh.combatcontainer.add(icon);
        this.wh.combatcontainer.bringToTop(icon);
        ///icon.destroy();

        var racexi = this.wh.registry.list.rcvarpass.gameobj.scene.scenes[0].add.sprite(
          100,
          100,
          'racetag', 'b-' + item.cbt['race']
        );
        //racexi.play("b-1");
        racexi.displayWidth = 32;
        racexi.scaleY = racexi.scaleX;
        if (item['cbt']['type'] == 'char') {
          racexi.setPosition(icon.x + 20, icon.y - 32);
        } else {
          racexi.setPosition(icon.x + 20, icon.y - (32 + 16));
        }
        //this.plog.log("racexi team1", item.cbt['race'], this.wh.registry.list.rcvarpass.allrace[item.cbt['race']])
        if (this.wh.registry.list.rcvarpass.allrace[item.cbt['race']] != undefined) {
          racexi.name = this.wh.registry.list.rcvarpass.allrace[item.cbt['race']].name + " lv." + item.cbt['level'];

          racexi.setInteractive();
          racexi.on('pointerover', function () {
            //this.plog.log("racexi",this,arguments);
            this.scene.registry.list.gameitf.gamemenutooltip(this.scene, this, '' + this.name, 'bottom');
          });
        }

        this.wh.combatcontainer.add(racexi);
        this.wh.combatcontainer.bringToTop(racexi);

        //this.plog.log('createpanel', key, item.sprite);
        this.team1list[key].racetag = racexi;
        this.team1list[key].icon = icon;
        this.team1list[key].pgbar_health = new PGBar(
          this.wh.registry.list.rcvarpass.gameobj.scene.scenes[0],
          {
            x: leftpos + boxw * i + space * i,
            y: toppos + 75,
            w: boxw,
            h: 12,
            max: item.cbt['maxhp'],
            me: item.cbt['hp'],
            barcol1: 0x24ff2b,
            barcol2: 0x00d407,
            txt: 'HP',
          }, "img"
        );
        this.team1list[key].pgbar_health.draw();
        item.pgbar_health = this.team1list[key].pgbar_health
        this.wh.combatcontainer.add(item.pgbar_health.bar);
        this.wh.combatcontainer.bringToTop(item.pgbar_health.bar);
        item.pgbar_health.draw();
        this.wh.combatcontainer.add(item.pgbar_health.txt);
        this.wh.combatcontainer.bringToTop(item.pgbar_health.txt);
        item.pgbar_health.bar.setPosition(
          leftpos + boxw * i + space * i,
          toppos + 75
        );
        item.pgbar_health.txt.setPosition(
          item.pgbar_health.bar.x + 2,
          item.pgbar_health.bar.y + 2
        );
        if (this.mincombat == "yes") {
          item.pgbar_health.bar.destroy();
        }
        if (this.mincombat != "yes") {
          var skillh = 0;
          //skill
          var skilli = 0;
          for (const key in item.cbt['skilluse']) {
            if (Object.prototype.hasOwnProperty.call(item.cbt['skilluse'], key)) {
              if (item.cbt['skilluse'][key]['skill_type'] == 'passive') continue;
              skilli++;
              //var skillistr="_"+skilli;
              skillh = skillh + 15;

              item.cbt['skilluse'][key].bar = new PGBar(
                this.wh.registry.list.rcvarpass.gameobj.scene.scenes[0],
                {
                  x: leftpos + boxw * i + space * i,
                  y: toppos + 75 + skillh,
                  w: boxw,
                  h: 12,
                  imgcol: "red",
                  max: item.cbt['skilluse'][key]['skilleffects']['a.energy'],
                  me: item.cbt['skilluse'][key]['skilleffects']['a.energy'] / 2,
                  barcol1: 0xff4f38,
                  barcol2: 0xcc2610,
                  txt:
                    'S:' +
                    item.cbt['skilluse'][key]['name'] +
                    ' ' +
                    item.cbt['skilluse'][key]['skilleffects']['a.energy'],
                }, "img"
              );
              item.cbt['skilluse'][key].bar.draw();

              this.wh.combatcontainer.add(item.cbt['skilluse'][key].bar.bar);
              this.wh.combatcontainer.bringToTop(
                item.cbt['skilluse'][key].bar.bar
              );

              item.cbt['skilluse'][key].bar.bar.setAlpha(0.3);
              item.cbt['skilluse'][key].bar.barfg.setAlpha(0.8);
              item.cbt['skilluse'][key].bar.draw();
              this.wh.combatcontainer.add(item.cbt['skilluse'][key].bar.txt);
              this.wh.combatcontainer.bringToTop(
                item.cbt['skilluse'][key].bar.txt
              );

              item.cbt['skilluse'][key].bar.bar.setPosition(
                leftpos + boxw * i + space * i,
                toppos + 75 + skillh
              );
              item.cbt['skilluse'][key].bar.txt.setPosition(
                item.cbt['skilluse'][key].bar.bar.x + 2,
                item.cbt['skilluse'][key].bar.bar.y + 2
              );
            }
          }

          //var skillh=0;
          //skill
          for (const key in item.cbt['skillweaponuse']) {
            if (
              Object.prototype.hasOwnProperty.call(
                item.cbt['skillweaponuse'],
                key
              )
            ) {
              if (item.cbt['skillweaponuse'][key]['skill_type'] == 'passive')
                continue;
              skillh = skillh + 15;

              item.cbt['skillweaponuse'][key].bar = new PGBar(
                this.wh.registry.list.rcvarpass.gameobj.scene.scenes[0],
                {
                  x: leftpos + boxw * i + space * i,
                  y: toppos + 75 + skillh,
                  w: boxw,
                  h: 12,
                  imgcol: 'orange',
                  max: item.cbt['skillweaponuse'][key]['skilleffects'][
                    'a.energy'
                  ],
                  me:
                    item.cbt['skillweaponuse'][key]['skilleffects']['a.energy'] /
                    2,
                  barcol1: 0xebb73f,
                  barcol2: 0xde9d07,
                  txt:
                    'W:' +
                    item.cbt['skillweaponuse'][key]['name'] +
                    ' ' +
                    item.cbt['skillweaponuse'][key]['skilleffects']['a.energy'],
                }, "img"
              );


              this.wh.combatcontainer.add(item.cbt['skillweaponuse'][key].bar.bar);
              this.wh.combatcontainer.bringToTop(item.cbt['skillweaponuse'][key].bar.bar);
              item.cbt['skillweaponuse'][key].bar.draw();
              this.wh.combatcontainer.add(item.cbt['skillweaponuse'][key].bar.txt);
              this.wh.combatcontainer.bringToTop(item.cbt['skillweaponuse'][key].bar.txt);

              item.cbt['skillweaponuse'][key].bar.bar.setPosition(
                leftpos + boxw * i + space * i,
                toppos + 75 + skillh
              );
              item.cbt['skillweaponuse'][key].bar.txt.setPosition(
                item.cbt['skillweaponuse'][key].bar.bar.x + 2,
                item.cbt['skillweaponuse'][key].bar.bar.y + 2
              );

              item.cbt['skillweaponuse'][key].bar.bar.setAlpha(0.3);
              item.cbt['skillweaponuse'][key].bar.barfg.setAlpha(0.8);
            }
          }
        }
        i++;

        iconbg2.displayHeight = 14 + skillh;

        if (this.mincombat == "yes") {
          iconbg2.destroy();
        }
      }

    }

    for (const key in this.team1list) {
      if (Object.prototype.hasOwnProperty.call(this.team1list, key)) {
        const item = Object.assign({}, this.team1list[key]);

        if (this.mincombat == "yes") {
          item.pgbar_health.txt.y = item.pgbar_health.txt.y - 5;
        }
      }
    }
  }

  createpanelteam2() {
    var width = this.wh.game.canvas.width;
    var height = this.wh.game.canvas.height;

    var space = 12;
    var hspace = 60;
    var boxw = 80;
    var toppos = 120;
    var leftpos = width - (boxw + space);
    var i = 0;
    toppos = height - (this.team2list.length * (hspace + space) + 80)
    var panelbg =
      this.wh.registry.list.rcvarpass.gameobj.scene.scenes[0].add.sprite(
        100,
        100,
        'thecolor', 'white'
      );
    this.wh.combatcontainer.add(panelbg);
    this.wh.combatcontainer.bringToTop(panelbg);
    panelbg.setAlpha(0.3);
    panelbg.setOrigin(0, 0);
    panelbg.displayWidth = 500;
    panelbg.displayHeight = (this.team2list.length * hspace) + 10;
    panelbg.y = toppos + 25;
    panelbg.x = leftpos - 28;


    this.plog.log('createpanelteam2', this.team2list);
    for (const key in this.team2list) {
      if (Object.prototype.hasOwnProperty.call(this.team2list, key)) {
        i++;
        var item = this.team2list[key];
        //this.plog.log('createpanelteam2 item', item);
        var icon =
          this.wh.registry.list.rcvarpass.gameobj.scene.scenes[0].add.sprite(
            100,
            100,
            item.spriteorig + ''
          );
        var iconbg =
          this.wh.registry.list.rcvarpass.gameobj.scene.scenes[0].add.sprite(
            100,
            100,
            'thecolor', 'white'
          );//item.sprite.anims.currentAnim.key
        iconbg.setAlpha(0.3);
        iconbg.setOrigin(0, 0.5);
        icon.play({ key: item.spriteorig, repeat: 1 });
        //icon.play(item.sprite.anims.currentAnim.key);
        icon.setOrigin(0.5, 0.5);
        icon.flipX = false;
        //this.plog.log("xxxx", item.cbt);
        this.wh.combatcontainer.add(iconbg);
        this.wh.combatcontainer.bringToTop(iconbg);
        this.wh.combatcontainer.add(icon);
        this.wh.combatcontainer.bringToTop(icon);

        //this.plog.log("createpanelteam2", key, item.sprite);
        item.pgbar_health = new PGBar(
          this.wh.registry.list.rcvarpass.gameobj.scene.scenes[0],
          {
            x: leftpos,
            y: toppos + hspace * i - 5,
            w: boxw,
            h: 12,
            max: item.cbt['maxhp'],
            me: item.cbt['hp'],
            imgcol: 'green',
            barcol1: 0x24ff2b,
            barcol2: 0x00d407,
            txt: 'HP',
          }, "img"
        );
        item.pgbar_health.draw();
        item.pgbar_health.setmax();


        //icon.anims.pause(icon.anims.currentAnim.frames[0]);
        if (item.cbt['type'] == "char") {
          //icon.anims.pause(icon.anims.currentAnim.frames[0]);
          icon.anims.play({ key: item.spriteorig + "-walk-r-idle", repeat: 1 });
        } else {
          icon.anims.play({ key: "mob_" + item.spriteorig + "_idle", repeat: 1 });
        }

        icon.displayWidth = 32;
        icon.scaleY = icon.scaleX;
        icon.x = leftpos;
        icon.y = toppos + hspace * i;
        this.wh.combatcontainer.add(item.pgbar_health.bar);
        this.wh.combatcontainer.add(item.pgbar_health.barfg);
        this.wh.combatcontainer.bringToTop(item.pgbar_health.bar);
        this.wh.combatcontainer.bringToTop(item.pgbar_health.barfg);
        this.wh.combatcontainer.add(item.pgbar_health.txt);
        this.wh.combatcontainer.bringToTop(item.pgbar_health.txt);
        //this.plog.log('createpanelteam2 pos', leftpos, toppos + hspace * i - 5);
        //item.pgbar_health.bar.setPosition(leftpos, toppos + hspace * i - 5);
        //console.error(item);
        item.pgbar_health.txt.setPosition(
          item.pgbar_health.bar.x + 2,
          item.pgbar_health.bar.y + 2
        );
        var namet = this.wh.add.text(0, 0, item.name, { fontFamily: 'uuuu', fontSize: '6px', stroke: '#f00', strokeThickness: 0, color: '#000', fontStyle: 'bold' });
        namet.setPosition(
          item.pgbar_health.bar.x + 15,
          item.pgbar_health.bar.y - 15
        );
        this.wh.combatcontainer.add(namet);
        this.wh.combatcontainer.bringToTop(namet);

        iconbg.x = icon.x - 20;
        iconbg.y = icon.y;
        iconbg.displayWidth = 500;
        iconbg.displayHeight = 54;
        if (this.mincombat == "yes") {
          item.pgbar_health.bar.destroy();
          item.pgbar_health.destroy();
        }
        var racexi = this.wh.registry.list.rcvarpass.gameobj.scene.scenes[0].add.sprite(
          100,
          100,
          'racetag', 'b-' + item.cbt['race']
        );
        //racexi.play("b-1");
        racexi.displayWidth = 32;
        racexi.scaleY = racexi.scaleX;
        racexi.setPosition(icon.x - 20, icon.y + 0);
        this.wh.combatcontainer.add(racexi);
        this.wh.combatcontainer.bringToTop(racexi);
        racexi.name = this.wh.registry.list.rcvarpass.allrace[item.cbt['race']].name + " lv." + item.cbt['level'];
        racexi.setInteractive();
        racexi.on('pointerover', function () {
          //this.plog.log("racexi",this,arguments);
          this.scene.registry.list.gameitf.gamemenutooltip(this.scene, this, '' + this.name, 'left');
        });

        this.team2list[key].racetag = racexi;
        this.team2list[key].icon = icon;

        var skillx = 0;
        //skill
        var skilli = 0;
        var addypos = 0;
        for (const key in item.cbt['skilluse']) {
          if (Object.prototype.hasOwnProperty.call(item.cbt['skilluse'], key)) {
            if (item.cbt['skilluse'][key]['skill_type'] == 'passive') continue;
            skillx = skillx + 15;

            var iconuse = item.cbt['skilluse'][key]['code'] + "";
            iconuse = iconuse.replace("-", "");
            item.cbt['skilluse'][key].icon = this.wh.add.sprite(0, 0, "skill_tp", iconuse).setOrigin(0, 0);
            item.cbt['skilluse'][key].iconbar = this.wh.add.sprite(0, 0, "thecolor", "green").setOrigin(0, 0);

            item.cbt['skilluse'][key].icon.displayWidth = item.cbt['skilluse'][key].icon.displayHeight = 15;
            item.cbt['skilluse'][key].iconbar.displayWidth = 1;
            item.cbt['skilluse'][key].iconbar.displayHeight = 3;
            this.wh.combatcontainer.add(item.cbt['skilluse'][key].icon);
            this.wh.combatcontainer.bringToTop(item.cbt['skilluse'][key].icon);

            this.wh.combatcontainer.add(item.cbt['skilluse'][key].iconbar);
            this.wh.combatcontainer.bringToTop(item.cbt['skilluse'][key].iconbar);

            item.cbt['skilluse'][key].icon.setPosition(
              leftpos + skillx - 10,
              toppos + hspace * i - 5 + 12 + addypos
            );
            item.cbt['skilluse'][key].iconbar.setPosition(
              leftpos + skillx - 10,
              toppos + hspace * i - 5 + 12 + addypos + 12
            );
            skilli++;
            if (skilli == 5) {
              skilli = 0;
              skillx = 0;
              addypos = addypos + 15;
            }
          }
        }

        //skillx = 0;
        //skillweaponuse

        for (const key in item.cbt['skillweaponuse']) {
          if (Object.prototype.hasOwnProperty.call(item.cbt['skillweaponuse'], key)) {
            if (item.cbt['skillweaponuse'][key]['skill_type'] == 'passive') continue;
            /*if (item.cbt['team'] == 2) {
              this.plog.error("skill_weapon_tp team2 create", item.cbt['skillweaponuse'][key],skilli);
            }*/
            skillx = skillx + 15;

            var iconuse = item.cbt['skillweaponuse'][key]['code'] + "";
            iconuse = iconuse.replace("-", "");
            item.cbt['skillweaponuse'][key].icon = this.wh.add.sprite(0, 0, "skill_weapon_tp", iconuse).setOrigin(0, 0);
            item.cbt['skillweaponuse'][key].iconbar = this.wh.add.sprite(0, 0, "thecolor", "blue").setOrigin(0, 0);

            item.cbt['skillweaponuse'][key].icon.displayWidth = item.cbt['skillweaponuse'][key].icon.displayHeight = 15;
            item.cbt['skillweaponuse'][key].iconbar.displayWidth = 1;
            item.cbt['skillweaponuse'][key].iconbar.displayHeight = 3;
            this.wh.combatcontainer.add(item.cbt['skillweaponuse'][key].icon);
            this.wh.combatcontainer.bringToTop(item.cbt['skillweaponuse'][key].icon);

            this.wh.combatcontainer.add(item.cbt['skillweaponuse'][key].iconbar);
            this.wh.combatcontainer.bringToTop(item.cbt['skillweaponuse'][key].iconbar);

            item.cbt['skillweaponuse'][key].icon.setPosition(
              leftpos + skillx - 10,
              toppos + hspace * i - 5 + 12 + addypos
            );
            item.cbt['skillweaponuse'][key].iconbar.setPosition(
              leftpos + skillx - 10,
              toppos + hspace * i - 5 + 12 + addypos + 12
            );
            skilli++;
            if (skilli == 5) {
              skilli = 0;
              skillx = 0;
              addypos = addypos + 15;
            }
          }

        }



      }
    }

    for (const key in this.team2list) {
      if (Object.prototype.hasOwnProperty.call(this.team2list, key)) {
        const item = Object.assign({}, this.team2list[key]);
        if (this.mincombat == "yes") {
          item.pgbar_health.txt.y = item.pgbar_health.txt.y - 5;
        }
      }
    }
  }
  pointerdownteam1(cbtoid) {
    var cbtwh = this.cbto[cbtoid];
    this.selectedchar_id = cbtoid;
    this.wh.kbfocuslv1 = "combatmovepos";
    this.plog.log('pointerdownteam1', cbtoid, this.selectedchar_id, this);
    this.selectedchar_eff.visible = true;
    this.selectedchar_eff.x = cbtwh.sprite.x;
    this.selectedchar_eff.y = cbtwh.sprite.y;
    this.selectedchar_eff.displayWidth = cbtwh.sprite.displayWidth * 1.2;
    this.selectedchar_eff.displayHeight = cbtwh.sprite.displayWidth * 0.5;
    //cbtwh.sprite.setDepth(1001);
    this.wh.combatcontainer.bringToTop(this.selectedchar_eff);
    this.wh.combatcontainer.bringToTop(cbtwh.sprite);
    //this.selectedchar_eff.setDepth(1000);
    //this.wh.combatcontainer.swap(this.selectedchar_eff,cbtwh.sprite);
    var delaycount = 0;
    for (let xx = 1; xx <= this.gridw / 2 - 0; xx++) {
      //if (tmpteam2grid[xx] == undefined) tmpteam2grid[xx] = [];
      for (let yy = 1; yy <= this.gridh - 1; yy++) {
        this.battlegridbtn[xx + '-' + yy].removeAllListeners();
        this.battlegridbtn[xx + '-' + yy].setInteractive();
        this.battlegridbtn[xx + '-' + yy].visible = true;
        delaycount += 2;
        var tw = this.wh.tweens.add({
          targets: this.battlegridbtn[xx + '-' + yy],
          displayWidth: this.posinfo.grid,
          displayHeight: this.posinfo.grid,
          ease: 'Power3',
          duration: 200, // duration of animation; higher=slower
          delay: delaycount, // wait 500 ms before starting
          onComplete: function () {
            //this.visible=false;
          }, // set context? how?
        });
        tw.play();
        this.battlegridbtn[xx + '-' + yy].on('pointerdown', function () {
          //this.plog.log('arguments', arguments, this);
          var tmppos = this.name + '';
          var tmpposa = tmppos.split('-');
          this.scene.registry.list.combat.moveselectedcharto(
            tmpposa[1],
            tmpposa[2]
          );
        });
      }
    }
  }
  moveselectedcharto(x, y) {
    var tmp = this.cbto[this.selectedchar_id];
    //this.plog.log('moveselectedcharto', this.selectedchar_id, tmp, this);
    tmp.sprite.x = this.battlegridbtn[x + '-' + y].x;
    tmp.sprite.y = this.battlegridbtn[x + '-' + y].y;
    this.selectedchar_eff.visible = false;
    tmp.setspawnpos(x + '-' + y);

    for (let xx = 1; xx <= this.gridw - 0; xx++) {
      for (let yy = 1; yy <= this.gridh - 0; yy++) {
        this.battlegridbtn[xx + '-' + yy].displayHeight = 4;
        this.battlegridbtn[xx + '-' + yy].displayWidth = 4;
        this.battlegridbtn[xx + '-' + yy].visible = false;
      }
    }
    this.updateracetagline();
    this.battletick_followset();
  }
  createcombatants(mode, id) {
    this.plog.log('createcombatants', mode, id);

  }
  async exitbattle(clearkbfocus = false) {
    ///this.wh.registry.list.snd.bgm("bgm");
    this.wh.mapcontainer_show();
    if (clearkbfocus == true) {
      this.wh.kbfocus = "";
      this.wh.kbfocuslv1 = "";
    }
    this.combatinprogress = "no";
    this.wh.combatcontainer.removeAll(true);
    this.wh.combatcontainer_reward.removeAll(true);
    for (const k in this.logtextdsp) {
      if (this.logtextdsp[k] != undefined) {
        this.logtextdsp[k].destroy();
        this.logtextdsp[k] = undefined;
      }

    }

    this.bg = undefined;
    this.combatants = [];
    this.cbto = [];
    this.wh.registry.list.rcvarpass.gameobj.scene.scenes[0].time.removeEvent(
      this.timer
    );
    this.wh.resumemapmob();
    this.destroyracetagline();
    for (const k in this.wh.combatcontainer.list) {
      try {
        await this.wh.combatcontainer.remove(this.wh.combatcontainer.list[k], true);
        //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list[k].destroy();
      } catch (e) {
        this.plog.log("ERROR! mapcontainer.destroy() ", e);
      }
    }
    for (const k in this.wh.combatcontainer.list) {
      try {
        await this.wh.combatcontainer.remove(this.wh.combatcontainer.list[k], true);
        //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list[k].destroy();
      } catch (e) {
        this.plog.log("ERROR! mapcontainer.destroy() ", e);
      }
    }
    if (clearkbfocus == true) {
      var savehpres = await this.wh.registry.list.rchttp.getcommon2("savehp", {
        resulthp: this.battlelog
      });
    }
    await this.wh.registry.list.rchttp.updatecharinfo();

  }
  beginbattle() {
    this.combatresultsent = false;
    this.destroyracetagline();
    //this.plog.log("xxxxxxxx",this);
    this.wh.registry.list.tut.close();


    this.mobsniffer.visible = false;
    this.shine1.visible = false;

    for (let xx = 1; xx <= this.gridw - 0; xx++) {
      for (let yy = 1; yy <= this.gridh - 0; yy++) {
        this.battlegridbtn[xx + '-' + yy].displayHeight = 4;
        this.battlegridbtn[xx + '-' + yy].displayWidth = 4;
        this.battlegridbtn[xx + '-' + yy].visible = false;
      }
    }
    //this.wh.registry.list.wsrc.sendchat(":emote:bt-auto:");
    this.wh.registry.list.wsrc.sendeffect("Battling!", "ixbeff125_b");

    this.battlebegin = "yes";

    for (const k in this.cbto) {
      var v = this.cbto[k];
      if (v == undefined) continue;
      //this.plog.log('xxxxxxxx', v);
      this.battlelog[k] = {};
      this.battlelog[k]['uid'] = v['cbt']['uid'];
      this.battlelog[k]['type'] = v['cbt']['type'];
      this.battlelog[k]['sprite'] = v['cbt']['sprite'];
      this.battlelog[k]['team'] = v['cbt']['team'];
      this.battlelog[k]['mobcode'] = v['cbt']['mobcode'];
      //this.battlelog[k]["mappos"] = this.wh.char_main.currentx+"-"+this.wh.char_main.currenty;

      this.battlelog[k]['gen_damage'] = 0;
      this.battlelog[k]['gen_health'] = 0;
      this.battlelog[k]['gen_skilluse'] = 0;
      this.battlelog[k]['gen_atk'] = 0;
      this.battlelog[k]['dealt_dmg'] = 0;
      this.battlelog[k]['gen_atkcount'] = 0;
      this.battlelog[k]['hp_remain'] = v['cbt']['hp'];
    }
    this.plog.log('beginbattle()');
    //this.plog.log('beginbattle()', this.wh);
    this.beginbattlebtn.visible = false;
    this.timercount = this.timelimit;
    this.updatetopclock();
    for (const key in this.cbto) {
      if (
        Object.prototype.hasOwnProperty.call(this.cbto, key) &&
        this.cbto[key] != undefined
      ) {
        if (this.cbto[key] != undefined) {
          //this.cbto[key].sprite.flipX = false; //cancel flip upon battle start
          /**/
          this.cbto[key].dopassiveskill();
        }
      }
    }
    this.settimerwithspeed();

  }

  battletick() {
    //this.plog.log("combat-battletick()",);
    //this.plog.log("combat-battletick() mincombat", this.mincombat);
    //this.plog.log("combat-battletick() ctnrlength", this.wh.combatcontainer.list.length);
    if (this.wh.combatcontainer.list.length > 5000) {
      this.plog.log("combat-battletick() ctnr list", this.wh.combatcontainer.list);
      //this.wh.lksjdf();
    }
    this.timerperseccount++;
    if (this.timerperseccount >= this.timerpersec) {
      this.timercount--;
      this.timerperseccount = 0;
      this.updatelogtext();
    }
    //this.plog.log("combat-battletick()",this.timerperseccount);

    this.setzindex();
    //this.plog.log("battletick()");
    if (this.timercount < 0) {
      //time s up, check who wins
      var countteam1 = 0;
      var countteam2 = 0;
      for (const key in this.cbto) {
        if (
          Object.prototype.hasOwnProperty.call(this.cbto, key) &&
          this.cbto[key] != undefined
        ) {
          if (this.cbto[key].cbt['team'] == 1) countteam1++;
          if (this.cbto[key].cbt['team'] == 2) countteam2++;
        }
      } /*
            if (countteam1 == 0) {
                this.plog.log("team 2 wins");
                this.wh.registry.list.rcvarpass.gameobj.scene.scenes[0].time.removeEvent(this.combatobj.timer);
                this.team2wins();
                return 0;
            }
            if (countteam2 == 0) {
                this.plog.log("team 1 wins");
                this.wh.registry.list.rcvarpass.gameobj.scene.scenes[0].time.removeEvent(this.combatobj.timer);
                this.team1wins();
                return 0;
            }*/
      if (countteam2 != 0) {
        if (
          this.config['combatmode'] == 'challenge' ||
          this.config['combatmode'] == 'svboss' ||
          this.config['combatmode'] == 'boss'
        ) {
          this.plog.log("team 2 wins in combatmode!='' ");
          this.wh.registry.list.rcvarpass.gameobj.scene.scenes[0].time.removeEvent(
            this.timer
          );
          this.team1wins();
          return;
        }
        this.plog.log('team 2 wins');
        this.wh.registry.list.rcvarpass.gameobj.scene.scenes[0].time.removeEvent(
          this.timer
        );
        this.team2wins();
        return 0;
      }

    }
    this.walkablegrid = [];
    for (let xx = 0; xx <= this.gridw; xx++) {
      for (let yy = 0; yy <= this.gridh; yy++) {
        if (this.walkablegrid[yy] == undefined) this.walkablegrid[yy] = [];
        this.walkablegrid[yy][xx] = 0;
      }
    }

    this.updatetopclock();
    //sthis.plog.log("walkablegrid", this.walkablegrid);
    for (const key in this.cbto) {
      if (
        Object.prototype.hasOwnProperty.call(this.cbto, key) &&
        this.cbto[key] != undefined
      ) {
        if (this.cbto[key] != undefined) this.cbto[key].battletick();
        if (this.cbto[key] != undefined) {
          this.walkablegrid[this.cbto[key].currentposx][this.cbto[key].currentposy] = 1;
          this.cbto[key].attacktime = this.cbto[key].attacktimeorig * this.timespeed;
        }
        //if (this.cbto[key] != undefined)
        //    this.plog.log("xxx------", key, "targetting", this.cbto[key].targetid);
      }
    }

    /////superenergy
    for (const k in this.superenergy) {
      var v = this.superenergy[k];
      //this.plog.log("superenergy", v);
      if (v != undefined && v.cbt != undefined) {
        for (const k2 in v.cbt.skilluse) {
          var v2 = v.cbt.skilluse[k2];
          //this.plog.log("superenergy v2", v2);
          if (v2 != undefined && v2.skilleffects != undefined && v2.skilleffects["a.energy"] != undefined) {
            try {
              v2["energy"] = v2["skilleffects"]["a.energy"];
            } catch (e) {
              this.plog.log(e);
            }
          }
        }
      }
    }


    /////nostatuses
    for (const k in this.nostatuses) {
      var v = this.nostatuses[k];
      //this.plog.log("nostatuses", v);
      if (v != undefined) {
        for (const k2 in v.statuses) {
          var v2 = v.statuses[k2];
          //this.plog.log("nostatuses v2", v2);
          if (v2 != undefined) {
            try {
              v2["time"] = 0;
            } catch (e) {
              this.plog.log(e);
            }
          }
        }
      }
    }


    /////follower cmd (followset)
    this.battletick_followset();
  }
  battletick_followset() {

    for (const k in this.followset) {
      var v = this.followset[k];
      //this.plog.log("followset", v);
      if (v.leader == undefined) {
        if (v.follower != undefined) {
          try {
            v.follower.destroy();
          } catch (e) {
            this.plog.log(e);
          }
        }
        delete this.followset[k];
        continue;
      }
      if (v.follower == undefined) {
        if (v.leader != undefined) {
          try {
            v.leader.destroy();
          } catch (e) {
            this.plog.log(e);
          }
        }
        delete this.followset[k];
        continue;
      }
      if (v.follower != undefined && v.leader != undefined) {
        v.follower.x = v.leader.x;
        v.follower.y = v.leader.y;
      }
    }
  }
  getxy(x, y) {
    //this.plog.log("getxy", x, y);
    var tmpx = this.battlegridbtn[x + '-' + y].x;
    var tmpy = this.battlegridbtn[x + '-' + y].y;
    return { x: tmpx, y: tmpy };
  }
  async team1wins() {
    if (this.combatresultsent == true) {
      this.plog.log('team1wins skip sent');
      return;
    }
    this.wh.mapcontainer_show();
    this.wh.kbfocus = "";
    this.wh.kbfocuslv1 = "";
    this.wh.lastcombattoken = this.combattoken;
    this.combatresultsent = true;
    this.plog.log('team1wins');
    this.logtext.push("0bold:::Team 1 win");

    for (const key in this.cbto) {
      if (
        Object.prototype.hasOwnProperty.call(this.cbto, key) &&
        this.cbto[key] != undefined
      ) {
        //this.plog.log(this.cbto[key])
        if (this.cbto[key].cbt['team'] == 1) {
          if (this.cbto[key].cbt['type'] == 'char') {
            this.cbto[key].sprite.play({
              key: this.cbto[key].cbt['sprite'] + '-spellcast-d',
              frameRate: this.cbtfr,
            });
          } else {
            this.cbto[key].sprite.play({
              key: 'mob_' + this.cbto[key].cbt['sprite'] + '_bored',
              frameRate: this.cbtfr,
            });
          }
        }
      }
    }
    if (this.config['combatmode'] == "pvp") {
      combatres = await this.wh.registry.list.rchttp.getcommon2("pvpwin", {
        battlelog: this.battlelog,
        opoid: this.config["opoid"],
        combattoken: this.combattoken,
        logtext: this.logtext
      }
      );
    } else {
      var combatres = await this.wh.registry.list.rchttp.combatwin(
        this.battlelog,
        this.config['data']['code'],
        this.totaldmg,
        this.combattoken,
        this.logtext
      );
    }
    this.wh.registry.list.gameitf.savesnapshot(this.wh, "combatwin", this.combattoken);
    await this.wh.registry.list.rctoast.sleep(950);


    //this.plog.log("xxxx",this);
    this.wh.registry.list.rcloading.loading2(this.wh, false, false);
    //display obtained items/ combatres["dropped"]
    var dropinfo = combatres['dropped'];
    await this.wh.registry.list.rcvarpass.setactivechar(
      combatres['chardata'],
      this.wh.registry.list.rcvarpass.activechar['id']
    );
    //this.plog.log('dropinfo', dropinfo);

    await this.wh.registry.list.rcvarpass.gameobj.registry.list.gameitf.charinfoupdated(
      this.wh.registry.list.rcvarpass.gameobj.scene.scenes[0]
    );
    this.wh.registry.list.rcloading.close();
    //resultscreen
    if (
      this.config['combatmode'] == 'challenge' ||
      this.config['combatmode'] == 'svboss' ||
      this.config['combatmode'] == 'boss'
    ) {
      //var tmpbg = this.wh.add.image(300, 300, 'combatwin');
      this.wh.registry.list.showreward.show(this.wh, dropinfo, {
        bg: 'combatwin',
        text: 'Victory',
        combatredocount: combatres['combatredocount']
      });
    } else {
      //var tmpbg = this.wh.add.image(300, 300, 'combatlose');
      this.wh.registry.list.showreward.show(this.wh, dropinfo, {
        bg: 'combatwin',
        text: 'Victory!',
        combatredocount: combatres['combatredocount']
      });

    }
    this.wh.walkpathcmd = [];
    this.wh.registry.list.rcloading.close();
    this.wh.registry.list.phm.updatechestnpcdsp(this.wh);
    var width = this.wh.game.canvas.width;
    var height = this.wh.game.canvas.height;

    this.exitbattle(false);
    this.wh.registry.list.ga.se2('combatend', "team1win");
  }
  async team2wins() {

    if (this.combatresultsent == true) {
      this.plog.log('team2wins skip sent');
      return;
    }
    this.wh.lastcombattoken = this.combattoken;
    this.wh.mapcontainer_show();
    this.wh.kbfocus = "";
    this.wh.kbfocuslv1 = "";
    this.combatresultsent = true;
    this.logtext.push("0bold:::Team 2 win");
    this.plog.log('team2wins()');
    for (const key in this.cbto) {
      if (
        Object.prototype.hasOwnProperty.call(this.cbto, key) &&
        this.cbto[key] != undefined
      ) {
        //this.plog.log(this.cbto[key])
        if (this.cbto[key].cbt['team'] == 2) {
          if (this.cbto[key].cbt['type'] == 'char') {
            this.cbto[key].sprite.play(
              this.cbto[key].cbt['type'] + '-spellcast-d'
            );
          } else {
            this.cbto[key].sprite.play(
              'mob_' + this.cbto[key].cbt['sprite'] + '_bored'
            );
          }
        }
      }
    }

    //resultscreen

    var width = this.wh.game.canvas.width;
    var height = this.wh.game.canvas.height;
    //resultscreen

    //this.plog.log("xxxx",this);
    this.wh.registry.list.gameitf.savesnapshot(this.wh, "combatlose", this.combattoken);
    await this.wh.registry.list.rctoast.sleep(950);
    this.wh.registry.list.rcloading.loading2(this.wh, false, false);
    var combatres;
    if (this.config['combatmode'] == "pvp") {
      combatres = await this.wh.registry.list.rchttp.getcommon2("pvplose", {
        battlelog: this.battlelog,
        opoid: this.config["opoid"],
        combattoken: this.combattoken,
        logtext: this.logtext
      }
      );
    } else {
      combatres = await this.wh.registry.list.rchttp.combatlose(
        this.battlelog,
        this.config['data']['code'],
        this.totaldmg,
        this.combattoken,
        this.logtext
      );
    }
    //display obtained items/ combatres["dropped"]
    var dropinfo = combatres['dropped'];
    await this.wh.registry.list.rcvarpass.setactivechar(
      combatres['chardata'],
      this.wh.registry.list.rcvarpass.activechar['id']
    );
    //this.plog.log('dropinfo', dropinfo);

    await this.wh.registry.list.rcvarpass.gameobj.registry.list.gameitf.charinfoupdated(
      this.wh.registry.list.rcvarpass.gameobj.scene.scenes[0]
    );
    this.wh.registry.list.rcloading.close();

    if (
      this.config['combatmode'] == 'challenge' ||
      this.config['combatmode'] == 'svboss' ||
      this.config['combatmode'] == 'boss'
    ) {
      //var tmpbg = this.wh.add.image(300, 300, 'combatwin');
      this.wh.registry.list.showreward.show(this.wh, dropinfo, {
        bg: 'combatwin',
        text: 'Victory',
      });
    } else {
      //var tmpbg = this.wh.add.image(300, 300, 'combatlose');
      /*
      this.wh.registry.list.showreward.show(this.wh, dropinfo, {
        bg: 'combatlose',
        text: 'You Lose',
        light: false,
      });*/
      this.wh.registry.list.showreward.show(this.wh, dropinfo, {
        bg: 'combatlose',
        text: 'You Lose!',
      });
      var spawnpos = this.wh.registry.list.phm.getspawnpos();
      var spawnposa = spawnpos.split('-');
      this.wh.registry.list.phm.movemaincharto(spawnposa[1], spawnposa[0]);
      this.wh.registry.list.phm.movemaincharto(spawnposa[1], spawnposa[0]);
    }
    this.exitbattle(false);
    this.wh.registry.list.ga.se2('combatend', "team2win");

  }
  dspdropinfo(wh, dropinfo, toppos) {
    var width = wh.game.canvas.width;
    var height = wh.game.canvas.height;
    var rowheight = 30;
    var ic = 0;
    for (const k in dropinfo) {
      ic++;
      var v = dropinfo[k];
      var va = v.split(':');
      var usetoppos = toppos + rowheight * ic;

      if (va[0] == 'msgorange') {
        //this.plog.log('msgorange', this);
        var tmpobtain = this.wh.add.text(0, 0, va[1], {
          fontFamily: 'ffff',
          fontSize: '25px',
          color: '#ff8400',
        });
        tmpobtain.setStroke('#ffffff', 6);
        tmpobtain.setShadow(0, 0, '#000000', 2, false, true);
        tmpobtain.setPosition(
          width / 2 - tmpobtain.displayWidth / 2,
          usetoppos
        );
        this.wh.combatcontainer_reward.add(tmpobtain);
        this.wh.combatcontainer_reward.bringToTop(tmpobtain);
      }
      if (va[0] == 'msgred') {
        //this.plog.log('msgred', this);
        var tmpobtain = this.wh.add.text(0, 0, va[1], {
          fontFamily: 'ffff',
          fontSize: '25px',
          color: '#f00',
        });
        tmpobtain.setStroke('#ffffff', 6);
        tmpobtain.setShadow(0, 0, '#000000', 2, false, true);
        tmpobtain.setPosition(
          width / 2 - tmpobtain.displayWidth / 2,
          usetoppos
        );
        this.wh.combatcontainer_reward.add(tmpobtain);
        this.wh.combatcontainer_reward.bringToTop(tmpobtain);
      }
      if (va[0] == 'item') {
        var iteminfo = this.wh.registry.list.rcvarpass.allitem[va[1]];
        var tmpobtain = this.wh.add.text(
          0,
          0,
          'Item: ' + iteminfo['name'] + ' ',
          { fontFamily: 'ffff', fontSize: '20px', color: '#fff' }
        );
        tmpobtain.setStroke('#2d2d2d', 6);
        tmpobtain.setShadow(4, 4, '#000000', 2, false, true);
        tmpobtain.setPosition(
          width / 2 - tmpobtain.displayWidth / 2,
          usetoppos
        );
        this.wh.combatcontainer_reward.add(tmpobtain);
        this.wh.combatcontainer_reward.bringToTop(tmpobtain);
        //icon
        var itemsprite =
          this.wh.registry.list.rcvarpass.gameobj.scene.scenes[0].add.sprite(
            100,
            100,
            'item_tp',
            va[1]
          );
        itemsprite.setPosition(
          width / 2 - (tmpobtain.displayWidth / 2 + rowheight),
          usetoppos
        );
        itemsprite.displayWidth = itemsprite.displayHeight = rowheight + 4;
        itemsprite.setOrigin(0, 0);
        this.wh.combatcontainer_reward.add(itemsprite);
        this.wh.combatcontainer_reward.bringToTop(itemsprite);
      }

      if (va[0] == 'stackitem') {
        var iteminfo = this.wh.registry.list.rcvarpass.allstackitem[va[1]];
        if (iteminfo == undefined) {
          this.plog.log('missing stackitem info', va);
          continue;
        }
        var tmpobtain = this.wh.add.text(
          0,
          0,
          'StackItem: ' + iteminfo['name'] + ' x' + va[2],
          { fontFamily: 'ffff', fontSize: '20px', color: '#fff' }
        );
        tmpobtain.setStroke('#2d2d2d', 6);
        tmpobtain.setShadow(4, 4, '#000000', 2, false, true);
        tmpobtain.setPosition(
          width / 2 - tmpobtain.displayWidth / 2,
          usetoppos
        );
        this.wh.combatcontainer_reward.add(tmpobtain);
        this.wh.combatcontainer_reward.bringToTop(tmpobtain);
        var itemsprite =
          this.wh.registry.list.rcvarpass.gameobj.scene.scenes[0].add.sprite(
            100,
            100,
            'stackitem_tp',
            va[1]
          );
        itemsprite.setPosition(
          width / 2 - (tmpobtain.displayWidth / 2 + rowheight),
          usetoppos
        );
        itemsprite.displayWidth = itemsprite.displayHeight = rowheight + 4;
        itemsprite.setOrigin(0, 0);
        this.wh.combatcontainer_reward.add(itemsprite);
        this.wh.combatcontainer_reward.bringToTop(itemsprite);
      }

      if (va[0] == 'xp') {
        var tmpobtain = this.wh.add.text(0, 0, 'XP+' + va[1], {
          fontFamily: 'ffff',
          fontSize: '20px',
          color: '#fff',
        });
        tmpobtain.setStroke('#2d2d2d', 6);
        tmpobtain.setShadow(4, 4, '#000000', 2, false, true);
        tmpobtain.setPosition(
          width / 2 - tmpobtain.displayWidth / 2,
          usetoppos
        );
        this.wh.combatcontainer_reward.add(tmpobtain);
        this.wh.combatcontainer_reward.bringToTop(tmpobtain);
      }
      if (va[0] == 'ki') {
        var tmpobtain = this.wh.add.text(0, 0, 'KI+' + va[1], {
          fontFamily: 'ffff',
          fontSize: '20px',
          color: '#fff',
        });
        tmpobtain.setStroke('#2d2d2d', 6);
        tmpobtain.setShadow(4, 4, '#000000', 2, false, true);
        tmpobtain.setPosition(
          width / 2 - tmpobtain.displayWidth / 2,
          usetoppos
        );
        this.wh.combatcontainer_reward.add(tmpobtain);
        this.wh.combatcontainer_reward.bringToTop(tmpobtain);
      }
    }
    //this.wh.combatcontainer_reward.setDepth();
  }
  gett() {
    var d = new Date();
    return Date.now();
    Math.floor(Date.now() / 1000);
  }
  setzindex() {
    //const cbtol = Object.assign({},this.cbto);
    //this.plog.log("setzindex();",this.cbto,cbtol);

    for (const key1 in this.cbto) {
      var callerobj = this.cbto[key1];
      if (callerobj == undefined) continue;
      var tmpchary = callerobj.sprite.y + 0; //wh.char_main.y + 0;
      if (callerobj.cbt.type == 'char') tmpchary += 14;
      for (const key in this.cbto) {
        //if (Object.prototype.hasOwnProperty.call(this.wh.cbto, key)) {
        const element = this.cbto[key];
        //this.plog.log("setzindex();", element, callerobj);
        if (element != undefined && callerobj != undefined)
          if (element.sprite.y < tmpchary) {
            if (element != undefined && callerobj != undefined) {
              try {
                this.wh.combatcontainer.moveBelow(
                  element.sprite,
                  callerobj.sprite
                );
              } catch (e) {
                this.plog.log(e);
              }
            }
          }
        if (element != undefined && callerobj != undefined)
          if (element.sprite.y > tmpchary) {
            if (element != undefined && callerobj != undefined) {
              try {
                this.wh.combatcontainer.moveBelow(
                  callerobj.sprite,
                  element.sprite
                );
              } catch (e) {
                this.plog.log(e);
              }
            }
          }
        //}
      }
    }

    for (const k in this.followset) {
      var v = this.followset[k];
      //this.plog.log("followset", v);
      if (v.leader == undefined) {
        if (v.follower != undefined) {
          try {
            try {
              this.wh.combatcontainer.moveBelow(
                v.follower,
                v.leader
              );
            } catch (e) {
              this.plog.log(e);
            }
          } catch (e) {
            this.plog.log(e);
          }
        }
        delete this.followset[k];
        continue;
      }
    }
  }
  randp(arr) {
    var decisarr = {};
    var maxposs = 0;

    for (var k in arr) {
      var v = arr[k];
      //this.plog.log("randp", k, v);
      decisarr[v.k] = [];
      decisarr[v.k]['min'] = maxposs;
      maxposs = maxposs + Number(v.n);
      decisarr[v.k]['max'] = maxposs;
    }
    var decis = Number(Math.random() * (maxposs - 0) + 0);
    //this.plog.log("randp maxposs", maxposs);
    //this.plog.log("randp decis", decis);
    for (var k in decisarr) {
      var v = decisarr[k];
      //this.plog.log("randp scanning", k, v,"with",decis);
      if (decis >= v['min'] && decis <= v['max']) {
        //this.plog.log("yyyy randp found this",  k,v,decis);
        return k;
      }
    }
  }
  addplayertoteam(team, data) {
    this.plog.log("addplayertoteam", team, data);
    var tmpme = Object.assign({}, data['cbt']);
    tmpme["skill"] = Object.assign({}, data['cbt']["skill"]);
    tmpme["skillweapon"] = Object.assign({}, data['cbt']["skillweapon"]);

    for (const k in tmpme["skillconfig"]) {
      for (const k2 in tmpme["skill"]) {
        if (tmpme["skill"][k2] == k) {
          //tmpme["skill"][k2]=undefined;
          //this.plog.log("skillconfig skill", tmpme["skill"][k2], k);
          //var removed = tmpme["skill"].splice(k2, k2);
          tmpme["skill"][k2] = undefined;
        }
      }
      for (const k2 in tmpme["skillweapon"]) {
        if (tmpme["skillweapon"][k2] == k) {
          //tmpme["skill"][k2]=undefined;
          //this.plog.log("skillconfig skillweapon", tmpme["skillweapon"][k2], k,);
          //var removed = tmpme["skillweapon"].splice(k2, k2);
          tmpme["skillweapon"][k2] = undefined;
        }
      }
    }
    // this.plog.log("skillconfig skill left", tmpme["skill"]);

    //this.plog.log("skillconfig skillweapon left", tmpme["skillweapon"]);
    tmpme['sprite'] = data['sprite'];
    tmpme['name'] = data['name'];
    tmpme['team'] = team;
    tmpme['element'] = data['element'];
    tmpme['dbid'] = data['id'];
    tmpme['uid'] = 0;
    if (tmpme['type'] == "" || tmpme['type'] == undefined)
      tmpme['type'] = 'char';
    //tmpme['mobcode'] = '';
    tmpme['spawnpos'] = data['combatpos'];
    this.combatants[this.combatants.length] = tmpme;
    // this.combatants.push(tmpme);

    for (const key in data['pet']) {
      if (
        Object.prototype.hasOwnProperty.call(
          data['pet'],
          key
        )
      ) {
        //Object.assign({}, data['pet'][key]);
        const pet = JSON.parse(JSON.stringify(data['pet'][key]));
        var catchk = pet["mob"].substring(0, 3);
        this.plog.log("catchk", catchk, pet);
        if (catchk == "cat") {
          //this.plog.error("combatfoundcat", pet);
          var catid = pet["mob"].substring(3, 5)
          //this.plog.error("combatfoundcat catid", catid);
          if (team == 1) {
            this.catteam1[catid] = pet;
          }
          if (team == 2) {
            this.catteam2[catid] = pet;
          }
          continue;
        }

        //const pet = data['pet'][key];
        //var tmpme = pet['cbt'];
        const tmpme = Object.assign({}, pet['cbt']);
        tmpme['spawnpos'] = pet['combatpos'];
        tmpme['name'] = pet['name'];
        tmpme['element'] = pet['element'];
        tmpme['dbid'] = pet['id'];
        tmpme['mobcode'] = pet['mob'];
        tmpme['sprite'] = pet['sprite'];
        tmpme['team'] = team;
        tmpme['uid'] = pet['id'];
        tmpme['type'] = 'pet';
        for (const k in tmpme["skillconfig"]) {
          for (const k2 in tmpme["skill"]) {
            if (tmpme["skill"][k2] == k) {
              //tmpme["skill"][k2]=undefined;
              //this.plog.log("skillconfig pet skill", tmpme["skill"][k2], k);
              //var removed = tmpme["skill"].splice(k2, k2);
              tmpme["skill"][k2] = undefined;
            }
          }
        }
        //this.combatants.push(tmpme);
        this.combatants[this.combatants.length] = Object.assign({}, tmpme);// tmpme;
      }
    }
  }
  addmapmobtoteam2(mobteamuse) {
    //this.plog.log('combat mobteamuse', mobteamuse);
    for (let index = 0; index <= 5; index++) {
      var mobchk = mobteamuse['mob' + index];
      var mobchkmin = mobteamuse['mob' + index + '_min'];
      var mobchkmax = mobteamuse['mob' + index + '_max'];
      mobchkmin = Number(mobchkmin);
      mobchkmax = Number(mobchkmax);
      var mobdat = this.wh.registry.list.rcvarpass.allmob[mobchk];
      if (mobdat == undefined) {
        this.plog.log('mobdat undefined', mobchk, mobdat);
        continue;
      }

      /*
      for (const key in this.combatants) {
        if (Object.prototype.hasOwnProperty.call(this.combatants, key)) {
          if (this.combatants[key]['team']==2) {
            this.plog.log('mobteam2 chkpos', this.combatants[key],this.wh.registry.list.rcvarpass.activemap['map']);
            if (this.wh.registry.list.rcvarpass.activemap['map']['mobteam']['teampos1']!=undefined) {
              this.plog.log('mobteam2 foundteampos', this.wh.registry.list.rcvarpass.activemap['map']['mobteam']['teampos1']);
  
            }
  
          }
        }
      }*/
      if (mobchk != undefined && mobchk != '') {
        var monnum = Math.random() * (mobchkmax - mobchkmin) + mobchkmin;
        monnum = Math.floor(monnum);
        //this.plog.log('spawnmob ' + mobchk + ' ' + index + '/' + monnum, mobchkmin, mobchkmax, mobchk, mobdat);
        for (let indexm = 0; indexm < monnum; indexm++) {
          var mobdatuse;
          var teamposkey = index + "-" + (indexm + 1);

          ///mobdatuse = this.wh.registry.list.rcvarpass.allmob[mobchk];
          mobdatuse = Object.assign({}, this.wh.registry.list.rcvarpass.allmob[mobchk]);

          if (Number(this.wh.registry.list.rcvarpass.activemap['map']['forcemoblevel']) != 0) {
            var tmpuselv = Number(this.wh.registry.list.rcvarpass.activemap['map']['forcemoblevel']);
            this.plog.log('forcemoblevel level  ', tmpuselv);
            //for (var li = uselv; li >= uselv - 5; uselv--) {
            for (const k in this.wh.registry.list.rcvarpass.stdstat[mobdatuse['attmimic']]) {
              if (this.wh.registry.list.rcvarpass.stdstat[mobdatuse['attmimic']][k]["level"] >= tmpuselv) {
                var tmpforce = this.wh.registry.list.rcvarpass.stdstat[mobdatuse['attmimic']][k];
                var tmporig = Object.assign({}, mobdatuse);
                mobdatuse['cbt'] = tmpforce['cbt'];
                mobdatuse['cbt']['attackani'] = tmporig['cbt']['attackani'];
                mobdatuse['cbt']['combatrange'] = tmporig['cbt']['combatrange'];
                mobdatuse['cbt']['skilluse'] = mobdatuse['cbt']['skill'];
                this.plog.log("forcemoblevel found ", k, mobdatuse);
                break;
              }
              //this.plog.log("forcemoblevel i",this.wh.registry.list.rcvarpass.allmob[k]);
              //continue;
            }
          }

          //this.plog.log('challenge allmob', mobdatuse);
          var userunnerlevel = 0;
          if (this.config['combatmode'] == 'challenge' || this.config['combatmode'] == 'svboss') {
            //set attribute to lv 1 of that attmimic
            //this.plog.log("challenge", mobdatuse, wh.registry.list.rcvarpass.stdstat);
            //this.plog.log('challenge cb b4', mobdatuse['cbt']);

            if (this.config["data"] != undefined && this.config["data"]["maxlevel"] != undefined) {
              userunnerlevel = this.config["data"]["maxlevel"];
            }
            var tmpcbt = Object.assign(
              {},
              this.wh.registry.list.rcvarpass.stdstat[mobdatuse['attmimic']][userunnerlevel][
              'cbt'
              ]
            );
            //this.plog.log('challenge cb tp', tmpcbt);
            for (const k in tmpcbt) {
              //copy only existing value
              mobdatuse['cbt'][k] = tmpcbt[k];
            }
            //this.plog.log('challenge cb af', mobdatuse['cbt']);
            //mobdatuse["cbt"]=wh.registry.list.rcvarpass.stdstat[mobdatuse["attmimic"]][0]["cbt"];
            //this.plog.log("challenge stdstat", mobdatuse);
          }
          if (mobdatuse["level"] == -1) {//auto level

            var tmpcbt = Object.assign(
              {},
              this.wh.registry.list.rcvarpass.stdstat[mobdatuse['attmimic']]
            );
            var uselv = this.wh.registry.list.rcvarpass.activechar["level"];
            if (uselv < 5) { uselv = 5; }
            this.plog.log('auto level cb ', tmpcbt, "uselv", uselv);
            var foundtp = false;
            //for (var li = uselv; li >= uselv - 5; uselv--) {
            for (const k in tmpcbt) {
              var lvdiff = Math.max(tmpcbt[k]["level"], uselv) - Math.min(tmpcbt[k]["level"], uselv);
              lvdiff = Math.abs(lvdiff);
              //this.plog.log("auto level looking level", uselv, "lvdiff", lvdiff, tmpcbt[k]);
              if (lvdiff <= 5) {
                this.plog.log("auto level found level", uselv, tmpcbt[k]);
                for (const k2 in tmpcbt[k]["cbt"]) {
                  //copy only existing value
                  mobdatuse['cbt'][k2] = tmpcbt[k]["cbt"][k2];
                }
                foundtp = true;
                break;
              }
              //if (foundtp == true) break;
              //break;
            }
            //}
            //for (var li=)
          }
          var tmpme;
          tmpme = Object.assign({}, mobdatuse['cbt']);
          //= mobdatuse['cbt'];
          tmpme['sprite'] = mobdatuse['sprite'];
          tmpme['name'] = mobdatuse['name'];
          tmpme['mobcode'] = mobdatuse['code'];
          tmpme['element'] = mobdatuse['element'];
          tmpme['team'] = 2;
          tmpme['dbid'] = 0;
          tmpme['userunnerlevel'] = userunnerlevel;
          tmpme['uid'] = mobdatuse['id'];
          //tmpme["randid"]="Mine"+Math.random();
          tmpme['type'] = 'mob';
          if (tmpme["hp"] > tmpme["maxhp"]) tmpme["hp"] = tmpme["maxhp"];
          if (tmpme["mp"] > tmpme["maxmp"]) tmpme["mp"] = tmpme["maxmp"];
          if (this.config['combatmode'] == "") {
            if (this.wh.registry.list.rcvarpass.activemap['map']['mobteam']['teampos1'] != undefined) {
              var useteampos = this.wh.registry.list.rcvarpass.activemap['map']['mobteam']['teampos1'][teamposkey];
              useteampos = useteampos.replace("place-", "")
              this.plog.log("teampos", teamposkey, useteampos);
              //combatpos
              //this.wh.registry.list.rcvarpass.activemap['map']['mobteam']['teampos1']
              var useteamposa = useteampos.split("-");
              tmpme["spawnpos"] = useteamposa[1] + "-" + useteamposa[0];

              //tmpme["spawnpos"] = useteampos;
              this.plog.log("teampos tmpme", tmpme);

            }
          }
          //this.combatants.push(tmpme);
          this.combatants[this.combatants.length] = tmpme;
        }
      }
    }
  }
  reversespawnpos(pos) {
    var posa = pos.split("-");
    //var newposx = Math.floor(this.gridw / 2) + (this.gridw - (Number(posa[0]) + 1));
    var newposx = (this.gridw - (Number(posa[0])));
    return newposx + "-" + posa[1];
  }
  racetaglinelist = [];
  destroyracetagline() {

    for (const key in this.racetaglinelist) {
      if (Object.prototype.hasOwnProperty.call(this.racetaglinelist, key)) {
        //var item = this.team1list[key];
        try {
          this.racetaglinelist[key].destroy();
        } catch (e) {
          this.plog.log("ERROR", e);
        }

      }
    }
    for (const key in this.team1list) {
      if (Object.prototype.hasOwnProperty.call(this.team1list, key)) {
        const item = Object.assign({}, this.team1list[key]);
        item.racetag.visible = false;
      }
    }
    for (const key in this.team2list) {
      if (Object.prototype.hasOwnProperty.call(this.team2list, key)) {
        const item = Object.assign({}, this.team2list[key]);
        item.racetag.visible = false;
      }
    }
  }
  updateracetagline() {
    this.destroyracetagline();

    if (this.battlebegin == "yes") return;


    if (this.mobsnifferactive != true) return;

    for (const key in this.team1list) {
      if (Object.prototype.hasOwnProperty.call(this.team1list, key)) {
        const item = Object.assign({}, this.team1list[key]);
        item.racetag.visible = true;
      }
    }
    for (const key in this.team1list) {
      if (Object.prototype.hasOwnProperty.call(this.team1list, key)) {
        //var item = this.team1list[key];
        //clone
        const item = Object.assign({}, this.team1list[key]);
        //new Line(scene [, x] [, y] [, x1] [, y1] [, x2] [, y2] [, strokeColor] [, strokeAlpha])
        this.plog.log("updateracetagline", item);
        this.plog.log("updateracetagline race", this.wh.registry.list.rcvarpass.allrace[item.cbt['race']]);
        var col = this.wh.registry.list.rcvarpass.allrace[item.cbt['race']].col;
        var cola = this.wh.registry.list.rcvarpass.allrace[item.cbt['race']].col2;
        this.plog.log("updateracetagline col", col);
        var coluse = Phaser.Display.Color.ValueToColor(col);
        this.plog.log("updateracetagline coluse", coluse);
        var coluse2 = coluse.color;
        var colusea = Phaser.Display.Color.ValueToColor(cola);
        this.plog.log("updateracetagline coluse", colusea);
        var coluse2 = coluse.color;
        var coluse2a = colusea.color;
        var line = this.wh.add.line(0, 0, item.racetag.x, item.racetag.y + 16, item.sprite.x, item.sprite.y, coluse2).setOrigin(0);
        line.setLineWidth(2);
        var line2 = this.wh.add.line(0, 0, item.racetag.x, item.racetag.y + 16, item.sprite.x, item.sprite.y, coluse2a).setOrigin(0);
        line2.setStrokeStyle(10, coluse2a, 1);

        const circ1 = this.wh.add.circle(item.sprite.x, item.sprite.y, 25, coluse2);
        circ1.scaleY = 0.2;
        circ1.scaleX = 0.2;

        this.wh.tweens.add({
          targets: circ1,
          scaleX: 1,
          scaleY: 0.5,
          yoyo: false,
          repeat: 0,
          duration: 350,
          ease: 'Bounce'

        });

        this.wh.combatcontainer.add(line2);
        this.wh.combatcontainer.bringToTop(line2);
        this.wh.combatcontainer.add(line);
        this.wh.combatcontainer.bringToTop(line);
        this.wh.combatcontainer.add(circ1);
        this.wh.combatcontainer.bringToTop(circ1);
        circ1.setStrokeStyle(10, coluse2a, 1);
        this.wh.combatcontainer.bringToTop(item.sprite);

        this.racetaglinelist.push(line);
        this.racetaglinelist.push(line2);
        this.racetaglinelist.push(circ1);
      }
    }

    for (const key in this.team1list) {
      if (Object.prototype.hasOwnProperty.call(this.team1list, key)) {
        const item = Object.assign({}, this.team1list[key]);
        this.wh.combatcontainer.bringToTop(item.sprite);
      }
    }


    for (const key in this.team2list) {
      if (Object.prototype.hasOwnProperty.call(this.team2list, key)) {
        const item = Object.assign({}, this.team2list[key]);
        item.racetag.visible = true;
      }
    }

    for (const key in this.team2list) {
      if (Object.prototype.hasOwnProperty.call(this.team2list, key)) {
        //var item = this.team1list[key];
        //clone
        const item = Object.assign({}, this.team2list[key]);
        //new Line(scene [, x] [, y] [, x1] [, y1] [, x2] [, y2] [, strokeColor] [, strokeAlpha])
        this.plog.log("updateracetagline", item);
        this.plog.log("updateracetagline race", this.wh.registry.list.rcvarpass.allrace[item.cbt['race']]);
        var col = this.wh.registry.list.rcvarpass.allrace[item.cbt['race']].col;
        this.plog.log("updateracetagline col", col);
        var coluse = Phaser.Display.Color.ValueToColor(col);
        this.plog.log("updateracetagline coluse", coluse);
        var coluse2 = coluse.color;

        var line = this.wh.add.line(0, 0, item.racetag.x, item.racetag.y + 16, item.sprite.x, item.sprite.y, coluse2).setOrigin(0);
        line.setLineWidth(2);

        const circ1 = this.wh.add.circle(item.sprite.x, item.sprite.y, 25, coluse2);
        circ1.scaleY = 0.2;
        circ1.scaleX = 0.2;


        this.wh.tweens.add({
          targets: circ1,
          scaleX: 1,
          scaleY: 0.5,
          yoyo: false,
          repeat: 0,
          duration: 350,
          ease: 'Bounce'

        });

        this.wh.combatcontainer.add(line);
        this.wh.combatcontainer.bringToTop(line);
        this.wh.combatcontainer.add(circ1);
        this.wh.combatcontainer.bringToTop(circ1);
        this.racetaglinelist.push(line);
        this.racetaglinelist.push(circ1);
      }
    }

    for (const key in this.team2list) {
      if (Object.prototype.hasOwnProperty.call(this.team2list, key)) {
        const item = Object.assign({}, this.team2list[key]);
        this.wh.combatcontainer.bringToTop(item.sprite);
        this.wh.combatcontainer.bringToTop(item.icon);
        this.wh.combatcontainer.bringToTop(item.racetag);
        this.wh.combatcontainer.bringToTop(item.pgbar_health.bar);
        this.wh.combatcontainer.bringToTop(item.pgbar_health.txt);
        if (item.pgbar_health.barfg != undefined) {
          this.wh.combatcontainer.bringToTop(item.pgbar_health.barfg);
        }
      }
    }

  }
  createcatpanel() {
    this.plog.log("createcatpanel", this.catteam1, this.catteam2);
    this.plog.log("createcatpanel", this.battlegridbtn);

    for (const key in this.catteam1) {
      if (Object.prototype.hasOwnProperty.call(this.catteam1, key)) {
        const item = Object.assign({}, this.catteam1[key]);
        if (item == "") continue;
        var cc = 0; for (const ktmp in item) cc++;

        if (item.length < 5) continue;
        if (cc < 5) continue;
        if (cc == 0) continue;
        this.catteam1[key]["spritecode"] = item["sprite"];
        this.catteam1[key].team = 1;
        this.catteam1[key].enemyteam = 2;
        this.catteam1[key].executecc = 0;
        this.catteam1[key].sprite = this.wh.add.sprite(0, 0, "" + item["sprite"]);
        this.catteam1[key].sprite.play(item["sprite"] + "-sit");
        this.catteam1[key].sprite.setPosition(300, 300);
        this.plog.log("createcatpanel i", item);
        this.wh.combatcontainer.add(this.catteam1[key].sprite);
        this.wh.combatcontainer.bringToTop(this.catteam1[key].sprite);
        var addy = 30;
        if (key.toString() == "01") {
          var tmpbref = this.battlegridbtn["1-" + this.gridh];
          this.catteam1[key].sprite.setPosition(tmpbref.x, tmpbref.y + addy);
        }
        if (key.toString() == "02") {
          var tmpbref = this.battlegridbtn["2-" + this.gridh];
          this.catteam1[key].sprite.setPosition(tmpbref.x, tmpbref.y + addy);
        }
        if (key.toString() == "03") {
          var tmpbref = this.battlegridbtn["3-" + this.gridh];
          this.catteam1[key].sprite.setPosition(tmpbref.x, tmpbref.y + addy);
        }
        if (key.toString() == "04") {
          var tmpbref = this.battlegridbtn["4-" + this.gridh];
          this.catteam1[key].sprite.setPosition(tmpbref.x, tmpbref.y + addy);
        }
        if (key.toString() == "05") {
          var tmpbref = this.battlegridbtn["5-" + this.gridh];
          this.catteam1[key].sprite.setPosition(tmpbref.x, tmpbref.y + addy);
        }
        this.catteam1[key].sprite.displayWidth = this.catteam1[key].sprite.displayHeight = 85;
      }
    }

    //////////////////////////////


    for (const key in this.catteam2) {
      if (Object.prototype.hasOwnProperty.call(this.catteam2, key)) {
        const item = Object.assign({}, this.catteam2[key]);
        if (item == "") continue;
        var cc = 0; for (const ktmp in item) cc++;

        if (item.length < 5) continue;
        if (cc < 5) continue;
        if (cc == 0) continue;
        this.catteam2[key]["spritecode"] = item["sprite"];
        this.catteam2[key].team = 2;
        this.catteam2[key].enemyteam = 1;
        this.catteam2[key].executecc = 0;
        this.catteam2[key].sprite = this.wh.add.sprite(0, 0, "" + item["sprite"]);
        this.catteam2[key].sprite.play(item["sprite"] + "-sit");
        this.catteam2[key].sprite.setPosition(300, 300);
        this.plog.log("createcatpanel i", item);
        this.wh.combatcontainer.add(this.catteam2[key].sprite);
        this.wh.combatcontainer.bringToTop(this.catteam2[key].sprite);
        var addy = 30;
        if (key.toString() == "01") {
          var tmpbref = this.battlegridbtn["1-" + this.gridh];
          this.catteam2[key].sprite.setPosition(this.width - tmpbref.x, tmpbref.y + addy);
          this.catteam2[key].sprite.flipX = true;
        }
        if (key.toString() == "02") {
          var tmpbref = this.battlegridbtn["2-" + this.gridh];
          this.catteam2[key].sprite.setPosition(this.width - tmpbref.x, tmpbref.y + addy);
          this.catteam2[key].sprite.flipX = true;
        }
        if (key.toString() == "03") {
          var tmpbref = this.battlegridbtn["3-" + this.gridh];
          this.catteam2[key].sprite.setPosition(this.width - tmpbref.x, tmpbref.y + addy);
          this.catteam2[key].sprite.flipX = true;
        }
        if (key.toString() == "04") {
          var tmpbref = this.battlegridbtn["4-" + this.gridh];
          this.catteam2[key].sprite.setPosition(this.width - tmpbref.x, tmpbref.y + addy);
          this.catteam2[key].sprite.flipX = true;
        }
        if (key.toString() == "05") {
          var tmpbref = this.battlegridbtn["5-" + this.gridh];
          this.catteam2[key].sprite.setPosition(this.width - tmpbref.x, tmpbref.y + addy);
          this.catteam2[key].sprite.flipX = true;
        }
        this.catteam2[key].sprite.displayWidth = this.catteam2[key].sprite.displayHeight = 85;
      }
    }
  }

  topclockdone = [];
  updatetopclock() {
    //this.plog.log("updatetopclock()");

    var width = this.wh.game.canvas.width;
    var height = this.wh.game.canvas.height;

    //this.timerclock.setPosition(width / 2, (this.timerclock.displayHeight / 2) + 5);
    //this.timerclock.x = width / 2;
    var minleft = Math.floor(this.timercount / 60);
    var secleft = Math.floor(this.timercount % 60);
    var minleftdsp = minleft.toString();
    var secleftdsp = secleft.toString();
    if (minleftdsp.length == 1) minleftdsp = '0' + minleftdsp;
    if (secleftdsp.length == 1) secleftdsp = '0' + secleftdsp;
    this.timerclock.setText(minleftdsp + ':' + secleftdsp);

    //this.plog.log("updatetopclock()", this.timercount);
    if (this.topclockdone[this.timercount] == undefined) {
      if (this.timercount == 52) {
        this.triggercatability(this.catteam1["04"], "superbuff");
        this.triggercatability(this.catteam2["04"], "superbuff");
      }
      if (this.timercount == 48) {
        this.triggercatability(this.catteam1["05"], "superskill");
        this.triggercatability(this.catteam2["05"], "superskill");
      }
      if (this.timercount == 55) {
        this.triggercatability(this.catteam1["01"], "stunenemy");
        this.triggercatability(this.catteam2["01"], "stunenemy");
      }
      if (this.timercount == 50) {
        this.triggercatability(this.catteam1["01"], "stunenemy");
        this.triggercatability(this.catteam2["01"], "stunenemy");
        this.triggercatability(this.catteam1["03"], "eatenemy");
        this.triggercatability(this.catteam2["03"], "eatenemy");
      }

      if (this.timercount == 45) {
        this.triggercatability(this.catteam1["01"], "stunenemy");
        this.triggercatability(this.catteam2["01"], "stunenemy");
      }
      if (this.timercount == 40) {
        this.triggercatability(this.catteam1["01"], "stunenemy");
        this.triggercatability(this.catteam2["01"], "stunenemy");
        this.triggercatability(this.catteam1["03"], "eatenemy");
        this.triggercatability(this.catteam2["03"], "eatenemy");
      }
      if (this.timercount == 35) {
        this.triggercatability(this.catteam1["01"], "stunenemy");
        this.triggercatability(this.catteam2["01"], "stunenemy");
      }
      if (this.timercount == 30) {
        this.triggercatability(this.catteam1["01"], "stunenemy");
        this.triggercatability(this.catteam2["01"], "stunenemy");
        this.triggercatability(this.catteam1["03"], "eatenemy");
        this.triggercatability(this.catteam2["03"], "eatenemy");
      }

      if (this.timercount == 25) {
        this.triggercatability(this.catteam1["01"], "stunenemy");
        this.triggercatability(this.catteam2["01"], "stunenemy");
      }
      if (this.timercount == 20) {
        this.triggercatability(this.catteam1["01"], "stunenemy");
        this.triggercatability(this.catteam2["01"], "stunenemy");
        this.triggercatability(this.catteam1["03"], "eatenemy");
        this.triggercatability(this.catteam2["03"], "eatenemy");
      }

      if (this.timercount == 15) {
        this.triggercatability(this.catteam1["01"], "stunenemy");
        this.triggercatability(this.catteam2["01"], "stunenemy");
      }
      if (this.timercount == 10) {
        this.triggercatability(this.catteam1["01"], "stunenemy");
        this.triggercatability(this.catteam2["01"], "stunenemy");
        this.triggercatability(this.catteam1["03"], "eatenemy");
        this.triggercatability(this.catteam2["03"], "eatenemy");
      }

      if (this.timercount == 5) {
        this.triggercatability(this.catteam1["01"], "stunenemy");
        this.triggercatability(this.catteam2["01"], "stunenemy");
      }
      if (this.timercount == 1) {
        this.triggercatability(this.catteam1["02"], "addtime");
        this.triggercatability(this.catteam2["02"], "addtime");
      }
      this.topclockdone[this.timercount] = 1;
    }
  }
  async triggercatability(catid, action) {

    var cct1 = 0; for (const ktmp in catid) cct1++;
    if (cct1 < 5) return;
    this.plog.log("triggercatability", catid, action)
    catid.executecc++;

    switch (action) {
      case "stunenemy":
        var tgy = catid.sprite.y - 100;
        var origy = catid.sprite.y;
        var tgscale = catid.sprite.scale * 1.5;
        var origscale = catid.sprite.scale;
        this.plog.log("stunenemy", tgy, origy);
        var timeline = this.wh.add.timeline([
          {
            at: 1,
            tween:
            {
              targets: catid.sprite,
              y: { from: origy, to: tgy },
              scale: { from: origscale, to: tgscale },
              ease: 'Power3',
              duration: 200 * this.timespeed,
              onComplete: function () {
                this.plog.log("oncomplete");
                var tg = this.getrandomfromteam(catid.enemyteam);
                if (tg != undefined) {
                  this.plog.log("stunenemy", tg);
                  tg.statuses.push({
                    stun: 1,
                    time: this.gett() + (5000),
                    icon: ""
                  });
                }
                this.dspcbteffect(tg, "ixbeff245_b");
                var tmp;
                tmp = this.dspcateffect(catid, "ixbeff245_b");
                this.removeeff(tmp);

                tmp.displayWidth = 100;
                tmp.scaleY = tmp.scaleX;

              }.bind(this)
            }
          }, {
            at: 200 * this.timespeed,
            tween: {
              targets: catid.sprite,
              loop: 0,
              onComplete: function () {
                this.plog.log("oncomplete");
                var tg = this.getrandomfromteam(catid.enemyteam);
                if (tg != undefined) {
                  this.plog.log("stunenemy", tg);
                  tg.statuses.push({
                    stun: 1,
                    time: this.gett() + (5000),
                    icon: ""
                  });
                }
                this.dspcbteffect(tg, "ixbeff245_b");
                var tmp;
                tmp = this.dspcateffect(catid, "ixbeff245_b");
                this.removeeff(tmp);

                tmp.displayWidth = 100;
                tmp.scaleY = tmp.scaleX;

              }.bind(this),

              y: { from: tgy, to: origy },
              scale: { from: tgscale, to: origscale },
              ease: 'Bounce',
              duration: 200 * this.timespeed,
            }
          }
        ]);
        timeline.play();
        break;
      case "eatenemy":
        if (
          this.config['combatmode'] == 'challenge' ||
          this.config['combatmode'] == 'svboss' ||
          this.config['combatmode'] == 'boss'
        ) {
          break;
        }
        var tg = this.getrandomfromteam(catid.enemyteam);
        if (tg == undefined) {
          this.plog.log("eatenemy no target", tg);
          break;
        }
        this.plog.log("eatenemy", tg);
        tg.statuses.push({
          stun: 1,
          time: this.gett() + (5000),
          icon: ""
        });

        catid.sprite.play({ key: catid.spritecode + "-jump", repeat: 0, startFrame: 3 });
        this.battlepause();
        this.timer.paused = true;
        //this.plog.error("eatenemy paused", catid, this.timer);

        await this.wh.registry.list.rctoast.sleep(200);

        var tgy = tg.sprite.y - 5;
        var tgx = tg.sprite.x - 64;
        if (catid.team == 2) {
          tgx = tg.sprite.x + 64;
        }
        var origy = catid.sprite.y;
        var origx = catid.sprite.x;
        var tgscale = catid.sprite.scale * 2;
        var origscale = catid.sprite.scale;

        var timepline = this.wh.add.timeline([
          {
            at: 0,
            tween: {
              targets: catid.sprite,
              y: { from: origy, to: tgy },
              x: { from: origx, to: tgx },
              scale: { from: origscale, to: tgscale },
              ease: 'Power3',
              duration: 500 * this.timespeed,
              onComplete: function () {
                //console.error("oncompletex",this,arguments);
                this.wh.plog.log("oncomplete", catid, arguments);
                this.wh.anims.resumeAll();
                try {
                  catid.sprite.play({ key: catid.spritecode + "-attack", repeat: 0 });
                } catch (e) {
                  console.log(e);
                }
                tg.cbt["hp"] = 0;

              }.bind(this)
            }
          }, {
            at: 500 * this.timespeed,
            tween: {
              targets: tg.sprite,
              angle: 359,
              x: { from: tg.sprite.x, to: tg.sprite.x + this.width },
              ease: 'Linear',
              duration: 1500 * this.timespeed, // duration of animation; higher=slower
              delay: 0,
              repeat: 0,
              onComplete: function () {
                this.wh.plog.log("oncomplete", catid, arguments);
              }.bind(this),
            }
          }
        ]
        );
        timepline.play();

        var tmptxt;
        tmptxt = this.wh.add.text(0, 0, 'POW', {
          fontFamily: 'ffff',
          fontSize: '32px',
          stroke: '#f00',
          strokeThickness: 6,
          color: '#fff',
        });
        tmptxt.setOrigin(0.5, 0.5);
        this.wh.combatcontainer.add(tmptxt);
        this.wh.combatcontainer.bringToTop(tmptxt);
        tmptxt.setPosition(this.width / 2, this.height / 2);

        var tw2 = this.wh.tweens.add({
          targets: tmptxt,
          angle: 359,
          ease: 'Linear',
          duration: 500 * this.timespeed, // duration of animation; higher=slower
          delay: 0,
          repeat: 0,
          onComplete: function () {
            //this.plog.log("oncomplete", catid, arguments);
          }.bind(this),
        });
        await this.wh.registry.list.rctoast.sleep(1500 * this.timespeed);
        catid.sprite.scale = origscale;
        catid.sprite.x = origx;
        catid.sprite.y = origy;
        try {
          catid.sprite.play(catid.spritecode + "-sit");
        } catch (e) {
          this.plog.log(e);
        }


        this.battleresume();
        tmptxt.destroy();

        break;


      case "addtime":
        if (catid.executecc > 1) break;
        this.battlepause();
        //this.plog.error("eatenemy paused", catid, this.timer);
        catid.sprite.play({ key: catid.spritecode + "-jump", repeat: 0, startFrame: 3 });
        await this.wh.registry.list.rctoast.sleep(100);

        var tgy = catid.sprite.y - 100;
        var origy = catid.sprite.y;
        var origx = catid.sprite.x;
        var tgscale = catid.sprite.scale * 2;
        var origscale = catid.sprite.scale;

        var timeline = this.wh.add.timeline([
          {
            at: 0,
            tween: {
              targets: catid.sprite,
              y: { from: origy, to: this.height / 2 },
              x: { from: origx, to: this.width / 2 },
              scale: { from: origscale, to: tgscale },
              ease: 'Power3',
              duration: 500 * this.timespeed, onComplete: function () {
                //this.plog.log("oncomplete", catid, arguments);


              }.bind(this)
            }
          }

        ]);
        timeline.play();

        var tmptxt;
        tmptxt = this.wh.add.text(0, 0, 'ADD TIME!', {
          fontFamily: 'ffff',
          fontSize: '32px',
          stroke: '#f00',
          strokeThickness: 6,
          color: '#fff',
        });
        tmptxt.setOrigin(0.5, 0.5);
        this.wh.combatcontainer.add(tmptxt);
        this.wh.combatcontainer.bringToTop(tmptxt);
        tmptxt.setPosition(this.width / 2, this.height / 2);

        var tw2 = this.wh.tweens.add({
          targets: tmptxt,
          angle: 359,
          ease: 'Linear',
          duration: 500 * this.timespeed, // duration of animation; higher=slower
          delay: 0,
          repeat: 0,
          onComplete: function () {
            //this.plog.log("oncomplete", catid, arguments);
          }.bind(this),
        });
        await this.wh.registry.list.rctoast.sleep(2000 * this.timespeed);
        this.topclockdone = [];
        catid.sprite.scale = origscale;
        catid.sprite.x = origx;
        catid.sprite.y = origy;
        catid.sprite.play(catid.spritecode + "-sit")

        var tmp;
        tmp = this.wh.registry.list.rcvarpass.gameobj.scene.scenes[0].add.sprite(100, 100, "" + "ixbeff29_b").play("" + "ixbeff29_b");

        this.wh.combatcontainer.add(tmp);
        this.wh.combatcontainer.bringToTop(tmp);
        tmp.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);;

        tmp.displayWidth = tmp.displayHeight = 55;
        tmp.setPosition(catid.sprite.x, catid.sprite.y);
        tmp.y = tmp.y - 10;
        tmp.x = tmp.x - 5;
        tmp.setAlpha(0.8)
        this.battleresume();
        tmptxt.destroy();

        var tmp2cataddtime;
        //tmp2cataddtime = this.dspcateffect(catid, "ixbeff29_b");
        tmp2cataddtime = this.wh.registry.list.rcvarpass.gameobj.scene.scenes[0].add.sprite(100, 100, "" + "ixbeff29_b").play("" + "ixbeff29_b");

        this.wh.combatcontainer.add(tmp2cataddtime);
        this.wh.combatcontainer.bringToTop(tmp2cataddtime);
        tmp2cataddtime.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);;
        tmp2cataddtime.setOrigin(0, 0);
        tmp2cataddtime.displayWidth = 150;//this.timerclock.displayWidth;
        tmp2cataddtime.displayHeight = 50;//this.timerclock.displayHeight;
        tmp2cataddtime.x = 10;//this.timerclock.x+1;
        tmp2cataddtime.y = 10;//this.timerclock.y+1;
        //tmp2.y = tmp2.y - 10;        tmp2.x = tmp2.x - 5;
        this.removeeff(tmp2cataddtime, 1000);
        tmp2cataddtime.setOrigin(0, 0);
        if (catid.executecc == 1) {
          this.timercount = 60;
        }
        this.plog.log("addtime effaf", tmp2cataddtime);
        break;

      case "superbuff":
        if (catid.executecc > 1) break;
        //this.battlepause();
        //this.plog.error("superbuff ", catid, this.timer);
        var tg = this.getrandomfromteam(catid.team);
        if (tg == undefined) {
          this.plog.log("superbuff can't find tg", tg);
          break;
        }
        tg.cbt["patk"] = tg.cbt["patk"] * 10;
        tg.cbt["def"] = tg.cbt["def"] * 5;
        //this.plog.error("superbuff tg", tg);

        catid.sprite.play({ key: catid.spritecode + "-jump", repeat: 0, startFrame: 3 });
        await this.wh.registry.list.rctoast.sleep(100);

        var tmptxt;
        tmptxt = this.wh.add.text(0, 0, 'Meow Buff!', {
          fontFamily: 'ffff',
          fontSize: '32px',
          stroke: '#f00',
          strokeThickness: 6,
          color: '#fff',
        });
        tmptxt.setOrigin(0.5, 0.5);
        this.wh.combatcontainer.add(tmptxt);
        this.wh.combatcontainer.bringToTop(tmptxt);
        tmptxt.setPosition(this.width / 2, this.height / 2);

        var tw2 = this.wh.tweens.add({
          targets: tmptxt,
          angle: 359,
          ease: 'Linear',
          duration: 500 * this.timespeed, // duration of animation; higher=slower
          delay: 0,
          repeat: 0,
          onComplete: function () {
            //this.plog.log("tmptxt oncomplete", catid, arguments);
            try {
              arguments[1][0].destroy();
            } catch (e) {
              console.log(e);
            }
          }.bind(this),
        });

        //await this.wh.registry.list.rctoast.sleep(2000 * this.timespeed);
        /*catid.sprite.scale = origscale;
        catid.sprite.x = origx;
        catid.sprite.y = origy;*/
        catid.sprite.play(catid.spritecode + "-sit")

        var tmp;
        tmp = this.wh.registry.list.rcvarpass.gameobj.scene.scenes[0].add.sprite(100, 100, "" + "ixbeff256_b").play("" + "ixbeff256_b");

        this.wh.combatcontainer.add(tmp);
        this.wh.combatcontainer.bringToTop(tmp);
        tmp.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);;

        tmp.displayWidth = tmp.displayHeight = 55;
        tmp.setPosition(catid.sprite.x, catid.sprite.y);
        tmp.y = tmp.y - 10;
        tmp.x = tmp.x - 5;

        var tmp2cataddtime;
        //tmp2cataddtime = this.dspcateffect(catid, "ixbeff29_b");
        tmp2cataddtime = this.wh.registry.list.rcvarpass.gameobj.scene.scenes[0].add.sprite(100, 100, "" + "ixbeff256_b").play("" + "ixbeff256_b");

        this.wh.combatcontainer.add(tmp2cataddtime);
        this.wh.combatcontainer.bringToTop(tmp2cataddtime);
        tmp2cataddtime.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);;
        this.followset.push({ leader: tg.sprite, follower: tmp2cataddtime });
        this.nostatuses.push(tg);
        //tmp2.y = tmp2.y - 10;        tmp2.x = tmp2.x - 5;
        tmp2cataddtime.setOrigin(0, 0);
        tmp2cataddtime.displayWidth = 120;
        tmp2cataddtime.scaleY = tmp2cataddtime.scaleX;
        //this.plog.log("superbuff effaf", tmp2cataddtime);
        break;


      case "superskill":
        if (catid.executecc > 1) break;
        //this.battlepause();
        //this.plog.error("superskill ", catid, this.timer);
        var tg = this.getrandomfromteam(catid.team);
        if (tg == undefined) {
          this.plog.log("superskill can't find tg", tg);
          break;
        }
        tg.cbt["patk"] = tg.cbt["patk"] * 10;
        tg.cbt["def"] = tg.cbt["def"] * 5;
        //this.plog.error("superskill tg", tg);

        catid.sprite.play({ key: catid.spritecode + "-jump", repeat: 0, startFrame: 3 });
        await this.wh.registry.list.rctoast.sleep(100);

        var tmptxt;
        tmptxt = this.wh.add.text(0, 0, 'Meow Buff!', {
          fontFamily: 'ffff',
          fontSize: '32px',
          stroke: '#f00',
          strokeThickness: 6,
          color: '#fff',
        });
        tmptxt.setOrigin(0.5, 0.5);
        this.wh.combatcontainer.add(tmptxt);
        this.wh.combatcontainer.bringToTop(tmptxt);
        tmptxt.setPosition(this.width / 2, this.height / 2);

        var tw2 = this.wh.tweens.add({
          targets: tmptxt,
          angle: 359,
          ease: 'Linear',
          duration: 500 * this.timespeed, // duration of animation; higher=slower
          delay: 0,
          repeat: 0,
          onComplete: function () {
            //this.plog.log("tmptxt oncomplete", catid, arguments);
            try {
              arguments[1][0].destroy();
            } catch (e) {
              console.log(e);
            }
          }.bind(this),
        });

        //await this.wh.registry.list.rctoast.sleep(2000 * this.timespeed);
        /*catid.sprite.scale = origscale;
        catid.sprite.x = origx;
        catid.sprite.y = origy;*/
        catid.sprite.play(catid.spritecode + "-sit")

        var tmp;
        tmp = this.wh.registry.list.rcvarpass.gameobj.scene.scenes[0].add.sprite(100, 100, "" + "ixbeff216_b").play("" + "ixbeff216_b");

        this.wh.combatcontainer.add(tmp);
        this.wh.combatcontainer.bringToTop(tmp);
        tmp.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);;

        tmp.displayWidth = tmp.displayHeight = 55;
        tmp.setPosition(catid.sprite.x, catid.sprite.y);
        tmp.y = tmp.y - 10;
        tmp.x = tmp.x - 5;

        var tmp2cataddtime;
        //tmp2cataddtime = this.dspcateffect(catid, "ixbeff29_b");
        tmp2cataddtime = this.wh.registry.list.rcvarpass.gameobj.scene.scenes[0].add.sprite(100, 100, "" + "ixbeff216_b").play("" + "ixbeff216_b");

        this.wh.combatcontainer.add(tmp2cataddtime);
        this.wh.combatcontainer.bringToTop(tmp2cataddtime);
        tmp2cataddtime.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);;
        this.followset.push({ leader: tg.sprite, follower: tmp2cataddtime });
        this.superenergy.push(tg);
        //tmp2.y = tmp2.y - 10;        tmp2.x = tmp2.x - 5;
        tmp2cataddtime.setOrigin(0, 0);
        tmp2cataddtime.displayWidth = 120;
        tmp2cataddtime.scaleY = tmp2cataddtime.scaleX;
        //this.plog.log("superbuff effaf", tmp2cataddtime);
        break;

      default:
        break;
    }
  }
  getrandomfromteam(team) {
    var rnd = [];
    for (const k in this.cbto) {
      var v = this.cbto[k];
      if (v == undefined) continue;
      if (v.cbt == undefined) continue;
      if (v.cbt["team"] == team) {
        //this.plog.log("getrandomfromteam", v);
        rnd.push(v);
      }
    }

    var rtg = rnd[Math.floor(Math.random() * rnd.length)];
    // this.plog.log("MapPet.catbehave catplay catdoing", catdoing, this.catbehavehowlonginrange);
    //catdoing = "atkmob";
    return rtg;
  }
  dspcbteffect(tg, effect) {
    if (tg == undefined) {
      return this.wh.registry.list.rcvarpass.gameobj.scene.scenes[0].add.sprite(100, 100, "spacera").play("spacera_b");
    };
    const tmp = tg.doskill_createeff(effect, effect);
    this.wh.combatcontainer.add(tmp);
    this.wh.combatcontainer.bringToTop(tmp);
    tmp.setPosition(tg.sprite.x, tg.sprite.y);
    this.removeeff(tmp);
  }

  dspcateffect(tg, effect) {
    const tmp = this.createcateff(effect, effect);
    this.wh.combatcontainer.add(tmp);
    this.wh.combatcontainer.bringToTop(tmp);
    tmp.setPosition(tg.sprite.x, tg.sprite.y);

    return tmp;
  }

  createcateff(a, b) {
    //this.plog.log("doskill_createeff", this.wh.combateffcount);
    var tmp = this.wh.registry.list.rcvarpass.gameobj.scene.scenes[0].add.sprite(100, 100, "" + a).play({ key: "" + b, repeat: 0 });
    tmp.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);;
    return tmp;
  }

  removeeff(wh, dur: number = 400) {
    var tw = this.wh.tweens.add(
      {
        targets: wh,
        alpha: 0,
        ease: 'Power3',
        duration: 50 * this.timespeed, // duration of animation; higher=slower
        delay: dur * this.timespeed,
        onComplete: function () {
          //this.plog.log("oncomplete combatant walk", this, arguments);

          try {
            arguments[1][0].destroy();
          } catch (e) {
            this.plog.log("ERROR intween", e);
          }
          try {
            this.targets[0].destroy();
          } catch (e) {
            this.plog.log("ERROR intween", e);
          }
        }  // set context? how?
      }, this
    );
  }
  battlepause() {
    this.timer.paused = true;
    this.wh.anims.pauseAll();
  }
  battleresume() {
    this.timer.paused = false;
    this.wh.anims.resumeAll();
  }
}
