import { Injectable } from '@angular/core';
import { PGBar } from './pgbar';
import { plog } from './plog.service';

@Injectable({
  providedIn: 'root',
})
export class ShipNavigationService {
  hometw;
  travellingto = "";
  landbtn;
  timeperclick = 16;
  timeperclickbase = 16;
  angle1 = 0;
  distance1 = 100;
  wh;
  scrolltimer;
  spincursor = 0;
  selected;
  playbtn;
  closing = false;
  floatertimerfunctime = 2000;
  bg;
  bg1; bg2; bg3;
  closebtn;
  startimer;
  circ1;
  circ2;
  powereffect;
  fishbar;
  rodpower = 1;
  hookpower = 1;
  floater;
  floatertimer;
  astring;
  astringstep = 0;
  waittime = 10;
  powerpgbar;
  powertimer;
  currentpower = 0;
  fishingrule = '';
  currentstar = 0;
  data;
  txt;
  ll1; ll2;
  starsprite = [];
  linesprite = [];
  linesprite2 = [];
  plntname = [];
  homesprite;
  istravelling = false;
  zoomlevel = [0.1, 0.3, 0.5, 0.8, 1, 2, 4];
  zoom = 4;
  zoominbtn;
  zoomoutbtn;
  plog;

  constructor() { 
    this.plog=new plog();
  }

  async show(wh) {
    this.plog.log('shipnavigation start', wh);
    this.close();
    if (this.scrolltimer != undefined) this.scrolltimer.remove();
    if (this.scrolltimer != undefined) this.scrolltimer.remove();
    if (this.scrolltimer != undefined) this.scrolltimer.remove();


    this.zoom = 4;
    this.currentpower = 0;
    this.currentstar = 0;
    this.closing = false;
    this.wh = wh;

    var width = wh.game.canvas.width;
    var height = wh.game.canvas.height;
    /*
        this.wh.tweens.add({
          targets: this.wh.mapcontainer,
          scale: { from: 1, to: 0.1 },
          x: { from: 0, to: width / 2 },
          y: { from: 0, to: height / 2 },
          ease: 'Power2',
          loop: 0,
          duration: 2000, // duration of animation; higher=slower
          yoyo: false
        });
        await this.wh.registry.list.rctoast.sleep(2000);
    */

    this.data = await this.wh.registry.list.rchttp.getcommon2("mapspace", {});
    await this.wh.registry.list.rchttp.updatecharinfo();

    if (wh.shipnavigationcontainer == undefined) {
      wh.shipnavigationcontainer = wh.add.container(0, 0);
    }
    if (wh.shipnavigationcontainer_gui == undefined) {
      wh.shipnavigationcontainer_gui = wh.add.container(0, 0);
    }
    if (wh.shipnavigationcontainer_bg == undefined) {
      wh.shipnavigationcontainer_bg = wh.add.container(0, 0);
    }
    //wh.shipnavigationcontainer.setDepth(38000);
    wh.shipnavigationcontainer_bg.setDepth(99999);
    wh.shipnavigationcontainer.setDepth(100000);
    wh.shipnavigationcontainer_gui.setDepth(100001);

    let loader = new Phaser.Loader.LoaderPlugin(wh);

    var svversionurlstring = this.wh.registry.list.rcvarpass.svversionstring;
    if (svversionurlstring != '') {
      svversionurlstring = '?' + svversionurlstring;
    }

    for (var k in this.data['star']) {
      var key = this.data['star'][k]['icon'];
      if (key == "xx") continue;
      var keya = key.split("_");
      loader.atlas(
        keya[0] + '',
        this.wh.registry.list.rcvarpass.rc_baseurl +
        'sprite/' +
        keya[0] +
        '.png',
        this.wh.registry.list.rcvarpass.rc_baseurl +
        'sprite/' +
        keya[0] +
        '.json'
      )
    }

    var loadlist = ['spacebg/bg1-0', 'spacebg/bg2-5', 'spacebg/bg3-10'];
    for (var k in loadlist) {
      var keyii = loadlist[k];
      if (keyii == "xx") continue;
      var tmpurl = this.wh.registry.list.rcvarpass.getlocal(

        keyii +
        '.png' +
        svversionurlstring
      );
      loader.image(
        keyii + '',
        tmpurl
      );
    }

    var loadlist = ['homemapspace'];
    for (var k in loadlist) {
      var key2 = loadlist[k];
      if (key2 == "xx") continue;
      loader.atlas(
        key2 + '',
        this.wh.registry.list.rcvarpass.rc_baseurl +
        'sprite/' +
        key2 +
        '.png' +
        svversionurlstring,
        this.wh.registry.list.rcvarpass.rc_baseurl +
        'sprite/' +
        key2 +
        '.json' +
        svversionurlstring
      );
    }
    this.wh.registry.list.rcloading.loading2(this.wh);
    loader.on(
      'progress',
      function (value) {
        //this.plog.log("loaderevent", value, loader.totalComplete, loader.totalToLoad);
        this.wh.registry.list.rcloading.loading2text(
          'Loading ' + loader.totalComplete + '/' + loader.totalToLoad
        );
        this.wh.registry.list.rcloading.loading2percent(value);
      },
      this
    );
    loader.once(
      Phaser.Loader.Events.COMPLETE,
      () => {
        this.plog.log('Phaser.Loader.Events.COMPLETE');
        this.plog.log(this);
        this.show_loaded();
      },
      this
    );
    loader.start();
  }
  async show_loaded() {
    this.plog.log("shipnavigation.show_loaded();");
    var wh = this.wh;
    var chardata = wh.registry.list.rcvarpass.activechar;

    var width = wh.game.canvas.width;
    var height = wh.game.canvas.height;

    this.bg1 = wh.add.tileSprite(0, 0, width, height, "spacebg/bg1-0")
      .setOrigin(0, 0)
      .setScrollFactor(0, 1); //this line keeps your background from scrolling outside of camera bounds
    this.bg1.setInteractive();

    wh.shipnavigationcontainer_bg.add(this.bg1);
    wh.shipnavigationcontainer_bg.bringToTop(this.bg1);
    /*this.bg2 = wh.add.tileSprite(0, 0, width, height, "spacebg/bg2-5")
      .setOrigin(0, 0)
      .setScrollFactor(0, 1); //this line keeps your background from scrolling outside of camera bounds
    wh.shipnavigationcontainer.add(this.bg2);
    wh.shipnavigationcontainer.bringToTop(this.bg2);
    this.bg3 = wh.add.tileSprite(0, 0, width, height, "spacebg/bg3-10")
      .setOrigin(0, 0)
      .setScrollFactor(0, 1); //this line keeps your background from scrolling outside of camera bounds
    wh.shipnavigationcontainer.add(this.bg3);
    wh.shipnavigationcontainer.bringToTop(this.bg3);
    */
    for (var k in this.data['star']) {
      var key = this.data['star'][k]['icon'];
      if (key == "xx") continue;
      var keya = key.split("_");
      this.wh.registry.list.gameitf.genloadedanims(this.wh, key);
    }
    this.wh.registry.list.gameitf.genloadedanims(this.wh, "homemapspace_b");
    //this.plog.log("this.wh", this.wh);
    var i = 0;
    for (var k in this.data['star']) {
      var x = this.data['star'][k]['x'];
      var y = this.data['star'][k]['y'];
      var key = this.data['star'][k]['icon'];
      var sscale = this.data['star'][k]['size'];
      var name = this.data['star'][k]['name'];
      var osize = this.data['star'][k]['osize'];
      if (key == "xx") continue;
      i++;
      var keya = key.split("_");
      this.starsprite[k] = this.wh.add.sprite(x / 1, y / 1, keya[0]);
      this.starsprite[k].play({
        key: key,
        frameRate: 8,
        //repeat: 0,
      })
      this.starsprite[k].scaleX = this.starsprite[k].scaleY = sscale;
      //, key

      wh.shipnavigationcontainer.add(this.starsprite[k]);
      wh.shipnavigationcontainer.bringToTop(this.starsprite[k]);
      /*this.bg.setPosition(width / 2, height / 2);
      this.bg.displayWidth = width;
      this.bg.displayHeight = height;*/
      this.starsprite[k].texture.setFilter(Phaser.Textures.FilterMode.NEAREST);;

      this.starsprite[k].setInteractive({ pixelPerfect: true });
      this.starsprite[k].removeAllListeners();
      this.starsprite[k].name = k;
      this.starsprite[k].on(
        'pointerdown',
        function () {
          this.scene.plog.log(arguments, this);//return;
          //this.plog.log(this,this.name);//return;
          this.scene.registry.list.shipnavigation.goto(this.scene, this.name);
        }
      );

      //planet name

      this.plntname[k] = wh.add.text(0, 0, '' + name, {
        fontFamily: 'ffff',
        //fontStyle: 'bold',
        fontSize: '16px',
        color: '#FFFFFF',
        stroke: '#4A90E2',
        strokeThickness: 4,
        shadow: { color: '#2F2F2F', fill: true, blur: 17, stroke: true, offsetX: 2, offsetY: 2 },
        resolution: 4
      });
      this.plntname[k].setPosition(x - (this.plntname[k].displayWidth / 2), y + osize * 16);
      wh.shipnavigationcontainer.add(this.plntname[k]);
      wh.shipnavigationcontainer.bringToTop(this.plntname[k]);
    }

    for (var k in this.data['line']) {
      //this.plog.log("lines", k, this.data['line'][k]);
      var lss = this.data['line'][k]['s'];
      var lee = this.data['line'][k]['e'];
      var ltype = this.data['line'][k]['type'];
      var lcol = this.data['line'][k]['col'];
      var stars = this.starsprite[lss];
      var stare = this.starsprite[lee];
      if (key == "xx") continue;
      this.linesprite[k] = this.wh.add.sprite(0, 0, "thecolor", "" + lcol).setOrigin(0, 0.5);
      this.linesprite[k].texture.setFilter(Phaser.Textures.FilterMode.NEAREST);;
      this.linesprite2[k] = this.wh.add.sprite(0, 0, "thecolor", "" + lcol).setOrigin(0, 0.5);
      this.linesprite2[k].texture.setFilter(Phaser.Textures.FilterMode.NEAREST);;
      wh.shipnavigationcontainer.add(this.linesprite[k]);
      wh.shipnavigationcontainer.sendToBack(this.linesprite[k]);
      wh.shipnavigationcontainer.add(this.linesprite2[k]);
      wh.shipnavigationcontainer.sendToBack(this.linesprite2[k]);
      var ldist = this.calculateDistance(stars.x, stars.y, stare.x, stare.y);
      var langle = this.calculateAngle(stars.x, stars.y, stare.x, stare.y)
      this.linesprite[k].setPosition(stars.x, stars.y);
      this.linesprite[k].displayWidth = ldist;
      this.linesprite[k].displayHeight = 4;
      this.linesprite[k].setAngle(langle);
      this.linesprite2[k].setPosition(stars.x, stars.y);
      this.linesprite2[k].displayWidth = ldist;
      this.linesprite2[k].displayHeight = 16;
      this.linesprite2[k].setAngle(langle);
      this.linesprite2[k].setAlpha(0.3);
      //this.plog.log("lines o", this.linesprite[k], ldist);

      this.wh.tweens.add({
        targets: this.linesprite[k],
        alpha: { from: 0.3, to: 0.7 },
        ease: 'Power2',
        loop: -1,
        duration: 3000, // duration of animation; higher=slower
        yoyo: true
      });

      this.linesprite2[k].setInteractive();
      this.linesprite2[k].removeAllListeners();
      this.linesprite2[k].name = k;
      this.linesprite2[k].on(
        'pointerdown',
        function () {
          this.scene.plog.log(arguments, this);//return;
          var linedat = this.scene.registry.list.shipnavigation.data['line'][this.name];
          var lss = linedat['s'];
          var lee = linedat['e'];
          //this.plog.log("linedat", linedat, lss, lee);
          var chardata = this.scene.registry.list.rcvarpass.activechar;
          var amat = chardata['mapspace']['at'];
          var dst = lss + "";
          if (amat == lss) {
            dst = lee;
          }
          this.scene.plog.log("imat", amat, "goingto", dst);
          this.scene.registry.list.shipnavigation.goto(this.scene, dst);
        });
    }
    wh.shipnavigationcontainer.sendToBack(this.bg1);

    this.wh.registry.list.rcloading.close();

    this.scrolltimer = wh.time.addEvent({
      delay: 50, // ms
      callback: this.scrolltimerfunc,
      //args: [],
      callbackScope: this,
      loop: true,
    });

    this.closebtn = wh.add.sprite(0, 0, "thesq", 'close');
    wh.shipnavigationcontainer_gui.add(this.closebtn);
    wh.shipnavigationcontainer_gui.bringToTop(this.closebtn);
    this.zoominbtn = wh.add.sprite(0, 0, "thesq", 'zoomin');
    wh.shipnavigationcontainer_gui.add(this.zoominbtn);
    wh.shipnavigationcontainer_gui.bringToTop(this.zoominbtn);
    this.zoomoutbtn = wh.add.sprite(0, 0, "thesq", 'zoomout');
    wh.shipnavigationcontainer_gui.add(this.zoomoutbtn);
    wh.shipnavigationcontainer_gui.bringToTop(this.zoomoutbtn);

    this.landbtn = wh.add.sprite(0, 0, "thesb", 'land');
    wh.shipnavigationcontainer.add(this.landbtn);
    wh.shipnavigationcontainer.bringToTop(this.landbtn);
    this.landbtn.visible = false;

    this.landbtn.setInteractive({ pixelPerfect: true });
    this.landbtn.removeAllListeners();
    this.landbtn.name = k;
    this.landbtn.on(
      'pointerdown',
      function () {
        //this.plog.log(this,this.name);//return;
        var chardata = this.wh.registry.list.rcvarpass.activechar;
        this.close();
        this.wh.plog.log("landbtn", this.data['star'][chardata["mapspace"]["at"]]["map"]);//return;
        this.wh.chkwarppos_warp(this.wh, "" + this.data['star'][chardata["mapspace"]["at"]]["map"]);
        //this.wh.registry.list.phm.initmap(); 
      }, this
    );
    this.closebtn.displayWidth = this.closebtn.displayHeight = 64;
    this.zoominbtn.displayWidth = this.zoominbtn.displayHeight = 64;
    this.zoomoutbtn.displayWidth = this.zoomoutbtn.displayHeight = 64;
    //this.closebtn.setPosition(40, height - 40);
    this.closebtn.setInteractive();
    this.closebtn.removeAllListeners();
    this.closebtn.on(
      'pointerdown',
      function () {
        this.close();
      },
      this
    );

    this.zoomoutbtn.setInteractive();
    this.zoomoutbtn.removeAllListeners();
    this.zoomoutbtn.on(
      'pointerdown',
      function () {
        this.zoomin();
      },
      this
    );

    this.zoominbtn.setInteractive();
    this.zoominbtn.removeAllListeners();
    this.zoominbtn.on(
      'pointerdown',
      function () {
        this.zoomout();
      },
      this
    );



    this.homesprite = this.wh.add.sprite(0, 0, "homemapspace");
    this.homesprite.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);;
    this.homesprite.play({
      key: "homemapspace_b",
      frameRate: 60,
      //repeat: 0,
    })
    this.homesprite.displayWidth = 32;
    this.homesprite.displayHeight = 32;

    wh.shipnavigationcontainer.add(this.homesprite);
    wh.shipnavigationcontainer.bringToTop(this.homesprite);
    if (chardata['mapspace'] == undefined || chardata['mapspace']['at'] == undefined) {
      chardata['mapspace']['at'] = "peacely";
    }

    this.distance1 = this.data["star"][chardata['mapspace']['at']]["osize"] * 32;
    var homex = this.data['star'][chardata['mapspace']['at']]["x"];
    var homey = this.data['star'][chardata['mapspace']['at']]["y"];
    if (chardata['mapspace']['going'] == "") {
      this.homesprite.setPosition(homex, homey);
    } else {
      var currentpercent = this.calculateProgressPercentage(chardata['mapspace']['goings'], chardata['mapspace']['goinge'], chardata['mapspace']['currenttime']);
      if (currentpercent < 100) {
        var tgpos = wh.registry.list.shipnavigation.calculateDistancebf(this.homesprite, this.starsprite[chardata['mapspace']['going']], this.data['star'][chardata['mapspace']['going']]['osize'] * 32);
        var poscurrent = this.interpolateCoordinates({ x: this.data['star'][chardata['mapspace']['at']]["x"], y: this.data['star'][chardata['mapspace']['at']]["y"] }, { x: tgpos.x, y: tgpos.y }, currentpercent);
        this.plog.log("resumetravel", currentpercent, "%", poscurrent);
        this.homesprite.setPosition(poscurrent.x, poscurrent.y);
        this.travellingto = chardata['mapspace']['going'];
        this.istravelling = true;
        var dist = wh.registry.list.shipnavigation.calculateDistance(poscurrent.x, poscurrent.y, this.data['star'][chardata['mapspace']['going']]["x"], this.data['star'][chardata['mapspace']['going']]["y"]);

        this.timeperclick = this.timeperclickbase;
        //this.plog.log("gotoinputitemdata", inputitemdata);
        var inputitemdata = wh.registry.list.rcvarpass.inputitem

        for (var k in inputitemdata) {
          if ((k + "").indexOf("shipfuel") == -1) continue;
          var v = inputitemdata[k];
          if (v.selfactivate == 1) {
            this.timeperclick = this.timeperclick * 0.7;
          }
        }
        this.timeperclick = Math.floor(this.timeperclick);
        var usetime = dist * this.timeperclick;
        try {
          this.hometw.stop();
        } catch (e) {
          this.plog.log(e);
        }
        this.hometw = wh.tweens.add({
          targets: this.homesprite,
          x: { from: poscurrent.x, to: tgpos.x },
          y: { from: poscurrent.y, to: tgpos.y },
          ease: 'Power2',
          duration: usetime, // duration of animation; higher=slower
          loop: 0,
          yoyo: false,
          onComplete: function () {
            //this.plog.log("oncomplete", arguments);
            if (arguments[1][0].scene == undefined) return;
            arguments[1][0].scene.registry.list.shipnavigation.traveldone();
            //this.targets[0].destroy();
          }
        });
      } else {
        this.travellingto = chardata['mapspace']['going'];
        this.traveldone();
        //this.homesprite.setPosition(poscurrent.x, poscurrent.y);
      }
    }
    return;
    this.powerpgbar = new PGBar(this.wh, {
      x: 0,
      y: 0,
      w: width - 40,
      h: 20,
      max: 100,
      me: 0,
      barcol1: 0x6ec2ff,
      barcol2: 0x0394fc,
      txt: '',
    });
    this.powerpgbar.draw();
    this.wh.registry.list.gameitf.genloadedanims(this.wh, 'ixbeff172_b');
    //this.wh.registry.list.gameitf.genloadedanims(this.wh, 'waterripple_b');
    //this.wh.registry.list.gameitf.genloadedanims(this.wh, 'fishbar_a');

    this.bg = wh.add.image(0, 0, 'spinnerbg/');
    wh.shipnavigationcontainer.add(this.bg);
    wh.shipnavigationcontainer.bringToTop(this.bg);
    this.bg.setPosition(width / 2, height / 2);
    this.bg.displayWidth = width;
    this.bg.displayHeight = height;
    this.bg.setInteractive();
    this.bg.removeAllListeners();



    this.bg1 = wh.add.tileSprite(0, 0, width, height, "spinnerbg/l1")
      .setOrigin(0, 0)
      .setScrollFactor(0, 1); //this line keeps your background from scrolling outside of camera bounds
    wh.shipnavigationcontainer.add(this.bg1);
    wh.shipnavigationcontainer.bringToTop(this.bg1);
    this.ll1 = wh.add.tileSprite(0, 0, width, height, "spinnerbg/ll1")
      .setOrigin(0, 0)
      .setScrollFactor(0, 1); //this line keeps your background from scrolling outside of camera bounds
    wh.shipnavigationcontainer.add(this.ll1);
    wh.shipnavigationcontainer.bringToTop(this.ll1);
    this.bg2 = wh.add.tileSprite(0, 0, width, height, "spinnerbg/l2")
      .setOrigin(0, 0)
      .setScrollFactor(0, 1); //this line keeps your background from scrolling outside of camera bounds
    wh.shipnavigationcontainer.add(this.bg2);
    wh.shipnavigationcontainer.bringToTop(this.bg2);
    this.ll2 = wh.add.tileSprite(0, 0, width, height, "spinnerbg/ll2")
      .setOrigin(0, 0)
      .setScrollFactor(0, 1); //this line keeps your background from scrolling outside of camera bounds
    wh.shipnavigationcontainer.add(this.ll2);
    wh.shipnavigationcontainer.bringToTop(this.ll2);
    this.bg3 = wh.add.tileSprite(0, 0, width, height, "spinnerbg/l3")
      .setOrigin(0, 0)
      .setScrollFactor(0, 1); //this line keeps your background from scrolling outside of camera bounds
    wh.shipnavigationcontainer.add(this.bg3);
    wh.shipnavigationcontainer.bringToTop(this.bg3);

    //this.bg1.setPosition(width / 2, height / 2);
    //this.bg2.setPosition(width / 2, height / 2);
    //this.bg3.setPosition(width / 2, height / 2);
    //this.bg4.setPosition(width / 2, height / 2);
    //if (width>height) {
    var scale = height / 270;
    this.plog.log("bg1", scale, this.bg1, this.bg2, this.bg3)
    this.bg1.scale = scale; //this.bg1.scaleX=this.bg1.scaleY;
    this.bg2.scale = scale; //this.bg1.scaleX=this.bg1.scaleY;
    this.bg3.scale = scale; //this.bg1.scaleX=this.bg1.scaleY;
    this.bg3.scale = scale; //this.bg1.scaleX=this.bg1.scaleY;
    this.ll1.scale = scale; //this.bg1.scaleX=this.bg1.scaleY;
    this.ll2.scale = scale; //this.bg1.scaleX=this.bg1.scaleY;

    //this.bg1.x=0;


    wh.shipnavigationcontainer.add(this.txt);
    wh.shipnavigationcontainer.bringToTop(this.txt);
    this.txt.setWordWrapWidth(width - 150)
    this.txt.setPosition((width / 2) - (this.txt.displayWidth / 2), height - this.txt.displayHeight);



    var griddat = [];
    var griddatf = [];

    griddat.push(this.playbtn);
    griddatf.push(this.playbtn);
    griddat.push(this.closebtn);
    this.wh.registry.list.gp.guigrid(griddat, griddatf);

  }

  interpolateCoordinates(start, end, percentage: number) {
    if (percentage < 0 || percentage > 100) {
      throw new Error("Percentage must be between 0 and 100.");
    }

    const t = percentage / 100;

    const interpolatedX = start.x + (end.x - start.x) * t;
    const interpolatedY = start.y + (end.y - start.y) * t;

    return { x: interpolatedX, y: interpolatedY };
  }
  calculateProgressPercentage(start: number, end: number, current: number): number {
    if (end === start) {
      return 100;
    }

    const progress = (current - start) / (end - start) * 100;
    var result = Math.min(Math.max(progress, 0), 100); // Ensure the result is between 0 and 100
    return result;
  }
  async close() {
    this.closing = true;
    this.istravelling = false;
    this.travellingto = "";
    if (this.scrolltimer != undefined) this.scrolltimer.remove();
    if (this.scrolltimer != undefined) this.scrolltimer.remove();
    if (this.wh == undefined) return;
    if (this.wh != undefined && this.wh.shipnavigationcontainer != undefined) {
      for (var i = 0; i <= 10; i++) {
        for (const k in this.wh.shipnavigationcontainer.list) {
          try {
            await this.wh.shipnavigationcontainer.remove(this.wh.shipnavigationcontainer.list[k], true);
          } catch (e) {
            this.plog.log("ERROR!", e);
          }
        }
        for (const k in this.wh.shipnavigationcontainer_gui.list) {
          try {
            await this.wh.shipnavigationcontainer_gui.remove(this.wh.shipnavigationcontainer_gui.list[k], true);
          } catch (e) {
            this.plog.log("ERROR!", e);
          }
        }
        for (const k in this.wh.shipnavigationcontainer_bg.list) {
          try {
            await this.wh.shipnavigationcontainer_bg.remove(this.wh.shipnavigationcontainer_bg.list[k], true);
          } catch (e) {
            this.plog.log("ERROR!", e);
          }
        }
      }
    }
    try {
      this.wh.shipnavigationcontainer.destroy();
    } catch (e) {
      this.plog.log("ERROR!", e);
    }
    this.wh.registry.list.rcvarpass.inputitem =
      await this.wh.registry.list.rchttp.getcommon2('inputitem', {
        mode: 'get',
      });
    this.wh.registry.list.gameitf.inputitem_updatedisplay(this.wh);
    this.wh.shipnavigationcontainer = undefined;
    //this=undefined;
    this.wh.kbfocus = "";
  }

  calculateDistance(x1: number, y1: number, x2: number, y2: number): number {
    const deltaX = x2 - x1;
    const deltaY = y2 - y1;
    return Math.sqrt(deltaX * deltaX + deltaY * deltaY);
  }
  calculateDistancebf(obj1, obj2, distanceBefore: number) {
    this.plog.log("calculateDistancebf", obj1, obj2, distanceBefore);
    // Calculate the difference in positions
    const deltaX = obj2.x - obj1.x;
    const deltaY = obj2.y - obj1.y;

    // Calculate the distance between obj1 and obj2
    const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);

    // Normalize the vector (deltaX, deltaY)
    const directionX = deltaX / distance;
    const directionY = deltaY / distance;

    // Calculate the new target position, 100 pixels before obj2
    const targetX = obj2.x - directionX * distanceBefore;
    const targetY = obj2.y - directionY * distanceBefore;

    return { x: targetX, y: targetY };
  }

  calculateAngleRad(x1: number, y1: number, x2: number, y2: number): number {
    var offset = 0;
    var anglerad = Math.atan2(y2 - y1, x2 - x1);
    var angledeg = (Math.atan2(y2 - y1, x2 - x1) * 180 / Math.PI);
    angledeg += offset;
    return anglerad;
  }
  calculateAngle(x1: number, y1: number, x2: number, y2: number): number {
    var offset = 0;
    var anglerad = Math.atan2(y2 - y1, x2 - x1);
    var angledeg = (Math.atan2(y2 - y1, x2 - x1) * 180 / Math.PI);
    angledeg += offset;
    return angledeg;
    const deltaX = x2 - x1;
    const deltaY = y2 - y1;
    return Math.atan2(deltaY, deltaX);
  }
  scrolltimerfunc() {
    this.bg1.tilePositionX += 0.2;
    //this.ll1.tilePositionX += 0.8;
    //this.bg2.tilePositionX += 1;
    //this.ll2.tilePositionX += 1.2;
    //this.bg3.tilePositionX += 2;
    //Phaser.Actions.RotateAroundDistance(this.homesprite, { x: 100, y: 200 }, 2, 150);

    if (this.istravelling == false) {
      var chardata = this.wh.registry.list.rcvarpass.activechar;
      if (chardata['mapspace'] != undefined) {
        if (chardata['mapspace']['at'].indexOf(",") == -1) {
          //no , - at planet

          this.homesprite.setPosition(this.starsprite[chardata['mapspace']['at']].x, this.starsprite[chardata['mapspace']['at']].y);

          Phaser.Math.RotateAroundDistance(this.homesprite,
            this.starsprite[chardata['mapspace']['at']].x, this.starsprite[chardata['mapspace']['at']].y,
            this.angle1, this.distance1);
          this.angle1 = Phaser.Math.Angle.Wrap(this.angle1 - 0.02);

        } else {
          var posa = chardata['mapspace']['at'].split(",");
          this.homesprite.setPosition(posa[0], posa[1]);

        }
      }

    } else {

    }
    if (this.wh != undefined && this.wh.shipnavigationcontainer != undefined) {

      var width = this.wh.game.canvas.width;
      var height = this.wh.game.canvas.height;

      var ux = 0 - this.homesprite.x;
      var uy = 0 - this.homesprite.y;
      var thezoom = this.zoomlevel[this.zoom];
      if (thezoom == undefined) thezoom = 1;
      ux = ux * thezoom;
      uy = uy * thezoom;
      //this.plog.log("zzzz orig="+this.homesprite.x+"-"+this.homesprite.y,"zoom=",this.zoom,thezoom,"uxy="+ux+","+uy);
      ux = ux + (width / 2);
      uy = uy + (height / 2);

      this.wh.shipnavigationcontainer.setPosition(ux, uy);

      this.bg1.displayWidth = width;
      this.bg1.displayHeight = height;
      /*
      this.bg1.setPosition(
        0 - this.wh.shipnavigationcontainer.x,
        0 - this.wh.shipnavigationcontainer.y);
        */
      this.bg1.setPosition(
        0,
        0);

      /*
     this.bg2.setPosition(
       0 - this.wh.shipnavigationcontainer.x,
       0 - this.wh.shipnavigationcontainer.y)
     this.bg3.setPosition(
       0 - this.wh.shipnavigationcontainer.x,
       0 - this.wh.shipnavigationcontainer.y)*/
      /*
    this.closebtn.setPosition(
      0 - this.wh.shipnavigationcontainer.x + 70,//(width / 2),
      0 - this.wh.shipnavigationcontainer.y + (70));
    this.zoominbtn.setPosition(
      0 - this.wh.shipnavigationcontainer.x + 70 + 70,//(width / 2),
      0 - this.wh.shipnavigationcontainer.y + (70));
    this.zoomoutbtn.setPosition(
      0 - this.wh.shipnavigationcontainer.x + 70 + 70 + 70,//(width / 2),
      0 - this.wh.shipnavigationcontainer.y + (70));
      */

      this.closebtn.setPosition(
        0 + 70,//(width / 2),
        0 + (70));
      this.zoominbtn.setPosition(
        0 + 70 + 70,//(width / 2),
        0 + (70));
      this.zoomoutbtn.setPosition(
        0 + 70 + 70 + 70,//(width / 2),
        0 + (70));
    }
  }
  async traveldone() {
    if (this.travellingto == "") return;
    await this.wh.registry.list.rchttp.getcommon2("mapspacetravel", { at: this.travellingto });
    await this.wh.registry.list.rchttp.updatecharinfo();
    // this.wh.registry.list.gameitf.mapfadeout(this.wh, 1000);
    var chardata = this.wh.registry.list.rcvarpass.activechar;

    if (this.data['star'][chardata["mapspace"]["at"]] != undefined)
      this.distance1 = this.data['star'][chardata["mapspace"]["at"]]["osize"] * 32;
    this.travellingto = "";
    this.istravelling = false;
    var langle = this.calculateAngleRad(

      this.starsprite[chardata["mapspace"]["at"]].x,
      this.starsprite[chardata["mapspace"]["at"]].y,
      this.homesprite.x, this.homesprite.y)
    this.angle1 = langle;
    this.goto(this.wh, chardata["mapspace"]["at"])
  }
  async savetravel(arg) {
    await this.wh.registry.list.rchttp.getcommon2("mapspacetravel", arg);
    await this.wh.registry.list.rchttp.updatecharinfo();
    var chardata = this.wh.registry.list.rcvarpass.activechar;

    if (this.data['star'][chardata["mapspace"]["at"]] != undefined)
      this.distance1 = this.data['star'][chardata["mapspace"]["at"]]["osize"] * 32;

  }
  goto(wh, plnt) {
    if (wh.registry.list.shipnavigation.istravelling == true) return;
    if (wh.registry.list.shipnavigation.travellingto != "") return;

    var chardata = wh.registry.list.rcvarpass.activechar;
    var inputitemdata = wh.registry.list.rcvarpass.inputitem
    //this.plog.log("gotochardata",chardata);
    //this.plog.log("gotoinputitemdb",inputitemdata);
    this.timeperclick = this.timeperclickbase;
    //this.plog.log("gotoinputitemdata", inputitemdata);
    for (var k in inputitemdata) {
      if ((k + "").indexOf("shipfuel") == -1) continue;
      var v = inputitemdata[k];
      if (v.selfactivate == 1) {
        this.timeperclick = this.timeperclick * 0.7;
        var kuse = k + "";
        //kuse=kuse.replace("inputitemnpc-","npc-");
        kuse = kuse.replace("---0", "");
        kuse = kuse.replace("---1", "");
        kuse = kuse.replace("---2", "");
        //await 
        wh.registry.list.rchttp.getcommon2('inputitemactivate', { npcid: kuse, forcespend: "yes" });
      }
    }
    this.timeperclick = Math.floor(this.timeperclick);

    //this.plog.log("gotoinputitemdata timeperclick", this.timeperclick);
    if (this.timeperclick < 1) this.timeperclick = 1;
    var landbtn = wh.registry.list.shipnavigation.landbtn;
    landbtn.visible = false;
    var startg = wh.registry.list.shipnavigation.data['star'][plnt];
    var startgs = wh.registry.list.shipnavigation.starsprite[plnt];
    if (plnt == chardata["mapspace"]["at"]) {
      if (startg["map"] != undefined && startg["map"] != "") {
        landbtn.visible = true;
        landbtn.setPosition(startgs.x, startgs.y - (landbtn.displayWidth / 2));
      }
      return;
    }
    wh.registry.list.shipnavigation.istravelling = true;

    wh.registry.list.shipnavigation.travellingto = plnt;

    var hs = wh.registry.list.shipnavigation.homesprite;
    var dist = wh.registry.list.shipnavigation.calculateDistance(hs.x, hs.y, startg.x, startg.y);
    var tgpos = wh.registry.list.shipnavigation.calculateDistancebf(hs, startgs, startg['osize'] * 32);
    var usetime = dist * wh.registry.list.shipnavigation.timeperclick;

    wh.registry.list.shipnavigation.distance1 = startg['osize'] * 32;

    if (wh.registry.list.shipnavigation.travellingto == "") {
      //start from planet
      wh.registry.list.shipnavigation.savetravel({ going: plnt, goinge: Math.floor(usetime / 1000) });
    } else {
      //start from mid way in the space
      ///wh.registry.list.shipnavigation.savetravel({ at: hs.x + "," + hs.y, going: plnt, goinge: Math.floor(usetime / 1000) });
      wh.registry.list.shipnavigation.savetravel({ going: plnt, goinge: Math.floor(usetime / 1000) });
    }

    this.plog.log("startg/hs", startg, hs);
    this.plog.log("dist/usetime/tgpos", dist, usetime, tgpos);
    wh.registry.list.shipnavigation.hometw = wh.tweens.add({
      targets: hs,
      x: { from: hs.x, to: tgpos.x },
      y: { from: hs.y, to: tgpos.y },
      //ease: 'Power2',
      ease: 'Linear',
      duration: usetime, // duration of animation; higher=slower
      loop: 0,
      yoyo: false,
      onComplete: function () {
        //this.plog.log("oncomplete", arguments);
        if (arguments[1][0].scene == undefined) return;
        arguments[1][0].scene.registry.list.shipnavigation.traveldone();
        //this.targets[0].destroy();
      }
    });
  }
  zoomout() {
    var nextzoom = this.zoom + 1;
    if (this.zoomlevel[nextzoom] == undefined) {
      nextzoom = this.zoomlevel.length - 1;
    }
    this.zoom = nextzoom;
    this.dozoom();
  }
  zoomin() {
    var nextzoom = this.zoom - 1;
    if (nextzoom <= 0) {
      nextzoom = 0;
    }
    this.zoom = nextzoom;
    this.dozoom();
  }
  dozoom() {
    var thezoom = this.zoomlevel[this.zoom];
    this.plog.log("dozoom", this.zoom, "=", thezoom);
    this.wh.shipnavigationcontainer.setScale(thezoom);
  }
}
