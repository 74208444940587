import { Injectable } from '@angular/core';
import { plog } from './plog.service';

@Injectable({
  providedIn: 'root'
})
export class FarmPlotService {
  wh;
  plotid;
  lastfarmplotstatus;
  plog;

  constructor() {
    this.plog = new plog();
    //this.plog.setLevel(4);
  }
  async show(wh, plotid) {
    this.plog.log("FarmPlotService show()", wh, plotid);
    //return;//xxxpeace
    this.wh = wh;
    this.plotid = plotid;


    var svversionurlstring = this.wh.registry.list.rcvarpass.svversionstring;
    if (svversionurlstring != "") {
      svversionurlstring = "?" + svversionurlstring;
    }
    let loader = new Phaser.Loader.LoaderPlugin(this.wh);
    var loadlist = [];
    for (var k in loadlist) {
      var key = loadlist[k];
    }
    loader.once(Phaser.Loader.Events.COMPLETE, () => {
      this.plog.log("Phaser.Loader.Events.COMPLETE");
      this.plog.log(this);
      this.show_loaded();
    });
    loader.start();
  }
  async plantseed(id, idno) {
    //plantseed
    var uid = this.wh.registry.list.farmplot.plotid;
    this.plog.log("farmplot plantseed", id, this);
    var res = await this.wh.registry.list.rchttp.getcommon2("farmplotplant", { stackitemid: id, uid: uid });
    this.plog.log("plantseed res ", res);
    if (res == "ok") {
      //this.show(this.wh, this.plotid);
      await this.wh.registry.list.rchttp.updatecharinfo();
      this.wh.registry.list.farmplot.chkmapdsp(this.wh);
    }
  }
  async show_loaded() {
    var farmres = await this.wh.registry.list.rchttp.getcommon2("farmplotclick", { map: this.wh.registry.list.rcvarpass.activechar["map"], uid: this.plotid });
    //this.plog.log("farmplot farmres", farmres);
    if (farmres["status"] == "empty") {
      //await this.wh.registry.list.rctoast.rcalert(this.wh, "Farm Plot Empty", "This farm plot is empty, plant some seed");

      this.wh.registry.list.phm.voicethis(this.wh, "planting");
      this.wh.registry.list.pickitem.show(this.wh, this.plantseed, "plantfarmplot", "", "Select a seed to plant");
    }

    if (farmres["status"] == "waiting") {
      //await this.wh.registry.list.rctoast.rcalert(this.wh, "Farm Plot Status", farmres["msg"]);
      this.wh.registry.list.phm.charsaybubble(this.wh.char_main, farmres["msg"]);
    }

    if (farmres["status"] == "hatched") {
      this.plog.log("eggres hatched", this.wh.registry);
      //await this.wh.registry.list.showreward.show(this.wh, ["stackitem:" + farmres["obtain"] + ":4"]);
    }
    await this.wh.registry.list.rchttp.updatecharinfo();
    //this.plog.log("EggNestService show", this.wh);
    await this.chkmapdsp(this.wh);
    this.updatedsp(this.wh);
  }
  async chkmapdsp(wh) {

    //this.plog.log("fff FarmPlot chkmapdsp", wh);
    //return;//xxxpeace
    this.wh = wh;
    //alter npc sprite if incubating

    if (this.wh.registry.list.rcvarpass.activechar["map"] != undefined) {
      var res = await wh.registry.list.rchttp.getcommon2("farmplotstatus", { map: this.wh.registry.list.rcvarpass.activechar["map"] });
      this.lastfarmplotstatus = res;
      this.updatedsp(this.wh);
      //this.plog.log("FarmPlot chkmapdsp res", res);
      //this.plog.log("fff FarmPlot chkmapdsp npclist", res);
    }
  }
  updatedsp(wh) {
    this.wh = wh;
    //this.plog.log("farm-plot farmplot.updatedsp();");
    var humantimeuse = wh.registry.list.timectl.humantimeorig + wh.gametimesec;

    for (const k in this.wh.npclist) {
      var v = this.wh.npclist[k];
      var tmpnamea = v.name.split("-");//"backyard-npc-pnnpcfarmplot-26-36-backyard"
      //this.plog.log("fffFOUND1 farmplot_a", v.name);
      if (tmpnamea[1] == "farmplot" || tmpnamea[1] == "farmplot1" || tmpnamea[1] == "pnnpcfarmplot") {
        //v.anims.stop();
        v.play('farmplot_a');
      }
    }
    for (const ki in this.lastfarmplotstatus) {
      var vi = this.lastfarmplotstatus[ki];
      //this.plog.log("fff FarmPlot chkmapdsp res i", ki, vi);

      for (const k in this.wh.npclist) {
        var v = this.wh.npclist[k];
        if (v == undefined) continue;
        var lookingfor = vi["uid"].replace(this.wh.registry.list.rcvarpass.activechar["map"] + "-", "");
        //this.plog.log("FINDFOUNDfff farmPlot chkmapdsp", v.name,lookingfor);
        var tmpnamea = v.name.split("-");

        if (lookingfor == v.name) {
          v.anims.stop();
          var timeleft = vi['dte'] - humantimeuse;//vi["timeleft"];
          var humantimeuse = wh.registry.list.timectl.humantimeorig + wh.gametimesec;
          vi['dts'] = Number(vi['dts']);
          vi['dte'] = Number(vi['dte']);
          var timetotal = vi['dte'] - vi['dts'];
          var timepregress = vi['dte'] - humantimeuse;
          //this.plog.log("FOUNDfff vi", timetotal, timepregress);

          const percent = ((humantimeuse - vi['dts']) / (vi['dte'] - vi['dts'])) * 100;
          //this.plog.log("FOUNDfff",timeleft,v,vi,tmpnamea,lookingfor);
          //this.plog.log("FOUNDfff vi", vi, percent);
          v.play('farmplot_' + vi['stackitem'] + 'b');
          //this.plog.log("FOUND",wh,v,vi,timeleft);
          if (percent > 20) {
            v.anims.stop();
            v.play('farmplot_' + vi['stackitem'] + 'b');
          }
          if (percent > 50) {
            v.anims.stop();
            v.play('farmplot_' + vi['stackitem'] + 'c');
          }
          if (percent > 75) {
            v.anims.stop();
            v.play('farmplot_' + vi['stackitem'] + 'd');
          }
          if (percent > 100) {
            v.anims.stop();
            v.play('farmplot_' + vi['stackitem'] + 'e');
          }
          //v.anims.stop();
          //
        } else {

        }
      };
    }
  }
}
