import { Injectable } from '@angular/core';
import { RCVarsService } from './rcvars-service.service';
import { RCOSDService } from './rcosd.service';
import { RCHTTP } from './rchttp.service';
import { RCRequirementService } from './rcrequirement.service';
import { plog } from './plog.service';

@Injectable({
  providedIn: 'root'
})
export class QuestInfoService {
  shopdragbtn;
  title;
  hint;
  tonpc;
  offerbtn;

  questcompletebtn;
  completedt;
  objt;
  objt2;
  nextstepbtn;
  rewardlist = [];
  qname;
  itemdescr;
  wh;
  shopguibg;
  shopclosebtn;
  questcode;
  rewardt;
  aq;//active quest
  cancelbtn;
  plog;

  constructor(
    public rcvarsservice: RCVarsService,
    public rcosd: RCOSDService,
    public rchttp: RCHTTP,
    public req: RCRequirementService
  ) {
    this.plog = new plog();
  }

  firsttimerun = true;
  firsttimerunfunc() {
    if (this.wh.questinfocontainer != undefined) {
      //if (this.firsttimerun == true) {
      this.wh.registry.list.gameitf.guiwindowclick(this.wh.questinfocontainer);
    }
    this.firsttimerun = false;
  }
  async show(wh, questcode, forcefocus = false) {
    this.plog.log("quest show", wh, questcode, this);
    if (forcefocus == true) this.firsttimerun = true;

    this.wh = wh;

    if (this.wh.questinfocontainer != undefined) {
      await this.show_close(this.wh);
    }
    this.questcode = questcode;
    await this.rchttp.updatecharinfo();
    this.aq = this.rcvarsservice.activechar["quest"];
    //this.show_close();
    /*
    if (wh.questinfocontainer != undefined) {
      wh.questinfocontainer.visible = true;
      this.show_loaded();
      return;
    }*/
    var width = wh.game.canvas.width;
    var height = wh.game.canvas.height;
    this.plog.log("quest show", wh, this);


    const shopguibg = wh.add.image(0, 0, "blankbarguibg");//questguibg
    shopguibg.setOrigin(0.5, 0.5);
    shopguibg.setInteractive({ pixelPerfect: true });
    shopguibg.removeAllListeners();
    this.shopguibg = shopguibg;
    this.plog.log("questguibg var", this.rcvarsservice);

    this.shopclosebtn = wh.add.sprite(300, 300, "thesq", 'close');
    this.shopclosebtn.name = 'skipcntnrsizecal';
    this.shopclosebtn.setOrigin(0.5, 0.5);
    this.shopclosebtn.setPosition(155, -200);
    this.shopclosebtn.displayWidth = 40;
    this.shopclosebtn.displayHeight = 40;
    this.shopclosebtn.setInteractive();
    this.shopclosebtn.removeAllListeners();
    this.shopclosebtn.on('pointerup', function (pointer, localX, localY, event) {
      //this.scene.registry.list["thethis"].phm.floorclick(this);
      this.plog.log("gc.npcbackbtn pointerup", pointer, localX, localY, event, this);
      this.show_close(wh);
    }, this);



    wh.questinfocontainer = wh.add.container(300, 300);
    wh.questinfocontainer.name = "questinfocontainer";
    wh.questinfocontainer.add(this.shopclosebtn);
    wh.questinfocontainer.add(shopguibg);
    wh.questinfocontainer.bringToTop(this.shopclosebtn);
    //Click Item to View Description
    var itemdescr = wh.add.text(0, 0, " ", { fontFamily: 'ffff', fontSize: '12px', fill: '#000', boundsAlignH: "center", boundsAlignV: "middle" });
    wh.questinfocontainer.add(itemdescr);
    itemdescr.setOrigin(0, 0);
    itemdescr.setPosition(-140, 179);
    this.itemdescr = itemdescr;




    //drag


    this.shopdragbtn = wh.add.image(300, 300, 'spacer');
    this.shopdragbtn.setOrigin(0.5, 0.5);
    this.shopdragbtn.setPosition(0, -200);
    this.shopdragbtn.displayWidth = 270;
    this.shopdragbtn.displayHeight = 60;
    this.shopdragbtn.setInteractive();
    this.shopdragbtn.removeAllListeners();
    this.shopdragbtn.on('pointerout', function (pointer, localX, localY, event) {
      this.rcvarsservice.gameobj.scene.scenes[0].questinfocontainer.dragactive = false;
    }, this);
    this.shopdragbtn.on('pointerup', function (pointer, localX, localY, event) {
      this.rcvarsservice.gameobj.scene.scenes[0].questinfocontainer.dragactive = false;
    }, this);
    this.shopdragbtn.on('pointerdown', function (pointer, localX, localY, event) {
      this.rcvarsservice.gameobj.registry.list.gameitf.guiwindowclick(this.rcvarsservice.gameobj.scene.scenes[0].questinfocontainer);

      this.rcvarsservice.gameobj.scene.scenes[0].questinfocontainer.dragactive = true;
      this.rcvarsservice.gameobj.scene.scenes[0].questinfocontainer.dragactivex = this.rcvarsservice.gameobj.scene.scenes[0].questinfocontainer.x;
      this.rcvarsservice.gameobj.scene.scenes[0].questinfocontainer.dragactivey = this.rcvarsservice.gameobj.scene.scenes[0].questinfocontainer.y;
      this.rcvarsservice.gameobj.scene.scenes[0].questinfocontainer.dragactivepx = pointer.x;
      this.rcvarsservice.gameobj.scene.scenes[0].questinfocontainer.dragactivepy = pointer.y;
    }, this);
    this.rcvarsservice.gameobj.scene.scenes[0].questinfocontainer.dragactive = false;
    this.rcvarsservice.gameobj.scene.scenes[0].questinfocontainer.dragactivex = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].questinfocontainer.dragactivey = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].questinfocontainer.dragactivepx = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].questinfocontainer.dragactivepy = 0;
    this.shopdragbtn.on('pointermove', function (pointer, localX, localY, event) {
      //this.scene.registry.list["thethis"].phm.floorclick(this);
      //this.plog.log("shop pointermove", pointer, localX, localY, event, this);
      //this.showinventory_close(wh);
      if (this.rcvarsservice.gameobj.scene.scenes[0].questinfocontainer.dragactive == true) {
        var tmpx = this.rcvarsservice.gameobj.scene.scenes[0].questinfocontainer.dragactivex;
        var tmpy = this.rcvarsservice.gameobj.scene.scenes[0].questinfocontainer.dragactivey;
        var tmppx = this.rcvarsservice.gameobj.scene.scenes[0].questinfocontainer.dragactivepx;
        var tmppy = this.rcvarsservice.gameobj.scene.scenes[0].questinfocontainer.dragactivepy;
        var movedx = tmppx - pointer.x;
        var movedy = tmppy - pointer.y;
        //this.plog.log("drag", tmppx, pointer.x, movedx);

        this.rcvarsservice.gameobj.scene.scenes[0].questinfocontainer.x = tmpx - movedx;
        this.rcvarsservice.gameobj.scene.scenes[0].questinfocontainer.y = tmpy - movedy;
        //this.maskg.setPosition(this.rcvarsservice.gameobj.scene.scenes[0].questinfocontainer.x,this.rcvarsservice.gameobj.scene.scenes[0].questinfocontainer.y)
        //this.rcvarsservice.gameobj.scene.scenes[0].questinfocontainer.y = pointer.y;
      }
    }, this);
    wh.questinfocontainer.add(this.shopdragbtn);
    wh.questinfocontainer.bringToTop(this.shopdragbtn);


    this.title = wh.add.text(0, 0, 'QUEST INFO', wh.fs["winguititle"]);
    this.plog.log("winguititle fs", wh.fs["winguititle"])
    wh.questinfocontainer.add(this.title);
    wh.questinfocontainer.bringToTop(this.title);
    this.title.setPosition(0 - this.title.displayWidth / 2, -223);
    //var equipment = this.rcvarsservice.activechar["equip"];
    //this.plog.log("equipment eq", equipment);
    //this.plog.log("questinfocontainer", wh.questinfocontainer, width, height);
    wh.questinfocontainer.setPosition((width / 2), (height / 2));
    let loader = new Phaser.Loader.LoaderPlugin(wh);

    loader.once(Phaser.Loader.Events.COMPLETE, () => {
      this.plog.log("Phaser.Loader.Events.COMPLETE");
      this.plog.log(this);
      this.show_loaded();
    }, this);
    wh.camman(wh);
    loader.start();
  }
  async show_loaded() {
    //this.rcvarsservice.gameobj.registry.list.gameitf.guiwindowclick(this.rcvarsservice.gameobj.scene.scenes[0].questinfocontainer);
    var qdat = this.wh.registry.list.rcvarpass.allquest[this.questcode];
    this.plog.log("quest show_loaded", qdat);
    this.firsttimerunfunc();


    if (this.completedt != undefined) {
      this.completedt.destroy();
    }
    if (this.offerbtn != undefined) {
      this.offerbtn.destroy();
      this.offerbtn = undefined;
    }
    if (this.cancelbtn != undefined) {
      this.cancelbtn.destroy();
      this.cancelbtn = undefined;
    }

    if (this.qname != undefined) {
      this.qname.destroy();
    }
    if (this.questcompletebtn != undefined) {
      this.questcompletebtn.destroy();
    }
    if (this.objt != undefined) {
      this.objt.destroy();
    }
    if (this.objt2 != undefined) {
      this.objt2.destroy();
    }
    if (this.nextstepbtn != undefined) {
      this.nextstepbtn.destroy();
    }
    if (this.rewardt != undefined) {
      this.rewardt.destroy();
    }
    if (this.tonpc != undefined) {
      this.tonpc.destroy();
    }
    if (this.hint != undefined) {
      this.hint.destroy();
    }
    for (var k in this.rewardlist) {
      this.rewardlist[k].destroy();
    }

    if (qdat == undefined) {
      this.show_close(this.wh);
      return;
    }
    this.qname = this.wh.add.text(0, 0, "" + this.wh.gl(qdat["name"]), this.wh.fs["questinfo_title"]);
    this.qname.setWordWrapWidth(this.shopguibg.displayWidth * 0.8);

    this.wh.questinfocontainer.add(this.qname);
    this.wh.questinfocontainer.bringToTop(this.qname);
    var ypos = -155;
    this.qname.setPosition(0 - (this.qname.displayWidth / 2), ypos - 15);
    ypos += 35;


    var rqtext;
    var rqres;

    this.plog.log("quest show_loaded activechar", this.rcvarsservice.activechar["quest"][this.questcode]);
    //chk completed but stuck in wingui
    if (this.rcvarsservice.activechar["quest"][this.questcode] == undefined) {
      this.show_close(this.wh);
      return;
    }
    if (this.rcvarsservice.activechar["quest"][this.questcode]["step"] == 0) {
      //quest completed

      this.completedt = this.wh.add.text(0, 0, this.wh.trs("f:questinfocontainer:Quest Completed===Quest Completed"), this.wh.fs["winguibardescr"]);
      this.wh.questinfocontainer.add(this.completedt);
      this.wh.questinfocontainer.bringToTop(this.completedt);
      this.completedt.setPosition(-120, ypos);
      this.completedt.visible = false;
      this.plog.log("qqqq", this.wh, this.rcvarsservice.activechar["quest"][this.questcode]);
      ypos += 55;
      this.questcompletebtn = this.wh.add.sprite(0, 0, "thesb", "complete");//this.wh.add.image(0, 0, "questcompletebtn");
      this.wh.questinfocontainer.add(this.questcompletebtn);
      this.wh.questinfocontainer.bringToTop(this.questcompletebtn);
      this.questcompletebtn.displayWidth = 120;
      this.questcompletebtn.scaleY = this.questcompletebtn.scaleX;
      this.questcompletebtn.setPosition(0 - (this.questcompletebtn.displayWidth / 2), ypos);
      this.questcompletebtn.setInteractive();
      this.questcompletebtn.removeAllListeners();
      this.questcompletebtn.visible = false;

      this.questcompletebtn.name = this.questcode;
      this.questcompletebtn.on("pointerup", async function () {
        //this.plog.log("this", this);
        var tmpdata = await this.texture.manager.game.scene.scenes[0].registry.list.rchttp.getcommon2("removequest", { questid: this.name });
        await this.texture.manager.game.scene.scenes[0].registry.list.gameitf.charinfoupdated(this.texture.manager.game.scene.scenes[0]);
        this.texture.manager.game.scene.scenes[0].registry.list.questinfo.show_close(this.texture.manager.game.scene.scenes[0]);
      });
      this.questcompletebtn.visible = true;
      this.completedt.visible = true;

    } else {
      //quest not completed
      this.objt = this.wh.add.text(0, 0, this.wh.gl("Next Objective"), this.wh.fs["winguibardescr"]);
      this.wh.questinfocontainer.add(this.objt);
      this.wh.questinfocontainer.bringToTop(this.objt);
      this.objt.setPosition(-120, ypos);
      //this.objt.visible = false;
      this.objt2 = this.wh.add.text(0, 0, "", this.wh.fs["questinfo_obj"]);

      this.wh.questinfocontainer.add(this.objt2);
      this.wh.questinfocontainer.bringToTop(this.objt2);
      this.objt2.setPosition(-120, ypos);
      var tmpchkreqchk;
      if (this.rcvarsservice.activechar["quest"][this.questcode]["thestep"]["tasktype"] == "gather") {
        //gather
        this.plog.log("qqqq gather", this.rcvarsservice.activechar["quest"][this.questcode]);
        rqtext = this.req.chk(this.rcvarsservice.activechar["quest"][this.questcode]["thestep"]["requirements"], "text");
        rqres = this.req.chk(this.rcvarsservice.activechar["quest"][this.questcode]["thestep"]["requirements"]);
        tmpchkreqchk = rqres;
      } else {
        this.plog.log("qqqq NOTgather", this.rcvarsservice.activechar["quest"][this.questcode]["thestep"]["conver_npc"]);

        rqtext = this.wh.gl("Talk to: ") + this.rcvarsservice.npc[this.rcvarsservice.activechar["quest"][this.questcode]["thestep"]["conver_npc"]]["name"];
        var tmpchkreq = this.req.chk(this.rcvarsservice.activechar["quest"][this.questcode]["thestep"]["requirements"], "text");
        tmpchkreqchk = this.req.chk(this.rcvarsservice.activechar["quest"][this.questcode]["thestep"]["requirements"]);

        rqtext = rqtext + "\n";
        if (tmpchkreq != undefined && tmpchkreq != "") {
          rqtext = rqtext + this.wh.gl("With: ") + "\n" + tmpchkreq;
        }
        var npcnametolookfor = "";
        for (const k in this.wh.npclist) {
          var lname = this.wh.npclist[k].name;
          var lnamea = lname.split("-");
          if (lnamea[1] == this.rcvarsservice.activechar["quest"][this.questcode]["thestep"]["conver_npc"]) {
            npcnametolookfor = lname;
          }
        }
        if (npcnametolookfor != "") { //target npc in this map
          this.tonpc = this.wh.add.sprite(-140, ypos + 48, "thesq", "targetgreen");
          this.tonpc.displayWidth = this.tonpc.displayHeight = 48;
          this.wh.questinfocontainer.add(this.tonpc);
          this.wh.questinfocontainer.bringToTop(this.tonpc);
          this.tonpc.setInteractive();
          this.tonpc.removeAllListeners();
          this.tonpc.on("pointerup", async function (input, x, y, event) {
            //console.log(arguments,event);

            //event.preventDefault();
            event.stopPropagation();
            //this.plog.log("objnpc", this.wh.npclist, this.rcvarsservice.activechar["quest"][this.questcode]["thestep"]["conver_npc"]);
            var npcnametolookfor = "";
            for (const k in this.wh.npclist) {
              var lname = this.wh.npclist[k].name;
              var lnamea = lname.split("-");
              if (lnamea[1] == this.rcvarsservice.activechar["quest"][this.questcode]["thestep"]["conver_npc"]) {
                npcnametolookfor = lname;
              }
            }
            if (npcnametolookfor == "") return;
            await this.wh.registry.list.rctoast.sleep(300);
            var npcsprite = this.wh.registry.list.phm.findnpc(this.wh, npcnametolookfor);
            this.wh.registry.list.phm.charinteractnpc(this.wh, npcsprite, 'spellcast');
            this.show_close(this.wh);
            if (
              this.wh.questlistcontainer !=
              undefined
            ) {
              //console.log(this.rcvarsservice.gameobj.scene.scenes[0],this.rcvarsservice.gameobj.scene.scenes[0].questlistcontainer);
              this.wh.registry.list.gameitf.closegui(this.rcvarsservice.gameobj.scene.scenes[0], this.wh.questlistcontainer);
              this.wh.registry.list.questlist.show_close(this.wh);
            }
          }, this);
        } else { //target npc NOT in this map
          this.tonpc = this.wh.add.sprite(130, ypos + 45, "thesq", "targetgreen");
          this.tonpc.displayWidth = this.tonpc.displayHeight = 32
          this.wh.questinfocontainer.add(this.tonpc);
          this.wh.questinfocontainer.bringToTop(this.tonpc);
          this.tonpc.setInteractive();
          this.tonpc.removeAllListeners();
          this.tonpc.on("pointerup", async function () {
            this.plog.log("objnpc", this.wh.npclist, this.rcvarsservice.activechar["quest"][this.questcode]["thestep"]["conver_npc"]);
            var telp1 = this.wh.registry.list.rcreq.chk({ stackitem1: "teleportkey2", stackitem1_min: 1 });
            var telp2 = this.wh.registry.list.rcreq.chk({ stackitem1: "teleportkey3", stackitem1_min: 1 });
            var npclocx = await this.wh.registry.list.rchttp.getcommon2("npclocation", { npcid: this.rcvarsservice.activechar["quest"][this.questcode]["thestep"]["conver_npc"] });
            var npcloca = npclocx.split(":::::");
            var npcloc = npcloca[1];
            var npcloccode = npcloca[0];
            this.plog.log("npclocationchk", telp1, telp2, npcloc);
            if ((telp1 == true || telp2 == true) && npcloccode != "" && npcloccode != undefined) {
              var cfm = await this.wh.registry.list.rctoast.rcconfirm(this.wh,
                this.wh.trs("f:questinfocontainer:NPC Found===NPC Found"),
                "" + npcloc + "\n\n" + this.wh.trs("f:questinfocontainer:Use TeleportKey 2/3 to go there now?===Use TeleportKey 2/3 to go there now?"));
              if (telp1 == true && cfm == "ok") {
                var res = await this.rchttp.getcommon2("consumestackitem", { usecode: 'teleportkey2' })
                if (res == 'ok') {
                  this.wh.chkwarppos_warp(this.wh, "" + npcloccode);
                }
                if (
                  this.wh.questlistcontainer !=
                  undefined
                ) {
                  //console.log(this.rcvarsservice.gameobj.scene.scenes[0],this.rcvarsservice.gameobj.scene.scenes[0].questlistcontainer);
                  this.wh.registry.list.gameitf.closegui(this.rcvarsservice.gameobj.scene.scenes[0], this.wh.questlistcontainer);
                  this.wh.registry.list.questlist.show_close(this.wh);

                }
                await this.wh.registry.list.rchttp.updatecharinfo();
                return;
              }

              if (telp2 == true && cfm == "ok") {
                var res = await this.rchttp.getcommon2("consumestackitem", { usecode: 'teleportkey3' })
                if (res == 'ok') {
                  this.wh.chkwarppos_warp(this.wh, "" + npcloccode);
                }
                if (
                  this.wh.questlistcontainer !=
                  undefined
                ) {
                  //console.log(this.rcvarsservice.gameobj.scene.scenes[0],this.rcvarsservice.gameobj.scene.scenes[0].questlistcontainer);
                  this.wh.registry.list.gameitf.closegui(this.rcvarsservice.gameobj.scene.scenes[0], this.wh.questlistcontainer);
                  this.wh.registry.list.questlist.show_close(this.wh);

                }
                await this.wh.registry.list.rchttp.updatecharinfo();
                return;
              }
            } else {
              this.wh.registry.list.rctoast.rcalert(this.wh, this.wh.trs("f:questinfocontainer:NPC Information===NPC Information"), "" + npcloc);
            }
          }, this);
        }
      }
      this.plog.log("rqtext", rqres, rqtext);
      ypos += 30;
      this.wh.questinfocontainer.add(this.objt2);
      this.wh.questinfocontainer.bringToTop(this.objt2);
      this.objt2.setPosition(-120, ypos);


      if (rqres == true) { // fullfilled "gather" items
        this.objt2.setText(this.wh.gl(rqtext));
        this.objt2.setWordWrapWidth(this.shopguibg.displayWidth * 0.7);
        ypos += 50;

        this.nextstepbtn = this.wh.add.sprite(0, 0, "thesb", "nextstep");
        this.wh.questinfocontainer.add(this.nextstepbtn);
        this.wh.questinfocontainer.bringToTop(this.nextstepbtn);
        this.nextstepbtn.displayWidth = 120;
        this.nextstepbtn.scaleY = this.nextstepbtn.scaleX;
        this.nextstepbtn.setPosition(0 - (this.nextstepbtn.displayWidth / 2), ypos);
        this.nextstepbtn.setInteractive();
        this.nextstepbtn.removeAllListeners();
        var tmpqstepid = this.rcvarsservice.activechar["quest"][this.questcode]["step"];
        this.nextstepbtn.name = this.questcode + ":" + tmpqstepid;
        this.nextstepbtn.on("pointerup", async function () {
          //this.plog.log("this", this);
          var tmpnamea = this.name.split(":");
          //this.plog.log("tmpnamea", tmpnamea);
          var tmpdata = await this.texture.manager.game.scene.scenes[0].registry.list.rchttp.getcommon2("questnextstep", { questid: tmpnamea[0], stepid: tmpnamea[1] });
          await this.texture.manager.game.scene.scenes[0].registry.list.rcvarpass.setactivechar(tmpdata, this.texture.manager.game.scene.scenes[0].registry.list.rcvarpass.activechar["id"]);
          this.texture.manager.game.scene.scenes[0].registry.list.gameitf.charinfoupdated(this.texture.manager.game.scene.scenes[0]);

          //this.texture.manager.game.scene.scenes[0].registry.list.questinfo.show_close();
          //await this.texture.manager.game.scene.scenes[0].registry.list.rctoast.sleep(550);
          this.texture.manager.game.scene.scenes[0].registry.list.questinfo.show(this.texture.manager.game.scene.scenes[0], this.texture.manager.game.scene.scenes[0].registry.list.questinfo.questcode, true);
        });
      } else {
        this.objt2.setText(this.wh.gl(rqtext));
        this.objt2.setWordWrapWidth(this.shopguibg.displayWidth * 0.65);


      }
      this.plog.log("tmpchkreqchk=", tmpchkreqchk);

      if (tmpchkreqchk == false) {
        //offers
        var offers = Number(this.rcvarsservice.activechar["quest"][this.questcode]["thestep"]["offercost"]);
        this.plog.log("tmpchkreqchk=false", offers);
        if (offers == undefined) offers = 0;
        if (offers != 0) {
          this.offerbtn = this.wh.add.sprite(0, 0, "theb", "usediamonds");
          this.offerbtn.setOrigin(0.5, 0.5);
          this.wh.questinfocontainer.add(this.offerbtn);
          this.wh.questinfocontainer.bringToTop(this.offerbtn);
          this.offerbtn.setPosition(-120 + (this.offerbtn.displayWidth / 2), ypos + this.objt2.displayHeight + 5 + (this.offerbtn.displayHeight / 2));
          //this.offerbtn.displayWidth = 500;
          //this.offerbtn.displayHeight = 500;
          ypos += this.offerbtn.displayHeight;
          ypos += 5;
          this.offerbtn.setInteractive();
          this.offerbtn.removeAllListeners();
          this.plog.log("this.offerbtn", this.offerbtn);
          this.offerbtn.on("pointerup", async function () {
            var qinfo = await this.wh.registry.list.rchttp.getcommon2("questusediamonds", { mode: "info", questcode: this.questcode });
            this.plog.log("offerbtn qinfo", qinfo);
            if (qinfo["text"] == undefined) return;
            var cfm = await this.wh.registry.list.rctoast.rcconfirm(this.wh, this.wh.gl("Confirm"), qinfo["text"]);
            this.plog.log("offerbtn cfm", cfm);
            if (cfm == "ok") {
              await this.wh.registry.list.rchttp.getcommon2("questusediamonds", { mode: "spend", questcode: this.questcode });
              await this.rchttp.updatecharinfo();
            }
          }, this);
        }
      }
    }
    if (this.objt2 != undefined)
      ypos += this.objt2.displayHeight; //50;
    ypos += 5;

    if (this.rcvarsservice.activechar["quest"][this.questcode]["thestep"]["hint"] != "") {
      this.hint = this.wh.add.text(0, 0, this.wh.gl(this.rcvarsservice.activechar["quest"][this.questcode]["thestep"]["hint"]), this.wh.fs["questinfo_hint"]);
      this.hint.setAlpha(0.85);
      this.wh.questinfocontainer.add(this.hint);
      this.wh.questinfocontainer.bringToTop(this.hint);
      this.hint.setWordWrapWidth(this.shopguibg.displayWidth * 0.7);
      this.hint.setPosition(-120, ypos);
      ypos += this.hint.displayHeight;
      ypos += 10;

    }



    this.rewardt = this.wh.add.text(0, 0, this.wh.trs("f:questinfocontainer:Quest Rewards===Quest Rewards"), this.wh.fs["winguibardescr"]);
    this.wh.questinfocontainer.add(this.rewardt);
    this.wh.questinfocontainer.bringToTop(this.rewardt);
    this.rewardt.setPosition(-120, ypos);
    if (this.rcvarsservice.activechar["quest"][this.questcode]["step"] == 0) {
      this.rewardt.setText(this.wh.gl(this.wh.trs("f:questinfocontainer:Quest Rewards===Quest Rewards") + " \n   (" + this.wh.trs("f:questinfocontainer:added to your inventory===added to your inventory") + ")\n "));
      ypos += 25;
    }
    ypos += 30;
    var dspi = 0;
    for (var k in qdat["reward"]) {
      var v = qdat["reward"][k];
      var va = v.split(":");
      var dsprew = "";
      if (va[0] == "xp") {
        dsprew = "XP : " + va[1];
      }
      if (va[0] == "ki") {
        dsprew = "KI : " + va[1];
      }
      if (va[0] == "item") {
        dsprew = "Item : " + this.wh.registry.list.rcvarpass.allitem[va[1]]["name"] + " (lv" + this.wh.registry.list.rcvarpass.allitem[va[1]]["level"] + ")";
      }
      if (va[0] == "stackitem") {
        dsprew = "" + this.wh.registry.list.rcvarpass.allstackitem[va[1]]["name"] + " x" + va[2];
      }
      this.rewardlist[k] = this.wh.add.text(0, 0, "" + this.wh.gl(dsprew), this.wh.fs["questinfo_txt"]);
      this.wh.questinfocontainer.add(this.rewardlist[k]);
      this.wh.questinfocontainer.bringToTop(this.rewardlist[k]);
      this.rewardlist[k].setPosition(-110, ypos + (dspi * 20));
      dspi++;

      this.plog.log("dspii", ypos, dspi, (dspi * 20));
    }
    this.plog.log("this.rewardlist", this.rewardlist, this.rewardt);

    ypos = ypos + (dspi * 20);





    this.cancelbtn = this.wh.add.sprite(0, 0, "theb", "abandonquest");
    this.cancelbtn.setOrigin(0.5, 0.5);
    this.wh.questinfocontainer.add(this.cancelbtn);
    this.wh.questinfocontainer.bringToTop(this.cancelbtn);
    this.cancelbtn.setPosition(-120 + (this.cancelbtn.displayWidth / 2), 170 + (this.cancelbtn.displayHeight / 2));
    this.cancelbtn.removeAllListeners();
    this.cancelbtn.setInteractive();
    this.cancelbtn.on('pointerup', async function (pointer, localX, localY, event) {
      //this.plog.log("gc.npcbackbtn pointerup", pointer, localX, localY, event, this);

      var cfm = await this.wh.registry.list.rctoast.rcconfirm(this.wh,
        this.wh.trs("f:questinfocontainer:Confirm Abandon Quest===Confirm Abandon Quest"),
        this.wh.trs("f:questinfocontainer:Cancel this quest and lose all progress===Cancel this quest and lose all progress")
      );
      if (cfm == "ok") {
        this.wh.registry.list.rcloading.loading2(this.wh);
        await this.wh.registry.list.rchttp.getcommon2('cancelquest', { questid: this.questcode });
        await this.wh.registry.list.rchttp.updatecharinfo();
        this.wh.registry.list.rcloading.close();
        this.show_close(this.wh);
      }
    }, this);


    var griddat = [];
    var griddatf = [];
    griddat.push(this.shopclosebtn);
    griddat.push(this.cancelbtn);


    griddat.push(this.nextstepbtn);
    griddat.push(this.questcompletebtn);
    griddat.push(this.tonpc);
    griddatf.push(this.tonpc);
    griddatf.push(this.nextstepbtn);
    griddatf.push(this.offerbtn);
    griddat.push(this.offerbtn);
    this.wh.registry.list.gp.guigrid(griddat, griddatf);

  }
  async show_close(wh) {

    wh.questinfocontainer.visible = false;
    await wh.registry.list.gameitf.closegui(wh, wh.questinfocontainer);

    for (const k in this.wh.questinfocontainer.list) {
      try {
        await this.wh.questinfocontainer.remove(this.wh.questinfocontainer.list[k], true);
        //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list[k].destroy();
      } catch (e) {
        this.plog.log("ERROR! questinfocontainer.destroy() ", e);
      }
    }
    try {
      await this.wh.questinfocontainer.destroy(true);
    } catch (e) {
      this.plog.log("ERROR! questinfocontainer.destroy() ", e);
    }
    wh.questinfocontainer = undefined;
  }
}
