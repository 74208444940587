import { RCVarsService } from "../rcvars-service.service";
import { Injectable } from '@angular/core';
import { Component } from '@angular/core';
//import { Scene } from "phaser";
import { GAService } from "../ga.service";
//import { SplashScreen } from '@capacitor/splash-screen';
//import { Device } from '@capacitor/device';
import { Capacitor } from "@capacitor/core";
import { plog } from "../plog.service";

@Injectable({
    providedIn: 'root'
})

@Component({
    providers: [RCVarsService]
})

export class Welcome extends Phaser.Scene {
    walkers = {};
    platform = "";
    currentimage = 1;
    currentimagemax = 12;
    cliversionstring = "2.59.44";
    firsttimerun = true
    splashlogo;
    splashbg;
    testermode = false;
    appversiont;
    appversiontcount = 0;
    selectsv_currentpage = 0;
    selectsv_maxpage = 0;
    selectsv_perpage = 2;
    settingcontainer;
    settingheader;
    setting_antih;
    setting_msg;
    setting_anti;
    setting_langh;
    setting_lang;
    setting_langlist = ["EN", "FR", "DE", "ES", "PT", "IT", "JA", "KO", "TH", "VI", "ZH", "ZHTW"];
    setting_lango = [];
    setting_lighteffectsh;
    setting_lighteffects;
    setting_weathereffectsh;
    setting_weathereffects;
    setting_soundh;
    setting_sounddsp;
    setting_soundplus;
    setting_soundminus;
    setting_back;
    setting_bgmh;
    setting_bgm;
    setting_autoplayh;
    setting_autoplay;

    setting_mincombath;
    setting_mincombat;
    setting_badinth;
    setting_badint;

    isgameplayed = "no";

    astring;

    offlinecontainer;
    offline_retry;
    offline_bg;
    offline_msg;
    offline_msg2;
    offline_header;

    authserv;
    isloaded;

    profile_avartar = "";
    is_loggedin = false;
    lastpageshown = "";

    logoutbtn;
    aboutbtn;
    settingbtn;
    bugreportbtn;
    curlangbtn;

    sociallinkbtn;
    sociallinkback;
    sociallinkbg;
    sociallinkfb;
    sociallinkgg;
    sociallinktw;
    sociallink_name = [];
    sociallink_unlink = [];
    sociallinkcontainer;

    createchar_selectedrace = 1;
    createchar_selectedgender = "M";
    createchar_header;
    createcharcontainer;
    createchart_back;
    createchart_create;
    createchart_race;
    createchart_race1;
    createchart_race2;
    createchart_race3;
    createchart_race4;
    createchart_race5;
    createchart_racename;
    createchart_racedescr;
    createchart_gender;
    createchart_genderm;
    createchart_genderf;
    createchart_charname;
    createchart_name;
    createchart_preview;
    createcharwhite1;
    createcharwhite2;
    createcharwhite3;

    racebgid = [];

    login_default_server;
    login_default_email;
    reg_selected_server;
    reg_header;

    selectchar_count = 0;
    selectchar_header;
    selectchar_pageno;
    selectchar_charslot;
    selectchar_cname = [];
    selectchar_cicon = [];
    selectchar_cicons = [];
    selectchar_ciconsbg = [];
    selectchar_detail1 = [];
    selectchar_detail2 = [];
    selectchar_detail = [];
    selectchar_playbtn = [];
    selectchar_delbtn = [];
    selectchar_back;
    selectchar_fwd;
    selectchar_msg;
    selectchar_create;



    resetpassword_gamerid;
    forgotb_back;
    forgotb_reg;
    forgot_sv;
    forgot_header;
    forgot_svdetail;
    forgotwhite1;
    forgotwhite2;
    forgotwhite3;
    forgott_u;
    forgot_u;
    forgott_p;
    forgot_p;
    forgott_sv;
    forgott_svdetail;
    forgotb_sendcode;
    forgot_np;
    forgott_np;


    regcontainer;
    regt_sv;
    regt_svdetail;
    reg_sv;
    reg_u;
    regt_u;
    reg_p;
    regt_p;
    regb_back;
    regb_reg;



    tweentree1;
    tweentree2;


    svlist_back;
    svlist_next;
    svlistbg;
    svlist = [];
    svlistdescr = [];
    svlistbtn = [];
    loginitfbg;
    itftopbg;
    itftopbg2;
    //itftreebg;
    //itftreebg2;
    //itfbgfg;
    itflogo;
    itf_bg1;//tilesprite
    itf_bg2;//tilesprite
    thecanvas;
    logincontainer;
    selectcharcontainer;
    forgotcontainer;
    logint_u;
    login_u;
    logint_p;
    login_p;
    logint_sv;
    logint_svdetail;
    login_sv;

    loginb_reg;
    loginb_login;
    loginb_forgot;
    loginb_guest;
    loginb_fb;
    loginb_gg;
    loginb_tw;
    loginb_listbackbtn;
    loginb_listfwdbtn;
    white1;
    white2;
    white3;
    regwhite1;
    regwhite2;
    regwhite3;

    wh;

    fs = {
        charname: {
            fontFamily: 'ffff,uuuu',
            fontStyle: 'bold',
            fontSize: '16px',
            color: '#000077',
            stroke: '#FFFFFF',
            strokeThickness: 2,
            resolution: 12,
            //shadow: { color: '#000000', fill: true, blur: 12, stroke: true },
        },
        pageheader: {
            fontFamily: 'ffff,uuuu',
            fontStyle: 'bold',
            fontSize: '30px',
            color: '#F64900',
            stroke: '#FFFFFF',
            strokeThickness: 4,
            resolution: 4,

        },
        loginformtext: {
            fontFamily: 'ffff,uuuu',
            //fontStyle: 'bold',
            fontSize: '16px',
            color: '#F64900',
            stroke: '#FFFFFF',
            strokeThickness: 5,
            shadow: { color: '#000000', fill: true, blur: 4, stroke: true },
            resolution: 4,
            align: 'center'
        },
        settinghead: {
            fontFamily: 'ffff,uuuu',
            fontStyle: 'bold',
            fontSize: '16px',
            color: '#666666',
            stroke: '#FFFFFF',
            strokeThickness: 5,
            shadow: { color: '#000000', fill: true, blur: 4, stroke: true },
            resolution: 4
        },
        chardetail: {
            fontFamily: 'ffff,uuuu',
            fontStyle: 'normal',
            fontSize: '1.2em',
            color: '#3F3F3F',
            stroke: '#FFFFFF',
            strokeThickness: 4,
            shadow: { color: '#616161', fill: true, blur: 4, stroke: true, offsetX: 2, offsetY: 2 },
            resolution: 8
        },
        chardetail2: {
            fontFamily: 'ffff,uuuu',
            fontStyle: 'normal',
            fontSize: '1em',
            color: '#3F3F3F',
            stroke: '#FFFFFF',
            strokeThickness: 2,
            shadow: { color: '#616161', fill: true, blur: 4, stroke: true, offsetX: 2, offsetY: 2 },
            resolution: 8
        },
        racename: {
            fontFamily: 'ffff,uuuu',
            fontStyle: 'normal',
            fontSize: '14px',
            color: '#222222',
            stroke: '#FFFFFF',
            strokeThickness: 2,
            shadow: { color: '#616161', fill: true, blur: 4, stroke: true, offsetX: 2, offsetY: 2 },
            resolution: 8
        },
        racename2: {
            fontFamily: 'ffff,uuuu',
            fontStyle: 'normal',
            fontSize: '10px',
            color: '#222222',
            stroke: '#FFFFFF',
            strokeThickness: 2,
            shadow: { color: '#616161', fill: true, blur: 4, stroke: true, offsetX: 2, offsetY: 2 },
            resolution: 8
        },
        loginformtextdetail: {
            fontFamily: 'ffff,uuuu',
            fontStyle: 'normal',
            fontSize: '8px',
            color: '#3F3F3F',
            stroke: '#FFFFFF',
            strokeThickness: 4,
            shadow: { color: '#616161', fill: true, blur: 4, stroke: true, offsetX: 2, offsetY: 2 },
            resolution: 8
        },
        selectsvbtn: {
            fontFamily: 'ffff,uuuu',
            fontSize: '12px',
            color: '#853E08',
            strokeThickness: 1,
            shadow: { color: '#616161', fill: true, blur: 4, stroke: true, offsetX: 2, offsetY: 2 },
            resolution: 4
        },
        selectsvdescr: {
            fontFamily: 'ffff,uuuu',
            fontSize: '8px',
            color: '#853E08',
            strokeThickness: 1,
            shadow: { color: '#616161', fill: true, blur: 4, stroke: true, offsetX: 2, offsetY: 2 },
            resolution: 4
        },

        selectsv: {
            fontFamily: 'ffff,uuuu',
            fontSize: '10px',
            color: '#FF5600',
            backgroundColor: '#ffffff',
            stroke: '#FFDF92',
            strokeThickness: 5,
            shadow: { color: '#000000', fill: true, blur: 4, stroke: true },
            resolution: 4
        },
        iap_itemtitle: {
            fontFamily: 'ffff,uuuu',
            fontStyle: 'bold',
            fontSize: '8px',
            color: '#0552AD',
            stroke: '#7ECDBF',
            strokeThickness: 2,
            shadow: { color: '#FFFFFF', fill: true, blur: 11, stroke: true },
            resolution: 4
        },

        rcalert_text: {
            fontFamily: 'ffff,uuuu',
            fontSize: '16px',
            color: '#000000',
            strokeThickness: 1,
            shadow: { color: '#000000', fill: true, stroke: true, blur: 0 },
            resolution: 4
        },
        rcalert_head: {
            fontFamily: 'ffff,uuuu',
            backgroundColor: '#F5A6238C',
            align: 'center',
            fontSize: '20px',
            color: '#000000',
            stroke: '#FFFFFF',
            strokeThickness: 3,
            shadow: { color: '#000000', fill: true, stroke: true, blur: 4 },
            resolution: 4
        },
    }
    plog;
    //intl;
    loadingText;
    progressBox;
    progressBar;
    percentText;
    welcomehead;
    welcomehead2;

    constructor(
        public rcvarsservice: RCVarsService,
        public ga: GAService
    ) {
        super('Welcome');
        //super({});
        //this.intl = new intl();
        this.plog = new plog();
        this.plog.setLevel(1);
        //this.plog.log("Welcome constructor", this); 
    }
    preload() {
        var width = this.cameras.main.width;
        var height = this.cameras.main.height;
        this.progressBox = this.add.graphics();
        this.progressBar = this.add.graphics();
        this.progressBox.fillStyle(0x222222, 1.0);
        var boxw = 240;
        var boxh = 40
        ////here
        this.progressBox.fillRect(width / 2 - (boxw + 20) / 2, height / 2 - (boxh + 20) / 2, boxw + 20, boxh + 20);

        //this.progressBox.preFX.addBloom(0xffffff, 1, 1, 2, 2);

        this.progressBox.postFX.addGlow(0x222222, 6, 0, false, 0.2, 10);
        //this.progressBar.postFX.addGlow(0xffffff, 4, 0, false, 0.2, 10);

        var loadingText = this.make.text({
            x: width / 2,
            y: height / 2 - 50,
            text: 'LOADING',
            style: {
                font: '20px monospace',
                color: '#ffffff'
            }
        });
        loadingText.setOrigin(0.5, 0.5);

        this.loadingText = loadingText;

        this.percentText = this.add.text(0, 0, "", {
            fontFamily: 'monospace',
            fontSize: '18px',
            stroke: '#ffffff',
            strokeThickness: 0,
            color: '#000000'

        });
        this.percentText.setOrigin(0.5, 0.5);
        //this.percentText.setDepth(500);
        let loader = new Phaser.Loader.LoaderPlugin(this);

        loader.on('progress', async function (value) {
            //console.log(this);
            //console.log(arguments);
            //
            await this.registry.list.rctoast.sleep(500);
            var width = this.cameras.main.width;
            var height = this.cameras.main.height;
            this.loadingText.setPosition(width / 2, height / 2 - 50);
            this.percentText.setPosition(width / 2, height / 2);
            try {
                this.percentText.setText(Math.floor(value * 100) + '%');
            } catch (e) {
                //console.log(e);
            }
            //loadingText.setPosition(width / 2, height / 2 - 50)
            this.progressBox.clear();
            this.progressBox.fillStyle(0x222222, 1.0);
            this.progressBox.fillRect(width / 2 - (boxw + 20) / 2, height / 2 - (boxh + 20) / 2, boxw + 20, boxh + 20);
            this.progressBar.clear();
            this.progressBar.fillStyle(0xffffff, 1);
            this.progressBar.fillRect(width / 2 - boxw / 2, height / 2 - boxh / 2, boxw * value, boxh);

            //this.progressBox.moveTo(width / 2 - (boxw + 20) / 2, height / 2 - (boxh + 20) / 2);
            //console.log(this,value);
            this.resized();
        }, this);

        loader.on('fileprogress', function (file) {
            
        });
        loader.on('complete', function () {
            //return;
            //console.log(arguments,this)
        });
        //this.plog.log("Preloader preload");

        var WebFontConfig = {
            custom: {
                families: ['aaaa', 'ffff', 'uuuu'],
                urls: ['assets/font/AgreloycAlmond.css']
            }
        };
        var tmp;
        tmp = this.plugins.get('rexWebFontLoader');//.addToScene(this);
        //this.plugins.Mana.addToScene(tmp);
        //this.plog.log("rex", tmp);
        //this.plugins.
        //tmp.pluginManager.addToScene(this,tmp);
        var ll;
        ll = this.load;
        ll.rexWebFont(WebFontConfig);

        loader.atlas('theb', 'assets/theb.png', 'assets/theb.json');
        loader.atlas('thebs', 'assets/thebs.png', 'assets/thebs.json');
        loader.atlas('thesb', 'assets/thesb.png', 'assets/thesb.json');
        loader.atlas('thesq', 'assets/thesq.png', 'assets/thesq.json');
        loader.atlas('thebb', 'assets/thebb.png', 'assets/thebb.json');
        loader.atlas('loadingcat', 'assets/loadingcat.png', 'assets/loadingcat.json');
        loader.atlas('thecolor', 'assets/thecolor.png', 'assets/thecolor.json');

        loader.atlas('walker1', 'assets/character-sprite/3-female-none.png', 'assets/character-sprite/3-female-none.json');
        loader.atlas('walker2', 'assets/character-sprite/4-male-none.png', 'assets/character-sprite/4-male-none.json');


        loader.start();
        //this.plog.log("Welcome preload", this);
        for (var bgi = 1; bgi <= 12; bgi++) {
            loader.image('loadingbg' + bgi, 'assets/loadingbg/' + bgi + '.png');

        }
        loader.image('welcomehead', 'assets/welcomehead.png');
        loader.image('justcircle', 'assets/justcircle.png');
        loader.image('spacerwelcome', 'assets/spacer.png');
        loader.image('joybase', 'assets/joybase.png');
        //loader.image('itf-mainbg', 'assets/mainbg0.jpg');
        ///loader.image('itf-topbg', 'assets/mainbg.png');
        ///loader.image('itf-treebg', 'assets/mainbg_tree.png');
        ///loader.image('itf-treebgfar', 'assets/treelinefar.png');
        loader.image('itf-logo', 'assets/logo.png');
        loader.image('itf-thinbtn', 'assets/thinbtn.png');
        //loader.image('itf-bgfg', 'assets/mainbgfg.png');
        ///loader.image('itf-bgfg', 'assets/mainbgfg.png');
        loader.image('itf-white', 'assets/white.png');
        loader.image('itf-black', 'assets/black.png');
        loader.image('itf-race1', 'assets/interface/race/1.png');
        loader.image('itf-race2', 'assets/interface/race/2.png');
        loader.image('itf-race3', 'assets/interface/race/3.png');
        loader.image('itf-race4', 'assets/interface/race/4.png');
        loader.image('itf-race5', 'assets/interface/race/5.png');
        loader.image('dialogbg', 'assets/dialogbg.png');
        loader.image('loginitfbg', 'assets/loginitfbg.png');



        //allaudio
        loader.audio("intro", ["assets/audio/intro.mp3"]);
        loader.audio("intro2", ["assets/audio/intro2.mp3"]);
        loader.audio("bgm", ["assets/audio/bgm.mp3"]);
        loader.audio("btn", ["assets/audio/btn.mp3"]);
        loader.audio("btn2", ["assets/audio/btn2.mp3"]);
        loader.audio("btn3", ["assets/audio/btn3.mp3"]);

        //loader.bitmapFont('fffw', 'assets/font/fffw.png', 'assets/font/fffw.fnt');
        //loader.bitmapFont('fffb', 'assets/font/fffb.png', 'assets/font/fffb.fnt');
        //loader.bitmapFont('fpw', 'assets/font/fpw.png', 'assets/font/fpw.fnt');

        /*
                loader.image('itf-loginb_login', 'assets/loginb_login.png');
                loader.image('itf-loginb_reg', 'assets/loginb_reg.png');
                loader.image('itf-loginb_forgot', 'assets/loginb_forgot.png');
                loader.image('itf-loginb_fb', 'assets/loginb_fb.png');
                loader.image('itf-loginb_gg', 'assets/loginb_gg.png');
                loader.image('itf-loginb_tw', 'assets/loginb_tw.png');
                loader.image('itf-loginb_listbackbtn', 'assets/loginb_listbackbtn.png');
                loader.image('itf-loginb_listfwdbtn', 'assets/loginb_listfwdbtn.png');*/

        loader.once(Phaser.Loader.Events.COMPLETE, () => {

            this.progressBar.destroy();
            this.progressBox.destroy();
            this.loadingText.destroy();
            this.percentText.destroy();
            this.mycreate();

        });
    }
    async create() {
        this.racebgid[1] = 9; //sword
        this.racebgid[2] = 11; //magic
        this.racebgid[3] = 4; //ranger
        this.racebgid[4] = 1; //tank
        this.racebgid[5] = 5; //nec

        this.registry.list.rcloading.setText("Initializing UI Language Pack");

        await this.registry.list.rcsvvars.updatelangpack();
        this.registry.list.rcloading.setText("Initializing UI");

        this.input.keyboard.on('keydown-ENTER',
            function (event) {
                //this.plog.log(            'Hello from the ENTER Key!',            this.wh.chatinputbox.visible,            this.wh.chatinputbox,            arguments          );
                //this.plog.log(arguments[0].srcElement.nodeName);
                if (this.isgameplayed == "yes") {
                    //this.plog.log("welcome input enter while game played");
                    return;
                }
                //event.stopPropagation();
                if (event.ctrlKey) return; if (event.altKey) return;
                //this.plog.error("ENTER ON WELCOME", this.selectchar_pageno, event, this);
                this.plog.log("ENTER ON WELCOME", this.selectchar_pageno, this.selectchar_playbtn[this.selectchar_pageno].name);
                if (this.lastpageshown == "selectchar") {
                    this.playGame(this.selectchar_playbtn[this.selectchar_pageno].name);
                }

            }.bind(this)
        );
        this.input.keyboard.on('keydown-LEFT',
            function (event) {
                if (this.isgameplayed == "yes") {
                    //this.plog.log("welcome input LEFT while game played");
                    return;
                }
                //event.stopPropagation();
                if (event.ctrlKey) return; if (event.altKey) return;
                this.selectchar_page("back");
            }.bind(this)
        );
        this.input.keyboard.on('keydown-RIGHT',
            function (event) {
                if (this.isgameplayed == "yes") {
                    //this.plog.log("welcome input RIGHT while game played");
                    return;
                }
                //event.stopPropagation();
                if (event.ctrlKey) return; if (event.altKey) return;
                this.selectchar_page("next");
            }.bind(this)
        );
        var width = this.game.canvas.width;

        if (width < 360) {
            this.fs["pageheader"] = {
                fontFamily: 'ffff,uuuu',
                fontStyle: 'bold',
                fontSize: '24px',
                color: '#F64900',
                stroke: '#FFFFFF',
                strokeThickness: 4,
                resolution: 4
            };
        }

        this.authserv = this.registry.list.thethis.authserv;

        this.isloaded = "yes";

        this.input.keyboard.on(
            'keydown-ENTER',
            async function (event) {
                //this.plog.log(                    'Welcome ENTER Key!',                    arguments[0].srcElement.id,                    arguments                );
                if (arguments[0].srcElement.id == "login_u" || arguments[0].srcElement.id == "login_p") {
                    //this.plog.log("start login by enter key");
                    await this.login();
                }
                event.preventDefault();
                return false;
            }, this);

        this.platform = Capacitor.getPlatform();
        await this.registry.list.rcstorage.set('remember_platform', this.platform);
        //   console.log("this.platform",this.platform);
    }
    async gencharsprite(sprite) {
        this.plog.log('loadcharsprite_loaded', sprite);//, this);

        var spriteset = [
            { code: 'idle', framename: 'walk-d', fr: 1, end: 1 },
            { code: 'walk-d', framename: 'walk-d', fr: 16, end: 9 },
            { code: 'walk-u', framename: 'walk-u', fr: 16, end: 9 },
            { code: 'walk-l', framename: 'walk-l', fr: 16, end: 9 },
            { code: 'walk-r', framename: 'walk-r', fr: 16, end: 9 },
            { code: 'thrust-d', framename: 'thrust-d', fr: 8, end: 8 },
            { code: 'thrust-u', framename: 'thrust-u', fr: 8, end: 8 },
            { code: 'thrust-l', framename: 'thrust-l', fr: 8, end: 8 },
            { code: 'thrust-r', framename: 'thrust-r', fr: 8, end: 8 },
            { code: 'spellcast-d', framename: 'spellcast-d', fr: 7, end: 7 },
            { code: 'spellcast-u', framename: 'spellcast-u', fr: 7, end: 7 },
            { code: 'spellcast-l', framename: 'spellcast-l', fr: 7, end: 7 },
            { code: 'spellcast-r', framename: 'spellcast-r', fr: 7, end: 7 },
            { code: 'slash-d', framename: 'slash-d', fr: 6, end: 6 },
            { code: 'slash-u', framename: 'slash-u', fr: 6, end: 6 },
            { code: 'slash-l', framename: 'slash-l', fr: 6, end: 6 },
            { code: 'slash-r', framename: 'slash-r', fr: 6, end: 6 },
            { code: 'shoot-d', framename: 'shoot-d', fr: 13, end: 13 },
            { code: 'shoot-u', framename: 'shoot-u', fr: 13, end: 13 },
            { code: 'shoot-l', framename: 'shoot-l', fr: 13, end: 13 },
            { code: 'shoot-r', framename: 'shoot-r', fr: 13, end: 13 },
            { code: 'atk-d', framename: 'atk-d', fr: 6, end: 6 },
            { code: 'atk-u', framename: 'atk-u', fr: 6, end: 6 },
            { code: 'atk-l', framename: 'atk-l', fr: 6, end: 6 },
            { code: 'atk-r', framename: 'atk-r', fr: 6, end: 6 },
            { code: 'walk-d-idle', framename: 'idle-d', fr: 3, end: 3 },
            { code: 'walk-u-idle', framename: 'idle-u', fr: 3, end: 3 },
            { code: 'walk-l-idle', framename: 'idle-l', fr: 3, end: 3 },
            { code: 'walk-r-idle', framename: 'idle-r', fr: 3, end: 3 },
            { code: 'idle-d', framename: 'idle-d', fr: 3, end: 3 },
            { code: 'idle-u', framename: 'idle-u', fr: 3, end: 3 },
            { code: 'idle-l', framename: 'idle-l', fr: 3, end: 3 },
            { code: 'idle-r', framename: 'idle-r', fr: 3, end: 3 },
            { code: 'dead', framename: 'dead', fr: 6, end: 6 },
            { code: 'climb', framename: 'climb', fr: 6, end: 6 },
            { code: 'sit-u', framename: 'sit-u', fr: 1, end: 1 },
            { code: 'sit-d', framename: 'sit-d', fr: 1, end: 1 },
            { code: 'sit-r', framename: 'sit-r', fr: 1, end: 1 },
            { code: 'sit-l', framename: 'sit-l', fr: 1, end: 1 },
        ];
        for (var idx in spriteset) {
            var val = spriteset[idx];
            //this.plog.error("loadcharsprite", idx, val);
            if (this.game.textures.list[sprite].frames[val['framename'] + '-1'] == undefined) {
                //this.plog.log("loadcharsprite_loaded xx skip, textures not found", val['framename'] + '-1');
                continue;
            }
            this.anims.remove(sprite + '-' + val['code']);
            this.anims.create({
                key: sprite + '-' + val['code'],
                frames: this.anims.generateFrameNames(sprite, {
                    prefix: val['framename'] + '-',
                    end: val['end'],
                    zeroPad: 0,
                    start: 1,
                }),
                repeat: -1,
                frameRate: val['fr'],
            });
        }
        //console.log("gencharsprite", sprite, this);
        this.walkers[sprite] = this.add.sprite(400, 300, '' + sprite);
        this.walkers[sprite].setOrigin(0.5, 1);

        var width = this.game.canvas.width;
        var height = this.game.canvas.height;
        this.walkers[sprite].setPosition(width / 2, height - 16);
        this.walkers[sprite].setDepth(16000005);
        if (sprite == "walker1") {

            const timeline = this.add.timeline([
                {
                    at: 0,
                    run: () => {
                        this.walkers[sprite].play({ key: sprite + "-walk-l", frameRate: 8 });

                    }
                }, {
                    at: 0,
                    tween: {
                        targets: this.walkers[sprite],
                        x: width / 2 - 130,
                        duration: 1300,
                        ease: 'Linear'
                    }
                },
                {
                    at: 1300,
                    run: () => {
                        this.walkers[sprite].play({ key: sprite + "-idle-d", frameRate: 2 })
                    }
                },
                {
                    at: 2000,
                    run: () => {
                        this.walkers[sprite].play({ key: sprite + "-spellcast-d", frameRate: 8 })
                    }
                },
                {
                    at: 3000,
                    run: () => {
                        this.walkers[sprite].play({ key: sprite + "-spellcast-r", frameRate: 8 })
                    }
                },
                {
                    at: 4000,
                    run: () => {
                        this.walkers[sprite].play({ key: sprite + "-spellcast-l", frameRate: 8 })
                    }
                },
                {
                    at: 5000,
                    run: () => {
                        this.walkers[sprite].play({ key: sprite + "-idle-d", frameRate: 2 })
                    }
                },
                {
                    at: 6000,
                    run: () => {
                        this.walkers[sprite].play({ key: sprite + "-dead", frameRate: 8, repeat: 0 })
                    }
                },
                {
                    at: 9000,
                    run: () => {
                        this.walkers[sprite].play({ key: sprite + "-idle-d", frameRate: 1, repeat: 0 })
                    }
                }, {
                    at: 12000,
                    run: () => {
                        this.walkers[sprite].play({ key: sprite + "-walk-r", frameRate: 8 });

                    }
                }, {
                    at: 12000,
                    tween: {
                        targets: this.walkers[sprite],
                        x: width / 2,
                        duration: 1300,
                        ease: 'Linear'
                    }
                }, {
                    at: 13300,
                    run: () => {
                        this.walkers[sprite].play({ key: sprite + "-idle-r", frameRate: 8, repeat: 2 });

                    }
                }, {
                    at: 15000,
                    run: () => {
                        this.walkers[sprite].play({ key: sprite + "-sit-r", frameRate: 8, repeat: 2 });
                    }
                }, {
                    at: 19000,
                    run: () => {
                        this.walkers[sprite].play({ key: sprite + "-sit-l", frameRate: 8, repeat: 2 });
                    }
                }
            ]);

            timeline.repeat().play();
        }
        if (sprite == "walker2") {

            const timeline = this.add.timeline([
                {
                    at: 0,
                    run: () => {
                        this.walkers[sprite].play({ key: sprite + "-walk-r", frameRate: 8 });

                    }
                }, {
                    at: 0,
                    tween: {
                        targets: this.walkers[sprite],
                        x: width / 2 + 80,
                        duration: 800,
                        ease: 'Linear'
                    }
                },
                {
                    at: 800,
                    run: () => {
                        this.walkers[sprite].play({ key: sprite + "-idle-d", frameRate: 2 })
                    }
                },
                {
                    at: 1500,
                    run: () => {
                        this.walkers[sprite].play({ key: sprite + "-sit-d", frameRate: 2 })
                    }
                },
                {
                    at: 2300,
                    run: () => {
                        this.walkers[sprite].play({ key: sprite + "-sit-l", frameRate: 2 })
                    }
                },
                {
                    at: 4000,
                    run: () => {
                        this.walkers[sprite].play({ key: sprite + "-walk-l", frameRate: 8 })
                    }
                }, {
                    at: 4000,
                    tween: {
                        targets: this.walkers[sprite],
                        x: width / 2 - 100,
                        duration: 2000,
                        ease: 'Linear'
                    }
                },
                {
                    at: 6000,
                    run: () => {
                        this.walkers[sprite].play({ key: sprite + "-idle-l", frameRate: 2 })
                    }
                },
                {
                    at: 7000,
                    run: () => {
                        this.walkers[sprite].play({ key: sprite + "-thrust-l", frameRate: 8, repeat: 1 })
                    }
                },
                {
                    at: 10000,
                    run: () => {
                        this.walkers[sprite].play({ key: sprite + "-walk-r", frameRate: 8, repeat: 1 })
                    }
                }, {
                    at: 10000,
                    tween: {
                        targets: this.walkers[sprite],
                        x: width / 2 + 50,
                        duration: 1000,
                        ease: 'Linear'
                    }
                },
                {
                    at: 11000,
                    run: () => {
                        this.walkers[sprite].play({ key: sprite + "-idle-r", frameRate: 8, repeat: 1 })
                    }
                },
                {
                    at: 13000,
                    run: () => {
                        this.walkers[sprite].play({ key: sprite + "-sit-d", frameRate: 8, repeat: 1 })
                    }
                },
                {
                    at: 17000,
                    run: () => {
                        this.walkers[sprite].play({ key: sprite + "-idle-d", frameRate: 8, repeat: 1 })
                    }
                }
            ]);

            timeline.repeat().play();
        }
        //console.log(this.walkers);
    }
    async mycreate() {
        this.registry.list.snd.prepare();
        this.gencharsprite("walker1");
        this.gencharsprite("walker2");

        //this.plog.error("welcome.mycreate()",this);
        this.wh = this;
        //console.log("mycreate", this);
        /*
                if (this.registry.list.phaserGame.scene.scenes[0].volume == undefined || this.registry.list.phaserGame.scene.scenes[0].volume == "") {
                    this.registry.list.phaserGame.scene.scenes[0].volume = await this.registry.list.rcstorage.get('setting_volume');
                }
                this.registry.list.phaserGame.scene.scenes[0].sound.setVolume(this.registry.list.phaserGame.scene.scenes[0].volume / 10);
        */
        this.registry.list.rcloading.loading2(this, false, false);
        this.registry.list.rcloading.loading2text(
            this.trs("f:home:Connecting..===Connecting.."));

        var width = this.game.canvas.width;
        var height = this.game.canvas.height;
        //local splash s
        if (this.firsttimerun == true) {
            this.splashlogo = this.add.image(0, 0, 'itf-logo');
            this.splashlogo.setOrigin(0.5, 0.5);
            this.splashlogo.setPosition(width / 2, height / 2);
            this.splashlogo.displayWidth = width * 0.9;
            //this.splashlogo.postFX.addBloom(0xffffff, 1,1, 2,1.2);
            this.splashlogo.postFX.addGlow(0xffffff, 5, 0, false, 1, 5);
            //addGlow([color], [outerStrength], [innerStrength], [knockout], [quality], [distance])

            //addBloom([color], [offsetX], [offsetY], [blurStrength], [strength], [steps])

            if (this.splashlogo.displayWidth > (height * 0.9) && width > height) {
                //wide landscape
                this.splashlogo.displayWidth = height * 0.9;
            }
            this.splashlogo.scaleY = this.splashlogo.scaleX;
            this.splashlogo.setDepth(16000005);
            this.splashlogo.alpha = 0;
            this.splashlogo.postFX.addShine(0.5, 0.2, 5, false);
            //addShine([speed], [lineWidth], [gradient], [reveal])

            //cover loading2's bg
            this.splashbg = this.add.rectangle(0, 0, width, this.splashlogo.displayHeight + 20, 0x000000);
            this.splashbg.setPosition(width / 2, height / 2);
            this.splashbg.setOrigin(0.5, 0.5);
            this.splashbg.setAlpha(1);
            this.splashbg.visible = true;
            this.splashbg.setDepth(16000000);


            if (this.registry.list.rcloading.loading2bg != undefined) {
                this.registry.list.rcloading.loading2bg.setAlpha(1);
            }
            this.tweens.add({
                targets: [
                    this.splashlogo
                ],
                alpha: 1,
                ease: 'Power1',
                duration: 500,
                delay: 0,
                yoyo: false,
                repeat: 0,
                onComplete: function () {
                },
            });
            this.registry.list.snd.snd("intro");

            this.firsttimerun = false;
        }
        //local splash e
        //this.plog.log("Welcome create ", this);
        if (Capacitor.getPlatform() == "android") {
            try {
                //this.registry.list.scro.lock(this.registry.list.scro.ORIENTATIONS.PORTRAIT);
                //window.screen.orientation.lock('portrait');
                //this.plog.log("Welcome create ", window.screen.orientation);

            } catch (err) {
                this.plog.log("scro error", err);
            }
        }
        var infostr = "";
        if (Capacitor.getPlatform() == "android") {
            //var info = await Capacitor.Plugins.App.getInfo();
            //var info = await Device.getInfo();
            //this.plog.log("info", info, Capacitor);
            infostr = "android";//info["version"]
        } else {
            infostr = "web";
        }



        this.appversiont = this.wh.add.text(0, 0, "" + infostr + " " + this.cliversionstring, this.fs["chardetail2"]);
        this.appversiont.removeAllListeners();
        this.appversiont.setInteractive();
        this.appversiont.on("pointerdown", function () {
            this.appversiontcount++;
            if (this.appversiontcount > 4) {
                this.plog.warn("Enter testermode");
                this.testermode = true;
            }
        }, this);

        this.itftopbg = this.add.sprite(400, 300, 'itf-black').setOrigin(0, 0);
        this.itftopbg.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);
        this.itftopbg.setOrigin(0.5, 0.5);

        this.itftopbg2 = this.add.sprite(400, 300, 'loadingbg1').setOrigin(0, 0);
        this.itftopbg2.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);
        this.itftopbg2.setOrigin(0.5, 0.5);
        if (width > height) {
            this.itftopbg.displayWidth = width + 10;
            this.itftopbg.scaleY = this.itftopbg.scaleX;
            this.itftopbg2.displayWidth = width + 10;
            this.itftopbg2.scaleY = this.itftopbg2.scaleX;
        } else {
            this.itftopbg.displayHeight = height + 10;
            this.itftopbg.scaleX = this.itftopbg.scaleY;
            this.itftopbg2.displayHeight = height + 10;
            this.itftopbg2.scaleX = this.itftopbg2.scaleY;
        }
        this.itftopbg.setPosition(0, 0);
        this.itftopbg.setDepth(1000);
        this.itftopbg2.x = this.itftopbg.x;
        this.itftopbg2.y = this.itftopbg.y;
        this.itftopbg2.setDepth(1001);

        this.white1 = this.add.sprite(400, 300, 'itf-white').setOrigin(0.5, 0);
        this.white2 = this.add.sprite(400, 300, 'itf-white').setOrigin(0.5, 0);
        this.white3 = this.add.sprite(400, 300, 'itf-white').setOrigin(0.5, 0);

        this.sociallinkbtn = this.add.sprite(400, 300, 'theb', 'sociallink');
        this.sociallinkbtn.setDepth(1010);
        this.sociallinkbtn.visible = false;
        this.sociallinkbtn.removeAllListeners();
        this.sociallinkbtn.setInteractive();
        this.sociallinkbtn.on("pointerdown", function () {
            this.managesociallink();
        }, this);



        this.curlangbtn = this.add.sprite(400, 300, 'thebs', 'minussign');
        this.curlangbtn.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);

        this.settingbtn = this.add.sprite(400, 300, 'theb', 'settings');
        this.logoutbtn = this.add.sprite(400, 300, 'theb', 'logout');
        this.aboutbtn = this.add.sprite(400, 300, 'theb', 'aboutcredits');
        this.bugreportbtn = this.add.sprite(400, 300, 'theb', 'bugreport');

        this.aboutbtn.setDepth(1010);
        this.logoutbtn.setDepth(1010);
        this.bugreportbtn.setDepth(1010);

        this.logoutbtn.visible = false;
        this.aboutbtn.removeAllListeners();
        this.aboutbtn.setInteractive();
        this.aboutbtn.on("pointerdown", function () {
            this.openWebpage("https://peacely.online/en-about_n_credits.html");
        }, this);
        this.bugreportbtn.removeAllListeners();
        this.bugreportbtn.setInteractive();
        this.bugreportbtn.on("pointerdown", async function () {
            var usesv = await this.wh.registry.list.rcstorage.get('rc_usesv');
            // this.wh.registry.list.execmod.openWebpage("https://docs.google.com/forms/d/e/1FAIpQLSe9pw1L2x8O8auZKe54mmEqwDk5exynHOrRZUd0uJI6iH5hlA/viewform?usp=pp_url&entry.2023924251=" + usesv + "&entry.1281232028=loginscreen");
            this.wh.registry.list.execmod.openWebpage("https://discord.gg/JCyPFjJqaS");

        }, this);

        this.logoutbtn.removeAllListeners();
        this.logoutbtn.setInteractive();
        this.logoutbtn.on("pointerdown", function () {
            this.logout();
        }, this);

        this.settingbtn.setDepth(1010);
        this.settingbtn.visible = false;
        this.settingbtn.removeAllListeners();
        this.settingbtn.setInteractive();
        this.settingbtn.on("pointerdown", function () {
            this.showpage("settings");
        }, this);

        this.curlangbtn.setDepth(1010);
        this.curlangbtn.visible = false;
        this.curlangbtn.removeAllListeners();
        this.curlangbtn.setInteractive();
        this.curlangbtn.on("pointerdown", function () {
            this.showpage("settings");
        }, this);

        this.itflogo = this.add.sprite(400, 300, 'itf-logo').setOrigin(0.5, 0);
        this.itflogo.displayWidth = width;
        if (width > 1600) this.itflogo.displayWidth = 800;
        if (width > 1200) this.itflogo.displayWidth = 600;
        if (width > 700) this.itflogo.displayWidth = 600;
        this.itflogo.scaleY = this.itflogo.scaleX;
        this.itflogo.setPosition(width / 2, 0);
        this.itflogo.setDepth(1010);
        this.itflogo.postFX.addShine(0.5, 0.2, 5, false);

        this.welcomehead = this.add.image(0, 0, 'welcomehead');
        this.welcomehead.setOrigin(0, 0);
        this.welcomehead.flipY = true;
        this.welcomehead.displayWidth = width + 5;
        this.welcomehead.displayHeight = 180;
        this.welcomehead.setPosition(0, 0);
        this.welcomehead.setDepth(1009);

        this.welcomehead2 = this.add.image(0, 0, 'welcomehead');
        this.welcomehead2.setOrigin(0, 1);
        this.welcomehead2.flipY = false;
        this.welcomehead2.displayWidth = width + 5;
        this.welcomehead2.displayHeight = 45;
        this.welcomehead2.setPosition(0, height + 5);
        this.welcomehead2.setDepth(1009);

        /*
                this.itftreebg = this.add.sprite(400, 300, 'itf-treebgfar').setOrigin(0, 0);
                this.itftreebg.displayWidth = width;
                this.itftreebg.scaleY = this.itftreebg.scaleX;
                this.itftreebg.setPosition(0, height);
                this.itftreebg.setDepth(1001);
                // this.itftreebg.setScale(1.2);
        */
        /*
                this.itftreebg2 = this.add.sprite(400, 300, 'itf-treebg').setOrigin(0, 0);
                this.itftreebg2.displayWidth = width;
                this.itftreebg2.scaleY = this.itftreebg2.scaleX;
                this.itftreebg2.setPosition(0, height);
                this.itftreebg2.setDepth(1002);
                //this.itftreebg2.setScale(1.4);
        */

        this.loginitfbg = this.add.sprite(400, 300, 'loginitfbg').setOrigin(0, 0);
        this.loginitfbg.displayWidth = width;
        // this.loginitfbg.scaleY = this.itftreebg2.scaleX;
        this.loginitfbg.scaleY = this.itftopbg.scaleX;
        this.loginitfbg.setOrigin(0.5, 0.5);
        this.loginitfbg.setPosition(width / 2, height / 2);
        this.loginitfbg.setDepth(1007);
        this.loginitfbg.setAlpha(0.7);

        /*
                this.itfbgfg = this.add.sprite(400, 300, 'itf-bgfg').setOrigin(0, 1);
                if (width > height) {
                    this.itfbgfg.displayHeight = Math.min(width, height) * 0.7;
                    this.itfbgfg.scaleX = this.itfbgfg.scaleY;
                } else {
                    this.itfbgfg.displayWidth = Math.min(width, height) * 0.7;
                    this.itfbgfg.scaleY = this.itfbgfg.scaleX;
                }
                this.itfbgfg.setPosition(0, height);
                this.itfbgfg.setDepth(1005);
          */
        //this.itftreebg.visible=false;
        //this.itftreebg2.visible=false;
        //this.itftopbg.visible=false;

        /*this.itf_bg2 = this.add.tileSprite(0, 0, width, height, "itf-treebgfar")
            .setOrigin(0, 0)
            .setScrollFactor(0, 1); //this line keeps your background from scrolling outside of camera bounds
        this.itf_bg1 = this.add.tileSprite(0, 0, width, height, "itf-treebg")
            .setOrigin(0, 0)
            .setScrollFactor(0, 0.5); //this line keeps your background from scrolling outside of camera bounds
          */
        this.thecanvas = document.querySelector("#thecanvas > canvas");
        //this.thecanvas = document.getElementById("thecanvas");
        //this.plog.log("Welcome thecanvas ", this.thecanvas, this.thecanvas.style);
        this.thecanvas.style.width = "100%";
        this.thecanvas.style.height = "100%";

        this.tweens.add({
            targets: [
                this.itflogo
            ],
            y: { from: this.itflogo.displayHeight * 0.05, to: this.itflogo.displayHeight * 0.1 },
            ease: 'Power1',
            duration: 2000,
            delay: 0,
            yoyo: false,
            repeat: 0,
            onComplete: function () {
            },
        });
        /*
                this.tweens.add({
                    targets: [
                        this.itftreebg
                    ],
                    y: this.itflogo.displayHeight * 1.5,
                    ease: 'Power1',
                    duration: 800,
                    delay: 400,
                    yoyo: false,
                    repeat: 0,
                    onComplete: function () {
                        this.startsyncanimation();
                    }.bind(this),
                });
                this.tweens.add({
                    targets: [
                        this.itftreebg2
                    ],
                    y: height / 2,
                    //y: this.itftreebg.y + this.itftreebg.displayHeight * 0.5,
                    ease: 'Power1',
                    duration: 1200,
                    delay: 0,
                    yoyo: false,
                    repeat: 0,
                    onComplete: function () {
                    },
                });
                this.tweens.add({
                    targets: [
                        this.itftopbg
                    ],
                    y: 0 - (this.itftopbg.displayHeight * 0.4),
                    ease: 'Power1',
                    duration: 800,
                    delay: 0,
                    yoyo: false,
                    repeat: 0,
                    onComplete: function () {
                    },
                });
        */
        this.sociallinkcontainer = this.add.container(0, 0);
        this.sociallinkcontainer.visible = false;

        /////////////////////////////////////////////////////
        //login container

        this.loginb_login = this.add.sprite(400, 300, 'thebb', 'login');
        this.loginb_reg = this.add.sprite(400, 300, 'thebb', 'signup');
        this.loginb_forgot = this.add.sprite(400, 300, 'theb', 'forgotpwd');
        this.loginb_guest = this.add.sprite(400, 300, 'theb', 'guest');
        this.loginb_fb = this.add.sprite(400, 300, 'theb', 'facebooklogin');
        this.loginb_gg = this.add.sprite(400, 300, 'theb', 'googlelogin');
        this.loginb_tw = this.add.sprite(400, 300, 'theb', 'twitterlogin');

        this.logincontainer = this.add.container(0, 0);
        this.logincontainer.visible = false;

        this.logincontainer.add(this.itflogo);
        this.logincontainer.bringToTop(this.itflogo);
        this.logincontainer.add(this.white1);
        this.logincontainer.bringToTop(this.white1);
        this.logincontainer.add(this.white2);
        this.logincontainer.bringToTop(this.white2);
        this.logincontainer.add(this.white3);
        this.logincontainer.bringToTop(this.white3);

        this.logincontainer.add(this.loginb_login);
        this.logincontainer.bringToTop(this.loginb_login);
        this.logincontainer.add(this.loginb_reg);
        this.logincontainer.bringToTop(this.loginb_reg);
        this.logincontainer.add(this.loginb_forgot);
        this.logincontainer.bringToTop(this.loginb_forgot);
        this.logincontainer.add(this.loginb_guest);
        this.logincontainer.bringToTop(this.loginb_guest);
        this.logincontainer.add(this.loginb_fb);
        this.logincontainer.bringToTop(this.loginb_fb);
        this.logincontainer.add(this.loginb_gg);
        this.logincontainer.bringToTop(this.loginb_gg);
        this.logincontainer.add(this.loginb_tw);
        this.logincontainer.bringToTop(this.loginb_tw);
        this.loginb_fb.removeAllListeners();
        this.loginb_fb.setInteractive();
        this.loginb_fb.on("pointerdown", function () {
            this.facebookAuth('login');
        }, this);
        this.loginb_gg.removeAllListeners();
        this.loginb_gg.setInteractive();
        this.loginb_gg.on("pointerdown", function () {
            this.googleAuth('login');
        }, this);
        this.loginb_tw.removeAllListeners();
        this.loginb_tw.setInteractive();
        this.loginb_tw.on("pointerdown", function () {
            this.twitterAuth('login');
        }, this);

        this.loginb_login.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);
        this.loginb_reg.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);
        this.loginb_forgot.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);
        this.loginb_guest.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);
        this.loginb_fb.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);
        this.loginb_gg.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);
        this.loginb_tw.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);

        //this.plog.log("xxxxx", this.wh);
        this.logint_sv = this.wh.add.text(0, 0, this.trs("f:home:select-server===Select Server"), this.fs["loginformtext"]);
        this.logint_svdetail = this.wh.add.text(0, 0, " ", this.fs["loginformtextdetail"]);

        this.login_sv = this.wh.add.text(0, 0, " Click to Select ", this.fs["selectsv"]);
        this.login_sv.removeAllListeners();
        this.login_sv.setInteractive();
        this.login_sv.on("pointerdown", function () {
            this.selectsv();
        }, this);
        this.loginb_forgot.removeAllListeners();
        this.loginb_forgot.setInteractive();
        this.loginb_forgot.on("pointerdown", function () {
            this.showpage("forgot");
        }, this);
        this.loginb_guest.removeAllListeners();
        this.loginb_guest.setInteractive();
        this.loginb_guest.on("pointerdown", function () {
            //this.showpage("forgot");
            this.guestmode();
        }, this);
        this.logint_u = this.wh.add.text(0, 0, "Email", this.fs["loginformtext"]);
        this.login_u = this.wh.add.rexInputText(0, 0, 100, 25, {
            id: 'login_u',
            type: 'text',
            text: '',
            fontSize: '20px',
            color: '#000',
            //autoComplete: 'off',
            border: 1,
            backgroundColor: '#f2f2f2',
            borderColor: '#666',
            selectAll: true,
        })
            .resize(150, 30)
            .setOrigin(0.5)
            .on('textchange', function (inputText) {
                //this.plog.log(inputText);
            });
        this.logint_p = this.wh.add.text(0, 0, "Password", this.fs["loginformtext"]);
        this.login_p = this.wh.add.rexInputText(0, 0, 100, 25, {
            id: 'login_p',
            type: 'password',
            text: '',
            fontSize: '20px',
            color: '#000',
            //autoComplete: 'off',
            border: 1,
            backgroundColor: '#f2f2f2',
            borderColor: '#666',
            selectAll: true,
        })
            .resize(150, 30)
            .setOrigin(0.5)
            .on('keydown', function (a1, a2, a3) {
                //this.plog.log(arguments);
                //this.plog.log(inputText);
            });

        this.logincontainer.add(this.logint_u);
        this.logincontainer.add(this.login_u);
        this.logincontainer.add(this.logint_p);
        this.logincontainer.add(this.login_p);
        this.logincontainer.add(this.logint_sv);
        this.logincontainer.add(this.login_sv);
        this.logincontainer.add(this.logint_svdetail);
        this.logincontainer.bringToTop(this.logint_u);
        this.logincontainer.bringToTop(this.login_u);
        this.logincontainer.bringToTop(this.logint_p);
        this.logincontainer.bringToTop(this.login_p);
        this.logincontainer.bringToTop(this.logint_sv);
        this.logincontainer.bringToTop(this.login_sv);
        this.logincontainer.bringToTop(this.logint_svdetail);
        this.logincontainer.setDepth(11000);
        //this.plog.log("this.logincontainer", this.logincontainer);

        this.loginb_reg.removeAllListeners();
        this.loginb_reg.setInteractive();
        this.loginb_reg.on("pointerdown", function () {
            this.showpage("reg");
        }, this);

        this.loginb_login.removeAllListeners();
        this.loginb_login.setInteractive();
        this.loginb_login.removeAllListeners();
        this.loginb_login.on("pointerdown", async function () {
            //this.plog.log("loginbtn-")
            await this.login();
        }, this);
        //this.plog.log("create login btn")
        //reg *////////////////////////////////////////////////////

        this.regcontainer = this.add.container(0, 0);
        this.regcontainer.visible = false;

        this.regt_sv = this.wh.add.text(0, 0, this.trs("f:home:select-server===Select Server"), this.fs["loginformtext"]);
        this.regt_svdetail = this.wh.add.text(0, 0, " ", this.fs["loginformtextdetail"]);

        this.reg_header = this.wh.add.text(0, 0, this.trs("f:home:Registration===Registration"), this.fs["pageheader"]);

        this.reg_sv = this.wh.add.text(0, 0, this.trs("f:home:Click to Select===Click to Select"), this.fs["selectsv"]);
        this.reg_sv.removeAllListeners();
        this.reg_sv.setInteractive();
        this.reg_sv.on("pointerdown", function () {
            this.selectsv();
        }, this);

        this.regwhite1 = this.add.sprite(400, 300, 'itf-white').setOrigin(0.5, 0);
        this.regwhite2 = this.add.sprite(400, 300, 'itf-white').setOrigin(0.5, 0);
        this.regwhite3 = this.add.sprite(400, 300, 'itf-white').setOrigin(0.5, 0);


        this.regt_u = this.wh.add.text(0, 0, this.trs("f:home:email===E-Mail"), this.fs["loginformtext"]);
        this.reg_u = this.wh.add.rexInputText(0, 0, 100, 25, {
            id: 'reg_u',
            type: 'text',
            text: '',
            fontSize: '20px',
            color: '#000',
            autoComplete: 'off',
            border: 1,
            backgroundColor: '#f2f2f2',
            borderColor: '#666',
            selectAll: true,
        })
            .resize(150, 30)
            .setOrigin(0.5)
            .on('textchange', function (inputText) {
                //console.log(inputText);
            });
        this.regt_p = this.wh.add.text(0, 0, this.trs("f:home:password===Password"), this.fs["loginformtext"]);
        this.reg_p = this.wh.add.rexInputText(0, 0, 100, 25, {
            id: 'reg_p',
            type: 'password',
            text: '',
            fontSize: '20px',
            color: '#000',
            autoComplete: 'off',
            border: 1,
            backgroundColor: '#f2f2f2',
            borderColor: '#666',
            selectAll: true,
        })
            .resize(150, 30)
            .setOrigin(0.5)
            .on('textchange', function (inputText) {
                //console.log(inputText);
            });

        this.regb_back = this.add.sprite(0, 0, "thebb", "back");
        this.regb_reg = this.add.sprite(0, 0, "thebb", "register");

        this.regb_back.removeAllListeners();
        this.regb_back.setInteractive();
        this.regb_back.on("pointerdown", function () {
            this.showpage("login");
        }, this);

        this.regb_reg.removeAllListeners();
        this.regb_reg.setInteractive();
        this.regb_reg.on("pointerdown", function () {
            this.register();
        }, this);


        this.regcontainer.add(this.regt_u);
        this.regcontainer.add(this.reg_u);
        this.regcontainer.add(this.regt_p);
        this.regcontainer.add(this.reg_p);
        this.regcontainer.add(this.regt_sv);
        this.regcontainer.add(this.reg_sv);
        this.regcontainer.add(this.regt_svdetail);
        this.regcontainer.add(this.reg_header);
        this.regcontainer.add(this.regb_back);
        this.regcontainer.add(this.regb_reg);
        this.regcontainer.add(this.regwhite1);
        this.regcontainer.add(this.regwhite2);
        this.regcontainer.add(this.regwhite3);
        this.regcontainer.bringToTop(this.regb_back);
        this.regcontainer.bringToTop(this.regb_reg);
        this.regcontainer.bringToTop(this.regt_u);
        this.regcontainer.bringToTop(this.reg_u);
        this.regcontainer.bringToTop(this.regt_p);
        this.regcontainer.bringToTop(this.reg_p);
        this.regcontainer.bringToTop(this.regt_sv);
        this.regcontainer.bringToTop(this.reg_sv);
        this.regcontainer.bringToTop(this.regt_svdetail);
        this.regcontainer.bringToTop(this.reg_header);
        this.reg_header.visible = false;
        this.regcontainer.setDepth(11000);
        //this.plog.log("this.regcontainer", this.regcontainer);


        //forgot *////////////////////////////////////////////////////

        this.forgotcontainer = this.add.container(0, 0);
        this.forgotcontainer.visible = false;

        this.forgott_sv = this.wh.add.text(0, 0, this.trs("f:home:select-server===Select Server"), this.fs["loginformtext"]);
        this.forgott_svdetail = this.wh.add.text(0, 0, " ", this.fs["loginformtextdetail"]);

        this.forgot_header = this.wh.add.text(0, 0, this.trs("f:home:Forgot Password===Forgot Password"), this.fs["pageheader"]);

        this.forgot_sv = this.wh.add.text(0, 0, this.trs("f:home:Click to Select===Click to Select"), this.fs["selectsv"]);
        this.forgot_sv.removeAllListeners();
        this.forgot_sv.setInteractive();
        this.forgot_sv.on("pointerdown", function () {
            this.selectsv();
        }, this);

        this.forgotwhite1 = this.add.sprite(400, 300, 'itf-white').setOrigin(0.5, 0);
        this.forgotwhite2 = this.add.sprite(400, 300, 'itf-white').setOrigin(0.5, 0);
        this.forgotwhite3 = this.add.sprite(400, 300, 'itf-white').setOrigin(0.5, 0);


        this.forgott_u = this.wh.add.text(0, 0, this.trs("f:home:email===E-Mail"), this.fs["loginformtext"]);
        this.forgot_u = this.wh.add.rexInputText(0, 0, 100, 25, {
            id: 'forgot_u',
            type: 'text',
            text: '',
            fontSize: '20px',
            color: '#000',
            autoComplete: 'off',
            border: 1,
            backgroundColor: '#f2f2f2',
            borderColor: '#666',
            selectAll: true,
        })
            .resize(150, 30)
            .setOrigin(0.5)
            .on('textchange', function (inputText) {
                //this.plog.log(inputText);
            });

        this.forgotb_sendcode = this.add.sprite(0, 0, "theb", "sendcode");


        this.forgott_p = this.wh.add.text(0, 0, this.trs("f:home:ENTER CODE===ENTER CODE"), this.fs["loginformtext"]);
        this.forgot_p = this.wh.add.rexInputText(0, 0, 100, 25, {
            id: 'forgot_p',
            type: 'text',
            text: '',
            fontSize: '20px',
            color: '#000',
            autoComplete: 'off',
            border: 1,
            backgroundColor: '#f2f2f2',
            borderColor: '#666',
            selectAll: true,
        })
            .resize(150, 30)
            .setOrigin(0.5)
            .on('textchange', function (inputText) {
                this.plog.log(inputText);
            });


        this.forgott_np = this.wh.add.text(0, 0, this.trs("f:home:ENTER NEW PASSWORD===ENTER NEW PASSWORD"), this.fs["loginformtext"]);
        this.forgot_np = this.wh.add.rexInputText(0, 0, 100, 25, {
            id: 'reg_np',
            type: 'password',
            text: '',
            fontSize: '20px',
            color: '#000',
            autoComplete: 'off',
            border: 1,
            backgroundColor: '#f2f2f2',
            borderColor: '#666',
            selectAll: true,
        })
            .resize(150, 30)
            .setOrigin(0.5)
            .on('textchange', function (inputText) {
                this.plog.log(inputText);
            });


        this.forgotb_back = this.add.sprite(0, 0, "thebb", "back");
        this.forgotb_reg = this.add.sprite(0, 0, "thebb", "recover");

        this.forgotb_back.removeAllListeners();
        this.forgotb_back.setInteractive();
        this.forgotb_back.on("pointerdown", function () {
            this.showpage("login");
        }, this);

        this.forgotb_reg.removeAllListeners();
        this.forgotb_reg.setInteractive();
        this.forgotb_reg.on("pointerdown", function () {
            this.forgot_entercode();
        }, this);

        this.forgotb_sendcode.removeAllListeners();
        this.forgotb_sendcode.setInteractive();
        this.forgotb_sendcode.on("pointerdown", function () {
            this.forgot_sendcode();
        }, this);

        this.forgotcontainer.add(this.forgott_u);
        this.forgotcontainer.add(this.forgot_u);
        this.forgotcontainer.add(this.forgott_p);
        this.forgotcontainer.add(this.forgot_p);
        this.forgotcontainer.add(this.forgott_np);
        this.forgotcontainer.add(this.forgot_np);
        this.forgotcontainer.add(this.forgott_sv);
        this.forgotcontainer.add(this.forgot_sv);
        this.forgotcontainer.add(this.forgott_svdetail);
        this.forgotcontainer.add(this.forgot_header);
        this.forgotcontainer.add(this.forgotb_back);
        this.forgotcontainer.add(this.forgotb_reg);
        this.forgotcontainer.add(this.forgotwhite1);
        this.forgotcontainer.add(this.forgotwhite2);
        this.forgotcontainer.add(this.forgotwhite3);
        this.forgotcontainer.add(this.forgotb_sendcode);

        this.forgotcontainer.bringToTop(this.forgotb_back);
        this.forgotcontainer.bringToTop(this.forgotb_reg);
        this.forgotcontainer.bringToTop(this.forgott_u);
        this.forgotcontainer.bringToTop(this.forgot_u);
        this.forgotcontainer.bringToTop(this.forgott_p);
        this.forgotcontainer.bringToTop(this.forgot_p);
        this.forgotcontainer.bringToTop(this.forgott_np);
        this.forgotcontainer.bringToTop(this.forgot_np);
        this.forgotcontainer.bringToTop(this.forgott_sv);
        this.forgotcontainer.bringToTop(this.forgot_sv);
        this.forgotcontainer.bringToTop(this.forgott_svdetail);
        this.forgotcontainer.bringToTop(this.forgot_header);
        this.forgotcontainer.bringToTop(this.forgotb_sendcode);
        this.forgotcontainer.setDepth(11000);
        //this.plog.log("this.forgotcontainer", this.forgotcontainer);


        //selectchar if logged in//////////////////////////////////////////////////
        this.selectcharcontainer = this.add.container(0, 0);

        this.selectcharcontainer.setDepth(11000);
        this.selectcharcontainer.visible = false;

        //this.selectChar_build();


        //createchar//////////////////////////////////
        this.createcharcontainer = this.add.container(0, 0);
        this.createcharcontainer.setDepth(11000);
        this.createcharcontainer.visible = false;
        this.createchart_race = this.wh.add.text(0, 0, this.trs("f:home:Select Race===Select Race"), this.fs["loginformtext"]);
        this.createchart_race1 = this.add.image(0, 0, 'itf-race1');
        this.createchart_race2 = this.add.image(0, 0, 'itf-race2');
        this.createchart_race3 = this.add.image(0, 0, 'itf-race3');
        this.createchart_race4 = this.add.image(0, 0, 'itf-race4');
        this.createchart_race5 = this.add.image(0, 0, 'itf-race5');
        this.createchart_racename = this.wh.add.text(0, 0, " ", this.fs["racename"]);
        this.createchart_racedescr = this.wh.add.text(0, 0, " ", this.fs["racename2"]);


        this.createchart_race1.removeAllListeners();
        this.createchart_race1.setInteractive();
        this.createchart_race1.on("pointerdown", function () { this.createchar_clickrace(1); }, this);
        this.createchart_race2.removeAllListeners();
        this.createchart_race2.setInteractive();
        this.createchart_race2.on("pointerdown", function () { this.createchar_clickrace(2); }, this);
        this.createchart_race3.removeAllListeners();
        this.createchart_race3.setInteractive();
        this.createchart_race3.on("pointerdown", function () { this.createchar_clickrace(3); }, this);
        this.createchart_race4.removeAllListeners();
        this.createchart_race4.setInteractive();
        this.createchart_race4.on("pointerdown", function () { this.createchar_clickrace(4); }, this);
        this.createchart_race5.removeAllListeners();
        this.createchart_race5.setInteractive();
        this.createchart_race5.on("pointerdown", function () { this.createchar_clickrace(5); }, this);

        this.createchart_gender = this.wh.add.text(0, 0, this.trs("f:home:Select Gender===Select Gender"), this.fs["loginformtext"]);
        this.createchart_genderm = this.add.sprite(0, 0, 'thebb', 'male');
        this.createchart_genderf = this.add.sprite(0, 0, 'thebb', 'female');
        this.createchart_genderf.removeAllListeners();
        this.createchart_genderf.setInteractive();
        this.createchart_genderf.on("pointerdown", function () { this.createchar_clickgender("F"); }, this);
        this.createchart_genderm.removeAllListeners();
        this.createchart_genderm.setInteractive();
        this.createchart_genderm.on("pointerdown", function () { this.createchar_clickgender("M"); }, this);

        this.createchart_charname = this.wh.add.text(0, 0, this.trs("f:home:Character Name===Character Name"), this.fs["loginformtext"]);

        this.createchart_name = this.wh.add.rexInputText(0, 0, 100, 25, {
            id: 'createchart_name',
            type: 'text',
            text: '',
            fontSize: '20px',
            color: '#000',
            autoComplete: 'off',
            border: 1,
            backgroundColor: '#f2f2f2',
            borderColor: '#666',
            selectAll: true,
        })
            .resize(150, 30)
            .setOrigin(0.5);
        this.createchart_preview = this.add.sprite(0, 0, 'itf-white');

        this.createchar_header = this.wh.add.text(0, 0, this.trs("f:home:Create Character===Create Character"), this.fs["pageheader"]);

        this.createchart_back = this.add.sprite(0, 0, 'thebb', 'back');
        this.createchart_create = this.add.sprite(0, 0, 'thebb', 'create');


        this.createchart_back.removeAllListeners();
        this.createchart_back.setInteractive();
        this.createchart_back.on("pointerdown", function () { this.showpage("selectchar"); }, this);


        this.createchart_create.removeAllListeners();
        this.createchart_create.setInteractive();
        this.createchart_create.on("pointerdown", function () { this.createchar("selectchar"); }, this);

        this.createcharwhite1 = this.add.sprite(400, 300, 'itf-white').setOrigin(0.5, 0);
        this.createcharwhite2 = this.add.sprite(400, 300, 'itf-white').setOrigin(0.5, 0);
        this.createcharwhite3 = this.add.sprite(400, 300, 'itf-white').setOrigin(0.5, 0);


        this.createcharcontainer.add(this.createchart_race);
        this.createcharcontainer.bringToTop(this.createchart_race);
        this.createcharcontainer.add(this.createchart_race1);
        this.createcharcontainer.bringToTop(this.createchart_race1);
        this.createcharcontainer.add(this.createchart_race2);
        this.createcharcontainer.bringToTop(this.createchart_race2);
        this.createcharcontainer.add(this.createchart_race3);
        this.createcharcontainer.bringToTop(this.createchart_race3);
        this.createcharcontainer.add(this.createchart_race4);
        this.createcharcontainer.bringToTop(this.createchart_race4);
        this.createcharcontainer.add(this.createchart_race5);
        this.createcharcontainer.bringToTop(this.createchart_race5);
        this.createcharcontainer.add(this.createchart_racename);
        this.createcharcontainer.bringToTop(this.createchart_racename);
        this.createcharcontainer.add(this.createchart_racedescr);
        this.createcharcontainer.bringToTop(this.createchart_racedescr);
        this.createcharcontainer.add(this.createchart_gender);
        this.createcharcontainer.bringToTop(this.createchart_gender);
        this.createcharcontainer.add(this.createchart_genderm);
        this.createcharcontainer.bringToTop(this.createchart_genderm);
        this.createcharcontainer.add(this.createchart_genderf);
        this.createcharcontainer.bringToTop(this.createchart_genderf);
        this.createcharcontainer.add(this.createchart_charname);
        this.createcharcontainer.bringToTop(this.createchart_charname);
        this.createcharcontainer.add(this.createchart_name);
        this.createcharcontainer.bringToTop(this.createchart_name);
        this.createcharcontainer.add(this.createchart_preview);
        this.createcharcontainer.bringToTop(this.createchart_preview);
        this.createcharcontainer.add(this.createcharwhite1);
        this.createcharcontainer.bringToTop(this.createcharwhite1);
        this.createcharcontainer.add(this.createcharwhite2);
        this.createcharcontainer.bringToTop(this.createcharwhite2);
        this.createcharcontainer.add(this.createcharwhite3);
        this.createcharcontainer.bringToTop(this.createcharwhite3);
        this.createcharcontainer.add(this.createchar_header);
        this.createcharcontainer.bringToTop(this.createchar_header);
        this.createcharcontainer.add(this.createchart_back);
        this.createcharcontainer.bringToTop(this.createchart_back);
        this.createcharcontainer.add(this.createchart_create);
        this.createcharcontainer.bringToTop(this.createchart_create);

        //offline
        this.offlinecontainer = this.add.container(0, 0);
        this.offlinecontainer.setDepth(11000);
        this.offlinecontainer.visible = false;
        //this.offline_header = this.wh.add.text(0, 0, "Offline", this.fs["pageheader"]);

        ;

        this.offline_bg = this.wh.add.image(0, 0, "itf-white");
        this.offline_bg.setPosition(0, 0);
        this.offline_bg.setOrigin(0, 0);
        this.offline_bg.setAlpha(0.8);
        this.offline_bg.visible = true;
        this.offline_bg.setInteractive();

        this.offline_msg = this.add.text(0, 0, " ", this.fs["loginformtext"]);
        this.offline_msg2 = this.add.text(0, 0, " ", this.fs["loginformtext"]);
        this.offline_msg2.removeAllListeners();
        this.offline_msg2.setInteractive();
        this.offline_msg2.on("pointerdown", function () {
            this.logout();
        }, this);
        this.offline_retry = this.add.sprite(0, 0, "theb", "reconnect");
        ;
        this.offline_header = this.wh.add.text(0, 0, " Offline ", this.fs["pageheader"]);
        ;
        this.offlinecontainer.add(this.offline_bg);
        this.offlinecontainer.bringToTop(this.offline_bg);
        this.offlinecontainer.add(this.offline_msg);
        this.offlinecontainer.bringToTop(this.offline_msg);
        this.offlinecontainer.add(this.offline_msg2);
        this.offlinecontainer.bringToTop(this.offline_msg2);
        this.offlinecontainer.add(this.offline_retry);
        this.offlinecontainer.bringToTop(this.offline_retry);
        this.offlinecontainer.add(this.offline_header);
        this.offlinecontainer.bringToTop(this.offline_header);
        this.offline_retry.removeAllListeners();
        this.offline_retry.setInteractive();
        this.offline_retry.on("pointerdown", function () {
            this.serverstatus_reconnect();
        }, this);

        //setting

        this.settingcontainer = this.add.container(0, 0);
        this.settingcontainer.setDepth(11000);
        this.settingcontainer.visible = false;
        this.settingheader = this.add.text(0, 0, this.trs("f:home:SETTINGS===SETTINGS"), this.fs["pageheader"]);
        /*this.setting_antih = this.add.text(0, 0, "Antialias", this.fs["settinghead"]);
        this.setting_anti = this.add.sprite(0, 0, "thebs", "on");
        this.setting_anti.removeAllListeners();
        this.setting_anti.setInteractive();
        this.setting_anti.on("pointerdown", function () {
            this.scene.updatesettingtoggle("antialias", this);
        });*/
        this.setting_langh = this.add.text(0, 0, "Language*", this.fs["settinghead"]);
        this.setting_lang = this.add.text(0, 0, "EN", this.fs["settinghead"]);

        for (const k in this.setting_langlist) {
            var v = this.setting_langlist[k];
            this.setting_lango[v] = this.add.sprite(0, 0, "thebs", "" + v.toLowerCase());
            this.settingcontainer.add(this.setting_lango[v]);
            this.setting_lango[v].name = v;
            this.setting_lango[v].removeAllListeners();
            this.setting_lango[v].setInteractive();
            this.setting_lango[v].on("pointerdown", function () {
                this.scene.updatesettingi("lang", this.name);
            });
        }
        this.setting_lighteffectsh = this.add.text(0, 0, this.trs("f:home:Light Effects===Light Effects"), this.fs["settinghead"]);
        this.setting_lighteffects = this.add.sprite(0, 0, "thebs", "on");
        this.setting_lighteffects.removeAllListeners();
        this.setting_lighteffects.setInteractive();
        this.setting_lighteffects.on("pointerdown", function () {
            this.scene.updatesettingtoggle("lighteffects", this);
        });

        this.setting_weathereffectsh = this.add.text(0, 0, this.trs("f:home:Weather Effects===Weather Effects"), this.fs["settinghead"]);
        this.setting_weathereffects = this.add.sprite(0, 0, "thebs", "on");
        this.setting_weathereffects.removeAllListeners();
        this.setting_weathereffects.setInteractive();
        this.setting_weathereffects.on("pointerdown", function () {
            this.scene.updatesettingtoggle("weathereffects", this);
        });

        this.setting_bgmh = this.add.text(0, 0, "BGM", this.fs["settinghead"]);
        this.setting_bgm = this.add.sprite(0, 0, "thebs", "on");
        this.setting_bgm.removeAllListeners();
        this.setting_bgm.setInteractive();
        this.setting_bgm.on("pointerdown", function () {
            this.scene.updatesettingtoggle("bgm", this);
        });


        this.setting_autoplayh = this.add.text(0, 0, "AutoStart", this.fs["settinghead"]);
        this.setting_autoplay = this.add.sprite(0, 0, "thebs", "on");
        this.setting_autoplay.removeAllListeners();
        this.setting_autoplay.setInteractive();
        this.setting_autoplay.on("pointerdown", function () {
            this.scene.updatesettingtoggle("autoplay", this);
        });


        this.setting_mincombath = this.add.text(0, 0, "Minimal Combat", this.fs["settinghead"]);
        this.setting_mincombat = this.add.sprite(0, 0, "thebs", "off");
        this.setting_mincombat.removeAllListeners();
        this.setting_mincombat.setInteractive();
        this.setting_mincombat.on("pointerdown", function () {
            this.scene.updatesettingtoggle("mincombat", this);
        });
        this.setting_badinth = this.add.text(0, 0, "Bad Internet Mode", this.fs["settinghead"]);
        this.setting_badint = this.add.sprite(0, 0, "thebs", "off");
        this.setting_badint.removeAllListeners();
        this.setting_badint.setInteractive();
        this.setting_badint.on("pointerdown", function () {
            this.scene.updatesettingtoggle("badint", this);
        });

        this.setting_soundh = this.add.text(0, 0, this.trs("f:home:Sound Volume===Sound Volume") + "", this.fs["settinghead"]);
        this.setting_sounddsp = this.add.text(0, 0, "--", this.fs["settinghead"]);
        this.setting_soundplus = this.add.sprite(0, 0, "thebs", "plussign");
        this.setting_soundminus = this.add.sprite(0, 0, "thebs", "minussign");

        this.setting_soundplus.removeAllListeners();
        this.setting_soundplus.setInteractive();
        this.setting_soundplus.on("pointerdown", function () {
            this.scene.updatesettingnum("volume", 1);
        });
        this.setting_soundminus.removeAllListeners();
        this.setting_soundminus.setInteractive();
        this.setting_soundminus.on("pointerdown", function () {
            this.scene.updatesettingnum("volume", -1);
        });

        this.setting_msg = this.add.text(0, 0, this.trs("f:home:Some setting need to restart to take effect===Some setting need to restart to take effect"), this.fs["chardetail"]);


        this.setting_back = this.add.sprite(0, 0, "thebb", "back");
        this.setting_back.removeAllListeners();
        this.setting_back.setInteractive();
        this.setting_back.on("pointerdown", function () {
            this.scene.showpage("selectchar");
        });
        this.settingcontainer.add(this.settingheader);
        // this.settingcontainer.add(this.setting_antih);
        //this.settingcontainer.add(this.setting_anti);
        this.settingcontainer.add(this.setting_langh);
        this.settingcontainer.add(this.setting_lang);
        this.settingcontainer.add(this.setting_lighteffectsh);
        this.settingcontainer.add(this.setting_lighteffects);
        this.settingcontainer.add(this.setting_weathereffectsh);
        this.settingcontainer.add(this.setting_weathereffects);
        this.settingcontainer.add(this.setting_soundh);
        this.settingcontainer.add(this.setting_sounddsp);
        this.settingcontainer.add(this.setting_soundplus);
        this.settingcontainer.add(this.setting_soundminus);
        this.settingcontainer.add(this.setting_back);
        this.settingcontainer.add(this.setting_msg);
        this.settingcontainer.add(this.setting_bgmh);
        this.settingcontainer.add(this.setting_bgm);
        this.settingcontainer.add(this.setting_autoplayh);
        this.settingcontainer.add(this.setting_autoplay);
        this.settingcontainer.add(this.setting_mincombath);
        this.settingcontainer.add(this.setting_mincombat);
        this.settingcontainer.add(this.setting_badinth);
        this.settingcontainer.add(this.setting_badint);

        //proceed session

        //check reload and play
        this.registry.list.rcloading.setText("Checking Session");

        var chklogin_l = await this.registry.list.rcstorage.get('rc_login');
        var chklogin_loggedin = await this.registry.list.rcstorage.get('loggedin');
        var chklogin_sv = await this.registry.list.rcstorage.get('rc_usesv');
        var chklogin_sid = await this.registry.list.rcstorage.get('rc_sid');

        if (chklogin_sv != undefined && chklogin_sv != '') {
            var tmpsvresult = await this.selectsv_select(chklogin_sv);
            if (tmpsvresult == "error") {
                this.showpage("offline");
                return;
            }
        } else {
            //suggest preferred server
            var mycc = this.registry.list.rcvarpass.mycc;
            var possiblecc = [];
            var defaultcc = [];
            for (const ksv in this.registry.list.thethis.rcserverlistreg) {
                var vsv = this.registry.list.thethis.rcserverlistreg[ksv];
                //this.plog.log("possiblecc i", vsv);
                if (vsv["ishidden"] == "YES") continue;
                if (vsv["isdefault"] == "YES") defaultcc.push(vsv);
                if (vsv["country"].indexOf(',' + mycc + ',') != -1)
                    possiblecc.push(vsv)

            }
            //this.plog.log("possiblecc", possiblecc);
            //this.plog.log("autoselectcode", autoselectcode);
            if (possiblecc.length != 0)
                var autoselectcode = possiblecc[0];
            if (autoselectcode == undefined)
                autoselectcode = defaultcc[0];
            await this.selectsv_select(autoselectcode["code"]);
        }
        if (
            this.registry.list.thethis.rcserverlist[chklogin_sv] != undefined &&
            chklogin_loggedin == 'yes' &&
            chklogin_sv != '' &&
            chklogin_sv != undefined
        ) {
            //check if loggedin
            this.registry.list.rcvarpass.rcserver_url =
                this.registry.list.thethis.rcserverlist[chklogin_sv]['url'];
            this.registry.list.rcvarpass.rcserver_wsurl =
                this.registry.list.thethis.rcserverlist[chklogin_sv]['wsurl'];
            this.registry.list.rcvarpass.svversionstring =
                this.registry.list.thethis.rcserverlist[chklogin_sv]['versionstring'];

            var serverstatuschk = await this.serverstatus();
            if (serverstatuschk != true) {
                //this.plog.log("Server status not online", serverstatuschk);
                return;
            }
            //this.profile_avartar=this.rcvarservice.rcserver_url+"defavatar.png";
            var cdata = await this.registry.list.rchttp.getcharacters(true);
            //this.plog.log('zzz1 cdata', cdata);
            //gamer
            var gamer = await this.registry.list.rchttp.getcommon2('gamer');
            //this.plog.log("gamer n1", gamer);
            //this.initGame();
            if (
                cdata == undefined ||
                (gamer != undefined && gamer['error'] != '' && gamer['error'] != undefined) ||
                (cdata != undefined && cdata['error'] != '' && cdata['error'] != undefined)
            ) {
                //saved login fail
                this.plog.log("ERROR: saved login fail", this);
                this.registry.list.rcvarpass.charinfo = [];
                this.registry.list.rcloading.close();
                if (this.splashlogo != undefined) {
                    try {
                        this.splashlogo.visible = false;
                    } catch (e) {
                        this.plog.log("ERROR", e);
                    }
                }
                if (this.splashbg != undefined) {
                    try {
                        this.splashbg.visible = false;
                    } catch (e) {
                        this.plog.log("ERROR", e);
                    }
                }
                this.showpage("none");
                await this.registry.list.rctoast.rcalert(this, this.trs("f:home:Failed to continue with saved login===Failed to continue with saved login"), this.trs("f:home:Failed to continue with saved login descr===If you logged in on other browser / device. Please try login again"));
                this.showpage('login');
                this.is_loggedin = false;
            } else {

                this.registry.list.anyjs.onesignal_init(chklogin_l);

                this.registry.list.rcvarpass.gamer = gamer;
                this.profile_avartar = gamer['avatar'];
                this.registry.list.rcvarpass.charinfo = cdata;
                this.is_loggedin = true;

                var cdata = await this.registry.list.rchttp.getcharacters(true);
                //this.plog.log('zzz2 cdata', cdata);
                this.registry.list.rcvarpass.charinfo = cdata;

                await this.registry.list.rcsvvars.initallvars(this);
                //this.initGame(false);
                //this.plog.log('loginstage a09');
                this.selectChar_build();
                this.showpage("selectchar");

                //chkreloadandplay =parseInt(chkreloadandplay);
                //this.plog.log('loginstage a09 chkreloadandplay', chkreloadandplay);

                /*var chkreloadandplay = await this.registry.list.rcstorage.set('reloadandplay_id');
                if (chkreloadandplay != undefined && chkreloadandplay != "" && Math.floor(chkreloadandplay) != NaN && Math.floor(chkreloadandplay) != 0) {

                    return;
                }*/
                /*xxx
                var chkreloadandplay = await this.registry.list.rcstorage.get('reloadandplay_id');
                if (chkreloadandplay != undefined && chkreloadandplay != '0') {
                    await this.registry.list.rcstorage.set('reloadandplay_id', '0');
                    this.playGame(chkreloadandplay);
                    return;
                }*/
                //this.initGame(); //show selectchar
                this.registry.list.rcloading.close();
            }
        } else {
            this.showpage("login");
        }
        this.scale.on('resize', function () {
            //this.plog.log("resize",this,PhaserMapResizeManagerService);
            //var tmp = new PhaserMapResizeManagerService();
            this.resized();
        }, this);
        this.resized();
        this.updatesettingdsp();
        //this.showpage("login");

        // Hide the splash (you should do this on app launch)
        //await SplashScreen.hide();

        this.tweens.add({
            targets: [
                this.splashlogo,
                this.splashbg
            ],
            alpha: 0,
            ease: 'Power1',
            duration: 200,
            delay: 0,
            yoyo: false,
            repeat: 0,
            onComplete: function () {
                try {
                    arguments[1][0].destroy();
                } catch (e) {
                    this.plog.log("ERROR", e);
                }
                try {
                    arguments[1][1].destroy();
                } catch (e) {
                    this.plog.log("ERROR", e);
                }
            },
        });
        this.registry.list.rcloading.close();

        this.registry.list.snd.bgm("bgm", true);
    }
    async selectsv_select(id) {
        this.showpage(this.lastpageshown);
        //console.error("selectsv_select", id, this.registry.list.thethis.rcserverlist);
        this.selectsv_close();
        if (this.registry.list.thethis.rcserverlist[id] == undefined) {
            this.showpage("none");
            this.registry.list.rcloading.close();

            if (this.splashlogo != undefined) {
                try {
                    this.splashlogo.visible = false;
                } catch (e) {
                    this.plog.log("ERROR", e);
                }
            }
            if (this.splashbg != undefined) {
                try {
                    this.splashbg.visible = false;
                } catch (e) {
                    this.plog.log("ERROR", e);
                }
            }
            await this.registry.list.rctoast.rcalert(this, this.trs("f:home:Please select server===Please select server"), 'Invalid server selected, please try again\n\nfor more information visit https://peacely.online/');
            //this.showpage(this.lastpageshown);
            this.showpage("offline");
            //this.plog.log("here");
            this.login_default_server = undefined;
            this.reg_selected_server = undefined;
            return "error";
        }
        var width = this.game.canvas.width;
        var height = this.game.canvas.height;

        this.login_default_server = id;//this.registry.list.thethis.rcserverlistreg[id]['code'];//id;
        this.reg_selected_server = id;//this.registry.list.thethis.rcserverlistreg[id]['code'];//id;
        var tmpurl = this.registry.list.thethis.rcserverlistreg[id]['url'];
        var tmpname = this.registry.list.thethis.rcserverlistreg[id]['name'];
        this.login_sv.setText(' ' + tmpname + ' ');
        this.login_sv.setPosition((width / 2) - (this.login_sv.displayWidth / 2), this.login_sv.y);
        this.reg_sv.setText(' ' + tmpname + ' ');
        this.reg_sv.setPosition((width / 2) - (this.reg_sv.displayWidth / 2), this.reg_sv.y);
        this.forgot_sv.setText(' ' + tmpname + ' ');
        this.forgot_sv.setPosition((width / 2) - (this.forgot_sv.displayWidth / 2), this.forgot_sv.y);

        //this.plog.log('onSelectChange_server', tmpurl, this);
        var res = await this.registry.list.rchttp.getcommon('serverusage', tmpurl);
        //this.plog.log('selectsv_select serverusage ', res);
        if (res['data'] != undefined) {
            this.logint_svdetail.setText(tmpname + ' Server Load ' + res['data']['percent'] + '%');
            this.logint_svdetail.setPosition((width / 2) - (this.logint_svdetail.displayWidth / 2), this.logint_svdetail.y);
        } else {
            this.logint_svdetail.setText(' ');
        }
    }
    selectsv_close() {
        this.showpage(this.lastpageshown);

        if (this.svlistbg != undefined) this.svlistbg.destroy();
        for (var i = 0; i <= 100; i++) {
            if (this.svlist[i] != undefined) this.svlist[i].destroy();
        }
        for (var i = 0; i <= 100; i++) {
            if (this.svlistdescr[i] != undefined) this.svlistdescr[i].destroy();
        }
        for (var i = 0; i <= 100; i++) {
            if (this.svlistbtn[i] != undefined) this.svlistbtn[i].destroy();
        }
        if (this.svlist_back != undefined) this.svlist_back.destroy();
        if (this.svlist_next != undefined) this.svlist_next.destroy();
    }
    selectsv() {
        //this.plog.log(this);
        this.showpage("none");
        var width = this.game.canvas.width;
        var height = this.game.canvas.height;

        if (this.svlistbg != undefined) this.svlistbg.destroy();
        for (var i = 0; i <= 100; i++) {
            if (this.svlist[i] != undefined) this.svlist[i].destroy();
        }
        for (var i = 0; i <= 100; i++) {
            if (this.svlistdescr[i] != undefined) this.svlistdescr[i].destroy();
        }
        for (var i = 0; i <= 100; i++) {
            if (this.svlistbtn[i] != undefined) this.svlistbtn[i].destroy();
        }
        this.svlist_back = this.wh.add.sprite(0, 0, "thebb", "backsign");
        this.svlist_next = this.wh.add.sprite(0, 0, "thebb", "forwardsign");
        this.svlist_back.removeAllListeners();
        this.svlist_back.setInteractive();
        this.svlist_back.on("pointerdown", function () {
            this.selectsv_showpage("back");
        }, this);
        this.svlist_next.removeAllListeners();
        this.svlist_next.setInteractive();
        this.svlist_next.on("pointerdown", function () {
            this.selectsv_showpage("next");
        }, this);
        this.svlist_back.setDepth(1500020);
        this.svlist_next.setDepth(1500020);

        this.svlistbg = this.wh.add.rectangle(0, 0, width, height, 0x000000);
        this.svlistbg.setPosition(0, 0);
        this.svlistbg.setOrigin(0, 0);
        this.svlistbg.setAlpha(0.8);
        this.svlistbg.visible = true;
        this.svlistbg.setDepth(1500000);
        this.svlistbg.setInteractive();
        var toppos = 50;
        var svitemh = 70;
        this.selectsv_perpage = Math.floor((height - ((toppos * 2) + 100)) / svitemh);
        //this.selectsv_perpage = 2;
        var svcount = 0;
        for (const k in this.registry.list.thethis.rcserverlistreg) {
            svcount++;
        }
        this.registry.list.rcsvvars.initserversdata(this.registry.list.thethis);
        this.selectsv_maxpage = Math.ceil(svcount / this.selectsv_perpage);
        var svi = 0;
        var ii = 0;
        for (const k in this.registry.list.thethis.rcserverlistreg) {
            var v = this.registry.list.thethis.rcserverlistreg[k];
            if (this.testermode == false) {
                if (v["ishidden"] == 'YES') continue;
            }
            if (v["ishidden"] == 'YES') {
                v["descr"] = '' + v["descr"] + ' (hidden)';
            }
            this.svlistbtn[svi] = this.wh.add.image(0, 0, "itf-thinbtn");
            this.svlistbtn[svi].setOrigin(0, 0);
            this.svlistbtn[svi].displayWidth = 150;
            if (v["suggested"] == "YES") {
                this.svlistbtn[svi].displayWidth = 170;
            } else {
                this.svlistbtn[svi].alpha = 0.8;
            }
            this.svlistbtn[svi].scaleY = this.svlistbtn[svi].scaleX;
            this.svlistbtn[svi].setPosition((width / 2) - (this.svlistbtn[svi].displayWidth / 2), toppos + (svitemh * ii))
            this.svlistbtn[svi].setDepth(1500001);
            this.svlistbtn[svi].name = k;
            this.svlistbtn[svi].removeAllListeners();
            this.svlistbtn[svi].setInteractive();
            this.svlistbtn[svi].on("pointerdown", function () {
                this.scene.plog.log("svlistbtn ", this);
                this.scene.selectsv_select(this.name);
            });

            this.svlist[svi] = this.wh.add.text(0, 0, " " + v["name"] + " ", this.fs["selectsvbtn"]);
            this.svlistdescr[svi] = this.wh.add.text(0, 0, " " + v["descr"] + " ", this.fs["selectsvdescr"]);

            this.svlist[svi].setPosition((width / 2) - (this.svlist[svi].displayWidth / 2), toppos + (svitemh * ii) + 15);
            this.svlist[svi].setDepth(1500002);
            this.svlistdescr[svi].setPosition((width / 2) - (this.svlistdescr[svi].displayWidth / 2), this.svlist[svi].y + this.svlist[svi].displayHeight - 3);
            this.svlistdescr[svi].setDepth(1500002);
            //this.plog.log("selectsv", v, this.svlist[svi]);

            //this.logincontainer.add(this.svlist[svi]);
            //this.logincontainer.bringToTop(this.svlist[svi]);
            svi++;
            ii++;
            if (ii >= this.selectsv_perpage) {
                ii = 0;
            }
        }
        this.svlist_back.setPosition(width / 2 - 100, height - 50);
        this.svlist_next.setPosition(width / 2 + 100, height - 50);
        this.selectsv_showpage("1st");
        // this.svlist[i] = this.wh.add.text(0, 0, " Click to Select ", this.fs["selectsv"]);
    }
    selectsv_showpage(pg) {
        if (pg == "1st") {
            this.selectsv_currentpage = 0;
        }
        if (pg == "back") {
            this.selectsv_currentpage--;
            if (this.selectsv_currentpage <= 0) this.selectsv_currentpage = 0;
        }
        if (pg == "next") {
            this.selectsv_currentpage++;
            if (this.selectsv_currentpage >= this.selectsv_maxpage - 1) this.selectsv_currentpage = this.selectsv_maxpage - 1;
        }
        //this.plog.log("selectsv_showpage", this.selectsv_currentpage);
        for (var i = 0; i <= 100; i++) {
            if (this.svlist[i] != undefined) this.svlist[i].visible = false;
        }
        for (var i = 0; i <= 100; i++) {
            if (this.svlistbtn[i] != undefined) this.svlistbtn[i].visible = false;
        }
        var fromn = this.selectsv_currentpage * this.selectsv_perpage;
        var ton = fromn + this.selectsv_perpage;
        //this.plog.log("selectsv_showpage", this.selectsv_currentpage, fromn, ton);
        for (var i = fromn; i < ton; i++) {
            if (this.svlistbtn[i] != undefined) this.svlistbtn[i].visible = true;
            if (this.svlist[i] != undefined) this.svlist[i].visible = true;
        }

    }
    async showintro() {

    }
    resized() {

        var width = this.game.canvas.width;
        var height = this.game.canvas.height;
        //this.plog.log("welcome resized", width, height, this);
        this.startsyncanimation();
        if (this.walkers["walker1"] != undefined) {
            this.walkers["walker1"].y = height - 16;
        }
        if (this.walkers["walker2"] != undefined) {
            this.walkers["walker2"].y = height - 16;
        }
        if (this.loginitfbg != undefined) {
            this.loginitfbg.setPosition(width / 2, height / 2);
            // this.loginitfbg.displayWidth = width * 0.8;
            this.loginitfbg.displayHeight = height;//* 0.8;
            this.loginitfbg.scaleX = this.loginitfbg.scaleY;
            if (this.loginitfbg.displayWidth > width)
                this.loginitfbg.displayWidth = width;
        }
        //console.log("resized",this.splashlogo)
        if (this.splashlogo != undefined) {
            this.splashlogo.setPosition(width / 2, height / 2);
            if (this.splashlogo.displayWidth > width) {
                this.splashlogo.displayWidth = width;
                this.splashlogo.scaleY = this.splashlogo.scaleY;
            }
        }



        if (this.welcomehead != undefined) {
            this.welcomehead.displayWidth = width + 5;
            this.welcomehead.displayHeight = 180;
            this.welcomehead.setPosition(0, 0);
        }

        if (this.welcomehead != undefined) {
            this.welcomehead2.displayWidth = width + 5;
            this.welcomehead2.displayHeight = 45;
            this.welcomehead2.setPosition(0, height + 5);
        }


        if (this.loadingText != undefined) {
            this.loadingText.setPosition(width / 2, height / 2 - 50);
        }

        //this.itf_bg1.setPosition(0,0); //(width / 2, height / 2);
        //this.itf_bg2.setPosition(0,0);//(width / 2, height / 2);
        //itf
        if (this.itflogo == undefined) return;
        this.itflogo.displayWidth = width;
        if (width > 1600) this.itflogo.displayWidth = 800;
        if (width > 1000) this.itflogo.displayWidth = 600;
        if (width > 700) this.itflogo.displayWidth = 600;
        this.itflogo.displayWidth = this.loginitfbg.displayWidth;
        this.itflogo.scaleY = this.itflogo.scaleX;
        this.itflogo.setPosition(width / 2, this.itflogo.displayHeight * 0.1);
        //this.appversiont.setPosition(width / 2 - this.appversiont.displayWidth / 2, height - this.appversiont.displayHeight)
        this.appversiont.setPosition(3 + this.appversiont.displayWidth / 2, height - this.appversiont.displayHeight)
        this.appversiont.setDepth(10000);

        if (this.tweentree1 != undefined) {
            this.tweentree1.stop();
            this.tweentree1.remove();
        }
        if (this.tweentree2 != undefined) {
            this.tweentree2.stop();
            this.tweentree2.remove();
        }
        if (width > height) {
            this.itftopbg.displayWidth = width + 10;
            this.itftopbg.scaleY = this.itftopbg.scaleX;
            this.itftopbg2.displayWidth = width + 10;
            this.itftopbg2.scaleY = this.itftopbg2.scaleX;
        } else {
            this.itftopbg.displayHeight = height + 10;
            this.itftopbg.scaleX = this.itftopbg.scaleY;
            this.itftopbg2.displayHeight = height + 10;
            this.itftopbg2.scaleX = this.itftopbg2.scaleY;
        }
        this.itftopbg.setPosition(width / 2, height / 2);

        this.itftopbg2.x = this.itftopbg.x;
        this.itftopbg2.y = this.itftopbg.y;

        this.startsyncanimation();
        //this.itftopbg.displayWidth = width;
        //this.itftopbg.scaleY = this.itftopbg.scaleX;
        //this.itftopbg.setPosition(0, 0 - (this.itftopbg.displayHeight * 0.4));
        /*
                this.itftreebg.displayWidth = width * 1.2;
                this.itftreebg.scaleY = this.itftreebg.scaleX;
                this.itftreebg.setPosition(0, this.itflogo.displayHeight * 1.5);
        
                this.itftreebg2.displayWidth = width * 1.4;
                this.itftreebg2.scaleY = this.itftreebg2.scaleX;
                this.itftreebg2.setPosition(0, height / 2);
        
                this.itftopbg.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);
                this.itftreebg.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);
                this.itftreebg2.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);
        
                if (width > height) {
                    this.itfbgfg.displayHeight = Math.min(width, height) * 0.7;
                    this.itfbgfg.scaleX = this.itfbgfg.scaleY;
                } else {
                    this.itfbgfg.displayWidth = Math.min(width, height) * 0.7;
                    this.itfbgfg.scaleY = this.itfbgfg.scaleX;
                }
                this.itfbgfg.setCrop(1, 1, 10000, 10000);
        
                this.itfbgfg.setPosition(0, height);
        */
        //login
        var avaih = height - (this.itflogo.displayHeight * 1.5);
        var starth = this.itflogo.displayHeight * 0.9;
        this.logint_sv.setPosition((width / 2) - (this.logint_sv.displayWidth / 2), starth + 15);
        this.login_sv.setPosition((width / 2) - (this.login_sv.displayWidth / 2), (starth + 70) - (this.login_sv.displayHeight / 2));
        this.logint_svdetail.setPosition((width / 2) - (this.logint_svdetail.displayWidth / 2), (starth + 95) - (this.logint_svdetail.displayHeight / 2));

        this.logint_u.setPosition((width / 2) - (this.logint_u.displayWidth / 2), starth + 105);
        this.login_u.setPosition(width / 2, starth + 160);
        this.logint_p.setPosition((width / 2) - (this.logint_p.displayWidth / 2), starth + 180);
        this.login_p.setPosition(width / 2, starth + 235);

        this.white1.setPosition(width / 2, this.logint_sv.y);
        this.white1.displayWidth = 300; this.white1.displayHeight = this.logint_sv.displayHeight;
        this.white1.setAlpha(0.9);
        this.white2.setPosition(width / 2, this.logint_u.y);
        this.white2.displayWidth = 300; this.white2.displayHeight = this.logint_u.displayHeight;
        this.white2.setAlpha(0.75);
        this.white3.setPosition(width / 2, this.logint_p.y);
        this.white3.displayWidth = 300; this.white3.displayHeight = this.logint_p.displayHeight;
        this.white3.setAlpha(0.75);
        this.loginb_login.setPosition((width / 2) - 80, starth + 290);
        this.loginb_reg.setPosition((width / 2) + 80, starth + 290);

        var starth2 = starth + 305;
        var btnrowh = 35; var btnrowi = 0;
        this.loginb_login.displayWidth = 150; this.loginb_login.scaleY = this.loginb_login.scaleX;
        this.loginb_reg.displayWidth = 150; this.loginb_reg.scaleY = this.loginb_reg.scaleX;

        this.loginb_forgot.displayWidth = 150; this.loginb_forgot.scaleY = this.loginb_forgot.scaleX;
        this.loginb_guest.displayWidth = 300; this.loginb_guest.scaleY = this.loginb_guest.scaleX;
        this.loginb_fb.displayWidth = 150; this.loginb_fb.scaleY = this.loginb_fb.scaleX;
        this.loginb_gg.displayWidth = 150; this.loginb_gg.scaleY = this.loginb_gg.scaleX;
        this.loginb_tw.displayWidth = 150; this.loginb_tw.scaleY = this.loginb_tw.scaleX;


        btnrowi++;
        this.loginb_guest.setPosition(width / 2, starth2 + (btnrowi * btnrowh) + btnrowh * 0.5);
        btnrowi++;
        btnrowi++; this.loginb_forgot.setPosition(width / 2, starth2 + (btnrowi * btnrowh));
        btnrowi++; this.loginb_fb.setPosition((width / 2) - 80, starth2 + (btnrowi * btnrowh));
        this.loginb_gg.setPosition((width / 2) + 80, starth2 + (btnrowi * btnrowh));
        btnrowi++; this.loginb_tw.setPosition(width / 2, starth2 + (btnrowi * btnrowh));

        //reg

        var avaih = height - (this.itflogo.displayHeight * 1.5);
        var starth = this.itflogo.displayHeight * 1.2;
        this.regt_sv.setPosition((width / 2) - (this.regt_sv.displayWidth / 2), starth + 15);
        this.reg_sv.setPosition((width / 2) - (this.reg_sv.displayWidth / 2), (starth + 70) - (this.reg_sv.displayHeight / 2));
        this.regt_svdetail.setPosition((width / 2) - (this.regt_svdetail.displayWidth / 2), (starth + 95) - (this.regt_svdetail.displayHeight / 2));

        this.regt_u.setPosition((width / 2) - (this.regt_u.displayWidth / 2), starth + 105);
        this.reg_u.setPosition(width / 2, starth + 160);
        this.regt_p.setPosition((width / 2) - (this.regt_p.displayWidth / 2), starth + 180);
        this.reg_p.setPosition(width / 2, starth + 235);

        this.regwhite1.setPosition(width / 2, this.regt_sv.y);
        this.regwhite1.displayWidth = 300; this.regwhite1.displayHeight = this.regt_sv.displayHeight;
        this.regwhite1.setAlpha(0.9);
        this.regwhite2.setPosition(width / 2, this.regt_u.y);
        this.regwhite2.displayWidth = 300; this.regwhite2.displayHeight = this.regt_u.displayHeight;
        this.regwhite2.setAlpha(0.75);
        this.regwhite3.setPosition(width / 2, this.regt_p.y);
        this.regwhite3.displayWidth = 300; this.regwhite3.displayHeight = this.regt_p.displayHeight;
        this.regwhite3.setAlpha(0.75);
        this.regb_back.displayWidth = 150; this.regb_back.scaleY = this.regb_back.scaleX;
        this.regb_reg.displayWidth = 150; this.regb_reg.scaleY = this.regb_reg.scaleX;

        this.regb_back.setPosition((width / 2) - 80, starth + 290);
        this.regb_reg.setPosition((width / 2) + 80, starth + 290);

        this.reg_header.setPosition((width / 2) - (this.reg_header.displayWidth / 2), this.itflogo.y);


        //forgot

        var avaih = height - (this.itflogo.displayHeight * 1.5);
        var starth = this.itflogo.displayHeight * 1.2;
        this.forgott_sv.setPosition((width / 2) - (this.forgott_sv.displayWidth / 2), starth + 15);
        this.forgot_sv.setPosition((width / 2) - (this.forgot_sv.displayWidth / 2), (starth + 70) - (this.forgot_sv.displayHeight / 2));
        this.forgott_svdetail.setPosition((width / 2) - (this.forgott_svdetail.displayWidth / 2), (starth + 95) - (this.forgott_svdetail.displayHeight / 2));

        this.forgott_u.setPosition((width / 2) - (this.forgott_u.displayWidth / 2), starth + 105);
        this.forgot_u.setPosition(width / 2, starth + 160);
        this.forgotb_sendcode.setPosition(width / 2, starth + 190);
        this.forgott_p.setPosition((width / 2) - (this.forgott_p.displayWidth / 2), starth + 215);
        this.forgot_p.setPosition(width / 2, starth + 270);
        this.forgott_np.setPosition((width / 2) - (this.forgott_np.displayWidth / 2), starth + 290);
        this.forgot_np.setPosition(width / 2, starth + 340);



        this.forgotwhite1.setPosition(width / 2, this.forgott_sv.y);
        this.forgotwhite1.displayWidth = 300; this.forgotwhite1.displayHeight = this.forgott_sv.displayHeight;
        this.forgotwhite1.setAlpha(0.9);
        this.forgotwhite2.setPosition(width / 2, this.forgott_u.y);
        this.forgotwhite2.displayWidth = 300; this.forgotwhite2.displayHeight = this.forgott_u.displayHeight;
        this.forgotwhite2.setAlpha(0.75);
        this.forgotwhite3.setPosition(width / 2, this.forgott_p.y);
        this.forgotwhite3.displayWidth = 300; this.forgotwhite3.displayHeight = this.forgott_p.displayHeight;
        this.forgotwhite3.setAlpha(0.75);

        this.forgotb_back.setPosition((width / 2) - 80, starth + 400);
        this.forgotb_reg.setPosition((width / 2) + 80, starth + 400);
        this.forgotb_back.displayWidth = 150; this.forgotb_back.scaleY = this.forgotb_back.scaleX;
        this.forgotb_reg.displayWidth = 150; this.forgotb_reg.scaleY = this.forgotb_reg.scaleX;

        this.forgot_header.setPosition((width / 2) - (this.forgot_header.displayWidth / 2), this.itflogo.y);

        //selectchar//////////////////////////////////
        if (this.selectchar_header != undefined) {
            //this.plog.log("resized, selectchar", this.selectchar_header)
            this.selectchar_header.setPosition((width / 2) - (this.selectchar_header.displayWidth / 2), this.itflogo.y);
            this.selectchar_charslot.setPosition((width / 2) - (this.selectchar_charslot.displayWidth / 2), this.selectchar_header.y + 50);
            this.selectchar_msg.setPosition((width / 2) - (this.selectchar_msg.displayWidth / 2), height / 2 - 60);
            var sicony = this.selectchar_charslot.y + this.selectchar_charslot.displayHeight * 2 + 20;
            var siconw = width / this.selectchar_cicon.length;
            if (siconw > 70) siconw = 70;
            var siconstart = (width / 2) - (((this.selectchar_cicon.length - 1) * siconw) / 2);
            var ki = 0;

            for (const k in this.selectchar_cicon) {
                this.selectchar_cicons[k].displayWidth = 32;
                this.selectchar_cicons[k].displayHeight = 32;
                this.selectchar_ciconsbg[k].displayWidth = 32;
                this.selectchar_ciconsbg[k].displayHeight = 32;

                this.selectchar_cicons[k].setPosition(siconstart + (siconw * ki), sicony);
                this.selectchar_ciconsbg[k].setPosition(this.selectchar_cicons[k].x, this.selectchar_cicons[k].y - 6);
                //this.plog.log("sicon ", this.selectchar_cicons[k], this.selectchar_cicons[k].x, this.selectchar_cicons[k].y)
                ki++;
                this.selectchar_cicon[k].displayWidth = 128;
                this.selectchar_cicon[k].displayHeight = 128;
                this.selectchar_cicon[k].setPosition(width / 2, height / 2 + 35);
                this.selectchar_cname[k].setPosition((width / 2) - (this.selectchar_cname[k].displayWidth / 2), (height / 2) - (this.selectchar_cicon[k].displayHeight / 2) - 25);
                this.selectchar_detail1[k].setPosition((width / 2) - (this.selectchar_detail1[k].displayWidth / 2), (height / 2) + (this.selectchar_cicon[k].displayHeight / 2) + 15);
                this.selectchar_detail2[k].setPosition((width / 2) - (this.selectchar_detail2[k].displayWidth / 2), (height / 2) + (this.selectchar_cicon[k].displayHeight / 2) + 40);
                this.selectchar_playbtn[k].setPosition((width / 2), (height / 2) + (this.selectchar_cicon[k].displayHeight / 2) + 80);
                this.selectchar_delbtn[k].setPosition((width / 2), (height / 2) + (this.selectchar_cicon[k].displayHeight / 2) + 110);
            }
            if (this.selectchar_cicons[this.selectchar_pageno] != undefined) {
                this.astring.clear();
                this.astring.lineStyle(10, 0xdddddd, 0.9);
                this.astring.lineBetween(
                    width / 2,
                    height / 2,
                    this.selectchar_cicons[this.selectchar_pageno].x,
                    this.selectchar_cicons[this.selectchar_pageno].y
                );
            }
            this.selectchar_back.setPosition((width / 2) - 100, height / 2 + 50);
            this.selectchar_fwd.setPosition((width / 2) + 100, height / 2 + 50);
            this.selectchar_create.setPosition((width / 2), height / 2 + 220);
        }

        //createchar
        this.createchar_header.setPosition((width / 2) - (this.createchar_header.displayWidth / 2), this.itflogo.y);

        var avaih = height - (this.itflogo.displayHeight * 1.5);
        var starth = this.itflogo.displayHeight * 1.2;
        starth = 45;
        this.createchart_race.setPosition((width / 2) - (this.createchart_race.displayWidth / 2), starth + 15);
        this.createchart_race1.displayWidth = this.createchart_race1.displayHeight = 48;
        this.createchart_race2.displayWidth = this.createchart_race2.displayHeight = 48;
        this.createchart_race3.displayWidth = this.createchart_race3.displayHeight = 48;
        this.createchart_race4.displayWidth = this.createchart_race4.displayHeight = 48;
        this.createchart_race5.displayWidth = this.createchart_race5.displayHeight = 48;
        this.createchart_race1.setPosition((width / 2) - 52 * 2, (starth + 80));
        this.createchart_race2.setPosition((width / 2) - 52 * 1, (starth + 80));
        this.createchart_race3.setPosition((width / 2) - 52 * 0, (starth + 80));
        this.createchart_race4.setPosition((width / 2) + 52 * 1, (starth + 80));
        this.createchart_race5.setPosition((width / 2) + 52 * 2, (starth + 80));

        this.createchart_racename.setPosition(this.createchart_race1.x - this.createchart_race1.displayWidth / 2, (starth + 110));
        this.createchart_racedescr.setPosition(this.createchart_race1.x - this.createchart_race1.displayWidth / 2, (starth + 130));
        starth += 10;
        this.createchart_gender.setPosition(width / 2 - this.createchart_gender.displayWidth / 2, (starth + 150));
        this.createchart_genderm.setPosition(width / 2 - 60, (starth + 210));
        this.createchart_genderf.setPosition(width / 2 + 60, (starth + 210));

        this.createchart_charname.setPosition(width / 2 - this.createchart_charname.displayWidth / 2, (starth + 235));
        this.createchart_name.setPosition(width / 2, (starth + 290));

        this.createchart_back.setPosition(width / 2 - 60, (starth + 340));
        this.createchart_create.setPosition(width / 2 + 60, (starth + 340));

        this.createchart_preview.setPosition(width / 2, (starth + 450));
        this.createchart_preview.setScale(2);

        this.curlangbtn.displayWidth = 64;
        this.curlangbtn.scaleY = this.curlangbtn.scaleX;
        this.curlangbtn.setPosition(width - this.curlangbtn.displayWidth / 2 + 4, height - (this.curlangbtn.displayHeight / 2) - (this.settingbtn.displayHeight * 5) - 10);
        this.settingbtn.setPosition(width - this.settingbtn.displayWidth / 2 + 10, height - (this.settingbtn.displayHeight / 2) - (this.settingbtn.displayHeight * 4) - 10);
        this.sociallinkbtn.setPosition(width - this.sociallinkbtn.displayWidth / 2 + 10, height - (this.sociallinkbtn.displayHeight / 2) - (this.aboutbtn.displayHeight * 3) - 10);
        this.logoutbtn.setPosition(width - this.logoutbtn.displayWidth / 2 + 10, height - (this.logoutbtn.displayHeight / 2) - (this.aboutbtn.displayHeight * 2) - 10);
        this.bugreportbtn.setPosition(width - this.bugreportbtn.displayWidth / 2 + 10, height - (this.bugreportbtn.displayHeight / 2) - (this.bugreportbtn.displayHeight * 1) - 10);
        this.aboutbtn.setPosition(width - this.aboutbtn.displayWidth / 2 + 10, height - this.aboutbtn.displayHeight / 2 - 10);



        //offline
        this.offline_header.setPosition((width / 2) - (this.offline_header.displayWidth / 2), this.itflogo.y);
        this.offline_msg.setPosition(width / 2 - this.offline_msg.displayWidth / 2, height / 2);
        this.offline_retry.setPosition(width / 2, this.offline_msg.y + (this.offline_msg.displayHeight) + 50);
        this.offline_msg2.setPosition(width / 2 - this.offline_msg2.displayWidth / 2, this.offline_retry.y + 20);
        this.offline_bg.displayWidth = width;
        this.offline_bg.displayHeight = height;

        //settings
        this.settingheader.setPosition((width / 2) - (this.settingheader.displayWidth / 2), this.itflogo.y);
        var starth = 100;
        this.setting_langh.setPosition(width / 2 - 150, starth + 0);
        this.setting_lang.setPosition(width / 2 + 110, starth + 0);
        var i = 0;
        var langperline = 6;
        var langline = 0;
        for (var k in this.setting_langlist) {
            var v = this.setting_langlist[k];
            i++;
            this.setting_lango[v].setPosition(width / 2 - 150 + (i * 44), starth + 50 + (22 * langline));
            if (i % langperline == 0) {
                langline++;
                i = 0;
            }
        }
        starth = starth + (22 * (langline - 1));
        //this.setting_antih.setPosition(width / 2 - 150, starth + 60);
        //this.setting_anti.setPosition(width / 2 + 130, 20 + starth + 60);

        this.setting_autoplayh.setPosition(width / 2 - 150, starth + 60);
        this.setting_autoplay.setPosition(width / 2 + 130, 20 + starth + 60);
        this.setting_lighteffectsh.setPosition(width / 2 - 150, starth + 90);
        this.setting_lighteffects.setPosition(width / 2 + 130, 20 + starth + 90);
        this.setting_weathereffectsh.setPosition(width / 2 - 150, starth + 120);
        this.setting_weathereffects.setPosition(width / 2 + 130, 20 + starth + 120);
        this.setting_soundh.setPosition(width / 2 - 150, starth + 150);
        this.setting_sounddsp.setPosition(width / 2 + 83, starth + 148);
        this.setting_soundminus.setPosition(width / 2 + 50, 20 + starth + 150);
        this.setting_soundplus.setPosition(width / 2 + 130, 20 + starth + 150);
        this.setting_bgmh.setPosition(width / 2 - 150, starth + 180);
        this.setting_bgm.setPosition(width / 2 + 130, 20 + starth + 180);
        this.setting_mincombath.setPosition(width / 2 - 150, starth + 210);
        this.setting_mincombat.setPosition(width / 2 + 130, 20 + starth + 210);
        this.setting_badinth.setPosition(width / 2 - 150, starth + 240);
        this.setting_badint.setPosition(width / 2 + 130, 20 + starth + 240);
        this.setting_back.setPosition(width / 2, 20 + starth + 300);
        this.setting_msg.setPosition(width / 2 - (this.setting_msg.displayWidth / 2), 20 + starth + 340);
    }

    startsyncanimation() {

        if (this.itftopbg != undefined) {
            //console.log("startsyncanimation pass");

            if (this.tweentree1 != undefined) {
                try {
                    this.tweentree1.remove();
                } catch (e) {
                    console.log(e);
                }
            }
            this.tweentree1 = this.tweens.add({
                targets: [
                    this.itftopbg
                ],
                y: { from: this.itftopbg.y, to: this.itftopbg.y + 4 },
                ease: 'Power1',
                duration: 5000,
                delay: 0,
                yoyo: true,
                repeat: -1
            });
            if (this.tweentree2 != undefined) {
                try {
                    this.tweentree2.remove();
                } catch (e) {
                    console.log(e);
                }
            }
            this.tweentree2 = this.tweens.add({
                targets: [
                    this.itftopbg
                ],
                x: { from: this.itftopbg.x, to: this.itftopbg.x + 5 },
                ease: 'Power1',
                duration: 7000,
                delay: 0,
                yoyo: true,
                repeat: -1,
                onYoyo: async function () {
                    //console.log("tweentree2 complete", this);
                    this.currentimage++;

                    var width = this.game.canvas.width;
                    var height = this.game.canvas.height;

                    if (this.currentimage > this.currentimagemax) this.currentimage = 1;
                    this.tweens.add({
                        targets: [
                            this.itftopbg2
                        ],
                        alpha: { from: 1, to: 0 },
                        ease: 'Power1', duration: 500, delay: 0,
                        onComplete: function () {
                            this.itftopbg2.setTexture("loadingbg" + this.currentimage);
                            this.itftopbg2.setPosition(width / 2, height / 2);

                            if (width > height) {
                                this.itftopbg2.displayWidth = width + 10;
                                this.itftopbg2.scaleY = this.itftopbg2.scaleX;

                                //for effect
                                this.itftopbg2.y = (height / 2) + ((this.itftopbg2.displayHeight - height) / 2);
                                this.tweens.add({
                                    targets: [
                                        this.itftopbg2
                                    ],
                                    y: (height / 2) - ((this.itftopbg2.displayHeight - height) / 2),
                                    ease: 'Linear', duration: 10000, delay: 0,
                                });
                            } else {
                                this.itftopbg2.displayHeight = height + 10;
                                this.itftopbg2.scaleX = this.itftopbg2.scaleY;
                                //for effect
                                this.itftopbg2.x = (width / 2) + ((this.itftopbg2.displayWidth - width) / 2);
                                this.tweens.add({
                                    targets: [
                                        this.itftopbg2
                                    ],
                                    x: (width / 2) - ((this.itftopbg2.displayWidth - width) / 2),
                                    ease: 'Linear', duration: 10000, delay: 0,
                                });
                            }
                            this.tweens.add({
                                targets: [
                                    this.itftopbg2
                                ],
                                alpha: { from: 0, to: 1 },
                                ease: 'Power1', duration: 500, delay: 0,
                            });
                        }.bind(this)
                    });

                    //await this.registry.list.rctoast.sleep(7000);


                    // await this.registry.list.rctoast.sleep(500);

                }.bind(this)
            });
        }

        /*
                if (this.itftreebg != undefined) {
                    this.tweentree1 = this.tweens.add({
                        targets: [
                            this.itftreebg
                        ],
                        y: this.itftreebg.y + 4,
                        ease: 'Power1',
                        duration: 5000,
                        delay: 0,
                        yoyo: true,
                        repeat: -1
                    });
                }
          */
        /*
         if (this.itftreebg2 != undefined) {
 
             this.tweentree2 = this.tweens.add({
                 targets: [
                     this.itftreebg2
                 ],
                 y: this.itftreebg2.y + 10,
                 ease: 'Power1',
                 duration: 5000,
                 delay: 0,
                 yoyo: true,
                 repeat: -1
             });
         }*/

    }
    update() {
        //this.plog.log("preloader update()",this);
        /*if (this.itf_bg1 != undefined)
            this.itf_bg1.tilePositionX += 0.2;
        if (this.itf_bg2 != undefined)
            this.itf_bg2.tilePositionX += 0.2;
*/
    }
    async register() {
        this.registry.list.snd.snd("btn3");
        this.plog.log("register");

        if (this.reg_selected_server == undefined || this.reg_selected_server == '') {
            //this.plog.log('no sv');
            this.registry.list.rcloading.close();
            this.showpage("none");

            if (this.splashlogo != undefined) {
                try {
                    this.splashlogo.visible = false;
                } catch (e) {
                    this.plog.log("ERROR", e);
                }
            }
            if (this.splashbg != undefined) {
                try {
                    this.splashbg.visible = false;
                } catch (e) {
                    this.plog.log("ERROR", e);
                }
            }
            await this.registry.list.rctoast.rcalert(this, this.trs("f:home:Please select server===Please select server"), 'Select server by clicking [select server]');
            this.showpage('reg');
            return;
        }
        this.registry.list.rcvarpass.rcserver_url =
            this.registry.list.thethis.rcserverlistreg[this.reg_selected_server]['url'];
        this.registry.list.rcvarpass.rcserver_wsurl =
            this.registry.list.thethis.rcserverlistreg[this.reg_selected_server]['wsurl'];
        this.registry.list.rcvarpass.svversionstring =
            this.registry.list.thethis.rcserverlistreg[this.reg_selected_server]['versionstring'];

        //login_c_server=login_c_server.code;
        //this.plog.log("login_c_server code", login_c_server);
        var register_c_login;
        register_c_login = this.reg_u.text;
        //this.plog.log('registerc_login', register_c_login);
        if (register_c_login == undefined || register_c_login == '') {
            this.registry.list.rcloading.close();
            this.showpage("none");
            await this.registry.list.rctoast.rcalert(this, 'Please enter valid email', 'If the email is invalid, you won\'t be able to recover your account');
            this.showpage('reg');
            return;
        }
        var emailchk = this.EmailValidation(register_c_login);
        //this.plog.log('emailchk', emailchk);
        if (emailchk != true) {
            this.registry.list.rcloading.close();
            this.showpage("none");
            await this.registry.list.rctoast.rcalert(this, 'Please enter valid email', 'If the email is invalid, you won\'t be able to recover your account');
            this.showpage('reg');
            return;
        }
        var register_c_pwd;
        register_c_pwd = this.reg_p.text;
        if (register_c_pwd == undefined || register_c_pwd == '') {
            this.registry.list.rcloading.close();
            this.showpage("none");
            await this.registry.list.rctoast.rcalert(this, 'Please enter password', 'Password field can\'t be empty');
            this.showpage('reg');
            return;
        }
        /*
        var register_c_agree;
        register_c_agree = document.getElementById('register_agree');
        //this.plog.log("register_c_agree", register_c_agree);
        var register_c_agree = register_c_agree.checked;
        //this.plog.log("register_c_agree checked", register_c_agree);
        if (register_c_agree != true) {
             //return;
        }*/
        var sitecookies = document.cookie.toString() ? document.cookie.toString() : "";
        var postdat1 = {
            server: this.registry.list.thethis.rcserverlistreg[this.reg_selected_server]['code'],
            email: register_c_login,
            sitecookies: sitecookies,
            password: register_c_pwd,
        };
        //var xxx = await this.authserv.createUser(postdat1);
        //this.plog.log('createUser', xxx);
        //return;
        var sv;
        var postdat;
        postdat = {
            server: this.registry.list.thethis.rcserverlistreg[this.reg_selected_server]['code'],
            email: register_c_login,
            sitecookies: sitecookies,
            pwd: register_c_pwd,
        };

        sv = await this.registry.list.rchttp.register(postdat);
        //this.plog.log('regresult:', sv);
        if (sv['msg'] == 'ok') {
            this.registry.list.rcloading.close();

            await this.registry.list.rcstorage.set('remember_server', this.reg_selected_server);
            await this.registry.list.rcstorage.set('remember_email', register_c_login);
            await this.registry.list.rcstorage.set('remember_pwd', register_c_pwd);
            this.login_default_server = this.reg_selected_server;
            this.login_default_email = register_c_login;
            this.showpage("none");
            await this.registry.list.rctoast.rcalert(this, 'Welcome!, registration complete', 'Login to continue');
            this.showpage('login');
            this.login_u.text = this.reg_u.text;
            this.login_p.text = this.reg_p.text;
            this.reg_u.text = "";
            this.reg_p.text = "";
        } else {
        }
    }
    async forgot_sendcode() {
        this.registry.list.snd.snd("btn2");
        var va = this.forgot_u.text;
        //this.plog.log('forgot_sendcode', va);
        this.registry.list.rcloading.loading2(this, false, false);
        var fgserver;
        fgserver = this.forgot_sv.text;
        if (fgserver == undefined || fgserver == '' || this.reg_selected_server == undefined || this.reg_selected_server == '') {
            //this.plog.log('no sv');
            this.registry.list.rcloading.close();
            this.showpage("none");

            if (this.splashlogo != undefined) {
                try {
                    this.splashlogo.visible = false;
                } catch (e) {
                    this.plog.log("ERROR", e);
                }
            }
            if (this.splashbg != undefined) {
                try {
                    this.splashbg.visible = false;
                } catch (e) {
                    this.plog.log("ERROR", e);
                }
            }
            await this.registry.list.rctoast.rcalert(this, this.trs("f:home:Please select server===Please select server"), 'Select server by clicking [select server]');
            this.showpage('forgot');
            return;
        }

        if (va == undefined || va == '') {
            this.registry.list.rcloading.close();
            this.showpage("none");
            await this.registry.list.rctoast.rcalert(this, 'Please enter a valid email', 'If the email is invalid, you won\'t be able to recover your account');
            this.showpage('forgot');
            return; this.reg_selected_server
        }
        var emailchk = this.EmailValidation(va);
        //this.plog.log('emailchk', emailchk);
        if (emailchk != true) {
            this.registry.list.rcloading.close();
            this.showpage("none");
            await this.registry.list.rctoast.rcalert(this, 'Please enter a valid email', 'If the email is invalid, you won\'t be able to recover your account');
            this.showpage('forgot'); return;
        }
        var forceurl = this.registry.list.thethis.rcserverlistreg[this.reg_selected_server]['url'];

        var chk = await this.registry.list.rchttp.getcommon2(
            'forgotpassword',
            {
                step: 'enteremail',
                email: va,
                server: this.reg_selected_server,
            },
            forceurl
        );
        //this.plog.log('forgotpassword_btn chk', chk);
        if (chk['status'] != 'ok') {
            this.registry.list.rcloading.close();
            this.showpage("none");
            await this.registry.list.rctoast.rcalert(this, 'Something went wrong', chk['msg']);
            this.showpage('forgot');
        } else {
            this.registry.list.rcloading.close();
            this.resetpassword_gamerid = chk['gamerid'];

            this.registry.list.rcloading.close();
            this.showpage("none");
            await this.registry.list.rctoast.rcalert(this, 'Email Sent', 'Please check your email\n\nand enter the code from the email in the box below\n' +
                chk['msg']);
            this.showpage('login');
        }
    }
    async forgot_entercode() {
        this.registry.list.snd.snd("btn2");
        var va = this.forgot_p.text;
        var np = this.forgot_np.text;
        //this.plog.log('forgot_entercode', va);
        this.registry.list.rcloading.loading2(this, false, false);
        var fgserver;
        fgserver = this.forgot_sv.text;
        if (fgserver == undefined || fgserver == '' || this.reg_selected_server == undefined || this.reg_selected_server == '') {
            this.plog.log('no sv');
            this.registry.list.rcloading.close();

            if (this.splashlogo != undefined) {
                try {
                    this.splashlogo.visible = false;
                } catch (e) {
                    this.plog.log("ERROR", e);
                }
            }
            if (this.splashbg != undefined) {
                try {
                    this.splashbg.visible = false;
                } catch (e) {
                    this.plog.log("ERROR", e);
                }
            }
            this.registry.list.rctoast.alert(this.trs("f:home:Please select server===Please select server"), 'Form error', 'Select server by clicking [select server]');
            return;
        }
        if (va == undefined || va == '') {
            this.plog.log('no sv');
            this.registry.list.rcloading.close();
            this.registry.list.rctoast.alert('Please enter code', 'Form error', 'Please enter code you got from the email');
            return;
        }
        if (np == undefined || np == '') {
            this.plog.log('no np');
            this.registry.list.rcloading.close();
            this.registry.list.rctoast.alert('Please enter new password', 'Form error', 'Please enter new password you want to use');
            return;
        }
        var forceurl = this.registry.list.thethis.rcserverlistreg[this.reg_selected_server]['url'];

        var chk = await this.registry.list.rchttp.getcommon2(
            'forgotpassword',
            {
                step: 'entercode',
                code: va,
                server: this.reg_selected_server,
                gamerid: this.resetpassword_gamerid,
            },
            forceurl
        );
        //this.plog.log('forgotpassword2_btn chk', chk);
        if (chk['status'] != 'ok') {
            this.registry.list.rcloading.close();
            await this.registry.list.rctoast.alert('Error', 'Something went wrong', chk['msg']);
        } else {
            this.registry.list.rcloading.close();
            //await this.registry.list.rctoast.alert(                'Code Accepted',                'Please set new password',                chk['msg']            );
            var chk = await this.registry.list.rchttp.getcommon2(
                'forgotpassword',
                {
                    step: 'setnew',
                    np: np,
                    code: va,
                    server: fgserver,
                    gamerid: this.resetpassword_gamerid,
                },
                forceurl
            );
            //this.plog.log('forgotpassword3_btn chk', chk);
            if (chk['status'] != 'ok') {
                this.registry.list.rcloading.close();
                await this.registry.list.rctoast.alert('Error', 'Something went wrong', chk['msg']);
            } else {
                this.registry.list.rcloading.close();
                await this.registry.list.rctoast.alert(
                    'Reset password done',
                    'Please try login with new password',
                    chk['msg']
                );
                this.showpage('login');
            }
            //this.showpage('login');
        }
    }
    async login() {
        this.registry.list.snd.snd("btn2");
        //this.plog.log("login func");
        var u = this.login_u.text;
        var p = this.login_p.text;



        this.registry.list.rcloading.loading2(this, false, false);

        if (this.reg_selected_server == undefined || this.reg_selected_server == '') {
            this.plog.log('no sv');
            await this.registry.list.rcloading.close();
            this.showpage("none");

            if (this.splashlogo != undefined) {
                try {
                    this.splashlogo.visible = false;
                } catch (e) {
                    this.plog.log("ERROR", e);
                }
            }
            if (this.splashbg != undefined) {
                try {
                    this.splashbg.visible = false;
                } catch (e) {
                    this.plog.log("ERROR", e);
                }
            }
            await this.registry.list.rctoast.rcalert(this, this.trs("f:home:Please select server===Please select server"), this.trs("f:home:Select server by clicking===Select server by clicking [select server]\n\n then click to select server") + '\n\n');
            this.showpage("login");
            //this.registry.list.rctoast.alert('Please select server', 'Form error', 'Select server by clicking [select server]<BR><BR>\n\n then click to select server<BR><BR>\n\n');
            return;
        }
        //login_c_server=login_c_server.code;
        //this.plog.log("login_c_server code", login_c_server);

        if (u == undefined || u == '') {
            this.registry.list.rcloading.close();
            this.showpage("none");
            await this.registry.list.rctoast.rcalert(this, this.trs("f:home:Please enter email===Please enter email"), this.trs("f:home:Please enter email===Please enter email"));
            this.showpage("login");
            return;
        }

        if (p == undefined || p == '') {
            this.registry.list.rcloading.close();
            this.showpage("none");
            await this.registry.list.rctoast.rcalert(this, this.trs("f:home:Please enter password===Please enter password"), this.trs("f:home:Password can't be empty===Password can't be empty"));
            this.showpage("login");
            return;
        }

        // if (rememberme == true) {
        await this.registry.list.rcstorage.set('remember_server', this.reg_selected_server);
        await this.registry.list.rcstorage.set('remember_email', u);
        //this.rcstorage.set("remember_pwd", login_c_pwd);
        await this.registry.list.rcstorage.listall();
        //}
        var sv;
        var postdat;
        postdat = {
            server: this.reg_selected_server,
            email: u,
            pwd: p,
        };
        this.showpage("none");
        this.registry.list.rcloading.loading2text("Logging in...");

        sv = await this.registry.list.rchttp.login(this.reg_selected_server, postdat);
        //this.plog.log("login result:", sv);
        if (sv['msg'] == 'ok') {
            this.plog.log('login success', sv);
            await this.registry.list.rcstorage.set('rc_usesv', this.reg_selected_server);
            this.is_loggedin = true;
            await this.registry.list.rcstorage.set('setting_lang', "EN");
            //set default sv and url
            this.registry.list.rcvarpass.rcserver_url =
                this.registry.list.thethis.rcserverlist[this.reg_selected_server]['url'];
            this.registry.list.rcvarpass.rcserver_wsurl =
                this.registry.list.thethis.rcserverlist[this.reg_selected_server]['wsurl'];
            this.registry.list.rcvarpass.svversionstring =
                this.registry.list.thethis.rcserverlist[this.reg_selected_server]['versionstring'];
            this.registry.list.rcloading.loading2text(
                this.trs("f:home:Preparing stage===Preparing stage"));

            await this.registry.list.rcsvvars.initallvars(this);

            await this.registry.list.rcstorage.set('rc_login', u);
            await this.registry.list.rcstorage.set('rc_sid', sv['rcsid']);
            await this.registry.list.rcstorage.set('loggedin', 'yes');
            this.is_loggedin = true;
            this.registry.list.rcvarpass.rc_sid = sv['rcsid'];

            var gamer = await this.registry.list.rchttp.getcommon2('gamer');
            this.registry.list.rcvarpass.gamer = gamer;

            this.registry.list.rcloading.loading2text("Getting Characters");

            var cdata = await this.registry.list.rchttp.getcharacters(true);
            //this.plog.log('zzz2 cdata', cdata);
            this.registry.list.rcvarpass.charinfo = cdata;
            //this.plog.log("zzz on login ",RCVarsService.rcserver_url);
            //this.plog.log("int vars:", RCVarsService.rc_sid, RCVarsService.rcserverurl);
            this.selectChar_build();
            this.showpage("selectchar");
            //this.rcloading.close();
        } else {
            this.showpage("none");
            await this.registry.list.rctoast.rcalert(this, 'Something went wrong', sv['error']);
            this.showpage("login");
        }
        this.registry.list.rcloading.close();
    }

    async showpage(id) {
        //console.log("showpage",id);
        this.registry.list.snd.snd("btn2");
        this.registry.list.ga.se2("welcome", id);
        if (id != "none")
            this.lastpageshown = id;
        if (this.lastpageshown == undefined) this.lastpageshown = "login";
        this.logoutbtn.visible = false;
        this.sociallinkbtn.visible = false;
        this.settingbtn.visible = false;
        this.curlangbtn.visible = false;

        //this.plog.log("showpage()", id, this);
        if (id == "none") {
            this.logincontainer.visible = false;
            this.selectcharcontainer.visible = false;
            this.regcontainer.visible = false;
            this.forgotcontainer.visible = false;
            this.createcharcontainer.visible = false;
            this.sociallinkcontainer.visible = false;
            this.offlinecontainer.visible = false;
            this.settingcontainer.visible = false;
        }
        if (id == "login") {
            this.logincontainer.visible = true;
            this.regcontainer.visible = false;
            this.forgotcontainer.visible = false;
            this.selectcharcontainer.visible = false;
            this.createcharcontainer.visible = false;
            this.sociallinkcontainer.visible = false;
            this.offlinecontainer.visible = false;
            this.settingcontainer.visible = false;

        }
        if (id == "reg") {
            this.regcontainer.visible = true;
            this.logincontainer.visible = false;
            this.forgotcontainer.visible = false;
            this.selectcharcontainer.visible = false;
            this.createcharcontainer.visible = false;
            this.sociallinkcontainer.visible = false;
            this.offlinecontainer.visible = false;
            this.settingcontainer.visible = false;

        }
        if (id == "forgot") {
            this.forgotcontainer.visible = true;
            this.regcontainer.visible = false;
            this.logincontainer.visible = false;
            this.selectcharcontainer.visible = false;
            this.createcharcontainer.visible = false;
            this.sociallinkcontainer.visible = false;
            this.offlinecontainer.visible = false;
            this.settingcontainer.visible = false;

        }
        if (id == "selectchar") {
            this.forgotcontainer.visible = false;
            this.regcontainer.visible = false;
            this.logincontainer.visible = false;
            this.selectcharcontainer.visible = true;
            this.createcharcontainer.visible = false;
            this.logoutbtn.visible = true;
            this.sociallinkcontainer.visible = false;
            this.sociallinkbtn.visible = true;
            this.offlinecontainer.visible = false;
            this.settingbtn.visible = true;
            this.curlangbtn.visible = true;
            this.settingcontainer.visible = false;
            var curlang = await this.registry.list.rcstorage.get('setting_lang');
            if (curlang == undefined || !this.setting_langlist.includes(curlang)) {
                curlang = "en";
            }
            this.curlangbtn.setTexture("thebs", curlang.toLowerCase());

        }

        if (id == "createchar") {
            this.createcharcontainer.visible = true;
            this.forgotcontainer.visible = false;
            this.regcontainer.visible = false;
            this.logincontainer.visible = false;
            this.selectcharcontainer.visible = false;
            this.sociallinkcontainer.visible = false;
            this.offlinecontainer.visible = false;
            this.settingcontainer.visible = false;

        }
        if (id == "offline") {
            this.createcharcontainer.visible = false;
            this.forgotcontainer.visible = false;
            this.regcontainer.visible = false;
            this.logincontainer.visible = false;
            this.selectcharcontainer.visible = false;
            this.sociallinkcontainer.visible = false;
            this.offlinecontainer.visible = true;
            this.settingcontainer.visible = false;
            this.plog.log("welcomeoffline", this);

            if (this.splashlogo != undefined) {
                try {
                    this.splashlogo.visible = false;
                } catch (e) {
                    this.plog.log("ERROR", e);
                }
            }
            if (this.splashbg != undefined) {
                try {
                    this.splashbg.visible = false;
                } catch (e) {
                    this.plog.log("ERROR", e);
                }
            }
        }
        if (id == "settings") {
            this.updatesettingdsp();
            this.createcharcontainer.visible = false;
            this.forgotcontainer.visible = false;
            this.regcontainer.visible = false;
            this.logincontainer.visible = false;
            this.selectcharcontainer.visible = false;
            this.sociallinkcontainer.visible = false;
            this.offlinecontainer.visible = false;
            this.settingcontainer.visible = true;
        }

        this.registry.list.ga.se2("welcome-page", id);
        this.resized();
    }
    EmailValidation(email) {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }
    selectChar() {
        //this.plog.log("selectChar();");
        this.selectChar_build();
    }
    async selectChar_build() {
        //this.plog.log("selectChar_build();");
        await this.registry.list.rcsvvars.updatelangpack();

        this.registry.list.snd.snd("btn2");
        // this.plog.log("infoCapacitor", Capacitor, Capacitor.getPlatform());

        var info = await Capacitor.getPlatform();
        //await Capacitor.Plugins.App.getInfo();
        var tmpsv = await this.registry.list.rcstorage.get('rc_usesv');
        this.plog.log("info", info, Capacitor, tmpsv); //return;
        //var infostr = info["version"]
        this.appversiont.setText(info + " " + this.cliversionstring + " - " + tmpsv);
        if (this.isgameplayed == "no") {
            //autoplay last char
            //this.plog.log("autoplay", this.registry.list)
            await this.registry.list.rctoast.sleep(500);
            var isautoplay = await this.registry.list.rcstorage.get('setting_autoplay');
            if (isautoplay == "yes") {
                var lastplaychar = await this.registry.list.rcstorage.get('lastplaychar');
                lastplaychar = Math.floor(lastplaychar);
                if (lastplaychar != 0 && this.registry.list.rcvarpass.charinfo[lastplaychar] != undefined) {
                    await this.registry.list.rctoast.sleep(500);

                    this.registry.list.rcloading.loading2(this, false, false);
                    this.registry.list.rcloading.loading2text(
                        this.trs("f:home:Resuming Game===Resuming Game"));
                }
            }
        }

        for (var i = 0; i <= 20; i++) {
            if (this.selectchar_cicon[i] != undefined)
                this.selectchar_cicon[i].destroy();
            if (this.selectchar_cicons[i] != undefined)
                this.selectchar_cicons[i].destroy();
            if (this.selectchar_ciconsbg[i] != undefined)
                this.selectchar_ciconsbg[i].destroy();
            if (this.selectchar_cname[i] != undefined)
                this.selectchar_cname[i].destroy();
            if (this.selectchar_detail1[i] != undefined)
                this.selectchar_detail1[i].destroy();
            if (this.selectchar_detail2[i] != undefined)
                this.selectchar_detail2[i].destroy();
            if (this.selectchar_playbtn[i] != undefined)
                this.selectchar_playbtn[i].destroy();
            if (this.selectchar_delbtn[i] != undefined)
                this.selectchar_delbtn[i].destroy();
        }

        var charinfo;
        charinfo = this.registry.list.rcvarpass.charinfo;
        if ((charinfo == undefined || charinfo == '')) {
            this.plog.log("selectChar_build(); halted, charinfo undefined");
            //return;
        }
        if (this.selectchar_msg != undefined) this.selectchar_msg.destroy();
        //await this.rchttp.getcharacters();
        //this.plog.log('selectChar_build', this.rcvarsservice);
        //this.plog.log("recreate_selectcharacter this",this.rcvarsservice.gameobj.registry.list.thethis);
        //this.rcvarsservice.gameobj.registry.list.thethis.selectcharimg0
        //this.rcvarsservice.charinfo = charinfo['data'];
        //this.plog.log("chardb1", charinfo["data"]);
        //this.plog.log("chardb", this.rcvarsservice.charinfo);
        //for (var i=0;i<=10;i++)this.rcvarsservice.rcgame.selectcharimg[i]="xxxx"+i;
        //var charinforaw = [];
        var count = 0;
        let loader = new Phaser.Loader.LoaderPlugin(this);

        for (var vv in this.registry.list.rcvarpass.charinfo) {
            var vdat = this.registry.list.rcvarpass.charinfo[vv];
            var genderu = 'male';
            if (vdat['gender'] == 'F') genderu = 'female';
            var sprite = vdat['race'] + "-" + genderu;

            //this.plog.log("recreate_selectcharacter", vv, sprite);

            this.registry.list.rcvarpass.charinfo[vv]['spriteuseitf'] = sprite;
            //this.plog.log("recreate_selectcharacter vdat", vdat);
            //this.plog.log("this.rcvarsservice",this.rcvarsservice);
            //this.registry.list.rcvarpass.rcgame.selectcharimg[count] = vdat['spriteuse'];
            //charinforaw.push(vdat);

            //if (this.astring == undefined) 
            this.astring = this.add.graphics();

            this.selectchar_cicon[count] = this.add.sprite(0, 0, "spacerwelcome");
            this.selectchar_cicons[count] = this.add.sprite(0, 0, "spacerwelcome");
            this.selectchar_cicons[count].setOrigin(0.5, 0.5);
            this.selectchar_ciconsbg[count] = this.add.sprite(0, 0, "justcircle");

            this.selectchar_ciconsbg[count].name = count;
            this.selectchar_ciconsbg[count].removeAllListeners();
            this.selectchar_ciconsbg[count].setInteractive();
            this.selectchar_ciconsbg[count].on("pointerdown", function () {
                this.scene.selectchar_page(this.name);
            });
            this.selectchar_ciconsbg[count].setAlpha(0.8);
            //this.selectchar_cicon[count].setOrigin(0.5, 0.5);

            this.selectcharcontainer.add(this.astring);
            this.selectcharcontainer.bringToTop(this.astring);
            this.selectcharcontainer.add(this.selectchar_ciconsbg[count]);
            this.selectcharcontainer.bringToTop(this.selectchar_ciconsbg[count]);
            this.selectcharcontainer.add(this.selectchar_cicon[count]);
            this.selectcharcontainer.bringToTop(this.selectchar_cicon[count]);
            this.selectcharcontainer.add(this.selectchar_cicons[count]);
            this.selectcharcontainer.bringToTop(this.selectchar_cicons[count]);

            //loader.image(vdat['sprite'], vdat['spriteuse']);


            var theurl = 'assets/character-sprite/' + sprite;
            loader.atlas("charpreview-" + vdat["id"], theurl + '-none.png', theurl + '-none.json');
            //loader.atlas(vdat['sprite'], vdat['spriteuse']);
            this.selectchar_cicon[count].displayWidth = 32;
            this.selectchar_cicon[count].displayHeight = 64;
            this.selectchar_cicon[count].setScale(2);
            this.selectchar_cicon[count].texture.setFilter(Phaser.Textures.FilterMode.NEAREST);
            const color1 = Phaser.Display.Color.HexStringToColor('#' + this.registry.list.rcvarpass.allrace[vdat["race"]]["col2"]);
            //console.log(color1,this.registry.list.rcvarpass.allrace[vdat["race"]]["col2"]);
            this.selectchar_cicon[count].postFX.addGlow(color1.color, 4, 0, false, 0.2, 10);

            this.selectchar_cicons[count].displayWidth = 16;
            this.selectchar_cicons[count].displayHeight = 32;
            this.selectchar_cicons[count].setScale(0.5);
            this.selectchar_cicons[count].texture.setFilter(Phaser.Textures.FilterMode.NEAREST);

            this.selectchar_cname[count] = this.wh.add.text(0, 0, " " + vdat["name"], this.fs["charname"]);
            this.selectchar_cname[count].postFX.addGlow(0xffffff, 6, 0, false, 0.2, 10);

            this.selectcharcontainer.add(this.selectchar_cname[count]);
            this.selectcharcontainer.bringToTop(this.selectchar_cname[count]);

            //this.plog.log("race", this.registry.list.rcvarpass)
            //console.log(this.registry.list.rcvarpass.allrace[vdat["race"]]);
            this.selectchar_detail1[count] = this.wh.add.text(0, 0, " " + this.trs("t:race:id:name:" + this.registry.list.rcvarpass.allrace[vdat["race"]]["id"] + "===") + " lv." + vdat["level"], this.fs["chardetail"]);
            this.selectcharcontainer.add(this.selectchar_detail1[count]);
            this.selectcharcontainer.bringToTop(this.selectchar_detail1[count]);

            if (this.registry.list.rcvarpass.allmap[vdat["map"]] != undefined) {
                this.selectchar_detail2[count] = this.wh.add.text(0, 0, this.wh.trs("f:home:at-atmap===At map:") + " " + this.registry.list.rcvarpass.allmap[vdat["map"]]["name"], this.fs["chardetail2"]);
            } else {
                this.selectchar_detail2[count] = this.wh.add.text(0, 0, this.wh.trs("f:home:at-atmap===At map:") + " ????", this.fs["chardetail2"]);
            }
            this.selectcharcontainer.add(this.selectchar_detail2[count]);
            this.selectcharcontainer.bringToTop(this.selectchar_detail2[count]);

            this.selectchar_playbtn[count] = this.add.sprite(0, 0, "thebb", "play");
            this.selectchar_playbtn[count].name = vdat["id"];
            this.selectcharcontainer.add(this.selectchar_playbtn[count]);
            this.selectcharcontainer.bringToTop(this.selectchar_playbtn[count]);
            this.selectchar_playbtn[count].removeAllListeners();
            this.selectchar_playbtn[count].setInteractive();
            this.selectchar_playbtn[count].on("pointerdown", function () {
                //this.plog.log("playbtn", this);
                this.visible = false;
                this.scene.showpage('none');

                this.scene.playGame(this.name);
            });

            this.selectchar_delbtn[count] = this.add.sprite(0, 0, "theb", "delete");
            this.selectchar_delbtn[count].name = vdat["id"];
            this.selectcharcontainer.add(this.selectchar_delbtn[count]);
            this.selectcharcontainer.bringToTop(this.selectchar_delbtn[count]);
            this.selectchar_delbtn[count].removeAllListeners();
            this.selectchar_delbtn[count].setInteractive();
            this.selectchar_delbtn[count].on("pointerdown", function () {
                //this.plog.log("delbtn", this);
                this.scene.deletechar(this);
            });

            count++;
        }
        this.selectchar_count = count;
        loader.start();
        this.selectchar_header = this.wh.add.text(0, 0, this.trs("f:home:select-character===Select Character"), this.fs["pageheader"]);
        this.selectchar_charslot = this.wh.add.text(0, 0, this.trs("f:home:character===Character") + " " + count + "/" + this.registry.list.rcvarpass.gamer['charslot'], this.fs["loginformtext"]);
        loader.once(Phaser.Loader.Events.COMPLETE, () => {
            this.selectchar_seticonsprite();
        });

        this.selectchar_msg = this.wh.add.text(0, 0,
            this.trs("f:home:no-character-yet===You don't have a character yet\n create now"), this.fs["loginformtext"]);
        this.selectchar_msg.visible = false;
        //this.plog.log("selectchar_msg", count);
        if (count == 0) {
            this.selectchar_msg.visible = true;
        } else {
            this.selectchar_msg.visible = false;
        }

        this.selectcharcontainer.add(this.selectchar_header);
        this.selectcharcontainer.bringToTop(this.selectchar_header);
        this.selectcharcontainer.add(this.selectchar_charslot);
        this.selectcharcontainer.bringToTop(this.selectchar_charslot);
        this.selectcharcontainer.add(this.selectchar_msg);
        this.selectcharcontainer.bringToTop(this.selectchar_msg);

        this.selectchar_back = this.wh.add.sprite(0, 0, "thebb", "backsign");
        this.selectchar_fwd = this.wh.add.sprite(0, 0, "thebb", "forwardsign");
        this.selectcharcontainer.add(this.selectchar_back);
        this.selectcharcontainer.bringToTop(this.selectchar_back);
        this.selectcharcontainer.add(this.selectchar_fwd);
        this.selectcharcontainer.bringToTop(this.selectchar_fwd);
        this.selectchar_back.removeAllListeners();
        this.selectchar_back.setInteractive();
        this.selectchar_back.on("pointerdown", function () {
            this.selectchar_page("back");
        }, this);

        this.selectchar_fwd.removeAllListeners();
        this.selectchar_fwd.setInteractive();
        this.selectchar_fwd.on("pointerdown", function () {
            this.selectchar_page("next");
        }, this);


        this.selectchar_create = this.add.sprite(0, 0, "thebb", "create");
        this.selectcharcontainer.add(this.selectchar_create);
        this.selectcharcontainer.bringToTop(this.selectchar_create);
        if (count >= this.registry.list.rcvarpass.gamer['charslot'] && this.registry.list.rcvarpass.gamer['id'] != 6) {
            this.selectchar_create.visible = false;
        }
        this.selectchar_create.removeAllListeners();
        this.selectchar_create.setInteractive();
        this.selectchar_create.on("pointerdown", function () {
            this.createchar_clickrace(1);
            this.showpage("createchar");
        }, this);

        /*
         
        selectchar_detail = [];
        selectchar_playbtn = [];
        selectchar_delbtn = []; 
        ;
        selectchar_create;
        */
        this.selectchar_page("1st");
        if (this.isgameplayed == "no") {
            var chkreloadandplay = await this.registry.list.rcstorage.get('reloadandplay_id');
            if (chkreloadandplay != undefined && chkreloadandplay != '0') {
                await this.registry.list.rcstorage.set('reloadandplay_id', '0');
                this.playGame(chkreloadandplay);
                return;
            }
            //autoplay last char
            var isautoplay = await this.registry.list.rcstorage.get('setting_autoplay');
            if (isautoplay == "yes") {

                var lastplaychar = await this.registry.list.rcstorage.get('lastplaychar');
                await this.registry.list.rctoast.sleep(500);

                lastplaychar = Math.floor(lastplaychar);
                if (lastplaychar != 0 && this.registry.list.rcvarpass.charinfo[lastplaychar] != undefined) {
                    this.registry.list.rcloading.loading2(this, false, false);
                    this.registry.list.rcloading.loading2text(this.trs("f:home:Resuming Game===Resuming Game"));
                    if (this.selectchar_playbtn[lastplaychar - 1] != undefined)
                        this.selectchar_playbtn[lastplaychar - 1].visible = false;
                    if (this.selectchar_playbtn[lastplaychar] != undefined)
                        this.selectchar_playbtn[lastplaychar].visible = false;
                    if (this.selectchar_delbtn[lastplaychar - 1] != undefined)
                        this.selectchar_delbtn[lastplaychar - 1].visible = false;
                    if (this.selectchar_delbtn[lastplaychar] != undefined)
                        this.selectchar_delbtn[lastplaychar].visible = false;
                    this.selectchar_create.visible = false;
                    this.playGame(lastplaychar);
                }
            }
        } else {

        }


        //this.plog.log("selectChar_build - e", lastplaychar, this.isgameplayed,this.registry.list.phaserGame.scene.scenes, this);
    }

    async selectchar_page(pageid) {
        this.resized();
        if (pageid == "1st") {
            var lastplaychar = await this.registry.list.rcstorage.get('lastplaychar');
            if (lastplaychar == undefined || lastplaychar == '') {
                this.selectchar_pageno = 0;
            } else {
                var setcharid = 0;
                var i = 0;
                for (var vv in this.registry.list.rcvarpass.charinfo) {
                    var vdat = this.registry.list.rcvarpass.charinfo[vv];
                    if (vdat["id"] == lastplaychar) {
                        setcharid = i;
                    }
                    i++;
                }
                this.selectchar_pageno = setcharid;
            }
        } else if (pageid == "back") {
            this.selectchar_pageno -= 1;
            if (this.selectchar_pageno < 0) this.selectchar_pageno = 0;
        } else if (pageid == "next") {
            this.selectchar_pageno += 1;
            if (this.selectchar_pageno > this.selectchar_count - 1) this.selectchar_pageno = this.selectchar_count - 1;
        } else {
            this.selectchar_pageno = pageid;
        }
        for (var i = 0; i <= 20; i++) {
            if (this.selectchar_cicon[i] != undefined)
                this.selectchar_cicon[i].visible = false;
            if (this.selectchar_cname[i] != undefined)
                this.selectchar_cname[i].visible = false;
            if (this.selectchar_detail1[i] != undefined)
                this.selectchar_detail1[i].visible = false;
            if (this.selectchar_detail2[i] != undefined)
                this.selectchar_detail2[i].visible = false;
            if (this.selectchar_playbtn[i] != undefined)
                this.selectchar_playbtn[i].visible = false;
            if (this.selectchar_delbtn[i] != undefined)
                this.selectchar_delbtn[i].visible = false;

        }

        if (this.selectchar_cicon[this.selectchar_pageno] != undefined)
            this.selectchar_cicon[this.selectchar_pageno].visible = true;
        if (this.selectchar_cname[this.selectchar_pageno] != undefined)
            this.selectchar_cname[this.selectchar_pageno].visible = true;
        if (this.selectchar_detail1[this.selectchar_pageno] != undefined)
            this.selectchar_detail1[this.selectchar_pageno].visible = true;
        if (this.selectchar_detail2[this.selectchar_pageno] != undefined)
            this.selectchar_detail2[this.selectchar_pageno].visible = true;
        if (this.selectchar_playbtn[this.selectchar_pageno] != undefined)
            this.selectchar_playbtn[this.selectchar_pageno].visible = true;
        if (this.selectchar_delbtn[this.selectchar_pageno] != undefined)
            this.selectchar_delbtn[this.selectchar_pageno].visible = true;


        var width = this.game.canvas.width;
        var height = this.game.canvas.height;
        if (this.astring != undefined) {
            this.astring.clear();
            this.astring.lineStyle(10, 0xdddddd, 0.9);
            this.astring.lineBetween(
                width / 2,
                height / 2,
                this.selectchar_cicons[this.selectchar_pageno].x,
                this.selectchar_cicons[this.selectchar_pageno].y
            );

            this.selectcharcontainer.sendToBack(this.astring);
        }
        //this.plog.log("astring", this.selectchar_pageno, this.astring, this.selectchar_cicons[this.selectchar_pageno]);
        //this.plog.log("astring",            width / 2,            height / 2,            this.selectchar_cicons[this.selectchar_pageno].x,            this.selectchar_cicons[this.selectchar_pageno].y        );
    }
    async serverstatus() {
        var res = await this.registry.list.rchttp.getcommon('serverstatus');
        //this.plog.log('serverstatus', res);
        if (res['data'] != 'ok') {
            this.registry.list.rcloading.close();
            this.offline_msg.setText(res['servermessage']);
            this.offline_msg2.setText(
                this.trs("f:home:problem-connect-sv===Problem connecting with your server\n click here to logout"), this.fs["loginformtext"]);

            this.showpage('offline');
            return false;
        } else {
            this.plog.log('serverstatus ok', res);
            //this.mycreate();
            //location.reload();
            return true;
            //this.initializeSession();
        }
    }

    async serverstatus_reconnect() {

        this.registry.list.rcloading.loading2(this, false, false);
        await this.registry.list.rctoast.sleep(1000);
        var res = await this.registry.list.rchttp.getcommon('serverstatus', this.registry.list.rcvarpass.rc_baseurl);
        //this.plog.log('serverstatus', res);
        if (res['data'] != 'ok') {
            this.registry.list.rcloading.close();
            this.offline_msg.setText(res['servermessage']);
            this.showpage('offline');
            return false;
        } else {
            //return true;
            this.plog.log('serverstatus ok', res);
            location.reload();
            //this.mycreate();
            return true;

        }
    }
    selectchar_seticonsprite() {
        var spriteset = [
            { code: 'walk-d', framename: 'walk-d', fr: 16, end: 9 },
        ];
        var count = 0;
        var charinfo = this.registry.list.rcvarpass.charinfo;
        for (var vv in charinfo) {
            var vdat = this.registry.list.rcvarpass.charinfo[vv];


            for (var idx in spriteset) {
                var val = spriteset[idx];
                //this.plog.log("spriteuseitf", vdat["spriteuseitf"], val);
                //this.plog.log("loadcharsprite", idx, val);
                this.anims.remove("charpreviewa-" + vdat["id"]);
                try {
                    this.anims.create({
                        key: "charpreview-" + vdat["id"],
                        frames: this.anims.generateFrameNames("charpreview-" + vdat["id"], {
                            prefix: val['framename'] + '-',
                            end: val['end'],
                            zeroPad: 0,
                            start: 1,
                        }),
                        repeat: -1,
                        frameRate: val['fr'],
                    });
                } catch (e) {
                    this.plog.log("selectchar_seticonsprite() ERROR", e);
                }
            }

            if (this.selectchar_cicon[count] != undefined) {
                try {
                    this.selectchar_cicon[count].setTexture(vdat["spriteuseitf"]);
                    this.selectchar_cicon[count].play({ key: "charpreview-" + vdat["id"], frameRate: 2 });
                    this.selectchar_cicon[count].texture.setFilter(Phaser.Textures.FilterMode.NEAREST);
                } catch (e) {
                    this.plog.log("selectchar_seticonsprite error", e);
                }
            }
            if (this.selectchar_cicons[count] != undefined) {
                try {
                    this.selectchar_cicons[count].setTexture(vdat["spriteuseitf"]);
                    this.selectchar_cicons[count].play({ key: "charpreview-" + vdat["id"], frameRate: 2 });
                    this.selectchar_cicons[count].texture.setFilter(Phaser.Textures.FilterMode.NEAREST);
                } catch (e) {
                    this.plog.log("selectchar_seticonsprite error", e);
                }
            }
            count++;
        }
        this.resized();
    }
    async deletechar(id) {
        this.registry.list.snd.snd("btn3");
        //this.plog.log("deletechar", id);

        var charid: string;
        charid = id.name;
        this.plog.log('deletechar charid', charid, this.registry.list.rcvarpass.charinfo);
        var chartodelete = this.registry.list.rcvarpass.charinfo[charid];
        this.plog.log('chartodelete', chartodelete);
        const alert = await this.registry.list.thethis.AlertController.create({
            message:
                this.trs("f:home:confirm-delete-char-1===Please confirm deletion of this character \n \nName:")
                +
                chartodelete['name'] +
                ' \nLevel:' +
                chartodelete['level'] +
                ' \n \n' + this.trs("f:home:confirm-delete-char-2===type word YES into the box below and click DELETE"),
            inputs: [
                {
                    name: 'confirmtext',
                    type: 'text',
                },
            ],

            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                    handler: () => {
                        this.plog.log('Confirm Cancel');
                    },
                },
                {
                    text: 'DELETE',
                    cssClass: 'secondary',
                    handler: (alertData) => {
                        //takes the data
                        if (alertData.confirmtext == 'YES') {
                            //this.plog.log(this);
                            this.plog.log(alertData.confirmtext);
                            this.deletechar_delete(charid);
                        }
                    },
                },
            ],
        });
        await alert.present();
    }
    async deletechar_delete(charid) {
        this.registry.list.snd.snd("btn3");
        this.plog.log("deletechar_delete", charid);

        this.registry.list.rcloading.loading2(this, false, false);

        var chartodelete = this.registry.list.rcvarpass.charinfo[charid];

        //this.plog.log('rcdeletechar_delete', charid, chartodelete);
        var cresult;
        cresult = await this.registry.list.rchttp.getcommon2('deletechar', {
            chartodelete: chartodelete['id'],
        });
        //this.plog.log('cresult', cresult);
        if (cresult.msg == 'ok') {
            var tmp = await this.registry.list.rchttp.getcharacters(true);

            this.registry.list.rcvarpass.charinfo = tmp;
            this.selectChar_build();
            top.location.reload();

        }
        this.registry.list.rcloading.close();

    }
    createchar_clickrace(id) {
        this.registry.list.snd.snd("btn2");
        // this.plog.log("createchar_clickrace", id);
        //this.createchart_racename.setText(this.registry.list.phaserGame.scene.scenes[0].gl(this.registry.list.rcvarpass.allrace[id]["name"]));
        this.createchart_racename.setText(this.trs("t:race:id:name:" + id + "==="));

        this.createchart_racedescr.setText(this.trs("t:race:id:descr:" + id + "==="));
        this.createchart_racedescr.setWordWrapWidth(this.createchart_race5.displayWidth * 5, true);
        this.createchart_racedescr.visible = false;
        //this.createchart_racedescr.setText(this.registry.list.phaserGame.scene.scenes[0].gl(this.registry.list.rcvarpass.allrace[id]["descr"]));
        this.createchar_selectedrace = id;
        this.currentimage = this.racebgid[id];
        this.itftopbg2.setTexture("loadingbg" + this.currentimage);
        this.startsyncanimation();
        this.createchar_updatepreview();
    }
    createchar_clickgender(id) {
        this.registry.list.snd.snd("btn2");
        //this.plog.log("createchar_clickgender", id);
        this.createchar_selectedgender = id;
        this.createchar_updatepreview();
    }
    createchar_updatepreview_key = "";
    createchar_updatepreview() {
        var urlgender = "female";
        if (this.createchar_selectedgender == "M") {
            urlgender = "male";
        }
        var thekey = this.createchar_selectedrace + "-" + urlgender + "-none";
        this.createchar_updatepreview_key = thekey;
        let loader = new Phaser.Loader.LoaderPlugin(this);
        var theurl = this.registry.list.rcvarpass.rc_baseurl + 'sprite/' + thekey;
        loader.atlas(thekey, theurl + '.png', theurl + '.json');
        //this.plog.log("createchar_updatepreview load", thekey, theurl + '.png', theurl + '.json');
        loader.once(Phaser.Loader.Events.COMPLETE, () => {
            this.createchar_updatepreview_loaded();
        });
        loader.start();
    }
    createchar_updatepreview_loaded() {
        //this.plog.log("createchar_updatepreview_loaded");

        var spriteset = [
            { code: 'idle', framename: 'walk-d', fr: 1, end: 1 },
            { code: 'walk-d', framename: 'walk-d', fr: 16, end: 9 },
            { code: 'walk-u', framename: 'walk-u', fr: 16, end: 9 },
            { code: 'walk-l', framename: 'walk-l', fr: 16, end: 9 },
            { code: 'walk-r', framename: 'walk-r', fr: 16, end: 9 },
            { code: 'thrust-d', framename: 'thrust-d', fr: 8, end: 8 },
            { code: 'thrust-u', framename: 'thrust-u', fr: 8, end: 8 },
            { code: 'thrust-l', framename: 'thrust-l', fr: 8, end: 8 },
            { code: 'thrust-r', framename: 'thrust-r', fr: 8, end: 8 },
            { code: 'walk-d-idle', framename: 'idle-d', fr: 3, end: 3 },
            { code: 'walk-u-idle', framename: 'idle-u', fr: 3, end: 3 },
            { code: 'walk-l-idle', framename: 'idle-l', fr: 3, end: 3 },
            { code: 'walk-r-idle', framename: 'idle-r', fr: 3, end: 3 },
        ];

        var urlgender = "female";
        if (this.createchar_selectedgender == "M") {
            urlgender = "male";
        }
        for (var idx in spriteset) {
            var val = spriteset[idx];
            //this.plog.log("loadcharsprite", idx, val);
            this.anims.remove(this.createchar_selectedrace + '-' + urlgender + '-' + val['code']);
            this.anims.create({
                key: this.createchar_selectedrace + '-' + urlgender + '-' + val['code'],
                frames: this.anims.generateFrameNames(this.createchar_updatepreview_key, {
                    prefix: val['framename'] + '-',
                    end: val['end'],
                    zeroPad: 0,
                    start: 1,
                }),
                repeat: -1,
                frameRate: val['fr'],
            });
        }
        this.createchart_preview.setTexture(this.createchar_updatepreview_key);
        this.createchart_preview.play({ key: this.createchar_selectedrace + '-' + urlgender + "-walk-d", frameRate: 2 });
        //this.plog.log("playing", this.createchar_updatepreview_key);
        // this.plog.log(this);
        this.createchart_preview.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);

    }
    async createchar() {
        this.registry.list.snd.snd("btn2");
        this.registry.list.rcloading.loading2(this, false, false);

        var data;
        var nameo;
        nameo = this.createchart_name.text;

        data = {
            race: this.createchar_selectedrace,
            gender: this.createchar_selectedgender,
            name: nameo,
        };
        //this.plog.log('data', data);

        if (this.createchar_selectedgender == undefined || this.createchar_selectedgender == '') {
            this.registry.list.rcloading.close();
            this.showpage("none");
            await this.registry.list.rctoast.rcalert(this,
                this.trs("f:home:Please select character gender===Please select character gender"), this.trs("f:home:Please select character gender===Please select character gender"));
            this.showpage("createchar");
            return;
        }
        if (this.createchar_selectedrace == undefined || this.createchar_selectedrace == 0) {
            this.registry.list.rcloading.close();
            this.showpage("none");
            await this.registry.list.rctoast.rcalert(this, this.trs("f:home:Please select character race===Please select character race"), this.trs("f:home:Please select character race===Please select character race"));
            this.showpage("createchar");
            return;
        }
        if (nameo == undefined || nameo == '' || nameo.length < 4) {
            this.registry.list.rcloading.close();
            this.showpage("none");
            await this.registry.list.rctoast.rcalert(this,
                this.trs("f:home:Please Enter Character Name===Please Enter Character Name"),
                this.trs("f:home:Please Enter Character Name - Minimum 4 characters===Please Enter Character Name - Minimum 4 characters")
            );
            this.showpage("createchar");
            return;
        }
        var cresult;
        cresult = await this.registry.list.rchttp.createCharacter(data);
        this.registry.list.rcloading.close();
        if (cresult.msg == 'ok') {
            var tmp = await this.registry.list.rchttp.getcharacters(true);

            this.registry.list.rcvarpass.charinfo = tmp;
            this.selectChar_build();
            this.showpage('selectchar');
        }
    }
    openWebpage(url: string) {
        this.registry.list.snd.snd("btn3");
        /*const options: InAppBrowserOptions = {
          zoom: 'no'
        }
        // Opening a URL and returning an InAppBrowserObject
        const browser = this.iab.create(url, '_self', options);
       // Inject scripts, css and more with browser.X
       */
        //const browser = this.iab.create(url, '_self');
        window.open(url, '_blank');
    }
    async logout() {
        //var confirm = await this.registry.list.rctoast.confirmbox_logout();
        var confirm = await this.registry.list.rctoast.rcconfirm(this, "Logout", "Are you sure you want to logout?");
        this.plog.error("cfm", confirm);
        await this.registry.list.rctoast.sleep(200);

        //this.plog.log("confirm", confirm);
        if (confirm != 'ok') {
            return;
        }
         var logout = await this.registry.list.rchttp.getcommon2('logout');
       await this.registry.list.rcstorage.set('rc_sid', '');
        await this.registry.list.rcstorage.set('rc_login', '');
        await this.registry.list.rcstorage.set('loggedin', 'no');

        this.is_loggedin = false;
        location.reload();
        return;
        this.showpage('login');
        this.registry.list.rcvarpass.charinfo = [];
        this.registry.list.rcvarpass.charinfo = [];
        var chkrememberemail = await this.registry.list.rcstorage.get('remember_email');
        //this.plog.log('chkrememberemail', chkrememberemail);
        if (chkrememberemail != '' && chkrememberemail != "") {
            this.login_u.setText(chkrememberemail);
        }
        var chkrememberserver = await this.registry.list.rcstorage.get('remember_server');
        if (chkrememberserver != '' && chkrememberserver != undefined) {
            await this.selectsv_select(chkrememberserver);
        }
    }
    async managesociallink() {
        this.registry.list.snd.snd("btn2");
        //this.plog.log(this);
        this.showpage("none");
        var width = this.game.canvas.width;
        var height = this.game.canvas.height;

        this.registry.list.rcloading.loading2(this, false, false);
        var gamer = await this.registry.list.rchttp.getcommon2('gamer');
        this.registry.list.rcvarpass.gamer = gamer;
        this.registry.list.rcloading.close();
        if (this.sociallinkbg != undefined) this.sociallinkbg.destroy();
        for (var i = 0; i <= 100; i++) {
            if (this.sociallink_name[i] != undefined) this.sociallink_name[i].destroy();
        }
        for (var i = 0; i <= 100; i++) {
            if (this.sociallink_unlink[i] != undefined) this.sociallink_unlink[i].destroy();
        }
        this.sociallinkbg = this.wh.add.rectangle(0, 0, width, height, 0x000000);
        this.sociallinkbg.setPosition(0, 0);
        this.sociallinkbg.setOrigin(0, 0);
        this.sociallinkbg.setAlpha(0.8);
        this.sociallinkbg.visible = true;
        this.sociallinkbg.setDepth(1500000);
        this.sociallinkbg.setInteractive();
        var toppos = 120;
        var svitemh = 50;

        this.sociallinkback = this.add.sprite(0, 0, "theb", "back");
        this.sociallinkback.setDepth(1500010);
        this.sociallinkback.setPosition(this.sociallinkback.displayWidth / 2 + 10, height - this.sociallinkback.displayHeight);
        this.sociallinkback.removeAllListeners();
        this.sociallinkback.setInteractive();
        this.sociallinkback.on("pointerdown", function () {
            this.managesociallink_close();
        }, this);

        this.sociallinkfb = this.add.sprite(0, 0, "theb", "connectfacebook");
        this.sociallinkfb.setDepth(1500010);
        this.sociallinkfb.setPosition(width / 2, 50);
        this.sociallinkfb.removeAllListeners();
        this.sociallinkfb.setInteractive();
        this.sociallinkfb.on("pointerdown", function () {
            this.facebookAuth('link');

        }, this);

        this.sociallinkgg = this.add.sprite(0, 0, "theb", "connectgoogle");
        this.sociallinkgg.setDepth(1500010);
        this.sociallinkgg.setPosition(width / 2, 70);
        this.sociallinkgg.removeAllListeners();
        this.sociallinkgg.setInteractive();
        this.sociallinkgg.on("pointerdown", function () {
            this.googleAuth('link');
        }, this);
        this.sociallinktw = this.add.sprite(0, 0, "theb", "connecttwitter");
        this.sociallinktw.setDepth(1500010);
        this.sociallinktw.setPosition(width / 2, 90);
        this.sociallinktw.removeAllListeners();
        this.sociallinktw.setInteractive();
        this.sociallinktw.on("pointerdown", function () {
            this.twitterAuth('link');
        }, this);

        var svi = 0;
        for (const k in this.registry.list.rcvarpass.gamer.sslink) {
            var v = this.registry.list.rcvarpass.gamer.sslink[k];
            this.sociallink_name[svi] = this.wh.add.text(0, 0, v["provider"] + ":" + v["data"]["displayName"], this.fs["chardetail"]);
            this.sociallink_name[svi].setOrigin(0, 0);

            this.sociallink_name[svi].setPosition((width / 2) - (this.sociallink_name[svi].displayWidth / 2), toppos + (svitemh * svi))
            this.sociallink_name[svi].setDepth(1500001);

            this.sociallink_unlink[svi] = this.add.sprite(0, 0, "theb", "delete");
            this.sociallink_unlink[svi].name = v["id"];
            this.sociallink_unlink[svi].removeAllListeners();
            this.sociallink_unlink[svi].setInteractive();
            this.sociallink_unlink[svi].on("pointerdown", function () {
                console.log("sociallink_unlink ", this);
                this.scene.sociallink_unlinkthis(this.name);
            });
            this.sociallink_unlink[svi].setDepth(1500001);
            this.sociallink_unlink[svi].setPosition(width / 2, this.sociallink_name[svi].y + 35);


            svi++;
        }
    }
    managesociallink_close() {
        this.registry.list.snd.snd("btn2");
        this.showpage("selectchar");

        if (this.sociallinkfb != undefined) this.sociallinkfb.destroy();
        if (this.sociallinkgg != undefined) this.sociallinkgg.destroy();
        if (this.sociallinktw != undefined) this.sociallinktw.destroy();
        if (this.sociallinkbg != undefined) this.sociallinkbg.destroy();
        if (this.sociallinkback != undefined) this.sociallinkback.destroy();
        for (var i = 0; i <= 100; i++) {
            if (this.sociallink_unlink[i] != undefined) this.sociallink_unlink[i].destroy();
        }
        for (var i = 0; i <= 100; i++) {
            if (this.sociallink_name[i] != undefined) this.sociallink_name[i].destroy();
        }

    }
    async sociallink_unlinkthis(id) {
        this.registry.list.snd.snd("btn2");
        //this.plog.log("sociallink_unlinkthis", id);
        var confirm = await this.registry.list.rctoast.rcconfirm(this, this.trs("f:home:Unlink this account===Unlink this account"),
            this.trs("f:home:Please confirm unlinking this social account===Please confirm unlinking this social account"));
        /*
                var confirm = await this.registry.list.rctoast.confirm(
                    this.trs("f:home:Unlink this account===Unlink this account"),
                    this.trs("f:home:Please confirm unlinking this social account===Please confirm unlinking this social account")
                );*/
        //this.plog.log('unlinksocialaccount confirm', confirm);
        if (confirm == 'ok') {
            this.plog.log('unlinksocialaccount this', this);
            var res = await this.registry.list.rchttp.getcommon2('unlinksocialaccount', {
                linkid: id,
            });
            this.plog.log('unlinksocialaccount res', res);
            if (res == 'ok') {
                var gamer = await this.registry.list.rchttp.getcommon2('gamer');
                this.registry.list.rcvarpass.gamer = gamer;
                this.selectChar_build();
                this.managesociallink_close();
                this.showpage("selectchar");
            }
        }
    }

    async facebookAuth(mode) {
        this.registry.list.snd.snd("btn2");
        //this.plog.log('facebookAuth', this, mode);
        if (mode == 'login') {

            this.registry.list.rcloading.loading2(this, false, false);

            if (this.reg_selected_server == undefined || this.reg_selected_server == '') {
                this.plog.log('no sv');

                if (this.splashlogo != undefined) {
                    try {
                        this.splashlogo.visible = false;
                    } catch (e) {
                        this.plog.log("ERROR", e);
                    }
                }
                if (this.splashbg != undefined) {
                    try {
                        this.splashbg.visible = false;
                    } catch (e) {
                        this.plog.log("ERROR", e);
                    }
                }
                this.registry.list.rctoast.rctoast(this, this.trs("f:home:Please select server===Please select server"));

                this.registry.list.rcloading.close();
                return;
            }
            await this.registry.list.rcstorage.set('remember_server', this.reg_selected_server);
        }
        //return;
        this.authserv.FacebookAuth();
    }

    async googleAuth(mode) {
        this.registry.list.snd.snd("btn2");
        if (mode == 'login') {


            this.registry.list.rcloading.loading2(this, false, false);

            if (this.reg_selected_server == undefined || this.reg_selected_server == '') {
                this.plog.log('no sv');

                if (this.splashlogo != undefined) {
                    try {
                        this.splashlogo.visible = false;
                    } catch (e) {
                        this.plog.log("ERROR", e);
                    }
                }
                if (this.splashbg != undefined) {
                    try {
                        this.splashbg.visible = false;
                    } catch (e) {
                        this.plog.log("ERROR", e);
                    }
                }
                this.registry.list.rctoast.rctoast(this, this.trs("f:home:Please select server===Please select server"));
                this.registry.list.rcloading.close();
                return;
            }
            //this.rcstorage.set('remember_server', login_c_server);
            await this.registry.list.rcstorage.set('remember_server', this.reg_selected_server);
        }
        //       await this.authserv.GoogleAuth();

        await this.authserv.GoogleAuth();
    }
    async twitterAuth(mode) {
        this.registry.list.snd.snd("btn2");
        if (mode == 'login') {


            this.registry.list.rcloading.loading2(this, false, false);

            if (this.reg_selected_server == undefined || this.reg_selected_server == '') {
                this.plog.log('no sv');

                if (this.splashlogo != undefined) {
                    try {
                        this.splashlogo.visible = false;
                    } catch (e) {
                        this.plog.log("ERROR", e);
                    }
                }
                if (this.splashbg != undefined) {
                    try {
                        this.splashbg.visible = false;
                    } catch (e) {
                        this.plog.log("ERROR", e);
                    }
                }
                this.registry.list.rctoast.rctoast(this, this.trs("f:home:Please select server===Please select server"));
                this.registry.list.rcloading.close();
                return;
            }
            localStorage.setItem('remember_server', this.reg_selected_server);
        }
        this.authserv.TwitterAuth();
    }
    async playGame(charid) {
        this.registry.list.snd.snd("btn3");
        this.registry.list.rcloading.loading2(this, false, false);
        //this.plog.log('playGame', charid, this, this.registry.list.phaserGame.scene.scenes[0]);
        var setplayresult = await this.registry.list.rchttp.getcommon2('loginsetactivedevice');
        await this.registry.list.rcstorage.set('lastplaychar', charid);
        if (this.isgameplayed == "yes") {
            //this.plog.log('isgameplayed yes, reloadandplay', charid);
            this.reloadandplay(charid);
            return;
        }
        //this.is_gamerunning = true;
        /*
        
        var serverstatuschk = await this.serverstatus();
        if (serverstatuschk != true) {
            this.plog.log("server status error");
            return;
        }*/

        await this.registry.list.rcstorage.setupcache();
        await this.registry.list.thethis.initializePayment();
        await this.registry.list.scrw.signin();
        //this.plog.log('check charinfo', this.registry.list.rcvarpass.charinfo, charid);
        await this.registry.list.rcvarpass.setactivechar(this.registry.list.rcvarpass.charinfo, charid);
        await this.registry.list.rchttp.updatecharinfo();
        //this.plog.log('charid', charid, this.registry.list.rcvarpass.charinfo[charid]);

        //this.input.keyboard.enabled = false;


        var tmpmap = await this.registry.list.rchttp.load_map(
            this.registry.list.rcvarpass.charinfo[charid].map,
            'fromlogin'
        );
        this.registry.list.rcvarpass.activemap = tmpmap;
        this.registry.list.rcloading.close();
        await this.registry.list.phm.initmap();
        //this.scene.resume('Preloader');
        //this.scene.pause();

        this.isgameplayed = "yes";
        //this.plog.log("getPlatform()=", Capacitor.getPlatform())
        if (Capacitor.getPlatform() == "android") {
            try {
                window.screen.orientation.unlock();
            } catch (err) {
                this.plog.log("ERROR", err)
            }
        }
        try {
            this.scene.start('Preloader');
        } catch (e) {
            this.plog.error("ERROR", e);
            top.location.reload();
        }
        //this.scene.remove("Welcome");//remove this scene; NO
        //this.registry.list.phaserGame.scene.scenes[0].scene.restart();
    }
    async reloadandplay(charid) {
        //this.plog.log('reloadandplay', charid);
        this.registry.list.rcloading.loading2(this, false, false);

        //await this.rcstorage.set('reloadandplay_id', charid);
        //await this.rcstorage.set('reloadandplay_id', charid);
        while (true) {
            //this.plog.log('loginstage  reloadandplay loop check', charid);
            await this.registry.list.rcstorage.set('reloadandplay_id', charid);
            var savedrap = await this.registry.list.rcstorage.get('reloadandplay_id');
            if (savedrap == charid) break;
        }
        top.location.reload();
    }
    async updatesettingi(setting, val) {
        this.registry.list.snd.snd("btn2");
        //this.plog.log("updatesettingi", setting, val);
        await this.registry.list.rcstorage.set('setting_' + setting, val);
        this.updatesettingdsp();
        if (setting == "lang") {
            var forceurl = this.registry.list.thethis.rcserverlistreg[this.reg_selected_server]['url'];
            var chk = await this.registry.list.rchttp.getcommon2(
                'savegamerlang',
                {
                    lang: val,
                },
                forceurl
            );
            this.registry.list.rcvarpass.lang = val;
            await this.registry.list.rcsvvars.updatelangpack();
        }
    }
    async updatesettingtoggle(setting, val) {
        this.registry.list.snd.snd("btn2");
        //this.plog.log("updatesettingtoggle", setting, val);
        var oldval = await this.registry.list.rcstorage.get('setting_' + setting);
        if (oldval == "yes") {
            await this.registry.list.rcstorage.set('setting_' + setting, "no");
            val.setTexture("thebs", "off");
            if (setting == "bgm") {
                this.registry.list.phaserGame.scene.scenes[0].bgmsetting = "no";
            }
        } else {
            await this.registry.list.rcstorage.set('setting_' + setting, "yes");
            val.setTexture("thebs", "on");
            if (setting == "bgm") {
                this.registry.list.phaserGame.scene.scenes[0].bgmsetting = "yes";
            }
        }

        //await this.registry.list.rcstorage.set('setting_'+setting, val);

    }
    async updatesettingnum(setting, val) {
        this.registry.list.snd.snd("btn2");
        var oldval = await this.registry.list.rcstorage.get('setting_' + setting);
        var useval = oldval;
        if (val == 1 && oldval < 9) {
            useval = oldval + 1;
            await this.registry.list.rcstorage.set('setting_' + setting, useval);
        }
        if (val == -1 && oldval > 0) {
            useval = oldval - 1;
            await this.registry.list.rcstorage.set('setting_' + setting, useval);
        }
        //this.plog.log("useval", val, useval);
        this.registry.list.phaserGame.scene.scenes[0].sound.setVolume(useval / 10);
        this.updatesettingdsp();
    }
    async updatesettingdsp() {
        var oldval = await this.registry.list.rcstorage.get('setting_lang');
        if (oldval == undefined || oldval == "") {
            oldval = "EN";
            await this.registry.list.rcstorage.set('setting_lang', "EN");
        }
        this.setting_lang.setText(oldval);
        this.registry.list.rcvarpass.lang = oldval;
        var oldval = await this.registry.list.rcstorage.get('setting_volume');
        //this.plog.log("updatesettingdsp volume, oldval", oldval);
        if (oldval != 0 && (oldval == undefined || oldval == "")) {
            oldval = 5;
            await this.registry.list.rcstorage.set('setting_volume', 5);
            //this.plog.log("updatesettingdsp volume undefined, oldval", oldval);
        }
        this.registry.list.phaserGame.scene.scenes[0].sound.setVolume(oldval / 10);
        this.setting_sounddsp.setText(oldval);
        var oldval = await this.registry.list.rcstorage.get('setting_lighteffects');
        if (oldval == undefined || oldval == "") {
            oldval = "no";
            await this.registry.list.rcstorage.set('setting_lighteffects', "no");
        }
        if (oldval == "yes") {
            this.setting_lighteffects.setTexture("thebs", "on");
        } else {
            this.setting_lighteffects.setTexture("thebs", "off");
        }
        var oldval = await this.registry.list.rcstorage.get('setting_weathereffects');
        if (oldval == undefined || oldval == "") {
            oldval = "no";
            await this.registry.list.rcstorage.set('setting_weathereffects', "no");
        }
        if (oldval == "yes") {
            this.setting_weathereffects.setTexture("thebs", "on");
        } else {
            this.setting_weathereffects.setTexture("thebs", "off");
        }
        var oldval = await this.registry.list.rcstorage.get('setting_antialias');
        if (oldval == undefined || oldval == "") {
            oldval = "no";
            await this.registry.list.rcstorage.set('setting_antialias', "no");
        }
        if (oldval == "yes") {
            //this.setting_anti.setTexture("thebs", "on");
        } else {
            //this.setting_anti.setTexture("thebs", "off");
        }
        var oldval = await this.registry.list.rcstorage.get('setting_bgm');
        if (oldval == undefined || oldval == "") {
            oldval = "no";
            await this.registry.list.rcstorage.set('setting_bgm', "no");
        }
        if (oldval == "yes") {
            this.setting_bgm.setTexture("thebs", "on");
        } else {
            this.setting_bgm.setTexture("thebs", "off");
        }
        var oldval = await this.registry.list.rcstorage.get('setting_autoplay');
        if (oldval == undefined || oldval == "") {
            oldval = "no";
            await this.registry.list.rcstorage.set('setting_autoplay', "no");
        }
        if (oldval == "yes") {
            this.setting_autoplay.setTexture("thebs", "on");
        } else {
            this.setting_autoplay.setTexture("thebs", "off");
        }

        var oldval = await this.registry.list.rcstorage.get('setting_mincombat');
        if (oldval == undefined || oldval == "") {
            oldval = "no";
            await this.registry.list.rcstorage.set('setting_mincombat', "no");
        }
        if (oldval == "yes") {
            this.setting_mincombat.setTexture("thebs", "on");
        } else {
            this.setting_mincombat.setTexture("thebs", "off");
        }
        var oldval = await this.registry.list.rcstorage.get('setting_badint');
        if (oldval == undefined || oldval == "") {
            oldval = "no";
            await this.registry.list.rcstorage.set('setting_badint', "no");
        }
        if (oldval == "yes") {
            this.setting_badint.setTexture("thebs", "on");
        } else {
            this.setting_badint.setTexture("thebs", "off");
        }
    }
    async guestmode() {
        this.showpage("none");
        this.registry.list.rcloading.loading2(this);
        this.registry.list.rcloading.loading2text("Setting up...");
        this.registry.list.snd.snd("btn3");
        //var guestdat = await this.registry.list.rchttp.getcommon2('genguest');
        //this.registry.list.rcloading.close();
        this.plog.log("guestmode();", this.reg_selected_server)


        if (this.reg_selected_server == undefined || this.reg_selected_server == '') {
            this.plog.log('no sv');
            await this.registry.list.rcloading.close();
            this.showpage("none");

            if (this.splashlogo != undefined) {
                try {
                    this.splashlogo.visible = false;
                } catch (e) {
                    this.plog.log("ERROR", e);
                }
            }
            if (this.splashbg != undefined) {
                try {
                    this.splashbg.visible = false;
                } catch (e) {
                    this.plog.log("ERROR", e);
                }
            }
            await this.registry.list.rctoast.rcalert(this, this.trs("f:home:Please select server===Please select server"), 'Select server by clicking [select server]\n\n then click to select server\n\n');
            this.showpage("login");
            //this.registry.list.rctoast.alert('Please select server', 'Form error', 'Select server by clicking [select server]<BR><BR>\n\n then click to select server<BR><BR>\n\n');
            return;
        }

        //this.rcstorage.set("remember_pwd", login_c_pwd);
        await this.registry.list.rcstorage.listall();
        //}
        var sv;
        var postdat;
        postdat = {
            server: this.reg_selected_server,
        };

        sv = await this.registry.list.rchttp.reg_guest(this.reg_selected_server, postdat);


        await this.registry.list.rcstorage.set('remember_server', this.reg_selected_server);
        this.registry.list.rcloading.loading2text("Logging in...");


        this.login_u.text = sv['tmpemail'];
        this.login_p.text = sv['tmppwd'];
        this.showpage("login");
        await this.login();
        this.showpage('none');
        this.playGame(sv['newcharid']);
        //await this.registry.list.rcstorage.set('remember_email', u);
    }

    trs(str) { //translate/get string using intl
        //this.plog.error("trs",str,this);
        return this.registry.list.intl.ff(str);
    }
}
