import { Injectable } from '@angular/core';
import { RCVarsService } from './rcvars-service.service';
import { RCOSDService } from './rcosd.service';
import { RCHTTP } from './rchttp.service';
import { plog } from './plog.service';

@Injectable({
  providedIn: 'root'
})
export class UpgradeRarItemService {
  selectcircle;
  selecteditemid;
  unequipbtn;
  shopclosebtn;
  itemdescr;
  itemdescrt;
  eqitems = [];
  eqitemsp = [];
  eqitemsr=[];
  itemwh = 54;
  shopguibg;
  charicon;
  wh;
  plog;

  constructor(
    public rcvarsservice: RCVarsService,
    public rcosd: RCOSDService,
    public rchttp: RCHTTP
  ) {
    this.plog = new plog();
  }


  async show(wh) {
    this.wh = wh;
    if (wh.upgraderarcontainer != undefined) {
      await this.show_close(wh);
    }
    var width = wh.game.canvas.width;
    var height = wh.game.canvas.height;
    this.plog.log("equipment show", wh, this);

    const selectcircle = wh.add.image(0, 0, "selectcircle");
    selectcircle.setOrigin(0.5, 0.5);
    selectcircle.visible = false;
    selectcircle.setAlpha(0.7);
    const shopguibg = wh.add.image(0, 0, "upgradeitemguibg");
    shopguibg.setOrigin(0.5, 0.5);
    shopguibg.setInteractive({ pixelPerfect: true });
    shopguibg.removeAllListeners();
    this.shopguibg = shopguibg;
    //this.plog.log("showinventory var", this.rcvarsservice);
    this.selectcircle = selectcircle;


    var shopclosebtn = wh.add.sprite(300, 300, "thesq", 'close');
    this.shopclosebtn = shopclosebtn;
    this.shopclosebtn.setOrigin(0.5, 0.5);
    this.shopclosebtn.setPosition(155, -200);
    shopclosebtn.displayWidth = 40;
    shopclosebtn.displayHeight = 40;
    this.shopclosebtn.setInteractive();
    this.shopclosebtn.removeAllListeners();
    this.shopclosebtn.on('pointerup', function (pointer, localX, localY, event) {
      //this.scene.registry.list["thethis"].phm.floorclick(this);
      this.plog.log("gc.npcbackbtn pointerup", pointer, localX, localY, event, this);
      this.show_close(wh);
    }, this);

    var tmpframe = this.rcvarsservice.gameobj.anims.generateFrameNames(
      this.rcvarsservice.activechar["sprite"] + "", {
      prefix: 'walk-d-',
      start: 1,
      end: 2,
      zeroPad: 0
    });
    this.rcvarsservice.gameobj.anims.remove("mainchar-idle");
    var tmpx = this.rcvarsservice.gameobj.anims.create({
      key: 'mainchar-idle',
      frames: tmpframe,
      frameRate: 1,
      repeat: -1
    });
    const charicon = this.rcvarsservice.gameobj.scene.scenes[0].add.sprite(100, 100, this.rcvarsservice.activechar["sprite"], tmpframe[0]["frame"]).setPosition(0, 50).setScale(3).play('mainchar-idle');//.setDisplayOrigin(0.5, 0.8);
    this.plog.log("Phaser.Textures.FilterMode", Phaser.Textures.FilterMode);
    charicon.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);
    //Phaser.Textures.FilterMode.LINEAR or Phaser.Textures.FilterMode.NEAREST


    wh.upgraderarcontainer = wh.add.container(300, 300);
    wh.upgraderarcontainer._refsrv = this;
    wh.upgraderarcontainer.add(this.shopclosebtn);
    wh.upgraderarcontainer.add(selectcircle);
    wh.upgraderarcontainer.add(shopguibg);
    wh.upgraderarcontainer.add(charicon);
    this.charicon = charicon;
    wh.upgraderarcontainer.bringToTop(this.shopclosebtn);
    var itemdescr = wh.add.text(0, 0, "", wh.fs["winguibardescr"]);
    wh.upgraderarcontainer.add(itemdescr);
    itemdescr.setOrigin(0, 0);
    itemdescr.setPosition(-140, 179);
    this.itemdescr = itemdescr;
    var itemdescrt = wh.add.text(0, 0, wh.gl("Click Item you wish to upgrade rarity"), wh.fs["winguibardescr"]);
    wh.upgraderarcontainer.add(itemdescrt);
    itemdescrt.setOrigin(0, 0);
    itemdescrt.setPosition(-140, -173);
    this.itemdescrt = itemdescrt;


    //drag

    var shopdragbtn = wh.add.image(300, 300, 'spacer');
    wh.shopdragbtn = shopdragbtn;
    wh.shopdragbtn.setOrigin(0.5, 0.5);
    wh.shopdragbtn.setPosition(0, -200);
    shopdragbtn.displayWidth = 270;
    shopdragbtn.displayHeight = 60;
    wh.shopdragbtn.setInteractive();
    wh.shopdragbtn.removeAllListeners();
    wh.shopdragbtn.on('pointerout', function (pointer, localX, localY, event) {
      this.rcvarsservice.gameobj.scene.scenes[0].upgraderarcontainer.dragactive = false;
    }, this);
    wh.shopdragbtn.on('pointerup', function (pointer, localX, localY, event) {
      this.rcvarsservice.gameobj.scene.scenes[0].upgraderarcontainer.dragactive = false;
    }, this);
    wh.shopdragbtn.on('pointerdown', function (pointer, localX, localY, event) {
      this.rcvarsservice.gameobj.registry.list.gameitf.guiwindowclick(this.rcvarsservice.gameobj.scene.scenes[0].upgraderarcontainer);

      this.rcvarsservice.gameobj.scene.scenes[0].upgraderarcontainer.dragactive = true;
      this.rcvarsservice.gameobj.scene.scenes[0].upgraderarcontainer.dragactivex = this.rcvarsservice.gameobj.scene.scenes[0].upgraderarcontainer.x;
      this.rcvarsservice.gameobj.scene.scenes[0].upgraderarcontainer.dragactivey = this.rcvarsservice.gameobj.scene.scenes[0].upgraderarcontainer.y;
      this.rcvarsservice.gameobj.scene.scenes[0].upgraderarcontainer.dragactivepx = pointer.x;
      this.rcvarsservice.gameobj.scene.scenes[0].upgraderarcontainer.dragactivepy = pointer.y;
    }, this);
    this.rcvarsservice.gameobj.scene.scenes[0].upgraderarcontainer.dragactive = false;
    this.rcvarsservice.gameobj.scene.scenes[0].upgraderarcontainer.dragactivex = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].upgraderarcontainer.dragactivey = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].upgraderarcontainer.dragactivepx = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].upgraderarcontainer.dragactivepy = 0;
    wh.shopdragbtn.on('pointermove', function (pointer, localX, localY, event) {
      //this.scene.registry.list["thethis"].phm.floorclick(this);
      //this.plog.log("shop pointermove", pointer, localX, localY, event, this);
      //this.showinventory_close(wh);
      if (this.rcvarsservice.gameobj.scene.scenes[0].upgraderarcontainer.dragactive == true) {
        var tmpx = this.rcvarsservice.gameobj.scene.scenes[0].upgraderarcontainer.dragactivex;
        var tmpy = this.rcvarsservice.gameobj.scene.scenes[0].upgraderarcontainer.dragactivey;
        var tmppx = this.rcvarsservice.gameobj.scene.scenes[0].upgraderarcontainer.dragactivepx;
        var tmppy = this.rcvarsservice.gameobj.scene.scenes[0].upgraderarcontainer.dragactivepy;
        var movedx = tmppx - pointer.x;
        var movedy = tmppy - pointer.y;
        //this.plog.log("drag", tmppx, pointer.x, movedx);

        this.rcvarsservice.gameobj.scene.scenes[0].upgraderarcontainer.x = tmpx - movedx;
        this.rcvarsservice.gameobj.scene.scenes[0].upgraderarcontainer.y = tmpy - movedy;
        //this.rcvarsservice.gameobj.scene.scenes[0].upgraderarcontainer.y = pointer.y;
      }
    }, this);
    wh.upgraderarcontainer.add(wh.shopdragbtn);
    wh.upgraderarcontainer.bringToTop(wh.shopdragbtn);

    /*
    this.plog.log("dragsize",shopguibg.width, shopguibg.height);
    wh.upgraderarcontainer.setSize(shopguibg.width, shopguibg.height);
    wh.upgraderarcontainer.setInteractive();
    wh.input.on('mousemove', function(pointer) {
      //check here is a scene has a selected gameObject
      //if(this.selected) { //to be able to detect if user really select something.
          wh.setPosition(pointer.x, pointer.y);
      //}
    }, this);*/
    let loader = new Phaser.Loader.LoaderPlugin(wh);


    //move
    wh.upgraderarcontainer.x = width - (this.shopguibg.width / 2);

    /*
    //move to spritepack
        for (var vv in equipment) {
          var vdat = equipment[vv];
          var itemdat = this.rcvarsservice.allitem[vdat.item];
          loader.image(vdat["item"], this.rcvarsservice.rc_baseurl + "item/" + itemdat["pcode"] + "/" + itemdat["sprite"] + ".png");
    
          this.plog.log("load shop ", vv, vdat, itemdat);
        }*/
    loader.once(Phaser.Loader.Events.COMPLETE, () => {
      this.plog.log("Phaser.Loader.Events.COMPLETE");
      this.plog.log(this);
      this.show_loaded(wh);
    }, this);
    loader.start();
  }
  updateslotsize() {
    for (var vv in this.eqitems) {
      var vdat = this.eqitems[vv];
      this.plog.log("updateslotsize", vv, vdat);
      this.eqitems[vv].displayWidth = this.itemwh;
      this.eqitems[vv].displayHeight = this.itemwh;;
      this.wh.upgraderarcontainer.bringToTop(this.eqitems[vv]);
      //this.eqitems[vv].setInteractive(false);
      //this.eqitems[vv].setInteractive();

    }
  }
  show_loaded(wh) {
    this.plog.log("show_loaded", wh, this);

    var width = wh.game.canvas.width;
    var height = wh.game.canvas.height;
    this.wh.camman(this.wh);
    var equipment = this.rcvarsservice.activechar["equip"];
    this.plog.log("equipment eq", equipment);
    this.plog.log("upgraderarcontainer", wh.upgraderarcontainer, width, height);
    wh.upgraderarcontainer.setPosition((width / 2), (height / 2));

    var equiplist = ["weapon1", "weapon2", "head", "armor", "pant", "boot", "hand", "earring", "ring", "necklace", "bracelet"];
    for (let i = 0; i < equiplist.length; i++) {
      var vname = equiplist[i];
      if (this.eqitemsp[vname] != undefined)
        this.eqitemsp[vname].destroy();
      if (this.eqitems[vname] != undefined)
        this.eqitems[vname].destroy();
      if (this.eqitemsr[vname] != undefined)
        this.eqitemsr[vname].destroy();
    }
    for (let i = 0; i < equiplist.length; i++) {
      var vname = equiplist[i];
      this.eqitemsp[vname] = wh.add.text(0, 0, " ", wh.fs["wingui_plus"]);

      this.plog.log("eqitems i", i, vname);
      this.eqitems[vname] = wh.add.image(0, 0, "selectcircle");
      this.eqitems[vname].displayWidth = this.itemwh;
      this.eqitems[vname].name = vname;
      this.eqitems[vname].displayHeight = this.itemwh;;

      this.eqitemsr[vname] = wh.add.sprite(16, 16, "spacer");

      //this.eqitems[vname].setInteractive();
      if (vname == "weapon1") {
        this.eqitems[vname].setPosition(-118, -33);
      }
      if (vname == "weapon2") {
        this.eqitems[vname].setPosition(-118, 27);
      }
      if (vname == "head") {
        this.eqitems[vname].setPosition(118, -93);
      }
      if (vname == "armor") {
        this.eqitems[vname].setPosition(118, -33);
      }
      if (vname == "hand") {
        this.eqitems[vname].setPosition(60, -33);
      }
      if (vname == "pant") {
        this.eqitems[vname].setPosition(118, 27);
      }
      if (vname == "boot") {
        this.eqitems[vname].setPosition(118, 87);
      }
      if (vname == "ring") {
        this.eqitems[vname].setPosition(-118, 144);
      }
      if (vname == "necklace") {
        this.eqitems[vname].setPosition(-58, 144);
      }
      if (vname == "earring") {
        this.eqitems[vname].setPosition(0, 144);
      }
      if (vname == "bracelet") {
        this.eqitems[vname].setPosition(60, 144);
      }
      this.eqitemsp[vname].x = this.eqitems[vname].x - 27;
      this.eqitemsp[vname].y = this.eqitems[vname].y + 12;
      /*this.eqitems[vname].on("pointerover", function () {
        this.plog.log("equip i over", arguments, this);
      });*/


      wh.upgraderarcontainer.add(this.eqitems[vname]);
      wh.upgraderarcontainer.add(this.eqitemsp[vname]);
      wh.upgraderarcontainer.add(this.eqitemsr[vname]);
      wh.upgraderarcontainer.bringToTop(this.eqitems[vname]);
      wh.upgraderarcontainer.bringToTop(this.eqitemsp[vname]);
      wh.upgraderarcontainer.bringToTop(this.eqitemsr[vname]);
    }
    this.plog.log("eqitems", this.eqitems);


    ///this.rcvarsservice.gameobj.registry.list.gameitf.guiwindowclick(this.rcvarsservice.gameobj.scene.scenes[0].upgraderarcontainer);
    this.charicon.play(this.rcvarsservice.activechar["sprite"] + "-idle");
    var equipment = this.rcvarsservice.activechar["equip"];
    this.plog.log("equipment list", equipment);

    var griddat = [];
    var griddatf = [];

    griddat.push(this.shopclosebtn);

    for (var vv in this.eqitems) {
      var vdat = this.eqitems[vv];
      this.eqitemsp[vv].setText("");
      this.eqitems[vv].setTexture("spacer");
      var isequipped = this.finditembyequippos(wh.registry.list.rcvarpass.activechar["equip"], vv);
      if (isequipped == false) {
        this.plog.log("set event for unequipped slot ", vv);
        this.eqitems[vv].removeAllListeners();
        //this.eqitems[vv].setInteractive(new Phaser.Geom.Rectangle(-25, -25, 54, 54), Phaser.Geom.Rectangle.Contains);
        this.eqitems[vv].setInteractive();
        //this.eqitems[vv].input.hitArea.setTo(-25, -25, 25, 25);
        // this.wh.input.enableDebug(this.eqitems[vv], 0xff00ff);

        this.eqitems[vv].on("pointerover", function () {
          var ttstr = wh.registry.list.rcvarpass.equippos[this.name];
          var tmpitem = this.scene.registry.list.upgraderar.eqitems[this.name];
          wh.registry.list.gameitf.gamemenutooltip(wh, tmpitem, wh.trs("t:equippos:code:name:" + ttstr['code'] + "===" + ttstr['name']), "top");
          //this.scene.registry.list.upgraderar.updateslotsize();;
        });
      } else {
        this.plog.log("skip equipped ", vv);
      }
      this.eqitems[vv].displayWidth = this.itemwh;
      this.eqitems[vv].displayHeight = this.itemwh;;
      //this.eqitems[vv].setInteractive();

    }

    for (var vv in equipment) {
      var vdat = equipment[vv];
      var itemdat = this.rcvarsservice.allitem[vdat.item];
      if (Number(vdat["itemplus"]) != 0 && (Number(vdat["itemplus"]) + "") != "NaN") {
        this.eqitemsp[vdat["equippos"]].setText("+" + Number(vdat["itemplus"]));
      }
      this.plog.log("equipped i", vdat["equippos"], vdat, itemdat);
      this.eqitems[vdat["equippos"]].removeAllListeners();
      //this.eqitems[vdat["equippos"]].setTexture("shopguibg");
      this.eqitems[vdat["equippos"]].setTexture("item_tp", vdat["item"]);
      //this.eqitems[vdat["equippos"]].setTexture(vdat["item"]);
      //this.eqitems[vdat["equippos"]].visible = false;
      //this.eqitems[vdat["equippos"]].visible = true;
      this.eqitems[vdat["equippos"]].displayWidth = this.itemwh;
      this.eqitems[vdat["equippos"]].displayHeight = this.itemwh;
      this.eqitems[vdat["equippos"]].setInteractive();
      
      if (vdat["rarity"] == "rar") {
        this.eqitemsr[vdat['equippos']].setTexture("effrar").play("effrar_b");
      }
      if (vdat["rarity"] == "leg") {
        this.eqitemsr[vdat['equippos']].setTexture("effleg").play("effleg_b");
      }
      
      this.eqitemsr[vdat['equippos']].displayHeight = this.eqitemsr[vdat['equippos']].displayWidth = 128;
      this.eqitemsr[vdat['equippos']].setPosition(this.eqitems[vdat['equippos']].x, this.eqitems[vdat['equippos']].y);

      //this.eqitems[vdat["equippos"]].setInteractive(new Phaser.Geom.Rectangle(-25, -25, 54, 54), Phaser.Geom.Rectangle.Contains);
      /*
      //this.eqitems[vdat["equippos"]].setInteractive();*/
      this.eqitems[vdat["equippos"]].on("pointerup", function () {
        this.scene.plog.log("pointerup equipment i", this.name, this);
        this.scene.registry.list.upgraderar.show_itemclick(this.scene, this);
      });
      this.eqitems[vdat["equippos"]].on("pointerover", function () {
        var ttstr = wh.registry.list.rcvarpass.equippos[this.name];
        var tmpitem = this.scene.registry.list.upgraderar.eqitems[this.name];
        //this.plog.log(tmpitem);
        wh.registry.list.gameitf.gamemenutooltip(wh, tmpitem, ttstr["name"], "top");
      });

      griddat.push(this.eqitems[vdat["equippos"]]);
      griddatf.push(this.eqitems[vdat["equippos"]]);
    }

    for (var vv in equipment) {
      var vdat = equipment[vv];
      this.plog.log("equipped i", vdat["equippos"], vdat, itemdat);
      
      wh.upgraderarcontainer.bringToTop(this.eqitems[vdat["equippos"]]);

    }
    this.wh.registry.list.gp.guigrid(griddat, griddatf);

  }


  async show_itemclick(wh, item) {
    this.selectcircle.visible = true;
    //var namea = item.name.split("-");
    this.plog.log("show_itemclick", wh, item);
    this.selectcircle.displayWidth = this.itemwh;
    this.selectcircle.displayHeight = this.itemwh;
    item.displayWidth = this.itemwh;
    item.displayHeight = this.itemwh;
    this.selectcircle.setPosition(item.x, item.y)
    //wh.upgraderarcontainer.sendToBack(wh.shopguibg);
    wh.upgraderarcontainer.bringToTop(this.selectcircle);
    wh.upgraderarcontainer.bringToTop(item);
    this.plog.log("show_itemclick selectcircle", item.name, item, wh.registry.list.rcvarpass.activechar);
    var iteminfo = this.finditembyequippos(wh.registry.list.rcvarpass.activechar["equip"], item.name);
    //wh.registry.list.rcvarpass.activechar["equip"][name];
    var iteminfo2 = wh.registry.list.rcvarpass.allitem[iteminfo["item"]];
    this.plog.log("show_itemclick selectcircle iteminfo2", iteminfo);
    if (iteminfo["itemplus"] >= 9) {
      wh.registry.list.rctoast.rcalert(wh,
        wh.trs("f:upgraderarcontainer:Maxinum upgraded===Maxinum upgraded"),
        wh.trs("f:upgraderarcontainer:This item already reached maxinum upgrade level===This item already reached maxinum upgrade level"));
      return;
    }
    this.selecteditemid = item.name;
    /*wh.registry.list.pickitem.show(wh, this.itemupgrade, "upgraderarresource", iteminfo["itemplus"], 
      wh.trs("f:upgraderarcontainer:Select item use to upgrade===Select item use to upgrade")
    );*/
    var info = await this.wh.registry.list.rchttp.getcommon2("upgraderar", {
      mode: "getinfo",
      itempiece: this.selecteditemid
    });
    if (info != undefined && info["result"] == "ok" && info['msg'] != undefined && info['msg'] != "") {
      var cfm = await this.wh.registry.list.rctoast.rcconfirm(this.wh,
        this.wh.trs("f:upgradeitemcontainer:Upgrade confirmation===Upgrade confirmation"),
        info['msg']);
      if (cfm == "ok") {
        //upgrade
        var upgraderes = await this.wh.registry.list.rchttp.getcommon2("upgraderar", {
          mode: "doupgrade",
          itempiece: this.selecteditemid
        });
        if (upgraderes == "success") {
          //this.wh.registry.list.showreward.show(this.wh,["text:Upgrade Success!"]);
          this.wh.registry.list.showreward.show(this.wh, ["msgred:Upgrade Success!"], {
            bg: 'combatwin',
            text: this.wh.trs("f:upgradeitemcontainer:Upgrade Success!!===Upgrade Success!!"),
          });
          this.show_close(this.wh);
          await this.wh.registry.list.rchttp.updatecharinfo();
        }
      }
    }
    //async show(wh, cbfunc, invenmode,param2 ="", itemdescrtxt = "Select Item") {

    //this.selectcircle.width=
    //this.itemdescr.setText(iteminfo2["name"] + " x" + iteminfo["amnt"]);
    //this.itemdescr.setText(iteminfo2["name"]);
    this.rcosd.show(wh, "itemnoeq", iteminfo2, iteminfo);
  }
  async show_close(wh) {

    wh.upgraderarcontainer.visible = false;
    await wh.registry.list.gameitf.closegui(wh, wh.upgraderarcontainer);
    for (const k in this.wh.upgraderarcontainer.list) {
      try {
        await this.wh.upgraderarcontainer.remove(this.wh.upgraderarcontainer.list[k], true);
        //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list[k].destroy();
      } catch (e) {
        this.plog.log("ERROR! upgraderarcontainer.destroy() ", e);
      }
    }

    for (const k in this.wh.upgraderarcontainer.list) {
      try {
        await this.wh.upgraderarcontainer.remove(this.wh.upgraderarcontainer.list[k], true);
        //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list[k].destroy();
      } catch (e) {
        this.plog.log("ERROR! upgraderarcontainer.destroy() ", e);
      }
    }
    wh.upgraderarcontainer = undefined;
  }
  finditembyequippos(arr, pos) {
    this.plog.log("finditembyequippos", arr, pos);
    for (var vv in arr) {
      var vdat = arr[vv];
      if (vdat["equippos"] == pos) {
        this.plog.log("finditembyequippos found", vdat);
        return vdat;
      }
    }
    this.plog.log("finditembyequippos NOT FOUND");
    return false;
  }

  rebuildguigrid() {
    this.plog.log("rebuildguigrid();b", this.wh.kbfocus);
    this.show(this.wh);
    this.plog.log("rebuildguigrid();a", this.wh.kbfocus);
  }
}
