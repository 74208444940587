//SCRW - Social Reward, PlayStore/iOS leaderboard and achievement
import { Injectable } from '@angular/core';
//import { CapacitorGameConnect } from '@openforge/capacitor-game-connect';
import { RCStorage } from './rcstorage.service';
//import { Storage } from '@ionic/storage-angular';
//import { CapacitorGameConnectPlugin } from '@openforge/capacitor-game-connect';
//import { Storage } from '@ionic/storage';
//import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
//import { CapacitorGameConnect } from '@openforge/capacitor-game-connect';

import { plog } from './plog.service';

@Injectable({
  providedIn: 'root'
})

export class SCRW {
  gameconn;
  plog;

  platform = "";
  //private gameconn: CapacitorGameConnectPlugin

  constructor(
    private rcstorage: RCStorage
  ) {
    //this.storage = new RCStorage(new Storage);
    this.init();
    this.plog = new plog();
    //this.plog.setLevel(4);
  }

  async init() {

  }
  public async signin() {
    //var loginresult = await CapacitorGameConnect.signIn();
    this.plog.log("singin");
    var loginresult;
    try {
      // loginresult = await CapacitorGameConnect.signIn();
      this.plog.log('User signed in successfully', loginresult);
    } catch (error) {
      console.error('An error occurred during sign-in:', error);
    }
    this.plog.log('loginresult', loginresult);

  }
  work_loading = false;
  wh;
  archidata = [];
  badgeshowing = false;
  async firstloaded() {
    //this.archidata = await this.wh.registry.list.rchttp.getcommon2('archimedal');
    //this.plog.log(this);
    this.work_loading = false;
  }
  async work(wh, dat) {
    this.wh = wh;
    this.plog.log(wh, dat, this);
    if (this.work_loading == false) {

      this.work_loading = true;
      if (typeof dat === 'object' && !Array.isArray(dat) && dat !== null) {
        if (typeof dat['archi'] === 'object' && !Array.isArray(dat['archi']) && dat['archi'] !== null) {
          if (this.archidata["archi"] == undefined)
            this.archidata["archi"] = await this.wh.registry.list.rchttp.getcommon2('archimedal');
        }
        if (typeof dat['archistack'] === 'object' && !Array.isArray(dat['archistack']) && dat['archistack'] !== null) {
          if (this.archidata["archistack"] == undefined)
            this.archidata["archistack"] = await this.wh.registry.list.rchttp.getcommon2('archistack');
        }
        if (typeof dat['archiitem'] === 'object' && !Array.isArray(dat['archiitem']) && dat['archiitem'] !== null) {
          if (this.archidata["archiitem"] == undefined)
            this.archidata["archiitem"] = await this.wh.registry.list.rchttp.getcommon2('archiitem');
        }
        if (typeof dat['archimap'] === 'object' && !Array.isArray(dat['archimap']) && dat['archimap'] !== null) {
          if (this.archidata["archimap"] == undefined)
            this.archidata["archimap"] = await this.wh.registry.list.rchttp.getcommon2('archimap');
        }
        if (typeof dat['archimob'] === 'object' && !Array.isArray(dat['archimob']) && dat['archimob'] !== null) {
          if (this.archidata["archimob"] == undefined)
            this.archidata["archimob"] = await this.wh.registry.list.rchttp.getcommon2('archimob');
        }
        if (typeof dat['archiquest'] === 'object' && !Array.isArray(dat['archiquest']) && dat['archiquest'] !== null) {
          if (this.archidata["archiquest"] == undefined)
            this.archidata["archiquest"] = await this.wh.registry.list.rchttp.getcommon2('archiquest');
        }
      }
      this.work_loading = false;
    }
    if (wh.game.textures.list["archi_archi_tp"] == undefined) {
      if (this.work_loading == false) {


        var loaderlocal = new Phaser.Loader.LoaderPlugin(
          wh
        ); // + Math.random()+ Math.random()
        var svversionurlstring = wh.registry.list.rcvarpass.svversionstring;
        if (svversionurlstring != '') {
          svversionurlstring = '?' + svversionurlstring;
        }
        loaderlocal.atlas(
          "archi_archi_tp" + '',
          wh.registry.list.rcvarpass.rc_baseurl + 'sprite/' + "archi_archi_tp" + '.png' +
          svversionurlstring,
          wh.registry.list.rcvarpass.rc_baseurl + 'sprite/' + "archi_archi_tp" + '.json' +
          svversionurlstring
        );
        //this.plog.log("loadcatsprite", Phaser.Loader.Events, sprite, this, loaderlocal);
        loaderlocal.once(
          Phaser.Loader.Events.COMPLETE,
          () => {
            // texture loaded so use instead of the placeholder
            this.firstloaded();
            //this.plog.log("oncomplete ", this);
          },
          this
        );
        //this.plog.log("loadcatsprite afterset", Phaser.Loader.Events);
        loaderlocal.start();

      }
      this.work_loading = true;
      //load 
      return;
    }
    this.plog.log("scrw do real work", dat);
    this.platform = await this.rcstorage.get("remember_platform", true);
    //this.plog.log("platform", this.platform);
    //this.plog.log("dat", dat);
    //if (!Array.isArray(dat)) return;
    if (typeof dat === 'object' && !Array.isArray(dat) && dat !== null) {
      for (const k in dat) {
        for (const k2 in dat[k]) {
          if (dat[k][k2] != undefined) {
            this.showbadge("" + k2, dat[k][k2], "" + k);
            //console.log("scrw showing badge", k2, dat[k][k2], "" + k);
            if (k == "archimap" && k2 == "home" && dat[k][k2] == 1) {
              wh.registry.list.tut.show(wh, "firstenterhome");
            }
          }
        }


      }
    }

  }
  badge_icon;
  badge_bg;
  badge_pgbg;
  badge_pbfg;
  badge_head;
  badge_descr;
  badge_showslot = [];
  lastdsp = [];
  showbadge(id, cc, dataset) {
    /*if (this.badgeshowing == true) {
      return;//show 1 at a time
    }*/
    //this.plog.log("showbadge", id);
    //this.plog.log(id, this.archidata)
    var width = this.wh.game.canvas.width;
    var height = this.wh.game.canvas.height;
    var iconsize = 32;
    var border = 6;
    var margin = 0;
    var toppos = 200;
    var fullw = 200;
    var thisshowslot = -1;
    for (var sloti = 0; sloti <= 5; sloti++) {
      if (this.badge_showslot[sloti] == undefined || this.badge_showslot[sloti] == -1) {
        thisshowslot = sloti + 0;
        break;
      }
    }
    //this.plog.log("XX", id, thisshowslot, this.badge_showslot);
    if (thisshowslot == -1) {
      this.plog.log("cant find showslot");
      return;
    }
    if (this.archidata[dataset] == undefined) {
      this.plog.log("archi data empty", dataset, this.archidata[dataset]);
      return;
    }
    toppos = toppos + (thisshowslot * 46);
    var headtext = "";
    var descrtext = "";// + cc;
    var maxfreqtime = 60 * 0.1;
    if (this.lastdsp[dataset] != undefined && this.lastdsp[dataset] > this.wh.registry.list.timectl.humantime - maxfreqtime) {
      if (this.archidata[dataset] != undefined) return;
      this.archidata[dataset][id]['cc'] = this.archidata[dataset][id]['cc'] + cc;
      this.plog.log("skip for just displayed");
      return;
    }
    this.lastdsp[dataset] = this.wh.registry.list.timectl.humantime + 0;
    switch (dataset) {
      case "archi":
        this.plog.log("*" + dataset, this.archidata[dataset], id)
        if (this.archidata[dataset][id] == undefined) {
          //unregistered archi
          this.plog.log("unregistered archi", dataset, id)
          return;
        }
        if (this.archidata[dataset] == undefined || this.archidata[dataset][id] == undefined) return;

        headtext = this.archidata[dataset][id]['name'];
        descrtext = "";
        break;
      case "archistack":
        this.plog.log("*" + dataset, this.archidata[dataset], id)
        headtext = "Obtain StackItem";
        if (this.archidata[dataset] == undefined || this.archidata[dataset][id] == undefined) return;

        descrtext = this.archidata[dataset][id]['name'];
        this.plog.log(this.wh.registry.list.rcvarpass);
        break;
      case "archiitem":
        this.plog.log("*" + dataset, this.archidata[dataset], id)
        headtext = "Obtain Item";
        if (this.archidata[dataset] == undefined || this.archidata[dataset][id] == undefined) return;
        descrtext = this.archidata[dataset][id]['name'];
        this.plog.log(this.wh.registry.list.rcvarpass);
        break;
      case "archimob":
        this.plog.log("*" + dataset, this.archidata[dataset], id)
        headtext = "Monster Slain";
        if (this.archidata[dataset] == undefined || this.archidata[dataset][id] == undefined) return;
        descrtext = this.archidata[dataset][id]['name'];
        this.plog.log(this.wh.registry.list.rcvarpass);
        break;
      case "archimap":
        this.plog.log("*" + dataset, this.archidata[dataset], id)
        headtext = "Enter Map";
        if (this.archidata[dataset] == undefined || this.archidata[dataset][id] == undefined) return;
        descrtext = this.archidata[dataset][id]['name'];
        this.plog.log(this.wh.registry.list.rcvarpass);
        break;

      default:
        this.plog.log("*default", dataset, this.archidata[dataset], id)
        headtext = id;
        break;
    }

    if (this.archidata[dataset][id] != undefined && this.archidata[dataset][id]['name'] != undefined) {
      descrtext += " " + this.archidata[dataset][id]['cc'] + " +" + cc;
      this.archidata[dataset][id]['cc'] = this.archidata[dataset][id]['cc'] + cc;
      var starstr = "";
      var maxstepnumber = 0;
      var maxstepnumberuse = "";
      for (var stari = 1; stari <= 2; stari++) { //show only if first 2 level
        //69+9993+66666666666666this.plog.log("starconfig" + stari, id, this.archidata['STARCONFIG'], this.archidata[id]['cc'])
        if (this.archidata[dataset][id]['cc'] > this.archidata[dataset]['STARCONFIG'][stari]['num']) {
          starstr += "★";
        } else {
          starstr += "☆";
          if (maxstepnumber == 0) maxstepnumber = this.archidata[dataset]['STARCONFIG'][stari]['num'];
        }
      }
      if (maxstepnumber != 0) {
        maxstepnumberuse = "/" + maxstepnumber;
        descrtext = descrtext + " " + maxstepnumberuse + " " + starstr;
      } else {
        this.plog.log("Dont show for max lv");
        return;//don't show if reach max lv of whatever

      }
    }

    this.badge_showslot[sloti] = 1;

    this.badge_bg = this.wh.add.sprite(0, 0, 'thecolor', 'white');
    this.badge_bg.name = thisshowslot;
    this.badge_icon = this.wh.add.sprite(0, 0, 'archi_archi_tp', '' + id);
    this.badge_head = this.wh.add.text(0, 0, '' + headtext, this.wh.fs["scrw_badgehead"]);
    this.badge_descr = this.wh.add.text(0, 0, '' + descrtext, this.wh.fs["scrw_badgedescr"]);
    this.wh.gifscontainer.add(this.badge_bg);
    this.wh.gifscontainer.add(this.badge_icon);
    this.wh.gifscontainer.add(this.badge_head);
    this.wh.gifscontainer.add(this.badge_descr);
    this.badge_bg.setOrigin(0, 0);
    this.badge_icon.setOrigin(0, 0);
    this.badge_head.setOrigin(0, 0);
    this.badge_descr.setOrigin(0, 0);
    this.wh.gifscontainer.bringToTop(this.badge_bg);
    this.wh.gifscontainer.bringToTop(this.badge_icon);
    this.wh.gifscontainer.bringToTop(this.badge_head);
    this.wh.gifscontainer.bringToTop(this.badge_descr);
    this.badge_bg.displayHeight = iconsize + border * 2;
    this.badge_bg.displayWidth = fullw;
    this.badge_icon.displayWidth = this.badge_icon.displayHeight = 1;
    this.badge_bg.setPosition(width, toppos);
    this.badge_icon.setPosition(width - (iconsize + (border * 1) + margin), toppos + border);
    this.badge_head.setPosition(width + iconsize, toppos + 0);
    this.badge_descr.setPosition(width + iconsize, toppos + 16);

    var timeline = this.wh.add.timeline([
      {
        at: 0,
        tween: {
          targets: this.badge_bg,
          x: { from: width, to: width - (iconsize + (border * 2) + margin) },
          ease: 'Sine.easeInOut',
          duration: 300,
        }
      },
      {
        at: 200,
        tween: {
          targets: this.badge_icon,
          displayWidth: { from: 1, to: iconsize },
          displayHeight: { from: 1, to: iconsize },
          ease: 'Sine.easeInOut',
          duration: 200,
        }
      },


      {
        at: 1000,
        tween: {
          targets: this.badge_bg,
          x: { from: width - (iconsize + (border * 2) + margin), to: width - fullw },
          ease: 'Sine.easeOut',
          duration: 300,
        }
      },
      {
        at: 1000,
        tween: {
          targets: this.badge_icon,
          x: { from: this.badge_icon.x, to: (width - fullw) + border },
          ease: 'Sine.easeOut',
          duration: 300,
        }
      },
      {
        at: 1000,
        tween: {
          targets: this.badge_head,
          x: { from: this.badge_head.x, to: (width - fullw) + border * 2 + iconsize },
          ease: 'Sine.easeOut',
          duration: 300,
        }
      },
      {
        at: 1000,
        tween: {
          targets: this.badge_descr,
          x: { from: this.badge_descr.x, to: (width - fullw) + border * 2 + iconsize },
          ease: 'Sine.easeOut',
          duration: 300,
        }
      },


      {
        at: 4500,
        tween: {
          targets: this.badge_bg,
          x: { from: width - fullw, to: width },
          ease: 'Sine.easeIn',
          duration: 200,
        }
      },
      {
        at: 4500,
        tween: {
          targets: this.badge_icon,
          x: { from: width - fullw, to: width + border },
          ease: 'Sine.easeIn',
          duration: 200,
        }
      },
      {
        at: 4500,
        tween: {
          targets: this.badge_head,
          x: { from: this.badge_head.x, to: width + border + iconsize },
          ease: 'Sine.easeIn',
          duration: 200,
        }
      },
      {
        at: 4500,
        tween: {
          targets: this.badge_descr,
          x: { from: this.badge_descr.x, to: width + border + iconsize },
          ease: 'Sine.easeIn',
          duration: 200,
        }
      }
    ]
    );

    timeline.on('complete', function () {
      //this.plog.log("redirjump timelinecomplete ", wh, zwnameclickeda);
      //this.chkwarppos_warp(wh, zwnameclickeda[1]);
      //this.plog.log(arguments,this);
      //this.plog.log(arguments);
      try {
        this.badge_showslot[arguments[0]] = -1;
      } catch (e) {
        console.log(e);
      }
      var thetween = arguments[1];
      //this.plog.log(thetween);
      if (thetween['events'] == undefined) return;
      this.badge_showing--;
      for (const k in thetween['events']) {
        var v = thetween['events'][k];
        //this.plog.log(k,v);
        if (v == undefined) continue;
        if (v['tween'] == undefined) continue;
        if (v['tween']['targets'] == undefined) continue;

        try {
          //this.plog.log("ocp destroying",v['tween']['targets']);
          v['tween']['targets'].destroy();
        } catch (e) {
          console.log(e);
        }
      }
    }.bind(this, thisshowslot));
    timeline.play();
  }

}
