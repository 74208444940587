<ion-app>
  <ion-content overflow-scroll="false">
    <div id="odspdiv" class="">
      <div id="odspdivct" (click)="$event.preventDefault(); $event.stopPropagation(); tmplog($event,this);"
        (pointerdown)="$event.preventDefault(); $event.stopPropagation(); tmplog($event,this);"></div>
      <div class="background" (click)="$event.preventDefault(); $event.stopPropagation(); tmplog($event,this);"
        (pointerdown)="$event.preventDefault(); $event.stopPropagation(); tmplog($event,this);"></div>
    </div>
    <div id="thecanvas" style="	pointer-events: inherit !important;">
    </div>
    <div id="thecanvasoverlay"></div>
    <div id="overlay">
      <div id="overlaypage_shop" class="overlayContent"></div>
    </div>
    <div id="overlaybg"></div>
  </ion-content>
</ion-app>