import { Injectable } from '@angular/core';
import { Drivers, Storage } from '@ionic/storage';

//import { Storage } from '@ionic/storage';
//import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';

@Injectable({
  providedIn: 'root'
})

export class RCStorage {
  private _storage: Storage | null = null;
  settingcache = [];
  cachedb;
  //private _storage: Storage ;
  constructor(
    private storage: Storage
  ) {

    this.init();

  }

  async init() {
    // If using, define drivers here: await this.storage.defineDriver(/*...*/);
    const storage = await this.storage.create();
    //await this.storage.defineDriver(CordovaSQLiteDriver);
    this._storage = storage;
    /*this.cachedb = new Storage({
      name: '__mycache',
      storeName: '_cachedb',
      driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
    });
    this.cachedb.create();
    this.cacheset("test","jjj") */
  }

  // Create and expose methods that users of this service can
  // call, for example:
  async set(key: string, value: any) {
    this.storage.set(key, value);
    while (true) {
      await this.storage.set(key, value);
      var savedrap = await this.storage.get(key);
      if (savedrap == value) break;
    }
    return true;
  }
  async get(key: string, cacheok = false): Promise<string> {
    //console.log(this);
    if (cacheok == true) {
      //console.log("Storage get cachok",key);
      if (this.settingcache[key] != undefined) {
        //console.log("Storage get cachok got",key,this.settingcache[key]);
        return this.settingcache[key];
      }
    }
    try {
      const result = await this.storage.get(key);
      //console.log("result",result);
      return result;
    }
    catch (e) {
      console.log(e)
    }

    return await this.storage.get(key);
    this.storage.get(key).then((val) => {
      console.log("get " + key, val);
      return val;
    });
  }
  public async remove(settingName) {
    return await this.storage.remove(`setting:${settingName}`);
  }
  public clear() {
    this.storage.clear().then(() => {
      console.log('all keys cleared');
    });
  }
  public async setupcache() {
    await this.storage.forEach((value, key) => {
      //console.log("listall:",key,value);
      if (key.indexOf("z_") == -1) {
        this.settingcache[key] = value;
        // console.log("listall:setup", key, value);
      }
      //
    });
    //console.log("settingcachestorage:", this.storage);
    //console.log("settingcache:", this.settingcache);
  }
  public listall() {
    this.storage.forEach((value, key) => {
      //console.log("listall:",key,value);
    });
  }
  async resettutorial() {
    this.storage.forEach((value, key) => {
      console.log("resettutorial:", key, value);
      if (key.indexOf("ztutdone_") != -1) {
        this.set(key, "no");
      }
    });
    this.set("ztutdone_mapload-afterlogin", "yes");//too dangerouse to redo this
    this.set("ztutdisable", "no");
  }
  //////////////////////////////////////////////////////////////////////
  /*
  async cacheset(key: string, value: any) {
    this.cachedb.set(key, value);
    while (true) {
      await this.cachedb.set(key, value);
      var savedrap = await this.cachedb.get(key);
      if (savedrap == value) break;
    }
    return true;
  }*/
}
