import { AStarFinder } from 'astar-typescript';
import { plog } from './plog.service';

export class MapBird {
  o;
  wh;
  currentx;
  currenty;
  goingtox;
  goingtoy;
  sprite;
  doing = '';
  status = "fly";
  behavetimer;
  doinginprocess = false;
  astar;
  hidecountdown = 0;
  walkarray = [];
  lastspriteface = 'd';
  idlecountdown = 0;
  actionspeed;
  plog;
  constructor(wh, o) {
    this.plog = new plog();
    this.wh = wh;
    this.o = o;
    this.plog.setLevel(0);
    //this.plog.log('MapBird constructor', wh, o);
    if (this.o['sprite'] == undefined) return;
    var mobchkmax = 10;
    var mobchkmin = 5;
    var monnum = Math.floor(
      Math.random() * (mobchkmax - mobchkmin) + mobchkmin
    );
    this.actionspeed = Math.floor(Math.random() * (1500 - 600) + 600);
    this.idlecountdown = monnum;
    this.astar = new AStarFinder({
      grid: {
        matrix: this.wh.registry.list.rcvarpass.activemapmetrix,
      },
      includeStartNode: false,
      includeEndNode: true,
    });
    //this.plog.log("critter astar", this.astar);
    this.behavetimer = this.wh.time.addEvent({
      delay: this.actionspeed,
      callback: this.behave,
      callbackScope: this,
      loop: true,
    });
    //this.wh.mobs.push(this);
    this.wh.mobs[this.o.petid] = this;

  }
  getobj() {
    return this.o;
  }
  talk() {
    this.plog.log('critter talk', this.o.talk);
    if (this.o.talk == undefined || this.o.talk == '') return;
    var items = this.o.talk.split('|');
    var item = items[Math.floor(Math.random() * items.length)];
    this.wh.registry.list.phm.charsaybubble(this.sprite, item);
  }
  spawn(optionalpos?) {
    if (this.o['sprite'] == undefined) return;
    //if (this.o==undefined) this.destroy();
    const tmp = this.wh.add.sprite(64, 64, this.o.sprite);
    //var actarr = ["u", "d", "l", "r"];
    //this.plog.log('mapbird.spawn();', optionalpos);
    this.plog.log("mapbird.spawn();", optionalpos);
    tmp.play(this.o['sprite'] + '_idle'); //.setDisplayOrigin(0.5, 0.8);
    ///this.wh.zsortlist.push(tmp); // so it won't appear under fg
    tmp.mapmonobj = this;
    //tmp.setScale(this.o["mapsize"]);
    this.sprite = tmp;
    this.sprite.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);
    /*
        this.sprite.setInteractive();
        this.sprite.on(
          'pointerdown',
          function () {
            this.talk();
          },
          this
        );*/
    //this.plog.log('crit', this.wh);
    //var tmp = this.rcvarsservice.gameobj.scene.scenes[0].add.image((spawnposa[1] * 32), (spawnposa[0] * 32), this.rcvarsservice.activechar["sprite"], "walk-d-1").setDepth(999);
    this.wh.mapcontainer.add(tmp);
    this.wh.mapcontainer.bringToTop(tmp);
    this.showspritewalk("right", "fly");
    this.sprite.play(this.o['sprite'] + '_fly'); //.setDisplayOrigin(0.5, 0.8);
    // this.status = "landing";
    //this.movetorandomarea(optionalpos);
    /*

    var moveto = this.getrandwalkpos() + '';
    var movetoa = moveto.split('-');
    var startPos = { x: this.currentx, y: this.currenty };
    var goalPos = { x: movetoa[0], y: movetoa[1] };
    //this.plog.log("I'mma walk to ", movetoa);
    var myPathway = this.astar.findPath(startPos, goalPos);
    //this.plog.log(myPathway);
    if (myPathway.length > 1) {
      myPathway = myPathway.slice(0, 1);
    }
    this.walkarray = myPathway;
*/
    //this.plog.log("charic",charic);
    //this.plog.log("moveTo", this.charzindex, this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer);
    //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.moveTo(tmp, this.charzindex);
    //this.rcvarsservice.gameobj.scene.scenes[0].char_main = tmp;
    this.sprite.play(this.o['sprite'] + '_fly');
    this.sprite.displayHeight = 64; // * this.o["mapsize"];
    this.sprite.displayWidth = 64; // * this.o["mapsize"];
    //this.behave();
  }
  landing(movetoa) {
    //movetoa = ["32", "24"];
    this.plog.log("mapbird.landing()", movetoa)
    this.status = "landing";
    //this.sprite.play(this.o['sprite'] + '_fly');
    /*
        var randx = Math.floor(
          //Math.random() * (500 - -1000) + -1000
          0 - (movetoa[0] * 32) - 500
        );
        var randy = -1000
    
        this.sprite.x = randx;
        this.sprite.y = randy;
    */

    var localdir = "left";


    this.goingtox = movetoa[0];
    this.goingtoy = movetoa[1];
    var destx = (Number(movetoa[0]) - 1) * 32;
    var desty = (Number(movetoa[1]) - 1) * 32;

    this.sprite.setAlpha(1);
    this.sprite.x = destx - 1000;
    this.sprite.y = desty - 1000;
    this.wh.mapcontainer.bringToTop(this.sprite);

    if (destx > this.sprite.x) {
      localdir = "right";
    } else {
      localdir = 'left';
    }
    this.showspritewalk(localdir, "fly");

    if (this.sprite.y > desty) {
      this.sprite.y = desty - 300;
    }
    //this.plog.log("bird landing from ", this.sprite.x, this.sprite.y);
    var dist = this.calculateDistance(this.sprite.x, this.sprite.y, destx, desty);
    var timeuse = Math.floor(dist * 4);
    //this.sprite.x = destx;
    //this.sprite.y = desty; 

    var timelinel = this.wh.add.timeline([
      {
        at: 1,
        tween:
        {
          targets: this.sprite,
          x: { from: this.sprite.x, to: destx },
          ease: 'Linear',
          duration: timeuse,
        }
      }, {
        at: 1,
        tween:
        {
          targets: this.sprite,
          y: { from: this.sprite.y, to: desty, },
          ease: 'Sine.EaseIn',
          duration: timeuse,
        }
      }, {
        at: 1,
        tween:
        {
          targets: this.sprite,
          scale: { from: 3, to: 1 },
          ease: 'Sine.EaseIn',
          duration: timeuse,
        }
      }, {
        at: timeuse + 5,
        run: function () {
          // this.plog.log("redirjump timelinecomplete ", wh, zwnameclickeda);
          //this.chkwarppos_warp(wh, zwnameclickeda[1]);
          this.plog.error("mapbird landcomplete", timelinel);
          this.status = "land";
          this.currentx = this.goingtox;
          this.currenty = this.goingtoy;
          var action = ['left', 'right'];
          var randomactioni = Math.floor(Math.random() * action.length);
          var randomaction = action[randomactioni];
          try {
            this.showspritewalk(randomaction, "land");
          } catch (e) {
            this.plog.log("ERROR", e);
          }
        }.bind(this)
      }
    ]);

    this.showspritewalk(localdir, "fly");
    timelinel.play();
  }
  movetorandomarea(setpos?) {
    if (setpos == '' || setpos == undefined) {
      var moveto = this.getrandwalkpos() + '';
      var movetoa = moveto.split('-');
    } else {
      movetoa = setpos;
    }
    ///

    if (this.status == "fly") {
      this.landing(movetoa);
      return;
    }
    //this.plog.log('crit moveto', movetoa);
    this.currentx = Number(movetoa[0]);
    this.currenty = Number(movetoa[1]);
    this.sprite.x = (this.currentx - 1) * 32; //+width/2;
    this.sprite.y = (this.currenty - 1) * 32; //+width/2;
    this.sprite.displayHeight = 64; // * this.o["mapsize"];
    this.sprite.displayWidth = 64; // * this.o["mapsize"];
    this.sprite.setOrigin(0.5, 0.8);
    //this.plog.log(random, months[random]);
  }
  getrandwalkpos() {
    var mobwalkarr = this.wh.registry.list.rcvarpass.activemap['walkable'];
    if (mobwalkarr == undefined) {
      console.warn('critter movetorandomarea: walk not found');
    }
    var usablemobwalk = [];
    for (var key in mobwalkarr) {
      var val = mobwalkarr[key];
      if (val == 'yn_y') {
        usablemobwalk.push(key);
      }
    }
    var randompos = Math.floor(Math.random() * usablemobwalk.length);
    var moveto = usablemobwalk[randompos];
    //this.plog.log('mapmon movetorandomarea: usablemobwalk', usablemobwalk);
    //this.plog.log('mapmon movetorandomarea: randompos', moveto);
    return moveto;
  }
  findnextclosestwalkable() {
    var mobwalkarr = this.wh.registry.list.rcvarpass.activemap['walkable'];
    var closestdist = 1000;
    var bestpos = '';
    for (var key in mobwalkarr) {
      var val = mobwalkarr[key];
      if (val == 'yn_y') {
        var keya = key.split('-');
        //this.plog.log("findnextclosestwalkable keya",keya);
        var dist = Math.sqrt(
          Math.pow(this.currentx - Number(keya[0]), 2) +
          Math.pow(this.currenty - Number(keya[1]), 2)
        );
        //this.plog.log("findnextclosestwalkable dist",dist);
        if (dist < closestdist) {
          closestdist = dist;
          bestpos = key;
        }
      }
    }
    return bestpos;
  }

  takeoff() {
    this.plog.log("takeoff");
    var countdown = 10;
    this.hidecountdown = (countdown * 1000) / this.actionspeed;

    //this.status = "fly";
    this.status = "takingoff";
    var destx = Math.floor(
      Math.random() * (4500 - -1000) + -1000
    );
    var desty = Math.floor(
      Math.random() * (4500 - -1000) + -1000
    );


    if (desty > this.sprite.y) {
      desty = this.sprite.y - 300;
    }
    var destx = ((Number(this.currentx) - 1) * 32) + 1000;
    var desty = ((Number(this.currenty) - 1) * 32) - 500;

    var dist = this.calculateDistance(this.sprite.x, this.sprite.y, destx, desty);

    var timeuse = Math.floor(dist * 4);

    var localdir = "left";
    if (destx > this.sprite.x) {
      localdir = "right";
    } else {
      localdir = 'left';
    }
    this.showspritewalk(localdir, "fly");
    this.wh.mapcontainer.bringToTop(this.sprite);

    var timeline = this.wh.add.timeline([
      {
        at: 1,
        tween:
        {
          targets: this.sprite,
          x: { from: this.sprite.x, to: destx },
          ease: 'Sine.easeInOut',
          duration: timeuse,
          yoyo: false,
        }
        ,
      }, {
        at: 1,
        tween:
        {
          targets: this.sprite,
          y: {
            from: this.sprite.y, to: desty,
          },
          ease: 'Sine.easeOut',
          duration: timeuse,
          offset: 0,
        }
        ,
      }, {
        at: 1,
        tween:
        {
          targets: this.sprite,
          scale: { from: 1, to: 3 },
          ease: 'Sine.EaseIn',
          duration: timeuse,
        }
      }, {
        at: timeuse + 5,
        run: function () {
          this.status = "fly";
          this.plog.error("mapbird tookoff complete");
        }.bind(this)
      }, {
        at: timeuse + 5,
        tween:
        {
          targets: this.sprite,
          alpha: { from: 1, to: 0 },
          ease: 'Sine.EaseIn',
          duration: 100,
        }
      }
    ]);

    timeline.play();
  }

  calculateDistance(x1, y1, x2, y2) {
    const deltaX = x2 - x1;
    const deltaY = y2 - y1;
    return Math.sqrt(deltaX * deltaX + deltaY * deltaY);
    /*
    const deltaX = x2 - x1;
    const deltaY = y2 - y1;
    const distance = Math.sqrt(deltaX ** 2 + deltaY ** 2);
    return distance;*/
  }
  behave() {
    this.plog.log("mapbird.behave()", this.status);
    if (this.status == "landing") {
      return;
    }
    if (this.status == "takingoff") {
      return;
    }

    if (this.hidecountdown > 0) {
      //this.plog.log("bird hidecountdown ", this.hidecountdown);


      this.hidecountdown = this.hidecountdown - 1;
      if (this.wh.registry.list.timectl.timename == "night" && this.hidecountdown < 1) {
        this.hidecountdown = 2;
      }
      return;
    }

    if (this.status == "fly") {

      var moveto = this.getrandwalkpos() + '';
      var movetoa = moveto.split('-');
      this.landing(movetoa);
      return;
    }
    if (this.astar == undefined) {
      return;
    }
    if (
      this.wh.combatcontainer != undefined &&
      this.wh.combatcontainer.list.length > 0
    ) {
      return;
    }

    if (this.sprite != undefined && this.sprite.scene == undefined) {
      this.plog.log("error, mapmob.behave(), this.sprite.scene=undefined");
      this.mydestroy();
      return;
    }

    //takeoff if close to user
    //this.plog.log("updatezmapchar_cache",this.wh.registry.list.phm.updatezmapchar_cache);
    if (this.wh.registry.list.phm.updatezmapchar_cache != undefined) {
      for (const k in this.wh.registry.list.phm.updatezmapchar_cache) {
        var v = this.wh.registry.list.phm.updatezmapchar_cache[k];
        var zpos = v['mappos'] + '';
        if (zpos == "undefined") continue;
        var zposa = zpos.split('-');
        var startPos = { x: this.currentx, y: this.currenty };
        var goalPos = { x: zposa[0], y: zposa[1] };
        //this.plog.log("I'mma walk to ", movetoa);
        try {
          var distfromz = this.astar.findPath(startPos, goalPos);
        } catch (e) {
          this.plog.log("MapBird astar findPath error", e);
          return;
        }

        if (distfromz.length < 5) {
          this.takeoff();
          return;
        }
      }
    }
    //this.plog.log("behaving", this);
    if (this.sprite != undefined) {
      this.sprite.scale = 1;
    }
    var action = ['walk', 'idle']; //, "sleep"
    //var action = ["walk"];

    if (this.doing == '') {
      var randomaction = Math.floor(Math.random() * action.length);
      var randomactionv = action[randomaction] + '';
      this.doing = randomactionv;
    }
    if (this.o.nature == 'still') {
      this.doing = 'idle';
    }
    if (this.doinginprocess == true) return;

    if (this.doing == 'walk') {
      //this.sprite.play("mob_" + this.o.mobcode + "_idle");
      this.sprite.play(this.o['sprite'] + '_idle');
      //this.plog.log("MapBird walk sprite",'critter_' + this.o['sprite'] + '_' + this.o['subcode'] + '_' + this.lastspriteface + '_idle')

      //this.doinginprocess=true;
      if (this.walkarray.length == 0) {
        var moveto = this.getrandwalkpos() + '';
        var movetoa = moveto.split('-');
        var startPos = { x: this.currentx, y: this.currenty };
        var goalPos = { x: movetoa[0], y: movetoa[1] };
        //this.plog.log("I'mma walk to ", movetoa);
        try {
          var myPathway = this.astar.findPath(startPos, goalPos);
        } catch (e) {
          this.plog.log("MapBird astar findPath error", e);
          return;
        }

        if (myPathway.length > 1) {
          myPathway = myPathway.slice(0, 1);
        }
        //this.plog.log('critter mypathway', myPathway);
        this.walkarray = myPathway;
        this.doing = '';
        if (myPathway.length == 0) {
          //this.plog.log('critter mypathway problem,', myPathway);
          var tmpnextpos = this.findnextclosestwalkable();
          //this.plog.log('critter mypathway tmpnextpos,', tmpnextpos);
          if (tmpnextpos != '') {
            var tmpnextposa = tmpnextpos.split('-');
            var jumpup = 64;
            var timeline = this.wh.add.timeline({
              targets: this.sprite,
              loop: 0,
              tweens: [
                {
                  x: { from: this.sprite.x, to: (Number(tmpnextposa[0]) - 1) * 32 },
                  ease: 'Sine.easeInOut',
                  duration: 400,
                  yoyo: false,
                },
                {
                  y: {
                    from: this.sprite.y,
                    to:
                      Math.min((Number(tmpnextposa[1]) - 1) * 32, this.sprite.y) -
                      jumpup,
                  },
                  ease: 'Sine.easeOut',
                  duration: 200,
                  offset: 0,
                },
                {
                  y: {
                    from:
                      Math.min((Number(tmpnextposa[1]) - 1) * 32, this.sprite.y) -
                      jumpup,
                    to: (Number(tmpnextposa[1]) - 1) * 32,
                  },
                  ease: 'Sine.easeIn',
                  duration: 200,
                },
                {
                  at: 400 + 5,
                  run: function () {

                    // this.plog.log("redirjump timelinecomplete ", wh, zwnameclickeda);
                    //this.chkwarppos_warp(wh, zwnameclickeda[1]);
                    this.currentx = tmpnextposa[0];
                    this.currenty = tmpnextposa[1];

                  }.bind(this)
                }
              ],
            });

            timeline.play();
            //this.plog.log(timeline);
          }
        }
        return;
      }
      if (this.walkarray.length != 0) {
        var nextstop = this.walkarray.shift();
        var dir;
        if (nextstop[0] > this.currentx && nextstop[1] > this.currenty) {
          dir = 'down right';
          this.lastspriteface = 'r';
          dir = 'right';
        }
        if (nextstop[0] > this.currentx && nextstop[1] < this.currenty) {
          dir = 'up right';
          this.lastspriteface = 'r';
          dir = 'right';
        }
        if (nextstop[0] < this.currentx && nextstop[1] < this.currenty) {
          dir = 'up left';
          this.lastspriteface = 'l';
          dir = 'left';
        }
        if (nextstop[0] < this.currentx && nextstop[1] > this.currenty) {
          dir = 'down left';
          this.lastspriteface = 'l';
          dir = 'left';
        }
        if (nextstop[0] == this.currentx && nextstop[1] > this.currenty) {
          dir = 'down';
          this.lastspriteface = 'l';
          dir = 'down';
        }
        if (nextstop[0] == this.currentx && nextstop[1] < this.currenty) {
          dir = 'up';
          this.lastspriteface = 'r';
          dir = 'up';
        }
        if (nextstop[0] > this.currentx && nextstop[1] == this.currenty) {
          dir = 'right';
          this.lastspriteface = 'r';
        }
        if (nextstop[0] < this.currentx && nextstop[1] == this.currenty) {
          dir = 'left';
          this.lastspriteface = 'l';
        }
        var newcx = nextstop[0] * 32;
        var newcy = nextstop[1] * 32;
        this.currentx = nextstop[0];
        this.currenty = nextstop[1];
        this.showspritewalk(dir);
        //this.plog.log("walk", dir, nextstop);
        var walkspeednormal = this.actionspeed - 5;
        var tweenchar = this.wh.tweens.add({
          targets: this.sprite,
          x: newcx,
          y: newcy,
          ease: 'Linear',
          duration: walkspeednormal,
          repeat: 0
        });
      }
    }
    if (this.doing == 'idle') {
      this.sprite.play(
        this.o['sprite'] +
        '_idle'
      );

      if (this.idlecountdown < 0) {
        var mobchkmax = 10;
        var mobchkmin = 5;
        var monnum = Math.floor(
          Math.random() * (mobchkmax - mobchkmin) + mobchkmin
        );

        this.idlecountdown = monnum;
        this.doing = '';
      }
      this.idlecountdown--;
      //this.plog.log("Idle it is", this.idlecountdown);
    }
    if (this.doing == 'sleep') {
      this.sprite.play('mob_' + this.o.mobcode + '_sleep');
      if (this.idlecountdown < 0) {
        var mobchkmax = 10;
        var mobchkmin = 5;
        var monnum = Math.floor(
          Math.random() * (mobchkmax - mobchkmin) + mobchkmin
        );

        this.idlecountdown = monnum;
        this.doing = '';
      }
      this.idlecountdown--;
      //this.plog.log("SLEEP it is", this.idlecountdown);
    }
  }
  showspritewalk(dir, ani = "walk") {
    //this.sprite.alpha = 0.5;
    //this.sprite.setAlpha(0.5);
    this.plog.log("mapbird.showspritewalk()", dir, ani);
    if (this.sprite == undefined) return;
    if (this.sprite == undefined) return;
    try {
      this.sprite.play(
        this.o['sprite'] +
        '_' + ani
      );
      if (dir == "left") {
        this.sprite.flipX = true;
      }
      if (dir == "right") {
        this.sprite.flipX = false;
      }
    } catch (e) {
      //console.log(e);
    }
    return;
  }

  async mydestroy() {
    //return;
    this.plog.log("MapMob mydestroy();", this.behavetimer);

    if (this.behavetimer != undefined) {
      try {
        this.behavetimer.paused = true;

        await this.behavetimer.remove(false);
        //this.behavetimer = undefined;
      } catch (e) {
        this.plog.log("mappet mydestroy error1", e)
      }
    }

    if (this.sprite != undefined) {
      this.sprite.destroy();
      this.sprite = undefined;
    }
  }
}
