import { Injectable } from '@angular/core';
import { plog } from './plog.service';

@Injectable({
  providedIn: 'root',
})
export class NotifService {
  notiflist = [];
  wh;
  plog;
  constructor() { 
    this.plog=new plog();
    this.plog.setLevel(2);
  }

  show(wh, tg, pos = 'tr', secs = 1) {
    //this.plog.log('notif show(),', wh, tg, pos, secs);

    for (const k in this.notiflist) {
      var v = this.notiflist[k];
      if (v == undefined) continue;
      try {
        ///v.destroy();
      } catch (e) {
        this.plog.log("ERROR", e);
      }
    }
    this.wh = wh;
    if (tg == undefined) {
      this.plog.warn('notif show(), tg is undefined', wh, tg, pos);
      return;
    }
    var num = this.notiflist.length;
    //this.notiflist[num] = wh.add.image(0, 0, 'notif');
    this.notiflist[num] = wh.add.sprite(0, 0, 'notif');
    //this.plog.log("gamemenutooltip", tmp);
    var plusx = 0;
    var plusy = 0;

    this.notiflist[num].setVisible(true);
    this.notiflist[num].visible=true;
    this.notiflist[num].displayWidth = this.notiflist[num].displayHeight = 16;

    
    if (tg.parentContainer != undefined && tg.parentContainer != null) {
      //plusx = tg.parentContainer.x;
      //plusy = tg.parentContainer.y;
      tg.parentContainer.add(this.notiflist[num]);
      tg.parentContainer.bringToTop(this.notiflist[num]);
    } else {
    }
    if (pos == 'btm') {
      this.notiflist[num].setPosition(
        tg.x - this.notiflist[num].displayWidth / 2 + plusx,
        tg.y + tg.displayHeight + plusy
      );
    }
    if (pos == 'tr') {
      this.notiflist[num].setPosition(
        tg.x + tg.displayWidth / 2 + plusx,
        tg.y - tg.displayHeight / 2 + plusy
      );
    }
    if (pos == 'tl') {
      this.notiflist[num].setPosition(
        tg.x - tg.displayWidth / 2 + plusx,
        tg.y - tg.displayHeight / 2 + plusy
      );
      this.plog.log("notif tl", this.notiflist[num]);
    }
    if (pos == 'bl') {
      this.notiflist[num].setPosition(
        tg.x - tg.displayWidth / 2 + plusx,
        tg.y + tg.displayHeight / 2 + plusy
      );
    }
    if (pos == 'top') {
      this.notiflist[num].setPosition(
        tg.x - this.notiflist[num].displayWidth / 2 + plusx,
        tg.y - (this.notiflist[num].height + tg.displayHeight / 2) + plusy
      );
    }
    if (pos == 'right') {
      this.notiflist[num].setPosition(
        tg.x + tg.displayWidth / 2 + plusx,
        tg.y - this.notiflist[num].displayHeight / 2 + plusy
      );
    }
    if (pos == 'right2') {
      this.notiflist[num].setPosition(
        tg.x + tg.displayWidth   + plusx,
        tg.y - this.notiflist[num].displayHeight / 2 + plusy
      );
    }

    if (tg.parentContainer != undefined && tg.parentContainer != null) {
      tg.parentContainer.bringToTop(this.notiflist[num]);
    } else {
      this.notiflist[num].setDepth(60000001);
    }

    //this.plog.log("gamemenutooltip txt", tmp);
    this.notiflist[num].setAlpha(1);
    var tw = wh.tweens.add(
      {
        targets: this.notiflist[num],
        alpha: 0,
        ease: 'Power3',
        duration: 1000, // duration of animation; higher=slower
        delay: secs * 1000, // wait 500 ms before starting
        onComplete: function () {
          //this.plog.error("notif autodestroy pre", arguments);
          try {
            arguments[1][0].destroy();
            //this.plog.error("notif autodestroy",);
          } catch (e) {
            this.plog.log("ERROR", e);
          }
          if (this.notiflist != undefined && this.notiflist[num] != undefined) {
            try {
             /// this.notiflist[num].destroy();
            } catch (e) {
              this.plog.log("ERROR", e);
            }
          }
        }, // set context? how?
      },
      this
    );

    //this.plog.log("notif.show end of function txt", num, this.notiflist[num], this.notiflist[num].parentContainer);
    return this.notiflist[num];
  }
}
