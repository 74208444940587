import { AStarFinder } from "astar-typescript";
import { plog } from "./plog.service";

export class MapMob {
    o;
    wh;
    currentx;
    currenty;
    sprite;
    doing = "";
    behavetimer;
    doinginprocess = false;
    astar;
    walkarray = [];
    lastspriteface;
    idlecountdown = 0;
    actionspeed;
    hidecountdown = 0;
    mobdata;
    walktarget = "";
    petname;
    fixeddoing = "";
    plog;

    constructor(wh, o) {
        this.wh = wh;
        this.o = o;
        this.plog=new plog();
        //this.plog.log("MapMob constructor", wh, o); 
        if (this.o["mobcode"] == undefined || this.o["mobcode"] == "") return;
        var catchk = this.o["mobcode"].substring(0, 3);
        if (catchk == "cat") {
            this.plog.log("mapmob found cat"); return;
        }

        if (this.wh.registry.list.rcvarpass.activemapmetrixmob == undefined) return;
        if (this.wh.registry.list.rcvarpass.activemapmetrixmob.length == 0) return;
        //this.plog.log("MapMob constructorthis.wh.registry.list.rcvarpass.activemapmetrixmob", this.wh.registry.list.rcvarpass.activemapmetrixmob);
        this.petname = this.o["petname"];
        var mobchkmax = 10;
        var mobchkmin = 5;
        var monnum = Math.floor(Math.random() * (mobchkmax - mobchkmin) + mobchkmin);
        this.actionspeed = Math.floor(Math.random() * (1500 - 600) + 600);
        this.idlecountdown = monnum;
        if (this.o.nature == "rapid") {
            this.actionspeed = this.actionspeed / 4;
        }
        if (this.o.nature == "teleport") {
            this.actionspeed = 100;
        }
        this.mobdata = this.wh.registry.list.rcvarpass.allmob[this.o.mobcode];
        if (this.wh.registry.list.rcvarpass.activemapmetrixmob == undefined) {
            //this.wh.registry.list.rcvarpass.activemapmetrixmob=[[]];
        }
        //this.plog.log("mapmob astar upon construct", this.wh.registry.list.rcvarpass.activemapmetrixmob);
        if (this.wh.registry.list.rcvarpass.activemapmetrixmob == undefined) {
            this.plog.log("this.wh.registry.list.rcvarpass.activemapmetrixmob not defind");
            return;
        }
        this.astar = new AStarFinder({
            grid: {
                matrix: this.wh.registry.list.rcvarpass.activemapmetrixmob
            },
            includeStartNode: false,
            includeEndNode: true
        });
        //this.plog.log("mob astar", this.astar, this);
        this.behavetimer = this.wh.time.addEvent({ delay: this.actionspeed, callback: this.behave, callbackScope: this, loop: true });
        //this.wh.mobs.push(this);
        this.wh.mobs[this.o.petid] = this;

    }
    getobj() {
        return this.o;
    }
    spawn() {
        if (this.o["mobcode"] == undefined || this.o["mobcode"] == "") return;
        if (this.sprite != undefined && this.o["mobcode"] != "") return;
        if (this.wh.registry.list.rcvarpass.activemapmetrixmob.length == 0) return;
        //this.plog.log("mapmob spawn", this, this.wh);
        var catchk = this.o["mobcode"].substring(0, 3);
        if (catchk == "cat") {
            this.plog.log("mapmob spawn found cat"); return;
        }
        if (this.wh.textures.list[this.o.sprite] == undefined) {
            this.plog.log("mapmob.spawn, can't find sprite", this.o);
            return;
        }
        this.sprite = this.wh.add.sprite(100, 100, this.o.sprite);
        this.sprite.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);

        try {
            this.sprite.play("mob_" + this.o["sprite"] + "_idle");//.setDisplayOrigin(0.5, 0.8);
        } catch (err) {
            this.plog.log("mapmob.spawn() error while trying to play idle ", err);
            return;
        }
        this.sprite.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);

        this.wh.zsortlist.push(this.sprite);
        this.wh.extradestroylist.push(this.sprite);
        this.sprite.mapmonobj = this;
        //tmp.sprite.setScale(this.o["mapsize"]);
        this.sprite.displayWidth = this.o["mapsize"] * 32;
        this.sprite.scaleY = this.sprite.scaleX;
        //this.plog.log("mapmop spawn sprite", this.sprite);
        //tmp.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);

        this.sprite.setInteractive();
        this.sprite.on("pointerdown", function () {
            //this.plog.log("mobclick", this);
            if (this.wh.registry.list.rcvarpass.activemap["map"]["code"] != "backyard" && this.wh.registry.list.rcvarpass.activemap["map"]["code"] != "backyard2") {
                if (this.wh.registry.list.showreward.isclosed()) {
                    this.wh.registry.list.combat.start(this.wh);
                    this.hide(4);
                } else {
                    this.hide(1);
                }
            } else {
                //click mob on backyard
                this.plog.log("mobpetname", this);
                if (this.petname != undefined && this.petname != "") {
                    //this.wh.registry.list.phm.charsaybubble(this.sprite, this.petname);
                    this.wh.registry.list.phm.charsaybubble(this.wh.char_main, "That's " + this.petname);
                    this.cheers();
                }
            }
        }, this);
        //var tmp = this.rcvarsservice.gameobj.scene.scenes[0].add.image((spawnposa[1] * 32), (spawnposa[0] * 32), this.rcvarsservice.activechar["sprite"], "walk-d-1").setDepth(999);
        this.wh.mapcontainer.add(this.sprite);
        this.movetorandomarea();

        var moveto = this.getrandwalkpos() + "";
        var movetoa = moveto.split("-");
        var startPos = { x: this.currentx, y: this.currenty }
        var goalPos = { x: movetoa[0], y: movetoa[1] }
        //this.plog.log("I'mma walk to ", movetoa);
        var myPathway = this.astar.findPath(startPos, goalPos);
        //this.plog.log(myPathway);
        this.walkarray = myPathway;


        //this.plog.log("charic",charic);
        //this.plog.log("moveTo", this.charzindex, this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer);
        //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.moveTo(tmp, this.charzindex);
        //this.rcvarsservice.gameobj.scene.scenes[0].char_main = tmp;
        this.behave();
    }
    movetorandomarea() {
        var moveto = this.getrandwalkpos() + "";
        var movetoa = moveto.split("-");
        this.currentx = Number(movetoa[0]);
        this.currenty = Number(movetoa[1]);
        this.sprite.x = ((this.currentx - 1) * 32);//+width/2;
        this.sprite.y = ((this.currenty - 1) * 32);//+width/2;
        this.sprite.displayHeight = 64 * this.o["mapsize"];
        this.sprite.scaleX = this.sprite.scaleY;//displayWidth = 64 * this.o["mapsize"];
        this.sprite.setOrigin(0.5, 0.8);
        //this.plog.log(random, months[random]);
    }
    getrandwalkpos() {
        var mobwalkarr = this.wh.registry.list.rcvarpass.activemap["mobwalk"];
        if (mobwalkarr == undefined) {
            //this.plog.log("mapmon movetorandomarea: mobwalk not found");
            var mobwalkarr = this.wh.registry.list.rcvarpass.activemap["walkable"];

        }
        var usablemobwalk = [];
        for (var key in mobwalkarr) {
            var val = mobwalkarr[key];
            if (val == "yn_y") {
                usablemobwalk.push(key);
            }
        }
        var randompos = Math.floor(Math.random() * usablemobwalk.length);
        var moveto = usablemobwalk[randompos];
        //this.plog.log("mapmon movetorandomarea: usablemobwalk", usablemobwalk);
        //this.plog.log("mapmon movetorandomarea: randompos", moveto);
        return moveto;
    }
    hide(countdown) {
        this.sprite.visible = false;
        this.hidecountdown = (countdown * 1000) / this.actionspeed;
    }
    behave() {
        //this.plog.log("mapmob.behave",this.wh.combatcontainer);
        //this.plog.log("mapmob.behave", this.sprite);
        if (this.wh.pauseload == true) return;
        if (this.sprite == undefined) {
            this.plog.log("error, mapmob.behave(), this.sprite=undefined");
            return;
        }
        if (this.sprite != undefined && this.sprite.scene == undefined) {
            this.plog.log("error, mapmob.behave(), this.sprite.scene=undefined");
            this.mydestroy();
            return;
        }
        if (this.wh.combatcontainer != undefined && this.wh.combatcontainer.list.length > 0) {
            return;
        }
        if (this.fixeddoing != "") {
            if (this.hidecountdown > 1) {
                this.hidecountdown = 1;
            }
        }
        if (this.hidecountdown > 0) {
            //this.plog.log("mapmob hidecountdown ", this.hidecountdown);
            this.hidecountdown = this.hidecountdown - 1;
            return;
        }
        if (this.sprite.visible == false) {
            this.wh.registry.list.phm.mapitemeffect(this.sprite, "warpgate_b");
            this.sprite.visible = true;
        }
        //this.plog.log("mapmob.behave",this.wh.combatcontainer);
        if (this.astar == undefined) {

        }
        //this.plog.log("behaving", this);
        var action = ["walk", "idle", "sleep"];
        //var action = ["walk"];
        if (this.fixeddoing != "") {
            this.doing = this.fixeddoing;
        }
        if (this.doing == "") {
            if (this.wh.registry.list.rcvarpass.activemap["map"]["mobhuntchar"] == "YES") {
                //this.plog.log("mapmob find doing to do ", this.wh.registry.list.rcvarpass.activemap["map"]);
                //chk if char in mobwalk area
                var tmpcharpos = this.wh.char_main.currentx + "-" + this.wh.char_main.currenty;
                var ischarinarea = "no";
                if (this.wh.registry.list.rcvarpass.activemap["mobwalk"][tmpcharpos] == "yn_y") {
                    ischarinarea = "yes"
                }
                //this.plog.log("mapmob char_main pos", tmpcharpos, "mobwalk", ischarinarea);
                if (ischarinarea == "yes") {
                    var huntstartPos = { x: this.currentx, y: this.currenty }
                    var huntgoalPos = { x: this.wh.char_main.currentx, y: this.wh.char_main.currenty }
                    var huntmyPathway = this.astar.findPath(huntstartPos, huntgoalPos);
                    this.plog.log("mapmob huntchar findPath", huntstartPos, huntgoalPos);
                    this.plog.log("mapmob huntchar path", huntmyPathway.length, huntmyPathway);
                    if (huntmyPathway.length == 1 ||
                        (huntstartPos.x == huntgoalPos.x && huntstartPos.y == huntgoalPos.y)) {
                        //caughtup, start combat
                        this.plog.log("mapmob huntchar starting combat");
                        if (this.wh.registry.list.showreward.isclosed()) {
                            this.wh.registry.list.combat.start(this.wh, {}, "mobinitcombat");
                            this.hide(15);
                        } else {
                            this.hide(1);
                        }
                    } else {
                        this.walktarget = "char";
                        this.walkarray = huntmyPathway;
                        this.doing = "walk";
                    }
                } else {
                    var randomaction = Math.floor(Math.random() * action.length);
                    var randomactionv = action[randomaction] + "";
                    //this.plog.log("mapmob xxxdoing", this);
                    if (this.wh.registry.list.timectl.timename == this.mobdata["sleeptime"]) randomactionv = "dead";
                    this.doing = randomactionv;
                }
            } else {

                var randomaction = Math.floor(Math.random() * action.length);
                var randomactionv = action[randomaction] + "";
                //this.plog.log("mapmob xxxdoing", this);
                if (this.wh.registry.list.timectl.timename == this.mobdata["sleeptime"]) randomactionv = "dead";
                this.doing = randomactionv;
            }
        }
        if (this.o.nature == "still") {
            this.doing = "idle";
        }
        if (this.o.nature == "sleep") {
            this.doing = "sleep";
        }
        if (this.o.nature == "warp") {
            var action = ["warp", "idle", "sleep"];
            var randomaction = Math.floor(Math.random() * action.length);
            var randomactionv = action[randomaction] + "";
            this.doing = randomactionv;
        }
        if (this.doinginprocess == true) return;

        if (this.fixeddoing != "") {
            this.doing = this.fixeddoing;

            if (this.fixeddoing == "sleep") {
                this.sprite.play("mob_" + this.o.sprite + "_dead");
                //this.plog.log("fixed sleep");
                return;
            }
        }

        if (this.doing == "warp") {
            var moveto = this.getrandwalkpos() + "";
            var movetoa = moveto.split("-");
            this.currentx = Number(movetoa[0]);
            this.currenty = Number(movetoa[1]);
            this.sprite.x = ((this.currentx - 1) * 32);//+width/2;
            this.sprite.y = ((this.currenty - 1) * 32);//+width/2;
            this.wh.registry.list.phm.mapitemeffect(this.sprite, "effect95_b");
        }
        if (this.doing == "walk") {
            if (this.sprite == undefined) return;
            try {
                this.sprite.play("mob_" + this.o.sprite + "_idle");
            } catch (e) {
                this.plog.log(e);
            }

            //this.doinginprocess=true;
            if (this.walkarray.length == 0) {
                var moveto = this.getrandwalkpos() + "";
                var movetoa = moveto.split("-");
                var startPos = { x: this.currentx, y: this.currenty }
                var goalPos = { x: movetoa[0], y: movetoa[1] }
                //this.plog.log("I'mma walk to ", movetoa);
                //this.plog.log("mobastar ", this.astar);
                if (startPos == undefined || goalPos == undefined) return;
                try {
                    var myPathway = this.astar.findPath(startPos, goalPos);
                } catch (e) {
                    this.plog.log("mapmob astar findPath error", e);
                    return;
                }
                //this.plog.log(myPathway);
                this.walkarray = myPathway;
                this.walktarget = "";
                this.doing = "";
                return;
            }
            if (this.walkarray.length != 0) {
                if (this.wh.registry.list.rcvarpass.activemap["map"]["mobhuntchar"] == "YES") {
                    //&& this.walktarget == ""
                    var tmpcharpos = this.wh.char_main.currentx + "-" + this.wh.char_main.currenty;
                    var ischarinarea = "no";
                    if (this.wh.registry.list.rcvarpass.activemap["mobwalk"][tmpcharpos] == "yn_y") {
                        ischarinarea = "yes"
                    }
                    if (ischarinarea == "yes") {
                        //change target midway
                        //this.doing = "";
                        this.walkarray = [];
                        var huntstartPos = { x: this.currentx, y: this.currenty }
                        var huntgoalPos = { x: this.wh.char_main.currentx, y: this.wh.char_main.currenty }
                        var huntmyPathway = this.astar.findPath(huntstartPos, huntgoalPos);
                        this.plog.log("mapmobwalking huntchar findPath", huntstartPos, huntgoalPos);
                        this.plog.log("mapmobwalking huntchar path", huntmyPathway.length, huntmyPathway);
                        this.walkarray = huntmyPathway;
                        this.walktarget = "char";
                        /*if (this.walkarray.length > 1)
                            this.walkarray.shift();
                        if (this.walkarray.length > 1)
                            this.walkarray.shift();*/
                        /*if (huntmyPathway.length == 1 ||
                            (huntstartPos.x == huntgoalPos.x && huntstartPos.y == huntgoalPos.y )) { 
                            this.plog.log("mapmobwalking huntchar starting combat");
                            this.wh.registry.list.combat.start(this.wh, {}, "mobinitcombat");
                            this.hide(30);
                        }*/
                        //return;
                    }
                }
                var nextstop = this.walkarray.shift();;
                var dir;
                if (nextstop == undefined) {
                    this.walktarget = "";
                    this.doing = "";
                    return;
                }
                if (nextstop[0] > this.currentx && nextstop[1] > this.currenty) {
                    dir = "down right";
                    this.lastspriteface = "right";
                    dir = "right";
                }
                if (nextstop[0] > this.currentx && nextstop[1] < this.currenty) {
                    dir = "up right";
                    this.lastspriteface = "right";
                    dir = "right";
                }
                if (nextstop[0] < this.currentx && nextstop[1] < this.currenty) {
                    dir = "up left";
                    this.lastspriteface = "left";
                    dir = "left";
                }
                if (nextstop[0] < this.currentx && nextstop[1] > this.currenty) {
                    dir = "down left";
                    this.lastspriteface = "left";
                    dir = "left";
                }
                if (nextstop[0] == this.currentx && nextstop[1] > this.currenty) {
                    dir = "down";
                    this.lastspriteface = "down";
                    dir = "left";
                }
                if (nextstop[0] == this.currentx && nextstop[1] < this.currenty) {
                    dir = "up";
                    this.lastspriteface = "up";
                    dir = "right";
                }
                if (nextstop[0] > this.currentx && nextstop[1] == this.currenty) {
                    dir = "right";
                    this.lastspriteface = "right";
                }
                if (nextstop[0] < this.currentx && nextstop[1] == this.currenty) {
                    dir = "left";
                    this.lastspriteface = "left";
                }
                var newcx = (nextstop[0] - 1) * 32;
                var newcy = (nextstop[1] - 1) * 32;
                this.currentx = nextstop[0];
                this.currenty = nextstop[1];
                this.showspritewalk(dir);
                //this.plog.log("walk", dir, nextstop);
                var walkspeednormal = this.actionspeed - 5;
                var tweenchar = this.wh.tweens.add({
                    targets: this.sprite,
                    x: (newcx),
                    y: (newcy),
                    ease: 'Linear',
                    duration: walkspeednormal,
                    repeat: 0,
                    onStart: function () {
                        //this.plog.log('onStart'); this.plog.log(arguments); 
                    },
                    onComplete: function (wh) {
                        //this.plog.log('onComplete', this);
                        this.doing = "";
                        if (this.targets[0].mapmonobj.o.nature == "teleport" && this.targets[0].mapmonobj.walkarray.length == 0) {
                            this.targets[0].scene.phasermapservice.mapitemeffect(this.targets[0], "effect95_b");
                        }

                    },
                    onYoyo: function () {
                        //this.plog.log('onYoyo'); this.plog.log(arguments); 
                    },
                    onRepeat: function () {
                        //this.plog.log('onRepeat'); this.plog.log(arguments); 
                    }
                });
            }
        }
        if (this.doing == "idle") {

            if (this.sprite == undefined) return;
            try {
                this.sprite.play("mob_" + this.o.sprite + "_idle");
            } catch (e) {
                this.plog.log(e);
            }
            if (this.wh.registry.list.rcvarpass.activemap["map"]["mobhuntchar"] == "YES" && this.walktarget == "") {
                var tmpcharpos = this.wh.char_main.currentx + "-" + this.wh.char_main.currenty;
                var ischarinarea = "no";
                if (this.wh.registry.list.rcvarpass.activemap["mobwalk"][tmpcharpos] == "yn_y") {
                    ischarinarea = "yes"
                }
                if (ischarinarea == "yes") {
                    //change target midway
                    this.idlecountdown = -1;
                    this.doing = "";
                    this.walkarray = [];
                    return;
                }
            }
            if (this.idlecountdown < 0) {
                var mobchkmax = 10;
                var mobchkmin = 5;
                var monnum = Math.floor(Math.random() * (mobchkmax - mobchkmin) + mobchkmin);

                this.idlecountdown = monnum;
                this.doing = "";
            }
            this.idlecountdown--;
            //this.plog.log("Idle it is", this.idlecountdown);
        }
        if (this.doing == "sleep") {
            if (this.sprite == undefined) return;
            try {
                this.sprite.play("mob_" + this.o.sprite + "_sleep");
            } catch (e) {
                this.plog.log(e);
            }
            if (this.idlecountdown < 0) {
                var mobchkmax = 10;
                var mobchkmin = 5;
                var monnum = Math.floor(Math.random() * (mobchkmax - mobchkmin) + mobchkmin);

                this.idlecountdown = monnum;
                this.doing = "";
            }
            this.idlecountdown--;
            //this.plog.log("SLEEP it is", this.idlecountdown);
        }
        if (this.doing == "cheers") {

            if (this.sprite == undefined) return;
            try {
                this.sprite.play("mob_" + this.o.sprite + "_bored");
            } catch (e) {
                this.plog.log(e);
            }

            this.idlecountdown--;
            if (this.idlecountdown < 0) {
                this.doing = "";
            }

            //this.plog.log("SLEEP it is", this.idlecountdown);
        }
        if (this.doing == "dead") {
            if (this.sprite == undefined) return;
            try {
                this.sprite.play("mob_" + this.o.sprite + "_dead");
            } catch (e) {
                this.plog.log(e);
            }
            if (this.idlecountdown < 0) {
                var mobchkmax = 10;
                var mobchkmin = 5;
                var monnum = Math.floor(Math.random() * (mobchkmax - mobchkmin) + mobchkmin);

                this.idlecountdown = monnum;
                this.doing = "";
            }
            this.idlecountdown--;
            //this.plog.log("SLEEP it is", this.idlecountdown);
        }
    }
    showspritewalk(dir) {
        //this.sprite.alpha = 0.5;
        //this.sprite.setAlpha(0.5);
        if (dir == "left") {
            this.sprite.flipX = false;
        }
        if (dir == "right") {
            this.sprite.flipX = true;
        }
    }
    cheers() {
        this.plog.log("cheers()", this);
        this.walkarray = [];
        var mobchkmax = 2;
        var mobchkmin = 1;
        var monnum = Math.floor(Math.random() * (mobchkmax - mobchkmin) + mobchkmin);

        this.idlecountdown = 2;
        this.doing = "cheers";
        return;
        try {
            this.sprite.play({ key: "mob_" + this.o["sprite"] + "_bored", repeat: 5 });//.setDisplayOrigin(0.5, 0.8);
        } catch (err) {
            this.plog.log("mapmob.cheers() error while trying to play victory (cheers) ", err);
            return;
        }
    }

    async mydestroy() {
        //return;
        this.plog.log("MapMob mydestroy();", this.behavetimer);

        if (this.behavetimer != undefined) {
            try {
                this.behavetimer.paused = true;

                await this.behavetimer.remove(false);
                //this.behavetimer = undefined;
            } catch (e) {
                this.plog.log("mappet mydestroy error1", e)
            }
        }

        if (this.sprite != undefined) {
            this.sprite.destroy();
            this.sprite = undefined;
        }
    }
}