import { Injectable } from '@angular/core';
import { PGBar } from './pgbar';
import { plog } from './plog.service';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  scrolltimer;
  spincursor = 0;
  selected;
  playbtn;
  closing = false;
  floatertimerfunctime = 2000;
  bg;
  wh;
  bg1; bg2; bg3;
  closebtn;
  startimer;
  circ1;
  circ2;
  powereffect;
  fishbar;
  rodpower = 1;
  hookpower = 1;
  floater;
  floatertimer;
  astring;
  astringstep = 0;
  waittime = 10;
  powerpgbar;
  powertimer;
  currentpower = 0;
  fishingrule = '';
  currentstar = 0;
  npcid = "";
  data;
  obj = [];
  objt = [];
  txt;
  ll1; ll2;
  plog;

  constructor() {
    this.plog = new plog();
  }

  async show(wh, id) {
    this.plog.log('spinner start', wh, id);
    this.currentpower = 0;
    this.currentstar = 0;
    this.closing = false;
    this.wh = wh;
    this.data = await this.wh.registry.list.rchttp.getcommon2("spinnerdata", { code: id });
    var reschk = this.wh.registry.list.rcreq.chk(this.data['spinner']['requirements']);
    if (reschk == false) {
      var reschktxt = this.wh.registry.list.rcreq.chk(this.data['spinner']['requirements'], "text");
      await this.wh.registry.list.rctoast.rcalert(this.wh, "Unable to use Free Spinner", "Unable to use Free Spinner\n\nPlease complete requirements to use this spinner:\n\n" + reschktxt);
      return;
    }
    this.npcid = id;
    if (wh.spinnercontainer == undefined) {
      wh.spinnercontainer = wh.add.container(0, 0);
    }
    wh.spinnercontainer.setDepth(1000000);

    let loader = new Phaser.Loader.LoaderPlugin(wh);

    var svversionurlstring = this.wh.registry.list.rcvarpass.svversionstring;
    if (svversionurlstring != '') {
      svversionurlstring = '?' + svversionurlstring;
    }

    var loadlist = ['spinnerbg/' + this.npcid, 'spinnerbg/l1', 'spinnerbg/l2', 'spinnerbg/l3', 'spinnerbg/ll1', 'spinnerbg/ll2'];
    for (var k in loadlist) {
      var key = loadlist[k];
      if (key == "xx") continue;
      var tmpurl = this.wh.registry.list.rcvarpass.getlocal(

        key +
        '.png' +
        svversionurlstring
      );
      loader.image(
        key + '',
        tmpurl
      );
    }
    var loadlist = ['ixbeff172'];
    for (var k in loadlist) {
      var key = loadlist[k];
      if (key == "xx") continue;
      loader.atlas(
        key + '',
        this.wh.registry.list.rcvarpass.rc_baseurl +
        'sprite/' +
        key +
        '.png' +
        svversionurlstring,
        this.wh.registry.list.rcvarpass.rc_baseurl +
        'sprite/' +
        key +
        '.json' +
        svversionurlstring
      );
    }
    this.wh.registry.list.rcloading.loading2(this.wh);
    loader.on(
      'progress',
      function (value) {
        //this.plog.log("loaderevent", value, loader.totalComplete, loader.totalToLoad);
        this.wh.registry.list.rcloading.loading2text(
          'Loading ' + loader.totalComplete + '/' + loader.totalToLoad
        );
        this.wh.registry.list.rcloading.loading2percent(value);
      },
      this
    );
    loader.once(
      Phaser.Loader.Events.COMPLETE,
      () => {
        this.plog.log('Phaser.Loader.Events.COMPLETE');
        this.plog.log(this);
        this.show_loaded(wh, id);
      },
      this
    );
    loader.start();
  }
  floatertimerfunc() {
    this.plog.log('floatertimerfunc', this);

    var width = this.wh.game.canvas.width;
    var height = this.wh.game.canvas.height;
    var l = width * 0.2;
    var r = width * 0.8;
    var t = height * 0.2;
    var b = height * 0.8;
    //    return Math.random() * (max - min) + min;
    var usew = Math.random() * (r - l) + l;
    var useh = Math.random() * (t - b) + b;
    //this.floater.setPosition(usew,useh);
    /*
    var tw = this.wh.tweens.add({
      targets: this.floater,
      x: usew,
      y: useh,
      ease: 'Linear',
      duration: this.floatertimerfunctime, // duration of animation; higher=slower
      loop: 0,
      onUpdate: function (a1, a2, a3, a4, a5) {
        //this.plog.log("onupdate",this);
        if (
          this.targets[0] != undefined &&
          this.targets[0].scene != undefined
        ) {
          this.targets[0].scene.registry.list.fishing.astringstep++;
          if (this.targets[0].scene.registry.list.fishing.astringstep == 10) {
            this.targets[0].scene.registry.list.fishing.astringstep = 0;
            var tmp = this.targets[0].scene.add
              .sprite(0, 0, 'waterripple')
              .play('waterripple_b_once');
            tmp.setPosition(
              this.targets[0].x,
              this.targets[0].y - this.targets[0].displayHeight / 2
            );
            tmp.setOrigin(0.5, 0.5);
            tmp.on('animationcomplete', function () {
              //this.plog.log("animationcomplete",this);
              this.destroy();
            });
          }
          this.targets[0].scene.registry.list.fishing.astring.clear();
          this.targets[0].scene.registry.list.fishing.astring.lineBetween(
            this.targets[0].scene.game.canvas.width + 100,
            -200,
            this.targets[0].x,
            this.targets[0].y - this.targets[0].displayHeight / 2
          );
        }
      },
    });
    */
    //this.astring.clear();
    //this.astring.lineBetween(100, 100, usew, useh);
  }
  async show_loaded(wh, id) {
    this.data = await this.wh.registry.list.rchttp.getcommon2("spinnerdata", { code: id });
    var userod = wh.registry.list.rcvarpass.inputitem[id + '---0'];
    var usehook = wh.registry.list.rcvarpass.inputitem[id + '---1'];
    var usebait = wh.registry.list.rcvarpass.inputitem[id + '---2'];
    wh.spinnercontainer.setDepth(1000000);
    var msg = this.wh.trs("f:spinnercontainer:Free Spin===Free Spin") + " " + this.data["usedcount"] + "/" + this.data["spinner"]["limitperday"] + ", extra spin cost " + this.data["spinfee"] + " diamond";
    msg = wh.gl(msg);
    this.txt = this.wh.add.text(0, 0, msg, this.wh.fs["spinner_txt"]);

    this.plog.log('spinner txt', this.txt);
    this.plog.log('spinnerdata ', this.data);
    //this.fishingrule = this.fishingrules[Math.floor(Math.random()*this.fishingrules.length)];
    this.floatertimerfunctime =
      this.floatertimerfunctime / this.fishingrule['fishpower'];
    this.plog.log(
      'fishpower',
      this.fishingrule['fishpower'],
      this.floatertimerfunctime
    );

    this.wh.registry.list.rcloading.close();

    var width = wh.game.canvas.width;
    var height = wh.game.canvas.height;
    this.powerpgbar = new PGBar(this.wh, {
      x: 0,
      y: 0,
      w: width - 40,
      h: 20,
      max: 100,
      me: 0,
      barcol1: 0x6ec2ff,
      barcol2: 0x0394fc,
      txt: '',
    });
    this.powerpgbar.draw();
    this.wh.registry.list.gameitf.genloadedanims(this.wh, 'ixbeff172_b');
    //this.wh.registry.list.gameitf.genloadedanims(this.wh, 'waterripple_b');
    //this.wh.registry.list.gameitf.genloadedanims(this.wh, 'fishbar_a');
    this.plog.log('SpinnerService show_loaded', wh, id);

    this.bg = wh.add.image(0, 0, 'spinnerbg/' + this.npcid);
    wh.spinnercontainer.add(this.bg);
    wh.spinnercontainer.bringToTop(this.bg);
    this.bg.setPosition(width / 2, height / 2);
    this.bg.displayWidth = width;
    this.bg.displayHeight = height;
    this.bg.setInteractive();
    this.bg.removeAllListeners();



    this.bg1 = wh.add.tileSprite(0, 0, width, height, "spinnerbg/l1")
      .setOrigin(0, 0)
      .setScrollFactor(0, 1); //this line keeps your background from scrolling outside of camera bounds
    wh.spinnercontainer.add(this.bg1);
    wh.spinnercontainer.bringToTop(this.bg1);
    this.ll1 = wh.add.tileSprite(0, 0, width, height, "spinnerbg/ll1")
      .setOrigin(0, 0)
      .setScrollFactor(0, 1); //this line keeps your background from scrolling outside of camera bounds
    wh.spinnercontainer.add(this.ll1);
    wh.spinnercontainer.bringToTop(this.ll1);
    this.bg2 = wh.add.tileSprite(0, 0, width, height, "spinnerbg/l2")
      .setOrigin(0, 0)
      .setScrollFactor(0, 1); //this line keeps your background from scrolling outside of camera bounds
    wh.spinnercontainer.add(this.bg2);
    wh.spinnercontainer.bringToTop(this.bg2);
    this.ll2 = wh.add.tileSprite(0, 0, width, height, "spinnerbg/ll2")
      .setOrigin(0, 0)
      .setScrollFactor(0, 1); //this line keeps your background from scrolling outside of camera bounds
    wh.spinnercontainer.add(this.ll2);
    wh.spinnercontainer.bringToTop(this.ll2);
    this.bg3 = wh.add.tileSprite(0, 0, width, height, "spinnerbg/l3")
      .setOrigin(0, 0)
      .setScrollFactor(0, 1); //this line keeps your background from scrolling outside of camera bounds
    wh.spinnercontainer.add(this.bg3);
    wh.spinnercontainer.bringToTop(this.bg3);

    //this.bg1.setPosition(width / 2, height / 2);
    //this.bg2.setPosition(width / 2, height / 2);
    //this.bg3.setPosition(width / 2, height / 2);
    //this.bg4.setPosition(width / 2, height / 2);
    //if (width>height) {
    var scale = height / 270;
    this.plog.log("bg1", scale, this.bg1, this.bg2, this.bg3)
    this.bg1.scale = scale; //this.bg1.scaleX=this.bg1.scaleY;
    this.bg2.scale = scale; //this.bg1.scaleX=this.bg1.scaleY;
    this.bg3.scale = scale; //this.bg1.scaleX=this.bg1.scaleY;
    this.bg3.scale = scale; //this.bg1.scaleX=this.bg1.scaleY;
    this.ll1.scale = scale; //this.bg1.scaleX=this.bg1.scaleY;
    this.ll2.scale = scale; //this.bg1.scaleX=this.bg1.scaleY;

    //this.bg1.x=0;


    wh.spinnercontainer.add(this.txt);
    wh.spinnercontainer.bringToTop(this.txt);
    this.txt.setWordWrapWidth(width - 150)
    this.txt.setPosition((width / 2) - (this.txt.displayWidth / 2), height - this.txt.displayHeight);


    this.selected = wh.add.sprite(0, 0, "ixbeff172_b").play({ key: "ixbeff172_b", frameRate: 64 });
    wh.spinnercontainer.add(this.selected);
    wh.spinnercontainer.bringToTop(this.selected);

    var dist1 = Math.min(width, height);
    var dist = dist1 / 4;
    var i = 0;
    for (var i = 0; i <= 7; i++) {
      if (this.obj[i] != undefined) {
        this.obj[i].destroy(); this.obj[i] = undefined;
      }
      if (this.objt[i] != undefined) {
        this.objt[i].destroy(); this.objt[i] = undefined;
      }
    }
    this.plog.log("spinner data", this.data);
    for (var i = 0; i <= 7; i++) {
      if (this.data["items"][i]["itemtype"] == "item") {
        this.obj[i] = wh.add.sprite(0, 0, "item_tp", this.data["items"][i]["item"]);
        this.objt[i] = this.wh.add.text(0, 0, "+" + this.data["items"][i]["itemplus"], wh.fs["wingui_amnt"]);
      }
      if (this.data["items"][i]["itemtype"] == "stackitem") {
        this.obj[i] = wh.add.sprite(0, 0, "stackitem_tp", this.data["items"][i]["item"]);
        this.objt[i] = this.wh.add.text(0, 0, this.data["items"][i]["amnt"], wh.fs["wingui_amnt"]);
      }
      wh.spinnercontainer.add(this.obj[i]);
      wh.spinnercontainer.bringToTop(this.obj[i]);
      wh.spinnercontainer.add(this.objt[i]);
      wh.spinnercontainer.bringToTop(this.objt[i]);
      this.obj[i].displayWidth = this.obj[i].displayHeight = 64;
    }
    var centx = width / 2;
    var centy = height / 2;
    this.obj[0].setPosition(centx + 0, centy + 0 - dist);
    this.obj[1].setPosition(centx + dist, centy + 0 - dist);
    this.obj[2].setPosition(centx + dist, centy + 0);
    this.obj[3].setPosition(centx + dist, centy + dist);
    this.obj[4].setPosition(centx + 0, centy + dist);
    this.obj[5].setPosition(centx + 0 - dist, centy + 0 + dist);
    this.obj[6].setPosition(centx + 0 - dist, centy + 0);
    this.obj[7].setPosition(centx + 0 - dist, centy + 0 - dist);
    for (var iii = 0; iii <= 7; iii++) {
      this.objt[iii].setPosition(this.obj[iii].x - 30, this.obj[iii].y + 18,)
    }
    this.selected.setPosition(centx, centy);
    this.selected.displayWidth = this.selected.displayHeight = 128;



    this.closebtn = wh.add.sprite(0, 0, "thesq", 'close');
    wh.spinnercontainer.add(this.closebtn);
    wh.spinnercontainer.bringToTop(this.closebtn);

    this.playbtn = wh.add.sprite(0, 0, "thesb", "start");
    this.playbtn.displayWidth = (dist * 2) - 150;
    if (this.playbtn.displayWidth < (dist * 1.5))
      this.playbtn.displayWidth = dist * 1.5
    this.playbtn.scaleY = this.playbtn.scaleX;
    this.playbtn.setPosition(centx, centy);
    this.playbtn.setInteractive();
    this.playbtn.removeAllListeners();
    this.playbtn.on(
      'pointerdown',
      function () {
        this.startspin();
      },
      this
    );
    wh.spinnercontainer.add(this.playbtn);
    wh.spinnercontainer.bringToTop(this.playbtn);

    this.selected.displayWidth = this.playbtn.displayWidth + 64;
    this.selected.displayHeight = this.playbtn.displayHeight + 64;
    this.closebtn.displayWidth = this.closebtn.displayHeight = 70;
    this.closebtn.setPosition(40, height - 40);
    this.closebtn.setInteractive();
    this.closebtn.removeAllListeners();
    this.closebtn.on(
      'pointerdown',
      function () {
        this.close();
      },
      this
    );
    this.scrolltimer = wh.time.addEvent({
      delay: 50, // ms
      callback: this.scrolltimerfunc,
      //args: [],
      callbackScope: this,
      loop: true,
    });
    /*
        var waitt = Math.random() * (4000 - 2500) + 2500;
        this.plog.log('fishing random wait', waitt);
    
        await wh.registry.list.rctoast.sleep(waitt);
        this.startfishing(wh);*/

    var griddat = [];
    var griddatf = [];

    griddat.push(this.playbtn);
    griddatf.push(this.playbtn);
    griddat.push(this.closebtn);
    this.wh.registry.list.gp.guigrid(griddat, griddatf);

  }
  async startfishing(wh) {
    this.plog.log('fishing startfishing', this.data);
    if (this.closing == true) {
      this.plog.log('fishing startfishing this.closing');
      return;
    }


    this.floatertimer = wh.time.addEvent({
      delay: this.floatertimerfunctime, // ms
      callback: this.floatertimerfunc,
      //args: [],
      callbackScope: this,
      loop: true,
    });

    this.startimer = wh.time.addEvent({
      delay: 2000, // ms
      callback: this.startimerfunc,
      //args: [],
      callbackScope: this,
      loop: true,
    });

    this.circ1.visible = true;
    this.circ2.visible = true;

    var tw1 = this.wh.tweens.add({
      targets: this.circ1,
      alpha: 0.7,
      ease: 'Power3',
      duration: 495, // duration of animation; higher=slower
      loop: -1,
      yoyo: true,
    });
    var tw2 = this.wh.tweens.add({
      targets: this.circ2,
      alpha: 0.4,
      ease: 'Power3',
      duration: 752, // duration of animation; higher=slower
      loop: -1,
      yoyo: true,
    });
  }
  powertimerfunc() {
    var powereachtick = 100 / this.waittime;
    powereachtick = powereachtick / 10;
    this.currentpower += powereachtick;
    this.powerpgbar.set1(this.currentpower);
    //this.plog.log('powertimerfunc', this.currentpower);
    if (this.currentpower >= 100) {
      this.powereffect.visible = true;
    } else {
      this.powereffect.visible = false;
    }
    if (this.fishbar != undefined) {
      if (this.currentstar == 0) this.fishbar.setTexture('fishbar', 'a-1');
      if (this.currentstar == 1) this.fishbar.setTexture('fishbar', 'b-1');
      if (this.currentstar == 2) this.fishbar.setTexture('fishbar', 'c-1');
      if (this.currentstar == 3) this.fishbar.setTexture('fishbar', 'd-1');
      if (this.currentstar == 4) this.fishbar.setTexture('fishbar', 'e-1');
      if (this.currentstar == 5) this.fishbar.setTexture('fishbar', 'f-1');
      if (this.currentstar == 6) this.fishbar.setTexture('fishbar', 'g-1');
      if (this.currentstar == 7) this.fishbar.setTexture('fishbar', 'h-1');
      if (this.currentstar == 8) this.fishbar.setTexture('fishbar', 'i-1');
      if (this.currentstar == 9) this.fishbar.setTexture('fishbar', 'j-1');
      if (this.currentstar == 10) this.fishbar.setTexture('fishbar', 'k-1');
    }
  }
  /*
  tapped(wh) {
    this.plog.log('fishing tapped', wh);
    if (this.currentpower < 100) {
      var tw2 = this.wh.tweens.add({
        targets: this.powerpgbar,
        y: this.powerpgbar.y - 20,
        ease: 'Power1',
        duration: 100, // duration of animation; higher=slower
        loop: 0,
        yoyo: true,
      });
      return;
    }

    var width = this.wh.game.canvas.width;
    var height = this.wh.game.canvas.height;
    this.currentpower = 0;
    var x1 = this.floater.x;
    var y1 = this.floater.y;
    var x2 = width / 2;
    var y2 = height / 2;
    var a = x1 - x2;
    var b = y1 - y2;

    var c = Math.sqrt(a * a + b * b);
    var c1step = Math.min(width, height) / 2 / 10;
    var cuse = Math.floor(c / c1step);
    this.plog.log('fishing dist', c, c1step, cuse);
    var starcollected = 0;
    if (cuse == 0) starcollected = 5;
    if (cuse == 1) starcollected = 4;
    if (cuse == 2) starcollected = 3;
    if (cuse == 3) starcollected = 3;
    if (cuse == 4) starcollected = 2;
    if (cuse == 5) starcollected = 2;
    if (cuse == 6) starcollected = 1;
    if (cuse == 7) starcollected = 0;
    if (cuse == 8) starcollected = 0;
    if (cuse == 9) starcollected = 0;
    if (cuse == 10) starcollected = 0;
    this.currentstar += starcollected;
    if (starcollected > 0) this.currentstar += this.rodpower;
    if (this.currentstar > 10) this.currentstar = 10;
    if (starcollected != 0) {
      var tmp = this.wh.add.sprite(0, 0, 'star');
      tmp.displayWidth = tmp.displayHeight = 32;
      tmp.setPosition(
        this.floater.x,
        this.floater.y - this.floater.displayHeight / 2
      );
      var tw2 = this.wh.tweens.add({
        targets: tmp,
        x: width - 40,
        y: height - 40,
        ease: 'Power1',
        duration: 300, // duration of animation; higher=slower
        loop: 0,
        yoyo: false,
        onComplete: function () {
          this.plog.log('startweencomplete', this);
          this.targets[0].destroy();
        },
      });
    }
    if (this.currentstar >= 10) {
      this.win();
    }
  }
  */
  startimerfunc() {
    this.plog.log("startimerfunc");
    if (this.currentstar > 1) this.currentstar--;
    if (this.currentstar > 10) this.currentstar = 10;
  }
  async win() {
    var winres = await this.wh.registry.list.rchttp.getcommon2(
      'spinner_spin',
      { code: this.npcid }
    );
    this.plog.log("spinner_spin", winres);
    //show(wh, reward, config = {})
    this.wh.registry.list.showreward.show(this.wh, ["stackitem:" + winres["item"] + ":1"], { text: "You Caught: " });
    this.close();
    await this.wh.registry.list.rchttp.updatecharinfo();
    this.wh.registry.list.rcvarpass.inputitem =
      await this.wh.registry.list.rchttp.getcommon2('inputitem', {
        mode: 'get',
      });
    this.wh.registry.list.gameitf.inputitem_updatedisplay(this.wh);
  }
  async close() {
    this.closing = true;
    if (this.powertimer != undefined) this.powertimer.remove();
    this.plog.log('this.floatertimer', this.floatertimer);
    if (this.floatertimer != undefined) this.floatertimer.remove();
    if (this.startimer != undefined) this.startimer.remove();

    for (const k in this.wh.spinnercontainer.list) {
      try {
        await this.wh.spinnercontainer.remove(this.wh.spinnercontainer.list[k], true);
      } catch (e) {
        this.plog.log("ERROR!", e);
      }
      //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list[k].destroy();
    }
    try {
      this.wh.spinnercontainer.destroy();
    } catch (e) {
      this.plog.log("ERROR!", e);
    }
    this.wh.spinnercontainer = undefined;
    //this=undefined;
    this.wh.kbfocus = "";
  }
  async startspin() {
    this.plog.log("startspin");

    if (this.data["usedcount"] >= this.data["spinner"]["limitperday"]) {
      var cfm = await this.wh.registry.list.rctoast.rcconfirm(this.wh,
        this.wh.trs("f:spinnercontainer:Please Confirm===Please Confirm"),
        this.wh.trs("f:spinnercontainer:Out of free spin===Out of free spin") + "\n" + this.wh.trs("f:spinnercontainer:You are spending diamond to spin?===You are spending diamond to spin?"));
      if (cfm != "ok") return;
    }


    this.spincursor = Math.floor(Math.random() * (7 - 0) + 0);
    var tw1 = this.wh.tweens.add({
      targets: this.selected,
      displayWidth: 96,
      displayHeight: 96,
      ease: 'Power3',
      duration: 500, // duration of animation; higher=slower
      loop: 0,
      yoyo: false,
      onComplete: function () {
        this.plog.log("startspin onComplete");
        var startnum = Math.random() * (15 - 8) + 8;
        this.startspin_spin(startnum);
      }.bind(this)
    });

    var tw2 = this.wh.tweens.add({
      targets: this.playbtn,
      alpha: 0,
      ease: 'Power3',
      duration: 300, // duration of animation; higher=slower
      loop: 0,
      yoyo: false
    });
  }
  startspin_spin(time) {
    this.spincursor++;
    if (this.spincursor == 8) this.spincursor = 0;
    this.plog.log("startspin_spin", time, this.spincursor);
    var tw1 = this.wh.tweens.add({
      targets: this.selected,
      x: this.obj[this.spincursor].x,
      y: this.obj[this.spincursor].y,
      ease: 'Power3',
      duration: time, // duration of animation; higher=slower
      loop: 0,
      yoyo: false,
      onComplete: async function () {
        if (time < 780) {
          var addnum = Math.random() * (30 - 10) + 10;
          addnum = addnum / 100;
          this.startspin_spin(time * (1.0 + addnum));
        } else {
          var res = await this.wh.registry.list.rchttp.getcommon2("spinnerspin",
            { item: this.spincursor, code: this.npcid });
          if (res != undefined) {
            this.wh.registry.list.showreward.show(this.wh, res, {});
          }
          await this.wh.registry.list.rchttp.updatecharinfo();

          this.close();
        }
      }.bind(this)
    });


  }
  scrolltimerfunc() {
    this.bg1.tilePositionX += 0.2;
    this.ll1.tilePositionX += 0.8;
    this.bg2.tilePositionX += 1;
    this.ll2.tilePositionX += 1.2;
    this.bg3.tilePositionX += 2;
  }
}
