/**
 * @license
 * SPDX-License-Identifier: Apache-2.0
 */
/**
 * The type of the hints that can be passed to a ResourceUrlPolicy.
 */
export var ResourceUrlPolicyHintsType;
(function (ResourceUrlPolicyHintsType) {
  ResourceUrlPolicyHintsType[ResourceUrlPolicyHintsType["STYLE_TAG"] = 0] = "STYLE_TAG";
  ResourceUrlPolicyHintsType[ResourceUrlPolicyHintsType["STYLE_ATTRIBUTE"] = 1] = "STYLE_ATTRIBUTE";
  ResourceUrlPolicyHintsType[ResourceUrlPolicyHintsType["HTML_ATTRIBUTE"] = 2] = "HTML_ATTRIBUTE";
})(ResourceUrlPolicyHintsType || (ResourceUrlPolicyHintsType = {}));
/**
 * Parses a URL. If the URL is invalid, returns URL instance with
 * `about:invalid`.
 */
export function parseUrl(value) {
  try {
    return new URL(value, window.document.baseURI);
  } catch (e) {
    return new URL('about:invalid');
  }
}