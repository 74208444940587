import GetStyle from '../../../utils/canvas/GetStyle.js';

//  Key: [ Object Key, Default Value, postCallback ]

var PropertyMap = {
  // background
  backgroundColor: ['backgroundColor', null, GetStyle],
  backgroundColor2: ['backgroundColor2', null, GetStyle],
  backgroundHorizontalGradient: ['backgroundHorizontalGradient', true, null],
  backgroundStrokeColor: ['backgroundStrokeColor', null, GetStyle],
  backgroundStrokeLineWidth: ['backgroundStrokeLineWidth', 2, null],
  backgroundCornerRadius: ['backgroundCornerRadius', 0, null],
  backgroundCornerIteration: ['backgroundCornerIteration', null, null],
  // font
  fontFamily: ['fontFamily', 'Courier', null],
  fontSize: ['fontSize', '16px', null],
  fontStyle: ['fontStyle', '', null],
  color: ['color', '#fff', GetStyle],
  stroke: ['stroke', '#fff', GetStyle],
  strokeThickness: ['strokeThickness', 0, null],
  shadowOffsetX: ['shadow.offsetX', 0, null],
  shadowOffsetY: ['shadow.offsetY', 0, null],
  shadowColor: ['shadow.color', '#000', GetStyle],
  shadowBlur: ['shadow.blur', 0, null],
  shadowStroke: ['shadow.stroke', false, null],
  shadowFill: ['shadow.fill', false, null],
  // underline
  underlineColor: ['underline.color', '#000', GetStyle],
  underlineThickness: ['underline.thickness', 0, null],
  underlineOffset: ['underline.offset', 0, null],
  // strikethrough
  strikethroughColor: ['strikethrough.color', '#000', GetStyle],
  strikethroughThickness: ['strikethrough.thickness', 0, null],
  strikethroughOffset: ['strikethrough.offset', 0, null],
  // align
  halign: ['halign', 'left', null],
  valign: ['valign', 'top', null],
  // size
  maxLines: ['maxLines', 0, null],
  fixedWidth: ['fixedWidth', 0, null],
  fixedHeight: ['fixedHeight', 0, null],
  resolution: ['resolution', 0, null],
  lineSpacing: ['lineSpacing', 0, null],
  xOffset: ['xOffset', 0, null],
  rtl: ['rtl', false, null],
  testString: ['testString', '|MÃ‰qgy', null],
  baselineX: ['baselineX', 1.2, null],
  baselineY: ['baselineY', 1.4, null],
  // wrap
  wrapMode: ['wrap.mode', 0, null],
  wrapWidth: ['wrap.width', 0, null],
  wrapCallback: ['wrap.callback', null],
  wrapCallbackScope: ['wrap.callbackScope', null]
};
export default PropertyMap;