import { Injectable } from '@angular/core';
//import { AnonymousSubject } from 'rxjs/internal/Subject';
import { Platform } from '@ionic/angular';
//import { RCToastService } from './rctoast.service';
import { plog } from './plog.service';

@Injectable({
  providedIn: 'root',
})
export class RCVarsService {
  //public rc_gamemode: string;
  public activemapreveal = [];
  static rc_gamemode: string;
  static rc_playerID: string;
  static rc_playerName: string;
  //public rc_baseurl = 'https://peacely.raincash.co/provider/';
  public rc_baseurl = 'https://assets.peacely.online/provider/';
  public rc_website = 'https://peacely.online/';
  //static lcatname: any;
  static gameobj: any;
  public rcgame;
  static rc_playinggame: any;
  static rc_sid: any;
  public rc_server;
  static rcserver;
  public rc_sv; // server list , from base app init
  public rcserver_url: string; //url of choosen server
  public rcserver_wsurl: string; //WS url of choosen server
  public svversionstring: string;
  static racelist;
  public mycc;
  public paymentoptions = [];
  public activemapmetrix: any;
  public activemapmetrixmob: any;
  public activemapmetrixmobx: any;

  public activemap: any;
  public activechar: any;
  public scene_preload: any;
  public gameobj;
  public allchars;//= any[];
  public iapstoredata: [];
  public inputitem = []; //define when npc exists in phm - init_preloaddone
  public charstatus_tp: [];
  public gamer;
  public reslist: any[];
  public anims: [];
  public allmap: [];
  public npc: [];
  public allquest: [];
  public allrace: [];
  static npcconvers: [];
  public allnpcshop: [];
  public allstackitem: [];
  public allitem: [];
  public gamemenu: [];
  public equippos: [];
  public level: [];
  public allmob: [];
  public skillweapontp: [];
  public stackitemtype: [];
  public skilltp: [];
  public allanims: [];
  public stdstat: [];
  public lng: any;
  public lang = "EN";//default language
  plog;
  //public zsortlis: Array<any>;
  //public zsortlist:[];
  //iwidth: number;
  //iheight;
  charinfo;

  constructor(public platform: Platform) {
    this.create();
    this.plog = new plog();
  }
  create() {
    //this.iwidth = Number(this.platform.width);
    //this.iheight = Number(this.platform.height);
  }
  getlocal(url: never) {
    //this.plog.log("getlocal",url,this.reslist);
    //this.rcvarsservice.rc_baseurl
    //var urlx="provider"+url;
    if (this.reslist.includes(url)) {
      //this.plog.log("getlocal, found ",url);
      return "../assets/provider/provider/" + url;
    } else {
      //this.plog.log("getlocal, NOT found ",url);
      return this.rcserver_url + url;
    }
  }
  async setactivechar(chardb, charid) {
    //this.plog.log("rcvarservice setactivechar", chardb, charid);
    //this.plog.log("rcvarservice this", this);
    if (chardb == undefined) {
      this.plog.error("setactivechar, chardb undefined");
      return;
    }
    if (chardb['ok'] == false) {
      this.gameobj.registry.list.rctoast.alert(
        'Error',
        ' Update Data error',
        ' please try again'
      );
      return;
    }
    if (chardb == undefined) {
      //this.plog.log("rcvarservice chardb", this);

      this.gameobj.registry.list.rctoast.alert(
        'Error',
        ' Update Data error',
        ' please try again'
      );
    } else {
      this.charinfo = chardb;
      this.activechar = this.charinfo[charid];
    }
  }
}
