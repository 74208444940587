var ALIGN_CONST = {
  /**
  * A constant representing a top-left alignment or position.
  * @constant
  * @name Phaser.Display.Align.TOP_LEFT
  * @since 3.0.0
  * @type {integer}
  */
  TOP_LEFT: 0,
  /**
  * A constant representing a top-center alignment or position.
  * @constant
  * @name Phaser.Display.Align.TOP_CENTER
  * @since 3.0.0
  * @type {integer}
  */
  TOP_CENTER: 1,
  /**
  * A constant representing a top-right alignment or position.
  * @constant
  * @name Phaser.Display.Align.TOP_RIGHT
  * @since 3.0.0
  * @type {integer}
  */
  TOP_RIGHT: 2,
  /**
  * A constant representing a left-top alignment or position.
  * @constant
  * @name Phaser.Display.Align.LEFT_TOP
  * @since 3.0.0
  * @type {integer}
  */
  LEFT_TOP: 3,
  /**
  * A constant representing a left-center alignment or position.
  * @constant
  * @name Phaser.Display.Align.LEFT_CENTER
  * @since 3.0.0
  * @type {integer}
  */
  LEFT_CENTER: 4,
  /**
  * A constant representing a left-bottom alignment or position.
  * @constant
  * @name Phaser.Display.Align.LEFT_BOTTOM
  * @since 3.0.0
  * @type {integer}
  */
  LEFT_BOTTOM: 5,
  /**
  * A constant representing a center alignment or position.
  * @constant
  * @name Phaser.Display.Align.CENTER
  * @since 3.0.0
  * @type {integer}
  */
  CENTER: 6,
  /**
  * A constant representing a right-top alignment or position.
  * @constant
  * @name Phaser.Display.Align.RIGHT_TOP
  * @since 3.0.0
  * @type {integer}
  */
  RIGHT_TOP: 7,
  /**
  * A constant representing a right-center alignment or position.
  * @constant
  * @name Phaser.Display.Align.RIGHT_CENTER
  * @since 3.0.0
  * @type {integer}
  */
  RIGHT_CENTER: 8,
  /**
  * A constant representing a right-bottom alignment or position.
  * @constant
  * @name Phaser.Display.Align.RIGHT_BOTTOM
  * @since 3.0.0
  * @type {integer}
  */
  RIGHT_BOTTOM: 9,
  /**
  * A constant representing a bottom-left alignment or position.
  * @constant
  * @name Phaser.Display.Align.BOTTOM_LEFT
  * @since 3.0.0
  * @type {integer}
  */
  BOTTOM_LEFT: 10,
  /**
  * A constant representing a bottom-center alignment or position.
  * @constant
  * @name Phaser.Display.Align.BOTTOM_CENTER
  * @since 3.0.0
  * @type {integer}
  */
  BOTTOM_CENTER: 11,
  /**
  * A constant representing a bottom-right alignment or position.
  * @constant
  * @name Phaser.Display.Align.BOTTOM_RIGHT
  * @since 3.0.0
  * @type {integer}
  */
  BOTTOM_RIGHT: 12
};
export default ALIGN_CONST;