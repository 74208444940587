import { AStarFinder } from "astar-typescript";
import { plog } from "./plog.service";

export class MapPet {
  o;
  wh;
  cattimeline;
  currentx;
  currenty;
  sprite;
  doing = "";
  behavetimer;
  catbehavetimer;
  doinginprocess = false;
  catdoing = "";
  astar;
  walkarray = [];
  lastspriteface;
  idlecountdown = 0;
  actionspeed;
  hidecountdown = 0;
  mobdata;
  walktarget = "";
  petname;
  iscat = false;
  petid = 0;
  duringdestroying = false;
  plog;
  ///catchkloading=false;

  constructor(wh, o) {
    this.wh = wh;
    this.o = o;
    this.plog = new plog();
    if (this.o.petid != undefined && this.wh.mobs[this.o.petid] != undefined) {
      //this.plog.error("duplicate pet creation constructor", this.o,this.wh.mobs[this.o.petid]);
      return;
    }
    //this.plog.log("MapPet constructor", wh, o);
    if (this.o["mobcode"] == undefined || this.o["mobcode"] == "") return;
    if (this.wh.registry.list.rcvarpass.activemapmetrix == undefined) return;
    if (this.wh.registry.list.rcvarpass.activemapmetrix.length == 0) return;
    var catchk = this.o["mobcode"].substring(0, 3);

    if (catchk == "cat") {
      //this.plog.log("mappet found cat", this.o); //return;
      this.iscat = true;
      if (this.wh.anims.anims.entries[this.o.sprite] == undefined) {
        this.wh.registry.list.phm.loadcatsprite(this.o.sprite);
      }
      this.petname = this.o["petname"];
      this.petid = this.o["petid"];
    } else {
      this.o.sprite = "petstar_a";
      this.petname = this.o["petname"];
      //this.o.spawnpos == "15-15";
      if (this.o.followdist % 2 == 0) this.o.sprite = "petstar_c";
      this.wh.registry.list.gameitf.genloadedanims(this.wh, this.o.sprite);
    }

    var mobchkmax = 10;
    var mobchkmin = 5;
    var monnum = Math.floor(Math.random() * (mobchkmax - mobchkmin) + mobchkmin);
    this.actionspeed = 220;
    this.idlecountdown = monnum;

    //this.plog.log("MapPet constructorthis.wh.registry.list.rcvarpass.activemapmetrix", this.wh.registry.list.rcvarpass.activemapmetrix);
    //
    this.mobdata = this.wh.registry.list.rcvarpass.allmob[this.o.mobcode];
    if (this.wh.registry.list.rcvarpass.activemapmetrix == undefined) {
      //this.wh.registry.list.rcvarpass.activemapmetrix=[[]];
    }
    //this.plog.log("MapPet astar upon construct", this.wh.registry.list.rcvarpass.activemapmetrix);
    if (this.wh.registry.list.rcvarpass.activemapmetrix == undefined) {
      this.plog.log("this.wh.registry.list.rcvarpass.activemapmetrix not defind");
      return;
    }
  }
  getobj() {
    return this.o;
  }
  spawn() {
    //this.plog.log("mappet spawn()");

    if (this.o.petid != undefined && this.wh.mobs[this.o.petid] != undefined) {
      //this.plog.error("duplicate pet creation spawn", this.o,this.wh.mobs[this.o.petid]);
      return;
    }
    if (this.duringdestroying == true) {
      this.plog.log("mappet duringdestroying (spawn)");
      return;
    }

    if (this.iscat == true) {
      this.catspawn();
      return;
    }
    if (this.o["mobcode"] == undefined || this.o["mobcode"] == "") return;
    if (this.sprite != undefined && this.o["mobcode"] != "") return;
    //if (this.wh.registry.list.rcvarpass.activemapmetrix.length == 0) return;
    //this.plog.log("MapPet spawn", this, this.wh);
    //var gridclone = Object.assign({}, this.wh.registry.list.rcvarpass.activemapmetrix);
    //this.plog.log("spawngridclone",gridclone);

    this.astar = new AStarFinder({
      grid: {
        matrix: this.wh.registry.list.rcvarpass.activemapmetrix
      },
      includeStartNode: false,
      includeEndNode: true
    });
    //this.plog.log("mob astar", this.astar, this);
    //this.wh.mobs.push(this);

    //if (this.wh.textures.list[this.o.sprite] == undefined) {
    if (this.wh.anims.anims.entries[this.o.sprite] == undefined) {
      this.plog.log("MapPet.spawn, can't find sprite", this.o);
      return;
    }
    this.sprite = this.wh.add.sprite(100, 100, this.o.sprite);
    this.sprite.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);

    try {
      this.sprite.play("" + this.o["sprite"] + "");//.setDisplayOrigin(0.5, 0.8);
    } catch (err) {
      this.plog.log("MapPet.spawn() error while trying to play idle ", err);
      return;
    }
    this.wh.zsortlist.push(this.sprite);
    this.wh.extradestroylist.push(this.sprite);
    this.sprite.mapmonobj = this;
    //tmp.sprite.setScale(this.o["mapsize"]);
    this.sprite.displayWidth = this.o["mapsize"] * 32;
    this.sprite.scaleY = this.sprite.scaleX;
    //this.plog.log("mapmop spawn sprite", this.sprite);
    //tmp.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);

    /*this.sprite.setInteractive();
    this.sprite.on("pointerdown", function () {
      this.plog.log("mobclick", this);

      //click mob on backyard
      this.plog.log("mobpetname", this);
      if (this.petname != undefined && this.petname != "") {
        //this.wh.registry.list.phm.charsaybubble(this.sprite, this.petname);
        this.wh.registry.list.phm.charsaybubble(this.wh.char_main, "That's " + this.petname);

      }
    }, this);*/
    //var tmp = this.rcvarsservice.gameobj.scene.scenes[0].add.image((spawnposa[1] * 32), (spawnposa[0] * 32), this.rcvarsservice.activechar["sprite"], "walk-d-1").setDepth(999);
    this.wh.mapcontainer.add(this.sprite);
    //this.movetorandomarea();
    var spawnpos = this.o.spawnpos.split("-")
    this.currentx = Number(spawnpos[0]);
    this.currenty = Number(spawnpos[1]);
    this.sprite.x = ((this.currentx - 1) * 32);//+width/2;
    this.sprite.y = ((this.currenty - 1) * 32);//+width/2;
    this.sprite.displayHeight = 32 * this.o["mapsize"];
    this.sprite.scaleX = this.sprite.scaleY;//displayWidth = 64 * this.o["mapsize"];
    this.sprite.setOrigin(0.5, 0.5);


    this.wh.mobs[this.o.petid] = this;
    /*var moveto = this.getrandwalkpos() + "";
    var movetoa = moveto.split("-");
    var startPos = { x: this.currentx, y: this.currenty }
    var goalPos = { x: movetoa[0], y: movetoa[1] }
    //this.plog.log("I'mma walk to ", movetoa);
    var myPathway = this.astar.findPath(startPos, goalPos);
    //this.plog.log(myPathway);
    this.walkarray = myPathway;*/


    //this.plog.log("charic",charic);
    //this.plog.log("moveTo", this.charzindex, this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer);
    //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.moveTo(tmp, this.charzindex);
    //this.rcvarsservice.gameobj.scene.scenes[0].char_main = tmp;
    //this.behave();

    this.behavetimer = this.wh.time.addEvent({
      delay: this.actionspeed,
      callback: this.behave,
      callbackScope: this,
      loop: true
    });
  }

  catspawn() {
    //this.plog.log("mappet catspawn()");
    if (this.duringdestroying == true) {
      this.plog.log("mappet duringdestroying (catspawn)");
      return;
    }
    if (this.o["mobcode"] == undefined || this.o["mobcode"] == "") return;
    if (this.sprite != undefined && this.o["mobcode"] != "") return;
    //var gridclone = Object.assign([], this.wh.registry.list.rcvarpass.activemapmetrix);
    //const gridclone = this.wh.registry.list.rcvarpass.activemapmetrix.slice();
    //const gridclone2 = [...this.wh.registry.list.rcvarpass.activemapmetrix];
    //const gridclone= gridclone2.slice();
    const gridclone = JSON.parse(JSON.stringify(this.wh.registry.list.rcvarpass.activemapmetrix))
    for (const k in this.wh.npclist) {
      var v = this.wh.npclist[k];
      if (gridclone[v.currenty] != undefined) {
        gridclone[v.currenty][v.currentx] = 0;
      }
    }
    //this.plog.log("catspawngridclone",gridclone);
    this.astar = new AStarFinder({
      grid: {
        matrix: gridclone
      },
      includeStartNode: false,
      includeEndNode: true
    });
    //this.plog.log("mob astar", this.astar, this);
    //this.wh.mobs.push(this);


    //this.plog.log("mappet catspawn cat", this.wh.anims.anims.entries[this.o.sprite + "-idle"], this.o);
    //if (this.wh.textures.list[this.o.sprite] == undefined) {
    if (this.wh.anims.anims.entries[this.o.sprite + "-idle"] == undefined) {
      //this.plog.log("MapPet.catspawn, can't find cat sprite", this.o);
      return;
    }
    this.sprite = this.wh.add.sprite(100, 100, this.o.sprite);
    this.sprite.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);
    //this.plog.log("MapPet.catspawn, creating cat sprite", this.sprite);

    try {
      this.sprite.play("" + this.o["sprite"] + "-idle");//.setDisplayOrigin(0.5, 0.8);
    } catch (err) {
      this.plog.log("MapPet.catspawn() error while trying to play idle ", err);
      return;
    }

    if (this.wh.registry.list.rcvarpass.activechar['map'] == "home") {
      this.sprite.setInteractive(this.wh.input.makePixelPerfect(150));
      this.sprite.on("pointerdown", function () {
        this.plog.log("petcatclick", this);
        if (this.petname != undefined && this.petname != "") {
          //this.wh.registry.list.phm.charsaybubble(this.sprite, this.petname);
          //this.wh.registry.list.phm.charsaybubble(this.wh.char_main, "That's " + this.petname);
          this.wh.registry.list.execmod.exec("petpetcat:" + this.petid + ":" + this.o["sprite"], this.wh);
        }
      }, this);
    }
    this.wh.zsortlist.push(this.sprite);
    this.wh.extradestroylist.push(this.sprite);
    this.sprite.mapmonobj = this;
    //tmp.sprite.setScale(this.o["mapsize"]);
    //this.sprite.displayWidth = this.o["mapsize"] * 32*2;
    //this.sprite.scaleY = this.sprite.scaleX; 
    this.wh.mapcontainer.add(this.sprite);
    //this.movetorandomarea();
    var spawnpos = this.o.spawnpos.split("-")
    this.currentx = Number(spawnpos[0]);
    this.currenty = Number(spawnpos[1]);
    this.sprite.x = ((this.currentx - 1) * 32);//+width/2;
    this.sprite.y = ((this.currenty - 1) * 32);//+width/2;
    this.sprite.displayHeight = 32 * this.o["mapsize"] * 2;
    this.sprite.scaleX = this.sprite.scaleY;//displayWidth = 64 * this.o["mapsize"];
    this.sprite.setOrigin(0.5, 0.5);
    //this.catbehave();
    this.wh.mobs[this.o.petid] = this;

    this.catbehavetimer = this.wh.time.addEvent({
      delay: this.actionspeed,
      callback: this.catbehave,
      callbackScope: this,
      loop: true
    });
  }
  movetorandomarea() {
    var moveto = this.getrandwalkpos() + "";
    var movetoa = moveto.split("-");

    //this.plog.log(random, months[random]);
  }
  getrandwalkpos() {
    var mobwalkarr = this.wh.registry.list.rcvarpass.activemap["mobwalk"];
    if (mobwalkarr == undefined) {
      this.plog.log("mapmon movetorandomarea: mobwalk not found");
      var mobwalkarr = this.wh.registry.list.rcvarpass.activemap["walkable"];

    }
    var usablemobwalk = [];
    for (var key in mobwalkarr) {
      var val = mobwalkarr[key];
      if (val == "yn_y") {
        usablemobwalk.push(key);
      }
    }
    var randompos = Math.floor(Math.random() * usablemobwalk.length);
    var moveto = usablemobwalk[randompos];
    //this.plog.log("mapmon movetorandomarea: usablemobwalk", usablemobwalk);
    //this.plog.log("mapmon movetorandomarea: randompos", moveto);
    return moveto;
  }
  hide(countdown) { //more like "idle" countdown in mappet
    //this.sprite.visible = false;
    //this.hidecountdown = (countdown * 1000) / this.actionspeed;
    this.hidecountdown = countdown;// * this.actionspeed;
  }
  async behave() {
    //this.plog.log("MapPet.behave",this.wh.combatcontainer);
    //return;
    if (this.duringdestroying == true) {
      this.plog.log("mappet duringdestroying (behave)");
      return;
    }
    if (this.wh.pauseload == true) return;
    if (this.sprite == undefined) {
      this.plog.log("error, MapPet.behave(), this.sprite=undefined");
      return;
    }
    if (this.wh.combatcontainer != undefined && this.wh.combatcontainer.list.length > 0) {
      return;
    }
    if (this.hidecountdown > 0) {
      //this.plog.log("MapPet hidecountdown ", this.hidecountdown);
      this.hidecountdown = this.hidecountdown - 1;
      return;
    }
    if (this.sprite.visible == false) {
      this.wh.registry.list.phm.mapitemeffect(this.sprite, "warpgate_b");
      this.sprite.visible = true;
    }

    //this.plog.log("MapPet.behave",this.wh.combatcontainer);
    if (this.astar == undefined) {
      this.plog.log("mappet astar undefinded")
    }
    //this.plog.log("behaving", this);
    var action = ["follow"];
    //var action = ["walk"];
    this.doing = "follow";


    if (this.doinginprocess == true) return;


    if (this.doing == "follow") {
      //this.sprite.play("mob_" + this.o.sprite + "_idle");

      //this.doinginprocess=true;
      //this.plog.log("Mappet doing=follow",this.o.follow);
      if (this.wh.registry.list.rcvarpass.activemapmetrix == undefined) return;
      if (this.wh.registry.list.rcvarpass == undefined) return;
      if (this.o.follow == undefined) {
        this.duringdestroying = true;
        await this.mydestroy();
        return;
      }
      if (this.o.follow != undefined && this.o.follow.scene == undefined) {
        this.duringdestroying = true;
        await this.mydestroy();
        return;
      }
      if (this.walkarray.length == 0) {
        if (this.iscat != true && this.wh.registry.list.rcvarpass.activechar['map'] == "home") {
          if (this.hidecountdown <= 0 && this.walkarray.length <= 1) {
            this.sprite.visible = false;
            this.hide(10000);
          }
          var bytarget = [];
          for (const zwk in this.wh.registry.list.rcvarpass.activemap['zwarp']) {
            var zwv = this.wh.registry.list.rcvarpass.activemap['zwarp'][zwk];
            if (zwv == "backyard") {
              var zwka = zwk.split('-');
              //this.plog.log('mappet redirecting to backyard', zwka, this.astar.grid.gridNodes);
              bytarget[0] = zwka[0];
              bytarget[1] = zwka[1];
              break;
            }
          }
          var startPos = { x: this.currentx, y: this.currenty }
          var goalPosby = { x: bytarget[0], y: bytarget[1] }
          this.plog.log('mappet redirecting ', startPos, goalPosby);

          //this.plog.log("I'mma walk to ", movetoa);
          //this.plog.log("mobastar ", this.astar);
          if (startPos == undefined || goalPosby == undefined) return;
          try {
            var myPathway = this.astar.findPath(startPos, goalPosby);
          } catch (e) {
            this.plog.log("mappet astar findPath error", e);
            return;
          }
          //this.plog.log(myPathway);
          this.walkarray = myPathway;
          this.walktarget = "";
          this.doing = "";
          this.o.followdist = 0;
        } else {

          var moveto = this.o.follow.currentx + "-" + this.o.follow.currenty;
          var movetoa = moveto.split("-");
          var startPos = { x: this.currentx, y: this.currenty }
          var goalPos = { x: movetoa[0], y: movetoa[1] }
          //this.plog.log("I'mma walk to ", movetoa);
          //this.plog.log("mobastar ", this.astar);
          if (startPos == undefined || goalPos == undefined) return;
          try {
            var myPathway = this.astar.findPath(startPos, goalPos);
          } catch (e) {
            this.plog.log("mappet astar findPath error", e);
            return;
          }
          //this.plog.log(myPathway);
          this.walkarray = myPathway;
          this.walktarget = "";
          this.doing = "";
        }
        return;
      }
      if (this.walkarray.length != 0) {

        if (this.walkarray.length <= this.o.followdist) {
          this.walkarray = [];
          return;
        }
        var nextstop = this.walkarray.shift();;
        if (nextstop == undefined) {
          this.walktarget = "";
          this.doing = "";
          return;
        }

        var newcx = (nextstop[0] - 1) * 32;
        var newcy = (nextstop[1] - 1) * 32;
        this.currentx = nextstop[0];
        this.currenty = nextstop[1];
        //this.plog.log("walk", dir, nextstop);
        var walkspeednormal = this.actionspeed - 1;
        var tweenchar = this.wh.tweens.add({
          targets: this.sprite,
          x: (newcx),
          y: (newcy),
          ease: 'Linear',
          duration: walkspeednormal,
          repeat: 0,
          onStart: function () {
            //this.plog.log('onStart'); this.plog.log(arguments); 
          },
          onComplete: function (wh) {
            //this.plog.log('onComplete', this);
            this.doing = "";
            if (this.targets[0].mapmonobj.o.nature == "teleport" && this.targets[0].mapmonobj.walkarray.length == 0) {
              this.targets[0].scene.phasermapservice.mapitemeffect(this.targets[0], "effect95_b");
            }

          },
          onYoyo: function () {
            //this.plog.log('onYoyo'); this.plog.log(arguments); 
          },
          onRepeat: function () {
            //this.plog.log('onRepeat'); this.plog.log(arguments); 
          }
        });
      }
    }


  }
  showspritewalk(dir) {
    //this.sprite.alpha = 0.5;
    //this.sprite.setAlpha(0.5);
    if (dir == "left") {
      this.sprite.flipX = false;
    }
    if (dir == "right") {
      this.sprite.flipX = true;
    }
  }
  async mydestroy() {
    //return;
    //this.plog.log("MapPet mydestroy();", this.duringdestroying);
    //this.plog.log("MapPet mydestroy();", this.catbehavetimer);

    if (this.behavetimer != undefined) {
      try {
        this.behavetimer.paused = true;

        await this.behavetimer.remove(false);
        //this.behavetimer = undefined;
      } catch (e) {
        this.plog.log("mappet mydestroy error1", e)
      }
    }

    if (this.catbehavetimer != undefined) {
      try {
        this.catbehavetimer.paused = true;
        await this.catbehavetimer.remove(false);
        //this.behavetimer = undefined;
      } catch (e) {
        this.plog.log("mappet mydestroy error2", e)
      }
    }

    if (this.sprite != undefined) {
      this.sprite.destroy();
      this.sprite = undefined;
    }
  }
  catbehavehowlonginrange = 0;
  catbehavehowlonginrange_decis = 40;

  async catbehave() {
    //this.plog.log("MapPet.catbehave", this.catbehavehowlonginrange);//this.wh.combatcontainer);
    //return;

    if (this.duringdestroying == true) {
      this.mydestroy();
      this.plog.log("mappet duringdestroying (catbehave)");
      return;
    }
    if (this.wh.pauseload == true) return;
    if (this.sprite == undefined) {
      this.plog.log("error, MapPet.catbehave(), this.sprite=undefined", this.catbehavetimer);
      //.warn("error, MapPet.catbehave(), this.sprite=undefined", this.wh.anims);
      if (
        this.wh.anims.anims.entries[this.o.sprite + "-idle"] != '' &&
        this.wh.anims.anims.entries[this.o.sprite + "-idle"] != undefined
      ) {
        this.spawn();
      }
      return;
    }
    if (this.wh.combatcontainer != undefined && this.wh.combatcontainer.list.length > 0) {
      return;
    }
    if (this.hidecountdown > 0) {
      //this.plog.log("MapPet hidecountdown ", this.hidecountdown);
      this.hidecountdown = this.hidecountdown - 1;
      return;
    }
    if (this.sprite.visible == false) {
      this.wh.registry.list.phm.mapitemeffect(this.sprite, "warpgate_b");
      this.sprite.visible = true;
    }

    //this.plog.log("MapPet.behave",this.wh.combatcontainer);
    if (this.astar == undefined) {
      this.plog.log("mappet astar undefinded")
    }
    //this.plog.log("behaving", this);
    var action = ["follow"];
    //var action = ["walk"];
    this.doing = "follow";

    if (this.doinginprocess == true) return;

    //this.sprite.play("mob_" + this.o.sprite + "_idle");

    //this.doinginprocess=true;
    //this.plog.log("Mappet doing=follow",this.o.follow);
    if (this.wh.registry.list.rcvarpass.activemapmetrix == undefined) return;
    if (this.wh.registry.list.rcvarpass == undefined) return;
    if (this.o.follow == undefined) {
      this.duringdestroying = true;
      await this.mydestroy();
      return;
    }
    if (this.o.follow != undefined && this.o.follow.scene == undefined) {
      this.duringdestroying = true;
      await this.mydestroy();
      return;
    }

    //do calculate
    if (this.walkarray.length == 0) {
      if (this.iscat != true && this.wh.registry.list.rcvarpass.activechar['map'] == "home") {
        if (this.hidecountdown <= 0 && this.walkarray.length <= 1) {
          this.sprite.visible = false;
          this.hide(10000);
        }
        var bytarget = [];
        for (const zwk in this.wh.registry.list.rcvarpass.activemap['zwarp']) {
          var zwv = this.wh.registry.list.rcvarpass.activemap['zwarp'][zwk];
          if (zwv == "backyard") {
            var zwka = zwk.split('-');
            this.plog.log('mappet redirecting to backyard', zwka, this.astar.grid.gridNodes);
            bytarget[0] = zwka[0];
            bytarget[1] = zwka[1];
            break;
          }
        }
        var startPos = { x: this.currentx, y: this.currenty }
        var goalPosby = { x: bytarget[0], y: bytarget[1] }
        this.plog.log('mappet redirecting ', startPos, goalPosby);

        //this.plog.log("I'mma walk to ", movetoa);
        //this.plog.log("mobastar ", this.astar);
        if (startPos == undefined || goalPosby == undefined) return;
        try {
          var myPathway = this.astar.findPath(startPos, goalPosby);
        } catch (e) {
          this.plog.log("mappet astar findPath error", e);
          return;
        }
        //this.plog.log(myPathway);
        this.walkarray = myPathway;
        this.walktarget = "";
        this.doing = "";
        this.o.followdist = 0;
      } else {
        this.catbehavehowlonginrange++;
        var moveto = this.o.follow.currentx + "-" + this.o.follow.currenty;
        var movetoa = moveto.split("-");
        var startPos = { x: this.currentx, y: this.currenty }
        var goalPos = { x: movetoa[0], y: movetoa[1] }
        //this.plog.log("I'mma walk to ", movetoa);
        //this.plog.log("mobastar ", this.astar);
        if (startPos == undefined || goalPos == undefined) return;
        try {
          var myPathway = this.astar.findPath(startPos, goalPos);
        } catch (e) {
          this.plog.log("mappet astar findPath error", e);
          return;
        }
        //this.plog.log(myPathway);
        if (myPathway.length <= this.o.followdist) {
          this.walkarray = [];
        } else {
          this.walkarray = myPathway;
          this.catdoing = "";
        }
        this.walktarget = "";
        this.doing = "";
        //return;
      }
    }

    if (this.walkarray != undefined && this.walkarray.length <= this.o.followdist) {
      this.walkarray = [];
    }

    //this.plog.log("cat behave, b4 dowalk", this.catbehavehowlonginrange);

    //do walk
    if (this.walkarray.length != 0) {
      //this.plog.log("cat behave, b4 dowalk", this.walkarray);
      var nextstop = this.walkarray.shift();;
      if (nextstop == undefined) {
        this.walkarray = [];
        this.walktarget = "";
        this.doing = "";
        this.catdoing = "";
        return;
      }

      var xdiff = this.currentx - nextstop[0];
      if (xdiff > 0) {
        this.faceleft();
      } else {
        this.faceright();
      }
      if (this.cattimeline != undefined) {
        try {
          this.cattimeline.stop();
          this.catdoing = "";
        } catch (e) {
          this.plog.log("ERROR", e);
        }
      }
      //this.plog.log("catbehave", xdiff);
      var newcx = (nextstop[0] - 1) * 32;
      var newcy = (nextstop[1] - 1) * 32;
      this.currentx = nextstop[0];
      this.currenty = nextstop[1];
      //this.plog.log("walk", dir, nextstop);
      this.catbehavehowlonginrange = 0;

      var walkspeednormal = this.actionspeed - 1;
      var tweenchar = this.wh.tweens.add({
        targets: this.sprite,
        x: (newcx),
        y: (newcy),
        ease: 'Linear',
        duration: walkspeednormal,
        repeat: 0,
        onStart: function () {
          //this.plog.log('onStart'); this.plog.log(arguments); 
          this.sprite.play({ key: this.o["sprite"] + "-walk" });
        }.bind(this),
        onComplete: function (wh) {
          //this.plog.log('onComplete', this);
          this.sprite.play({ key: this.o["sprite"] + "-idle" });

        }.bind(this),
        onYoyo: function () {
          //this.plog.log('onYoyo'); this.plog.log(arguments); 
        },
        onRepeat: function () {
          //this.plog.log('onRepeat'); this.plog.log(arguments); 
        }
      });
      //return;
    }

    // do catdoing if idle
    if (this.catdoing != "") {
      //this.plog.log("MapPet.catbehave this.catdoing skipping", this.catdoing);
      return;
    }
    if (this.catbehavehowlonginrange > this.catbehavehowlonginrange_decis) {
      //this.plog.log("MapPet.catbehave catplay");//this.wh.combatcontainer);
      //var cataction = ["climbnpc", "sleep", "run", "sneak", "walk"];
      var cataction = ["climbnpc", "crouch", "atkmob"];
      if (this.wh.registry.list.rcvarpass.activechar['map'] == "home") {
        cataction = ["crouch"];
      }
      var catdoing = cataction[Math.floor(Math.random() * cataction.length)];
      // this.plog.log("MapPet.catbehave catplay catdoing", catdoing, this.catbehavehowlonginrange);
      //catdoing = "atkmob";
      this.catdoing = catdoing;

      ///////////////////////////////////////////////////////////////////
      if (this.catdoing == "atkmob") {
        this.sprite.play({ key: this.o["sprite"] + "-jump", repeat: 0 });
        var tmp = this.findclosestenemy();
        if (tmp == undefined) return;
        //this.plog.log("findclosestenemy", tmp);
        if (this.wh.mobs == undefined) return;
        if (this.wh.mobs[tmp[0]] == undefined) return;
        if (this.wh.mobs[tmp[0]].sprite == undefined) return;

        if ((tmp[1] / 32) > 5) {
          //this.plog.log("findclosestenemy closest too far", (tmp[1] / 32), tmp[1]);
          this.catdoing = "";
          return;
        }
        var xdiff = this.currentx - this.wh.mobs[tmp[0]].currentx;
        var tgx = this.wh.mobs[tmp[0]].sprite.x;
        var tgy = this.wh.mobs[tmp[0]].sprite.y;
        if (xdiff > 0) {
          this.faceleft();
          tgx = this.wh.mobs[tmp[0]].sprite.x + 32;
        } else {
          this.faceright();
          tgx = this.wh.mobs[tmp[0]].sprite.x - 32;
        }
        //this.currentx=this.wh.mobs[tmp[0]].currentx;
        //this.currenty=this.wh.mobs[tmp[0]].currenty;
        //this.plog.log("findclosestenemy", tmp, this.wh.mobs[tmp[0]]);
        this.wh.mobs[tmp[0]].doing = "sleep";
        this.wh.mobs[tmp[0]].fixeddoing = "sleep";

        this.cattimeline = this.wh.add.timeline([
          {
            at: 0,
            tween: {
              targets: this.sprite,
              onComplete: function () {
                try {
                  this.sprite.play({ key: this.o["sprite"] + "-attack", repeat: -1, repeatDelay: 1800 });
                  this.cattimeline.destroy();

                } catch (e) {
                  this.plog.log("ERROR", e);
                }
              }.bind(this),
              x: { from: this.sprite.x, to: tgx },
              y: { from: this.sprite.y, to: tgy },
              ease: 'Power3',
              duration: 1000,
            }
          }

        ]);
        this.cattimeline.play();
      }
      if (this.catdoing == "crouch") {
        if (this.sprite == undefined) return;
        this.sprite.play({ key: this.o["sprite"] + "-crouch", repeat: 0 });

        this.cattimeline = this.wh.add.timeline([
          {
            at: 0,
            tween: {
              targets: this.sprite,
              onComplete: function () {
                try {
                  this.sprite.play({ key: this.o["sprite"] + "-liedown", repeat: -1 });
                  this.cattimeline.destroy();
                } catch (e) {
                  this.plog.log("ERROR", e);
                }
              }.bind(this),
              x: { from: this.sprite.x, to: this.sprite.x },
              y: { from: this.sprite.y, to: this.sprite.y },
              ease: 'Power3',
              duration: 1000,
            }
          }
        ]);
      }
      if (this.catdoing == "climbnpc") {
        var tmp = this.findclosestnpcs();
        if (tmp == undefined) return;
        if ((tmp[1] / 32) > 5) {
          //this.plog.log("climbnpc closest too far", (tmp[1] / 32), tmp[1]);
          //this.catdoing = "";
          return;
        }
        //this.plog.log("climbnpc", this.wh.npclist[tmp[0]]);
        //this.plog.log("climbnpc", tmp, this.astar);
        if (this.wh.npclist[tmp[0]] == undefined) {
          this.plog.log("this.wh.npclist[tmp[0]] undefined", tmp[0]);
          return;
        }

        if (this.wh.npclist[tmp[0]].width != 64 || this.wh.npclist[tmp[0]].height != 64) {
          //this.plog.log("this.wh.npclist[tmp[0]] size different", tmp[0]);
          return;
        }
        var tgx = this.wh.npclist[tmp[0]].currentx;
        var tgy = this.wh.npclist[tmp[0]].currenty;

        var masterx = (tgx - 1) * 32;
        var mastery = (tgy - 1) * 32;
        this.wh.mapcontainer.bringToTop(this.sprite);
        this.currentx = tgx;
        this.currenty = tgy;
        this.faceright();
        this.sprite.play({ key: this.o["sprite"] + "-jump", repeat: 0 })
        masterx = masterx - 10;
        mastery = mastery - 20;
        this.cattimeline = this.wh.add.timeline([
          {
            at: 0,
            tween: {
              targets: this.sprite,
              onComplete: function () {
                //this.plog.log('cattimeline complete ', arguments);
                //this.catdoing = "";
                this.sprite.play({ key: this.o["sprite"] + "-ledgeclimb", repeat: 0 });
                this.wh.mapcontainer.bringToTop(this.sprite);

                try {
                  this.cattimeline.destroy();
                  this.sprite.x = this.sprite.x + 12;
                  this.sprite.y = this.sprite.y - 12;

                } catch (e) {
                  this.plog.log("ERROR", e);
                }
              }.bind(this),
              x: { from: this.sprite.x, to: masterx },
              y: { from: this.sprite.y, to: mastery },
              ease: 'Power3',
              duration: 800,
            }
          }, {
            at: 801,
            tween: {
              targets: this.sprite,
              x: { from: masterx, to: masterx },
              y: { from: mastery, to: mastery },
              ease: 'Power3',
              duration: 1000,
              onComplete: function () {
                this.plog.log('cattimeline 3st tween complete ', this.sprite);
                this.sprite.play({ key: this.o["sprite"] + "-liedown", repeat: 20 });

                //this.cattimeline.destroy();
                this.sprite.x = this.sprite.x + 12;
                this.sprite.y = this.sprite.y - 12;
              }.bind(this),
            }
          }
        ]);
        this.cattimeline.play();
      }
      ///////////////////////////////////////////////////////////////////
    }




  }

  faceright() {
    this.sprite.flipX = false;
  }
  faceleft() {
    this.sprite.flipX = true;
  }
  findclosestnpcs() {
    var tmpa = [];
    for (const key in this.wh.npclist) {
      if (Object.prototype.hasOwnProperty.call(this.wh.npclist, key) && this.wh.npclist[key] != undefined) {
        const mob = this.wh.npclist[key];
        tmpa.push([key, Phaser.Math.Distance.BetweenPoints(this.sprite, mob)]);
      }
    }
    tmpa.sort(function (a, b) {
      return a[1] - b[1];
    });
    if (tmpa.length == 0) return undefined;
    if (tmpa[0].length == 0) return undefined;
    var target = tmpa[0];
    //this.plog.log("findclosestnpcs res", target, tmpa);
    return target;
  }
  findclosestenemy() {

    var tmpa = [];
    for (const key in this.wh.mobs) {
      if (Object.prototype.hasOwnProperty.call(this.wh.mobs, key) && this.wh.mobs[key] != undefined) {
        const mob = this.wh.mobs[key];
        if (mob.sprite == undefined) continue;
        if (mob.petname != undefined) continue;
        //this.plog.log("findclosestenemy mob", mob);
        var tmpd = Phaser.Math.Distance.BetweenPoints(this.sprite, mob.sprite);
        if (tmpd == 0) continue;
        tmpa.push([key, tmpd]);
      }
    }
    tmpa.sort(function (a, b) {
      return a[1] - b[1];
    });
    if (tmpa.length == 0) return undefined;
    if (tmpa[0].length == 0) return undefined;
    var target = tmpa[0];
    //this.plog.log("findclosestenemy res", target, tmpa);
    return target;
  }
}