var CONST = {
  // new line mode
  NO_NEWLINE: 0,
  RAW_NEWLINE: 1,
  WRAPPED_NEWLINE: 2,
  // wrap mode
  NO_WRAP: 0,
  WORD_WRAP: 1,
  CHAR_WRAP: 2,
  MIX_WRAP: 3,
  // split lines
  SPLITREGEXP: /(?:\r\n|\r|\n)/
};
export default CONST;