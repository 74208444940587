import { Injectable } from '@angular/core';
import { RCVarsService } from './rcvars-service.service';
import { RCOSDService } from './rcosd.service';
import { RCHTTP } from './rchttp.service';
import { plog } from './plog.service';

@Injectable({
  providedIn: 'root',
})
export class MsgsService {
  title;
  shopdragbtn;
  skicon = [];
  skob = [];
  sktt = [];
  skbg = [];
  skdescr = [];
  plog;
  collectallbtn;
  empty;
  resptotalpage;
  respperpage = 6;
  currentresppage = 1;

  shopbackbtn;
  shopfwdbtn;

  dwidescr_weekly;
  wwdata;
  dwichest_weekly;
  wwtitle = [];
  wwdescr = [];
  wwicon = [];
  wwbtn = [];

  lastdat;
  ddtitle = [];
  dddescr = [];
  ddicon = [];
  ddbtn = [];
  dwidescr_daily;
  dwichest_daily;
  countpets;
  msgscontainer_char;
  //maskg;
  //maskm;
  selectcircle;
  scrollareabox;
  shopclosebtn;
  itemdescr;
  eqitems = [];
  eqitemsp = [];
  itemwh = 54;
  shopguibg;
  wh;
  menubaritem = [];
  btnstory;
  btndaily;
  btnweekly;
  countpet;
  takebtn = [];
  takebtnamnt = [];
  loading;
  constructor(
    public rcvarsservice: RCVarsService,
    public rcosd: RCOSDService,
    public rchttp: RCHTTP
  ) { 
    this.plog=new plog();
  }

  firsttimerun = true;
  firsttimerunfunc() {
    if (this.firsttimerun == true) {
      this.wh.registry.list.gameitf.guiwindowclick(this.wh.msgscontainer);
    }
    this.firsttimerun = false;
  }

  loadingshow() {
    this.wh.msgscontainer.bringToTop(this.loading);
    this.loading.visible = true;
  }
  loadinghide() {
    this.loading.visible = false;
  }
  async show(wh, forcefocus = false) {
    if (forcefocus == true) this.firsttimerun = true;
    this.wh = wh;
    if (wh.msgscontainer != undefined) {
      await this.show_close(this.wh);
    }
    var width = wh.game.canvas.width;
    var height = wh.game.canvas.height;
    this.plog.log('petgui show', wh, this);

    this.shopbackbtn = wh.add.sprite(0, 0, "thesb", "backsign");//wh.add.image(0, 0, 'listbackbtn');
    this.shopbackbtn.setOrigin(0.5, 0.5);
    this.shopbackbtn.setInteractive();
    this.shopbackbtn.removeAllListeners();
    this.shopfwdbtn = wh.add.sprite(0, 0, "thesb", "forwardsign");//wh.add.image(0, 0, 'listfwdbtn');
    this.shopfwdbtn.setOrigin(0.5, 0.5);
    this.shopfwdbtn.setInteractive();
    this.shopfwdbtn.removeAllListeners();
    this.collectallbtn = wh.add.sprite(0, 0, "thesb", "collectall");//wh.add.image(0, 0, 'collectallbtn');
    this.collectallbtn.setOrigin(0.5, 0.5);
    this.collectallbtn.setInteractive();
    this.collectallbtn.removeAllListeners();

    const shopguibg = wh.add.image(0, 0, 'blankrowguibg');//msgsguibg
    shopguibg.setOrigin(0.5, 0.5);
    shopguibg.setInteractive({ pixelPerfect: true });
    shopguibg.removeAllListeners();
    this.shopguibg = shopguibg;
    //this.plog.log("questgui var", this.rcvarsservice);

    this.shopclosebtn = wh.add.sprite(300, 300, "thesq", 'close');
    this.shopclosebtn.name='skipcntnrsizecal';
    this.shopclosebtn.setOrigin(0.5, 0.5);
    this.shopclosebtn.setPosition(155, -200);
    this.shopclosebtn.displayWidth = 40;
    this.shopclosebtn.displayHeight = 40;
    this.shopclosebtn.setInteractive();
    this.shopclosebtn.removeAllListeners();
    this.shopclosebtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        //this.scene.registry.list["thethis"].phm.floorclick(this);
        this.plog.log(
          'gc.npcbackbtn pointerup',
          pointer,
          localX,
          localY,
          event,
          this
        );
        this.show_close(wh);
      },
      this
    );

    wh.msgscontainer = wh.add.container(300, 300);
    wh.msgscontainer._refsrv = this;
    wh.msgscontainer.visible = false;
    wh.msgscontainer.name = "msgscontainer";
    wh.msgscontainer.add(this.shopclosebtn);
    wh.msgscontainer.add(shopguibg);
    wh.msgscontainer.bringToTop(this.shopclosebtn);
    var itemdescr = wh.add.text(0, 0, '', {
      fontFamily: 'ffff',
      fontSize: '12px',
      fill: '#000',
      boundsAlignH: 'center',
      boundsAlignV: 'middle',
    });
    wh.msgscontainer.add(itemdescr);
    itemdescr.setOrigin(0, 0);
    itemdescr.setPosition(-140, 179);
    this.itemdescr = itemdescr;

    this.shopbackbtn.displayWidth = 100;
    this.shopfwdbtn.displayWidth = 100;
    this.collectallbtn.displayWidth = 100;

    this.shopbackbtn.displayHeight = 40;
    this.shopfwdbtn.displayHeight = 40;
    this.collectallbtn.displayHeight = 40;

    this.shopbackbtn.setPosition(-110, 220);
    this.shopfwdbtn.setPosition(110, 220);
    this.collectallbtn.setPosition(0, 220);
    wh.msgscontainer.add(this.shopfwdbtn);
    wh.msgscontainer.add(this.shopbackbtn);
    wh.msgscontainer.add(this.collectallbtn);
    wh.msgscontainer.bringToTop(this.shopfwdbtn);
    wh.msgscontainer.bringToTop(this.shopbackbtn);
    wh.msgscontainer.bringToTop(this.collectallbtn);

    this.shopbackbtn.removeAllListeners();
    this.shopfwdbtn.removeAllListeners();
    this.collectallbtn.removeAllListeners();
    this.shopbackbtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        this.plog.log('shop shopbackbtn', this);
        this.showinventory_showpage(wh, 'back');
      },
      this
    );

    this.shopfwdbtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        this.plog.log('shop shopfwdbtn', this);
        this.showinventory_showpage(wh, 'next');
      },
      this
    );
    this.collectallbtn.on(
      'pointerup',
      async function (pointer, localX, localY, event) {
        this.wh.plog.log('shop collectallbtn', this);
        this.loadingshow();
        var rew = await this.wh.registry.list.rchttp.getcommon2(
          'msgs_collectall',
          {}
        );
        await this.rchttp.updatecharinfo();
        if (rew.length > 0) this.wh.registry.list.showreward.show(wh, rew);
        /*var dat = await this.rchttp.getcommon2('msgs', {});
        this.lastdat = dat;
        this.show(wh);*/
        this.loadinghide();
        this.show_close(wh);
      },
      this
    );

    this.empty = wh.add.image(300, 300, 'empty');
    this.empty.setOrigin(0.5, 0.5);
    this.empty.setPosition(0, 0);
    this.empty.displayWidth = this.empty.displayHeight = 300;
    wh.msgscontainer.add(this.empty);
    wh.msgscontainer.bringToTop(this.empty);
    this.empty.visible = false;

    this.loading = wh.add.sprite(300, 300, 'pixelloading').play('pixelloading_b');
    this.loading.setOrigin(0.5, 0.5);
    this.loading.setPosition(0, 0);
    this.loading.displayWidth = this.loading.displayHeight = 60;
    wh.msgscontainer.add(this.loading);
    wh.msgscontainer.bringToTop(this.loading);

    //drag
    this.shopdragbtn = wh.add.image(300, 300, 'spacer');
     this.shopdragbtn.setOrigin(0.5, 0.5);
    this.shopdragbtn.setPosition(0, -200);
    this.shopdragbtn.displayWidth = 270;
    this.shopdragbtn.displayHeight = 60;
    this.shopdragbtn.setInteractive();
    this.shopdragbtn.removeAllListeners();
    this.shopdragbtn.on(
      'pointerout',
      function (pointer, localX, localY, event) {
        this.rcvarsservice.gameobj.scene.scenes[0].msgscontainer.dragactive =
          false;
      },
      this
    );
    this.shopdragbtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        this.rcvarsservice.gameobj.scene.scenes[0].msgscontainer.dragactive =
          false;
      },
      this
    );
    this.shopdragbtn.on(
      'pointerdown',
      function (pointer, localX, localY, event) {
        this.rcvarsservice.gameobj.registry.list.gameitf.guiwindowclick(
          this.rcvarsservice.gameobj.scene.scenes[0].msgscontainer
        );

        this.rcvarsservice.gameobj.scene.scenes[0].msgscontainer.dragactive =
          true;
        this.rcvarsservice.gameobj.scene.scenes[0].msgscontainer.dragactivex =
          this.rcvarsservice.gameobj.scene.scenes[0].msgscontainer.x;
        this.rcvarsservice.gameobj.scene.scenes[0].msgscontainer.dragactivey =
          this.rcvarsservice.gameobj.scene.scenes[0].msgscontainer.y;
        this.rcvarsservice.gameobj.scene.scenes[0].msgscontainer.dragactivepx =
          pointer.x;
        this.rcvarsservice.gameobj.scene.scenes[0].msgscontainer.dragactivepy =
          pointer.y;
      },
      this
    );
    this.rcvarsservice.gameobj.scene.scenes[0].msgscontainer.dragactive = false;
    this.rcvarsservice.gameobj.scene.scenes[0].msgscontainer.dragactivex = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].msgscontainer.dragactivey = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].msgscontainer.dragactivepx = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].msgscontainer.dragactivepy = 0;
    this.shopdragbtn.on(
      'pointermove',
      function (pointer, localX, localY, event) {
        //this.scene.registry.list["thethis"].phm.floorclick(this);
        //this.plog.log('shop pointermove', pointer, localX, localY, event, this);
        //this.showinventory_close(wh);
        if (
          this.rcvarsservice.gameobj.scene.scenes[0].msgscontainer.dragactive ==
          true
        ) {
          var tmpx =
            this.rcvarsservice.gameobj.scene.scenes[0].msgscontainer
              .dragactivex;
          var tmpy =
            this.rcvarsservice.gameobj.scene.scenes[0].msgscontainer
              .dragactivey;
          var tmppx =
            this.rcvarsservice.gameobj.scene.scenes[0].msgscontainer
              .dragactivepx;
          var tmppy =
            this.rcvarsservice.gameobj.scene.scenes[0].msgscontainer
              .dragactivepy;
          var movedx = tmppx - pointer.x;
          var movedy = tmppy - pointer.y;
          //this.plog.log('drag', tmppx, pointer.x, movedx);

          this.rcvarsservice.gameobj.scene.scenes[0].msgscontainer.x =
            tmpx - movedx;
          this.rcvarsservice.gameobj.scene.scenes[0].msgscontainer.y =
            tmpy - movedy;
          //this.maskg.setPosition(this.rcvarsservice.gameobj.scene.scenes[0].msgscontainer.x,this.rcvarsservice.gameobj.scene.scenes[0].msgscontainer.y)
          //this.rcvarsservice.gameobj.scene.scenes[0].msgscontainer.y = pointer.y;
          //this.setmaskposition();
        }
      },
      this
    );
    wh.msgscontainer.add(this.shopdragbtn);
    wh.msgscontainer.bringToTop(this.shopdragbtn);
    this.menubaritem.push(this.shopdragbtn);
    //skilltype bar
    //var equipment = this.rcvarsservice.activechar["equip"];
    //this.plog.log("equipment eq", equipment);
    //this.plog.log("msgscontainer", wh.msgscontainer, width, height);
    var svversionurlstring = this.wh.registry.list.rcvarpass.svversionstring;
    if (svversionurlstring != '') {
      svversionurlstring = '?' + svversionurlstring;
    }
    wh.msgscontainer.setPosition(width / 2, height / 2);
    let loader = new Phaser.Loader.LoaderPlugin(wh);
    var loadlist = ['stackitem_tp', 'stackitem_tpg', 'item_tp', 'item_tpg'];
    for (var k in loadlist) {
      var key = loadlist[k];
      loader.atlas(
        key + '',
        this.wh.registry.list.rcvarpass.rc_baseurl +
        'sprite/' +
        key +
        '.png' +
        svversionurlstring,
        this.wh.registry.list.rcvarpass.rc_baseurl +
        'sprite/' +
        key +
        '.json' +
        svversionurlstring
      );
    }
    //move
    wh.msgscontainer.x = width - this.shopguibg.width / 2 + 20;

    this.title = wh.add.text(0, 0, 'INBOX', wh.fs["winguititle42"]);
    wh.msgscontainer.add(this.title);
    wh.msgscontainer.bringToTop(this.title);
    this.title.setPosition(0 - this.title.displayWidth / 2, -226);
    /*
    var graphics = wh.make.graphics();
    graphics.fillStyle(0xff0000);
    graphics.fillRect(-200, 0, 300, 300);
    //wh.msgscontainer.add(graphics);
    //graphics.setOrigin(0,0);
    graphics.setPosition(0, 0);
    graphics.setDepth(10000000);

    //graphics.displayHeight = graphics.displayWidth = 400;
    //this.maskg = graphics;
    this.plog.log("xxxgraphics", graphics);

    //var mask = new Phaser.Display.Masks.GeometryMask(wh.msgscontainer_char, graphics);
    const mask = graphics.createGeometryMask();
    this.maskm = mask;
*/

    //wh.msgscontainer_char.on('pointermove', function (pointer) {
    //this.plog.log("xxxcontainer", this);
    //});
    //this.setmaskposition();
    loader.once(
      Phaser.Loader.Events.COMPLETE,
      () => {
        //this.plog.log('Phaser.Loader.Events.COMPLETE');
       // this.plog.log(this);
        //this.show_loaded();
        //this.setmaskposition();

        this.buildpetctnr();
        this.rcvarsservice.gameobj.registry.list.gameitf.guiwindowclick(
          this.rcvarsservice.gameobj.scene.scenes[0].msgscontainer
        );
      },
      this
    );
    loader.start();
  }
  /*
  setmaskposition() {
    this.maskm.geometryMask.setPosition( 
      this.rcvarsservice.gameobj.scene.scenes[0].msgscontainer.x + 50,
      this.rcvarsservice.gameobj.scene.scenes[0].msgscontainer.y - 130
    );
    //this.maskm.geometryMask.displayWidth=500;
  }*/
  async buildpetctnr() {
    this.plog.log('buildpetctnr');
    this.loadingshow();

    this.firsttimerunfunc();
    var wh = this.wh;
    if (wh.msgscontainer_char != undefined) {
      wh.msgscontainer_char.destroy();
    }

    var width = wh.game.canvas.width;
    var height = wh.game.canvas.height;

    wh.msgscontainer_char = wh.add.container(0, 0);
    wh.msgscontainer.add(wh.msgscontainer_char);
    var dat;
    dat = await this.rchttp.getcommon2('msgs', {});
    this.plog.log('msgs dat', dat);

    this.lastdat = dat;
    this.countpet = 0;
    for (const k in dat) {
      var v = dat[k];
      this.countpet++;
    }
    if (this.countpet > 5) {
      //pages
      this.respperpage = 5;
      this.resptotalpage = Math.ceil(this.countpet / this.respperpage);
    }
    if (this.countpet == 0) {
      this.empty.visible = true;
    } else {
      this.empty.visible = false;
    }
    this.plog.log('xxxcountpet', this.countpet);
    this.msgscontainer_char = wh.msgscontainer_char;
    wh.msgscontainer_char.setPosition(-120, -320);
    var i = -5;
    var rowh = 58;
    var iconwh = 40;
    var rowi = 0;
    var ii = 0;
    var rowhadjust = -235;
    for (const k in dat) {
      var v = dat[k];
      this.plog.log('xxxskdat', ii, rowi, v);
      this.skicon[ii] = wh.add.image(0, 0, 'mailicondis');
      if (v['isnew'] == 1) this.skicon[ii].setTexture('mailicon');
      wh.msgscontainer_char.add(this.skicon[ii]);
      this.skicon[ii].displayHeight = this.skicon[ii].displayWidth = iconwh;
      this.skicon[ii].setPosition(
        iconwh / 2 - 20,
        rowi * rowh - 5 + rowhadjust
      );
      this.skbg[ii] = wh.add.image(0, 0, 'spacer');
      wh.msgscontainer_char.add(this.skbg[ii]);
      this.skbg[ii].setOrigin(0, 0);
      this.skbg[ii].setInteractive();
      this.skbg[ii].removeAllListeners();
      this.skbg[ii].name = ii; //v['id'];

      /*this.skbg[ii].on('pointermove', function (pointer) {
        //this.plog.log(this);
        //var pos = this.parentContainer.y + this.y;
        //this.plog.log("pos", pos);
        //if (pos > -150) {
        if (pointer.isDown) {
          this.scene.registry.list.petgui.msgscontainer_char.y += (pointer.velocity.y / 3);
          this.scene.registry.list.petgui.msgscontainer_char.y = Phaser.Math.Clamp(this.scene.registry.list.petgui.msgscontainer_char.y, 430 - (this.scene.registry.list.petgui.countpet * 50), 150);
          //this.scene.registry.list.petgui.movemenubartotop();
          //this.plog.log("xxxskill in container", 0-(this.countpet*50),this.msgscontainer_char.y);
        }
        //}
      });*/

      this.skbg[ii].on('pointerup', async function (pointer) {
        this.scene.plog.log('xxx,click,', this);
        //var tmpnamea = this.name.split("---");
        var ldata = this.scene.registry.list.msgs.lastdat[this.name];
        this.scene.plog.log('xxx,click,', ldata);
        var datadsp =
          ldata['title'] + '\n\n' + ldata['dtstr'] + '\n\n' + ldata['body'];
        if (ldata["itemtype"] == "stackitem") {
          datadsp += "\n\n۞═════════════۞\n" + this.scene.registry.list.rcvarpass.allstackitem[ldata["item"]]["name"] + " x" + ldata["amnt"];
        }
        if (ldata["itemtype"] == "item") {
          datadsp += "\n\n۞═════════════۞\n" + this.scene.registry.list.rcvarpass.allitem[ldata["item"]]["name"] + " +" + ldata["itemplus"];
        }

        await this.scene.registry.list.rctoast.spritepaperdialog(
          this.scene,
          datadsp
        );

        //this.scene.registry.list.rcosd.show(wh, "pet", data[this.name]);
      });

      this.skbg[ii].setPosition(-20, rowi * rowh - rowh / 2 + 5 + rowhadjust);
      this.skbg[ii].displayHeight = iconwh;
      this.skbg[ii].displayWidth = 300;
      wh.msgscontainer_char.sendToBack(this.skbg[ii]);

      var namedsp = v['title'] + '';
      namedsp = namedsp;
      this.sktt[ii] = wh.add.text(0, 0, namedsp, wh.fs["winguilist_title"]);
      wh.msgscontainer_char.add(this.sktt[ii]);
      wh.msgscontainer_char.bringToTop(this.sktt[ii]);
      this.sktt[ii].setPosition(30, rowi * rowh - 25 + rowhadjust);
      //tmpskname.setResolution(0.25);
      this.plog.log('zzzzzz', this.sktt[ii]);
      this.skdescr[ii] = wh.add.text(0, 0, ' ' + v['dtstr'], wh.fs["winguilist_descr"]);
      wh.msgscontainer_char.add(this.skdescr[ii]);
      wh.msgscontainer_char.bringToTop(this.skdescr[ii]);
      this.skdescr[ii].setPosition(30, rowi * rowh - 5 + rowhadjust);
      /*    if (mode == "char" && this.rcvarsservice.activechar["cbt"]["skill"].includes(v["code"])) {
            this.plog.log("skillobtained", v);
            var tmpskobtained = wh.add.text(0, 0, "Obtained", { fontFamily: 'ffff', fontSize: '12px', fill: '#090', boundsAlignH: "center", boundsAlignV: "middle" });
            wh.msgscontainer_char.add(tmpskobtained);
            wh.msgscontainer_char.bringToTop(tmpskobtained);
            tmpskobtained.setPosition(210, (i * rowh) - 25);
          }
      */
      this.takebtnamnt[ii] = wh.add.text(0, 0, ' ', {
        fontFamily: 'ffff',
        fontSize: '13px',
        fill: '#000',
        boundsAlignH: 'center',
        boundsAlignV: 'middle',
        stroke: '#fff',
        strokeThickness: 3,
      });
      this.skdescr[ii].setPosition(30, rowi * rowh - 5 + rowhadjust);
      this.takebtnamnt[ii].setPosition(210, rowi * rowh + 1 + rowhadjust);

      this.takebtn[ii] = wh.add.image(0, 0, 'spacer');
      if (v['itemtype'] == 'item') {
        if (v['isnew'] == 1) {
          this.takebtn[ii].setTexture('item_tp', v['item']);
        } else {
          this.takebtn[ii].setTexture('item_tpg', v['item']);
        }
        this.takebtnamnt[ii].setText('+' + v['itemplus']);
      }
      if (v['itemtype'] == 'stackitem') {
        if (v['isnew'] == 1) {
          this.takebtn[ii].setTexture('stackitem_tp', v['item']);
        } else {
          this.takebtn[ii].setTexture('stackitem_tpg', v['item']);
        }
        this.takebtnamnt[ii].setText('x' + v['amnt']);
      }
      wh.msgscontainer_char.add(this.takebtn[ii]);
      wh.msgscontainer_char.bringToTop(this.takebtn[ii]);

      wh.msgscontainer_char.add(this.takebtnamnt[ii]);
      wh.msgscontainer_char.bringToTop(this.takebtnamnt[ii]);

      this.takebtn[ii].displayWidth = 50;
      this.takebtn[ii].scaleY = this.takebtn[ii].scaleX;
      this.takebtn[ii].setPosition(240, rowi * rowh - 5 + rowhadjust);
      this.takebtn[ii].name = k + '-' + ii;
      /*this.takebtn[ii].setInteractive();
      this.takebtn[ii].removeAllListeners();
      this.takebtn[ii].on('pointerup', async function () {
        var tmpnamea = this.name.split('-');
        this.plog.log('tmptakbtn', this.name, this);

        var res = await this.scene.registry.list.rchttp.getcommon2(
          'pettoggletakehome',
          { petid: tmpnamea[0], set: 'leave' }
        );
        this.plog.log(res);
        if (res == 'ok') {
          //this.scene.registry.list.petgui.takebtn[this.name].visible = false;
          //this.scene.registry.list.petgui.homebtn[this.name].visible = true;
          this.scene.registry.list.petgui.takebtn[tmpnamea[1]].visible = false;
          this.scene.registry.list.petgui.homebtn[tmpnamea[1]].visible = true;
          this.scene.registry.list.petgui.lastdat[tmpnamea[0]]['take'] = 'NO';
        }
      });*/

      if (v['isnew'] == 1) {
        this.takebtn[ii].visible = true;
        this.takebtnamnt[ii].visible = true;
      } else {
        this.takebtn[ii].visible = true;//false;
        this.takebtnamnt[ii].visible = true;//false;
      }

      i++;
      ii++;
      rowi++;
      if (rowi == 5) rowi = 0;
    }
    this.msgscontainer_char.y = 150;
    //wh.msgscontainer_char.setMask(this.maskm);
    this.showinventory_showpage(wh, 'back');
    wh.msgscontainer.visible = true;
    this.wh.registry.list.resizeman.RCMapResizeManager_work(this.wh);

    //this.plog.log("xxxmask", this.maskm);
    this.wh.registry.list.tut.show(this.wh, "msgloaded");
    this.wh.registry.list.tut.istutrunning = true;
  }

  showinventory_showpage(gc, viewpage) {
    if (viewpage == 'back') {
      if (this.currentresppage > 1) this.currentresppage--;
    }
    if (viewpage == 'next') {
      if (this.currentresppage < this.resptotalpage) this.currentresppage++;
    }
    for (var i = 0; i <= 100; i++) {
      //this.plog.log(this.shopitemlist[i]);
      if (this.skicon[i] != undefined) {
        this.skicon[i].visible = false;
      }
      if (this.sktt[i] != undefined) {
        this.sktt[i].visible = false;
      }
      if (this.skob[i] != undefined) {
        this.skob[i].visible = false;
      }
      if (this.skbg[i] != undefined) {
        this.skbg[i].visible = false;
      }
      if (this.skdescr[i] != undefined) {
        this.skdescr[i].visible = false;
      }
      if (this.takebtn[i] != undefined) {
        this.takebtn[i].visible = false;
      }
      if (this.takebtnamnt[i] != undefined) {
        this.takebtnamnt[i].visible = false;
      }
    }
    var numfrom = this.respperpage * (this.currentresppage - 1);
    var numto = this.respperpage * this.currentresppage;
    this.plog.log(
      'showinventory_showpage',
      viewpage,
      this.currentresppage,
      'fromto',
      numfrom,
      numto,
      'perpage',
      this.respperpage
    );

    
    var griddat = [];
    var griddatf = [];

    griddat.push(this.shopclosebtn);

    griddat.push(this.shopfwdbtn);
    griddat.push(this.shopbackbtn);
    griddat.push(this.collectallbtn);
    griddatf.push(this.collectallbtn);
    
    for (var i = numfrom; i < numto; i++) {
      if (this.skicon[i] != undefined) {
        this.skicon[i].visible = true;
        this.skbg[i].visible = true;
        if (this.skob[i] != undefined) this.skob[i].visible = true;
        this.sktt[i].visible = true;
        this.skdescr[i].visible = true;
        this.takebtn[i].visible = true;
        if (this.takebtn[i] != undefined && this.takebtn[i].name != undefined) {
          var namea = this.takebtn[i].name.split('-');
          //this.plog.log("decisbv",i,namea,this.lastdat);
          if (this.lastdat[namea[0]] != undefined && this.lastdat[namea[0]]['isnew'] == 1) {
            this.takebtn[i].visible = true;
            this.takebtnamnt[i].visible = true;
          } else {
            this.takebtn[i].visible = true;
            this.takebtnamnt[i].visible = true;
          }
        }
      }
    }
    this.loadinghide();
    this.wh.registry.list.gp.guigrid(griddat, griddatf);

  }

  async show_close(wh) {
    wh.msgscontainer.visible = false;
    this.wh.registry.list.gameitf.closegui(wh, wh.msgscontainer);

    for (const k in this.wh.msgscontainer.list) {
      try {
        await this.wh.msgscontainer.remove(this.wh.msgscontainer.list[k], true);
        //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list[k].destroy();
      } catch (e) {
        this.plog.log("ERROR! msgscontainer.destroy() ", e);
      }
    }

    for (const k in this.wh.msgscontainer.list) {
      try {
        await this.wh.msgscontainer.remove(this.wh.msgscontainer.list[k], true);
        //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list[k].destroy();
      } catch (e) {
        this.plog.log("ERROR! msgscontainer.destroy() ", e);
      }
    }
    wh.msgscontainer = undefined;
  }


  rebuildguigrid() {
    this.plog.log("rebuildguigrid();b", this.wh.kbfocus);
    this.showinventory_showpage(null, "");
    this.plog.log("rebuildguigrid();a", this.wh.kbfocus);
  }
}
