import { AStarFinder } from "astar-typescript";
import { plog } from "./plog.service";

export class MapMobX {
    o;
    wh;
    currentx;
    currenty;
    sprite;
    doing = "";
    behavetimer;
    doinginprocess = false;
    astar;
    walkarray = [];
    lastspriteface;
    idlecountdown = 0;
    actionspeed;
    hidecountdown = 0;
    mobdata;
    behavetimeout = 0;
    walktarget = "";
    petname;
    actiontodo = "";
    plog;
    currentactionnumber = 0;

    constructor(wh, o) {
        this.wh = wh;
        this.o = o;
        this.plog = new plog();
        this.plog.setLevel(2);
        if (this.o["mobcode"] == undefined || this.o["mobcode"] == "") return;
        if (this.wh.registry.list.rcvarpass.activemapmetrixmobx == undefined) return;
        if (this.wh.registry.list.rcvarpass.activemapmetrixmobx.length == 0) return;
        this.plog.log("MapMobX constructor", wh, o);
        //this.plog.log("MapMob constructorthis.wh.registry.list.rcvarpass.activemapmetrixmobx", this.wh.registry.list.rcvarpass.activemapmetrixmobx);
        this.petname = this.o["petname"];
        var mobchkmax = 10;
        var mobchkmin = 5;
        var monnum = Math.floor(Math.random() * (mobchkmax - mobchkmin) + mobchkmin);
        this.actionspeed = 10;
        this.idlecountdown = monnum;
        /*
        if (this.o.nature == "rapid") {
            this.actionspeed = this.actionspeed / 4;
        }
        if (this.o.nature == "teleport") {
            this.actionspeed = 100;
        }*/
        this.mobdata = this.wh.registry.list.rcvarpass.allmob[this.o.mobcode];
        if (this.wh.registry.list.rcvarpass.activemapmetrixmobx == undefined) {
            //this.wh.registry.list.rcvarpass.activemapmetrixmobx=[[]];
        }
        //this.plog.log("mapmob astar upon construct", this.wh.registry.list.rcvarpass.activemapmetrixmobx);
        if (this.wh.registry.list.rcvarpass.activemapmetrixmobx == undefined) {
            this.plog.log("this.wh.registry.list.rcvarpass.activemapmetrixmobx not defind");
            return;
        }
        this.plog.log("mobx activemapmetrixmobx", this.wh.registry.list.rcvarpass.activemapmetrixmobx)
        this.astar = new AStarFinder({
            grid: {
                matrix: this.wh.registry.list.rcvarpass.activemapmetrixmobx
            },
            includeStartNode: false,
            includeEndNode: true
        });
        //this.plog.log("mob astar", this.astar, this);
        this.behavetimer = this.wh.time.addEvent({ delay: this.actionspeed, callback: this.behave, callbackScope: this, loop: true });
        this.wh.mobs.push(this);
    }
    getobj() {
        return this.o;
    }
    spawn() {
        if (this.o["mobcode"] == undefined || this.o["mobcode"] == "") return;
        if (this.sprite != undefined && this.o["mobcode"] != "") return;
        if (this.wh.registry.list.rcvarpass.activemapmetrixmobx.length == 0) return;

        //this.plog.log("mapmob spawn", this, this.wh);
        if (this.wh.textures.list[this.o.sprite] == undefined) {
            this.plog.log("mapmob.spawn, can't find sprite", this.o);
            return;
        }
        this.sprite = this.wh.physics.add.sprite(100, 100, this.o.sprite);
        this.sprite.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);
        this.sprite.cbt = this.o.data.cbt; //mobx calculate / contact char etc

        try {
            this.sprite.play("mob_" + this.o["sprite"] + "_idle");//.setDisplayOrigin(0.5, 0.8);
        } catch (err) {
            this.plog.log("mapmob.spawn() error while trying to play idle ", err);
            return;
        }
        this.wh.zsortlist.push(this.sprite);
        this.wh.extradestroylist.push(this.sprite);
        this.sprite.mapmonobj = this;
        //tmp.sprite.setScale(this.o["mapsize"]);
        this.sprite.displayWidth = this.o["mapsize"] * 32;
        this.sprite.setBodySize(28, 28);
        this.sprite.scaleY = this.sprite.scaleX;
        this.plog.log("mapmopx spawn sprite", this.sprite);
        this.plog.log("mapmopx spawn this", this);
        //tmp.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);

        this.sprite.setInteractive();
        //this.wh.physics.add.existing(this.sprite);
        //this.sprite.setBodySize(32,32);
        this.sprite.atkpow = Number(this.o.data.cbt.patk) + 1;//xxx
        //this.plog.log("atkpowchk", this.o.data,this.sprite);
        this.wh.physics.add.collider(this.sprite, this.wh.char_main, function (a1, a2) {
            a1.scene.charhitbullet(a1.scene, a1);
        });
        /*this.sprite.on("pointerdown", function () {
            //this.plog.log("mobclick", this);
            if (this.wh.registry.list.rcvarpass.activemap["map"]["code"] != "backyard") {
                if (this.wh.registry.list.showreward.isclosed()) {
                    this.wh.registry.list.combat.start(this.wh);
                    this.hide(4);
                } else {
                    this.hide(1);
                }
            } else {
                //click mob on backyard
                this.plog.log("mobpetname", this);
                if (this.petname != undefined && this.petname != "") {
                    //this.wh.registry.list.phm.charsaybubble(this.sprite, this.petname);
                    this.wh.registry.list.phm.charsaybubble(this.wh.char_main, "That's " + this.petname);
                    this.cheers();
                }
            }
        }, this);*/
        //var tmp = this.rcvarsservice.gameobj.scene.scenes[0].add.image((spawnposa[1] * 32), (spawnposa[0] * 32), this.rcvarsservice.activechar["sprite"], "walk-d-1").setDepth(999);
        this.wh.mapcontainer.add(this.sprite);
        //this.movetorandomarea();

        var moveto = this.o.spawnpos;//this.getrandwalkpos() + "";
        var movetoa = moveto.split("-");
        this.currentx = Math.floor(movetoa[0])
        this.currenty = Math.floor(movetoa[1])
        var startPos = { x: this.currentx, y: this.currenty }
        var goalPos = { x: Math.floor(movetoa[0]), y: Math.floor(movetoa[1]) }
        this.plog.log("mobx I'mma walk to ", startPos, goalPos);
        try {
            var myPathway = this.astar.findPath(startPos, goalPos);
        } catch (e) {
            this.plog.log("mobx getwalkpath error in spawn()");
        }
        //this.plog.log(myPathway);
        if (myPathway == undefined) {
            this.walkarray = [];
        } else {
            this.walkarray = myPathway;
        }

        this.sprite.x = ((this.currentx - 1) * 32);//+width/2;
        this.sprite.y = ((this.currenty - 1) * 32);//+width/2;
        this.sprite.displayHeight = 64 * this.o["mapsize"];
        this.sprite.scaleX = this.sprite.scaleY;//displayWidth = 64 * this.o["mapsize"];
        this.sprite.setOrigin(0.5, 0.8);
        //this.plog.log("charic",charic);
        //this.plog.log("moveTo", this.charzindex, this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer);
        //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.moveTo(tmp, this.charzindex);
        //this.rcvarsservice.gameobj.scene.scenes[0].char_main = tmp;
        this.behave();
    }
    movetorandomarea() {
        var moveto = this.getrandwalkpos() + "";
        var movetoa = moveto.split("-");
        this.currentx = Number(movetoa[0]);
        this.currenty = Number(movetoa[1]);
        this.sprite.x = ((this.currentx - 1) * 32);//+width/2;
        this.sprite.y = ((this.currenty - 1) * 32);//+width/2;
        this.sprite.displayHeight = 64 * this.o["mapsize"];
        this.sprite.scaleX = this.sprite.scaleY;//displayWidth = 64 * this.o["mapsize"];
        this.sprite.setOrigin(0.5, 0.8);
        //this.plog.log(random, months[random]);
    }
    getrandwalkpos() {
        var mobwalkarr = this.wh.registry.list.rcvarpass.activemap["mobxwalk"];
        if (mobwalkarr == undefined) {
            //this.plog.log("mapmobx movetorandomarea: mobwalk not found");
            var mobwalkarr = this.wh.registry.list.rcvarpass.activemap["walkable"];

        }
        var usablemobwalk = [];
        for (var key in mobwalkarr) {
            var val = mobwalkarr[key];
            if (val == "yn_y") {
                usablemobwalk.push(key);
            }
        }
        var randompos = Math.floor(Math.random() * usablemobwalk.length);
        var moveto = usablemobwalk[randompos];
        //this.plog.log("mapmon movetorandomarea: usablemobwalk", usablemobwalk);
        //this.plog.log("mapmon movetorandomarea: randompos", moveto);
        return moveto;
    }
    hide(countdown) {
        this.sprite.visible = false;
        this.hidecountdown = (countdown * 1000) / this.actionspeed;
    }
    behave() {


        //this.plog.log("mapmob.behave",this.wh.combatcontainer);
        if (this.wh.pauseload == true) return;
        if (this.sprite == undefined) {
            this.plog.log("error, mapmob.behave(), this.sprite=undefined");
            return;
        }
        if (this.wh.combatcontainer != undefined && this.wh.combatcontainer.list.length > 0) {
            return;
        }
        if (this.hidecountdown > 0) {
            //this.plog.log("mapmob hidecountdown ", this.hidecountdown);
            this.hidecountdown = this.hidecountdown - 1;
            return;
        }
        if (this.behavetimeout > 0) {
            //this.plog.log("mapmob behavetimeout ", this.behavetimeout);
            this.behavetimeout = this.behavetimeout - 1;
            return;
        }
        if (this.sprite.visible == false) {
            this.wh.registry.list.phm.mapitemeffect(this.sprite, "warpgate_b");
            this.sprite.visible = true;
        }
        //this.plog.log("mapmob.behave",this.wh.combatcontainer);
        if (this.astar == undefined) {

        }
        //this.plog.log("behaving", this);
        //var action = ["walk", "idle"];
        //this.plog.log("mobxactionSTART=",this.currentactionnumber,this.actiontodo);
        this.currentactionnumber++;
        if (this.currentactionnumber > 10) this.currentactionnumber = 1;

        for (var i = this.currentactionnumber; i <= 10; i++) {
            if (this.o.data.mobx["action" + i] != undefined && this.o.data.mobx["action" + i] != "") {
                this.actiontodo = this.o.data.mobx["action" + i];
                this.currentactionnumber = i;
                //this.plog.log("mobxaction=",this.currentactionnumber,this.actiontodo);
                break;
            }
        }
        if (this.actiontodo == "") {
            this.currentactionnumber = 0;
            for (var i = this.currentactionnumber; i <= 10; i++) {
                if (this.o.data.mobx["action" + i] != undefined && this.o.data.mobx["action" + i] != "") {
                    this.actiontodo = this.o.data.mobx["action" + i];
                    this.currentactionnumber = i;
                    //this.plog.log("mobxaction2=",this.currentactionnumber,this.actiontodo);

                    break;
                }
            }
        }
        //this.plog.log("mobx behave actiontodo=" + this.currentactionnumber, this.actiontodo);//, this.o.data.mobx);
        if (this.actiontodo == "") {
            return;
        }

        if (this.actiontodo == "walk") {
            this.actiontodo = "";
            this.behavetimeout = 100;
            //return;
            var mobwalkarr = this.wh.registry.list.rcvarpass.activemap["mobxwalk"];
            if (mobwalkarr == undefined) {
                this.plog.log("mapmobx movetorandomarea: mobwalk not found");
                //mobwalkarr = this.wh.registry.list.rcvarpass.activemap["walkable"];
                return; // not walking
            }
            var usablemobwalk = [];
            for (var key in mobwalkarr) {
                var val = mobwalkarr[key];
                if (val == "yn_y") {
                    var keya = key.split("-");
                    var chk1 = Number(keya[0]);
                    var chk2 = Number(keya[1]);
                    if (
                        (chk1 == this.currentx && chk2 == this.currenty - 1) ||
                        (chk1 == this.currentx && chk2 == this.currenty + 1) ||
                        (chk1 == this.currentx - 1 && chk2 == this.currenty) ||
                        (chk1 == this.currentx + 1 && chk2 == this.currenty)) {
                        //this.plog.log("mobxwalk",key,keya);
                        usablemobwalk.push(key);
                    }
                }
            }
            //this.plog.log("mobxwalkxxx ",this.currenty,this.currentx,usablemobwalk);
            if (usablemobwalk.length > 0) {
                var walkto = usablemobwalk[Math.floor(Math.random() * usablemobwalk.length)];
                //this.plog.log("mobxwalk, im at",this.currentx,this.currenty," moving to ",walkto);
                if (walkto == undefined || walkto == null) return;
                var walktoa = walkto.split("-");
                var chk1 = Number(walktoa[0]);
                var chk2 = Number(walktoa[1]);
                if (chk1 > this.currentx) this.showspritewalk("right");
                if (chk1 < this.currentx) this.showspritewalk("left");
                this.currentx = chk1;
                this.currenty = chk2;
                var tweenchar = this.wh.tweens.add({
                    targets: this.sprite,
                    x: (chk1 * 32),
                    y: (chk2 * 32),
                    ease: 'Linear',
                    duration: 200,
                    repeat: 0
                });
            }
        }
        if (this.actiontodo == "shoot") {
            this.actiontodo = "";
            var shootdir = this.o.data.mobx["action" + this.currentactionnumber + "-shoot-direction"];
            //this.plog.log("mobx behave shoot dir=", shootdir);

            this.behavetimeout = Math.floor(this.o.data.mobx["action" + this.currentactionnumber + "-delay"] / this.actionspeed);
            //this.plog.log("mobx behave shoot dir=", shootdir, this.behavetimeout);
            this.genbullet(
                this.o.data.mobx["action" + this.currentactionnumber + "-shoot-direction"],
                this.o.data.mobx["action" + this.currentactionnumber + "-shoot-ani"],
                this.o.data.mobx["action" + this.currentactionnumber + "-shoot-range"],
                this.o.data.mobx["action" + this.currentactionnumber + "-shoot-speed"],
                this.o.data.mobx["action" + this.currentactionnumber + "-shoot-snd"],
                this.o.data.mobx["action" + this.currentactionnumber + "-shoot-size"],
                "no"
            );

            //try {
            //this.sprite.play("mob_" + this.o["sprite"] + "_idle");
        }
        if (this.actiontodo == "shootbomb") {
            this.actiontodo = "";
            var shootdir = this.o.data.mobx["action" + this.currentactionnumber + "-shoot-direction"];
            //this.plog.log("mobx behave shoot dir=", shootdir);

            this.behavetimeout = Math.floor(this.o.data.mobx["action" + this.currentactionnumber + "-delay"] / this.actionspeed);
            //this.plog.log("mobx behave shoot dir=", shootdir, this.behavetimeout);
            this.genbullet(
                this.o.data.mobx["action" + this.currentactionnumber + "-shoot-direction"],
                this.o.data.mobx["action" + this.currentactionnumber + "-shoot-ani"],
                this.o.data.mobx["action" + this.currentactionnumber + "-shoot-range"],
                this.o.data.mobx["action" + this.currentactionnumber + "-shoot-speed"],
                this.o.data.mobx["action" + this.currentactionnumber + "-shoot-snd"],
                this.o.data.mobx["action" + this.currentactionnumber + "-shoot-size"],
                {
                    ani: this.o.data.mobx["action" + this.currentactionnumber + "-bomb-ani"],
                    range: this.o.data.mobx["action" + this.currentactionnumber + "-bomb-range"],
                    atkpow: Number(this.o.data.cbt.patk) + 1
                }
            );

            //try {
            //this.sprite.play("mob_" + this.o["sprite"] + "_idle");
        }

        if (this.actiontodo == "bomb") {
            this.actiontodo = "";
            var shootdir = this.o.data.mobx["action" + this.currentactionnumber + "-shoot-direction"];
            //this.plog.log("mobx behave shoot dir=", shootdir);

            this.behavetimeout = Math.floor(this.o.data.mobx["action" + this.currentactionnumber + "-delay"] / this.actionspeed);
            //this.plog.log("mobx behave shoot dir=", shootdir, this.behavetimeout);
            var ani = this.o.data.mobx["action" + this.currentactionnumber + "-bomb-ani"]
            var size = this.o.data.mobx["action" + this.currentactionnumber + "-bomb-range"]
            var bullanim = this.wh.registry.list.rcvarpass.allanims[ani];

            var tmpbullet = this.genbullet_sprite(bullanim, size);
            var bulltgx = tmpbullet.x;
            var bulltgy = tmpbullet.y - 10;
            var tw = this.wh.tweens.add({
                targets: tmpbullet,
                x: bulltgx, y: bulltgy,
                ease: 'Linear', duration: 80,
                onComplete: function () {
                    try {
                        if (this.targets[0] != undefined)
                            this.targets[0].destroy();
                    } catch (e) {
                        console.log(e);
                    }
                }
            }, this);
            this.wh.physics.add.collider(tmpbullet, this.wh.char_main, function (a1, a2) {
                a1.scene.charhitbullet(a1.scene, a1);
            });
        }
    }
    genbullet_sprite(bullanim, size) {
        //this.plog.log("genbullet_sprite", this.o.data.cbt);
        //var tmpbullet = this.wh.add.sprite(100, 100, bullanim["sprite"]);
        var tmpbullet = this.wh.physics.add.sprite(100, 100, bullanim["sprite"]);
        tmpbullet.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);
        tmpbullet.play(bullanim["code"]);
        tmpbullet.setOrigin(0.5, 0.5);
        this.wh.mapcontainer.add(tmpbullet);
        this.wh.mapcontainer.bringToTop(tmpbullet);
        tmpbullet.x = this.sprite.x;//- (this.sprite.displayWidth / 2);
        tmpbullet.y = this.sprite.y;//- (this.sprite.displayHeight / 2);
        if (size == undefined) size = 1;
        tmpbullet.displayWidth = size * 32;
        tmpbullet.scaleY = tmpbullet.scaleX;
        tmpbullet.body.setSize(size * 32, size * 32);
        //tmpbullet.body.setOffset(16, 16);
        tmpbullet.body.scaleX = tmpbullet.scaleX
        tmpbullet.body.scaleY = tmpbullet.scaleY
        tmpbullet.atkpow = Number(this.o.data.cbt.patk) + 1;//
        tmpbullet.cbt = this.o.data.cbt
        //this.wh.mapcontainer.sendBelow
        this.wh.mapcontainer.moveBelow(tmpbullet, this.sprite);
        //this.plog.log("genbullet_sprite end", tmpbullet);
        return tmpbullet;
    }

    genbullet(dir, ani, range, spd, snd, size, bombobj) {

        var bullanim = this.wh.registry.list.rcvarpass.allanims[ani];
        //this.plog.log("mobx bullanim", bullanim);

        switch (dir) {
            case "l":
                var tmpbullet = this.genbullet_sprite(bullanim, size);
                tmpbullet.spawnbomb = bombobj;
                tmpbullet.bulletdirection = "l";
                var bulltgx = tmpbullet.x; var bulltgy = tmpbullet.y;
                bulltgx = bulltgx - (32 * range);
                var tw = this.wh.tweens.add({
                    targets: tmpbullet,
                    x: bulltgx, y: bulltgy,
                    ease: 'Linear', duration: spd,
                    onComplete: function () {
                        try {
                            if (this.targets[0] != undefined && this.targets[0].spawnbomb != "no")
                                this.targets[0].scene.spawnbomb(this.targets[0].spawnbomb, this.targets[0]);
                        } catch (e) {
                            console.log(e);
                        }
                        try {
                            if (this.targets[0] != undefined)
                                this.targets[0].destroy();
                        } catch (e) {
                            console.log(e);
                        }
                    }
                }, this);
                this.wh.physics.add.collider(tmpbullet, this.wh.char_main, function (a1, a2) {
                    a1.scene.charhitbullet(a1.scene, a1);
                });
                this.showspritewalk("left");
                break;
            case "r":
                var tmpbullet = this.genbullet_sprite(bullanim, size);
                tmpbullet.spawnbomb = bombobj;
                tmpbullet.bulletdirection = "r";
                var bulltgx = tmpbullet.x; var bulltgy = tmpbullet.y;
                bulltgx = bulltgx + (32 * range);
                var tw = this.wh.tweens.add({
                    targets: tmpbullet,
                    x: bulltgx, y: bulltgy,
                    ease: 'Linear', duration: spd,
                    onComplete: function () {
                        try {
                            if (this.targets[0] != undefined && this.targets[0].spawnbomb != "no")
                                this.targets[0].scene.spawnbomb(this.targets[0].spawnbomb, this.targets[0]);
                        } catch (e) {
                            console.log(e);
                        }
                        try {
                            if (this.targets[0] != undefined)
                                this.targets[0].destroy();
                        } catch (e) {
                            console.log(e);
                        }
                    }
                }, this);
                this.wh.physics.add.collider(tmpbullet, this.wh.char_main, function (a1, a2) {
                    a1.scene.charhitbullet(a1.scene, a1);
                });
                this.showspritewalk("right");
                break;
            case "u":
                var tmpbullet = this.genbullet_sprite(bullanim, size);
                tmpbullet.spawnbomb = bombobj;
                tmpbullet.bulletdirection = "u";
                var bulltgx = tmpbullet.x; var bulltgy = tmpbullet.y;
                bulltgy = bulltgy - (32 * range);
                var tw = this.wh.tweens.add({
                    targets: tmpbullet,
                    x: bulltgx, y: bulltgy,
                    ease: 'Linear', duration: spd,
                    onComplete: function () {
                        try {
                            if (this.targets[0] != undefined && this.targets[0].spawnbomb != "no")
                                this.targets[0].scene.spawnbomb(this.targets[0].spawnbomb, this.targets[0]);
                        } catch (e) {
                            console.log(e);
                        }
                        try {
                            if (this.targets[0] != undefined)
                                this.targets[0].destroy();
                        } catch (e) {
                            console.log(e);
                        }
                    }
                }, this);
                this.wh.physics.add.collider(tmpbullet, this.wh.char_main, function (a1, a2) {
                    a1.scene.charhitbullet(a1.scene, a1);
                });
                break;
            case "d":
                var tmpbullet = this.genbullet_sprite(bullanim, size);
                tmpbullet.spawnbomb = bombobj;
                tmpbullet.bulletdirection = "d";
                var bulltgx = tmpbullet.x; var bulltgy = tmpbullet.y;
                bulltgy = bulltgy + (32 * range);
                var tw = this.wh.tweens.add({
                    targets: tmpbullet,
                    x: bulltgx, y: bulltgy,
                    ease: 'Linear', duration: spd,
                    onComplete: function () {
                        try {
                            if (this.targets[0] != undefined && this.targets[0].spawnbomb != "no")
                                this.targets[0].scene.spawnbomb(this.targets[0].spawnbomb, this.targets[0]);
                        } catch (e) {
                            console.log(e);
                        }
                        try {
                            if (this.targets[0] != undefined)
                                this.targets[0].destroy();
                        } catch (e) {
                            console.log(e);
                        }
                    }
                }, this);
                this.wh.physics.add.collider(tmpbullet, this.wh.char_main, function (a1, a2) {
                    a1.scene.charhitbullet(a1.scene, a1);
                });
                break;

            case "lr":
                var tmpbullet = this.genbullet_sprite(bullanim, size);
                tmpbullet.spawnbomb = bombobj;
                tmpbullet.bulletdirection = "r";
                var bulltgx = tmpbullet.x; var bulltgy = tmpbullet.y;
                bulltgx = bulltgx + (32 * range);
                var tw = this.wh.tweens.add({
                    targets: tmpbullet,
                    x: bulltgx, y: bulltgy,
                    ease: 'Linear', duration: spd,
                    onComplete: function () {
                        try {
                            if (this.targets[0] != undefined && this.targets[0].spawnbomb != "no")
                                this.targets[0].scene.spawnbomb(this.targets[0].spawnbomb, this.targets[0]);
                        } catch (e) {
                            console.log(e);
                        }
                        try {
                            if (this.targets[0] != undefined)
                                this.targets[0].destroy();
                        } catch (e) {
                            console.log(e);
                        }
                    }
                }, this);
                //this.plog.log("mobxcollision",tmpbullet)
                this.wh.physics.add.collider(tmpbullet, this.wh.char_main, function (a1, a2) {
                    //this.plog.error(arguments,"lr l");
                    a1.scene.charhitbullet(a1.scene, a1);
                });

                var tmpbullet2 = this.genbullet_sprite(bullanim, size);
                tmpbullet2.spawnbomb = bombobj;
                tmpbullet2.bulletdirection = "l";
                var bulltgx2 = tmpbullet2.x; var bulltgy2 = tmpbullet2.y;
                bulltgx2 = bulltgx2 - (32 * range);
                var tw = this.wh.tweens.add({
                    targets: tmpbullet2,
                    x: bulltgx2, y: bulltgy2,
                    ease: 'Linear', duration: spd,
                    onComplete: function () {
                        try {
                            if (this.targets[0] != undefined && this.targets[0].spawnbomb != "no")
                                this.targets[0].scene.spawnbomb(this.targets[0].spawnbomb, this.targets[0]);
                        } catch (e) {
                            console.log(e);
                        }
                        try {
                            if (this.targets[0] != undefined)
                                this.targets[0].destroy();
                        } catch (e) {
                            console.log(e);
                        }
                    }
                }, this);
                this.wh.physics.add.collider(tmpbullet2, this.wh.char_main, function (a1, a2) {
                    a1.scene.charhitbullet(a1.scene, a1);
                });
                break;

            case "ud":
                var tmpbullet = this.genbullet_sprite(bullanim, size);
                tmpbullet.spawnbomb = bombobj;
                tmpbullet.bulletdirection = "u";
                var bulltgx = tmpbullet.x; var bulltgy = tmpbullet.y;
                bulltgy = bulltgy + (32 * range);
                var tw = this.wh.tweens.add({
                    targets: tmpbullet,
                    x: bulltgx, y: bulltgy,
                    ease: 'Linear', duration: spd,
                    onComplete: function () {
                        try {
                            if (this.targets[0] != undefined && this.targets[0].spawnbomb != "no")
                                this.targets[0].scene.spawnbomb(this.targets[0].spawnbomb, this.targets[0]);
                        } catch (e) {
                            console.log(e);
                        }
                        try {
                            if (this.targets[0] != undefined)
                                this.targets[0].destroy();
                        } catch (e) {
                            console.log(e);
                        }
                    }
                }, this);
                //this.plog.log("mobxcollision",tmpbullet)
                this.wh.physics.add.collider(tmpbullet, this.wh.char_main, function (a1, a2) {
                    a1.scene.charhitbullet(a1.scene, a1);
                });

                var tmpbullet2 = this.genbullet_sprite(bullanim, size);
                tmpbullet2.spawnbomb = bombobj;
                tmpbullet2.bulletdirection = "d";
                var bulltgx2 = tmpbullet2.x; var bulltgy2 = tmpbullet2.y;
                bulltgy2 = bulltgy2 - (32 * range);
                var tw = this.wh.tweens.add({
                    targets: tmpbullet2,
                    x: bulltgx2, y: bulltgy2,
                    ease: 'Linear', duration: spd,
                    onComplete: function () {
                        //this.plog.log("spawnbomb tw",arguments,this);
                        try {
                            if (this.targets[0] != undefined && this.targets[0].spawnbomb != "no")
                                this.targets[0].scene.spawnbomb(this.targets[0].spawnbomb, this.targets[0]);
                        } catch (e) {
                            console.log(e);
                        }
                        try {
                            if (this.targets[0] != undefined)
                                this.targets[0].destroy();
                        } catch (e) {
                            console.log(e);
                        }
                    }
                }, this);
                this.wh.physics.add.collider(tmpbullet2, this.wh.char_main, function (a1, a2) {
                    a1.scene.charhitbullet(a1.scene, a1);
                });
                break;

            default:
                break;
        }
    }
    showspritewalk(dir) {
        //this.sprite.alpha = 0.5;
        //this.sprite.setAlpha(0.5);
        if (dir == "left") {
            this.sprite.flipX = false;
        }
        if (dir == "right") {
            this.sprite.flipX = true;
        }
    }
    cheers() {
        this.plog.log("cheers()", this);
        this.walkarray = [];
        var mobchkmax = 2;
        var mobchkmin = 1;
        var monnum = Math.floor(Math.random() * (mobchkmax - mobchkmin) + mobchkmin);

        this.idlecountdown = 2;
        this.doing = "cheers";
        return;
        try {
            this.sprite.play({ key: "mob_" + this.o["sprite"] + "_bored", repeat: 5 });//.setDisplayOrigin(0.5, 0.8);
        } catch (err) {
            this.plog.log("mapmob.cheers() error while trying to play victory (cheers) ", err);
            return;
        }
    }
}