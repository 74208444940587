const Linear = Phaser.Math.Linear;
const Percent = Phaser.Math.Percent;
export default {
  setValue(value, min, max) {
    if (value === undefined || value === null) {
      return this;
    }
    if (min !== undefined) {
      value = Percent(value, min, max);
    }
    this.value = value;
    return this;
  },
  addValue(inc, min, max) {
    if (min !== undefined) {
      inc = Percent(inc, min, max);
    }
    this.value += inc;
    return this;
  },
  getValue(min, max) {
    var value = this.value;
    if (min !== undefined) {
      value = Linear(min, max, value);
    }
    return value;
  }
};