import { Injectable } from '@angular/core';
import { RCVarsService } from './rcvars-service.service';
import { AStarFinder } from 'astar-typescript';
import { MapMob } from './mapmob';
import { MapMobX } from './mapmobx';
import { MapCritter } from './mapcritter';
import { MapBird } from './mapbird';
import { MapPet } from './mappet';
import { MapWalker } from './mapwalker';
import { plog } from './plog.service';

@Injectable({
  providedIn: 'root',
})
export class PhaserMapService {
  appupdatechecked = false;
  shiptimertxt;
  shiptimertxt_currenttime = 0;
  maincharelement;
  name;//fricking vscode don't recognize binding env
  wh;
  zmapchatdt = 0;
  updatezmapchar_cache;
  astar;
  floorastar;
  charzindex;
  spriteliveloaded = [];
  charanim_idle;
  loaderrorlist = [];
  intmap_preloaddone_runcount = 0;
  plog;

  constructor(
    private rcvarsservice: RCVarsService) {
    this.plog = new plog();
    this.plog.setLevel(1);
  }
  isbadint = "no";
  initmapinprocess = false;
  async initmap() {
    //console.log("INITMAP();");
    if (this.initmapinprocess == true) {
      this.plog.warn("PHM.initmap() called again while loading");
      return;
    }
    this.initmapinprocess = true;
    this.wh = this.rcvarsservice.gameobj.scene.scenes[0];
    try {
      //this.plog.log("initmap map containersize", this.wh.mapcontainer.list.length);
      //this.plog.log("initmap gifs containersize", this.wh.gifscontainer.list.length);
      //this.plog.log("initmap nogif containersize", this.wh.registry.list.notif.notiflist.length,this.wh.registry.list.notif.notiflist);

    } catch (e) {
      this.plog.log("ERROR", e);
    }
    try {
      this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rcvarpass.activemapreveal = [];
    } catch (e) {
      console.log(e);
    }
    this.isbadint = await this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rcstorage.get("setting_badint", true);

    try {
      var tmpreveal = await this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rcstorage.get(
        "z_mapreveal_" + this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rcvarpass.activemap["map"]["code"]
      );
      if (tmpreveal != undefined && Array.isArray(tmpreveal)) {// && tmpreveal.isArray()) {
        this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rcvarpass.activemapreveal = tmpreveal;
        //console.log("successfully restore reveal",
        //this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rcvarpass.activemap["map"]["code"]
        // , tmpreveal);
      }
    } catch (e) {
      console.log(e);
    }

    this.rcvarsservice.gameobj.scene.scenes[0].registry.list.resizeman.RCMapResizeManager_work(
      this.rcvarsservice.gameobj.scene.scenes[0]
    );
    this.rcvarsservice.gameobj.scene.scenes[0].char_main_lastspriteface =
      'down';
    this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rcloading.loading2lock(true);
    this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rcloading.loading2(
      this.rcvarsservice.gameobj.scene.scenes[0]
    );
    this.rcvarsservice.gameobj.scene.scenes[0].maptweening = false;
    this.rcvarsservice.gameobj.scene.scenes[0].registry.list.resizeman.RCMapResizeManager_work(
      this.rcvarsservice.gameobj.scene.scenes[0]
    );
    this.plog.log(
      'PhaserMapS-',
      this.rcvarsservice.gameobj,
      this.rcvarsservice.activemap,
      this.rcvarsservice.activechar
    );
    this.rcvarsservice.gameobj.scene.scenes[0].registry.list.ga.se2(
      'loadmap',
      this.rcvarsservice.activemap['name']
    );
    //this.rcvarsservice.gameobj.scene.scenes[0].maplayers = [];
    if (
      this.rcvarsservice.gameobj.scene.scenes[0].inventorycontainer_stackitem !=
      undefined
    ) {
      //console.log(this.rcvarsservice.gameobj.scene.scenes[0],this.rcvarsservice.gameobj.scene.scenes[0].inventorycontainer_stackitem);
      this.rcvarsservice.gameobj.scene.scenes[0].registry.list.gameitf.closegui(this.rcvarsservice.gameobj.scene.scenes[0], this.rcvarsservice.gameobj.scene.scenes[0].inventorycontainer_stackitem);
      this.rcvarsservice.gameobj.scene.scenes[0].registry.list.stackinven.showinventory_close(this.rcvarsservice.gameobj.scene.scenes[0]);

    }
    if (
      this.rcvarsservice.gameobj.scene.scenes[0].inventorycontainer_item !=
      undefined
    ) {
      this.rcvarsservice.gameobj.scene.scenes[0].registry.list.gameitf.closegui(this.rcvarsservice.gameobj.scene.scenes[0], this.rcvarsservice.gameobj.scene.scenes[0].inventorycontainer_item);
    }
    if (this.rcvarsservice.gameobj.scene.scenes[0].partymapcontainer != undefined) {
      this.rcvarsservice.gameobj.scene.scenes[0].registry.list.gameitf.closegui(this.rcvarsservice.gameobj.scene.scenes[0], this.rcvarsservice.gameobj.scene.scenes[0].partymapcontainer);
    }
    if (this.rcvarsservice.gameobj.scene.scenes[0].partymycontainer != undefined) {
      this.rcvarsservice.gameobj.scene.scenes[0].registry.list.gameitf.closegui(this.rcvarsservice.gameobj.scene.scenes[0], this.rcvarsservice.gameobj.scene.scenes[0].partymycontainer);
    }
    if (this.rcvarsservice.gameobj.scene.scenes[0].zmapi != undefined) {
      //this.plog.log("xxxx", this);
      for (const k in this.rcvarsservice.gameobj.scene.scenes[0].zmapi) {
        //this.plog.log("xxxx", k, this.rcvarsservice.gameobj.scene.scenes[0].zmapi[k]);
        if (this.rcvarsservice.gameobj.scene.scenes[0].zmapi[k] != undefined) {
          this.rcvarsservice.gameobj.scene.scenes[0].zmapi[k].destroy();
          this.rcvarsservice.gameobj.scene.scenes[0].zmapi[k] = undefined;
        }
      }
      this.rcvarsservice.gameobj.scene.scenes[0].zmapi = [];
    }

    this.updatezmapchar('usecache');
    this.rcvarsservice.gameobj.scene.scenes[0].registry.list.wsrc.ping();

    var svversionurlstring = this.rcvarsservice.svversionstring;
    if (svversionurlstring != '') {
      svversionurlstring = '?' + svversionurlstring;
    }
    //this.rcvarsservice.gameobj.scene.scenes[0].inventorycontainer_item.destroy();true

    //this.plog.log("loader.mobs", this.rcvarsservice.gameobj.scene.scenes[0].mobs);
    for (var key in this.rcvarsservice.gameobj.scene.scenes[0].mobs) {
      var mobi = this.rcvarsservice.gameobj.scene.scenes[0].mobs[key];
      if (this.rcvarsservice.gameobj.scene.scenes[0].mobs[key].behavetimer != undefined)
        this.rcvarsservice.gameobj.scene.scenes[0].mobs[key].behavetimer.remove(false);
      if (this.rcvarsservice.gameobj.scene.scenes[0].mobs[key].sprite != undefined)
        this.rcvarsservice.gameobj.scene.scenes[0].mobs[key].sprite.destroy();
      this.rcvarsservice.gameobj.scene.scenes[0].mobs[key] = undefined;
      //mobi.destroy();
    }
    this.rcvarsservice.gameobj.scene.scenes[0].mobs = [];
    //this.rcvarsservice.gameobj.scene.scenes[0].inputitemdb = undefined;
    //this.plog.log("loader.mobs after", this.rcvarsservice.gameobj.scene.scenes[0].mobs);
    //this.plog.log("service gameobj", this.rcvarsservice.gameobj);
    //this.rcvarsservice.gameobj.scene.scenes[0].load.image('red', this.rcvarsservice.rc_baseurl + '../semitrans.png');
    if (this.rcvarsservice.activemap["map"]['bgm'] != undefined && this.rcvarsservice.activemap["map"]['bgm'] != "") {
      let loaderbgm = new Phaser.Loader.LoaderPlugin(this.rcvarsservice.gameobj.scene.scenes[0]);
      loaderbgm.audio('mapbgm_' + this.rcvarsservice.activemap["map"]['code'], ["assets/audio/" + this.rcvarsservice.activemap["map"]['bgm'] + ".mp3"]);
      loaderbgm.start();
      loaderbgm.once(Phaser.Loader.Events.COMPLETE, () => {

        this.plog.log("audioloaded");
        this.bgmloaded();
      }, this);
    }

    let loader = new Phaser.Loader.LoaderPlugin(this.rcvarsservice.gameobj.scene.scenes[0]);
    // ask the LoaderPlugin to load the texture
    //loader.image('red', this.rcvarsservice.rc_baseurl + '../test.png');
    //this.rcvarsservice.gameobj.scene.scenes[0].load.image('red', this.rcvarsservice.rc_baseurl + '../test.png');
    //this.plog.log("service gameobj", this.rcvarsservice.gameobj.scene.scenes[0]);
    //this.rcvarsservice.gameobj.scene.scenes[0].maptweening=false;
    //phase 1 preload all known things to display
    var spritetoload = [];
    var skipbadint = [];
    var loadmaplayers = [];
    //add to load list
    //spritetoload['skill_weapon_tp'] = 1;
    //spritetoload['skill_tp'] = 1;

    //spritetoload['questitem'] = 1;
    //spritetoload['questitemg'] = 1;
    spritetoload['gamemenu'] = 1;
    spritetoload['gamemenug'] = 1;
    spritetoload['raindrops'] = 1;
    spritetoload['snowflakes'] = 1;
    //spritetoload['spacer'] = 1;
    spritetoload['fog'] = 1;
    spritetoload['charava'] = 1;
    spritetoload['snowflakeslarge'] = 1;
    spritetoload['updownarrow'] = 1;
    spritetoload['flag'] = 1;
    spritetoload['thesb'] = 1;
    spritetoload['emotes'] = 1;
    spritetoload['petstar'] = 1;
    //spritetoload['flares'] = 1;

    spritetoload['element'] = 1;
    spritetoload['mapgrow'] = 1;
    spritetoload['item_tp'] = 1;
    //spritetoload['charstatus_tp'] = 1;
    spritetoload['stackitem_tp'] = 1;
    spritetoload['stackitem_tpt'] = 1;

    //mapwalker
    for (const mapwalkerk in this.rcvarsservice.activemap['map']['mapwalker']) {
      spritetoload[mapwalkerk] = 1;
      skipbadint[mapwalkerk] = 1;
    }

    //screenbg s
    if (this.rcvarsservice.activemap['map']['screenbg'] != undefined &&
      this.rcvarsservice.activemap['map']['screenbg'] != ""
      //&&this.rcvarsservice.activemap['map']['screenbg'].length != undefined &&
      //this.rcvarsservice.activemap['map']['screenbg'].length > 0
    ) {
      this.plog.log("screenbg preload", this.rcvarsservice.activemap['map']['screenbg']);
      for (const screenbgk in this.rcvarsservice.activemap['map']['screenbg']) {
        if (this.rcvarsservice.activemap['map']['screenbg'][screenbgk] != undefined) {
          //spritetoload[this.rcvarsservice.activemap['map']['screenbg'][screenbgk]["file"]] = 1;
          var tmpurl = this.rcvarsservice.gameobj.registry.list.rcvarpass.getlocal(
            this.rcvarsservice.activemap['map']['screenbg'][screenbgk]["file"]
          );
          loader.image(this.rcvarsservice.activemap['map']['screenbg'][screenbgk]["file"], tmpurl);
        }
      }
    }
    //screenbg e
    //this.plog.log("xxxpeace2");
    spritetoload[this.rcvarsservice.activechar.sprite] = 1;
    //this.plog.log('activechar.sprite', this.rcvarsservice.activechar);
    //this.plog.log(mapw, maph);
    //remove npc by requirements first
    var chestnpcexists = false;
    var farmplotexists = false;
    var bushrewardnpcexists = false;
    var npcbribeexists = false;
    for (var key in this.rcvarsservice.activemap) {
      var value = this.rcvarsservice.activemap[key];
      if (value != null && key == 'npc') {
        //this.plog.log("nnn",value);
        for (var key2 in value) {
          var key2a = key2.split('_');
          var value2 = value[key2];
          //this.plog.log("npcloop phm initmap",this,key2a,value2);
          if (this.rcvarsservice.npc[value2] == undefined) continue;
          var instantmodule = this.rcvarsservice.npc[value2]["instantmodule"] + "";
          if (instantmodule.indexOf('npcbribe:') != -1) {
            npcbribeexists = true;
          }
          var npcchkreq = this.rcvarsservice.gameobj.registry.list.rcreq.chk(
            this.rcvarsservice.npc[value2]['requirements']
          );
          //this.plog.log("nnn req",npcchkreq,key2);
          if (npcchkreq != true) { //instead of 'delete' will hide it instead
            this.plog.log("npcloop not pass requirement", this, key2a, value2);
            //this.rcvarsservice.activemap["npc"].splice(key2, 1);
            ///delete this.rcvarsservice.activemap['npc'][key2];
            //[key2]=undefined;
            continue;
          } else {
            if (value2.indexOf('chestlv') != -1) {
              chestnpcexists = true;
            }
            if (value2.indexOf('bushreward') != -1) {
              bushrewardnpcexists = true;
            }
            if (value2.indexOf('farmplot') != -1) {
              farmplotexists = true;
            }
          }
        }
      }
    }
    //this.plog.log("xxxpeace3");
    if (farmplotexists == true) {
      spritetoload["farmplot"] = 1;
    }
    if (chestnpcexists == true) {
      this.plog.log('chestnpcexists true');
      this.rcvarsservice.gameobj.scene.scenes[0].cheststatus =
        await this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rchttp.getcommon2(
          'chestsstatus'
        );
      this.plog.log(
        'chestsstatus',
        this.rcvarsservice.gameobj.scene.scenes[0].cheststatus
      );
    }
    if (bushrewardnpcexists == true) {
      this.plog.log('bushrewardnpcexists true');
      this.rcvarsservice.gameobj.scene.scenes[0].bushrewardstatus =
        await this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rchttp.getcommon2(
          'bushrewardstatus'
        );
      /*this.plog.log(
        'bushrewardstatus',
        this.rcvarsservice.gameobj.scene.scenes[0].bushrewardstatus
      );*/
    }
    if (npcbribeexists == true) {
      this.plog.log('npcbribeexists true');
      this.rcvarsservice.gameobj.scene.scenes[0].npcbribestatus =
        await this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rchttp.getcommon2(
          'npcbribestatus'
        );
      this.plog.log(
        'npcbribestatus',
        this.rcvarsservice.gameobj.scene.scenes[0].npcbribestatus
      );
    }
    //this.plog.error("nnn", this.rcvarsservice.activemap);
    for (var key in this.rcvarsservice.activemap) {
      if (
        key == 'bg' ||
        key == 'bg2' ||
        key == 'bg3' ||
        key == 'fg' ||
        key == 'fg2' ||
        key == 'fg3'
      ) {
        if (this.rcvarsservice.activemap[key] == "merged") {
          loadmaplayers[
            'mapdata_' + this.rcvarsservice.activemap['map']['code'] + '_' + key
          ] = 'merged';
        } else {
          loadmaplayers[
            'mapdata_' + this.rcvarsservice.activemap['map']['code'] + '_' + key
          ] = 'xxx';
        }
        continue; //load generated map instead//phaser limit
      }

      var value = this.rcvarsservice.activemap[key];

      if (
        value != null &&
        key != 'map' &&
        key != 'zani' &&
        key != 'zaninight' &&
        key != 'zwarp' &&
        key != 'npc' &&
        key != 'npcclose' &&
        key != 'tooltip' &&
        key != 'possay' &&
        key != 'light' &&
        key != 'zmap'
      ) {
        //this.plog.log("loop1", key, value);
        //this.plog.log(value + ':-:' + key);
        for (var key2 in value) {
          //this.plog.log(value,key2);
          var value2 = value[key2];
          var valuea = value2.split('_');
          //this.plog.log(key2,value,valuea);
          spritetoload[valuea[0]] = '1';
        }
      }
      if (value != null && (key == 'zani' || key == 'zaninight')) {
        //this.plog.log("xxx",value);
        for (var key2 in value) {
          var value2 = value[key2];
          var valuea = value2.split('_');
          //this.plog.log(key2,valuea);
          spritetoload[valuea[0]] = '1';
        }
      }
      if (value != null && key == 'npc') {
        //this.plog.log("xxx",value);
        for (var key2 in value) {
          var key2a = key2.split('_');
          var value2 = value[key2];
          //this.plog.log("LKJLKJ",this.rcvarsservice.npc[value2],this.rcvarsservice.npc[value2]["zsortfloorlevel"])
          if (this.rcvarsservice.npc[value2] == undefined) continue;

          if (this.rcvarsservice.npc[value2]['zsort'] != 'floor')
            this.rcvarsservice.activemap['walkable'][key2] = 'yn_nn';
          var thisanims = this.rcvarsservice.npc[value2]['mapsprite'];
          //this.plog.log("preloadnpc key2", key2);
          var tmpanims: string = thisanims;
          var valuea: any;
          valuea = tmpanims.split('_');
          //this.plog.log('preloading ', valuea[0], 'for npc ', value2);
          //this.plog.log("preloadnpc2",valuea);
          //this.plog.log(key2,valuea);
          spritetoload[valuea[0]] = '1';
          skipbadint[valuea[0]] = '1';

          if (this.rcvarsservice.npc[value2]['buildable'] != undefined) {
            if (this.rcvarsservice.npc[value2]['buildable']["harvest_readysprite"] != undefined && this.rcvarsservice.npc[value2]['buildable']["harvest_readysprite"] != '') {
              var tmphavanim = this.rcvarsservice.allanims[this.rcvarsservice.npc[value2]['buildable']["harvest_readysprite"]];
              // spritetoload[tmphavanim['sprite']] = '1';
              this.plog.log("harvestanims", tmphavanim, this.rcvarsservice.anims);
              if (tmphavanim != undefined) {
                this.rcvarsservice.anims[this.rcvarsservice.npc[value2]['buildable']["harvest_readysprite"]] = tmphavanim;
              } else {
                this.plog.log("allanimsundefined harvest_readysprite");
              }
            }
          }
        }
      }
    }
    //this.plog.log("xxxpeace4");
    for (var key in this.rcvarsservice.anims) {
      var val = this.rcvarsservice.anims[key];
      //this.plog.log("load anims", key, val);
      spritetoload[val['sprite']] = '1';
    }
    //pre populate map if backyard
    this.plog.log("prepopulate", this);
    if (this.wh.registry.list.rcvarpass.activemap["map"]["code"] == "backyard" || this.wh.registry.list.rcvarpass.activemap["map"]["code"] == "backyard2") {
      //this.wh.registry.list.rcvarpass.activemap.map.mobteam=[];

      //this.plog.log("prepopulate backyard", this.wh.registry.list.rcvarpass.activemap["map"]);
      this.wh.registry.list.rcvarpass.activemap["map"]["mobteam"] = [];
      var tmpmob = {};
      var mobbyi = 0;
      for (const mobk in this.wh.registry.list.rcvarpass.activechar.pet) {
        mobbyi++;
        if (mobbyi >= 9) continue;
        var mobv = this.wh.registry.list.rcvarpass.activechar.pet[mobk];
        tmpmob["mob" + mobbyi] = mobv["mob"];
        tmpmob["mob" + mobbyi + "_min"] = 1;
        tmpmob["mob" + mobbyi + "_max"] = 1;
        tmpmob["mob" + mobbyi + "_petname"] = mobv["name"];

        //this.wh.registry.list.rcvarpass.activemap["map"]["mobteam"].push(tmpmob);
      }
      this.wh.registry.list.rcvarpass.activemap["map"]["mobteam"] = tmpmob;
      //this.plog.log("prepopulate backyard", this.wh.registry.list.rcvarpass.activemap["map"]["mobteam"]);
    }
    //activemap mapmobx
    if (this.rcvarsservice.activemap['map']["activemap"] == "YES") {
      for (const k in this.rcvarsservice.activemap['mobxspawn']) {
        var v = this.rcvarsservice.activemap['mobxspawn'][k];
        v = v.replace("mobx", "");
        spritetoload[this.rcvarsservice.allmob[v]['sprite']] = '1';
        if (this.rcvarsservice.allmob[v]["mobx"] != undefined && this.rcvarsservice.allmob[v]["mobx"] != null) {
          for (var scananii = 0; scananii <= 10; scananii++) {
            if (this.rcvarsservice.allmob[v]["mobx"]["action" + scananii + "-shoot-ani"] != undefined && this.rcvarsservice.allmob[v]["mobx"]["action" + scananii + "-shoot-ani"] != "") {
              var tmpspritefromanim = this.rcvarsservice.allanims[this.rcvarsservice.allmob[v]["mobx"]["action" + scananii + "-shoot-ani"]];
              if (tmpspritefromanim != "") {
                spritetoload[tmpspritefromanim["sprite"]] = '1';
              }

              this.plog.log("mobxassets", v, this.rcvarsservice.allmob[v]["mobx"]["action" + scananii + "-shoot-ani"], tmpspritefromanim);
            }
          }
        }
      }
    }
    //this.plog.log("xxxpeace5");
    //checkmob
    var mobcount = 0;
    for (let index = 0; index <= 5; index++) {
      if (this.rcvarsservice.activemap['map']['mobteam'] != undefined) {
        var mobchk =
          this.rcvarsservice.activemap['map']['mobteam']['mob' + index];
        //this.plog.log("mobchk", index, mobchk);
        if (
          mobchk != undefined &&
          mobchk != '' &&
          this.rcvarsservice.allmob[mobchk] != undefined
        ) {
          //spritetoload[mobchk] = "1";
          spritetoload[this.rcvarsservice.allmob[mobchk]['sprite']] = '1';
          skipbadint[this.rcvarsservice.allmob[mobchk]['sprite']] = 1;
          mobcount++;
        }
      }
    }
    var tmpmincombat = await this.wh.registry.list.rcstorage.get('setting_mincombat', true);
    if (tmpmincombat == "yes") {
      spritetoload["mincombatmobb"] = 1;
      spritetoload["mincombatmobr"] = 1;
      await this.wh.registry.list.phm.loadcharsprite("mincombatcharb");
      await this.wh.registry.list.phm.loadcharsprite("mincombatcharr");
    }
    if (mobcount > 0) {
      //loader.image("battleground_"+this.rcvarsservice.activemap["map"]["code"], this.rcvarsservice.rc_baseurl + "battleground/"+this.rcvarsservice.activemap["map"]["code"]+".png");
    }
    //checkcritter
    for (let index = 0; index <= 5; index++) {
      var critterschk =
        this.rcvarsservice.activemap['map']['critters'][
        'crit' + index + '_sprite'
        ];
      //this.plog.log("critterschk", index, critterschk);
      if (critterschk != undefined && critterschk != '') {
        spritetoload[critterschk] = '1';
      }
    }
    //flybystatus
    if (this.rcvarsservice.activemap['map']['isoutside'] == 'YES') {
      this.rcvarsservice.activemap['map']['critters']['crit10_behave'] = 'walk';
      this.rcvarsservice.activemap['map']['critters']['crit10_max'] = 0;
      this.rcvarsservice.activemap['map']['critters']['crit10_min'] = 0;
      this.rcvarsservice.activemap['map']['critters']['crit10_sprite'] =
        'flybycritter';
      this.rcvarsservice.activemap['map']['critters']['crit10_spritesubcode'] =
        'a';
      this.rcvarsservice.activemap['map']['critters']['crit10_talk'] =
        'meow|Meow.. just a cat|Meow|Yarnnn';

      spritetoload['flybycritter'] = '1';
      skipbadint['flybycritter'] = '1';

      if (this.rcvarsservice.activemap['map']['bird'] != undefined && this.rcvarsservice.activemap['map']['bird'] != "") {
        spritetoload[this.rcvarsservice.activemap['map']['bird']] = 1;
        skipbadint[this.rcvarsservice.activemap['map']['bird']] = 1;

      }

    }
    //this.plog.log("xxxpeace6");
    //this.plog.log("loadmaplayers", loadmaplayers);

    for (var key in loadmaplayers) {
      var keya = key.split('_');

      if (loadmaplayers[key] == "merged") {
        var tmpurl;
        if (this.isbadint == "yes") {
          tmpurl = this.rcvarsservice.gameobj.registry.list.rcvarpass.getlocal(
            '_badint/map_layers/' + keya[1] + '-' + keya[2] + 'merge.png'
          );
        } else {
          tmpurl = this.rcvarsservice.gameobj.registry.list.rcvarpass.getlocal(
            'map_layers/' + keya[1] + '-' + keya[2] + 'merge.png'
          );

        }

      } else {
        var tmpurl = this.rcvarsservice.gameobj.registry.list.rcvarpass.getlocal(
          'map_layers/' + keya[1] + '-' + keya[2] + '.png'
        );
      }
      loader.image(key, tmpurl + svversionurlstring); // + Math.random());
    }
    /*
    DON"T DELETE
    for (var key in loadmaplayers) {
      var keya = key.split('_');
      if (keya[2] == 'bgorlayerthatireallreallywanttousenormalmap') {
        loader.image(key, [
          this.rcvarsservice.rc_baseurl +
          'map_layers/' +
          keya[1] +
          '-' +
          keya[2] +
          '.png' +
          svversionurlstring,
          'https://assets.peacely.online/provider/map1-normal3.png',
        ]); // + Math.random());
      } else {
        if (loadmaplayers[key] == "merged") {
          var tmpurl = this.rcvarsservice.gameobj.registry.list.rcvarpass.getlocal(
            'map_layers/' + keya[1] + '-' + keya[2] + 'merge.png'
          );

        } else {
          var tmpurl = this.rcvarsservice.gameobj.registry.list.rcvarpass.getlocal(
            'map_layers/' + keya[1] + '-' + keya[2] + '.png'
          );
        }
        loader.image(key, tmpurl + svversionurlstring); // + Math.random());
      }
    }*/
    // this.plog.log("xxxpeace7");
    //images

    var loadlista = {

      blankguibg: 'gui/blankguibg.png',
      ilight: 'gui/ilight.png',
      ilightblack: 'gui/ilight-black.png',
      mobileguibg: 'gui/mobileguibg.jpg',
      loreprogressmain: 'gui/loreprogressmain.png',
      //squareblock: 'gui/squareblock.png',
      itftl: 'gui/itftl.png',
      itftr1: 'gui/itftr1.png',
      avaframe: 'gui/avaframe.png',
      mapframe: 'gui/mapframe.png',
      pgframe: 'gui/pgframe.png',
      curbgsupp: 'gui/curbg-supp.png',
      curbggold: 'gui/curbg-gold.png',
      curbgdiamond: 'gui/curbg-diamond.png',
      blankbarguibg: 'gui/blankbarguibg.png',
      iapitembg: 'gui/iapitembg.png',
      empty: 'gui/empty.png',
      winguiltab: 'gui/winguiltab.png',
      mailicon: 'gui/mailicon.png',
      paperguibg: 'gui/paperguibg.png',
      paperwithheadguibg: 'gui/paperwithheadguibg.png',
      mailicondis: 'gui/mailicon-dis.png',
      selectcircle: 'gui/selectcircle.png',
      toprankguibg: 'gui/toprankguibg.png',
      zcharinfoguibg: 'gui/zcharinfoguibg.png',
      dialogbglong: 'gui/dialogbglong.png',
      blankbarboxguibg: 'gui/blankbarboxguibg.png',
      blankrowguibg: 'gui/blankrowguibg.png',
      spritedialogguibg: 'gui/spritedialogguibg.png',
      upgradeitemguibg: 'gui/upgradeitemguibg.png',
      pickitemguibg: 'gui/pickitemguibg.png',
      localmapguibg: 'gui/localmapguibg.png',
      farmorderguibg: 'gui/farmorderguibg.png',
      beginbattlebtn: 'gui/beginbattlebtn.png',
      clock: 'gui/clock.png',
      rowbg: 'gui/rowbg.png',
      nest: 'gui/nest.png',
      nestwithegg: 'gui/nestwithegg.png',
      ////resourceboxd: 'gui/resourcebox-diamond.png',
      ////resourceboxg: 'gui/resourcebox-gold.png',
      ////resourceboxb: 'gui/resourcebox-both.png',
      //minimapbg: 'gui/minimapbg.png',
      pricebtn: 'gui/pricebtn.png',
      shopguibg3x2: 'gui/shopguibg3x2.png',
      shopguibg5x5: 'gui/shopguibg5x5.png',
      equipguibg: 'gui/equipguibg.png',
      attackbtn: 'gui/attackbtn.png',
      jumpbtn: 'gui/jumpbtn.png',
      dashbtn: 'gui/dashbtn.png',
      guardbtn: 'gui/guardbtn.png',
      justcircle: 'gui/justcircle.png',
      spacer: 'spacer.png',
      defavatar: 'defavatar.png',
      homeinveniguibg: 'gui/homeinveniguibg.png',
      homeinvensguibg: 'gui/homeinvensguibg.png',
      flare0: 'gui/flare0.png',
      rewardbg: 'gui/rewardbg.png',
      dialogbg: 'gui/dialogbg.png',
      dialogdedbg: 'gui/dialogdedbg.png',
      notif: 'gui/notif.png',
      atkarrow: 'atkarrow.png',
      spellcastatk: 'spellcastatk.png',
      combatwin: 'gui/combat-win.jpg',
      combatlose: 'gui/combat-lose.jpg',
      chatbubble: 'gui/chatbubble.png',
      chatbubblearrow: 'gui/chatbubblearrow.png',
      thinkbubble: 'gui/thinkbubble.png',
      thinkbubblearrow: 'gui/thinkbubblearrow.png',
      testlightts: 'testlightt.png',
      alpha10: 'alpha10.png',
      white: 'gui/white.png',
      npcmark: 'npcmark.png',
      revealbrush: 'gui/revealbrush.png',
      revealbrushlight: 'gui/revealbrush-light.png',
      ballooncat: 'ballooncat.png',
    };
    //map boarder
    var mapbdcolor = this.rcvarsservice.activemap['map']['mapbdcolor'];
    if (mapbdcolor == undefined || mapbdcolor == '') mapbdcolor = 'green';
    loadlista['border-' + mapbdcolor + '-d'] = 'border/border-' + mapbdcolor + '-d.png';
    loadlista['border-' + mapbdcolor + '-l'] = 'border/border-' + mapbdcolor + '-l.png';
    loadlista['border-' + mapbdcolor + '-r'] = 'border/border-' + mapbdcolor + '-r.png';
    loadlista['border-' + mapbdcolor + '-u'] = 'border/border-' + mapbdcolor + '-u.png';

    //this.plog.log("svversionstring", svversionurlstring, this.rcvarsservice.svversionstring);
    //this.plog.log("xxxpeace8");
    var gamemenu = this.rcvarsservice.gamemenu;
    //this.plog.log("gamemenu", gamemenu);
    var mapw = this.rcvarsservice.activemap.map.w;
    var maph = this.rcvarsservice.activemap.map.h;
    this.astar = undefined;
    this.floorastar = undefined;
    //don't walk over npc, with range
    var npcwithinputitemexists = false;
    //(this also make NPC accessible from any distance)
    if (this.rcvarsservice.activemap['npc'] != undefined) {
      for (var w = 0; w <= mapw; w++) {
        for (var h = 0; h <= maph; h++) {
          var cellvaluenpc = this.rcvarsservice.activemap['npc'][w + '-' + h];

          var npcdata = this.rcvarsservice.npc[cellvaluenpc];
          if (cellvaluenpc != undefined) {

            //calculate npc inputitem
            //this.plog.log('inputitem', npcdata);
            var npcdata = this.rcvarsservice.npc[cellvaluenpc];

            if (
              npcdata != undefined &&
              npcdata['inputitem'] != null &&
              npcdata['inputitem'] != undefined
            ) {
              var npcdatainputitem: any = npcdata['inputitem'];
              var ccinputitem = 0;
              for (var k in npcdatainputitem) {
                if (k == "selfactivate") continue;
                ccinputitem++;
              }
              if (ccinputitem > 0) {
                this.plog.log('inputitem exists npcdata', npcdata);
                npcwithinputitemexists = true;
              }
              if (npcdata['inputitem'] != undefined &&
                npcdata['inputitem']['selfactivateani'] != "" &&
                npcdata['inputitem']['selfactivateani'] != undefined) {
                var tmpania = (npcdata['inputitem']['selfactivateani'] + "").split("_");

                spritetoload[tmpania[0]] = 1;
                skipbadint[tmpania[0]] = 1;

              }
            }
          }
        }
      }
      if (npcwithinputitemexists == true) {
        //this.plog.log('inputitem exists npcdata,adding to spritetoload');

        spritetoload['inputitem1'] = 1;
        spritetoload['inputitem2'] = 1;
        spritetoload['inputitem3'] = 1;
      }
    }
    var audiotoload = [];
    /*
    
        loader.audio("", ["assets/audio/combat.mp3"]);
        loader.audio("", ["assets/audio/drop.mp3"]);
        loader.audio("", ["assets/audio/coins.mp3"]);
        loader.audio("", ["assets/audio/buy.mp3"]);
        loader.audio("", ["assets/audio/pickup.mp3"]);
        loader.audio("", ["assets/audio/combatwin.mp3"]);

        loader.audio("", ["assets/audio/combatlose.mp3"]);
        loader.audio("", ["assets/audio/meow1.mp3"]);
        loader.audio("", ["assets/audio/heal.mp3"]);
        loader.audio("", ["assets/audio/balloonwoosh.mp3"]);
        loader.audio("", ["assets/audio/balloonpop.mp3"]);
        loader.audio("", ["assets/audio/fruitcollect.mp3"]);
        loader.audio("", ["assets/audio/atkhit1.mp3"]);
        loader.audio("", ["assets/audio/atkhit2.mp3"]);
        loader.audio("", ["assets/audio/atkhit3.mp3"]);
        loader.audio("", ["assets/audio/atkarrow1.mp3"]);
        loader.audio("", ["assets/audio/atkarrow2.mp3"]);
        loader.audio("", ["assets/audio/atkbuff1.mp3"]);
        loader.audio("", ["assets/audio/atkcurse1.mp3"]);
        loader.audio("", ["assets/audio/atkcurse2.mp3"]);
        loader.audio("", ["assets/audio/atkexplode1.mp3"]);
        loader.audio("", ["assets/audio/atkexplode2.mp3"]);
        loader.audio("", ["assets/audio/atkwhip1.mp3"]);
        loader.audio("", ["assets/audio/atkwhip2.mp3"]);
        loader.audio("", ["assets/audio/atkwink1.mp3"]);
        loader.audio("", ["assets/audio/atkwink2.mp3"]);
        loader.audio("", ["assets/audio/atkelectric1.mp3"]);
        loader.audio("", ["assets/audio/atkarcane1.mp3"]);
        loader.audio("", ["assets/audio/atkarcane2.mp3"]);
        loader.audio("", ["assets/audio/light-rain.mp3"]);
        loader.audio("", ["assets/audio/heavy-rain.mp3"]);
        loader.audio("thunder", ["assets/audio/thunder.mp3"]);
        */
    audiotoload["combat"] = 1;
    audiotoload["drop"] = 1;
    audiotoload["coins"] = 1;
    audiotoload["buy"] = 1;
    audiotoload["pickup"] = 1;
    audiotoload["combatwin"] = 1;
    audiotoload["combatlose"] = 1;
    audiotoload["meow1"] = 1;
    audiotoload["heal"] = 1;
    audiotoload["balloonwoosh"] = 1;
    audiotoload["balloonpop"] = 1;
    audiotoload["fruitcollect"] = 1;
    audiotoload["atkhit1"] = 1;
    audiotoload["atkhit2"] = 1;
    audiotoload["atkhit3"] = 1;
    audiotoload["atkarrow1"] = 1;
    audiotoload["atkarrow2"] = 1;
    audiotoload["atkbuff1"] = 1;
    audiotoload["atkcurse1"] = 1;
    audiotoload["atkcurse2"] = 1;
    audiotoload["atkexplode1"] = 1;
    audiotoload["atkexplode2"] = 1;
    audiotoload["atkwhip1"] = 1;
    audiotoload["atkwhip2"] = 1;
    audiotoload["atkwink1"] = 1;
    audiotoload["atkwink2"] = 1;
    audiotoload["atkelectric1"] = 1;
    audiotoload["atkarcane1"] = 1;
    audiotoload["atkarcane2"] = 1;
    audiotoload["light-rain"] = 1;
    audiotoload["heavy-rain"] = 1;
    audiotoload["thunder"] = 1;
    for (var key in audiotoload) {
      if (this.wh.cache.audio.entries.entries[key] == undefined) {
        loader.audio("" + key, ["assets/audio/" + key + ".mp3"]);
      }
    }
    //console.log("audiotoload", this)

    for (var key in spritetoload) {
      //this.plog.log("load atlas", key, this.rcvarsservice.rc_baseurl + "sprite/" + key + ".png", this.rcvarsservice.rc_baseurl + "sprite/" + key + ".json");// + Math.random());
      // + Math.random()
      //loader.atlas(key + "", this.rcvarsservice.rc_baseurl + "sprite/" + key + ".png", this.rcvarsservice.rc_baseurl + "sprite/" + key + ".json");// + Math.random());
      if (key == "") continue;
      //this.plog.log("spritetoload",key, this.rcvarsservice.rc_baseurl +      'sprite/' +      key +      '.png' +      svversionurlstring);
      //this.plog.log("xxxpeace spritetoload", key, this.rcvarsservice.rc_baseurl + 'sprite/' + key + '.png' + svversionurlstring);
      //if (key=="farmplot") continue;
      //
      // console.log("skipbadint", skipbadint, key);
      if (this.isbadint == "yes" &&
        key.indexOf("gamemenu") == -1 &&
        key.indexOf("-male-") == -1 &&
        key.indexOf("-female-") == -1 &&
        skipbadint[key] == undefined
      ) {
        if (this.rcvarsservice.gameobj.textures.list[key] == undefined) {
          loader.atlas(
            key + '',
            this.rcvarsservice.rc_baseurl + '_badint/sprite/' + key + '.png' +
            svversionurlstring,
            this.rcvarsservice.rc_baseurl + 'sprite/' + key + '.json' +
            svversionurlstring
          );
        }
      } else {
        if (this.rcvarsservice.gameobj.textures.list[key] == undefined) {
          loader.atlas(
            key + '',
            this.rcvarsservice.rc_baseurl + 'sprite/' + key + '.png' +
            svversionurlstring,
            this.rcvarsservice.rc_baseurl + 'sprite/' + key + '.json' +
            svversionurlstring
          );
        }

      }

    }

    for (let key of Object.keys(loadlista)) {
      let val = loadlista[key];
      //
      if (this.rcvarsservice.gameobj.anims.textureManager.list[key] == undefined) {
        var tmpurl =
          this.rcvarsservice.gameobj.registry.list.rcvarpass.getlocal(val);
        loader.image(key, tmpurl + svversionurlstring);
      }
    }

    var tmpurl = this.rcvarsservice.gameobj.registry.list.rcvarpass.getlocal(
      'minimap/' + this.rcvarsservice.activemap['map'].code + '.png'
    );

    loader.image(
      'minimap_' + this.rcvarsservice.activemap['map'].code,
      tmpurl + svversionurlstring
    );

    //this.plog.log("tmpmetrix",tmpmetrix);
    //this.plog.log("tmpmetrix", this.rcvarsservice.activemapmetrix);
    //this.plog.log('tmpmetrixmob', this.rcvarsservice.activemapmetrixmob);
    loader.on('progress', async function (value) {
      //this.plog.log("loaderevent", value, loader.totalComplete, loader.totalToLoad);
      this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rcloading.loading2text(
        'Loading ' + loader.totalComplete + '/' + loader.totalToLoad
      );
      //await this.wh.registry.list.rctoast.sleep(50);

      this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rcloading.loading2percent(
        value
      );
    }, this);
    loader.on('loaderror', function (value) {
      this.plog.log("chkerror loaderror", value);
      this.loaderrorlist.push(value);
    }, this);
    loader.once(Phaser.Loader.Events.COMPLETE, () => {
      // texture loaded so use instead of the placeholder
      //this.plog.log("Phaser.Loader.Events.COMPLETE");
      //this.plog.log(this);
      this.intmap_preloaddone();
    });
    //this.plog.log("spritetoload", spritetoload);
    //this.plog.log("xxxpeace9");
    this.loaderrorlist = [];
    loader.start();
    //this.plog.log("xxxpeace10");
    /*this.rcvarsservice.gameobj.scene.scenes[0].registry.list.resizeman.RCMapResizeManager_work(
      this.rcvarsservice.gameobj.scene.scenes[0]
    );*/
  }

  calculateDistance(x1, y1, x2, y2) {
    const deltaX = x2 - x1;
    const deltaY = y2 - y1;
    return Math.sqrt(deltaX * deltaX + deltaY * deltaY);
    /*
    const deltaX = x2 - x1;
    const deltaY = y2 - y1;
    const distance = Math.sqrt(deltaX ** 2 + deltaY ** 2);
    return distance;*/
  }
  determineDirection(x1, y1, x2, y2) {
    var direction = "";
    const deltaX = x2 - x1;
    const deltaY = y2 - y1;

    if (Math.abs(deltaX) > Math.abs(deltaY)) {
      // Horizontal movement
      if (deltaX > 0) {
        direction = "right";
      } else {
        direction = "left";
      }
    } else {
      // Vertical movement
      if (deltaY > 0) {
        direction = "down";
      } else {
        direction = "up";
      }
    }

    return direction;
  }
  async intmap_preloaddone() {
    this.intmap_preloaddone_runcount++;
    this.wh.registry.list.snd.prepare();
    if (this.intmap_preloaddone_runcount == 1) {
      this.wh.registry.list.snd.snd("intro2");

    }
    if (this.loaderrorlist.length != 0) {
      let loader = new Phaser.Loader.LoaderPlugin(this.rcvarsservice.gameobj.scene.scenes[0]);
      this.plog.warn("intmap_preloaddone chkerror", this.loaderrorlist);
      var svversionurlstring = this.rcvarsservice.svversionstring;
      if (svversionurlstring != '') {
        svversionurlstring = '?retrying' + svversionurlstring + "retrying";
      }

      for (var key in this.loaderrorlist) {
        var value = this.loaderrorlist[key];
        if (value['type'] == 'image') {
          var tmpurl = value['url'];
          loader.image(
            '' + value['key'],
            tmpurl + svversionurlstring
          );
        }
        if (value['type'] == 'json' && value['src'].indexOf("/sprite/") != -1) {
          //load as sprite/atlas
          var tmpurl = value['src'];
          loader.atlas(
            key + '',
            tmpurl.replace(".json?", ".png?") + svversionurlstring,
            tmpurl + svversionurlstring
          );
        }
      }

      loader.on('progress', function (value) {
        //this.plog.log("loaderevent", value, loader.totalComplete, loader.totalToLoad);
        this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rcloading.loading2text(
          'Loading ' + loader.totalComplete + '/' + loader.totalToLoad
        );
        this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rcloading.loading2percent(value);
      }, this);
      loader.on('loaderror', function (value) {
        console.error("chkerror(inretry) loaderror", value);
        //this.loaderrorlist.push(value);
      }, this);
      loader.once(Phaser.Loader.Events.COMPLETE, () => {
        this.intmap_preloaddone();
      });
      this.loaderrorlist = [];
      loader.start();
      return;
    }
    this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rcloading.loading2lock(false);

    this.rcvarsservice.gameobj.scene.scenes[0].registry.list.wsrc.ping();
    this.rcvarsservice.gameobj.scene.scenes[0].joyStick.visible = true;
    //this.plog.log("xxxx",    this.rcvarsservice.gameobj.scene.scenes[0].joyStick);
    this.plog.log('intmap_preloaddone', this);
    //fix white scr
    this.rcvarsservice.gameobj.scene.scenes[0].game.scale.resize(
      window.innerWidth,
      window.innerHeight
    );
    if (this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rcvarpass.activemap["map"]["activemap"] != "YES") {
      //this.plog.log("xxxh",this);
    }

    if (this.rcvarsservice.activemap["map"]['bgm'] != undefined && this.rcvarsservice.activemap["map"]['bgm'] != "") {
      if (this.wh.registry.list.snd.playingbgm != 'mapbgm_' + this.rcvarsservice.activemap["map"]['code']) {
        this.wh.registry.list.snd.bgm('mapbgm_' + this.rcvarsservice.activemap["map"]['code']);
      }
    } else {
      if (this.wh.registry.list.snd.playingbgm != "bgm") {
        this.wh.registry.list.snd.bgm('bgm');
      }
    }
    await this.loadcharsprite(this.rcvarsservice.activechar['sprite']);

    if (this.rcvarsservice.activechar['spriteorig'] != this.rcvarsservice.activechar['sprite']) {
      await this.loadcharsprite(this.rcvarsservice.activechar['spriteorig']);
    }
    //return;
    this.rcvarsservice.gameobj.scene.scenes[0].registry.list.wsrc.initmap(
      this.rcvarsservice.activechar['map']
    );

    //create anims from autopublish anims
    await this.initmap_preloaddone_createanim();

    //activemap mapmobx
    await this.initmap_preloaddone_mapmobxanim();

    for (const mapwalkerk in this.rcvarsservice.activemap['map']['mapwalker']) {
      this.initmap_preloaddone_genstdnpcsprite([mapwalkerk]);
    }

    this.plog.log('intmap_preloaddone');
    for (var desi = 0; desi <= 10; desi++) {
      await this.initmap_preloaddone_destroymapcontainer();
    }
    /*
        this.rcvarsservice.gameobj.scene.scenes[0].tilecontainer =
          this.rcvarsservice.gameobj.scene.scenes[0].add.container(0, 0);
        this.rcvarsservice.gameobj.scene.scenes[0].tilecontainer.setPosition(0, 0);
        this.rcvarsservice.gameobj.scene.scenes[0].tilecontainer.name = "tilecontainer";
    */
    this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer =
      this.rcvarsservice.gameobj.scene.scenes[0].add.container(0, 0);
    this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.setPosition(0, 0);
    this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.name = "mapcontainer";



    //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.setPosition(0, 0);
    var mapw = this.rcvarsservice.activemap.map.w;
    var maph = this.rcvarsservice.activemap.map.h;
    //var sprite0 = this.add.sprite(-400, 0, 'rick');
    var localzindex;
    localzindex = 0;
    var farmplotexists2 = false;
    for (var key in this.rcvarsservice.activemap) {
      var value = this.rcvarsservice.activemap[key];
      //key != "walkable" add spacer to walkable
      if (
        value != null &&
        key != 'map' &&
        key != 'spawn' &&
        key != 'zani' &&
        key != 'zaninight' &&
        key != 'mobxwalk' &&
        key != 'mobxspawn' &&
        key != 'zwarp' &&
        key != 'npc' &&
        key != 'possay' &&
        key != 'tooltip' &&
        key != 'mobwalk' &&
        key != 'light' &&
        key != 'weed' &&
        key != 'build' &&
        key != 'zmap'
      ) {
        if (key == 'fg') this.charzindex = localzindex;

        if (
          key == 'fg' ||
          key == 'fg2' ||
          key == 'fg3' ||
          key == 'bg' ||
          key == 'bg2' ||
          key == 'bg3'
        ) {
          //this.plog.log("mapdata loadlayer" + "mapdata_" + this.rcvarsservice.activemap["map"]["code"] + "_" + key)
          this.rcvarsservice.gameobj.scene.scenes[0].maplayers[key] = this.rcvarsservice.gameobj.scene.scenes[0].add.image(16, 16,
            'mapdata_' + this.rcvarsservice.activemap['map']['code'] + '_' + key
          );
          this.rcvarsservice.gameobj.scene.scenes[0].maplayers[key].displayWidth = this.rcvarsservice.activemap['map']['w'] * 32;
          this.rcvarsservice.gameobj.scene.scenes[0].maplayers[key].displayHeight = this.rcvarsservice.activemap['map']['h'] * 32;
          this.rcvarsservice.gameobj.scene.scenes[0].maplayers[key].setOrigin(0, 0);
          this.rcvarsservice.gameobj.scene.scenes[0].maplayers[key].texture.setFilter(Phaser.Textures.FilterMode.NEAREST);
          ///peace add extras
          this.rcvarsservice.gameobj.scene.scenes[0].extradestroylist.push(this.rcvarsservice.gameobj.scene.scenes[0].maplayers[key]);
          //this.rcvarsservice.gameobj.scene.scenes[0].maplayers[key].x = this.rcvarsservice.gameobj.scene.scenes[0].maplayers[key].y = 16;
          //this.plog.log("xxxxxmaplayers",  this.rcvarsservice.gameobj.scene.scenes[0].maplayers[key]);
          //tmp.setTint( 0x0000ff, 0xffffff, 0xff0000);

          //tmp.setDepth(localzindex);
          //this.plog.log(key,localzindex);
          this.rcvarsservice.gameobj.scene.scenes[0].maplayers[key].name = 'maplayer-' + key;
          //this.plog.log("adding maplayers", tmp, this.rcvarsservice.gameobj.scene.scenes[0].maplayers);
          //this.rcvarsservice.gameobj.scene.scenes[0].maplayers[key] = tmp;

          //this.plog.log("adding maplayers a", tmp, this.rcvarsservice.gameobj.scene.scenes[0].maplayers)
          this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.add(
            this.rcvarsservice.gameobj.scene.scenes[0].maplayers[key]
          );
          //this.plog.log(this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer);
          this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.sendToBack(
            this.rcvarsservice.gameobj.scene.scenes[0].maplayers[key]
          );
          //this.plog.log("maplayer", key, tmp);
          if (key == 'bg')
            this.rcvarsservice.gameobj.scene.scenes[0].mapbgimg = this.rcvarsservice.gameobj.scene.scenes[0].maplayers[key];

          //this.plog.log("maplayer", this.rcvarsservice.activemap["map"]);
          //if (key != "bg")
          continue;
        }

      }
      if (key == 'zani' || key == 'zaninight') {
        var value = this.rcvarsservice.activemap[key];
        for (var w = 1; w <= mapw; w++) {
          for (var h = 1; h <= maph; h++) {
            var cellvalue = this.rcvarsservice.activemap[key][w + '-' + h];
            if (cellvalue != undefined) {
              var cellvaluea = cellvalue.split('_');
              var thisanims = this.rcvarsservice.allanims[cellvalue];
              if (thisanims == undefined) {
                this.plog.log('thisanims notfound ERROR', thisanims);
                continue;
              }
              //this.plog.log("zani cellvaluea1", cellvalue, this.rcvarsservice.allanims);
              //this.plog.log('zani cellvaluea', cellvalue, cellvaluea);
              //this.plog.log('zani', thisanims);
              //this.plog.log("zani2", this.rcvarsservice.anims);
              //this.plog.log("zani2", this.rcvarsservice.anims[cellvalue]);
              var tmpframe =
                await this.rcvarsservice.gameobj.anims.generateFrameNames(
                  cellvaluea[0] + '',
                  {
                    prefix: cellvaluea[1] + '-',
                    start: 1,
                    end: thisanims['num'],
                    zeroPad: 0,
                  }
                );
              //this.plog.log('zani frames for ' + cellvalue, tmpframe, thisanims, this.rcvarsservice.gameobj.anims);
              if (this.wh.anims.anims.entries[cellvalue] == undefined) {

                var tmpf = await this.rcvarsservice.gameobj.anims.create({
                  key: cellvalue,
                  frames: tmpframe,
                  frameRate: thisanims['fr'],
                  repeat: -1,
                });
              }
              //peacepos
              // var tmpaniposx=((w-1)*32)+this.rcvarsservice.gameobj.scene.scenes[0].defaultmaplayerx;
              //var tmpaniposy=((h-1)*32)+this.rcvarsservice.gameobj.scene.scenes[0].defaultmaplayery;
              var tmpaniposx = ((w - 1) * 32);//-this.rcvarsservice.gameobj.scene.scenes[0].defaultmaplayerx;
              var tmpaniposy = ((h - 1) * 32);//-this.rcvarsservice.gameobj.scene.scenes[0].defaultmaplayery;
              let tmp = this.rcvarsservice.gameobj.scene.scenes[0].add.sprite(
                tmpaniposx,
                tmpaniposy,
                cellvalue
              );
              if (thisanims['playon'] != 'stepon') {
                try {
                  tmp.play(cellvalue);
                } catch (e) {
                  this.plog.log("ERROR! playerror ", e);
                }
              } else {
                tmp.play(cellvalue).stop().visible = false;
              }
              this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.add(tmp);
              tmp.setPosition(tmpaniposx, tmpaniposy);
              tmp.displayWidth = 32 * thisanims['size'];
              tmp.scaleY = tmp.scaleX;
              tmp.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);
              //this.plog.log("rcvarsservice", this.rcvarsservice);
              this.rcvarsservice.gameobj.scene.scenes[0].zsortlist.push(tmp);
              if (key == 'zaninight') {
                tmp.name = 'zaninight--' + w + '-' + h + '--' + cellvalue;
                //this.plog.log("this.rcvarsservice.gameobj.", this.rcvarsservice.gameobj);
                this.rcvarsservice.gameobj.scene.scenes[0].zaninightlist.push(tmp);
                this.rcvarsservice.gameobj.scene.scenes[0].extradestroylist.push(tmp);

                /*var tmplight=this.rcvarsservice.gameobj.scene.scenes[0].add.image(w * 32, h * 32, "light").setBlendMode(Phaser.BlendModes.DARKEN).setAlpha(0.5);;
               
               // Create a Light at [400, 300] with a radius of 200
                this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.add(tmplight);
               //this.plog.log(this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer);
               this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.bringToTop(tmplight);*/
              } else {
                tmp.name = 'zani--' + w + '-' + h + '--' + cellvalue;
              }

            }
          }
        }
      }
      // this.plog.log("xxxpeace1");

      if (key == 'npc' && this.rcvarsservice.activemap[key] != undefined) {
        //this.plog.log("npc mapservice", this.rcvarsservice.npc);
        var value = this.rcvarsservice.activemap[key];
        for (var w = 1; w <= mapw; w++) {
          for (var h = 1; h <= maph; h++) {
            var cellvalue = this.rcvarsservice.activemap[key][w + '-' + h];
            if (cellvalue != undefined) {
              var thisanims = this.rcvarsservice.npc[cellvalue]['mapsprite']; //this.rcvarsservice.anims[cellvalue];
              var thissprite = this.rcvarsservice.allanims[thisanims];
              var npcdata = this.rcvarsservice.npc[cellvalue];

              //this.plog.log("npc cellvaluea", key, cellvalue, thisanims, thissprite);
              //this.plog.log("npc thisanims", thisanims);
              var tmpthisanims = thisanims + '';
              var thisanimsa = tmpthisanims.split('_');
              //console.error(thisanimsa);
              if (this.rcvarsservice.gameobj.textures.game.textures.list[thisanimsa[0]] == undefined) {
                this.plog.warn("zani xx skip NPC, whole textures not found", thisanimsa[1] + '-1');
                continue;
              }

              //this.plog.log('zani xx', this.rcvarsservice.gameobj, thisanims, thissprite);
              if (this.rcvarsservice.gameobj.textures.game.textures.list[thisanimsa[0]].frames[thisanimsa[1] + '-1'] == undefined) {
                //this.plog.log("zani xx skip NPC, textures not found", thisanimsa[1] + '-1');
                continue;
              }
              //this.plog.log("zani2", this.rcvarsservice.anims);
              //this.plog.log("zani2", this.rcvarsservice.anims[cellvalue]);
              var tmpframe =
                this.rcvarsservice.gameobj.anims.generateFrameNames(
                  thisanimsa[0] + '',
                  {
                    prefix: thisanimsa[1] + '-',
                    start: 1,
                    end: thissprite['num'],
                    zeroPad: 0,
                  }
                );
              //this.plog.log('zani frames for ' + cellvalue, tmpframe);
              //this.plog.log('zani frames for ' + cellvalue + ' (tmpf)', tmpf);
              if (tmpframe.frames != undefined && tmpframe.frames.length == 0) {
                console.error("skip frames not found, NPC", cellvalue);
                continue;
              }
              //console.error(this);
              try {
                if (this.wh.anims.anims.entries[thisanimsa[0]] == undefined) {
                  var tmpf = this.rcvarsservice.gameobj.anims.create({
                    key: thisanimsa[0], ///cellvalue
                    frames: tmpframe,
                    frameRate: 1,
                    repeat: -1,
                  });
                }
              } catch (e) {
                this.plog.warn(e);
              }
              if (thisanimsa[0] == 'chests1') {
                //create another anims for opened chest
                var localchestopenedsrpite =
                  (thisanimsa[1] + '').substring(
                    0,
                    (thisanimsa[1] + '').length - 1
                  ) + 'o';
                //this.plog.log('chest opened', thisanims, localchestopenedsrpite);
                //this.plog.log('chest cellvalue', cellvalue);
                var tmpframe =
                  this.rcvarsservice.gameobj.anims.generateFrameNames(
                    thisanimsa[0] + '',
                    {
                      prefix: localchestopenedsrpite + '-',
                      start: 1,
                      end: thissprite['num'], //same length as c sprite
                      zeroPad: 0,
                    }
                  );
                //this.plog.log("zani frames for " + cellvalue, tmpframe);
                var tmpf = this.rcvarsservice.gameobj.anims.create({
                  key: cellvalue + 'o',
                  frames: tmpframe,
                  frameRate: 1,
                  repeat: -1,
                });
              }
              if (thisanimsa[0].indexOf('bushreward') != -1) {
                //preload other state for bushreward
                var actarr = ['a', 'b'];
                for (let index = 0; index <= 1; index++) {
                  var tmpaniv = actarr[index];
                  // this.plog.log('create anims for bushreward', thisanimsa, tmpaniv);
                  var tmpframe =
                    this.rcvarsservice.gameobj.anims.generateFrameNames(
                      thisanimsa[0] + '',
                      {
                        prefix: tmpaniv + '-',
                        start: 1,
                        end: 1, //same length as c sprite
                        zeroPad: 0,
                      }
                    );
                  //this.plog.log("zani frames for " + cellvalue, tmpframe);
                  try {
                    if (this.wh.anims.anims.entries[thisanimsa[0] + '_' + tmpaniv] == undefined) {
                      var tmpf = this.rcvarsservice.gameobj.anims.create({
                        key: thisanimsa[0] + '_' + tmpaniv,
                        frames: tmpframe,
                        frameRate: 1,
                        repeat: -1,
                      });
                    }
                  } catch (e) {
                    this.plog.warn(e);
                  }
                }
              }
              if (thisanimsa[0] == 'farmplot' || thisanimsa[0] == 'pnnpcfarmplot') {
                farmplotexists2 = true;
              }
              await this.initmap_preloaddone_genstdnpcsprite(thisanimsa);

              //this.plog.log('xxxxxxxxxxxxx npc', thisanims, thissprite, cellvalue, thisanimsa);
              var repeatdelayuse = 2000;
              if (thisanims == "partyflag_b") repeatdelayuse = 0;
              var tmpnpcx = (w - 1) * 32;
              var tmpnpcy = (h - 1) * 32;
              //this.plog.error("npcsprite", cellvalue, thisanimsa[0])
              let tmp = this.rcvarsservice.gameobj.scene.scenes[0].add
                .sprite(tmpnpcx, tmpnpcy, cellvalue)
                .play({ key: thisanimsa[0], frameRate: thissprite["fr"], repeatDelay: repeatdelayuse }); ///cellvalue
              //npc mapsprite size
              tmp.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);
              tmp.displayWidth = thissprite['size'] * 32;
              tmp.scaleY = tmp.scaleX;
              tmp.name = 'npc-' + cellvalue + '-' + w + '-' + h + '-' + this.rcvarsservice.activemap['map']['code'];


              //this.plog.error('npcsprite.name', tmp.name);
              //this.plog.log('zani npcsprite', tmp);
              this.rcvarsservice.gameobj.scene.scenes[0].npclist[tmp.name] = tmp;

              if (npcdata['shownpcmark'] == 'YES') {
                var tmpmapname = this.rcvarsservice.gameobj.scene.scenes[0].add.text(0, 0, "" + npcdata["name"], this.rcvarsservice.gameobj.scene.scenes[0].fs["npcmapname"]);
                this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.add(tmpmapname);
                this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.bringToTop(tmpmapname);
                this.rcvarsservice.gameobj.scene.scenes[0].extradestroylist.push(tmpmapname);

                var tmpmark = this.rcvarsservice.gameobj.scene.scenes[0].add.image(
                  Number(w - 1) * 32,
                  (Number(h - 1) - 1) * 32 - (tmp.displayHeight / 2),
                  'npcmark'
                );
                //this.plog.log("npcmarknpcdata",npcdata);
                var npcspriteheight = 64;
                if (npcdata['clicksize'] == 'char') {
                  tmp.displayWidth = 64;
                  tmp.displayHeight = 64;
                  //tmp.setPosition(tmpitem.x, tmpitem.y);
                  //tmp.setPosition(tmpitem.x, tmpitem.y);
                } else {
                  //this.plog.log('npcdata', npcdata['mapsprite'], npcdata, tmp.scene.anims.anims.entries[npcdata['mapsprite']]);
                  //this.plog.log('npcdata', npcdata, tmp.scene.anims.anims.entries);
                  //this.plog.log('mapspritedata', mapspritedata);
                  var npcclicksize = npcdata['clicksize'] + '';
                  var npcclicksizea = npcclicksize.split('x');
                  tmp.displayWidth = Number(npcclicksizea[0]) * 32 * 0.9;
                  tmp.displayHeight = Number(npcclicksizea[1]) * 32 * 0.9;
                  //tmp.setOrigin(tmp.scene.anims.anims.entries[mapspritedata[0]].frames[0].frame.pivotX, tmp.scene.anims.anims.entries[mapspritedata[0]].frames[0].frame.pivotY);
                  //this.plog.log('npc clickspacer', npcclicksizea, npcdata);
                  //tmp.y = tmp.y + tmp.scene.anims.anims.entries[mapspritedata[0]].frames[0].frame.centerY;
                  //this.plog.log('npc clickspacer frame ', tmp.scene.anims.anims.entries[mapspritedata[0]].frames[0].frame);
                }
                this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.add(tmpmark);
                this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.bringToTop(tmpmark);
                this.rcvarsservice.gameobj.scene.scenes[0].extradestroylist.push(tmpmark);
                tmpmark.displayWidth = 12;
                tmpmark.displayHeight = 12;
                this.rcvarsservice.gameobj.scene.scenes[0].npclist[tmp.name].tmpmark = tmpmark;

                tmpmapname.setPosition(tmpmark.x - (tmpmapname.displayWidth / 2), tmpmark.y - 24);
                this.rcvarsservice.gameobj.scene.scenes[0].npclist[tmp.name].tmpmapname = tmpmapname;

                var tween = this.rcvarsservice.gameobj.scene.scenes[0].tweens.add({
                  targets: tmpmark,
                  y: tmpmark.y - 5,
                  ease: 'Power1',
                  duration: 1000,
                  yoyo: true,
                  repeat: -1,
                });
                tmpmark.setInteractive();
                tmpmark.removeAllListeners();
                tmpmark.name = tmp.name + "";
                tmpmark.on('pointerdown', function () {
                  if (arguments[0].downElement.tagName != 'CANVAS') return;
                  this.scene.registry.list['thethis'].phm.npcclick(this);
                });
              }
              //this.plog.log('chest status', this.rcvarsservice.gameobj.scene.scenes[0].cheststatus);
              if (
                this.rcvarsservice.gameobj.scene.scenes[0].cheststatus[cellvalue] == 'yes'
              ) {
                tmp.play(cellvalue + 'o');
                tmp.displayWidth = thissprite['size'] * 32;
                tmp.scaleY = tmp.scaleX;

              }
              //this.plog.log('bushrewardstatus', thisanimsa, this.rcvarsservice.gameobj.scene.scenes[0].bushrewardstatus);
              if (
                this.rcvarsservice.gameobj.scene.scenes[0].bushrewardstatus[tmp.name] == 'yes'
              ) {
                tmp.play(thisanimsa[0] + '_b');
                tmp.displayWidth = thissprite['size'] * 32;
                tmp.scaleY = tmp.scaleX;
              }
              //this.plog.log("npcsize", thissprite, tmp);
              //this.plog.log("bushreward status", this.rcvarsservice.gameobj.scene.scenes[0].bushrewardstatus,thisanimsa);

              tmp.currentx = w; tmp.currenty = h;

              //this.plog.log("rcvarsservice", this.rcvarsservice);
              //this.plog.log("npcdata after", tmp);
              //this.plog.log("rcvarsservice npcdata", cellvalue,thisanims,npcdata);
              //if (npcdata["zsort"] != "floor") {
              this.rcvarsservice.gameobj.scene.scenes[0].zsortlist.push(tmp);
              //}

              this.rcvarsservice.gameobj.scene.scenes[0].extradestroylist.push(tmp);
              var charic = this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.add(tmp);
              if (npcdata['zsort'] == 'floor') {
                //this.plog.log("rcvarsservice npcdata", this.rcvarsservice.gameobj.scene.scenes[0].char_main,tmp);
                tmp.zsortfloorlevel = true;
                //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.moveBelow(this.rcvarsservice.gameobj.scene.scenes[0].char_main, tmp);
                //tmp.setDepth(3001);//bg3=3000
              }

              //calculate npc inputitem
              //this.plog.log('npcdata', npcdata); 
              if (npcdata['pcode'] == 'buildable') {
                this.plog.log('buildable ..buildable', npcdata, tmp.name);
                this.rcvarsservice.gameobj.scene.scenes[0].registry.list.gameitf.createbuildablepanel(
                  this.rcvarsservice.gameobj.scene.scenes[0],
                  tmp.name
                );

              }
              if (
                npcdata['inputitem'] != null &&
                npcdata['inputitem'] != undefined
              ) {
                var npcdatainputitem: any = npcdata['inputitem'];
                var ccinputitem = 0;
                for (var k in npcdatainputitem) ccinputitem++;
                if (ccinputitem > 0) {
                  this.plog.log('inputitem npcdata', npcdata);
                  this.plog.log('inputitem', ccinputitem, npcdatainputitem);
                  //init inputbox
                  this.rcvarsservice.inputitem =
                    await this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rchttp.getcommon2(
                      'inputitem',
                      { mode: 'get' }
                    );
                  /*await this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rchttp.getcommon2(
                      'inputitem',
                      { code: npcdata['code'] }
                    );*/
                  this.plog.log(
                    'inputitem ..inputitem',
                    this.rcvarsservice.inputitem
                  );
                  await this.rcvarsservice.gameobj.scene.scenes[0].registry.list.gameitf.createinputitem(
                    this.rcvarsservice.gameobj.scene.scenes[0],
                    tmp.name
                  );
                }
              }
              tmp.removeAllListeners();
              if (thisanimsa[0] == "spacer") {
                tmp.setInteractive();
              } else {
                tmp.setInteractive({ pixelPerfect: true });
              }
              tmp.on('pointerdown', function () {

                if (arguments[0].downElement.tagName != 'CANVAS') return;
                this.scene.registry.list['thethis'].phm.npcclick(this);
              });
              if (cellvalue.indexOf("shareboss") != -1) {
                this.mapitemeffect(tmp, "magicbg_b", 199)
              }
            }
          }
        }
      }
    }
    //handle map click s
    //this.plog.log("hmc", this.rcvarsservice.gameobj.scene.scenes[0].maplayers)
    //          this.rcvarsservice.gameobj.scene.scenes[0].maplayers[key].displayWidth = this.rcvarsservice.activemap['map']['w'] * 32;
    if (this.rcvarsservice.gameobj.scene.scenes[0].maplayers["bg"] != undefined && this.rcvarsservice.gameobj.scene.scenes[0].maplayers["bg"] != "") {
      this.rcvarsservice.gameobj.scene.scenes[0].maplayers["bg"].removeAllListeners();
      try {
        this.rcvarsservice.gameobj.scene.scenes[0].maplayers["bg"].setInteractive();
      } catch (e) {
        await this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rctoast.alert(
          this.rcvarsservice.gameobj.scene.scenes[0].gl('Something Went Wrong'),
          this.rcvarsservice.gameobj.scene.scenes[0].gl('Map is not ready (') +
          this.rcvarsservice.activemap['map']['name'] +
          ')',
          this.rcvarsservice.gameobj.scene.scenes[0].gl(' \n\nWe will send you back to hometown (Peacely Ville)\nYou may need to restart the game (or reload the page).')
        );
        this.plog.error('map is not ready (cant setInteractive for BG)', this);

        await this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rchttp.getcommon2("warpbackmapnotfound");
        await this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rchttp.updatecharinfo();
        this.rcvarsservice.gameobj.scene.scenes[0].registry.list.gameitf.charinfoupdated(this.rcvarsservice.gameobj.scene.scenes[0]);
        this.rcvarsservice.gameobj.scene.scenes[0].chkwarppos_warp(this.rcvarsservice.gameobj.scene.scenes[0], "map1");
        await this.wh.registry.list.rchttp.updatecharinfo();
        this.wh.registry.list.gameitf.charinfoupdated(this.wh);
        this.wh.chkwarppos_warp(this.wh, "map1");
        return;
      } /*
           this.rcvarsservice.gameobj.scene.scenes[0].maplayers["bg"].on('gameobjectdown', function () {
            this.plog.log("hmc gameobjectdown arg", arguments);
           },this);
      */
      this.rcvarsservice.gameobj.scene.scenes[0].maplayers["bg"].on('pointerdown', function () {
        //this.plog.log("hmc click arg", arguments);
        /*
                if (this.mapmouseinterval != undefined) clearInterval(this.mapmouseinterval);
                this.mapmouseinterval = setTimeout(() => {
                  if (this.rcvarsservice.gameobj.scene.scenes[0].game.input.activePointer.isDown == true) {
                    //still down
                    this.rcvarsservice.gameobj.scene.scenes[0].joyStick.setPosition(this.rcvarsservice.gameobj.scene.scenes[0].game.input.activePointer.x,this.rcvarsservice.gameobj.scene.scenes[0].game.input.activePointer.y);
        
                  }
                },300);
        */
        return;

      }, this);
      this.rcvarsservice.gameobj.scene.scenes[0].maplayers["bg"].on('pointerup', function () {
        var downtime = arguments[0].upTime - arguments[0].downTime;

        // console.log("bg pointerup", this.rcvarsservice.gameobj.scene.scenes[0].game.input, arguments, downtime);
        if (downtime < 250) {
          var chk = this.calculateDistance(arguments[0].downX, arguments[0].downY, arguments[0].upX, arguments[0].upY);
          //console.log("dist", chk);
          if (chk > 50) {
            var direction = "";
            direction = this.determineDirection(arguments[0].downX, arguments[0].downY, arguments[0].upX, arguments[0].upY);
            //console.log(direction);
            if (direction != "") {
              //console.log("just turning face", direction);
              this.rcvarsservice.gameobj.scene.scenes[0].char_main_lastspriteface = direction;
              var spritename = this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rcvarpass.activechar["sprite"];
              if (direction == "up") {
                this.rcvarsservice.gameobj.scene.scenes[0].char_main.play({ key: spritename + "-walk-u", repeat: 0, frameRate: 1 }, true); return;
              }
              if (direction == "down") {
                this.rcvarsservice.gameobj.scene.scenes[0].char_main.play({ key: spritename + "-walk-d", repeat: 0, frameRate: 1 }, true); return;
              }
              if (direction == "left") {
                this.rcvarsservice.gameobj.scene.scenes[0].char_main.play({ key: spritename + "-walk-l", repeat: 0, frameRate: 1 }, true); return;
              }
              if (direction == "right") {
                this.rcvarsservice.gameobj.scene.scenes[0].char_main.play({ key: spritename + "-walk-r", repeat: 0, frameRate: 1 }, true); return;
              }
              return;
            }
          }
        }
        this.rcvarsservice.gameobj.scene.scenes[0].lastmousedownobj = arguments[0];
        var tmppass = [];
        ////peacepos
        var tmppassx = Math.floor(arguments[1] / 32) + 1;
        var tmppassy = Math.floor(arguments[2] / 32) + 1;
        tmppass["name"] = "any-" + tmppassx + "-" + tmppassy;
        this.rcvarsservice.gameobj.scene.scenes[0].registry.list.phm.floorclick(tmppass, arguments);
        this.rcvarsservice.gameobj.scene.scenes[0].mapclickworking = true;

        this.wh.mapclickworking = false;
        if (this.mapmouseinterval != undefined) clearInterval(this.mapmouseinterval);

        this.wh.joyStick.setPosition(-1000, -1000);

      }, this);
      this.rcvarsservice.gameobj.scene.scenes[0].maplayers["bg"].on('pointerover', function () {
        this.rcvarsservice.gameobj.scene.scenes[0].mapclickworking = false;
        //this.plog.log("hmc over arg", arguments);
      }, this);

      this.rcvarsservice.gameobj.scene.scenes[0].maplayers["bg"].on('wheel', (pointer) => {
        //console.log(pointer, pointer.deltaY);
        //console.log(pointer, usexraw, useyraw);
        //console.log(arguments);
        var newscale = this.rcvarsservice.gameobj.scene.scenes[0].cameras.cameras[0].zoom + pointer.deltaY * 0.005;
        //console.log(newscale);
        if (newscale < 0.4) newscale = 0.4;
        if (newscale > 1.5) newscale = 1.5;
        //wh.worldmap2.scale = newscale;
        var tw = this.rcvarsservice.gameobj.scene.scenes[0].tweens.add({
          targets: this.rcvarsservice.gameobj.scene.scenes[0].cameras.cameras[0],
          zoom: newscale,
          ease: 'Power3',
          duration: 150,
          delay: 0,
        });

      });
      /*
      this.rcvarsservice.gameobj.scene.scenes[0].maplayers["bg"].on('pointerover', function () {
        this.plog.log("hmc over arg", arguments);
        if (
          arguments[0].isDown == true &&
          arguments[0].manager.game.scene.scenes[0].joyStick.pointer ==
          undefined
        ) {
          this.scene.registry.list['thethis'].phm.floorclick(this, arguments);
        }
      }, this);*/
      /*
      tmpitem.on('pointerdown', function (tmpitem, event) {
        //if (event.downElement.tagName!="CANVAS") return;
        //this.plog.log("bg click", this);
        //this.plog.log("bg click", tmpitem);
        //also attatch npc click
        //this.plog.log("tryflootclick",this);
        var thisname = this.name + '';
        var thisnamea = thisname.split('-');
 
        //var isalsoonnpc=this.scene.registry.list["rcvarpass"].activemap["npc"][thisnamea[1]+"-"+thisnamea[2]];
        this.scene.registry.list['thethis'].phm.floorclick(this, arguments);
      });
      tmpitem.on('pointerover', function (event) {
        //this.plog.log("pointerover",arguments[0].isDown);
        //this.plog.log("pointerover", arguments[0].manager);
        //this.plog.log("pointerover", arguments[0].manager.game.scene.scenes[0].joyStick);
        //this.plog.log("pointerover", arguments[0].manager.game.scene.scenes[0].joyStick.pointer);
        if (
          arguments[0].isDown == true &&
          arguments[0].manager.game.scene.scenes[0].joyStick.pointer ==
          undefined
        ) {
          this.scene.registry.list['thethis'].phm.floorclick(this, arguments);
        }
      });*/
    } else {
      console.error("hmc can't find bg", this.rcvarsservice.gameobj.scene.scenes[0].maplayers);
    }


    //handle map click e
    if (farmplotexists2 == true) {

      //preload other state for farmplot
      // this.plog.log("preload other state for farmplot")
      //xxxpeace
      // var actarr = ['a', 'b', 'c', 'd', 'e'];
      var actarr = ['farmplot_tomatoa', 'farmplot_tomatob', 'farmplot_tomatoc', 'farmplot_tomatod', 'farmplot_tomatoe', 'farmplot_cabbagea', 'farmplot_cabbageb', 'farmplot_cabbagec', 'farmplot_cabbaged', 'farmplot_cabbagee', 'farmplot_carrota', 'farmplot_carrotb', 'farmplot_carrotc', 'farmplot_carrotd', 'farmplot_carrote', 'farmplot_corna', 'farmplot_cornb', 'farmplot_cornc', 'farmplot_cornd', 'farmplot_corne', 'farmplot_cucumbera', 'farmplot_cucumberb', 'farmplot_cucumberc', 'farmplot_cucumberd', 'farmplot_cucumbere', 'farmplot_eggplanta', 'farmplot_eggplantb', 'farmplot_eggplantc', 'farmplot_eggplantd', 'farmplot_eggplante', 'farmplot_greenbeansa', 'farmplot_greenbeansb', 'farmplot_greenbeansc', 'farmplot_mushroomd', 'farmplot_mushroome', 'farmplot_mushrooma', 'farmplot_mushroomb', 'farmplot_radisha', 'farmplot_radishb', 'farmplot_radishc', 'farmplot_radishd', 'farmplot_radishe', 'farmplot_strawberrya', 'farmplot_strawberryb', 'farmplot_strawberryc', 'farmplot_strawberryd', 'farmplot_strawberrye', 'farmplot_b', 'farmplot_a', 'farmplot_c', 'farmplot_d', 'farmplot_e', 'farmplot_mushroomc', 'farmplot_greenbeansd', 'farmplot_greenbeanse'];
      for (let index = 0; index <= actarr.length; index++) {
        var tmpaniv = actarr[index];
        if (tmpaniv == undefined) continue;
        var tmpaniva = tmpaniv.split("_");
        //this.plog.log('fff create anims for farmplot1', thisanimsa, tmpaniv);
        var tmpframe;
        try {
          tmpframe =
            this.rcvarsservice.gameobj.anims.generateFrameNames(
              'farmplot',
              {
                prefix: tmpaniva[1] + '-',
                start: 1,
                end: 1, //same length as c sprite
                zeroPad: 0,
              }
            );
        } catch (e) {
          this.plog.warn("ERROR!!xxxxpeace", e);
        }
        //this.plog.log("fff farmplot frames for " + tmpaniv, tmpframe);
        try {
          //this.plog.log("zani frames for " + cellvalue, tmpframe);
          if (this.wh.anims.anims.entries[tmpaniv] == undefined) {

            var tmpf = this.rcvarsservice.gameobj.anims.create({
              key: '' + tmpaniv,
              frames: tmpframe,
              frameRate: 1,
              repeat: -1,
            });
          }
        } catch (e) {
          this.plog.warn("ERROR!!xxxxpeace", e);
        }

      }
    }
    var tmpmetrix = [];
    var tmpmetrixmob = [];
    var tmpmetrixmobx = [];
    var tmpallmetrix = await this.buildmapmetrix(this.rcvarsservice.gameobj.scene.scenes[0]);
    tmpmetrix = tmpallmetrix[0];
    tmpmetrixmob = tmpallmetrix[1];
    tmpmetrixmobx = tmpallmetrix[2];
    this.plog.log("buildmapmetrix", tmpallmetrix);

    this.rcvarsservice.activemapmetrix = tmpmetrix;
    this.rcvarsservice.activemapmetrixmob = tmpmetrixmob;
    this.rcvarsservice.activemapmetrixmobx = tmpmetrixmobx;

    this.updatenpcbribedsp(this.rcvarsservice.gameobj.scene.scenes[0]);
    //loop bg for click-walk
    /* remove since hmc
    for (var item in this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer
      .list) {
      var tmpitem =
        this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list[item];
      //this.plog.log("ctitems", item, tmpitem);
      var tmpnamea = tmpitem.name.split('-');
      if (tmpnamea[0] == 'walkable') {
        this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.sendToBack(
          tmpitem
        );
        tmpitem.setInteractive();
        tmpitem.on('pointerdown', function (tmpitem, event) {
          //if (event.downElement.tagName!="CANVAS") return;
          //this.plog.log("bg click", this);
          //this.plog.log("bg click", tmpitem);
          //also attatch npc click
          //this.plog.log("tryflootclick",this);
          var thisname = this.name + '';
          var thisnamea = thisname.split('-');
 
          //var isalsoonnpc=this.scene.registry.list["rcvarpass"].activemap["npc"][thisnamea[1]+"-"+thisnamea[2]];
          this.scene.registry.list['thethis'].phm.floorclick(this, arguments);
        });
        tmpitem.on('pointerover', function (event) {
          //this.plog.log("pointerover",arguments[0].isDown);
          //this.plog.log("pointerover", arguments[0].manager);
          //this.plog.log("pointerover", arguments[0].manager.game.scene.scenes[0].joyStick);
          //this.plog.log("pointerover", arguments[0].manager.game.scene.scenes[0].joyStick.pointer);
          if (
            arguments[0].isDown == true &&
            arguments[0].manager.game.scene.scenes[0].joyStick.pointer ==
            undefined
          ) {
            this.scene.registry.list['thethis'].phm.floorclick(this, arguments);
          }
        });
      }
    }*/
    //nightlight chk storage value


    //tooltip functions
    if (this.rcvarsservice.activemap['tooltip'] != undefined) {
      for (var key in this.rcvarsservice.activemap['tooltip']) {
        var keya = key.split('-');

        let tmp = this.rcvarsservice.gameobj.scene.scenes[0].add.image(
          Number(keya[0]) * 32,
          Number(keya[1]) * 32,
          'spacer'
        );
        tmp.width = 32;
        tmp.height = 32;
        tmp.setOrigin(0.5, 0.5);
        tmp.setInteractive();
        tmp.name = 'tt_' + key;
        tmp.on('pointerdown', function (tmpitem) {
          //this.scene.registry.list["thethis"].phm.floorclick(this);
          //this.plog.log("ttclick", this);
          var ttid = this.name;
          ttid = ttid.replace('tt_', '');
          //this.plog.log("ttid", ttid, arguments, this);
          var txt =
            this.scene.registry.list.rcvarpass.activemap['tooltip'][ttid];
          this.scene.registry.list.phm.charsaybubble(this.scene.registry.list.phaserGame.scene.scenes[0].char_main, txt);

        });
        this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.add(tmp);
        //this.plog.log("tt", key, tmp);
      }
    }

    //spawn
    //spawn point
    var spawnpos; // = this.rcvarsservice.activemap["spawn"];
    for (var key in this.rcvarsservice.activemap['spawn']) {
      if (this.rcvarsservice.activemap['spawn'][key] == 'yn_y') {
        spawnpos = key;
      }
      //this.rcvarsservice.activemap["spawn"][key];
    }
    //npc click
    /*
    for (var item in this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer
      .list) {
      var tmpitem =
        this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list[item];
      //this.plog.log("ctitems", item, tmpitem);
      var tmpnamea = tmpitem.name.split('-');
      if (tmpnamea[0] == 'npc') {
        //this.plog.log('phmscanningnpc', tmpitem.name, tmpnamea);
        //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.sendToBack(tmpitem);
        var keyanpc = tmpitem['name'].split('-');
 
        let tmp = this.rcvarsservice.gameobj.scene.scenes[0].add.image(
          Number(keyanpc[2]) * 32,
          (Number(keyanpc[3]) - 1) * 32,
          'spacer'
        );
        var npcdata = this.rcvarsservice.npc[keyanpc[1]];
        var mapspritedata = (npcdata['mapsprite'] + '').split('_');
        //this.plog.log('copynpcspriteinfo', keyanpc, npcdata, tmp.scene.anims.anims.entries[mapspritedata[0]].frames[0].frame);
        tmp.name = tmpitem.name;
        this.plog.error('npcbox.name', tmp.name);
 
        tmp.currentx = tmpitem.currentx;
        tmp.currenty = tmpitem.currenty;
        tmp.setAlpha(0.01);
        tmp.setOrigin(
          tmp.scene.anims.anims.entries[mapspritedata[0]].frames[0].frame
            .pivotX,
          tmp.scene.anims.anims.entries[mapspritedata[0]].frames[0].frame.pivotY
        );
 
        if (npcdata['clicksize'] == 'char') {
          tmp.displayWidth = 32;
          tmp.displayHeight = 64;
          tmp.setPosition(tmpitem.x, tmpitem.y);
          tmp.setPosition(tmpitem.x, tmpitem.y);
        } else {
          //this.plog.log('npcdata', npcdata['mapsprite'], npcdata, tmp.scene.anims.anims.entries[npcdata['mapsprite']]);
          //this.plog.log('npcdata', npcdata, tmp.scene.anims.anims.entries);
          //this.plog.log('mapspritedata', mapspritedata);
          var npcclicksize = npcdata['clicksize'] + '';
          var npcclicksizea = npcclicksize.split('x');
          tmp.displayWidth = Number(npcclicksizea[0]) * 32 * 0.9;
          tmp.displayHeight = Number(npcclicksizea[1]) * 32 * 0.9;
          //tmp.setOrigin(tmp.scene.anims.anims.entries[mapspritedata[0]].frames[0].frame.pivotX, tmp.scene.anims.anims.entries[mapspritedata[0]].frames[0].frame.pivotY);
          //this.plog.log('npc clickspacer', npcclicksizea, npcdata);
          //tmp.y = tmp.y + tmp.scene.anims.anims.entries[mapspritedata[0]].frames[0].frame.centerY;
          //this.plog.log('npc clickspacer frame ', tmp.scene.anims.anims.entries[mapspritedata[0]].frames[0].frame);
          tmp.setPosition(tmpitem.x, tmpitem.y);
        }
        tmp.setInteractive();
 
        //tmp.setPosition(Number(keyanpc[2]) * 32, (Number(keyanpc[3])) * 32);
 
        //this.plog.log('npc clickspacer', tmp);
        this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.add(tmp);
        this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.bringToTop(tmp);
 
        tmp.on('pointerdown', function () {
          //this.plog.log("bg click", this);
          //this.plog.log("npc pointerdown (spacer proxy)", this);
          if (arguments[0].downElement.tagName != 'CANVAS') return;
          this.scene.registry.list['thethis'].phm.npcclick(this);
          //this.plog.log("zxc ",arguments);
        });
        this.rcvarsservice.gameobj.scene.scenes[0].npclist[tmpitem.name].tmpclickbox = tmp;
 
        /*        tmpitem.on('pointerover', function (tmpitem) {
                  //this.plog.log("bg click", this);
                  //this.plog.log("bg click", tmpitem);
                  this.setAlpha(0.5);
                });* /
      }
    }*/

    //open npc chests
    this.plog.log(
      'chestsstatus',
      this.rcvarsservice.gameobj.scene.scenes[0].cheststatus
    );
    for (var key in this.rcvarsservice.activemap['npc']) {
      var valchestsstatus = this.rcvarsservice.activemap['npc'][key];
      if (
        this.rcvarsservice.gameobj.scene.scenes[0].cheststatus[
        valchestsstatus
        ] == 'yes'
      ) {
        this.plog.log('chestsstatus opening chest ', valchestsstatus);
      }
    }

    if (this.rcvarsservice.activechar.warpfrommap != undefined) {
      //warp from warp gate, set position to gate to that gate+1
      for (var key in this.rcvarsservice.activemap['zwarp']) {
        if (
          this.rcvarsservice.activemap['zwarp'][key] ==
          this.rcvarsservice.activechar.warpfrommap
        ) {
          spawnpos = key;
        }
        //this.rcvarsservice.activemap["spawn"][key];
      }
      //this.plog.log(        'warpfrommap',        this.rcvarsservice.activechar.warpfrommap,        spawnpos,        this.rcvarsservice.activemap['zwarp']      );
      this.rcvarsservice.activechar.warpfrommap = undefined;
    }

    //map border
    var mapbdcolor = this.rcvarsservice.activemap['map']['mapbdcolor'];
    var mapbdl = this.rcvarsservice.gameobj.scene.scenes[0].add
      .image(16, 16, 'border-' + mapbdcolor + '-l')
      .setAlpha(0);
    this.rcvarsservice.gameobj.scene.scenes[0].mapbdl = mapbdl;
    var mapbdr = this.rcvarsservice.gameobj.scene.scenes[0].add
      .image(16, 16, 'border-' + mapbdcolor + '-r')
      .setAlpha(0);
    this.rcvarsservice.gameobj.scene.scenes[0].mapbdr = mapbdr;
    var mapbdd = this.rcvarsservice.gameobj.scene.scenes[0].add
      .image(16, 16, 'border-' + mapbdcolor + '-d')
      .setAlpha(0);
    this.rcvarsservice.gameobj.scene.scenes[0].mapbdd = mapbdd;
    var mapbdu = this.rcvarsservice.gameobj.scene.scenes[0].add
      .image(16, 16, 'border-' + mapbdcolor + '-u')
      .setAlpha(0);
    this.rcvarsservice.gameobj.scene.scenes[0].mapbdu = mapbdu;



    await this.rcvarsservice.gameobj.scene.scenes[0].addinterface();
    await this.initmap_preloaddone_initfow();

    await this.spawnmainchar(spawnpos);

    await this.spawnmob();
    await this.spawncritter();
    await this.spawnmapwalker();
    if (this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rcvarpass.activemap['map']['isoutside'] == "YES") {
      await this.spawnbird();
    }
    //this.updatezmap();
    this.rcvarsservice.gameobj.scene.scenes[0].registry.list.eggnest.chkmapdsp(
      this.rcvarsservice.gameobj.scene.scenes[0]
    );
    this.rcvarsservice.gameobj.scene.scenes[0].registry.list.farmplot.chkmapdsp(
      this.rcvarsservice.gameobj.scene.scenes[0]
    );
    this.initmap_preloaddone_sortlayer_mapdata();
    this.initmap_preloaddone_sortlayer();//.bind(this);

    // this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.moveBelow(this.rcvarsservice.gameobj.scene.scenes[0].char_main, this.rcvarsservice.gameobj.scene.scenes[0].maplayers["fg"]); 
    //this.plog.log('maplayers a', this.rcvarsservice.gameobj.scene.scenes[0].maplayers);
    //lets create map!

    this.rcvarsservice.gameobj.scene.scenes[0].registry.list.resizeman.RCMapResizeManager_work(
      this.rcvarsservice.gameobj.scene.scenes[0]
    );
    await this.wh.cameras.cameras[0].zoomTo(0.5, 0);
    //console.log("zooming camera after map load");

    await this.wh.registry.list.timectl.timechange('', this.wh);
    await this.wh.registry.list.rctoast.sleep(50);
    await this.wh.cameras.cameras[0].zoomTo(1, 1000);

    //this.plog.log('updatezmap i', tmp);
    //this.updatezmap();
    //effectlist

    //var cwidth =      this.rcvarsservice.gameobj.scene.scenes[0].maplayers['bg'].displayWidth;
    //var cheight =      this.rcvarsservice.gameobj.scene.scenes[0].maplayers['bg'].displayHeight;

    var effectlist = this.rcvarsservice.activemap['map']['effectlist'];
    var effectlista = effectlist.split(',');
    //this.plog.log('effectlist map', this.rcvarsservice.activemap['map']);
    //this.plog.log('effectlist game', this.rcvarsservice.gameobj);
    this.rcvarsservice.gameobj.scene.scenes[0].registry.list.weather.reset(
      this.rcvarsservice.gameobj.scene.scenes[0]
    );
    this.initmap_preloaddone_doeffect(effectlista);

    //this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rcloading.close();

    this.rcvarsservice.gameobj.scene.scenes[0].registry.list.resizeman.RCMapResizeManager_work(
      this.rcvarsservice.gameobj.scene.scenes[0]
    );

    //this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rcloading.close();
    this.rcvarsservice.gameobj.scene.scenes[0].timerseccount = 0;
    var ctt = 0;
    for (const k in this.wh.textures.list) ctt++;
    //this.plog.log('intmap_preloaddone textures', ctt, this.wh.textures.list);
    var ctt = 0;
    for (const k in this.wh.anims.anims) ctt++;
    //this.plog.log('intmap_preloaddone anims', ctt, this.wh.anims.anims);

    //await this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rctoast.sleep(5000);
    this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rcloading.close();

    if (this.rcvarsservice.gameobj.scene.scenes[0].mapname != undefined) {
      this.rcvarsservice.gameobj.scene.scenes[0].mapname.setText(
        this.rcvarsservice.activemap['map']['name']
      );
    }
    if (this.rcvarsservice.gameobj.scene.scenes[0].playerName != undefined) {
      this.rcvarsservice.gameobj.scene.scenes[0].playerName.setText(
        this.rcvarsservice.activechar['name']
      );
    }

    //screenbg s
    if (this.rcvarsservice.activemap['map']['screenbg'] != undefined &&
      this.rcvarsservice.activemap['map']['screenbg'] != ""
      //&&      this.rcvarsservice.activemap['map']['screenbg'].length != undefined &&
      //this.rcvarsservice.activemap['map']['screenbg'].length > 0
    ) {

      var width = this.rcvarsservice.gameobj.canvas.width;
      var height = this.rcvarsservice.gameobj.canvas.height;
      //this.plog.log("screenbg", this.rcvarsservice.activemap['map']['screenbg']);
      var screenbtcc = 0;
      for (const screenbgk in this.rcvarsservice.activemap['map']['screenbg']) {
        if (this.rcvarsservice.activemap['map']['screenbg'][screenbgk] != undefined) {
          var scrbv = this.rcvarsservice.activemap['map']['screenbg'][screenbgk];
          //spritetoload[this.rcvarsservice.activemap['map']['screenbg'][screenbgk]["file"]] = 1;
          //loader.image(this.rcvarsservice.activemap['map']['screenbg'][screenbgk]["file"], this.rcvarsservice.rc_baseurl + this.rcvarsservice.activemap['map']['screenbg'][screenbgk]["file"]);
          if (this.rcvarsservice.gameobj.scene.scenes[0].textures.list[scrbv["file"]] == undefined) continue;
          screenbtcc++;
          this.rcvarsservice.gameobj.scene.scenes[0].screenbg[scrbv["key"]]
            = this.rcvarsservice.gameobj.scene.scenes[0].add.tileSprite(
              0 - width / 2, 0 - height / 2,
              this.rcvarsservice.activemap['map']['w'] * 32 + width, this.rcvarsservice.activemap['map']['h'] * 32 + height, scrbv["file"]);
          this.rcvarsservice.gameobj.scene.scenes[0].screenbg[scrbv["key"]].setOrigin(0, 0);
          //var scaleh = height / this.rcvarsservice.gameobj.scene.scenes[0].textures.list[scrbv["key"]["file"]].frames.__BASE.height;
          //this.plog.log("screenbg i", scrbv, this.rcvarsservice.gameobj.scene.scenes[0].textures.list[scrbv["file"]], this.rcvarsservice.gameobj.scene.scenes[0].textures.list[scrbv["file"]]);

          //this.rcvarsservice.gameobj.scene.scenes[0].screenbg[scrbv["key"]].setScrollFactor(0, 0);
          this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.add(this.rcvarsservice.gameobj.scene.scenes[0].screenbg[scrbv["key"]]);

          //var scaleh = height / this.rcvarsservice.gameobj.scene.scenes[0].textures.list[scrbv["file"]].source[0].height;
          var scaleh = this.rcvarsservice.gameobj.scene.scenes[0].screenbg[scrbv["key"]].displayHeight / this.rcvarsservice.gameobj.scene.scenes[0].textures.list[scrbv["file"]].source[0].height;
          this.rcvarsservice.gameobj.scene.scenes[0].screenbg[scrbv["key"]].setTileScale(scaleh, scaleh);
          this.rcvarsservice.gameobj.scene.scenes[0].screenbg[scrbv["key"]].setDepth(0 - scrbv["depth"] * 2)
          //this.rcvarsservice.gameobj.scene.scenes[0].screenbg[scrbv["key"]].fixedToCamera = false;;          
          //this.rcvarsservice.gameobj.scene.scenes[0].screenbg[scrbv["key"]].setScrollFactor(0);
          //this.plog.log("screenbg depth", this.rcvarsservice.gameobj.scene.scenes[0].screenbg[scrbv["key"]].depth, this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.depth);

          this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.sendToBack(this.rcvarsservice.gameobj.scene.scenes[0].screenbg[scrbv["key"]]);

        }
      }
    }
    //screenbg e

    this.rcvarsservice.gameobj.scene.scenes[0].registry.list.tut.show(this.rcvarsservice.gameobj.scene.scenes[0], "mapload-afterlogin");


    this.updatezmapchar('usecache'); // also update zmapchar, since this function also use to load zmapchar
    this.rcvarsservice.gameobj.scene.scenes[0].registry.list.gameitf.updatenpcvisiblereq(this.rcvarsservice.gameobj.scene.scenes[0]);
    //if (this.rcvarsservice.gameobj.scene.scenes[0].gifcam != undefined)
    //  this.rcvarsservice.gameobj.scene.scenes[0].gifcam.ignore(this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer);
    await this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rchttp.updatecharinfo();
    this.initmapinprocess = false;
    //chk app update
    //console.log(this.rcvarsservice.gameobj.scene.scenes);
    if (this.appupdatechecked == false) {
      var tmpplatform = "";
      try {
        tmpplatform = this.rcvarsservice.gameobj.scene.scenes[1].platform;
      } catch (e) {
        console.log(e);
      }
      if (tmpplatform == "android") {
        try {
          var currentappversion = await this.rcvarsservice.gameobj.scene.scenes[0].registry.list.thethis.getCurrentAppVersion();
        } catch (e) {
          console.log(e);
          if (this.rcvarsservice.gameobj.scene.scenes[0].updateapp != undefined) {
            console.log("Skip check version");
          }
        }
        try {
          var availableversion = await this.rcvarsservice.gameobj.scene.scenes[0].registry.list.thethis.getAvailableAppVersion()
        } catch (e) {
          console.log(e);
          if (this.rcvarsservice.gameobj.scene.scenes[0].updateapp != undefined) {
            console.log("Skip check version");
          }
        }
        console.log("Check app version for update", currentappversion, availableversion);
        if (currentappversion != undefined && availableversion != undefined) {
          if (currentappversion != availableversion) {
            //got update
            this.charsaybubble(this.rcvarsservice.gameobj.scene.scenes[0].char_main, "There are new version available in the store");
            if (this.rcvarsservice.gameobj.scene.scenes[0].updateapp != undefined) {
              this.rcvarsservice.gameobj.scene.scenes[0].updateapp.visible = true;
            }
          }
        } else {
          if (this.rcvarsservice.gameobj.scene.scenes[0].updateapp != undefined) {
            console.log("Skip check version");
          }
        }
      } else {
        this.rcvarsservice.gameobj.scene.scenes[0].updateapp.visible = false;
      }
      this.appupdatechecked = true;
    }
  }
  async initmap_preloaddone_initfow() {


    if (this.wh.registry.list.thethis != undefined && this.wh.registry.list.thethis.chkconfiglighteffects != undefined && this.wh.registry.list.thethis.chkconfiglighteffects == "yes") {
      this.wh.registry.list.timectl.initmaplight(this.wh);

    }

    //var image = this.rcvarsservice.gameobj.scene.scenes[0].add.image(1, 1, 'red');
    //this.plog.log("image", image);

    //create fow fog of war
    var fowcol = Phaser.Display.Color.GetColor(0, 0, 0);
    //this.plog.log(this);
    var width = this.wh.game.canvas.width;
    var height = this.wh.game.canvas.height;
    this.rcvarsservice.gameobj.scene.scenes[0].foww = width;
    this.rcvarsservice.gameobj.scene.scenes[0].fowh = height;
    this.rcvarsservice.gameobj.scene.scenes[0].fow = this.rcvarsservice.gameobj.scene.scenes[0].add.renderTexture(0 - width / 2, 0 - height / 2, this.rcvarsservice.activemap['map']['w'] * 32 + width, this.rcvarsservice.activemap['map']['h'] * 32 + height);
    this.rcvarsservice.gameobj.scene.scenes[0].fowlight = this.rcvarsservice.gameobj.scene.scenes[0].add.renderTexture(0 - width / 2, 0 - height / 2, this.rcvarsservice.activemap['map']['w'] * 32 + width, this.rcvarsservice.activemap['map']['h'] * 32 + height);
    //this.rcvarsservice.gameobj.scene.scenes[0].fow = this.rcvarsservice.gameobj.scene.scenes[0].add.renderTexture(0 - 16, 0 - 16, this.rcvarsservice.activemap['map']['w'] * 32 + 16, this.rcvarsservice.activemap['map']['h'] * 32 + 16);
    this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.add(
      this.rcvarsservice.gameobj.scene.scenes[0].fow
    );
    this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.add(
      this.rcvarsservice.gameobj.scene.scenes[0].fowlight
    );
    this.rcvarsservice.gameobj.scene.scenes[0].fow.fill(fowcol, 1);
    this.rcvarsservice.gameobj.scene.scenes[0].fow.setAlpha(1);//0.8
    this.rcvarsservice.gameobj.scene.scenes[0].fow.setOrigin(0, 0);
    this.rcvarsservice.gameobj.scene.scenes[0].fowlight.setAlpha(1);//0.8
    this.rcvarsservice.gameobj.scene.scenes[0].fowlight.setOrigin(0, 0);
    if (
      this.rcvarsservice.activemap['map']['code'] == "home" ||
      this.rcvarsservice.activemap['map']['code'] == "backyard" ||
      this.rcvarsservice.activemap['map']['code'] == "backyard2" ||
      this.rcvarsservice.activemap['map']['code'] == "ship1"
    ) {
      this.rcvarsservice.gameobj.scene.scenes[0].fow.clear();
    }
    if (
      this.rcvarsservice.activemap['map']['fow'] == "NO"
    ) {
      this.rcvarsservice.gameobj.scene.scenes[0].fow.clear();
    }



    if (this.wh.registry.list.thethis != undefined && this.wh.registry.list.thethis.chkconfiglighteffects != undefined && this.wh.registry.list.thethis.chkconfiglighteffects == "yes") {

      if (this.rcvarsservice.activemap['light'] != undefined) {

        for (var key in this.rcvarsservice.activemap['light']) {
          var keya = key.split('-');
          var varlight = this.rcvarsservice.activemap['light'][key];
          var varlighta = varlight.split(':');
          //console.log("light", keya, varlighta);
          //peacepos
          var tmplightposx = (Number(keya[0]) - 1) * 32;
          var tmplightposy = (Number(keya[1]) - 1) * 32;
          /*var tmp = this.rcvarsservice.gameobj.scene.scenes[0].add.sprite(
            tmplightposx,
            tmplightposy,
            "ilight"
          );
          this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.add(tmp);
          this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.bringToTop(tmp);
          tmp.displayWidth = 64 * varlighta[0];
          tmp.scaleY = tmp.scaleX;
          this.rcvarsservice.gameobj.scene.scenes[0].lightlist.push(tmp);
          this.rcvarsservice.gameobj.scene.scenes[0].extradestroylist.push(tmp);
          //var tmpl = this.rcvarsservice.gameobj.scene.scenes[0].add.pointlight(tmplightposx, tmplightposy, 0xffffff, 32 * varlighta[0], 0.03);
          //tmpl.attenuation = 0.1;

          this.rcvarsservice.gameobj.scene.scenes[0].time.addEvent({
            delay: 100,
            callback: function () {
              //console.log("",this,arguments)
              const ellipse1 = new Phaser.Geom.Ellipse(arguments[1], arguments[2], 5, 5);

              Phaser.Geom.Ellipse.Random(ellipse1, this);
              //Phaser.Geom.Ellipse.Random(ellipse2, light2);
            }.bind(tmp, tmp, tmplightposx, tmplightposy),
            //callbackScope: tmpl,
            repeat: -1
          });
          tmp.setAlpha(0.2)

          if (varlighta[2] != '' && varlighta[2] != undefined) {
            //this.plog.log("set light color to", varlighta[2], Phaser.Display.Color.RGBStringToColor(varlighta[2]));
            tmp.setTint(
              Phaser.Display.Color.RGBStringToColor(varlighta[2]).color
            );
            //console.log(Phaser.Display.Color.RGBStringToColor(varlighta[2]), 0xffffff);
          }
          
          this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.add(tmp);
          this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.bringToTop(tmp);
          //console.log(tmpl);
          this.rcvarsservice.gameobj.scene.scenes[0].extradestroylist.push(tmp);

          var fowcol = Phaser.Display.Color.GetColor(0, 0, 0);
          */
          /*try {
            this.rcvarsservice.gameobj.scene.scenes[0].fow.erase('revealbrushlight',
              tmplightposx - this.rcvarsservice.gameobj.scene.scenes[0].fowsize + this.rcvarsservice.gameobj.scene.scenes[0].foww / 2,
              tmplightposy - this.rcvarsservice.gameobj.scene.scenes[0].fowsize + this.rcvarsservice.gameobj.scene.scenes[0].fowh / 2);
          } catch (e) {
            console.log(e);
          }*/
          //tmp.postFX.addGlow(0xffffff, 4, 0, false, 0.2, 10);
          //addPointLight(x, y, [color], [radius], [intensity], [attenuation])
          //this.rcvarsservice.gameobj.scene.scenes[0].fow.fill(fowcol,0.55); //
          ///this.rcvarsservice.gameobj.scene.scenes[0].fowlight.draw('revealbrushlight', tmplightposx, tmplightposy, 0.2, Phaser.Display.Color.RGBStringToColor(varlighta[2]).color)
          ///tmp.setBlendMode(Phaser.BlendModes.ADD);

          //
          //tmp2.enable().setAmbientColor(0x555555);
          //this.rcvarsservice.gameobj.scene.scenes[0].zsortlist.push(tmp);

          // this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.add(tmp2);
          // this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.bringToTop(tmp2);

          //tmp.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);

          //
          //tmp.zsortfloorlevel=true;
          //tmp.setBlendMode(Phaser.BlendModes.LIGHTEN);
          //tmp.setBlendMode(Phaser.BlendModes.HARD_LIGHT);
          //tmp.setBlendMode(Phaser.BlendModes.LUMINOSITY);//1
          //tmp.setBlendMode(Phaser.BlendModes.MULTIPLY);

          var tmplight = this.rcvarsservice.gameobj.scene.scenes[0].lights.addLight(
            Number(tmplightposx),
            Number(tmplightposy),
            100
          );
          if (varlighta[1] != '' && varlighta[1] != undefined) {
            //this.plog.log("set light setIntensity", Number(varlighta[1]));
            tmplight.setIntensity(Number(varlighta[1]));
          }
          if (varlighta[2] != '' && varlighta[2] != undefined) {
            //this.plog.log("set light color to", varlighta[2], Phaser.Display.Color.RGBStringToColor(varlighta[2]));
            tmplight.setColor(
              Phaser.Display.Color.RGBStringToColor(varlighta[2]).color
            );
          }
          tmplight.name = 'light-' + key;
          this.rcvarsservice.gameobj.scene.scenes[0].lightlist.push(tmplight);
          this.rcvarsservice.gameobj.scene.scenes[0].extradestroylist.push(tmplight);

          //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.add(tmp);
          // this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.bringToTop(tmp);

        }
        //this.plog.log(        'lightlist',        this.rcvarsservice.gameobj.scene.scenes[0].lightlist      );
        this.rcvarsservice.gameobj.scene.scenes[0].updatenightlightposition();
      }
    }
    if (
      this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rcvarpass.activemapreveal != undefined &&
      Array.isArray(this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rcvarpass.activemapreveal)
    ) {
      for (var rev in this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rcvarpass.activemapreveal) {
        var reva = rev.split("-");
        var revax = Number(reva[0]);
        var revay = Number(reva[1]);
        //console.log("restore revealing",rev);
        this.rcvarsservice.gameobj.scene.scenes[0].fow.erase('revealbrush', revax * 32 - this.rcvarsservice.gameobj.scene.scenes[0].fowsize - 32 + this.rcvarsservice.gameobj.scene.scenes[0].foww / 2, revay * 32 - this.rcvarsservice.gameobj.scene.scenes[0].fowsize - 32 + this.rcvarsservice.gameobj.scene.scenes[0].fowh / 2);
      }
      //wh.fow.erase('revealbrush', newcx * 32 - wh.fowsize - 32 + wh.foww / 2, newcy * 32 - wh.fowsize - 32 + wh.fowh / 2);
    }
    var fowcol = Phaser.Display.Color.GetColor(0, 0, 0);
    this.rcvarsservice.gameobj.scene.scenes[0].fow.fill(fowcol, 0.55); //

    this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.bringToTop(this.rcvarsservice.gameobj.scene.scenes[0].fow);
  }
  async fow_clear(x, y, type = "revealbrush") {
    try {

      this.rcvarsservice.gameobj.scene.scenes[0].fow.erase(type, Number(x) * 32 - this.rcvarsservice.gameobj.scene.scenes[0].fowsize - 32 + this.rcvarsservice.gameobj.scene.scenes[0].foww / 2, Number(y) * 32 - this.rcvarsservice.gameobj.scene.scenes[0].fowsize - 32 + this.rcvarsservice.gameobj.scene.scenes[0].fowh / 2);
    } catch (error) {
      this.plog.log("error fow on fow_clear", error);
    }
  }
  async initmap_preloaddone_genstdnpcsprite(thisanimsa) {
    var npcdata = this.rcvarsservice.npc[thisanimsa[0]];
    //this.plog.log("initmap_preloaddone_genstdnpcsprite", this.rcvarsservice.npc, thisanimsa)

    if (npcdata != undefined && npcdata['inputitem'] != undefined &&
      npcdata['inputitem']['selfactivateani'] != "" &&
      npcdata['inputitem']['selfactivateani'] != undefined) {
      //var tmpania = (npcdata['inputitem']['selfactivateani'] + "").split("_");
      this.rcvarsservice.gameobj.scene.scenes[0].registry.list.gameitf.genloadedanims(this.rcvarsservice.gameobj.scene.scenes[0], npcdata['inputitem']['selfactivateani']);
      //this.plog.log("initmap_preloaddone_genstdnpcsprite", this.rcvarsservice.npc, thisanimsa)

    }
    var spriteset = [
      /* { code: 'idle', framename: 'idle-d', fr: 1, end: 2 },
       { code: 'idle-d', framename: 'idle-d', fr: 3, end: 3 },
       { code: 'idle-u', framename: 'idle-u', fr: 3, end: 3 },
       { code: 'idle-l', framename: 'idle-l', fr: 3, end: 3 },
       { code: 'idle-r', framename: 'idle-r', fr: 3, end: 3 },
       { code: 'walk-d', framename: 'walk-d', fr: 16, end: 9 },
       { code: 'walk-u', framename: 'walk-u', fr: 16, end: 9 },
       { code: 'walk-l', framename: 'walk-l', fr: 16, end: 9 },
       { code: 'walk-r', framename: 'walk-r', fr: 16, end: 9 }*/
      //repalced with this big set for drama

      { code: 'idle', framename: 'walk-d', fr: 1, end: 1 },
      { code: 'walk-d', framename: 'walk-d', fr: 16, end: 9 },
      { code: 'walk-u', framename: 'walk-u', fr: 16, end: 9 },
      { code: 'walk-l', framename: 'walk-l', fr: 16, end: 9 },
      { code: 'walk-r', framename: 'walk-r', fr: 16, end: 9 },
      { code: 'thrust-d', framename: 'thrust-d', fr: 8, end: 8 },
      { code: 'thrust-u', framename: 'thrust-u', fr: 8, end: 8 },
      { code: 'thrust-l', framename: 'thrust-l', fr: 8, end: 8 },
      { code: 'thrust-r', framename: 'thrust-r', fr: 8, end: 8 },
      { code: 'spellcast-d', framename: 'spellcast-d', fr: 7, end: 7 },
      { code: 'spellcast-u', framename: 'spellcast-u', fr: 7, end: 7 },
      { code: 'spellcast-l', framename: 'spellcast-l', fr: 7, end: 7 },
      { code: 'spellcast-r', framename: 'spellcast-r', fr: 7, end: 7 },
      { code: 'slash-d', framename: 'slash-d', fr: 6, end: 6 },
      { code: 'slash-u', framename: 'slash-u', fr: 6, end: 6 },
      { code: 'slash-l', framename: 'slash-l', fr: 6, end: 6 },
      { code: 'slash-r', framename: 'slash-r', fr: 6, end: 6 },
      { code: 'shoot-d', framename: 'shoot-d', fr: 13, end: 13 },
      { code: 'shoot-u', framename: 'shoot-u', fr: 13, end: 13 },
      { code: 'shoot-l', framename: 'shoot-l', fr: 13, end: 13 },
      { code: 'shoot-r', framename: 'shoot-r', fr: 13, end: 13 },
      { code: 'atk-d', framename: 'atk-d', fr: 6, end: 6 },
      { code: 'atk-u', framename: 'atk-u', fr: 6, end: 6 },
      { code: 'atk-l', framename: 'atk-l', fr: 6, end: 6 },
      { code: 'atk-r', framename: 'atk-r', fr: 6, end: 6 },
      { code: 'walk-d-idle', framename: 'idle-d', fr: 3, end: 3 },
      { code: 'walk-u-idle', framename: 'idle-u', fr: 3, end: 3 },
      { code: 'walk-l-idle', framename: 'idle-l', fr: 3, end: 3 },
      { code: 'walk-r-idle', framename: 'idle-r', fr: 3, end: 3 },
      { code: 'idle-d', framename: 'idle-d', fr: 3, end: 3 },
      { code: 'idle-u', framename: 'idle-u', fr: 3, end: 3 },
      { code: 'idle-l', framename: 'idle-l', fr: 3, end: 3 },
      { code: 'idle-r', framename: 'idle-r', fr: 3, end: 3 },
      { code: 'dead', framename: 'dead', fr: 6, end: 6 },
    ];
    for (var idx in spriteset) {
      var valspriteset = spriteset[idx];
      //this.plog.log("loadcharsprite", idx, val);
      this.rcvarsservice.gameobj.anims.remove(thisanimsa[0] + '-' + valspriteset['code']);
      //this.plog.log('zaninpc xx', this.rcvarsservice.gameobj, thisanims, thissprite);
      if (this.rcvarsservice.gameobj.textures.game.textures.list[thisanimsa[0]].frames[valspriteset['code'] + '-1'] == undefined) {
        //this.plog.warn("zaninpc xx skip, textures not found", thisanimsa[1] + '-1', this.rcvarsservice.gameobj.textures.game.textures.list[thisanimsa[0]]);
        continue;
      }
      if (this.wh.anims.anims.entries[thisanimsa[0] + '-' + valspriteset['code']] == undefined) {

        this.rcvarsservice.gameobj.anims.create({
          key: thisanimsa[0] + '-' + valspriteset['code'],
          frames: this.rcvarsservice.gameobj.anims.generateFrameNames(thisanimsa[0], {
            prefix: valspriteset['framename'] + '-',
            end: valspriteset['end'],
            zeroPad: 0,
            start: 1,
          }),
          repeat: -1,
          frameRate: valspriteset['fr'],
        });
      }
      /*this.plog.log("framesdebug", this.rcvarsservice.gameobj.anims.generateFrameNames(thisanimsa[0], {
        prefix: valspriteset['framename'] + '-',
        end: valspriteset['end'],
        zeroPad: 0,
        start: 1,
      }))*/
    }
  }
  async initmap_preloaddone_destroymapcontainer() {
    //this.plog.log("initmap_preloaddone_destroymapcontainer()s", this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer);

    try {
      await this.rcvarsservice.gameobj.scene.scenes[0].fow.destroy();
    } catch (error) {
      //this.plog.log("fow destroy", error);
      this.plog.warn("fow destroy", error);
    }
    for (const k in this.rcvarsservice.gameobj.scene.scenes[0].mobs) {

      try {
        await this.rcvarsservice.gameobj.scene.scenes[0].mobs[k].mydestroy();
      } catch (error) {
        this.plog.log("mobs[k].mydestroy() ", k, error);
      }
    }

    for (const k in this.rcvarsservice.gameobj.scene.scenes[0].mapwalker) {

      try {
        await this.rcvarsservice.gameobj.scene.scenes[0].mapwalker[k].mydestroy();
      } catch (error) {
        this.plog.log("mapwalker[k].mydestroy() ", k, error);
      }
    }
    for (const k in this.rcvarsservice.gameobj.scene.scenes[0].mobs) {
      try {
        await this.rcvarsservice.gameobj.scene.scenes[0].mobs[k].destroy();
      } catch (error) {
        this.plog.log("mobs[k].mydestroy() ", k, error);
      }
    }
    for (const k in this.rcvarsservice.gameobj.scene.scenes[0].mobs) {
      try {
        this.rcvarsservice.gameobj.scene.scenes[0].mobs[k] = undefined;
      } catch (error) {
        this.plog.log("mobs[k].mydestroy() ", k, error);
      }
    }
    if (this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer != undefined) {
      /*this.plog.log(
        'intmap_preloaddone mapcontainer.destroy',
        this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list
      );
      
            try {
              this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.destroy(true);//
            } catch (err) {
              this.plog.log('ERROR: mapcontainer.destroy(true)', err);
            }*/
      for (var destroyi = 0; destroyi <= 10; destroyi++) {
        try {
          for (const k in this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list) {
            await this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.remove(this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list[k], true);
            //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list[k].destroy();
          }
        } catch (e) {
          this.plog.log("ERROR! mapcontainer.destroy() ", e);
        }
      }
      for (var destroyi = 0; destroyi <= 10; destroyi++) {
        try {
          for (const k in this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list) {
            await this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list[k].destroy();
            //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list[k].destroy();
          }
        } catch (e) {
          this.plog.log("ERROR! mapcontainer.destroy() ", e);
        }
      }

      //this.plog.log('mapcontainer.destroy() extradestroylist b4', this.rcvarsservice.gameobj.scene.scenes[0].extradestroylist.length, this.rcvarsservice.gameobj.scene.scenes[0].extradestroylist);

      for (const k in this.rcvarsservice.gameobj.scene.scenes[0].extradestroylist) {
        if (this.rcvarsservice.gameobj.scene.scenes[0].extradestroylist[k] != undefined) {
          try {
            await this.rcvarsservice.gameobj.scene.scenes[0].extradestroylist[k].destroy();
          } catch (error) {
            this.plog.log("mapcontainer.destroy() extradestroylist sub error", error);
            this.plog.log("mapcontainer.destroy() extradestroylist sub error", this.rcvarsservice.gameobj.scene.scenes[0].extradestroylist[k]);
          }
        }
        //this.rcvarsservice.gameobj.scene.scenes[0].extradestroylist[k]=undefined;
      }

      for (const k in this.rcvarsservice.gameobj.scene.scenes[0].maplayers) {
        try {
          //this.plog.log("mapcontainer.destroy destroy maplayers", k, this.rcvarsservice.gameobj.scene.scenes[0].maplayers, this.rcvarsservice.gameobj.scene.scenes[0].maplayers[k]);
          await this.rcvarsservice.gameobj.scene.scenes[0].maplayers[k].destroy();
        } catch (e) {
          this.plog.log("mapcontainer.destroy() extradestroylist sub error", e);
        }
      }


      for (const k in this.rcvarsservice.gameobj.scene.scenes[0].npclist) {
        try {
          await this.rcvarsservice.gameobj.scene.scenes[0].npclist[k].destroy();
        } catch (error) {
          ///this.plog.log("ERROR: destroy npclist sub error", error);
        }
      }

      for (const k in this.rcvarsservice.gameobj.scene.scenes[0].zsortlist) {
        try {
          await this.rcvarsservice.gameobj.scene.scenes[0].zsortlist[k].destroy();
        } catch (error) {
          ///this.plog.log("ERROR: destroy zsortlist sub error", error);
        }
      }


      for (const k in this.rcvarsservice.gameobj.scene.scenes[0].screenbg) {
        try {
          await this.rcvarsservice.gameobj.scene.scenes[0].screenbg[k].destroy();
          this.rcvarsservice.gameobj.scene.scenes[0].screenbg[k] = undefined;
        } catch (error) {
          ///this.plog.log("ERROR: destroy zsortlist sub error", error);
        }
      }
      try {
        //this.plog.log('mapcontainer.destroy() ctnr', this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer);
        //this.plog.log('mapcontainer.destroy() scene', this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.scene);
        //this.plog.log('mapcontainer.destroy() maplayers', this.rcvarsservice.gameobj.scene.scenes[0].maplayers);
        // this.plog.log('mapcontainer.destroy() list', this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list.length);

        //this.plog.log('mapcontainer.destroy() extradestroylist af', this.rcvarsservice.gameobj.scene.scenes[0].extradestroylist.length, this.rcvarsservice.gameobj.scene.scenes[0].extradestroylist);

        this.rcvarsservice.gameobj.scene.scenes[0].screenbg = [];

        this.rcvarsservice.gameobj.scene.scenes[0].extradestroylist = [];
        await this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.destroy();
        //this.plog.log('mapcontainer.destroy() ed', this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer);
      } catch (err) {
        this.plog.log('ERROR: mapcontainer.destroy()', err);
      }
      /*
      try {
        await this.rcvarsservice.gameobj.scene.scenes[0].tilecontainer.destroy();
      } catch (err) {
        this.plog.log('ERROR: tilecontainer.destroy()', err);
      }
*/
      try {
        await this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.destroy();
      } catch (err) {
        this.plog.log('ERROR: mapcontainer.destroy()2', err);
      }
      /*
      for (const ck in this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer
        .list) {
        try {
          this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list[ck].destroy(true);
        } catch (err) {
          this.plog.log(
            'ERROR: mapcontainer.destroy(true) i',
            ck,
            this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list[ck],
            err
          );
        }
      }
      */
      //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.destroy();
      this.rcvarsservice.gameobj.scene.scenes[0].shiptimertxt = undefined;
    }

    this.rcvarsservice.gameobj.scene.scenes[0].npclist = [];
    this.rcvarsservice.gameobj.scene.scenes[0].zsortlist = [];
    this.rcvarsservice.gameobj.scene.scenes[0].zaninightlist = [];
    for (const k in this.rcvarsservice.gameobj.scene.scenes[0].lightlist) {
      //var v=this.rcvarsservice.gameobj.scene.scenes[0].lightlist[k];
      try {
        this.rcvarsservice.gameobj.scene.scenes[0].lights.removeLight(
          this.rcvarsservice.gameobj.scene.scenes[0].lightlist[k]
        );
        this.rcvarsservice.gameobj.scene.scenes[0].lightlist[k] = undefined;
      } catch (e) {
        this.plog.log('ERROR: mapcontainer.destroy()2', e);
      }
    }
    this.rcvarsservice.gameobj.scene.scenes[0].lightlist = [];
    /*if (this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer == undefined) {
      this.plog.log("xxgg create map container, initmap_preloaddonw");
      this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer =
        this.rcvarsservice.gameobj.scene.scenes[0].add.container(0, 0);
    } else {
      this.plog.log("xxgg  map container exists, initmap_preloaddonw", this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer);
      //destroy all
      / *for (const k in this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list) {
        this.plog.log("xxgg destroying", k);
        this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list[k].destroy();
        this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list[k]=undefined;
      }
      this.plog.log("xxgg  map container exists, initmap_preloaddonw af", this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer);* /
      for (const k in this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list) {
        this.plog.log("xxgg destroying", k);
        this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.remove(this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list[k],true); 
      }
      //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.destroy();
      this.plog.log("xxgg  map container exists, initmap_preloaddonw af", this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer);
      //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer =        this.rcvarsservice.gameobj.scene.scenes[0].add.container(0, 0);
    }
    if (this.rcvarsservice.gameobj.scene.scenes[0].gifcam!=undefined)
    this.rcvarsservice.gameobj.scene.scenes[0].gifcam.ignore(this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer);
    */
    //this.plog.log("mapcontainer.destroy initmap_preloaddone_destroymapcontainer()e", this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer);

  }
  async initmap_preloaddone_mapmobxanim() {
    if (this.rcvarsservice.activemap['map']["activemap"] == "YES") {
      for (const k in this.rcvarsservice.activemap['mobxspawn']) {
        var v = this.rcvarsservice.activemap['mobxspawn'][k];
        v = v.replace("mobx", "");
        if (this.rcvarsservice.allmob[v]["mobx"] != undefined && this.rcvarsservice.allmob[v]["mobx"] != null) {
          for (var scananii = 0; scananii <= 10; scananii++) {
            if (this.rcvarsservice.allmob[v]["mobx"]["action" + scananii + "-shoot-ani"] != undefined && this.rcvarsservice.allmob[v]["mobx"]["action" + scananii + "-shoot-ani"] != "") {
              var tmpspritefromanim = this.rcvarsservice.allanims[this.rcvarsservice.allmob[v]["mobx"]["action" + scananii + "-shoot-ani"]];
              if (tmpspritefromanim != "") {
                var animsmobxsdataa = (tmpspritefromanim["code"] + "").split('_');
                var tmpframe =
                  await this.rcvarsservice.gameobj.anims.generateFrameNames(
                    animsmobxsdataa[0] + '',
                    {
                      prefix: animsmobxsdataa[1] + '-',
                      start: 1,
                      end: tmpspritefromanim['num'],
                      zeroPad: 0,
                    }
                  );
                //this.plog.log('mobxani frames for ' + tmpspritefromanim["code"], tmpframe, tmpspritefromanim);
                var tmpf = await this.rcvarsservice.gameobj.anims.create({
                  key: tmpspritefromanim["code"],
                  frames: tmpframe,
                  frameRate: tmpspritefromanim['fr'],
                  repeat: -1,
                });
              }

            }
          }
        }
      }
    }
  }
  initmap_preloaddone_doeffect(effectlista) {

    for (var k in effectlista) {
      //this.plog.log('effectlist', k, effectlista[k]);
      if (effectlista[k] == undefined || effectlista[k] == '') continue;
      var v = effectlista[k];
      if (v == 'rain')
        this.rcvarsservice.gameobj.scene.scenes[0].registry.list.weather.rainStart(
          this.rcvarsservice.gameobj.scene.scenes[0]
        );
      if (v == 'heavyrain')
        this.rcvarsservice.gameobj.scene.scenes[0].registry.list.weather.heavyRainStart(
          this.rcvarsservice.gameobj.scene.scenes[0]
        );
      if (v == 'snow')
        this.rcvarsservice.gameobj.scene.scenes[0].registry.list.weather.snowStart(
          this.rcvarsservice.gameobj.scene.scenes[0]
        );
      if (v == 'ray1')
        this.rcvarsservice.gameobj.scene.scenes[0].registry.list.weather.startRay1(
          this.rcvarsservice.gameobj.scene.scenes[0]
        );
      if (v == 'fog')
        this.rcvarsservice.gameobj.scene.scenes[0].registry.list.weather.fogStart(
          this.rcvarsservice.gameobj.scene.scenes[0]
        );
    }
  }
  initmap_preloaddone_sortlayer() {
    this.plog.log("initmap_preloaddone_sortlayer()",);
    //this.plog.log("this.rcvarsservice.gameobj.scene.scenes[0].maplayers", this.rcvarsservice.gameobj.scene.scenes[0].maplayers, this.rcvarsservice.gameobj.scene.scenes[0].char_main);
    if (this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer == undefined)
      return;

    this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.setPosition(0, 0);
    if (this.rcvarsservice.gameobj.scene.scenes[0].maplayers['bg'] != undefined) this.rcvarsservice.gameobj.scene.scenes[0].maplayers['bg'].setPosition(this.rcvarsservice.gameobj.scene.scenes[0].defaultmaplayerx, this.rcvarsservice.gameobj.scene.scenes[0].defaultmaplayery);
    if (this.rcvarsservice.gameobj.scene.scenes[0].maplayers['bg2'] != undefined) this.rcvarsservice.gameobj.scene.scenes[0].maplayers['bg2'].setPosition(this.rcvarsservice.gameobj.scene.scenes[0].defaultmaplayerx, this.rcvarsservice.gameobj.scene.scenes[0].defaultmaplayery);
    if (this.rcvarsservice.gameobj.scene.scenes[0].maplayers['bg4'] != undefined) this.rcvarsservice.gameobj.scene.scenes[0].maplayers['bg3'].setPosition(this.rcvarsservice.gameobj.scene.scenes[0].defaultmaplayerx, this.rcvarsservice.gameobj.scene.scenes[0].defaultmaplayery);
    if (this.rcvarsservice.gameobj.scene.scenes[0].maplayers['fg'] != undefined) this.rcvarsservice.gameobj.scene.scenes[0].maplayers['fg'].setPosition(this.rcvarsservice.gameobj.scene.scenes[0].defaultmaplayerx, this.rcvarsservice.gameobj.scene.scenes[0].defaultmaplayery);
    if (this.rcvarsservice.gameobj.scene.scenes[0].maplayers['fg2'] != undefined) this.rcvarsservice.gameobj.scene.scenes[0].maplayers['fg2'].setPosition(this.rcvarsservice.gameobj.scene.scenes[0].defaultmaplayerx, this.rcvarsservice.gameobj.scene.scenes[0].defaultmaplayery);
    if (this.rcvarsservice.gameobj.scene.scenes[0].maplayers['fg3'] != undefined) this.rcvarsservice.gameobj.scene.scenes[0].maplayers['fg3'].setPosition(this.rcvarsservice.gameobj.scene.scenes[0].defaultmaplayerx, this.rcvarsservice.gameobj.scene.scenes[0].defaultmaplayery);
    if (
      this.rcvarsservice.gameobj.scene.scenes[0].char_main != undefined &&
      this.rcvarsservice.gameobj.scene.scenes[0].char_main.parentContainer != undefined && this.rcvarsservice.gameobj.scene.scenes[0].char_main != undefined &&
      this.rcvarsservice.gameobj.scene.scenes[0].maplayers['bg3'] != undefined &&
      this.rcvarsservice.gameobj.scene.scenes[0].maplayers['bg3'].parentContainer != undefined
    ) {
      this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.moveAbove(
        this.rcvarsservice.gameobj.scene.scenes[0].char_main,
        this.rcvarsservice.gameobj.scene.scenes[0].maplayers['bg3']
      );
    } else {
      if (
        this.rcvarsservice.gameobj.scene.scenes[0].char_main != undefined &&
        this.rcvarsservice.gameobj.scene.scenes[0].char_main.parentContainer != undefined && this.rcvarsservice.gameobj.scene.scenes[0].char_main != undefined &&
        this.rcvarsservice.gameobj.scene.scenes[0].maplayers['bg2'] != undefined &&
        this.rcvarsservice.gameobj.scene.scenes[0].maplayers['bg2'].parentContainer != undefined
      ) {
        this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.moveAbove(
          this.rcvarsservice.gameobj.scene.scenes[0].char_main,
          this.rcvarsservice.gameobj.scene.scenes[0].maplayers['bg2']
        );
      } else {
        if (
          this.rcvarsservice.gameobj.scene.scenes[0].char_main != undefined &&
          this.rcvarsservice.gameobj.scene.scenes[0].char_main.parentContainer != undefined && this.rcvarsservice.gameobj.scene.scenes[0].char_main != undefined &&
          this.rcvarsservice.gameobj.scene.scenes[0].maplayers['bg'] !=
          undefined &&
          this.rcvarsservice.gameobj.scene.scenes[0].maplayers['bg'].parentContainer != undefined
        ) {
          //this.plog.log("xxxxmoveAbove", this.rcvarsservice.gameobj.scene.scenes[0].char_main,            this.rcvarsservice.gameobj.scene.scenes[0].maplayers['bg']);
          this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.moveAbove(
            this.rcvarsservice.gameobj.scene.scenes[0].char_main,
            this.rcvarsservice.gameobj.scene.scenes[0].maplayers['bg']
          );
        }
      }
    }

    if (
      this.rcvarsservice.gameobj.scene.scenes[0].char_main != undefined &&
      this.rcvarsservice.gameobj.scene.scenes[0].char_main.parentContainer != undefined && this.rcvarsservice.gameobj.scene.scenes[0].char_main != undefined &&
      this.rcvarsservice.gameobj.scene.scenes[0].maplayers['fg'] != undefined &&
      this.rcvarsservice.gameobj.scene.scenes[0].maplayers['fg'].parentContainer != undefined
    ) {
      this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.moveBelow(
        this.rcvarsservice.gameobj.scene.scenes[0].char_main,
        this.rcvarsservice.gameobj.scene.scenes[0].maplayers['fg']
      );
    } else {
      if (
        this.rcvarsservice.gameobj.scene.scenes[0].char_main != undefined &&
        this.rcvarsservice.gameobj.scene.scenes[0].char_main.parentContainer != undefined && this.rcvarsservice.gameobj.scene.scenes[0].char_main != undefined &&
        this.rcvarsservice.gameobj.scene.scenes[0].maplayers['fg2'] != undefined &&
        this.rcvarsservice.gameobj.scene.scenes[0].maplayers['fg2'].parentContainer != undefined
      ) {
        this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.moveBelow(
          this.rcvarsservice.gameobj.scene.scenes[0].char_main,
          this.rcvarsservice.gameobj.scene.scenes[0].maplayers['fg2']
        );
      } else {
        if (
          this.rcvarsservice.gameobj.scene.scenes[0].char_main != undefined &&
          this.rcvarsservice.gameobj.scene.scenes[0].char_main.parentContainer != undefined && this.rcvarsservice.gameobj.scene.scenes[0].char_main != undefined &&
          this.rcvarsservice.gameobj.scene.scenes[0].maplayers['fg3'] !=
          undefined &&
          this.rcvarsservice.gameobj.scene.scenes[0].maplayers['fg3'].parentContainer != undefined
        ) {
          this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.moveBelow(
            this.rcvarsservice.gameobj.scene.scenes[0].char_main,
            this.rcvarsservice.gameobj.scene.scenes[0].maplayers['fg3']
          );
        }
      }
    }

    //screenbg s
    for (const screenbgk in this.rcvarsservice.activemap['map']['screenbg']) {
      if (this.rcvarsservice.activemap['map']['screenbg'][screenbgk] != undefined) {
        this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.sendToBack(this.rcvarsservice.gameobj.scene.scenes[0].screenbg[screenbgk]);

      }
    }
    //screenbg e
  }
  async initmap_preloaddone_createanim() {
    //create anims from autopublish anims
    for (var key in this.rcvarsservice.anims) {
      try {
        var val = this.rcvarsservice.anims[key];
      } catch (e) {
        this.plog.warn(e);
        continue;
      }
      var codea = val['code'] + '';
      var codea2 = codea.split('_');
      //this.plog.log("load2 anims", codea2, key, val);
      if (this.rcvarsservice.gameobj.textures.game.textures.list[val['sprite']].frames[codea2[1] + '-1'] == undefined) {
        //this.plog.log("load2e xx skip, textures not found", codea2[1] + '-1');
        continue;
      }
      var tmpframe = this.rcvarsservice.gameobj.anims.generateFrameNames(
        val['sprite'] + '',
        {
          prefix: codea2[1] + '-',
          start: 1,
          end: val['num'],
          zeroPad: 0,
        }
      );
      //this.plog.log('load2e frames for ' + key, tmpframe, val);
      if (this.wh.anims.anims.entries[key] == undefined) {

        var tmpf = this.rcvarsservice.gameobj.anims.create({
          key: key + '',
          frames: tmpframe,
          frameRate: val['fr'],
          repeat: -1,
        });
      }
      //this.plog.log("load2e res", tmpframe, tmpf);
      //this.plog.log("load2e anims", this.rcvarsservice.gameobj.anims);
    }
    //this.plog.log("anims xxxx",this.rcvarsservice.gameobj.anims)
    //checkmob loaded
    var actarr = [
      'idle',
      'attack',
      'evade',
      'active',
      'spella',
      'bored',
      'chant',
      'spellb',
      'sleep',
      'guard',
      'seta',
      'dizzy',
      'damage',
      'chantb',
      'injure',
      'xxx',
      'chantc',
      'dead',
    ];
    if (this.rcvarsservice.activemap['map']["activemap"] == "YES") {
      for (const k in this.rcvarsservice.activemap['mobxspawn']) {
        var v = this.rcvarsservice.activemap['mobxspawn'][k];
        v = v.replace("mobx", "");
        var mobsprite = this.rcvarsservice.allmob[v]['sprite'];
        //this.plog.log("mobsprite", mobsprite, mobchk, this.rcvarsservice.allmob[mobchk])
        for (var akey in actarr) {
          var action = actarr[akey];

          var tmpframe = this.rcvarsservice.gameobj.anims.generateFrameNames(
            mobsprite,
            {
              prefix: action + '-',
              start: 1,
              end: 3,
              zeroPad: 0,
            }
          );
          //this.plog.log("mobchk loaded frames for " + action, mobchk, tmpframe);
          var tmpf = this.rcvarsservice.gameobj.anims.create({
            key: 'mob_' + mobsprite + '_' + action,
            frames: tmpframe,
            frameRate: 8,
            yoyo: true,
            repeat: -1,
          });
        }
      }
    }
    if (this.rcvarsservice.activemap['map']['mobteam'] != undefined) {
      for (let index = 0; index <= 5; index++) {
        var mobchk =
          this.rcvarsservice.activemap['map']['mobteam']['mob' + index];
        //this.plog.log("mobchk loaded", index, mobchk);
        if (
          mobchk != undefined &&
          mobchk != '' &&
          this.rcvarsservice.allmob[mobchk] != undefined
        ) {
          var mobsprite = this.rcvarsservice.allmob[mobchk]['sprite'];
          //this.plog.log("mobsprite", mobsprite, mobchk, this.rcvarsservice.allmob[mobchk])
          for (var akey in actarr) {
            var action = actarr[akey];

            var tmpframe = this.rcvarsservice.gameobj.anims.generateFrameNames(
              mobsprite,
              {
                prefix: action + '-',
                start: 1,
                end: 3,
                zeroPad: 0,
              }
            );
            //this.plog.log("mobchk loaded frames for " + action, mobchk, tmpframe);
            if (this.wh.anims.anims.entries['mob_' + mobsprite + '_' + action] == undefined) {

              var tmpf = this.rcvarsservice.gameobj.anims.create({
                key: 'mob_' + mobsprite + '_' + action,
                frames: tmpframe,
                frameRate: 8,
                yoyo: true,
                repeat: -1,
              });
            }
          }
        }
      }
    }


    var tmpmincombat = await this.wh.registry.list.rcstorage.get('setting_mincombat', true);
    if (tmpmincombat == "yes") {
      var minmobarr = ["mincombatmobb", "mincombatmobr"];
      for (var minmobarrk in minmobarr) {
        var mobspritem = minmobarr[minmobarrk];
        for (var akey in actarr) {
          var action = actarr[akey];
          var tmpframe = this.rcvarsservice.gameobj.anims.generateFrameNames(
            mobspritem,
            {
              prefix: action + '-',
              start: 1,
              end: 3,
              zeroPad: 0,
            }
          );
          //this.plog.log("tmpmincombat loaded frames for " + action, mobchk, tmpframe);
          var tmpf = this.rcvarsservice.gameobj.anims.create({
            key: 'mob_' + mobspritem + '_' + action,
            frames: tmpframe,
            frameRate: 8,
            yoyo: true,
            repeat: -1,
          });
        }
      }
    }
    //critter loaded
    var actarr = ['u', 'd', 'l', 'r'];
    for (let index = 0; index <= 10; index++) {
      var critterchk =
        this.rcvarsservice.activemap['map']['critters'][
        'crit' + index + '_sprite'
        ];
      var critterchkcode =
        this.rcvarsservice.activemap['map']['critters'][
        'crit' + index + '_spritesubcode'
        ];
      //this.plog.log(        'critterchk loaded',        index,        critterchk,        this.rcvarsservice.activemap['map']['critters']      );
      if (critterchk != undefined && critterchk != '') {
        for (var akey in actarr) {
          var action = actarr[akey];

          var tmpframe = this.rcvarsservice.gameobj.anims.generateFrameNames(
            critterchk,
            {
              prefix: critterchkcode + '-' + action + '-',
              start: 1,
              end: 3,
              zeroPad: 0,
            }
          );
          //this.plog.log("critter loaded frames for " + action, mobchk, tmpframe);
          if (this.rcvarsservice.gameobj.anims.anims.entries["loadingcat"] == undefined) {
            if (this.wh.anims.anims.entries['critter_' + critterchk + '_' + critterchkcode + '_' + action] == undefined) {
              var tmpf = this.rcvarsservice.gameobj.anims.create({
                key: 'critter_' + critterchk + '_' + critterchkcode + '_' + action,
                frames: tmpframe,
                frameRate: 8,
                yoyo: true,
                repeat: -1,
              });
            }
          }
          //this.plog.log("critter fkey","critter_" + critterchk + "_" + critterchkcode + "_" + action);
          var tmpframe = this.rcvarsservice.gameobj.anims.generateFrameNames(
            critterchk,
            {
              prefix: critterchkcode + '-' + action + '-',
              start: 1,
              end: 1,
              zeroPad: 0,
            }
          );
          //this.plog.log("mobchk loaded frames for " + action, mobchk, tmpframe);
          var tmpf = this.rcvarsservice.gameobj.anims.create({
            key:
              'critter_' +
              critterchk +
              '_' +
              critterchkcode +
              '_' +
              action +
              '_idle',
            frames: tmpframe,
            frameRate: 1,
            yoyo: true,
            repeat: -1,
          });
        }
      }
    }
  }
  initmap_preloaddone_sortlayer_mapdata() {

    //ordering maplayers mapdata
    for (var item in this.rcvarsservice.gameobj.scene.scenes[0].maplayers) {
      var tmpitem = this.rcvarsservice.gameobj.scene.scenes[0].maplayers[item];
      //this.plog.log("ordering maplayers", item, tmpitem);
      //this.plog.log("ordering maplayers mainchar", this.rcvarsservice.gameobj.scene.scenes[0].char_main);
      if (item == 'bg3') {
        this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.sendToBack(
          tmpitem
        );
      }
      if (item == 'fg1') {
        this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.bringToTop(
          tmpitem
        );
      }
    }
    for (var item in this.rcvarsservice.gameobj.scene.scenes[0].maplayers) {
      var tmpitem = this.rcvarsservice.gameobj.scene.scenes[0].maplayers[item];
      //this.plog.log("ordering maplayers", item, tmpitem);
      //this.plog.log("ordering maplayers mainchar", this.rcvarsservice.gameobj.scene.scenes[0].char_main);
      if (item == 'bg2') {
        this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.sendToBack(
          tmpitem
        );
      }
      if (item == 'fg2') {
        this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.bringToTop(
          tmpitem
        );
      }
    }
    for (var item in this.rcvarsservice.gameobj.scene.scenes[0].maplayers) {
      var tmpitem = this.rcvarsservice.gameobj.scene.scenes[0].maplayers[item];
      //this.plog.log("ordering maplayers", item, tmpitem);
      //this.plog.log("ordering maplayers mainchar", this.rcvarsservice.gameobj.scene.scenes[0].char_main);
      if (item == 'bg') {
        this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.sendToBack(
          tmpitem
        );
      }
      if (item == 'fg3') {
        this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.bringToTop(
          tmpitem
        );
      }
    }

    /*
    for (var item in this.rcvarsservice.gameobj.scene.scenes[0].maplayers) {
      var tmpitem = this.rcvarsservice.gameobj.scene.scenes[0].maplayers[item];
      // tmpitem.setDepth(400);
      //this.plog.log('z maplayers', this.charzindex, item, tmpitem.depth, tmpitem.z);
      //this.plog.log("ordering maplayers", item, tmpitem);
      //this.plog.log("ordering maplayers mainchar", this.rcvarsservice.gameobj.scene.scenes[0].char_main);
    }*/

    /* //can't set depth in container
    if (this.rcvarsservice.gameobj.scene.scenes[0].maplayers["bg"] != undefined)
      this.rcvarsservice.gameobj.scene.scenes[0].maplayers["bg"].setDepth(1000);
    if (this.rcvarsservice.gameobj.scene.scenes[0].maplayers["bg2"] != undefined)
      this.rcvarsservice.gameobj.scene.scenes[0].maplayers["bg2"].setDepth(2000);
    if (this.rcvarsservice.gameobj.scene.scenes[0].maplayers["bg3"] != undefined)
      this.rcvarsservice.gameobj.scene.scenes[0].maplayers["bg3"].setDepth(3000);
    if (this.rcvarsservice.gameobj.scene.scenes[0].maplayers["fg"] != undefined)
      this.rcvarsservice.gameobj.scene.scenes[0].maplayers["fg"].setDepth(4000);
    if (this.rcvarsservice.gameobj.scene.scenes[0].maplayers["fg2"] != undefined)
      this.rcvarsservice.gameobj.scene.scenes[0].maplayers["fg2"].setDepth(5000);
    if (this.rcvarsservice.gameobj.scene.scenes[0].maplayers["fg3"] != undefined)
      this.rcvarsservice.gameobj.scene.scenes[0].maplayers["fg3"].setDepth(6000);
    this.rcvarsservice.gameobj.scene.scenes[0].char_main.setDepth(3500);*/
    //this.plog.log("spawnpos", spawnpos);
    //this.plog.log("maptilea", maptilea);
    //this.plog.log("maplayer this.charzindex", this.charzindex);
    //this.plog.log("maplayer", this.rcvarsservice.gameobj.scene.scenes[0].maplayers);

    //this.plog.log('maplayers b', this.rcvarsservice.gameobj.scene.scenes[0].maplayers);
    /*this.plog.log("maplayers test this", this);
     */
    /*
     this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.moveAbove(
       this.rcvarsservice.gameobj.scene.scenes[0].maplayers["bg2"],
       this.rcvarsservice.gameobj.scene.scenes[0].maplayers["bg"]
     );
     this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.moveAbove(
       this.rcvarsservice.gameobj.scene.scenes[0].maplayers["bg3"],
       this.rcvarsservice.gameobj.scene.scenes[0].maplayers["bg2"]
     );
     this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.moveAbove(
       this.rcvarsservice.gameobj.scene.scenes[0].maplayers["fg"],
       this.rcvarsservice.gameobj.scene.scenes[0].maplayers["bg3"]
     );
     this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.moveAbove(
       this.rcvarsservice.gameobj.scene.scenes[0].maplayers["fg2"],
       this.rcvarsservice.gameobj.scene.scenes[0].maplayers["fg"]
     );
     this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.moveAbove(
       this.rcvarsservice.gameobj.scene.scenes[0].maplayers["fg3"],
       this.rcvarsservice.gameobj.scene.scenes[0].maplayers["fg2"]
     );*/
  }
  npcclick(wh) {
    this.plog.warn('npcclick', wh, arguments);
    if (this.rcvarsservice.gameobj.scene.scenes[0].registry.list.drama.isdramaplaying == true) return;

    this.rcvarsservice.gameobj.scene.scenes[0].lastnpcuidclicked = wh.name;
    //if (this.astar == undefined) {

    this.rcvarsservice.gameobj.scene.scenes[0].mapclickworking = true;
    setTimeout(() => {
      //this.plog.log("timeout",this);
      this.wh.mapclickworking = false;
    }, 200);
    var tmp = wh.name + '';
    var tmpa;
    tmpa = tmp.split('-');
    var npcid = tmpa[1];
    //this.plog.log("npcclick tmpa", tmpa);
    let startPos = {
      x: this.rcvarsservice.gameobj.scene.scenes[0].char_main.currentx,
      y: this.rcvarsservice.gameobj.scene.scenes[0].char_main.currenty,
    };

    let goalPos = { x: Number(tmpa[2]), y: Number(tmpa[3]) };
    var charfarx = goalPos.x - startPos.x;
    var charfary = goalPos.y - startPos.y;
    if (Math.abs(charfarx) > Math.abs(charfary)) {
      //farer on x axis
      if (charfarx > 0) {
        goalPos.x--;
      } else {
        goalPos.x++;
      }
    } else {
      if (charfary > 0) {
        goalPos.y--;
      } else {
        goalPos.y++;
      }
    }
    //var tmpmetrix;
    //tmpmetrix = this.rcvarsservice.activemapmetrix;
    //override that npc location is walkable
    //tmpmetrix[goalPos.y][goalPos.x] = 0;
    //this.plog.log("npc tmpmetrix", tmpmetrix);
    //this.plog.log("npc creating astar", tmpmetrix);
    if (this.astar == undefined) {
      this.astar = new AStarFinder({
        grid: {
          matrix: this.rcvarsservice.activemapmetrix,
        },
        includeStartNode: false,
        includeEndNode: true,
        heuristic: "Manhattan",
        diagonalAllowed: true,
        weight: 0.5
      });
      //this.astar.setHeuristic("Octile"); // (Manhattan, Euclidean, Chebyshev or Octile):
      //this.astar.setWeight(0.5);
      //this.plog.log("npc astar", this.astar);
    }
    //this.plog.log("npcclick activemapmetrix", this.rcvarsservice.activemapmetrix);
    // this.plog.log("npcclick startPos", startPos);
    //this.plog.log("npcclick goalPos", goalPos);
    var myPathway = this.astar.findPath(startPos, goalPos);
    //this.plog.log("npcclick myPathway", myPathway);
    var isnexttonpc = false;
    var tmpcharx = startPos.x;
    var tmpchary = startPos.y;
    if (
      (tmpcharx == goalPos.x + 1 && tmpchary == goalPos.y + 1) ||
      (tmpcharx == goalPos.x + 1 && tmpchary == goalPos.y - 1) ||
      (tmpcharx == goalPos.x - 1 && tmpchary == goalPos.y + 1) ||
      (tmpcharx == goalPos.x - 1 && tmpchary == goalPos.y - 1) ||
      (tmpcharx == goalPos.x + 0 && tmpchary == goalPos.y + 1) ||
      (tmpcharx == goalPos.x + 0 && tmpchary == goalPos.y - 1) ||
      (tmpcharx == goalPos.x + 1 && tmpchary == goalPos.y + 0) ||
      (tmpcharx == goalPos.x - 1 && tmpchary == goalPos.y + 0) ||
      (tmpcharx == goalPos.x && tmpchary == goalPos.y)
    )
      isnexttonpc = true;

    //talkdistance
    var npcinfo = this.rcvarsservice.gameobj.registry.list.rcvarpass.npc[npcid];
    //this.plog.log('npcclick npcinfo', npcinfo, wh);
    var diffx = wh.currentx - this.rcvarsservice.gameobj.scene.scenes[0].char_main.currentx;
    var diffy = wh.currenty - this.rcvarsservice.gameobj.scene.scenes[0].char_main.currenty;
    //this.plog.log('npcclick npcinfo diff', diffx, diffy);

    //turning face 
    var npcfacesprite;
    if (Math.abs(diffx) > Math.abs(diffy)) {
      if (diffx < 0) {
        var npcfacespriteinfo = npcinfo["mapsprite"].split("_");
        npcfacesprite = npcfacespriteinfo[0] + "-idle-r";
      }
      if (diffx > 0) {
        var npcfacespriteinfo = npcinfo["mapsprite"].split("_");
        npcfacesprite = npcfacespriteinfo[0] + "-idle-l";
      }
    } else {
      if (diffy < 0) {
        var npcfacespriteinfo = npcinfo["mapsprite"].split("_");
        npcfacesprite = npcfacespriteinfo[0] + "-idle-d";
      }
      if (diffy > 0) {
        var npcfacespriteinfo = npcinfo["mapsprite"].split("_");
        npcfacesprite = npcfacespriteinfo[0] + "-idle-u";
      }
    }
    if (npcfacesprite != undefined) {
      var npcsprite = this.rcvarsservice.gameobj.scene.scenes[0].registry.list.phm.findnpc(this.rcvarsservice.gameobj.scene.scenes[0], wh.name);

      //this.plog.log('npcclick npcinfo npcfacesprite', npcfacesprite, this.rcvarsservice.gameobj.scene.scenes[0].anims.anims.entries);
      if (this.rcvarsservice.gameobj.scene.scenes[0].anims.anims.entries[npcfacesprite] != undefined &&
        this.rcvarsservice.gameobj.scene.scenes[0].anims.anims.entries[npcfacesprite].frames != undefined &&
        this.rcvarsservice.gameobj.scene.scenes[0].anims.anims.entries[npcfacesprite].frames.length > 0) {
        npcsprite.play({ key: npcfacesprite, frameRate: 0.2 });
        //this.plog.log('npcclick npcinfo facing to', npcfacesprite, this);
        this.wh.npcfacespriteevent = this.wh.time.addEvent({ delay: 4000, callback: this.restorenpcsprite, callbackScope: npcsprite, loop: false, args: [this] });
      }
    }
    //turning face e

    if (Number(npcinfo['talkdistance']) != 0) {
      for (var npctdi = 1; npctdi < Number(npcinfo['talkdistance']); npctdi++) {
        if (myPathway.length > 0) var popped = myPathway.pop();
      }
    }
    //this.plog.log("can we talk?1", goalPos, tmpcharx, tmpchary);
    //this.plog.log("can we talk?", myPathway.length, isnexttonpc);
    if (myPathway.length <= 3 || isnexttonpc) {
      //this.plog.log("talktonpc", npcid, this.rcvarsservice.gameobj, this.rcvarsservice.gameobj.scene.scenes[0]);
      this.rcvarsservice.gameobj.registry.list.npcconver.starttalk(
        npcid,
        this.rcvarsservice.gameobj.scene.scenes[0]
      );
    } else {
      if (myPathway.length > 0) {///
        if (this.rcvarsservice.activemap["map"]["activemap"] != "YES") {
          //not auto walk if activemap
          this.rcvarsservice.gameobj.scene.scenes[0].walkingtonpc = npcid;
          this.rcvarsservice.gameobj.scene.scenes[0].walkpathcmd = myPathway;
        }
      }
    }
  }
  restorenpcsprite() {
    arguments[0].wh.plog.log("restorenpcsprite()", this, arguments);
    if (this.name == undefined) return;
    //return;
    //var thisname = Object.assign({}, this);

    var namea = this.name.split("-");
    var npcinfo = arguments[0].wh.registry.list.rcvarpass.npc[namea[1]];
    var mapspriteuse = npcinfo["mapsprite"];
    mapspriteuse = mapspriteuse.replace("_", "-");
    if (npcinfo == undefined) return;
    //this.plog.log("restorenpcsprite() npcinfo", mapspriteuse, this.name, npcinfo);
    var npcsprite = arguments[0].wh.registry.list.phm.findnpc(arguments[0].wh, this.name);
    if (npcsprite != undefined)
      npcsprite.play({ key: mapspriteuse, frameRate: 0.5, repeatDelay: 2000 });
  }
  floorclick(wh, xxx) {
    //this.plog.log("floorclick try", xxx);
    if (
      xxx != undefined &&
      xxx[0].downElement != undefined &&
      xxx[0].downElement.tagName != 'CANVAS'
    )
      return;
    if (this.rcvarsservice.gameobj.scene.scenes[0].registry.list.drama.isdramaplaying == true) return;

    //clear kbfocus on floor click (obviously not using GP)
    this.rcvarsservice.gameobj.scene.scenes[0].kbfocus = "";
    this.rcvarsservice.gameobj.scene.scenes[0].kbfocuslv1 = "";

    if (wh.iswarping == true) return;
    //this.plog.log("floorclick", wh, this.floorastar,event);
    //this.plog.log("floorclick event", xxx);
    //console.log("floorclick arguments", arguments);
    this.rcvarsservice.gameobj.scene.scenes[0].walkingtonpc = '';
    if (this.floorastar == undefined) {
      //this.plog.log("creating floorastar", this.rcvarsservice.activemapmetrix);
      this.floorastar = new AStarFinder({
        grid: {
          matrix: this.rcvarsservice.activemapmetrix,
        },
        includeStartNode: false,
        includeEndNode: true,
        diagonalAllowed: true,
      });
    }
    //this.plog.log("xxxx", this.floorastar)
    // this.plog.log("xxxx",this.rcvarsservice.gameobj.scene.scenes[0].char_main)
    if (this.rcvarsservice.gameobj.scene.scenes[0].char_main == undefined) {
      return;
    }
    //console.log("floorclick arguments", arguments,this);
    this.rcvarsservice.gameobj.scene.scenes[0].char_main.idlecount = 0;
    //console.log("floorclick here char_main.idlecount", this.rcvarsservice.gameobj.scene.scenes[0].char_main.idlecount);

    let startPos = {
      x: this.rcvarsservice.gameobj.scene.scenes[0].char_main.currentx,
      y: this.rcvarsservice.gameobj.scene.scenes[0].char_main.currenty,
    };
    var tmp = wh.name + '';
    var tmpa;
    tmpa = tmp.split('-');

    //check redir, redirect to map warp position
    //this.plog.log("redir zw",this.rcvarsservice.activemap["zwarp"]);

    if (
      this.rcvarsservice.activemap['zwarp'] != undefined &&
      this.rcvarsservice.activemap['zwarp'][tmpa[1] + '-' + tmpa[2]] !=
      undefined
    ) {
      var zwnameclicked =
        this.rcvarsservice.activemap['zwarp'][tmpa[1] + '-' + tmpa[2]];
      if (zwnameclicked != undefined) {
        var zwnameclickeda = zwnameclicked.split('-');
        if (zwnameclickeda[0] == 'redir') {
          //find target
          for (const zwk in this.rcvarsservice.activemap['zwarp']) {
            var zwv = this.rcvarsservice.activemap['zwarp'][zwk];
            if (zwv == zwnameclickeda[1]) {
              var zwka = zwk.split('-');
              this.plog.log('redir redirecting to ', zwnameclickeda[1], zwka);
              tmpa[1] = zwka[0];
              tmpa[2] = zwka[1];
              break;
            }
          }
        }
      }
      //this.plog.log("redir zwnameclicked",zwnameclicked);
    }

    let goalPos = { x: Number(tmpa[1]), y: Number(tmpa[2]) };
    //this.plog.log("floorastar1", this.floorastar);
    //this.plog.log("floorastar", startPos, goalPos);
    var myPathway = [];
    try {
      myPathway = this.floorastar.findPath(startPos, goalPos);
    } catch (err) {
      this.plog.log('ERROR catch', err);
    }
    //this.plog.log("myPathway", myPathway);
    if (myPathway.length > 0) {
      this.rcvarsservice.gameobj.scene.scenes[0].walkpathcmd = myPathway;
    }
  }
  walkto(x, y, action = '') {
    this.plog.log('walkto', x, y);
    //this.plog.log("floorclick", wh, this.floorastar,event);
    //this.plog.log("floorclick event", xxx);
    this.rcvarsservice.gameobj.scene.scenes[0].walkingtonpc = '';
    const mobmetrix = JSON.parse(
      JSON.stringify(this.rcvarsservice.activemapmetrix)
    );
    /*var mobmetrix = this.rcvarsservice.activemapmetrix.reduce((newArray, element) => {
      newArray.push(element);
      return newArray;
    }, []);*/
    //    Object.assign({}, this.rcvarsservice.activemapmetrix);
    if (mobmetrix[y] == undefined) mobmetrix[y] = [];
    mobmetrix[y][x] = 0;
    //this.plog.log('walkto metrix', mobmetrix);
    //if (this.floorastar == undefined) {
    //this.plog.log("creating floorastar", this.rcvarsservice.activemapmetrix);
    try {
      var tmpfloorastar = new AStarFinder({
        grid: {
          matrix: mobmetrix,
        },
        includeStartNode: true,
        includeEndNode: true,
        diagonalAllowed: true,
      });
    } catch (e) {
      this.plog.log("ERROR: Astar", e);
    }
    //}
    this.plog.log('walkto tmpfloorastar', tmpfloorastar);
    if (this.rcvarsservice.gameobj.scene.scenes[0].char_main == undefined) {
      return;
    }
    let startPos = {
      x: this.rcvarsservice.gameobj.scene.scenes[0].char_main.currentx,
      y: this.rcvarsservice.gameobj.scene.scenes[0].char_main.currenty,
    };

    let goalPos = { x: Number(x), y: Number(y) };
    //this.plog.log("floorastar1", this.floorastar);
    //this.plog.log("floorastar", startPos, goalPos);
    try {
      var myPathwayx = tmpfloorastar.findPath(startPos, goalPos);
    } catch (e) {
      this.plog.warn("ERROR!", e);
    }
    this.plog.log('walkto startPos', startPos);
    this.plog.log('walkto goalPos', goalPos);
    this.plog.log('walkto myPathway', myPathwayx);
    if (myPathwayx == undefined) return;
    if (myPathwayx.length > 0) {
      this.rcvarsservice.gameobj.scene.scenes[0].walkpathcmd = myPathwayx;
      if (action != '') {
        this.rcvarsservice.gameobj.scene.scenes[0].walkdoneaction = action;
      }
    }
  }
  async spawnmainchar(spawnpos) {
    //center map
    var width = this.rcvarsservice.gameobj.canvas.width;
    var height = this.rcvarsservice.gameobj.canvas.height;
    if (spawnpos == '' || spawnpos == undefined) {
      this.plog.log('Error, phm.spawnmainchar, spawnpos undefined');
    }
    if (this.rcvarsservice.gameobj.scene.scenes[0].char_main != undefined) {
      this.plog.log("spawnmainchar xskip", this.rcvarsservice.gameobj.scene.scenes[0].char_main)
      //return;
    }
    //this.plog.log("spawnpos", spawnpos)
    var spawnposa = spawnpos.split('-');
    //this.plog.log("spawnposa", spawnposa);
    //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.x =      0 - spawnposa[0] * 32 + width / 2;
    //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.y =      0 - spawnposa[1] * 32 + height / 2;
    //this.plog.log("activechar", this.rcvarsservice.activechar);
    //var tmp = this.rcvarsservice.gameobj.scene.scenes[0].add.image((spawnposa[1]*32),(spawnposa[0]*32), this.rcvarsservice.activechar["sprite"], "walk-d-1").setDepth(999);
    //this.plog.log("b4 generateFrameNames", this.rcvarsservice.gameobj.anims);

    await this.loadcharsprite(this.rcvarsservice.activechar['sprite']);
    /*
    var tmpframe = this.rcvarsservice.gameobj.anims.generateFrameNames(
      this.rcvarsservice.activechar["sprite"] + "", {
      prefix: 'walk-d-',
      start: 1,
      end: 2,
      zeroPad: 0
    });
    this.plog.log("generateFrameNames", tmpframe);
 
    this.plog.log("sprite", this.rcvarsservice.gameobj);
    this.rcvarsservice.gameobj.anims.remove(this.rcvarsservice.activechar["sprite"]+"-idle");
    var tmpx = this.rcvarsservice.gameobj.anims.create({
      key: this.rcvarsservice.activechar["sprite"]+'-idle',
      frames: tmpframe,
      frameRate: 1,
      repeat: -1
    });
    this.charanim_idle = tmpx;
    this.plog.log("tmpx", tmpx);
*/
    //this.plog.log("playatt", this.rcvarsservice.activechar["sprite"]);
    //, this.rcvarsservice.activechar["sprite"]
    //await this.loadcharsprite(this.rcvarsservice.activechar['sprite']);
    //this.plog.log("anims", this.rcvarsservice.gameobj.anims);
    //this.plog.log("xxxx",this.rcvarsservice.activechar["sprite"]+'-idle',);
    this.plog.log("charmain at spawnmainchar(); b4", this.rcvarsservice.gameobj.scene.scenes[0].char_main);
    this.rcvarsservice.gameobj.scene.scenes[0].char_main = this.rcvarsservice.gameobj.scene.scenes[0].add
      .sprite(0, 0, this.rcvarsservice.activechar['sprite'])
      .setDepth(this.charzindex)
      .setSize(32, 64);
    this.rcvarsservice.gameobj.scene.scenes[0].char_main.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);


    this.spawnmainchar_managecam();
    /*if (this.rcvarsservice.gameobj.scene.scenes[0].cameras.main._follow == undefined ||
      this.rcvarsservice.gameobj.scene.scenes[0].cameras.main._follow == null) {
      this.rcvarsservice.gameobj.scene.scenes[0].cameras.main.startFollow(this.rcvarsservice.gameobj.scene.scenes[0].char_main);
      this.plog.log("xxcam following");
    } else {
      this.plog.log("xxcam already following", this.rcvarsservice.gameobj.scene.scenes[0].cameras.main)
    }*/
    ///

    //this.rcvarsservice.gameobj.scene.scenes[0].cameras.main.setBounds(0, 0, width, height);
    //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.add(this.rcvarsservice.gameobj.scene.scenes[0].char_main);

    //this.rcvarsservice.gameobj.scene.scenes[0].gifcam.ignore(this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer);
    //this.rcvarsservice.gameobj.scene.scenes[0].cameras.main.setBounds(0, 0, 300, 300);
    /*var scrol_x = this.rcvarsservice.gameobj.scene.scenes[0].char_main.x - width / 2;
    var scrol_y = this.rcvarsservice.gameobj.scene.scenes[0].char_main.y - height / 2;
 
    this.rcvarsservice.gameobj.scene.scenes[0].cameras.main.scrollX = scrol_x;    ///  scrollX - Х top left point of camera
    this.rcvarsservice.gameobj.scene.scenes[0].cameras.main.scrollY = scrol_y;    ///  scrollY - Y top left point of camera
    */
    /*const tmp = this.rcvarsservice.gameobj.scene.scenes[0].physics.add
      .sprite(32, 64, this.rcvarsservice.activechar['sprite'])
      .setDepth(this.charzindex);*/
    //, this.rcvarsservice.activechar["sprite"]

    this.rcvarsservice.gameobj.scene.scenes[0].physics.add.existing(this.rcvarsservice.gameobj.scene.scenes[0].char_main);
    this.rcvarsservice.gameobj.scene.scenes[0].char_main.body.setSize(30, 30, true);
    this.rcvarsservice.gameobj.scene.scenes[0].char_main.body.setOffset(16, 35);
    this.rcvarsservice.gameobj.scene.scenes[0].char_main
      .play(this.rcvarsservice.activechar['sprite'] + '-idle')
      .setDepth(this.charzindex); //.setDisplayOrigin(0.5, 0.8);
    this.rcvarsservice.gameobj.scene.scenes[0].zsortlist.push(this.rcvarsservice.gameobj.scene.scenes[0].char_main);
    if (this.rcvarsservice.activechar['element'] != undefined && this.rcvarsservice.activechar['element'] != "") {
      this.rcvarsservice.gameobj.scene.scenes[0].maincharelement = this.rcvarsservice.gameobj.scene.scenes[0].add
        .sprite(100, 100, "element", this.rcvarsservice.activechar['element'] + "1");

      var tmpelement = this.rcvarsservice.activechar['element'];
      if (tmpelement == "wind") tmpelement = "air";
      this.rcvarsservice.gameobj.scene.scenes[0].maincharelement.play("element_" + tmpelement);
      this.rcvarsservice.gameobj.scene.scenes[0].maincharelement.displayWidth = this.rcvarsservice.gameobj.scene.scenes[0].maincharelement.displayHeight = 16;
      this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.add(this.rcvarsservice.gameobj.scene.scenes[0].maincharelement);
      this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.bringToTop(this.rcvarsservice.gameobj.scene.scenes[0].maincharelement);

    }
    if (this.rcvarsservice.activechar['cbt'] != undefined && this.rcvarsservice.activechar['cbt']["raritycount"] != undefined && this.rcvarsservice.activechar['cbt']["raritycount"] != "" && this.rcvarsservice.activechar['cbt']["raritycount"] + "" != "0") {
      /*
      this.rcvarsservice.gameobj.scene.scenes[0].maincharrarity = this.rcvarsservice.gameobj.scene.scenes[0].add
        .sprite(100, 100, "effleg", "effleg_b");
      this.rcvarsservice.gameobj.scene.scenes[0].maincharrarity.play("effleg_b");
      this.rcvarsservice.gameobj.scene.scenes[0].maincharrarity.setAlpha(this.rcvarsservice.activechar['cbt']["raritycount"]/10);
      this.rcvarsservice.gameobj.scene.scenes[0].maincharrarity.displayWidth = this.rcvarsservice.gameobj.scene.scenes[0].maincharrarity.displayHeight = 72;
      this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.add(this.rcvarsservice.gameobj.scene.scenes[0].maincharrarity);
      this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.bringToTop(this.rcvarsservice.gameobj.scene.scenes[0].maincharrarity);
      this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.bringToTop(this.rcvarsservice.gameobj.scene.scenes[0].char_main);*/

    }

    //this.rcvarsservice.gameobj.scene.scenes[0].registry.list.wsrc.initmap(this.rcvarsservice.activechar["map"]);
    //var tmp = this.rcvarsservice.gameobj.scene.scenes[0].add.image((spawnposa[1] * 32), (spawnposa[0] * 32), this.rcvarsservice.activechar["sprite"], "walk-d-1").setDepth(999);
    //var charic =      this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.add(tmp);
    //this.plog.log("charic",charic);
    //this.plog.log("moveTo", this.charzindex, this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer);
    /* ///this.plog.log("moveToxx", tmp,
      this.charzindex);
    this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.moveTo(
      tmp,
      this.charzindex
    );*/
    this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.add(this.rcvarsservice.gameobj.scene.scenes[0].char_main);
    this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.bringToTop(this.rcvarsservice.gameobj.scene.scenes[0].char_main);
    this.rcvarsservice.gameobj.scene.scenes[0].char_main.setAlpha(1);
    //this.rcvarsservice.gameobj.scene.scenes[0].char_main = tmp;
    //this.plog.log("xxx char_main", this.rcvarsservice.gameobj.scene.scenes[0].char_main);
    //this.plog.log("gameobj",this.rcvarsservice.gameobj);

    await this.movemaincharto(spawnposa[1], spawnposa[0]);

    this.mapitemeffect(
      this.rcvarsservice.gameobj.scene.scenes[0].char_main,
      'warpgate_b'
    );

    //spawn MapPet
    if (this.rcvarsservice.activechar['map'] != "backyard" && this.rcvarsservice.activechar['map'] != "backyard2") {
      //this.rcvarsservice.activechar['map'] != "home" &&
      await this.spawnpet(this.rcvarsservice.gameobj.scene.scenes[0], this.rcvarsservice.gameobj.scene.scenes[0].char_main, this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rcvarpass.activechar["pet"]);
    }
    //homevoice
    if (this.rcvarsservice.activechar['map'] == "home") {
      this.voicethis(this.rcvarsservice.gameobj.scene.scenes[0], "backhome");
    }

  }
  async respawnpet() {
    //this.plog.log("respawnpet()");
    if (this.rcvarsservice.activechar['map'] != "backyard" || this.rcvarsservice.activechar['map'] != "backyard2") {
      //this.rcvarsservice.activechar['map'] != "home" &&
      //console.log(this.rcvarsservice.activechar);
      await this.spawnpet(this.rcvarsservice.gameobj.scene.scenes[0], this.rcvarsservice.gameobj.scene.scenes[0].char_main, this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rcvarpass.activechar["pet"]);
    }
  }
  spawnmainchar_managecam() {
    this.rcvarsservice.gameobj.scene.scenes[0].camman_refollow(this.rcvarsservice.gameobj.scene.scenes[0]);
    //this.plog.log("xxcam following");

    if (this.rcvarsservice.gameobj.scene.scenes[0].gifcam != undefined) {
      this.rcvarsservice.gameobj.scene.scenes[0].cameras.remove(this.rcvarsservice.gameobj.scene.scenes[0].gifcam);
    }

    var width = this.rcvarsservice.gameobj.canvas.width;
    var height = this.rcvarsservice.gameobj.canvas.height;
    this.rcvarsservice.gameobj.scene.scenes[0].gifcam = this.rcvarsservice.gameobj.scene.scenes[0].cameras.add(0, 0, width, height);

    /*
    camera as minimap, disable because can't put it under frame border
    var mnmsize = 120;
    var mnmmargin = 70;
    console.log("gifsc", this.wh);
    if (this.wh.mnmcam == undefined) {
      this.wh.mnmcam = this.wh.cameras.add(
        width - (mnmsize*1.5 + mnmmargin),
        mnmmargin,
        mnmsize, mnmsize);
      this.wh.mnmcam.setOrigin(0, 0);
      this.wh.mnmcam.setZoom(0.4);
      this.wh.mnmcam.setMask(this.wh.maskmnm,true);
     // this.wh.mnmcam.setViewport(200, 150, 400, 300);
      this.wh.mnmcam.startFollow(this.wh.char_main, true, 0, 0, 0.01, 0.01);
      //startFollow(target, [roundPixels], [lerpX], [lerpY], [offsetX], [offsetY])

      //this.wh.mnmcam.setDepth(50003);
      console.log("gifsc mnmcam", this.wh.mnmcam);
    }*/

    this.rcvarsservice.gameobj.scene.scenes[0].camman(this.rcvarsservice.gameobj.scene.scenes[0]);

  }
  async spawnpet(wh, mainsprite, pets) {
    //this.plog.log("spawnpet()", wh, mainsprite, pets);
    var resarr = [];

    for (var key in this.wh.registry.list.rcvarpass.activemap['walkable']) {
      if (this.wh.registry.list.rcvarpass.activemap['walkable'][key] == 'yn_y') {
        var keya = key.split("-");
        var chk = Phaser.Math.Distance.Between(Number(keya[0]), Number(keya[1]), mainsprite.currentx, mainsprite.currenty);
        if (chk <= 5) {
          resarr.push([Number(keya[0]), Number(keya[1])]);
        }
      }
      //this.rcvarsservice.activemap["spawn"][key];
    }
    wh.registry.list.rcloading.shuffle(resarr);
    //this.plog.log("mappet helper spawn mappet, resarr", resarr);
    var peti = 0;
    for (var k in pets) {
      var petv = pets[k];
      peti++;
      var newpos = resarr[peti];
      if (newpos == undefined) newpos = [mainsprite.currentx, mainsprite.currenty];
      var mobdat = this.rcvarsservice.allmob[petv["mob"]];
      //this.plog.log("spawnpet() mappet " + k + "/", petv, mobdat);
      try {
        var tmpmappet = new MapPet(wh, {
          petid: petv["petid"],
          mobcode: petv["mob"],
          petname: petv["name"],
          sprite: mobdat['sprite'],
          mapsize: mobdat['mapsize'],
          follow: mainsprite,
          followdist: peti,
          spawnpos: newpos[0] + "-" + newpos[1]
        });
        tmpmappet.spawn();
      } catch (e) {
        console.log(e);
      }
      //console.log(this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rcvarpass.activechar.warpfrommap,this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rcvarpass.activechar,petv);
      ;
      try {
        if (this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rcvarpass.activechar["map"] == "home") {
          tmpmappet.hide(peti);
        }
      } catch (e) {
        console.log(e);
      }
    }
    return resarr;
  }
  async movemaincharto(y, x) {
    this.plog.warn('movemaincharto', x, y);
    if (
      this.rcvarsservice.gameobj.scene.scenes[0].maincharwalktween != undefined
    ) {
      this.rcvarsservice.gameobj.scene.scenes[0].maincharwalktween.complete();
      this.rcvarsservice.gameobj.scene.scenes[0].maincharwalktween.stop();
    }

    if (this.rcvarsservice.gameobj.scene.scenes[0].char_main != undefined) {
      this.rcvarsservice.gameobj.scene.scenes[0].char_main.idlecount = 0;
    }

    this.rcvarsservice.gameobj.scene.scenes[0].walkpathcmd = [];
    this.rcvarsservice.gameobj.scene.scenes[0].char_main.currentx = Number(x);
    this.rcvarsservice.gameobj.scene.scenes[0].char_main.currenty = Number(y);
    //var width = this.rcvarsservice.gameobj.canvas.width;
    //var height = this.rcvarsservice.gameobj.canvas.height;
    //peacepos
    this.rcvarsservice.gameobj.scene.scenes[0].char_main.x = ((Number(x) - 1) * 32); //+width/2;
    this.rcvarsservice.gameobj.scene.scenes[0].char_main.y = ((Number(y) - 1) * 32); //+height/2;
    //var height = this.rcvarsservice.gameobj.canvas.height;
    //this.rcvarsservice.gameobj.scene.scenes[0].char_main.x = this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.x + (x * 32); //+width/2;
    //this.rcvarsservice.gameobj.scene.scenes[0].char_main.y = this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.y + (y * 32); //+height/2;
    this.rcvarsservice.gameobj.scene.scenes[0].char_main.setOrigin(0.5, 0.8);
    this.rcvarsservice.gameobj.scene.scenes[0].char_main.visible = true;
    //this.plog.log("movemaincharto", this.rcvarsservice.gameobj.scene.scenes[0].char_main);
    this.rcvarsservice.gameobj.scene.scenes[0].pauseload = false;

    await this.rcvarsservice.gameobj.scene.scenes[0].registry.list.gameitf.updateminimap(
      this.rcvarsservice.gameobj.scene.scenes[0]
    );
    //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.x =      0 - x * 32 + width / 2;
    //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.y =      0 - y * 32 + height / 2;

    await this.rcvarsservice.gameobj.scene.scenes[0].camman(this.rcvarsservice.gameobj.scene.scenes[0]);
    await this.rcvarsservice.gameobj.scene.scenes[0].camman_refollow(this.rcvarsservice.gameobj.scene.scenes[0]);
    await this.rcvarsservice.gameobj.scene.scenes[0].updatenightlightposition();

    //fow
    this.rcvarsservice.gameobj.scene.scenes[0].registry.list.phm.fow_clear(x, y, 'revealbrush');


  }
  mapitemeffect(wh, efftectid, repeat = 0) {
    //this.plog.log('mapitemeffect', wh, efftectid, this);
    //console.log('mapitemeffect', wh, efftectid, this);
    var addypos = 0;
    var addxpos = 0;
    //if (efftectid == 'warpgate_b') addypos = 10;
    //if (efftectid == 'warpgate_b') addxpos = -3;
    var rcvartmp;
    if (wh == undefined) {
      return;
    }
    if (
      this.rcvarsservice == undefined &&
      wh.scene.registry.list.rcvarpass != undefined
    ) {
      rcvartmp = wh.scene.registry.list.rcvarpass;
    } else {
      rcvartmp = this.rcvarsservice;
    }
    var h = wh.displayHeight / 2;
    h = h - wh._displayOriginY;
    addypos += h;
    var tmpeffect;
    if (wh.scene != undefined && wh == wh.scene.char_main) {
      //mainchar, outside mapcontainer
      tmpeffect = rcvartmp.gameobj.scene.scenes[0].add.sprite(
        wh.x,
        wh.y,
        efftectid
      );
      tmpeffect.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);;
    } else {
      tmpeffect = rcvartmp.gameobj.scene.scenes[0].add.sprite(
        wh.x + addxpos,
        wh.y + addypos,
        efftectid
      );
      tmpeffect.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);;
    }

    tmpeffect.displayWidth = rcvartmp.allanims[efftectid]['size'] * 32;
    tmpeffect.scaleY = tmpeffect.scaleX;
    //this.plog.log('mapitemeffect', efftectid, rcvartmp.allanims[efftectid]);
    rcvartmp.gameobj.scene.scenes[0].mapcontainer.add(tmpeffect);

    tmpeffect.on('animationcomplete', function () {
      //this.plog.log('animationcomplete', arguments);
      //this.plog.log(arguments);
      try {
        arguments[2].destroy();
      } catch (e) {
        //console.log(e);
      }
    });
    try {
      tmpeffect.play({
        key: efftectid,
        frameRate: rcvartmp.allanims[efftectid]['fr'],
        repeat: repeat,
        /*onComplete: function () {
          this.plog.log('onComplete'); this.plog.log(arguments);
          if (arguments[1][0] != undefined)
            arguments[1][0].destroy();
        }*/
      });
    } catch (e) {
      console.log(e);
    }
    if (repeat == 0) {
      var tween = rcvartmp.gameobj.scene.scenes[0].tweens.add({
        targets: tmpeffect,
        alpha: 0,
        ease: 'Power1',
        duration: 500,
        delay: 1000,
        yoyo: false,
        repeat: 0,
        onComplete: function () {
          //this.plog.log('onComplete'); this.plog.log(arguments);
          //console.log(arguments);
          if (arguments[1][0] != undefined)
            arguments[1][0].destroy();
        },
      });
    }
    return tmpeffect;
  }
  async spawnmobx() {
    //this.plog.log("spawnmobx()", this.rcvarsservice.activemap['map']);
    this.plog.log("spawnmobx()", this.rcvarsservice.activemap);
    for (const k in this.rcvarsservice.activemap['mobxspawn']) {
      var v = this.rcvarsservice.activemap['mobxspawn'][k];
      v = v.replace("mobx", "");
      //this.plog.log("spawnmobx", k, v);
      var mobdat = this.rcvarsservice.allmob[v];
      if (mobdat == undefined) {
        continue;
      }
      var tmp = new MapMobX(this.wh, {
        spawnpos: k,
        mobcode: v,
        data: mobdat,
        sprite: mobdat['sprite'],
        mapsize: mobdat['mapsize'],
      });
      tmp.spawn();
      this.wh.mapmob.push(tmp);
    }
  }
  async spawnmob() {
    var wh = this.rcvarsservice.gameobj.scene.scenes[0];
    //this.plog.log("spawnmob", this, wh);
    //checkmob
    if (this.rcvarsservice.activemap['map']['mobteam'] == undefined) {
      return;
    }
    //this.plog.log("spawnmob()", );
    if (this.rcvarsservice.activemap['map']["activemap"] == "YES") {
      this.spawnmobx();
      return;
    }
    for (let index = 0; index <= 5; index++) {
      var mobchk =
        this.rcvarsservice.activemap['map']['mobteam']['mob' + index];
      var mobsprite = ''; //this.rcvarsservice.activemap["map"]["mobteam"]["mob" + index];
      var mobchkmin =
        this.rcvarsservice.activemap['map']['mobteam']['mob' + index + '_min'];
      var mobchkmax =
        this.rcvarsservice.activemap['map']['mobteam']['mob' + index + '_max'];
      var mobpetname =
        this.rcvarsservice.activemap['map']['mobteam']['mob' + index + '_petname'];

      mobchkmin = Number(mobchkmin);
      mobchkmax = Number(mobchkmax);
      var mobdat = this.rcvarsservice.allmob[mobchk];
      if (mobdat == undefined) {
        //this.plog.log("mobdat undefined", mobchk, mobdat);
        continue;
      }
      //mobchkmin=2;mobchkmax=5
      //this.plog.log("mobchk spawn", index, mobchk);
      if (mobchk != undefined && mobchk != '') {
        var monnum = mobchkmax; //set to max so user see more mob on map mup mup
        //Math.random() * (mobchkmax - mobchkmin) + mobchkmin;
        //this.plog.log('spawnmob ' + mobchk + ' ' + index + '/' + monnum, mobchkmin, mobchkmax, mobchk, mobdat);
        for (let index = 0; index < monnum; index++) {
          var tmp = new MapMob(wh, {
            petid: 2000 + index,
            mobcode: mobchk,
            nature: mobdat['nature'],
            petname: mobpetname,
            sprite: mobdat['sprite'],
            mapsize: mobdat['mapsize'],
          });
          tmp.spawn();
          wh.mapmob.push(tmp);
        }
      }
    }
  }
  async spawnmapwalker() {
    var wh = this.rcvarsservice.gameobj.scene.scenes[0];
    //this.plog.log("spawnmob", this, wh);
    //checkmob
    this.plog.log("spawnmapwalker()", this.rcvarsservice.activemap['map']['mapwalker'], this.rcvarsservice.activemap['map']['mapwalker'].length);

    if (this.rcvarsservice.activemap['map']['mapwalker'] == undefined) {
      return;
    }
    for (const mapwalkerk in this.rcvarsservice.activemap['map']['mapwalker']) {


      var tmp = new MapWalker(wh, {
        npccode: mapwalkerk,
        path: this.rcvarsservice.activemap['map']['mapwalker'][mapwalkerk],
        //npcname: npcnametolookfor, 
      });
      tmp.spawn();
      wh.mapwalker.push(tmp);
    }
  }
  async spawnbird() {
    var wh = this.rcvarsservice.gameobj.scene.scenes[0];
    //this.plog.log("spawnbird", this, wh);
    //checkmob

    var critterchk = this.rcvarsservice.activemap['map']['bird'] + "";
    var critterchkmin = 1;
    var critterchkmax = 4;
    var critterchktalk = "";
    var critterchkbehave = "";
    critterchkmin = Number(critterchkmin);
    critterchkmax = Number(this.rcvarsservice.activemap['map']['birdcount']);
    var critternature = "";

    //mobchkmin=2;mobchkmax=5
    //this.plog.log("critterchk spawn", index, critterchk);
    if (critterchk != undefined && critterchk != '') {
      var critternum = Math.floor(
        Math.random() * (critterchkmax - critterchkmin + 1) + critterchkmin
      );
      //this.plog.log("spawncritter " + critterchk + " " + index + "/" + critternum, critterchkmin, critterchkmax, critterchk);
      for (let index = 1; index <= critternum; index++) {
        //this.plog.log("spawningcritter " + index + "/" + critternum);
        var tmp = new MapBird(wh, {
          petid: 1000 + index,
          sprite: critterchk,
          nature: critternature,
          talk: critterchktalk,
          behave: critterchkbehave
        });
        tmp.spawn();
      }
    }
  }
  async spawncritter() {
    var wh = this.rcvarsservice.gameobj.scene.scenes[0];
    //this.plog.log("spawncritter", this, wh);
    //checkmob
    for (let index = 0; index <= 5; index++) {
      var critterchk =
        this.rcvarsservice.activemap['map']['critters'][
        'crit' + index + '_sprite'
        ];
      var critterchkcode =
        this.rcvarsservice.activemap['map']['critters'][
        'crit' + index + '_spritesubcode'
        ];
      var critterchkmin =
        this.rcvarsservice.activemap['map']['critters'][
        'crit' + index + '_min'
        ];
      var critterchkmax =
        this.rcvarsservice.activemap['map']['critters'][
        'crit' + index + '_max'
        ];
      var critterchktalk =
        this.rcvarsservice.activemap['map']['critters'][
        'crit' + index + '_talk'
        ];
      var critterchkbehave =
        this.rcvarsservice.activemap['map']['critters'][
        'crit' + index + '_behave'
        ];
      critterchkmin = Number(critterchkmin);
      critterchkmax = Number(critterchkmax);
      var critternature =
        this.rcvarsservice.activemap['map']['critters'][
        'crit' + index + '_behave'
        ];

      //mobchkmin=2;mobchkmax=5
      //this.plog.log("critterchk spawn", index, critterchk);
      if (critterchk != undefined && critterchk != '') {
        var critternum = Math.floor(
          Math.random() * (critterchkmax - critterchkmin + 1) + critterchkmin
        );
        //this.plog.log("spawncritter " + critterchk + " " + index + "/" + critternum, critterchkmin, critterchkmax, critterchk);
        for (let index = 0; index <= critternum; index++) {
          //this.plog.log("spawningcritter " + index + "/" + critternum);
          var tmp = new MapCritter(wh, {
            sprite: critterchk,
            nature: critternature,
            subcode: critterchkcode,
            talk: critterchktalk,
            behave: critterchkbehave
          });
          tmp.spawn();
        }
      }
    }
  }

  async loadcatsprite(sprite) {
    this.plog.log('loadcatsprite start', sprite, this.rcvarsservice.gameobj.textures.list);
    if (this.rcvarsservice.gameobj.scene.scenes[0].spriteliveloaded[sprite] != undefined) {
      //console.log("loadcatsprite skip spriteliveloaded", sprite);
      return;
    } else {
      //console.log("loadcatsprite OK spriteliveloaded", sprite,this.rcvarsservice.gameobj.scene.scenes[0].spriteliveloaded);
    }
    this.rcvarsservice.gameobj.scene.scenes[0].spriteliveloaded[sprite] = 1;

    if (
      this.rcvarsservice.gameobj.textures.list[sprite] != '' &&
      this.rcvarsservice.gameobj.textures.list[sprite] != undefined
    ) {
      //loaded
      //console.log("loadcatsprite skip exists - jump to loaded", sprite);
      this.loadcatsprite_loaded(sprite);
      return;
    }
    //this.plog.log("loadcatsprite load new, since not exists", this);

    var loaderlocal = new Phaser.Loader.LoaderPlugin(
      this.rcvarsservice.gameobj.scene.scenes[0]
    ); // + Math.random()+ Math.random()
    var svversionurlstring = this.rcvarsservice.svversionstring;
    if (svversionurlstring != '') {
      svversionurlstring = '?' + svversionurlstring;
    }
    loaderlocal.atlas(
      sprite + '',
      this.rcvarsservice.rc_baseurl +
      'sprite/' +
      sprite +
      '.png' +
      svversionurlstring,
      this.rcvarsservice.rc_baseurl +
      'sprite/' +
      sprite +
      '.json' +
      svversionurlstring
    );
    //this.plog.log("loadcatsprite", Phaser.Loader.Events, sprite, this, loaderlocal);
    loaderlocal.once(
      Phaser.Loader.Events.COMPLETE,
      () => {
        // texture loaded so use instead of the placeholder
        this.loadcatsprite_loaded(sprite);
        //this.plog.log("oncomplete ", this);
      },
      this
    );
    //this.plog.log("loadcatsprite afterset", Phaser.Loader.Events);
    loaderlocal.start();
  }
  async loadcatsprite_loaded(sprite) {
    //this.plog.log('loadcatsprite_loaded', sprite);//, this);
    if (this.rcvarsservice.gameobj.anims.anims.entries[sprite] != undefined) {
      console.log("catsprite exists", sprite);
      return;
    }
    var spriteset = [
      { code: 'attack', framename: 'attack', fr: 8, end: 7 },
      { code: 'crouch', framename: 'crouch', fr: 8, end: 8 },
      { code: 'dash', framename: 'dash', fr: 8, end: 9 },
      { code: 'faint', framename: 'faint', fr: 8, end: 8 },
      { code: 'fall', framename: 'fall', fr: 8, end: 3 },
      { code: 'fright', framename: 'fright', fr: 8, end: 8 },
      { code: 'hurt', framename: 'hurt', fr: 8, end: 4 },
      { code: 'idleblink', framename: 'idleblink', fr: 8, end: 8 },
      { code: 'idle', framename: 'idle', fr: 8, end: 8 },
      { code: 'jump', framename: 'jump', fr: 8, end: 4 },
      { code: 'land', framename: 'land', fr: 8, end: 2 },
      { code: 'ledgeclimb', framename: 'ledgeclimb', fr: 8, end: 11 },
      { code: 'ledgeclimbstruggle', framename: 'ledgeclimbstruggle', fr: 8, end: 12 },
      { code: 'ledgegrab', framename: 'ledgegrab', fr: 8, end: 5 },
      { code: 'ledgeidle', framename: 'ledgeidle', fr: 8, end: 8 },
      { code: 'liedown', framename: 'liedown', fr: 8, end: 24 },
      { code: 'run', framename: 'run', fr: 8, end: 4 },
      { code: 'sit', framename: 'sit', fr: 8, end: 8 },
      { code: 'sneak', framename: 'sneak', fr: 8, end: 8 },
      { code: 'walk', framename: 'walk', fr: 16, end: 8 },
      { code: 'wallclimb', framename: 'wallclimb', fr: 8, end: 8 },
      { code: 'wallgrab', framename: 'wallgrab', fr: 8, end: 8 },
    ];
    for (var idx in spriteset) {
      var val = spriteset[idx];
      //this.plog.log("loadcatsprite", idx, val);
      if (this.rcvarsservice.gameobj.textures.game.textures.list[sprite].frames[val['framename'] + '-1'] == undefined) {
        //this.plog.log("loadcatsprite_loaded xx skip, textures not found", val['framename'] + '-1');
        continue;
      }
      ///this.rcvarsservice.gameobj.anims.remove(sprite + '-' + val['code']);
      this.rcvarsservice.gameobj.anims.create({
        key: sprite + '-' + val['code'],
        frames: this.rcvarsservice.gameobj.anims.generateFrameNames(sprite, {
          prefix: val['framename'] + '-',
          end: val['end'],
          zeroPad: 0,
          start: 1,
        }),
        repeat: -1,
        frameRate: val['fr'],
      });
    }
    //this.plog.log("loadcatsprite_loaded anims after", this.rcvarsservice.gameobj.anims);
    /*this.rcvarsservice.gameobj.scene.scenes[0].registry.list.gameitf.charinfoupdated(
      this.rcvarsservice.gameobj.scene.scenes[0]
    );*/
    this.updatezmapchar('usecache'); // also update zmapchar, since this function also use to load zmapchar
    this.respawnpet();

  }
  async loadcharsprite(sprite) {
    //this.plog.log(      'loadcharsprite start',      sprite,      this.rcvarsservice.gameobj.textures.list    );
    if (this.rcvarsservice.gameobj.scene.scenes[0].spriteliveloaded[sprite] != undefined) {
      //this.plog.log("loadcharsprite skip spriteliveloaded", sprite);
      return;
    }
    if (
      this.rcvarsservice.gameobj.textures.list[sprite] != '' &&
      this.rcvarsservice.gameobj.textures.list[sprite] != undefined
    ) {
      //loaded
      //this.plog.log("loadcharsprite skip exists", sprite);
      this.loadcharsprite_loaded(sprite);
      return;
    }
    //this.plog.log("loadcharsprite load new, since not exists", this);
    this.rcvarsservice.gameobj.scene.scenes[0].spriteliveloaded[sprite] = 1;

    var loaderlocal = new Phaser.Loader.LoaderPlugin(
      this.rcvarsservice.gameobj.scene.scenes[0]
    ); // + Math.random()+ Math.random()
    var svversionurlstring = this.rcvarsservice.svversionstring;
    if (svversionurlstring != '') {
      svversionurlstring = '?' + svversionurlstring;
    }
    loaderlocal.atlas(
      sprite + '',
      this.rcvarsservice.rc_baseurl +
      'sprite/' +
      sprite +
      '.png' +
      svversionurlstring,
      this.rcvarsservice.rc_baseurl +
      'sprite/' +
      sprite +
      '.json' +
      svversionurlstring
    );
    //this.plog.log("loadcharsprite", Phaser.Loader.Events, sprite, this, loaderlocal);
    loaderlocal.once(
      Phaser.Loader.Events.COMPLETE,
      () => {
        // texture loaded so use instead of the placeholder
        this.loadcharsprite_loaded(sprite);
        //this.plog.log("oncomplete ", this);
      },
      this
    );
    //this.plog.log("loadcharsprite afterset", Phaser.Loader.Events);
    loaderlocal.start();
  }
  async loadcharsprite_loaded(sprite) {
    this.plog.log('loadcharsprite_loaded', sprite);//, this);

    var spriteset = [
      { code: 'idle', framename: 'walk-d', fr: 1, end: 1 },
      { code: 'walk-d', framename: 'walk-d', fr: 16, end: 9 },
      { code: 'walk-u', framename: 'walk-u', fr: 16, end: 9 },
      { code: 'walk-l', framename: 'walk-l', fr: 16, end: 9 },
      { code: 'walk-r', framename: 'walk-r', fr: 16, end: 9 },
      { code: 'thrust-d', framename: 'thrust-d', fr: 8, end: 8 },
      { code: 'thrust-u', framename: 'thrust-u', fr: 8, end: 8 },
      { code: 'thrust-l', framename: 'thrust-l', fr: 8, end: 8 },
      { code: 'thrust-r', framename: 'thrust-r', fr: 8, end: 8 },
      { code: 'spellcast-d', framename: 'spellcast-d', fr: 7, end: 7 },
      { code: 'spellcast-u', framename: 'spellcast-u', fr: 7, end: 7 },
      { code: 'spellcast-l', framename: 'spellcast-l', fr: 7, end: 7 },
      { code: 'spellcast-r', framename: 'spellcast-r', fr: 7, end: 7 },
      { code: 'slash-d', framename: 'slash-d', fr: 6, end: 6 },
      { code: 'slash-u', framename: 'slash-u', fr: 6, end: 6 },
      { code: 'slash-l', framename: 'slash-l', fr: 6, end: 6 },
      { code: 'slash-r', framename: 'slash-r', fr: 6, end: 6 },
      { code: 'shoot-d', framename: 'shoot-d', fr: 13, end: 13 },
      { code: 'shoot-u', framename: 'shoot-u', fr: 13, end: 13 },
      { code: 'shoot-l', framename: 'shoot-l', fr: 13, end: 13 },
      { code: 'shoot-r', framename: 'shoot-r', fr: 13, end: 13 },
      { code: 'atk-d', framename: 'atk-d', fr: 6, end: 6 },
      { code: 'atk-u', framename: 'atk-u', fr: 6, end: 6 },
      { code: 'atk-l', framename: 'atk-l', fr: 6, end: 6 },
      { code: 'atk-r', framename: 'atk-r', fr: 6, end: 6 },
      { code: 'walk-d-idle', framename: 'idle-d', fr: 3, end: 3 },
      { code: 'walk-u-idle', framename: 'idle-u', fr: 3, end: 3 },
      { code: 'walk-l-idle', framename: 'idle-l', fr: 3, end: 3 },
      { code: 'walk-r-idle', framename: 'idle-r', fr: 3, end: 3 },
      { code: 'idle-d', framename: 'idle-d', fr: 3, end: 3 },
      { code: 'idle-u', framename: 'idle-u', fr: 3, end: 3 },
      { code: 'idle-l', framename: 'idle-l', fr: 3, end: 3 },
      { code: 'idle-r', framename: 'idle-r', fr: 3, end: 3 },
      { code: 'dead', framename: 'dead', fr: 6, end: 6 },
      { code: 'climb', framename: 'climb', fr: 6, end: 6 },
      { code: 'sit-u', framename: 'sit-u', fr: 1, end: 1 },
      { code: 'sit-d', framename: 'sit-d', fr: 1, end: 1 },
      { code: 'sit-r', framename: 'sit-r', fr: 1, end: 1 },
      { code: 'sit-l', framename: 'sit-l', fr: 1, end: 1 },
      { code: 'swim-u', framename: 'swim-u', fr: 1, end: 2 },
      { code: 'swim-d', framename: 'swim-d', fr: 1, end: 2 },
      { code: 'swim-r', framename: 'swim-r', fr: 1, end: 2 },
      { code: 'swim-l', framename: 'swim-l', fr: 1, end: 2 },
    ];
    for (var idx in spriteset) {
      var val = spriteset[idx];
      //this.plog.error("loadcharsprite", idx, val);
      if (this.rcvarsservice.gameobj.textures.game.textures.list[sprite].frames[val['framename'] + '-1'] == undefined) {
        //this.plog.log("loadcharsprite_loaded xx skip, textures not found", val['framename'] + '-1');
        continue;
      }
      this.rcvarsservice.gameobj.anims.remove(sprite + '-' + val['code']);
      this.rcvarsservice.gameobj.anims.create({
        key: sprite + '-' + val['code'],
        frames: this.rcvarsservice.gameobj.anims.generateFrameNames(sprite, {
          prefix: val['framename'] + '-',
          end: val['end'],
          zeroPad: 0,
          start: 1,
        }),
        repeat: -1,
        frameRate: val['fr'],
      });
    }
    //this.plog.log("loadcharsprite_loaded anims after", this.rcvarsservice.gameobj.anims);
    this.rcvarsservice.gameobj.scene.scenes[0].registry.list.gameitf.charinfoupdated(
      this.rcvarsservice.gameobj.scene.scenes[0]
    );
    this.updatezmapchar('usecache'); // also update zmapchar, since this function also use to load zmapchar
  }
  updatezmapcharwalk(k) {
    //this.plog.log("updatezmapcharwalk", k);
    if (this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k] == undefined)
      return;
    if (
      this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].anims ==
      undefined
    )
      return;
    if (
      this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].walkcmd !=
      undefined &&
      this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].walkcmd
        .length == 0 &&
      this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].anims
        .currentAnim != undefined
    ) {
      this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].anims.pause(
        this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].anims
          .currentAnim.frames[0]
      );
    }
    if (
      this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].walkcmd !=
      undefined &&
      this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].walkcmd
        .length == 0
    )
      return;
    var nextstop =
      this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[
        k
      ].walkcmd.shift();

    var dir;
    if (
      nextstop[0] >
      this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].currentx &&
      nextstop[1] >
      this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].currenty
    ) {
      dir = 'down right';
    }
    if (
      nextstop[0] >
      this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].currentx &&
      nextstop[1] <
      this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].currenty
    ) {
      dir = 'up right';
    }
    if (
      nextstop[0] <
      this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].currentx &&
      nextstop[1] <
      this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].currenty
    ) {
      dir = 'up left';
    }
    if (
      nextstop[0] <
      this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].currentx &&
      nextstop[1] >
      this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].currenty
    ) {
      dir = 'down left';
    }
    if (
      nextstop[0] ==
      this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].currentx &&
      nextstop[1] >
      this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].currenty
    ) {
      dir = 'down';
    }
    if (
      nextstop[0] ==
      this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].currentx &&
      nextstop[1] <
      this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].currenty
    ) {
      dir = 'up';
    }
    if (
      nextstop[0] >
      this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].currentx &&
      nextstop[1] ==
      this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].currenty
    ) {
      dir = 'right';
    }
    if (
      nextstop[0] <
      this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].currentx &&
      nextstop[1] ==
      this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].currenty
    ) {
      dir = 'left';
    }
    //this.plog.log("updatezmapcharwalk", k, "nextstop", nextstop, "dir", dir);

    var newcx = 0;
    var newcy = 0;
    /////////////////////////////////
    newcx = 0; //this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].currentx;
    newcy = 0; // this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].currenty;
    var walkspeednormal = 140;
    var walkspeedslow = 170;
    walkspeednormal = 200;
    walkspeedslow = 220;
    var walkspeed = walkspeednormal;
    var spritename =
      this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k]['spritex'];
    if (this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k] == undefined)
      return;

    if (this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].lastspriteface == undefined) {
      this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].lastspriteface = "down";
    }
    if (dir == 'up') {
      newcy -= 1;
      try {
        if (
          this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k] !=
          undefined
        ) {
          this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].lastspriteface = "up";
          if (this.rcvarsservice.gameobj.scene.scenes[0].isladder(this.rcvarsservice.gameobj.scene.scenes[0], this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].currentx, this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].currenty)) {
            this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].play(
              spritename + '-climb',
              true
            );
          } else {
            this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].play(
              spritename + '-walk-u',
              true
            );
          }
        }
      } catch (error) {
        this.plog.log('trycatch', error);
      }
    }
    if (dir == 'up right') {
      newcy -= 1;
      newcx += 1;
      try {
        if (
          this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k] !=
          undefined
        ) {
          this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].lastspriteface = "right";
          if (this.rcvarsservice.gameobj.scene.scenes[0].isladder(this.rcvarsservice.gameobj.scene.scenes[0], this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].currentx, this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].currenty)) {
            this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].play(
              spritename + '-climb',
              true
            );
          } else {
            this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].play(
              spritename + '-walk-r',
              true
            );
          }
        }
      } catch (error) {
        this.plog.log('trycatch', error);
      }
      walkspeed = walkspeedslow;
    }
    if (dir == 'right') {
      newcx += 1;
      try {
        if (
          this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k] !=
          undefined
        ) {
          this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].lastspriteface = "right";
          if (this.rcvarsservice.gameobj.scene.scenes[0].isladder(this.rcvarsservice.gameobj.scene.scenes[0], this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].currentx, this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].currenty)) {
            this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].play(
              spritename + '-climb',
              true
            );
          } else {
            this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].play(
              spritename + '-walk-r',
              true
            );
          }
        }
      } catch (error) {
        this.plog.log('trycatch', error);
      }
    }
    if (dir == 'down right') {
      newcy += 1;
      newcx += 1;
      try {
        if (
          this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k] !=
          undefined
        ) {
          this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].lastspriteface = "right";
          if (this.rcvarsservice.gameobj.scene.scenes[0].isladder(this.rcvarsservice.gameobj.scene.scenes[0], this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].currentx, this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].currenty)) {
            this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].play(
              spritename + '-climb',
              true
            );
          } else {
            this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].play(
              spritename + '-walk-r',
              true
            );
          }
        }
      } catch (error) {
        this.plog.log('trycatch', error);
      }
      walkspeed = walkspeedslow;
    }
    if (dir == 'down') {
      newcy += 1;
      try {
        if (
          this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k] !=
          undefined
        ) {
          this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].lastspriteface = "down";
          if (this.rcvarsservice.gameobj.scene.scenes[0].isladder(this.rcvarsservice.gameobj.scene.scenes[0], this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].currentx, this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].currenty)) {
            this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].play(
              spritename + '-climb',
              true
            );
          } else {
            this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].play(
              spritename + '-walk-d',
              true
            );
          }
        }
      } catch (error) {
        this.plog.log('trycatch', error);
      }
    }
    if (dir == 'down left') {
      newcy += 1;
      newcx -= 1;
      try {
        if (
          this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k] !=
          undefined
        ) {
          this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].lastspriteface = "left";
          if (this.rcvarsservice.gameobj.scene.scenes[0].isladder(this.rcvarsservice.gameobj.scene.scenes[0], this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].currentx, this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].currenty)) {
            this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].play(
              spritename + '-climb',
              true
            );
          } else {
            this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].play(
              spritename + '-walk-l',
              true
            );
          }
        }
      } catch (error) {
        this.plog.log('trycatch', error);
      }
      walkspeed = walkspeedslow;
    }
    if (dir == 'left') {
      newcx -= 1;
      try {
        if (
          this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k] !=
          undefined
        ) {
          this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].lastspriteface = "left";
          if (this.rcvarsservice.gameobj.scene.scenes[0].isladder(this.rcvarsservice.gameobj.scene.scenes[0], this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].currentx, this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].currenty)) {
            this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].play(
              spritename + '-climb',
              true
            );
          } else {
            this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].play(
              spritename + '-walk-l',
              true
            );
          }
        }
      } catch (error) {
        this.plog.log('trycatch', error);
      }
    }
    if (dir == 'up left') {
      newcy -= 1;
      newcx -= 1;
      try {
        if (
          this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k] !=
          undefined
        ) {
          this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].lastspriteface = "left";
          if (this.rcvarsservice.gameobj.scene.scenes[0].isladder(this.rcvarsservice.gameobj.scene.scenes[0], this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].currentx, this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].currenty)) {
            this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].play(
              spritename + '-climb',
              true
            );
          } else {
            this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].play(
              spritename + '-walk-l',
              true
            );
          }
        }
      } catch (error) {
        this.plog.log('trycatch', error);
      }
      walkspeed = walkspeedslow;
    }
    //this.plog.log("updatezmapcharwalk", this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k], "newcx ", newcx, "newcy", newcy);
    this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].currentx +=
      Number(newcx);
    this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].currenty +=
      Number(newcy);
    var tweenzchar = this.rcvarsservice.gameobj.scene.scenes[0].tweens.add({
      targets: this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k],
      x:
        (this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].currentx - 1) *
        32,
      y:
        (this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].currenty - 1) *
        32,
      ease: 'Power1',
      duration: walkspeed,
      repeat: 0,
      onComplete: function () {
        //this.plog.log('onComplete'); this.plog.log(arguments);
        this.updatezmapcharwalk(k);
      }.bind(this),
    });
  }
  voicethis_lastid = 0;
  voicethis_lastplay = [];
  async voicethis(wh, data) {
    //this.plog.log("voicethis()", data, foundv);
    var maxfreq = 2; //how often a sound can play
    if (this.voicethis_lastplay[data] == undefined) this.voicethis_lastplay[data] = 0;
    var sincelastplay = this.rcvarsservice.gameobj.registry.list.phaserGame.scene.scenes[0].registry.list.timectl.timerunsec - this.voicethis_lastplay[data];
    //console.log("snd() chk", data, sincelastplay, this.voicethis_lastplay);
    if (this.voicethis_lastplay[data] == 0 || sincelastplay > maxfreq) {
      //ok
      this.voicethis_lastplay[data] = this.rcvarsservice.gameobj.registry.list.phaserGame.scene.scenes[0].registry.list.timectl.timerunsec;
    } else {
      return;
    }
    var foundv = await wh.registry.list.rchttp.getcommon2("getvoice", { cate: data });
    //this.plog.log("voicethis() data", foundv);
    var voiceid = foundv['file'];
    var voicecate = foundv['cate'];
    this.voicethis_lastid = foundv['id'];
    var loaderlocal = new Phaser.Loader.LoaderPlugin(
      wh
    ); // + Math.random()+ Math.random()
    var svversionurlstring = this.rcvarsservice.svversionstring;
    if (svversionurlstring != '') {
      svversionurlstring = '?' + svversionurlstring;
    }
    loaderlocal.audio(
      'voice_' + foundv['id'] + '',
      this.rcvarsservice.rc_baseurl +
      's-voice/' + voicecate + '/' + voiceid +
      svversionurlstring
    );
    var tmpsay = voiceid + "";
    tmpsay = tmpsay.replace(".mp3", "");
    tmpsay = tmpsay.replace("m.", "");
    tmpsay = tmpsay.replace("f.", "");
    tmpsay = tmpsay.split('-').join(' ');
    //this.plog.log("voicethis tmpsay",tmpsay);
    //this.plog.log("charsaytxt",this.rcvarsservice.gameobj.scene.scenes[0].charsaybubble_txt,this.rcvarsservice.gameobj.scene.scenes[0].charsaybubble_txt.visible); 
    if (this.rcvarsservice.gameobj.scene.scenes[0].charsaybubble_txt == undefined || (this.rcvarsservice.gameobj.scene.scenes[0].charsaybubble_txt != undefined && this.rcvarsservice.gameobj.scene.scenes[0].charsaybubble_txt.visible == false)) {
      if (tmpsay != "undefined" && tmpsay != undefined) {
        this.charsaybubble(this.rcvarsservice.gameobj.scene.scenes[0].char_main, tmpsay);
      }
    }
    loaderlocal.once(
      Phaser.Loader.Events.COMPLETE,
      () => {
        this.voicethis_loaded();
      },
      this
    );
    loaderlocal.start();
  }
  voicethis_loaded() {
    this.plog.log("voicethis_loaded()", this);
    this.plog.log("voicethis_lastid=", this.voicethis_lastid);
    try {
      let sfx = this.wh.sound.add('voice_' + this.voicethis_lastid);
      sfx.play({ volume: 0.8 });
    } catch (e) {
      this.plog.log("ERROR", e);
    }
  }
  npcvoice_lastid = 0;

  async npcvoice(wh, data) {
    this.plog.log("npcvoice()", data);
    this.npcvoice_lastid = data;

    var loaderlocal = new Phaser.Loader.LoaderPlugin(
      wh
    ); // + Math.random()+ Math.random()
    var svversionurlstring = this.rcvarsservice.svversionstring;
    if (svversionurlstring != '') {
      svversionurlstring = '?' + svversionurlstring;
    }
    loaderlocal.audio(
      'npcvoice_' + data + '',
      this.rcvarsservice.rc_baseurl +
      '' + data +
      svversionurlstring
    );
    loaderlocal.once(
      Phaser.Loader.Events.COMPLETE,
      () => {
        this.npcvoice_loaded();
      },
      this
    );
    loaderlocal.start();
  }
  npcvoice_loaded() {
    this.plog.log("npcvoice_loaded()", this);
    try {
      let sfx = this.wh.sound.add('npcvoice_' + this.npcvoice_lastid);
      sfx.play({ volume: 0.9 });
    } catch (e) {
      this.plog.log("ERROR", e);
    }
  }

  charsaybubble(charsprite, txt, mode = "say", delayhide = 0) {
    //this.plog.log("charsaybubble()", charsprite, txt, mode);

    if (txt.length < 3) txt = ' ' + txt + ' ';
    if (charsprite.charsaybubbleobj1 != undefined) {
      charsprite.charsaybubbleobj1.destroy();//true
      charsprite.charsaybubbleobj1 = undefined;
    }
    if (charsprite.charsaybubbleobj2 != undefined) {
      charsprite.charsaybubbleobj2.destroy();//true
      charsprite.charsaybubbleobj2 = undefined;
    }
    if (charsprite.charsaybubbleobj3 != undefined) {
      charsprite.charsaybubbleobj3.destroy();//true
      charsprite.charsaybubbleobj3 = undefined;
    }
    txt = this.rcvarsservice.gameobj.scene.scenes[0].gl(txt);
    if (delayhide == 0) delayhide = 3700;
    if (txt == "") return;
    if (txt != undefined && txt.length == 10 && txt.indexOf(":emote:") != -1) {
      var emodek = txt.replace(":emote:", "");
      emodek = emodek.replace(":", "");
      charsprite.charsaybubbleobj1 = this.rcvarsservice.gameobj.scene.scenes[0].add.sprite(0, 0, "emotes", "aa-1");
      if (this.wh.anims.anims.entries[emodek] == undefined) {
        //create anim
        this.rcvarsservice.gameobj.anims.create({
          key: "emote_" + emodek,
          frames: this.rcvarsservice.gameobj.anims.generateFrameNames("emotes", {
            prefix: emodek + '-',
            end: 3,
            zeroPad: 0,
            start: 1,
          }),
          repeat: -1,
          frameRate: 5,
        });
      }
      charsprite.charsaybubbleobj1.play("emote_" + emodek);

      charsprite.parentContainer.add(charsprite.charsaybubbleobj1);
      charsprite.parentContainer.bringToTop(charsprite.charsaybubbleobj1);
      charsprite.charsaybubbleobj1.setOrigin(0.5, 0.5);
      this.rcvarsservice.gameobj.scene.scenes[0].mapsayitems.push(charsprite.charsaybubbleobj1);


      var posa = []; //v["mappos"].split("-");
      posa[0] = Number(charsprite.x);
      posa[1] = Number(charsprite.y) - 24;

      charsprite.charsaybubbleobj1.setPosition(
        posa[0],
        posa[1] -
        charsprite._displayOriginY
        //(0 - this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.y) -
      );

      var tw = this.rcvarsservice.gameobj.scene.scenes[0].tweens.add({
        targets: [charsprite.charsaybubbleobj1],
        alpha: { start: 1, from: 1, to: 0 },
        ease: 'Power3',
        duration: 500, // duration of animation; higher=slower
        delay: delayhide, // wait 500 ms before starting
        onComplete: function () {
          // .log("charsay tweencomplete", this);
          try {
            this.targets[0].destroy();//true
          } catch (e) { this.plog.log("ERROR", e); }
        },
      });
      return;
    }


    this.rcvarsservice.gameobj.scene.scenes[0].charsaybubble_txt = this.rcvarsservice.gameobj.scene.scenes[0].add.text(
      0,
      0,
      '' + txt,
      this.rcvarsservice.gameobj.scene.scenes[0].fs["charsaybubble"]
    );
    this.rcvarsservice.gameobj.scene.scenes[0].charsaybubble_txt.setWordWrapWidth(200);

    //if (tmp.displayWidth < 20) tmp.displayWidth = 20;
    var posa = []; //v["mappos"].split("-");
    posa[0] = Number(charsprite.x);
    posa[1] = Number(charsprite.y) - 24;

    if (charsprite.parentContainer != undefined) {
      //posa[0]=posa[0]+charsprite.parentContainer.x
      //posa[1]=posa[1]+charsprite.parentContainer.y
    }
    //this.plog.log(charsprite);

    //this.plog.log("updatezmapchat", v, tmp);
    var useimg = "chat";
    if (mode == "think") useimg = "think";
    var graphics = this.rcvarsservice.gameobj.scene.scenes[0].add.image(
      0,
      0,
      useimg + 'bubble'
    );
    var graphics2 = this.rcvarsservice.gameobj.scene.scenes[0].add.image(
      0,
      0,
      useimg + 'bubblearrow'
    );


    if (charsprite.parentContainer != undefined) {
      charsprite.parentContainer.add(graphics);
      charsprite.parentContainer.bringToTop(graphics);
      charsprite.parentContainer.add(graphics2);
      charsprite.parentContainer.bringToTop(graphics2);
      charsprite.parentContainer.add(this.rcvarsservice.gameobj.scene.scenes[0].charsaybubble_txt);
      charsprite.parentContainer.bringToTop(this.rcvarsservice.gameobj.scene.scenes[0].charsaybubble_txt);
    }
    this.rcvarsservice.gameobj.scene.scenes[0].mapsayitems.push(graphics);
    this.rcvarsservice.gameobj.scene.scenes[0].mapsayitems.push(graphics2);
    this.rcvarsservice.gameobj.scene.scenes[0].mapsayitems.push(this.rcvarsservice.gameobj.scene.scenes[0].charsaybubble_txt);

    this.rcvarsservice.gameobj.scene.scenes[0].charsaybubble_txt.setPosition(
      posa[0] -
      //(0 - this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.x) -
      (this.rcvarsservice.gameobj.scene.scenes[0].charsaybubble_txt.displayWidth / 2 + 0),
      posa[1] -
      charsprite._displayOriginY -
      //(0 - this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.y) -
      this.rcvarsservice.gameobj.scene.scenes[0].charsaybubble_txt.displayHeight / 2
    );
    //this.plog.log("charsaybubble pos",charsprite.x,charsprite.y,tmp.x,tmp.y)

    /*
    graphics.fillStyle(0xffffff, 1);
    //  32px radius on the corners
    graphics.fillRoundedRect(tmp.x - 4, tmp.y - 2, tmp.displayWidth + 8, tmp.displayHeight + 4, 3);
    graphics.strokeRoundedRect(tmp.x - 4, tmp.y - 2, tmp.displayWidth + 8, tmp.displayHeight + 4, 3);
    graphics.lineStyle(1, 0x7777ff, 1);
 
 
    graphics.lineStyle(4, 0x222222, 0.5);
    //bubble.lineBetween(point2X - 1, point2Y + 6, point3X + 2, point3Y);
    var point1X, point1Y, point2X, point2Y, point3X, point3Y;
    point1X = (tmp.x + (tmp.displayWidth / 2)) - 3;
    point1Y = tmp.y + (tmp.displayHeight) + 1;
    point2X = (tmp.x + (tmp.displayWidth / 2)) + 3;
    point2Y = tmp.y + (tmp.displayHeight) + 1;
    point3X = tmp.x + (tmp.displayWidth / 2);
    point3Y = tmp.y + (tmp.displayHeight) + 5;
    //  Bubble arrow fill
    graphics.fillTriangle(point1X, point1Y, point2X, point2Y, point3X, point3Y);
    graphics.lineStyle(2, 0x565656, 1);
    graphics.lineBetween(point2X, point2Y, point3X, point3Y);
    graphics.lineBetween(point1X, point1Y, point3X, point3Y);
 
 
    //  Using an object to define a different radius per corner
    graphics.setDepth(600000);
    //}
    */
    graphics.displayWidth = this.rcvarsservice.gameobj.scene.scenes[0].charsaybubble_txt.displayWidth + 8;
    graphics.displayHeight = this.rcvarsservice.gameobj.scene.scenes[0].charsaybubble_txt.displayHeight + 3;
    graphics.setPosition(
      this.rcvarsservice.gameobj.scene.scenes[0].charsaybubble_txt.x + this.rcvarsservice.gameobj.scene.scenes[0].charsaybubble_txt.displayWidth / 2,
      this.rcvarsservice.gameobj.scene.scenes[0].charsaybubble_txt.y + this.rcvarsservice.gameobj.scene.scenes[0].charsaybubble_txt.displayHeight / 2
    );
    graphics2.setPosition(
      this.rcvarsservice.gameobj.scene.scenes[0].charsaybubble_txt.x + this.rcvarsservice.gameobj.scene.scenes[0].charsaybubble_txt.displayWidth / 2,
      this.rcvarsservice.gameobj.scene.scenes[0].charsaybubble_txt.y + this.rcvarsservice.gameobj.scene.scenes[0].charsaybubble_txt.displayHeight + 4
    );
    graphics.setOrigin(0.5, 0.5);
    graphics.setDepth(400000);
    graphics2.setDepth(400000);
    if (mode == "think") {
      graphics2.setPosition(graphics2.x, graphics2.y + 2)
    }
    this.rcvarsservice.gameobj.scene.scenes[0].charsaybubble_txt.setDepth(400010);

    charsprite.charsaybubbleobj1 = this.rcvarsservice.gameobj.scene.scenes[0].charsaybubble_txt;
    charsprite.charsaybubbleobj2 = graphics;
    charsprite.charsaybubbleobj3 = graphics2;
    var tw = this.rcvarsservice.gameobj.scene.scenes[0].tweens.add({
      targets: [this.rcvarsservice.gameobj.scene.scenes[0].charsaybubble_txt,
        graphics,
        graphics2],
      alpha: { start: 1, from: 1, to: 0 },
      ease: 'Power3',
      duration: 500, // duration of animation; higher=slower
      delay: delayhide, // wait 500 ms before starting
      onComplete: function () {
        //this.plog.log("charsay tweencomplete", this);
        try {
          this.targets[0].destroy();//true
        } catch (e) { this.plog.log("ERROR", e); }
        try {
          this.targets[1].destroy();//true
        } catch (e) { this.plog.log("ERROR", e); }
        try {
          this.targets[2].destroy();//true
        } catch (e) { this.plog.log("ERROR", e); }
      },
      onUpdate: function () {
        return;
        //this.plog.log("charsay tweenupdate", this,arguments);
        if (this.scene == undefined) return;
        arguments[0].targets[0].setPosition(
          this.x -
          (0 - this.scene.mapcontainer.x) -
          (arguments[0].targets[0].displayWidth / 2 + 0),
          this.y -
          this._displayOriginY -
          (0 - this.scene.mapcontainer.y) -
          arguments[0].targets[0].displayHeight / 2
        );
        arguments[0].targets[1].setPosition(
          arguments[0].targets[0].x + arguments[0].targets[0].displayWidth / 2,
          arguments[0].targets[0].y + arguments[0].targets[0].displayHeight / 2
        );
        arguments[0].targets[2].setPosition(
          arguments[0].targets[0].x + arguments[0].targets[0].displayWidth / 2,
          arguments[0].targets[0].y + arguments[0].targets[0].displayHeight + 4
        );
      }.bind(charsprite),
    });
    this.rcvarsservice.gameobj.scene.scenes[0].registry.list.phm.fow_clear(charsprite.currentx, charsprite.currenty, 'revealbrush-light');

  }
  hidemapsayitems() {
    for (const k in this.rcvarsservice.gameobj.scene.scenes[0].mapsayitems) {
      try {
        var v = this.rcvarsservice.gameobj.scene.scenes[0].mapsayitems[k];
      } catch (e) {
        this.plog.log("ERROR", e);
      }
      try {
        v.visible = false;
      } catch (e) {
        this.plog.log("ERROR", e);
      }
    }

  }
  zmapchareffect(data) {
    //console.log("zmapchareffect", data);
    if (data == undefined || data == '') return;
    if (this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer == undefined)
      return;
    if (data["effect"] == undefined || data["effect"] == '') return;
    if (data["effect"]["charid"] == undefined || data["effect"]["charid"] == '') return;
    if (data["effect"]["effect"] == undefined || data["effect"]["effect"] == '') return;
    if (data["effect"]["text"] == undefined || data["effect"]["text"] == '') return;
    if (this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist == undefined) return;
    if (this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[data["effect"]["charid"]] == undefined) return;
    var usetext = "" + data["effect"]["text"];

    switch (usetext) {
      case "sharereward":
        usetext = "ShareReward";
        break;
      case "redo":
        usetext = "REDO!";
        break;
      case "redo4":
        usetext = "REDOx4!";
        break;
      case "redo9":
        usetext = "REDOx9!";
        break;
      case "redo16":
        usetext = "REDOx16!";
        break;
      case "levelup":
        usetext = "Level Up!";
        break;
      case "attup":
        usetext = "AttributeUp";
        break;
      case "donate":
        usetext = "Donate";
        break;

      default:
        break;
    }

    this.zmapchareffect_osdflash(this.rcvarsservice.gameobj.scene.scenes[0],
      this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[data["effect"]["charid"]], usetext
    )
    if (this.rcvarsservice.gameobj.scene.scenes[0].anims.anims.entries["" + data["effect"]["effect"]] == undefined) {
      // need preload

      let loader = new Phaser.Loader.LoaderPlugin(this.wh);

      var spritea = data["effect"]["effect"].split("_");
      var svversionurlstring = this.wh.registry.list.rcvarpass.svversionstring;
      if (svversionurlstring != '') {
        svversionurlstring = '?' + svversionurlstring;
      }
      loader.atlas(
        spritea[0] + '',
        this.wh.registry.list.rcvarpass.rc_baseurl +
        'sprite/' +
        spritea[0] +
        '.png' +
        svversionurlstring,
        this.wh.registry.list.rcvarpass.rc_baseurl +
        'sprite/' +
        spritea[0] +
        '.json' +
        svversionurlstring
      );

      //this.wh.registry.list.rcloading.loading2(this.wh);
      loader.on(
        'progress',
        function (value) {
          //this.plog.log("loaderevent", value, loader.totalComplete, loader.totalToLoad);
          this.wh.registry.list.rcloading.loading2text(
            'Loading ' + loader.totalComplete + '/' + loader.totalToLoad
          );
          this.wh.registry.list.rcloading.loading2percent(value);
        },
        this
      );
      loader.once(
        Phaser.Loader.Events.COMPLETE,
        function () {
          //this.plog.log('Phaser.Loader.Events.COMPLETE');
          //this.plog.log(this);
          //this.effwithtxt_loaded();
          //console.log(arguments);
          this.rcvarsservice.gameobj.scene.scenes[0].registry.list.gameitf.genloadedanims(this.rcvarsservice.gameobj.scene.scenes[0], arguments[1]);

          this.mapitemeffect(arguments[0], arguments[1]);
        }.bind(this, this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[data["effect"]["charid"]], data["effect"]["effect"]),
        this
      );
      loader.start();
    } else {
      this.mapitemeffect(this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[data["effect"]["charid"]], data["effect"]["effect"]);
    }
  }

  zmapchareffect_osdflash(wh, tg, txt) {
    var eachlineh = 40;
    //console.log("zmapchareffect_osdflash", wh, tg, txt);
    var dsp = wh.add.text(0, 0, txt, wh.fs["osdflash_blue"]);
    wh.mapcontainer.add(dsp);
    wh.mapcontainer.bringToTop(dsp);
    dsp.setPosition(
      tg.x - dsp.displayWidth / 2,
      tg.y
    );

    //wh.gifcami(wh, dsparr);
    // wh.mapcami(wh, dsp);
    var tw = wh.tweens.add({
      targets: dsp,
      alpha: 0,
      ease: 'Power2',
      duration: 1000, // duration of animation; higher=slower
      delay: 500,
    });
    var tw1 = wh.tweens.add({
      targets: dsp,
      y: dsp.y - 100,
      ease: 'Power2',
      duration: 1500, // duration of animation; higher=slower
      delay: 0, // wait 500 ms before starting
      onComplete: function () {
        //console.log("osdflash tweencomplete", this);
        if (this.target != undefined && this.target[0] != undefined) {
          this.target[0].destroy();
        }
        if (this.targets != undefined && this.targets[0] != undefined) {
          this.targets[0].destroy();
        }
      }, // set context? how?
    });

  }
  updatezmapchatposchk = [];
  updatezmapchat(zmap) {
    //this.plog.log("updatezmapchat", zmap);
    if (zmap == undefined || zmap == '') return;
    if (this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer == undefined)
      return;
    if (this.zmapchatdt == undefined) this.zmapchatdt = 0;
    for (const k in zmap) {
      if (k == "allchat") {
        this.zmapchatwin(zmap[k]);
      }
      if (k == 'mode' || k == 'time' || k == 'allchat' || k == 'note-mapid') continue;
      var v = zmap[k];
      var isemote = false;
      if (this.rcvarsservice.activechar['block'].includes(v['charid'])) {
        //this.plog.log("updatezmapchar found blocked user", v["charid"],v);
        continue;
      }
      if (v['dt'] < this.zmapchatdt) continue;
      //this.plog.log("updatezmapchat loop", k, v);

      //if (this.rcvarsservice.gameobj.scene.scenes[0].zmapchatballoonlist[k] == undefined) {
      if (v["msg"] == ":emote:bt-auto:") v["msg"] = ":emote:bt:";
      if (v['msg'] != undefined && v['msg'].length < 3) v['msg'] = ' ' + v['msg'] + ' ';
      this.rcvarsservice.gameobj.scene.scenes[0].zmapchatballoonlist[k] = Array();
      if (v['msg'] != undefined && v["msg"].length == 10 && v["msg"].indexOf(":emote:") != -1) {

        var emodek = v["msg"].replace(":emote:", "");
        //console.error(emodek);
        emodek = emodek.replace(":", "");
        var tmp = this.rcvarsservice.gameobj.scene.scenes[0].add.sprite(0, 0, "emotes", "aa-1");

        if (this.wh.anims.anims.entries["emote_" + emodek] == undefined) {
          //create anim
          this.rcvarsservice.gameobj.anims.create({
            key: "emote_" + emodek,
            frames: this.rcvarsservice.gameobj.anims.generateFrameNames("emotes", {
              prefix: emodek + '-',
              end: 3,
              zeroPad: 0,
              start: 1,
            }),
            repeat: -1,
            frameRate: 5,
          });
        }
        isemote = true;
        tmp.play("emote_" + emodek);
      } else {
        var tmp = this.rcvarsservice.gameobj.scene.scenes[0].add.text(
          0,
          0,
          '' + v['msg'],
          {
            fontFamily: 'ffff',
            fontSize: '8px',
            color: '#000',
            align: 'center',
            padding: { top: 3, bottom: 3 },
          }
        );
      }
      //this.rcvarsservice.gameobj.scene.scenes[0[.registry.list.phm.charsaybubble cancel due to chat data binds to mappos not charsprite, meh
      //if (tmp.displayWidth < 20) tmp.displayWidth = 20;
      tmp.setDepth(600010);
      if (this.updatezmapchatposchk[v["mappos"]] != undefined) {
        this.updatezmapchatposchk[v["mappos"]]++;
        this.plog.log("dupmappos xx", this.updatezmapchatposchk[v["mappos"]]);//this.updatezmapchatposchk);
      } else {
        this.updatezmapchatposchk[v["mappos"]] = 1;
      }
      //this.plog.log("dupmappos chk", this.updatezmapchatposchk);

      var posa = v['mappos'].split('-');
      posa[0] = Number(posa[0]);
      posa[1] = Number(posa[1]);
      tmp.posdata = v["mappos"];
      //peacepos
      //(tmp.displayWidth / 2 + 0) -
      var useposx = ((posa[0] - 1) * 32) - (this.rcvarsservice.gameobj.scene.scenes[0].defaultmaplayerx + 32);
      var useposy = ((posa[1] - 1) * 32) - (32 + 32);
      this.plog.log("dupmappos zmapchatballoonlist", this.rcvarsservice.gameobj.scene.scenes[0].zmapchatballoonlist);
      var dupcount = this.updatezmapchatposchk[v["mappos"]];
      this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.add(tmp);

      tmp.setPosition(useposx + (dupcount * 15), useposy);
      //this.plog.log("updatezmapchat", v, tmp);
      try {
        this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rcvarpass.activemapreveal[posa[0] + "-" + posa[1]] = 1;
      } catch (e) {
        console.log(e);
      }
      try {
        this.rcvarsservice.gameobj.scene.scenes[0].fow.erase('revealbrush', Number(posa[0]) * 32 - this.rcvarsservice.gameobj.scene.scenes[0].fowsize - 32 + this.rcvarsservice.gameobj.scene.scenes[0].foww / 2, Number(posa[1]) * 32 - this.rcvarsservice.gameobj.scene.scenes[0].fowsize - 32 + this.rcvarsservice.gameobj.scene.scenes[0].fowh / 2);
      } catch (error) {
        this.plog.log("error fow on updatezmapchat", error);
      }

      if (isemote == true) {
        var graphics = this.rcvarsservice.gameobj.scene.scenes[0].add.image(0, 0, "spacer");

        this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.add(graphics);
        this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.bringToTop(graphics);
      } else {
        var graphics = this.rcvarsservice.gameobj.scene.scenes[0].add.graphics();

        this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.add(graphics);
        this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.bringToTop(graphics);
        graphics.fillStyle(0xffffff, 1);
        graphics.lineStyle(1, 0x777777, 1);
        //  32px radius on the corners
        graphics.fillRoundedRect(
          tmp.x - 4,
          tmp.y - 2,
          tmp.displayWidth + 8,
          tmp.displayHeight + 4,
          3
        );
        graphics.strokeRoundedRect(
          tmp.x - 4,
          tmp.y - 2,
          tmp.displayWidth + 8,
          tmp.displayHeight + 4,
          3
        );
        //graphics.lineStyle(1, 0x777777, 1);

        //graphics.lineStyle(4, 0x222222, 0.5);
        //bubble.lineBetween(point2X - 1, point2Y + 6, point3X + 2, point3Y);
        var point1X, point1Y, point2X, point2Y, point3X, point3Y;
        point1X = tmp.x + 4 - 3;
        point1Y = tmp.y + tmp.displayHeight + 1;
        point2X = tmp.x + 4 + 3;
        point2Y = tmp.y + tmp.displayHeight + 1;
        point3X = tmp.x + 4;
        point3Y = tmp.y + tmp.displayHeight + 5;
        //  Bubble arrow fill
        graphics.fillTriangle(
          point1X,
          point1Y,
          point2X,
          point2Y,
          point3X,
          point3Y
        );
        graphics.lineStyle(2, 0x565656, 1);
        graphics.lineBetween(point2X, point2Y, point3X, point3Y);
        graphics.lineBetween(point1X, point1Y, point3X, point3Y);
      }
      this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.bringToTop(tmp);

      //  Using an object to define a different radius per corner
      graphics.setDepth(600000);
      this.rcvarsservice.gameobj.scene.scenes[0].zmapchatballoonlist[k]['bg'] =
        graphics;
      this.rcvarsservice.gameobj.scene.scenes[0].zmapchatballoonlist[k]['txt'] =
        tmp;
      //}
      var delaytime = 4700;
      if (isemote == true) delaytime = 3500;
      var tw = this.rcvarsservice.gameobj.scene.scenes[0].tweens.add({
        targets: [tmp, graphics],
        alpha: 0,
        ease: 'Power3',
        duration: 200, // duration of animation; higher=slower
        delay: delaytime, // wait x ms before starting
        onComplete: function () {
          this.rcvarsservice.gameobj.scene.scenes[0].zmapchatballoonlist[k][
            'bg'
          ].destroy();
          this.rcvarsservice.gameobj.scene.scenes[0].zmapchatballoonlist[k][
            'txt'
          ].destroy();

          this.updatezmapchatposchk[arguments[1][0]["posdata"]]--;
          this.plog.log("dupmappos adjustdown", arguments[1][0]["posdata"], this.updatezmapchatposchk);
        }.bind(this), // set context? how?
      });
    }
    this.zmapchatdt = zmap['time'];
  }
  async updatezmapchar(zmap) {
    var ispublic = this.rcvarsservice.activemap['map']['ispublic'];
    //this.plog.log("updatezmapchar ispublic ", ispublic, this.rcvarsservice);
    if (ispublic != 'YES') return;
    //this.plog.log("updatezmapchar", zmap, this.rcvarsservice.activechar);
    if (zmap == 'usecache') {
      zmap = this.updatezmapchar_cache;
      //this.plog.log("updatezmapchar usecache", zmap);
      if (zmap == undefined) {
        this.rcvarsservice.gameobj.scene.scenes[0].registry.list.wsrc.pingchar();
      }
    } else {
      this.updatezmapchar_cache = zmap;
    }
    if (zmap == undefined || zmap == '') return;
    if (this.rcvarsservice.activechar == undefined) return;
    //for (const k in this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist) {
    //  this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k]["stillexists"] = 0;
    //}
    //this.plog.log("updatezmapchar usecache", zmap);
    for (const k in zmap) {
      var v = zmap[k];
      //this.plog.log("updatezmapchar i", k, v);
      if (this.rcvarsservice.activechar['block'].includes(v['charid'])) {
        //this.plog.log("updatezmapchar found blocked user", v["charid"],v);
        continue;
      }
      if (this.rcvarsservice.activechar['id'] == k || k == 'mode') continue;
      //this.plog.log("updatezmapchar i pass", k, v);
      if (this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k] == undefined) {
        //chk sprite
        //this.plog.log("updatezmapchar scenes", this.rcvarsservice.gameobj.scene.scenes[0]);
        //check for too old
        var time2h = Math.floor(Date.now() / 1000) - (60 * 60 * 2);
        //this.plog.log("updatezmapchar dt",time2h,v["dt"]);
        if (v["dt"] < time2h) continue;
        if (this.rcvarsservice.gameobj.scene.scenes[0].textures.exists(v['sprite'])) {
          //loaded
          // this.plog.log("updatezmapchar sprite loaded");
          const tmp = this.rcvarsservice.gameobj.scene.scenes[0].add.sprite(100, 100, v['sprite']); //.setDepth(this.charzindex)
          tmp.play(v['sprite'] + '-idle');
          this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.add(tmp);
          this.rcvarsservice.gameobj.scene.scenes[0].zsortlist.push(tmp);
          var posa = v['mappos'].split('-');
          tmp.setOrigin(0.5, 0.8);
          tmp.setPosition(posa[0] * 32, posa[1] * 32);
          this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.bringToTop(
            tmp
          );
          if (v["element"] != undefined && v["element"] != "") {
            var tmpe = this.rcvarsservice.gameobj.scene.scenes[0].add.sprite(100, 100, "element", v['element'] + "-1");
            var tmpelement = v['element'];
            if (tmpelement == "wind") tmpelement = "air";
            tmpe.play("element_" + tmpelement);
            tmpe.displayWidth = tmpe.displayHeight = 16;
            this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.add(tmpe);
            this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.bringToTop(tmpe);
            this.rcvarsservice.gameobj.scene.scenes[0].zsortlist.push(tmpe);
            tmpe.following = k;
            tmpe.followingx = 24;
            tmpe.followingy = -32;
            this.rcvarsservice.gameobj.scene.scenes[0].zmapcharfollower.push(tmpe);

          }

          if (v["element"] != undefined && v["element"] != "") {
            var tmpe = this.rcvarsservice.gameobj.scene.scenes[0].add.sprite(100, 100, "element", v['element'] + "-1");
            var tmpelement = v['element'];
            if (tmpelement == "wind") tmpelement = "air";
            tmpe.play("element_" + tmpelement);
            tmpe.displayWidth = tmpe.displayHeight = 16;
            this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.add(tmpe);
            this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.bringToTop(tmpe);
            this.rcvarsservice.gameobj.scene.scenes[0].zsortlist.push(tmpe);
            tmpe.following = k;
            tmpe.followingx = 24;
            tmpe.followingy = -32;
            this.rcvarsservice.gameobj.scene.scenes[0].zmapcharfollower.push(tmpe);
          }
          ///copy to charinfoupdate
          if (v["raritycount"] != undefined && Math.floor(Number(v["raritycount"])) != 0) {
            if (this.rcvarsservice.gameobj.scene.scenes[0].registry.list.thethis != undefined && this.rcvarsservice.gameobj.scene.scenes[0].registry.list.thethis.chkconfiglighteffects != undefined && this.rcvarsservice.gameobj.scene.scenes[0].registry.list.thethis.chkconfiglighteffects == "yes") {
              var tmpe = this.rcvarsservice.gameobj.scene.scenes[0].registry.list.gameitf.genchareff(Math.floor(Number(v["raritycount"])), this.rcvarsservice.gameobj.scene.scenes[0]);
              tmpe.following = k;//this.rcvarsservice.gameobj.scene.scenes[0].char_main; 
              tmpe.followingx = 0;
              tmpe.followingy = 0;

              this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.add(tmpe);
              this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.bringToTop(tmpe);
              //this.rcvarsservice.gameobj.scene.scenes[0].zsortlist.push(tmpe);
              this.rcvarsservice.gameobj.scene.scenes[0].zmapcharfollower.push(tmpe);
              this.plog.log("raritycount", tmpe);
            }
          }

          tmp.setAlpha(1);
          this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k] = tmp;
          this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].currentx = Number(posa[0]);
          this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].name = v['charid'];
          this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].rctype == 'zmapchar';
          this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].spritex = v['sprite'];
          this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].currenty = Number(posa[1]);
          this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].walkcmd = [];
          this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].istweening = false;
          //this.plog.log("updatezmapchar sprite created", tmp, " at ", posa[0], posa[1]);
          //console.error("chkrespawnpet1",v);
          if (v["pet"] != undefined && v["pet"].length != 0) {
            //console.error("chkrespawnpet2", v);
            await this.spawnpet(this.rcvarsservice.gameobj.scene.scenes[0], this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k], v["pet"]);
          }
          this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[
            k
          ].setInteractive();
          this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].on(
            'pointerover',
            function () {
              //this.plog.log(this, this.name);
              this.scene.registry.list.phm.zmapcharmouseover(
                this.scene,
                this.name
              );
            }
          );
          this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].on(
            'pointerdown',
            function () {
              if (
                this.scene.registry.list.phm.updatezmapchar_cache == undefined
              )
                return;
              if (
                this.scene.registry.list.phm.updatezmapchar_cache[this.name] ==
                undefined
              )
                return;
              var tmploc = {
                name:
                  'zchar-' +
                  this.scene.registry.list.phm.updatezmapchar_cache[this.name][
                  'mappos'
                  ],
              };
              //this.plog.log(tmploc, this, this.name);
              this.scene.registry.list.phm.floorclick(tmploc, arguments);
            }
          );
        } else {
          //load now
          this.plog.log("updatezmapchar sprite NOT loaded");
          this.loadcharsprite(v['sprite']);
        }
      } else {
        //zmapchar create, check moving
        //this.plog.error("zmapchar create, check moving", v);
        if (v['mappos'] == '') continue;
        var posa = v['mappos'].split('-');
        // this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k]["stillexists"] = 1;

        if (v["pet"] != undefined && v["pet"].length != 0) {
          //try recreate pets (cats loads later)
          //console.error("chkrespawnpet3", v);
          await this.spawnpet(this.rcvarsservice.gameobj.scene.scenes[0], this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k], v["pet"]);
        }

        if (
          this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].currentx !=
          posa[0] ||
          this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].currenty !=
          posa[1]
        ) {
          this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].idlecount = 0;
          //this.plog.log("updatezmapchar zcharmove", k, posa);
          //this.plog.log("creating floorastar", this.rcvarsservice.activemapmetrix);
          var zcharastar = new AStarFinder({
            grid: {
              matrix: this.rcvarsservice.activemapmetrix,
            },
            includeStartNode: false,
            includeEndNode: true,
            diagonalAllowed: true,
          });

          let startPos = {
            x: this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k]
              .currentx,
            y: this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k]
              .currenty,
          };
          let goalPos = { x: posa[0], y: posa[1] };
          //this.plog.log("floorastar1", this.floorastar);
          this.plog.log(
            'zmapchar move 1',
            this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k],
            posa
          );
          this.plog.log('zmapchar move', startPos, goalPos, zcharastar);
          var myPathway;
          var findpatherror = false;
          try {
            myPathway = zcharastar.findPath(startPos, goalPos);
            this.plog.log("zmapchar find path ok", zcharastar, startPos, goalPos);
          } catch (e) {
            this.plog.error("zmapchar find path error", zcharastar, startPos, goalPos);
            findpatherror = true;

          }
          if (findpatherror == true) {
            //force reset pos if find pos error
            //this.plog.error("force reset pos if find pos error", v);
            try {
              this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].destroy();
            } catch (e) {
              console.log(e);
            }
            try {
              delete this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k];
            } catch (e) {
              console.log(e);
            }
            /*
            this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].currentx = goalPos.x;
            this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].currentx = goalPos.y;
            this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].x =
              (this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].currentx - 1) *
              32;
            this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].y =
              (this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].currenty - 1) *
              32;
            this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k]["mappos"] = goalPos.x + "-" + goalPos.y;*/
            myPathway = [];
            //this.plog.error("force reset pos if find pos error after", k,this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k]);
          }
          //this.plog.log("zmapchar move myPathway", myPathway);

          if (myPathway.length > 0) {
            this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].walkcmd =
              myPathway;
            this.updatezmapcharwalk(k);
          }

          // this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].currentx = posa[0];
          // this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].currenty = posa[1];
        }
      }
    }
    //remove if left map
    /*
    for (var k in this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist) {
      var v = this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k];
      if (zmap[k] == undefined) {
        for (const k2 in this.rcvarsservice.gameobj.scene.scenes[0].zsortlist) {
          var v2 = this.rcvarsservice.gameobj.scene.scenes[0].zsortlist[k2];
          if (v2.rctype == "zmapchar" && v2 == this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k]) {
            this.plog.log("updatezmapchar leftmap", k, this.rcvarsservice.gameobj.scene.scenes[0].zsortlist[k]);
            this.rcvarsservice.gameobj.scene.scenes[0].zsortlist[k].destroy();
            this.rcvarsservice.gameobj.scene.scenes[0].zsortlist[k] = undefined;
          }
        }
        if (zmap[k] != undefined) {
          this.plog.log("xxx found left char", k, zmap[k]);
          this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].destroy();
          this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k] = undefined;
        }
      }
    }*/
    for (const k in this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist) {
      //this.plog.log("xxx found left char chk", k, this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k]["stillexists"],this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k]);
      if (zmap[k] == undefined) {
        //this.plog.log("xxx found left char", k, this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k]);
        if (
          this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k] !=
          undefined
        ) {
          this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].destroy();
          this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k] =
            undefined;
          try {
            delete this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k];
          } catch (e) {
            console.log(e);
          }
          this.plog.log("removed from zmap", this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k], k);
        }
      }
    }
    for (const k in this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist) {
      var v = this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k];
      if (v != undefined && v.scene == undefined
      ) {
        try {
          this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k].destroy();
        } catch (e) {
          console.log(e);
        }
        try {
          delete this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k];
        } catch (e) {
          console.log(e);
        }
      }
    }

  }
  zmapcharmouseover(wh, charid) {
    if (this.updatezmapchar_cache == undefined) return;
    if (this.updatezmapchar_cache[charid] == undefined) return;
    var data = this.updatezmapchar_cache[charid];
    //this.plog.log("zmapcharmouseover data",data);return;
    var tg = this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[charid];
    var txt = data['charname'];
    var useflag = data['flag'];
    if (wh.zmapcharmouseoverobj1 != undefined) {
      wh.zmapcharmouseoverobj1.destroy();
      wh.zmapcharmouseoverobj1 = undefined;
    }
    if (wh.zmapcharmouseoverobj2 != undefined) {
      wh.zmapcharmouseoverobj2.destroy();
      wh.zmapcharmouseoverobj2 = undefined;
    }
    if (wh.zmapcharmouseoverobj3 != undefined) {
      wh.zmapcharmouseoverobj3.destroy();
      wh.zmapcharmouseoverobj3 = undefined;
    }
    var tmp = wh.add.text(0, 0, txt, {
      fontFamily: 'ffff',
      fontSize: '12px',
      color: '#ffffff',
      align: 'center',
    });
    var flag = wh.add.sprite(0, 0, "spacer", "b-1");
    if (useflag != undefined && useflag != "") {
      flag.setTexture("flag", useflag);
    }
    flag.displayHeight = 15;
    flag.displayWidth = 20;
    flag.setOrigin(0.5, 0.5);
    //this.plog.log("gamemenutooltip", tg);
    //this.plog.log("gamemenutooltip", typeof tg.parentContainer);
    var plusx = 0;
    var plusy = 0;

    tmp.setStroke(0x333333, 4);
    tmp.setPosition(
      tg.x - tmp.displayWidth / 2 + plusx,
      tg.y - (tmp.height + tg.displayHeight / 2) + plusy
    );
    flag.setPosition(
      tmp.x + (tmp.displayWidth / 2),
      tmp.y - (tmp.height / 2) + 5
    );
    flag.removeAllListeners();
    flag.setInteractive();
    flag.name = charid;
    flag.on('pointerdown', function () {
      //this.plog.log("this", this, arguments);
      this.scene.registry.list.zchari.show(
        this.scene,
        this.name
      );
    });

    tmp.setShadow(0, 0, '#333333', 0, true, true);
    tmp.setDepth(600001);
    //this.plog.log("gamemenutooltip txt", tmp);
    tmp.removeAllListeners();
    tmp.setInteractive();
    tmp.name = charid;
    tmp.on('pointerdown', function () {
      //this.plog.log("this", this, arguments);
      this.renderer.game.scene.scenes[0].registry.list.zchari.show(
        this.renderer.game.scene.scenes[0],
        this.name
      );
    });
    //addbg
    var graphics = wh.add.sprite(0, 0, 'thecolor', 'black');
    //graphics.fillStyle(0x333333, 1);
    //  32px radius on the corners
    /*graphics.fillRoundedRect(
      tmp.x - 2,
      tmp.y + 3,
      tmp.displayWidth + 4,
      tmp.displayHeight - 6,
      3
    );*/
    graphics.setOrigin(0, 0);
    graphics.setPosition(tmp.x - 2, tmp.y + 3);
    graphics.displayWidth = tmp.displayWidth + 4;
    graphics.displayHeight = tmp.displayHeight - 6;
    //  Using an object to define a different radius per corner
    graphics.setDepth(600000);

    if (
      tg != undefined &&
      tg.parentContainer != undefined &&
      tg.parentContainer != null
    ) {
      //plusx = tg.parentContainer.x;
      //plusy = tg.parentContainer.y;
      tg.parentContainer.add(graphics);
      tg.parentContainer.bringToTop(graphics);
      tg.parentContainer.add(tmp);
      tg.parentContainer.bringToTop(tmp);
      tg.parentContainer.add(flag);
      tg.parentContainer.bringToTop(flag);
    }
    wh.zmapcharmouseoverobj1 = tmp;
    wh.zmapcharmouseoverobj2 = graphics;
    wh.zmapcharmouseoverobj3 = flag;
    var tw = wh.tweens.add({
      targets: [tmp, graphics, flag],
      alpha: 0,
      ease: 'Power3',
      duration: 350, // duration of animation; higher=slower
      delay: 2000, // wait 500 ms before starting
      onComplete: function () {
        tmp.destroy();
        graphics.destroy();
        flag.destroy();
      }, // set context? how?
    });
  }
  updatezmap(zmap) {
    //dropped data
    //this.plog.log('updatezmap', zmap);
    //var zmap = JSON.parse(data);//this.rcvarsservice.activemap["zmap"];
    //this.plog.log("updatezmap",data);
    if (zmap["note"] != "map-" + this.rcvarsservice.activemap["map"]["code"] &&
      zmap["note"] != "mapprivate-" + this.rcvarsservice.activemap["map"]["code"]) {
      this.plog.log("updatezmap skip", zmap["note"], this.rcvarsservice.activemap["map"]["code"]);
      return;
    }

    var mapw = this.rcvarsservice.activemap.map.w;
    var maph = this.rcvarsservice.activemap.map.h;
    if (zmap == undefined || zmap.length == 0) return;
    //set is exists var
    for (const k in this.rcvarsservice.gameobj.scene.scenes[0].zmapi) {
      if (this.rcvarsservice.gameobj.scene.scenes[0].zmapi[k] != undefined)
        this.rcvarsservice.gameobj.scene.scenes[0].zmapi[k]['stillexists'] = 0;
    }
    //var value = this.rcvarsservice.activemap["zmap"];
    for (var w = 1; w <= mapw; w++) {
      for (var h = 1; h <= maph; h++) {
        var cellvalue = zmap[w + '-' + h];
        if (cellvalue != undefined) {
          //this.plog.log("updatezmap cellvalue",cellvalue);

          var tmp;
          if (cellvalue['type1'] == 'stackitem') {
            if (
              this.rcvarsservice.gameobj.scene.scenes[0].zmapi[
              'i' + cellvalue['id']
              ] != undefined
            ) {
              this.rcvarsservice.gameobj.scene.scenes[0].zmapi[
                'i' + cellvalue['id']
              ]['stillexists'] = 1;
              continue;
            }
            var thisdata = this.rcvarsservice.allstackitem[cellvalue['item']];
            if (thisdata == undefined) continue;
            //this.plog.log("updatezmap thisdata", cellvalue, thisdata);
            //peacepos
            tmp = this.rcvarsservice.gameobj.scene.scenes[0].add.sprite(
              (w - 1) * 32,// + this.rcvarsservice.gameobj.scene.scenes[0].defaultmaplayerx,
              ((h - 1) * 32),
              'stackitem_tpt',
              thisdata['sprite']
            ).setOrigin(0.5, 0.5);
            tmp.name = 'zmap-stackitem-' + cellvalue['id'] + '-' + w + '-' + h;
          }
          if (cellvalue['type1'] == 'item') {
            if (
              this.rcvarsservice.gameobj.scene.scenes[0].zmapi[
              'i' + cellvalue['id']
              ] != undefined
            ) {
              this.rcvarsservice.gameobj.scene.scenes[0].zmapi[
                'i' + cellvalue['id']
              ]['stillexists'] = 1;
              continue;
            }
            var thisdata = this.rcvarsservice.allitem[cellvalue['item']];
            if (thisdata == undefined) continue;
            //this.plog.log("updatezmap thisdata", thisdata);
            //peacepos
            tmp = this.rcvarsservice.gameobj.scene.scenes[0].add.sprite(
              (w - 1) * 32,//+ this.rcvarsservice.gameobj.scene.scenes[0].defaultmaplayerx,
              (h - 1) * 32,
              'item_tp',
              thisdata['code']
            ).setOrigin(0.5, 0.5);
            tmp.name = 'zmap-item-' + cellvalue['id'] + '-' + w + '-' + h;
          }
          if (cellvalue['type1'] == 'mapgrow') {
            if (
              this.rcvarsservice.gameobj.scene.scenes[0].zmapi[
              'i' + cellvalue['id']
              ] != undefined
            ) {
              this.rcvarsservice.gameobj.scene.scenes[0].zmapi[
                'i' + cellvalue['id']
              ]['stillexists'] = 1;
              continue;
            }
            //var thisdata = this.rcvarsservice.allitem[cellvalue['item']];
            //if (thisdata == undefined) continue;
            //this.plog.log("updatezmap thisdata", thisdata);
            tmp = this.rcvarsservice.gameobj.scene.scenes[0].add.sprite(
              (w - 1) * 32,// + this.rcvarsservice.gameobj.scene.scenes[0].defaultmaplayerx,
              (h - 1) * 32,
              'mapgrow',
              cellvalue['item']
            );
            tmp.name = 'zmap-mapgrow-' + cellvalue['id'] + '-' + w + '-' + h;
            //this.plog.log("mapgrow",tmp);
          }
          if (tmp == undefined) continue;
          tmp.displayWidth = 24;
          tmp.displayHeight = 24;

          //this.plog.log("rcvarsservice", this.rcvarsservice);
          //this.rcvarsservice.gameobj.scene.scenes[0].zsortlist.push(tmp);
          //this.rcvarsservice.gameobj.scene.scenes[0].npclist.push(tmp);
          var charic =
            this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.add(tmp);
          //tmp.y = h * 32;
          this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.bringToTop(
            tmp
          );
          this.rcvarsservice.gameobj.scene.scenes[0].zmapi[
            'i' + cellvalue['id']
          ] = tmp;
          //this.plog.log('updatezmap i', tmp);

          if (
            this.rcvarsservice.gameobj.scene.scenes[0].char_main != undefined
          ) {
            if (tmp.name.indexOf("mapgrow") == -1) {
              tmp.y = tmp.y - 20;
              var tween = this.rcvarsservice.gameobj.scene.scenes[0].tweens.add({
                targets: tmp,
                y: tmp.y + 20,
                //y: { from: this.rcvarsservice.gameobj.scene.scenes[0].char_main.y, to: tmp.y + 20 },
                ease: 'Bounce',
                duration: 500,
                delay: Math.random() * (200 - 1) + 1,
                yoyo: false,
                repeat: 0,
              });
            }
          } else {
            var origscale = tmp.scale;
            tmp.scale = 0.1;
            var tween = this.rcvarsservice.gameobj.scene.scenes[0].tweens.add({
              targets: tmp,
              scale: origscale,
              //y: { from: this.rcvarsservice.gameobj.scene.scenes[0].char_main.y, to: tmp.y + 20 },
              ease: 'Bounce',
              duration: 500,
              delay: Math.random() * (200 - 1) + 50,
              yoyo: false,
              repeat: 0,
            });
          }
          /*
          var tween = this.rcvarsservice.gameobj.scene.scenes[0].tweens.add({
            targets: tmp,
            //y: tmp.y+50,
            x: {from: this.rcvarsservice.gameobj.scene.scenes[0].char_main.x, to: tmp.x },
            y: {from: this.rcvarsservice.gameobj.scene.scenes[0].char_main.y, to: tmp.y+50 },
            ease: 'Power3',
            duration: 500,
            delay: 0,
            yoyo: false,
            repeat: 0
          });*/
        }
      }
    }
    //remove if not exists
    for (const k in this.rcvarsservice.gameobj.scene.scenes[0].zmapi) {
      var v = this.rcvarsservice.gameobj.scene.scenes[0].zmapi[k];
      if (v != undefined) {
        if (v['stillexists'] == 0 || v['active'] == false) {
          v.destroy();
          this.rcvarsservice.gameobj.scene.scenes[0].zmapi[k] = undefined;
        }
      }
    }
    for (const k in this.rcvarsservice.gameobj.scene.scenes[0].zmapi) {
      var v = this.rcvarsservice.gameobj.scene.scenes[0].zmapi[k];
      if (v != undefined) {
        for (const k2 in this.rcvarsservice.gameobj.scene.scenes[0].zsortlist) {
          var v2 = this.rcvarsservice.gameobj.scene.scenes[0].zsortlist[k2];
          if (v2 != undefined) {
            if (
              this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list.includes(
                v
              ) &&
              this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list.includes(
                v2
              )
            )
              this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.moveBelow(
                v,
                v2
              );
          }
        }
      }
    }
    //this.plog.log('updatezmap e', this.rcvarsservice.gameobj.scene.scenes[0].zmapi);
  }
  findnpc(wh, npcid) {
    //this.plog.log('findnpc()', npcid);

    for (const k in wh.npclist) {
      var v = wh.npclist[k];
      //this.plog.log('findnpc npclist i', v.name, v);
      if (v.name == npcid) {
        //this.plog.log('findnpc found i', v.name, v);
        return v;
      }
    }
    return;
  }
  findnpccode(wh, npcid) {
    //this.plog.log('findnpccode()', npcid);

    for (const k in wh.npclist) {
      var v = wh.npclist[k];
      //this.plog.log('findnpc npclist i', v.name, v);
      if ((v.name + "").indexOf("npc-" + npcid + "-") != -1) {
        //this.plog.log('findnpc found i', v.name, v);
        return v;
      }
    }
    return;
  }
  async charinteractnpc(wh, tg, action) {
    this.plog.log('charinteractnpc', wh, tg, action);
    var tmpnamea = tg.name.split('-');
    var tmploc = { name: 'zchar-' + tmpnamea[2] + '-' + tmpnamea[3] };
    this.plog.log('charinteractnpc', tmpnamea);
    wh.registry.list.phm.walkto(tmpnamea[2], tmpnamea[3], action);
  }
  getspawnpos() {
    var spawnpos; // = this.rcvarsservice.activemap["spawn"];
    for (var key in this.rcvarsservice.activemap['spawn']) {
      if (this.rcvarsservice.activemap['spawn'][key] == 'yn_y') {
        spawnpos = key;
      }
      //this.rcvarsservice.activemap["spawn"][key];
    }
    return spawnpos;
  }
  async updatechestnpcdsp(wh) {
    this.rcvarsservice.gameobj.scene.scenes[0].cheststatus =
      await this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rchttp.getcommon2(
        'chestsstatus'
      );

    for (const k in wh.npclist) {
      var v = wh.npclist[k];
      //this.plog.log('updatechestnpcdsp npclist i', v.name, v);
      //if (v.name == npcid) {
      var tmpnamea = v.name.split('-');
      var npcdata = wh.registry.list.rcvarpass.npc[tmpnamea[1]];
      //this.plog.log('updatechestnpcdsp npcdata i', npcdata);
      var mapsprite = npcdata['mapsprite'];
      var mapspritea = mapsprite.split('_');
      if (mapspritea[0] == 'chests1') {
        //this.plog.log('updatechestnpcdsp mapspritea[0] i', mapspritea[0]);
        //this.plog.log(          'updatechestnpcdsp openstatus',          wh.cheststatus,          tmpnamea[1]        );
        //var newsprite=npcdata["sprite"].substring(0,npcdata["sprite"].length-1)+"o";
        var newsprite = tmpnamea[1] + 'o';
        if (wh.cheststatus[tmpnamea[1]] == 'yes') {
          this.plog.log('updatechestnpcdsp play new sprite (open) ', v, newsprite);

          v.play(newsprite);
        }
      }
      //this.plog.log('updatechestnpcdsp found i', v.name, v);
      return v;
      //}
    }
  }
  async updatenpcbribedsp(wh, reget = false) {
    if (reget == true) {
      this.rcvarsservice.gameobj.scene.scenes[0].npcbribestatus =
        await this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rchttp.getcommon2(
          'npcbribestatus'
        );
      //this.plog.log('npcbribestatus npclist ', wh.npclist);
    }
    for (const k in wh.npclist) {
      var v = wh.npclist[k];
      //this.plog.log('npcbribestatus npclist i', v.name, v);
      //if (v.name == npcid) {
      var tmpnamea = v.name.split('-');
      var npcdata = wh.registry.list.rcvarpass.npc[tmpnamea[1]];
      //this.plog.log('npcbribestatus npcdata i', npcdata);
      var mapsprite = npcdata['mapsprite'];
      var mapspritea = mapsprite.split('_');
      if (npcdata['instantmodule'].indexOf('npcbribe:') != -1) {
        //this.plog.log('updatebushrewardnpcdsp openstatus', wh.ushrewardstatus, v.name);
        //var newsprite=npcdata["sprite"].substring(0,npcdata["sprite"].length-1)+"o";
        var newsprite = mapspritea[0] + '_b';
        v.alpha = 1;
        if (wh.npcbribestatus[v.name] == 'yes') {
          this.plog.log("found active (hidden) npcbribestatus", v,);
          var tween = this.wh.tweens.add({
            targets: v,
            alpha: 0,
            ease: 'Sine.In',
            duration: 1000,
            yoyo: false,
            repeat: 0,
            onComplete: function () {
            },
          });
        } else {
          //v.visible=true;
        }
        //var thissprite = this.rcvarsservice.allanims[newsprite];
        //v.displayWidth = thissprite['size'] * 32;
        //v.scaleY = v.scaleX;
        //this.plog.log('updatebushrewardnpcdsp mapspritea[0] i', newsprite, mapspritea[0], thissprite);

      }

      //this.plog.log("updatechestnpcdsp found i", v.name, v);
      //return v;
      //}
    }
  }
  async updatebushrewardnpcdsp(wh) {
    this.rcvarsservice.gameobj.scene.scenes[0].bushrewardstatus =
      await this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rchttp.getcommon2(
        'bushrewardstatus'
      );
    //this.plog.log('updatebushrewardnpcdsp npclist ', wh.npclist);

    for (const k in wh.npclist) {
      var v = wh.npclist[k];
      //this.plog.log('updatebushrewardnpcdsp npclist i', v.name, v);
      //if (v.name == npcid) {
      var tmpnamea = v.name.split('-');
      var npcdata = wh.registry.list.rcvarpass.npc[tmpnamea[1]];
      //this.plog.log('updatebushrewardnpcdsp npcdata i', npcdata);
      var mapsprite = npcdata['mapsprite'];
      var mapspritea = mapsprite.split('_');
      if (npcdata['code'].indexOf('bushreward') != -1) {
        //this.plog.log('updatebushrewardnpcdsp openstatus', wh.ushrewardstatus, v.name);
        //var newsprite=npcdata["sprite"].substring(0,npcdata["sprite"].length-1)+"o";
        var newsprite = mapspritea[0] + '_b';
        if (wh.bushrewardstatus[v.name] == 'yes') {
          //this.plog.log('updatebushrewardnpcdsp play new sprite (open) ', v.name, newsprite);
          v.play(newsprite);
        } else {
          v.play(mapspritea[0] + '_a');
        }
        var thissprite = this.rcvarsservice.allanims[newsprite];
        v.displayWidth = thissprite['size'] * 32;
        v.scaleY = v.scaleX;
        //this.plog.log('updatebushrewardnpcdsp mapspritea[0] i', newsprite, mapspritea[0], thissprite);

      }

      //this.plog.log("updatechestnpcdsp found i", v.name, v);
      //return v;
      //}
    }
  }

  async timefunc1sec(wh) {
    this.wh = wh;
    var humantimeuse = wh.registry.list.timectl.humantimeorig + wh.gametimesec;
    if (this.wh.settingiconactivetime > 0) this.wh.settingiconactivetime--;
    if (this.wh.settingiconactivetime == 1) {
      this.wh.registry.list.gameitf.hidesettings();
    }
    //humantimeuse = humantimeuse - 5; //for server communication time, and harvesttime
    //this.plog.log("timefunc1sec", wh.registry.list.timectl.humantimeorig, wh.gametimesec);
    wh.gametimesec++;

    wh.registry.list.farmplot.updatedsp(wh);

    var currentdate = new Date(humantimeuse);
    /*var datetime = "xxx " + currentdate.getDate() + "/"
      + (currentdate.getMonth() + 1) + "/"
      + currentdate.getFullYear() + " @ "
      + currentdate.getHours() + ":"
      + currentdate.getMinutes() + ":"
      + currentdate.getSeconds();*/
    //this.plog.log("timefunc1sec", datetime);

    //return;
    /*if (wh.registry.list.timectl != undefined) {
      wh.registry.list.timectl.humantime++;
    }*/
    //pnnpc dsp
    //     this.plog.log("pnnpc1sec",wh.registry.list.rcvarpass.activemap['map']['npc_personal']);
    //this.plog.log(wh.mapcontainer);
    if (
      wh.registry.list.rcvarpass.activemap['map'] != undefined &&
      wh.registry.list.rcvarpass.activemap['map']['code'] != undefined &&
      wh.registry.list.rcvarpass.activemap['map']['code'] == "ship1" &&
      wh.mapcontainer != undefined) {
      if (wh.shiptimertxt == undefined) {
        wh.shiptimertxt = wh.add.text(0, 0,
          "xxxxx",
          {
            fontFamily: 'ffff',
            fontSize: '14px',
            color: '#FFFFFF',
            stroke: '#4A90E2',
            strokeThickness: 4,
            shadow: { color: '#2F2F2F', fill: true, blur: 17, stroke: true, offsetX: 2, offsetY: 2 },
            resolution: 4
          }
        );
        wh.mapcontainer.add(wh.shiptimertxt);
        return;
      }
      //wh.shiptimertxt.setDepth(10000000);
      //this.plog.log(wh.npclist);
      var shipna = wh.registry.list.phm.findnpc(wh, "npc-shipnavigation-38-23-ship1");
      if (shipna == undefined) {
        this.plog.log("shipna not found");
      } else {
        // this.plog.log("shipna", shipna, wh.shiptimertxt);
        //this.plog.log("shipna", wh.shiptimertxt);
        //this.plog.log("shipna", shipna.depth, wh.shiptimertxt.depth);
        var chardata = this.wh.registry.list.rcvarpass.activechar;
        if (wh.shiptimertxt != undefined &&
          chardata['mapspace'] != undefined &&
          chardata['mapspace']['going'] != undefined &&
          chardata['mapspace']['currenttime'] != undefined
        ) {
          //this.plog.log("Settimeb", wh.shiptimertxt_currenttime, chardata['mapspace']['currenttime']);
          if (wh.shiptimertxt_currenttime == 0 || wh.shiptimertxt_currenttime == undefined) {
            //this.plog.log("Settimeset", wh.shiptimertxt_currenttime, chardata['mapspace']['currenttime']);
            wh.shiptimertxt_currenttime = Math.floor(chardata['mapspace']['currenttime']);
          }
          //this.plog.log("Settimea", wh.shiptimertxt_currenttime);

          wh.shiptimertxt_currenttime++;
          //this.plog.log("Settime++", wh.shiptimertxt_currenttime);
          //this.plog.log("shiptime", chardata['mapspace']);
          //this.plog.log("wh.shiptimertxt", wh.shiptimertxt);
          if (wh.registry.list.shipnavigation.data == undefined)
            wh.registry.list.shipnavigation.data = await this.wh.registry.list.rchttp.getcommon2("mapspace", {});
          wh.shiptimertxt.visible = true;
          var remainingsecs = chardata['mapspace']['goinge'] - wh.shiptimertxt_currenttime;
          var useplnt = chardata['mapspace']['at'];
          if (chardata['mapspace']['going'] != "")
            useplnt = chardata['mapspace']['going']
          if (remainingsecs < 0) remainingsecs = 0;
          if (remainingsecs == 0) {
            wh.shiptimertxt.setText("Location: " + wh.registry.list.shipnavigation.data['star'][useplnt]['name']);

          } else {
            var remainingsecsdsp = wh.registry.list.timectl.secondsToDsp(remainingsecs);
            try {
              wh.shiptimertxt.setText("Travelling to: " + wh.registry.list.shipnavigation.data['star'][useplnt]['name'] + " " + remainingsecsdsp);
            } catch (e) {
              this.plog.warn("shiptimertxt error", e);
            }
            //this.plog.log("xxx", chardata['mapspace'], wh.shiptimertxt_currenttime);
            //wh.shiptimertxt.setPosition(shipna.x + 200, shipna.y);
          }
          wh.shiptimertxt.x = shipna.x - (wh.shiptimertxt.displayWidth / 2);
          wh.shiptimertxt.y = shipna.y - shipna.displayHeight - 24;

        } else {
          wh.shiptimertxt.visible = false;
          wh.shiptimertxt_currenttime = 0;
        }
      }
      wh.mapcontainer.bringToTop(wh.shiptimertxt);

    } else {
      if (wh.shiptimertxt != undefined) {
        wh.shiptimertxt.visible = false;
      }
    }

    for (const key in wh.pnnpcitfdb) {
      if (Object.prototype.hasOwnProperty.call(wh.pnnpcitfdb, key)) {
        var pnnpcv = wh.pnnpcitfdb[key];
        if (pnnpcv == undefined) {
          continue;
        }
        if (pnnpcv['text'] == undefined) {
          continue;
        }
        var tmpnpcid = pnnpcv['text'].name;
        pnnpcv["ffw"].visible = false;
        if (wh.registry.list.rcvarpass.activemap['map'] == undefined) continue;
        if (wh.registry.list.rcvarpass.activemap['map']['npc_personal'] == undefined) continue;
        var npcsprite = wh.registry.list.phm.findnpc(wh, tmpnpcid);
        var pnnpcdata = wh.registry.list.rcvarpass.activemap['map']['npc_personal'][tmpnpcid];
        if (pnnpcdata == undefined) {
          //pnnpc missing/forceremove
          if (npcsprite != undefined) {
            this.mapitemeffect(npcsprite, 'smokeexplode_b');
            npcsprite.destroy();
          }
          wh.registry.list.gameitf.destroybuildablepanel(wh, tmpnpcid);
          continue;
        }
        //this.plog.log("pnnpc1sec",wh.registry.list.rcvarpass.activemap['map']);
        if (pnnpcdata['stat'] != "") {
          var timedsp = pnnpcdata['statuntil'] - humantimeuse;
          var timedsp2 = " " + wh.registry.list.timectl.secondsToDsp(timedsp + 5)
          //this.plog.log("pnnpc1sec time", pnnpcdata['statuntil'], wh.registry.list.timectl, timedsp);
          if (pnnpcv['status'] == undefined) continue;

          try {
            pnnpcv['status'].visible = true;
            pnnpcv['status'].setText(pnnpcdata['stat'] + " " + timedsp2);
            wh.mapcontainer.bringToTop(pnnpcv['status']);
          } catch (e) {
            this.plog.warn("!ERROR", e);
            continue;
          }
          //this.plog.log("pnnpcdata['statuntil']", pnnpcdata, pnnpcdata['statuntil'], (wh.registry.list.timectl.humantimeorig + wh.gametimesec + 5));
          if (pnnpcdata['statuntil'] > (wh.registry.list.timectl.humantimeorig + wh.gametimesec + 5) && tmpnpcid == wh.lastnpcuidclicked) {

            pnnpcv['ffw'].visible = true;
            wh.mapcontainer.bringToTop(pnnpcv['ffw']);
            pnnpcv['btn1'].visible = false;
            if (wh.registry.list.gp.guigrid_current != pnnpcv['ffw']) {
              var griddat = [];
              var griddatf = [];
              griddatf.push(pnnpcv['ffw']);
              griddat.push(pnnpcv['ffw']);
              this.wh.registry.list.gp.guigrid(griddat, griddatf);
              this.wh.registry.list.gp.guigrid_noclosebtn = true;
            }
          }
          if (timedsp < 1) {
            this.mapitemeffect(npcsprite, 'smokeexplode_b');
            if (pnnpcdata['stat'] == "destroying") {
              if (npcsprite != undefined) {
                npcsprite.visible = false;
                await this.wh.registry.list.rchttp.getcommon2('pnnpc-destroy', {
                  destroythis: tmpnpcid, mode: 'force'
                });

                pnnpcv['status'].visible = false;
                wh.registry.list.gameitf.destroybuildablepanel(wh, tmpnpcid);
              }
            }
            if (pnnpcdata['stat'] == "building") {
              pnnpcv['status'].visible = false;
              wh.registry.list.rcvarpass.activemap['map']['npc_personal'][tmpnpcid]['stat'] = '';
            }
            if (pnnpcdata['stat'] == "packing") {
              if (npcsprite != undefined) {
                npcsprite.visible = false;
                await this.wh.registry.list.rchttp.getcommon2('pnnpc-pack', {
                  packthis: tmpnpcid, mode: 'force'
                });

                pnnpcv['status'].visible = false;
                wh.registry.list.gameitf.destroybuildablepanel(wh, tmpnpcid);
              }
            }

          }
          if (pnnpcdata['stat'] == "destroying" || pnnpcdata['stat'] == "building" || pnnpcdata['stat'] == "packing") {
            if (npcsprite != undefined) {
              //npcsprite.setBlendMode(Phaser.BlendModes.MULTIPLY);
              npcsprite.setAlpha(0.5);
            }
          }
        } else { //--pnnpcdata['stat'] != ""//////////////////////////////////////////
          pnnpcv['status'].visible = false;
          if (npcsprite != undefined) {
            //npcsprite.setBlendMode(Phaser.BlendModes.NORMAL);
            npcsprite.setAlpha(1);
          }
          var npcida = tmpnpcid.split('-');
          var npcdatatpchk = wh.registry.list.rcvarpass.npc[npcida[1]]
          //this.plog.log("npcdatatpchk",npcdatatpchk);
          var pnnpcdata = wh.registry.list.rcvarpass.activemap['map']['npc_personal'][tmpnpcid];
          if (npcdatatpchk['buildable'] != undefined && npcdatatpchk['buildable']['harvest_item'] == "" && npcdatatpchk['buildable']['harvest_stackitem'] == "") {
            pnnpcv["btn1"].setAlpha(0.5);
            if (tmpnpcid == wh.lastnpcuidclicked) {
              if (npcdatatpchk['code'] == "pnnpcfarmplot") {
                //this.plog.log("ffff found farm", npcdatatpchk, this.wh.registry.list.farmplot.lastfarmplotstatus);
                for (const ki in this.wh.registry.list.farmplot.lastfarmplotstatus) {
                  var localres = this.wh.registry.list.farmplot.lastfarmplotstatus[ki];
                  //this.plog.log("fff localres", localres['uid'], wh.lastnpcuidclicked, localres);
                  //this.plog.log("fff localres", localres['uid'], "///////", wh.registry.list.rcvarpass.activemap['map']['code'] + "-" + wh.lastnpcuidclicked);
                  if (localres['uid'] == wh.registry.list.rcvarpass.activemap['map']['code'] + "-" + wh.lastnpcuidclicked) {
                    if (localres['timeleft'] > 10) {
                      pnnpcv["ffw"].visible = true;
                      pnnpcv["btn1"].visible = false;
                      wh.mapcontainer.bringToTop(pnnpcv['ffw']);
                    } else {
                      pnnpcv["ffw"].visible = false;
                      pnnpcv["btn1"].visible = true;
                      wh.mapcontainer.bringToTop(pnnpcv['btn1']);
                    }
                  }
                }
              }
              // pnnpcv["ffw"].visible = true;
              //pnnpcv["btn1"].visible = false;
            }
            continue;
          }


          if (pnnpcdata['stat'] == '') { //double check if not doing anything
            if (npcsprite != undefined) {
              var harvesttime = pnnpcdata['harvesttime'] - humantimeuse;
              var flagreadyharvest = false;

              if (wh.registry.list.rcvarpass.activemap["map"]["npc_personal"][tmpnpcid]["harvestanimflag"] == "yes") {
                pnnpcv["btn1"].setAlpha(1);
                flagreadyharvest = true;
              }
              if (harvesttime < 1) {
                flagreadyharvest = true;
              }
              var usename = npcdatatpchk['name'];
              if (harvesttime < 1) {
                pnnpcv['text'].setText(usename);
              } else {
                pnnpcv['text'].setText(usename + " " + wh.registry.list.timectl.secondsToDsp(harvesttime + 5));
                //this.plog.log("why?", wh.lastnpcuidclicked, tmpnpcid);
                if (harvesttime > 10 && wh.lastnpcuidclicked == tmpnpcid && pnnpcv['text'].visible == true) {
                  pnnpcv['btn1'].visible = false;
                  pnnpcv['ffw'].visible = true;
                  wh.mapcontainer.bringToTop(pnnpcv['ffw']);
                  /*
                  
                  var griddat = [];
                  var griddatf = [];
                  griddatf.push(pnnpcv['ffw']);
                  griddat.push(pnnpcv['ffw']);
                  this.wh.registry.list.gp.guigrid(griddat, griddatf);
                  this.wh.registry.list.gp.guigrid_noclosebtn = true;*/
                }
              }
              //this.plog.log("harvesttime",harvesttime,pnnpcdata);
              //this.plog.log("harvesttime x",pnnpcdata['harvesttime'],wh.registry.list.timectl.humantime);
              //this.plog.log("decischk", flagreadyharvest, tmpnpcid, wh.registry.list.rcvarpass.activemap["map"]["npc_personal"][tmpnpcid]["harvestanimflag"], harvesttime);
              if (flagreadyharvest == true) {
                pnnpcv["btn1"].setAlpha(1);
                //this.plog.log("harvesttime harvest",pnnpcdata);
                //wh.registry.list.rcvarpass.activemap['map']['npc_personal'][tmpnpcid]["harvesttime"] = wh.registry.list.timectl.humantime * 2;
                var npcida = tmpnpcid.split('-');
                var npcdatatp = wh.registry.list.rcvarpass.npc[npcida[1]];
                //
                //this.plog.log("harvesttime harvest npcdatatp", npcdatatp);
                if (npcdatatp['buildable'] != undefined) {
                  //npcsprite.setTexture("" + npcdatatp['buildable']['harvest_readysprite']);
                  try {
                    npcsprite.play("" + npcdatatp['buildable']['harvest_readysprite']);
                  } catch (e) {
                    this.plog.warn("ERROR!", e);
                  }
                  //this.plog.log("HAVVVVVVVVVVVVVVV",wh.registry.list.rcvarpass.activemap["map"]["npc_personal"]);
                  wh.registry.list.rcvarpass.activemap["map"]["npc_personal"][tmpnpcid]["harvestanimflag"] = "yes";
                }

              } else {
                pnnpcv["btn1"].setAlpha(0.5);
                if (npcsprite != undefined) {
                  var npcida = tmpnpcid.split('-');
                  var npcdatatp = wh.registry.list.rcvarpass.npc[npcida[1]];
                  if (wh.registry.list.rcvarpass.activemap["map"]["npc_personal"][tmpnpcid] == undefined) {
                    this.plog.log("HAVNPC undefined", npcida[1]);
                    continue;;
                  }
                  if (wh.registry.list.rcvarpass.activemap["map"]["npc_personal"][tmpnpcid]["harvestanimflag"] != "yes" && npcdatatp['buildable'] != undefined && npcdatatp['buildable']['harvest_readysprite'] != '') {
                    var tmphavanim = this.rcvarsservice.allanims[wh.registry.list.rcvarpass.npc[npcida[1]]['sprite']];
                    //this.plog.log("harvest baseanom",tmphavanim)
                    //npcsprite.setTexture("" + tmphavanim);
                    npcsprite.play("" + tmphavanim['code']);
                  } else {
                    //this.plog.log("harvest baseanom flag",wh.registry.list.rcvarpass.activemap["map"]["npc_personal"][tmpnpcid]["harvestanimflag"]);
                  }
                }
              }
            }
          }
        }

      }
    }
    //maptalk
    wh.lastpossaysec++;
    wh.timerseccount++;
    if (wh.registry.list.drama.isdramaplaying != true) {
      var timename = wh.registry.list.timectl.timename;
      for (const k in wh.npclist) {

        var v = wh.npclist[k];
        if (v == undefined) continue;
        if (v.visible != true) continue;
        if (v.maptalktimeout == undefined) v.maptalktimeout = 0;
        if (v.maptalktimeout > 0) {
          v.maptalktimeout = v.maptalktimeout - 1;
          continue;
        }
        var tmpnamea = v.name.split('-');

        var npcdata = wh.registry.list.rcvarpass.npc[tmpnamea[1]];
        if (
          npcdata == undefined ||
          npcdata['maptalk'] == undefined ||
          npcdata['maptalk'] == ''
        )
          continue;
        //this.plog.log("timefunc1sec npclist i", v.name, tmpnamea, npcdata, v);
        var msglist = npcdata['maptalk'].split('\n');
        var usablemsg = [];
        for (const mk in msglist) {
          var mv = msglist[mk];
          mv = mv.trim();
          var mva = mv.split(':');
          if (mva[0] == timename || mva[0] == '*') {
            if (mva[2] != undefined && mva[2] != '') usablemsg.push(mva);
          }
        }
        if (usablemsg.length == 0) continue;
        var item = usablemsg[Math.floor(Math.random() * usablemsg.length)];
        if (item[2] == undefined || item[2] == '') continue;
        v.maptalktimeout = Number(item[1]);
        item[2] = item[2].replace('\r', '');
        this.charsaybubble(v, wh.gl(item[2]));
        //this.plog.log("timefunc1sec usable", usablemsg, "using", item);
      }
    }

    //fly-by reward
    if (
      wh.timerseccount == 5 ||
      (wh.timerseccount % 60 == 0 && wh.timerseccount != 0)
    ) {
      //check every 1 minutes to see if it should spawn
      //this.plog.log('flyby', wh);
      if (
        wh.registry.list.rcvarpass.activemap != undefined &&
        wh.registry.list.rcvarpass.activemap['map'] != undefined
      ) {
        var isoutside =
          wh.registry.list.rcvarpass.activemap['map']['isoutside'];
        var isloading = wh.registry.list.rcloading.isloading();
        if (isloading == true) {
          console.log("skip flyby for loading somethingelse");
        }
        if (isoutside == 'YES' && isloading == false) {
          var flybystatus =
            await this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rchttp.getcommon2(
              'flybystatus'
            );
          //this.plog.log('flybystatus', flybystatus);
          if (
            flybystatus < 1 &&
            this.rcvarsservice.gameobj.scene.scenes[0].maplayers['bg'] !=
            undefined
          ) {
            //fly
            var targety = Math.floor(
              Math.random() *
              (this.rcvarsservice.gameobj.scene.scenes[0].maplayers['bg']
                .displayHeight -
                3 +
                1) +
              3
            );
            //targety = 700;///
            let flybysprite =
              this.rcvarsservice.gameobj.scene.scenes[0].add.image(
                0,
                targety,
                'ballooncat'
              );
            //this.plog.log('flybystatus', this.rcvarsservice.gameobj.scene.scenes[0]);
            this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.add(
              flybysprite
            );
            this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.bringToTop(
              flybysprite
            );
            flybysprite.displayWidth = 48;
            flybysprite.scaleY = flybysprite.scaleX;
            flybysprite.removeAllListeners();
            flybysprite.setInteractive();
            flybysprite.on('pointerdown', function () {
              this.scene.plog.log('flyby click', this, arguments);
              this.scene.registry.list.phm.flybyclick(
                this.scene,
                this.x,
                this.y + 116
              );
              //this.destroy();
              //this.timelinev.stop();
              this.timelinev.destroy();
              var tween = this.scene.tweens.add(
                {
                  targets: this,
                  width: 32,
                  y: this.y + 100,
                  ease: 'Sine.In',
                  duration: 300,
                  yoyo: false,
                  repeat: 0,
                  onComplete: function () {
                    //arguments[1][0].scene.plog.log('flyby drop complete', this, arguments);
                    //arguments[1][0].destroy();
                    arguments[1][0].visible = false;
                  },
                }
              );
              //this.rcvarsservice.gameobj.registry.list.phm.flybyclick(this.rcvarsservice.gameobj.scene.scenes[0], arguments[1], arguments[2]);
            });
            var yduration = 1500;
            var targetx =
              this.rcvarsservice.gameobj.scene.scenes[0].maplayers['bg']
                .displayWidth;
            flybysprite.timelinev =
              this.rcvarsservice.gameobj.scene.scenes[0].add.timeline([
                {
                  at: 0,
                  loop: 0,
                  tween:
                  {
                    targets: flybysprite,
                    x: { from: 0, to: targetx },
                    ease: 'Linear',
                    duration: targetx * 30,
                    yoyo: false,
                    onComplete: function () {
                      //plog.error('flyby timelinecomplete ', arguments);
                      arguments[1][0].destroy();
                    },
                  },
                }, {
                  at: 0,
                  tween: {
                    targets: flybysprite,
                    y: { from: targety, to: targety - 20 },
                    ease: 'Linear',
                    duration: yduration,
                    offset: 0,
                    repeat: -1,
                    yoyo: true,
                  }
                }
              ]);
            flybysprite.timelinev.play();
            this.rcvarsservice.gameobj.scene.scenes[0].flybysprite = flybysprite;
          }
        }
      }
    }
  }
  async flybyclick(wh, cx, cy) {
    this.plog.log('flybyclick', wh);
    this.wh = wh;
    var res = await wh.registry.list.rchttp.getcommon2('flybyclick');
    this.plog.log('flybyclick res', res);
    var resstr = wh.registry.list.showreward.getstr(wh, res);
    this.plog.log('flybyclick resstr', resstr);
    wh.registry.list.gameitf.osdflash(wh, [{ col: 'green', txt: resstr }]);

    //var mapposx = (0-wh.mapcontainer.x)+cx;
    //var mapposy = (0-wh.mapcontainer.y)+cy;
    var mapposx = cx;
    var mapposy = cy;
    var mapposxp = Math.floor(mapposx / 32) + 3;
    var mapposyp = Math.floor(mapposy / 32);
    wh.plog.log('flybyclick mapposxy', mapposx, mapposy, mapposxp, mapposyp);
    var tmp = new MapCritter(wh, {
      sprite: 'flybycritter',
      nature: 'walk',
      subcode: 'a',
      talk: 'meow|Meow.. just a cat|Meow|Yarnnn',
    });
    tmp.spawn([mapposxp, mapposyp]);
    wh.registry.list.snd.snd("balloonpop");
    wh.registry.list.snd.snd("balloonwoosh");
    await wh.registry.list.rchttp.updatecharinfo();
  }
  broadcast_voicesaid = 0;
  broadcast(data) {
    if (this.wh == undefined)
      this.wh = this.rcvarsservice.gameobj.scene.scenes[0];
    this.plog.log('broadcast', data, this.wh.bc_text);
    if (this.wh.bc_text == undefined || this.wh.bc_bg == undefined) {
      this.plog.log('broadcast return', data, this.wh);

      return;
    }
    if (this.broadcast_voicesaid > 0) {
      this.broadcast_voicesaid--;
      this.plog.log("this.broadcast_voicesaid", this.broadcast_voicesaid);
    } else {
      this.broadcast_voicesaid = 10;
      this.voicethis(this.wh, "svannouce");
    }

    this.wh.bc_bg.visible = true;
    this.wh.bc_text.visible = true;
    this.wh.bc_text.setText(data['msg']);
    this.wh.bc_text.setOrigin(0, 0);
    this.wh.mapcami(this.wh, this.wh.bc_bg);
    this.wh.mapcami(this.wh, this.wh.bc_text);
    if (data['col'] == 'black') {
      this.wh.bc_text.setStyle({
        fontFamily: 'ffff',
        fontSize: '20px',
        stroke: '#fff',
        color: '#000',
        strokeThickness: 0,
      });
    }
    if (data['col'] == 'red') {
      this.wh.bc_text.setStyle({
        fontFamily: 'ffff',
        fontSize: '20px',
        stroke: '#fff',
        color: '#900',
        strokeThickness: 0,
      });
    }
    if (data['col'] == 'green') {
      this.wh.bc_text.setStyle({
        fontFamily: 'ffff',
        fontSize: '20px',
        stroke: '#fff',
        color: '#090',
        strokeThickness: 0,
      });
    }
    if (data['col'] == 'blue') {
      this.wh.bc_text.setStyle({
        fontFamily: 'ffff',
        fontSize: '20px',
        stroke: '#fff',
        color: '#009',
        strokeThickness: 0,
      });
    }
    this.rcvarsservice.gameobj.scene.scenes[0].registry.list.resizeman.RCMapResizeManager_work(
      this.rcvarsservice.gameobj.scene.scenes[0]
    );
    var width = this.rcvarsservice.gameobj.canvas.width;
    this.wh.bc_text.x = width;
    this.wh.bc_bg.displayHeight = this.wh.bc_text.displayHeight + 8;
    var maintweendelay = 0;
    if (this.wh.bc_text.visible == true) {
      if (this.wh.bctween != undefined) this.wh.bctween.stop();
      maintweendelay = 300;
      this.wh.bctween = this.rcvarsservice.gameobj.scene.scenes[0].tweens.add({
        targets: this.wh.bc_text,
        x: 0 - this.wh.bc_text.displayWidth,
        ease: 'Linear',
        duration: 300,
        yoyo: false,
        repeat: -1,
      });
    }
    //
    this.wh.bctween = this.rcvarsservice.gameobj.scene.scenes[0].tweens.add({
      targets: this.wh.bc_text,
      x: {
        from: width + this.wh.bc_text.displayWidth / 2,
        to: 0 - this.wh.bc_text.displayWidth,
      },
      ease: 'Linear',
      duration: this.wh.bc_text.displayWidth * 15,
      yoyo: false,
      delay: maintweendelay,
      repeat: 3,
      onComplete: function () {
        this.plog.log('broadcast tween complete');
        this.wh.bc_bg.visible = false;
        this.wh.bc_text.visible = false;
      }.bind(this),
    });
  }
  bgmloaded() {
    if (this.rcvarsservice.activemap["map"]['bgm'] != undefined && this.rcvarsservice.activemap["map"]['bgm'] != "") {
      this.plog.log("bgmloaded", this);
      this.wh.registry.list.snd.bgm('mapbgm_' + this.rcvarsservice.activemap["map"]['code']);
    }
  }
  async buildmapmetrix(wh) {
    //this.plog.log("buildmapmetrix", wh, this);
    //this.plog.log("buildmapmetrix2", this.rcvarsservice.activemap['walkable']);

    var tmpmetrix = [];
    var tmpmetrixmob = [];
    var tmpmetrixmobx = [];

    var mapw = this.rcvarsservice.activemap.map.w;
    var maph = this.rcvarsservice.activemap.map.h;

    if (this.rcvarsservice.activemap['walkable'] == undefined) {
      this.plog.log("map don't have walkable layer");
    }
    if (this.rcvarsservice.activemap['fg'] == undefined) {
      this.plog.log("map don't have fg layer", this.rcvarsservice.activemap);
    }
    //walkable
    if (this.rcvarsservice.activemap['walkable'] == undefined) {
      await this.wh.registry.list.rchttp.getcommon2("warpbackmapnotfound");

      await this.wh.registry.list.rctoast.alert(
        this.wh.gl('Something Went Wrong'),
        this.wh.gl('Map is not ready (') +
        this.rcvarsservice.activemap['map']['name'] +
        ')',
        this.wh.gl(' \n\nWe will send you back to hometown (Peacely Ville)\nYou may need to restart the game (or reload the page)..')
      );
      this.plog.log('map is not ready', this);
      /*await this.wh.registry.list.rctoast.rcalert(this.wh,
        'Something Went Wrong',
        'Map is not ready (' +
        this.rcvarsservice.activemap['map']['name'] +
        ')\n\nWe will send you back to hometown (PeaceVille)'
      );*/
      await this.wh.registry.list.rchttp.updatecharinfo();
      this.wh.registry.list.gameitf.charinfoupdated(this.wh);
      this.wh.chkwarppos_warp(this.wh, "map1");

      // this.wh.registry.list.phm.initmap();
      //this.rcvarsservice.rcgame.overlay_hide();
      //this.rcvarsservice.rcgame.rc_selectcharacter(false);
      //move char to home

      return; //prevents script halts
      //this.overlay_hide();
      //this.overlay_show('selectcharacter', false);
      // this.rc_selectcharacter(false);
    }
    for (var w = 0; w <= mapw; w++) {
      for (var h = 0; h <= maph; h++) {
        var cellvalue = this.rcvarsservice.activemap['walkable'][w + '-' + h];
        if (tmpmetrix[h] == undefined) tmpmetrix[h] = [];
        if (cellvalue == 'yn_y') {
          //don't walk over npc
          tmpmetrix[h][w] = 0;
        } else {
          tmpmetrix[h][w] = 1;
        }
      }
    }
    //this.plog.log('npcbribechk npclist pre', this.wh.npclist);

    for (const k in this.wh.npclist) {
      var v = this.wh.npclist[k];
      var tmpnamea = v.name.split("-");
      //this.plog.log('npcbribechk npclist i', tmpnamea, v);
      if (this.wh.npcbribestatus[v.name] == 'yes') {
        //this.plog.log('npcbribechk npclist i bribepass', this.wh.npcbribestatus[v.name], this.wh.npcbribestatus);

        tmpmetrix[tmpnamea[3]][tmpnamea[2]] = 0;
        this.rcvarsservice.activemap['walkable'][tmpnamea[2] + '-' + tmpnamea[3]] = "yn_y";
      } else {
        //tmpmetrix[tmpnamea[2]][tmpnamea[3]]  = 1;
      }
      //if (v.name == npcid) {
      //this.plog.log('findnpc found i', v.name, v);
      //}
    }
    //this.plog.log("tmpmetrix", tmpmetrix);
    //mobwalk
    if (this.rcvarsservice.activemap['mobwalk'] != undefined) {
      for (var w = 0; w <= mapw; w++) {
        for (var h = 0; h <= maph; h++) {
          var cellvalue = this.rcvarsservice.activemap['mobwalk'][w + '-' + h];
          //this.plog.log(";lkj;lkj"<cellvalue);
          if (tmpmetrixmob[h] == undefined) tmpmetrixmob[h] = [];
          if (cellvalue == 'yn_y') {
            // if (cellvalue == 'yn_y' && this.wh.npcbribestatus[v.name] != 'yes') {
            //don't walk over npc
            //this.plog.log("xxxx",this.rcvarsservice);
            if (
              this.rcvarsservice.activemap != undefined &&
              this.rcvarsservice.activemap['npc'] != undefined &&
              this.rcvarsservice.activemap['npc'][w + '-' + h] != undefined &&
              this.rcvarsservice.activemap['npc'][w + '-' + h] != ''
            ) {
              tmpmetrixmob[h][w] = 1;
            } else {
              // tmpmetrixmob[h][w] = 0;
            }
          } else {
            tmpmetrixmob[h][w] = 1;
          }
        }
      }
    } else {
      //create dud array
      tmpmetrixmob = tmpmetrix;
      /*for (var w = 0; w <= mapw; w++) {
        for (var h = 0; h <= maph; h++) {
          if (tmpmetrixmob[h] == undefined) tmpmetrixmob[h] = [];
            tmpmetrixmob[h][w] = 0;
        }
      }*/
    }
    //mobxwalk
    if (this.rcvarsservice.activemap['mobxwalk'] != undefined) {
      for (var w = 0; w <= mapw; w++) {
        for (var h = 0; h <= maph; h++) {
          var cellvalue = this.rcvarsservice.activemap['mobxwalk'][w + '-' + h];
          //this.plog.log(";lkj;lkj"<cellvalue);
          if (tmpmetrixmobx[h] == undefined) tmpmetrixmobx[h] = [];
          if (cellvalue == 'yn_y') {
            tmpmetrixmobx[h][w] = 0;
          } else {
            tmpmetrixmobx[h][w] = 1;
          }
        }
      }
    } else {
      //create dud array
      tmpmetrixmobx = tmpmetrix;
    }
    if (this.rcvarsservice.activemap['npc'] != undefined) {
      for (var w = 0; w <= mapw; w++) {
        for (var h = 0; h <= maph; h++) {
          var cellvaluenpc = this.rcvarsservice.activemap['npc'][w + '-' + h];

          var npcdata = this.rcvarsservice.npc[cellvaluenpc];
          if (cellvaluenpc != undefined) {
            if (npcdata['talkdistance'] >= 2) {
              this.plog.log('walkovernpc this.rcvarsservice', this.rcvarsservice);
              this.plog.log('walkovernpc', cellvaluenpc, npcdata);
              var xstart = w - Math.floor(npcdata['talkdistance'] / 2);
              var xend = w + Math.floor(npcdata['talkdistance'] / 2);
              var ystart = h - Math.floor(npcdata['talkdistance'] / 2);
              var yend = h + Math.floor(npcdata['talkdistance'] / 2);
              for (var Lw = xstart; Lw <= xend; Lw++) {
                for (var Lh = ystart; Lh <= yend; Lh++) {
                  if (tmpmetrix[Lh] != undefined) tmpmetrix[Lh][Lw] = 1;
                  if (tmpmetrixmob[Lh] != undefined) tmpmetrixmob[Lh][Lw] = 1;
                  if (tmpmetrixmobx[Lh] != undefined) tmpmetrixmob[Lh][Lw] = 1;
                }
              }
            }
            //calculate npc inputitem
            //this.plog.log('inputitem', npcdata);
          }
        }
      }
    }
    this.floorastar = undefined;
    this.astar = undefined;
    return [tmpmetrix, tmpmetrixmob, tmpmetrixmobx];
  }
  zmapchatwin(v) {
    //return;
    if (this.wh.gamemenu_main == undefined) return;
    var xpos = this.wh.gamemenu_main.x + this.wh.gamemenu_main.displayWidth / 2;
    var ypos = this.wh.gamemenu_main.y - this.wh.gamemenu_main.displayHeight;
    var dspcount = 0;
    this.plog.log("zmapchatwin s", this.wh.gamemenu_main);

    for (const k in v) {
      var vv = v[k];
      if (vv == undefined) continue;
      if (this.rcvarsservice.activechar['block'] != undefined && this.rcvarsservice.activechar['block'].includes(vv['charid'])) {
        //this.plog.log("updatezmapchar found blocked user", v["charid"],v);
        continue;
      }
      //this.plog.error(v, vv);
      if (vv["msg"] == ":emote:bt-auto:") {
        continue; //ignore auto msg that send automatically during beginCombat
      }
      if (this.wh.zmapchatwint[vv["id"]] != undefined) {
        this.wh.zmapchatwint[vv["id"]].stop();
        this.wh.zmapchatwint[vv["id"]] = undefined;
      }
      if (this.wh.zmapchatwini[vv["id"]] != undefined) {
        this.wh.zmapchatwini[vv["id"]].destroy();
        this.wh.zmapchatwini[vv["id"]] = undefined;
      }

      var usexpos = xpos + 10;
      var useypos = ypos - (dspcount * 20);
      dspcount++;
      if (dspcount >= 12) break;
      //var tmpmapname = this.rcvarsservice.gameobj.scene.scenes[0].add.text(0, 0, "" + npcdata["name"], this.rcvarsservice.gameobj.scene.scenes[0].fs["npcmapname"]);
      //this.plog.log("zmapchatwin", dspcount, vv);
      this.wh.zmapchatwini[vv["id"]] = this.wh.add.text(usexpos, useypos, vv["charname"] + ": " + vv["msg"], this.wh.fs["onscreenchat"]);
      this.wh.zmapchatwini[vv["id"]].setDepth(10000000);

      var delay = 5000;
      if (dspcount <= 3) delay = 7000;
      this.wh.zmapchatwint[vv["id"]] = this.wh.tweens.add({
        targets: this.wh.zmapchatwini[vv["id"]],
        alpha: 0,
        ease: 'Power1',
        duration: 200,
        delay: delay,
        yoyo: false,
        repeat: 0,
        onComplete: function () {
          //this.plog.log("zonComplete", arguments[1][0]);
          if (arguments[1][0] != undefined)
            arguments[1][0].destroy();
        },
      });
    }
    this.wh.mapcami(this.wh, this.wh.zmapchatwini);

    this.plog.log("zmapchatwin", this.wh.zmapchatwini);
  }

  async refreshnpc_soft() {
    this.plog.log("refreshnpc");
    await this.wh.registry.list.rchttp.updatecharinfo();
    var tmpmap = await this.wh.registry.list.rchttp.load_map(this.wh.registry.list.rcvarpass.activemap['map']['code']);
    this.wh.registry.list.rcvarpass.activemap = tmpmap;

    var tmpmetrix = [];
    var tmpmetrixmob = [];
    var tmpmetrixmobx = [];
    var tmpallmetrix = await this.buildmapmetrix(this.rcvarsservice.gameobj.scene.scenes[0]);
    tmpmetrix = tmpallmetrix[0];
    tmpmetrixmob = tmpallmetrix[1];
    tmpmetrixmobx = tmpallmetrix[2];
    this.plog.log("buildmapmetrix", tmpallmetrix);

    this.wh.registry.list.farmplot.chkmapdsp(this.wh);

    this.rcvarsservice.activemapmetrix = tmpmetrix;
    this.rcvarsservice.activemapmetrixmob = tmpmetrixmob;
    this.rcvarsservice.activemapmetrixmobx = tmpmetrixmobx;

    await this.wh.registry.list.timectl.regettime(this.wh);
  }
  async refreshnpc_hard() {
    this.plog.log("refreshnpc");
    var tmporigx = this.wh.char_main.currentx;
    var tmporigy = this.wh.char_main.currenty;
    await this.wh.registry.list.rchttp.updatecharinfo();
    var tmpmap = await this.wh.registry.list.rchttp.load_map(this.wh.registry.list.rcvarpass.activemap['map']['code']);
    this.wh.registry.list.rcvarpass.activemap = tmpmap;

    await this.wh.registry.list.rchttp.updatecharinfo();
    //await wh.registry.list.rchttp.load_map(wh.registry.list.rcvarpass.activemap['map']['code']);
    //await wh.registry.list.rctoast.sleep(300);
    await this.wh.chkwarppos_warp(this.wh, this.wh.registry.list.rcvarpass.activemap['map']['code']);

    await this.wh.registry.list.farmplot.chkmapdsp(this.wh);

    await this.wh.registry.list.timectl.regettime(this.wh);
    //await this.spawnmainchar(this.wh.char_main.currenty+"-"+this.wh.char_main.currentx);
    await this.movemaincharto(tmporigy, tmporigx);
    this.rcvarsservice.activemap['walkable'][tmporigx + "-" + tmporigy] = 'yn_y';
    this.plog.log("activemapmetrix b ", this.rcvarsservice.activemapmetrix);

    this.rcvarsservice.activemapmetrix[tmporigy][tmporigx] = 0;
    //this.rcvarsservice.activemapmetrix[tmporigy+"-"+tmporigx] = 'yn_y';
    this.plog.log("activemapmetrix a ", this.rcvarsservice.activemapmetrix);

    this.plog.log("movemaincharto x ", tmporigy, tmporigx);
    await this.movemaincharto(tmporigy, tmporigx);
  }
}
