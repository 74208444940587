
import { Injectable } from '@angular/core';
import { RCVarsService } from './rcvars-service.service';
import { RCOSDService } from './rcosd.service';
import { RCHTTP } from './rchttp.service';
import { plog } from './plog.service';

@Injectable({
  providedIn: 'root'
})
export class PartyMyService {
  sumdescr;
  wh;
  leavebtn;
  charname;
  partyname;
  partydescr;
  selectcircle;
  unequipbtn;
  shopclosebtn;
  itemdescr;
  membericon = [];
  membername = [];
  memberdescr = [];
  membercount = [];
  itemwh = 54;
  shopguibg;
  flag;
  data;
  plog;

  constructor(
    public rcvarsservice: RCVarsService,
    public rcosd: RCOSDService,
    public rchttp: RCHTTP
  ) {
    this.plog = new plog();
  }


  async show(wh) {
    this.plog.log("PartyMyService show", wh);
    //this.plog.log(tg);
    this.data = await this.rchttp.getcommon2("partymap", { mode: "getmy" });
    this.plog.log("partymy, data", this.data);
    if (this.data == undefined) {

      this.show_close(wh);
      return;
    }
    if (this.data == "none") {
      await wh.registry.list.rctoast.rcalert(wh, "Not in a party", "Currently you are not in a party\n\nCreate or join a party in a map with [Party Flag]]");

      this.show_close(wh);
      return;
    }
    this.wh = wh;
    if (wh.partymycontainer != undefined) {
      await this.show_close(this.wh);
    }
    var width = wh.game.canvas.width;
    var height = wh.game.canvas.height;


    const shopguibg = wh.add.image(0, 0, "zcharinfoguibg");
    shopguibg.setOrigin(0.5, 0.5);
    shopguibg.setInteractive({ pixelPerfect: true });
    this.shopguibg = shopguibg;
    //this.plog.log("showinventory var", this.rcvarsservice);


    var shopclosebtn = wh.add.sprite(300, 300, "thesq", 'close');
    this.shopclosebtn = shopclosebtn;
    this.shopclosebtn.setOrigin(0.5, 0.5);
    this.shopclosebtn.setPosition(80, -100);
    shopclosebtn.displayWidth = 40;
    shopclosebtn.displayHeight = 40;
    this.shopclosebtn.setInteractive();
    this.shopclosebtn.on('pointerup', function (pointer, localX, localY, event) {
      //this.scene.registry.list["thethis"].phm.floorclick(this);
      this.plog.log("gc.npcbackbtn pointerup", pointer, localX, localY, event, this);
      this.show_close(wh);
    }, this);

    /*
        var tmpframe = this.rcvarsservice.gameobj.anims.generateFrameNames(
          this.data["sprite"] + "", {
          prefix: 'walk-d-',
          start: 1,
          end: 2,
          zeroPad: 0
        });
        this.rcvarsservice.gameobj.anims.remove(this.data["sprite"]+'-idle');
        var tmpx = this.rcvarsservice.gameobj.anims.create({
          key: this.data["sprite"]+'-idle',
          frames: tmpframe,
          frameRate: 1,
          repeat: -1
        });
    this.charicon = this.rcvarsservice.gameobj.scene.scenes[0].add.sprite(100, 100, this.data["sprite"] + "-idle").setPosition(0, 0).setScale(1.5);//.play('mainchar-idle');//.setDisplayOrigin(0.5, 0.8);
    this.plog.log("Phaser.Textures.FilterMode", Phaser.Textures.FilterMode);
    this.charicon.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);*/
    //Phaser.Textures.FilterMode.LINEAR or Phaser.Textures.FilterMode.NEAREST
    this.flag = wh.add.sprite(0, 0, "spacer", "b-1");

    wh.partymycontainer = wh.add.container(300, 300);
    wh.partymycontainer._refsrv = this;
    wh.partymycontainer.name = "partymycontainer";
    wh.partymycontainer.add(this.shopclosebtn);
    wh.partymycontainer.add(shopguibg);

    this.flag.setPosition(-60, -55);


    var itemdescr = wh.add.text(0, 0, "..", { fontFamily: 'ffff', fontSize: '12px', fill: '#000', boundsAlignH: "center", boundsAlignV: "middle" });
    wh.partymycontainer.add(itemdescr);
    itemdescr.setOrigin(0, 0);
    itemdescr.setPosition(0 - (itemdescr.displayWidth / 2), 20);
    this.itemdescr = itemdescr;

    this.charname = wh.add.text(0, 0, "..", wh.fs["winguilist_title"]);
    wh.partymycontainer.add(this.charname);
    this.partyname = wh.add.text(0, 0, "..", wh.fs["winguilist_title"]);
    wh.partymycontainer.add(this.partyname);
    this.partydescr = wh.add.text(0, 0, "..", wh.fs["winguilist_descr"]);
    wh.partymycontainer.add(this.partydescr);
    this.charname.setOrigin(0, 0);

    //drag

    var shopdragbtn = wh.add.image(300, 300, 'spacer');
    wh.shopdragbtn = shopdragbtn;
    wh.shopdragbtn.setOrigin(0.5, 0.5);
    wh.shopdragbtn.setPosition(0, -20);
    shopdragbtn.displayWidth = 190;
    shopdragbtn.displayHeight = 190;
    wh.shopdragbtn.setInteractive();
    wh.shopdragbtn.on('pointerout', function (pointer, localX, localY, event) {
      this.rcvarsservice.gameobj.scene.scenes[0].partymycontainer.dragactive = false;
    }, this);
    wh.shopdragbtn.on('pointerup', function (pointer, localX, localY, event) {
      this.rcvarsservice.gameobj.scene.scenes[0].partymycontainer.dragactive = false;
    }, this);
    wh.shopdragbtn.on('pointerdown', function (pointer, localX, localY, event) {
      this.rcvarsservice.gameobj.registry.list.gameitf.guiwindowclick(this.rcvarsservice.gameobj.scene.scenes[0].partymycontainer);

      this.rcvarsservice.gameobj.scene.scenes[0].partymycontainer.dragactive = true;
      this.rcvarsservice.gameobj.scene.scenes[0].partymycontainer.dragactivex = this.rcvarsservice.gameobj.scene.scenes[0].partymycontainer.x;
      this.rcvarsservice.gameobj.scene.scenes[0].partymycontainer.dragactivey = this.rcvarsservice.gameobj.scene.scenes[0].partymycontainer.y;
      this.rcvarsservice.gameobj.scene.scenes[0].partymycontainer.dragactivepx = pointer.x;
      this.rcvarsservice.gameobj.scene.scenes[0].partymycontainer.dragactivepy = pointer.y;
    }, this);
    this.rcvarsservice.gameobj.scene.scenes[0].partymycontainer.dragactive = false;
    this.rcvarsservice.gameobj.scene.scenes[0].partymycontainer.dragactivex = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].partymycontainer.dragactivey = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].partymycontainer.dragactivepx = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].partymycontainer.dragactivepy = 0;
    wh.shopdragbtn.on('pointermove', function (pointer, localX, localY, event) {
      //this.scene.registry.list["thethis"].phm.floorclick(this);
      //this.plog.log("shop pointermove", pointer, localX, localY, event, this);
      //this.showinventory_close(wh);
      if (this.rcvarsservice.gameobj.scene.scenes[0].partymycontainer.dragactive == true) {
        var tmpx = this.rcvarsservice.gameobj.scene.scenes[0].partymycontainer.dragactivex;
        var tmpy = this.rcvarsservice.gameobj.scene.scenes[0].partymycontainer.dragactivey;
        var tmppx = this.rcvarsservice.gameobj.scene.scenes[0].partymycontainer.dragactivepx;
        var tmppy = this.rcvarsservice.gameobj.scene.scenes[0].partymycontainer.dragactivepy;
        var movedx = tmppx - pointer.x;
        var movedy = tmppy - pointer.y;
        //this.plog.log("drag", tmppx, pointer.x, movedx);

        this.rcvarsservice.gameobj.scene.scenes[0].partymycontainer.x = tmpx - movedx;
        this.rcvarsservice.gameobj.scene.scenes[0].partymycontainer.y = tmpy - movedy;
        //this.rcvarsservice.gameobj.scene.scenes[0].partymycontainer.y = pointer.y;
      }
    }, this);
    wh.partymycontainer.add(wh.shopdragbtn);
    wh.partymycontainer.bringToTop(wh.shopdragbtn);
    wh.partymycontainer.bringToTop(this.shopclosebtn);

    /*
    this.plog.log("dragsize",shopguibg.width, shopguibg.height);
    wh.partymycontainer.setSize(shopguibg.width, shopguibg.height);
    wh.partymycontainer.setInteractive();
    wh.input.on('mousemove', function(pointer) {
      //check here is a scene has a selected gameObject
      //if(this.selected) { //to be able to detect if user really select something.
          wh.setPosition(pointer.x, pointer.y);
      //}
    }, this);*/
    let loader = new Phaser.Loader.LoaderPlugin(wh);

    wh.partymycontainer.bringToTop(this.partyname);
    wh.partymycontainer.bringToTop(this.partydescr);

    //move
    wh.partymycontainer.x = width - (this.shopguibg.width / 2);

    /*
    //move to spritepack
        for (var vv in equipment) {
          var vdat = equipment[vv];
          var itemdat = this.rcvarsservice.allitem[vdat.item];
          loader.image(vdat["item"], this.rcvarsservice.rc_baseurl + "item/" + itemdat["pcode"] + "/" + itemdat["sprite"] + ".png");
    
          this.plog.log("load shop ", vv, vdat, itemdat);
        }*/
    loader.once(Phaser.Loader.Events.COMPLETE, () => {
      this.plog.log("Phaser.Loader.Events.COMPLETE");
      this.plog.log(this);
      this.show_loaded();
    }, this);
    loader.start();
  }

  async show_loaded() {
    var wh = this.wh;
    this.plog.log("show_loaded", wh, this, this.data);

    for (var i = 0; i <= 20; i++) {
      if (this.membericon[i] != undefined) {
        this.plog.log(i, this.membericon[i]);
        this.membericon[i].destroy();
        this.membericon[i] = undefined;
      }
      if (this.membername[i] != undefined) {
        this.membername[i].destroy();
        this.membername[i] = undefined;
      }
      if (this.memberdescr[i] != undefined) {
        this.memberdescr[i].destroy();
        this.memberdescr[i] = undefined;
      }
      if (this.membercount[i] != undefined) {
        this.membercount[i].destroy();
        this.membercount[i] = undefined;
      }

    }
    if (this.leavebtn != undefined) {
      this.leavebtn.destroy();
      this.leavebtn = undefined;
    }

    if (this.sumdescr != undefined) {
      this.sumdescr.destroy();
      this.sumdescr = undefined;
    }

    this.flag.displayHeight = 20;
    this.flag.displayWidth = 30;
    this.charname.setText(this.data["name"]);
    this.charname.setPosition(0 - (this.charname.displayWidth / 2), -107);
    this.itemdescr.setText(" ");
    this.itemdescr.setPosition(0 - (this.itemdescr.displayWidth / 2), 50);

    this.partyname.setText(this.data["descr"] + " " + this.rcvarsservice.allmap[this.data["map"]]["name"]);
    //this.partyname.setWordWrapWidth(100);
    this.partydescr.setText("");
    this.partyname.setPosition(0 - (this.partyname.displayWidth / 2), -73);
    this.partydescr.setPosition(0 - (this.partydescr.displayWidth / 2), -70 + (this.partyname.displayHeight));
    var width = wh.game.canvas.width;
    var height = wh.game.canvas.height;
    //this.plog.log("eqitems", this, this.eqitems);
    var i = 0;
    for (const k in this.data["member"]) {
      var v = this.data["member"][k];
      this.plog.log("partymy i ", this.rcvarsservice);
      this.membericon[i] = this.wh.add.sprite(0, 0, 'charava', v['sprite']);
      wh.partymycontainer.add(this.membericon[i]);
      wh.partymycontainer.bringToTop(this.membericon[i]);
      this.membericon[i].setPosition(-60, -25 + (i * 40));
      this.membericon[i].displayWidth = this.membericon[i].displayHeight = 32;
      this.membericon[i].removeAllListeners();
      this.membericon[i].setInteractive();
      this.membericon[i].name = v["id"];
      this.membericon[i].on("pointerup", function () {
        this.scene.registry.list.zchari.show(
          this.scene,
          this.name
        );
      });
      this.membername[i] = this.wh.add.text(0, 0, v["name"], wh.fs["winguilist_descr_black"]);
      wh.partymycontainer.add(this.membername[i]);
      wh.partymycontainer.bringToTop(this.membername[i]);
      this.membername[i].setPosition(this.membericon[i].x + 17, this.membericon[i].y - 19);
      this.memberdescr[i] = this.wh.add.text(0, 0, v["descr"], wh.fs["winguilist_descr"]);
      wh.partymycontainer.add(this.memberdescr[i]);
      wh.partymycontainer.bringToTop(this.memberdescr[i]);
      this.memberdescr[i].setPosition(this.membericon[i].x + 17, this.membericon[i].y - 4);
      if (v["map"] != this.data["map"]) {
        this.memberdescr[i].setStyle({ color: '#FF0000' });
        this.memberdescr[i].setText("Off map: " + this.rcvarsservice.allmap[v["map"]]["name"]);
      } else {
        this.memberdescr[i].setStyle(wh.fs["winguilist_descr"]);
      }

      //this.membercount[i] = this.wh.add.text(0, 0, v["battlecount"], wh.fs["winguilist_title"]);
      this.membercount[i] = this.wh.add.text(0, 0, v["battlecount"], wh.fs["winguilist_descr_black"]);
      wh.partymycontainer.add(this.membercount[i]);
      wh.partymycontainer.bringToTop(this.membercount[i]);
      this.membercount[i].setPosition((this.membericon[i].x + 138) - this.membercount[i].displayWidth, this.membericon[i].y - 4);


      i++;
    }

    this.leavebtn = this.wh.add.sprite(0, 0, "thesb", "leave");
    wh.partymycontainer.add(this.leavebtn);
    wh.partymycontainer.bringToTop(this.leavebtn);
    this.leavebtn.displayWidth = 100;
    this.leavebtn.scaleY = this.leavebtn.scaleX;
    this.leavebtn.setPosition(0, 115);
    this.leavebtn.removeAllListeners();
    this.leavebtn.setInteractive();
    this.leavebtn.on("pointerup", async function () {
      var cfm = await this.wh.registry.list.rctoast.rcconfirm(this.wh, this.wh.trs("f:partymycontainer:Leave Party===Leave Party"),
      this.wh.trs("f:partymycontainer:Leave this party===Leave this party"));
      if (cfm == "ok") {
        var res = await this.wh.registry.list.rchttp.getcommon2("partymap", { mode: "leave" });
        this.show_close(this.wh);
      }
    }, this);

    this.sumdescr = this.wh.add.text(0, 0, this.data["sum"], wh.fs["winguilist_descr_black"]);
    wh.partymycontainer.add(this.sumdescr);
    wh.partymycontainer.bringToTop(this.sumdescr);
    this.sumdescr.setPosition(0 - this.sumdescr.displayWidth / 2, 75);


    this.rcvarsservice.gameobj.registry.list.gameitf.guiwindowclick(this.rcvarsservice.gameobj.scene.scenes[0].partymycontainer);
    /*if (this.wh.anims.anims.entries[this.data["sprite"] + "-idle"] == undefined) {
      this.charicon.setTexture('charava', this.data['sprite']);
      this.plog.log("anims not found", this, this.eqitems);
    } else {
      this.charicon.play(this.data["sprite"] + "-idle");
    }*/
    if (this.wh.anims.anims.entries[this.data["sprite"] + "-idle"] != undefined) {
      //this.charicon.setTexture(this.data["sprite"] + "-idle");
      //this.charicon.play(this.data["sprite"] + "-idle");
    }
    var equipment = this.rcvarsservice.activechar["equip"];
    this.plog.log("equipment list", equipment);

    var griddat = [];
    var griddatf = [];

    griddat.push(this.shopclosebtn);
    griddatf.push(this.shopclosebtn);

    griddat.push(this.leavebtn);
    this.wh.registry.list.gp.guigrid(griddat, griddatf);

  }



  async show_close(wh) {
    if (wh.partymycontainer == undefined) return;;
    wh.partymycontainer.visible = false;
    await wh.registry.list.gameitf.closegui(wh, wh.partymycontainer);
    for (const k in this.wh.partymycontainer.list) {
      try {
        await this.wh.partymycontainer.remove(this.wh.partymycontainer.list[k], true);
        //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list[k].destroy();
      } catch (e) {
        this.plog.log("ERROR! partymycontainer.destroy() ", e);
      }
    }

    for (const k in this.wh.partymycontainer.list) {
      try {
        await this.wh.partymycontainer.remove(this.wh.partymycontainer.list[k], true);
        //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list[k].destroy();
      } catch (e) {
        this.plog.log("ERROR! partymycontainer.destroy() ", e);
      }
    }
    wh.partymycontainer = undefined;
  }

  rebuildguigrid() {
    this.plog.log("rebuildguigrid();b", this.wh.kbfocus);
    ///this.show(this.wh);
    this.plog.log("rebuildguigrid();a", this.wh.kbfocus);
  }
}
