import { Injectable } from '@angular/core';
import { RCVarsService } from './rcvars-service.service';
import { RCOSDService } from './rcosd.service';
import { RCHTTP } from './rchttp.service';
import { RCRequirementService } from './rcrequirement.service';
import { plog } from './plog.service';

@Injectable({
  providedIn: 'root'
})
export class DMServices {
  title;
  inputtext;

  questcompletebtn;
  sendbtn;
  msglist = [];
  qname;
  itemdescr;
  roomid = "";
  wh;
  shopguibg;
  shopclosebtn;
  dmid;
  rewardt;
  aq;//active quest
  plog;

  constructor(
    public rcvarsservice: RCVarsService,
    public rcosd: RCOSDService,
    public rchttp: RCHTTP,
    public req: RCRequirementService
  ) { 
    this.plog=new plog();
  }

  firsttimerun = true;
  firsttimerunfunc() {
    if (this.firsttimerun == true) {
      this.wh.registry.list.gameitf.guiwindowclick(this.wh.dmcontainer);
    }
    this.firsttimerun = false;
  }
  async show(wh, dmid, forcefocus = false) {
    this.plog.log("quest show", wh, dmid, this);
    if (forcefocus == true) this.firsttimerun = true;

    this.wh = wh;
    this.dmid = dmid;
    //await this.rchttp.updatecharinfo();
    this.aq = await this.wh.registry.list.rchttp.getcommon2("dm", { dmid: this.dmid }); //this.rcvarsservice.activechar["quest"];
    //this.show_close();
    try {
      var idsmin = Math.min(dmid, this.wh.registry.list.rcvarpass.activechar["id"]);
      var idsmax = Math.max(dmid, this.wh.registry.list.rcvarpass.activechar["id"]);
    } catch (e) {
      this.plog.warn("ERROR!", e);
    }
    this.roomid = idsmin + "-" + idsmax;
    if (wh.dmcontainer != undefined) {
      wh.dmcontainer.visible = true;
      this.show_loaded();
      return;
    }
    var width = wh.game.canvas.width;
    var height = wh.game.canvas.height;
    this.plog.log("dm show", wh, this);


    const shopguibg = wh.add.image(0, 0, "blankbarguibg");//questguibg
    shopguibg.setOrigin(0.5, 0.5);
    shopguibg.setInteractive({ pixelPerfect: true });
    shopguibg.removeAllListeners();
    this.shopguibg = shopguibg;
    this.plog.log("questguibg var", this.rcvarsservice);

    var shopclosebtn = wh.add.sprite(300, 300, "thesq", 'close');
    this.shopclosebtn = shopclosebtn;
    this.shopclosebtn.setOrigin(0.5, 0.5);
    this.shopclosebtn.setPosition(155, -200);
    shopclosebtn.displayWidth = 40;
    shopclosebtn.displayHeight = 40;
    this.shopclosebtn.setInteractive();
    this.shopclosebtn.removeAllListeners();
    this.shopclosebtn.on('pointerup', function (pointer, localX, localY, event) {
      //this.scene.registry.list["thethis"].phm.floorclick(this);
      this.plog.log("gc.npcbackbtn pointerup", pointer, localX, localY, event, this);
      this.show_close(wh);
    }, this);



    wh.dmcontainer = wh.add.container(300, 300);
    wh.dmcontainer.name = "dmcontainer";
    wh.dmcontainer.add(this.shopclosebtn);
    wh.dmcontainer.add(shopguibg);
    wh.dmcontainer.bringToTop(this.shopclosebtn);
    //Click Item to View Description
    var itemdescr = wh.add.text(0, 0, " ", { fontFamily: 'ffff', fontSize: '12px', fill: '#000', boundsAlignH: "center", boundsAlignV: "middle" });
    wh.dmcontainer.add(itemdescr);
    itemdescr.setOrigin(0, 0);
    itemdescr.setPosition(-140, 179);
    this.itemdescr = itemdescr;




    //drag


    var shopdragbtn = wh.add.image(300, 300, 'spacer');
    wh.shopdragbtn = shopdragbtn;
    wh.shopdragbtn.setOrigin(0.5, 0.5);
    wh.shopdragbtn.setPosition(0, -200);
    shopdragbtn.displayWidth = 270;
    shopdragbtn.displayHeight = 60;
    wh.shopdragbtn.setInteractive();
    wh.shopdragbtn.removeAllListeners();
    wh.shopdragbtn.on('pointerout', function (pointer, localX, localY, event) {
      this.rcvarsservice.gameobj.scene.scenes[0].dmcontainer.dragactive = false;
    }, this);
    wh.shopdragbtn.on('pointerup', function (pointer, localX, localY, event) {
      this.rcvarsservice.gameobj.scene.scenes[0].dmcontainer.dragactive = false;
    }, this);
    wh.shopdragbtn.on('pointerdown', function (pointer, localX, localY, event) {
      this.rcvarsservice.gameobj.registry.list.gameitf.guiwindowclick(this.rcvarsservice.gameobj.scene.scenes[0].dmcontainer);

      this.rcvarsservice.gameobj.scene.scenes[0].dmcontainer.dragactive = true;
      this.rcvarsservice.gameobj.scene.scenes[0].dmcontainer.dragactivex = this.rcvarsservice.gameobj.scene.scenes[0].dmcontainer.x;
      this.rcvarsservice.gameobj.scene.scenes[0].dmcontainer.dragactivey = this.rcvarsservice.gameobj.scene.scenes[0].dmcontainer.y;
      this.rcvarsservice.gameobj.scene.scenes[0].dmcontainer.dragactivepx = pointer.x;
      this.rcvarsservice.gameobj.scene.scenes[0].dmcontainer.dragactivepy = pointer.y;
    }, this);
    this.rcvarsservice.gameobj.scene.scenes[0].dmcontainer.dragactive = false;
    this.rcvarsservice.gameobj.scene.scenes[0].dmcontainer.dragactivex = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].dmcontainer.dragactivey = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].dmcontainer.dragactivepx = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].dmcontainer.dragactivepy = 0;
    wh.shopdragbtn.on('pointermove', function (pointer, localX, localY, event) {
      //this.scene.registry.list["thethis"].phm.floorclick(this);
      //this.plog.log("shop pointermove", pointer, localX, localY, event, this);
      //this.showinventory_close(wh);
      if (this.rcvarsservice.gameobj.scene.scenes[0].dmcontainer.dragactive == true) {
        var tmpx = this.rcvarsservice.gameobj.scene.scenes[0].dmcontainer.dragactivex;
        var tmpy = this.rcvarsservice.gameobj.scene.scenes[0].dmcontainer.dragactivey;
        var tmppx = this.rcvarsservice.gameobj.scene.scenes[0].dmcontainer.dragactivepx;
        var tmppy = this.rcvarsservice.gameobj.scene.scenes[0].dmcontainer.dragactivepy;
        var movedx = tmppx - pointer.x;
        var movedy = tmppy - pointer.y;
        //this.plog.log("drag", tmppx, pointer.x, movedx);

        this.rcvarsservice.gameobj.scene.scenes[0].dmcontainer.x = tmpx - movedx;
        this.rcvarsservice.gameobj.scene.scenes[0].dmcontainer.y = tmpy - movedy;
        //this.maskg.setPosition(this.rcvarsservice.gameobj.scene.scenes[0].dmcontainer.x,this.rcvarsservice.gameobj.scene.scenes[0].dmcontainer.y)
        //this.rcvarsservice.gameobj.scene.scenes[0].dmcontainer.y = pointer.y;
        if (this.inputtext != undefined) {
          this.inputtext.setPosition(this.rcvarsservice.gameobj.scene.scenes[0].dmcontainer.x - 20,
            this.rcvarsservice.gameobj.scene.scenes[0].dmcontainer.y + 200);
        }

      }
    }, this);
    wh.dmcontainer.add(wh.shopdragbtn);
    wh.dmcontainer.bringToTop(wh.shopdragbtn);


    this.title = wh.add.text(0, 0, 'MESSAGE', wh.fs["winguititle"]);
    //this.plog.log("winguititle fs", wh.fs["winguititle"])
    wh.dmcontainer.add(this.title);
    wh.dmcontainer.bringToTop(this.title);
    this.title.setPosition(0 - this.title.displayWidth / 2, -223);
    //var equipment = this.rcvarsservice.activechar["equip"];
    //this.plog.log("equipment eq", equipment);
    //this.plog.log("dmcontainer", wh.dmcontainer, width, height);
    wh.dmcontainer.setPosition((width / 2), (height / 2));
    let loader = new Phaser.Loader.LoaderPlugin(wh);

    loader.once(Phaser.Loader.Events.COMPLETE, () => {
      this.plog.log("Phaser.Loader.Events.COMPLETE");
      this.plog.log(this);
      this.show_loaded();
    }, this);
    loader.start();
  }
  show_loaded() {
    //this.rcvarsservice.gameobj.registry.list.gameitf.guiwindowclick(this.rcvarsservice.gameobj.scene.scenes[0].dmcontainer);
    this.plog.log("dm show_loaded", this.aq);
    this.firsttimerunfunc();
    this.rcvarsservice.gameobj.scene.scenes[0].registry.list.wsrc.senddm(this.dmid, "PINGDM");

    if (this.qname != undefined) {
      this.qname.destroy();
      this.qname = undefined;
    }
    if (this.sendbtn != undefined) {
      this.sendbtn.destroy();
      this.sendbtn = undefined;
    }

    if (this.aq == undefined) {
      this.show_close();
      return;
    }
    this.qname = this.wh.add.text(0, 0, "" + this.wh.gl(this.aq["char"]["name"]), this.wh.fs["questinfo_title"]);
    this.qname.setWordWrapWidth(this.shopguibg.displayWidth * 0.8);
    this.wh.dmcontainer.add(this.qname);
    this.wh.dmcontainer.bringToTop(this.qname);
    this.qname.setPosition(0 - (this.qname.displayWidth / 2), -170);


    this.inputtext = this.wh.add
      .rexInputText(0, 0, 250, 25, {
        id: 'dminput',
        type: 'text',
        text: '',
        fontSize: '20px',
        color: '#000',
        autoComplete: 'off',
        border: 2,
        backgroundColor: '#f2f2f2',
        borderColor: '#000',
        selectAll: true,
      })
      .resize(250, 30)
      .setOrigin(0.5);

    this.sendbtn = this.wh.add.sprite(0, 0, "thesq", "send");
    this.sendbtn.displayWidth = this.sendbtn.displayHeight = 50;
    this.wh.dmcontainer.add(this.sendbtn);
    this.wh.dmcontainer.bringToTop(this.sendbtn);
    this.sendbtn.setPosition(135, 200);
    this.sendbtn.removeAllListeners();
    this.sendbtn.setInteractive();
    this.sendbtn.on("pointerup", function () {
      this.rcvarsservice.gameobj.scene.scenes[0].registry.list.wsrc.senddm(this.dmid, this.inputtext.text);
      this.inputtext.text = "";
    }, this);

    try {
      //this.wh.dmcontainer.add(this.inputtext);
      // this.wh.dmcontainer.bringToTop(this.inputtext);
      this.inputtext.setPosition(this.wh.dmcontainer.x - 20, this.wh.dmcontainer.y + 200);
      //this.inputtext.setPosition(-20, 200);
      this.inputtext.displayWidth = 150;
    } catch (e) {
      this.plog.warn("ERROR!", e);
    }
  }
  show_close() {
    if (this.qname != undefined) {
      this.qname.destroy();
      this.qname = undefined;
    }
    if (this.inputtext != undefined) {
      this.inputtext.destroy();
      this.inputtext = undefined;
    }
    if (this.sendbtn != undefined) {
      this.sendbtn.destroy();
      this.sendbtn = undefined;
    }

    for (var k in this.msglist) {
      this.msglist[k].destroy();
      this.msglist[k] = undefined;
    }
    this.msglist = [];
    if (this.wh.dmcontainer != undefined) {
      this.wh.registry.list.gameitf.closegui(this.wh, this.wh.dmcontainer);

      //this.wh.dmcontainer.visible = false;
    }

  }
  updatedm(dat) {
    this.plog.log("dmservice updatedm", dat);
    this.plog.log("dmservice updatedm currentroomid", this.roomid);
    if (dat == undefined) return;
    if (dat["roomid"] == this.roomid) {
      for (var k in this.msglist) {
        this.msglist[k].destroy();
        this.msglist[k] = undefined;
      }
      this.msglist = [];
      var dspcount = 0;
      for (const k in dat) {
        if (k == "mode") continue;
        if (k == "time") continue;
        if (k == "roomid") continue;
        var v = dat[k];
        if (v["msg"] == "PINGDM") continue;
        var charname = "";
        if (v["sender"] == this.dmid) { //other 
          charname = this.aq["char"]["name"];
        } else {
          charname = this.wh.registry.list.rcvarpass.activechar["name"];
        }
        this.msglist[dspcount] = this.wh.add.text(0, 0, charname + ": " + v["msg"], this.wh.fs["dmtext"]);
        this.wh.dmcontainer.add(this.msglist[dspcount]);
        this.wh.dmcontainer.bringToTop(this.msglist[dspcount]);
        this.msglist[dspcount].setPosition(-140, 160 - (dspcount * 20));
        this.msglist[dspcount].setWordWrapWidth(this.shopguibg.displayWidth * 0.8);

        dspcount++;
        if (dspcount >= 16) break;
      }
    }
  }
}
