import { Injectable } from '@angular/core';
import { plog } from './plog.service';
import { RCVarsService } from './rcvars-service.service';


@Injectable({
  providedIn: 'root',
})
export class TutService {
  objlist = [];
  batch = [];
  followlist = [];
  wh;
  plog;
  showtap_wait = false;
  timer;
  closebtn;
  notutbtn;
  istutrunning = false;

  constructor(
    private rcvarsservice: RCVarsService) {
    this.plog = new plog();
    //this.plog.setLevel(4);
  }
  creategui() {

    var width = this.wh.game.canvas.width;
    var height = this.wh.game.canvas.height;
    this.closebtn = this.wh.add.sprite(0, 0, "thesb", "skip");
    this.notutbtn = this.wh.add.sprite(0, 0, "thesb", "tutoff");
    this.closebtn.displayWidth = 150;
    this.closebtn.scaleY = this.closebtn.scaleX;
    this.notutbtn.displayWidth = 150;
    this.notutbtn.scaleY = this.notutbtn.scaleX;
    this.notutbtn.setPosition(width - 80, height - 40);
    this.closebtn.setPosition(width - 80, height - 100);
    this.notutbtn.setDepth(100000000);
    this.closebtn.setDepth(100000000);

    this.closebtn.setInteractive();
    this.closebtn.removeAllListeners();
    this.closebtn.on("pointerup", function () {
      this.batch = [];
      this.close();
    }.bind(this), this);

    this.notutbtn.setInteractive();
    this.notutbtn.removeAllListeners();
    this.notutbtn.on("pointerup", async function () {
      await this.wh.registry.list.rcstorage.set("ztutdisable", "yes");
      this.batch = [];
      this.close();
    }.bind(this), this);

    this.wh.tweens.add(
      {
        targets: [this.closebtn, this.notutbtn],
        scale: 1.1,
        ease: 'Linear',
        duration: 100, // duration of animation; higher=slower
        delay: 3000,
        repeatDelay: 1000,
        repeat: -1,
        yoyo: true,
      }, this);
  }
  close() {
    this.plog.log("tut.close();", this.notutbtn, this.closebtn);
    try {
      this.notutbtn.destroy(true);
    } catch (e) {
      //console.log(e);
    }
    try {
      this.closebtn.destroy(true);
    } catch (e) {
      //console.log(e);
    }
    try {
      this.notutbtn.destroy();
    } catch (e) {
      //console.log(e);
    }
    try {
      this.closebtn.destroy();
    } catch (e) {
      //console.log(e);
    }
    this.clear();

    if (this.timer != undefined) {
      this.timer.remove(false);
      this.timer = undefined;
    }
    this.closebtn = undefined;
    this.notutbtn = undefined;
    this.istutrunning = false;
    this.plog.log("tut.close() END;", this.notutbtn, this.closebtn);

  }
  async show(wh, tutid) {
    //this.plog.log("tut.show()", wh, tutid);
    this.plog.log("tut.show() " + tutid, this.closebtn, this.notutbtn);
    this.plog.log("tut.show() istutrunning", this.istutrunning);
    if (this.istutrunning == true) {
      this.plog.warn("tut.show() stopping, istutrunning==true;");
      return;
    }
    this.istutrunning = true;
    if (this.timer != undefined) {
      this.timer.remove(false);
      this.timer = undefined;
    }
    //this.plog.log('notif show(),', wh, tg, pos, secs);
    if (wh != undefined) this.wh = wh;
    this.close();
    this.close();
    var chkdisable = await this.wh.registry.list.rcstorage.get("ztutdisable", false);
    if (chkdisable == "yes") {
      this.plog.log("ztutdisable", chkdisable);
      return;
    }
    var chkisdone = await this.wh.registry.list.rcstorage.get("ztutdone_" + tutid, false);
    if (chkisdone == "yes") {
      this.plog.log("tutdone", "ztutdone_" + tutid, chkisdone);
      return;
    }
    await this.wh.registry.list.rcstorage.set("ztutdone_" + tutid, "yes");

    var currentmap = this.rcvarsservice.activemap['map']['code'];
    this.plog.log("currentmap", currentmap);
    this.creategui();
    this.wh.camman(this.wh);
    this.timer = this.wh.time.addEvent(
      {
        delay: 300,
        callback: this.timerrun,
        args: [],
        callbackScope: this,
        loop: true,
        repeat: 0,
        startAt: 0,
        timeScale: 1,
        paused: false,
      },
      this
    );

    switch (tutid) {
      case "mapload-afterlogin":
        if (currentmap == "map1") {
          this.batch = [
            ["showhandmap", 21, 26, "floor"],
            ["showtext", "Click or tap floor to walk"],
            ["pause-handclick"],
            ["clear"],
            ["walkto", 22, 27],
            ["wait", 500],
            ["showtext", "Good! That's how you walk!"],
            ["wait", 4000],
            ["clear"],
            ["walkto", 28, 40],
            ["showtext", "Click on a monster to begin combat"],
            ["showhandmap_follow", wh.mapmob[0].sprite, "startcombat"],
            ["pause-handclick"],
            ["close"],
          ];
          await this.runbatch();
        }
        //await this.showtap(wh.char_main, 0, 5);
        break;
      case "firstcombat":
        if (currentmap == "map1") {
          this.batch = [

            ["showcircleobj", this.wh.registry.list.combat.spdbtn2, 1.5],
            ["showtext", "Click to adjust the combat speed."],
            ["wait", 4000],
            ["clear"],

            ["showcircleobj", this.wh.registry.list.combat.closebtn, 1.5],
            ["showtext", "Click to cancel combat"],
            ["wait", 4000],
            ["clear"],

            ["showhandobj", this.wh.registry.list.combat.beginbattlebtn, "startcombat"],
            ["showtext", "Click Begin Battle"],
            ["pause-handclick"],
            ["close"],
          ];
          await this.runbatch();
        }
        break;
      case "dissmissfirstreward":
        if (currentmap == "map1") {
          this.batch = [
            ["walkto", 17, 43],
            //["walkto", 17, 43],
            //["showhandmap", 16, 43, "startcombat"],
            ["showhandmap_follow", this.wh.npclist["npc-map1buyer-15-43-map1"], "npcclick"],
            ["showtext", "Click an NPC to interact"],
            ["pause-handclick"],
            ["clear"],
            ["wait", 500],
            ["showhandobj", this.wh.registry.list.npcconver.npcbackbtn, "closenpcdialog"],
            ["showtext", "Click to interact with an NPC from the choices above.\n\nClick here to close or exit."],
            ["pause-handclick"],
            //["showhandobj", this.wh.registry.list.combat.beginbattlebtn, "startcombat"],
            ["close"],
          ];
          await this.runbatch();
        }
        break;

      case "firstenterhome":

        this.batch = [
          ["wait", 400],
          ["showcirclemap", 18, 12, 1],
          ["showhandmap_follow", this.wh.char_main, "none"],

          ["walkto", 18, 18],
          ["wait", 1500],
          ["showtext", "This is your home"],
          ["wait", 4500],
          ["clear"],

          ["walkto", 22, 12],
          ["wait", 1500],
          ["showcirclemap", 23, 12, 2],
          ["showtext", "This is your bed. Click to heal HP for free."],
          ["wait", 5000],
          ["clear"],

          ["walkto", 15, 22],
          ["wait", 1500],
          ["showcirclemap", 13, 22, 3],
          ["showtext", "This is your home inventory.\n\nUse it to share items between your characters."],
          ["wait", 5500],
          ["clear"],
          //["clear"],
          //["showhandobj", this.wh.registry.list.combat.beginbattlebtn, "startcombat"],
          //["close"],
        ];
        await this.runbatch();

        break;

      case "rcattriloaded":
        this.batch = [

          ["showcircleobj", this.wh.registry.list.attri.levelupbtn, 2],
          ["showtext", "Click [Level Up] when you're ready."],
          ["wait", 4000],
          ["close"],
        ];
        await this.runbatch();
        break;
      case "msgloaded":
        this.batch = [

          ["showcircleobj", this.wh.registry.list.msgs.collectallbtn, 2],
          ["showtext", "Click [Collect All] to gather all items."],
          ["wait", 4000],
          ["close"],
        ];
        await this.runbatch();
        break;

      case "npcshop_stack_loaded":
        this.batch = [

          ["showcircleobj", this.wh.registry.list.npcshop.shopitemlist[0], 1.5],
          ["showtext", "Select item you want to buy from above"],
          ["wait", 4000],
          ["clear"],
          ["showcircleobj", this.wh.registry.list.npcshop.shopbuybtn, 1.5],
          ["showtext", "Then click [BUY]"],
          ["wait", 4000],
          ["clear"],
          ["showcircleobj", this.wh.registry.list.npcshop.buy10btn, 1],
          ["showtext", "Or click here to buy 10 items at once"],
          ["wait", 4000],
          ["clear"],
          ["showcircleobj", this.wh.registry.list.npcshop.buymultbtn, 1],
          ["showtext", "or click here to enter amount"],
          ["wait", 4000],
          ["close"],
        ];
        await this.runbatch();
        break;

      case "npcshop_item_loaded":
        this.batch = [

          ["showcircleobj", this.wh.registry.list.npcshop.shopitemlist[0], 1.5],
          ["showtext", "Select item you want to buy from above"],
          ["wait", 4000],
          ["clear"],
          ["showcircleobj", this.wh.registry.list.npcshop.shopbuybtn, 1.5],
          ["showtext", "Then click [BUY]"],
          ["wait", 4000],
          ["close"],
        ];
        await this.runbatch();
        break;


      case "questlistloaded_main":
        this.batch = [

          ["showcircleobj", this.wh.registry.list.questlist.btnstory, 1],
          ["showtext", "Here is the list of your accepted quests."],
          ["wait", 4000],
          ["clear"],

          ["showcircleobj", this.wh.registry.list.questlist.btndaily, 1],
          ["showtext", "Here is the list of your daily quests."],
          ["wait", 4000],
          ["clear"],

          ["showcircleobj", this.wh.registry.list.questlist.btnweekly, 1],
          ["showtext", "Here is the list of your weekly quests."],
          ["wait", 4000],
          ["close"],
        ];
        await this.runbatch();
        break;

      case "questlistloaded_daily":
        this.batch = [
          ["showcircleobj", this.wh.registry.list.questlist.dwichest_daily, 1],
          ["showtext", "Complete all the tasks above to receive a big daily quest reward."],
          ["wait", 4000],
          ["close"],
        ];
        await this.runbatch();
        break;
      case "questlistloaded_weekly":
        this.batch = [
          ["showcircleobj", this.wh.registry.list.questlist.dwichest_weekly, 1],
          ["showtext", "Complete all the tasks above to receive a big weekly quest reward."],
          ["wait", 4000],
          ["close"],
        ];
        await this.runbatch();
        break;


      case "pet_home_loaded_gotpet":
        this.batch = [
          ["showcircleobj", this.wh.registry.list.petgui.renamebtn[0], 0.5],
          ["showtext", "Click here to rename this pet."],
          ["wait", 4000],
          ["clear"],

          ["showcircleobj", this.wh.registry.list.petgui.takebtn[0], 1],
          ["showtext", "Click here to take this pet with you."],
          ["wait", 4000],
          ["close"],
        ];
        await this.runbatch();
        break;
      case "pet_travel_needhealing":
        this.batch = [
          ["showcircleobj", this.wh.registry.list.pettravel.healbtn[0], 1],
          ["showtext", "Click here to heal this pet."],
          ["wait", 4000],
          ["close"],
        ];
        await this.runbatch();
        break;


      case "pet_travel_needhealinghealallpets":
        this.batch = [
          ["showcircleobj", this.wh.registry.list.pettravel.healallpets, 1],
          ["showtext", "Click here to heal all pets at once."],
          ["wait", 4000],
          ["close"],
        ];
        await this.runbatch();
        break;
      case "stackinven_sellanywhere":
        this.batch = [
          ["showcircleobj", this.wh.registry.list.stackinven.sellanywhere, 1],
          ["showtext", "Click here to sell items from anywhere."],
          ["wait", 4000],
          ["close"],
        ];
        await this.runbatch();
        break;
      case "inven_sellanywhere":
        this.batch = [
          ["showcircleobj", this.wh.registry.list.inven.sellanywhere, 1],
          ["showtext", "Click here to sell items from anywhere."],
          ["wait", 4000],
          ["close"],
        ];
        await this.runbatch();
        break;

      case "iapstore_freecategory":
        this.batch = [
          ["showcircleobj", this.wh.registry.list.iapstore.submn["free"], 0.7],
          ["showtext", "Click here to see your daily free items."],
          ["wait", 4000],
          ["close"],
        ];
        await this.runbatch();
        break;




      default:
        this.plog.error("tut error, cant find tutid:", tutid);
        break;
    }
    this.close();
  }
  async runbatch() {
    if (this.batch.length == 0) {
      this.plog.log("runbatch() item length=0, haulting")
      return;
    }
    let firstItem = this.batch.shift();
    //this.plog.log("runbatch() doing", firstItem, "stack=", this.batch);
    switch (firstItem[0]) {
      case "pause-handclick":
        while (this.showtap_wait == true) {
          await this.wh.registry.list.rctoast.sleep(300);
        }
        break;
      case "showhandmap":
        await this.showhandmap(firstItem);
        break;
      case "showhandmap_follow":
        await this.showhandmap_follow(firstItem);
        break;
      case "showcircleobj":
        await this.showcircleobj(firstItem);
        break;
      case "showhandobj":
        await this.showhandobj(firstItem);
        break;
      case "showcirclemap":
        await this.showcirclemap(firstItem);
        break;

      case "showtext":
        await this.showtext(firstItem);
        break;
      case "walkto":
        await this.walkto(firstItem);
        break;
      case "wait":
        await this.wait(firstItem);
        break;
      case "clear":
        this.clear();
        break;
      case "close":
        this.close();
        break;
      default:
        this.plog.error("runbatch(), unknown cmd", firstItem);
        this.close();
        break;
    }
    this.wh.camman(this.wh);
    //this.clear();
    if (this.batch.length > 0) {
      await this.runbatch();
    }
    this.close();
    //this.clear();
  }
  clear() {
    for (var k in this.objlist) {
      try {
        this.objlist[k].destroy();
      } catch (e) {
        //console.log(e);
      }
      try {
        this.objlist[k] = undefined;
      } catch (e) {
        //console.log(e);
      }
    }
  }
  async walkto(arg) {
    this.plog.log("walkto()", arg, this);
    this.wh.registry.list.phm.floorclick({ name: "any-" + arg[1] + "-" + arg[2] });
  }
  async wait(arg) {
    this.plog.log("TUT wait", arg[1]);
    await this.wh.registry.list.rctoast.sleep(arg[1]);
    this.plog.log("TUT wait END", arg[1]);
  }
  showautofow(handobj) {

    this.plog.log("showautofow()", handobj);
    if (handobj == undefined) {
      this.plog.error("showautofow() handobj not found", handobj);
      return;
    }

    if (this.objlist['autofow'] != undefined) {
      try {
        this.objlist['autofow'].destroy();
        this.objlist['autofow'] = undefined;
      } catch (e) {
        console.log(e);
      }
    }
    var x = handobj.x;
    var y = handobj.y;

    var fowcol = Phaser.Display.Color.GetColor(255, 255, 255);
    //var redcol = Phaser.Display.Color.GetColor(255, 0, 0);
    //this.plog.log(this);
    var width = this.wh.game.canvas.width;
    var height = this.wh.game.canvas.height;
    this.objlist['autofow'] = this.wh.add.renderTexture(0, 0, width * 3, height * 3);

    this.objlist['autofow'].fill(fowcol, 0.4);

    this.objlist['autofow'].setOrigin(0.5, 0.5);
    this.objlist['autofow'].setPosition(x, y);
    const circlered = this.wh.add.circle(0, 0, 44, 0xff0000).setOrigin(0.5, 0.5).setVisible(false);
    const circle = this.wh.add.circle(0, 0, 40, 0xffffff).setOrigin(0.5, 0.5).setVisible(false);
    //this.objlist['fow'].draw(circle, obj.x + usexpos -64, obj.y + useypos-64, 1, redcol)
    if (handobj.parentContainer != undefined) {
      handobj.parentContainer.add(this.objlist['autofow']);
      handobj.parentContainer.bringToTop(this.objlist['autofow']);
      handobj.parentContainer.bringToTop(handobj);
    }
    this.objlist['autofow'].setInteractive();
    this.objlist['autofow'].removeAllListeners();
    var usexpos = x;
    var useypos = y;

    this.objlist['autofow'].draw(circlered, this.objlist['autofow'].displayWidth / 2, this.objlist['autofow'].displayHeight / 2, 1, 0xff0000)
    this.objlist['autofow'].erase(circle, this.objlist['autofow'].displayWidth / 2, this.objlist['autofow'].displayHeight / 2);
    this.objlist['autofow'].fill(fowcol, 0.05);

    var followlistid = "fowautofollow" + this.generateUniqueRandomString();
    this.followlist[followlistid] = [];
    this.followlist[followlistid]["target"] = this.objlist['hand'];
    this.followlist[followlistid]["follower"] = this.objlist['autofow'];

  }
  async showhandmap(arg) {
    this.plog.log("showhandmap()", arg);
    if (arg[0] == undefined) {
      this.plog.error("showhandmap() obj not found", arg);
      return;
    }
    var x = arg[1];
    var y = arg[2];
    this.showtap_wait = true;
    if (this.objlist['hand'] != undefined) {
      try {
        this.objlist['hand'].destroy();
        this.objlist['hand'] = undefined;
      } catch (e) {
        console.log(e);
      }
    }
    var width = this.wh.game.canvas.width;
    var height = this.wh.game.canvas.height;

    var usexpos = x * 32;
    var useypos = y * 32;
    var centerx = usexpos; //minus pointertab
    var centery = useypos; //minus pointertab 
    this.objlist['hand'] = this.wh.add.sprite(100, 100, "pointertap");;;
    this.objlist['hand'].setOrigin(0.5, 0.5);
    this.objlist['hand'].play({ key: "pointertap_b", frameRate: 6, repeat: -1 });
    //if (obj.parentContainer != undefined) {
    this.wh.mapcontainer.add(this.objlist['hand']);
    this.wh.mapcontainer.bringToTop(this.objlist['hand']);
    this.objlist['hand'].setScale(1.7);

    //}
    if (arg[3] == "floor") {
      this.objlist['hand'].setInteractive();
      this.objlist['hand'].removeAllListeners();
      this.objlist['hand'].on("pointerup", function () {
        //this.plog.log(arguments, this);
        //this.wh.registry.list.phm.floorclick(arguments[2] + "-" + arguments[3]);
        this.showtap_wait = false;
      }.bind(this, arg), this);
    } else if (arg[3] == 'startcombat') {
      this.objlist['hand'].setInteractive();
      this.objlist['hand'].removeAllListeners();
      this.objlist['hand'].on("pointerup", function () {
        //this.plog.log(arguments, this);
        //this.wh.registry.list.phm.floorclick(arguments[2] + "-" + arguments[3]);
        this.showtap_wait = false;
        this.wh.registry.list.combat.start(this.wh);

      }.bind(this, arg), this);
    } else if (arg[3] == 'npcclick') {
      this.objlist['hand'].setInteractive();
      this.objlist['hand'].removeAllListeners();
      this.objlist['hand'].on("pointerup", function () {
        //this.plog.log(arguments, this);
        //this.wh.registry.list.phm.floorclick(arguments[2] + "-" + arguments[3]);
        this.wh.registry.list.phm.npcclick(arg[4]);
        this.showtap_wait = false;
      }.bind(this, arg), this);
    } else {
      this.objlist['hand'].setInteractive();
      this.objlist['hand'].removeAllListeners();
      this.objlist['hand'].on("pointerup", function () {
        //this.plog.log(arguments, this);
        //this.wh.registry.list.phm.floorclick(arguments[2] + "-" + arguments[3]);
        this.showtap_wait = false;
      }.bind(this, arg), this);
      //this.objlist['hand'].off();
      //this.objlist['hand'].removeInteractive();
    }

    this.objlist['hand'].setPosition(centerx, centery);
    this.showautofow(this.objlist['hand']);
    //this.plog.log(this.objlist);

  }
  async showtext(arg) {
    this.plog.log("showtext()", arg);
    if (arg[0] == undefined) {
      this.plog.error("showtext() no text", arg);
      return;
    }

    if (this.objlist['text'] != undefined) {
      try {
        this.objlist['text'].destroy();
        this.objlist['text'] = undefined;
      } catch (e) {
        console.log(e);
      }
    }
    var x = arg[1];
    var y = arg[2];

    var width = this.wh.game.canvas.width;
    var height = this.wh.game.canvas.height;
    this.objlist["text"] = this.wh.add.text(0, 0, arg[1], { fontFamily: 'ffff', fontSize: '20px', stroke: '#fff', strokeThickness: 3, color: '#f00', fontStyle: 'bold', wordWrap: { width: width * 0.8, useAdvancedWrap: true }, });

    this.objlist["text"].setDepth(100000000);
    this.objlist["text"].setOrigin(0.5, 0.5);
    this.objlist["text"].setPosition(width / 2, height * 0.7);
    var usexpos = x * 32;
    var useypos = y * 32;
    var centerx = usexpos; //minus pointertab
    var centery = useypos; //minus pointertab
    var circlex = centerx;//- 16; //minus pointertab
    var circley = centery;//- 16; //minus pointertab 
    //this.plog.log(this.objlist);
    this.wh.tweens.add(
      {
        targets: this.objlist["text"],
        scale: 1.05,
        ease: 'Linear',
        duration: 800, // duration of animation; higher=slower
        delay: 500,
        repeatDelay: 1000,
        repeat: -1,
        yoyo: true,
      }, this);
  }
  async showcirclemap(arg) {
    this.plog.log("showcirclemap()", arg);
    if (this.objlist['circlemap'] != undefined) {
      try {
        this.objlist['circlemap'].destroy();
        this.objlist['circlemap'] = undefined;
      } catch (e) {
        console.log(e);
      }
    }
    var x = arg[1] * 32;
    var y = arg[2] * 32;
    var csize = arg[3] * 32;
    var fowcol = Phaser.Display.Color.GetColor(255, 255, 255);
    //var redcol = Phaser.Display.Color.GetColor(255, 0, 0);
    //this.plog.log(this);
    var width = this.wh.game.canvas.width;
    var height = this.wh.game.canvas.height;
    this.objlist['circlemap'] = this.wh.add.renderTexture(0, 0, width * 3, height * 3);

    this.objlist['circlemap'].fill(fowcol, 0.4);

    this.objlist['circlemap'].setOrigin(0.5, 0.5);
    this.objlist['circlemap'].setPosition(x, y);
    const circlered = this.wh.add.circle(0, 0, csize + 4, 0xff0000).setOrigin(0.5, 0.5).setVisible(false);
    const circle = this.wh.add.circle(0, 0, csize, 0xffffff).setOrigin(0.5, 0.5).setVisible(false);
    //this.objlist['fow'].draw(circle, obj.x + usexpos -64, obj.y + useypos-64, 1, redcol)
    //if (handobj.parentContainer != undefined) {
    //}
    this.objlist['circlemap'].setInteractive();
    this.objlist['circlemap'].removeAllListeners();

    this.objlist['circlemap'].fill(fowcol, 0.4);
    this.objlist['circlemap'].draw(circlered, this.objlist['circlemap'].displayWidth / 2, this.objlist['circlemap'].displayHeight / 2, 1, 0xff0000)
    this.objlist['circlemap'].erase(circle, this.objlist['circlemap'].displayWidth / 2, this.objlist['circlemap'].displayHeight / 2);
    this.objlist['circlemap'].fill(fowcol, 0.05);
    this.objlist['circlemap'].setPosition(x - csize / 2, y - csize / 2);
    this.objlist['circlemap'].setVisible(true);

    this.wh.mapcontainer.add(this.objlist['circlemap']);
    this.wh.mapcontainer.bringToTop(this.objlist['circlemap']);

    var followlistid = "fowautofollow" + this.generateUniqueRandomString();
    this.followlist[followlistid] = [];
    this.followlist[followlistid]["target"] = this.wh.char_main;
    this.followlist[followlistid]["follower"] = this.objlist['circlemap'];

    this.plog.log("showcirclemap e", this.objlist['circlemap']);
  }
  async showcircleobj(arg) {
    this.plog.log("showcircleobj()", arg);
    if (this.objlist['circleobj'] != undefined) {
      try {
        this.objlist['circleobj'].destroy();
        this.objlist['circleobj'] = undefined;
      } catch (e) {
        console.log(e);
      }
    }
    if (arg[1] == undefined) {
      this.plog.error("showhandobj() obj not found", arg);
      return;
    }

    var obj = arg[1];
    var x = arg[1].x;
    var y = arg[1].y;
    var csize = arg[2] * 32;

    if (obj.originX == 0) x = x + obj.displayWidth / 2;
    if (obj.originY == 0) y = y + obj.displayHeight / 2;
    var fowcol = Phaser.Display.Color.GetColor(255, 255, 255);
    //var redcol = Phaser.Display.Color.GetColor(255, 0, 0);
    //this.plog.log(this);
    var width = this.wh.game.canvas.width;
    var height = this.wh.game.canvas.height;
    this.objlist['circleobj'] = this.wh.add.renderTexture(0, 0, width * 3, height * 3);

    this.objlist['circleobj'].fill(fowcol, 0.4);

    this.objlist['circleobj'].setOrigin(0.5, 0.5);
    this.objlist['circleobj'].setPosition(x, y);
    const circlered = this.wh.add.circle(0, 0, csize + 4, 0xff0000).setOrigin(0.5, 0.5).setVisible(false);
    const circle = this.wh.add.circle(0, 0, csize, 0xffffff).setOrigin(0.5, 0.5).setVisible(false);
    //this.objlist['fow'].draw(circle, obj.x + usexpos -64, obj.y + useypos-64, 1, redcol)
    //if (handobj.parentContainer != undefined) {
    //}
    this.objlist['circleobj'].setInteractive();
    this.objlist['circleobj'].removeAllListeners();

    this.objlist['circleobj'].fill(fowcol, 0.4);
    this.objlist['circleobj'].draw(circlered, this.objlist['circleobj'].displayWidth / 2, this.objlist['circleobj'].displayHeight / 2, 1, 0xff0000)
    this.objlist['circleobj'].erase(circle, this.objlist['circleobj'].displayWidth / 2, this.objlist['circleobj'].displayHeight / 2);
    this.objlist['circleobj'].fill(fowcol, 0.05);
    this.objlist['circleobj'].setPosition(x, y);
    this.objlist['circleobj'].setVisible(true);
    if (obj.parentContainer != undefined) {
      obj.parentContainer.add(this.objlist['circleobj']);
      obj.parentContainer.bringToTop(this.objlist['circleobj']);
    }
    /*
    var followlistid = "fowautofollow" + this.generateUniqueRandomString();
    this.followlist[followlistid] = [];
    this.followlist[followlistid]["target"] = this.wh.char_main;
    this.followlist[followlistid]["follower"] = this.objlist['circleobj'];
*/
    this.plog.log("showcircleobj e", this.objlist['circleobj']);
  }
  async showhandobj(arg) {
    this.plog.log("showhandobj()", arg);
    if (arg[1] == undefined) {
      this.plog.error("showhandobj() obj not found", arg);
      return;
    }
    if (this.objlist['hand'] != undefined) {
      try {
        this.objlist['hand'].destroy();
        this.objlist['hand'] = undefined;
      } catch (e) {
        console.log(e);
      }
    }
    var obj = arg[1];
    var x = arg[1].x;
    var y = arg[1].y;
    this.showtap_wait = true;

    if (obj.originX == 0) x = x + obj.displayWidth / 2;
    if (obj.originY == 0) y = y + obj.displayHeight / 2;
    var fowcol = Phaser.Display.Color.GetColor(255, 255, 255);
    //var redcol = Phaser.Display.Color.GetColor(255, 0, 0);
    //this.plog.log(this);
    var width = this.wh.game.canvas.width;
    var height = this.wh.game.canvas.height;
    var usexpos = x;//+ (obj.displayWidth / 2);;// * 32;
    var useypos = y;//+ (obj.displayHeight / 2);;// * 32;  
    this.objlist['hand'] = this.wh.add.sprite(100, 100, "pointertap");;;
    this.objlist['hand'].setOrigin(0.5, 0.5);
    this.objlist['hand'].play({ key: "pointertap_b", frameRate: 6, repeat: 100 });
    if (obj.parentContainer != undefined) {
      obj.parentContainer.add(this.objlist['hand']);
      obj.parentContainer.bringToTop(this.objlist['hand']);
    }
    this.objlist['hand'].setScale(1.7);
    if (arg[2] == "startcombat") {

      this.objlist['hand'].setInteractive();
      this.objlist['hand'].removeAllListeners();
      this.objlist['hand'].on("pointerdown", function () {
        //console.log(arguments, this);
        this.wh.registry.list.combat.beginbattle();

        this.showtap_wait = false;
      }.bind(this, arg), this);
    } else if (arg[2] == "closenpcdialog") {
      this.objlist['hand'].setInteractive();
      this.objlist['hand'].removeAllListeners();
      this.objlist['hand'].on("pointerdown", function () {
        //console.log(arguments, this);
        this.wh.registry.list.npcconver.closedialog(this.wh);
        this.showtap_wait = false;
      }.bind(this, arg), this);
    } else if (arg[2] == "none") {
      this.objlist['hand'].setInteractive();
      this.objlist['hand'].removeAllListeners();
      this.objlist['hand'].on("pointerdown", function () {
        //console.log(arguments, this);
        //this.wh.registry.list.phm.floorclick(arguments[2] + "-" + arguments[3]);
        this.showtap_wait = false;
      }.bind(this, arg), this);
    } else {
      this.objlist['hand'].setInteractive();
      this.objlist['hand'].removeAllListeners();
      this.objlist['hand'].on("pointerdown", function () {
        //console.log(arguments, this);
        //this.wh.registry.list.phm.floorclick(arguments[2] + "-" + arguments[3]);
        this.showtap_wait = false;
      }.bind(this, arg), this);
    }
    this.objlist['hand'].setPosition(usexpos, useypos);
    //this.plog.log(this.objlist);
    this.plog.log("showhandobj()", this.objlist['hand']);
    this.showautofow(this.objlist['hand']);

  }
  async showhandmap_follow(arg) {
    this.showhandmap([0, 0, 0, "" + arg[2], arg[1]]);
    var followlistid = "handmapfollow" + this.generateUniqueRandomString();
    this.followlist[followlistid] = [];
    this.followlist[followlistid]["target"] = arg[1];
    this.followlist[followlistid]["follower"] = this.objlist['hand'];

  }
  generateUniqueRandomString(length = 12) {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charsLength = chars.length;

    for (let i = 0; i < length; i++) {
      result += chars.charAt(Math.floor(Math.random() * charsLength));
    }

    return result + Date.now().toString(36);
  }
  timerrun() {
    //console.log("timerrun");
    for (var k in this.followlist) {
      if (this.followlist[k] == undefined) {
        continue;
      }
      if (this.followlist[k]["target"] == undefined) {
        continue;
      }
      if (this.followlist[k]["follower"] == undefined) {
        continue;
      }
      var t = this.followlist[k]["target"];
      var f = this.followlist[k]["follower"];
      //console.log("following", t, f);
      f.setPosition(t.x, t.y);

    }
  }
}
