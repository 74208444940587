import { Injectable } from '@angular/core';
import { RCVarsService } from './rcvars-service.service';
import { RCStorage } from './rcstorage.service';
import { RCHTTP } from './rchttp.service';
import { plog } from './plog.service';

interface serversS {
  ishidden: string;
  country: string;
  wsurl: string;
  versionstring: string;
  isdefault: string;
  id: number;
  code: string;
  descr: string;
  suggested: string;
  name: string;
  url: string;
}
interface raceS {
  id: number;
  name: string;
  descr: string;
}
@Injectable({
  providedIn: 'root',
})
export class RCSVVARSService {
  racelist;
  plog;

  constructor(
    public rcvarservice: RCVarsService,
    public rcstorage: RCStorage,
    public rchttp: RCHTTP
  ) {
    this.plog = new plog();
  }

  async updatelangpack(lang = "") {
    //  this.plog.error("updatelangpack() CHKskip, language pack loaded",lang,this.rcvarservice.lng);

    if (lang == "") {
      //if (this.rcvarservice.lang != undefined && this.rcvarservice.lang != "") {
      //  lang = this.rcvarservice.lang;
      //} else {
      lang = await this.rcstorage.get('setting_lang');
      //}
      if (lang == "" || lang == undefined) lang = "en";
    }
    if (lang == "" || lang == undefined) lang = "en";
    if (this.rcvarservice.lng != undefined && this.rcvarservice.lng["thislangcode"] == lang.toLowerCase()) {
      this.plog.log("updatelangpack() skip, language pack loaded");
      return;
    }
    //console.error("updatelp lang rcvarservice.lang",this.rcvarservice.lang);
    //console.error("updatelp lang rcstorage.lang",await this.rcstorage.get('setting_lang'));
    //console.error("rc_baseurl",this.rcvarservice.rc_baseurl);
    //console.error("rcserver_url",this.rcvarservice.rcserver_url);
    var lpackurl = this.rcvarservice.rcserver_url;
    if (lpackurl == undefined || lpackurl == "") lpackurl = this.rcvarservice.rc_baseurl;
    lang = lang.toLowerCase();
    var svversionstring = this.rcvarservice.svversionstring + "";
    ///tempolary while deving
    svversionstring = Math.random() + "";
    if (svversionstring == undefined) svversionstring = "antic" + Math.random() + "";
    var tmp = await this.rchttp.geturl(lpackurl + 'intl/' + lang + ".json?v=" + svversionstring);

    this.rcvarservice.lng = tmp;
    //this.plog.error(tmp);
    //this.plog.error(this.rcvarservice.lng);
    //this.rcvarservice.gamemenu = sv.data;
  }
  async initserversdata(mainpage): Promise<void> {
    //this.plog.error("HERE");
    var svstring = await this.rchttp.getServers();// + "";
    //console.log("svstring",svstring);
    for (var i = 0; i <= 5; i++) {
      if (svstring == undefined || svstring["data"] == undefined) {
        svstring = await this.rchttp.getServers();// + "";
      }
    }
    //var svstring = `{"get":"sv","error":"","data":{"main":{"code":"main","name":"Main (TH)","country":"TH,XX,","allowreg":"YES","ishidden":"NO","url":"https:\/\/raincash.co\/peacely\/provider\/","versionstring":"20220107121124_729","wsurl":"wss:\/\/raincash.co\/connects\/","suggested":"YES"},"th2":{"code":"th2","name":"Thailand 2","country":",TH,","allowreg":"YES","ishidden":"NO","url":"https:\/\/raincash.co\/peacely\/provider\/xx\/","versionstring":"","wsurl":"","suggested":"NO"},"test1":{"code":"test1","name":"test1","country":",","allowreg":"YES","ishidden":"YES","url":"https:\/\/test1.peacely.co\/svcli\/provider\/","versionstring":"20220107121126_2309","wsurl":"wss:\/\/test.peacely.co\/connects\/","suggested":"NO"},"tester-sg":{"code":"tester-sg","name":"Tester SG","country":",SG,TH,","allowreg":"YES","ishidden":"NO","url":"https:\/\/tester-sg.peacely.co\/svcli\/provider\/","versionstring":"20220108224707_2363","wsurl":"wss:\/\/tester-sg.peacely.co\/connects\/","suggested":"NO"}}}`;
    //this.plog.log("pass load cmd", sv);
    //sv.data.forEach(v => {

    try {
      //this.plog.log("svstring", svstring);
      var svstringa = svstring;//JSON.parse(svstring);
    } catch (e) {
      this.plog.error("Error parsing server string", e);
    }
    var mycountry = await this.rchttp.mycc();
    this.rcvarservice.rc_sv = svstringa['data'];
    this.rcvarservice.mycc = mycountry['data'];
    mainpage.rcserverlistreg = [];
    mainpage.rcserverlistforgotpassword = [];
    //this.plog.log('loop data svstring =', this.rcvarservice.rc_sv);
    //this.plog.log('loop data svstring xx', mainpage.rcserverlistreg);

    for (const k in this.rcvarservice.rc_sv) {
      var v = this.rcvarservice.rc_sv[k];
      //this.plog.log('loop data sv', k, v);
      /* if (mainpage.testermode == false) {
         if (v.ishidden == 'YES') continue;
       }*/
      var newel = {} as serversS;
      newel.id = 0;
      newel.code = '' + v.code;
      newel.suggested = 'NO';
      if (v.country.indexOf(',' + this.rcvarservice.mycc + ',') != -1)
        newel.suggested = 'YES';
      /*if (v.ishidden == 'YES') {
        newel.name = '' + v.name + ' (hidden)';
      } else {
        newel.name = '' + v.name;
      }*/
      newel.name = '' + v.name;
      newel.country = '' + v.country;
      newel.ishidden = '' + v.ishidden;
      newel.isdefault = '' + v.isdefault;

      newel.url = '' + v.url;
      newel.descr = '' + v.descr;
      newel.wsurl = '' + v.wsurl;
      newel.versionstring = '' + v.versionstring;
      mainpage.rcserverlist[v.code] = newel;
      if (v.allowreg == 'YES')
        mainpage.rcserverlistreg[v.code] = newel;
      mainpage.rcserverlistforgotpassword[v.code] = newel;
    }
    //this.plog.log('loop data svstring sort',mainpage.rcserverlistreg.sort(this.sortsvbysuggestion));
    mainpage.rcserverlistreg.sort(this.sortsvbysuggestion)
    mainpage.rcserverlistforgotpassword.sort(this.sortsvbysuggestion)
    //this.plog.log('loop data svstring xxee', mainpage.rcserverlistreg);
    //this.plog.log('loop data svstring xxeef', mainpage.rcserverlistforgotpassword);
  }
  sortsvbysuggestion(a, b) {
    if (a.suggested > b.suggested) return -1;
    if (a.suggested < b.suggested) return 1;
    return 0;
  }

  async preloadstdstat() {
    var sv;
    sv = await this.rchttp.getcommon2('stdstat');
    this.rcvarservice.stdstat = sv;
    //this.plog.log("preloadstdstat",sv,this.rcvarservice.stdstat);
  }
  async initallvars(mainpage, force = false) {
    RCVarsService.rcserver = mainpage.rcserverlist;
    var sv;
    sv = await this.rchttp.getcommon('race');
    //this.plog.log("pass load cmd", sv);
    mainpage.racelist = [];
    for (const k in sv.data) {
      var v = sv.data[k];
      //this.plog.log("loop data", v);
      var newel = {} as raceS;
      newel.id = v.id;
      newel.name = '' + v.name;
      newel.descr = '' + v.descr;
      mainpage.racelist.push(newel);
      //this.rcserverlist.push({id: v.id, code: "a", name: "b"});
    }
    //this.plog.error("initallvar",this);
    this.rcvarservice.gameobj.registry.list.rcloading.loading2setdescr("");
    RCVarsService.racelist = mainpage.racelist;
    this.racelist = mainpage.racelist;
    //reslist
    if (force == true || this.rcvarservice.reslist == undefined || this.rcvarservice.reslist.length < 1) {
      var sv;
      sv = await this.rchttp.getlocal('../assets/provider/reslist.txt');
      var sva = sv.split('\n');
      var sva2 = [];
      for (const svk in sva) {
        sva2.push(sva[svk].replace('provider/', ''));
      }
      //this.plog.log("reslist",sva2);
      this.rcvarservice.reslist = sva2;
    }
    //gamemunu
    this.rcvarservice.gameobj.registry.list.rcloading.loading2setdescr("getting interface menu");
    if (force == true || this.rcvarservice.gamemenu == undefined || this.rcvarservice.gamemenu.length < 1) {
      var sv;
      sv = await this.rchttp.getcommon('gamemenu');
      this.rcvarservice.gamemenu = sv.data;
    }
    //stackitemtype
    this.rcvarservice.gameobj.registry.list.rcloading.loading2setdescr("getting stackitem info");
    if (force == true || this.rcvarservice.stackitemtype == undefined || this.rcvarservice.stackitemtype.length < 1) {
      var sv;
      sv = await this.rchttp.getcommon('stackitemtype');
      this.rcvarservice.stackitemtype = sv.data;
    }
    //npcshops    
    this.rcvarservice.gameobj.registry.list.rcloading.loading2setdescr("setting up npc shop");
    if (force == true || this.rcvarservice.allnpcshop == undefined || this.rcvarservice.allnpcshop.length < 1) {
      var sv;
      sv = await this.rchttp.getcommon('allnpcshop');
      this.rcvarservice.allnpcshop = sv.data;
    }
    //stackitem
    this.rcvarservice.gameobj.registry.list.rcloading.loading2setdescr("getting stackitem handbook");
    if (force == true || this.rcvarservice.allstackitem == undefined || this.rcvarservice.allstackitem.length < 1) {
      var sv;
      sv = await this.rchttp.getcommon('allstackitem');
      this.rcvarservice.allstackitem = sv.data;
    }
    //item    
    this.rcvarservice.gameobj.registry.list.rcloading.loading2setdescr("getting items info");
    if (force == true || this.rcvarservice.allitem == undefined || this.rcvarservice.allitem.length < 1) {
      var sv;
      sv = await this.rchttp.getcommon('allitem');
      this.rcvarservice.allitem = sv.data;
    }
    //equippos        
    this.rcvarservice.gameobj.registry.list.rcloading.loading2setdescr("getting equipment manual");
    if (force == true || this.rcvarservice.equippos == undefined || this.rcvarservice.equippos.length < 1) {
      var sv;
      sv = await this.rchttp.getcommon('equippos');
      this.rcvarservice.equippos = sv.data;
    }
    //level    
    this.rcvarservice.gameobj.registry.list.rcloading.loading2setdescr("getting world level rules");
    if (force == true || this.rcvarservice.level == undefined || this.rcvarservice.level.length < 1) {
      var sv;
      sv = await this.rchttp.getcommon('level');
      this.rcvarservice.level = sv.data;
    }

    this.rcvarservice.gameobj.registry.list.rcloading.loading2setdescr("getting quests directory");
    if (force == true || this.rcvarservice.allquest == undefined || this.rcvarservice.allquest.length < 1) {
      var sv;
      sv = await this.rchttp.getcommon('allquest');
      this.rcvarservice.allquest = sv.data;
    }

    this.rcvarservice.gameobj.registry.list.rcloading.loading2setdescr("studying race skills");
    if (force == true || this.rcvarservice.skilltp == undefined || this.rcvarservice.skilltp.length < 1) {
      var sv;
      sv = await this.rchttp.getcommon('skilltp');
      this.rcvarservice.skilltp = sv.data;
    }

    this.rcvarservice.gameobj.registry.list.rcloading.loading2setdescr("studying weapon skills");
    if (force == true || this.rcvarservice.skillweapontp == undefined || this.rcvarservice.skillweapontp.length < 1) {
      var sv;
      sv = await this.rchttp.getcommon('skillweapontp');
      this.rcvarservice.skillweapontp = sv.data;
    }
    this.rcvarservice.gameobj.registry.list.rcloading.loading2setdescr("adding world movements");
    if (force == true || this.rcvarservice.allanims == undefined || this.rcvarservice.allanims.length < 1) {
      var sv;
      sv = await this.rchttp.getcommon('allanims');
      this.rcvarservice.allanims = sv.data;
    }

    this.rcvarservice.gameobj.registry.list.rcloading.loading2setdescr("plotting maps");
    if (force == true || this.rcvarservice.allmap == undefined || this.rcvarservice.allmap.length < 1) {
      var sv;
      sv = await this.rchttp.getcommon('allmap');
      this.rcvarservice.allmap = sv.data;
    }
    //

    this.rcvarservice.gameobj.registry.list.rcloading.loading2setdescr("animating pixels");
    if (force == true || this.rcvarservice.anims == undefined || this.rcvarservice.anims.length < 1) {
      var sv;
      sv = await this.rchttp.getcommon('anims');
      //sv.data.forEach(v => {
      //this.rcvarservice.anims[v.code]=v;
      //});
      this.rcvarservice.anims = sv.data;
    }
    //

    this.rcvarservice.gameobj.registry.list.rcloading.loading2setdescr("getting energy info");
    if (force == true || this.rcvarservice.allrace == undefined || this.rcvarservice.allrace.length < 1) {
      var sv;
      sv = await this.rchttp.getcommon('race');
      this.rcvarservice.allrace = sv.data;
    }

    this.rcvarservice.gameobj.registry.list.rcloading.loading2setdescr("getting character status");
    if (force == true || this.rcvarservice.charstatus_tp == undefined || this.rcvarservice.charstatus_tp.length < 1) {
      var sv;
      sv = await this.rchttp.getcommon('charstatus_tp');
      this.rcvarservice.charstatus_tp = sv.data;
    }

    this.rcvarservice.gameobj.registry.list.rcloading.loading2setdescr("preparing mobs");
    if (force == true || this.rcvarservice.allmob == undefined || this.rcvarservice.allmob.length < 1) {
      var sv;
      sv = await this.rchttp.getcommon('mob');
      this.rcvarservice.allmob = sv.data;
    }

    this.rcvarservice.gameobj.registry.list.rcloading.loading2setdescr("getting to know npcs");
    if (force == true || this.rcvarservice.npc == undefined || this.rcvarservice.npc.length < 1) {
      var sv;
      sv = await this.rchttp.getcommon('npc');
      this.rcvarservice.npc = sv.data;
      //this.racelist=mainpage.racelist;
    }

    this.rcvarservice.gameobj.registry.list.rcloading.loading2setdescr(" ");
    mainpage.loggedin = await this.rcstorage.get('loggedin');
  }
  getrace(id) {
    id = Number(id);
    //this.plog.log("getrace", id, this.racelist);
    var foundname: string;
    foundname = '?';
    this.racelist.forEach((element) => {
      //this.plog.log(element.id,id);
      if (Number(element.id) == id) {
        //this.plog.log("found",element.name);
        foundname = element.name;
      }
    });
    return foundname;
  }
}
