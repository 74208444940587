// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    /*apiKey: "AIzaSyB8B4A6X4-0pFZZorlyErnB2C6obJGr2g0",
    authDomain: "localhost",
    databaseURL: "",
    projectId: "peacely-online-34267439",
    storageBucket: "myapp-project-123.appspot.com",
    messagingSenderId: "884581827531",
    appId: "",
    measurementId: ""*/
    apiKey: "AIzaSyB8B4A6X4-0pFZZorlyErnB2C6obJGr2g0",
    authDomain: "peacely-online-34267439.firebaseapp.com",
    databaseURL: "https://peacely-online-34267439-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "peacely-online-34267439",
    storageBucket: "peacely-online-34267439.appspot.com",
    messagingSenderId: "884581827531",
    appId: "1:884581827531:web:167768ec4b61049393ac4e",
    measurementId: "G-RG4PWLWRSB"//"G-6439EMZRBH"//G-G3HK1M2VPW"
  }
};

/* 
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
