import { Injectable } from '@angular/core';
import { RCVarsService } from './rcvars-service.service';
import { RCHTTP } from './rchttp.service';
import { RCToastService } from './rctoast.service';
import { plog } from './plog.service';

@Injectable({
  providedIn: 'root'
})
export class DiamondOfferService {
  flare;
  wh;
  bg;
  bgtile;
  icongold;
  icondiamond;
  text1;
  text2
  btnconfirm;
  btncancel;
  activeoffer = "";
  cost;
  cost_diamond;
  cost_gold;
  plog;

  constructor(
    public rcvarsservice: RCVarsService,
    public rchttp: RCHTTP,
    public rctoast: RCToastService
  ) { 
    this.plog=new plog();
  }

  async ungradeinvensize(wh) {
    var kw = "ungradeinvensize";
    this.activeoffer = kw;
    this.wh = wh;
    this.cost = await this.rchttp.getcommon2("upgradeinvenprice");
    await this.rchttp.updatecharinfo();
    this.cost_diamond = this.cost[this.rcvarsservice.activechar["invenlv"] ]["diamond"];
    this.cost_gold = this.cost[this.rcvarsservice.activechar["invenlv"] ]["gold"];

    let loader = new Phaser.Loader.LoaderPlugin(wh);
    loader.image(kw, this.rcvarsservice.rc_baseurl + "guidiamond/" + kw + ".png");

    loader.once(Phaser.Loader.Events.COMPLETE, () => {
      this.plog.log("Phaser.Loader.Events.COMPLETE");
      this.plog.log(this);
      this.show_loaded();
    }, this);
    loader.start();

  }

  async ungradestackinvensize(wh) {
    var kw = "ungradestackinvensize";
    this.activeoffer = kw;
    this.wh = wh;
    this.cost = await this.rchttp.getcommon2("upgradestackinvenprice");
    await this.rchttp.updatecharinfo();
    this.cost_diamond = this.cost[this.rcvarsservice.activechar["stackinvenlv"] ]["diamond"];
    this.cost_gold = this.cost[this.rcvarsservice.activechar["stackinvenlv"] ]["gold"];


    let loader = new Phaser.Loader.LoaderPlugin(wh);
    loader.image(kw, this.rcvarsservice.rc_baseurl + "guidiamond/" + kw + ".png");

    loader.once(Phaser.Loader.Events.COMPLETE, () => {
      this.plog.log("Phaser.Loader.Events.COMPLETE");
      this.plog.log(this);
      this.show_loaded();
    }, this);
    loader.start();
  }
  async show_loaded() {
    this.wh.kbfocuslv1="diamondoffer";
    if (this.bg != undefined) {
      this.bg.destroy();
      this.bg = undefined;
    }
    if (this.bgtile != undefined) {
      this.bgtile.destroy();
      this.bgtile = undefined;
    }
    if (this.icongold != undefined) {
      this.icongold.destroy();
      this.icongold = undefined;
    }
    if (this.icondiamond != undefined) {
      this.icondiamond.destroy();
      this.icondiamond = undefined;
    }
    if (this.text1 != undefined) {
      this.text1.destroy();
      this.text1 = undefined;
    }
    if (this.text2 != undefined) {
      this.text2.destroy();
      this.text2 = undefined;
    }
    if (this.btnconfirm != undefined) {
      this.btnconfirm.destroy();
      this.btnconfirm = undefined;
    }
    if (this.btncancel != undefined) {
      this.btncancel.destroy();
      this.btncancel = undefined;
    }
    if (this.flare != undefined) {
      this.flare.destroy();
      this.flare = undefined;
    }
    var offer = this.activeoffer;
    var width = this.wh.game.canvas.width;
    var height = this.wh.game.canvas.height;
    this.plog.log("DiamondOfferService show_loaded", offer, this);
    var cost = this.cost;
    this.plog.log("show_loaded", offer, cost);
    this.bgtile = this.wh.add.rectangle(0, 0, width, height, 0xffffff);
    this.bgtile.setPosition(0, 0);
    this.bgtile.setOrigin(0, 0);
    this.bgtile.setAlpha(0.7);
    this.bgtile.displayWidth = width;
    this.bgtile.displayHeight = height;
    this.bgtile.setInteractive();
    this.bgtile.removeAllListeners();
    this.bgtile.setDepth(200004);
    this.bg = this.wh.add.image(0, 0, offer);
    this.bg.setPosition(width / 2, height / 2);
    var bgw = Math.min(width, height);
    if (bgw > 800) bgw = 800;
    this.bg.displayWidth = this.bg.displayHeight = bgw;
    this.bg.setDepth(200005);

    this.flare = this.wh.add.image(300, 300, 'flare0');
    this.flare.setOrigin(0.5, 0.5);
    this.flare.setDepth(200001);
    this.flare.setPosition(width / 2, height / 2);
    this.flare.displayWidth = bgw;
    this.flare.scaleY = this.flare.scaleX;

    //this.tmpbg2.setBlendMode(Phaser.BlendModes.COLOR_BURN);
    var tw2 = this.wh.tweens.add({
      targets: this.flare,
      angle: 359,
      ease: 'Linear',
      duration: 5000, // duration of animation; higher=slower
      delay: 0,
      repeat: -1

    });
    if (this.cost_diamond == undefined || this.cost_gold == undefined) {
      await this.rctoast.rcalert(this.wh, "Unable to upgrade", " You probably got the highest level of inventory");
      return;
    }
    this.text1 = this.wh.add.text(0, 0, "" + this.cost_diamond, { fontFamily: 'ffff', fontSize: '36px', color: '#000' });
    this.text1.setDepth(200010);
    this.text2 = this.wh.add.text(0, 0, "" + this.cost_gold, { fontFamily: 'ffff', fontSize: '36px', color: '#000' });
    this.text2.setDepth(200010);

    var iconposy = bgw * 0.05;
    if (iconposy < 30) iconposy = 30;
    this.icondiamond = this.wh.add.sprite((width / 2), (height / 2) - iconposy, "stackitem_tp", "diamond");
    this.icondiamond.setDepth(200010);
    this.icongold = this.wh.add.sprite((width / 2), (height / 2) + iconposy, "stackitem_tp", "gold");
    this.icongold.setDepth(200010);
    this.icondiamond.displayWidth = this.icondiamond.displayHeight = 50;
    this.icongold.displayWidth = this.icongold.displayHeight = 50;

    this.text1.setPosition(this.icondiamond.x + 50, this.icondiamond.y - 25);
    this.text2.setPosition(this.icongold.x + 50, this.icongold.y - 25);

    var iconw = bgw / 2.5;
    if (iconw > 200) iconw = 200;
    this.btnconfirm = this.wh.add.sprite(0, 0, "thesb", "confirm");
    this.btnconfirm.setOrigin(1, 0.5);
    this.btnconfirm.setPosition((width / 2) - 10, (height / 2) + (bgw / 2) - this.btnconfirm.displayHeight);
    this.btnconfirm.setDepth(200010);

    this.btnconfirm.displayWidth = iconw;
    this.btnconfirm.scaleY = this.btnconfirm.scaleX;
    this.btncancel = this.wh.add.sprite((width / 2), (height / 2) + (bgw / 2), "thesb", "cancel");
    this.btncancel.setOrigin(0, 0.5);
    this.btncancel.setPosition((width / 2) + 10, (height / 2) + (bgw / 2) - this.btncancel.displayHeight);
    this.btncancel.setDepth(200010);
    this.btncancel.displayWidth = iconw;
    this.btncancel.scaleY = this.btncancel.scaleX;
    this.btncancel.setInteractive();
    this.btncancel.removeAllListeners();
    this.btncancel.on("pointerdown", function () {
      this.show_close();
    }, this);
    this.btnconfirm.setInteractive();
    this.btnconfirm.removeAllListeners();
    this.btnconfirm.on("pointerdown", async function () {
      var cfm = await this.wh.registry.list.rctoast.rcconfirm(this.wh, "Confirm Upgrade", "Get 10 more inventory spot using \n" + this.cost_diamond + " diamond and " + this.cost_gold + " gold");
      this.plog.log("cfm", cfm);
      await this.wh.registry.list.rctoast.sleep(200);

      if (cfm == "ok") {
        if (this.activeoffer == "ungradeinvensize") {
          await this.rchttp.getcommon2("ungradeinvensize");
        }
        if (this.activeoffer == "ungradestackinvensize") {
          await this.rchttp.getcommon2("ungradestackinvensize");
        }
        this.rcvarsservice.gameobj.registry.list.showreward.effwithtxt(this.rcvarsservice.gameobj.scene.scenes[0], "ixbeff313_b", "Inventory Upgraded!")
        await this.rchttp.updatecharinfo();
        this.show_close();
      } else {
        this.wh.kbfocuslv1 = "diamondoffer";
      }
    }, this);
  }
  show_close() {
    this.wh.kbfocuslv1 = "";
    var tw = this.wh.tweens.add({
      targets: [this.bg, this.bgtile, this.icongold, this.icondiamond, this.text1, this.text2, this.btnconfirm, this.btncancel, this.flare],
      alpha: 0,
      ease: 'Power3',
      duration: 400, // duration of animation; higher=slower
      delay: 0, // wait 500 ms before starting
      onComplete: function () {
        //wh.maplayerfader.setAlpha(1);
        //this.plog.log("closegui b", arguments);
        //tg.destroy();
        for (const k in arguments) {
          //tg.list[k].destroy();
          //tg.list[k]=undefined;
        }

        if (this.bg != undefined) {
          this.bg.destroy();
          this.bg = undefined;
        }
        if (this.bgtile != undefined) {
          this.bgtile.destroy();
          this.bgtile = undefined;
        }
        if (this.icongold != undefined) {
          this.icongold.destroy();
          this.icongold = undefined;
        }
        if (this.icondiamond != undefined) {
          this.icondiamond.destroy();
          this.icondiamond = undefined;
        }
        if (this.text1 != undefined) {
          this.text1.destroy();
          this.text1 = undefined;
        }
        if (this.text2 != undefined) {
          this.text2.destroy();
          this.text2 = undefined;
        }
        if (this.btnconfirm != undefined) {
          this.btnconfirm.destroy();
          this.btnconfirm = undefined;
        }
        if (this.btncancel != undefined) {
          this.btncancel.destroy();
          this.btncancel = undefined;
        }
        if (this.flare != undefined) {
          this.flare.destroy();
          this.flare = undefined;
        }
        //this.plog.log("closegui a", );
      }.bind(this), // set context? how?
    });
  }
}
