import { Injectable } from '@angular/core';
import { RCVarsService } from './rcvars-service.service';
import { GameInterfaceService } from './game-interface.service';
import { RCHTTP } from './rchttp.service';
import { plog } from './plog.service';

@Injectable({
  providedIn: 'root'
})
export class PickItemItemService {
  shopdata;
  selecteditemno = "";
  selecteditemcode = "";
  currentresppage = 1;
  respperpage = 15;
  resptotalpage;
  selectcircle;
  itemdescr;
  shopitemlist = [];
  shopitemlistr = [];
  shopitemlistp = [];
  shopfwdbtn;
  shopbackbtn;
  selectbtn;
  invenmode = "";
  shopclosebtn;
  bgcover;
  shopguibg;
  wh;
  cbfuncparam;
  loading;
  plog;

  constructor(
    public rcvarsservice: RCVarsService,
    public rchttp: RCHTTP,
    public gameitf: GameInterfaceService
  ) { 
    this.plog=new plog();
  }

  loadingshow() {
    this.wh.pickitemitemcontainer.bringToTop(this.loading);
    this.loading.visible = true;
  }
  loadinghide() {
    this.loading.visible = false;
  }
  async show(wh, cbfunc, invenmode, param2 = "", itemdescrtxt = "Select Item") {
    this.invenmode = invenmode;
    this.wh = wh;
    
    if (this.wh.registry.list.gameitf.clicktoosoon()) return; this.wh.registry.list.gameitf.markclick();

    if (this.wh.pickitemcontainer!=undefined) {
      await this.show_close(this.wh);
    }
    this.cbfuncparam = cbfunc;
    await this.wh.registry.list.rchttp.updatecharinfo();
    itemdescrtxt=this.wh.gl(itemdescrtxt);
    
    var width = wh.game.canvas.width;
    var height = wh.game.canvas.height;
    var shopdata = await wh.registry.list.rchttp.getcommon2("pickitemitemres", { invenmode: invenmode, param2: param2 });
    this.shopdata = shopdata;
    this.plog.log("show", wh, shopdata);

    const selectcircle = wh.add.sprite(0, 0, "selectcircle").play("selected_b");
    //const selectcircle = wh.add.image(0, 0, "selectcircle");
    selectcircle.setOrigin(0.5, 0.5);
    selectcircle.visible = false;
    selectcircle.setAlpha(0.7);
    const shopguibg = wh.add.image(0, 0, "pickitemguibg");
    shopguibg.setOrigin(0.5, 0.5);
    shopguibg.setInteractive({ pixelPerfect: true });
    shopguibg.removeAllListeners();
    this.shopguibg = shopguibg;
    const shopbackbtn = wh.add.sprite(0, 0, "thesb", "backsign");//wh.add.image(0, 0, "listbackbtn");
    shopbackbtn.setOrigin(0.5, 0.5);
    shopbackbtn.setInteractive();
    shopbackbtn.removeAllListeners();
    const shopfwdbtn = wh.add.sprite(0, 0, "thesb", "forwardsign");//wh.add.image(0, 0, "listfwdbtn");
    shopfwdbtn.setOrigin(0.5, 0.5);
    shopfwdbtn.setInteractive();
    shopfwdbtn.removeAllListeners();
    const selectbtn = wh.add.sprite(0, 0, "thesb", "select");//wh.add.image(0, 0, "selectbtn");
    selectbtn.setOrigin(0.5, 0.5);
    selectbtn.setInteractive();
    selectbtn.removeAllListeners();
    this.plog.log("show shopdata", shopdata);

    //this.plog.log("show var", this.rcvarsservice);
    this.shopfwdbtn = shopfwdbtn;
    this.shopbackbtn = shopbackbtn;
    this.selectcircle = selectcircle;
    this.selectbtn = selectbtn;

    var shopclosebtn = wh.add.sprite(300, 300, "thesq",'close');
    this.shopclosebtn = shopclosebtn;
    this.shopclosebtn.setOrigin(0.5, 0.5);
    this.shopclosebtn.setPosition(150, -145);
    shopclosebtn.displayWidth = 40;
    shopclosebtn.displayHeight = 40;
    this.shopclosebtn.setInteractive();
    this.shopclosebtn.removeAllListeners();
    this.shopclosebtn.on('pointerup', function (pointer, localX, localY, event) {
      //this.scene.registry.list["thethis"].phm.floorclick(this);
      this.plog.log("gc.npcbackbtn pointerup", pointer, localX, localY, event, this);
      this.show_close(wh);
    }, this);

    wh.pickitemitemcontainer = wh.add.container(300, 300);
    wh.pickitemitemcontainer._refsrv=this;
    wh.pickitemitemcontainer.add(this.shopclosebtn);
    wh.pickitemitemcontainer.add(selectcircle);
    wh.pickitemitemcontainer.add(shopguibg);
    wh.pickitemitemcontainer.add(shopbackbtn);
    wh.pickitemitemcontainer.add(shopfwdbtn);
    wh.pickitemitemcontainer.add(selectbtn);

    this.loading = wh.add.sprite(300, 300, 'pixelloading').play('pixelloading_b');
    this.loading.setOrigin(0.5, 0.5);
    this.loading.setPosition(0, 0);
    this.loading.displayWidth = this.loading.displayHeight = 60;
    wh.pickitemitemcontainer.add(this.loading);
    wh.pickitemitemcontainer.bringToTop(this.loading);

    this.bgcover = wh.add.rectangle(0, 0, width, height, 0xffffff);
    wh.pickitemitemcontainer.add(this.bgcover);
    wh.pickitemitemcontainer.sendToBack(this.bgcover);
    this.bgcover.setAlpha(0.7);
    this.bgcover.setOrigin(0.5, 0.5);
    this.bgcover.setPosition(0, 0);
    this.bgcover.setAlpha(0.5);
    this.bgcover.visible = true;
    this.bgcover.setInteractive();
    this.bgcover.removeAllListeners();


    shopbackbtn.displayWidth = 100;
    shopfwdbtn.displayWidth = 100;
    selectbtn.displayWidth = 100;
    shopbackbtn.displayHeight = 40;
    shopfwdbtn.displayHeight = 40;
    selectbtn.displayHeight = 40;
    shopbackbtn.setPosition(-110, 150);
    shopfwdbtn.setPosition(110, 150);
    selectbtn.setPosition(0, 150);

    wh.pickitemitemcontainer.bringToTop(this.shopclosebtn);
    var itemdescr = wh.add.text(0, 0, "" + itemdescrtxt, wh.fs["winguibardescr"]);
    wh.pickitemitemcontainer.add(itemdescr);
    itemdescr.setOrigin(0, 0);
    itemdescr.setPosition(-140, -115);
    this.itemdescr = itemdescr;

    //this.plog.log("pickitemitemcontainer", wh.pickitemitemcontainer, width, height);
    wh.pickitemitemcontainer.setPosition((width / 2), (height / 2));
    //move
    //wh.pickitemitemcontainer.x = width - (this.shopguibg.width / 2);

    /////////////////////////////////////////////////////////////////////////
    let loader = new Phaser.Loader.LoaderPlugin(this.wh);
    var svversionurlstring = this.wh.registry.list.rcvarpass.svversionstring;
    if (svversionurlstring != "") {
      svversionurlstring = "?" + svversionurlstring;
    }
    var loadlist = ["item_tpg","item_tp"];
    for (var k in loadlist) {
      var key = loadlist[k];
      loader.atlas(key + "", this.wh.registry.list.rcvarpass.rc_baseurl + "sprite/" + key + ".png" + svversionurlstring, this.wh.registry.list.rcvarpass.rc_baseurl + "sprite/" + key + ".json" + svversionurlstring);
    }
    wh.camman(wh);
    loader.once(Phaser.Loader.Events.COMPLETE, () => {
      this.plog.log("Phaser.Loader.Events.COMPLETE");
      this.plog.log(this);
      this.show_loaded();
    });
    wh.pickitemitemcontainer.setDepth(405000);
    loader.start();
  }
  show_loaded() {
    var wh = this.wh;
    var shopdata = this.shopdata;
    this.selectbtn.visible = true;

    this.plog.log("show_loaded", wh, shopdata);
    //this.rcvarsservice.gameobj.registry.list.gameitf.guiwindowclick(this.rcvarsservice.gameobj.scene.scenes[0].pickitemitemcontainer);

    var margintop = -32;
    var marginleft = -117;
    var cxpos = 0;
    var crow = 0;
    var i = 0;
    for (var i = 0; i < 100; i++) {
      if (this.shopitemlist[i] != undefined) {
        this.plog.log("shopitemlist", this.shopitemlist[i]);
        this.shopitemlist[i].visible = false;
      }
      if (this.shopitemlistp[i] != undefined) {
        this.shopitemlistp[i].visible = false;
      }
      if (this.shopitemlistr[i] != undefined) {
        this.shopitemlistr[i].visible = false;
      }
    }

    for (var i = 0; i < 100; i++) {
      if (this.shopitemlist[i] != undefined)
        this.shopitemlist[i].destroy();// = undefined;
      if (this.shopitemlistp[i] != undefined)
        this.shopitemlistp[i].destroy();// = undefined;
      if (this.shopitemlistr[i] != undefined)
        this.shopitemlistr[i].destroy();// = undefined;
    }

    this.currentresppage = 1;

    var i = 0;
    for (var vv in shopdata) {
      var vdat = shopdata[vv];
      var itemdat = this.rcvarsservice.allstackitem[vdat.item];
      //this.plog.log("loaded pickitem ", vv, vdat, itemdat);
      if (vdat["enable"] == "YES") {
        this.shopitemlist[i] = wh.add.sprite(32, 32, "item_tp", vdat["item"]);
      } else {
        this.shopitemlist[i] = wh.add.sprite(32, 32, "item_tpg", vdat["item"]);
      }
      this.shopitemlistr[i] = wh.add.sprite(16, 16, "spacer");

      if (vdat["rarity"] == "rar") {
        this.shopitemlistr[i].setTexture("effrar").play("effrar_b");
      }
      if (vdat["rarity"] == "leg") {
        this.shopitemlistr[i].setTexture("effleg").play("effleg_b");
      }
      if (vdat["rarity"] == "ult") {
        this.shopitemlistr[i].setTexture("effleg").play("effleg_b");
        this.shopitemlistr[i].setTint(0xff0000);
      }
      if (vdat["rarity"] == "rel") {
        this.shopitemlistr[i].setTexture("effleg").play("effleg_b");
        this.shopitemlistr[i].setTint(0x0000ff);
      }
      this.shopitemlistr[i].displayHeight = this.shopitemlistr[i].displayWidth = 32;

      //var tmp = wh.add.image(32, 32, itemdat["code"]);
      this.shopitemlist[i].displayWidth = 48;
      this.shopitemlist[i].displayHeight = 48;
      wh.pickitemitemcontainer.add(this.shopitemlist[i]);
      this.shopitemlist[i].setPosition(marginleft + (cxpos * 59), margintop + (crow * 59));
      this.shopitemlistr[i].setPosition(this.shopitemlist[i].x - 16, this.shopitemlist[i].y -16);

      this.shopitemlistp[i] = wh.add.text(0, 0, "+" + vdat["itemplus"], wh.fs["wingui_plus"]);
      wh.pickitemitemcontainer.add(this.shopitemlistp[i]);
      wh.pickitemitemcontainer.add(this.shopitemlistr[i]);
      this.shopitemlistp[i].setPosition(this.shopitemlist[i].x - 27, this.shopitemlist[i].y + 11);
       
       this.shopitemlist[i].visible = true;
      this.shopitemlistp[i].visible = true;
      this.shopitemlistr[i].visible = true;
      this.shopitemlist[i].setInteractive();
      this.shopitemlist[i].removeAllListeners();
      this.shopitemlist[i].name = "" + vdat["item"] + "-" + vdat["id"];//i;
      this.shopitemlist[i].on('pointerup', function (pointer, localX, localY, event) {
        this.scene.plog.log("shop itemclick this", this);
        this.scene.plog.log("shop itemclick", pointer, localX, localY, event);
        this.scene.registry.list.pickitemitem.show_itemclick(this.scene, this);
      });
      cxpos++;
      if (cxpos > 4) {
        cxpos = 0;
        crow++;
      }
      i++;
      if (i % 15 == 0) {
        cxpos = 0;
        crow = 0;
      }
    }
    this.plog.log("shop i count", i);
    if (i > 15) { //pages

      this.respperpage = 15;
      this.resptotalpage = Math.ceil(i / 15);
    }
    this.shopbackbtn.removeAllListeners();
    this.shopfwdbtn.removeAllListeners();
    this.shopbackbtn.on('pointerup', function (pointer, localX, localY, event) {
      this.plog.log("shop shopbackbtn", this);
      this.show_showpage(wh, "back");
    }, this);

    this.shopfwdbtn.on('pointerup', function (pointer, localX, localY, event) {
      this.plog.log("shop shopfwdbtn", this);
      this.show_showpage(wh, "next");
    }, this);

    this.show_showpage(wh, "current");

    this.selectbtn.removeAllListeners();
    this.selectbtn.on('pointerup', function (pointer, localX, localY, event) {
      this.plog.log("shop selectbtn", this);
      this.show_drop(this.wh);
    }, this);

  }


  async show_drop(wh) {
    this.plog.log("show_drop", wh, this.selecteditemno,this.selecteditemcode);
    if (this.selecteditemcode == "") return;

    this.cbfuncparam(this.selecteditemno,this.selecteditemcode);
    this.show_close(wh);
  }


  show_itemclick(wh, item) {
    this.plog.log("show_itemclick", wh, item);
    this.selectcircle.visible = true;
    var namea = item.name.split("-");
    this.plog.log("show_itemclick shopitemlist", namea, this.shopitemlist);
    wh.registry.list.pickitemitem.selecteditemno = namea[1];
    wh.registry.list.pickitemitem.selecteditemcode = namea[0];
    
    this.selectcircle.displayWidth = 52;
    this.selectcircle.displayHeight = 52;
    this.selectcircle.setPosition(item.x, item.y)
    //wh.pickitemitemcontainer.sendToBack(wh.shopguibg);
    wh.pickitemitemcontainer.bringToTop(this.selectcircle);
    wh.pickitemitemcontainer.bringToTop(this.shopitemlist[namea[1]]);
    wh.pickitemitemcontainer.bringToTop(this.shopitemlistp[namea[1]]);
    wh.pickitemitemcontainer.bringToTop(this.shopitemlistr[namea[1]]);
    this.plog.log("show_itemclick selectcircle", namea, item, wh.registry.list.rcvarpass.activechar);
    var iteminfo2 = wh.registry.list.rcvarpass.allitem[namea[0]];
    this.plog.log("show_itemclick selectcircle", namea, iteminfo2);
    var iteminfoown=this.shopdata[namea[1]];
    this.wh.registry.list.rcosd.show(wh, "itemnoeq", iteminfo2, iteminfoown);


    //this.selectcircle.width=
    //this.plog.log("xxxx", iteminfo2);



  }
  async show_close(wh) {
    //wh.pickitemitemcontainer.visible = false;
    //wh.registry.list.gameitf.closegui(wh,wh.pickitemitemcontainer);
    //wh.pickitemitemcontainer.destroy();//true
/*
    var tw1 = this.wh.tweens.add({
      targets: this.wh.pickitemitemcontainer,
      alpha: 0.0,
      ease: 'Power3',
      duration: 495, // duration of animation; higher=slower
      loop: 0,
      yoyo: false,
      onComplete: function () {
        wh.pickitemitemcontainer.destroy();
      }.bind(this)
    });
    */
   
    wh.pickitemitemcontainer.visible = false;
    await wh.registry.list.gameitf.closegui(wh, wh.pickitemitemcontainer);
    for (const k in this.wh.pickitemitemcontainer.list) {
      try {
        await this.wh.pickitemitemcontainer.remove(this.wh.pickitemitemcontainer.list[k], true);
        //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list[k].destroy();
      } catch (e) {
        this.plog.log("ERROR! pickitemitemcontainer.destroy() ", e);
      }
    }

    for (const k in this.wh.pickitemitemcontainer.list) {
      try {
        await this.wh.pickitemitemcontainer.remove(this.wh.pickitemitemcontainer.list[k], true);
        //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list[k].destroy();
      } catch (e) {
        this.plog.log("ERROR! pickitemitemcontainer.destroy() ", e);
      }
    }
    wh.pickitemitemcontainer = undefined;
  }
  show_showpage(gc, viewpage) {
    this.selectcircle.visible = false;
    if (viewpage == "back") {
      if (this.currentresppage > 1) this.currentresppage--;
    }
    if (viewpage == "next") {
      if (this.currentresppage < this.resptotalpage) this.currentresppage++;
    }
    for (var i = 0; i <= 100; i++) {
      //this.plog.log(this.shopitemlist[i]);
      if (this.shopitemlist[i] != undefined) {
        this.shopitemlist[i].visible = false;
      }
      if (this.shopitemlistp[i] != undefined) {
        this.shopitemlistp[i].visible = false;
      }
      if (this.shopitemlistr[i] != undefined) {
        this.shopitemlistr[i].visible = false;
      }
    }
    var numfrom = this.respperpage * (this.currentresppage - 1);
    var numto = this.respperpage * (this.currentresppage);
    this.plog.log("show_showpage", viewpage, this.currentresppage, "fromto", numfrom, numto, "perpage", this.respperpage);
    this.loadinghide();

    
    var griddat = [];
    var griddatf = [];

    griddat.push(this.shopclosebtn);

    griddat.push(this.shopfwdbtn);
    griddat.push(this.shopbackbtn);
    griddat.push(this.selectbtn);


    for (var i = numfrom; i < numto; i++) {
      if (this.shopitemlist[i] != undefined) {
        this.shopitemlist[i].visible = true;
        this.shopitemlistp[i].visible = true;
        this.shopitemlistr[i].visible = true;
        var namea = this.shopitemlist[i].name.split("-");
        var isnewdata = this.wh.registry.list.rcvarpass.activechar["stackitem"][namea[1]];
        //this.plog.log("xxx1", isnewdata);
        
        griddat.push(this.shopitemlist[i]);
        griddatf.push(this.shopitemlist[i]);
      }
    }
    this.wh.registry.list.gp.guigrid(griddat, griddatf);

  }

  rebuildguigrid() {
    this.plog.log("rebuildguigrid();b", this.wh.kbfocus);
    this.show_showpage(null, "");
    this.plog.log("rebuildguigrid();a", this.wh.kbfocus);
  }
}
