import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';


import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';

import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { Drivers, Storage } from '@ionic/storage';
import { IonicStorageModule } from '@ionic/storage-angular';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
//import { InAppPurchase2, IAPProduct } from '@awesome-cordova-plugins/in-app-purchase-2/ngx';
//import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { FirebaseApp } from '@angular/fire/app';

@NgModule({ declarations: [AppComponent],
    //entryComponents: [],
    bootstrap: [AppComponent], imports: [BrowserModule,
        IonicModule.forRoot(), //, 
        AppRoutingModule,
        IonicStorageModule.forRoot({
            name: '__mydb',
            driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage],
        }),
        FormsModule,
        ReactiveFormsModule,
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFireAuthModule,
        AngularFireDatabaseModule,
        AngularFirestoreModule], providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        //InAppPurchase2,
        //ScreenOrientation,
         provideHttpClient(withInterceptorsFromDi()),
      ] })
export class AppModule { }
