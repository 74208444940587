import { Injectable } from '@angular/core';
import { RCVarsService } from './rcvars-service.service';
import { plog } from './plog.service';

@Injectable({
  providedIn: 'root',
})
export class RCRequirementService {
  plog;
  wh;

  constructor(public rcvar: RCVarsService) {
    this.plog = new plog();
  }

  chk(req, restype = 'chk') {
    //console.log(this.rcvar );
    //console.log(this.rcvar.gameobj.scene.scenes[0]);
    this.wh = this.rcvar.gameobj.scene.scenes[0];
    if (req == undefined) {
      this.plog.warn("rcrequirement req is undefined");
      return false;
    }
    var cdat = this.rcvar.activechar;
    //this.plog.log('RCRequirementService chk()', req, cdat);
    //this.plog.log('RCRequirementService chk()', req);
    var ispass = true;
    var resmsg = '';
    for (let key of Object.keys(req)) {
      let val = req[key];
      //this.plog.log("RCRequirementService keyval", key, val);
      if (val == '' || val == undefined) continue;
      switch (key) {
        case 'xxx':
          break;

        case 'timeofday':
          this.plog.log("requirementchk timeofday ", req, val, this.rcvar.gameobj.registry.list.timectl);
          var isnegative = val.substring(0, 4);
          var isnegativev = val.substring(4);
          this.plog.log("requirementchk isnegative ", this.rcvar.gameobj.registry.list.timectl.timename, isnegative, isnegativev);
          var localresult = false;
          if (isnegative == "not-") {
            if (this.rcvar.gameobj.registry.list.timectl.timename != isnegativev) {
              localresult = true;
            }
            resmsg =
              resmsg + ' ' + this.wh.trs("f:rcrequirement:Time Of Day===Time Of Day") + ': not ' +
              isnegativev +
              '';
          } else {
            if (this.rcvar.gameobj.registry.list.timectl.timename == val) {
              localresult = true;
            }
            resmsg =
              resmsg +
              ' ' + this.wh.trs("f:rcrequirement:Time Of Day===Time Of Day") + ': ' +
              val +
              '';
          }
          ispass = ispass && localresult;
          break;

        case 'item1':
          //this.plog.log("requirementchk item1 ", req,val,cdat["stackitem"]);
          var localresult = false;
          for (let tmpk of Object.keys(cdat['item'])) {
            let tmpv = cdat['item'][tmpk];
            //this.plog.log("requirementchk stackitem ", tmpv);
            if (tmpv['item'] == val) {
              localresult = true;
            }
          }
          resmsg =
            resmsg +
            ' Item: ' +
            this.rcvar.allitem[req['item1']]['name'] +
            '';
          ispass = ispass && localresult;
          break;

        case 'custava':
          //this.plog.log("requirementchk item1 ", req,val,cdat["stackitem"]);
          var localresult = false;
          //this.plog.log("requirementchk stackitem ", tmpv);
          if (Number(val) == 1) {
            if (cdat['sprite'] != cdat['spriteorig']) {
              localresult = true;
            }
            resmsg = resmsg + ' Custom Avatar: Yes';
          } else {
            if (cdat['sprite'] == cdat['spriteorig']) {
              localresult = true;
            }
            resmsg = resmsg + ' Custom Avatar: No';
          }
          ispass = ispass && localresult;
          break;

        case 'npcclose':
          var localresult = false;
          if (req["npcclose_npc"] != undefined) {
            if (this.rcvar["activemap"] != undefined &&
              this.rcvar["activemap"]["map"] != undefined &&
              this.rcvar["activemap"]["map"]['npcclose'] != undefined &&
              this.rcvar["activemap"]["map"]['npcclose'][req["npcclose_npc"]] != undefined &&
              this.rcvar["activemap"]["map"]['npcclose'][req["npcclose_npc"]] >= Number(val)) {
              localresult = true;
            }
            if (this.rcvar.npc[req["npcclose_npc"]] == undefined) {
              resmsg = resmsg + ' ' + this.wh.trs("f:rcrequirement:Friendly to NPC===Friendly to NPC") + ': ' + val + "%";
            } else {
              resmsg = resmsg + ' ' + this.wh.trs("f:rcrequirement:Friendly to NPC===Friendly to NPC") + ' ' + this.rcvar.npc[req["npcclose_npc"]]['name'];// + ': ' + val + "%";
            }
            ispass = ispass && localresult;
          }
          break;
        case 'item2':
          //this.plog.log("requirementchk item2 ", req,val,cdat["stackitem"]);
          var localresult = false;
          for (let tmpk of Object.keys(cdat['item'])) {
            let tmpv = cdat['item'][tmpk];
            //this.plog.log("requirementchk stackitem ", tmpv);
            if (tmpv['item'] == val) {
              localresult = true;
            }
          }
          resmsg =
            resmsg +
            ' Item: ' +
            this.rcvar.allitem[req['item2']]['name'] +
            '';
          ispass = ispass && localresult;
          break;

        case 'item3':
          //this.plog.log("requirementchk item1 ", req,val,cdat["stackitem"]);
          var localresult = false;
          for (let tmpk of Object.keys(cdat['item'])) {
            let tmpv = cdat['item'][tmpk];
            //this.plog.log("requirementchk stackitem ", tmpv);
            if (tmpv['item'] == val) {
              localresult = true;
            }
          }
          resmsg =
            resmsg +
            ' Item: ' +
            this.rcvar.allitem[req['item3']]['name'] +
            '';
          ispass = ispass && localresult;
          break;
        case 'stackitem1':
          //this.plog.log("requirementchk stackitem1 ", req,val,cdat["stackitem"]);
          var localresult = false;
          var numneed = req['stackitem1_min'];
          var sumcurrent = 0;
          for (let tmpk of Object.keys(cdat['stackitem'])) {
            let tmpv = cdat['stackitem'][tmpk];
            //this.plog.log("requirementchk stackitem ", tmpv);
            if (tmpv['item'] == val) {
              sumcurrent = sumcurrent + tmpv['amnt'];
            }
          }
          if (sumcurrent >= numneed) {
            localresult = true;
          }
          //this.plog.log("requirementchk reached btm and set false");
          if (this.rcvar.allstackitem[req['stackitem1']] == undefined) break;
          resmsg =
            resmsg +
            ' StackItem: ' +
            this.rcvar.allstackitem[req['stackitem1']]['name'] + ' ' + sumcurrent + "/" + req['stackitem1_min'];
          ispass = ispass && localresult;
          break;

        case 'stackitem2':
          //this.plog.log("requirementchk stackitem1 ", req,val,cdat["stackitem"]);
          var localresult = false;
          var numneed = req['stackitem2_min'];
          var sumcurrent = 0;
          for (let tmpk of Object.keys(cdat['stackitem'])) {
            let tmpv = cdat['stackitem'][tmpk];
            //this.plog.log("requirementchk stackitem ", tmpv);
            if (tmpv['item'] == val) {
              sumcurrent = sumcurrent + tmpv['amnt'];
            }
          }
          if (sumcurrent >= numneed) {
            localresult = true;
          }
          //this.plog.log("requirementchk reached btm and set false");
          resmsg =
            resmsg +
            ' StackItem: ' +
            this.rcvar.allstackitem[req['stackitem2']]['name'] + ' ' + sumcurrent + "/" + req['stackitem2_min'];
          ispass = ispass && localresult;
          break;

        case 'stackitem3':
          //this.plog.log("requirementchk stackitem1 ", req,val,cdat["stackitem"]);
          var localresult = false;
          var numneed = req['stackitem3_min'];
          var sumcurrent = 0;
          for (let tmpk of Object.keys(cdat['stackitem'])) {
            let tmpv = cdat['stackitem'][tmpk];
            //this.plog.log("requirementchk stackitem ", tmpv);
            if (tmpv['item'] == val) {
              sumcurrent = sumcurrent + tmpv['amnt'];
            }
          }
          if (sumcurrent >= numneed) {
            localresult = true;
          }
          //this.plog.log("requirementchk reached btm and set false");
          resmsg =
            resmsg +
            ' StackItem: ' + this.rcvar.allstackitem[req['stackitem3']]['name'] + ' ' + sumcurrent + "/" + req['stackitem3_min'];
          ispass = ispass && localresult;
          break;

        case 'queststep':
          this.plog.log("requirementchk queststep ", req, val, cdat["quest"]);
          var localresult = false;
          var queststepid = req['queststep'];
          for (let tmpk of Object.keys(cdat['quest'])) {
            let tmpv = cdat['quest'][tmpk]["thestep"]["id"];
            //this.plog.log("requirementchk stackitem ", tmpv);
            if (tmpv == queststepid) {
              localresult = true;
            }
          }
          //this.plog.log("requirementchk reached btm and set false");
          //resmsg = resmsg + ' quest step: ' + queststepid;
          ispass = ispass && localresult;
          break;
        case 'weapon1':
          var localresult = false;
          this.plog.log('weapon1', cdat);
          for (const eqk in cdat['equip']) {
            var eqv = cdat['equip'][eqk];
            if (eqv['equippos'] == 'weapon1' && eqv['item'] == req['weapon1']) {
              localresult = true;
            }
          }
          resmsg =
            resmsg +
            ' ' + this.wh.trs("f:rcrequirement:Equip===Equip") + ' : ' +
            this.rcvar.allitem[req['weapon1']]['name'] +
            ' ';
          ispass = ispass && localresult;
          //ispass=false;
          break;

        case 'level_min':
          var localresult = false;
          if (cdat['level'] >= Number(val)) {
            localresult = true;
          }
          resmsg = resmsg + ' ' + this.wh.trs("f:rcrequirement:Min level===Min level") + ': ' + val;
          ispass = ispass && localresult;
          //this.plog.log("requirement level_min ", cdat["level"], val,localresult,ispass);
          break;
        case 'level_max':
          var localresult = false;
          if (cdat['level'] <= Number(val)) {
            localresult = true;
          }
          resmsg = resmsg + ' ' + this.wh.trs("f:rcrequirement:Max level===Max level") + ': ' + val;
          ispass = ispass && localresult;
          //this.plog.log("requirement level_min ", cdat["level"], val,localresult,ispass);
          break;
        case 'activemap':
          var localresult = false;
          if (this.rcvar["activemap"] != undefined &&
            this.rcvar["activemap"]["map"] != undefined &&
            this.rcvar["activemap"]["map"]['activemap'] != undefined) {
            if (Number(val) == 0) {
              resmsg = resmsg + " NOT in active map";
              if (this.rcvar["activemap"]["map"]['activemap'] != "YES") {
                localresult = true;
              }
            } else {
              resmsg = resmsg + " IN active map";
              if (this.rcvar["activemap"]["map"]['activemap'] == "YES") {
                localresult = true;
              }
            }
          }
          ispass = ispass && localresult;
          //this.plog.log("requirement level_min ", cdat["level"], val,localresult,ispass);
          break;

        case 'att_cha':
          //this.plog.log('att_cha', cdat);
          var localresult = false;
          if (cdat['s_cha'] >= Number(val)) {
            localresult = true;
          }
          resmsg = resmsg + ' CHA: ' + val;
          ispass = ispass && localresult;
          //this.plog.log("requirement level_min ", cdat["level"], val,localresult,ispass);
          break;

        case 'att_dex':
          //this.plog.log('att_dex', cdat);
          var localresult = false;
          if (cdat['s_dex'] >= Number(val)) {
            localresult = true;
          }
          resmsg = resmsg + ' DEX: ' + val;
          ispass = ispass && localresult;
          //this.plog.log("requirement level_min ", cdat["level"], val,localresult,ispass);
          break;

        case 'att_con':
          //this.plog.log('att_con', cdat);
          var localresult = false;
          if (cdat['s_con'] >= Number(val)) {
            localresult = true;
          }
          resmsg = resmsg + ' CON: ' + val;
          ispass = ispass && localresult;
          //this.plog.log("requirement level_min ", cdat["level"], val,localresult,ispass);
          break;

        case 'att_int':
          //this.plog.log('att_int', cdat);
          var localresult = false;
          if (cdat['s_int'] >= Number(val)) {
            localresult = true;
          }
          resmsg = resmsg + ' INT: ' + val;
          ispass = ispass && localresult;
          //this.plog.log("requirement level_min ", cdat["level"], val,localresult,ispass);
          break;

        case 'att_wis':
          //this.plog.log('att_wis', cdat);
          var localresult = false;
          if (cdat['s_wis'] >= Number(val)) {
            localresult = true;
          }
          resmsg = resmsg + ' WIS: ' + val;
          ispass = ispass && localresult;
          //this.plog.log("requirement level_min ", cdat["level"], val,localresult,ispass);
          break;

        case 'att_str':
          //this.plog.log('att_str', cdat);
          var localresult = false;
          if (cdat['s_str'] >= Number(val)) {
            localresult = true;
          }
          resmsg = resmsg + ' STR: ' + val;
          ispass = ispass && localresult;
          //this.plog.log("requirement level_min ", cdat["level"], val,localresult,ispass);
          break;
        case 'donationlevel':
          //this.plog.log('att_int', cdat);
          var localresult = false;
          if (cdat['donationlevel'] >= Number(val)) {
            localresult = true;
          }
          resmsg = resmsg + ' Donation Level (Donation Shed): ' + val;
          ispass = ispass && localresult;
          //this.plog.log("requirement level_min ", cdat["level"], val,localresult,ispass);
          break;

        case 'gender':
          // this.plog.log('gender', cdat);
          var localresult = false;
          if (cdat['gender'] == val + "") {
            localresult = true;
          }
          resmsg = resmsg + ' ' + this.wh.trs("f:rcrequirement:Gender===Gender") + ': ' + val;
          ispass = ispass && localresult;
          //this.plog.log("requirement level_min ", cdat["level"], val,localresult,ispass);
          break;
        case 'questdone':
          //this.plog.log('questdone', cdat);
          var localresult = false;
          if (cdat['questdone'][val] == val + "") {
            localresult = true;
          }
          resmsg = resmsg + ' ' + this.wh.trs("f:rcrequirement:Complete Quest===Complete Quest") + ': ' + this.rcvar.allquest[val]["name"];
          ispass = ispass && localresult;
          //this.plog.log("requirement level_min ", cdat["level"], val,localresult,ispass);
          break;
        case 'questnotdone':
          this.plog.log('questnotdone', cdat);
          var localresult = true;
          if (cdat['questdone'][val] == val + "") {
            localresult = false;
          }
          resmsg = resmsg + ' ' + this.wh.trs("f:rcrequirement:Quest NOT Complete===Quest NOT Complete") + ': ' + this.rcvar.allquest[val]["name"];
          ispass = ispass && localresult;
          //this.plog.log("requirement level_min ", cdat["level"], val,localresult,ispass);
          break;

        case 'incubating':
          var localresult = false;
          if (val == 0) {
            if (cdat['eggnest'].length == 0) {
              localresult = true;
            } else {
              localresult = false;
            }
            resmsg = resmsg + ' ' + this.wh.trs("f:rcrequirement:Not incubating an egg===Not incubating an egg") + ' ';
          }
          if (val == 1) {
            if (cdat['eggnest'].length == 0) {
              localresult = false;
            } else {
              localresult = true;
            }
            resmsg = resmsg + ' ' + this.wh.trs("f:rcrequirement:Incubating an egg===Incubating an egg") + ' ';
          }
          ispass = ispass && localresult;
          //this.plog.log("requirement level_min ", cdat["level"], val,localresult,ispass);
          break;

        default:
          break;
      }
    }
    //this.plog.log("requirement result ", ispass, req);
    ///  return ispass;
    if (restype == 'text') {
      return resmsg;
    } else {
      return ispass;
    }
  }
}
