import { Injectable } from '@angular/core';
import { RCVarsService } from './rcvars-service.service';
import { RCOSDService } from './rcosd.service';
import { RCHTTP } from './rchttp.service';
import { plog } from './plog.service';

@Injectable({
  providedIn: 'root',
})
export class RCSkillCraftService {
  shopdragbtn;
  title;
  lastclickpos = "";
  selectcircle;
  craftbtn;
  shopclosebtn;
  itemdescr;
  skilldescr;
  eqitems = [];
  eqitemsr = [];
  itemwh = 54;
  shopguibg;
  wh;
  loading;
  firsttimerun = true;
  plog;
  skillid;
  skillcode = "";
  lastdat;

  constructor(
    public rcvarsservice: RCVarsService,
    public rcosd: RCOSDService,
    public rchttp: RCHTTP
  ) {
    this.plog = new plog();
    //this.plog.setLevel(4);
  }

  loadingshow() {
    this.wh.skillcraftcontainer.bringToTop(this.loading);
    this.loading.visible = true;
  }
  loadinghide() {
    this.loading.visible = false;
  }
  firsttimerunfunc() {
    if (this.firsttimerun == true) {
      try {
        ///this.wh.registry.list.gameitf.guiwindowclick(this.wh.skillcraftcontainer);
      } catch (e) {
        this.plog.log("ERROR!", e);
      }
    }
    this.firsttimerun = false;
  }
  async show(wh, skillid, forcefocus = false) {
    this.plog.log("skillcraft.show", skillid);

    if (forcefocus == true) this.firsttimerun = true;
    if (skillid != undefined && skillid != "") {

      this.skillid = skillid + "";
      var tmpnamea = this.skillid.split("---");
      this.skillcode = tmpnamea[1];
    }

    this.wh = wh;
    if (this.rcvarsservice.activechar["cbt"]["skill"].includes(this.skillcode)) {
      this.wh.registry.list.rctoast.rctoast(this.wh, this.wh.trs("You already learn this skill"));

      return;
    }
    this.lastdat = this.rcvarsservice.skilltp[this.skillcode];
    if (this.lastdat == undefined) {
      this.plog.error("can't find skill data", this.skillcode);
      return;
    }
    this.plog.log("skill data", this.skillcode, this.lastdat);
    /*if (wh.skillcraftcontainer != undefined) {
      wh.skillcraftcontainer.visible = true;
      this.show_loaded(wh);
      return;
    }*/
    if (this.wh.skillcraftcontainer != undefined) {
      await this.show_close(this.wh);
    }
    var width = wh.game.canvas.width;
    var height = wh.game.canvas.height;
    this.plog.log('equipment show', wh, this);

    const selectcircle = wh.add.image(0, 0, 'selectcircle');
    selectcircle.setOrigin(0.5, 0.5);
    selectcircle.visible = false;
    selectcircle.setAlpha(0.7);
    const shopguibg = wh.add.image(0, 0, 'blankrowguibg');
    shopguibg.setOrigin(0.5, 0.5);
    shopguibg.setInteractive({ pixelPerfect: true });
    shopguibg.removeAllListeners();
    this.shopguibg = shopguibg;
    //this.plog.log('showinventory var', this.rcvarsservice);
    this.selectcircle = selectcircle;

    this.shopclosebtn = wh.add.sprite(300, 300, "thesq", 'close');
    this.shopclosebtn.name = 'skipcntnrsizecal';
    this.shopclosebtn.setOrigin(0.5, 0.5);
    this.shopclosebtn.setPosition(155, -200);
    this.shopclosebtn.displayWidth = 40;
    this.shopclosebtn.displayHeight = 40;
    this.shopclosebtn.setInteractive();
    this.shopclosebtn.removeAllListeners();
    this.shopclosebtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        //this.scene.registry.list["thethis"].phm.floorclick(this);
        this.plog.log(
          'gc.npcbackbtn pointerup',
          pointer,
          localX,
          localY,
          event,
          this
        );
        this.show_close(wh);
      },
      this
    );

    wh.skillcraftcontainer = wh.add.container(300, 300);
    wh.skillcraftcontainer._refsrv = this;
    wh.skillcraftcontainer.visible = false;
    wh.skillcraftcontainer.name = "skillcraftcontainer";
    wh.skillcraftcontainer.add(this.shopclosebtn);
    wh.skillcraftcontainer.add(selectcircle);
    wh.skillcraftcontainer.add(shopguibg);
    wh.skillcraftcontainer.bringToTop(this.shopclosebtn);
    this.itemdescr = wh.add.text(0, 0, wh.trs("f:skillcraftcontainer:Click Item to View Description===Click Item to View Description"), wh.fs["winguiitemdescr"]);
    wh.skillcraftcontainer.add(this.itemdescr);
    this.itemdescr.setOrigin(0, 0);
    this.itemdescr.setPosition(0 - this.itemdescr.displayWidth / 2, -150);

    this.skilldescr = wh.add.text(0, 0, "Crafting skill: " + this.lastdat['name'], wh.fs["winguilist_title"]);
    wh.skillcraftcontainer.add(this.skilldescr);
    this.skilldescr.setPosition(0 - this.skilldescr.displayWidth / 2, -178);



    this.loading = wh.add.sprite(300, 300, 'pixelloading').play('pixelloading_b');
    this.loading.setOrigin(0.5, 0.5);
    this.loading.setPosition(0, 0);
    this.loading.displayWidth = this.loading.displayHeight = 60;
    wh.skillcraftcontainer.add(this.loading);
    wh.skillcraftcontainer.bringToTop(this.loading);

    //drag

    this.shopdragbtn = wh.add.image(300, 300, 'spacer');
    this.shopdragbtn.setOrigin(0.5, 0.5);
    this.shopdragbtn.setPosition(0, -200);
    this.shopdragbtn.displayWidth = 270;
    this.shopdragbtn.displayHeight = 60;
    this.shopdragbtn.setInteractive();
    this.shopdragbtn.removeAllListeners();
    this.shopdragbtn.on(
      'pointerout',
      function (pointer, localX, localY, event) {
        this.rcvarsservice.gameobj.scene.scenes[0].skillcraftcontainer.dragactive =
          false;
      },
      this
    );
    this.shopdragbtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        this.rcvarsservice.gameobj.scene.scenes[0].skillcraftcontainer.dragactive =
          false;
      },
      this
    );
    this.shopdragbtn.on(
      'pointerdown',
      function (pointer, localX, localY, event) {
        this.rcvarsservice.gameobj.registry.list.gameitf.guiwindowclick(
          this.rcvarsservice.gameobj.scene.scenes[0].skillcraftcontainer
        );

        this.rcvarsservice.gameobj.scene.scenes[0].skillcraftcontainer.dragactive =
          true;
        this.rcvarsservice.gameobj.scene.scenes[0].skillcraftcontainer.dragactivex =
          this.rcvarsservice.gameobj.scene.scenes[0].skillcraftcontainer.x;
        this.rcvarsservice.gameobj.scene.scenes[0].skillcraftcontainer.dragactivey =
          this.rcvarsservice.gameobj.scene.scenes[0].skillcraftcontainer.y;
        this.rcvarsservice.gameobj.scene.scenes[0].skillcraftcontainer.dragactivepx =
          pointer.x;
        this.rcvarsservice.gameobj.scene.scenes[0].skillcraftcontainer.dragactivepy =
          pointer.y;
      },
      this
    );
    this.rcvarsservice.gameobj.scene.scenes[0].skillcraftcontainer.dragactive =
      false;
    this.rcvarsservice.gameobj.scene.scenes[0].skillcraftcontainer.dragactivex = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].skillcraftcontainer.dragactivey = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].skillcraftcontainer.dragactivepx = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].skillcraftcontainer.dragactivepy = 0;
    this.shopdragbtn.on(
      'pointermove',
      function (pointer, localX, localY, event) {
        //this.scene.registry.list["thethis"].phm.floorclick(this);
        //this.plog.log('shop pointermove', pointer, localX, localY, event, this);
        //this.showinventory_close(wh);
        if (
          this.rcvarsservice.gameobj.scene.scenes[0].skillcraftcontainer
            .dragactive == true
        ) {
          var tmpx =
            this.rcvarsservice.gameobj.scene.scenes[0].skillcraftcontainer
              .dragactivex;
          var tmpy =
            this.rcvarsservice.gameobj.scene.scenes[0].skillcraftcontainer
              .dragactivey;
          var tmppx =
            this.rcvarsservice.gameobj.scene.scenes[0].skillcraftcontainer
              .dragactivepx;
          var tmppy =
            this.rcvarsservice.gameobj.scene.scenes[0].skillcraftcontainer
              .dragactivepy;
          var movedx = tmppx - pointer.x;
          var movedy = tmppy - pointer.y;
          //this.plog.log('drag', tmppx, pointer.x, movedx);

          this.rcvarsservice.gameobj.scene.scenes[0].skillcraftcontainer.x =
            tmpx - movedx;
          this.rcvarsservice.gameobj.scene.scenes[0].skillcraftcontainer.y =
            tmpy - movedy;
          //this.rcvarsservice.gameobj.scene.scenes[0].skillcraftcontainer.y = pointer.y;
        }
      },
      this
    );
    wh.skillcraftcontainer.add(this.shopdragbtn);
    wh.skillcraftcontainer.bringToTop(this.shopdragbtn);

    /*
    this.plog.log("dragsize",shopguibg.width, shopguibg.height);
    wh.skillcraftcontainer.setSize(shopguibg.width, shopguibg.height);
    wh.skillcraftcontainer.setInteractive();
    wh.input.on('mousemove', function(pointer) {
      //check here is a scene has a selected gameObject
      //if(this.selected) { //to be able to detect if user really select something.
          wh.setPosition(pointer.x, pointer.y);
      //}
    }, this);*/
    let loader = new Phaser.Loader.LoaderPlugin(wh);
    var svversionurlstring = this.wh.registry.list.rcvarpass.svversionstring;
    if (svversionurlstring != "") {
      svversionurlstring = "?" + svversionurlstring;
    }
    var loadlist = ["stackitem_tpg"];
    for (var k in loadlist) {
      var key = loadlist[k];
      loader.atlas(key + "", this.wh.registry.list.rcvarpass.rc_baseurl + "sprite/" + key + ".png" + svversionurlstring, this.wh.registry.list.rcvarpass.rc_baseurl + "sprite/" + key + ".json" + svversionurlstring);
    }
    //move
    wh.skillcraftcontainer.setPosition((width / 2), (height / 2));

    wh.skillcraftcontainer.x = width - this.shopguibg.width / 2 + 20;


    this.title = wh.add.text(0, 0, 'Craft Skill', wh.fs["winguititle42"]);
    //this.plog.log("winguititle fs", wh.fs["winguititle"])
    wh.skillcraftcontainer.add(this.title);
    wh.skillcraftcontainer.bringToTop(this.title);
    this.title.setPosition(0 - this.title.displayWidth / 2, -226);


    this.wh.registry.list.gameitf.guiwindowclick(this.wh.skillcraftcontainer);

    /*
    //move to spritepack
        for (var vv in equipment) {
          var vdat = equipment[vv];
          var itemdat = this.rcvarsservice.allitem[vdat.item];
          loader.image(vdat["item"], this.rcvarsservice.rc_baseurl + "item/" + itemdat["pcode"] + "/" + itemdat["sprite"] + ".png");
    
          this.plog.log("load shop ", vv, vdat, itemdat);
        }*/
    loader.once(
      Phaser.Loader.Events.COMPLETE,
      async () => {
        this.plog.log('Phaser.Loader.Events.COMPLETE');
        //this.plog.log(this);
        //await this.wh.registry.list.rctoast.sleep(100);

        this.show_loaded(wh);
      },
      this
    );
    loader.start();
  }
  updateslotsize() {
    for (var vv in this.eqitems) {
      var vdat = this.eqitems[vv];
      this.plog.log('updateslotsize', vv, vdat);
      this.eqitems[vv].displayWidth = this.itemwh;
      this.eqitems[vv].displayHeight = this.itemwh;
      this.wh.skillcraftcontainer.bringToTop(this.eqitems[vv]);
      //this.eqitems[vv].setInteractive(false);
      //this.eqitems[vv].setInteractive();
    }
  }
  async show_loaded(wh) {
    this.firsttimerunfunc();
    this.plog.log('show_loaded', wh, this);
    //chk sprite loaded 

    this.loadinghide();

    //this.wh.registry.list.gameitf.guiwindowclick(this.wh.skillcraftcontainer);
    //await this.wh.registry.list.rctoast.sleep(100);
    var griddat = [];
    var griddatf = [];

    var width = wh.game.canvas.width;
    var height = wh.game.canvas.height;

    this.plog.log('skillcraftcontainer', wh.skillcraftcontainer, width, height);
    //wh.skillcraftcontainer.setPosition((width / 2), (height / 2));
    var rarstr = "";

    var equiplist = [
      'rune11',
      'rune12',
      'rune21',
      'rune31',
      'rune32',
      'rune41',
      'rune51',
      'rune52'
    ];
    if (this.craftbtn != undefined) this.craftbtn.destroy();

    for (let i = 0; i < equiplist.length; i++) {
      var vname = equiplist[i];
      if (this.eqitems[vname] != undefined) this.eqitems[vname].destroy();
      if (this.eqitemsr[vname] != undefined) this.eqitemsr[vname].destroy()

      if (this.eqitems[vname] != undefined) this.eqitems[vname] = undefined;
      if (this.eqitemsr[vname] != undefined) this.eqitemsr[vname] = undefined;
    }
    var gotallrune = true;
    for (let i = 0; i < equiplist.length; i++) {
      var vname = equiplist[i];
      this.eqitemsr[vname] = wh.add.image(100, 100, "spacer");
      //console.error(vname,this.lastdat);
      //console.error(vname,this.lastdat.craft[vname]);


      //this.plog.log('eqitems i', i, vname);
      if (this.lastdat.craft[vname] == undefined ||
        this.lastdat.craft[vname] == null ||
        this.lastdat.craft[vname] == ""
      ) {
        this.eqitems[vname] = wh.add.image(100, 100, "spacer");
      } else {
        this.eqitems[vname] = wh.add.sprite(0, 0, 'stackitem_tpg', this.lastdat.craft[vname]);
        if (wh.registry.list.rcreq.chk({ stackitem1: "" + this.lastdat.craft[vname], stackitem1_min: 1 })) {
          this.eqitems[vname] = wh.add.sprite(0, 0, 'stackitem_tp', this.lastdat.craft[vname]);
          this.eqitems[vname].postFX.addGlow(0x02A8DF, 7, 0, false, 0.2, 10);
          this.eqitems[vname].postFX.addShine(1, 0.2, 5, false);

        } else {
          gotallrune = false;
        }
      }

      wh.skillcraftcontainer.add(this.eqitems[vname]);
      wh.skillcraftcontainer.add(this.eqitemsr[vname]);
      wh.skillcraftcontainer.bringToTop(this.eqitemsr[vname]);
      wh.skillcraftcontainer.bringToTop(this.eqitems[vname]);
      /*
          if (wh.registry.list.rcreq. ({ stackitem1: "sellstackitem", stackitem1_min: 1 })) {
 
    }*/
      this.eqitems[vname].displayWidth = this.itemwh;
      this.eqitems[vname].name = this.lastdat.craft[vname];
      this.eqitems[vname].displayHeight = this.itemwh;
      //this.eqitems[vname].setInteractive();
      if (vname == 'rune11') {
        //console.error(vname, this.lastdat.craft[vname], this.eqitems[vname]);
        this.eqitems[vname].setPosition(-70, -90);
        //this.eqitems[vname].setPosition(-118, -33);
      }
      if (vname == 'rune12') {
        this.eqitems[vname].setPosition(70, -90);
        //this.eqitems[vname].setPosition(-118, -33);
      }
      if (vname == 'rune21') {
        this.eqitems[vname].setPosition(0, -31);
        //this.eqitems[vname].setPosition(-118, 27);
      }
      if (vname == 'rune31') {
        this.eqitems[vname].setPosition(-70, 27);
      }
      if (vname == 'rune32') {
        this.eqitems[vname].setPosition(70, 27);
      }

      if (vname == 'rune41') {
        this.eqitems[vname].setPosition(0, 87);
      }
      if (vname == 'rune51') {
        this.eqitems[vname].setPosition(-70, 144);
      }
      if (vname == 'rune52') {
        this.eqitems[vname].setPosition(70, 144);
      }
      /*this.eqitems[vname].on("pointerover", function () {
        this.plog.log("equip i over", arguments, this);
      });*/


      this.eqitems[vname].setInteractive();
      this.eqitems[vname].removeAllListeners();
      this.eqitems[vname].on("pointerup", function () {
        //console.log("eqitems down", wh, arguments);
        //console.log("eqitems down", this.name, this);

        var iteminfo = this.scene.registry.list.rcvarpass.allstackitem[this.name];
        if (iteminfo == undefined) {
          console.log('missing stackitem info', iteminfo);
          return;
        }
        var mapinfo = this.scene.registry.list.rcvarpass.allmap[iteminfo["droponlyin"]];

        this.scene.registry.list.rctoast.rcalert(
          this.scene,
          this.scene.trs("Rune Required"),
           "You can get : " + iteminfo['name'] + "\nFrom : " + mapinfo["name"] + " \n(Level " + mapinfo['level'] + ")"
        );
        //console.log(this.name, iteminfo);
        //}
      });
    }


    this.craftbtn = wh.add.sprite(0, 0, "thesb", "craftdis");//wh.add.image(0, 0, "craftbtn");
    this.craftbtn.setOrigin(0.5, 0.5);
    this.craftbtn.setAlpha(1);
    this.craftbtn.displayHeight = 50;
    this.craftbtn.scaleY = this.craftbtn.scaleX;
    wh.skillcraftcontainer.add(this.craftbtn);
    wh.skillcraftcontainer.bringToTop(this.craftbtn);
    this.craftbtn.setPosition(0, 195);
    this.craftbtn.setInteractive();
    this.craftbtn.removeAllListeners();
    if (gotallrune == true) {
      this.craftbtn.setTexture("thesb", "craft");
      this.craftbtn.on("pointerup", async function () {
        this.plog.log("craftbtn down", this, arguments);

        //if (wh.registry.list.rcosd.craftbtn != undefined) {
        this.craftbtn.visible = false;
        var res=await this.wh.registry.list.rchttp.getcommon2("skillcraft", {
          skillid: this.skillcode
        });
        await this.wh.registry.list.rchttp.updatecharinfo();
        this.show_close(this.wh);
        try {
          this.wh.registry.list.rcskill.show_close(this.wh);
        } catch(e) {
          console.log(e);
        }
        //}
      }.bind(this));
    }
    //this.plog.log('eqitems', this.eqitems);

    //this.rcvarsservice.gameobj.registry.list.gameitf.guiwindowclick(this.rcvarsservice.gameobj.scene.scenes[0].skillcraftcontainer);

    this.wh.skillcraftcontainer.visible = true;
    this.wh.registry.list.resizeman.RCMapResizeManager_work(this.wh);
    griddat.push(this.shopclosebtn);
    griddat.push(this.craftbtn);
    this.wh.registry.list.gp.guigrid(griddat, griddatf);
  }


  async show_close(wh) {
    wh.skillcraftcontainer.visible = false;
    await wh.registry.list.gameitf.closegui(wh, wh.skillcraftcontainer);

    for (var desi = 0; desi < 10; desi++) {
      for (const k in this.wh.skillcraftcontainer.list) {
        try {
          await this.wh.skillcraftcontainer.remove(this.wh.skillcraftcontainer.list[k], true);
          //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list[k].destroy();
        } catch (e) {
          this.plog.log("ERROR! skillcraftcontainer.destroy() ", e);
        }
      }
    }
    for (var desi = 0; desi < 10; desi++) {
      try {
        await this.wh.skillcraftcontainer.destroy(true);
      } catch (e) {
        this.plog.log("ERROR! skillcraftcontainer.destroy() ", e);
      }
    }
    wh.skillcraftcontainer = undefined;
  }
  finditembyequippos(arr, pos) {
    //this.plog.log('finditembyequippos', arr, pos);
    for (var vv in arr) {
      var vdat = arr[vv];
      if (vdat['equippos'] == pos) {
        //this.plog.log('finditembyequippos found', vdat);
        return vdat;
      }
    }
    //this.plog.log('finditembyequippos NOT FOUND');
    return false;
  } 

  rebuildguigrid() {
    this.plog.log("rebuildguigrid();b", this.wh.kbfocus);
    this.show_loaded(this.wh);
    this.plog.log("rebuildguigrid();a", this.wh.kbfocus);
  }
}
