import { Injectable } from '@angular/core';
import { PGBar } from './pgbar';
import { plog } from './plog.service';
import { RCVarsService } from './rcvars-service.service';
//import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class GameInterfaceService {
  emotedb = "aa,ab,ac,ad,ae,af,ag,ah,ai,aj,ak,al,am,an,ao,ap,aq,ar,as,at,au,av,aw,ax,ay,az,ba,bb,bc,bd,be,bf,bi,bj,bk,bl,bm,bn,bo,bp,bq,br,bs,bt,bu,bv,bw,by,bz,ca,ea,cb,cc,cd,ce,cf,cg,ch,ci,cj,ck,cl,cm,cn,co,cp,cq,cr,cs,ct,cu,cv,cw,cx,cy,cz,da,db,dc,dd,de,df,di,dj,dk,dl,dm,dn,do,dp,dq,dr,ds,dt,du,dv,dw,dx,dy,dz";
  emotedba = [];
  emoteobj = [];
  emotebg;


  added = false;
  lastinputitemid;
  lastinputitemidi;
  minimapviewbg;
  wh;
  gifscontainer;
  charxpbar;
  initeventkeydownenter = false;
  clickchktime = 0;
  registryclone;
  plog;

  constructor(
    private rcvar: RCVarsService
  ) {
    this.emotedba = this.emotedb.split(",");
    this.plog = new plog();
    this.plog.setLevel(1);
    //this.rcvar=new RCVarsService(Platform);
    //console.log("this.rcvar",this.rcvar);
  }
  guihidestatus = "show";
  async hidegui(wh) {
    //console.error("hidegui", this);
    //console.error("gameitf rcvar", this.rcvar);

    if (wh == undefined) {
      this.plog.error("gameitf.hidegui() invalid wh;");
      return;
    }
    this.guihidestatus = "hide";
    this.wh = wh;
    this.wh.minimap.x = -500;
    var width = this.wh.game.canvas.width;
    var height = this.wh.game.canvas.height;
    try {
      this.wh.gifstween.stop();
    } catch (e) {
      //console.log(e);
    }
    this.wh.minimap.visible = false;
    this.wh.gifstween = this.wh.tweens.add({
      targets: this.wh.gifscontainer,
      scale: 3,
      x: Math.floor(0 - width),
      y: Math.floor(0 - height),
      yoyo: false,
      repeat: 0,
      duration: 300,
      ease: 'Power3'

    });
    /* await this.wh.registry.list.rctoast.sleep(300);
    this.wh.gifscontainer.setScale(3);
    this.wh.gifscontainer.x = Math.floor(0 - width);
    this.wh.gifscontainer.y = Math.floor(0 - height);
    await this.wh.registry.list.rctoast.sleep(50);*/
    //console.error("hidegui force", this.wh.gifscontainer);
  }
  showgui(wh) {

    if (wh == undefined) {
      this.plog.error("gameitf.hidegui() invalid wh;");
      return;
    }
    this.guihidestatus = "show";
    this.wh = wh;
    this.wh.minimap.visible = true;

    var width = this.wh.game.canvas.width;
    var height = this.wh.game.canvas.height;
    //console.log("showgui",this.wh.gifscontainer.scale);
    if (this.wh.gifscontainer.scale == 1) return; //return if it is already visible;
    this.wh.gifscontainer.setScale(3);
    this.wh.gifscontainer.x = Math.floor(0 - width);
    this.wh.gifscontainer.y = Math.floor(0 - height);
    //this.wh.minimap.visible = true;
    this.wh.tweens.add({
      targets: this.wh.gifscontainer,
      scale: 1,
      x: 0,
      y: 0,
      yoyo: false,
      repeat: 0,
      duration: 350,
      ease: 'Power3',
      onComplete: function name() {
        this.wh.registry.list.resizeman.RCMapResizeManager_work(this.wh);
      }.bind(this)

    });
    this.wh.registry.list.resizeman.RCMapResizeManager_work(this.wh);
  }
  markclick() {
    this.clickchktime = this.getCurrentTime();
  }
  clicktoosoon() {
    var timesincelastclick = this.getCurrentTime() - this.clickchktime;
    if (timesincelastclick < 300) {
      this.plog.log("clicktoosoon(): clicktoosoon", timesincelastclick);
      this.markclick()
      return true;
    }
    return false;
  }
  getCurrentTime(): number {
    const date = new Date();
    return date.getTime();
  };
  hidechatinput(wh) {
    if (wh.chatinputbox != undefined) {
      try {
        wh.chatinputbox.visible = false;
      } catch (e) {
        console.log(e);
      }
    }
  }
  showchatinput(wh) {
    if (wh.chatinputbox.visible == false) {
      wh.chatinputbox.visible = true;
      wh.chatinputbox.setBlur();
      wh.chatinputbox.setFocus();
      // this.wh.chatinputbox.focus();
      setTimeout(() => {
        wh.chatinputbox.setFocus();
      }, 150);
    } else {
      wh.chatinputbox.visible = false;
    }
  }
  addinterfacecalled = 0;
  async addinterface(wh) {
    this.addinterfacecalled++;
    if (this.addinterfacecalled > 1) {
      this.plog.warn('gameitf.addinterface called', this.addinterfacecalled);
      return;
    }
    this.wh = wh;
    //this.plog.error("gifs.addinterface", wh,this.wh);

    this.wh.registry.list.gp.initgp(this.wh);
    this.wh.registry.list.gp.initkb(this.wh);
    if (this.initeventkeydownenter == false) {
      this.wh.input.on('pointermove', (pointer) => {
        //this.plog.log("this.wh.biglightsource2",this.wh.biglightsource2);
        //this.plog.log("this.wh.biglightsource2",this.wh);

        /*
        if (this.wh.biglightsource2 != undefined && this.wh.mapcontainer != undefined) {
          var tmpcampos = this.wh.input.activePointer.positionToCamera(this.wh.input.cameras.cameras[0])

          //this.wh.biglightsource2.x = pointer.x+this.wh.mapcontainer.x;
          //this.wh.biglightsource2.y = pointer.y+this.wh.mapcontainer.y;
          this.wh.biglightsource2.x = tmpcampos['x'];
          this.wh.biglightsource2.y = tmpcampos['y'];
          //this.plog.log("this.wh.biglightsource2",this.wh.input);
          //this.plog.log("this.wh.biglightsource2",this.wh);
        }*/
      });

      this.wh.chatinputbox = wh.add
        .rexInputText(0, 0, 100, 35, {
          id: 'chatinputbox',
          type: 'text',
          text: '',
          fontSize: '18px',
          color: '#000',
          autoComplete: 'off',
          border: "2px",
          padding: "4px",
          backgroundColor: '#f2f2f2',
          borderColor: '#444444',
          borderStyle: 'solid',
          selectAll: true,
          '-webkit-border-radius': '10px',
          '-moz-border-radius': '10px',
          'border-radius': '10px',
        })
        .resize(100, 40)
        .setOrigin(0.5)
        .setPlaceholder('Type message and press enter')
        .on('textchange', function (inputText) {
          //this.plog.log("chatinputbox", inputText);
        });
      this.wh.chatinputbox.visible = false;
    }


    //////////////////////////////////////////////////////////////////////


    this.gifscontainer = wh.add.container(0, 0);
    this.gifscontainer.setDepth(50000);
    this.wh.gifscontainer = this.gifscontainer;
    this.wh.gifscontainer.name = "gifscontainer";
    //wh.cameras.main.ignore(this.gifscontainer);

    this.wh.focusguil = wh.add.sprite(0, 0, "select", "select_l");
    this.wh.focusguir = wh.add.sprite(0, 0, "select", "select_r");
    this.wh.focusguil.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);
    this.wh.focusguir.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);
    this.wh.gifscontainer.add(this.wh.focusguil);
    this.wh.gifscontainer.bringToTop(this.wh.focusguil);
    this.wh.gifscontainer.add(this.wh.focusguir);
    this.wh.gifscontainer.bringToTop(this.wh.focusguir);
    this.wh.focusguil.setPosition(-1000, -1000);
    this.wh.focusguir.setPosition(-1000, -1000);

    this.wh.gamemenuloading = wh.add.sprite(300, 300, 'pixelloading').play('pixelloading_b');
    this.wh.gamemenuloading.setOrigin(0.5, 0.5);
    this.wh.gamemenuloading.setPosition(0, 0);
    this.wh.gamemenuloading.displayWidth = this.wh.gamemenuloading.displayHeight = 60;
    this.wh.gifscontainer.add(this.wh.gamemenuloading);
    this.wh.gifscontainer.bringToTop(this.wh.gamemenuloading);
    this.wh.gamemenuloading.visible = false;
    if (this.added != false) return;
    this.added = true;
    //this.plog.log("gifs addinterface", wh);

    var width = wh.game.canvas.width;
    var height = wh.game.canvas.height;
    var avatarsize = 58;

    const playerAvatar = wh.add.image(avatarsize, avatarsize, 'defavatar');
    playerAvatar.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);
    var avatarmargin = 5;
    //playerAvatar.setDepth(999);
    playerAvatar.displayWidth = avatarsize;
    playerAvatar.displayHeight = avatarsize;
    playerAvatar.setPosition(
      avatarsize / 2 + avatarmargin,
      avatarsize / 2 + avatarmargin
    );
    //image.scale=10;
    /*
    const shape = wh.make.graphics();
    shape.fillStyle(0xffffff);
    shape.beginPath();
    //shape.moveTo(70, 70);
    shape.setPosition(avatarsize + avatarmargin, avatarsize / 2 + avatarmargin);
    shape.arc(-avatarsize / 2, 0, avatarsize / 2, 0, Math.PI * 2);
    shape.fillPath();
    const mask = shape.createGeometryMask();*/
    //playerAvatar.setMask(mask);
    playerAvatar.setDepth(60002);
    wh.playerAvatar = playerAvatar;
    wh.playerAvatar.setInteractive({ pixelPerfect: true });
    //wh.playerAvatar.setInteractive();
    wh.playerAvatar.on(
      'pointerdown',
      function (pointer, localX, localY, event) {
        // this.plog.log(arguments);
        //this.plog.log(this);
        if (this.clicktoosoon()) return; this.markclick();
        this.wh.registry.list.snd.snd("btn");

        this.wh.registry.list.charinfo.show(wh);
      },
      this
    );

    wh.playerAvatar.on(
      'pointerover',
      function () {
        this.wh.registry.list.gameitf.gamemenutooltip(
          this.wh,
          this.wh.playerAvatar,
          this.wh.trs("f:gifscontainer:Your Character, click for more info===Your Character, click for more info"),
          'btmlw'
        );
      },
      this
    );
    this.updateava(wh);

    //broadcast
    wh.bc_bg = wh.add.image(0, 0, 'white');

    wh.bc_text = wh.add.text(
      0,
      0,
      wh.scene.scene.registry.list.rcvarpass.activechar.name,
      {
        fontFamily: 'ffff',
        fontSize: '20px',
        stroke: '#fff',
        color: '#000',
        strokeThickness: 0,
      }
    );
    //wh.bc_text = wh.add.bitmapText(0, 0, 'fffb', '0', 20, 2);

    wh.bc_bg.visible = false;
    wh.bc_text.visible = false;

    wh.clock_bg = wh.add.image(0, 0, 'clock');
    wh.clock_bg.setInteractive();
    wh.clock_bg.removeAllListeners();
    wh.clock_bg.on("pointerdown", function () {
      //this.plog.log("clock pointerdown", wh);
      this.wh.registry.list.timectl.showymd();

    }, this);
    //wh.clock_hh = wh.add.text(0, 0, "0", { fontFamily: 'ffff bold', fontSize: '16px', fill: '#000', strokeThickness: 0, align: 'right', boundsAlignH: "right" });
    //wh.clock_hh = wh.add.bitmapText(0, 0, 'fffb', '0', 18, 2);
    wh.clock_hh = wh.add.text(0, 0, '0', { fontFamily: 'ffff', fontSize: '12px', color: '#000' });
    wh.clock_dot = wh.add.image(0, 0, 'justcircle');


    wh.resb_gold = wh.add.image(0, 0, 'curbggold');
    wh.resb_diamond = wh.add.image(0, 0, 'curbgdiamond');
    wh.resb_supp = wh.add.image(0, 0, 'curbgsupp');
    //wh.resb_goldt = wh.add.text(0, 0, "0", { fontFamily: 'ffff', fontSize: '16px', stroke: '#fff', strokeThickness: 0, align: 'right', boundsAlignH: "right" });
    //wh.resb_diamondt = wh.add.text(0, 0, "0", { fontFamily: 'ffff', fontSize: '16px', stroke: '#fff', strokeThickness: 0, align: 'right', boundsAlignH: "right" });
    //wh.resb_goldt = wh.add.bitmapText(0, 0, 'fffb', '0', 18, 2);
    // wh.resb_diamondt = wh.add.bitmapText(0, 0, 'fffb', '0', 18, 2);
    wh.resb_goldt = wh.add.text(0, 0, '0', { fontFamily: 'ffff', fontSize: '8px', color: '#000' });
    wh.resb_diamondt = wh.add.text(0, 0, '0', { fontFamily: 'ffff', fontSize: '8px', color: '#000' });
    wh.resb_suppt = wh.add.text(0, 0, '0', { fontFamily: 'ffff', fontSize: '8px', color: '#000' });
    wh.resb_gold.setOrigin(0, 0);
    wh.resb_diamond.setOrigin(0, 0);
    wh.resb_supp.setOrigin(0, 0);
    wh.resb_goldt.setOrigin(1, 0);
    wh.resb_diamondt.setOrigin(1, 0);
    wh.resb_suppt.setOrigin(1, 0);
    this.gifscontainer.add(this.wh.resb_gold);
    this.gifscontainer.bringToTop(this.wh.resb_gold);
    this.gifscontainer.add(this.wh.resb_diamond);
    this.gifscontainer.bringToTop(this.wh.resb_diamond);
    this.gifscontainer.add(this.wh.resb_supp);
    this.gifscontainer.bringToTop(this.wh.resb_supp);
    this.gifscontainer.add(this.wh.resb_goldt);
    this.gifscontainer.bringToTop(this.wh.resb_goldt);
    this.gifscontainer.add(this.wh.resb_diamondt);
    this.gifscontainer.bringToTop(this.wh.resb_diamondt);
    this.gifscontainer.add(this.wh.resb_suppt);
    this.gifscontainer.bringToTop(this.wh.resb_suppt);
    this.wh.resb_gold.setInteractive();
    this.wh.resb_diamond.setInteractive();
    this.wh.resb_supp.setInteractive();
    this.wh.resb_gold.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);
    this.wh.resb_diamond.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);
    this.wh.resb_supp.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);
    this.wh.resb_gold.on('pointerover', function () {
      wh.registry.list.gameitf.gamemenutooltip(
        wh,
        wh.resb_gold,
        wh.trs("f:gifscontainer:Gold, common currency===Gold, common currency"),
        'btm'
      );
    });
    this.wh.resb_diamond.on('pointerover', function () {
      wh.registry.list.gameitf.gamemenutooltip(
        wh,
        wh.resb_diamond,
        wh.trs("f:gifscontainer:Diamond, exclusive currency===Diamond, exclusive currency"),
        'btm'
      );
    });
    this.wh.resb_supp.on('pointerover', function () {
      wh.registry.list.gameitf.gamemenutooltip(
        wh,
        wh.resb_supp,
        wh.trs("f:gifscontainer:Cat Fur, upgrade resource===Cat Fur, upgrade resource"),
        'btm'
      );
    });

    //circle
    //var r1 = wh.add.circle((avatarsize/2)+avatarmargin, (avatarsize/2)+avatarmargin, avatarsize/2,);
    /*
    var avartarcircle = wh.add.circle(
      avatarsize / 2 + avatarmargin,
      avatarsize / 2 + avatarmargin,
      avatarsize / 2,
      0x999999
    );
    avartarcircle.setStrokeStyle(4, 0x000000);
    // r1.setDepth(1000);
    avartarcircle.setDepth(60001);
    
    wh.avartarcircle = avartarcircle;
*/

    wh.hpbg = wh.add.image(0, 0, "pgframe");
    wh.xpbg = wh.add.image(0, 0, "pgframe");
    wh.hpbg.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);
    wh.xpbg.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);

    wh.hpbg.setInteractive();
    wh.hpbg.removeAllListeners();
    wh.hpbg.on(
      'pointerup',
      function () {
        if (this.clicktoosoon()) return; this.markclick();
        try {
          if (
            this.wh.registry.list.rcvarpass.activechar['hp'] !=
            this.wh.registry.list.rcvarpass.activechar['maxhp']
          ) {
            this.wh.registry.list.execmod.exec('healicon', this.wh);
          }
        } catch (e) {
          console.log(e);
        }
      },
      this
    );

    wh.xpbg.setInteractive();
    wh.xpbg.removeAllListeners();
    wh.xpbg.on(
      'pointerup',
      function () {
        if (this.clicktoosoon()) return; this.markclick();
        this.wh.registry.list.execmod.exec('gamemenu:attribute', this.wh);
      },
      this
    );

    this.gifscontainer.add(this.wh.hpbg);
    this.gifscontainer.add(this.wh.xpbg);
    this.gifscontainer.bringToTop(this.wh.hpbg);
    this.gifscontainer.bringToTop(this.wh.xpbg);
    wh.hpbg.setOrigin(0, 0);
    wh.xpbg.setOrigin(0, 0);
    wh.hpbg.setDepth(500001);
    wh.xpbg.setDepth(500001);
    wh.xpbg.displayWidth = 154;
    wh.xpbg.displayHeight = 16;//wh.xpbg.scaleY = wh.xpbg.scaleX;
    wh.hpbg.displayWidth = 154;
    wh.hpbg.displayHeight = 16;//wh.hpbg.scaleY = wh.xpbg.scaleX;
    wh.xpbg.setPosition(avatarsize + avatarmargin + 4, avatarmargin + 25);
    wh.hpbg.setPosition(avatarsize + avatarmargin + 4, avatarmargin + 42);

    wh.avartarcircle = wh.add.image(0, 0, "avaframe");
    wh.avartarcircle.displayWidth = wh.avartarcircle.displayHeight = avatarsize
    //player name
    //this.plog.log(wh.scene.scene.registry);
    wh.playerName = wh.add.text(
      0,
      0,
      wh.scene.scene.registry.list.rcvarpass.activechar.name + " lv." + wh.scene.scene.registry.list.rcvarpass.activechar.level,
      wh.fs["playername"],
    );
    wh.avartarcircle.setPosition(playerAvatar.x, playerAvatar.y);
    wh.avartarcircle.setDepth(60003);

    wh.playerName.setPosition(avatarsize + avatarmargin + 4, avatarmargin);
    //wh.playerName.setShadow(0, 0, '#000', 0, true, true);
    wh.playerName.setDepth(60000);


    //stat hp mp 
    var xpneed =
      this.wh.registry.list.rcvarpass.level[
      this.wh.registry.list.rcvarpass.activechar['level']
      ]['xp'];
    var curxp = this.wh.registry.list.rcvarpass.activechar['xp'];
    var xpbaroption = {
      x: wh.xpbg.x,
      y: wh.xpbg.y,
      w: wh.xpbg.displayWidth,
      h: 16,//wh.xpbg.displayHeight,
      max: xpneed,
      me: curxp,
      txt: '',
      barcol1: 0xffc70f,
      barcol2: 0xffc70f,
      bgcol: 0xffffff,
      imgcol: 'yellow'
    }; //0x6b4602

    this.wh.charxpbar = new PGBar(this.wh, xpbaroption, 'img');
    this.gifscontainer.add(this.wh.charxpbar.getobj());
    this.gifscontainer.bringToTop(this.wh.charxpbar.getobj());
    this.wh.charxptxt = wh.add.text(0, 0, '--/--', {
      fontFamily: 'ffff',
      fontSize: '8px',
      color: '#ffffff',
      stroke: '#111111',
      strokeThickness: 1,
      resolution: 16
    });
    this.wh.charxptxt.setAlpha(0.8);
    // this.wh.charxptxt.displayWidth=this.wh.charxptxt.width;
    //this.plog.log('this.wh.charxptxt', this.wh.charxptxt);
    this.gifscontainer.add(this.wh.charxptxt);
    this.gifscontainer.bringToTop(this.wh.charxptxt);

    var hpbaroption = {
      x: wh.hpbg.x,
      y: wh.hpbg.y,
      w: wh.hpbg.displayWidth,
      h: 16,//wh.hpbg.displayHeight,
      max: 200,
      me: 10,
      txt: '',
      barcol1: 0x24ff2b,
      barcol2: 0x00d407,
      bgcol: 0x999999,
      imgcol: 'green'
    };
    this.wh.charhpbar = new PGBar(this.wh.scene.scene, hpbaroption, 'img');
    this.gifscontainer.add(this.wh.charhpbar.getobj());
    this.gifscontainer.bringToTop(this.wh.charhpbar.getobj());
    this.wh.charhpbar.getobj().setDepth(500000);
    //this.wh.charhptxt = wh.add.text(0, 0, "--/--", { fontFamily: 'ffff', fontSize: '8px', fill: '#fff', boundsAlignH: "left", boundsAlignV: "left" });
    // this.wh.charhptxt = wh.add.bitmapText(0, 0, 'fffw', '--/--', 14, 2);
    this.wh.charhptxt = wh.add.text(0, 0, '--/--', {
      fontFamily: 'ffff',
      fontSize: '8px',
      color: '#ffffff',
      stroke: '#111111',
      strokeThickness: 1,
      resolution: 16
    });
    this.wh.charhptxt.setAlpha(0.8);
    // this.wh.homeinvensize = wh.add.bitmapText(0, 0, 'fffb', '--/--', 14, 3);
    this.wh.homeinvensize = wh.add.text(0, 0, '--/--', { fontFamily: 'ffff', fontSize: '6px', color: '#fff' });
    //this.wh.homeinvensizestack = wh.add.bitmapText(      0,      0,      'fffb',      '--/--',      14,      3    );
    this.wh.homeinvensizestack = wh.add.text(0, 0, '--/--', { fontFamily: 'ffff', fontSize: '8px', color: '#fff' });
    this.wh.homeinvensizestack.visible = false;
    this.wh.homeinvensize.visible = false;

    this.gifscontainer.add(this.wh.homeinvensize);
    this.gifscontainer.bringToTop(this.wh.homeinvensize);
    this.gifscontainer.add(this.wh.homeinvensizestack);
    this.gifscontainer.bringToTop(this.wh.homeinvensizestack);
    this.wh.charhptxt.setDepth(500000);
    this.gifscontainer.add(this.wh.charhptxt);
    this.gifscontainer.bringToTop(this.wh.charhptxt);

    wh.healicondis = wh.add.sprite(0, 0, "thesq", 'healicondis');
    this.gifscontainer.add(this.wh.healicondis);
    this.gifscontainer.bringToTop(this.wh.healicondis);
    wh.healicon = wh.add.sprite(0, 0, "thesq", 'healicon');
    this.gifscontainer.add(this.wh.healicon);
    this.gifscontainer.bringToTop(this.wh.healicon);
    wh.healicondis.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);
    wh.healicon.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);
    this.wh.healicon.setInteractive();
    this.wh.healicondis.setInteractive();
    this.wh.healicon.on(
      'pointerdown',
      function () {
        if (this.clicktoosoon()) return; this.markclick();
        this.wh.registry.list.execmod.exec('healicon', this.wh);
      },
      this
    );

    this.wh.healicon.on(
      'pointerover',
      function () {
        wh.registry.list.gameitf.gamemenutooltip(
          wh,
          wh.healicon,
          wh.trs("f:gifscontainer:healicon===Heal"),
          'btm'
        );
      },
      this
    );
    wh.healiconi = wh.add.image(0, 0, 'spacer');
    this.gifscontainer.add(this.wh.healiconi);
    this.gifscontainer.bringToTop(this.wh.healiconi);

    wh.jumpbtn = wh.add.image(0, 0, 'jumpbtn');
    this.gifscontainer.add(this.wh.jumpbtn);
    this.gifscontainer.bringToTop(this.wh.jumpbtn);
    wh.dashbtn = wh.add.image(0, 0, 'dashbtn');
    this.gifscontainer.add(this.wh.dashbtn);
    this.gifscontainer.bringToTop(this.wh.dashbtn);
    wh.guardbtn = wh.add.image(0, 0, 'guardbtn');
    this.gifscontainer.add(this.wh.guardbtn);
    this.gifscontainer.bringToTop(this.wh.guardbtn);
    wh.attackbtn = wh.add.image(0, 0, 'attackbtn');
    this.gifscontainer.add(this.wh.attackbtn);
    this.gifscontainer.bringToTop(this.wh.attackbtn);
    this.wh.jumpbtn.setInteractive();
    this.wh.dashbtn.setInteractive(); this.wh.dashbtn.visible = false;
    this.wh.guardbtn.setInteractive();
    this.wh.attackbtn.setInteractive();
    this.wh.dashbtn.on(
      'pointerdown',
      function (pointer, localX, localY, event) {
        if (this.wh.registry.list.rcvarpass.activemap["map"]["activemap"] == "YES") {
          this.wh.chardash(this.wh);
          try {
            event.stopPropagation();
          } catch (e) {
            console.log(e);
          }
          return;
        }
      },
      this
    );

    this.wh.jumpbtn.on(
      'pointerdown',
      function (pointer, localX, localY, event) {
        if (this.wh.registry.list.rcvarpass.activemap["map"]["activemap"] == "YES") {
          this.wh.charjump(this.wh);
          try {
            event.stopPropagation();
          } catch (e) {
            console.log(e);
          }
          return;
        }
      },
      this
    );
    this.wh.guardbtn.on(
      'pointerdown',
      function (pointer, localX, localY, event) {
        if (this.wh.registry.list.rcvarpass.activemap["map"]["activemap"] == "YES") {
          this.wh.charguard(this.wh);
          try {
            event.stopPropagation();
          } catch (e) {
            console.log(e);
          }
          return;
        }
      },
      this
    );
    this.wh.guardbtn.on(
      'pointerup',
      function () {
        if (this.wh.registry.list.rcvarpass.activemap["map"]["activemap"] == "YES") {
          this.wh.charguard_e(this.wh);
          return;
        }
      },
      this
    );
    this.wh.guardbtn.on(
      'pointerupoutside',
      function () {
        if (this.wh.registry.list.rcvarpass.activemap["map"]["activemap"] == "YES") {
          this.wh.charguard_e(this.wh);
          return;
        }
      },
      this
    );
    this.wh.attackbtn.on(
      'pointerdown',
      function (pointer, localX, localY, event) {
        if (this.wh.registry.list.rcvarpass.activemap["map"]["activemap"] == "YES") {
          this.wh.charatk(this.wh);
          try {
            event.stopPropagation();
          } catch (e) {
            console.log(e);
          }
          return;
        }
      },
      this
    );

    //map
    wh.mapname = wh.add.text(
      0,
      0,
      wh.scene.scene.registry.list.rcvarpass.activemap['map']['name'],
      {
        fontFamily: 'ffff',
        fontSize: '12px',
        stroke: '#000',
        strokeThickness: 3,
        align: 'right',
        boundsAlignH: 'center',
      }
    );
    //mapname.width=100;

    //wh.gifscontainer.add(wh.mapname);
    //wh.gifscontainer.bringToTop(wh.mapname);
    wh.gifscontainer.add(wh.mapname);
    wh.gifscontainer.bringToTop(wh.mapname);
    wh.minimap = wh.add.image(
      300,
      300,
      'minimap_' +
      wh.scene.scene.registry.list.rcvarpass.activemap['map']['code']
    );
    wh.minimap.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);
    var mnmsize = 120;
    var mnmmargin = 20;
    wh.minimap.setDepth(50002);
    wh.minimap.setOrigin(0, 0);
    wh.minimap.setPosition(
      width - (mnmsize / 2 + mnmmargin),
      mnmsize / 2 + mnmmargin
    );
    wh.mapname.removeAllListeners();
    wh.mapname.setInteractive();

    //image.scale=10;
    //var minimapclickshape = new Phaser.Geom.Circle(width - mnmmargin, (mnmsize / 2) + mnmmargin, mnmsize / 2);
    /*minimap.setInteractive(minimapclickshape, function (minimapclickshape, x, y, gameObject) {
      this.plog.log("minimap shape hander", shape, x, y);
      if (shape.radius > 0 && x >= shape.left && x <= shape.right && y >= shape.top && y <= shape.bottom) {
        var dx = (shape.x - x) * (shape.x - x);
        var dy = (shape.y - y) * (shape.y - y);
        return (dx + dy) <= (shape.radius * shape.radius);
      } else {
        return false;
      }
    });
    minimap.on('pointerdown', function (pointer, localX, localY, event) {
      this.plog.log("minimap pointerdown", pointer, localX, localY, event, this);
      this.scene.minimapview.visible = true;
    });*/

    wh.shapemnm = wh.add.image(0, 0, "itf-black");

    wh.gifscontainer.add(wh.shapemnm);
    wh.shapemnm.setOrigin(1, 0.5);
    wh.maskmnm = await wh.shapemnm.createBitmapMask();
    //wh.gifscontainer.add(wh.maskmnm);

    wh.shapemnm.visible = false;
    wh.minimap.setMask(wh.maskmnm);
    wh.minimap.setDepth(50003);

    wh.minimapbggui = wh.add.image(
      width / 2,
      height / 2,
      'mapframe'
    );
    wh.minimapbggui_black = wh.add.image(
      width / 2,
      height / 2,
      'itf-black'
    );
    wh.minimapbggui_black.setOrigin(0.5, 0.5);
    wh.minimapbggui_black.name = "minimapbggui_black";
    wh.minimapbggui.removeAllListeners();
    wh.minimapbggui.setInteractive();
    wh.minimapbggui.on(
      'pointerdown',
      function (pointer, localX, localY, event) {
        this.openminimapview(this.wh);
      },
      this
    );

    wh.gifscontainer.add(wh.minimapbggui_black);
    wh.gifscontainer.bringToTop(wh.minimapbggui_black);

    wh.gifscontainer.add(wh.minimap);
    wh.gifscontainer.bringToTop(wh.minimap);

    wh.gifscontainer.add(wh.minimapbggui);
    wh.gifscontainer.bringToTop(wh.minimapbggui);


    wh.minimapbggui.setDepth(50005);


    wh.minimapdot = wh.add.circle(
      width - (mnmsize / 2 + mnmmargin),
      mnmsize / 2 + mnmmargin,
      5
    );

    wh.gifscontainer.add(wh.minimapdot);
    wh.gifscontainer.bringToTop(wh.minimapdot);
    wh.minimapdot.setPosition(
      width - (mnmsize / 2 + mnmmargin),
      mnmsize / 2 + mnmmargin
    );

    wh.minimapdot.setStrokeStyle(4, 0xff0000);
    // r1.setDepth(1000);
    wh.minimapdot.setDepth(50009);

    wh.mapname.on('pointerdown', function (pointer, localX, localY, event) {
      if (wh.minimap.visible == true) {
        this.wh.minimap.visible = false;
        this.wh.minimapdot.visible = false;
        this.wh.minimapbggui.visible = false;
        this.wh.minimap.visible = false;
      } else {
        this.wh.minimap.visible = true;
        this.wh.minimapdot.visible = true;
        this.wh.minimapbggui.visible = true;
        this.wh.minimap.visible = true;
      }

      this.wh.registry.list.resizeman.RCMapResizeManager_work(this.wh);
    }, this);

    wh.gifscontainer.add(wh.clock_bg);
    wh.gifscontainer.bringToTop(wh.clock_bg);
    wh.gifscontainer.add(wh.clock_dot);
    wh.gifscontainer.bringToTop(wh.clock_dot);
    ////////////////////////////////////////////////////////
    //msgs icon
    wh.msgsicon = wh.add.sprite(0, 0, 'thesq', 'message');
    wh.msgsicondis = wh.add.image(0, 0, 'thesq', 'messagedis');
    wh.msgsicon.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);
    wh.msgsicondis.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);
    wh.msgsicon.setInteractive();

    wh.gifscontainer.add(wh.msgsicon);
    wh.gifscontainer.bringToTop(wh.msgsicon);
    wh.msgsicondis.setInteractive();

    wh.gifscontainer.add(wh.msgsicondis);
    wh.gifscontainer.bringToTop(wh.msgsicondis);
    wh.msgsicon.on(
      'pointerdown',
      function () {
        if (this.clicktoosoon()) return; this.markclick();

        wh.gamemenuloading.setPosition(wh.msgsicon.x, wh.msgsicon.y);
        wh.gifscontainer.bringToTop(wh.gamemenuloading);
        wh.gamemenuloading.visible = true;

        wh.registry.list.msgs.show(wh);
        wh.registry.list.snd.snd("btn");

      },
      this
    );
    wh.msgsicondis.on(
      'pointerdown',
      function (pointer, localX, localY, event) {
        if (this.clicktoosoon()) return; this.markclick();
        wh.registry.list.msgs.show(wh);
        wh.registry.list.snd.snd("btn");
        try {
          event.stopPropagation();
        } catch (e) {
          console.log(e);
        }
      },
      this
    );
    wh.msgsicondis.on(
      'pointerover',
      function () {
        wh.registry.list.gameitf.gamemenutooltip(
          wh,
          wh.msgsicondis,
          "Messages",
          'btm'
        );
      },
      this
    );
    wh.msgsicon.on(
      'pointerover',
      function (pointer, localX, localY, event) {
        wh.registry.list.gameitf.gamemenutooltip(
          wh,
          wh.msgsicon,
          wh.trs("f:gifscontainer:Messages===Messages"),
          'btm'
        );
        try {
          event.stopPropagation();
        } catch (e) {
          console.log(e);
        }
      },
      this
    );
    ////////////////////////////////////////////////////////
    //party icon
    wh.partyicon = wh.add.sprite(0, 0, 'thesq', 'party');
    wh.partyicon.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);
    wh.partyicon.setInteractive();

    wh.gifscontainer.add(wh.partyicon);
    wh.gifscontainer.bringToTop(wh.partyicon);
    wh.partyicon.on(
      'pointerdown',
      function (pointer, localX, localY, event) {
        wh.registry.list.partymy.show(wh);
        wh.registry.list.snd.snd("btn");
        try {
          event.stopPropagation();
        } catch (e) {
          console.log(e);
        }
      },
      this
    );
    wh.partyicon.on(
      'pointerover',
      function () {
        wh.registry.list.gameitf.gamemenutooltip(
          wh,
          wh.partyicon,
          wh.trs("f:gifscontainer:Party===Party"),
          'btm'
        );
      },
      this
    );

    ///theatre icon
    wh.theatreicon = wh.add.sprite(0, 0, 'thesq', 'theatre');
    wh.theatreicon.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);
    wh.theatreicon.setInteractive();

    wh.gifscontainer.add(wh.theatreicon);
    wh.gifscontainer.bringToTop(wh.theatreicon);
    wh.theatreicondis = wh.add.sprite(0, 0, 'thesq', 'theatredis');
    wh.theatreicondis.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);
    wh.theatreicondis.setInteractive();

    wh.gifscontainer.add(wh.theatreicon);
    wh.gifscontainer.bringToTop(wh.theatreicon);
    wh.gifscontainer.add(wh.theatreicondis);
    wh.gifscontainer.bringToTop(wh.theatreicondis);
    wh.theatreicon.on(
      'pointerdown',
      function (pointer, localX, localY, event) {

        if (this.clicktoosoon()) return; this.markclick();

        wh.gamemenuloading.setPosition(wh.theatreicon.x, wh.theatreicon.y);
        wh.gifscontainer.bringToTop(wh.gamemenuloading);
        wh.gamemenuloading.visible = true;

        wh.registry.list.execmod.exec("theatre", wh);
        wh.registry.list.snd.snd("btn");
        try {
          event.stopPropagation();
        } catch (e) {
          console.log(e);
        }
      },
      this
    );
    wh.theatreicon.on(
      'pointerover',
      function () {
        wh.registry.list.gameitf.gamemenutooltip(
          wh,
          wh.partyicon,
          wh.trs("f:gifscontainer:Theatre===Theatre"),
          'btm'
        );
      },
      this
    );

    wh.theatreicondis.on(
      'pointerdown',
      function (pointer, localX, localY, event) {

      },
      this
    );
    wh.theatreicondis.on(
      'pointerover',
      function () {
        wh.registry.list.gameitf.gamemenutooltip(
          wh,
          wh.partyicon,
          wh.trs("f:gifscontainer:Theatre===Theatre"),
          'btm'
        );
      },
      this
    );
    ////////////////////////////////////////////////////////
    //backhome icon
    wh.backhomeicon = wh.add.sprite(0, 0, 'thesq', 'backhome');
    wh.backhomeicon.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);
    wh.backhomeicon.setInteractive();

    wh.gifscontainer.add(wh.backhomeicon);
    wh.gifscontainer.bringToTop(wh.backhomeicon);
    wh.backhomeicon.on(
      'pointerdown',
      function (pointer, localX, localY, event) {
        //wh.registry.list.partymy.show(wh);
        if (this.clicktoosoon()) return; this.markclick();
        wh.chkwarppos_warp(wh, "map1");
        wh.registry.list.snd.snd("btn");
        try {
          event.stopPropagation();
        } catch (e) {
          console.log(e);
        }
      },
      this
    );
    wh.backhomeicon.on(
      'pointerover',
      function () {
        wh.registry.list.gameitf.gamemenutooltip(
          wh,
          wh.backhomeicon,
          wh.trs("f:gifscontainer:Back to hometownFree service for player level under 10===Back to hometown nFree service for player level under 10"),
          'left'
        );
      },
      this
    );

    //updateapp icon
    wh.updateapp = wh.add.sprite(0, 0, 'thesq', 'appupdate');
    wh.updateapp.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);
    wh.updateapp.setInteractive();

    wh.gifscontainer.add(wh.updateapp);
    wh.gifscontainer.bringToTop(wh.updateapp);
    wh.updateapp.on(
      'pointerdown',
      function (pointer, localX, localY, event) {
        //wh.registry.list.partymy.show(wh);
        if (this.clicktoosoon()) return; this.markclick();
        wh.registry.list.snd.snd("btn");
        try {
          wh.registry.list.thethis.openAppStore()
        } catch (e) {
          console.log(e);
        }
      },
      this
    );
    wh.updateapp.on(
      'pointerover',
      function () {
        wh.registry.list.gameitf.gamemenutooltip(
          wh,
          wh.updateapp,
          "Click to open the store to update app",
          'left'
        );
      },
      this
    );
    wh.updateapp.visible = false;

    //friend icon
    wh.friendlisticon = wh.add.sprite(0, 0, 'thesq', 'friend');
    wh.friendlisticon.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);
    wh.friendlisticon.setInteractive();
    wh.gifscontainer.add(wh.friendlisticon);
    wh.gifscontainer.bringToTop(wh.friendlisticon);
    wh.friendlisticon.on(
      'pointerdown',
      function (pointer, localX, localY, event) {
        if (this.clicktoosoon()) return; this.markclick();
        wh.registry.list.friendlist.show(wh);
        wh.registry.list.snd.snd("btn");
        try {
          event.stopPropagation();
        } catch (e) {
          console.log(e);
        }
      },
      this
    );

    wh.friendlisticon.on(
      'pointerover',
      function () {
        wh.registry.list.gameitf.gamemenutooltip(
          wh,
          wh.friendlisticon,
          wh.trs("f:gifscontainer:Contact===Contact"),
          'btm'
        );
      },
      this
    );

    //friend icon
    wh.wisdomcaticon = wh.add.sprite(0, 0, 'thesq', 'help');
    wh.wisdomcaticon.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);
    wh.wisdomcaticon.setInteractive();
    wh.gifscontainer.add(wh.wisdomcaticon);
    wh.gifscontainer.bringToTop(wh.wisdomcaticon);
    wh.wisdomcaticon.on(
      'pointerdown',
      function (pointer, localX, localY, event) {
        if (this.clicktoosoon()) return; this.markclick();
        wh.registry.list.execmod.exec("talktonpc:wisdomcat", wh);
        wh.registry.list.snd.snd("btn");
        try {
          event.stopPropagation();
        } catch (e) {
          console.log(e);
        }
      },
      this
    );
    wh.wisdomcaticon.on(
      'pointerover',
      function () {
        wh.registry.list.gameitf.gamemenutooltip(
          wh,
          wh.wisdomcaticon,
          wh.trs("f:gifscontainer:Wisdom Cat===Wisdom Cat"),
          'btmrw'
        );
      },
      this
    );
    //this.gifscontainer.add(this.wh.msgsicon);
    //this.gifscontainer.add(this.wh.msgsicondis);
    //this.gifscontainer.bringToTop(this.wh.msgsicon);
    //this.gifscontainer.bringToTop(this.wh.msgsicondis);

    ////////////////////////////////////////////////////////
    //gamemenu
    var gamemenu = wh.registry.list.rcvarpass.gamemenu;
    //this.plog.log("gamemenu", gamemenu);
    //mainmenu
    var tmpicon = wh.add.sprite(
      width / 2,
      height / 2, "thesq",
      'menu'
    );
    wh.gamemenu_main = tmpicon;
    wh.gamemenu_main.setInteractive();
    wh.gifscontainer.add(wh.gamemenu_main);
    wh.gifscontainer.bringToTop(wh.gamemenu_main);
    wh.gamemenu_main.on(
      'pointerdown',
      function (pointer, localX, localY, event) {
        try {
          if (pointer.downElement.tagName != 'CANVAS') {
            this.plog.log('zxc,gamemenu_main,prevent event');
            return;
          }
        } catch (e) {
          console.log(e);
        }
        for (let key of Object.keys(wh.gamemenu)) {
          let val = wh.gamemenu[key];
          if (wh.gamemenu_showing == true) {
            val.visible = false;
          } else {
            val.visible = true;
          }
          //this.plog.log("xxxx", val.visible);
          //this.plog.log("gamemenuchk", key, val.visible);

        }


        if (wh.gamemenu_showing == true) {
          //if (wh.partyicon != undefined) wh.partyicon.visible = false;
          //if (wh.backhomeicon != undefined) wh.backhomeicon.visible = false;
          //if (wh.msgsicon != undefined) wh.msgsicon.visible = false;
          //if (wh.msgsicondis != undefined) wh.msgsicondis.visible = false;
          //if (wh.friendlisticon != undefined) wh.friendlisticon.visible = false;
          //if (wh.wisdomcaticon != undefined) wh.wisdomcaticon.visible = false;
        } else {
          //if (wh.partyicon != undefined) wh.partyicon.visible = true;
          //if (wh.backhomeicon != undefined) wh.backhomeicon.visible = true;
          /*if (wh.registry.list.rcvarpass != undefined &&
            wh.registry.list.rcvarpass.activemap != undefined &&
            wh.registry.list.rcvarpass.activemap["map"] != undefined) {
            if (wh.registry.list.rcvarpass.activemap["map"]["code"] != "map1" &&
              wh.registry.list.rcvarpass.activemap["map"]["code"] != "home" &&
              wh.registry.list.rcreq.chk({ level_max: 10 })) {
              wh.backhomeicon.visible = true;
            } else {
              wh.backhomeicon.visible = false;
            }
          } else {
            wh.backhomeicon.visible = false;
          }
          if (wh.msgsicon != undefined) wh.msgsicon.visible = true;
          if (wh.msgsicondis != undefined) wh.msgsicondis.visible = true;
          if (wh.registry.list.rcvarpass.activechar['newmsg'] == 1) {
            wh.msgsicon.visible = true;
            wh.msgsicondis.visible = false;
          } else {
            wh.msgsicon.visible = false;
            wh.msgsicondis.visible = true;
          }
          if (wh.friendlisticon != undefined) wh.friendlisticon.visible = true;
          if (wh.wisdomcaticon != undefined) wh.wisdomcaticon.visible = true;
          */
        }

        for (let key of Object.keys(wh.gamemenudis)) {
          let val = wh.gamemenudis[key];
          if (wh.gamemenu_showing == true) {
            val.visible = false;
          } else {
            val.visible = true;
          }
          //this.plog.log("xxxx", val.visible);
          //this.plog.log("gamemenuchk dis", key, val.visible);
        }
        if (wh.gamemenu_showing == true) {
          wh.gamemenu_showing = false;
        } else {
          wh.gamemenu_showing = true;
        }
        //return;
        wh.registry.list.resizeman.RCMapResizeManager_work(wh);
        wh.registry.list.gameitf.updatereddots(wh);
        wh.registry.list.snd.snd("btn");
        //this.plog.log("gamemenuchk", wh.gamemenu_showing)
      },
      this
    );
    for (let key of Object.keys(gamemenu)) {
      let val = gamemenu[key];
      //this.plog.log("gamemenu", gamemenu);
      if (val['pos'] == 3) continue;
      var tmpicon = wh.add.sprite(
        width / 2,
        height / 2,
        'gamemenu',
        val.code
      );
      //var tmpicon = wh.add.image(wh.cameras.main.width / 2, wh.cameras.main.height / 2, 'icon_' + val.code);

      tmpicon.name = val.code + '-' + val.pos;
      tmpicon.setInteractive();
      wh.gifscontainer.add(tmpicon);
      wh.gifscontainer.bringToTop(tmpicon);
      tmpicon.on('pointerover', function () {
        if (arguments[0] != undefined && arguments[0].downElement != undefined && arguments[0].downElement.tagName != 'CANVAS') {
          console.log('zxc ,prevent event', arguments);
          return;
        }
        //this.plog.log("mainmenu over", arguments, this);
        //this.gamemenutooltip();
        var tmpvalnamea = this.name.split('-');
        var tooltiptext = wh.trs("t:gamemenu:code:name:" + this.scene.registry.list.rcvarpass.gamemenu[tmpvalnamea[0]].code + "===" +
          this.scene.registry.list.rcvarpass.gamemenu[tmpvalnamea[0]].name);
        //this.plog.log("mainmenu over", tooltiptext);
        var width = wh.game.canvas.width;
        var height = wh.game.canvas.height;
        //this.plog.log("xxxx",tmpvalnamea);
        if (width > height) {
          if (Number(tmpvalnamea[1]) == 1) {
            this.scene.registry.list.gameitf.gamemenutooltip(
              this.scene,
              this,
              tooltiptext,
              'top'
            );
          } else {
            this.scene.registry.list.gameitf.gamemenutooltip(
              this.scene,
              this,
              tooltiptext,
              'right'
            );
          }
        } else {
          if (Number(tmpvalnamea[1]) == 1) {
            this.scene.registry.list.gameitf.gamemenutooltip(
              this.scene,
              this,
              tooltiptext,
              'right'
            );
          } else {
            this.scene.registry.list.gameitf.gamemenutooltip(
              this.scene,
              this,
              tooltiptext,
              'top'
            );
          }
        }
      });
      tmpicon.on('pointerdown', function (pointer, localX, localY, event) {
        if (wh.registry.list.gameitf.clicktoosoon()) return; wh.registry.list.gameitf.markclick();
        wh.registry.list.snd.snd("btn");
        try {
          if (arguments[0].downElement.tagName != 'CANVAS') {
            console.log('zxc ,down,prevent event', arguments);
            return;
          }

        } catch (e) {
          console.log(e);
        }
        //this.plog.log("mainmenu pointerdown", arguments, this);
        var tmpvalnamea = this.name.split('-');
        this.scene.registry.list.thethis.execmod.exec(
          'gamemenu:' + tmpvalnamea[0],
          this.scene
        );
        try {
          event.stopPropagation();
        } catch (e) {
          console.log(e);
        }
      });
      wh.gamemenu[wh.gamemenu.length] = tmpicon;
      // var tmpicondis = wh.add.image(wh.cameras.main.width / 2, wh.cameras.main.height / 2, 'icon_' + val.code + "_dis");
      var tmpicondis = wh.add.image(
        width / 2,
        height / 2,
        'gamemenug',
        val.code
      );
      tmpicondis.name = val.code + '-' + val.pos;
      wh.gamemenudis[wh.gamemenudis.length] = tmpicondis;
      tmpicondis.setInteractive();

      wh.gifscontainer.add(tmpicondis);
      wh.gifscontainer.bringToTop(tmpicondis);

      tmpicondis.on('pointerdown', function (pointer, localX, localY, event) {
        try {
          event.stopPropagation();
        } catch (e) {
          console.log(e);
        }
      });
      tmpicondis.on('pointerover', function () {
        //this.plog.log("mainmenu over", arguments, this);
        //this.gamemenutooltip();
        var tmpvalnamea = this.name.split('-');

        var tooltiptext =
          this.scene.registry.list.rcvarpass.gamemenu[tmpvalnamea[0]].name;
        //this.plog.log("mainmenu over", tooltiptext);
        var width = wh.game.canvas.width;
        var height = wh.game.canvas.height;

        if (width > height) {
          if (Number(tmpvalnamea[1]) == 1) {
            this.scene.registry.list.gameitf.gamemenutooltip(
              this.scene,
              this,
              tooltiptext,
              'top'
            );
          } else {
            this.scene.registry.list.gameitf.gamemenutooltip(
              this.scene,
              this,
              tooltiptext,
              'right'
            );
          }
        } else {
          if (Number(tmpvalnamea[1]) == 1) {
            this.scene.registry.list.gameitf.gamemenutooltip(
              this.scene,
              this,
              tooltiptext,
              'right'
            );
          } else {
            this.scene.registry.list.gameitf.gamemenutooltip(
              this.scene,
              this,
              tooltiptext,
              'top'
            );
          }
        }
      });
    }

    //////

    wh.iconmainsetting = wh.add.sprite(0, 0, "thesq", "settingdark");
    wh.iconmainsetting.removeAllListeners();
    wh.iconmainsetting.setInteractive();
    wh.gifscontainer.add(wh.iconmainsetting);
    wh.gifscontainer.bringToTop(wh.iconmainsetting);
    wh.iconmainsetting.on("pointerdown", function () {
      if (this.wh.settingiconactivetime > 1) {
        this.hidesettings();
      } else {
        this.showsettings(wh);
      }
    }, this);
    wh.iconchatbubble = wh.add.sprite(0, 0, "thesq", "iconchat");
    wh.iconchatbubble.removeAllListeners();
    wh.iconchatbubble.setInteractive();
    wh.gifscontainer.add(wh.iconchatbubble);
    wh.gifscontainer.bringToTop(wh.iconchatbubble);
    wh.iconchatbubble.on("pointerdown", function () {
      this.showchatinput(wh);
    }, this);

    wh.iconchatbubble.on(
      'pointerover',
      function () {
        wh.registry.list.gameitf.gamemenutooltip(
          wh,
          wh.iconchatbubble,
          this.wh.trs("f:gifscontainer:iconchatbubble===iconchatbubble"),
          'left'
        );
      },
      this
    );
    wh.iconbugreport = wh.add.sprite(0, 0, "thesq", "iconbugreport");
    wh.iconbugreport.removeAllListeners();
    wh.iconbugreport.setInteractive();
    wh.gifscontainer.add(wh.iconbugreport);
    wh.gifscontainer.bringToTop(wh.iconbugreport);
    wh.iconbugreport.on("pointerdown", function () {
      this.bugreport(wh);
    }, this);

    wh.iconbugreport.on(
      'pointerover',
      function () {
        wh.registry.list.gameitf.gamemenutooltip(
          wh,
          wh.iconbugreport,
          this.wh.trs("f:gifscontainer:iconbugreport===iconbugreport"),
          'left'
        );
      },
      this
    );
    wh.iconsettings = wh.add.sprite(0, 0, "thesq", "iconsettings");
    wh.iconsettings.removeAllListeners();
    wh.iconsettings.setInteractive();
    wh.gifscontainer.add(wh.iconsettings);
    wh.gifscontainer.bringToTop(wh.iconsettings);
    wh.whref = wh;
    wh.iconsettings.on("pointerdown", async function () {
      //this.plog.log("iconsettings", wh);
      //wh.registry.list.phaserGame.scene.scenes[1]
      //wh.sys.game.destroy( true);
      /// wh.registry.destroy(); // destroy registry
      ///wh.events.off();  // disable all active events
      /// wh.scene.restart();  // restart current scene
      ////wh.scene.resume('Welcome');
      ////wh.scene.pause();
      //this.registry.list.phaserGame.scene.scenes[0]
      //this.scene.start('Welcome');
      //wh.registry.list.phaserGame.scene.scenes[1].isgameplayed = "no";
      var cfm = await wh.registry.list.rctoast.rcconfirm(wh, "Go to main menu", "Go to main menu to select another character or use setting screen?");
      if (cfm == "ok") {
        wh.registry.list.gameitf.hidechatinput(wh);

        wh.input.keyboard.enabled = false; //complete disable input capturing
        wh.registry.list.phaserGame.scene.start('Welcome');
      }

    }, wh);

    wh.iconsettings.on(
      'pointerover',
      function () {
        wh.registry.list.gameitf.gamemenutooltip(
          wh,
          wh.iconsettings,
          this.wh.trs("f:gifscontainer:iconsettings===iconsettings"),
          'left'
        );
      },
      this
    );
    wh.iconmusic = wh.add.sprite(0, 0, "thesq", "music");
    wh.iconmusic.removeAllListeners();
    wh.iconmusic.setInteractive();
    wh.gifscontainer.add(wh.iconmusic);
    wh.gifscontainer.bringToTop(wh.iconmusic);
    wh.iconmusic.on("pointerdown", async function () {
      //this.plog.log("iconmusic", wh.isofftmute);
      // wh.registry.list.phaserGame.scene.start('Welcome');
      //
      if (wh.isofftmute == true) {
        wh.isofftmute = false;
        var useval = await this.registry.list.rcstorage.get('setting_volume');
        wh.registry.list.phaserGame.scene.scenes[0].sound.setVolume(useval / 10);
      } else {
        wh.isofftmute = true;
        wh.registry.list.phaserGame.scene.scenes[0].sound.setVolume(0);
      }
      wh.mapresizeobj.RCMapResizeManager_work(wh);
    }, wh);


    wh.iconmusic.on(
      'pointerover',
      function () {
        wh.registry.list.gameitf.gamemenutooltip(
          wh,
          wh.iconmusic,
          this.wh.trs("f:gifscontainer:iconmusic===iconmusic"),
          'left'
        );
      },
      this
    );
    wh.iconfullscreen = wh.add.sprite(0, 0, "thesq", "music");
    wh.iconfullscreen.removeAllListeners();
    wh.iconfullscreen.setInteractive();
    wh.iconfullscreen.setDepth(100005);
    wh.gifscontainer.add(wh.iconfullscreen);
    wh.gifscontainer.bringToTop(wh.iconfullscreen);
    wh.iconfullscreen.on("pointerdown", async function () {
      //this.plog.log("iconfullscreen");
      // wh.registry.list.phaserGame.scene.start('Welcome');

      if (this.scale.isFullscreen) {
        wh.iconfullscreen.setTexture("thesq", "fullscreen");
        this.scale.stopFullscreen();
      }
      else {
        wh.iconfullscreen.setTexture("thesq", "fullscreenexit");
        this.scale.startFullscreen();
      }

      wh.mapresizeobj.RCMapResizeManager_work(wh);
    }, wh);

    wh.iconfullscreen.on(
      'pointerover',
      function () {
        wh.registry.list.gameitf.gamemenutooltip(
          wh,
          wh.iconfullscreen,
          this.wh.trs("f:gifscontainer:iconfullscreen===iconfullscreen"),
          'left'
        );
      },
      this
    );
    wh.offline_bg = wh.add.sprite("color", "black");
    wh.offline_bg.setInteractive();
    wh.offline_msg = wh.add.text(0, 0, " ", wh.fs["pageheader2"]);
    wh.offline_retry = wh.add.sprite(0, 0, "theb", "reconnect");
    wh.offline_header = wh.add.text(0, 0, " Offline ", wh.fs["pageheader"]);
    wh.offline_bg.visible = false;
    wh.offline_msg.visible = false;
    wh.offline_retry.visible = false;
    wh.offline_header.visible = false;


    /////////////////////////////////////////////////////////

    wh.itftl = wh.add.image(0, 0, "itftl");
    wh.gifscontainer.add(wh.itftl);
    wh.gifscontainer.sendToBack(wh.itftl);
    wh.itftl.displayHeight = 72;
    wh.itftl.scaleX = wh.itftl.scaleY;
    wh.itftl.setOrigin(0, 0);
    this.wh.registry.list.resizeman.RCMapResizeManager_work(this.wh);

    wh.itftr1 = wh.add.image(0, 0, "itftr1");
    wh.gifscontainer.add(wh.itftr1);
    wh.gifscontainer.sendToBack(wh.itftr1);
    wh.itftr1.displayHeight = 72;
    wh.itftr1.scaleX = wh.itftr1.scaleY;
    wh.itftr1.setOrigin(1, 0);

    wh.itftr2 = wh.add.image(0, 0, "itftr1");
    wh.gifscontainer.add(wh.itftr2);
    wh.gifscontainer.sendToBack(wh.itftr2);
    wh.itftr2.displayHeight = 144;
    wh.itftr2.scaleX = wh.itftr1.scaleY;
    wh.itftr2.setOrigin(0, 0);


    this.wh.registry.list.resizeman.RCMapResizeManager_work(this.wh);

    //console.error(wh);

    /////////////////////////////////////////////////////////
    this.updateminimap(wh);
    wh.registry.list.resizeman.RCMapResizeManager_work(wh);

    /*
    var tmp=wh.add.image(0,0,"testlightts");
    tmp.setOrigin(0,0);
    tmp.setPosition(0,0);
    tmp.displayWidth=width;
    tmp.displayHeight=height;
    //tmp.setBlendMode(Phaser.BlendModes.ADD);
    tmp.setBlendMode(Phaser.BlendModes.LIGHTEN);
    //tmp.setBlendMode(Phaser.BlendModes.HARD_LIGHT);
    //tmp.setBlendMode(Phaser.BlendModes.LUMINOSITY);//1
    //tmp.setBlendMode(Phaser.BlendModes.MULTIPLY);
    //tmp.setBlendMode(Phaser.BlendModes.EXCLUSION);
    tmp.setAlpha(0.7);
    var tw = wh.tweens.add(
      {
        targets: tmp,
        alpha: 0.5,
        ease: 'Power3',
        duration: 1000, // duration of animation; higher=slower
        delay: 500,      // wait 500 ms before starting
        loop: -1,
        yoyo: true
      }
    );*/
  }
  offline(wh) {
    wh.offline_bg.visible = true;
    wh.offline_msg.visible = true;
    wh.offline_retry.visible = true;
    wh.offline_header.visible = true;
    wh.registry.list.resizeman.RCMapResizeManager_work(wh);
  }

  async showrelogin(wh) {
    this.plog.log("showloginscreen", wh, this);
    wh.offline_msg.setText();
    await wh.registry.list.phaserGame.scene.scenes[0].scene.stop();
    wh.registry.list.phaserGame.scene.scenes[1].scene.start("Welcome");
  }
  openminimapview(wh) {
    var width = wh.game.canvas.width;
    var height = wh.game.canvas.height;
    if (wh.minimapviewbg != undefined) {
      wh.minimapviewbg.destroy();//true
      wh.minimapviewbg = undefined;
    }
    if (wh.minimapview != undefined) {
      wh.minimapview.destroy();//true
      wh.minimapview = undefined;
    }

    wh.minimapviewbg = wh.add.rectangle(0, 0, width, height, 0x000000);
    //wh.minimapviewbg.setAlpha(0.8);
    wh.minimapviewbg.setPosition(0, 0);
    wh.minimapviewbg.setOrigin(0, 0);
    wh.minimapviewbg.setAlpha(0.85);
    //wh.minimapviewbg.visible = false;
    //npcbg.setDepth(70000);
    wh.minimapviewbg.setInteractive();

    /*      wh.minimap.setTexture('minimap_' + wh.scene.scene.registry.list.rcvarpass.activemap["map"]["code"]);
      wh.minimapview.setTexture('minimap_' + wh.scene.scene.registry.list.rcvarpass.activemap["map"]["code"]);
      let scaleX = (wh.cameras.main.width - 50) / wh.minimapview.width;
      let scaleY = (wh.cameras.main.height - 200) / wh.minimapview.height;
      let scale = Math.min(scaleX, scaleY);
      //this.plog.log("minimapview scale", scale);
      wh.minimapview.setDepth(60100);
      wh.minimapviewbg.setDepth(60099);
      wh.minimapview.setScale(scale).setScrollFactor(0);*/

    wh.minimapview = wh.add.image(
      width / 2,
      height / 2,
      'minimap_' +
      wh.scene.scene.registry.list.rcvarpass.activemap['map']['code']
    );
    wh.minimapview.setAlpha(1);
    wh.minimapview.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);
    var marginmap = 100;
    if (width < 600) marginmap = 50;
    let scaleX = (width - marginmap) / wh.minimapview.width;
    let scaleY = (height - marginmap) / wh.minimapview.height;
    let scale = Math.min(scaleX, scaleY);
    //this.plog.log("minimapview scale", scale);
    wh.minimapview.setDepth(100101);
    wh.minimapviewbg.setDepth(100100);
    wh.minimapview.setScale(scale); //.setScrollFactor(0);

    wh.minimapview.setScale(5);
    var tw = wh.tweens.add({
      targets: [wh.minimapview],
      scale: scale,
      ease: 'Power3',
      duration: 400, // duration of animation; higher=slower
      delay: 0, // wait 500 ms before starting
      onComplete: function () {
        //arguments[1][0].visible = false;
        //arguments[1][1].visible = false;
      },
    });
    wh.minimapview.removeAllListeners();
    wh.minimapview.off();
    wh.minimapview.setInteractive();
    wh.minimapview.on('pointerdown', function (pointer, localX, localY, event) {
      //this.scene.registry.list["thethis"].phm.floorclick(this);
      //this.plog.log("minimapview pointerdown", pointer, localX, localY, event, this);
      var clickcellx = Math.floor(localX / 10) + 1; //divide by minimap cellsize
      var clickcelly = Math.floor(localY / 10) + 1; //divide by minimap cellsize
      var walkablechk =
        this.scene.registry.list.rcvarpass.activemap['walkable'][
        clickcellx + '-' + clickcelly
        ];
      //this.plog.log("minimapview clickx",clickcellx,clickcelly,walkablechk);
      if (walkablechk == 'yn_y') {
        //this.plog.log("minimapview walk", clickcellx, clickcelly, walkablechk, this.scene);
        var tmpmock = { name: 'bg-' + clickcellx + '-' + clickcelly };
        this.scene.registry.list.phm.floorclick(tmpmock);
      }

      var tw = wh.tweens.add({
        targets: [this, this.scene.minimapviewbg],
        alpha: 0,
        ease: 'Power3',
        duration: 400, // duration of animation; higher=slower
        delay: 150, // wait 500 ms before starting
        onComplete: function () {
          arguments[1][0].visible = false;
          arguments[1][1].visible = false;
        }, // set context? how?
      });
      //this.visible = false;
      //this.scene.minimapviewbg.visible = false;
      //     this.plog.log("minimapviewclick",this);;
    });
    wh.minimapview.on('pointermove', function (pointer, localX, localY, event) {
      var clickcellx = Math.floor(localX / 10); //divide by minimap cellsize
      var clickcelly = Math.floor(localY / 10); //divide by minimap cellsize
      var mchkx = clickcellx + 1;
      var mchky = clickcelly + 1;
      var walkablechk =
        this.scene.registry.list.rcvarpass.activemap['walkable'][
        mchkx + '-' + mchky
        ];
      //this.plog.log('minimapview move', clickcellx, clickcelly, walkablechk);
      if (walkablechk == 'yn_y') {
        this.scene.sys.canvas.style.cursor = 'pointer';
      } else {
        this.scene.sys.canvas.style.cursor = '';
      }
    });
    wh.minimapview.on('pointerout', function (pointer, localX, localY, event) {
      this.scene.sys.canvas.style.cursor = '';
    });
    //wh.minimapview.visible = false;
    wh.minimapviewbg.on(
      'pointerdown',
      function () {
        wh.minimapview.visible = false;
        wh.minimapviewbg.visible = false;
      },
      this
    );
    wh.camman(wh);
  }
  createdialogbg(wh, x, y, w, h, container) {
    //this.plog.log("createdialogbg()", wh, x, y, w, h);
    var d_top = wh.add.image(300, 300, 'dialogtop');
    d_top.setOrigin(0, 0);
    d_top.setPosition(x, y);
    d_top.displayWidth = w;
    d_top.setDepth(3);

    var d_btm = wh.add.image(300, 300, 'dialogbtm');
    d_btm.setOrigin(0, 1);
    d_btm.setPosition(x, y + h);
    d_btm.displayWidth = w;
    d_btm.setDepth(2);

    var d_body = wh.add.image(300, 300, 'dialogbody');
    d_body.setOrigin(0, 0);
    d_body.setPosition(x, y + d_top.displayHeight);
    d_body.displayWidth = w;
    d_body.displayHeight = h - (d_top.displayHeight + d_btm.displayHeight);
    d_body.setDepth(1);

    container.add(d_top);
    container.add(d_body);
    container.add(d_btm);

    return [d_top, d_body, d_btm];
  }
  updateminimap(wh) {
    //this.plog.log("updateminimap()", wh);

    var width = wh.game.canvas.width;
    var height = wh.game.canvas.height;

    var mnmsize = 120;
    var mnmmargin = 20;
    if (wh.char_main != undefined) {
      //this.plog.log("updateminimap() minimap", wh);

      var mnmx = wh.char_main.currentx * 10;
      var mnmy = wh.char_main.currenty * 10;
      mnmx = mnmx + 10;
      var mnmbasex = width - mnmsize / 2 - 1;
      var mnmbasey = mnmmargin + mnmsize / 2 + 4;
      wh.minimap.x = mnmbasex - mnmx;
      wh.minimap.y = mnmbasey - mnmy;
      //wh.maskmnm.x = mnmbasex - (mnmx+mnmmargin+200);
      // wh.maskmnm.y = mnmbasey - (mnmy+mnmmargin+200);

      wh.minimap.setTexture(
        'minimap_' +
        wh.scene.scene.registry.list.rcvarpass.activemap['map']['code']
      );
      //wh.minimapview.setTexture('minimap_' + wh.scene.scene.registry.list.rcvarpass.activemap["map"]["code"]);
      //let scaleX = (wh.cameras.main.width - 50) / wh.minimapview.width;
      //let scaleY = (wh.cameras.main.height - 200) / wh.minimapview.height;
      //let scale = Math.min(scaleX, scaleY);
      //this.plog.log("minimapview scale", scale);
      //wh.minimapview.setDepth(60100);
      //wh.minimapviewbg.setDepth(60099);
      //wh.minimapview.setScale(scale).setScrollFactor(0);
      //this.plog.log("set map to",'minimap_'+wh.scene.scene.registry.list.rcvarpass.activemap["map"]["code"]);
    }
  }

  gamemenutooltipobj_text;
  gamemenutooltipobj_bg;
  gamemenutooltip(wh, tg, txt, pos, maxwidth = 0, adddelay = 0) {
    //this.plog.log("gamemenutooltip", wh, tg, txt, pos);
    //this.plog.log("gamemenutooltip", wh, txt);
    //wh.visible=false;
    if (tg == undefined) return;

    txt = wh.gl(txt);
    if (this.gamemenutooltipobj_text != undefined) {
      //this.plog.log('gamemenutooltipobj_text', this.gamemenutooltipobj_text);
      this.gamemenutooltipobj_text.visible = false;
    }
    if (this.gamemenutooltipobj_bg != undefined) {
      //this.plog.log('gamemenutooltipobj_bg', this.gamemenutooltipobj_bg);
      this.gamemenutooltipobj_bg.visible = false;
    }
    //var tmp=wh.add.text();
    var tmp = wh.add.text(0, 0, txt, {
      fontFamily: 'ffff,uuuu',
      fontSize: '10px',
      color: '#ffffff',
      align: 'center',
      resolution: 12
    });
    if (maxwidth != 0) {
      tmp.setWordWrapWidth(maxwidth);
    }
    //this.plog.log("gamemenutooltip", tg);
    //this.plog.log("gamemenutooltip", typeof tg.parentContainer);
    var plusx = 0;
    var plusy = 0;

    tmp.setStroke(0x000000, 4);
    if (pos == 'btm' || pos == 'bottom') {
      tmp.setPosition(
        tg.x - tmp.displayWidth / 2 + plusx,
        tg.y + tg.displayHeight + plusy
      );
    }
    if (pos == 'btmrw') { //right wall adjust
      tmp.setOrigin(1, 0.5);
      tmp.setPosition(
        tg.x + (tg.displayWidth / 2) - 5,
        tg.y + tg.displayHeight + plusy
      );
    }
    if (pos == 'btmlw') { //right wall adjust
      tmp.setOrigin(0, 0.5);
      tmp.setPosition(
        tg.x - (tg.displayWidth / 2) - 5,
        tg.y + tg.displayHeight + plusy
      );
    }
    if (pos == 'top') {
      tmp.setPosition(
        tg.x - tmp.displayWidth / 2 + plusx,
        tg.y - (tmp.height + tg.displayHeight / 2) + plusy
      );
    }
    if (pos == 'right') {
      tmp.setPosition(
        tg.x + tg.displayWidth / 2 + plusx,
        tg.y - tmp.displayHeight / 2 + plusy
      );
    }
    if (pos == 'left') {
      tmp.setPosition(
        tg.x - (tg.displayWidth / 2 + tmp.displayWidth) + plusx,
        tg.y - tmp.displayHeight / 2 + plusy
      );
    }
    tmp.setShadow(0, 0, '#000000', 0, true, true);
    tmp.setDepth(600001);
    //this.plog.log("gamemenutooltip txt", tmp);
    tmp.name = "tooltiptext";
    this.gamemenutooltipobj_text = tmp;
    //addbg
    //var graphics = wh.add.graphics();
    var graphics = wh.add.image(0, 0, "thecolor", "black");
    graphics.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);
    graphics.name = "tooltipbg";

    //graphics.fillStyle(0x000000, 1);
    //  32px radius on the corners
    /*graphics.fillRoundedRect(
      tmp.x - 2,
      tmp.y + 3,
      tmp.displayWidth + 4,
      tmp.displayHeight - 6,
      3
    );*/
    graphics.displayWidth = tmp.displayWidth + 4;
    graphics.displayHeight = tmp.displayHeight - 6;
    graphics.setOrigin(tmp.originX, tmp.originY);
    //console.log(tmp,tg);
    graphics.setPosition(tmp.x - 2, tmp.y + 3);
    //  Using an object to define a different radius per corner
    graphics.setDepth(600000);

    this.gamemenutooltipobj_bg = graphics;

    if (
      tg != undefined &&
      tg.parentContainer != undefined &&
      tg.parentContainer != null
    ) {
      //plusx = tg.parentContainer.x;
      //plusy = tg.parentContainer.y;
      tg.parentContainer.add(graphics);
      tg.parentContainer.bringToTop(graphics);
      tg.parentContainer.add(tmp);
      tg.parentContainer.bringToTop(tmp);
    }
    var tw = wh.tweens.add({
      targets: [tmp, graphics],
      alpha: 0,
      ease: 'Power3',
      duration: 300, // duration of animation; higher=slower
      delay: 1650 + adddelay, // wait 500 ms before starting
      onComplete: function () {
        tmp.destroy();
        graphics.destroy();
      }, // set context? how?
    });
  }
  charinfoupdated_showlvuphinttime = 0;
  charinfoupdated(wh) {
    this.plog.warn('charinfoupdated', wh);
    try {
      wh.registry.list.phm.initmap_preloaddone_sortlayer();
    } catch (e) {
      console.log(e);
    }
    if (
      wh.inventorycontainer_stackitem != undefined &&
      wh.inventorycontainer_stackitem.visible == true
    ) {
      //this.plog.error("wh.inventorycontainer_stackitem", wh.inventorycontainer_stackitem);
      var shopdata = wh.registry.list.rcvarpass.activechar;
      //console.log("charinfoupdated stack",wh );
      if (wh.registry.list.phm.initmapinprocess != true) {
        wh.registry.list.stackinven.showinventory(
          wh,
          shopdata,
          wh.registry.list.stackinven.invenmode + ""
          //'retainpage'
        );
      }
    }
    if (
      wh.inventorycontainer_item != undefined &&
      wh.inventorycontainer_item.visible == true
    ) {
      var shopdata = wh.registry.list.rcvarpass.activechar;
      wh.registry.list.inven.showinventory_loaded(wh, shopdata, 'retainpage');
    }
    if (
      wh.equipmentcontainer != undefined &&
      wh.equipmentcontainer.visible == true
    ) {
      var shopdata = wh.registry.list.rcvarpass.activechar;
      wh.registry.list.equipment.show_loaded(wh, shopdata);
    }
    if (
      wh.skillcraftcontainer != undefined &&
      wh.skillcraftcontainer.visible == true
    ) {
      var shopdata = wh.registry.list.rcvarpass.activechar;
      wh.registry.list.skillcraft.show_loaded(wh, "", shopdata);
    }
    if (
      wh.homeinventorycontainer_item != undefined &&
      wh.homeinventorycontainer_item.visible == true
    ) {
      var shopdata = wh.registry.list.rcvarpass.activechar;
      wh.registry.list.homeinveni.showinventory_loaded(wh, shopdata);
    }
    if (
      wh.homeinventorycontainer_stackitem != undefined &&
      wh.homeinventorycontainer_stackitem.visible == true
    ) {
      var shopdata = wh.registry.list.rcvarpass.activechar;
      wh.registry.list.homeinvens.showinventory_loaded(wh, shopdata);
    }
    if (wh.attricontainer != undefined && wh.attricontainer.visible == true) {
      wh.registry.list.attri.show_loaded(wh);
    }
    if (wh.petguicontainer != undefined && wh.petguicontainer.visible == true) {
      wh.registry.list.petgui.buildpetctnr();
    }
    if (
      wh.pettravelcontainer != undefined &&
      wh.pettravelcontainer.visible == true
    ) {
      // wh.registry.list.pettravel.buildpetctnr();
      wh.registry.list.pettravel.buildpetctnr("retainpage");//showinventory_loaded(wh, 'retainpage');
    }
    if (
      wh.petskillcontainer != undefined &&
      wh.petskillcontainer.visible == true &&
      wh.registry.list.petskill.petid != undefined
    ) {
      // wh.registry.list.pettravel.buildpetctnr();
      wh.registry.list.petskill.show_loaded(wh, wh.registry.list.petskill.petid, "retainpage");//showinventory_loaded(wh, 'retainpage');
    }
    if (wh.backhomeicon != undefined) {
      //this.plog.log("checking backhomeicon", wh.backhomeicon, wh.registry.list.rcreq.chk({ level_max: 10 }))
      if (wh.registry.list.rcvarpass != undefined &&
        wh.registry.list.rcvarpass.activemap != undefined &&
        wh.registry.list.rcvarpass.activemap["map"] != undefined) {
        if (wh.registry.list.rcvarpass.activemap["map"]["code"] != "map1" &&
          wh.registry.list.rcvarpass.activemap["map"]["code"] != "home" &&
          wh.registry.list.rcreq.chk({ level_max: 10 })) {
          wh.backhomeicon.visible = true;
        } else {
          wh.backhomeicon.visible = false;
        }
      } else {
        wh.backhomeicon.visible = false;
      }
    }
    if (wh.theatreicon != undefined && wh.theatreicondis != undefined) {
      if (wh.registry.list.rcvarpass != undefined &&
        wh.registry.list.rcvarpass.activemap != undefined &&
        wh.registry.list.rcvarpass.activemap["map"] != undefined &&
        wh.registry.list.rcvarpass.activemap["map"]["theatre"] != undefined &&
        wh.registry.list.rcvarpass.activemap["map"]["theatre"] != 0
      ) {
        wh.theatreicon.visible = true;
        wh.theatreicondis.visible = false;
      } else {
        wh.theatreicon.visible = false;
        wh.theatreicondis.visible = true;
      }
    }
    if (
      wh.petuplevelcontainer != undefined &&
      wh.petuplevelcontainer.visible == true
    ) {
      wh.registry.list.petuplevel.buildpetctnr();
    }
    if (
      wh.upgradeitemcontainer != undefined &&
      wh.upgradeitemcontainer.visible == true
    ) {
      wh.registry.list.upgradeitem.show_loaded(wh);
    }
    if (
      wh.upgraderarcontainer != undefined &&
      wh.upgraderarcontainer.visible == true
    ) {
      wh.registry.list.upgraderar.show_loaded(wh);
    }
    if (
      wh.partymycontainer != undefined &&
      wh.partymycontainer.visible == true
    ) {
      wh.registry.list.partymy.show_loaded(wh);
    }
    if (wh.skillcontainer != undefined && wh.skillcontainer.visible == true) {
      //      wh.registry.list.rcskill.show(wh); 
      wh.registry.list.rcskill.show_loaded(wh, wh.registry.list.rcskill.currentmode, wh.registry.list.rcskill.currentsubid, "retainpage");
    }
    if (
      wh.questinfocontainer != undefined &&
      wh.questinfocontainer.visible == true
    ) {
      wh.registry.list.questinfo.show_loaded(wh);
    }
    if (
      wh.questlistcontainer != undefined &&
      wh.questlistcontainer.visible == true
    ) {
      //wh.registry.list.questlist.show_loaded();

      if (
        wh.questlistcontainer_daily != undefined &&
        wh.questlistcontainer_daily.visible == true
      ) {
        wh.registry.list.questlist.daily_loaded(true);
        this.plog.log('gameitf charinfoupdated showing q daily');
      } else if (
        wh.questlistcontainer_weekly != undefined &&
        wh.questlistcontainer_weekly.visible == true
      ) {
        wh.registry.list.questlist.weekly_loaded(true);
        this.plog.log('gameitf charinfoupdated showing q weekly');
      } else {
        wh.registry.list.questlist.buildquestctnr(true);
        this.plog.log('gameitf charinfoupdated showing q buildquestctnr');
      }
    }
    if (wh.msgsicon != undefined && wh.gamemenu_showing == true) {
      //this.plog.log('charinfoupdate,msgsicon', wh.registry.list.rcvarpass.activechar['newmsg']);
      if (wh.registry.list.rcvarpass.activechar['newmsg'] == 1) {
        wh.msgsicon.visible = true;
        wh.msgsicondis.visible = false;
        this.wiggle(wh, wh.msgsicon);
      } else {
        wh.msgsicon.visible = false;
        wh.msgsicondis.visible = true;
      }
    }
    if (wh.healicon != undefined) {
      if (
        wh.registry.list.rcvarpass.activechar['hp'] ==
        wh.registry.list.rcvarpass.activechar['maxhp']
      ) {
        wh.healicon.visible = false;
        wh.healicondis.visible = true;
      } else {
        wh.healicon.visible = true;
        wh.healicondis.visible = false;
      }
      if (wh.registry.list.rcreq.chk({ activemap: 1 })) {
        wh.healicon.visible = false;
        wh.healiconi.visible = false;
        wh.healicondis.visible = false;
      }
      //healitem
      var healitems = [];
      if (
        wh.registry.list.rcvarpass.activechar != undefined &&
        wh.registry.list.rcvarpass.activechar['stackitem'] != undefined
      ) {
        for (const k in wh.registry.list.rcvarpass.activechar['stackitem']) {
          var v = wh.registry.list.rcvarpass.activechar['stackitem'][k];
          var itemdata = wh.registry.list.rcvarpass.allstackitem[v['item']];
          if (itemdata == undefined) continue;
          //this.plog.log('healitems', v,itemdata);
          if (itemdata['pcode'] == 'hppotion') {
            healitems.push(v['item']);
          }
        }
        healitems.sort();
        healitems.reverse();
        //this.plog.log('healitems', healitems);
        if (healitems.length > 0) {
          var itemdata = wh.registry.list.rcvarpass.allstackitem[healitems[0]];
          wh.healiconi.setTexture('stackitem_tpt', itemdata['sprite']);
          wh.healiconi.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);

          wh.healiconi.visible = true;
        } else {
          wh.healiconi.visible = false;
        }
        wh.healiconi.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);

      }
    }
    if (wh.playerName != undefined) {
      wh.playerName.setText(wh.scene.scene.registry.list.rcvarpass.activechar.name + " lv." + wh.scene.scene.registry.list.rcvarpass.activechar.level)
    }
    //this.plog.log(this, wh);
    // if (wh.registry.list.rcvarpass.activechar['walkspeedboost']==undefined) 
    wh.registry.list.rcvarpass.activechar['walkspeedboost'] = 0;
    if (wh.registry.list.rcreq.chk({ stackitem1: "swifterkey", stackitem1_min: 1 })) {
      wh.registry.list.rcvarpass.activechar['walkspeedboost']++;
    }
    if (wh.registry.list.rcreq.chk({ stackitem1: "swifterkey", stackitem1_min: 5 })) {
      wh.registry.list.rcvarpass.activechar['walkspeedboost']++;
    }
    if (wh.registry.list.rcreq.chk({ stackitem1: "swifterkey", stackitem1_min: 10 })) {
      wh.registry.list.rcvarpass.activechar['walkspeedboost']++;
    }
    if (wh.registry.list.rcreq.chk({ stackitem1: "swifterkey", stackitem1_min: 20 })) {
      wh.registry.list.rcvarpass.activechar['walkspeedboost']++;
    }
    if (wh.registry.list.rcreq.chk({ stackitem1: "rapidas", stackitem1_min: 1 })) {
      wh.registry.list.rcvarpass.activechar['walkspeedboost'] = wh.registry.list.rcvarpass.activechar['walkspeedboost'] + 3;
    }
    if (wh.registry.list.rcreq.chk({ stackitem1: "rapidas", stackitem1_min: 5 })) {
      wh.registry.list.rcvarpass.activechar['walkspeedboost'] = wh.registry.list.rcvarpass.activechar['walkspeedboost'] + 3;
    }
    if (wh.registry.list.rcreq.chk({ stackitem1: "rapidas", stackitem1_min: 10 })) {
      wh.registry.list.rcvarpass.activechar['walkspeedboost'] = wh.registry.list.rcvarpass.activechar['walkspeedboost'] + 3;
    }
    if (wh.registry.list.rcreq.chk({ stackitem1: "rapidas", stackitem1_min: 20 })) {
      wh.registry.list.rcvarpass.activechar['walkspeedboost'] = wh.registry.list.rcvarpass.activechar['walkspeedboost'] + 3;
    }

    ///activewindow
    if (wh.activewindow != undefined) {
      if (wh.activewindow._refsrv != undefined) {
        try {
          wh.activewindow._refsrv.rebuildguigrid();
        } catch (e) {
          this.plog.warn(e);
        }
      }
    }

    // this.plog.log("char raritycount", wh.registry.list.rcvarpass.activechar.cbt);
    ///copy to updatezmapchar
    if (wh.registry.list.rcvarpass.activechar.cbt["raritycount"] != undefined && Math.floor(Number(wh.registry.list.rcvarpass.activechar.cbt["raritycount"])) == 0) {
      if (wh.charrarityeff != undefined) {
        try {
          wh.charrarityeff.destroy();
        } catch (e) {
          this.plog.log(e);
        }
      }
      wh.charrarityeff = undefined;
    }
    if (wh.mapcontainer != undefined &&
      wh.registry.list.rcvarpass.activechar.cbt["raritycount"] != undefined && Math.floor(Number(wh.registry.list.rcvarpass.activechar.cbt["raritycount"])) != 0) {

      if (wh.charrarityeff != undefined) {
        try {
          wh.charrarityeff.destroy();
        } catch (e) {
          this.plog.log(e);
        }
      }
      wh.charrarityeff = undefined;
      if (this.wh.registry.list.thethis != undefined && this.wh.registry.list.thethis.chkconfiglighteffects != undefined && this.wh.registry.list.thethis.chkconfiglighteffects == "yes") {
        wh.charrarityeff = this.genchareff(Math.floor(Number(wh.registry.list.rcvarpass.activechar.cbt["raritycount"])), wh);
      }
      //console.log( wh.charrarityeff);
      /*
      wh.charrarityeff = wh.add.particles('thecolor', "white",
        {
          frame: emitcol,
          //["white","black","blue"]
          color: [0x040d61, 0xfacc22, 0xf89800, 0xf83600, 0x9f0404, 0x4b4a4f, 0x353438, 0x040404],
          lifespan: 800,
          angle: { min: -130, max: -50 },
          scale: 0.5,
          speed: { min: 10, max: 50 },
          advance: 2000,
          //blendMode: 'ADD'
        });*/
      if (wh.charrarityeff != undefined) {
        wh.charrarityeff.following = "char_main";
        wh.charrarityeff.followingx = 0;
        wh.charrarityeff.followingy = 0;

        wh.mapcontainer.add(wh.charrarityeff);
        wh.mapcontainer.bringToTop(wh.charrarityeff);
        //this.rcvarsservice.gameobj.scene.scenes[0].zsortlist.push(tmpe);
        wh.zmapcharfollower.push(wh.charrarityeff);
      }
      //this.plog.log("char raritycount", wh.charrarityeff);

      //console.log("charinfoupdated", wh.registry.list.rcvarpass.activechar, this);

    }
    if (wh.mapcontainer != undefined) {
      //console.log("charinfoupdatetime ",this.charinfoupdated_showlvuphinttime,wh.registry.list.timectl.humantime);
      if (wh.registry.list.timectl.humantime > this.charinfoupdated_showlvuphinttime) {
        this.charinfoupdated_showlvuphinttime = wh.registry.list.timectl.humantime + 45;
        var dat = wh.registry.list.rcvarpass.activechar;

        var nextlvsupp = Number(wh.registry.list.rcvarpass.level[dat['level']]['supp']);
        var nextlvexp = wh.registry.list.rcvarpass.level[dat['level']]['xp'];

        var mysupp = 0;
        if (dat['stackitem'] != undefined) {
          for (const suppk in dat['stackitem']) {
            //this.plog.log("suppk",suppk,dat["stackitem"][suppk]);
            if (dat['stackitem'][suppk]['item'] == 'supp') {
              mysupp += dat['stackitem'][suppk]['amnt'];
            }
          }
        }
        if (dat['xp'] >= nextlvexp && mysupp >= nextlvsupp) {

          var width = wh.game.canvas.width;
          var height = wh.game.canvas.height;
          if (width > height) {
            try {
              wh.registry.list.gameitf.gamemenutooltip(wh, wh.gamemenu[4], wh.trs("f:gifscontainer:You can level up from Attribute menu===You can level up from Attribute menu"), 'top');
            } catch (e) {
              console.log(e);
            }
          } else {
            try {
              wh.registry.list.gameitf.gamemenutooltip(wh, wh.gamemenu[4], wh.trs("f:gifscontainer:You can level up from Attribute menu===You can level up from Attribute menu"), 'right');
            } catch (e) {
              console.log(e);
            }
          }
        }
      }
    }
    if (wh.charhptxt != undefined) {

      var hpmax = wh.registry.list.rcvarpass.activechar['cbt']['maxhp'];
      var curhp = wh.registry.list.rcvarpass.activechar['cbt']['hp'];
      wh.charhptxt.setText('HP ' + curhp + ' / ' + hpmax);
    }
    if (wh.charxptxt != undefined) {
      var xpneed =
        wh.registry.list.rcvarpass.level[
        wh.registry.list.rcvarpass.activechar['level']
        ]['xp'];
      var curxp = wh.registry.list.rcvarpass.activechar['xp'];
      var curxpdsp = wh.registry.list.gameitf.humannumber(curxp);
      wh.charxptxt.setText('XP ' + curxpdsp + ' / ' + xpneed);
    }

    if (wh.resb_goldt != undefined) {
      var myresgold = 0;
      if (wh.registry.list.rcvarpass.activechar['stackitem'] != undefined) {
        //this.plog.log("XXXXXXXXXXXXXX", wh);
        for (const suppk in wh.registry.list.rcvarpass.activechar[
          'stackitem'
        ]) {
          //this.plog.log("suppk",suppk,wh.registry.list.rcvarpass.activechar["stackitem"][suppk]);
          if (
            wh.registry.list.rcvarpass.activechar['stackitem'][suppk]['item'] ==
            'gold'
          ) {
            myresgold +=
              wh.registry.list.rcvarpass.activechar['stackitem'][suppk]['amnt'];
          }
        }
      }
      //myresgold = wh.registry.list.gameitf.humannumbercomma(myresgold);
      // wh.resb_goldt.setText('' + myresgold);

      if (wh.resb_goldt.updateTween != undefined && wh.resb_goldt.updateTween.isPlaying()) {
        //  The tween is already running, so we'll update the end value with resetting it
        var myresgoldh = wh.registry.list.gameitf.humannumbercomma(myresgold);
        wh.resb_goldt.updateTween.updateTo('value', myresgoldh);
      }
      else {
        //  The tween has finished, so create a new one
        wh.resb_goldt.updateTween = wh.tweens.addCounter({
          from: Math.round(parseInt(wh.registry.list.gameitf.numbernocomma(wh.resb_goldt.text))),
          to: myresgold,
          duration: 400,
          ease: 'linear',
          onUpdate: tween => {
            var value = Math.round(tween.getValue());
            //console.log(tween.getValue(),value, (wh.resb_goldt.text + ""),wh.registry.list.gameitf.numbernocomma(wh.resb_goldt.text + ""));
            //console.log("fromto",Math.round(parseInt(wh.registry.list.gameitf.numbernocomma(wh.resb_goldt.text ))),myresgold);
            if (isNaN(value)) return;
            value = wh.registry.list.gameitf.humannumbercomma(value);
            wh.resb_goldt.setText(value);
          },
          onComplete: function () {
            var value = wh.registry.list.gameitf.humannumbercomma(arguments[0]);
            wh.resb_goldt.setText(value);
            wh.registry.list.resizeman.RCMapResizeManager_work(wh);
          }.bind(this, myresgold)
        });
      }
    }

    if (wh.resb_diamondt != undefined) {
      var myresdiamond = 0;
      if (
        wh.scene.scene.registry.list.rcvarpass.activechar['stackitem'] !=
        undefined
      ) {
        for (const suppk in wh.scene.scene.registry.list.rcvarpass.activechar[
          'stackitem'
        ]) {
          //this.plog.log("suppk",suppk,dat["stackitem"][suppk]);
          if (
            wh.scene.scene.registry.list.rcvarpass.activechar['stackitem'][
            suppk
            ]['item'] == 'diamond'
          ) {
            myresdiamond +=
              wh.scene.scene.registry.list.rcvarpass.activechar['stackitem'][
              suppk
              ]['amnt'];
          }
        }
      }

      if (wh.resb_diamondt.updateTween != undefined && wh.resb_diamondt.updateTween.isPlaying()) {
        //  The tween is already running, so we'll update the end value with resetting it
        var myresdiamondh = wh.registry.list.gameitf.humannumbercomma(myresdiamond);
        wh.resb_diamondt.updateTween.updateTo('value', myresdiamond);
      }
      else {
        //  The tween has finished, so create a new one
        wh.resb_diamondt.updateTween = wh.tweens.addCounter({
          from: Math.round(parseInt(wh.registry.list.gameitf.numbernocomma(wh.resb_diamondt.text))),
          to: myresdiamond,
          duration: 400,
          ease: 'linear',
          onUpdate: tween => {
            var value = Math.round(tween.getValue());
            //console.log(tween.getValue(),value, (wh.resb_diamondt.text + ""),wh.registry.list.gameitf.numbernocomma(wh.resb_diamondt.text + ""));
            //console.log("fromto",Math.round(parseInt(wh.registry.list.gameitf.numbernocomma(wh.resb_diamondt.text ))),myresdiamond);
            if (isNaN(value)) return;
            value = wh.registry.list.gameitf.humannumbercomma(value);
            wh.resb_diamondt.setText(value);
          },
          onComplete: function () {
            var value = wh.registry.list.gameitf.humannumbercomma(arguments[0]);
            wh.resb_diamondt.setText(value);
            wh.registry.list.resizeman.RCMapResizeManager_work(wh);
          }.bind(this, myresdiamond)
        });
      }
      //myresdiamond = wh.registry.list.gameitf.humannumbercomma(myresdiamond);
      //wh.resb_diamondt.setText(myresdiamond);
    }


    if (wh.resb_suppt != undefined) {
      var myressupp = 0;
      if (
        wh.scene.scene.registry.list.rcvarpass.activechar['stackitem'] !=
        undefined
      ) {
        for (const suppk in wh.scene.scene.registry.list.rcvarpass.activechar[
          'stackitem'
        ]) {
          //this.plog.log("suppk",suppk,dat["stackitem"][suppk]);
          if (
            wh.scene.scene.registry.list.rcvarpass.activechar['stackitem'][
            suppk
            ]['item'] == 'supp'
          ) {
            myressupp +=
              wh.scene.scene.registry.list.rcvarpass.activechar['stackitem'][
              suppk
              ]['amnt'];
          }
        }
      }
      //myressupp = wh.registry.list.gameitf.humannumbercomma(myressupp);
      //wh.resb_suppt.setText(myressupp);

      if (wh.resb_suppt.updateTween != undefined && wh.resb_suppt.updateTween.isPlaying()) {
        //  The tween is already running, so we'll update the end value with resetting it
        var myressupph = wh.registry.list.gameitf.humannumbercomma(myressupp);
        wh.resb_suppt.updateTween.updateTo('value', myressupp);
      }
      else {
        //  The tween has finished, so create a new one
        wh.resb_suppt.updateTween = wh.tweens.addCounter({
          from: Math.round(parseInt(wh.registry.list.gameitf.numbernocomma(wh.resb_suppt.text))),
          to: myressupp,
          duration: 400,
          ease: 'linear',
          onUpdate: tween => {
            var value = Math.round(tween.getValue());
            //console.log(tween.getValue(),value, (wh.resb_suppt.text + ""),wh.registry.list.gameitf.numbernocomma(wh.resb_suppt.text + ""));
            //console.log("fromto",Math.round(parseInt(wh.registry.list.gameitf.numbernocomma(wh.resb_suppt.text ))),myressupp);
            if (isNaN(value)) return;
            value = wh.registry.list.gameitf.humannumbercomma(value);
            wh.resb_suppt.setText(value);
          },
          onComplete: function () {
            var value = wh.registry.list.gameitf.humannumbercomma(arguments[0]);
            wh.resb_suppt.setText(value);
            wh.registry.list.resizeman.RCMapResizeManager_work(wh);
          }.bind(this, myressupp)
        });
      }
    }

    wh.registry.list.resizeman.RCMapResizeManager_work(wh);
    wh.registry.list.gameitf.updatereddots(wh);
    wh.registry.list.gameitf.updatenpcvisiblereq(wh);
  }
  updatenpcvisiblereq(wh) {

    for (const k in wh.npclist) {
      var v = wh.npclist[k];
      if (v == undefined) continue;
      //this.plog.log('charinfoupdated npclist i', v, wh.npclist);
      var tmpnamea = v.name.split("-");
      //this.plog.log('charinfoupdated npclist i', tmpnamea, wh.registry.list.rcvarpass.npc, wh.registry.list.rcvarpass.npc[tmpnamea[1]]);
      if (wh.registry.list.rcvarpass.npc[tmpnamea[1]] == undefined) continue;
      var npcchkreq = wh.registry.list.rcreq.chk(
        wh.registry.list.rcvarpass.npc[tmpnamea[1]]['requirements']
      );
      //this.plog.log("nnn req",npcchkreq,key2);
      if (npcchkreq != true) {
        v.visible = false;
        if (v.tmpclickbox != undefined) v.tmpclickbox.visible = false;
        if (v.tmpmark != undefined) v.tmpmark.visible = false;
        if (v.tmpmapname != undefined) v.tmpmapname.visible = false;
        //hide inputitem

        if (wh.inputitemdb != undefined &&
          wh.inputitemdb[v.name] != undefined &&
          wh.inputitemdb[v.name]['bg'] != undefined &&
          wh.inputitemdb[v.name]['text'] != undefined &&
          wh.inputitemdb[v.name]['usebtn'] != undefined &&
          wh.inputitemdb[v.name]['btns'] != undefined
        ) {
          wh.inputitemdb[v.name]['bg'].visible = false;
          wh.inputitemdb[v.name]['text'].visible = false;
          wh.inputitemdb[v.name]['usebtn'].visible = false;
          wh.inputitemdb[v.name]['btns'][0].visible = false;
          if (wh.inputitemdb[v.name]['btns'][1] != undefined)
            wh.inputitemdb[v.name]['btns'][1].visible = false;
          if (wh.inputitemdb[v.name]['btns'][2] != undefined)
            wh.inputitemdb[v.name]['btns'][2].visible = false;
          wh.inputitemdb[v.name]['amount'][0].visible = false;
          if (wh.inputitemdb[v.name]['amount'][1] != undefined)
            wh.inputitemdb[v.name]['amount'][1].visible = false;
          if (wh.inputitemdb[v.name]['amount'][2] != undefined)
            wh.inputitemdb[v.name]['amount'][2].visible = false;
        }
      } else {
        v.visible = true;
        if (v.tmpclickbox != undefined) v.tmpclickbox.visible = true;
        if (v.tmpmark != undefined) v.tmpmark.visible = true;
        if (v.tmpmapname != undefined) v.tmpmapname.visible = true;
      }
    }
  }
  guiwindowhideall(wh) {
    this.plog.log('guiwindowhideall', wh);
    var winablelist = [
      //copy this list to phaser-map-resize-manager, remove ".scene." , guiwindownext
      wh.VIEWMYcontainer_item,
      wh.VIEWMYSTACKcontainer_item,
      wh.BUYITEMcontainer_item,
      wh.petuplevelcontainer,
      wh.msgscontainer,
      wh.BUYSTACKITEMcontainer_item,
      wh.sparbcontainer,
      wh.inventorycontainer_stackitem,
      wh.inventorycontainer_item,
      wh.shopcontainer_stackitem,
      wh.shopcontainer_item,
      wh.equipmentcontainer,
      wh.skillcraftcontainer,
      wh.attricontainer,
      wh.skillcontainer,
      wh.questlistcontainer,
      wh.questinfocontainer,
      wh.petguicontainer,
      wh.homeinventorycontainer_stackitem,
      wh.homeinventorycontainer_item,
      wh.pettravelcontainer,
      wh.petskillcontainer,
      wh.zcharinfocontainer,
      wh.iapstorecontainer,
      wh.dmcontainer,
      wh.partymapcontainer,
      wh.partymycontainer,
      wh.friendlistcontainer
    ];
    this.plog.log('guiwindownext winablelist', winablelist);

    wh.activewindow == undefined;
    for (const k in winablelist) {
      if (winablelist[k] != undefined && winablelist[k].visible == true) {
        try {
          winablelist[k].visible = false;
        } catch (e) {
          this.plog.log("ERROR", e);
        }
      }
    }
  }
  guiwindownext(wh) {
    this.plog.log('guiwindownext', wh);
    var winablelist = [
      //copy this list to phaser-map-resize-manager, remove ".scene." , guiwindownext
      wh.VIEWMYcontainer_item,
      wh.VIEWMYSTACKcontainer_item,
      wh.BUYITEMcontainer_item,
      wh.petuplevelcontainer,
      wh.msgscontainer,
      wh.BUYSTACKITEMcontainer_item,
      wh.sparbcontainer,
      wh.inventorycontainer_stackitem,
      wh.inventorycontainer_item,
      wh.shopcontainer_stackitem,
      wh.shopcontainer_item,
      wh.equipmentcontainer,
      wh.skillcraftcontainer,
      wh.attricontainer,
      wh.skillcontainer,
      wh.questlistcontainer,
      wh.questinfocontainer,
      wh.petguicontainer,
      wh.homeinventorycontainer_stackitem,
      wh.homeinventorycontainer_item,
      wh.pettravelcontainer,
      wh.petskillcontainer,
      wh.zcharinfocontainer,
      wh.iapstorecontainer,
      wh.dmcontainer,
      wh.partymapcontainer,
      wh.partymycontainer,
      wh.friendlistcontainer
    ];
    this.plog.log('guiwindownext winablelist', winablelist);
    //this.plog.error('guiwindownext winablelist', winablelist);
    //this.plog.error('guiwindownext thiswh', wh);

    if (wh.activewindow == undefined) {
      //find first active
      for (const k in winablelist) {
        if (winablelist[k] != undefined && winablelist[k].visible == true) {
          wh.activewindow = winablelist[k];
          wh.registry.list.gameitf.guiwindowclick(wh.activewindow);
          this.plog.log('guiwindownext no activewindow, set to and return', wh.activewindow);

          return wh.activewindow;
        }
      }
    }
    if (wh.activewindow == undefined) {
      this.plog.log('guiwindownext still no activewindow, return');
      return false;
    }
    //tonextwindow
    var findnext = false;
    var foundandfocus = false;
    for (const k in winablelist) {
      if (winablelist[k] == wh.activewindow && winablelist[k].visible == true) {
        //switch to next
        findnext = true;
        this.plog.log('guiwindownext found current, finding next');
        continue;
      }
      if (findnext == true) {
        if (winablelist[k] != undefined && winablelist[k].visible == true) {
          wh.activewindow = winablelist[k];
          wh.registry.list.gameitf.guiwindowclick(wh.activewindow);
          foundandfocus = true;
          this.plog.log('guiwindownext foundnext', wh.activewindow);
          return wh.activewindow;
        }
      }
    }
    if (foundandfocus == false) { //restart and loop from the beginning
      for (const k in winablelist) {
        if (winablelist[k] != undefined && winablelist[k].visible == true) {
          wh.activewindow = winablelist[k];
          wh.registry.list.gameitf.guiwindowclick(wh.activewindow);
          this.plog.log('guiwindownext foundnext, with restarting loop', wh.activewindow);

          return wh.activewindow;
        }
      }
    }
    this.plog.log('guiwindownext reaches eof', wh.activewindow);
    return false;
  }
  guiwindowclick(wh) {
    //set active/topmost
    this.plog.warn('guiwindowclick', wh);

    if (wh == undefined || wh.scene == undefined) {
      this.plog.error("guiwindowclick() invalid wh;");
      return;
    }
    if (this.wh == undefined) this.wh = wh.scene;
    wh.scene.kbfocus = "guiwindow";
    wh.scene.kbfocusi = wh.name;
    wh.scene.activewindow = wh;
    var width = wh.scene.game.canvas.width;
    var height = wh.scene.game.canvas.height;
    if (typeof wh.camman === "function") {
      // ref by Preloader scene
      wh.camman(wh);
    }
    if (typeof wh.scene.camman === "function") {
      // ref by Preloader scene
      wh.scene.camman(wh.scene);
    }
    if (wh.scene == undefined) return;
    if (wh.name != undefined && wh.name != "") {
      wh.scene.registry.list.ga.se2("guiwindowclick", wh.name);
    }
    var winablelist = [
      //copy this list to phaser-map-resize-manager, remove ".scene." , guiwindownext
      wh.scene.VIEWMYcontainer_item,
      wh.scene.VIEWMYSTACKcontainer_item,
      wh.scene.BUYITEMcontainer_item,
      wh.scene.petuplevelcontainer,
      wh.scene.msgscontainer,
      wh.scene.BUYSTACKITEMcontainer_item,
      wh.scene.sparbcontainer,
      wh.scene.inventorycontainer_stackitem,
      wh.scene.inventorycontainer_item,
      wh.scene.shopcontainer_stackitem,
      wh.scene.shopcontainer_item,
      wh.scene.equipmentcontainer,
      wh.scene.skillcraftcontainer,
      wh.scene.attricontainer,
      wh.scene.skillcontainer,
      wh.scene.questlistcontainer,
      wh.scene.questinfocontainer,
      wh.scene.petguicontainer,
      wh.scene.homeinventorycontainer_stackitem,
      wh.scene.homeinventorycontainer_item,
      wh.scene.pettravelcontainer,
      wh.scene.petskillcontainer,
      wh.scene.zcharinfocontainer,
      wh.scene.iapstorecontainer,
      wh.scene.dmcontainer,
      wh.scene.partymapcontainer,
      wh.scene.partymycontainer,
      wh.scene.friendlistcontainer
    ];

    for (const k in winablelist) {
      if (winablelist[k] != undefined) {
        winablelist[k].setDepth(401000);
        var tmpsize = this.getContainerSize(wh, winablelist[k]);
        //this.plog.log("guiwindowclick tmpsize", tmpsize);
        var percentfit = 1;
        var percentfit2 = 1;
        if (tmpsize.h > height) {
          percentfit = height / tmpsize.h;
          //this.plog.log("guiwindowclick percentfit", percentfit);
          //wh.setScale(percentfit);
        }
        if (tmpsize.w > width) {
          percentfit2 = width / tmpsize.w;
        }
        if (percentfit > percentfit2) percentfit = percentfit2;
        if (winablelist[k].visible == true && winablelist[k] != wh) {
          if (!this.wh.ismobileguimode()) {
            var tw = wh.scene.tweens.add({
              targets: winablelist[k],
              scale: percentfit * 0.9,
              ease: 'Power3',
              duration: 300, // duration of animation; higher=slower
              delay: 0,
            });
          } else {
            winablelist[k].setScale(percentfit * 0.9);
          }
          //this.plog.log("guiwindowclick i",k,winablelist[k])
        }
      }
    }
    /*var tw = wh.scene.tweens.add(
      {
        targets: wh,
        scale: 1,
        ease: 'Power3',
        duration: 500, // duration of animation; higher=slower
        delay: 0
      }
    );*/
    //this.plog.log("guiwindowclick focusing", wh);
    wh.setDepth(401500);
    var tmpsize = this.getContainerSize(wh, wh);
    //this.plog.log("guiwindowclick tmpsize", tmpsize);
    var percentfit = 1;
    if (tmpsize.h > height) {
      percentfit = height / tmpsize.h;
      //this.plog.log("guiwindowclick percentfit", percentfit);
      //wh.setScale(percentfit);
    }

    if (tmpsize.w > width) {
      percentfit2 = width / tmpsize.w;
    }
    if (percentfit > percentfit2) percentfit = percentfit2;
    if (!this.wh.ismobileguimode()) {
      var tw = wh.scene.tweens.add({
        targets: wh,
        scale: percentfit,
        ease: 'Power3',
        duration: 300, // duration of animation; higher=slower
        delay: 0,
      });
    } else {
      wh.setScale(percentfit);
    }
    this.makeguimobile(wh);

    if (wh._refsrv != undefined) {
      try {
        wh._refsrv.rebuildguigrid();
      } catch (e) {
        this.plog.warn(e);
      }
    }
  }
  /*pgbar(wh, options) {
    var container1 = wh.add.container(options.w, options.h);
    var bg = wh.add.graphics();
    bg.fillStyle(0xffff00, 1);
    bg.fillRoundedRect(options.x, options.y, options.w, options.h, options.h / 10);
    container1.add(bg);
    function xx() {
      this.plog.log("XX");
    }
    return container1;
  }*/
  osdflash(wh, txt) {
    var width = wh.game.canvas.width;
    var height = wh.game.canvas.height;

    var currenty = height / 2;
    var eachlineh = 40;
    var dsparr = [];
    var count = 0;
    for (var i in txt) {
      var val = txt[i];
      var dsparrl = dsparr.length;
      val['txt'] = wh.gl(val['txt']);
      if (val['col'] == 'blue') {
        dsparr[dsparrl] = wh.add.text(0, 0, val['txt'], wh.fs["osdflash_blue"]);
        dsparr[dsparrl].setPosition(
          width / 2 - dsparr[dsparrl].displayWidth / 2,
          height / 2 + count * eachlineh
        );
        dsparr[dsparrl].setDepth(150000);
      }
      if (val['col'] == 'red') {
        dsparr[dsparrl] = wh.add.text(0, 0, val['txt'], wh.fs["osdflash_red"]);
        dsparr[dsparrl].setPosition(
          width / 2 - dsparr[dsparrl].displayWidth / 2,
          height / 2 + count * eachlineh
        );
        dsparr[dsparrl].setDepth(150000);
      }
      if (val['col'] == 'green') {
        dsparr[dsparrl] = wh.add.text(0, 0, val['txt'], wh.fs["osdflash_green"]);
        dsparr[dsparrl].setPosition(
          width / 2 - dsparr[dsparrl].displayWidth / 2,
          height / 2 + count * eachlineh
        );
        dsparr[dsparrl].setDepth(150000);
      }
      //wh.gifcami(wh, dsparr);
      wh.mapcami(wh, dsparr);
      var tw = wh.tweens.add({
        targets: dsparr[dsparrl],
        alpha: 0,
        ease: 'Power3',
        duration: 1000, // duration of animation; higher=slower
        delay: 1000,
      });
      var tw1 = wh.tweens.add({
        targets: dsparr[dsparrl],
        y: dsparr[dsparrl].y - 100,
        ease: 'Power3',
        duration: 2000, // duration of animation; higher=slower
        delay: 0, // wait 500 ms before starting
        onComplete: function () {
          //this.plog.log("osdflash tweencomplete", this);
          if (this.target != undefined && this.target[0] != undefined) {
            this.target[0].destroy();
          }
          if (this.targets != undefined && this.targets[0] != undefined) {
            this.targets[0].destroy();
          }
        }, // set context? how?
      });
      count++;
    }
  }
  getContainerSize(wh, con) {
    this.plog.log('getContainerSize', wh, con);

    //set the top position to the bottom of the game
    var top;
    var left;

    if (con.scene == undefined && con.pipeline == undefined) {
      return { w: 0, h: 0 };
    }
    if (con.scene != undefined) {
      left = con.scene.game.config.width;
      top = con.scene.game.config.height;
    } else if (con.pipeline.game.scene.scenes[0] != undefined) {
      left = con.pipeline.game.config.width;
      top = con.pipeline.game.config.height;
    }
    var bottom = 0;
    //set the left to the right of the game
    var right = 0;
    //
    //
    //loop through the children
    //
    con.iterate(
      function (child) {
        if (child.name == 'skipcntnrsizecal') return;
        if (child.name == 'tooltiptext') return;
        if (child.name == 'tooltipbg') return;
        //get the positions of the child
        var childX = child.x;
        var childY = child.y;
        //
        //
        //
        var childW = child.displayWidth;
        var childH = child.displayHeight;
        //
        //
        //calcuate the child position
        //based on the origin
        //
        //
        var childTop = childY - childH * child.originY;
        var childBottom = childY + childH * (1 - child.originY);
        var childLeft = childX - childW * child.originX;
        var childRight = childX + childW * (1 - child.originY);
        //test the positions against
        //top, bottom, left and right
        //
        if (childBottom > bottom) {
          bottom = childBottom;
        }
        if (childTop < top) {
          top = childTop;
        }
        if (childLeft < left) {
          left = childLeft;
        }
        if (childRight > right) {
          right = childRight;
        }
      }.bind(this)
    );
    //
    //calculate the square
    var h = Math.abs(top - bottom);
    var w = Math.abs(right - left);
    //set the container size
    con.setSize(w, h);
    return { w: w, h: h };
    //set the top position to the bottom of the game
    /*var top = wh.config.height;
    var bottom = 0;
    //set the left to the right of the game
    var left = wh.config.width;
    var right = 0;
    var width = 0;
    //
    //
    //loop through the children
    //
    con.iterate(function (child) {
      //get the positions of the child
      var childX = child.x;
      var childXW = child.displayWidth;
      var childY = child.y;
      //
      //
      //
      //test the positions against
      //top, bottom, left and right
      //
      if (childY > bottom) {
        bottom = childY;
      }
      if (childY < top) {
        top = childY;
      }
      if (childX < left) {
        left = childX;
      }
      if (childX > right) {
        right = childX;
      }
      if (childXW > width) {
        width = childXW;
      }
    }.bind(this));

    //calculate the square
    var h = Math.abs(top - bottom);
    var w = Math.abs(right - left) + width;

    //set the container size
    //con.displayWidth=width;
    //con.setSize(w, h);
    this.plog.log("getContainerSize res", { w: w, h: h });
    return { w: w, h: h }
    */
  }
  numberWithCommas(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  numbernocomma(num) {
    var nums = num + "";
    for (var i = 1; i <= 10; i++) {
      nums = nums.replace(",", "");

    }
    return nums;
  }
  humannumbercomma(number: number): string {
    // Convert the number to a string with fixed decimal digits

    var numbert = number + "";
    // Use a regular expression to add commas to the integer part
    const formattedIntegerPart = numbert.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    //var result=`${formattedIntegerPart}.${fractionalPart}`;
    var result = `${formattedIntegerPart}`;
    result = result.replace(".undefined", "");
    result = result.replace("undefined", "");
    result = result.trim();
    // Return the formatted number with the specified number of decimal digits
    return result;
  }
  humannumber(num, digits) {
    //this.plog.log("humannumber", num, digits);
    const lookup = [
      { value: 1, symbol: '' },
      { value: 1e3, symbol: 'k' },
      { value: 1e6, symbol: 'M' },
      { value: 1e9, symbol: 'G' },
      { value: 1e12, symbol: 'T' },
      { value: 1e15, symbol: 'P' },
      { value: 1e18, symbol: 'E' },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup
      .slice()
      .reverse()
      .find(function (item) {
        return num >= item.value;
      });
    return item
      ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
      : '0';
  }
  mapfadeout(wh, twtime) {
    //console.log("mapfadeout",wh);


    wh.cameras.main.once('camerafadeoutcomplete', function (camera) {

      camera.fadeIn(twtime, 0, 0, 0);

    }, this);

    wh.cameras.main.fadeOut(twtime, 0, 0, 0);
    return;
    var width = wh.game.canvas.width;
    var height = wh.game.canvas.height;
    if (wh.maplayerfader == undefined) {
      wh.maplayerfader = wh.add.rectangle(0, 0, width, height, 0x000000);
    }
    wh.maplayerfader.setAlpha(0);
    wh.maplayerfader.setPosition(0, 0);
    wh.maplayerfader.setOrigin(0, 0);
    wh.maplayerfader.setDepth(39000);
    wh.maplayerfader.visible = true;
    wh.maplayerfader.displayWidth = width;
    wh.maplayerfader.displayHeight = height;
    wh.maplayerfader.setAlpha(0);
    wh.maplayerfader.visible = true;

    //this.plog.log("mapfadeout()", wh.maplayerfader);
    var tw = wh.tweens.add({
      targets: wh.maplayerfader,
      alpha: 0.9,
      ease: 'Power3',
      duration: Math.floor(twtime / 2), // duration of animation; higher=slower
      delay: 0, // wait 500 ms before starting
      onComplete: function () {
        //wh.maplayerfader.setAlpha(1);
      }.bind(this), // set context? how?
    });
    var tw = wh.tweens.add({
      targets: wh.maplayerfader,
      alpha: 0,
      ease: 'Power3',
      duration: Math.floor(twtime / 2), // duration of animation; higher=slower
      delay: Math.floor(twtime / 2), // wait 500 ms before starting
      onComplete: function () {
        //wh.maplayerfader.setAlpha(1);
      }.bind(this), // set context? how?
    });
    wh.camman(wh);
  }
  async closegui(wh, tg) {
    if (wh == undefined) {
      this.plog.error("gameitf closegui() wh undefined", wh, tg);
    }
    if (this.wh == undefined) this.wh = wh;

    if (wh.mobfillbg != undefined) {
      wh.mobfillbg.visible = false;
    }
    if (wh.moblocaladdbackbtn != undefined) {
      wh.moblocaladdbackbtn.visible = false;
    }

    if (tg == undefined) {
      this.plog.warn("closegui invalid tg", tg);
      return;
    }
    var tw = wh.tweens.add({
      targets: tg,
      alpha: 0,
      ease: 'Power3',
      duration: 400, // duration of animation; higher=slower
      delay: 0, // wait 500 ms before starting
      onComplete: function () {
        //wh.maplayerfader.setAlpha(1);
        //this.plog.log("closegui b", tg, tg.list);
        if (tg == undefined) return;
        tg.visible = false;
        tg.setAlpha(1);
        //tg.destroy();
        for (const k in tg.list) {
          //tg.list[k].destroy();
          //tg.list[k]=undefined;
        }
        //this.plog.log("closegui a", tg, tg.list);
      }.bind(this), // set context? how?
    });
    if (this.wh.activewindow != undefined && this.wh.activewindow.name != "" && this.wh.activewindow.name == tg.name) {
      this.wh.activewindow = undefined;
    }

    /*
    try {
      this.wh.cameras.main.postFX.clear();
    } catch (e) {
      console.log(e);
    }*/
    var nextwinstatus;
    try {
      nextwinstatus = this.guiwindownext(wh);
    } catch (e) {
      this.plog.log(e);
    }
    this.plog.log("closegui() closingwin/newwin", tg, nextwinstatus)
    if (tg.name == nextwinstatus.name) {
      //looped, and found itself;
      nextwinstatus = false;
    }
    if (nextwinstatus == false) {
      this.wh.kbfocus = ""; //no new win, exit kbfocus=guiwindow
      this.plog.log("closegui() no new win, exit kbfocus='' changed", this.wh.kbfocus);

      if (wh.mobfillbg != undefined) {
        wh.mobfillbg.visible = false;
      }
      if (wh.moblocaladdbackbtn != undefined) {
        wh.moblocaladdbackbtn.visible = false;
      }
    }
    this.plog.log("closegui() nextwin", nextwinstatus);

    for (var destroyi = 0; destroyi <= 27; destroyi++) {

      if (tg != undefined) {
        if (tg.list != undefined) {
          for (const k in tg.list) {
            try {
              //this.plog.log("closeguidestroying", k, tg.list[k], tg.list[k].scene);
              if (tg.list[k].scene != undefined) {
                await tg.remove(tg.list[k], true);
              }
              //await tg.remove(tg.list[k]);
              //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list[k].destroy();
            } catch (e) {
              this.plog.log("ERROR! tg.destroy() loop", destroyi, e);
            }
          }
        }
      }
    }
    if (tg != undefined) {
      for (const k in tg.list) {
        try {
          //this.plog.log("closeguidestroying", k, tg.list[k], tg.list[k].scene);
          if (tg.list[k].scene != undefined) {
            await tg.remove(tg.list[k], true);
          }
          //await tg.remove(tg.list[k]);
          //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list[k].destroy();
        } catch (e) {
          this.plog.log("ERROR! tg.destroy() ", e);
        }
      }
    }
    if (tg != undefined) {
      for (const k in tg.list) {
        try {
          //this.plog.log("closeguidestroying", k, tg.list[k], tg.list[k].scene);
          if (tg.list[k].scene != undefined) {
            await tg.remove(tg.list[k], true);
          }
          //await tg.remove(tg.list[k]);
          //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list[k].destroy();
        } catch (e) {
          this.plog.log("ERROR! tg.destroy() ", e);
        }
      }
      for (const k in tg.list) {
        try {
          if (tg.list[k].scene != undefined) {
            await tg.remove(tg.list[k], true);
          }
        } catch (e) {
          this.plog.log("ERROR! tg.destroy() 2  ", e);
        }
      }
      try {
        //await tg.destroy();
        await tg.destroy(true);
      } catch (e) {
        this.plog.log("ERROR! tg.destroy() ", e, tg);
      }
    }
    tg = undefined;
  }
  formattimestamp(UNIX_timestamp) {
    var a = new Date(UNIX_timestamp * 1000);
    var months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    var min = a.getMinutes();
    var sec = a.getSeconds();
    var time =
      date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec;
    return time;
  }
  doidoi(wh, tg) {
    this.plog.log("doidoi", wh, tg);
    if (tg == undefined) return;
    //var npcinfo=wh.registry.list.rcvarpass.npc[namea[1]];
    //var spritesize = wh.registry.list.rcvarpass.allanims[npcinfo["mapsprite"]];
    //this.plog.log("doidoi spritesize", namea, spritesize);
    //var origw = 32 * spritesize["size"];
    //tg.displayWidth = origw; 
    //tg.scaleY = tg.scaleX;
    var origh = tg.scaleX;
    var origw = tg.scaleY;
    this.plog.log("doidoi scale", origh, origw);
    var timeline = wh.add.timeline([
      {
        at: 0,
        tween: {
          targets: tg,
          scaleX: { from: origw, to: origw * 0.5 },
          scaleY: { from: origh, to: origh * 1.5 },
          ease: 'Sine.easeInOut',
          duration: 200,
        }
      }, {
        at: 200,
        tween: {
          targets: tg,
          scaleX: { from: origw * 1.5, to: origw * 1 },
          scaleY: { from: origh * 0.5, to: origh * 1 },
          ease: 'Sine.easeIn',
          duration: 150
        }
      }, {
        at: 350,
        tween: {
          targets: tg,
          scaleX: { from: origw * 1.5, to: origw * 1 },
          scaleY: { from: origh * 0.5, to: origh * 1 },
          ease: 'Sine.easeIn',
          duration: 100
        }
      }, {
        at: 450,
        tween: {
          targets: tg,
          scaleX: { from: origw * 1, to: origw * 0.7 },
          scaleY: { from: origh * 1, to: origh * 1.2 },
          ease: 'Sine.easeInOut',
          duration: 50,
        }
      }, {
        at: 500,
        tween: {
          targets: tg,
          scaleX: { from: origw * 0.7, to: origw * 1.2 },
          scaleY: { from: origh * 1.2, to: origh * 0.7 },
          ease: 'Sine.easeOut',
          duration: 50,
        }
      }, {
        at: 550,
        tween: {
          targets: tg,
          scaleX: { from: origw * 1.2, to: origw * 1 },
          scaleY: { from: origh * 0.7, to: origh * 1 },
          ease: 'Sine.easeIn',
          duration: 50
        }
      }
    ]);
    timeline.play();
  }
  wiggle(wh, tg, setloop = -1) {
    //this.plog.log("gameinterfaceservice wiggle",wh,tg)

    var timeline = wh.add.timeline([
      {
        at: 0,
        tween: {
          targets: tg,
          angle: 20,
          ease: 'Power3',
          duration: 50,
        }
      }, {
        at: 50,
        tween: {
          targets: tg,
          angle: 0,
          ease: 'Power3',
          duration: 50,
        }
      }, {
        at: 100,
        tween: {
          targets: tg,
          angle: 20,
          ease: 'Power3',
          duration: 50,
        }
      }, {
        at: 150,
        tween: {
          targets: tg,
          angle: 0,
          ease: 'Power3',
          duration: 50,
        }
      },
      {
        at: 3000,
        stop: true
      }
    ]);
    //timeline.loop = setloop;
    //timeline.loopDelay = 2000;
    //this.plog.log("gameinterfaceservice timeline",timeline)

    timeline.repeat().play();
  }
  genloadedanims(wh, spritecode) {
    if (wh == undefined) {
      this.plog.error("gameitf genloadedanims() wh undefined", wh, spritecode);
    }
    this.wh = wh;
    //this.plog.log('genloadedanims', this.wh, spritecode);
    var val = this.wh.registry.list.rcvarpass.allanims[spritecode];
    //this.plog.log('genloadedanims val', val);
    var codea = val['code'] + '';
    var codea2 = codea.split('_');
    //this.plog.log("load2 anims", codea2, key, val);
    var tmpframe =
      this.wh.registry.list.rcvarpass.gameobj.anims.generateFrameNames(
        val['sprite'] + '',
        {
          prefix: codea2[1] + '-',
          start: 1,
          end: val['num'],
          zeroPad: 0,
        }
      );
    //this.plog.log('genloadedanims frames for ' + spritecode, tmpframe, val);
    if (this.wh.anims.anims.entries[spritecode] == undefined) {
      var tmpf = this.wh.registry.list.rcvarpass.gameobj.anims.create({
        key: spritecode + '',
        frames: tmpframe,
        frameRate: val['fr'],
        repeat: -1,
      });
    }
    if (this.wh.anims.anims.entries[spritecode + '_once'] == undefined) {
      var tmpf_once = this.wh.registry.list.rcvarpass.gameobj.anims.create({
        key: spritecode + '_once',
        frames: tmpframe,
        frameRate: val['fr'],
        repeat: 0,
      });
    }
  }
  destroybuildablepanel(wh, id) {
    wh.registry.list.rcvarpass.activemap['map']['npc_personal'][id] = undefined;
    this.plog.log("destroybuildablepanel()", id)
    this.plog.log("destroybuildablepanel()s", wh.pnnpcitfdb, wh.pnnpcitfdb[id])
    for (const key in wh.pnnpcitfdb[id]) {
      if (Object.prototype.hasOwnProperty.call(wh.pnnpcitfdb[id], key)) {
        var thev = wh.pnnpcitfdb[id][key];
        if (thev != undefined) {
          try {
            thev.setInteractive(false);
          } catch (e) {
            this.plog.log("ERROR!", e);
          }
          thev.destroy();
          this.plog.log("destroybuildablepanel()d", thev)

          //if (thev["text"] != undefined) thev['text'].destroy();
          //if (thev["status"] != undefined) thev['status'].destroy();
          //if (thev["btn1"] != undefined) thev['btn1'].destroy();
          //if (thev["btn2"] != undefined) thev['btn2'].destroy();
          //if (thev["btn3"] != undefined) thev['btn3'].destroy();
        }
      }
    }
    this.plog.log("destroybuildablepanel()e", wh.pnnpcitfdb[id]);
    if (wh.npclist[id] != undefined && wh.npclist[id].tmpclickbox != undefined) {
      wh.npclist[id].tmpclickbox.destroy();
    }
    this.plog.log("destroybuildablepanel()e", wh);

    var npcsprite = wh.registry.list.phm.findnpc(wh, id);
    if (npcsprite != undefined) npcsprite.destroy();
    /*
    for (const k in this.wh.mapcontainer.list) {
      var v = this.wh.mapcontainer.list[k];
      this.plog.log(v.name);
      if (v.name == undefined || (v.name + "").length < 5) continue;
      var namea = v.name.split('-');

      if (namea[0] == 'npc') {
        if (v.frame.name == 'spacer.png') {
          v.destroy();
          this.plog.log(v.name, v);
        }
      }
    }*/

    wh.pnnpcitfdb[id] = undefined;
  }
  createbuildablepanel(wh, id) {
    //wh.pnnpcitfdb = [];
    //tmpobj = [];
    var npcsprite = wh.registry.list.phm.findnpc(wh, id);
    var ida = id.split('-');
    var tmpobj = [];
    var npcdata = wh.registry.list.rcvarpass.npc[ida[1]];
    this.plog.log('createbuildablepanel npcsprite', wh, id, npcsprite);
    var usename = npcdata['name'];
    tmpobj['text'] = wh.add.text(0, 0, '' + usename, {
      fontFamily: 'ffff',
      fontSize: '8px',
      color: '#fff',
      stroke: '#02A8DF',
      strokeThickness: 2,
      shadow: { color: '#070707', fill: true, blur: 16, stroke: true },
      resolution: 4
    });

    wh.mapcontainer.add(tmpobj['text']);
    wh.mapcontainer.bringToTop(tmpobj['text']);

    tmpobj['text'].setPosition(
      npcsprite.x - (tmpobj['text'].displayWidth / 2),
      npcsprite.y - npcsprite.displayHeight - 5
    );
    tmpobj['status'] = wh.add.text(0, 0, '', {
      fontFamily: 'ffff',
      fontSize: '8px',
      color: '#fff',
      stroke: '#880000',
      strokeThickness: 2,
      shadow: { color: '#070707', fill: true, blur: 16, stroke: true },
      resolution: 4
    });

    wh.mapcontainer.add(tmpobj['status']);
    wh.mapcontainer.bringToTop(tmpobj['status']);

    tmpobj['status'].setPosition(
      tmpobj['text'].x - (tmpobj['status'].displayWidth / 2),
      tmpobj['text'].y - 20
    );
    tmpobj['status'].visible = false;

    tmpobj['btn1'] = wh.add.sprite(64, 64, 'thesq', 'pnnpc-harvest');
    tmpobj['btn1'].setOrigin(0.5, 0.5);
    wh.mapcontainer.add(tmpobj['btn1']);
    wh.mapcontainer.bringToTop(tmpobj['btn1']);
    tmpobj['btn1'].setPosition(
      npcsprite.x + npcsprite.displayWidth / 2 + 3,
      npcsprite.y - 33 - 9
    );
    tmpobj['btn1'].displayWidth = tmpobj['btn1'].displayHeight = 32;

    tmpobj['btn2'] = wh.add.sprite(64, 64, 'thesq', 'pnnpc-pack');
    tmpobj['btn2'].setOrigin(0.5, 0.5);
    wh.mapcontainer.add(tmpobj['btn2']);
    wh.mapcontainer.bringToTop(tmpobj['btn2']);
    tmpobj['btn2'].setPosition(
      npcsprite.x + npcsprite.displayWidth / 2 + 3,
      npcsprite.y + 0 - 9
    );
    tmpobj['btn2'].displayWidth = tmpobj['btn2'].displayHeight = 32;

    tmpobj['btn3'] = wh.add.sprite(64, 64, 'thesq', 'pnnpc-destroy');
    tmpobj['btn3'].setOrigin(0.5, 0.5);
    wh.mapcontainer.add(tmpobj['btn3']);
    wh.mapcontainer.bringToTop(tmpobj['btn3']);
    tmpobj['btn3'].setPosition(
      npcsprite.x + npcsprite.displayWidth / 2 + 3,
      npcsprite.y + 33 - 9
    );
    tmpobj['btn3'].displayWidth = tmpobj['btn3'].displayHeight = 32;

    tmpobj['ffw'] = wh.add.sprite(64, 64, 'thesq', 'ffw');
    tmpobj['ffw'].setOrigin(0.5, 0.5);
    wh.mapcontainer.add(tmpobj['ffw']);
    wh.mapcontainer.bringToTop(tmpobj['ffw']);
    tmpobj['ffw'].setPosition(
      npcsprite.x + npcsprite.displayWidth / 2 + 3,
      npcsprite.y - 33 - 9
    );
    tmpobj['ffw'].displayWidth = tmpobj['ffw'].displayHeight = 32;

    tmpobj['text'].name = id;
    tmpobj['btn1'].name = id;
    tmpobj['btn2'].name = id;
    tmpobj['btn3'].name = id;
    tmpobj['ffw'].name = id;
    tmpobj['text'].wh = wh;
    tmpobj['btn1'].wh = wh;
    tmpobj['btn2'].wh = wh;
    tmpobj['btn3'].wh = wh;
    tmpobj['ffw'].wh = wh;
    tmpobj['text'].visible = false;
    tmpobj['btn1'].visible = false;
    tmpobj['btn2'].visible = false;
    tmpobj['btn3'].visible = false;
    tmpobj['ffw'].visible = false;
    tmpobj['btn1'].setInteractive();
    tmpobj['btn1'].on('pointerover', function () {
      wh.registry.list.gameitf.gamemenutooltip(wh, tmpobj['btn1'], wh.trs("f:gifscontainer:buildableHarvest===Harvest"), 'right');
    });
    tmpobj['btn2'].setInteractive();
    tmpobj['btn2'].on('pointerover', function () {
      wh.registry.list.gameitf.gamemenutooltip(wh, tmpobj['btn2'], wh.trs("f:gifscontainer:buildablePack to StackItem===Pack to StackItem"), 'right');
    });
    tmpobj['btn3'].setInteractive();
    tmpobj['btn3'].on('pointerover', function () {
      wh.registry.list.gameitf.gamemenutooltip(wh, tmpobj['btn3'], wh.trs("f:gifscontainer:buildableDestroy===Destroy"), 'right');
    });
    tmpobj['ffw'].setInteractive();
    tmpobj['ffw'].on('pointerover', function () {
      wh.registry.list.gameitf.gamemenutooltip(wh, tmpobj['ffw'], wh.trs("f:gifscontainer:buildableFinish now===Finish now"), 'right');
    });
    tmpobj['btn1'].on('pointerdown', async function () {
      var ida = this.name.split('-');
      var tmpinfo = this.wh.registry.list.rcvarpass.npc[ida[1]];
      // this.plog.log("destroybtn", tmpinfo);
      //this.plog.log("destroybtn",this,arguments);

      var res = await this.wh.registry.list.rchttp.getcommon2('pnnpc-harvest', {
        harvestthis: this.name
      });
      if (res == "ok") {
        var charorigx = wh.char_main.currentx;
        var charorigy = wh.char_main.currenty;
        //await wh.registry.list.rchttp.updatecharinfo();
        wh.registry.list.rcvarpass.activemap["map"]["npc_personal"][this.name]["harvestanimflag"] = undefined;
        //await wh.registry.list.rchttp.load_map(this.wh.registry.list.rcvarpass.activemap['map']['code']);
        //await wh.chkwarppos_warp(this.wh, this.wh.registry.list.rcvarpass.activemap['map']['code']);
        await wh.registry.list.phm.refreshnpc_soft();
        this.wh.registry.list.gp.guigrid_focushide();
        //this.wh.kbfocuslv1 = "";
        this.wh.kbfocus = "";
        this.wh.registry.list.execmod.exec("pnnpcclick:all", this.wh);
        //wh.registry.list.phm.spawnmainchar(wh.char_main.currentx+"-"+wh.char_main.currenty);
        //await wh.registry.list.rctoast.sleep(300);
        //await wh.registry.list.phm.movemaincharto(charorigx, charorigy);
      }
    });
    tmpobj['btn2'].on('pointerdown', async function () {
      var ida = this.name.split('-');
      var tmpinfo = this.wh.registry.list.rcvarpass.npc[ida[1]];
      //this.plog.log("packbtn", tmpinfo);
      //this.plog.log("destroybtn",this,arguments);
      var cfm = await this.wh.registry.list.rctoast.rcconfirm(this.wh, "Pack to StackItem", "Pack this structure and convert into StackItem?\n\n" + tmpinfo.name);
      if (cfm == "ok") {
        var res = await this.wh.registry.list.rchttp.getcommon2('pnnpc-pack', {
          packthis: this.name
        });
        if (res == "ok") {
          //await wh.registry.list.rchttp.updatecharinfo();
          //await wh.registry.list.rchttp.load_map(this.wh.registry.list.rcvarpass.activemap['map']['code']);
          //wh.chkwarppos_warp(this.wh, this.wh.registry.list.rcvarpass.activemap['map']['code']);
          await this.wh.registry.list.phm.refreshnpc_soft();
          this.wh.registry.list.execmod.exec("pnnpcclick:all", this.wh);

          this.wh.registry.list.gp.guigrid_focushide();
          this.wh.kbfocuslv1 = "";
          this.wh.kbfocus = "";
        }
      }
    });
    tmpobj['btn3'].on('pointerdown', async function () {
      var ida = this.name.split('-');
      var tmpinfo = this.wh.registry.list.rcvarpass.npc[ida[1]];
      //this.plog.log("destroybtn", tmpinfo);
      //this.plog.log("destroybtn",this,arguments);
      var cfm = await this.wh.registry.list.rctoast.rcconfirm(this.wh, "Destroy", "Destroy this structure?\n\n" + tmpinfo.name);
      if (cfm == "ok") {
        var res = await this.wh.registry.list.rchttp.getcommon2('pnnpc-destroy', {
          destroythis: this.name
        });
        if (res == "ok") {
          //await wh.registry.list.rchttp.updatecharinfo();
          //await wh.registry.list.rchttp.load_map(this.wh.registry.list.rcvarpass.activemap['map']['code']);
          //wh.chkwarppos_warp(this.wh, this.wh.registry.list.rcvarpass.activemap['map']['code']);
          await wh.registry.list.phm.refreshnpc_soft();
          this.wh.registry.list.execmod.exec("pnnpcclick:all", this.wh);

          this.wh.registry.list.gp.guigrid_focushide();
          this.wh.kbfocuslv1 = "";
          this.wh.kbfocus = "";
        }
      }
    });
    tmpobj['ffw'].on('pointerdown', async function () {
      var ida = this.name.split('-');
      var tmpinfo = this.wh.registry.list.rcvarpass.npc[ida[1]];
      //this.plog.log("ffwbtn", tmpinfo);
      var res = await this.wh.registry.list.rchttp.getcommon2('pnnpc-ffwinfo', {
        npcid: this.name
      });
      //this.plog.log("ffw res", res);
      if (res['halt'] == 'yes') return;
      if (res['diamondcost'] == 0) {
        await this.wh.registry.list.rctoast.rcconfirm(this.wh, "Speed-up for free!", "" + res['msg']);
        var res = await this.wh.registry.list.rchttp.getcommon2('pnnpc-ffw', {
          npcid: this.name
        });
        if (res == "ok") {
          await wh.registry.list.phm.refreshnpc_soft();
          this.wh.registry.list.execmod.exec("pnnpcclick:all", this.wh);

          this.wh.registry.list.gp.guigrid_focushide();
          this.wh.kbfocuslv1 = "";
          this.wh.kbfocus = "";
        }
      } else {
        var res = await this.wh.registry.list.rctoast.rcconfirm(this.wh, "Speed-up", "" + res['msg']);
        if (res == "ok") {
          var res = await this.wh.registry.list.rchttp.getcommon2('pnnpc-ffw', {
            npcid: this.name
          });
          await wh.registry.list.phm.refreshnpc_soft();


          if (res == "ok") {
            var npcsprite = wh.registry.list.phm.findnpc(wh, id);
            if (npcsprite != undefined) {
              wh.registry.list.phm.mapitemeffect(npcsprite, 'eff12explodingimplodingltblue_b');
            }

            this.wh.registry.list.gp.guigrid_focushide();
            this.wh.kbfocuslv1 = "";
            this.wh.kbfocus = "";
          }
          this.wh.registry.list.execmod.exec("pnnpcclick:all", this.wh);
        }
      }

      return;
      //this.plog.log("destroybtn",this,arguments);
      var cfm = await this.wh.registry.list.rctoast.rcconfirm(this.wh, "Destroy", "Destroy this structure?\n\n" + tmpinfo.name);
      if (cfm == "ok") {
        var res = await this.wh.registry.list.rchttp.getcommon2('pnnpc-destroy', {
          destroythis: this.name
        });
        if (res == "ok") {
          //await wh.registry.list.rchttp.updatecharinfo();
          //await wh.registry.list.rchttp.load_map(this.wh.registry.list.rcvarpass.activemap['map']['code']);
          //wh.chkwarppos_warp(this.wh, this.wh.registry.list.rcvarpass.activemap['map']['code']);
          await wh.registry.list.phm.refreshnpc_soft();
          this.wh.registry.list.execmod.exec("pnnpcclick:all", this.wh);
        }
      }
    });
    wh.pnnpcitfdb[id] = tmpobj;
  }
  async createinputitem(wh, id) {
    this.plog.log('createinputitem', wh, id);
    //clean wh.inputitemdb here later
    //wh.inputitemdb = [];

    var npcsprite = wh.registry.list.phm.findnpc(wh, id);
    var ida = id.split('-');
    //this.plog.log('createinputitem npcsprite', ida, npcsprite);
    var npcdata = wh.registry.list.rcvarpass.npc[ida[1]];

    if (
      npcdata != undefined &&
      npcdata['inputitem'] != null &&
      npcdata['inputitem'] != undefined
    ) {
      var npcdatainputitem: any = npcdata['inputitem'];
      var ccinputitem = 0;
      for (var k in npcdatainputitem) {
        if (k == "selfactivateani") continue;
        //this.plog.log("inputitemxx",k);
        ccinputitem++;
      }
      this.plog.log("createinputitem ccinputitem", ccinputitem, npcdatainputitem);
      //ccinputitem = ccinputitem - 1;
      ccinputitem = ccinputitem / 2;
      this.plog.log("createinputitem ccinputitem final", ccinputitem);
      if (ccinputitem > 0) {
        wh.inputitemdb[id] = Array();
        //this.plog.log('createinputitem e', ccinputitem);
        if (ccinputitem == 1) {
          var bg = wh.add.sprite(300, 300, 'inputitem1', 'b-1');
          bg.setOrigin(0.5, 0);
          wh.mapcontainer.add(bg);
          wh.mapcontainer.bringToTop(bg);
          bg.setPosition(
            npcsprite.x,
            npcsprite.y + npcsprite.displayHeight / 2
          );
          //this.plog.log('createinputitem bg', bg);
          wh.inputitemdb[id]['bg'] = bg;
          var usename = npcdata['name'];
          if (usename == "Pet Healer") usename = "Heal";
          if (usename == "Ship Health") usename = "HP";
          if (usename == "Ship Weapon") usename = "ATK";
          if (usename == "Ship Shield") usename = "DEF";
          if (usename == "Fuel 1") usename = "Fuel";
          if (usename == "Fuel 2") usename = "Fuel";
          if (usename == "Fuel 3") usename = "Fuel";
          var tmpt = wh.add.text(0, 0, '' + usename, {
            fontFamily: 'ffff',
            fontSize: '8px',
            stroke: '#fff',
            strokeThickness: 0,
            align: 'right',
            boundsAlignH: 'right',
            color: '#000',
          });
          wh.mapcontainer.add(tmpt);
          wh.mapcontainer.bringToTop(tmpt);
          wh.inputitemdb[id]['text'] = tmpt;

          tmpt.setPosition(
            bg.x - bg.displayWidth / 2 + 5,
            npcsprite.y + npcsprite.displayHeight / 2 + 2
          );
          wh.inputitemdb[id]['btns'] = Array();
          var btn1 = wh.add.sprite(300, 300, "thesq", 'plus');
          btn1.displayWidth = btn1.displayHeight = 24;
          wh.mapcontainer.add(btn1);
          wh.mapcontainer.bringToTop(btn1);
          btn1.setPosition(bg.x, bg.y + 32);
          wh.inputitemdb[id]['btns'][0] = btn1;


          var usebtn = wh.add.image(0, 0, 'spacer');
          usebtn.displayWidth = bg.displayWidth;
          usebtn.displayHeight = 16;
          wh.mapcontainer.add(usebtn);
          wh.mapcontainer.bringToTop(usebtn);
          usebtn.setPosition(bg.x, bg.y + 32 + 24);
          wh.inputitemdb[id]['usebtn'] = usebtn;
          wh.inputitemdb[id]['btns'][0].name = 'inputitem' + id + '---0';
          wh.inputitemdb[id]['usebtn'].name = 'inputitem' + id;
          wh.inputitemdb[id]['btns'][0].setInteractive({ pixelPerfect: true });
          wh.inputitemdb[id]['usebtn'].setInteractive();
          wh.inputitemdb[id]['usebtn'].on('pointerdown', function () {
            //this.scene.plog.log('inputitem usebtn pointerdown', this);
            this.scene.registry.list.gameitf.inputitem_use(
              this.scene,
              this.name
            );
          });
          wh.inputitemdb[id]['btns'][0].on('pointerdown', function () {
            this.scene.registry.list.gameitf.inputitem_inputi(
              this.scene,
              this.name,
              '0'
            );
          });

          wh.inputitemdb[id]['amount'] = Array();
          // var amnt1 = wh.add.bitmapText(0, 0, 'fffb', '-', 14, 1);
          var amnt1 = wh.add.text(0, 0, '0', { fontFamily: 'ffff', fontSize: '8px', color: '#000' });

          wh.mapcontainer.add(amnt1);
          wh.mapcontainer.bringToTop(amnt1);
          amnt1.setPosition(bg.x - 14, bg.y + 32);
          wh.inputitemdb[id]['amount'][0] = amnt1;
          this.plog.log('inputitem wh.inputitemdb xx', id, wh.inputitemdb);
        }
        if (ccinputitem == 3) {
          var bg = wh.add.sprite(300, 300, 'inputitem3', 'b-1');
          bg.setOrigin(0.5, 0);
          wh.mapcontainer.add(bg);
          wh.mapcontainer.bringToTop(bg);
          bg.setPosition(
            npcsprite.x,
            npcsprite.y + npcsprite.displayHeight / 2
          );
          this.plog.log('createinputitem bg', bg);
          wh.inputitemdb[id]['bg'] = bg;
          var tmpt = wh.add.text(0, 0, '' + npcdata['name'], {
            fontFamily: 'ffff',
            fontSize: '8px',
            stroke: '#fff',
            strokeThickness: 0,
            align: 'right',
            boundsAlignH: 'right',
            color: '#000',
          });
          wh.mapcontainer.add(tmpt);
          wh.mapcontainer.bringToTop(tmpt);
          wh.inputitemdb[id]['text'] = tmpt;

          tmpt.setPosition(
            bg.x - bg.displayWidth / 2 + 5,
            npcsprite.y + npcsprite.displayHeight / 2 + 2
          );
          wh.inputitemdb[id]['btns'] = Array();
          var btn1 = wh.add.sprite(300, 300, "thesq", 'plus');
          btn1.displayWidth = btn1.displayHeight = 24;
          wh.mapcontainer.add(btn1);
          wh.mapcontainer.bringToTop(btn1);
          btn1.setPosition(bg.x - 32, bg.y + 32);
          wh.inputitemdb[id]['btns'][0] = btn1;
          var btn2 = wh.add.sprite(300, 300, "thesq", 'plus');
          btn2.displayWidth = btn2.displayHeight = 24;
          wh.mapcontainer.add(btn2);
          wh.mapcontainer.bringToTop(btn2);
          btn2.setPosition(bg.x, bg.y + 32);
          wh.inputitemdb[id]['btns'][1] = btn2;
          var btn3 = wh.add.sprite(300, 300, "thesq", 'plus');
          btn3.displayWidth = btn3.displayHeight = 24;
          wh.mapcontainer.add(btn3);
          wh.mapcontainer.bringToTop(btn3);
          btn3.setPosition(bg.x + 32, bg.y + 32);
          wh.inputitemdb[id]['btns'][2] = btn3;

          wh.inputitemdb[id]['amount'] = Array();
          //var amnt1 = wh.add.bitmapText(0, 0, 'fffb', '-', 14, 1);
          var amnt1 = wh.add.text(0, 0, '-', { fontFamily: 'ffff', fontSize: '8px', color: '#000' });
          wh.mapcontainer.add(amnt1);
          wh.mapcontainer.bringToTop(amnt1);
          amnt1.setPosition(bg.x - 48, bg.y + 32);
          wh.inputitemdb[id]['amount'][0] = amnt1;
          //var amnt2 = wh.add.bitmapText(0, 0, 'fffb', '-', 14, 1);
          var amnt2 = wh.add.text(0, 0, '-', { fontFamily: 'ffff', fontSize: '8px', color: '#000' });
          wh.mapcontainer.add(amnt2);
          wh.mapcontainer.bringToTop(amnt2);
          amnt2.setPosition(bg.x - 16, bg.y + 32);
          wh.inputitemdb[id]['amount'][1] = amnt2;
          // var amnt3 = wh.add.bitmapText(0, 0, 'fffb', '-', 14, 1);
          var amnt3 = wh.add.text(0, 0, '-', { fontFamily: 'ffff', fontSize: '8px', color: '#000' });

          wh.mapcontainer.add(amnt3);
          wh.mapcontainer.bringToTop(amnt3);
          amnt3.setPosition(bg.x + 16, bg.y + 32);
          wh.inputitemdb[id]['amount'][2] = amnt3;

          var usebtn = wh.add.image(0, 0, 'spacer');
          usebtn.displayWidth = bg.displayWidth;
          usebtn.displayHeight = 16;
          wh.mapcontainer.add(usebtn);
          wh.mapcontainer.bringToTop(usebtn);
          usebtn.setPosition(bg.x, bg.y + 32 + 24);
          wh.inputitemdb[id]['usebtn'] = usebtn;
          wh.inputitemdb[id]['btns'][0].name = 'inputitem' + id + '---0';
          wh.inputitemdb[id]['btns'][1].name = 'inputitem' + id + '---1';
          wh.inputitemdb[id]['btns'][2].name = 'inputitem' + id + '---2';
          wh.inputitemdb[id]['usebtn'].name = 'inputitem' + id;
          wh.inputitemdb[id]['btns'][0].setInteractive({ pixelPerfect: true });
          wh.inputitemdb[id]['btns'][1].setInteractive({ pixelPerfect: true });
          wh.inputitemdb[id]['btns'][2].setInteractive({ pixelPerfect: true });
          wh.inputitemdb[id]['usebtn'].setInteractive();
          wh.inputitemdb[id]['usebtn'].on('pointerdown', function () {
            //this.scene.plog.log('inputitem usebtn pointerdown', this);
            this.scene.registry.list.gameitf.inputitem_use(
              this.scene,
              this.name
            );
          });
          wh.inputitemdb[id]['btns'][0].on('pointerdown', function () {
            this.scene.registry.list.gameitf.inputitem_inputi(
              this.scene,
              this.name,
              '0'
            );
          });
          wh.inputitemdb[id]['btns'][1].on('pointerdown', function () {
            this.scene.registry.list.gameitf.inputitem_inputi(
              this.scene,
              this.name,
              '1'
            );
          });
          wh.inputitemdb[id]['btns'][2].on('pointerdown', function () {
            this.scene.registry.list.gameitf.inputitem_inputi(
              this.scene,
              this.name,
              '2'
            );
          });
          //this.plog.log('inputitem wh.inputitemdb', wh.inputitemdb);
        }
      }
    }
    this.plog.log('inputitem wh.inputitemdb', wh.inputitemdb);
    this.inputitem_updatedisplay(wh);
  }
  inputitem_use(wh, id) {
    this.plog.log('inputitem_use', wh, id);
    this.plog.log('inputitem_use data', wh.registry.list.rcvarpass.inputitem);
    var id2 = id.replace('inputitemnpc-', 'npc-');
    var db = wh.inputitemdb[id2];
    this.plog.log('inputitem_use data2', id2, wh.inputitemdb, db);
    var passall = true;
    for (const kb in db['btns']) {
      var vb = db['btns'][kb];
      var va = db['amount'][kb];
      this.plog.log('inputitem_use i', vb, va);
      var chk = wh.registry.list.rcvarpass.inputitem[vb.name];
      this.plog.log('inputitem_use chk', chk);
      if (chk == undefined) {
        passall = false;
        continue;
      }
      if (chk['amnt'] < 1) passall = false;
    }
    this.plog.log('inputitem_use passall', passall);
    if (passall == false) {
      wh.registry.list.phm.charsaybubble(
        this.wh.char_main,
        wh.gl('I have to place all items first')
      );
      return;
    }
    if (passall == true) {
      var codea = id.split('-');
      this.plog.log('inputitem_use use', codea);
      wh.registry.list.execmod.exec('inputitemuse:' + codea[1] + ':' + id, wh);
    }
  }
  inputitem_updatedisplay(wh) {
    this.plog.log('inputitem_updatedisplay', this.wh, wh);
    //this.plog.log(      'inputitem_updatedisplay data',      wh.registry.list.rcvarpass.inputitem    );
    //this.plog.log(      'inputitem_updatedisplay inputitemdb',      wh.inputitemdb    );
    //    
    for (const k in wh.inputitemdb) {
      var v = wh.inputitemdb[k];
      var ccinputitem = 0;
      if (v == undefined) continue;
      for (var ktmp in v['btns']) ccinputitem++;
      //ccinputitem = ccinputitem / 2;
      //this.plog.log('inputitem_updatedisplay ccinputitem', ccinputitem);
      //this.plog.log('inputitem_updatedisplay i', k, v);
      var npcsprite = wh.registry.list.phm.findnpc(wh, k);
      if (npcsprite != undefined) {
        var ida = k.split('-');

        var npcdata = wh.registry.list.rcvarpass.npc[ida[1]];
        //this.plog.log('inputitem_updatedisplay selfactivated1', npcdata['inputitem']);
        //this.plog.log('inputitem_updatedisplay npcsprite', npcsprite);
        var chksa = wh.registry.list.rcvarpass.inputitem["inputitem" + k + "---0"];
        //this.plog.log('inputitem_updatedisplay chksa', chksa,wh.registry.list.rcvarpass.inputitem,k);
        //this.plog.log('inputitem_updatedisplay chk', chk);
        if (chksa != undefined && chksa['selfactivate'] != undefined && chksa['selfactivate'] == 1) {

          this.plog.log('inputitem_updatedisplay npcdata', npcdata);
          if (npcdata != undefined && npcsprite != undefined &&
            npcdata['inputitem'] != null &&
            npcdata['inputitem'] != undefined) {
            if (npcdata['inputitem']['selfactivateani'] != undefined &&
              npcdata['inputitem']['selfactivateani'] != ""
            ) {

              v.effect = wh.registry.list.phm.mapitemeffect(npcsprite, npcdata['inputitem']['selfactivateani'] + '', 500);
              this.plog.log('inputitem_updatedisplay selfactivated', v.effects, npcsprite, npcdata['inputitem']['selfactivateani'] + '');
            } else {
              if (v.effect != undefined) {
                try {
                  v.effect.destroy();
                } catch (e) {
                  this.plog.log(e);
                }
              }
            }
          }
        } else {

          if (v.effect != undefined) {
            try {
              v.effect.destroy();
            } catch (e) {
              this.plog.log(e);
            }
          }
        }
      }
      var foundall = true;
      for (const kb in v['btns']) {
        var vb = v['btns'][kb];
        var vbt = v['amount'][kb];
        if (vb == undefined) continue;
        if (vbt == undefined) continue;
        //this.plog.log('inputitem_updatedisplay ib', kb, vb);
        var chk = wh.registry.list.rcvarpass.inputitem[vb.name];
        this.plog.log('inputitem_updatedisplay chk', chk);
        if (chk != undefined && chk['selfactivate'] != undefined && chk['selfactivate'] == 1) {

        }
        if (chk != undefined) {
          //found
          try {
            //this.plog.log('inputitem_updatedisplay found amnt', vbt);
            if (vb == undefined) continue;
            vb.setTexture('stackitem_tp', chk['item']);
            if (vbt == undefined) continue;
            vbt.setText('' + chk['amnt']);
          } catch (e) {
            this.plog.warn("!ERROR", e);
          }
        } else {
          try {
            vb.setTexture("thesq", 'plus');
          } catch (e) {
            this.plog.warn("!ERROR", e);
          }
          foundall = false;
          try {
            vbt.setText('-');
          } catch (e) {
            this.plog.log(e);
          }
        }
        v['btns'][kb].displayWidth =
          vb.displayHeight =
          vb.width =
          vb.height =
          24;
        if (chk == undefined) {
          foundall = false;
          continue;
        }
        if (chk['amnt'] < 1) foundall = false;

        //vb.setInteractive({ pixelPerfect: true });
        /*v['btns'][kb].removeAllListeners();
        v['btns'][kb].removeInteractive();
        v['btns'][kb].setInteractive();
        v['btns'][kb].on('pointerdown', function () {
          this.scene.registry.list.gameitf.inputitem_inputi(
            this.scene,
            this.name,""
          );
        });*/
        this.plog.log('inputitem_updatedisplay ib chk', kb, chk, vb);
      }
      //this.plog.log('inputitem_updatedisplay foundall', foundall, v['bg']);

      if (foundall == true) {
        this.plog.log('inputitem_updatedisplay foundall', v, wh.inputitemdb);
        try {
          if (ccinputitem == 1) v['bg'].setTexture('inputitem1', 'b-1');
          if (ccinputitem == 2) v['bg'].setTexture('inputitem2', 'b-1');
          if (ccinputitem == 3) v['bg'].setTexture('inputitem3', 'b-1');
        } catch (e) {
          this.plog.warn("!ERROR", e);
        }
        v['bg'].setOrigin(0.5, 0);
      } else {
        try {
          if (ccinputitem == 1) v['bg'].setTexture('inputitem1', 'c-1');
          if (ccinputitem == 2) v['bg'].setTexture('inputitem2', 'c-1');
          if (ccinputitem == 3) v['bg'].setTexture('inputitem3', 'c-1');
        } catch (e) {
          this.plog.warn("!ERROR", e);
        }
        v['bg'].setOrigin(0.5, 0);
      }
    }
  }
  async inputitem_inputi_useitem(a1, a2) {
    this.plog.log('inputitem_inputi_useitem this', this); //pickitem service
    this.plog.log('inputitem_inputi_useitem', a1, a2);
    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
    var res = await this.wh.registry.list.rchttp.getcommon2('inputitem', {
      mode: 'input',
      code: a1,
      npc: this.wh.registry.list.gameitf.lastinputitemid,
      slot: this.wh.registry.list.gameitf.lastinputitemidi,
    });
    await this.wh.registry.list.rchttp.updatecharinfo();
    this.wh.registry.list.rcvarpass.inputitem =
      await this.wh.registry.list.rchttp.getcommon2('inputitem', {
        mode: 'get',
      });
    this.wh.registry.list.gameitf.inputitem_updatedisplay(this.wh);
  }
  async inputitem_inputi(wh, id, i) {
    if (wh == undefined) {
      this.plog.error("gameitf inputitem_inputi() wh undefined", wh, id);
    }
    this.lastinputitemid = id;
    this.lastinputitemidi = i;
    this.wh = wh;
    i = Number(i);
    this.plog.log('inputitem_inputi', wh, id, i);
    this.plog.log(
      'inputitem_inputi inputitem',
      wh.registry.list.rcvarpass.inputitem
    );

    //chkifalreadygotitem
    var chk = this.wh.registry.list.rcvarpass.inputitem[this.lastinputitemid];
    this.plog.log('inputitem_inputi_useitem chk ', chk, this.lastinputitemid);
    if (chk != undefined) {
      this.plog.log('inputitem_inputi_useitem chk gotitem,removing');
      await wh.registry.list.rchttp.getcommon2('inputitem', {
        mode: 'remove',
        npc: this.lastinputitemid,
      });
      this.wh.registry.list.rcvarpass.inputitem =
        await this.wh.registry.list.rchttp.getcommon2('inputitem', {
          mode: 'get',
        });
      //await this.wh.registry.list.rchttp.updatecharinfo();
      this.wh.registry.list.gameitf.inputitem_updatedisplay(this.wh);
      return;
    }

    var ida = id.split('-');
    var idu = id + '---' + i;
    var npcdata = wh.registry.list.rcvarpass.npc[ida[1]];
    if (
      wh.registry.list.rcvarpass.inputitem[idu] == undefined ||
      wh.registry.list.rcvarpass.inputitem[idu] == ''
    ) {
      var itemrq = npcdata['inputitem']['input' + (i + 1)];
      this.plog.log(
        'inputitem_inputi npcdata',
        npcdata,
        npcdata['inputitem'],
        'input' + (i + 1),
        itemrq
      );
      wh.registry.list.pickitem.show(
        wh,
        this.inputitem_inputi_useitem,
        itemrq,
        'xx',
        'Select item '
      );
    } else {
    }
  }
  getgamemenuicon(wh, id) {
    for (var k in wh.gamemenu) {
      var namea = wh.gamemenu[k].name.split('-');
      //this.plog.log('getmainmenuicon i', k, wh.gamemenu[k]);
      if (namea[0] == id) return wh.gamemenu[k];
    }
    return;
  }
  disablegamemenuicon(wh, id) {
    this.plog.log("disablegamemenuicon", id);
    for (var k in wh.gamemenu) {
      var namea = wh.gamemenu[k].name.split('-');
      //this.plog.log('getmainmenuicon i', k, wh.gamemenu[k]);
      if (namea[0] == id) {
        wh.gamemenu[k].visible = false;;

        if (wh.gamemenuloading != undefined) {
          wh.gamemenuloading.setPosition(wh.gamemenudis[k].x, wh.gamemenudis[k].y);
          wh.gifscontainer.bringToTop(wh.gamemenuloading);
          wh.gamemenuloading.visible = true;
        }
      }
    }
    for (var k in wh.gamemenudis) {
      var namea = wh.gamemenudis[k].name.split('-');
      //this.plog.log('getmainmenuicon i', k, wh.gamemenu[k]);
      if (namea[0] == id) wh.gamemenudis[k].visible = true;;
    }
  }
  reddotlist = [];
  updatereddots(wh) {
    //return
    for (const k in this.reddotlist) {
      var v = this.reddotlist[k];
      //this.plog.log('updatereddots_i', k, v);
      if (v != undefined) {
        try {
          v.destroy();
        } catch (e) {
          this.plog.log("ERROR", e);
        }
        ///v.visible = false;
      }
    }


    if (wh.friendlisticon != undefined && wh.gamemenu_showing == true) {
      if (wh.registry.list.rcvarpass.activechar['newdm'] == 1) {
        var tmp = wh.registry.list.notif.show(wh, wh.friendlisticon, 'bl', 10);
        this.reddotlist.push(tmp);

      }
    }
    if (wh.iapstorecontainer != undefined && wh.registry.list.iapstore != undefined && wh.registry.list.iapstore.submn != undefined && wh.registry.list.iapstore.submn['free'] != undefined) {

      //this.plog.log("notif", wh.iapstorecontainer);
      //this.plog.log("notif iapstore", wh.registry.list.iapstore);
      var tmp = wh.registry.list.notif.show(wh, wh.registry.list.iapstore.submn['free'], 'right', 10);
      this.reddotlist.push(tmp);
    }
    var tgsprite = wh.registry.list.gameitf.getgamemenuicon(wh, 'shop');
    if (tgsprite != undefined && tgsprite.visible == true) {
      if (wh.registry.list.rcvarpass.activechar['iapstoredailyclaim'] == 1) {
        if (wh.gamemenu_showing == true) {
          var tmp = wh.registry.list.notif.show(wh, tgsprite, 'tr', 10);
          this.reddotlist.push(tmp);
          this.plog.log("notifshopdot", tmp);
          //this.plog.error("iapstoredailyclaim", tgsprite, wh.registry.list.rcvarpass.activechar['iapstoredailyclaim'], tmp);
        } else {
          var tmp = wh.registry.list.notif.show(wh, wh.gamemenu_main, 'tr', 10);
          this.reddotlist.push(tmp);
        }
        if (wh.iapstorecontainer != undefined && wh.registry.list.iapstore != undefined
          && wh.registry.list.iapstore.submn != undefined && wh.registry.list.iapstore.submn["free"] != undefined) {
          var tmp = wh.registry.list.notif.show(wh, wh.registry.list.iapstore.submn["free"], 'bl', 5);
          this.reddotlist.push(tmp);
        }
      }
    }
    if (wh.msgsicon != undefined && wh.gamemenu_showing == true) {
      //this.plog.log('charinfoupdate,msgsicon', wh.registry.list.rcvarpass.activechar['newmsg']);
      if (wh.registry.list.rcvarpass.activechar['newmsg'] == 1) {
        this.wiggle(wh, wh.msgsicon);
        var tmp = wh.registry.list.notif.show(wh, wh.msgsicon, 'bl', 10);
        this.reddotlist.push(tmp);
      }
    }



    var tgsprite = wh.registry.list.gameitf.getgamemenuicon(wh, 'shop');
    if (tgsprite != undefined && tgsprite.visible == true) {
      if (wh.registry.list.rcvarpass.activechar['iapstoredailyclaim'] == 1) {
        if (wh.gamemenu_showing == true) {
          var tmp = wh.registry.list.notif.show(wh, tgsprite, 'tr', 10);
          this.reddotlist.push(tmp);
          //this.plog.error("iapstoredailyclaim", tgsprite, wh.registry.list.rcvarpass.activechar['iapstoredailyclaim'], tmp);
        }
      }
    }
    //quest
    for (var k in wh.registry.list.rcvarpass.activechar['quest']) {
      var v = wh.registry.list.rcvarpass.activechar['quest'][k];
      if (wh.registry.list.rcreq.chk(v.thestep.requirements)) {
        //this.plog.log('updatereddots quest pass', v, wh);
        var tgsprite = wh.registry.list.gameitf.getgamemenuicon(wh, 'quest');
        if (tgsprite != undefined && tgsprite.visible == true) {
          var tmp = wh.registry.list.notif.show(wh, tgsprite, 'tr', 10);
          this.reddotlist.push(tmp);
        }
        if (
          wh.registry.list.questlist.btnstory != undefined &&
          wh.registry.list.questlist.btnstory.visible == true
        ) {
          var tmp = wh.registry.list.notif.show(
            wh,
            wh.registry.list.questlist.btnstory, 'tr', 10);
          //this.plog.log('wh.gamemenu_showing', wh.gamemenu_showing);
          this.reddotlist.push(tmp);
        }

        if (wh.gamemenu_showing == false) {
          var tmp = wh.registry.list.notif.show(wh, wh.gamemenu_main, 'tr', 10);
          this.reddotlist.push(tmp);
        }
      }
    }
    //quest daily
    var allpass = true;
    var neednotif = false;
    for (const k in wh.registry.list.rcvarpass.activechar['dailyquest']) {
      if (k == 'dailystat') continue;
      var v = wh.registry.list.rcvarpass.activechar['dailyquest'][k];
      if (v['num'] >= v['rqnum'] && v['claimed'] == 0) {
        neednotif = true;
      }
      if (v['num'] < v['rqnum']) {//&& v['claimed'] == 1) {
        allpass = false;
      }
    }
    if (neednotif == true) {
      var tgsprite = wh.registry.list.gameitf.getgamemenuicon(wh, 'quest');
      if (tgsprite != undefined && tgsprite.visible == true) {
        var tmp = wh.registry.list.notif.show(wh, tgsprite, 'tr', 10);
        this.reddotlist.push(tmp);
      }
      if (wh.registry.list.questlist.btndaily != undefined) {
        var tmp = wh.registry.list.notif.show(wh, wh.registry.list.questlist.btndaily, 'tr', 10);
        this.reddotlist.push(tmp);
      }
    }
    if (
      allpass == true &&
      wh.registry.list.rcvarpass.activechar['dailyquest']['dailystat'][
      'claimed'
      ] == 0
    ) {
      var tmp = wh.registry.list.notif.show(wh, wh.registry.list.questlist.btndaily, 'tr', 10);
      this.reddotlist.push(tmp);
    }

    //quest weekly
    var neednotif = false;
    var allpass = true;
    for (const k in wh.registry.list.rcvarpass.activechar['dailyquest']) {
      if (k == 'weeklystat') continue;
      var v = wh.registry.list.rcvarpass.activechar['dailyquest'][k];
      if (v['num'] >= v['rqnum'] && v['claimed'] == 0) {
        neednotif = true;
      }
      if (v['num'] < v['rqnum'] && v['claimed'] == 1) {
        allpass = false;
      }
      //this.plog.log('weekly_i', v, k, neednotif, allpass);
    }
    if (neednotif == true) {
      var tgsprite = wh.registry.list.gameitf.getgamemenuicon(wh, 'quest');
      if (tgsprite != undefined && tgsprite.visible == true) {
        var tmp = wh.registry.list.notif.show(wh, tgsprite, 'tr', 10);
        this.reddotlist.push(tmp);
      }
    }
    if (
      allpass == true &&
      wh.registry.list.rcvarpass.activechar['weeklyquest']['weeklystat'][
      'claimed'
      ] == 0
    ) {
      var tmp = wh.registry.list.notif.show(wh, wh.registry.list.questlist.btnweekly, 'tr', 10);
      this.reddotlist.push(tmp);
    }
  }
  makeid(length) {
    var result = '';
    //var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  updateava(wh) {
    var randid = this.makeid(10);
    this.plog.log('updateava', randid, wh);
    var avatarsize = 58;

    let loader = new Phaser.Loader.LoaderPlugin(wh);
    try {
      //wh.textures.remove('playerava');
    } catch (err) {
      this.plog.log('ERROR', err);
    }

    wh.currentcharavar = 'playerava' + randid;
    loader.image('playerava' + randid, wh.registry.list.rcvarpass.rcserver_url + "charava/" + wh.registry.list.rcvarpass.activechar['sprite'] + ".png");
    //this.plog.log(      'updateava playerava' + randid,      wh.registry.list.rcvarpass.gamer['avatar']    );

    loader.once(Phaser.Loader.Events.COMPLETE, () => {
      if (wh.playerAvatar != undefined) {
        wh.playerAvatar.setTexture('playerava' + randid);
        wh.playerAvatar.displayWidth = avatarsize;
        wh.playerAvatar.displayHeight = avatarsize;
      }
    });
    loader.start();
  }
  async bugreport() {
    var cfm = await this.wh.registry.list.rctoast.rcconfirm(this.wh, "Report Bug/Suggestion", "This will open a webpage for you to submit bug report/submit suggestionะ");
    if (cfm == "ok") {
      var usesv = await this.wh.registry.list.rcstorage.get('rc_usesv');

      //this.wh.registry.list.execmod.openWebpage("https://docs.google.com/forms/d/e/1FAIpQLSdbFLGu3bci-R1CdusQuDFM_bz0PPnsf4m5uHsfferstld5Sg/viewform?usp=sf_link&usp=pp_url&entry.2023924251=" + usesv + "&entry.1281232028=" + this.wh.registry.list.rcvarpass.activechar["name"]);
      this.wh.registry.list.execmod.openWebpage("https://discord.gg/JCyPFjJqaS");

    }
  }
  buildemotekb_showing = false;
  buildemotekb() {
    //this.plog.error(this.wh,this.wh.game.canvas)
    if (this.buildemotekb_showing == true) {
      this.buildemotekb_close();
      return;
    }
    this.buildemotekb_close();
    this.buildemotekb_showing = true;
    var width = this.wh.game.canvas.width;
    var height = this.wh.game.canvas.height;
    var colnum = 8;
    var i = 0;
    var ypos = 190;
    var boxwh = 24;
    var icol = 0;
    var irow = 0;
    var xpos = (width / 2) - (boxwh * colnum / 2);
    xpos = width - (boxwh * colnum);
    this.emotebg = this.wh.add.rectangle(width / 2, height / 2, width, height, 0x000000);
    this.emotebg.setAlpha(0.01);
    this.emotebg.removeAllListeners();
    this.emotebg.setInteractive();

    this.emotebg.on("pointerdown", function (pointer, localX, localY, event) {
      this.buildemotekb_close();
      try {
        event.stopPropagation();
      } catch (e) {
        console.log(e);
      }
    }, this);

    for (const k in this.emotedba) {
      var v = this.emotedba[k];
      //this.plog.log(i, v);
      this.emoteobj[v] = this.wh.add.sprite(xpos + (icol * boxwh), ypos + (irow * boxwh), "emotes", v + "-1");
      this.emoteobj[v].name = v;
      this.emoteobj[v].removeAllListeners();
      this.emoteobj[v].setInteractive();
      this.emoteobj[v].on("pointerdown", function () {
        //this.plog.log(this.scene.registry.list.gameitf);
        this.scene.registry.list.wsrc.sendchat(":emote:" + this.name + ":");
        //this.scene.registry.list.gameitf.buildemotekb_close();
        //.bind(this.scene.registry.list.gameitf);
      });

      i++;
      icol++;
      if (icol == colnum) {
        icol = 0;
        // ypos += boxwh;
        irow++;
      }
    }
    this.wh.mapcami(this.wh, this.emoteobj);
  }
  buildemotekb_close() {
    this.buildemotekb_showing = false;
    this.plog.log("buildemotekb_close", this);
    if (this.emotebg != undefined) {
      this.emotebg.destroy();
      this.emotebg = undefined;
    }
    for (const k in this.emoteobj) {
      if (this.emoteobj[k] != undefined) {
        this.emoteobj[k].destroy();
        this.emoteobj[k] = undefined;
      }
    }
  }
  async savesnapshot(wh, name, iden) {
    this.plog.log("savesnapshot", wh, name);
    wh.game.renderer.snapshot(function (image) {
      this.savesnapshot_save(wh, name, iden, image.src);
    }.bind(this), "image/jpeg", 0.3);
  }
  async savesnapshot_save(wh, name, iden, img) {
    this.plog.log("savesnapshot_save", wh, name);
    var res = wh.registry.list.rchttp.getcommon2('savesnapshot', {
      name: name,
      iden: iden,
      imgdata: img
    });
    this.plog.log("savesnapshot_save done", res);
  }

  async savesnapshotsys(wh, name, iden) {
    this.plog.log("savesnapshotsys", wh, name);
    wh.game.renderer.snapshot(function (image) {
      this.savesnapshotsys_save(wh, name, iden, image.src);
    }.bind(this), "image/jpeg", 0.3);
  }
  async savesnapshotsys_save(wh, name, iden, img) {
    this.plog.log("savesnapshotsys_save", wh, name);
    var res = wh.registry.list.rchttp.getcommon2('savesnapshotsys', {
      name: name,
      iden: iden,
      imgdata: img
    });
    this.plog.log("savesnapshotsys_save done", res);
  }
  async makeguimobile(a1) {
    this.plog.log(".makeguimobile.wh", this.wh, this);
    var width = this.wh.game.canvas.width;
    var height = this.wh.game.canvas.height;
    if (this.wh.ismobileguimode() == true) {

    } else {
      return;
    }
    this.plog.warn("makeguimobile", a1);

    if (a1 == undefined || a1.scene == undefined) {
      this.plog.log("makeguimobile, undefined argument");
      return;
    }

    a1.x = width / 2;
    a1.y = height / 2;

    var adjustx = 0
    var dabg;
    var datitle;
    var dashopclosebtn;
    var dawinguiltab;
    var daspecial;
    var daspecialshine;
    var dashopdragbtn;
    var dawisdomcaticon;
    if (a1.name == "partymycontainer" ||
      a1.name == "zcharinfocontainer") {
      //ignore small size
      return;
    }
    if (this.wh.mobfillbg == undefined) {
      //this.wh.mobfillbg = this.wh.add.sprite(0, 0, "thecolor", "yellow");
      this.wh.mobfillbg = this.wh.add.image(0, 0, "mobileguibg");
      this.wh.mobfillbg.setOrigin(0.5, 0.5);
      this.wh.mobfillbg.setInteractive(); //{ pixelPerfect: true }
      this.wh.mobfillbg.removeAllListeners();

      this.wh.mobfillbg.setAlpha(0);
    }
    this.wh.mobfillbg.visible = true;
    this.wh.mobfillbg.setDepth(a1.depth - 1);
    this.wh.mobfillbg.displayWidth = width;
    this.wh.mobfillbg.displayHeight = height;
    this.wh.mobfillbg.setPosition(width / 2, height / 2);
    //a1.setScale(1);

    this.wh.tweens.add({
      targets: this.wh.mobfillbg,
      alpha: 1,
      ease: 'Power3',
      duration: 200, // duration of animation; higher=slower
      delay: 0,
    });
    /*
        try {
          this.wh.cameras.main.postFX.clear();
          this.wh.cameras.main.postFX.addBlur(0,2,2,1);
        } catch (e) {
          console.log(e);
        }*/
    if (a1.name == "inventorycontainer_stackitem") {
      adjustx = 0;
      dabg = this.wh.registry.list.stackinven.shopguibg;
      //dawinguiltab = this.wh.registry.list.stackinven.winguiltab;
      datitle = this.wh.registry.list.stackinven.title;
      dashopclosebtn = this.wh.registry.list.stackinven.shopclosebtn
      daspecial = this.wh.registry.list.stackinven.sellanywhere;
      daspecialshine = this.wh.registry.list.stackinven.shine1;
      dashopdragbtn = this.wh.registry.list.stackinven.shopdragbtn;
      dawisdomcaticon = this.wh.registry.list.stackinven.wisdomcaticon;
    }
    if (a1.name == "inventorycontainer_item") {
      adjustx = 0;
      dabg = this.wh.registry.list.inven.shopguibg;
      //dawinguiltab = this.wh.registry.list.inven.winguiltab;
      datitle = this.wh.registry.list.inven.title;
      dashopclosebtn = this.wh.registry.list.inven.shopclosebtn
      daspecial = this.wh.registry.list.inven.sellanywhere;
      daspecialshine = this.wh.registry.list.inven.shine1;
      dashopdragbtn = this.wh.registry.list.inven.shopdragbtn;
      dawisdomcaticon = this.wh.registry.list.inven.wisdomcaticon;
    }
    if (a1.name == "attricontainer") {
      dabg = this.wh.registry.list.attri.shopguibg;
      dawinguiltab = this.wh.registry.list.attri.winguiltab;
      datitle = this.wh.registry.list.attri.title;
      dashopclosebtn = this.wh.registry.list.attri.shopclosebtn
      daspecial = this.wh.registry.list.attri.sellanywhere;
      daspecialshine = this.wh.registry.list.attri.shine1;
      dashopdragbtn = this.wh.registry.list.attri.shopdragbtn;
      dawisdomcaticon = this.wh.registry.list.attri.helpicon;
    }
    if (a1.name == "equipmentcontainer") {
      //dabg = this.wh.registry.list.equipment.shopguibg;
      dawinguiltab = this.wh.registry.list.equipment.winguiltab;
      datitle = this.wh.registry.list.equipment.title;
      dashopclosebtn = this.wh.registry.list.equipment.shopclosebtn
      daspecial = this.wh.registry.list.equipment.sellanywhere;
      daspecialshine = this.wh.registry.list.equipment.shine1;
      dashopdragbtn = this.wh.registry.list.equipment.shopdragbtn;
      dawisdomcaticon = this.wh.registry.list.equipment.wisdomcaticon;
    }


    if (a1.name == "skillcraftcontainer") {
      dabg = this.wh.registry.list.equipment.shopguibg;
      dawinguiltab = this.wh.registry.list.skillcraft.winguiltab;
      datitle = this.wh.registry.list.skillcraft.title;
      dashopclosebtn = this.wh.registry.list.skillcraft.shopclosebtn
      daspecial = this.wh.registry.list.skillcraft.sellanywhere;
      daspecialshine = this.wh.registry.list.skillcraft.shine1;
      dashopdragbtn = this.wh.registry.list.skillcraft.shopdragbtn;
      //dawisdomcaticon = this.wh.registry.list.skillcraft.wisdomcaticon;
    }
    if (a1.name == "skillcontainer") {
      dabg = this.wh.registry.list.rcskill.shopguibg;
      dawinguiltab = this.wh.registry.list.rcskill.winguiltab;
      datitle = this.wh.registry.list.rcskill.title;
      dashopclosebtn = this.wh.registry.list.rcskill.shopclosebtn
      daspecial = this.wh.registry.list.rcskill.sellanywhere;
      daspecialshine = this.wh.registry.list.rcskill.shine1;
      dashopdragbtn = this.wh.registry.list.rcskill.shopdragbtn;
      dawisdomcaticon = this.wh.registry.list.rcskill.wisdomcaticon;
    }
    if (a1.name == "pettravelcontainer") {
      dabg = this.wh.registry.list.pettravel.shopguibg;
      dawinguiltab = this.wh.registry.list.pettravel.winguiltab;
      datitle = this.wh.registry.list.pettravel.title;
      dashopclosebtn = this.wh.registry.list.pettravel.shopclosebtn
      daspecial = this.wh.registry.list.pettravel.healallpets;
      daspecialshine = this.wh.registry.list.pettravel.shine1;
      dashopdragbtn = this.wh.registry.list.pettravel.shopdragbtn;
      dawisdomcaticon = this.wh.registry.list.pettravel.wisdomcaticon;
    }
    if (a1.name == "petskillcontainer") {
      dabg = this.wh.registry.list.petskill.shopguibg;
      dawinguiltab = this.wh.registry.list.petskill.winguiltab;
      datitle = this.wh.registry.list.petskill.title;
      dashopclosebtn = this.wh.registry.list.petskill.shopclosebtn
      daspecial = this.wh.registry.list.petskill.healallpets;
      daspecialshine = this.wh.registry.list.petskill.shine1;
      dashopdragbtn = this.wh.registry.list.petskill.shopdragbtn;
      dawisdomcaticon = this.wh.registry.list.petskill.wisdomcaticon;
    }
    if (a1.name == "petguicontainer") {
      dabg = this.wh.registry.list.petgui.shopguibg;
      dawinguiltab = this.wh.registry.list.petgui.winguiltab;
      datitle = this.wh.registry.list.petgui.title;
      dashopclosebtn = this.wh.registry.list.petgui.shopclosebtn
      daspecial = this.wh.registry.list.petgui.healallpets;
      daspecialshine = this.wh.registry.list.petgui.shine1;
      dashopdragbtn = this.wh.registry.list.petgui.shopdragbtn;
      dawisdomcaticon = this.wh.registry.list.petgui.wisdomcaticon;
    }
    if (a1.name == "msgscontainer") {
      dabg = this.wh.registry.list.msgs.shopguibg;
      dawinguiltab = this.wh.registry.list.msgs.winguiltab;
      datitle = this.wh.registry.list.msgs.title;
      dashopclosebtn = this.wh.registry.list.msgs.shopclosebtn
      daspecial = this.wh.registry.list.msgs.healallpets;
      daspecialshine = this.wh.registry.list.msgs.shine1;
      dashopdragbtn = this.wh.registry.list.msgs.shopdragbtn;
      dawisdomcaticon = this.wh.registry.list.msgs.wisdomcaticon;
    }
    if (a1.name == "friendlistcontainer") {
      dabg = this.wh.registry.list.friendlist.shopguibg;
      dawinguiltab = this.wh.registry.list.friendlist.winguiltab;
      datitle = this.wh.registry.list.friendlist.title;
      dashopclosebtn = this.wh.registry.list.friendlist.shopclosebtn
      daspecial = this.wh.registry.list.friendlist.healallpets;
      daspecialshine = this.wh.registry.list.friendlist.shine1;
      dashopdragbtn = this.wh.registry.list.friendlist.shopdragbtn;
      dawisdomcaticon = this.wh.registry.list.friendlist.helpicon;
    }
    if (a1.name == "partymapcontainer") {
      dabg = this.wh.registry.list.partymap.shopguibg;
      dawinguiltab = this.wh.registry.list.partymap.winguiltab;
      datitle = this.wh.registry.list.partymap.title;
      dashopclosebtn = this.wh.registry.list.partymap.shopclosebtn
      daspecial = this.wh.registry.list.partymap.healallpets;
      daspecialshine = this.wh.registry.list.partymap.shine1;
      dashopdragbtn = this.wh.registry.list.partymap.shopdragbtn;
      dawisdomcaticon = this.wh.registry.list.partymap.helpicon;
    }
    if (a1.name == "shopcontainer_stackitem") {
      dabg = this.wh.registry.list.npcshop.shopguibg;
      dawinguiltab = this.wh.registry.list.npcshop.winguiltab;
      datitle = this.wh.registry.list.npcshop.title;
      dashopclosebtn = this.wh.registry.list.npcshop.shopclosebtn
      daspecial = this.wh.registry.list.npcshop.healallpets;
      daspecialshine = this.wh.registry.list.npcshop.shine1;
      dashopdragbtn = this.wh.registry.list.npcshop.shopdragbtn;
      dawisdomcaticon = this.wh.registry.list.npcshop.helpicon;
    }
    if (a1.name == "questlistcontainer") {
      dabg = this.wh.registry.list.questlist.shopguibg;
      dawinguiltab = this.wh.registry.list.questlist.winguiltab;
      datitle = this.wh.registry.list.questlist.title;
      dashopclosebtn = this.wh.registry.list.questlist.shopclosebtn
      daspecial = this.wh.registry.list.questlist.healallpets;
      daspecialshine = this.wh.registry.list.questlist.shine1;
      dashopdragbtn = this.wh.registry.list.questlist.shopdragbtn;
      dawisdomcaticon = this.wh.registry.list.questlist.helpicon;
    }
    if (a1.name == "questinfocontainer") {
      dabg = this.wh.registry.list.questinfo.shopguibg;
      dawinguiltab = this.wh.registry.list.questinfo.winguiltab;
      datitle = this.wh.registry.list.questinfo.title;
      dashopclosebtn = this.wh.registry.list.questinfo.shopclosebtn
      daspecial = this.wh.registry.list.questinfo.healallpets;
      daspecialshine = this.wh.registry.list.questinfo.shine1;
      dashopdragbtn = this.wh.registry.list.questinfo.shopdragbtn;
      dawisdomcaticon = this.wh.registry.list.questinfo.helpicon;
    }
    if (a1.name == "homeinventorycontainer_stackitem") {
      dabg = this.wh.registry.list.homeinvens.shopguibg;
      dawinguiltab = this.wh.registry.list.homeinvens.winguiltab;
      datitle = this.wh.registry.list.homeinvens.title;
      dashopclosebtn = this.wh.registry.list.homeinvens.shopclosebtn
      daspecial = this.wh.registry.list.homeinvens.healallpets;
      daspecialshine = this.wh.registry.list.homeinvens.shine1;
      dashopdragbtn = this.wh.registry.list.homeinvens.shopdragbtn;
      dawisdomcaticon = this.wh.registry.list.homeinvens.helpicon;
    }
    if (a1.name == "homeinventorycontainer_item") {
      dabg = this.wh.registry.list.homeinveni.shopguibg;
      dawinguiltab = this.wh.registry.list.homeinveni.winguiltab;
      datitle = this.wh.registry.list.homeinveni.title;
      dashopclosebtn = this.wh.registry.list.homeinveni.shopclosebtn
      daspecial = this.wh.registry.list.homeinveni.healallpets;
      daspecialshine = this.wh.registry.list.homeinveni.shine1;
      dashopdragbtn = this.wh.registry.list.homeinveni.shopdragbtn;
      dawisdomcaticon = this.wh.registry.list.homeinveni.helpicon;
    }
    if (a1.name == "iapstorecontainer") {
      dabg = this.wh.registry.list.iapstore.shopguibg;
      //dawinguiltab = this.wh.registry.list.iapstore.winguiltab;
      datitle = this.wh.registry.list.iapstore.title;
      dashopclosebtn = this.wh.registry.list.iapstore.shopclosebtn
      daspecial = this.wh.registry.list.iapstore.healallpets;
      daspecialshine = this.wh.registry.list.iapstore.shine1;
      dashopdragbtn = this.wh.registry.list.iapstore.shopdragbtn;
      dawisdomcaticon = this.wh.registry.list.iapstore.helpicon;
    }
    if (a1.name == "zcharinfocontainer") {
      dabg = this.wh.registry.list.zchari.shopguibg;
      //dawinguiltab = this.wh.registry.list.iapstore.winguiltab;
      datitle = this.wh.registry.list.zchari.title;
      dashopclosebtn = this.wh.registry.list.zchari.shopclosebtn
      //daspecial = this.wh.registry.list.zchari.healallpets;
      //daspecialshine = this.wh.registry.list.zchari.shine1;
      dashopdragbtn = this.wh.registry.list.zchari.shopdragbtn;
      //dawisdomcaticon = this.wh.registry.list.zchari.helpicon;
    }

    a1.x = (width / 2) - adjustx;

    if (dabg != undefined) {
      //dabg.displayWidth = 5000;//width * 1.5;
      //dabg.displayHeight = 5000;//height * 1.5;
      //dabg.visible = false;
    }
    if (dashopdragbtn != undefined) {
      this.plog.log("makeguimobile, dashopdragbtn", dashopdragbtn);
      dashopdragbtn.removeAllListeners();
      dashopdragbtn.off();
      dashopdragbtn.removeInteractive();
    }
    if (datitle != undefined) {
      this.plog.log("makeguimobile, datitle", datitle);
      //datitle.y = 0 - (height / 2) + datitle.displayHeight;
    }
    var scaled = 1;
    if (a1.scale != 1) {
      var scaled = 1 - a1.scale;
      scaled = 1 + (scaled);
    }
    if (dashopclosebtn != undefined) {
      if (this.wh.moblocaladdbackbtn == undefined) {
        this.wh.moblocaladdbackbtn = this.wh.add.sprite(300, 300, "thesq", 'back');
      }
      this.wh.moblocaladdbackbtn.setDepth(a1.depth + 1);
      this.wh.moblocaladdbackbtn.displayWidth = this.wh.moblocaladdbackbtn.displayHeight = 72;
      this.wh.moblocaladdbackbtn.setOrigin(0, 1);
      this.wh.moblocaladdbackbtn.setPosition(0, height);
      //localaddbackbtn.setDepth(50010);
      this.wh.moblocaladdbackbtn.setInteractive();
      this.wh.moblocaladdbackbtn.removeAllListeners();
      this.wh.moblocaladdbackbtn.on(
        'pointerup',
        async function () {
          //this.scene.registry.list["thethis"].phm.floorclick(this);
          //console.log(this, arguments);
          if (arguments[1] != undefined) {
            arguments[1].visible = false;
          }
          await this.wh.registry.list.gameitf.closegui(this.wh, arguments[0]);
        }.bind(this, a1, this.wh.moblocaladdbackbtn),
        this
      );
      //console.log(this.wh.moblocaladdbackbtn, a1);
      this.wh.moblocaladdbackbtn.visible = true;
    }
    /*
        if (dashopclosebtn != undefined) {
          dashopclosebtn.setOrigin(0.5, 0.5);
    
          this.plog.log("makeguimobile, dashopclosebtn", dashopclosebtn, a1.scale, scaled);
          dashopclosebtn.displayWidth = dashopclosebtn.displayHeight = 64;
          dashopclosebtn.y = 0 - (height * scaled / 2) + dashopclosebtn.displayHeight / 2 + 3;
          dashopclosebtn.x = (width * scaled / 2) - (dashopclosebtn.displayWidth / 2) - 3;
          //dashopclosebtn.y = dashopclosebtn.y * scaled;
          //dashopclosebtn.x = dashopclosebtn.x * scaled;
    
          this.plog.log("makeguimobile, dashopclosebtn", dashopclosebtn);
          if (dawisdomcaticon != undefined) {
            this.plog.log("makeguimobile, dawisdomcaticon", dawisdomcaticon);
            dawisdomcaticon.setOrigin(0.5, 0.5);
            dawisdomcaticon.y = dashopclosebtn.y + (dashopclosebtn.displayHeight + 5);
            dawisdomcaticon.x = dashopclosebtn.x;
    
          }
        }*/
    if (dawinguiltab != undefined) {
      var ow = dawinguiltab.displayWidth;
      var oh = dawinguiltab.displayHeight;
      dawinguiltab.setTexture("thesq", "blank");
      dawinguiltab.displayWidth = ow;
      dawinguiltab.displayHeight = oh;
    }/*
    if (daspecial != undefined) {
      this.plog.log("makeguimobile, daspecial", daspecial);
      daspecial.setOrigin(0.5, 0.5);
      daspecial.y = 0 - (height / 2) + daspecial.displayHeight / 2;
      daspecial.x = 0 - (width / 2) + daspecial.displayWidth / 2;
      daspecial.y = daspecial.y * scaled;
      daspecial.x = daspecial.x * scaled;
      if (daspecialshine != undefined) {
        daspecialshine.x = daspecial.x;
        daspecialshine.y = daspecial.y;
      }

    }*/

  }
  showsettings() {
    var width = this.wh.game.canvas.width;
    this.wh.settingiconactivetime = 15;
    this.wh.tweens.add({
      targets: this.wh.iconsettings,
      x: width - 20,
      yoyo: false,
      repeat: 0,
      duration: 350,
      ease: 'Power3',
      delay: 0,
    });
    this.wh.tweens.add({
      targets: this.wh.iconchatbubble,
      x: width - 20,
      yoyo: false,
      repeat: 0,
      duration: 350,
      ease: 'Power3',
      delay: 100,
    });
    this.wh.tweens.add({
      targets: this.wh.iconbugreport,
      x: width - 20,
      yoyo: false,
      repeat: 0,
      duration: 350,
      ease: 'Power3',
      delay: 200,
    });
    this.wh.tweens.add({
      targets: this.wh.iconmusic,
      x: width - 20,
      yoyo: false,
      repeat: 0,
      duration: 350,
      ease: 'Power3',
      delay: 300,
    });
  }
  hidesettings() {

    var width = this.wh.game.canvas.width;
    this.wh.settingiconactivetime = 0;
    this.wh.tweens.add({
      targets: this.wh.iconsettings,
      x: width + 20,
      yoyo: false,
      repeat: 0,
      duration: 350,
      ease: 'Power3',
      delay: 0,
    });
    this.wh.tweens.add({
      targets: this.wh.iconchatbubble,
      x: width + 20,
      yoyo: false,
      repeat: 0,
      duration: 350,
      ease: 'Power3',
      delay: 100,
    });
    this.wh.tweens.add({
      targets: this.wh.iconbugreport,
      x: width + 20,
      yoyo: false,
      repeat: 0,
      duration: 350,
      ease: 'Power3',
      delay: 200,
    });
    this.wh.tweens.add({
      targets: this.wh.iconmusic,
      x: width + 20,
      yoyo: false,
      repeat: 0,
      duration: 350,
      ease: 'Power3',
      delay: 300,
    });
  }
  genchareff(lv, wh) {
    if (lv >= 20) {
      var tmp = wh.add.sprite(100, 100, "aura", "aura_d");
      tmp.play("aura_d");
      return tmp;
    }
    if (lv >= 16) {
      var tmp = wh.add.sprite(100, 100, "aura", "aura_c");
      tmp.play("aura_c");
      return tmp;
    }
    if (lv >= 12) {
      var tmp = wh.add.sprite(100, 100, "aura", "aura_b");
      tmp.play("aura_b");
      return tmp;
    }
    if (lv >= 8) {
      var tmp = wh.add.sprite(100, 100, "aura", "aura_b");
      tmp.play("aura_a");
      return tmp;
    }

    var emitcol = ['white', 'grey'];
    if (lv >= 6) {
      emitcol = ['blue', 'darkblue', 'white', 'lightblue'];
    } else if (lv >= 4) {
      emitcol = ['white', 'red', 'darkred', 'orange'];
    } else if (lv >= 2) {
      emitcol = ['white', 'orange'];
    }
    var tmp = wh.add.particles(400, 200, 'thecolor', {
      frame: emitcol,
      //["white","black","blue"]
      //color: [0x040d61, 0xfacc22, 0xf89800, 0xf83600, 0x9f0404, 0x4b4a4f, 0x353438, 0x040404],
      lifespan: 800,
      angle: { min: -130, max: -50 },
      scale: 0.5,
      speed: { min: 10, max: 50 },
      advance: 2000,
    });
    return tmp;

  }
}
