import { Injectable } from '@angular/core';
import { RCVarsService } from './rcvars-service.service';
import { RCOSDService } from './rcosd.service';
import { RCHTTP } from './rchttp.service';
import { PGBar } from './pgbar';
import { plog } from './plog.service';

@Injectable({
  providedIn: 'root',
})
export class PetUpLevelService {
  loading;
  plog;
  title;
  dwidescr_weekly;
  wwdata;
  dwichest_weekly;
  wwtitle = [];
  wwdescr = [];
  wwicon = [];
  wwbtn = [];
  tmpskname = [];
  tmpskdescr = [];
  tmpdiamonneed = [];


  lastdat;
  ddtitle = [];
  dddescr = [];
  ddicon = [];
  ddbtn = [];
  petxpbar = [];
  pethpbar = [];
  dwidescr_daily;
  dwichest_daily;
  countpets;
  petuplevelcontainer_char;
  maskg;
  maskm;
  selectcircle;
  scrollareabox;
  shopclosebtn;
  itemdescr;
  eqitems = [];
  eqitemsp = [];
  itemwh = 54;
  shopguibg;
  wh;
  menubaritem = [];
  btnstory;
  btndaily;
  btnweekly;
  countpet;
  healbtndis = [];
  healbtn = [];
  empty;

  constructor(
    public rcvarsservice: RCVarsService,
    public rcosd: RCOSDService,
    public rchttp: RCHTTP
  ) {
    this.plog = new plog();
  }

  loadingshow() {
    this.wh.petuplevelcontainer.bringToTop(this.loading);
    this.loading.visible = true;
  }
  loadinghide() {
    this.loading.visible = false;
  }
  firsttimerun = true;
  firsttimerunfunc() {
    if (this.firsttimerun == true) {
      this.wh.registry.list.gameitf.guiwindowclick(this.wh.petuplevelcontainer);
    }
    this.firsttimerun = false;
  }

  show(wh, forcefocus = false) {
    if (forcefocus == true) this.firsttimerun = true;
    this.wh = wh;
    if (wh.petuplevelcontainer != undefined) {
      wh.petuplevelcontainer.visible = true;
      this.setmaskposition();

      this.buildpetctnr();
      this.rcvarsservice.gameobj.registry.list.gameitf.guiwindowclick(
        this.rcvarsservice.gameobj.scene.scenes[0].petuplevelcontainer
      );
      return;
    }
    var width = wh.game.canvas.width;
    var height = wh.game.canvas.height;
    this.plog.log('petgui show', wh, this);

    const shopguibg = wh.add.image(0, 0, 'blankbarguibg');//petsguibg
    shopguibg.setOrigin(0.5, 0.5);
    shopguibg.setInteractive({ pixelPerfect: true });
    shopguibg.removeAllListeners();
    this.shopguibg = shopguibg;
    //this.plog.log("questgui var", this.rcvarsservice);



    var shopclosebtn = wh.add.sprite(300, 300, "thesq", 'close');
    this.shopclosebtn = shopclosebtn;
    this.shopclosebtn.setOrigin(0.5, 0.5);
    this.shopclosebtn.setPosition(155, -200);
    shopclosebtn.displayWidth = 40;
    shopclosebtn.displayHeight = 40;
    this.shopclosebtn.setInteractive();
    this.shopclosebtn.removeAllListeners();
    this.shopclosebtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        //this.scene.registry.list["thethis"].phm.floorclick(this);
        this.plog.log(
          'gc.npcbackbtn pointerup',
          pointer,
          localX,
          localY,
          event,
          this
        );
        this.show_close(wh);
      },
      this
    );

    wh.petuplevelcontainer = wh.add.container(300, 300);
    wh.petuplevelcontainer.name = "petuplevelcontainer";
    wh.petuplevelcontainer.add(this.shopclosebtn);
    wh.petuplevelcontainer.add(shopguibg);
    wh.petuplevelcontainer.bringToTop(this.shopclosebtn);
    var itemdescr = wh.add.text(0, 0, this.wh.trs("f:petuplevelcontainer:Upgrade Pet Level With Diamonds===Upgrade Pet Level With Diamonds"), wh.fs["winguibardescr"]);
    wh.petuplevelcontainer.add(itemdescr);
    itemdescr.setOrigin(0, 0);
    itemdescr.setPosition(-145, -175);
    this.itemdescr = itemdescr;


    this.loading = wh.add.sprite(300, 300, 'pixelloading').play('pixelloading_b');
    this.loading.setOrigin(0.5, 0.5);
    this.loading.setPosition(0, 0);
    this.loading.displayWidth = this.loading.displayHeight = 60;
    wh.petuplevelcontainer.add(this.loading);
    wh.petuplevelcontainer.bringToTop(this.loading);


    this.empty = wh.add.image(300, 300, 'empty');
    this.empty.setOrigin(0.5, 0.5);
    this.empty.setPosition(0, 0);
    this.empty.displayWidth = this.empty.displayHeight = 300;
    wh.petuplevelcontainer.add(this.empty);
    wh.petuplevelcontainer.bringToTop(this.empty);
    this.empty.visible = false;

    //drag
    var shopdragbtn = wh.add.image(300, 300, 'spacer');
    wh.shopdragbtn = shopdragbtn;
    wh.shopdragbtn.setOrigin(0.5, 0.5);
    wh.shopdragbtn.setPosition(0, -200);
    shopdragbtn.displayWidth = 270;
    shopdragbtn.displayHeight = 60;
    wh.shopdragbtn.setInteractive();
    wh.shopdragbtn.removeAllListeners();
    wh.shopdragbtn.on(
      'pointerout',
      function (pointer, localX, localY, event) {
        this.rcvarsservice.gameobj.scene.scenes[0].petuplevelcontainer.dragactive =
          false;
      },
      this
    );
    wh.shopdragbtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        this.rcvarsservice.gameobj.scene.scenes[0].petuplevelcontainer.dragactive =
          false;
      },
      this
    );
    wh.shopdragbtn.on(
      'pointerdown',
      function (pointer, localX, localY, event) {
        this.rcvarsservice.gameobj.registry.list.gameitf.guiwindowclick(
          this.rcvarsservice.gameobj.scene.scenes[0].petuplevelcontainer
        );

        this.rcvarsservice.gameobj.scene.scenes[0].petuplevelcontainer.dragactive =
          true;
        this.rcvarsservice.gameobj.scene.scenes[0].petuplevelcontainer.dragactivex =
          this.rcvarsservice.gameobj.scene.scenes[0].petuplevelcontainer.x;
        this.rcvarsservice.gameobj.scene.scenes[0].petuplevelcontainer.dragactivey =
          this.rcvarsservice.gameobj.scene.scenes[0].petuplevelcontainer.y;
        this.rcvarsservice.gameobj.scene.scenes[0].petuplevelcontainer.dragactivepx =
          pointer.x;
        this.rcvarsservice.gameobj.scene.scenes[0].petuplevelcontainer.dragactivepy =
          pointer.y;
      },
      this
    );
    this.rcvarsservice.gameobj.scene.scenes[0].petuplevelcontainer.dragactive =
      false;
    this.rcvarsservice.gameobj.scene.scenes[0].petuplevelcontainer.dragactivex = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].petuplevelcontainer.dragactivey = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].petuplevelcontainer.dragactivepx = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].petuplevelcontainer.dragactivepy = 0;
    wh.shopdragbtn.on(
      'pointermove',
      function (pointer, localX, localY, event) {
        //this.scene.registry.list["thethis"].phm.floorclick(this);
        //this.plog.log('shop pointermove', pointer, localX, localY, event, this);
        //this.showinventory_close(wh);
        if (
          this.rcvarsservice.gameobj.scene.scenes[0].petuplevelcontainer
            .dragactive == true
        ) {
          var tmpx =
            this.rcvarsservice.gameobj.scene.scenes[0].petuplevelcontainer
              .dragactivex;
          var tmpy =
            this.rcvarsservice.gameobj.scene.scenes[0].petuplevelcontainer
              .dragactivey;
          var tmppx =
            this.rcvarsservice.gameobj.scene.scenes[0].petuplevelcontainer
              .dragactivepx;
          var tmppy =
            this.rcvarsservice.gameobj.scene.scenes[0].petuplevelcontainer
              .dragactivepy;
          var movedx = tmppx - pointer.x;
          var movedy = tmppy - pointer.y;
          //this.plog.log('drag', tmppx, pointer.x, movedx);

          this.rcvarsservice.gameobj.scene.scenes[0].petuplevelcontainer.x =
            tmpx - movedx;
          this.rcvarsservice.gameobj.scene.scenes[0].petuplevelcontainer.y =
            tmpy - movedy;
          //this.maskg.setPosition(this.rcvarsservice.gameobj.scene.scenes[0].petuplevelcontainer.x,this.rcvarsservice.gameobj.scene.scenes[0].petuplevelcontainer.y)
          //this.rcvarsservice.gameobj.scene.scenes[0].petuplevelcontainer.y = pointer.y;
          this.setmaskposition();
        }
      },
      this
    );
    wh.petuplevelcontainer.add(wh.shopdragbtn);
    wh.petuplevelcontainer.bringToTop(wh.shopdragbtn);
    this.menubaritem.push(wh.shopdragbtn);


    this.title = wh.add.text(0, 0, 'Up Level', wh.fs["winguititle42"]);
    this.plog.log("winguititle fs", wh.fs["winguititle"])
    wh.petuplevelcontainer.add(this.title);
    wh.petuplevelcontainer.bringToTop(this.title);
    this.title.setPosition(0 - this.title.displayWidth / 2, -228);


    //skilltype bar
    //var equipment = this.rcvarsservice.activechar["equip"];
    //this.plog.log("equipment eq", equipment);
    //this.plog.log("petuplevelcontainer", wh.petuplevelcontainer, width, height);
    var svversionurlstring = this.wh.registry.list.rcvarpass.svversionstring;
    if (svversionurlstring != '') {
      svversionurlstring = '?' + svversionurlstring;
    }
    wh.petuplevelcontainer.setPosition(width / 2, height / 2);
    let loader = new Phaser.Loader.LoaderPlugin(wh);
    var loadlist = ['mob'];
    for (var k in loadlist) {
      var key = loadlist[k];
      loader.atlas(
        key + '',
        this.wh.registry.list.rcvarpass.rc_baseurl +
        'sprite/' +
        key +
        '.png' +
        svversionurlstring,
        this.wh.registry.list.rcvarpass.rc_baseurl +
        'sprite/' +
        key +
        '.json' +
        svversionurlstring
      );
    }
    //move
    wh.petuplevelcontainer.x = width - this.shopguibg.width / 2 + 20;

    var graphics = wh.make.graphics();
    graphics.fillStyle(0xff0000);
    graphics.fillRect(-200, 0, 300, 300);
    //wh.petuplevelcontainer.add(graphics);
    //graphics.setOrigin(0,0);
    graphics.setPosition(0, 0);
    graphics.setDepth(10000000);

    //graphics.displayHeight = graphics.displayWidth = 400;
    this.maskg = graphics;
    this.plog.log('xxxgraphics', graphics);

    //var mask = new Phaser.Display.Masks.GeometryMask(wh.petuplevelcontainer_char, graphics);
    const mask = graphics.createGeometryMask();
    this.maskm = mask;


    //wh.petuplevelcontainer_char.on('pointermove', function (pointer) {
    //this.plog.log("xxxcontainer", this);
    //});
    this.setmaskposition();
    loader.once(
      Phaser.Loader.Events.COMPLETE,
      () => {
        //this.plog.log('Phaser.Loader.Events.COMPLETE');
        //this.plog.log(this);
        //this.show_loaded();
        this.setmaskposition();

        this.buildpetctnr();
        this.rcvarsservice.gameobj.registry.list.gameitf.guiwindowclick(
          this.rcvarsservice.gameobj.scene.scenes[0].petuplevelcontainer
        );
      },
      this
    );
    loader.start();
  }

  setmaskposition() {
    this.maskm.geometryMask.setPosition(
      this.rcvarsservice.gameobj.scene.scenes[0].petuplevelcontainer.x + 50,
      this.rcvarsservice.gameobj.scene.scenes[0].petuplevelcontainer.y - 130
    );
    //this.maskm.geometryMask.displayWidth=500;
  }
  async buildpetctnr() {
    this.plog.log('buildpetctnr');
    var wh = this.wh;
    if (wh.petuplevelcontainer_char != undefined) {
      wh.petuplevelcontainer_char.destroy();
    }

    var width = wh.game.canvas.width;
    var height = wh.game.canvas.height;
    this.loadingshow();
    wh.petuplevelcontainer_char = wh.add.container(0, 0);
    wh.petuplevelcontainer.add(wh.petuplevelcontainer_char);
    var dat;
    dat = await this.rchttp.getcommon2('allpets', {});
    this.lastdat = dat;
    this.countpet = 0;
    for (const k in dat) {
      var v = dat[k];
      if (v['take'] != 'YES') continue;
      this.countpet++;
    }
    this.firsttimerunfunc();

    if (this.countpet == 0) {
      this.empty.visible = true;
    } else {
      this.empty.visible = false;
    }

    this.plog.log('xxxcountpet', this.countpet);
    this.petuplevelcontainer_char = wh.petuplevelcontainer_char;
    wh.petuplevelcontainer_char.setPosition(-120, -120);
    var i = -5;
    var rowh = 50;
    var iconwh = 40;

    for (const k in dat) {
      var v = dat[k];
      //this.plog.log("petinfo 1",v);
      if (v['take'] != 'YES') continue;
      var petsprite = wh.registry.list.rcvarpass.allmob[v['code']];
      //this.plog.log("xxxskdat", v);
      var tmpskicon = wh.add.sprite(0, 0, 'mob', v['mob']);

      wh.petuplevelcontainer_char.add(tmpskicon);
      tmpskicon.displayHeight = tmpskicon.displayWidth = iconwh;
      tmpskicon.setPosition(iconwh / 2 - 20, i * rowh - 5);
      var bg = wh.add.image(0, 0, 'rowbg');
      wh.petuplevelcontainer_char.add(bg);
      bg.setOrigin(0, 0);
      bg.setInteractive();
      bg.removeAllListeners();
      bg.name = v['id'];

      bg.on('pointermove', function (pointer) {
        //this.plog.log(this);
        //var pos = this.parentContainer.y + this.y;
        //this.plog.log("pos", pos);
        //if (pos > -150) {
        if (pointer.isDown) {
          this.scene.registry.list.petuplevel.petuplevelcontainer_char.y +=
            pointer.velocity.y / 3;
          this.scene.registry.list.petuplevel.petuplevelcontainer_char.y =
            Phaser.Math.Clamp(
              this.scene.registry.list.petuplevel.petuplevelcontainer_char.y,
              430 - this.scene.registry.list.petuplevel.countpet * 50,
              150
            );
          //this.scene.registry.list.petuplevel.movemenubartotop();
          //this.plog.log("xxxskill in container", 0-(this.countpet*50),this.petuplevelcontainer_char.y);
        }
        //}
      });

      bg.on('pointerup', async function (pointer) {
        this.scene.plog.log('xxx,skill,', this);
        //var tmpnamea = this.name.split("---");
        var data = await this.scene.registry.list.rchttp.getcommon2('allpets');
        this.scene.registry.list.rcosd.show(wh, 'pet', data[this.name]);
      });

      bg.setPosition(-20, i * rowh - rowh / 2 + 5);
      bg.displayHeight = iconwh;
      bg.displayWidth = 300;
      wh.petuplevelcontainer_char.sendToBack(bg);

      var namedsp = v['name'] + '';
      if (namedsp == '') namedsp = this.wh.trs("f:petuplevelcontainer:Unnamed Pet===Unnamed Pet");
      namedsp = namedsp + ' lv.' + v['level'];
      this.tmpskname[k] = wh.add.text(0, 0, namedsp, wh.fs["winguilist_title"]);
      wh.petuplevelcontainer_char.add(this.tmpskname[k]);
      wh.petuplevelcontainer_char.bringToTop(this.tmpskname[k]);
      this.tmpskname[k].setPosition(30, i * rowh - 35);
      //tmpskname.setResolution(0.25);
      //this.plog.log("petinfo", v);
      this.tmpskdescr[k] = wh.add.text(0, 0, ' ' + v['racename'] + ' ' + v['descr'], wh.fs["winguilist_descr"]);
      wh.petuplevelcontainer_char.add(this.tmpskdescr[k]);
      wh.petuplevelcontainer_char.bringToTop(this.tmpskdescr[k]);
      this.tmpskdescr[k].setPosition(30, i * rowh - 13);


      var hpbaroption = {
        x: 30,
        y: i * rowh + 5,
        w: 150,
        h: 5,
        max: v['cbt']['maxhp'],
        me: v['cbt']['hp'],
        txt: '',
        barcol1: 0x24ff2b,
        barcol2: 0x00d407,
        bgcol: 0xffffff,
        borderw: 0,
        imgcol: 'green',
      };
      this.pethpbar[k] = new PGBar(wh, hpbaroption, 'img');
      wh.petuplevelcontainer_char.add(this.pethpbar[k].getobj());
      wh.petuplevelcontainer_char.bringToTop(this.pethpbar[k].getobj());
      wh.petuplevelcontainer_char.add(this.pethpbar[k].getobjfg());
      wh.petuplevelcontainer_char.bringToTop(this.pethpbar[k].getobjfg());
      this.pethpbar[k].getobj().setPosition(30, i * rowh + 5).visible = false;;

      var reqxp = wh.registry.list.rcvarpass.level[v['level']]['xp'];
      //this.plog.log("reqxp",reqxp);
      var xpbaroption = {
        x: 30,
        y: i * rowh + 10,
        w: 150,
        h: 10,
        max: reqxp,
        me: v['xp'],
        txt: '',
        barcol1: 0xffc70f,
        barcol2: 0xffc70f,
        bgcol: 0xffffff,
        borderw: 0,
        imgcol: 'yellow',
      };
      this.petxpbar[k] = new PGBar(wh, xpbaroption, 'img');
      wh.petuplevelcontainer_char.add(this.petxpbar[k].getobj());
      wh.petuplevelcontainer_char.bringToTop(this.petxpbar[k].getobj());
      wh.petuplevelcontainer_char.add(this.petxpbar[k].getobjfg());
      wh.petuplevelcontainer_char.bringToTop(this.petxpbar[k].getobjfg());
      this.petxpbar[k].getobj().setPosition(30, i * rowh + 10);
 
      this.healbtndis[k] = wh.add.sprite(300, 300, "thesq", 'healdis');
      wh.petuplevelcontainer_char.add(this.healbtndis[k]);
      wh.petuplevelcontainer_char.bringToTop(this.healbtndis[k]);
      this.healbtndis[k].displayWidth = 50;
      this.healbtndis[k].scaleY = this.healbtndis[k].scaleX;
      this.healbtndis[k].setPosition(240, i * rowh - 5);
      this.healbtndis[k].setInteractive();
      this.healbtndis[k].removeAllListeners();
      this.healbtndis[k].name = k;

      this.tmpdiamonneed[k] = wh.add.text(0, 0, ' ' + v['uplevelcost'], wh.fs["petuplevel_diamond"]);
      wh.petuplevelcontainer_char.add(this.tmpdiamonneed[k]);
      wh.petuplevelcontainer_char.bringToTop(this.tmpdiamonneed[k]);
      this.tmpdiamonneed[k].setPosition(180, i * rowh - 15);

      this.healbtn[k] = wh.add.sprite(0, 0, "thesq", 'plusdiamond');
      wh.petuplevelcontainer_char.add(this.healbtn[k]);
      wh.petuplevelcontainer_char.bringToTop(this.healbtn[k]);
      this.healbtn[k].displayWidth = 50;
      this.healbtn[k].scaleY = this.healbtn[k].scaleX;
      this.healbtn[k].setPosition(240, i * rowh - 5);
      this.healbtn[k].setInteractive();
      this.healbtn[k].removeAllListeners();
      this.healbtn[k].name = k;
      this.healbtn[k].uplevelcost = v['uplevelcost'];
      this.healbtn[k].on('pointerover', async function () {
        this.scene.registry.list.gameitf.gamemenutooltip(
          this.scene,
          this,
          "Level up\nusing " + this.uplevelcost + " diamonds",
          'left'
        );
      });
      this.healbtn[k].on('pointerup', async function () {
        this.scene.plog.log('healbtn', this.name, this);
        this.setVisible(false);
        this.visible = false;
        this.scene.registry.list.petuplevel.loadingshow();
        var res = await this.scene.registry.list.rchttp.getcommon2('petuplevel', {
          petid: this.name,
        });
        // this.plog.log(res, this, this.name, this.scene.registry.list.petuplevel);
        if (res == 'ok') {
          //this.scene.registry.list.petuplevel.buildpetctnr();
          //this.scene.registry.list.petuplevel.healbtn[this.name].visible = false;
          this.scene.registry.list.rchttp.updatecharinfo();
        }
        this.scene.registry.list.petuplevel.loadinghide();
      });
      this.plog.log("pet-uplevel", v);
      if (v['level'] >= v['maxlevel']) {
        this.healbtndis[k].visible = true;
        this.healbtn[k].visible = false;
      } else {
        this.healbtndis[k].visible = false;
        this.healbtn[k].visible = true;
      }

      var catchk = v['mob'].substring(0, 3);
      if (catchk == "cat") {
        if (this.healbtndis[k] != undefined)
          this.healbtndis[k].visible = false;
        if (this.healbtn[k] != undefined)
          this.healbtn[k].visible = false;
        this.pethpbar[k].bar.visible = false;
        this.pethpbar[k].barfg.visible = false;
        this.petxpbar[k].bar.visible = false;
        this.petxpbar[k].barfg.visible = false;
        this.tmpskdescr[k].visible = false;
        this.tmpdiamonneed[k].visible = false;
      }

      i++;
    }
    this.petuplevelcontainer_char.y = 150;
    wh.petuplevelcontainer_char.setMask(this.maskm);
    this.loadinghide();
    this.plog.log('xxxmask', this.maskm);
  }

  async show_close(wh) {
    //this.plog.log(this);
    //this.wh.registry.list.gameitf.closegui(wh, wh.petuplevelcontainer);
    // wh.petuplevelcontainer.visible = false;

    try {
      for (let index = 0; index < this.petxpbar.length; index++) {
        this.petxpbar[index].destroy();

      }
    } catch (e) {
      this.plog.log("ERROR! petuplevelcontainer.destroy() ", e);
    }
    wh.petuplevelcontainer.visible = false;
    this.wh.registry.list.gameitf.closegui(wh, wh.petuplevelcontainer);
    for (let desi = 0; desi < 20; desi++) {

      for (const k in this.wh.petuplevelcontainer.list) {
        try {
          await this.wh.petuplevelcontainer.remove(this.wh.petuplevelcontainer.list[k], true);
          //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list[k].destroy();
        } catch (e) {
          this.plog.log("ERROR! petuplevelcontainer.destroy() ", e);
        }
      }
    }
    try {
      await this.wh.petuplevelcontainer.destroy(true);
    } catch (e) {
      this.plog.log("ERROR! petuplevelcontainer.destroy() ", e);
    }
    wh.petuplevelcontainer = undefined;
  }
}
