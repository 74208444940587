import { Injectable } from '@angular/core';
import { RCSVVARSService } from './rcsvvars.service';
import { RCRequirementService } from './rcrequirement.service';
import { RCToastService } from './rctoast.service';
import { plog } from './plog.service';

@Injectable({
  providedIn: 'root',
})
export class NPCConverService {
  thispageconvh = 0;
  emoteicon = [];
  rewardicon = [];
  rewardtxt = [];
  converlen = 0;
  kbcursorindi;
  kbcursor = 0;
  rcvarpass;
  bubbleadded = false;
  npctext;
  currentresppage;
  respperpage;
  resptotalpage;
  npcavatar;
  npcmyavatar;
  npcresp = [];
  bubblelist = [];
  npclistbackbtn;
  npclistfwdbtn;
  npcbackbtn;
  npcbg;
  npcdialogitems0;
  npcdialogitems1;
  npcdialogitems2;
  wh;
  npctextfull = "";
  npctexttypetimer;
  plog;

  constructor(
    public rcvarsservice: RCSVVARSService,
    public rcreq: RCRequirementService,
    public rctoast: RCToastService
  ) {
    this.plog = new plog();
    //this.plog.setLevel(4);
  }

  initthis(gc) {
    this.wh = gc;
    //var avatarsize = 100;
    //var spaceheight = 100;
    //var lpadding = 5;

    var width = gc.game.canvas.width;
    var height = gc.game.canvas.height;

    gc.npccontainer = gc.add.container(400, 300);

    this.plog.log('npcconver initthis', gc);
    var npcavatar = gc.add.image(300, 300, 'defavatar');
    npcavatar.setPosition(100, 100);
    npcavatar.setOrigin(0, 0);
    //npcavatar.setDepth(70001);

    npcavatar.visible = false;

    //var npcmyavatar = gc.add.image(300, 300, 'defavatar');
    //this.plog.error("npcmyavatar",gc.currentcharavar+'');
    //var npcmyavatar = gc.add.image(300, 300, gc.currentcharavar+'');

    var npcmyavatar = gc.add.sprite(300, 300, 'charava', gc.registry.list.rcvarpass.activechar["sprite"]);

    npcmyavatar.setPosition(100, 100);
    npcmyavatar.setOrigin(0, 0);
    //npcmyavatar.setDepth(70001);

    npcmyavatar.visible = false;
    //npcavatar.displayWidth = avatarsize;
    //npcavatar.displayHeight = avatarsize;

    this.npcavatar = npcavatar;
    this.npcmyavatar = npcmyavatar;
    gc.npccontainer.add(npcavatar);
    gc.npccontainer.add(npcmyavatar);
    //gc.npccontainer.setPosition(width/2,height/2);
    gc.npccontainer.setPosition(0, 0);
    gc.npccontainer.setDepth(510000);

    //response choices
    for (var i = 0; i <= 50; i++) {
      //{ font: "18px Arial", fill: "#000044", align: "left" }

      //legendary unknown dotdotdot fixed here
      this.npcresp[i] = gc.add.text(0, 0, '', gc.fs["npcconver_my"])
        .setOrigin(0, 0)
        .setInteractive().removeAllListeners();;

      this.npcresp[i].setWordWrapWidth(width);
      //wordWrap: { width: width, useAdvancedWrap: true },
      gc.npccontainer.add(this.npcresp[i]);
    }

    var npclistbackbtn = gc.add.sprite(0, 0, "thesb", "backsign");
    var npclistfwdbtn = gc.add.sprite(0, 0, "thesb", "forwardsign");
    this.npclistbackbtn = npclistbackbtn;
    this.npclistfwdbtn = npclistfwdbtn;
    this.npclistfwdbtn.visible = false;
    this.npclistbackbtn.visible = false;
    gc.npccontainer.add(npclistbackbtn);
    gc.npccontainer.add(npclistfwdbtn);

    this.npcbackbtn = gc.add.sprite(300, 300, "thesq", 'back');
    this.npcbackbtn.setPosition(0, 0);
    this.npcbackbtn.setDepth(50010);
    this.npcbackbtn.setOrigin(0, 0);
    this.npcbackbtn.setInteractive();
    this.npcbackbtn.removeAllListeners();
    this.npcbackbtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        //this.scene.registry.list["thethis"].phm.floorclick(this);
        this.plog.log(
          'this.npcbackbtn pointerup',
          pointer,
          localX,
          localY,
          event,
          this
        );
        this.closedialog(gc);
      },
      this
    );
    this.npcbackbtn.visible = false;
    gc.npccontainer.add(this.npcbackbtn);

    var npcbg = gc.add.image(0, 0, "itf-white");
    npcbg.setAlpha(0.05);
    npcbg.setPosition(0, 0);
    npcbg.setOrigin(0, 0);
    //npcbg.setAlpha(0.75);
    npcbg.visible = false;
    npcbg.displayWidth = width;
    npcbg.displayHeight = height;
    //npcbg.setDepth(70000);
    npcbg.setInteractive();
    npcbg.removeAllListeners();
    this.npcbg = npcbg;
    gc.npccontainer.add(npcbg);
    this.kbcursor = 0;

    this.kbcursorindi = gc.add.sprite(300, 300, "thesq", 'handcursor');
    gc.npccontainer.add(this.kbcursorindi);
    gc.npccontainer.bringToTop(this.kbcursorindi);
    this.kbcursorindi.visible = false;
    gc.camman(gc);
    //this.plog.log('npccontainer', gc.npccontainer);
  }
  starttalk(npcid, gc) {
    this.plog.log('npcconver-service, talking to', npcid);
    //this.plog.log('npcc', this, arguments);
    //preload/load stuff
    gc.registry.list.gp.guigrid_focushide();

    let loader = new Phaser.Loader.LoaderPlugin(gc);
    //this.plog.log('npcc v', gc);
    var tmpurl = gc.registry.list.rcvarpass.getlocal(
      'npcavatar/' + npcid + '.png'
    );
    loader.image(
      'npcavatar_' + npcid,
      tmpurl
    );
    gc.registry.list.rcloading.loading2(gc, true, false);
    loader.on(
      'progress',
      function (value) {
        //this.plog.log("loaderevent", value, loader.totalComplete, loader.totalToLoad);
        gc.registry.list.rcloading.loading2text(
          'Loading ' + loader.totalComplete + '/' + loader.totalToLoad
        );
        gc.registry.list.rcloading.loading2percent(value);
      },
      this
    );
    //this.plog.log('npcavatar_' + npcid);
    loader.once(Phaser.Loader.Events.COMPLETE, () => {
      //this.plog.log('Phaser.Loader.Events.COMPLETE', this);
      //this.plog.log(this);
      this.conver(npcid, gc, 0);
      gc.registry.list.rcloading.close();
    });
    loader.start();
  }

  async conver(npcid, gc, converid) {
    this.kbcursor = 0;
    this.plog.log('CONVER-', npcid, gc, converid);
    gc.npccontainer.setAlpha(1);

    gc.kbfocus = "npc";
    var width = gc.game.canvas.width;
    var height = gc.game.canvas.height;
    this.npcmyavatar.setTexture(gc.currentcharavar + '')
    this.npcbg.displayWidth = width;
    this.npcbg.displayHeight = height;
    
    if (gc.gamemenuloading != undefined) {
      gc.gamemenuloading.visible = false;
    }
    //load new or use cache
    //disable cache since implementing quest system
    //if (this.rcvarpass.npcconvers == undefined || this.rcvarpass.npcconvers[npcid] == undefined || this.rcvarpass.npcconvers[npcid][converid] == undefined) {
    //check for instantmodule
    if (
      this.rcvarpass.npc[npcid]['instantmodule'] != undefined &&
      this.rcvarpass.npc[npcid]['instantmodule'] != ''
    ) {
      gc.registry.list.npcconver.closedialog(gc);

      gc.kbfocus = "";
      gc.registry.list.execmod.exec(
        this.rcvarpass.npc[npcid]['instantmodule'],
        gc.game.scene.scenes[0]
      );

      return;
    }

    gc.registry.list.rcloading.loading2(gc, true, false);
    var tmpcv = await this.rcvarsservice.rchttp.getcommon2("get_npcconver", { npc: npcid, pid: converid });
    var maprevealdata = gc.registry.list.rcvarpass.activemapreveal;
    if (
      maprevealdata != undefined &&
      Array.isArray(maprevealdata) &&
      gc.registry.list.rcvarpass != undefined &&
      gc.registry.list.rcvarpass.activemap != undefined &&
      gc.registry.list.rcvarpass.activemap["map"] != undefined &&
      gc.registry.list.rcvarpass.activemap["map"]["code"] != undefined
    ) {
      gc.registry.list.rcstorage.set("z_mapreveal_" +  gc.registry.list.rcvarpass.activemap["map"]["code"] + "", maprevealdata);
    }
    // var tmpcv = await this.rcvarsservice.rchttp.load_npcconver(npcid, converid);
    gc.registry.list.rcloading.close();

    //this.plog.log('rcvarsservice', this, tmpcv);
    if (this.rcvarpass.npcconvers == undefined) {
      this.rcvarpass.npcconvers = [];
    }
    if (this.rcvarpass.npcconvers[npcid] == undefined) {
      this.rcvarpass.npcconvers[npcid] = [];
    }
    this.rcvarpass.npcconvers[npcid][converid] = tmpcv;
    //} else {
    //  this.plog.log("conver - use cached:", this.rcvarpass.npcconvers[npcid][converid]);
    //}

    var data = this.rcvarpass.npcconvers[npcid][converid];
    if (data == undefined || data['main'] == undefined) {
      this.plog.warn("npcconver.conver(): can't find main conver", gc);
      gc.kbfocus = "";
      try {
        gc.registry.list.npcconver.closedialog(gc.scene.scene.scene);
      } catch (e) {
        this.plog.log("ERROR", e);
      }

      try {
        gc.scene.registry.list.npcconver.closedialog(gc.scene.scene.scene);
      } catch (e) {
        this.plog.log("ERROR", e);
      }

      return;
    }
    if (data != undefined && data['npcconver'] != undefined && data['npcconver'] == "closedialog") {
      this.plog.warn("npcconver.conver(): found npcconver=closedialog");
      gc.kbfocus = "";
      this.closedialog(gc);

      return;
    }
    //first, check if single action npc
    //this.plog.log('initthis initchk', data);
    //if (converid == 0 && data["main"]["module"] != undefined && data["main"]["module"] != "") {
    if (data['main']['module'] != undefined && data['main']['module'] != '') {
      this.plog.log('found single action module', data, gc, npcid);
      //wh.scene.registry.list.npcconver.closedialog(wh.scene.scene.scene);
      gc.kbfocus = "";
      await this.closedialog(gc);
      await gc.game.scene.scenes[0].registry.list.rctoast.sleep(300);
      gc.registry.list.execmod.exec(
        data['main']['module'],
        gc.game.scene.scenes[0]
      );


      return;
    }
    await gc.registry.list.gameitf.hidegui(gc);

    this.converlen = data["sub"].length;
    //this.plog.log('CONVER-DATA', data);
    gc.npccontainer.visible = true;

    //in dialog

    var width = gc.game.canvas.width;
    var height = gc.game.canvas.height;
    //this.plog.log('dialog', gc);
    /*
        this.plog.log(
          'dialog',
          this.npcdialogitems0,
          this.npcdialogitems1,
          this.npcdialogitems2
        );*/

    if (this.npcdialogitems0 == undefined) {
      /*var dialogitems = gc.registry.list.gameitf.createdialogbg(gc, 0, 0, width, height,gc.npccontainer);
      this.npcdialogitems0 = dialogitems[0];
      this.npcdialogitems1 = dialogitems[1];
      this.npcdialogitems2 = dialogitems[2];*/
      /*
            var npcdialogitems0 = gc.add.image(300, 300, 'dialogtop');
            npcdialogitems0.setOrigin(0, 0);
            npcdialogitems0.setPosition(0);
            npcdialogitems0.displayWidth = width;
            npcdialogitems0.setDepth(3);
      
            var npcdialogitems1 = gc.add.image(300, 300, 'dialogbtm');
            npcdialogitems1.setOrigin(0, 1);
            npcdialogitems1.setPosition(0, height);
            npcdialogitems1.displayWidth = width;
            npcdialogitems1.setDepth(2);
      
            var npcdialogitems2 = gc.add.image(300, 300, 'dialogbody');
            npcdialogitems2.setOrigin(0, 0);
            npcdialogitems2.setPosition(0, npcdialogitems0.displayHeight);
            npcdialogitems2.displayWidth = width;
            npcdialogitems2.displayHeight =
              height -
              (npcdialogitems0.displayHeight + npcdialogitems1.displayHeight);
            npcdialogitems2.setDepth(1);
      
            gc.npccontainer.add(npcdialogitems0);
            gc.npccontainer.add(npcdialogitems1);
            gc.npccontainer.add(npcdialogitems2);
            //gc.npccontainer.add(this.npcdialogitems0);
            //gc.npccontainer.add(this.npcdialogitems1);
            //gc.npccontainer.add(this.npcdialogitems2);
      
            this.npcdialogitems0 = npcdialogitems0;
            this.npcdialogitems1 = npcdialogitems1;
            this.npcdialogitems2 = npcdialogitems2;      */

    }
    /*this.npcdialogitems0.visible = true;
    this.npcdialogitems1.visible = true;
    this.npcdialogitems2.visible = true;
    gc.npccontainer.sendToBack(this.npcdialogitems0);
    gc.npccontainer.sendToBack(this.npcdialogitems1);
    gc.npccontainer.sendToBack(this.npcdialogitems2);*/
    //dialogitems[0].visible=false;
    //dialogitems[1].visible=false;
    //dialogitems[2].visible=false;
    //cons
    var lrpadding = 70;
    var avatarsize = 100;
    var spaceheight = 50;
    var lpadding = 5;
    var npctextheight = 100;
    if (width < 600) {
      lrpadding = 15;
    }
    this.plog.log('npcconver-conver()', this, arguments);
    this.npcavatar.setTexture('npcavatar_' + npcid);
    this.npcavatar.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);

    this.npcavatar.setPosition(
      width - (avatarsize + lpadding + lrpadding),
      spaceheight + lpadding
    );
    this.npcavatar.visible = true;
    //this.npcavatar.setPosition(100, 100);
    this.npcavatar.displayWidth = avatarsize;
    this.npcavatar.displayHeight = avatarsize;

    this.npcmyavatar.visible = true;
    this.npcmyavatar.displayWidth = avatarsize;
    this.npcmyavatar.displayHeight = avatarsize;
    this.npcmyavatar.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);
    this.npcmyavatar.setPosition(
      lpadding + lrpadding,
      spaceheight + lpadding * 3 + npctextheight + avatarsize
    );
    if (width > height) {
      this.npcmyavatar.setPosition(
        lpadding + lrpadding,
        spaceheight + lpadding * 3 + npctextheight
      );
    }
    //npc text
    //var npctexta = [];
    //this.plog.error("MAIN", data['main']);
    //if (data['main']['msg1'] != '') npctexta.push(data['main']['msg1']);
    // (data['main']['msg2'] != '') npctexta.push(data['main']['msg2']);
    //if (data['main']['msg3'] != '') npctexta.push(data['main']['msg3']);
    var npctextstr = this.wh.trs("t:npc_conver:id:msg1:" + data['main']['id'] + "===" + data['main']['msg1']);//npctexta[Math.floor(Math.random() * npctexta.length)];
    if (npctextstr == undefined) npctextstr = " ";
    npctextstr = npctextstr.replace(
      '%name%',
      gc.registry.list.rcvarpass.activechar["name"]
    );
    npctextstr = npctextstr.replace('\\n', "\n").replace('\\n', "\n").replace('\\n', "\n").replace('\\n', "\n").replace('\\n', "\n");
    var mapname = gc.registry.list.rcvarpass.activemap['map']['name'];

    npctextstr = npctextstr.replace(
      '%mapname%',
      mapname
    );

    for (var bi in this.rewardicon) {
      if (this.rewardicon[bi] != undefined) {
        this.rewardicon[bi].destroy();
        this.rewardicon[bi] = undefined;
      }
    }
    for (var bi in this.emoteicon) {
      if (this.emoteicon[bi] != undefined) {
        this.emoteicon[bi].destroy();
        this.emoteicon[bi] = undefined;
      }
    }
    for (var bi in this.rewardtxt) {
      if (this.rewardtxt[bi] != undefined) {
        this.rewardtxt[bi].destroy();
        this.rewardtxt[bi] = undefined;
      }
    }
    for (var bi in this.bubblelist) {
      this.bubblelist[bi].destroy();
    }
    if (height > width) {
      var npctext = this.createSpeechBubble(
        gc,
        lpadding + lrpadding,
        spaceheight + avatarsize + lpadding * 2,
        width - (lpadding * 2 + lrpadding * 2),
        npctextheight,
        npctext,
        'top',
        avatarsize
      );
      /*      npctext.setPosition(
        (width - (lpadding * 4 + lrpadding * 2) - avatarsize) / 2,
        (npctextheight) + 10
      );*/
    } else {
      var npctext = this.createSpeechBubble(
        gc,
        lpadding + lrpadding,
        spaceheight + lpadding * 1,
        width - (lpadding * 4 + lrpadding * 2) - avatarsize,
        npctextheight,
        npctext,
        'right',
        avatarsize
      );

      /*npctext.setPosition(
        (lpadding + lrpadding) - (width - (lpadding * 4 + lrpadding * 2) - avatarsize),
        (spaceheight + lpadding * 1) - npctextheight
      );*/
    }
    //if (this.npctext == undefined) this.npctext = npctext;
    if (this.npctext != undefined) this.npctext.destroy();
    npctextstr = npctextstr.replace('%name%', gc.registry.list.rcvarpass.activechar["name"]);
    npctextstr = npctextstr.replace('%name%', gc.registry.list.rcvarpass.activechar["name"]);
    npctextstr = gc.gl(npctextstr);
    this.npctext = npctext;

    this.npctext.setText('' + npctextstr);
    //this.npctext.setText('' + gc.gl(npctextstr));
    this.npctextfull = gc.gl(npctextstr);
    this.npctext.setOrigin(0, 0);
    this.plog.log('npctext', this.npctext.displayHeight, npctextheight, this);
    this.npctext.setInteractive();
    this.npctext.removeAllListeners();
    //this.npctext.setFixedSize(width - (lpadding * 2 + lrpadding * 2));
    if (this.npctext.displayHeight > (npctextheight - 15)) {
      //enable click to expand
      /*this.npctext.setText(
        npctextstr.substring(0, 50) + gc.gl('... (click to read more)')
      );*/
      this.npctextfull =
        npctextstr.substring(0, 50) + gc.gl('... (' + this.wh.trs("f:npccontainer:click to read more===click to read more") + ')')
      this.npctext.on(
        'pointerup',
        function () {
          this.rctoast.rclongalert(this.wh, gc.gl('Message'), npctextstr);
        },
        this
      );
    }
    this.npctext.setText('');

    this.typenpctext();

    //show emote
    if (data['main']['emote'] != undefined && data['main']['emote'].length > 0) {
      var emotelist = (data['main']['emote'].toString() + "").split(",");
      var emotei = 0;
      for (const emotek in emotelist) {
        var emotev = emotelist[emotek];
        if (emotev.length == 2) {
          var emodeuse = emotev.replace(":emote:", "");
          emodeuse = emodeuse.replace(":", "");
          this.rewardicon[emotek] = this.wh.add.sprite(0, 0, "emotes", "aa-1");
          if (this.wh.anims.anims.entries[emodeuse] == undefined) {
            emotei++;
            //create anim
            this.wh.anims.create({
              key: "emote_" + emodeuse,
              frames: this.wh.anims.generateFrameNames("emotes", {
                prefix: emodeuse + '-',
                end: 3,
                zeroPad: 0,
                start: 1,
              }),
              repeat: -1,
              frameRate: 5,
            });
          }
          this.rewardicon[emotek].play("emote_" + emodeuse);

          this.wh.npccontainer.add(this.rewardicon[emotek]);
          this.wh.npccontainer.bringToTop(this.rewardicon[emotek]);
          this.rewardicon[emotek].setPosition(this.npcavatar.x + (emotei * 20), this.npcavatar.y - 16);
        }
      }
    }

    //play audio if exists
    if (data['main']['audio'] != undefined && data['main']['audio'] != "") {
      this.plog.log("npcconverautio", this.rcvarsservice);
      this.rcvarsservice.rcvarservice.gameobj.scene.scenes[0].registry.list.phm.npcvoice(this.rcvarsservice.rcvarservice.gameobj.scene.scenes[0], data['main']['audio']);
      //var tmpplay = await this.rcvarsservice.phm.getcommon2("get_npcconver", { npc: npcid, pid: converid });
    }

    //show quest reward if exists
    if (data['main']['reward'] != undefined && data['main']['reward'].length > 0) {
      var rewii = 0;
      var rewwh = 48;
      var rewmargin = 4;
      var rewposx = this.npcavatar.x - (this.npcavatar.displayHeight / 2);
      var rewposy = this.npcavatar.y + this.npcavatar.displayHeight - (rewwh / 2);
      for (const rewardk in data['main']['reward']) {
        var rewardv = data['main']['reward'][rewardk];
        var rwva = rewardv.split(":");
        if (rwva[0] == "stackitem") {
          this.rewardicon[rewardk] = this.wh.add.sprite(0, 0, "stackitem_tp", rwva[1]);
          this.rewardicon[rewardk].name = this.wh.registry.list.rcvarpass.allstackitem[rwva[1]]["name"] + " x" + rwva[2];
          this.rewardtxt[rewardk] = this.wh.add.text(0, 0, rwva[2], this.wh.fs["wingui_amnt"]);

        }
        if (rwva[0] == "item") {
          this.rewardicon[rewardk] = this.wh.add.sprite(0, 0, "item_tp", rwva[1]);
          this.rewardicon[rewardk].name = this.wh.registry.list.rcvarpass.allitem[rwva[1]]["name"] + " x" + rwva[2];
          this.rewardtxt[rewardk] = this.wh.add.text(0, 0, rwva[2], this.wh.fs["wingui_amnt"]);

        }
        if (this.rewardicon[rewardk] != undefined && this.rewardtxt[rewardk] != undefined) {
          this.wh.npccontainer.add(this.rewardicon[rewardk]);
          this.wh.npccontainer.bringToTop(this.rewardicon[rewardk]);
          this.wh.npccontainer.add(this.rewardtxt[rewardk]);
          this.wh.npccontainer.bringToTop(this.rewardtxt[rewardk]);
          this.rewardicon[rewardk].displayWidth = this.rewardicon[rewardk].displayHeight = rewwh;
          this.rewardicon[rewardk].setPosition(rewposx - (rewii * (rewwh + rewmargin)), rewposy + (rewwh / 2));
          this.rewardtxt[rewardk].setPosition(this.rewardicon[rewardk].x - (rewwh / 2) + rewmargin, this.rewardicon[rewardk].y + 5);
          this.rewardicon[rewardk].removeAllListeners();
          this.rewardicon[rewardk].setInteractive();
          this.rewardicon[rewardk].on("pointerup", function () {
            this.scene.registry.list.rctoast.rctoast(this.scene, this.name);
          });
          rewii++;
        }
      }
    }

    //my text
    var bubbleypos = spaceheight + avatarsize + lpadding * 4 + npctextheight;
    var bubblexpos = lpadding + avatarsize + lpadding + lrpadding;
    if (width > height) {
      var bubbleypos = spaceheight + lpadding * 4 + npctextheight;
    }
    var bubblew = width - (lpadding * 3 + avatarsize + lrpadding * 2);
    var resph = 40;
    var respmargin = 5;
    var paddingbtm = 20;
    var bubbleh = data['sub'].length * resph + paddingbtm;
    this.plog.log('override bubbleh resppage', resppage);

    var spaceleft = height - (bubbleypos + paddingbtm) - 40;
    //our response h
    var respslot = Math.floor((spaceleft - paddingbtm) / resph) - 1; // -1 to make spare pos for long option text
    this.plog.log('spaceleft', spaceleft, ' avai slot', respslot, ' need slot ', data['sub'].length);
    var resppage = 1;
    var datasubusepre = data['sub'];
    //chk requirements
    this.plog.log('datasubuse', datasubusepre);
    if (datasubusepre.length == 0) {
      //auto add close txt
      //{ "id": 11, "npc": "potionshop1", "pid": 10, "mytext": "Oh.. right.", "msg1": "", "msg2": "", "msg3": "", , "requirements_hide": "NO", "module": "npc_close", "ordr": 0, "forqueststep": 0, "gotoqueststep": 0 }
      datasubusepre[0] = [];
      datasubusepre[0]["id"] = 0;
      datasubusepre[0]["module"] = "npc_close";
      datasubusepre[0]["mytext"] = this.wh.trs("f:npccontainer:Okay===Okay");
      datasubusepre[0]["requirements"] = {};
      datasubusepre[0]["requirements_hide"] = "NO";
    }
    var datasubuse = [];
    for (let key of Object.keys(datasubusepre)) {
      let val = datasubusepre[key];
      var reschk = this.rcreq.chk(val['requirements']);
      val['mytext'] = val['mytext'].replace('%name%', gc.registry.list.rcvarpass.activechar["name"]);
      val['mytext'] = val['mytext'].replace('%name%', gc.registry.list.rcvarpass.activechar["name"]);
      val['mytext'] = val['mytext'].replace("\\n", "\n");
      //this.plog.log("datasubuse " + key, reschk);
      if (reschk == false) {
        if (val['requirements_hide'] == 'NO') {
          //add, butdisable
          datasubuse[datasubuse.length] = val;
          datasubuse[datasubuse.length - 1]['mytext'] =
            '[disabled] ' + datasubuse[datasubuse.length - 1]['mytext'];
        } else {
          datasubuse[datasubuse.length] = val;
          datasubuse[datasubuse.length - 1]['mytext'] =
            '[disabled] ' + datasubuse[datasubuse.length - 1]['mytext'];
          //disablehide current not working
        }
      } else {
        datasubuse[datasubuse.length] = val;
      }
    }

    //this.plog.log("datasubusepre after", datasubusepre);
    //this.plog.log("datasubusepre res", datasubuse);

    if (datasubuse.length > respslot) {
      resppage = Math.ceil(datasubuse.length / respslot);

      this.npclistbackbtn.setOrigin(1, 1);
      this.npclistfwdbtn.setOrigin(0, 1);
      this.npclistbackbtn.setPosition(width / 2, height);
      this.npclistfwdbtn.setPosition(width / 2, height);
      //this.npcdialogitems2.visible=false;
      gc.npccontainer.bringToTop(this.npclistbackbtn);
      gc.npccontainer.bringToTop(this.npclistfwdbtn);
      this.plog.log('npc resppage', resppage, 'respslot', respslot);
      this.plog.log('npc bbx', this.npclistbackbtn, this.npclistfwdbtn);
    }

    if (resppage > 1) {
      this.plog.log('override bubbleh');
      //bubbleh = respslot * resph + paddingbtm;
    }
    //this.bubbleadded = true;

    var f = {
      wordWrap: { width: bubblew - lpadding * 4, useAdvancedWrap: true },
      color: '#00a',
    };
    var fdis = {
      wordWrap: { width: bubblew - lpadding * 4, useAdvancedWrap: true },
      color: '#777',
    };
    var fq = {
      wordWrap: { width: bubblew - lpadding * 4, useAdvancedWrap: true },
      color: '#090',
    };
    var fdim = {
      wordWrap: { width: bubblew - lpadding * 4, useAdvancedWrap: true },
      color: '#005',
    };
    for (var i = 0; i < 50; i++) {
      this.npcresp[i].setText('');
      this.npcresp[i].visible = false;
    }
    var previousy = 0;
    for (var i = 0; i < datasubuse.length; i++) {
      if (i >= 50) continue; //current max option is 20
      this.npcresp[i].visible = true;
      //this.plog.log('displaynpcoption', this.npcresp[i]);
      //this.plog.log('displaynpcoption', datasubuse[i]);
      //check for queststep
      var tmpchkq = [];
      if (Number.isInteger(datasubuse[i]['id'])) {
      } else {
        tmpchkq = datasubuse[i]['id'].split(':');
      }
      if (datasubuse[i]['mytext'].indexOf('[disabled]') != -1) {
        //continue;
      }

      var disabledthis = false;
      if (datasubuse[i]['mytext'].indexOf('[disabled]') != -1) {
        //this.plog.log('displaynpcoption disabledthis', this.npcresp[i]);
        datasubuse[i]['mytext'] = datasubuse[i]['mytext'].replace(
          '[disabled]',
          ''
        );
        disabledthis = true;
      }
      datasubuse[i]['mytext'] = datasubuse[i]['mytext'].replace(
        '[charname]',
        gc.registry.list.rcvarpass.activechar["name"]
      );
      datasubuse[i]['mytext'] = datasubuse[i]['mytext'].replace('%name%', gc.registry.list.rcvarpass.activechar["name"]);
      datasubuse[i]['mytext'] = datasubuse[i]['mytext'].replace('%name%', gc.registry.list.rcvarpass.activechar["name"]);
      datasubuse[i]['mytext'] = datasubuse[i]['mytext'].replace("\\n", "\n");
      datasubuse[i]['mytext'] = datasubuse[i]['mytext'].replace("(icon-funny)", "😺");
      datasubuse[i]['mytext'] = datasubuse[i]['mytext'].replace("(icon-sad)", "😿");
      datasubuse[i]['mytext'] = datasubuse[i]['mytext'].replace("(icon-emotional)", "🙀");
      datasubuse[i]['mytext'] = datasubuse[i]['mytext'].replace("(icon-puns)", "🤦");
      datasubuse[i]['mytext'] = datasubuse[i]['mytext'].replace("(icon-dad-jokes)", "🤦");
      datasubuse[i]['mytext'] = datasubuse[i]['mytext'].replace("(icon-paradigm-shift)", "🤔");
      datasubuse[i]['mytext'] = datasubuse[i]['mytext'].replace("(icon-paradigm-shift)", "🤔");
      datasubuse[i]['mytext'] = datasubuse[i]['mytext'].replace("(icon-board)", "📖");
      datasubuse[i]['mytext'] = datasubuse[i]['mytext'].replace("(icon-boss)", "💀");
      datasubuse[i]['mytext'] = datasubuse[i]['mytext'].replace("(icon-challenge)", "⚔");
      datasubuse[i]['mytext'] = datasubuse[i]['mytext'].replace("(icon-lore)", "[lore] 🗣");
      datasubuse[i]['mytext'] = datasubuse[i]['mytext'].replace("(icon-lore-bg)", "[lore] 🗣");

      //icons https://www.vertex42.com/ExcelTips/unicode-symbols.html#block
      if (datasubuse[i]['id'] != undefined && datasubuse[i]['requirements'] != undefined && datasubuse[i]['requirements']['questdone'] != undefined) {

        if (datasubuse[i]['id'].toString().indexOf("quest:lore1") != -1 || datasubuse[i]['requirements']['questdone'].toString().indexOf("lore1-") != -1) {
          this.npcresp[i].setText(' 🌏 ' + this.wh.trs("t:npc_conver:id:mytext:" + datasubuse[i]['id'] + "===" + datasubuse[i]['mytext']));
        } else if (datasubuse[i]['id'].toString().indexOf("quest:lore2") != -1) {
          this.npcresp[i].setText(' 🌄 ' + this.wh.trs("t:npc_conver:id:mytext:" + datasubuse[i]['id'] + "===" + datasubuse[i]['mytext']));
        } else if (datasubuse[i]['id'].toString().indexOf("quest:lore3") != -1) {
          this.npcresp[i].setText(' 🌓 ' + this.wh.trs("t:npc_conver:id:mytext:" + datasubuse[i]['id'] + "===" + datasubuse[i]['mytext']));
        } else {
          this.npcresp[i].setText(' ⬤ ' + this.wh.trs("t:npc_conver:id:mytext:" + datasubuse[i]['id'] + "===" + datasubuse[i]['mytext']));
        }
      } else {
        this.npcresp[i].setText(' ⬤ ' + this.wh.trs("t:npc_conver:id:mytext:" + datasubuse[i]['id'] + "===" + datasubuse[i]['mytext']));
      }

      //this.npcresp[i].displayWidth=bubblew;
      //this.npcresp[i].displayHeight=resph;
      if ((i % respslot) == 0) {
        previousy = bubbleypos + lpadding + respmargin;
      } else {
        previousy = previousy + respmargin;
      }
      this.npcresp[i].setPosition(
        bubblexpos + respmargin * 2,
        previousy
        //bubbleypos + lpadding + (i % respslot) * resph + respmargin
      );

      //this.npcresp[i].y = this.npcresp[i].y + this.npcresp[i].displayHeight+100;

      //this.plog.log(i, datasubuse[i], this.npcresp[i]);
      this.npcresp[i].removeAllListeners();
      //this.plog.log("cevents", this.npcresp[i]._events);
      gc.npccontainer.bringToTop(this.npcresp[i]);
      //this.plog.log("clickchoice,oldname", this.npcresp[i].name);
      this.npcresp[i].name = 'respclick-' + npcid + '-' + converid + '-' + i; //this.npcresp[i].id;
      //this.plog.log("clickchoice,newname", this.npcresp[i].name);
      this.npcresp[i].off();
      //this.plog.error(datasubuse[i]);
      if (tmpchkq[0] == 'queststep') {
        var reschk = this.rcreq.chk(datasubuse[i]['requirements']);

        if (reschk == true) {
          this.npcresp[i].setText('' + this.wh.trs("t:npc_conver:id:mytext:" + datasubuse[i]['id'] + "===" + datasubuse[i]['mytext']));
          this.npcresp[i].setStyle(fq);
          this.npcresp[i].on('pointerup', function (tmpitem) {
            this.scene.plog.log('bg click', this, arguments);
            this.scene.registry.list.npcconver.clickchoice(this);
          });
        } else {
          this.npcresp[i].setText('' + this.wh.trs("t:npc_conver:id:mytext:" + datasubuse[i]['id'] + "===" + datasubuse[i]['mytext']));
          this.npcresp[i].setStyle(fdis);
          this.npcresp[i].reqtext = this.rcreq.chk(datasubuse[i]['requirements'], "text");
          this.npcresp[i].on('pointerover', function (tmpitem) {
            this.scene.plog.log('bg click', this, arguments);
            this.scene.registry.list.gameitf.gamemenutooltip(
              this.scene, this, '' + this.reqtext, 'btm', 300
            );
          });
        }
      } else {
        //this.plog.log("npcconver notqueststep", datasubuse[i], this.npcresp[i]);

        if (disabledthis == false) {
          this.npcresp[i].on('pointerup', function (tmpitem) {
            this.scene.plog.log('bg click', this, arguments);
            this.scene.registry.list.npcconver.clickchoice(this);
          });
          this.npcresp[i].setStyle(f);
          if (datasubuse[i]["dramanew"] == "no") {
            this.npcresp[i].setStyle(fdim);
          }

        } else {
          this.npcresp[i].requirements = datasubuse[i]["requirements"];
          this.npcresp[i].setStyle(fdis);
          this.npcresp[i].on('pointerup', async function (tmpitem) {
            this.scene.plog.log('byclick', this, arguments);
            var reqstr = this.scene.registry.list.rcreq.chk(this.requirements, "text");
            if (this.requirements != undefined && this.requirements['questdone'] != "") {
              var tmpqstartinf = await this.scene.registry.list.rchttp.getcommon2("queststartinfo", { questid: this['requirements']['questdone'] });
              reqstr = reqstr + "\n" + tmpqstartinf['startby'];
            }


            this.scene.registry.list.gameitf.gamemenutooltip(
              this.scene,
              this,
              '' + reqstr,
              'btm'
              , 300, 1500
            );
          });

        }
      }



      previousy = previousy + this.npcresp[i].displayHeight;
      if (previousy > this.thispageconvh)
        this.thispageconvh = previousy;
      //this.plog.log("ceventsA", this.npcresp[i]._events);
    }

    this.plog.log('override bubbleh2', this.thispageconvh, bubbleypos);

    bubbleh = this.thispageconvh - bubbleypos;
    bubbleh = bubbleh + paddingbtm;
    this.createSpeechBubble(
      gc,
      bubblexpos,
      bubbleypos,
      bubblew,
      bubbleh,
      ' ',
      'left',
      avatarsize
    );
    if (resppage > 1) {
      this.currentresppage = 1;
      this.respperpage = respslot;
      this.resptotalpage = resppage;
      this.npcconver_showpage(gc, 'back');
      this.npclistbackbtn.visible = true;
      this.npclistfwdbtn.visible = true;
      this.npclistbackbtn.displayWidth = width / 5;
      this.npclistbackbtn.scaleY = this.npclistbackbtn.scaleX;
      this.npclistfwdbtn.displayWidth = width / 5;
      this.npclistfwdbtn.scaleY = this.npclistbackbtn.scaleX;

      this.npclistbackbtn.setInteractive();
      this.npclistfwdbtn.setInteractive();
      this.npclistbackbtn.removeAllListeners();
      this.npclistfwdbtn.removeAllListeners();
      this.npclistbackbtn.on(
        'pointerup',
        function (pointer, localX, localY, event) {
          this.plog.log('npc npclistfwdbtn', this);
          this.npcconver_showpage(gc, 'back');
        },
        this
      );
      this.npclistfwdbtn.on(
        'pointerup',
        function (pointer, localX, localY, event) {
          this.plog.log('npc npclistfwdbtn', this);
          this.npcconver_showpage(gc, 'next');
        },
        this
      );
    } else {

      this.npclistbackbtn.visible = false;
      this.npclistfwdbtn.visible = false;
    }

    var backbtnheight = 100;
    this.npcbackbtn.setPosition(0, height - backbtnheight);
    this.npcbackbtn.displayWidth = avatarsize;
    this.npcbackbtn.displayHeight = avatarsize;

    this.npcbackbtn.visible = true;
    this.npcbg.visible = true;

    gc.npccontainer.sendToBack(this.npcbg);

    //gc.npccontainer.sendToBack(this.npcdialogitems0);
    //gc.npccontainer.sendToBack(this.npcdialogitems1);
    //gc.npccontainer.sendToBack(this.npcdialogitems2);
    this.wh.registry.list.ga.se2('npcconver', npcid);

  }
  npcconver_showpage(gc, viewpage) {
    if (viewpage == 'back') {
      if (this.currentresppage > 1) this.currentresppage--;
    }
    if (viewpage == 'next') {
      if (this.currentresppage < this.resptotalpage) this.currentresppage++;
    }
    for (var i = 0; i <= 20; i++) {
      this.npcresp[i].visible = false;
    }
    var numfrom = this.respperpage * (this.currentresppage - 1);
    var numto = this.respperpage * this.currentresppage;
    this.plog.log(
      'npcconver_showpage',
      viewpage,
      this.currentresppage,
      'fromto',
      numfrom,
      numto,
      'perpage',
      this.respperpage
    );

    for (var i = numfrom; i < numto; i++) {
      if (this.npcresp[i] != undefined) {
        this.npcresp[i].visible = true;
      }
    }
    this.kbcursor = 0;
    this.kbcursorupdate();
    //gc.npccontainer.sendToBack(this.npcdialogitems0);
    //gc.npccontainer.sendToBack(this.npcdialogitems1);
    //gc.npccontainer.sendToBack(this.npcdialogitems2);
  }
  createSpeechBubble(gc, x, y, width, height, quote, pos, toppos = 0) {
    if (this.bubbleadded == true) return;
    var bubbleWidth = width;
    var bubbleHeight = height;
    var bubblePadding = 10;
    var arrowHeight = bubbleHeight / 4;

    var bubble = gc.add.graphics({ x: x, y: y });
    gc.npccontainer.add(bubble);
    //  Bubble shadow
    bubble.fillStyle(0x222222, 0.5);
    bubble.fillRoundedRect(6, 6, bubbleWidth, bubbleHeight, 16);

    //  Bubble color
    bubble.fillStyle(0xffffff, 1);

    //  Bubble outline line style
    bubble.lineStyle(4, 0x565656, 1);

    //  Bubble shape and outline
    bubble.strokeRoundedRect(0, 0, bubbleWidth, bubbleHeight, 16);
    bubble.fillRoundedRect(0, 0, bubbleWidth, bubbleHeight, 16);
    var twide;
    var point1X;
    var point1Y;
    var point2X;
    var point2Y;
    var point3X;
    var point3Y;

    if (pos == 'top') {
      //  Calculate arrow coordinates
      twide = Math.floor(toppos / 4);
      point1X = Math.floor(bubbleWidth - (toppos + twide + twide));
      point1Y = 0;
      point2X = Math.floor(bubbleWidth - (toppos + twide));
      point2Y = 0;
      point3X = Math.floor(bubbleWidth - (toppos + 5));
      point3Y = Math.floor(0 - twide * 1.5);
    } else if (pos == 'left') {
      //  Calculate arrow coordinates
      twide = Math.floor(toppos / 4);
      point1X = 0;
      point1Y = Math.floor(twide / 2);
      point2X = 0;
      point2Y = Math.floor(twide * 1.5);
      point3X = Math.floor(0 - twide * 1);
      point3Y = Math.floor(twide * 2);
    } else if (pos == 'right') {
      //  Calculate arrow coordinates
      twide = Math.floor(toppos / 4);
      point1X = bubbleWidth;
      point1Y = Math.floor(twide / 2);
      point2X = bubbleWidth;
      point2Y = Math.floor(twide * 1.5);
      point3X = Math.floor(bubbleWidth + twide * 0.5);
      point3Y = Math.floor(twide * 2);
    }
    //  Bubble arrow shadow
    bubble.lineStyle(4, 0x222222, 0.5);
    //bubble.lineBetween(point2X - 1, point2Y + 6, point3X + 2, point3Y);

    //  Bubble arrow fill
    bubble.fillTriangle(point1X, point1Y, point2X, point2Y, point3X, point3Y);
    bubble.lineStyle(2, 0x565656, 1);
    bubble.lineBetween(point2X, point2Y, point3X, point3Y);
    bubble.lineBetween(point1X, point1Y, point3X, point3Y);

    var content = gc.add.text(0, 0, quote, gc.fs["npcconver_npc"]);
    content.setWordWrapWidth((bubbleWidth - bubblePadding * 2) * 0.9);
    //content.setWidth((bubbleWidth - bubblePadding * 2) * 0.9);
    //content.displayWidth=(bubbleWidth - bubblePadding * 2) * 0.9;
    gc.npccontainer.add(content);
    var b = content.getBounds();
    this.npcbackbtn.displayWidth = bubbleWidth - bubblePadding * 2;
    this.npcbackbtn.displayHeight = bubbleHeight - bubblePadding * 2;

    /*content.setPosition(
      bubble.x + bubbleWidth / 2 - b.width / 2,
      bubble.y + (bubbleHeight / 2 - b.height / 2) + 10
    );*/
    content.setPosition(
      bubble.x + bubblePadding * 2,
      bubble.y + (bubblePadding)
    );
    //content.setOrigin(0,0);
    gc.npccontainer.sendToBack(content);
    gc.npccontainer.sendToBack(bubble);
    this.bubblelist.push(bubble);
    this.plog.log('createSpeechBubble created', bubble);

    return content; //{text: content, bubble: bubble};
  }

  async closedialog(wh) {
    this.kbcursor = 0;
    wh.kbfocus = '';
    wh.walkingtonpc = "";
    this.plog.log('closedialog', wh);
    try {
      await wh.registry.list.gameitf.showgui(wh);
    } catch (e) {
      console.log(e);
    }
    this.wh.tweens.add({
      targets: [
        this.wh.npccontainer
      ],
      alpha: 0,
      ease: 'Power1',
      duration: 100,
      delay: 100,
      yoyo: false,
      repeat: 0,
      onComplete: function () {
        arguments[1][0].scene.plog.log('localmapclose tweencomplete', this);
        arguments[1][0].visible = false;;
      },
    });
    return;

    wh.npccontainer.visible = false;
    if (this.npcdialogitems0 != undefined) {
      this.npcdialogitems0.destroy();
      this.npcdialogitems0 = undefined;
    }
    if (this.npcdialogitems1 != undefined) {
      this.npcdialogitems1.destroy();
      this.npcdialogitems1 = undefined;
    }
    if (this.npcdialogitems2 != undefined) {
      this.npcdialogitems2.destroy();
      this.npcdialogitems2 = undefined;
    }
    if (this.npctext != undefined) {
      this.npctext.destroy();
      this.npctext = undefined;
    }
  }
  async clickchoice(wh) {
    this.plog.log('clickchoice', wh);
    var namea = wh.name.split('-');
    this.plog.log('clickchoice namea', namea);
    var newconverid =
      wh.scene.registry.list.rcvarpass.npcconvers[namea[1]][namea[2]]['sub'][
      namea[3]
      ];
    //this.plog.log("newconver", newconverid);
    this.plog.log(
      'npcconver CLICKCHOICE, conv data ',
      wh.scene.registry.list.rcvarpass.npcconvers
    );
    if (newconverid.module != undefined && newconverid.module != '' && newconverid.module != "npc_back") {
      this.plog.log('npcconver CLICKCHOICE, do module ' + newconverid.module, newconverid);
      await wh.scene.registry.list.npcconver.closedialog(wh.scene.scene.scene);
      wh.kbfocus = "";
      await wh.scene.registry.list.rctoast.sleep(300);
      wh.scene.registry.list.execmod.exec(
        newconverid.module,
        wh.scene.scene.scene
      );
    } else {
      //this.plog.log("xxxx",namea)
      if (newconverid.drama != undefined && newconverid.drama != '') {
        this.plog.log('npcconver CLICKCHOICE, do drama ' + newconverid.drama, newconverid);
        await wh.scene.registry.list.drama.play(
          wh.scene,
          newconverid.drama,
          "npcconver"
        );
        await wh.scene.registry.list.rctoast.sleep(100);

        // this.closedialog(wh);
      }
      while (true) {
        //this.plog.log("npcconverwaiting", wh.scene.registry.list.drama.isdramaplaying);
        if (wh.scene.registry.list.drama.isdramaplaying == true) {
          await wh.scene.registry.list.rctoast.sleep(50);
        } else {
          break;
        }
      }
      if (newconverid.module == "npc_back") {
        this.plog.log("npc_back", newconverid, wh);
        try {
          var pidid = wh.scene.registry.list.rcvarpass.npcconvers[newconverid.npc][newconverid.pid]['main']['pid'];
        } catch (e) {
          this.plog.log(e);
          return;
        }
        this.plog.log("pidid", pidid, wh.scene.registry.list.rcvarpass.npcconvers[newconverid.npc][newconverid.pid]);

        this.conver(newconverid.npc, wh.scene, newconverid.pid);
        return;
      }
      this.plog.log("npcconver pass dramawait", namea[1], newconverid.id)
      wh.scene.registry.list.npcconver.conver(
        namea[1],
        wh.scene,
        newconverid.id
      );
    }
  }
  async clickchoicebyid() {
    var thelink = this.npcresp[this.kbcursor];
    this.plog.log(
      'clickchoicebyid, ',
      this.kbcursor, this.npcresp, thelink
    );
    if (this.npcresp[this.kbcursor] == undefined) {
      this.plog.log("clickchoicebyid()", this.kbcursor, "undefined");
      return;
    }
    this.npcresp[this.kbcursor].emit('pointerup', {});

    this.kbcursor = 0;
    this.kbcursorupdate();
    return;

    if (thelink == undefined) return;
    this.plog.log(
      'clickchoicebyid, ',
      this.kbcursor, this.npcresp, thelink.name
    );
    this.plog.log('clickchoicebyid, CHOSEN ', this, thelink);
    var namea = thelink.name.split("-");
    var newconverid =
      thelink.scene.registry.list.rcvarpass.npcconvers[namea[1]][namea[2]]['sub'][
      namea[3]
      ];
    this.plog.log("newconver", newconverid);
    this.plog.log(
      'clickchoicebyid, conv data ',
      thelink.scene.registry.list.rcvarpass.npcconvers
    );

    //if (newconverid['requirements']!=null) {
    var chkclickidreq = thelink.scene.registry.list.rcreq.chk(newconverid['requirements']);
    if (chkclickidreq != true) {

      var chkclickidreqmsg = thelink.scene.registry.list.rcreq.chk(newconverid['requirements'], "text");
      if (newconverid['requirements']['questdone'] != "") {
        var tmpqstartinf = await this.rcvarsservice.rchttp.getcommon2("queststartinfo", { questid: newconverid['requirements']['questdone'] });
        chkclickidreqmsg = chkclickidreqmsg + "\n\n" + tmpqstartinf['startby'];
      }
      this.rctoast.rclongalert(this.wh, 'Message', chkclickidreqmsg);
      return;

    }
    //}
    if (newconverid.module != undefined && newconverid.module != '') {
      this.plog.log('CLICKCHOICE, do module ' + newconverid.module, newconverid);
      if (newconverid.module == "npc_back") {
        this.plog.log("npc_back", newconverid, this.wh);
        try {
          var pidid = this.wh.registry.list.rcvarpass.npcconvers[newconverid.npc][newconverid.pid]['main']['pid'];
        } catch (e) {
          this.plog.log(e);
          return;
        }
        this.plog.log("pidid", pidid, this.wh.registry.list.rcvarpass.npcconvers[newconverid.npc][newconverid.pid]);

        this.conver(newconverid.npc, this.wh, pidid);
      } else {
        //thelink.scene.registry.list.npcconver.closedialog(thelink.scene.scene.scene);
        this.closedialog(this.wh);
        //thelink.scene.scene.scene.kbfocus = "";
        this.wh.kbfocus = "";
        await this.wh.registry.list.rctoast.sleep(300);

        this.wh.registry.list.execmod.exec(
          newconverid.module,
          this.wh
        );
      }
    } else {
      if (newconverid.drama != undefined && newconverid.drama != '') {
        this.plog.log('npcconver CLICKCHOICE, do drama ' + newconverid.drama, newconverid);
        await thelink.scene.registry.list.drama.play(
          thelink.scene,
          newconverid.drama,
          "npcconver"
        );
        await thelink.scene.registry.list.rctoast.sleep(100);

        // this.closedialog(wh);
      }
      while (true) {
        //this.plog.log("npcconverwaiting", wh.scene.registry.list.drama.isdramaplaying);
        if (thelink.scene.registry.list.drama.isdramaplaying == true) {
          await thelink.scene.registry.list.rctoast.sleep(50);
        } else {
          break;
        }
      }

      //this.plog.log("xxxx",namea)
      thelink.scene.registry.list.npcconver.conver(
        namea[1],
        thelink.scene,
        newconverid.id
      );
    }
    this.kbcursor = 0;
    this.kbcursorupdate();
  }
  kbcursorupdate() {
    if (this.kbcursor <= 0) this.kbcursor = 0;
    if (this.kbcursor >= this.npcresp.length) this.kbcursor = this.npcresp.length;
    if (this.kbcursor >= this.converlen - 1) this.kbcursor = this.converlen - 1;
    this.plog.log("npcconver kbcursorupdate", this.kbcursor);
    this.kbcursorindi.displayWidth = this.kbcursorindi.displayHeight = 24;
    if (this.npcresp[this.kbcursor] != undefined) {
      this.kbcursorindi.visible = true;
      this.kbcursorindi.setPosition(this.npcresp[this.kbcursor].x - 2, this.npcresp[this.kbcursor].y + 8);
    }
  }
  typenpctext() {

    if (this.npctexttypetimer != undefined) {
      this.npctexttypetimer.remove(false);
      this.npctexttypetimer = undefined;
    }
    if (this.npctext == undefined) return;
    //this.plog.error("battleticktime",(1000 / this.timerpersec) * this.timespeed);
    var delay = 30;
    var npctextlen = this.npctext.text + "";
    var npctextlenlen = npctextlen.length;
    //this.plog.log("npctypetext", npctextlen, npctextlenlen);
    if (npctextlenlen < this.npctextfull.length) {
      this.npctext.setText(this.npctextfull.substring(0, npctextlenlen + 3));
    } else {
      return;
    }
    //this.timerpersec// * this.timespeed,
    this.npctexttypetimer = this.wh.time.addEvent(
      {
        delay: delay,// * this.timespeed,
        callback: this.typenpctext,
        args: [],
        callbackScope: this,
        loop: false,
        repeat: 0,
        startAt: 0,
        timeScale: 1,
        paused: false,
      },
      this
    );
  }
}
