import { Injectable } from '@angular/core';
import { RCVarsService } from './rcvars-service.service';
import { RCOSDService } from './rcosd.service';
import { RCHTTP } from './rchttp.service';
import { PGBar } from './pgbar';
import { plog } from './plog.service';

@Injectable({
  providedIn: 'root',
})
export class RCSkillService {
  plog;
  shopdragbtn;
  skillpg;
  title;
  skicon = [];
  skob = [];
  sktt = [];
  skbg = [];
  currentmode;
  currentsubid;
  skdescr = [];
  skcraft = [];
  filtertype = '';
  resptotalpage;
  respperpage = 6;
  currentresppage = 1;
  countraceskill;
  skillctnr_char;
  selectcircle;
  scrollareabox;
  shopclosebtn;
  itemdescr;
  eqitems = [];
  eqitemsp = [];
  itemwh = 54;
  shopguibg;
  wh;
  menubaritem = [];
  onoffbtn = [];
  shopfwdbtn;
  shopbackbtn;
  loading;
  isbadint = "no";

  constructor(
    public rcvarsservice: RCVarsService,
    public rcosd: RCOSDService,
    public rchttp: RCHTTP
  ) {
    this.plog = new plog();
    this.plog.setLevel(2);
  }


  loadingshow() {
    this.wh.skillcontainer.bringToTop(this.loading);
    this.loading.visible = true;
  }
  loadinghide() {
    this.loading.visible = false;
  }

  firsttimerun = true;
  firsttimerunfunc() {
    if (this.firsttimerun == true) {
      ///this.wh.registry.list.gameitf.guiwindowclick(this.wh.skillcontainer);
    }
    this.firsttimerun = false;
  }
  async show(wh, forcefocus = false) {
    if (forcefocus == true) this.firsttimerun = true;
    this.wh = wh;
    if (wh.skillcontainer != undefined) {
      await this.show_close(this.wh);
    }
    /*
    if (wh.skillcontainer != undefined) {
      wh.skillcontainer.visible = true;
      this.show_loaded(
        wh,
        'char',
        this.rcvarsservice.activechar['race'],
        'normal'
      );
      return;
    }*/
    this.isbadint = await this.wh.registry.list.rcstorage.get("setting_badint", true);

    var width = wh.game.canvas.width;
    var height = wh.game.canvas.height;
    this.plog.log('equipment show', wh, this);

    const shopguibg = wh.add.image(0, 0, 'blankbarguibg');//skillguibg
    shopguibg.setOrigin(0.5, 0.5);
    shopguibg.setInteractive({ pixelPerfect: true });
    shopguibg.removeAllListeners();
    this.shopguibg = shopguibg;
    this.plog.log('skillgui var', this.rcvarsservice);

    this.skillpg = new PGBar(
      this.wh.registry.list.rcvarpass.gameobj.scene.scenes[0],
      {
        x: -140,
        y: 172,
        w: 280,
        h: 8,
        max: 100,
        me: 0,
        barcol1: 0x24ff2b,
        barcol2: 0xc45f00,
        txt: '',
        imgcol: 'orange',
      }, 'img'
    );

    this.shopbackbtn = wh.add.sprite(0, 0, "thesb", "backsign");//wh.add.image(0, 0, 'listbackbtn');
    this.shopbackbtn.setOrigin(0.5, 0.5);
    this.shopbackbtn.setInteractive();
    this.shopbackbtn.removeAllListeners();
    this.shopfwdbtn = wh.add.sprite(0, 0, "thesb", "forwardsign");//wh.add.image(0, 0, 'listfwdbtn');//wh.add.sprite(0, 0, "theb","forwardsign");//
    this.shopfwdbtn.setOrigin(0.5, 0.5);
    this.shopfwdbtn.setInteractive();
    this.shopfwdbtn.removeAllListeners();

    this.shopclosebtn = wh.add.sprite(300, 300, "thesq", 'close');
    this.shopclosebtn.name = 'skipcntnrsizecal';
    this.shopclosebtn.setOrigin(0.5, 0.5);
    this.shopclosebtn.setPosition(155, -200);
    this.shopclosebtn.displayWidth = 40;
    this.shopclosebtn.displayHeight = 40;
    this.shopclosebtn.setInteractive();
    this.shopclosebtn.removeAllListeners();
    this.shopclosebtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        //this.scene.registry.list["thethis"].phm.floorclick(this);
        this.plog.log(
          'gc.npcbackbtn pointerup',
          pointer,
          localX,
          localY,
          event,
          this
        );
        this.show_close(wh);
      },
      this
    );

    wh.skillcontainer = wh.add.container(300, 300);
    wh.skillcontainer._refsrv = this;
    wh.skillcontainer.visible = false;
    wh.skillcontainer.name = "skillcontainer";
    wh.skillcontainer.add(this.shopclosebtn);
    wh.skillcontainer.add(shopguibg);
    wh.skillcontainer.bringToTop(this.shopclosebtn);
    var itemdescr = wh.add.text(0, 0, wh.gl('Click Item to View Description'), wh.fs["winguiitemdescr"]);



    this.loading = wh.add.sprite(300, 300, 'pixelloading').play('pixelloading_b');
    this.loading.setOrigin(0.5, 0.5);
    this.loading.setPosition(0, 0);
    this.loading.displayWidth = this.loading.displayHeight = 60;
    wh.skillcontainer.add(this.loading);
    wh.skillcontainer.bringToTop(this.loading);

    if (wh.skillcontainer_char != undefined) {
      wh.skillcontainer_char.destroy();
    }


    wh.skillcontainer_char = wh.add.container(-130, -100);
    wh.skillcontainer.add(wh.skillcontainer_char);

    wh.skillcontainer.add(itemdescr);
    itemdescr.setOrigin(0, 0);
    itemdescr.setPosition(-140, 174);
    this.skillpg.bar.setPosition(-140, 172);
    this.itemdescr = itemdescr;

    wh.skillcontainer.add(this.shopbackbtn);
    wh.skillcontainer.add(this.shopfwdbtn);

    this.shopbackbtn.displayWidth = 100;
    this.shopfwdbtn.displayWidth = 100;
    this.shopbackbtn.displayHeight = 40;
    this.shopfwdbtn.displayHeight = 40;
    this.shopbackbtn.setPosition(-110, 220);
    this.shopfwdbtn.setPosition(110, 220);

    this.shopbackbtn.removeAllListeners();
    this.shopfwdbtn.removeAllListeners();
    this.shopbackbtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        this.plog.log('shop shopbackbtn', this);
        this.showinventory_showpage(wh, 'back');
      },
      this
    );

    this.shopfwdbtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        this.plog.log('shop shopfwdbtn', this);
        this.showinventory_showpage(wh, 'next');
      },
      this
    );

    //drag

    this.shopdragbtn = wh.add.image(300, 300, 'spacer');
    this.shopdragbtn.setOrigin(0.5, 0.5);
    this.shopdragbtn.setPosition(0, -200);
    this.shopdragbtn.displayWidth = 270;
    this.shopdragbtn.displayHeight = 60;

    this.shopdragbtn.setInteractive();
    this.shopdragbtn.removeAllListeners();
    this.shopdragbtn.on(
      'pointerout',
      function (pointer, localX, localY, event) {
        this.rcvarsservice.gameobj.scene.scenes[0].skillcontainer.dragactive =
          false;
      },
      this
    );
    this.shopdragbtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        this.rcvarsservice.gameobj.scene.scenes[0].skillcontainer.dragactive =
          false;
      },
      this
    );
    this.shopdragbtn.on(
      'pointerdown',
      function (pointer, localX, localY, event) {
        this.rcvarsservice.gameobj.registry.list.gameitf.guiwindowclick(
          this.rcvarsservice.gameobj.scene.scenes[0].skillcontainer
        );

        this.rcvarsservice.gameobj.scene.scenes[0].skillcontainer.dragactive =
          true;
        this.rcvarsservice.gameobj.scene.scenes[0].skillcontainer.dragactivex =
          this.rcvarsservice.gameobj.scene.scenes[0].skillcontainer.x;
        this.rcvarsservice.gameobj.scene.scenes[0].skillcontainer.dragactivey =
          this.rcvarsservice.gameobj.scene.scenes[0].skillcontainer.y;
        this.rcvarsservice.gameobj.scene.scenes[0].skillcontainer.dragactivepx =
          pointer.x;
        this.rcvarsservice.gameobj.scene.scenes[0].skillcontainer.dragactivepy =
          pointer.y;
      },
      this
    );
    this.rcvarsservice.gameobj.scene.scenes[0].skillcontainer.dragactive =
      false;
    this.rcvarsservice.gameobj.scene.scenes[0].skillcontainer.dragactivex = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].skillcontainer.dragactivey = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].skillcontainer.dragactivepx = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].skillcontainer.dragactivepy = 0;
    this.shopdragbtn.on(
      'pointermove',
      function (pointer, localX, localY, event) {
        //this.scene.registry.list["thethis"].phm.floorclick(this);
        //this.plog.log('shop pointermove', pointer, localX, localY, event, this);
        //this.showinventory_close(wh);
        if (
          this.rcvarsservice.gameobj.scene.scenes[0].skillcontainer
            .dragactive == true
        ) {
          var tmpx =
            this.rcvarsservice.gameobj.scene.scenes[0].skillcontainer
              .dragactivex;
          var tmpy =
            this.rcvarsservice.gameobj.scene.scenes[0].skillcontainer
              .dragactivey;
          var tmppx =
            this.rcvarsservice.gameobj.scene.scenes[0].skillcontainer
              .dragactivepx;
          var tmppy =
            this.rcvarsservice.gameobj.scene.scenes[0].skillcontainer
              .dragactivepy;
          var movedx = tmppx - pointer.x;
          var movedy = tmppy - pointer.y;
          //this.plog.log('drag', tmppx, pointer.x, movedx);

          this.rcvarsservice.gameobj.scene.scenes[0].skillcontainer.x =
            tmpx - movedx;
          this.rcvarsservice.gameobj.scene.scenes[0].skillcontainer.y =
            tmpy - movedy;
          //this.maskg.setPosition(this.rcvarsservice.gameobj.scene.scenes[0].skillcontainer.x,this.rcvarsservice.gameobj.scene.scenes[0].skillcontainer.y)
          //this.rcvarsservice.gameobj.scene.scenes[0].skillcontainer.y = pointer.y;
        }
      },
      this
    );

    wh.skillcontainer.add(this.shopdragbtn);
    wh.skillcontainer.bringToTop(this.shopdragbtn);
    this.menubaritem.push(this.shopdragbtn);
    //skilltype bar
    var skill0 = wh.add.sprite(0, 0, "thesq", 'skill');
    wh.skillcontainer.add(skill0);
    wh.skillcontainer.bringToTop(skill0);
    skill0.on('pointerover', function () {
      this.plog.log('this', this);
      this.scene.registry.list.gameitf.gamemenutooltip(
        this.scene,
        this,
        'Character Skill',
        'top'
      );
    });
    var barypos = -157;
    var barxpos = -130;
    var bariconwh = 48;
    var bariconmargin = -2;
    skill0.displayHeight = skill0.displayWidth = bariconwh;
    skill0.setPosition(barxpos, barypos);
    skill0.setInteractive();
    skill0.removeAllListeners();
    skill0.name = vbar;
    this.menubaritem.push(skill0);
    skill0.on('pointerup', function () {
      this.scene.plog.log('this', this);
      this.scene.registry.list.rcskill.show_loaded(this.scene, "char", this.scene.registry.list.rcvarpass.activechar["race"], "normal");

      //this.scene.registry.list.rcskill.buildskillctnr("char", this.scene.registry.list.rcvarpass.activechar["race"]);
    });

    var tmpskilltypes = [
      'axe',
      'bow',
      'dagger',
      'sword',
      'longaxe',
      'longsword',
      'wand',
    ];
    var ibar = 0;
    var bariconwh = 40;
    for (const k in tmpskilltypes) {
      ibar++;
      var vbar = tmpskilltypes[k];
      this.plog.log('itemtype', 'itemtype' + vbar);
      var skill0 = wh.add.sprite(0, 0, "thesq", '' + vbar);

      wh.skillcontainer.add(skill0);
      wh.skillcontainer.bringToTop(skill0);
      this.menubaritem.push(skill0);

      skill0.displayHeight = skill0.displayWidth = bariconwh;
      skill0.setPosition(
        barxpos + ibar * bariconwh + ibar * bariconmargin,
        barypos
      );
      skill0.setInteractive();
      skill0.removeAllListeners();
      skill0.name = vbar;
      skill0.on('pointerup', function () {
        this.scene.plog.log('this', this);
        this.scene.registry.list.rcskill.show_loaded(this.scene, "weapon", this.name, "normal");
        //this.scene.registry.list.rcskill.buildskillctnr("weapon", this.name);
      });
      skill0.on('pointerover', function () {
        this.scene.plog.log('this', this);
        this.scene.registry.list.gameitf.gamemenutooltip(
          this.scene,
          this,
          this.scene.trs("f:skillcontainer:Weapon Skill===Weapon Skill") + ': ' + this.scene.trs("t:item_type:code:name:" + this.name + "===" + this.name),
          'top'
        );
      });
    }

    //var equipment = this.rcvarsservice.activechar["equip"];
    //this.plog.log("equipment eq", equipment);
    //this.plog.log("skillcontainer", wh.skillcontainer, width, height);
    wh.skillcontainer.setPosition(width / 2, height / 2);
    let loader = new Phaser.Loader.LoaderPlugin(wh);

    this.plog.log('eqitems', this.eqitems);

    //move
    wh.skillcontainer.x = width - this.shopguibg.width / 2 + 20;



    this.title = wh.add.text(0, 0, 'SKILLS', wh.fs["winguititle42"]);
    wh.skillcontainer.add(this.title);
    wh.skillcontainer.bringToTop(this.title);
    this.title.setPosition(0 - this.title.displayWidth / 2, -226);

    wh.skillcontainer.add(this.skillpg.bar);
    wh.skillcontainer.bringToTop(this.skillpg.bar);
    wh.skillcontainer.add(this.skillpg.barfg);
    wh.skillcontainer.bringToTop(this.skillpg.barfg);
    this.skillpg.bar.visible = false;
    this.skillpg.barfg.visible = false;
    //this.buildskillctnr("char", this.rcvarsservice.activechar["race"]);

    /*
        var scrollarea = wh.add.image(0, 0, 'red');
        wh.skillcontainer.add(scrollarea);
        wh.skillcontainer.bringToTop(scrollarea);
        scrollarea.setPosition(0, 0);
        scrollarea.displayWidth = scrollarea.displayHeight = 300;
        scrollarea.y = 20;
        scrollarea.setInteractive();
    */
    //  The rectangle they can 'drag' within
    //var zone = wh.add.zone(-200, 0, 300, 300).setOrigin(0,0).setInteractive();
    //wh.skillcontainer_char.setInteractive();

    /*
        scrollarea.on('pointermove', function (pointer) {
          this.plog.log("xxx", this);
          if (pointer.isDown) {
            this.plog.log("xxx", this);
            // text.y += (pointer.velocity.y / 10);
            //text.y = Phaser.Math.Clamp(text.y, -400, 300);
          }
        }, this);
        scrollarea.visible = false;
        //wh.skillcontainer.add(zone);
    */
    /*
    //move to spritepack
        for (var vv in equipment) {
          var vdat = equipment[vv];
          var itemdat = this.rcvarsservice.allitem[vdat.item];
          loader.image(vdat["item"], this.rcvarsservice.rc_baseurl + "item/" + itemdat["pcode"] + "/" + itemdat["sprite"] + ".png");
    
          this.plog.log("load shop ", vv, vdat, itemdat);
        }*/
    var svversionurlstring = this.wh.registry.list.rcvarpass.svversionstring;
    if (svversionurlstring != '') {
      svversionurlstring = '?' + svversionurlstring;
    }
    var loadlist = ['skill_weapon_tp', 'skill_tp'];
    for (var k in loadlist) {
      var key = loadlist[k];
      if (this.isbadint == "yes") {
        loader.atlas(
          key + '',
          this.wh.registry.list.rcvarpass.rc_baseurl +
          '_badint/sprite/' +
          key +
          '.png' +
          svversionurlstring,
          this.wh.registry.list.rcvarpass.rc_baseurl +
          'sprite/' +
          key +
          '.json' +
          svversionurlstring
        );
      } else {

        loader.atlas(
          key + '',
          this.wh.registry.list.rcvarpass.rc_baseurl +
          'sprite/' +
          key +
          '.png' +
          svversionurlstring,
          this.wh.registry.list.rcvarpass.rc_baseurl +
          'sprite/' +
          key +
          '.json' +
          svversionurlstring
        );
      }
    }
    loader.once(
      Phaser.Loader.Events.COMPLETE,
      () => {
        this.plog.log('Phaser.Loader.Events.COMPLETE');
        this.plog.log(this);
        this.show_loaded(
          wh,
          'char',
          this.rcvarsservice.activechar['race'],
          'normal'
        );
      },
      this
    );
    this.wh.registry.list.gameitf.guiwindowclick(this.wh.skillcontainer);
    loader.start();
  }
  movemenubartotop() {
    for (const k in this.menubaritem) {
      var v = this.menubaritem[k];
      this.wh.skillcontainer.bringToTop(v);
    }
  }

  show_loaded(wh, mode, subid, pagemode = 'normal') {
    this.plog.log('skill show_loaded', wh, mode, subid, pagemode);

    this.currentmode = mode;
    this.currentsubid = subid;
    var margintop = -90;
    var marginleft = -117;
    var i = 0;
    for (var i = 0; i < 100; i++) {
      if (this.skicon[i] != undefined) {
        //this.plog.log('shopitemlist', this.skicon[i]);
        this.skicon[i].visible = false;
        //this.shopitemlistp[i].setText("");
        this.sktt[i].visible = false;
        this.skbg[i].visible = false;
        if (this.skob[i] != undefined)
          this.skob[i].visible = false;
        this.skdescr[i].visible = false;
        if (this.skcraft[i] != undefined)
          this.skcraft[i].visible = false;
      }
    }

    for (var i = 0; i < 100; i++) {
      if (this.skicon[i] != undefined) this.skicon[i].destroy(); // = undefined;
      if (this.sktt[i] != undefined) this.sktt[i].destroy(); // = undefined;
      if (this.skbg[i] != undefined) this.skbg[i].destroy(); // = undefined;
      if (this.skob[i] != undefined) this.skob[i].destroy(); // = undefined;
      if (this.skdescr[i] != undefined) this.skdescr[i].destroy(); // = undefined;
      if (this.onoffbtn[i] != undefined) this.onoffbtn[i].destroy(); // = undefined;
      if (this.onoffbtn[i] != undefined) this.onoffbtn[i] = undefined;
      if (this.skcraft[i] != undefined) this.skcraft[i].destroy(); // = undefined;

    }

    this.plog.log('pagemode', pagemode, this.currentresppage);
    if (pagemode == 'normal') {
      this.currentresppage = 1;
    }
    if (pagemode == 'retainpage') {
      if (this.currentresppage == 0 || this.currentresppage == undefined)
        this.currentresppage = 1;
    }

    if (mode == 'char') {
      var dat = this.rcvarsservice.skilltp;
    }
    if (mode == 'weapon') {
      var dat = this.rcvarsservice.skillweapontp;
    }

    this.countraceskill = 0;
    for (const k in dat) {
      var v = dat[k];
      if (mode == 'char') {
        if (v['targetrace'] != subid) continue;
      }
      if (mode == 'weapon') {
        if (v['pcode'] != subid) continue;
      }
      this.countraceskill++;
    }
    this.plog.log('skill show_loaded', wh, dat);

    var rowh = 50;
    var iconwh = 40;
    var i = 0;
    var rowi = 0;
    for (var vv in dat) {
      var vdat = dat[vv];

      if (mode == "char") {
        //this.plog.log('skill mode char show_loaded i', i, vdat["targetrace"], subid, vdat);
        if (vdat["targetrace"] != subid) continue;
      }
      if (mode == "weapon") {
        if (vdat["pcode"] != subid) continue;
      }
      //this.plog.log("xxxskdat", v);
      var codeicon = (vdat["code"] + "").replace("-", "");

      if (mode == "char") {
        this.skicon[i] = wh.add.sprite(0, 0, "skill_tp", codeicon);
        this.skicon[i].name = "char----" + vdat["code"];

      }
      if (mode == "weapon") {
        this.skicon[i] = wh.add.sprite(0, 0, "skill_weapon_tp", vdat["code"]);
        this.skicon[i].name = "weapon---" + vdat["code"];

      }
      //this.plog.log('skill show_loaded i', i, vdat);

      wh.skillcontainer_char.add(this.skicon[i]);
      this.skicon[i].displayHeight = this.skicon[i].displayWidth = iconwh;
      this.skicon[i].setPosition((iconwh / 2) - 20, (rowi * rowh) - 5);
      this.skbg[i] = wh.add.image(0, 0, "rowbg");
      wh.skillcontainer_char.add(this.skbg[i]);
      this.skbg[i].setOrigin(0, 0);
      this.skbg[i].setInteractive();
      this.skbg[i].removeAllListeners();
      this.skbg[i].name = mode + "---" + vdat["code"];

      this.skbg[i].on('pointerup', function (pointer) {
        //this.scene.plog.log("xxx,skill,", this);
        var tmpnamea = this.name.split("---");
        if (tmpnamea[0] == "char") {
          this.scene.registry.list.rcosd.show(wh, "skill", this.scene.registry.list.rcvarpass["skilltp"][tmpnamea[1]]);
        }
        if (tmpnamea[0] == "weapon") {
          this.scene.registry.list.rcosd.show(wh, "skill", this.scene.registry.list.rcvarpass["skillweapontp"][tmpnamea[1]]);
        }
      });

      this.skbg[i].setPosition(-20, ((rowi * rowh) - (rowh / 2)) + 5);
      this.skbg[i].displayHeight = iconwh;
      this.skbg[i].displayWidth = 300;
      wh.skillcontainer_char.sendToBack(this.skbg[i]);

      this.sktt[i] = wh.add.text(0, 0, vdat["name"] + " lv." + vdat["level"], wh.fs["winguilist_title"]);
      wh.skillcontainer_char.add(this.sktt[i]);
      wh.skillcontainer_char.bringToTop(this.sktt[i]);
      this.sktt[i].setPosition(30, (rowi * rowh) - 25);
      //tmpskname.setResolution(0.25);
      //this.plog.log("zzzzzz", this.sktt[i]);
      var tmpsktype = "(Passive)";
      if (vdat["skill_type"] == "active") tmpsktype = "(Active)";
      this.skdescr[i] = wh.add.text(0, 0, tmpsktype + " " + vdat["descr"], wh.fs["winguilist_descr"]);
      wh.skillcontainer_char.add(this.skdescr[i]);
      wh.skillcontainer_char.bringToTop(this.skdescr[i]);
      this.skdescr[i].setPosition(30, (rowi * rowh) - 3);


      if (mode == "char") {
        if (this.rcvarsservice.activechar["cbt"]["skill"].includes(vdat["code"])) {
          //this.plog.log("skillobtained", vdat);
          this.skob[i] = wh.add.text(0, 0, "Obtained", wh.fs["winguilist_descr_green"]);
          wh.skillcontainer_char.add(this.skob[i]);
          wh.skillcontainer_char.bringToTop(this.skob[i]);
          this.skob[i].setPosition(210, (rowi * rowh) - 25);
          if (vdat["skill_type"] == "active") {
            this.onoffbtn[i] = wh.add.sprite(0, 0, "thesq", "off");
            this.onoffbtn[i].name = "char---" + vdat["code"];
          }
        } else {
          //craft
          if (this.rcvarsservice.activechar['level'] >= vdat['level']) {

            this.skcraft[i] = wh.add.sprite(0, 0, "thesq", "craft");
            wh.skillcontainer_char.add(this.skcraft[i]);
            wh.skillcontainer_char.bringToTop(this.skcraft[i]);

            //console.log(this.rcvarsservice.activechar,vdat);

            this.skcraft[i].setPosition(260, (rowi * rowh) - 12);
            this.skcraft[i].displayWidth = this.skcraft[i].displayHeight = 32;
            this.skcraft[i].setInteractive();
            this.skcraft[i].removeAllListeners();
            this.skcraft[i].name = "char---" + vdat["code"];

            this.skcraft[i].on('pointerover', async function () {
              this.scene.registry.list.gameitf.gamemenutooltip(
                this.scene,
                this,
                this.scene.trs("f:skillcontainer:craftthisskill===Craft this skill"),
                'left'
              );
            });
            this.skcraft[i].on('pointerup', async function () {
              //this.plog.log('skcraft', this.name, this);
              if (this.scene.registry.list.gameitf.clicktoosoon()) return; this.scene.registry.list.gameitf.markclick();

              this.scene.registry.list.skillcraft.show(this.scene, this.name, true);
            });

          }
        }
      }
      if (mode == "weapon" && this.rcvarsservice.activechar["cbt"]["allskillweapon"].includes(vdat["code"])) {
        //this.plog.log("skillobtained", vdat);
        this.skob[i] = wh.add.text(0, 0, "Obtained", wh.fs["winguilist_descr_green"]);
        wh.skillcontainer_char.add(this.skob[i]);
        wh.skillcontainer_char.bringToTop(this.skob[i]);
        this.skob[i].setPosition(210, (rowi * rowh) - 25);
        if (vdat["skill_type"] == "active") {
          this.onoffbtn[i] = wh.add.sprite(0, 0, "thesq", "off");
          this.onoffbtn[i].name = "weapon---" + vdat["code"];
        }
      }

      //this.onoffbtn[i].visible = false;
      if (this.onoffbtn[i] != undefined) {
        if (wh.registry.list.rcvarpass.activechar != undefined &&
          wh.registry.list.rcvarpass.activechar["cbt"] != undefined &&
          wh.registry.list.rcvarpass.activechar["cbt"]["skillconfig"] != undefined &&
          wh.registry.list.rcvarpass.activechar["cbt"]["skillconfig"][vdat["code"]] != undefined) {
          this.onoffbtn[i].setTexture("thesq", "off");
          this.plog.log("onoffbtn exists is off", i, this.onoffbtn[i]);

        } else {
          this.onoffbtn[i].setTexture("thesq", "on");
          this.plog.log("onoffbtn exists is ON", i, this.onoffbtn[i]);
        }
        this.onoffbtn[i].removeAllListeners();
        this.onoffbtn[i].setInteractive();
        this.onoffbtn[i].on("pointerup", async function () {
          var tmpnamea = this.name.split("---");
          this.scene.plog.log("onoffbtn pointerup", this.name, tmpnamea, this.scene.registry.list.rcvarpass.activechar);
          if (this.scene.registry.list.rcvarpass.activechar == undefined) return;
          if (this.scene.registry.list.rcvarpass.activechar["cbt"] == undefined) return;
          if (this.scene.registry.list.rcvarpass.activechar["cbt"]["skillconfig"] == undefined) return;
          if (this.scene.registry.list.rcvarpass.activechar["cbt"]["skillconfig"][tmpnamea[1]] == undefined) {
            this.scene.registry.list.rchttp.getcommon2("skillconfig", { setoff: this.name });
            this.scene.plog.log("onoffbtn", this.name, "currentoff", this.scene.registry.list.rcvarpass.activechar["cbt"]["skillconfig"]);
          } else {
            this.scene.registry.list.rchttp.getcommon2("skillconfig", { seton: this.name });
            this.scene.plog.log("onoffbtn", this.name, "currentON", this.scene.registry.list.rcvarpass.activechar["cbt"]["skillconfig"]);
          }
          await this.scene.registry.list.rchttp.updatecharinfo();

        });

        wh.skillcontainer_char.add(this.onoffbtn[i]);
        wh.skillcontainer_char.bringToTop(this.onoffbtn[i]);
        this.onoffbtn[i].setPosition(this.skbg[i].x + this.skbg[i].displayWidth, (this.skbg[i].y - this.skbg[i].displayHeight / 2) + 24);
        this.plog.log("onoffbtn", this.onoffbtn[i]);
        this.onoffbtn[i].displayWidth = this.onoffbtn[i].displayHeight = 24;

      }

      i++;
      rowi++;
      if (rowi % 6 == 0) rowi = 0;
    }
    //this.plog.log('shop i count', i);
    if (i > 6) {
      //pages

      this.respperpage = 6;
      this.resptotalpage = Math.ceil(i / this.respperpage);
    }
    //this.rcvarsservice.gameobj.registry.list.gameitf.guiwindowclick(this.rcvarsservice.gameobj.scene.scenes[0].skillcontainer);
    if (pagemode == "normal") {
      this.showinventory_showpage(wh, "back");
    } else {
      this.showinventory_showpage(wh, "retainpage");
    }
    //this.showinventory_showpage(wh, "back");
    this.firsttimerunfunc();

    this.wh.skillcontainer.visible = true;
    this.wh.registry.list.resizeman.RCMapResizeManager_work(this.wh);



  }
  async show_close(wh) {
    //wh.skillcontainer.visible = false;

    await wh.registry.list.gameitf.closegui(wh, wh.skillcontainer);

    for (const k in this.rcvarsservice.gameobj.scene.scenes[0].skillcontainer.list) {
      try {
        await this.rcvarsservice.gameobj.scene.scenes[0].skillcontainer.remove(this.rcvarsservice.gameobj.scene.scenes[0].skillcontainer.list[k], true);
      } catch (e) {
        this.plog.log("ERROR!", e);
      }
      //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list[k].destroy();
    }
    for (const k in this.rcvarsservice.gameobj.scene.scenes[0].skillcontainer.list) {
      try {
        await this.rcvarsservice.gameobj.scene.scenes[0].skillcontainer.remove(this.rcvarsservice.gameobj.scene.scenes[0].skillcontainer.list[k], true);
      } catch (e) {
        this.plog.log("ERROR!", e);
      }
      //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list[k].destroy();
    }

    try {
      wh.skillcontainer.destroy();
    } catch (e) {
      this.plog.log("ERROR!", e);
    }

    try {
      wh.skillcontainer.destroy(true);
    } catch (e) {
      this.plog.log("ERROR!", e);
    }

    try {
      this.skillpg.bar.destroy();
      this.skillpg.txt.destroy();
    } catch (e) {
      this.plog.log("ERROR!", e);
    }
    for (var i = 0; i < 100; i++) {
      if (this.skicon[i] != undefined) this.skicon[i].destroy(); // = undefined;
      if (this.sktt[i] != undefined) this.sktt[i].destroy(); // = undefined;
      if (this.skbg[i] != undefined) this.skbg[i].destroy(); // = undefined;
      if (this.skob[i] != undefined) this.skob[i].destroy(); // = undefined;
      if (this.skdescr[i] != undefined) this.skdescr[i].destroy(); // = undefined;
      if (this.skcraft[i] != undefined) this.skcraft[i].destroy(); // = undefined;
      if (this.onoffbtn[i] != undefined) this.onoffbtn[i].destroy(); // = undefined;
      if (this.onoffbtn[i] != undefined) this.onoffbtn[i] = undefined;

    }

  }

  showinventory_showpage(gc, viewpage) {
    this.skillpg.bar.visible = false;
    this.skillpg.barfg.visible = false;

    var griddat = [];
    var griddatf = [];
    griddat.push(this.shopclosebtn);
    griddat.push(this.shopbackbtn);
    griddat.push(this.shopfwdbtn);

    for (const k in this.menubaritem) {
      if (this.menubaritem[k].type == "Image") continue;
      this.plog.log("skillmenubaritem", this.menubaritem[k]);
      griddat.push(this.menubaritem[k]);
    }
    if (this.currentmode == "char") {
      this.itemdescr.setText(this.wh.trs("f:skillcontainer:Click skill to view description===Click skill to view description"));

    }
    if (this.currentmode == "weapon") {
      this.skillpg.bar.visible = true;
      this.skillpg.barfg.visible = true;
      //if (this.rcvarsservice.activechar["cbt"]["allskillweaponpg"].includes(this.currentsubid)) {
      if (
        this.rcvarsservice.activechar != undefined &&
        this.rcvarsservice.activechar["cbt"] != undefined &&
        this.rcvarsservice.activechar["cbt"]["allskillweaponpg"] != undefined &&
        this.rcvarsservice.activechar["cbt"]["allskillweaponpg"][this.currentsubid] != undefined
      ) {
        var tmpaddstr = "";
        if (this.rcvarsservice.activechar["cbt"]["allskillweaponpg"][this.currentsubid]["iscurrent"] == "yes") {
          tmpaddstr = " (equipped)";
        }
        var tmpwpname = this.currentsubid + "";
        tmpwpname = tmpwpname.toLowerCase().replace(/\b[a-z]/g, function (letter) {
          return letter.toUpperCase();
        });
        var tmplevel = this.rcvarsservice.activechar["cbt"]["allskillweaponpg"][this.currentsubid]["level"];
        this.itemdescr.setText(tmpwpname + " lv." + tmplevel + " : exp " + this.wh.registry.list.gameitf.numberWithCommas(this.rcvarsservice.activechar["cbt"]["allskillweaponpg"][this.currentsubid]["xp"]) + "/" + this.wh.registry.list.gameitf.numberWithCommas(this.rcvarsservice.level[tmplevel]["xp"]) + tmpaddstr);
        this.skillpg.set(this.rcvarsservice.activechar["cbt"]["allskillweaponpg"][this.currentsubid]["xp"], this.rcvarsservice.level[tmplevel]["xp"]);
        //this.wh.registry.list.gameitf.humannumber(this.rcvarsservice.activechar["cbt"]["allskillweaponpg"][this.currentsubid]["xp"]) + "/" + this.wh.registry.list.gameitf.humannumber(this.rcvarsservice.level[tmplevel]["xp"])
      } else {
        this.itemdescr.setText(this.currentsubid + " skills");
        this.skillpg.set(0, 100);
      }
    }
    if (viewpage == 'back') {
      if (this.currentresppage > 1) this.currentresppage--;
    }
    if (viewpage == 'next') {
      if (this.currentresppage < this.resptotalpage) this.currentresppage++;
    }
    //console.log(this.currentmode);
    for (var i = 0; i <= 100; i++) {
      //this.plog.log(this.shopitemlist[i]);
      if (this.skicon[i] != undefined) {
        this.skicon[i].visible = false;
      }
      if (this.sktt[i] != undefined) {
        this.sktt[i].visible = false;
      }
      if (this.skob[i] != undefined) {
        this.skob[i].visible = false;
      }
      if (this.skbg[i] != undefined) {
        this.skbg[i].visible = false;
      }
      if (this.skdescr[i] != undefined) {
        this.skdescr[i].visible = false;
      }

      if (this.skcraft[i] != undefined) {
        this.skcraft[i].visible = false;
      }
      if (this.onoffbtn[i] != undefined) {
        this.onoffbtn[i].visible = false;
        griddat.push(this.onoffbtn[i]);
        griddatf.push(this.onoffbtn[i]);
      }
    }
    var numfrom = this.respperpage * (this.currentresppage - 1);
    var numto = this.respperpage * this.currentresppage;
    this.plog.log(
      'showinventory_showpage',
      viewpage,
      this.currentresppage,
      'fromto',
      numfrom,
      numto,
      'perpage',
      this.respperpage
    );
    this.loadinghide();
    for (var i = numfrom; i < numto; i++) {
      if (this.skicon[i] != undefined) {
        this.skicon[i].visible = true;
        this.skbg[i].visible = true;
        if (this.skob[i] != undefined)
          this.skob[i].visible = true;
        this.sktt[i].visible = true;
        this.skdescr[i].visible = true;
        if (this.onoffbtn[i] != undefined)
          this.onoffbtn[i].visible = true;
        if (this.currentmode == "char") {
          if (this.skcraft[i] != undefined) {
            this.skcraft[i].visible = true;
            griddat.push(this.skcraft[i]);
          }
        } else {
          if (this.skcraft[i] != undefined) {
            this.skcraft[i].visible = false;
          }
        }
        var namea = this.skicon[i].name.split('-');
      }
    }

    this.wh.registry.list.gp.guigrid(griddat, griddatf);

  }
}
