import { Injectable } from '@angular/core';
import { RCVarsService } from './rcvars-service.service';
import { plog } from './plog.service';

@Injectable({
  providedIn: 'root',
})
export class GPService {
  plog;
  slfocusl;
  guigrid_initx = 0;
  guigrid_inity = 0;
  guigrid_maxx = 0;
  guigrid_maxy = 0;
  slfocusr;
  guigrid_grid = [];
  guigrid_origgrid;
  guigrid_preferfirst = [];
  guigrid_current = undefined;
  guigrid_grindround = 10;
  guigrid_noclosebtn = false; // just close on call do_escape
  wh;
  width; height;
  isUp = false;
  isDown = false;
  isLeft = false;
  isRight = false;
  arrowDown = false;
  gamemenu_sl_vh = "";
  gamemenu_sl_vi = 1;
  gamemenu_sl_hi = 1;
  inputboxhist1 = "";
  inputboxhist2 = "";
  inputboxhist3 = "";

  constructor(
    public rcvars: RCVarsService
  ) {
    //this.plog.log("SND constructor", this);
    this.plog = new plog();
    //this.plog.setLevel(4);
  }
  initgp(wh) {
    this.wh = wh;

    this.width = this.wh.game.canvas.width;
    this.height = this.wh.game.canvas.height;

    const pads = this.wh.input.gamepad.gamepads;

    // var pads = this.input.gamepad.getAll();
    // var pads = navigator.getGamepads();

    for (let i = 0; i < pads.length; i++) {
      const pad = pads[i];
      this.plog.log("GPS intgp() registering", pad);
      if (!pad) {
        continue;
      }
      pad.removeAllListeners();
      pad.on('down', (index, value, button) => {
        //this.plog.log("GPS initgp() down arguments", arguments);
        this.gphandler_down(arguments, index, value, button);
      });
      pad.on('up', (index, value, button) => {
        //this.plog.log("GPS initgp() up arguments", arguments);
        this.gphandler_up(arguments, index, value, button);
      });
    }
    this.wh.input.gamepad.once('connected', pad => {
      //this.plog.log("GPS intgp() once connect", pad);
      pad.removeAllListeners();
      pad.on('down', (index, value, button) => {
        //this.plog.log("GPS connected arguments", arguments)
        this.gphandler_down(arguments, index, value, button);
      });
      pad.on('up', (index, value, button) => {
        //this.plog.log("GPS connected arguments", arguments);
        this.gphandler_up(arguments, index, value, button);
      });
    });
    this.wh.input.gamepad.once('disconnected', pad => {
      this.wh.plog.log("GPS intgp() once disconnected", pad);
      pad.off();
    });
  }
  style_h1 = "font-weight: bold; ";
  async gphandler_down(x1, index, value, button) {
    this.plog.log("GPS gphandler_down", x1, "index=", index, "value=", value, "button=", button, "kbfocus=", this.wh.kbfocus);
    //this.plog.log("GPS gphandler_down", x1, "button=", button, "kbfocus=", this.wh.kbfocus);
    if (this.wh.focusguil == undefined) {
      return;
    }
    for (const k in button.pad) {
      var v = button.pad[k];
      if (v.pressed == undefined) continue;
      if (v.pressed == true) {
        //this.plog.log(k,v)
        this.plog.log("%cGPS gphandler_down", this.style_h1, k, "kbfocus=", this.wh.kbfocus);
        this.plog.log("GPS gphandler_down kbfocuslv1=", this.wh.kbfocuslv1);
      }
    }
    //this.plog.log("GPS gphandler_down this.wh.kbfocus", this.wh.kbfocus);
    if (button != undefined && button.pad != undefined) {
      if (button.pad._LCLeft && button.pad._LCLeft.pressed) {
        this.isLeft = true;
      }
      if (button.pad._LCRight && button.pad._LCRight.pressed) {
        this.isRight = true;
      }
      if (button.pad._LCTop && button.pad._LCTop.pressed) {
        this.isUp = true;
      }
      if (button.pad._LCBottom && button.pad._LCBottom.pressed) {
        this.isDown = true;
      }
      /*if (button.pad._FBLeftTop && button.pad._FBLeftTop.pressed) {
        //it's _FBLeftBottom on XBox controller
        if (this.wh.kbfocus == "") {
          this.do_escape(); return;
        }
      }*/
      if ((button.pad._FBLeftTop && button.pad._FBLeftTop.pressed)) {
        //it's _FBLeftBottom on XBox controller
        if (this.wh.kbfocus == "") {
          ///this.do_gamemenu(); return;
          this.wh.registry.list.gameitf.guiwindowhideall(this.wh);
          this.wh.minimap.visible = true;
          this.wh.gamemenu_showing = false;
          this.wh.gamemenu_main.emit("pointerdown");
          await this.wh.registry.list.resizeman.RCMapResizeManager_work(this.wh);
          await this.wh.registry.list.gameitf.updatereddots(this.wh);
          //}
          var griddat = [];
          var griddatf = [];
          for (let key of Object.keys(this.wh.gamemenu)) {
            let val = this.wh.gamemenu[key];
            griddat.push(val);
            griddatf.push(val);
          }
          this.wh.registry.list.gp.guigrid(griddat, griddatf);
          this.wh.registry.list.gp.guigrid_noclosebtn = true;
          return;
        }
        /*if (this.wh.kbfocus == "gamemenu") {
          this.wh.gamemenu_showing = true;
          this.do_gamemenu();
        }*/
      }
    }
    this.arrowDown = false;
    this.arrowDown = this.isUp || this.isDown || this.isLeft || this.isRight;
    ////////////////////////////////
    if (this.wh.kbfocuslv1 != "") {

      if (this.wh.kbfocuslv1 == "showreward") {
        if ((button.pad._RCRight && button.pad._RCRight.pressed) ||
          (button.pad._RCBottom && button.pad._RCBottom.pressed)) {

          try {
            this.wh.registry.list.showreward.tmpbg.emit("pointerdown");
          } catch (e) {
            this.plog.warn(e);
          }
          this.wh.kbfocuslv1 = "";
          return;
        }
        return;
      }
      if (this.wh.kbfocuslv1 == "localmap") {
        if ((button.pad._RCRight && button.pad._RCRight.pressed) ||
          (button.pad._RCBottom && button.pad._RCBottom.pressed)) {

          try {
            this.wh.registry.list.rctoast.localmap_close();;
          } catch (e) {
            this.plog.warn(e);
          }
          this.wh.kbfocuslv1 = "";
          return;
        }
        return;
      }

      if (this.wh.kbfocuslv1 == "npcvideo") {
        if ((button.pad._RCRight && button.pad._RCRight.pressed) ||
          (button.pad._RCBottom && button.pad._RCBottom.pressed)) {

          try {
            this.wh.registry.list.rctoast.npcvideo_close()
          } catch (e) {
            this.plog.warn(e);
          }
          this.wh.kbfocuslv1 = "";
          return;
        }
        return;
      }
      if (this.wh.kbfocuslv1 == "spritedialog") {
        if ((button.pad._RCRight && button.pad._RCRight.pressed) ||
          (button.pad._RCBottom && button.pad._RCBottom.pressed)) {

          try {
            this.wh.registry.list.rctoast.spritedialog_close();;
          } catch (e) {
            this.plog.warn(e);
          }
          this.wh.kbfocuslv1 = "";
          return;
        }
        return;
      }
      if (this.wh.kbfocuslv1 == "rcalert") {
        if ((button.pad._RCRight && button.pad._RCRight.pressed) ||
          (button.pad._RCBottom && button.pad._RCBottom.pressed)) {

          try {
            this.plog.log("kbfocusrcalert", this.wh.registry.list.rctoast);
            this.wh.registry.list.rctoast.rcalert_clickclosebtn(this.wh);// = "ok";
          } catch (e) {
            this.plog.warn(e);
          }
          this.wh.kbfocuslv1 = "";
          return;
        }


        return;
      }
      if (this.wh.kbfocuslv1 == "rclongalert") {
        if ((button.pad._RCRight && button.pad._RCRight.pressed) ||
          (button.pad._RCBottom && button.pad._RCBottom.pressed)) {

          try {
            this.plog.log("kbfocusrclongalert", this.wh.registry.list.rctoast);
            this.wh.registry.list.rctoast.rclongalert_clickclosebtn(this.wh);// = "ok";
          } catch (e) {
            this.plog.warn(e);
          }
          this.wh.kbfocuslv1 = "";
          return;
        }


        return;
      }
      if (this.wh.kbfocuslv1 == "fishing") {
        this.plog.error("kbfocuslv1 fishing", this.wh.kbfocuslv1, this.wh.registry.list.rctoast);
        if ((button.pad._RCBottom && button.pad._RCBottom.pressed)) {
          try {
            this.plog.log("kbfocus fishing", this.wh.registry.list.rctoast);
            this.wh.registry.list.fishing.tapped(this.wh);// = "ok";
          } catch (e) {
            this.plog.warn(e);
          }
          //this.wh.kbfocuslv1 = "";
          return;
        }
        if ((button.pad._RCRight && button.pad._RCRight.pressed)) {
          try {
            this.plog.log("kbfocus fishing", this.wh.registry.list.rctoast);
            this.wh.registry.list.fishing.close();// = "ok";
          } catch (e) {
            this.plog.warn(e);
          }
          this.wh.kbfocuslv1 = "";
          return;
        }
        return;

      }

      if (this.wh.kbfocuslv1 == "rcenternum") {
        this.plog.error("kbfocuslv1 rcenternum", this.wh.kbfocuslv1, this.wh.registry.list.rctoast);
        if ((button.pad._RCBottom && button.pad._RCBottom.pressed)) {
          try {
            this.plog.log("kbfocus rcenternum", this.wh.registry.list.rctoast);
            this.wh.registry.list.rctoast.rcenternum_ok();// = "ok";
          } catch (e) {
            this.plog.warn(e);
          }
          this.wh.kbfocuslv1 = "";
          return;
        }
        if ((button.pad._RCRight && button.pad._RCRight.pressed)) {
          try {
            this.plog.log("kbfocus rcenternum", this.wh.registry.list.rctoast);
            this.wh.registry.list.rctoast.rcenternum_cancel();// = "ok";
          } catch (e) {
            this.plog.warn(e);
          }
          this.wh.kbfocuslv1 = "";
          return;
        }
        return;

      }
      if (this.wh.kbfocuslv1 == "rcentertext") {
        this.plog.error("kbfocuslv1 rcentertext", this.wh.kbfocuslv1, this.wh.registry.list.rctoast);
        if ((button.pad._RCBottom && button.pad._RCBottom.pressed)) {
          try {
            this.plog.log("kbfocus rcentertext", this.wh.registry.list.rctoast);
            this.wh.registry.list.rctoast.rcentertext_ok();// = "ok";
          } catch (e) {
            this.plog.warn(e);
          }
          this.wh.kbfocuslv1 = "";
          return;
        }
        if ((button.pad._RCRight && button.pad._RCRight.pressed)) {
          try {
            this.plog.log("kbfocus rcentertext", this.wh.registry.list.rctoast);
            this.wh.registry.list.rctoast.rcentertext_cancel();// = "ok";
          } catch (e) {
            this.plog.warn(e);
          }
          this.wh.kbfocuslv1 = "";
          return;
        }
        return;

      }

      if (this.wh.kbfocuslv1 == "rcconfirm") {
        this.plog.error("kbfocuslv1rcconfirm", this.wh.kbfocuslv1, this.wh.registry.list.rctoast);
        if ((button.pad._RCBottom && button.pad._RCBottom.pressed)) {
          try {
            this.plog.log("kbfocusrcconfirm", this.wh.registry.list.rctoast);
            this.wh.registry.list.rctoast.rcconfirm_clickclose_ok(this.wh);// = "ok";
          } catch (e) {
            this.plog.warn(e);
          }
          this.wh.kbfocuslv1 = "";
          return;
        }
        if ((button.pad._RCRight && button.pad._RCRight.pressed)) {
          try {
            this.plog.log("kbfocusrcconfirm", this.wh.registry.list.rctoast);
            this.wh.registry.list.rctoast.rcconfirm_clickclose_cancel(this.wh);// = "ok";
          } catch (e) {
            this.plog.warn(e);
          }
          this.wh.kbfocuslv1 = "";
          return;
        }
        return;

      }

      if (this.wh.kbfocuslv1 == "diamondoffer") {
        this.plog.error("kbfocuslv1 diamondoffer", this.wh.kbfocuslv1, this.wh.registry.list.rctoast);
        if ((button.pad._RCBottom && button.pad._RCBottom.pressed)) {
          try {
            this.plog.log("kbfocus diamondoffer", this.wh.registry.list.diamondoffer);
            this.wh.registry.list.diamondoffer.btnconfirm.emit("pointerdown");// = "ok";
          } catch (e) {
            this.plog.warn(e);
          }
          //this.wh.kbfocuslv1 = ""; //it will confirm in the next step
          return;
        }
        if ((button.pad._RCRight && button.pad._RCRight.pressed)) {
          try {
            this.plog.log("kbfocus diamondoffer", this.wh.registry.list.diamondoffer);
            this.wh.registry.list.diamondoffer.btncancel.emit("pointerdown");// = "ok";
          } catch (e) {
            this.plog.warn(e);
          }
          this.wh.kbfocuslv1 = "";
          return;
        }
        return;

      }
      if (this.wh.kbfocuslv1 == "combatmovepos") {

        var selectedchar_id = this.wh.registry.list.combat.selectedchar_id;
        var obj = this.wh.registry.list.combat.cbto[selectedchar_id];
        if (obj != undefined) {
          this.plog.log("selectedchar_id", obj);
          var newposx = obj.currentposx;
          var newposy = obj.currentposy;
          if (button.pad._LCLeft && button.pad._LCLeft.pressed) {
            //battlegridbtn pointerdownteam1id
            newposx--;
            var gridbtn = obj.combatobj.battlegridbtn[newposx + "-" + newposy];
            if (gridbtn == undefined || gridbtn == null) return;
            gridbtn.emit("pointerdown");
            obj.sprite.emit("pointerdown");
            this.guigrid_focus(this.guigrid_current);
            return;
          }
          if (button.pad._LCRight && button.pad._LCRight.pressed) {
            newposx++;
            var gridbtn = obj.combatobj.battlegridbtn[newposx + "-" + newposy];
            if (gridbtn == undefined || gridbtn == null) return;
            gridbtn.emit("pointerdown");
            obj.sprite.emit("pointerdown");
            this.guigrid_focus(this.guigrid_current);
            return;
          }
          if (button.pad._LCTop && button.pad._LCTop.pressed) {
            newposy--;
            var gridbtn = obj.combatobj.battlegridbtn[newposx + "-" + newposy];
            if (gridbtn == undefined || gridbtn == null) return;
            gridbtn.emit("pointerdown");
            obj.sprite.emit("pointerdown");
            this.guigrid_focus(this.guigrid_current);
            return;
          }
          if (button.pad._LCBottom && button.pad._LCBottom.pressed) {
            newposy++;
            var gridbtn = obj.combatobj.battlegridbtn[newposx + "-" + newposy];
            if (gridbtn == undefined || gridbtn == null) return;
            gridbtn.emit("pointerdown");
            obj.sprite.emit("pointerdown");
            this.guigrid_focus(this.guigrid_current);
            return;
          }
          if (button.pad._RCRight && button.pad._RCRight.pressed) {
            var gridbtn = obj.combatobj.battlegridbtn[newposx + "-" + newposy];
            if (gridbtn == undefined || gridbtn == null) return;
            gridbtn.emit("pointerdown");
            this.wh.kbfocuslv1 = "";
            return;
          }
          if (button.pad._RCBottom && button.pad._RCBottom.pressed) {
            var gridbtn = obj.combatobj.battlegridbtn[newposx + "-" + newposy];
            if (gridbtn == undefined || gridbtn == null) return;
            gridbtn.emit("pointerdown");
            this.wh.kbfocuslv1 = "";
            return;
          }
        }
      }
    }
    ////////////////////////////////
    if (this.wh.kbfocus == "guiwindow") {
      if (button.pad._RCRight && button.pad._RCRight.pressed) {
        this.do_escape();
      }
      if (button.pad._RCTop && button.pad._RCTop.pressed) {
        this.do_altnext();
      }
      return;
    }
    if (this.wh.kbfocus == "guigrid") {
      if (button.pad._LCLeft && button.pad._LCLeft.pressed) {
        this.do_l(); return;
      }
      if (button.pad._LCRight && button.pad._LCRight.pressed) {
        this.do_r(); return;
      }
      if (button.pad._LCTop && button.pad._LCTop.pressed) {
        this.do_u(); return;
      }
      if (button.pad._LCBottom && button.pad._LCBottom.pressed) {
        this.do_d(); return;
      }
      if (button.pad._RCRight && button.pad._RCRight.pressed) {
        this.do_escape();
        return;
      }
      if (button.pad._RCTop && button.pad._RCTop.pressed) {
        this.do_altnext();
        return;
      }

      if (button.pad._RCBottom && button.pad._RCBottom.pressed) {
        this.guigrid_okenter();;
        return;
      }
      return;
    }
    if (this.wh.kbfocus == "gamemenu") {

      if (this.gamemenu_sl_vh == "") {
        if (this.width > this.height) {
          this.gamemenu_sl_vh = "v";
        } else {
          this.gamemenu_sl_vh = "h";
        }
      }
      if (button != undefined && button.pad != undefined) {
        if (button.pad._LCLeft && button.pad._LCLeft.pressed) {
          if (this.gamemenu_sl_vh == "h") this.gamemenu_sl_vi = 0;
          this.gamemenu_sl_vh = "v";
          this.gamemenu_sl_vi--;
          this.do_gamemenu_selector();
        }
      }
      if (button.pad._LCRight && button.pad._LCRight.pressed) {
        if (this.gamemenu_sl_vh == "h") this.gamemenu_sl_vi = 0;
        this.gamemenu_sl_vh = "v";
        this.gamemenu_sl_vi++;
        this.do_gamemenu_selector();
      }
      if (button != undefined && button.pad != undefined) {
        if (button.pad._LCTop && button.pad._LCTop.pressed) {
          if (this.gamemenu_sl_vh == "v") this.gamemenu_sl_hi = 0;
          this.gamemenu_sl_vh = "h";
          this.gamemenu_sl_hi++;
          this.do_gamemenu_selector();
        }
      }
      if (button.pad._LCBottom && button.pad._LCBottom.pressed) {
        if (this.gamemenu_sl_vh == "v") this.gamemenu_sl_hi = 0;
        this.gamemenu_sl_vh = "h";
        this.gamemenu_sl_hi--;
        this.do_gamemenu_selector();
      }
      if (button.pad._RCBottom && button.pad._RCBottom.pressed) {
        this.do_gamemenu_okenter();
      }

      if ((button.pad._FBLeftBottom && button.pad._FBLeftBottom.pressed)) {
        //it's _FBLeftBottom on XBox controller
        this.do_escape();
      }
      if (button.pad._RCRight && button.pad._RCRight.pressed) {
        //it's _FBLeftBottom on XBox controller
        this.do_escape();
      }
      return;
    }
    if (this.wh.kbfocus == "homeinvensi") {
      if (button.pad._RCRight && button.pad._RCRight.pressed) {
        this.do_escape();
        this.guigrid_focushide();
      }
      if (button.pad._RCTop && button.pad._RCTop.pressed) {
        this.do_altnext();
      }
      return;
    }
    if (this.wh.kbfocus == "npc") {

      if (button.pad._LCLeft && button.pad._LCLeft.pressed) {
        this.do_l(); return;
      }
      if (button.pad._LCRight && button.pad._LCRight.pressed) {
        this.do_r(); return;
      }
      if (button.pad._LCTop && button.pad._LCTop.pressed) {
        this.do_u(); return;
      }
      if (button.pad._LCBottom && button.pad._LCBottom.pressed) {
        this.do_d(); return;
      }
      if (button.pad._RCRight && button.pad._RCRight.pressed) {
        this.do_escape(); return;
      }
      if (button.pad._RCBottom && button.pad._RCBottom.pressed) {
        this.do_okenter(); return;
      }
      return;
    }
    if (this.wh.kbfocus == "") {

      if (button.pad._RCBottom && button.pad._RCBottom.pressed) {
        this.do_okenter();
        return;
      }
      if ((button.pad._FBRightTop && button.pad._FBRightTop.pressed)) {
        // if (this.wh.minimap.visible == false) {
        this.wh.registry.list.gameitf.guiwindowhideall(this.wh);
        this.wh.minimap.visible = true;
        this.wh.gamemenu_showing = false;
        this.wh.gamemenu_main.emit("pointerdown");
        await this.wh.registry.list.resizeman.RCMapResizeManager_work(this.wh);
        await this.wh.registry.list.gameitf.updatereddots(this.wh);
        //}
        var griddat = [];
        var griddatf = [];
        griddat.push(this.wh.healicon);
        griddat.push(this.wh.msgsicon);
        griddat.push(this.wh.partyicon);
        griddat.push(this.wh.backhomeicon);
        griddat.push(this.wh.friendlisticon);
        griddat.push(this.wh.wisdomcaticon);
        this.wh.registry.list.gp.guigrid(griddat, griddatf);
        this.wh.registry.list.gp.guigrid_noclosebtn = true;
        return;
      }
      /*if (button.pad._RCRight && button.pad._RCRight.pressed) {
        this.wh.kbfocus = ""; return;
      }*/
      return;
    }
  }
  gphandler_up(x1, index, value, button) {
    // this.plog.log("GPS gphandler_up", x1, "index=", index, "value=", value, "button=", button);

    if (this.wh.focusguil == undefined) {
      return;
    }

    if (button != undefined && button.pad != undefined) {
      if (button.pad._LCLeft && button.pad._LCLeft.pressed != undefined) {
        this.isLeft = false;
      }
      if (button.pad._LCRight && button.pad._LCRight.pressed != undefined) {
        this.isRight = false;
      }
      if (button.pad._LCTop && button.pad._LCTop.pressed != undefined) {
        this.isUp = false;
      }
      if (button.pad._LCBottom && button.pad._LCBottom.pressed != undefined) {
        this.isDown = false;
      }
    }

    this.arrowDown = false;
    this.arrowDown = this.isUp || this.isDown || this.isLeft || this.isRight;
  }
  initkb(wh) {
    this.wh = wh;

    this.wh.input.keyboard.on('keydown-TAB', function (event) {
      //this.plog.log('Hello from the TAB Key!', arguments, this);
      if (event.ctrlKey) return; if (event.altKey) return;
      if (arguments[0].srcElement != undefined &&
        arguments[0].srcElement.id != 'chatinputbox') {
        if (arguments[0].srcElement.nodeName == 'TEXTAREA' ||
          arguments[0].srcElement.nodeName == 'INPUT')
          return;
        //this.scene.registry.list.gameitf.guiwindownext(this.scene);
        this.do_altnext();
        event.preventDefault();
      }
    }.bind(this));
    this.wh.input.keyboard.on('keydown-H', function (event) {
      //this.plog.log('Hello from the H Key!', arguments, this);
      //this.plog.log(arguments[0].srcElement.nodeName);
      if (event.ctrlKey) return; if (event.altKey) return;
      if (arguments[0].srcElement != undefined &&
        arguments[0].srcElement.id != 'chatinputbox') {
        //this.plog.log("inputchk",arguments[0].srcElement.id,this.wh.chatinputbox)
        if (arguments[0].srcElement.nodeName == 'TEXTAREA' ||
          arguments[0].srcElement.nodeName == 'INPUT')
          return;
        wh.registry.list.execmod.exec('healicon', wh);
      }
    });
    this.wh.input.keyboard.on('keydown-A', function (event) {
      //this.plog.log('Hello from the A Key!', arguments, this);
      //this.plog.log(arguments[0].srcElement.nodeName);
      if (event.ctrlKey) return; if (event.altKey) return;
      if (arguments[0].srcElement != undefined &&
        arguments[0].srcElement.id != 'chatinputbox') {
        //this.plog.log("inputchk",arguments[0].srcElement.id,this.wh.chatinputbox)
        if (arguments[0].srcElement.nodeName == 'TEXTAREA' ||
          arguments[0].srcElement.nodeName == 'INPUT')
          return;
        var gamemenu = wh.registry.list.rcvarpass.gamemenu;
        if (wh.registry.list.rcreq.chk(gamemenu["attribute"].requirements))
          this.scene.registry.list.execmod.exec("gamemenu:attribute", this.scene);
      }
    });
    this.wh.input.keyboard.on('keydown-F', function (event) {
      //this.plog.log('Hello from the A Key!', arguments, this);
      //this.plog.log(arguments[0].srcElement.nodeName);
      if (event.ctrlKey) return; if (event.altKey) return;
      if (arguments[0].srcElement != undefined &&
        arguments[0].srcElement.id != 'chatinputbox') {
        //this.plog.log("inputchk",arguments[0].srcElement.id,this.wh.chatinputbox)
        if (arguments[0].srcElement.nodeName == 'TEXTAREA' ||
          arguments[0].srcElement.nodeName == 'INPUT')
          return;
        this.scene.registry.list.friendlist.show(this.scene);
      }
    });
    this.wh.input.keyboard.on('keydown-I', function (event) {
      //this.plog.log('Hello from the I Key!', arguments, this);
      //this.plog.log(arguments[0].srcElement.nodeName);
      if (event.ctrlKey) return; if (event.altKey) return;
      if (arguments[0].srcElement != undefined &&
        arguments[0].srcElement.id != 'chatinputbox') {
        //this.plog.log("inputchk",arguments[0].srcElement.id,this.wh.chatinputbox)
        if (arguments[0].srcElement.nodeName == 'TEXTAREA' ||
          arguments[0].srcElement.nodeName == 'INPUT')
          return;
        var gamemenu = wh.registry.list.rcvarpass.gamemenu;
        if (wh.registry.list.rcreq.chk(gamemenu["inventory"].requirements))
          this.scene.registry.list.execmod.exec("gamemenu:inventory", this.scene);
      }
    });
    this.wh.input.keyboard.on('keydown-S', function (event) {
      //this.plog.log('Hello from the S Key!', arguments, this);
      //this.plog.log(arguments[0].srcElement.nodeName);
      if (event.ctrlKey) return; if (event.altKey) return;
      if (arguments[0].srcElement != undefined &&
        arguments[0].srcElement.id != 'chatinputbox') {
        //this.plog.log("inputchk",arguments[0].srcElement.id,this.wh.chatinputbox)
        if (arguments[0].srcElement.nodeName == 'TEXTAREA' ||
          arguments[0].srcElement.nodeName == 'INPUT')
          return;
        var gamemenu = wh.registry.list.rcvarpass.gamemenu;
        if (wh.registry.list.rcreq.chk(gamemenu["stackitem"].requirements))
          this.scene.registry.list.execmod.exec("gamemenu:stackitem", this.scene);
      }
    });
    this.wh.input.keyboard.on('keydown-Q', function (event) {
      //this.plog.log('Hello from the Q Key!', arguments, this);
      //this.plog.log(arguments[0].srcElement.nodeName);
      if (event.ctrlKey) return; if (event.altKey) return;
      if (arguments[0].srcElement != undefined &&
        arguments[0].srcElement.id != 'chatinputbox') {
        //this.plog.log("inputchk",arguments[0].srcElement.id,this.wh.chatinputbox)
        if (arguments[0].srcElement.nodeName == 'TEXTAREA' ||
          arguments[0].srcElement.nodeName == 'INPUT')
          return;
        var gamemenu = wh.registry.list.rcvarpass.gamemenu;
        if (wh.registry.list.rcreq.chk(gamemenu["quest"].requirements))
          this.scene.registry.list.execmod.exec("gamemenu:quest", this.scene);
      }
    });
    this.wh.input.keyboard.on('keydown-E', function (event) {
      //this.plog.log('Hello from the E Key!', arguments, this);
      //this.plog.log(arguments[0].srcElement.nodeName);
      if (event.ctrlKey) return; if (event.altKey) return;
      if (arguments[0].srcElement != undefined &&
        arguments[0].srcElement.id != 'chatinputbox') {
        //this.plog.log("inputchk",arguments[0].srcElement.id,this.wh.chatinputbox)
        if (arguments[0].srcElement.nodeName == 'TEXTAREA' ||
          arguments[0].srcElement.nodeName == 'INPUT')
          return;
        var gamemenu = wh.registry.list.rcvarpass.gamemenu;
        if (wh.registry.list.rcreq.chk(gamemenu["equipment"].requirements))
          this.scene.registry.list.execmod.exec("gamemenu:equipment", this.scene);
      }
    });
    this.wh.input.keyboard.on('keydown-K', function (event) {
      //this.plog.log('Hello from the K Key!', arguments, this);
      //this.plog.log(arguments[0].srcElement.nodeName);
      if (event.ctrlKey) return; if (event.altKey) return;
      if (arguments[0].srcElement != undefined &&
        arguments[0].srcElement.id != 'chatinputbox') {
        //this.plog.log("inputchk",arguments[0].srcElement.id,this.wh.chatinputbox)
        if (arguments[0].srcElement.nodeName == 'TEXTAREA' ||
          arguments[0].srcElement.nodeName == 'INPUT')
          return;
        var gamemenu = wh.registry.list.rcvarpass.gamemenu;
        if (wh.registry.list.rcreq.chk(gamemenu["skill"].requirements))
          this.scene.registry.list.execmod.exec("gamemenu:skill", this.scene);
      }
    });
    this.wh.input.keyboard.on('keydown-P', function (event) {
      //this.plog.log('Hello from the P Key!', arguments, this);
      //this.plog.log(arguments[0].srcElement.nodeName);
      if (event.ctrlKey) return; if (event.altKey) return;
      if (arguments[0].srcElement != undefined &&
        arguments[0].srcElement.id != 'chatinputbox') {
        //this.plog.log("inputchk",arguments[0].srcElement.id,this.wh.chatinputbox)
        if (arguments[0].srcElement.nodeName == 'TEXTAREA' ||
          arguments[0].srcElement.nodeName == 'INPUT')
          return;
        var gamemenu = wh.registry.list.rcvarpass.gamemenu;
        if (wh.registry.list.rcreq.chk(gamemenu["pet"].requirements))
          this.scene.registry.list.execmod.exec("gamemenu:pet", this.scene);
      }
    });


    this.wh.input.keyboard.on('keydown-CTRL', function (event) {
      //this.plog.log('Hello from the CTRL Key!', arguments, this);
      //this.plog.log(arguments[0].srcElement.nodeName);
      //if (event.ctrlKey) return;
      if (event.altKey) return;
      if (arguments[0].srcElement != undefined &&
        arguments[0].srcElement.id != 'chatinputbox') {
        //this.plog.log("inputchk",arguments[0].srcElement.id,this.wh.chatinputbox)
        if (arguments[0].srcElement.nodeName == 'TEXTAREA' ||
          arguments[0].srcElement.nodeName == 'INPUT')
          return;

      }
      if (this.scene.registry.list.rcvarpass.activemap["map"]["activemap"] == "YES") {
        this.scene.charatk(this.scene);
        return;
      }
    });
    this.wh.input.keyboard.on('keydown-SHIFT', function (event) {
      //this.plog.log('Hello from the SHIFT Key!', arguments, this);
      //this.plog.log(arguments[0].srcElement.nodeName);
      //if (event.ctrlKey) return;
      if (event.altKey) return;
      if (arguments[0].srcElement != undefined &&
        arguments[0].srcElement.id != 'chatinputbox') {
        //this.plog.log("inputchk",arguments[0].srcElement.id,this.wh.chatinputbox)
        if (arguments[0].srcElement.nodeName == 'TEXTAREA' ||
          arguments[0].srcElement.nodeName == 'INPUT')
          return;

      }
      if (this.scene.registry.list.rcvarpass.activemap["map"]["activemap"] == "YES") {
        this.scene.charguard(this.scene);
        return;
      }
    });
    this.wh.input.keyboard.on('keyup-SHIFT', function (event) {
      //this.plog.log('Hello from the SHIFT Key!', arguments, this);
      //this.plog.log(arguments[0].srcElement.nodeName);
      //if (event.ctrlKey) return;
      if (event.altKey) return;
      if (arguments[0].srcElement != undefined &&
        arguments[0].srcElement.id != 'chatinputbox') {
        //this.plog.log("inputchk",arguments[0].srcElement.id,this.wh.chatinputbox)
        if (arguments[0].srcElement.nodeName == 'TEXTAREA' ||
          arguments[0].srcElement.nodeName == 'INPUT')
          return;

      }
      if (this.scene.registry.list.rcvarpass.activemap["map"]["activemap"] == "YES") {
        this.scene.charguard_e(this.scene);
        return;
      }
    });
    this.wh.input.keyboard.on('keydown-ENTER',
      async function (event) {
        //this.plog.log(            'Hello from the ENTER Key!',            this.wh.chatinputbox.visible,            this.wh.chatinputbox,            arguments          );
        //this.plog.log(arguments[0].srcElement.nodeName);
        if (event.ctrlKey) return; if (event.altKey) return;
        var firstchar = '';
        if (arguments[0] != undefined &&
          arguments[0].srcElement != undefined &&
          arguments[0].srcElement.id == "dminput") {
          if (this.wh.registry.list.dm != undefined &&
            this.wh.registry.list.dm.dmid != undefined &&
            this.wh.registry.list.dm.inputtext != undefined &&
            this.wh.registry.list.dm.inputtext.text != undefined
          ) {
            this.wh.registry.list.wsrc.senddm(this.wh.registry.list.dm.dmid, this.wh.registry.list.dm.inputtext.text);
            this.wh.registry.list.dm.inputtext.text = "";
            return;
          }
        }
        if (
          arguments[0].srcElement != undefined &&
          arguments[0].srcElement.id != 'chatinputbox'
        ) {
          //this.plog.log("inputchk",arguments[0].srcElement.id,this.wh.chatinputbox)
          if (
            arguments[0].srcElement.nodeName == 'TEXTAREA' ||
            arguments[0].srcElement.nodeName == 'INPUT'
          )
            return;
        }
        if (this.wh.chatinputbox.visible == false) {
          this.wh.chatinputbox.visible = true;
          this.wh.chatinputbox.setBlur();
          this.wh.chatinputbox.setFocus();
          // this.wh.chatinputbox.focus();
          setTimeout(() => {
            this.wh.chatinputbox.setFocus();
          }, 150);
        } else {
          var txt = this.wh.chatinputbox.text;
          if (txt == '') {
            this.wh.chatinputbox.visible = false;
          } else {
            this.inputboxhist3 = this.inputboxhist2;
            this.inputboxhist2 = this.inputboxhist1;
            this.inputboxhist1 = txt;
            if (txt == ".") {
              this.wh.registry.list.gameitf.buildemotekb();
              this.wh.chatinputbox.setText('');
              return;
            }
            firstchar = txt.substring(0, 1);
            var cmda = txt.split(" ");
            this.plog.log('firstchar', firstchar, txt);
            if (firstchar == '.') {
              if (txt == '.pos') {
                this.wh.chatinputbox.setText('');
                this.wh.registry.list.phm.charsaybubble(
                  this.wh.char_main,
                  txt +
                  ' ' +
                  this.wh.char_main.currentx +
                  '-' +
                  this.wh.char_main.currenty
                );
                this.wh.chatinputbox.setText('');
                txt = "";
              }
              if (cmda[0] == ".s") {
                this.wh.registry.list.showreward.effwithtxt(this.wh, cmda[1], "Play Sprite");
                txt = "";
                this.wh.registry.list.wsrc.sendeffect("Sprite!", ""+cmda[1]);

              }
              if (cmda[0] == ".d") {
                this.wh.playdrama(cmda[1]);
                txt = "";
              }
              if (cmda[0] == ".drama") {
                txt = "";
                this.wh.registry.list.drama.play(this.wh, cmda[1]);
                return;
              }
              if (cmda[0] == ".lang") {
                this.wh.chatinputbox.setText(''); txt = "";
                await this.wh.registry.list.rcsvvars.updatelangpack(cmda[1]);
                return;
              }


              if (cmda[0] == ".nofog") {
                this.wh.chatinputbox.setText(''); txt = "";
                this.wh.fow.clear();
                return;
              }
              if (cmda[0] == ".dramasnapshot") {
                this.wh.chatinputbox.setText('');
                this.wh.registry.list.drama.snapshotflag = "yes";

                this.wh.registry.list.phm.charsaybubble(
                  this.wh.char_main,
                  "Okay, now playing drama will also save snapshot"
                );
                txt = "";
                return;
              }
              if (cmda[0] == ".timespeed") {
                this.wh.chatinputbox.setText('');
                this.wh.registry.list.drama.snapshotflag = "yes";

                this.wh.registry.list.timectl.secperround = Math.floor(cmda[1]);
                txt = "";
                return;
              }
              if (txt == ".initvar" || txt == ".initvars") {
                this.wh.chatinputbox.setText(''); txt = "";
                this.wh.registry.list.rcsvvars.initallvars(this.wh, true);
              }
              if (txt == '.initmap') {
                this.wh.chatinputbox.setText(''); txt = "";
                this.wh.registry.list.phm.initmap();
              }

              if (txt == '.lvup') {
                this.wh.chatinputbox.setText(''); txt = "";
                this.wh.registry.list.showreward.effwithtxt(this.wh, 'ixbeff310_b', "Level Up");
                this.wh.registry.list.wsrc.sendeffect("levelup", "ixbeff310_b");

              }
              if (txt == '.black') {
                this.wh.chatinputbox.setText(''); txt = "";
                //this.wh.maplayers["bg"].setTexture("spacer");
                for (const k in this.wh.maplayers) this.wh.maplayers[k].setTexture("spacer");
                //this.wh.registry.list.showreward.effwithtxt(this.wh, 'ixbeff310_b', "Level Up");
              }
              if (txt == '.daytime') {
                this.wh.chatinputbox.setText(''); txt = "";
                this.wh.registry.list.timectl.timefunc(this.wh, "morning");
                this.wh.registry.list.timectl.timechange('morning');
                this.wh.registry.list.timectl.timename = "morning";
              }
              if (txt == '.nighttime') {
                this.wh.chatinputbox.setText(''); txt = "";
                this.wh.registry.list.timectl.prevtimename = "night";
                this.wh.registry.list.timectl.timename = "night";
                this.wh.registry.list.timectl.game_hr = 13;
                this.wh.registry.list.timectl.pausetime = true;
                this.wh.registry.list.timectl.secperround = 0;
                this.wh.registry.list.timectl.timefunc(this.wh, "night");
                this.wh.registry.list.timectl.timechange('night');
              }
              if (txt == '.wreset') {
                this.wh.chatinputbox.setText(''); txt = "";
                this.wh.registry.list.weather.reset(this.wh);
              }
              if (txt == '.rain') {
                this.wh.chatinputbox.setText(''); txt = "";
                this.wh.registry.list.weather.rainStart(this.wh);
              }
              if (txt == '.spawnbird') {
                this.wh.chatinputbox.setText(''); txt = "";
                this.wh.registry.list.phm.spawnbird();
              }
              if (txt == '.ray1') {
                this.wh.chatinputbox.setText(''); txt = "";
                this.wh.registry.list.weather.startRay1(this.wh);
              }
              if (txt == '.heavyrain') {
                this.wh.chatinputbox.setText(''); txt = "";
                this.wh.registry.list.weather.heavyRainStart(this.wh);
              }
              if (txt == '.snow') {
                this.wh.chatinputbox.setText(''); txt = "";
                this.wh.registry.list.weather.snowStart(this.wh);
              }
              if (txt == '.fog') {
                this.wh.chatinputbox.setText(''); txt = "";
                this.wh.registry.list.weather.fogStart(this.wh);
              }
              if (txt == ".walker") {
                //this.wh.chatinputbox.setText(''); 
                txt = "";
                if (this.wh.walkermode == true) {
                  this.wh.walkermode = false;
                } else {
                  this.wh.walkermode = true;
                }
                return;
              }
              if (txt == ".fps") {
                this.wh.chatinputbox.setText(''); txt = "";
                if (this.wh.showfpsmode == true) {
                  this.wh.showfpsmode = false;
                } else {
                  this.wh.showfpsmode = true;
                }
                // return;
              }

              if (txt == ".var") {
                this.wh.chatinputbox.setText(''); txt = "";
                console.log(this.wh);
              }
              if (txt == ".onliner60") {
                this.wh.chatinputbox.setText(''); txt = "";
                var onlineres = await this.wh.registry.list.rchttp.getcommon2('onliner', {
                  time: 60
                });
                this.wh.registry.list.rctoast.rcalert(this.wh, "onliner", onlineres);// = "ok";

              }
              if (txt == ".onliner") {
                this.wh.chatinputbox.setText(''); txt = "";
                var onlineres = await this.wh.registry.list.rchttp.getcommon2('onliner', {
                  time: 10
                });
                this.wh.registry.list.rctoast.rcalert(this.wh, "onliner", onlineres);// = "ok";
              }
              if (txt == ".spawngold") {
                this.wh.chatinputbox.setText(''); txt = "";
                var debugres = await this.wh.registry.list.rchttp.getcommon2('debug', {
                  rq: 'spawngold'
                });
                this.wh.registry.list.rctoast.rcalert(this.wh, "debugres", debugres);// = "ok";
              }

              if (cmda[0] == ".debug") {
                this.wh.chatinputbox.setText(''); txt = "";
                var debugres = await this.wh.registry.list.rchttp.getcommon2('debug', {
                  rq: '' + cmda[1]
                });
                this.wh.registry.list.rctoast.rcalert(this.wh, "debugres", debugres);// = "ok";

                return;
              }
              if (txt == '.npcbox') {
                this.wh.chatinputbox.setText(''); txt = "";
                this.plog.log(this.wh.mapcontainer);
                for (const k in this.wh.mapcontainer.list) {
                  var v = this.wh.mapcontainer.list[k];
                  this.plog.log(v.name);
                  if (v.name == undefined || (v.name + "").length < 5) continue;
                  var namea = v.name.split('-');

                  if (namea[0] == 'npc') {
                    //if (v.frame.name == 'spacer.png') {
                    /*var prew = v.displayWidth;
                    var preh = v.displayHeight;
                    v.setTexture('xxx').setAlpha(1);
                    v.displayWidth = prew;
                    v.displayHeight = preh;
                    //v.setAlpha(1);
                    */
                    if (v.texture.key == "spacer") {
                      v.setTexture('white')
                    } else {
                      v.setTint(0xff0000);
                      this.plog.error(v.name, v);
                    }
                    //}
                  }
                }
              }
            } //else {
            this.wh.registry.list.wsrc.sendchat(txt);
            this.wh.chatinputbox.setText('');
            //}
          }
        }
      },
      this
    );

    this.wh.input.keyboard.on('keydown-SPACE',
      function (event) {
        //this.plog.log(            'Hello from the SPACE Key!', "this.scene.kbfocus=", this.scene.kbfocus          );
        //this.plog.log('Hello from the SPACE Key!', arguments);
        //this.plog.log(arguments[0].srcElement.nodeName);
        //this.plog.log("inputchk",arguments[0].srcElement.id,this.wh.chatinputbox)
        if (event.ctrlKey) return; if (event.altKey) return;
        if (
          arguments[0].srcElement.nodeName == 'TEXTAREA' ||
          arguments[0].srcElement.nodeName == 'INPUT'
        ) {
          arguments[0].srcElement.value = arguments[0].srcElement.value + ' ';
          return true;
          this.scene.chatinputbox.setText(this.scene.chatinputbox.text + ' ');
          return false;
        }
        this.scene.registry.list.gp.do_okenter();

      });


    this.wh.input.keyboard.on(
      'keydown-ESC',
      function (event) {
        this.wh.plog.log('Hello from the ESC Key!');
        //this.plog.log(arguments[0].srcElement.nodeName);
        //this.plog.log("inputchk",arguments[0].srcElement.id,this.wh.chatinputbox)
        if (event.ctrlKey) return; if (event.altKey) return;
        if (
          arguments[0].srcElement.nodeName == 'TEXTAREA' ||
          arguments[0].srcElement.nodeName == 'INPUT'
        ) return;

        this.wh.plog.log("rcconfirm cancel");//, this.scene.registry.list.rctoast);
        this.wh.registry.list.gp.do_escape();
      }.bind(this));//.bind(this));

    this.wh.input.keyboard.on(
      'keydown-PERIOD',
      function (event) {
        //this.plog.log('Hello from the PERIOD Key!');
        //this.plog.log(arguments[0].srcElement.nodeName);
        //this.plog.log("inputchk",arguments[0].srcElement.id,this.wh.chatinputbox)
        if (event.ctrlKey) return; if (event.altKey) return;
        if (
          arguments[0].srcElement.nodeName == 'TEXTAREA' ||
          arguments[0].srcElement.nodeName == 'INPUT'
        )
          return;
        if (this.emotebg != undefined) {
          this.wh.registry.list.gameitf.buildemotekb_close();
        } else {
          this.wh.registry.list.gameitf.buildemotekb();
        }

      }.bind(this));

    this.wh.input.keyboard.on(
      'keydown-UP',
      function (event) {
        if (event.ctrlKey) return; if (event.altKey) return;
        //this.plog.log(            'Hello from the UP Key!', "this.scene.kbfocus=", this.scene.kbfocus          );
        //this.plog.log(arguments[0].srcElement.nodeName);
        //this.plog.log("inputchk",arguments[0].srcElement.id,this.wh.chatinputbox)
        if (
          arguments[0].srcElement.nodeName == 'TEXTAREA' ||
          arguments[0].srcElement.nodeName == 'INPUT'
        ) {
          this.wh.chatinputbox.text = this.inputboxhist1;
          return;
        }

        if (this.wh.kbfocus == "") return;
        this.wh.plog.log("do kb up");
        this.do_u();
      }.bind(this));
    this.wh.input.keyboard.on(
      'keydown-DOWN',
      function (event) {
        if (event.ctrlKey) return; if (event.altKey) return;
        //this.plog.log(            'Hello from the DOWN Key!', "this.scene.kbfocus=", this.scene.kbfocus          );
        //this.plog.log(arguments[0].srcElement.nodeName);
        //this.plog.log("inputchk",arguments[0].srcElement.id,this.wh.chatinputbox)
        if (
          arguments[0].srcElement.nodeName == 'TEXTAREA' ||
          arguments[0].srcElement.nodeName == 'INPUT'
        )
          return;
        if (this.wh.kbfocus == "") return;
        // this.plog.log("do kb DOWN");
        this.do_d();
      }.bind(this));
    ///this.initeventkeydownenter = true;

  }
  ////////////////////////////////////////////////////////////////////
  do_escape() {
    this.plog.log("GPS do_escape()", this.wh.kbfocus);
    if (this.wh.kbfocuslv1 != "") {
      this.plog.log("GPS do_escape() halted, kbfocuslv1", this.wh.kbfocuslv1);
    }
    if (this.wh.kbfocus == 'homeinvensi') {
      this.plog.log("homeinvensi cancel", this.wh.registry.list.rctoast);
      //this.wh.registry.list.rctoast.homeinvenclosebtn.click();
      this.wh.registry.list.rctoast.homeinvensipass = 'close';
      this.wh.registry.list.rctoast.homeinvensi_close();
      //this.homeinvensipass = 'close';
      //this.homeinvensi_close();
      //this.wh.registry.list.rctoast.rcconfirm_clickclose(this.wh);
      this.wh.kbfocus = "";
      return;
    }
    if (this.wh.kbfocus == 'rcconfirm') {
      this.plog.log("rcconfirm cancel", this.wh.registry.list.rctoast);
      this.wh.registry.list.rctoast.rcconfirmpass = "cancel";
      this.wh.registry.list.rctoast.rcconfirm_clickclose(this.wh);
      this.wh.kbfocus = "";
      return;
    }
    if (this.wh.kbfocus == 'gamemenu') {
      this.plog.log("gamemenu do_escape");
      this.wh.gamemenu_showing = true;
      this.do_gamemenu();
      return;
    }

    if (this.wh.kbfocus == 'guiwindow') {
      this.plog.log("guiwindow do_escape", this.wh.activewindow, this.wh);
      //this.do_gamemenu();
      if (this.wh.activewindow != undefined) {
        this.wh.registry.list.gameitf.closegui(this.wh, this.wh.activewindow)
        //wh.registry.list.gameitf.closegui(wh, wh.iapstorecontainer);
      }
      //this.wh.kbfocus = "";
      return;
    }
    if (this.wh.kbfocus == 'guigrid') {
      this.plog.log("guigrid do_escape", this.guigrid_origgrid);
      //this.do_gamemenu();
      if (this.guigrid_noclosebtn == true) {
        this.guigrid_focushide();
        this.wh.kbfocus = "";
        return false;
      }
      for (const k in this.guigrid_origgrid) {
        var v = this.guigrid_origgrid[k];
        if (v == undefined || v == null) continue;
        if (v.type == "Sprite") {
          //this.plog.log(v, v.type, v.texture.firstFrame, v.frame.name);
          if (v.texture.firstFrame == "thesq.png" && v.frame.name == "close") {
            this.plog.log(" found, emitting ", v);
            //this.wh.kbfocus = "";
            v.emit('pointerdown');
            this.wh.kbfocus = "";
            return;
          }
        }
      }
      return;
    }

    if (this.wh.kbfocus == 'npc') {
      this.plog.log("npc do_escape");
      try {
        this.wh.registry.list.npcconver.closedialog(this.wh);
      } catch (e) {
        this.plog.warn(e);
      }
      return;
    }
  }
  do_gamemenu() {
    this.plog.log("GPS.do_gamemenu()")

    if (this.wh.gamemenu_showing == true) {
      this.wh.gamemenu_showing = false;
      this.wh.kbfocus = "";
      this.do_focushide();
    } else {
      this.wh.gamemenu_showing = true;
      this.wh.kbfocus = "gamemenu";
    }
    for (let key of Object.keys(this.wh.gamemenu)) {
      let val = this.wh.gamemenu[key];
      if (this.wh.gamemenu_showing == true) {
        val.visible = true;
      } else {
        val.visible = false;
      }
    }

    for (let key of Object.keys(this.wh.gamemenudis)) {
      let val = this.wh.gamemenudis[key];
      if (this.wh.gamemenu_showing == true) {
        val.visible = true;
      } else {
        val.visible = false;
      }
    }
    if (this.wh.gamemenu_showing == false) {
      if (this.wh.partyicon != undefined) this.wh.partyicon.visible = false;
      if (this.wh.backhomeicon != undefined) this.wh.backhomeicon.visible = false;
      if (this.wh.msgsicon != undefined) this.wh.msgsicon.visible = false;
      if (this.wh.msgsicondis != undefined) this.wh.msgsicondis.visible = false;
      if (this.wh.friendlisticon != undefined) this.wh.friendlisticon.visible = false;
      if (this.wh.wisdomcaticon != undefined) this.wh.wisdomcaticon.visible = false;
    } else {
      if (this.wh.partyicon != undefined) this.wh.partyicon.visible = true;
      //if (wh.backhomeicon != undefined) wh.backhomeicon.visible = true;
      if (this.wh.registry.list.rcvarpass != undefined &&
        this.wh.registry.list.rcvarpass.activemap != undefined &&
        this.wh.registry.list.rcvarpass.activemap["map"] != undefined) {
        if (this.wh.registry.list.rcvarpass.activemap["map"]["code"] != "map1" &&
          this.wh.registry.list.rcvarpass.activemap["map"]["code"] != "home" &&
          this.wh.registry.list.rcreq.chk({ level_max: 10 })) {
          this.wh.backhomeicon.visible = true;
        } else {
          this.wh.backhomeicon.visible = false;
        }
      } else {
        this.wh.backhomeicon.visible = false;
      }
      if (this.wh.msgsicon != undefined) this.wh.msgsicon.visible = true;
      if (this.wh.msgsicondis != undefined) this.wh.msgsicondis.visible = true;
      if (this.wh.registry.list.rcvarpass.activechar['newmsg'] == 1) {
        this.wh.msgsicon.visible = true;
        this.wh.msgsicondis.visible = false;
      } else {
        this.wh.msgsicon.visible = false;
        this.wh.msgsicondis.visible = true;
      }
      if (this.wh.friendlisticon != undefined) this.wh.friendlisticon.visible = true;
      if (this.wh.wisdomcaticon != undefined) this.wh.wisdomcaticon.visible = true;
    }


    this.wh.registry.list.resizeman.RCMapResizeManager_work(this.wh);
    this.wh.registry.list.gameitf.updatereddots(this.wh);
    this.wh.registry.list.snd.snd("btn3");

    if (this.wh.gamemenu_showing == true) {
      this.do_gamemenu_selector();
    }
  }
  do_gamemenu_selector() {
    this.plog.log("GPS.do_gamemenu_selector()");
    if (this.wh.focusguil == undefined) {
      return;
    }

    var vi = 0;
    var hi = 0;
    for (let key of Object.keys(this.wh.registry.list.rcvarpass.gamemenu)) {
      let val = this.wh.registry.list.rcvarpass.gamemenu[key];
      //this.plog.log("xxx",val)
      var tmpnamea = val.name.split('-');

      if (this.width > this.height) {
        if (val.pos == 1) {
          vi++;
        }
        if (val.pos == 2) {
          hi++;
        }
      } else {
        if (val.pos == 2) {
          vi++;
        }
        if (val.pos == 1) {
          hi++;
        }
      }
    }
    if (this.gamemenu_sl_vh == "") {
      if (this.width > this.height) {
        this.gamemenu_sl_vh = "v";
      } else {
        this.gamemenu_sl_vh = "h";
      }
    }
    if (this.gamemenu_sl_vi < 1) this.gamemenu_sl_vi = 1;
    if (this.gamemenu_sl_vi > vi) this.gamemenu_sl_vi = vi;
    if (this.gamemenu_sl_hi < 1) this.gamemenu_sl_hi = 1;
    if (this.gamemenu_sl_hi > hi) this.gamemenu_sl_hi = hi;
    this.plog.log("GPS.do_gamemenu_selector()", this.gamemenu_sl_vh, this.gamemenu_sl_vi, this.gamemenu_sl_hi);
    this.plog.log("GPS.do_gamemenu_selector() kbfocus", this.wh.kbfocus);

    if (this.gamemenu_sl_vh == "v") {
      var tmpico = this.gamemenu_findicon("v", this.gamemenu_sl_vi);
      this.plog.log("GPS.focusico", tmpico, tmpicoobj);
      if (tmpico == undefined) {
        this.plog.log("ERROR cant find Vv", this.gamemenu_sl_vi);
      } else {
        var tmpicoobj = this.wh.registry.list.gameitf.getgamemenuicon(this.wh, tmpico.code)
        this.do_focus(tmpicoobj);
      }
    }
    if (this.gamemenu_sl_vh == "h") {
      var tmpico = this.gamemenu_findicon("h", this.gamemenu_sl_hi);

      if (tmpico == undefined) {
        this.plog.log("ERROR cant find Hh", this.gamemenu_sl_hi);
      } else {
        this.plog.log("GPS.focusico", tmpico, tmpicoobj);
        var tmpicoobj = this.wh.registry.list.gameitf.getgamemenuicon(this.wh, tmpico.code)
        this.do_focus(tmpicoobj);
      }
    }
  }
  gamemenu_findicon(vh, indexneed) {
    //this.plog.log("xxx",this.wh.gamemenu,this.wh.registry.list.rcvarpass.gamemenu)
    var vi = 0;
    var hi = 0;
    for (let key of Object.keys(this.wh.registry.list.rcvarpass.gamemenu)) {
      let val = this.wh.registry.list.rcvarpass.gamemenu[key];

      //var tmpnamea = val.name.split('-');

      if (this.width > this.height) {
        this.plog.log("xxx v", val, vi, hi, indexneed)
        if (val.pos == 1) {
          vi++;
          if (vh == "v" && vi == indexneed) return val;
        }
        if (val.pos == 2) {
          hi++;
          if (vh == "h" && hi == indexneed) return val;
        }
      } else {
        if (val.pos == 2) {
          vi++;
          if (vh == "v" && vi == indexneed) return val;
        }
        if (val.pos == 1) {
          hi++;
          if (vh == "h" && hi == indexneed) return val;
        }
      }

    }
    this.plog.log("gamemenu_findicon CANTFIND", vh, indexneed, this.wh.registry.list.rcvarpass.gamemenu)
  }
  ///////////////////////////////////////////////////////////
  do_focushide() {

    if (this.wh.focusguil == undefined) {
      return;
    }
    this.wh.focusguil.setPosition(-1000, -1000);
    this.wh.focusguir.setPosition(-1000, -1000);
  }
  do_focus(tgobj) {
    this.plog.log("GPS.do_focus()", tgobj);
    if (tgobj == undefined) {
      return;
    }
    this.wh.focusguil.play("select_l");
    //this.wh.focusguil.setOrigin(0, tgobj.originY);
    //this.plog.log("GPS.this.wh.focusguil", this.wh.focusguil);
    //this.wh.focusguil.setPosition(tgobj.x - tgobj.displayOriginX, tgobj.y - tgobj.displayOriginY);
    this.wh.focusguil.setPosition(tgobj.x - tgobj.displayOriginX + this.wh.focusguil.displayOriginX, tgobj.y);
    this.wh.focusguil.displayHeight = tgobj.displayHeight;
    this.wh.focusguil.scaleX = this.wh.focusguil.scaleY;
    this.wh.gifscontainer.bringToTop(this.wh.focusguil);


    this.wh.focusguir.play("select_r");
    //this.wh.focusguil.setOrigin(0, tgobj.originY);
    //this.plog.log("GPS.this.wh.focusguir", this.wh.focusguil);
    //this.wh.focusguir.setPosition(tgobj.x - tgobj.displayOriginX, tgobj.y - tgobj.displayOriginY);
    this.wh.focusguir.setPosition(tgobj.x + tgobj.displayOriginX - this.wh.focusguir.displayOriginX, tgobj.y);
    this.wh.focusguir.displayHeight = tgobj.displayHeight;
    this.wh.focusguir.scaleX = this.wh.focusguir.scaleY;
    this.wh.gifscontainer.bringToTop(this.wh.focusguir);

  }
  do_gamemenu_okenter() {
    var tmpico;
    if (this.gamemenu_sl_vh == "v") {
      tmpico = this.gamemenu_findicon("v", this.gamemenu_sl_vi);
    }
    if (this.gamemenu_sl_vh == "h") {
      tmpico = this.gamemenu_findicon("h", this.gamemenu_sl_hi);
    }
    this.plog.log("GPS.do_gamemenu_okenter", tmpico);
    if (tmpico != undefined && tmpico.code != undefined) {
      this.wh.registry.list.thethis.execmod.exec(
        'gamemenu:' + tmpico.code,
        this.wh
      );
      this.do_focushide();
      //close gamemenu 
      //this.wh.gamemenu_showing = true;
      //this.do_gamemenu();

    }
  }
  do_okenter() {
    this.plog.log("GPS.do_okenter");
    if (this.wh.registry.list.rcvarpass.activemap["map"]["activemap"] == "YES") {
      this.wh.charjump(this.wh);
      return;
    }
    //if (this.scene.kbfocus != "") return;
    //this.plog.log("run space down", this.wh.kbfocus, arguments, this);
    var tmpspriteface = (this.wh.char_main_lastspriteface + "").split(" ");
    var tmpspritefaceuse = tmpspriteface[0];
    this.plog.log("tmpspritefaceuse", tmpspritefaceuse);
    switch (this.wh.kbfocus) {
      case "":
        var chkpos;
        var chkpos2;
        var chkpos3;
        if (tmpspritefaceuse == "left") {
          chkpos = (this.wh.char_main.currentx - 1) + "-" + (this.wh.char_main.currenty);
          chkpos2 = (this.wh.char_main.currentx - 2) + "-" + (this.wh.char_main.currenty);
          chkpos3 = (this.wh.char_main.currentx - 3) + "-" + (this.wh.char_main.currenty);
        }
        if (tmpspritefaceuse == "right") {
          chkpos = (this.wh.char_main.currentx + 1) + "-" + (this.wh.char_main.currenty);
          chkpos2 = (this.wh.char_main.currentx + 2) + "-" + (this.wh.char_main.currenty);
          chkpos3 = (this.wh.char_main.currentx + 3) + "-" + (this.wh.char_main.currenty);
        }
        if (tmpspritefaceuse == "up") {
          chkpos = (this.wh.char_main.currentx) + "-" + (this.wh.char_main.currenty - 1);
          chkpos2 = (this.wh.char_main.currentx) + "-" + (this.wh.char_main.currenty - 2);
          chkpos3 = (this.wh.char_main.currentx) + "-" + (this.wh.char_main.currenty - 3);
        }
        if (tmpspritefaceuse == "down") {
          chkpos = (this.wh.char_main.currentx) + "-" + (this.wh.char_main.currenty + 1);
          chkpos2 = (this.wh.char_main.currentx) + "-" + (this.wh.char_main.currenty + 2);
          chkpos3 = (this.wh.char_main.currentx) + "-" + (this.wh.char_main.currenty + 3);
        }
        if (this.wh.registry.list.rcvarpass.activemap["npc"] == undefined) return;
        var npc = this.wh.registry.list.rcvarpass.activemap["npc"][chkpos];
        this.plog.log("run space down npc", chkpos, npc);
        if (npc != undefined) {
          //this.scene.registry.list.npcconver.starttalk(npc, this.scene);
          var npcsprite = this.wh.registry.list.phm.findnpc(this.wh, "npc-" + npc + "-" + chkpos + "-" + this.wh.registry.list.rcvarpass.activemap["map"]["code"]);

          this.wh.registry.list.phm.npcclick(npcsprite);
          return;
        }
        var npc = this.wh.registry.list.rcvarpass.activemap["npc"][chkpos2];
        this.plog.log("run space down npc 2", chkpos2, npc);
        if (npc != undefined) {
          //this.scene.registry.list.npcconver.starttalk(npc, this.scene);
          var npcsprite = this.wh.registry.list.phm.findnpc(this.wh, "npc-" + npc + "-" + chkpos2 + "-" + this.wh.registry.list.rcvarpass.activemap["map"]["code"]);

          this.wh.registry.list.phm.npcclick(npcsprite);
          return;
        }
        var npc = this.wh.registry.list.rcvarpass.activemap["npc"][chkpos3];
        this.plog.log("run space down npc 3", chkpos3, npc);
        if (npc != undefined) {
          //this.scene.registry.list.npcconver.starttalk(npc, this.scene);
          var npcsprite = this.wh.registry.list.phm.findnpc(this.wh, "npc-" + npc + "-" + chkpos3 + "-" + this.wh.registry.list.rcvarpass.activemap["map"]["code"]);

          this.wh.registry.list.phm.npcclick(npcsprite);
          return;
        }
        //start combat of found mob
        //this.plog.log("mapmobspace", this.scene.mapmob);
        if (this.wh.mapmob != undefined) {
          var tmpchkpos = chkpos.split("-");
          for (const k in this.wh.mapmob) {
            if (this.wh.mapmob[k] == undefined) continue;
            if ((this.wh.mapmob[k].currentx == tmpchkpos[0] &&
              this.wh.mapmob[k].currenty == tmpchkpos[1]) || (
                this.wh.mapmob[k].currentx == this.wh.char_main.currentx &&
                this.wh.mapmob[k].currenty == this.wh.char_main.currenty
              )) {
              this.plog.log("startcombat from space (bar)");
              this.wh.registry.list.combat.start(this.wh);
              return;
            }
          }
        }
        if (this.wh.flybysprite != undefined) {
          var dist = this.calculateDistance(this.wh.flybysprite.x, this.wh.flybysprite.y, this.wh.char_main.x, this.wh.char_main.y);
          this.plog.log("flybydist", dist);
          if (dist < 120) {
            try {
              this.wh.flybysprite.emit("pointerdown");
            } catch (e) {
              this.plog.log(e);
            }
          }
        }

        break;

      case 'npc':
        if (this.wh.registry.list.npcconver.kbcursor != undefined) {
          this.wh.registry.list.npcconver.clickchoicebyid();
        }
        this.plog.log("GPS.do_okenter npc");
        break;

      case 'rcalert':
        this.plog.log("kbfocusrcalert", this.wh.registry.list.rctoast);
        this.wh.registry.list.rctoast.rcalert_clickclosebtn(this.wh);// = "ok";
        //this.scene.registry.list.rctoast.rcalert_close();
        this.wh.kbfocus = "";
        break;


      case 'rclongalert':
        this.plog.log("kbfocusrclongalert", this.wh.registry.list.rctoast);
        this.wh.registry.list.rctoast.rclongalert_clickclosebtn(this.wh);// = "ok";
        //this.scene.registry.list.rctoast.rcalert_close();
        this.wh.kbfocus = "";
        break;

      case 'rcconfirm':
        this.plog.log("rcconfirm ", this.wh.registry.list.rctoast);
        this.wh.registry.list.rctoast.rcconfirmpass = "ok";
        this.wh.registry.list.rctoast.rcconfirm_clickclose(this.wh);// = "ok";
        //this.scene.registry.list.rctoast.rcalert_close();
        this.wh.kbfocus = "";
        break;
      default:
        break;
    }
  }
  do_altnext() {

    if (this.wh.kbfocus == 'guiwindow') {
      this.plog.log("guiwindow do_altnext", this.wh.activewindow, this.wh);
      //this.do_gamemenu();

      try {
        this.wh.registry.list.gameitf.guiwindownext(this.wh);
      } catch (e) {
        this.plog.log(e);
      }

    }
  }
  async guigrid(grid, preferfirst = null) {
    this.wh.kbfocus = "guigrid";
    this.guigrid_noclosebtn = false;
    if (this.slfocusl != undefined) {
      try {
        this.slfocusl.destroy();
      } catch (e) {
        this.plog.log(e);
      }
    }
    if (this.slfocusr != undefined) {
      try {
        this.slfocusr.destroy();
      } catch (e) {
        this.plog.log(e);
      }
    }

    this.plog.warn("GPS.guigrid()");
    const pads = this.wh.input.gamepad.gamepads;
    if (pads != undefined && pads.length == 0) {
      this.plog.warn("GPS.guigrid , pads.length == 0;");
      return;
    }

    this.guigrid_preferfirst = preferfirst;
    this.guigrid_origgrid = grid;

    var chkres = this.guigrid_creategridsub();
    if (chkres == false) {
      this.wh.kbfocus = "";
      this.plog.warn("GPS.guigrid ,guigrid_creategridsub returned false;");
      return;
    }

    this.slfocusl = this.wh.add.sprite(0, 0, "select", "select_l");
    this.slfocusr = this.wh.add.sprite(0, 0, "select", "select_r");
    this.slfocusl.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);
    this.slfocusr.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);

    var tgobj = grid[0];
    if (tgobj != undefined && tgobj.parentContainer != undefined && tgobj.parentContainer != null &&
      (this.slfocusl.parentContainer == undefined || this.slfocusl.parentContainer == null)) {
      //if (tgobj.parentContainer.name != "mapcontainer") {
      this.plog.warn("GPS.guigrid() set parentContainer", tgobj);

      tgobj.parentContainer.add(this.slfocusl);
      tgobj.parentContainer.add(this.slfocusr);
      tgobj.parentContainer.bringToTop(this.slfocusl);
      tgobj.parentContainer.bringToTop(this.slfocusr);
      //}
    }
    //this.plog.error("HALT")
    //return;

    ///this.slfocusl.setPosition(-1000, -1000);
    ///this.slfocusr.setPosition(-1000, -1000);
    this.slfocusl.visible = true; this.slfocusr.visible = true;
    //this.plog.log("GPS.initxy", initx, inity); 

    this.plog.log("this.guigrid_grid", this.guigrid_grid);
    var firstitem;
    firstitem = this.guigrid_findfirstitem(this.guigrid_grid);
    if (this.guigrid_preferfirst != undefined && this.guigrid_preferfirst != null) {
      if (this.guigrid_preferfirst[0] != undefined) {
        firstitem = this.guigrid_preferfirst[0];
      }
    }
    this.plog.log("this.guigrid_grid firstitem", firstitem);
    this.guigrid_preferfirst = null;
    if (firstitem != undefined) {
      //await this.wh.registry.list.rctoast.sleep(100);
      //this.guigrid_current = firstitem;
      this.guigrid_focus(firstitem);
    }
  }
  guigrid_creategridsub() {
    var grid = this.guigrid_origgrid;
    this.guigrid_grid = [];
    var chknotempty = false;
    for (const k in grid) {
      var v = grid[k];
      if (v == undefined || v == null) continue;
      //this.plog.log("GPS.guigrid_creategridsub", k, v);
      if (v.visible == false) continue;
      var addx = 0;
      var addy = 0;
      if (v.parentContainer != undefined && v.parentContainer != null) {
        if (v.parentContainer.parentContainer != undefined && v.parentContainer.parentContainer != null) {
          addx = v.parentContainer.x;
          addy = v.parentContainer.y;
        }
      }
      var xuse = Math.floor((v.x + addx) / this.guigrid_grindround);
      var yuse = Math.floor((v.y + addy) / this.guigrid_grindround);
      //this.plog.log("set grind", xuse, yuse, v);
      //this.plog.log("set grind x", (v.x + addx), xuse);
      //this.plog.log("set grind y", (v.y + addy), yuse);
      if (yuse < this.guigrid_inity) this.guigrid_inity = yuse;
      if (xuse < this.guigrid_initx) this.guigrid_initx = xuse;
      if (yuse > this.guigrid_maxy) this.guigrid_maxy = yuse;
      if (xuse > this.guigrid_maxx) this.guigrid_maxx = xuse;

      if (this.guigrid_grid[yuse] == undefined) this.guigrid_grid[yuse] = [];
      this.guigrid_grid[yuse][xuse] = v;
      chknotempty = true;
    }
    if (chknotempty == false) {
      this.plog.error("guigrid_creategridsub none visible");
      return false;
    }
    return true;
  }
  guigrid_findfirstitem(array) {
    let leftmostItem;
    let lowestY = Infinity;

    var inity = this.guigrid_inity;;
    var initx = this.guigrid_initx;

    for (let i = inity; i < array.length; i++) {
      const item = array[i];
      //this.plog.log("gridloop", i, item);
      if (item == undefined) continue;
      for (let i2 = initx; i2 < item.length; i2++) {
        const y = item[i2];
        //this.plog.log("gridloop2", i2, y);
        if (y == undefined) continue;
        if (y == null) continue;
        if (i2 < lowestY) {
          lowestY = i2;
          leftmostItem = y;
        }
        // Assuming the y position is stored at index 1
      }
    }

    return leftmostItem;
  }
  async guigrid_focushide() {
    this.plog.warn("GPS.guigrid_focushide()")
    if (this.slfocusl != undefined) {
      try {
        this.slfocusl.destroy();
      } catch (e) {
        this.plog.log(e);
      }
    }
    if (this.slfocusr != undefined) {
      try {
        this.slfocusr.destroy();
      } catch (e) {
        this.plog.log(e);
      }
    }
    //this.guigrid_current = undefined;
  }

  async guigrid_focus(tgobj) {
    this.plog.warn("GPS.guigrid_focus()", tgobj);
    this.plog.warn("GPS.guigrid_focus() slobj", this.slfocusl, this.slfocusr);
    if (tgobj == undefined) {
      this.plog.warn("GPS.guigrid_focus() tgobj == undefined", tgobj);
      return;
    }
    if (this.wh.kbfocus == "") {
      this.plog.warn("GPS.guigrid_focus() kbfocus=''", tgobj);
      return;
    }
    if (tgobj.type == "Sprite") {
      this.plog.warn("GPS.guigrid_focus() sprite", tgobj.texture.firstFrame, tgobj.frame.name);
    }
    if (tgobj.type == "Image") {
      this.plog.warn("GPS.guigrid_focus() image", tgobj.texture.key);
    }

    var xuse = Math.floor(tgobj.x / this.guigrid_grindround);
    var yuse = Math.floor(tgobj.y / this.guigrid_grindround);
    this.plog.log("GPS.guigrid_focus() located at", xuse, yuse);

    this.guigrid_current = tgobj;
    //this.guigrid_current.scale=5
    //this.guigrid_current.alpha = 0.5
    this.slfocusl.play("select_l");
    //this.wh.focusguil.setOrigin(0, tgobj.originY);
    //this.plog.log("GPS.this.wh.focusguil", this.wh.focusguil);
    //this.wh.focusguil.setPosition(tgobj.x - tgobj.displayOriginX, tgobj.y - tgobj.displayOriginY);
    var addx = 0;
    var addy = 0;
    if (tgobj.parentContainer != undefined && tgobj.parentContainer != null) {
      if (
        (tgobj.parentContainer.parentContainer != undefined && tgobj.parentContainer.parentContainer != null)
      ) {
        addx = tgobj.parentContainer.x;
        addy = tgobj.parentContainer.y;
        this.plog.log("ADDXY", addx, addy);
      }
    }

    //this.slfocusl.setPosition(tgobj.x - tgobj.displayOriginX + this.slfocusl.displayOriginX + addx, tgobj.y + addy);
    this.slfocusl.setPosition(
      tgobj.x - (tgobj.displayWidth / 2) + addx,
      tgobj.y + addy);
    this.slfocusl.displayHeight = tgobj.displayHeight;
    this.slfocusl.scaleX = this.slfocusl.scaleY;


    this.slfocusr.play("select_r");

    //this.wh.focusguil.setOrigin(0, tgobj.originY);
    //this.plog.log("GPS.this.wh.focusguir", this.wh.focusguil);
    //this.wh.focusguir.setPosition(tgobj.x - tgobj.displayOriginX, tgobj.y - tgobj.displayOriginY);
    //this.slfocusr.setPosition(      tgobj.x + tgobj.displayOriginX - this.lfocusr.displayOriginX + addx,       tgobj.y + addy);
    this.slfocusr.setPosition(
      tgobj.x + (tgobj.displayWidth / 2) + addx,
      tgobj.y + addy);
    this.slfocusr.displayHeight = tgobj.displayHeight;
    this.slfocusr.scaleX = this.slfocusr.scaleY;


    if (false && tgobj.type == "Image") {
      this.slfocusl.setPosition(
        this.slfocusl.x,
        this.slfocusl.y + (tgobj.displayHeight / 2)
      );
      this.slfocusr.setPosition(
        this.slfocusr.x + (tgobj.displayWidth),
        this.slfocusr.y + (tgobj.displayHeight / 2)
      );
    }

    try {
      tgobj.emit("pointerover");
    } catch (e) {
      this.plog.log(e);
    }
    if (tgobj.parentContainer != undefined && tgobj.parentContainer != null && tgobj.parentContainer.name != "mapcontainer") {
      try {
        tgobj.parentContainer.bringToTop(this.slfocusl);
        this.plog.log("BRINGTOTOP!", this.slfocusl);
      } catch (e) {
        this.plog.log(e);
      }
      try {
        tgobj.parentContainer.bringToTop(this.slfocusr);
      } catch (e) {
        this.plog.log(e);
      }
    } else {
      this.slfocusl.setDepth(999999999)
      this.slfocusr.setDepth(999999999)
    }
    this.wh.registry.list.resizeman.RCMapResizeManager_work(this.wh);
    this.wh.camman(this.wh);
    this.guigrid_creategridsub();
  }
  guigrid_findwithdir(direction) {
    //var array = JSON.parse(      JSON.stringify(this.guigrid_grid)    );
    var array = this.guigrid_grid;
    this.plog.log("GPS.guigrid_findwithdir", array)
    var numRows = this.guigrid_maxy;//array.length;
    var numCols = this.guigrid_maxx;//;
    /*for (let i = 0; i < array.length; i++) {
      var arrayv = array[i];
      if (arrayv == undefined || arrayv == null) continue;
      if (arrayv.length > numCols) numCols = arrayv.length;
    }*/
    //this.plog.log("GPS.guigrid_findwithdir",numRows,numCols)

    var addx = 0;
    var addy = 0;
    if (this.guigrid_current != undefined && this.guigrid_current.parentContainer != undefined && this.guigrid_current.parentContainer != null) {
      if (this.guigrid_current.parentContainer.parentContainer != undefined && this.guigrid_current.parentContainer.parentContainer != null) {
        addx = this.guigrid_current.parentContainer.x;
        addy = this.guigrid_current.parentContainer.y;
      }
    }
    if (this.guigrid_current == undefined) {
      this.plog.error("guigrid_findwithdir guigrid_current=undefined", this.guigrid_current);
      return;
    }
    var startX = Math.floor((this.guigrid_current.x + addx) / this.guigrid_grindround);
    var startY = Math.floor((this.guigrid_current.y + addy) / this.guigrid_grindround);

    let currentX = startX;
    let currentY = startY;
    this.plog.log("GPS.guigrid_findwithdir current", currentX, currentY)

    switch (direction) {
      case 'left':
        while (currentX >= this.guigrid_initx) {
          currentX--;
          if (array[currentY] != undefined && array[currentY][currentX] != undefined && array[currentY][currentX] != null) {
            if (array[currentY][currentX].visible == true)
              return [currentX, currentY, array[currentY][currentX]];
          }
        }
        break;
      case 'right':
        while (currentX <= numCols) {
          currentX++;
          if (array[currentY] != undefined && array[currentY][currentX] != undefined && array[currentY][currentX] != null) {
            if (array[currentY][currentX].visible == true)
              return [currentX, currentY, array[currentY][currentX]];
          }
        }
        break;
      case 'up':
        while (currentY >= this.guigrid_inity) {
          currentY--;
          if (array[currentY] != undefined && array[currentY][currentX] != undefined && array[currentY][currentX] != null) {
            if (array[currentY][currentX].visible == true)
              return [currentX, currentY, array[currentY][currentX]];
          }
        }
        break;
      case 'down':
        while (currentY <= numRows) {
          currentY++;
          if (array[currentY] != undefined && array[currentY][currentX] != undefined && array[currentY][currentX] != null) {
            if (array[currentY][currentX].visible == true)
              return [currentX, currentY, array[currentY][currentX]];
          }
        }
        break;
      default:
        //return null;
        break;
    }
    this.plog.log("GPS.guigrid_findwithdir NOTFOUND");

    //doing intensive search depend on direction

    switch (direction) {
      case 'left':
        for (var i = this.guigrid_inity; i <= numRows; i++) {
          for (var j = startX - 1; j >= this.guigrid_initx; j--) {
            if (array[i] != undefined && array[i][j] != undefined && array[i][j] != null) {
              if (array[i][j].visible == true)
                return [j, i, array[i][j]];
            }
          }
        }
        break;
      case 'right':
        for (var i = this.guigrid_inity; i <= numRows; i++) {
          for (var j = startX + 1; j <= numCols; j++) {
            //this.plog.log("scanright", i, j);
            if (array[i] != undefined && array[i][j] != undefined && array[i][j] != null) {
              if (array[i][j].visible == true)
                return [j, i, array[i][j]];
            }
          }
        }
        break;
      case 'up':
        for (var i = startY - 1; i >= this.guigrid_inity; i--) {
          for (var j = this.guigrid_initx; j <= numCols; j++) {
            //this.plog.log("GPS.guigrid_findwithdir rnd 2",i,j);
            if (array[i] != undefined && array[i][j] != undefined && array[i][j] != null) {
              //this.plog.log("GPS.guigrid_findwithdir rnd 2 found", i, j, array[i][j].visible, array[i][j]);
              if (array[i][j].visible == true)
                return [j, i, array[i][j]];
            }
          }
        }
        break;
      case 'down':
        for (var i = startY + 1; i <= numRows; i++) {
          for (var j = this.guigrid_initx; j <= numCols; j++) {
            if (array[i] != undefined && array[i][j] != undefined && array[i][j] != null) {
              if (array[i][j].visible == true)
                return [j, i, array[i][j]];
            }
          }
        }
        break;
      default:
        //return null;
        break;
    }
    this.plog.log("GPS.guigrid_findwithdir NOTFOUND round 2");

    return null; // No valid position found in the specified direction
  }
  guigrid_okenter() {
    this.plog.log("GPS.guigrid_okenter()", this.guigrid_current);
    //this.guigrid_focushide();
    if (this.wh.kbfocuslv1 != "") {
      this.plog.log("GPS guigrid_okenter() halted, kbfocuslv1", this.wh.kbfocuslv1);
    }
    //this.guigrid_focushide();
    //this.wh.kbfocus = "";
    try {
      this.guigrid_current.emit('pointerdown');
    } catch (e) {
      this.plog.error("GPS.guigrid_okenter()", e);
    }
    this.guigrid_creategridsub();
  }
  do_l() {
    this.plog.log("GPS.do_l", this.wh.kbfocus);
    if (this.wh.kbfocus == "guigrid") {
      var tmp = this.guigrid_findwithdir('left');
      this.plog.log("GPS.do_r", tmp);
      if (tmp != null && tmp != undefined && tmp[2] != undefined && tmp[2] != null) {
        this.guigrid_focus(tmp[2]);
      }
    }

  }
  do_r() {
    this.plog.log("GPS.do_r", this.wh.kbfocus);
    if (this.wh.kbfocus == "guigrid") {
      var tmp = this.guigrid_findwithdir('right');
      this.plog.log("GPS.do_r", tmp);
      if (tmp != null && tmp != undefined && tmp[2] != undefined && tmp[2] != null) {
        this.guigrid_focus(tmp[2]);
      }
    }
  }
  do_u() {
    this.plog.log("GPS.do_u", this.wh.kbfocus);
    if (this.wh.kbfocus == "guigrid") {
      var tmp = this.guigrid_findwithdir('up');
      this.plog.log("GPS.do_r", tmp);
      if (tmp != null && tmp != undefined && tmp[2] != undefined && tmp[2] != null) {
        this.guigrid_focus(tmp[2]);
      }
      return;
    }
    if (this.wh.kbfocus == "npc") {
      if (this.wh.registry.list.npcconver.kbcursor != undefined) {
        this.wh.registry.list.npcconver.kbcursor -= 1;
        if (this.wh.registry.list.npcconver.kbcursor <= -1)
          this.wh.registry.list.npcconver.kbcursor = 0;
        this.wh.registry.list.npcconver.kbcursorupdate();
      }
      return;
    }


  }
  do_d() {
    console.log("GPS.do_d", this.wh.kbfocus);
    if (this.wh.kbfocus == "guigrid") {
      var tmp = this.guigrid_findwithdir('down');
      this.plog.log("GPS.do_r", tmp);
      if (tmp != null && tmp != undefined && tmp[2] != undefined && tmp[2] != null) {
        this.guigrid_focus(tmp[2]);
      }
      return;
    }
    if (this.wh.kbfocus == "npc") {
      if (this.wh.registry.list.npcconver.kbcursor != undefined) {
        this.wh.registry.list.npcconver.kbcursor += 1;
        this.wh.registry.list.npcconver.kbcursorupdate();
      }
      return;
    }


  }

  calculateDistance(x1, y1, x2, y2) {
    const deltaX = x2 - x1;
    const deltaY = y2 - y1;
    const distance = Math.sqrt(deltaX ** 2 + deltaY ** 2);
    return distance;
  }
}
